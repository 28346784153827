import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom"; // import de NavLink qui permet de modifier les URLS
import {
    Box, Drawer, Divider, List, ListItemIcon,
    ListItemText, IconButton, ListItemButton, ListSubheader, Stack, Typography, Chip
} from "@mui/material";

import {
    ViewList, PinDrop, Map, Balance, ChevronLeft
} from '@mui/icons-material';

import StringTranslate from '../assets/i18n/stringLanguage.jsx';
import ParcelFilter from './parcelFilter.jsx';
import { ParcelsHelper } from '../utils/parcelsHelper.js';
import lodashGet from 'lodash/get';
import {
    ActionGoToMap, 
    ActionGoToModulation, 
    ActionGoToBiomass, 
    ActionGoToFertilizer,
    ActionGoToHarvest, 
    ActionGoToMapAndSelectParcel, 
    ActionGoToParcels, 
    ActionGoToObservations,
    ActionBurgerMenuCollapse,
    ActionSelectParcel,
    EnumGoToView,
    ActionFitMapToBounds,
    ActionGoToModelisation
} from '../redux/actions/contextApp.js';
import { ActionInitFertilizerDatas } from '../redux/actions/fertilizer';
import { ThumbnailParcelShapeFromPathInfos } from './thumbnail/ThumbnailParcelShape.jsx';
import IconeModulation from '../assets/images/modulation/iconeModulation.tsx';
import FertilizationIcon from "../assets/images/fertilization/fertilizationIcon.tsx";
//import LogoSpotifarm from '../assets/logoSpotifarm/LogoSpotifarmTitreEtFeuille'; // version 'avec feuille' mais pas afficher par ce composant mais par le 'Header' (en cas d'écran PC) !
import LogoSpotifarmSansFeuille from "../assets/logoSpotifarm/LogoSpotifarmSansFeuille"; //Rq: Voir 'logo-spotifarm-noel-mobile.png' dans l'histo de TFS pour la 'version de Noël' !

import CornIcon from '../assets/images/harvest/cornIcon.tsx';

import { ConstantsContent } from '../utils/constantsContent.js';
//import { ConstantsHosters } from '../utils/constantsByConfig';

/* theme */
import getTheme from "../themes/index.js";
let theme = getTheme();

///////////////////////////////////////////////////////////////////////////
// Composant global définissant le menu générale de notree application web.
///////////////////////////////////////////////////////////////////////////
class Sidebar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant d'être averti de la sélection de l'une des vues
    // (Carte, modulation ou parcelles)
    ///////////////////////////////////////////////////////////////////////////
    handleSelectMapPageClick = (event) => {
        if (this.props.goToMap) {
            this.props.goToMap();
        }
    };

    handleSelectModulationPageClick = (event) => {
        const { goToModulation } = this.props;
        if (window.dataLayer) // google tag manager
            window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'modulationPage' });

        if (goToModulation) {
            goToModulation();
        }
    };

    handleSelectBiomassPageClick = (event) => {
        const { goToBiomass } = this.props;
        if (window.dataLayer) // google tag manager
            window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'biomassPage' });

        if (goToBiomass) {
            goToBiomass();
        }
    };

    handleSelectFertilizerPageClick = (event) => {
        const { goToFertilizer, initFertilizerDatas, culturesForFertilizing } = this.props;
        if (window.dataLayer) // google tag manager
            window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'fertilizerPage' });

        if (goToFertilizer) {
            //Initialisation (Mise à zéro des données concernant la fumure)
            if (culturesForFertilizing.length <= 0) {
                initFertilizerDatas();
            }

            goToFertilizer();
        }
    }

    handleSelectHarvestPageClick = (event) => {
        const { goToHarvest } = this.props;
        if (window.dataLayer) // google tag manager
            window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'harvestPage' });

        if (goToHarvest) {
            
            goToHarvest();
        }
    }

    handleSelectParcelsPageClick = (event) => {
        if (this.props.goToParcels) {
            this.props.goToParcels();
        }
    };

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant d'être averti de la sélection de l'une des vues
    // (Carte, modulation ou parcelles) Même fonction mais demande aussi la rétractation du sidebar 
    // du sidebar (cas mobile / petite tablette)
    ///////////////////////////////////////////////////////////////////////////
    handleSelectMapPageMobileOpenClick = (event) => {
        if (this.props.goToMap) {
            this.props.goToMap();
        }

        //Pas besoin de faire plus, pour permettre prévenir la parenté que l'on a sélectionné une parcelle,
        // et que l'on veut aller sur la carte.
        //Le composant 'TableRow fait la redirection!

        //Par contre prévient le parent que l'on souhaite voir le 'sidebar' se rétracter:
        if (this.props.colapseBurgerMenu) {
            this.props.colapseBurgerMenu();
        }
    };
    handleSelectParcelsPageMobileOpenClick = (event) => {

        const { goToParcels, colapseBurgerMenu } = this.props;
        if (goToParcels) {
            goToParcels();
        }

        //Pas besoin de faire plus, pour permettre prévenir la parenté que l'on a sélectionné une parcelle,
        // et que l'on veut aller sur la carte.
        //Le composant 'TableRow fait la redirection!

        //Par contre prévient le parent que l'on souhaite voir le 'sidebar' se rétracter:
        if (colapseBurgerMenu) {
            colapseBurgerMenu();
        }
    };

    handleSelectModulationPageMobileOpenClick = (event) => {
        const { goToModulation, colapseBurgerMenu } = this.props;

        if (window.dataLayer) // google tag manager
            window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'modulationPage' });

        if (goToModulation) {
            goToModulation();
        }

        //Par contre prévient le parent que l'on souhaite voir le 'sidebar' se rétracter:
        if (colapseBurgerMenu) {
            colapseBurgerMenu();
        }
    };

    handleSelectBiomassPageMobileOpenClick = (event) => {
        const { goToBiomass, colapseBurgerMenu } = this.props;

        if (window.dataLayer) // google tag manager
            window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'biomassPage' });

        if (goToBiomass) {
            goToBiomass();
        }

        //Par contre prévient le parent que l'on souhaite voir le 'sidebar' se rétracter:
        if (colapseBurgerMenu) {
            colapseBurgerMenu();
        }
    };

    handleSelectFertilizerPageMobileOpenClick = (event) => {
        const { goToFertilizer, colapseBurgerMenu, initFertilizerDatas, culturesForFertilizing } = this.props;

        if (window.dataLayer) // google tag manager
            window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'fertilizerPage' });

        if (goToFertilizer) {
            //Initialisation (Mise à zéro des données concernant la fumure)
            if (culturesForFertilizing.length <= 0) {
                initFertilizerDatas();
            }

            goToFertilizer();
        }

        //Par contre prévient le parent que l'on souhaite voir le 'sidebar' se rétracter:
        if (colapseBurgerMenu) {
            colapseBurgerMenu();
        }
    };

    handleSelectHarvestPageMobileOpenClick = (event) => {
        const { goToHarvest, colapseBurgerMenu } = this.props;

        if (window.dataLayer) // google tag manager
            window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'harvestPage' });

        if (goToHarvest) {
           
            goToHarvest();
        }

        //Par contre prévient le parent que l'on souhaite voir le 'sidebar' se rétracter:
        if (colapseBurgerMenu) {
            colapseBurgerMenu();
        }
    };

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant d'être averti de la sélection de l'une des parcelles
    ///////////////////////////////////////////////////////////////////////////
    handleParcelSelectingClick = (event, parcelId) => {
        const { goToMapAndSelectParcel } = this.props;

        let parcelIdNumber = parcelId;
        if (parcelId && (!Number.isInteger(parcelId))) {
            parcelIdNumber = parseInt(parcelId, 10);
            if (isNaN(parcelIdNumber) || (parcelIdNumber <= 0)) return;
        }

        if (goToMapAndSelectParcel) {
            goToMapAndSelectParcel(parcelIdNumber);
        }
    };

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant d'être averti de la sélection de l'une des parcelles
    // Même fonction mais demande aussi la rétractation du sidebar 
    // (cas mobile / petite tablette)
    ///////////////////////////////////////////////////////////////////////////
    handleParcelSelectingMobileOpenClick = (event, parcelId) => {
        const { goToMapAndSelectParcel, fitMapToBounds } = this.props;
        if (goToMapAndSelectParcel) {
            goToMapAndSelectParcel(parcelId);
            fitMapToBounds(parcelId);
        }

        //Pas besoin de faire plus, pour permettre prévenir la parenté que l'on a sélectionné une parcelle,
        // et que l'on veut aller sur la carte.
        //Le composant 'TableRow fait la redirection!

        //Par contre prévient le parent que l'on souhaite voir le 'sidebar' se rétracter:
        if (this.props.colapseBurgerMenu) {
            this.props.colapseBurgerMenu();
        }
    };

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de sélectionner la vue 'Mes observations' et de fermet le sidebar (exclusivement en mobile)
    ///////////////////////////////////////////////////////////////////////////
    handleSelectObservationsPageMobileOpenClick = (event) => {
        this.props.colapseBurgerMenu();
        this.props.goToObservations();
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant d'être averti de la fermeture du sidebar 
    // (cas mobile / petite tablette)
    ///////////////////////////////////////////////////////////////////////////
    handleSidebarMobileCloseClick() {
        if (this.props.colapseBurgerMenu) {
            this.props.colapseBurgerMenu();
        }
    };

    chooseimgForMobile() {
        /*let cultureValue = StringTranslate.getLanguage();
        if ((!cultureValue) || (cultureValue.length < 2)) {
            cultureValue = 'fr';
        }
        const languageValue = cultureValue.substring(0, 2);*/ //plus utile car le logo (feuille est invariable à la langue / pays !)

        return (
            <div>
                <LogoSpotifarmSansFeuille />
            </div>
        )
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if ((!nextProps) || (!nextProps.parcelIdSelected)) return;

        try {
            const ulElement = document.getElementById(`listItem_${nextProps.parcelIdSelected}`);
            if (ulElement) {
                ulElement.scrollIntoView();
            } // tampis!!! on ne replacera pas l'accesseur vertival, de façon à voir l'élément sélectionné !
        }
        catch (e) {
            //console.log(`updateScrollPosition-e:'${e}'!`);
        }
    }

    getContentParcelNamesWithThumbnail() {
        const { parcelDico, parcelIdSelected, parcelIdFilteredList, thumbnailParcelDico } = this.props;

        if ((!parcelDico) || (!parcelIdFilteredList)) return undefined;

        let returnContent = [];
        parcelIdFilteredList.forEach((itemParcelId, index) => {
            const parcelFund = ParcelsHelper.selectParcelFromDicoById(parcelDico, parseInt(itemParcelId));
            const thumbnailParcelItem = ParcelsHelper.selectParcelFromDicoById(thumbnailParcelDico, itemParcelId);
            if (parcelFund) {
                const parcelFundId = parcelFund.id;
                returnContent.push(
                    <ListItemButton id={`dftListItem_${parcelFundId}`}
                        component={Link} to={"/map/parcel/" + parcelFundId}
                        key={index} selected={parcelIdSelected === parcelFundId}
                        onClick={(event) => { this.handleParcelSelectingMobileOpenClick(event, parcelFundId) }}>
                        {/* ↓ AVEC ou SANS miniature ↓ */}
                        {(thumbnailParcelItem) && (<ListItemIcon>
                            <ThumbnailParcelShapeFromPathInfos id={`listItemTbl_${parcelFundId}`} {...thumbnailParcelItem} />
                        </ListItemIcon>)}

                        <ListItemText id={`dftListItemTxt_${parcelFundId}`}
                            primary={(parcelFund.name) ? parcelFund.name : `N°${index}`}
                            secondary={lodashGet(parcelFund, 'details.culture', null)}
                        />
                    </ListItemButton>
                );
            }
            //else //n'ajoute rien ! (mais ne devrait pas arriver)
        });

        return returnContent;
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction de rendu visuel - layout général
    ///////////////////////////////////////////////////////////////////////////
    render() { //TODO : Revoir la 'List' associée à la "ZONE - LOGO + bouton cloture burger", car sans 'item', je ne vois pas son intérêt...
        const { /*parcelDico, parcelIdSelected,*/ parcelDicoCounter, parcelIdFilteredList, parcelIdFilteredListCounter,
            burgerMenuIsOpen, currentPage, isOpenMenuBurgerDesktop, codeCountry, isFilterOpened } = this.props;

        const displayFertilizer = (codeCountry === 'FR');
        //console.log('ConstantsHosters.appMode: ', ConstantsHosters.appMode);

        return (
            <div className="wrapper">
                {/* Cache le contenu lorsque la taille d'écran est suppérieur à 'medium'. Apparaît alors dans le cas 'mobile / petite tablette', où ce menu n'apparaît que */}
                {/* si on a appuyé sur le bouton 'burger' (via le passage de 'burgerMenuIsOpen' à vrai). sur le clic d'un élément, on demande à ce qu'il soit rétracté! */}
                <Box
                    sx={{
                        display: { xs: 'block', sm: 'block', md: 'none' },
                    }}
                >
                    <Drawer
                        variant="temporary"
                        anchor="left"
                        open={burgerMenuIsOpen}
                        onClose={this.handleSidebarMobileCloseClick.bind(this)}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                        sx={{
                            '& .MuiDrawer-paper': {
                                width: '260px',
                            }
                        }}
                    >
                        <Box>
                            <ListSubheader component="nav">
                                {/* ↓ ZONE - LOGO + bouton cloture burger ↓ */}
                                <div className="logoAndButtonForMobile">
                                    <IconButton onClick={(event) => { this.handleSidebarMobileCloseClick(event) }}>
                                        <ChevronLeft />
                                    </IconButton>
                                    <div className="marqueImgWrapper" >
                                        {this.chooseimgForMobile()}
                                    </div>
                                </div>

                                <Divider />

                                {/* ↓ ZONE - MENU - BOUTONS ↓ */}

                                {/* bouton - carte */}
                                <ListItemButton
                                    selected={currentPage === EnumGoToView.map}
                                    onClick={(event) => { this.handleSelectMapPageMobileOpenClick(event) }}
                                >
                                    <ListItemIcon>
                                        <Map />
                                    </ListItemIcon>
                                    <ListItemText primary={`${StringTranslate.carte}`} />
                                </ListItemButton>

                                {/* bouton - Mes pesées de biomasse colza */}
                                <ListItemButton
                                    selected={currentPage === EnumGoToView.biomass} onClick={(event) => { this.handleSelectBiomassPageMobileOpenClick(event) }}>
                                    <ListItemIcon>
                                        <Balance />
                                    </ListItemIcon>
                                    <ListItemText primary={`${StringTranslate.btnBiomassOnSidebar}`} />
                                </ListItemButton>

                                {/* bouton - Ma modulation */}
                                <ListItemButton
                                    selected={currentPage === EnumGoToView.modulation} onClick={(event) => { this.handleSelectModulationPageMobileOpenClick(event) }}>
                                    <ListItemIcon>
                                        <IconeModulation/>
                                    </ListItemIcon>
                                    <ListItemText primary={`${StringTranslate.btnModulationOnSidebar}`} />
                                </ListItemButton>

                                {/* bouton - Fumure */}
                                {(displayFertilizer === true) &&
                                    (<ListItemButton
                                        selected={currentPage === EnumGoToView.fertilizer} onClick={(event) => { this.handleSelectFertilizerPageMobileOpenClick(event) }}
                                        sx={{
                                            "& .MuiListItemIcon-root": { "stroke": theme.palette.grey[700] },
                                            "&.Mui-selected": {
                                                "& .MuiListItemIcon-root": { "stroke": theme.palette.secondary.dark }
                                            },
                                            "&:hover": {
                                                "& .MuiListItemIcon-root": { "stroke": theme.palette.secondary.dark }
                                            }
                                        }}
                                    >
                                        <ListItemIcon>
                                            <FertilizationIcon sx={{ width: '34px', height: '34px' }} />
                                        </ListItemIcon>
                                        <ListItemText primary={StringTranslate.btnFertilizerOnSidebar}/>
                                    </ListItemButton>)}

                                {/* bouton - Ma récolte */}
                                <ListItemButton
                                    selected={currentPage === EnumGoToView.harvest} onClick={(event) => { this.handleSelectHarvestPageMobileOpenClick(event) }}>
                                    <ListItemIcon>
                                        <CornIcon
                                            sx={{
                                                stroke: theme.palette.grey[600],
                                                '&:selected': { stroke: theme.palette.secondary.main }
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                            <Typography>{StringTranslate.btnHarvestOnSidebar}</Typography>
                                            <Chip label={StringTranslate.newFeaturesAlert} color="primary" size={"small"} />
                                        </Stack>
                                    } />
                                </ListItemButton>

                                {/* bouton - parcelles */}
                                <ListItemButton
                                    selected={currentPage === EnumGoToView.parcels} onClick={(event) => { this.handleSelectParcelsPageMobileOpenClick(event) }}
                                >
                                    <ListItemIcon>
                                        <ViewList />
                                    </ListItemIcon>
                                    {(parcelDicoCounter > 0) ? (
                                        <ListItemText primary={
                                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                <Typography>{StringTranslate.parcellesListBtnOnSidebar}</Typography>
                                            </Stack>
                                        }/>
                                    ) : (
                                        <ListItemText primary={`${StringTranslate.parcellesImportBtnOnSidebar}`} />
                                    )}
                                </ListItemButton>

                                {/* bouton - mes observations */}
                                <ListItemButton
                                    selected={currentPage === EnumGoToView.observations}
                                    onClick={this.handleSelectObservationsPageMobileOpenClick}
                                >
                                    <ListItemIcon>
                                        <PinDrop />
                                    </ListItemIcon>
                                    <ListItemText primary={`${StringTranslate.observationBtnOnSidebar}`} />
                                </ListItemButton>

                                <Divider />

                                {/* ↓ ZONE - FILTRE ↓ */}
                                {(parcelDicoCounter > 0) && (<>
                                    <ParcelFilter {...this.props} />

                                    <Divider /></>)}

                            </ListSubheader>

                            <Box sx={{
                                paddingRight: "16px",
                                paddingLeft: "16px",
                            }}>
                                {/* ↓ ZONE - LISTE DES NOMS DE PARCELLE ↓ */}
                                {(parcelIdFilteredList && (parcelIdFilteredListCounter > 0)) && (
                                    <List id="listIdParcelName">
                                        {this.getContentParcelNamesWithThumbnail()}
                                    </List>
                                )}
                            </Box>

                        </Box>
                    </Drawer>
                </Box>

                {/* Cache le contenu lorsque la taille d'écran est inférieur à 'small medium'. apparaît alors dans le cas 'grande tablette / PC',  */}
                {/* ce menu est affiché en permanence! Pas besoin alors de prévenir d'une sélection, sauf le cas d'une parcelle pour sa surbrillance. */}
                <Box
                    sx={{
                        display: { xs: 'none', sm: 'none', md: 'block' }
                    }}
                >
                    <Drawer
                        anchor="left"
                        variant="persistent"
                        open={isOpenMenuBurgerDesktop}
                        sx={{
                            '& .MuiDrawer-paper': {
                                width: ConstantsContent.DrawerWidth,
                                background: theme.palette.background.default,
                                color: theme.palette.text.primary,
                                borderRight: 'none',
                                [theme.breakpoints.up('md')]: {
                                    top: '70px'
                                }
                            }
                        }}
                    >
                        <Box id="sidebarContainer" sx={{ pr: 1.5, pl: 1.5 }}>

                            {/* ↓ ZONE - MENU - BOUTONS ↓ */}
                            <ListSubheader component="nav">

                                {/* bouton - carte */}
                                <ListItemButton
                                    selected={currentPage === EnumGoToView.map}
                                    onClick={(event) => { this.handleSelectMapPageClick(event) }}
                                >
                                    <ListItemIcon>
                                        <Map />
                                    </ListItemIcon>
                                    <ListItemText primary={`${StringTranslate.carte}`} />
                                </ListItemButton>

                                {/* bouton - mes pesées de biomasse colza */}
                                <ListItemButton
                                    selected={currentPage === EnumGoToView.biomass}
                                    onClick={(event) => { this.handleSelectBiomassPageClick(event) }}>
                                    <ListItemIcon>
                                        <Balance />
                                    </ListItemIcon>
                                    <ListItemText primary={`${StringTranslate.btnBiomassOnSidebar}`} />
                                </ListItemButton>

                                {/* bouton - modulation */}
                                <ListItemButton
                                    selected={currentPage === EnumGoToView.modulation}
                                    onClick={(event) => { this.handleSelectModulationPageClick(event) }}>
                                    <ListItemIcon>
                                        <IconeModulation/>
                                    </ListItemIcon>
                                    <ListItemText primary={`${StringTranslate.btnModulationOnSidebar}`} />
                                </ListItemButton>

                                {/* bouton - fumure */}
                                {(displayFertilizer === true) &&
                                    (<ListItemButton
                                        selected={currentPage === EnumGoToView.fertilizer}
                                        onClick={(event) => { this.handleSelectFertilizerPageClick(event) }}
                                        sx={{
                                            "& .MuiListItemIcon-root": { "stroke": theme.palette.grey[700] },
                                            "&.Mui-selected": {
                                                "& .MuiListItemIcon-root": { "stroke": theme.palette.secondary.dark }
                                            },
                                            "&:hover": {
                                                "& .MuiListItemIcon-root": { "stroke": theme.palette.secondary.dark }
                                            }
                                        }}
                                    >
                                        <ListItemIcon>                                            
                                            <FertilizationIcon sx={{width:'34px', height:'34px'}} />
                                        </ListItemIcon>
                                        <ListItemText primary={StringTranslate.btnFertilizerOnSidebar}/>
                                    </ListItemButton>)}

                                {/* bouton - Ma récolte */}
                                <ListItemButton
                                    selected={currentPage === EnumGoToView.harvest} onClick={(event) => { this.handleSelectHarvestPageClick(event) }}>
                                    <ListItemIcon>
                                        <CornIcon
                                            sx={{
                                                stroke: theme.palette.grey[600],
                                                '&:selected': { stroke: theme.palette.secondary.main }
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={
                                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                            <Typography>{StringTranslate.btnHarvestOnSidebar}</Typography>
                                            <Chip label={StringTranslate.newFeaturesAlert} color="primary" size={"small"} />
                                        </Stack>
                                    } />
                                </ListItemButton>

                                {/* bouton - parcelles */}
                                <ListItemButton
                                    selected={currentPage === EnumGoToView.parcels}
                                    onClick={(event) => { this.handleSelectParcelsPageClick(event) }}
                                >
                                    <ListItemIcon>
                                        <ViewList />
                                    </ListItemIcon>
                                    {(parcelDicoCounter > 0) ? (
                                        <ListItemText primary={
                                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                <Typography>{StringTranslate.parcellesListBtnOnSidebar}</Typography>
                                            </Stack>
                                        }/>
                                    ) : (
                                        <ListItemText primary={`${StringTranslate.parcellesImportBtnOnSidebar}`} />
                                    )}
                                </ListItemButton>

                                {/* bouton - mes observations */}
                                <ListItemButton
                                    selected={currentPage === EnumGoToView.observations}
                                    onClick={this.props.goToObservations}
                                >
                                    <ListItemIcon>
                                        <PinDrop />
                                    </ListItemIcon>
                                    <ListItemText primary={`${StringTranslate.observationBtnOnSidebar}`} />
                                </ListItemButton>

                                <Divider />

                                {/* ↓ ZONE - FILTRE ↓ */}
                                {(parcelDicoCounter > 0) && (
                                    <>
                                        <ParcelFilter />

                                        <Divider />
                                    </>)}

                            </ListSubheader>

                            {/* ↓ ZONE - LISTE DES NOMS DE PARCELLE ↓ */}
                            {/* //TODO : A revoir pour être dynamique ! */}
                            {/* 310 : place occupée par le menu des pages ainsi que le filtre de parcelles */}
                            {/* 310 - 70 = 240 : 70px hauteur du header */}
                            {/* ce n'est plus 240 mais 300 maintenant qu'il y a plus d'onglet */}
                            {(parcelIdFilteredList && (parcelIdFilteredListCounter > 0)) && (
                                <List component="nav" id="listIdParcelName" sx={{ height: (isFilterOpened)?"calc(100% - 600px)":"calc(100% - 300px)" }}>
                                    {this.getContentParcelNamesWithThumbnail()}
                                </List>
                            )}
                        </Box>
                    </Drawer>
                </Box>
            </div>
        );
    }
}

//Définition des propriétés "décorant" le composant 'App':
const mapStateToProps = state => ({
    //Infos provenant du reducer 'parcels':
    parcelDico: state.parcelsData.parcelDico,
    parcelDicoCounter: state.parcelsData.parcelDicoCounter,
    parcelIdFilteredList: state.parcelsData.parcelIdFilteredList,
    parcelIdFilteredListCounter: state.parcelsData.parcelIdFilteredListCounter,
    thumbnailParcelDico: state.parcelsData.thumbnailParcelDico,

    //Infos provenant du reducer 'contextApp':
    currentPage: (state && state.contextAppData) ? state.contextAppData.currentPage : EnumGoToView.map,
    parcelIdSelected: (state && state.contextAppData) ? state.contextAppData.parcelIdSelected : -1,
    burgerMenuIsOpen: (state && state.contextAppData) ? state.contextAppData.burgerMenuIsOpen : false,

    isOpenMenuBurgerDesktop: (state && state.contextAppData) ? state.contextAppData.isOpenMenuBurgerDesktop : true,

    //Infos provenant du reducer 'fertilizer':
    culturesForFertilizing: lodashGet(state, 'fertilizerData.culturesForFertilizing', []),

    //Infos provenant du reducer 'Settings':
    codeCountry: lodashGet(state, 'settingsData.settings.codeCountry', 'FR'),

    isFilterOpened: lodashGet(state, 'contextAppData.isFilterOpened', false),
});

//Définition des méthodes "décorant" le composant 'App':
const mapDispatchToProps = dispatch => ({
    goToMap: () => dispatch(ActionGoToMap()),
    goToModulation: () => dispatch(ActionGoToModulation()),
    goToBiomass: () => dispatch(ActionGoToBiomass()),
    goToFertilizer: () => dispatch(ActionGoToFertilizer()),
    goToHarvest: () => dispatch(ActionGoToHarvest()),
    initFertilizerDatas: () => dispatch( ActionInitFertilizerDatas() ),
    goToMapAndSelectParcel: (parcelId) => dispatch(ActionGoToMapAndSelectParcel(parcelId)),
    goToParcels: () => dispatch(ActionGoToParcels()),
    goToObservations: () => dispatch(ActionGoToObservations()),
    goToModelisation: () => dispatch(ActionGoToModelisation()),

    colapseBurgerMenu: () => dispatch(ActionBurgerMenuCollapse()),

    selectParcel: (idParcel) => dispatch(ActionSelectParcel(idParcel)),

    fitMapToBounds: (parcelId) => dispatch(ActionFitMapToBounds(parcelId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);