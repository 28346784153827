import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


function SeedingIcon(props: SvgIconProps) {
    return ( 
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path stroke="current" d="M12 10a6 6 0 0 0 -6 -6h-3v2a6 6 0 0 0 6 6h3" />
              <path stroke="current" d="M12 14a6 6 0 0 1 6 -6h3v1a6 6 0 0 1 -6 6h-3" />
              <line stroke="current" x1="12" y1="20" x2="12" y2="10" />
            </svg>
        </SvgIcon>
    );
}

export default SeedingIcon;