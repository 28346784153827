import * as React from 'react';
import { connect } from 'react-redux';

/* Aides lodash pour obtention d'objet  dans un dico/savoir si un objet est vide/savoir si un objet est une date */
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';

/* Icones */
import IconPdf from '@mui/icons-material/PictureAsPdf';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import CloseIcon from '@mui/icons-material/Close';
import { ErrorOutline, Warning } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';

/* React components */
import CustomDataGrid from "../../components/customDataGrid";
import NitrogenResult from "../../components/fertilizer/nitrogenStepsManagement/nitrogenResult";
import { ThumbnailParcelShapeFromPathInfos } from '../../components/thumbnail/ThumbnailParcelShape.jsx';
import AlertDialog from '../alertDialog';

/* Redux */
import { TableType } from '../../redux/actions/settings.js';
import {
	ActionDeleteFertilizers, ActionGetAllFertilizers, 
	ActionClearFertilizerValue, ActionGoToShowThisFertilizer,
	ActionBuildListPrescriptions, ActionBuildFertilizerFile, ActionCleanErrorFertilizer, ActionGenerateFileOfThisFertilizer,
	ActionSetCropYearsSelectedDForValidatedFertilizer } from '../../redux/actions/fertilizer';
import { TypeDownloadChoice } from '../../redux/actions/modulations';
import { ActionDeleteLastNitrogenInput } from '../../redux/actions/lastNitrogenInput'; 
import { ProfilIndex, ActionShowProfilMenuDialog } from "../../redux/actions/contextApp.js";

/* Helpers */
import { IsNativeHoster } from '../../utils/platformHelper';
import FertilizerHelper from '../../utils/fertilizerHelper';
import { ParcelsHelper } from '../../utils/parcelsHelper.js';
import stringHelper from '../../utils/stringHelper';
import numberHelper from '../../utils/numberHelper.js';

/* Constantes */
import { ConstantsFertilizer } from '../../utils/constantsFertilizer.js';

/* Lien de contact */
import LinkToContactUs from '../linkToContactUs';

/* mui components */
import {
	CircularProgress, IconButton, Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
	Grid, Box, TextField, Stack, TableContainer, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel,
	InputLabel, Select, MenuItem, ListSubheader, OutlinedInput, Checkbox, ListItemText, Chip, List, ListItem
} from '@mui/material';
import DataGridSkeleton from '../dataGridSkeleton';

/* Traduction */
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

/* Theme Berry */
import getTheme from "../../themes/index.js";

let theme = getTheme();

/* ⚠ ⚠ ⚠ ⚠ */
/* Faire tous les tests pour la suppression de fumure car non testé */
class FertilizersList extends React.Component {

	constructor(props) {
		super(props);

		const firstErrorMessage = lodashGet(props, 'errorMessage', '');

		this.currentCropYear = FertilizerHelper.getCurrentCropYear(props.closingDateAzofert, props.openingDateAzofert); //Année de campagne en cours

		this.state = {
			//sauvegarde la langue en cours lors de la création du composant: (Car pas présente dans le store Redux)
            language: props.language,

			openConfirmDeleteFertilizersDialog: false,
			fertilizersToDelete: [],
			fertilizersSelected: [],
			showUseComputerForDownload: false, //ouverture de la dialog en mobile, indiquant qu'il faut passer sur l'appli PC !
			openConfirmDownloadFertilizerDialog: false, //ouverture de la dialog de téléchargement des fumures
			fertilizersToDownload: [],
			showExportModulationToDownload: true,
			searchFilter: '', //Zone de recherche du tableau 
			currentRowsOfTable: [], //Datas en fonction du texte tapé dans la zone de recherche
			rowsOfTable: [], //loadContentTable, //Les fumures
			openFertilizerResultDialog: false, //ouverture de la dialog pour afficher le résultat de la fumure
			fertilizerToShow: undefined, //résultat de fumure à afficher
			showFirstStepForDownloadingFertilizers: false, //affiche l'étape 1 qui signale 2 types d'apports différents (solide, liquide)
			typeDownloadChoice: TypeDownloadChoice.shp, //Par défaut, le type de fichier pour le téléchargement est SHP.
			showIncitationToUpgradeModulation: false, // Affiche un message d'incitation pour obtenir le droit de télécharger les carte de modulation

			bothSupplyType: false,
			totalDoseSupplyType: false, //False: Solid, True : Liquid
			countTotalDoseToExpand: 0,

			openDeleteLastInputNInfo: false,
			parcelNameWithLastNInput: [], // Nom de la parcelle avec dernier apport


			/* Historique */
			cropYears: [],
			cropYearsSelected: (props.cropYearsSelectedForFilterFertilizer && (props.cropYearsSelectedForFilterFertilizer.length > 0)) ? 
				props.cropYearsSelectedForFilterFertilizer : [this.currentCropYear],

		};

		this.handleCloseErrorDialog = this.handleCloseErrorDialog.bind(this);
		this.handleChangeTypeDownloadChoice = this.handleChangeTypeDownloadChoice.bind(this);

		// Définition du popup contenant le message d'erreur à diffuser au client:
		this.popupErrorDialog = {
			getTitle: () => { return (<ErrorOutline />); },
			description: firstErrorMessage, //Rq : sera mis à jour en fonction de la prop 'errorMessage' de redux 'fertilizerData' !
			getAdditionalDescription: () => {
				return (
					<Typography variant="subtitle1">
						{StringTranslate.errorDialogText2} <LinkToContactUs displayText={StringTranslate.contactUs} />. 
					</Typography>
				);
			},
			button: StringTranslate.close,
		};

		// Définition du popup contenant le message invitant le client à télécharger ses cartes depuis un PC:
		this.popupUseComputerForDownloadDialog = {
			getTitle: () => { return (<Warning />); },
			description: StringTranslate.msgUseComputerForDownloadModulation, //message d'incitation si on tente de télécharger depuis l'appli mobile !
			button: StringTranslate.close,
		};

        this.fullScreen = (window && window.innerWidth && (window.innerWidth < theme.breakpoints.values.lg)) ? true : false;
	}

	componentDidMount() {
		const { fertilizerDicoIsLoaded, clientId, getAllFertilizers, parcelsArchivedForFertilizerLoaded } = this.props;

		//Les fumures actuelles et archivées sont chargées à cette étape.

		//Les fumures actuelles et archivées:
		if ((fertilizerDicoIsLoaded === false) && (parcelsArchivedForFertilizerLoaded === false)) {
			getAllFertilizers(clientId);
		}

		//On alimente le tableau des fumures
		if (fertilizerDicoIsLoaded === true) {
			this.loadContentTable();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { errorMessage, fertilizerDicoIsLoaded, fertilizerDicoCounter, parcelArchivedForFertilizerDicoCounter } = this.props;
		const { language } = this.state;

		if (((!prevProps) || (!prevProps.errorMessage) || (prevProps.errorMessage === '') || (prevProps.errorMessage === undefined)) &&
			(errorMessage && (errorMessage !== '') && (errorMessage !== undefined))) {
			this.popupErrorDialog.description = errorMessage;

			this.setState({
				openDialogOfErrors: true,
			});
		}

		//On alimente le tableau des fumures :ParcelArchivedForFertilizerDico
		if (((prevProps.fertilizerDicoIsLoaded === false) && (fertilizerDicoIsLoaded === true)) ||
			(prevProps.fertilizerDicoCounter !== fertilizerDicoCounter) ||
			(prevProps.parcelArchivedForFertilizerDicoCounter !== parcelArchivedForFertilizerDicoCounter) ) {
			this.loadContentTable();
		}

		/* Lorsqu'il y a un changement de langue (même si on se doute que l'utilisateur ne changera pas de langue tous les 4 matins !), il faut actualiser certaines listes: */
        const currentLanguage = StringTranslate.getLanguage();
        if ((!language) || (language === '') || (language !== currentLanguage)) {

            this.setState({ language: currentLanguage, });
        }
	}

	//Toolbar customisé du tableau comprenant :
	// - la zone de recherche,
	// - les boutons de suppression et de téléchargement
	// - le bouton d'aide
	CustomToolbar(props) {
		const { bothSupplyType, countTotalDoseToExpand, totalDoseSupplyType, 
			handleChangeCropYear, cropYears, cropYearsSelected, currentCropYear } = props;
		const unity = (totalDoseSupplyType === false) ? "Kg" : "L";

		return (<>
			<Grid container spacing={2}>
				{/* Zone de recherche */}
				<Grid item xs={12}>
					<Grid container spacing={1}>
						<Grid item xs={8} sm={8} md={4} lg={4}>
							<TextField
								size="small"
								value={props.value}
								onChange={props.onChange}
								placeholder={StringTranslate.toolbarsearch}
								InputProps={{
									startAdornment: <SearchIcon fontSize="small" />,
									endAdornment: (
										<IconButton
											title="Clear"
											aria-label="Clear"
											size="small"
											style={{ visibility: props.value ? 'visible' : 'hidden' }}
											onClick={props.clearSearch}
										>
											<ClearIcon fontSize="small" />
										</IconButton>
									),
								}}
							/>
						</Grid>
						{/* Boutons de suppression et de téléchargement */}
						<Grid item xs={4} sm={4} md={8}>
							{/* Boutons en écran PC (>= md) */}
							<Box sx={{ display: { md: 'block', xs: 'none' }, '& button': { m: 1 } }} style={{ textAlign: 'end' }} >
								<Button
									variant="text" color="error"
									onClick={(evt, data) => props.deleteFertilizers(evt, props.rowsOfTable.filter(row => props.fertilizersSelected.includes(row.id)))}
									disabled={(props.fertilizersSelected.length <= 0) || (props.downloadingPrescriptions === true)}
								>
									{StringTranslate.deleteFertilizers}
								</Button>

								<Button
									variant="contained" color="primary"
									onClick={(evt, data) => props.downLoadFertilizers(evt, props.rowsOfTable.filter(row => props.fertilizersSelected.includes(row.id)))}
									disabled={(props.fertilizersSelected.length <= 0) || (props.downloadingPrescriptions === true)}
									startIcon={((props.downloadingPrescriptions === true)) && <CircularProgress size={20} color="inherit" />}
								>
									{((props.downloadingPrescriptions === true) ? StringTranslate.ColumnMdlToDownLoading : StringTranslate.ColumnMdlToDownLoad)}
								</Button>
							</Box>

							{/* Boutons en écran Mobile (< md) */}
							<Stack
								sx={{ display: { md: 'none', xs: 'block' }, '& button': { m: 1 }, pt: 1 }}
								style={{ textAlign: 'end' }}
								direction="row" spacing={1}
							>
								<IconButton color="error" size="large" aria-label="delete ferttilizer(s)" component="span"
									onClick={(evt, data) => props.deleteFertilizers(evt, props.rowsOfTable.filter(row => props.fertilizersSelected.includes(row.id)))}
									disabled={(props.fertilizersSelected.length <= 0) || (props.downloadingPrescriptions === true)}
									sx={{ p: 0 }}
								>
									<DeleteForeverSharpIcon />
								</IconButton>

								<IconButton color="primary" size="large" aria-label="download ferttilizer(s)" component="span"
									disabled={(props.fertilizersSelected.length <= 0) || (props.downloadingPrescriptions === true)}
									onClick={(evt, data) => { props.downLoadFertilizers(evt, props.rowsOfTable.filter(row => props.fertilizersSelected.includes(row.id))) }}
									sx={{ p: 0 }}
								>
									{(props.downloadingPrescriptions !== true) ? <DownloadIcon /> : <CircularProgress size={20} color="inherit" />}
								</IconButton>
							</Stack>
						</Grid>
					</Grid>
				</Grid>

				{/* historique actuelle ou antérieure */}
				{ (cropYears.length > 0) &&
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item>
								{/* Zone de recherche sur l'année de campagne' */}
								<FormControl sx={{ minWidth: 200 }} size="small">

									<InputLabel id="grouped-select-crop-year-lbl">{StringTranslate.cropYear}</InputLabel>
									<Select
										labelId="grouped-select-crop-year-lbl"
										id="grouped-select-crop-year"
										multiple
										value={cropYearsSelected}
										onChange={handleChangeCropYear}
                                    input={<OutlinedInput label={StringTranslate.cropYear} sx={{ textAlign: 'start' }} />}
										renderValue={(selected) => selected.join(', ')}
									>
										{/* La campagne en cours sera toujours affichée */}
										<ListSubheader>{StringTranslate.currentCropYear}</ListSubheader>
										<MenuItem value={currentCropYear}>
											<Checkbox checked={cropYearsSelected.includes(currentCropYear)} />
											<ListItemText primary={currentCropYear} />
										</MenuItem>

										{/* CropYears : Liste des campagnes présentes en base de données */}
										<ListSubheader>{StringTranslate.archivedCropYears}</ListSubheader>
										{cropYears.map((cropYear) => (
											(cropYear !== currentCropYear) && (<MenuItem key={cropYear} value={cropYear}>
												<Checkbox checked={cropYearsSelected.includes(cropYear)} />
												<ListItemText primary={cropYear} />
											</MenuItem>)))
										}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
				}
			</Grid>

			<Grid container spacing={2}>
				{/* Bouton d'aide */}
				<Grid item xs={6} sm={4} >
					<Button color="secondary" variant="text" size="small" onClick={() => props.showProfilMenuDialog(ProfilIndex.aide_Fumure)}>
						{StringTranslate.helpAsk}
					</Button>
				</Grid>

				{/* Quantité totale à épandre pour les fumures sélectionnées */}
				<Grid item xs={6} sm={8} style={{ textAlign: 'right' }}>
					<Stack
						direction="row"
						justifyContent="flex-end"
						alignItems="flex-end"
						spacing={1}
					>
						{(bothSupplyType === true) ? (
							<>
								<ReportProblemRoundedIcon color="error" />
								<Typography variant="body1">{` ${StringTranslate.totalDoseToExpandAlert}`}</Typography>
							</>
						) : (
							<Typography variant="body1">{(countTotalDoseToExpand > 0) ? `${StringTranslate.totalDoseToExpand}${numberHelper.fixeDecimal(countTotalDoseToExpand)} (${unity})` : `${StringTranslate.totalDoseToExpand} --`}</Typography>
						)}
					</Stack>
				</Grid>
			</Grid>
		</>
		);
	}

	/* Fonction qui permet la recherche dans le tableau */
	// Mets à jour également les années de campagne
	requestSearch = (searchValue, isFilterOnCropYear, rowsOfTable) => {
		let filteredRows = rowsOfTable;

		if (isFilterOnCropYear === false) {

			this.setState({ searchFilter: searchValue });

			try {
				const searchRegex = new RegExp(stringHelper.escapeRegExp(searchValue), 'i');
				filteredRows = rowsOfTable.filter((row) => {
					return Object.keys(row).some((field) => {
						let textValue = "" + row[field];
						return searchRegex.test(textValue.toString());
					});
				});

				this.setState({ currentRowsOfTable: filteredRows });
			}
			catch (errRegex) { /* Peut arriver si on saisis un truc du genre 'EARL++' ! (il n'aime pas les '++') */ }
		}
		else {
			filteredRows = filteredRows.filter((row) => {
				return searchValue.includes(row.cropYear); // new Date().getFullYear() - searchValue;
			});

			//Mets à jour les années de campgane sélectionnées
			this.setState({
				cropYearsSelected: searchValue
			});

			this.props.setCropYearsSelectedDForValidatedFertilizer(searchValue);
		}

		this.setState({ currentRowsOfTable: filteredRows });
	}

	//Vérification que les parcelles selectionnées n'ont pas de dernier apports associés
	onCheckFertilizersWithLastNitrogenInput = (fertiliersIds, event, fertilizers) => {
		const { lastNitrogenInputDico } = this.props;

		const fertilizerHasLastNitrogenInput = FertilizerHelper.hasLastNitrogenInput(fertiliersIds, fertilizers, lastNitrogenInputDico);
		
			//Si c'est le cas alors avertissement client dans la pop-up dialog de suppression de fumure
            if (fertilizerHasLastNitrogenInput.matchFound) {
                this.setState({
                    openDeleteLastInputNInfo: true, // Affichage de la popup
                    parcelNameWithLastNInput: fertilizerHasLastNitrogenInput.parcelName // Nom de la parcelle avec dernier apport
                });
            }

		//Dans tout les cas continue le processus de suppression
		this.onDeleteFertilizers(event, fertilizers)

	}

	/* ↓↓↓ Suppression des fumures ↓↓↓ */
	/* Demande de suppression des fumures sélectionnées (en paramètre) */
	onDeleteFertilizers(event, fertilizers) {

		this.setState({
			openConfirmDeleteFertilizersDialog: true,
			fertilizersToDelete: fertilizers
		});
	}

	/* Désaffiche la boite de dialog pour la suppression des fumures */
	handleCloseConfirmDialogForDeleting = () => {
		this.setState({
			openConfirmDeleteFertilizersDialog: false,
			fertilizersToDelete: []
		});
	}

	/* Fonction de suppression des fumures */
	onConfirmDeleteFertilizers = () => {
		const { fertilizersToDelete } = this.state;
		const { deleteFertilizers, deleteLastNitrogenInput } = this.props;

		let parcelsIdsToDelete = [];
        fertilizersToDelete.forEach(item => {
            parcelsIdsToDelete.push(item.idParcel);
        });		

		//Suppression des derniers apports associés à ce conseil
		deleteLastNitrogenInput(parcelsIdsToDelete)

		//Suppression des fumures
		deleteFertilizers(fertilizersToDelete);
		
		this.setState({
			openConfirmDeleteFertilizersDialog: false,
			fertilizersToDelete: []
		});
	}
	/* ↑↑↑ Suppression des fumures ↑↑↑ */

	/* Lance la procedure de téléchargement de l'ensemble des fichiers constituant les fumures
		Si le client a sélectionné des fumures comportant des types d'apport différents, 
		l'étape 1 sera la confirmation qu'il est d'accord. L'étape 2 sera la confirmation du téléchargement */
	onDownLoadFertilizers(event, fertiResultSelected) { //Attention ! Il ne s'agit pas des entités de fumure, mais de l'entité spécifique pour l'affichage de ce tableau !!!
		const { downloadingPrescriptions, authorizeModulation } = this.props;
		//On vérifie : 
		// * aucun téléchargement est déjà en cours... 
		// * et données ok !

		if ((downloadingPrescriptions === true) || (fertiResultSelected === undefined) || (!Array.isArray(fertiResultSelected))) {
			return;
		}

		//On vérifie si on est dans l'appli mobile. Si oui, on interdit le téléchargement mais en indiquant comment faire (utiliser l'appli web sur PC).
		if (IsNativeHoster() === true) {
			this.setState({ showUseComputerForDownload: true, });
			return;
		}
		//else //sinon, on autorise le téléchargement !

		//Il faut créer chaque paramètrage (ID de prescription) associé à chaque ligne sélectionnée (celles reçues ici):
		let idsFertiSelected = [];
		let canExportModulationToDownload = true; //Si les fumures archivées sont affichées, c'est qu'il ne faut pas afficher la partie de télécharement de rds, isoxml
		fertiResultSelected.forEach(fertilizer => {
			if (fertilizer && (fertilizer.id > 0)) {
				idsFertiSelected.push(fertilizer.id);
				if (fertilizer.isArchived === true) {
					canExportModulationToDownload = false;
				}
			}
		});

		// Si pas de droit de modulation, on affiche pas le choix de format des cartes de modulation
		// On restraint le choix aux fichiers PDF
		let showIncitationToUpgradeModulation = (authorizeModulation !== true);
		if (showIncitationToUpgradeModulation)
			canExportModulationToDownload = false;

		// On vérifie si toutes les prescriptions vise le même type d'apport (liquide / solide) ; Sans quoi, on avertira l'utilisateur:
		const withWarning = ((fertiResultSelected.filter(fertilizer => fertilizer.supplyType === false).length > 0) &&
			(fertiResultSelected.filter(fertilizer => fertilizer.supplyType === true).length > 0)) ? true : false;

		this.setState({
			openConfirmDownloadFertilizerDialog: true,
			fertilizersToDownload: idsFertiSelected,
			showFirstStepForDownloadingFertilizers: withWarning,
			showExportModulationToDownload: canExportModulationToDownload,
			showIncitationToUpgradeModulation: showIncitationToUpgradeModulation,
		});
	}

	/* Désaffiche la boite de dialog pour le téléchargement des fumures */
	handleCloseConfirmDialogForDownloading = () => {
		this.setState({ openConfirmDownloadFertilizerDialog: false });
	}

	/* Fonction de confirmation de téléchargement de fumures */
	onConfirmDownloadFertilizers = () => {
		const { fertilizersToDownload, typeDownloadChoice } = this.state;
		const { downloadListPrescriptions } = this.props;

		let formatToExport = 1; // == TypeDownloadChoice.shp;
		switch (typeDownloadChoice) {
			case TypeDownloadChoice.isoXml: {
				formatToExport = 2;
				break;
			}

			case TypeDownloadChoice.rds: {
				formatToExport = 3;
				break;
			}

			case TypeDownloadChoice.map: {
				formatToExport = 4;
				break;
			}

			case TypeDownloadChoice.ppf: {
				formatToExport = 5;
				break;
			}

			default: { // == TypeDownloadChoice.shp
				formatToExport = 1;
				break;
			}
		};

		//Téléchargement des prescriptions (IsoXml, Rds, Shp, PPF, Map)
		//Le téléchargement de IsoXml, Rds, Shp est dispo quand que des fumures sont sélectionnées.
		if (!lodashIsEmpty(fertilizersToDownload) && (fertilizersToDownload.length > 0)) {
			downloadListPrescriptions(fertilizersToDownload, formatToExport);
		}

		this.setState({
			openConfirmDownloadFertilizerDialog: false,
			fertilizersToDownload: [],
		});
	}

	handleCloseErrorDialog = () => {
		this.setState({
			showUseComputerForDownload: false,
		});

		//Supprime l'erreur du state Redux !
		this.props.cleanErrorFertilizer();
	}

	//Sélection des fumures
	//newSelectionFertilizers: id fertilizer
	setSelectionFertilizers = (newSelectionFertilizers) => {

		const { fertilizerDico, parcelDico, parcelArchivedForFertilizerDico } = this.props;

		let totalAverageToExpand = 0;
		let selectedSupplyType = undefined;
		let bothSupplyType = false;
		let isSelectedFertilizersArchived = false;

		newSelectionFertilizers.forEach(fertilizerId => {
			let averageToExpand = 0;
			
			//Recherche de la fumure dans le dico de fumures
			let fertilizer = lodashGet(fertilizerDico, `[${fertilizerId}]`, undefined);
			if ((fertilizer !== undefined) && (fertilizer !== null)) {
				let parcelOfFertilizer = lodashGet((fertilizer.isArchived === true) ? parcelArchivedForFertilizerDico : parcelDico, `[${fertilizer.idParcel}]`, undefined);

				if ((parcelOfFertilizer !== undefined) && (parcelOfFertilizer !== null)) {
					let zones = lodashGet(fertilizer, 'zones', []);
					let rateUnitToKgL = 100 / lodashGet(fertilizer, 'content', 100);

					let parcelAreaFund = lodashGet(parcelOfFertilizer, `area`, 0);

					if (zones && (parcelAreaFund > 0)) {
						zones.forEach(zoneItem => {
							averageToExpand += (zoneItem.ratio * parcelAreaFund * (
								(numberHelper.testDataValid(zoneItem.actualDose) && (zoneItem.actualDose > 0)) ? zoneItem.actualDose : 0));
						});

						averageToExpand = averageToExpand / parcelAreaFund;
					}
					else {
						averageToExpand = lodashGet(fertilizer, 'nitrogenPrescription', 0);
					}

					totalAverageToExpand = totalAverageToExpand + averageToExpand * rateUnitToKgL * parcelAreaFund;

					if (selectedSupplyType === undefined) { //pas encore de type d'intrant définis...
						selectedSupplyType = fertilizer.supplyType;
					} else if (selectedSupplyType !== fertilizer.supplyType) {
						bothSupplyType = true; //les deux types d'intrant sont sélectionnés !
					}
					//else //ok, le type actuel est le même que pour l'item de modulation !
				}
				//else //ok, le type actuel est le même que pour l'item de la fumure !
			}
			//else //pas normal. ne devrait pas arriver !
		});

		this.setState({
			fertilizersSelected: newSelectionFertilizers,
			bothSupplyType: bothSupplyType,
			countTotalDoseToExpand: numberHelper.fixeDecimal(totalAverageToExpand),
			totalDoseSupplyType: selectedSupplyType,
			typeDownloadChoice: (isSelectedFertilizersArchived === true) ? TypeDownloadChoice.map : TypeDownloadChoice.shp
		});
	}

	/* Lance l'affichage des détails de cette fumure */
	onShowFertilizerRowClick(fertilizer) {
		const { fertilizerDico, clearFertilizerValue, goToShowThisFertilizer } = this.props;

		if (!fertilizer) return;

		try {
			let fertilizerSelected = undefined;

			if (fertilizerDico) {
				fertilizerSelected = fertilizerDico[fertilizer.id];
			}
			
			if (!fertilizerSelected) return;

			fertilizerSelected.thumbnailInfos = fertilizer.thumbnailInfos;

			clearFertilizerValue(); //si une fumure a déjà été sélectionnée auparavant, vider les valeurs de la fumure :

			//Ouverture de la popup pour afficher le résultat de la fumure sélectionnée
			this.setState({
				openFertilizerResultDialog: true,
				fertilizerToShow: fertilizerSelected
			});

			goToShowThisFertilizer(fertilizerSelected); //Rq: Force le mode "consultation" !
		}
		catch (err) { }
	}

	/* Fermeture de la popup quiaffiche le résultat de la fumure sélectionnée */
	closeFertilizerResultDialog = () => {
		this.setState({
			openFertilizerResultDialog: false,
			fertilizerToShow: undefined
		});
	}

	//Titre des colonnes de la table des fumures
	getColumnsTable() {
		const { generatingFile, idFertilizerGenerating } = this.props;

		const columns = [
			{ headerName: 'id', field: 'id', hideable: false },
			{
				field: 'parcelName',
				minWidth: 150,
				hideable: false,
				renderHeader: (params) => {
					return (<Typography fontWeight='bold'>{StringTranslate.ColumnPclName}</Typography>)
				},
				renderCell: (params) => {
					return (<Typography className="select-fertilizer" onClick={() => this.onShowFertilizerRowClick(params.row)}>{params.value}</Typography>)
				}
			},
			{
				field: 'crop',
				hideable: false,
				renderHeader: (params) => {
					return (<Typography fontWeight='bold'>{StringTranslate.libelecolumnculture}</Typography>)
				},
				renderCell: (params) => {
					return (<Typography className="select-fertilizer" onClick={() => this.onShowFertilizerRowClick(params.row)}>{params.value}</Typography>)
				}
			},
			{
				field: 'cropYear',
				minWidth: 150,
				hideable: false,
				renderHeader: (params) => {
					return (<Typography fontWeight='bold'>{StringTranslate.libelecolumncampagne}</Typography>)
				},
				renderCell: (params) => {
					return (<Typography className="select-fertilizer" onClick={() => this.onShowFertilizerRowClick(params.row)}>{params.value}</Typography>)
				}
			},
			{
				field: 'isArchived',
				hideable: false,
				renderHeader: (params) => {
					return (<Typography fontWeight='bold'>{StringTranslate.statut}</Typography>)
				},
				renderCell: (params) => {
					return (
						<Chip 
						label={ (params.row.isArchived === true) ? StringTranslate.historized : StringTranslate.actual } 
						size="small" 
						color={ (params.row.isArchived === true) ? "primary" : "success" }
						onClick={() => this.onShowFertilizerRowClick(params.row)} 
						className="select-fertilizer"/>
					)
				}
			},
			{
				field: 'totalNDose',
				minWidth: 150,
				hideable: false,
				renderHeader: (params) => {
					return (<Typography fontWeight='bold'>{StringTranslate.totalNdose} (U/Ha)</Typography>)
				},
				renderCell: (params) => {
					return (<Typography className="select-fertilizer" onClick={() => this.onShowFertilizerRowClick(params.row)}>{params.value}</Typography>)
				}
			},
			{
				field: 'NModulatedDose',
				minWidth: 170,
				hideable: false,
				renderHeader: (params) => {
					return (<Typography fontWeight='bold'>{StringTranslate.modulatedNdose} (U/Ha)</Typography>)
				},
				renderCell: (params) => {
					return (<Typography className="select-fertilizer" onClick={() => this.onShowFertilizerRowClick(params.row)}>{params.value}</Typography>)
				}
			},
			{
				field: 'organicInputQuantity',
				minWidth: 180,
				hideable: false,
				renderHeader: (params) => {
					return (<Typography fontWeight='bold'>{StringTranslate.quantityToSpread}</Typography>)
				},
				renderCell: (params) => {
					return (<Typography className="select-fertilizer" onClick={() => this.onShowFertilizerRowClick(params.row)}>{params.value} {(params.row.supplyType === false) ? "Kg" : "L"}</Typography>)
				}
			},
			{
				field: 'nitrogenContent',
				minWidth: 160,
				hideable: false,
				renderHeader: (params) => {
					return (<Typography fontWeight='bold'>{StringTranslate.nitrogenContentShort} (%)</Typography>)
				},
				renderCell: (params) => {
					return (<Typography className="select-fertilizer" onClick={() => this.onShowFertilizerRowClick(params.row)}>{params.value}</Typography>)
				}
			},
			{
				headerName: StringTranslate.ppf,
				field: 'pdf',
				minWidth: 60,
				hideable: false,
				renderHeader: (params) => {
					return (<Typography fontWeight='bold' >{StringTranslate.ppf}</Typography>)
				},
				renderCell: (params) => {
					return (
						<>
							{((generatingFile === true) && (idFertilizerGenerating === params.value)) ?
								<CircularProgress size={20} color="primary" /> :
								<IconButton color="primary" disabled={(params.row.azurePdfURL === undefined)}
									onClick={(event) => this.onGenerateFertilizerFileClick(event, params.row)}>
									<IconPdf />
								</IconButton>}
						</>
					)
				}
			},
			{
				headerName: "Modulation",
				field: 'pdf2',
				minWidth: 160,
				hideable: false,
				renderHeader: (params) => {
					return (<Typography fontWeight='bold' >{StringTranslate.modulation}</Typography>)
				},
				renderCell: (params) => {
					return (
						<>
							{((generatingFile === true) && (idFertilizerGenerating === params.value)) ?
								<CircularProgress size={20} color="primary" /> :
								<IconButton color="primary" disabled={(params.row.azurePdfURL === undefined)}
									onClick={(event) => this.generateModulationFileOfThisFertilizer(event, params.row)}>
									<IconPdf />
								</IconButton>}
						</>
					)
				}
			}
		];

		return columns;
	}

	//Génération du pdf :
	onGenerateFertilizerFileClick(event, fertilizer) {
		if (event) { //pour ne pas sélectionner la ligne !
			event.preventDefault();
			event.stopPropagation(); // Really this time.
		}
		
		const { downloadFertilizerFile } = this.props;

		this.setState({ idFertilizerGenerating: fertilizer.id, });

		try {
			downloadFertilizerFile(fertilizer.id);
		}
		catch (err) { }
	}

	generateModulationFileOfThisFertilizer(event, fertilizer) {
		if (event) { //pour ne pas sélectionner la ligne !
			event.preventDefault();
			event.stopPropagation(); // Really this time.
		}

		this.setState({ idFertilizerGenerating: fertilizer.id, });

		const { generateModulationFileOfThisFertilizer } = this.props;
	
		generateModulationFileOfThisFertilizer(fertilizer);

	}

	//Données des fumures actuelles et antérieures pour remplir le tableau
	loadContentTable() {
		const { fertilizerDico, fertilizerDicoCounter, parcelDico, parcelArchivedForFertilizerDico, thumbnailParcelDico, thumbnailParcelArchivedForFertilizerDico } = this.props;
		const { cropYearsSelected } = this.state;

		let cropExistingYears = [];
		let rowsOfTable = [];

		if (fertilizerDico && (fertilizerDicoCounter > 0)) {
			for (const key in fertilizerDico) {
				const fertilizerItem = fertilizerDico[key];

				if (fertilizerItem) {
					// RQ: Si la parcelle n'existe pas ou plus, les fumures en liaison à cette parcelle ne doivent
					// pas s'afficher!
					const parcelOfFertilizer = lodashGet((fertilizerItem.isArchived === true) ? parcelArchivedForFertilizerDico : parcelDico, `[${fertilizerItem.idParcel}]`, undefined);
					if (parcelOfFertilizer) {
						const thumbnailParcelItem = ParcelsHelper.selectParcelFromDicoById((fertilizerItem.isArchived === true) ? thumbnailParcelArchivedForFertilizerDico : thumbnailParcelDico, parcelOfFertilizer.id);

						//RQ: Même si certaines données ne sont pas affichées, elles sont exploitées dans du fonctionnel 
						//On rajoute la condition sur l'année de campagne car on veut voir afficher que les conseils azotés validés
						if ((fertilizerItem.isComplet === true) && (fertilizerItem.zonesCounter > 0) && (fertilizerItem.nitrogenTotal > 0)  && (fertilizerItem.cropYear > 0)) {

							let averageToExpand = 0;

							let zones = lodashGet(fertilizerItem, 'zones', []);
							let rateUnitToKgL = 100 / lodashGet(fertilizerItem, 'content', 100);

							let parcelAreaFund = lodashGet(parcelOfFertilizer, `area`, 0);

							if (zones && (parcelAreaFund > 0)) {
								zones.forEach(zoneItem => {
									averageToExpand += (zoneItem.ratio * parcelAreaFund * (
										(numberHelper.testDataValid(zoneItem.actualDose) && (zoneItem.actualDose > 0)) ? zoneItem.actualDose : 0));
								});

								averageToExpand = numberHelper.fixeDecimal(averageToExpand / parcelAreaFund);
							} else {
								averageToExpand = lodashGet(fertilizerItem, 'nitrogenPrescription', 0);
							}

							let cropYearFertilizer = lodashGet(fertilizerItem, 'cropYear', undefined);

							//Concernant l'id dans le tableau des fumures actuelles et archivées, on se basera sur l'idParcel pour différencier
							//les lignes.
							//L'idParcel est toujours unique contrairement à l'idFertilizer ! 
							rowsOfTable.push({
								id: fertilizerItem.id,
								idParcel: fertilizerItem.idParcel,
								clientId: fertilizerItem.idClient,
								crop: lodashGet(fertilizerItem, 'crop.label', ""),
								cropYear: cropYearFertilizer,
								parcelName: parcelOfFertilizer.name,
								totalNDose: lodashGet(fertilizerItem, 'nitrogenTotal', ""), //propriété calculée de la dose à distribuer dans la modulation de fumure....
								NModulatedDose: lodashGet(fertilizerItem, 'nitrogenPrescription', ""), //dose liée aux résultats du calcul du moteur de fumure
								thumbnailInfos: thumbnailParcelItem,
								organicInputQuantity: numberHelper.fixeDecimal(averageToExpand * rateUnitToKgL * parcelAreaFund),
								nitrogenContent: lodashGet(fertilizerItem, 'content', ""),
								supplyType: lodashGet(fertilizerItem, 'supplyType', false),
								azurePdfURL: lodashGet(fertilizerItem, 'azurePdfURL', undefined),
								isArchived: fertilizerItem.isArchived, //Pour la différenciation entre les fumures actuelles et archivées
							});

							//Récupération des années de campagnes des fumures actuelles et archivées
							if ((!cropExistingYears.includes(cropYearFertilizer)) && (cropYearFertilizer !== undefined)) cropExistingYears.push(cropYearFertilizer);
						}
					}
				}
			}
		}

		//Tri des lignes par l'id des parcelles
		const rowsOfTableOrdered = FertilizerHelper.sortByParcel(rowsOfTable);
		
		//Return de 2 valeurs : les années de campagnes existantes dans les fumures et les fumures (actuelles et archivées)
		this.setState({
			rowsOfTable: rowsOfTableOrdered, //Toutes les fumures (actuelles et archivées)
			cropYears: cropExistingYears, //Les années de campagne présentes dans les fumures (actuelles et archivées)
			currentRowsOfTable: rowsOfTable.filter(row => cropYearsSelected.includes(row.cropYear)) //Les fumures à afficher par défaut en fonction de l'année de campagne à afficher par défaut
		});
	}

	//demande d'afficher l'étape 2 pour confirmaer le téléchargement des fumures shp, isoxml
	onGoToNextStepToDownload = () => {
		this.setState({ showFirstStepForDownloadingFertilizers: false });
	}

	/* Fonction appelée lors du choix du type de téléchargement : SHP ou ISOXML */
	handleChangeTypeDownloadChoice = (event) => {
		this.setState({ typeDownloadChoice: event.target.value });
	}

	/* Fonction appelée lors du choix du type de PDF de téléchargement : Carte modulation ou PPF */
	handleChangeTypePDFDownloadChoice = (event) => {
		this.setState({ typeDownloadPDFChoice: event.target.value });
	}

	/* Ferme le dialog des messages d'erreur */
	closeDialogOfErrors = () => {
		this.setState({
			openDialogOfErrors: false,
		});

		if (this.props.cleanErrorFertilizer) {
			this.props.cleanErrorFertilizer();
		}
	}

	render() {
		const { fertilizersSelected, openConfirmDeleteFertilizersDialog, showUseComputerForDownload,
			searchFilter, currentRowsOfTable, openFertilizerResultDialog, fertilizerToShow,
			openConfirmDownloadFertilizerDialog, showFirstStepForDownloadingFertilizers, typeDownloadChoice,
			openDialogOfErrors, bothSupplyType, countTotalDoseToExpand, totalDoseSupplyType, showExportModulationToDownload,
			cropYearsSelected, cropYears , rowsOfTable, openDeleteLastInputNInfo, parcelNameWithLastNInput, showIncitationToUpgradeModulation } = this.state;

		const { updateNbRowsPerPageTableFertilizers, showProfilMenuDialog, loading, pageSize,
			downloadingPrescriptions, fertilizerDicoIsLoaded, parcelsArchivedForFertilizerLoaded,
		} = this.props;

		return (<>
			{/* ↓↓ Partie visuel - affichage Pop d'une erreur ↓↓ */}
			{(openDialogOfErrors === true) && (
				<AlertDialog popup={this.popupErrorDialog} handleChangePopup={this.closeDialogOfErrors} />
			)}

			{/* ↓↓ Partie visuel - affichage Popup pour le téléchargement des fumures ↓↓ */}
			<Dialog open={openConfirmDownloadFertilizerDialog}
				onClose={this.handleCloseConfirmDialogForDownloading}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">{StringTranslate.ColumnMdlToDownLoad}</DialogTitle>

				{(showFirstStepForDownloadingFertilizers === true) ? (<>
					<DialogContent>
						<Button
							color="secondary" variant="text" size="small"
							onClick={() => showProfilMenuDialog(ProfilIndex.aide_Fumure)}
							sx={{ mb: 2 }}
						>
							{StringTranslate.helpAsk}
						</Button>
						{/* Etape 1 : Attention, 2 types d'apports différents, on affiche l'étape suivante qui est le choix entre l'IsoXml et le shp */}
						<DialogContentText>{StringTranslate.twoSupplyTypesSelected}</DialogContentText>
					</DialogContent>

					<DialogActions>
						<Button onClick={this.handleCloseConfirmDialogForDownloading} variant="text" color="error" autoFocus>{StringTranslate.annuler}</Button>
						<Button onClick={this.onGoToNextStepToDownload} variant="contained" color="primary">
							{StringTranslate.continueStep1}
						</Button>
					</DialogActions>
				</>) : (<>
					<DialogContent>
						{/* Etape 2 : Choix entre l'IsoXml et le shp */}
						<Grid container spacing={2}>
							
								{/* Affichage du choix de type de carte de modulation voulu */}
								{showExportModulationToDownload ?
									<>
										<Grid item xs={12}>
											<Stack direction="column">
												<FormControl component="fieldset">
													<FormLabel component="legend">{StringTranslate.dialogSubtitleTypeShape11}</FormLabel>
													<RadioGroup aria-label="downloadChoice" name="downloadChoice" value={typeDownloadChoice} onChange={this.handleChangeTypeDownloadChoice}>
														<FormControlLabel value={TypeDownloadChoice.shp} control={<Radio color="primary" />} label={StringTranslate.downloadChoiceShp} />
														<FormControlLabel value={TypeDownloadChoice.isoXml} control={<Radio color="primary" />} label={StringTranslate.downloadChoiceIsoXml} />
														<FormControlLabel value={TypeDownloadChoice.rds} control={<Radio color="primary" />} label={StringTranslate.downloadChoiceRds} />
													</RadioGroup>
												</FormControl>
											</Stack>
										</Grid>
										<Grid item xs={12}>
											<Button
												color="secondary" variant="text" size="small"
												onClick={() => showProfilMenuDialog(ProfilIndex.aide_ModulationFormatFichier)}
												sx={{ mb: 2 }}
											>
												{StringTranslate.helpAskModulation}
											</Button>
										</Grid>
									</>
									:
									showIncitationToUpgradeModulation ?
									// Si pas le droit de modulation, on l'incite à nous contacter
									<Grid item>
										<Typography>{StringTranslate.incitationDownloadFertiPrescriptions}</Typography>
										<Typography variant="subtitle1">
											<LinkToContactUs displayText={StringTranslate.contactUs} />{StringTranslate.popupIncitationFertilizer}
										</Typography>
									</Grid>
									// Si pas possible de télécharger les cartes de modulation (ex: conseils archivés)
									: <></>
								}

							{/* Affichage du choix du pdf voulue */}
							<Grid item xs={12}>
								<FormControl component="fieldset">
									<FormLabel component="legend">{StringTranslate.dialogSubtitleTypeShape12}</FormLabel>
									<RadioGroup aria-label="downloadPDFChoice" name="downloadPDFChoice" value={typeDownloadChoice} onChange={this.handleChangeTypeDownloadChoice}>
										<FormControlLabel value={TypeDownloadChoice.map} control={<Radio color="primary" />} label={"Carte"} />
										<FormControlLabel value={TypeDownloadChoice.ppf} control={<Radio color="primary" />} label={"PPF"} />
									</RadioGroup>
								</FormControl>
							</Grid>
							
						</Grid>
					</DialogContent>

					<DialogActions>
						<Button onClick={this.handleCloseConfirmDialogForDownloading} variant="text" color="error" autoFocus>
							{StringTranslate.cancelDeleteAction}
						</Button>
						<Button onClick={this.onConfirmDownloadFertilizers} variant="contained" color="primary">
							{StringTranslate.confirmDeleteAction}
						</Button>
					</DialogActions>
				</>)}
			</Dialog>

			{/* ↓↓ Partie visuel - affichage Popup pour la lecture des données de fumure ↓↓ */}
			{(openFertilizerResultDialog) && (
				<Dialog
					sx={{ '& .MuiDialog-paper': { maxHeight: { xs: "100%", md: "90%", lg: "90%" } } }}
					fullWidth={true}
					maxWidth={false}
					fullScreen={this.fullScreen}
					open={openFertilizerResultDialog}
					onClose={this.closeFertilizerResultDialog}
				>
					<DialogTitle
						sx={{
							m: 0,
							p: 2,
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center'
						}}
					>
						<ThumbnailParcelShapeFromPathInfos id={`listItemTbl_${fertilizerToShow.idParcel}`} {...fertilizerToShow.thumbnailInfos} />
						<Typography sx={{ fontSize: '1.25rem' }}>
							{fertilizerToShow.thumbnailInfos.parcelName}
						</Typography>

						<IconButton
							aria-label="close"
							onClick={this.closeFertilizerResultDialog}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
					</DialogTitle>

					<DialogContent dividers sx={{ paddingTop: "20px !important" }}>
						<NitrogenResult />
					</DialogContent>

					<DialogActions>
						<Button onClick={this.closeFertilizerResultDialog} variant="text" color="primary">{StringTranslate.close}</Button>
					</DialogActions>
				</Dialog>
			)}

			{/* ↓↓ Partie visuel - affichage Popup pour la suppression des fumures ↓↓ */}
			<Dialog open={openConfirmDeleteFertilizersDialog}
				onClose={this.handleCloseConfirmDialogForDeleting}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{StringTranslate.askDeleteFertilizers}</DialogTitle>
				{openDeleteLastInputNInfo && // s'affichant si une cohésion existe entre un conseil et un ajustement azoté.
					<DialogContent>
						<DialogContentText>{StringTranslate.deleteFertilizersWithLastInputN}</DialogContentText>
						<DialogContentText id="alert-dialog-parcels-name"> {/*Liste affichant la (les) parcelle(s) concernée(s) par une cohésion*/}
							<List dense>
								{parcelNameWithLastNInput.map((parcelName, index) => (
									<ListItem key={index}>
										<ListItemText primary={parcelName} />
									</ListItem>
								))}
							</List>
						</DialogContentText>
					</DialogContent>
				}
				<DialogActions>
					<Button onClick={this.handleCloseConfirmDialogForDeleting} variant="text" color="error" autoFocus>
						{StringTranslate.cancelDeleteAction}
					</Button>
					<Button onClick={this.onConfirmDeleteFertilizers} variant="contained" color="primary">
						{StringTranslate.confirmDeleteAction}
					</Button>
				</DialogActions>
			</Dialog>

			{/* Dialog d'incitation à utiliser le PC et l'appli web pour le téléchargement de modulation(s) */}
			{(showUseComputerForDownload === true) && (
				<AlertDialog popup={this.popupUseComputerForDownloadDialog} handleChangePopup={this.handleCloseErrorDialog} />
			)}

			{(loading === true) ||
				(fertilizerDicoIsLoaded === false) || (parcelsArchivedForFertilizerLoaded === false) ?
				(<DataGridSkeleton />) : (
					<TableContainer>
						<Grid style={{ width: '100%' }} item xs={12}>
							{/* partie tableau */}
							<CustomDataGrid
								disableSelectionOnClick={true}
								tableName={TableType.fertilizers}
								pageSize={pageSize}
								updateNbRowsPerPageTable={updateNbRowsPerPageTableFertilizers}
								onSelectionModelChange={(newSelectionFertilizers) => { //Sélection des parcelles par clic checkbox
									this.setSelectionFertilizers(newSelectionFertilizers);
								}}
								keepNonExistentRowsSelected={searchFilter !== "" ? true : false}
								Toolbar={this.CustomToolbar}
								toolbar={{
									downloadingPrescriptions: downloadingPrescriptions,
									fertilizersSelected: fertilizersSelected,
									rowsOfTable: rowsOfTable,
									bothSupplyType: bothSupplyType,
									countTotalDoseToExpand: countTotalDoseToExpand,
									totalDoseSupplyType: totalDoseSupplyType,
									currentCropYear: this.currentCropYear, //année de campagne en cours
									cropYears: cropYears, //Années de campagne présentes dans les fumures actuelles et archivées
									cropYearsSelected: cropYearsSelected, //Années de campagne sélectionnées dans la zone de filtre

									showProfilMenuDialog: () => showProfilMenuDialog(ProfilIndex.aide_Fumure),
									deleteFertilizers: ( evt, data) => this.onCheckFertilizersWithLastNitrogenInput(fertilizersSelected, evt, data),
									generateFertilizerFile: (evt, data) => this.onGenerateFertilizerFileClick(data.id),
									downLoadFertilizers: (evt, data) => this.onDownLoadFertilizers(evt, data),
									handleChangeCropYear: (event) => this.requestSearch(event.target.value, true, rowsOfTable),

									//Concerne la zone de recherche dans le tableau :
									value: searchFilter,
									onChange: (event) => this.requestSearch(event.target.value, false, rowsOfTable),
									clearSearch: () => this.requestSearch('', rowsOfTable),
								}}
								rows={(searchFilter !== "") ? currentRowsOfTable : ((cropYearsSelected.length >= 0) ? currentRowsOfTable : rowsOfTable)}
								columns={this.getColumnsTable()}
								checkBoxActive={true}
								//isRowSelectable={(params) => this.isRowSelectableByCulture(params.row.culture)}
							/>
						</Grid>
					</TableContainer>
				)}
		</>);
	}
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({

    clientId: lodashGet(state, 'clientUserData.clientDatas.id', 0),

	//Données concernant le fertilizerData :
	fertilizerDico: lodashGet(state, 'fertilizerData.fertilizerDico', {}), //dico dont la clef est l'ID de parcelle associée - valeur l'entité 'Fertilizerdata'. => Uniquement les pesée de la fumure complète en BdD.
	fertilizerDicoCounter: lodashGet(state, 'fertilizerData.fertilizerDicoCounter', 0),
	fertilizerDicoIsLoaded: lodashGet(state, 'fertilizerData.fertilizerDicoIsLoaded', false),
	parcelsArchivedForFertilizerLoaded: lodashGet(state, 'parcelsData.parcelsArchivedForFertilizerLoaded', false),
	parcelArchivedForFertilizerDicoCounter: lodashGet(state, 'parcelsData.parcelArchivedForFertilizerDicoCounter', 0),
	downloadingPrescriptions: lodashGet(state, 'fertilizerData.downloadingPrescriptions', false),
	errorMessage: lodashGet(state, 'fertilizerData.errorMessage', undefined),
	loading: lodashGet(state, 'fertilizerData.loading', false),
	cropYearsSelectedForFilterFertilizer: lodashGet(state, 'fertilizerData.cropYearsSelectedForFilterFertilizer', []),
	closingDateAzofert: lodashGet(state, 'fertilizerData.closingDateAzofert', ConstantsFertilizer.DefaultClosingDateAzofert),
    openingDateAzofert: lodashGet(state, 'fertilizerData.openingDateAzofert', ConstantsFertilizer.DefaultOpeningDateAzofert),

	//Données concernant le parcelsData :
	parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
	parcelDicoCounter: lodashGet(state, 'parcelsData.parcelDicoCounter', 0),
	parcelArchivedForFertilizerDico: lodashGet(state, 'parcelsData.parcelArchivedForFertilizerDico', {}),
	thumbnailParcelDico: lodashGet(state, 'parcelsData.thumbnailParcelDico', {}),
	thumbnailParcelArchivedForFertilizerDico: lodashGet(state, 'parcelsData.thumbnailParcelArchivedForFertilizerDico', {}),

	//Données concernant le clientData :
	authorizeModulation: lodashGet(state, 'clientUserData.clientDatas.authorizeModulation', false),

	//Données concernant le settingsData :
    pageSize: lodashGet(state, 'settingsData.settings.rowsPerPageForTableParcels', 20),
	language: lodashGet(state, 'settingsData.settings.language', StringTranslate.getLanguage()),

	//Dictionnaire des dereniers apports
	lastNitrogenInputDico: lodashGet(state, 'lastNitrogenInputData.lastNitrogenInputDico', {}),

});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
	deleteFertilizers: (fertilizersToDelete) => dispatch(ActionDeleteFertilizers(fertilizersToDelete)),
	getAllFertilizers: (clientId) => dispatch(ActionGetAllFertilizers(clientId)),
	setCropYearsSelectedDForValidatedFertilizer: (cropYearsSelected) => dispatch(ActionSetCropYearsSelectedDForValidatedFertilizer(cropYearsSelected)),
	
	//Méthode pour supprimer les derniers apports
	deleteLastNitrogenInput: (fertilizersToDelete) => {dispatch(ActionDeleteLastNitrogenInput(fertilizersToDelete))},

 	clearFertilizerValue: () => dispatch(ActionClearFertilizerValue()),
	goToShowThisFertilizer: (fertilizerParameter) => dispatch(ActionGoToShowThisFertilizer(fertilizerParameter)),
	generateModulationFileOfThisFertilizer: (fertilizer) => dispatch(ActionGenerateFileOfThisFertilizer(fertilizer)),
	downloadFertilizerFile: (fertilizerId) => dispatch(ActionBuildFertilizerFile(fertilizerId)),
	downloadListPrescriptions: (fertilizersToDownload, formatToExport) => dispatch(ActionBuildListPrescriptions(fertilizersToDownload, formatToExport)),
	showProfilMenuDialog: (index) => dispatch(ActionShowProfilMenuDialog(index)),
	cleanErrorFertilizer: () => dispatch(ActionCleanErrorFertilizer()),
})

export default connect(mapStateToProps, mapDispatchToProps)(FertilizersList);
