import * as React from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

/* MUI Components */
import { Box} from '@mui/material';

/* React Components */
import LastNitrogenInputList from './lastNitrogenInputList.jsx';
import LastNitrogenInputResult from './lastNitrogenInputResult.jsx';

/* Redux */
import { LastNitrogenInputStep } from '../../redux/actions/lastNitrogenInput';


function LastNitrogenInputStepsManagement(props) {
    
    return (
            <Box sx={{ width: '100%' }}>

                <Box sx={{mt: 3}}>
                    {(props.lastNitrogenInputStep === LastNitrogenInputStep.CHOIX_LAST_NITROGEN_INPUTS) && <LastNitrogenInputList />}
                    {(props.lastNitrogenInputStep === LastNitrogenInputStep.RESULT) && <LastNitrogenInputResult />}
                </Box>

        </Box>
    );
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    lastNitrogenInputStep: lodashGet(state, 'lastNitrogenInputData.lastNitrogenInputStep', LastNitrogenInputStep.CHOIX_LAST_NITROGEN_INPUTS),
    showLastNitrogenInputMap: lodashGet(state, 'lastNitrogenInputData.showLastNitrogenInputMap', false),
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    //RAS
})

export default connect(mapStateToProps, mapDispatchToProps)(LastNitrogenInputStepsManagement);
