import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


function IconIdentification(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <g>
        <rect x="11.54" y="4.95" width="0.41" height="4.12" transform="translate(-1.93 6.89) rotate(-30.32)" />
        <polygon points="12.18 9.69 9.73 6.98 10.04 6.7 12.48 9.42 12.18 9.69" />
        <polygon points="13.14 8.22 12 4.35 12.39 4.24 13.53 8.11 13.14 8.22" />
        <polygon points="13.87 8.33 13.39 4.11 13.79 4.06 14.27 8.28 13.87 8.33" />
        <polygon points="15.29 8.42 14.88 8.4 15.06 4.36 15.47 4.38 15.29 8.42" />
        <rect x="14.31" y="6.76" width="4.14" height="0.41" transform="translate(4.74 20.42) rotate(-72.16)" />
        <polygon points="15.62 9.97 15.31 9.71 18.32 6.11 18.63 6.38 15.62 9.97" />
        <path d="M10.55,12.38a4.32,4.32,0,0,0,5,4.36c2.65-.45,4.39-2.44,4.18-4.5S17.24,6.8,14.42,7.29,10.58,10.05,10.55,12.38Z" />
        <g>
          <path d="M10.74,20.82a18.4,18.4,0,0,0-5.55-3.65,1.36,1.36,0,0,1,.33,1.56c4,.1,3.14,3.59,3.14,3.59,2.2-.17,4.25,1.55,5.48,2.86A31.47,31.47,0,0,0,10.74,20.82Z" />
          <path d="M11,20.54a32,32,0,0,1,3.76,4.91,4.65,4.65,0,0,1,.06-3.69c-3.38-.24-2.72-3.07-2.72-3.07-3.73.67-3.55-2.51-3.55-2.51a2.32,2.32,0,0,1-2.46,1A17.74,17.74,0,0,1,11,20.54Z" />
        </g>
        <g>
          <path d="M27.85,16.91a24.38,24.38,0,0,0-5.59,2.68A17.81,17.81,0,0,0,18.07,24a7.15,7.15,0,0,1,2.16-1.55,8.55,8.55,0,0,1,4.08-.77S23.12,18,27.52,18.38A1.66,1.66,0,0,1,27.85,16.91Z" />
          <path d="M17.85,23.6A17.71,17.71,0,0,1,22,19.26a24.69,24.69,0,0,1,5.37-2.63,2.76,2.76,0,0,1-2.57-1.35A2.56,2.56,0,0,1,21.1,17.2s-.24,3.27-3.38,2.71A8.46,8.46,0,0,1,17.85,23.6Z" />
        </g>
        <path d="M16.36,28.24l-1.16-.41s1.39-4.08.22-11.23l1.21-.2C17.86,23.89,16.42,28.07,16.36,28.24Z" />
      </g>
    </SvgIcon>
  );
}

export default IconIdentification;



