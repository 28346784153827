import StringTranslate from '../assets/i18n/stringLanguage.jsx';

const dicoCultures = () => {

    return [
        {title: `${StringTranslate.libelleBetterave}`},
        {title: `${StringTranslate.libelleBleDur}`},
        {title: `${StringTranslate.libelleBleTendre}`},
        {title: `${StringTranslate.libelleColza}`},
        {title: `${StringTranslate.libelleLin}`},
        {title: `${StringTranslate.libelleLuzerne}`},
        {title: `${StringTranslate.libelleMaisEnsilage}`},
        {title: `${StringTranslate.libelleMaisGrain}`},
        {title: `${StringTranslate.libelleOrgeDePrintemps}`},
        {title: `${StringTranslate.libelleOrgeDHiver}`},
        {title: `${StringTranslate.libellePoisFourrager}`},
        {title: `${StringTranslate.libellePoisProteagineux}`},
        {title: `${StringTranslate.libellePrairiePermanente}`},
        {title: `${StringTranslate.libellePommeDeTerre}`},
        {title: `${StringTranslate.libelleSoja}`},
        {title: `${StringTranslate.libelleTournesol}`},
    ];
};


export default dicoCultures;