import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Modulations from "../../components/modulation/modulations";
import ChoixParcelsModulation from "../../components/modulation/choixParcelsModulation";
import DosesModulation from "../../components/modulation/dosesModulation";
import MapModulation from "../../components/modulation/mapModulation";
import CongratulationsModulation from "../../components/modulation/congratulationsModulation";
import MainCard from "../../components/subLayouts/mainCard.jsx";

import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import { IsNativeHoster } from '../../utils/platformHelper';

import '../../assets/css/modulation.css';

import CalculateIcon from '@mui/icons-material/Calculate';
import IconeModulation from '../../assets/images/modulation/iconeModulation.tsx';
import { Add } from '@mui/icons-material';

import { Tab, Tabs, Box } from '@mui/material';
import DataGridSkeleton from "../../components/dataGridSkeleton";

import {
    ActionGetAllModulations,
    ModulationStep,
     } from '../../redux/actions/modulations';
import CalculetteDoseAzote from "../../components/modulation/calculetteDoseAzote";
import { TabPanel, buildPropsForTab } from '../../components/tabPanel';

import { ConstantsContent } from '../../utils/constantsContent.js';

import getTheme from "../../themes/index.js";


let theme = getTheme();

///////////////////////////////////////////////////////////////////////////
// Composant général concernant la modulation de parcelles (gestion et affichage).
///////////////////////////////////////////////////////////////////////////
class AbstractModulation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modulationStep: this.props.modulationStep,
            valueTab: 0
        };
    }

    handleChangeTab = (event, newValue) => {
        this.setState({
            valueTab: newValue
        });
    }

    componentDidMount() {
        if (this.props.getAllModulations)
            this.props.getAllModulations(this.props.clientId);
    }

    getContentOfStep(modulationStep) {

        const { isOpenMenuBurgerDesktop } =this.props;

        //Quand le popup des félicitations est affiché, l'utilisateur est redirigé vers le choix des des parcelles pour la modulation 
        //La liste des modulations est affichée que quand on ferme le popup. Donc forcément, on se retrouve au niveau
        //du choix des parcelles pour la modulation. 
        if (window) {
            window.scrollTo(0, 0); //Permet de se positionner sur la liste des modulations enregistrées.
        }

        if (modulationStep === ModulationStep.DOSES) {
            return (

                <DosesModulation {...this.props} />
            );
        }
        else if (modulationStep === ModulationStep.MAP) {
            return (
                <Box
                    className="contentAbstractMap"
                    sx={{
                        [theme.breakpoints.up('md')]: { 
                            left: isOpenMenuBurgerDesktop ? ConstantsContent.DrawerWidth : ConstantsContent.MarginCard,
                            width: isOpenMenuBurgerDesktop ? `calc(100% - ${ConstantsContent.DrawerWidth + ConstantsContent.MarginCard}px)` : `calc(100% - ${ConstantsContent.MarginCard * 2}px)`
                        }
                    }}>
                    <MapModulation {...this.props} />
                </Box>
            );
        } else /*if (modulationStep === ModulationStep.CHOIX_PARCELS)*/ { //par défaut !
            return <ChoixParcelsModulation {...this.props} />;
        }
    }

    render() {
        const { modulationStep, loadWaiting } = this.props;
        const { valueTab } = this.state;

        const nativeAppHoster = IsNativeHoster();

        return (
            <>
                {((modulationStep === ModulationStep.CHOIX_PARCELS) || (modulationStep === ModulationStep.CONGRATULATIONS)) ? (
                    <>
                        <CongratulationsModulation {...this.props} />
                        <MainCard>
                            <Tabs
                                value={valueTab}
                                variant="scrollable"
                                onChange={this.handleChangeTab}
                                variant_pz="row"
                            >
                                {/* menu des onglets: ('buildPropsForTab' génère les ids entre les items de menu et leur contenu respectif) */}
                                <Tab
                                    component={Link}
                                    to="#"
                                    icon={<Add />}
                                    label={(nativeAppHoster === true) ? `${StringTranslate.ListparcelsModulationMobile}` : `${StringTranslate.ListparcelsModulation}`}
                                    {...buildPropsForTab('menuModul', 0)} />
                                <Tab
                                    component={Link}
                                    to="#"
                                    icon={<IconeModulation />}
                                    label={(nativeAppHoster === true) ? `${StringTranslate.ModulationsExistsTitleMobile}` : `${StringTranslate.ModulationsExistsTitle}`}
                                    {...buildPropsForTab('menuModul', 1)} />
                                <Tab
                                    component={Link}
                                    to="#"
                                    label={(nativeAppHoster === true) ? `${StringTranslate.CalculDoseNitrogenTitleMobile}` : `${StringTranslate.CalculDoseNitrogenTitle}`}
                                    icon={<CalculateIcon />}
                                    {...buildPropsForTab('menuModul', 2)} />
                            </Tabs>

                            {/* contenu des onglets: */}
                            <TabPanel value={valueTab} index={0} prefixId='menuModul'>
                                <ChoixParcelsModulation {...this.props} />
                            </TabPanel>
                            <TabPanel value={valueTab} index={1} prefixId='menuModul'>
                                {(loadWaiting === true) ?
                                    <DataGridSkeleton/>
                                    : <Modulations {...this.props} />}
                            </TabPanel>
                            <TabPanel value={valueTab} index={2} prefixId='menuModul'>
                                <CalculetteDoseAzote {...this.props} />
                            </TabPanel>

                        </MainCard>
                    </>) : (this.getContentOfStep(modulationStep))}
            </>
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    //Infos provenant du reducer 'modulations':
    modulationStep: state.modulationsData.modulationStep,
    loadWaiting: (state && state.modulationsData) ? state.modulationsData.loading : false, //@@RQ: Il en faudrait une lors des appel aux différents 'Build...' !

    //Infos provenant du reducer 'clientUser':
    clientId: (state && state.clientUserData && state.clientUserData.clientDatas) ? state.clientUserData.clientDatas.id : -1,
    isOpenMenuBurgerDesktop: (state && state.contextAppData) ? state.contextAppData.isOpenMenuBurgerDesktop : false,
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    /* Modulation */
    getAllModulations: (clientId) => dispatch(ActionGetAllModulations(clientId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AbstractModulation);