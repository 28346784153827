import { connect } from 'react-redux';

/* Redux */
//import { ProfilIndex, ActionShowProfilMenuDialog } from '../../redux/actions/contextApp';

/* MUI Components */
import { Grid } from '@mui/material';

import ConstantsTutorialHelps from '../../utils/constantsTutorialHelps.js';
import CustomIFrame from '../customIFrame.jsx';

///////////////////////////////////////////////////
// Composant d'affichage du texte pour s'abonner //
///////////////////////////////////////////////////
function FertilizerAuthorization(props) {

  	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<CustomIFrame className="iframes_profil" url={ConstantsTutorialHelps.UrlContactFormForFertilizer}/>
			</Grid>
		</Grid>
	);
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
	//RAS !
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
	//RAS !
});

export default connect(mapStateToProps, mapDispatchToProps)(FertilizerAuthorization);
