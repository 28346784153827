//extensions de fichier pris en compte lors de l'import de parcellaire
const extensionFile = {
    shp: 'shp',
    prj: 'prj',
    dbf: 'dbf',
    shx: 'shx',
    cpg: 'cpg',
};

//types de fichier reçu lors de l'import parcellaire
export const typeFile = {
    zip: 'zip',
    fichiers: 'fichiers',
};

export default extensionFile;