import { connect } from 'react-redux';

/* Redux */
import { ProfilIndex, ActionShowProfilMenuDialog } from '../redux/actions/contextApp';

/* MUI Components */
import { Link } from '@mui/material';


///////////////////////////////////////////////////
// Composant d'affichage du texte pour s'abonner //
///////////////////////////////////////////////////
function LinkToContactUs(props) {
	const handleGotoProfil = () => {
		const { showProfilMenuDialog } = props;

		if (window.dataLayer) {// google tag manager
			window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'contacteNousProfilPage' });
		}

		/* ↓ demande le changementde page, au profit de l'écran des abonnements */
		if (showProfilMenuDialog) {
			//Rq: On ne va pas sur la page d'abonnement (via 'ProfilIndex.abonnements') car les plans Stripe ne seront pas activés pour le moment !
			showProfilMenuDialog(ProfilIndex.contacterLeServiceClient);
		}
	};

    return (
        <Link style={{ cursor: "default", '&:hover': { cursor: "pointer" } }} href={undefined} onClick={() => handleGotoProfil()}>{props.displayText}</Link>
    );
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
	//RAS !
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
	showProfilMenuDialog: (index) => dispatch( ActionShowProfilMenuDialog(index) ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkToContactUs);
