import { Component } from 'react';
import { connect } from 'react-redux';
import { get as lodashGet, isNil as lodashIsNil } from 'lodash';

import { Box, Grid, Typography, Button, TableContainer, Divider, TextField, InputAdornment, IconButton } from '@mui/material';
import { Edit as EditIcon, Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material';


import { ThumbnailParcelShapeFromPathInfos } from '../thumbnail/ThumbnailParcelShape';
import ProcessStatusChip from '../customComponents/ProcessStatusChip';
import CustomDataGrid from '../customDataGrid';
import UpdateParcelInfo from '../commonStructures/updateParcelInfo';

import { ActionSelectParcelForModelisation, ActionGoToStepOfModelisation, ProcessStatus, ModelisationSteps } from '../../redux/actions/modelisation';
import { ActionOpenParcelEdit, ProfilIndex, ActionShowProfilMenuDialog } from '../../redux/actions/contextApp';
import { TableType, UpdateNbRowsPerPageTable } from '../../redux/actions/settings';

import DateHelper from '../../utils/dateHelper';
import stringHelper from '../../utils/stringHelper';
import { ParcelsHelper } from '../../utils/parcelsHelper';
import ModelisationHelper from "../../utils/modelisationHelper";

import StringTranslate from '../../assets/i18n/stringLanguage';

class ModelisationParcelChoice extends Component {

    constructor(props) {
        super(props);

        const { cultureSelected, parcelIdFilteredList } = props;

        let tableGridDatas = this.initialiseTableGridDatas(parcelIdFilteredList);
        let parcelIdsSelected = [];
        // Permet de présélectionner les parcelles correspondant à la culture choisie à l'étape précédante
        let { parcelsList, output_parcelsSelected} = ParcelsHelper.sortDataByCrop(tableGridDatas, cultureSelected, parcelIdFilteredList, parcelIdsSelected);

        this.state = {            
            parcelIdsSelected : output_parcelsSelected,
            searchFilter: '', //Zone de recherche du tableau
            currentRowsOfTable: [], //Datas en fonction du texte tapé dans la zone de recherche

            tableGridDatas : parcelsList,
        };

        this.columns = this.initialiseColumns(parcelIdFilteredList); //RQ: pas dans le 'state' car on en utilise des propriétés !
        this.CustomToolbar = this.CustomToolbar.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { language, parcelIdFilteredList } = this.props;

        // Si la langue de l'appli à changer, on met à jour les colonnes
        if (language !== nextProps.language) {
            this.columns = this.initialiseColumns(parcelIdFilteredList);
        }

        return true;
    }
    
    componentDidUpdate(prevProps, prevState) {
        //@@Réagir aux modifications sur les parcelles : Modification d'une ou plusieurs données d'une parcelle !
        const { parcelIdFilteredList, updatingParcelName, updatingParcelInfos, 
            modelisationDico, loadingModelisations, cultureSelected } = this.props;
        const { parcelIdsSelected } = this.state;

        /* Lorsqu'il y a une modification des données d'une parcelle (RQ: n'a pas être réalisé qu'ici!), il faut actualiser la liste des 'tableGridDatas': */
        /* Lorsque les modélisations sont chargées, on actualise la liste des 'tableGridDatas': */
        /* RQ: c'est plus rapide de recharger toutes les parcelles que de rechercher quelle parcelle a été modifié et quelle(s) info(s) de cette parcelle! */
        if ((prevProps.parcelIdFilteredList !== parcelIdFilteredList) || 
            ((prevProps.updatingParcelName === true) && (updatingParcelName === false)) ||
            ((prevProps.updatingParcelInfos === true) && (updatingParcelInfos === false)) ||
            ((prevProps.modelisationDico !== modelisationDico) && (loadingModelisations === false))) { //RQ: lorsque l'on passe de 'true' à 'false' c'est que le traitement est finis (même si pourrai être KO)
            const newDatas = this.initialiseTableGridDatas(parcelIdFilteredList);

            //Trie les données du tableau en fonction de la culture choisie
            const {parcelsList, output_parcelsSelected} = ParcelsHelper.sortDataByCrop(newDatas, cultureSelected, parcelIdFilteredList, parcelIdsSelected);
            
            this.setState({
                tableGridDatas: parcelsList,
                parcelIdsSelected: output_parcelsSelected,
            });
        }
    }

    /**
     * Initialisation des colonnes de parcelles.
     * Il y a une traduction sur les listes fixes de cultures, exploitations et variétés
     */
    initialiseColumns(parcelIdFilteredList) {
        const { openParcelEdit } = this.props;

        let newColumns = [];

        // Si pas de donnée à afficher, pas la peine de charger les colonnes
        if (lodashIsNil(parcelIdFilteredList)) {
            return newColumns;
        }

        // Ajout de la colonne d'édition
        newColumns.push({
            field: 'actions',
            editable: false,
            hideable: false,
            headerName: '',
            width: 60,
            renderCell: params => {
                return (
                    <IconButton onClick={() => openParcelEdit(params.value, params.row.thumbnailInfos)}>
                        <EditIcon color="inherit" />
                    </IconButton>
                )
            }
        });

        // TODO gérer les colonnes via un DataGridHelper permettant de factoriser la définition des colonnes
        // => elles sont toujours affiché de la même façon dans les différents tableaux
        /* ↓ ajout colonne visible - (Miniature + nom de la parcelle) ↓ */
        newColumns.push({
            headerName: `${StringTranslate.nomcolumn}`,
            field: "nom",
            minWidth: 180,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.nomcolumn}</Typography>)
            },
            renderCell: params => {
                return (
                    <Box style={{ display: "flex", flexDirection: "row", alignItems: "center"  }} >
                        {(params) ? (<ThumbnailParcelShapeFromPathInfos id={`listItemTbl_${params.row.thumbnailInfos.parcelId}`} {...params.row.thumbnailInfos} />) : (undefined)}
                        <Typography>{params.value}</Typography>
                    </Box>
                )
            }
        });

        // Colonne modelisationStatus
        newColumns.push({
            headerName: "modelisationStatus",
            field: "modelisationStatus",
            width: 180,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.modelisationStatusTitle}</Typography>)
            },
            renderCell: (params) => {
                return (<ProcessStatusChip processStatus={params.value} />);
            },
        });

        // Colonne campagne
        newColumns.push({
            headerName: "campagne",
            field: "campagne",
            width: 100,
            type: "date",
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.libelecolumncampagne}</Typography>)
            },
        });

        // Colonne culture
        newColumns.push({
            headerName: "culture",
            field: "culture",
            minWidth: 150,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.libelecolumnculture}</Typography>)
            }
        });

        // Colonne culture précédente
        newColumns.push({
            headerName: "culturePrecedente",
            field: "culturePrecedente",
            minWidth: 150,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.libelecolumncultureprece}</Typography>)
            }
        });

        // Colonne date Semis
        newColumns.push({
            headerName: "dateSemis",
            field: "dateSemi",
            minWidth: 110,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.libelecolumnsemis}</Typography>)
            },
        });

        // Colonne exploitation
        newColumns.push({
            headerName: "exploitation",
            field: "exploitation",
            minWidth: 150,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.libelecolumnexploitation}</Typography>)
            }
        });
        
        // Colonne Surface
        newColumns.push({
            headerName: "surface",
            field: "surface",
            width: 100,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.libelecolumnsurface}</Typography>)
            },
        });

        // Colonne variete
        newColumns.push({
            headerName: "variete",
            field: "variete",
            minWidth: 150,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.libelecolumnvariete}</Typography>)
            },
        });

        return newColumns;
    }

    /* fonction permettant d'extraire les données qui seront utilisées dans le visuel */
    initialiseTableGridDatas(parcelIdFilteredList) {
        const { parcelDico, thumbnailParcelDico, modelisationDico } = this.props;
        let parcels = ParcelsHelper.getParcelsDicoFromParcelIdList(parcelDico, parcelIdFilteredList);
        let newDatas = [];

        for (let itemPropName in parcels) {
            const parcel = parcels[itemPropName];

            if (lodashIsNil(parcel)) continue;


            //On désactive juste les parcelles en cours de modélisation ou déjà modélisées.
            let modelisation = ModelisationHelper.getModelisationByParcelId(modelisationDico, parcel.id);
            
            let modelisationStatus = lodashGet(modelisation, "modelisationStatus", ProcessStatus.Unknown);
            let isDisabled = (modelisationStatus !== ProcessStatus.Unknown);

            let sowingDateStr = lodashGet(parcel, "details.dateSemi", null);
            let sowingDate = null;

            if (sowingDateStr) {
                if (sowingDateStr instanceof (Date)) {
                    sowingDate = DateHelper.formati18n(sowingDateStr, 'P');
                }
                else if (DateHelper.getDateFromString(sowingDateStr) instanceof Date) {
                    sowingDate = DateHelper.formati18n(new Date(sowingDateStr), 'P');
                }
            }

            let parcelId = lodashGet(parcel, "id", 0);
            
            newDatas.push({
                id: parcelId,

                actions: parcel,
                parcel: parcel,

                nom: lodashGet(parcel, "name", null),
                thumbnailInfos: ParcelsHelper.selectParcelFromDicoById(thumbnailParcelDico, parcelId),


                campagne: lodashGet(parcel, "details.campagne", null),
                culture: lodashGet(parcel, "details.culture", null),
                culturePrecedente: lodashGet(parcel, "details.culturePrecedente", null),

                dateSemi: sowingDate,

                variete: lodashGet(parcel, "details.variete", null),
                exploitation: lodashGet(parcel, "details.exploitation", null),
                surface: lodashGet(parcel, "details.surface", null),

                isDisabled: isDisabled,
                modelisationStatus: modelisationStatus,
            });

        }
        return newDatas;
    }

    /* Fonction correspondant à la zone de recherche (recherche une correspondance dans toutes les colonnes) */
    requestSearch(searchValue, rowsOfTable) {
        this.setState({
            searchFilter: searchValue
        });

        try {
            const searchRegex = new RegExp(stringHelper.escapeRegExp(searchValue), 'i');
            const filteredRows = rowsOfTable.filter((row) => {
                return Object.keys(row).some((field) => {
                    let textValue = "" + row[field];
                    return searchRegex.test(textValue.toString());
                });
            });

            this.setState({
                currentRowsOfTable: filteredRows
            });
        }
        catch(errRegex) { /* Peut arriver si on saisis un truc du genre 'EARL++' ! (il n'aime pas les '++') */ }
    }
    // Fonction permettant d'effacer le filtre
    clearSearch = (tableGridDatas) => this.requestSearch('', tableGridDatas)
    
    //Maj des parcelles sélectionnées
    setSelectionParcels = (newSelectionParcels) => {
        this.setState({
            parcelIdsSelected: newSelectionParcels
        });
    }

    /**
     * Méthode permettant de passée à l'étape suivante en validant les parcelles sélectionnées
     */
    nextStage() {
        const { parcelIdsSelected } = this.state;
        const { parcelDico, selectParcels, clientId, cultureSelected } = this.props;

        let listModelisationParcelsInfo = parcelIdsSelected.map( (parcelId) =>
            ({
                clientId: clientId, 
                parcelId: parcelId, 
                sowingDate: lodashGet(parcelDico, `[${parcelId}]details.dateSemi`, null), 
                cropModel: lodashGet(cultureSelected, 'cropType', "")
            })
        );

        selectParcels(parcelIdsSelected, listModelisationParcelsInfo);
    }

    //Toolbar customisé du tableau comprenant :
    // - Btn d'aide
    // - la zone de recherche
    CustomToolbar = (props) => {
        const { tableGridDatas, searchFilter, showProfilMenuDialog, } = props;

        return (
            <Grid container spacing={2} sx={{ mt: 1 }}>
                {/* Bouton d'aide */}
                <Grid item xs={12}>
                    <Button color="secondary" variant="text" size="small" onClick={() => showProfilMenuDialog(ProfilIndex.aide_Modelisation)}>
                        {StringTranslate.helpAsk}
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                {/* Zone de recherche / zone du bouton de validation*/}
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Grid container spacing={1}>
                        {/* Zone de recherche */}
                        <Grid item xs={8} sm={8} md={4} lg={4}>
                            <TextField
                                value={searchFilter}
                                onChange={(event) => this.requestSearch(event.target.value, tableGridDatas)}
                                placeholder={StringTranslate.toolbarsearch}
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>),
                                    endAdornment: (
                                        <IconButton
                                            title="Clear"
                                            aria-label="Clear"
                                            size="small"
                                            style={{ visibility: searchFilter ? 'visible' : 'hidden' }}
                                            onClick={() => this.clearSearch(tableGridDatas)}
                                        >
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    render() {
        const { parcelIdsSelected, tableGridDatas, currentRowsOfTable, searchFilter } = this.state;

        const { cultureSelected, goBack, updateNbRowsPerPageTable, rowsPerPage, openUpdateParcel, showProfilMenuDialog } = this.props;

        let disableNextStep = (parcelIdsSelected.length <= 0);
        
        return (
            <Grid container sx={{ mt: 2 }}>

                {/* Dialog de modification des infos de la parcelle */}
                {
                    openUpdateParcel &&
                        <UpdateParcelInfo/> 
                }

                <Typography fontWeight="bold" m={2}>{StringTranslate.modelisationCrop} {lodashGet(cultureSelected, 'name', "")}</Typography>

                <Grid item xs={12}>

                    <TableContainer>
                        <Grid style={{ width: '100%' }} item xs={12}>
                            {/* partie tableau */}
                            <CustomDataGrid
                                // Type de table
                                tableName={TableType.modelisations}

                                // Gestion de la pagination
                                pageSize={rowsPerPage}
                                updateNbRowsPerPageTable={(rowsPerPage) => updateNbRowsPerPageTable(rowsPerPage, TableType.modelisations)}

                                // Gestion de la Toolbar
                                Toolbar={this.CustomToolbar} // Render
                                toolbar= {{ // props
                                    tableGridDatas: tableGridDatas,
                                    searchFilter: searchFilter,
                                    showProfilMenuDialog: showProfilMenuDialog,
                                }}

                                // lignes et colonnes à afficher
                                columns={this.columns}
                                rows={(searchFilter !== "") ? currentRowsOfTable : tableGridDatas}

                                // Sélection des parcelles
                                checkBoxActive={true}
                                selectionModel={parcelIdsSelected}
                                onSelectionModelChange={(newSelectionParcels) => { //Sélection des parcelles par clic checkbox
                                    this.setSelectionParcels(newSelectionParcels);
                                }}
                                isRowSelectable={(params) => params.row.isDisabled === false} //On désactive les parcelles qui ont une modélisation en cours / terminée
                                disableSelectionOnClick={true} // On oblige à clicker sur la checkBox pour la sélection (sinon, si on clique sur la ligne ça sélectionne)
                                keepNonExistentRowsSelected={searchFilter !== "" ? true : false}

                                getRowClassName={(params) => params.row.isDisabled === true ? 'disabled' : null} //Style 'disabled' quand la parcelle est désactivée pour la modélisation
                            /> 
                        </Grid>
                    </TableContainer>
                </Grid>

                
                <Grid container marginTop={3}>
                    <Grid item xs={6}>
                        <Button variant="text" onClick={goBack}>
                            {StringTranslate.revenir}
                        </Button>
                    </Grid>
                    <Grid item xs={6} justifyContent="flex-end" align="right">
                        <Button type="submit" variant="contained"
                            onClick={() => this.nextStage()}
                            disabled={disableNextStep}
                        >
                            {StringTranslate.validSlots}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
    parcelIdFilteredList: lodashGet(state, 'parcelsData.parcelIdFilteredList', []),
    updatingParcelName: lodashGet(state, 'parcelsData.updatingParcelName', false),
    updatingParcelInfos: lodashGet(state, 'parcelsData.updatingParcel', false),
    thumbnailParcelDico: lodashGet(state, 'parcelsData.thumbnailParcelDico', {}),

    cultureSelected: lodashGet(state, 'modelisationData.cultureSelected', undefined),
    modelisationDico: lodashGet(state, 'modelisationData.modelisationDico', {}),
    loadingModelisations: lodashGet(state, 'modelisationData.loadingModelisations', {}),

    clientId: lodashGet(state,'clientUserData.userDatas.idClient', null),

    rowsPerPage: lodashGet(state, 'settingsData.settings.rowsPerPageForTableModelisations', 20),
    language: lodashGet(state, 'settingsData.settings.language', StringTranslate.getLanguage()),
    
    openUpdateParcel: lodashGet(state, 'contextAppData.openDialogParcelEdit', false),   
});

const mapDispatchToProps = dispatch => ({
    openParcelEdit: (parcel, thumbnailInfos) => dispatch(ActionOpenParcelEdit(parcel, thumbnailInfos)),

    updateNbRowsPerPageTable: (rowsPerPage) => dispatch(UpdateNbRowsPerPageTable(rowsPerPage, TableType.modelisations)),

    selectParcels: (listOfParcels, listModelisationParcelsInfo) => dispatch(ActionSelectParcelForModelisation(listOfParcels, listModelisationParcelsInfo)),
    goBack: () => dispatch(ActionGoToStepOfModelisation(ModelisationSteps.CROP_CHOICE)),

    
    showProfilMenuDialog: (index) => dispatch(ActionShowProfilMenuDialog(index)),
});

 
export default connect(mapStateToProps,mapDispatchToProps)(ModelisationParcelChoice);