import React from "react";

import { Typography, List, ListItem, ListItemAvatar, ListItemText, Avatar } from "@mui/material";
import ReceiptIcon from '@mui/icons-material/Receipt';

import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

import getTheme from "../../themes/index.js";
let theme = getTheme();

class ADVLink extends React.Component {
    render() {
        return (
            <List sx={{ py: 0 }}>
                <ListItem alignItems="center" disableGutters
                    sx={{
                        py: 0,
                        "&:hover": {
                            backgroundColor: "transparent"
                        }
                    }}>
                    <ListItemAvatar>
                        <Avatar
                            variant="rounded"
                            component="a"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.largeAvatar,
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark
                            }}
                            target="_blank"
                            href='https://mesfactures.isagri.fr/'
                        >
                            <ReceiptIcon fontSize="inherit" />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        sx={{
                            py: 0,
                            mt: 0.45,
                            mb: 0.45
                        }}
                        primary={
                            <Typography
                                component="a"
                                variant="h4"
                                target="_blank"
                                href='https://mesfactures.isagri.fr/'>
                                {StringTranslate.viewYourBillsTitle}
                            </Typography>
                        }
                    />
                </ListItem>
            </List>
        )
    }
}

export default ADVLink;