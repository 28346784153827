import dateHelper from '../utils/dateHelper';
import isSameDay from 'date-fns/isSameDay';
import isValid from 'date-fns/isValid';


export function checkDateAccuracy(date, element) {
    const elementDate = ((element !== undefined) ? ((element.date instanceof Date) ? element.date : dateHelper.getDateFromString(element.date)): undefined);
    const validity = isValid(elementDate);
    const sameDates = isSameDay(elementDate, date);
    return (validity && sameDates);
};

export default checkDateAccuracy;