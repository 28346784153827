import React from "react";
import { connect } from 'react-redux';

import lodashGet from 'lodash/get';
import lodashIsNil from 'lodash/isNil';
import lodashIsNumber from 'lodash/isNumber';
import lodashIsNaN from 'lodash/isNaN';
import lodashCloneDeep from 'lodash/cloneDeep';

//MUI Components
import { Stack, Button, CardActions, Grid, Typography, ButtonGroup, TextField, styled, CardContent, Card, FormControl,
    Table, TableHead, TableRow, TableCell, TableBody, InputLabel, Tooltip, TableContainer, TableFooter, InputAdornment, Alert, 
    LinearProgress, CircularProgress, Slider} from "@mui/material";

//Redux
import { ActionGoToStepOfLastNitrogenInput, ActionClearLastNitrogenInputError,
    ActionBuildLastNitrogenSampleAndDoses, ActionSaveLastNitrogenInputModulation, ActionGoToSelectImage, ActionCreateLastInputNParameter, ActionGenerateFileOfThisLastNitrogen } from "../../redux/actions/lastNitrogenInput";

//Composant React
import SubCard from "../subLayouts/subCard";
import AlertDialog from "../alertDialog";

/* Lien de contact */
import LinkToContactUs from '../linkToContactUs';

//Helper
import numberHelper from "../../utils/numberHelper";
import dateHelper from "../../utils/dateHelper";
import { SupplyType } from '../../utils/lastNitrogenInputHelper';
import { DEFAULT_MODULATION_DISPERSION } from '../../utils/modulationsHelper';

//Icônes
import { Help, Add, Remove, ArrowBack, FileDownload, ChevronRight, ErrorOutline, InfoOutlined, Save } from '@mui/icons-material';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';

//Traduction
import StringTranslate from "../../assets/i18n/stringLanguage";

//Thème
import getTheme from "../../themes/index.js";

const AzoteUnit = "U";
const LiquidUnit = "L";
const SolidUnit = "Kg";
const AzoteUnitBySurface = `${AzoteUnit}/Ha`;
const LiquidUnitBySurface = `${LiquidUnit}/Ha`;
const SolidUnitBySurface = `${SolidUnit}/Ha`;

/* Obtient la couleur de la zone de modulation */
const getColor = function (color) {
    let Color = require('color');
    return Color(color).hex();
}

const CustomTextField = styled(TextField)({

    '& label.Mui-focused': {
        color: 'transparent',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'transparent',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'transparent',
        },
        '&:hover fieldset': {
            borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
        },
    },
});

let theme = getTheme();


class LastNitrogenInputResult extends React.Component{
    constructor(props){
        super(props);

        const errorMessage = lodashGet(props, 'errorMessage', undefined);
        const lastNitrogenInputSelected = lodashGet(props, 'lastNitrogenInputSelected', -1);
        const lastNitrogenInput = lodashGet(props, `lastNitrogenInputDico[${lastNitrogenInputSelected}]`, {});

        let readableMode = lodashGet(props, 'readableMode', false);
        let dosePreviouslyInput = lastNitrogenInput.dosePreviouslyInput;

        let supplyType = lodashGet(lastNitrogenInput, 'supplyType', SupplyType.Solid);
        let isSupplyTypeLiquid = (supplyType === SupplyType.Liquid);
        supplyType = (isSupplyTypeLiquid)? SupplyType.Liquid : SupplyType.Solid;

        /**
        * Retourne la répartition de la modulation ou null si c'est une ancienne modulation
        * @note l'utilisation d'une fonction, rend le code plus lisible qu'un ternaire trop grand
        * @returns {number | null}
        */
        const getRepartition = () => {
            //RQ: on multiplie par 100 pour avoir le pourcentage
            if (readableMode) {
                if (lastNitrogenInput && lastNitrogenInput.dispersion) {
                    return lastNitrogenInput.dispersion * 100;
                } else {
                    return null;
                }
            } else {
                if (lastNitrogenInput && lastNitrogenInput.dispersion) {
                    return lastNitrogenInput.dispersion * 100;
                } else {
                    return DEFAULT_MODULATION_DISPERSION;
                }
            }
        }

        this.state = {
			openDialogOfErrors: (errorMessage !== undefined),
            readableMode: readableMode,

            contentError: false,
            repartition: getRepartition(),
            dosePreviouslyInput: dosePreviouslyInput,

            isSupplyTypeLiquid: isSupplyTypeLiquid,
            supplyType: supplyType,
            contentType: (isSupplyTypeLiquid)? LiquidUnit : SolidUnit,
            contentTypeByHa: (isSupplyTypeLiquid)? LiquidUnitBySurface : SolidUnitBySurface,
        };

        // Définition du popup contenant le message d'erreur à diffuser au client:
		this.popupErrorDialog = {
			getTitle: () => { return (<ErrorOutline />); },
			description: errorMessage, //Rq : sera mis à jour en fonction de la prop 'errorMessage' !
			getAdditionalDescription: () => {
				return (
					<Typography variant="subtitle1">
						{StringTranslate.errorDialogText2} <LinkToContactUs displayText={StringTranslate.contactUs} />. 
					</Typography>
				);
			},
			button: StringTranslate.close,
		};

        
		// Définition du popup informant le client que le télécharement du pdf n'est pas encore implémenté dans l'application
		this.popupDownloadPdfDialog = {
			getTitle: () => { 
				return (<>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<InfoOutlined /><Typography sx={{ ml: 1 }} variant="subtitle1">{StringTranslate.downloadModulPdf}</Typography>
					</div>
				</>);
            },
			description: StringTranslate.pdfDownloadNotAvaible,
			button: StringTranslate.close,
		}

		
		this.closeDialogOfErrors = this.closeDialogOfErrors.bind(this);
        this.previousRepartition = this.state.repartition;
        this.onGenerateLastNitrogenFileClick = this.onGenerateLastNitrogenFileClick.bind(this);
    }

    componentDidMount(){
        const { lastInputNParameter, createAndUpdateLastInputNParameter, lastNitrogenInputSelected, lastNitrogenInputDico, buildLastNitrogenSampleAndDoses} = this.props;

        const lastNitrogenInput = lodashGet(lastNitrogenInputDico, `[${lastNitrogenInputSelected}]`, {});
        /*Crée et met à jour les paramètres pour la modulation dans le cas de la modification si l'application a été relancé 
        ces données sont normalement récuperées après la sélection de l'image mais puisqu'on arrive directement sur l'écran de résultat lors de la modification on ne les possède pas*/
        if ((Object.keys(lastInputNParameter).length === 0) || (lastInputNParameter.parcelId !== lastNitrogenInput.parcelId)) { 
            let newLastInputNParameter = {
                clientId: lastNitrogenInput.clientId,
                parcelId: lastNitrogenInput.parcelId,
                imagSourceId: lastNitrogenInput.imagSourceId,
                dateImagSource: lastNitrogenInput.dateImagSource,
                providerImagSource: lastNitrogenInput.providerImagSource,
            }
            createAndUpdateLastInputNParameter(newLastInputNParameter);
        }

        if ((lastNitrogenInput.nitrogenPrescription !== lastNitrogenInput.doseMedium) && (lastNitrogenInput.hasModulation === true)){
            let newLastInputNParameter = {
                clientId: lastNitrogenInput.clientId,
                parcelId: lastNitrogenInput.parcelId,
                imagSourceId: lastNitrogenInput.imagSourceId,
                dateImagSource: lastNitrogenInput.dateImagSource,
                providerImagSource: lastNitrogenInput.providerImagSource,
                zonesCounter: lastNitrogenInput.zonesCounter,
                dosePreviouslyInput: lastNitrogenInput.dosePreviouslyInput,
                content: lastNitrogenInput.content,
                dispersion: lastNitrogenInput.dispersion,
                usingAjustedDose: lastNitrogenInput.usingAjustedDose,
            }
            buildLastNitrogenSampleAndDoses(newLastInputNParameter);
        }

    }

    componentDidUpdate(prevProps) {
        const { buildingSampleAndDose, lastNitrogenInputSelected, lastNitrogenInputDico, errorMessage } = this.props;


        //gestion de cas d'erreur:
		if (((!prevProps) || (!prevProps.errorMessage) || (prevProps.errorMessage === '') || (prevProps.errorMessage === undefined)) &&
		(errorMessage && (errorMessage !== '') && (errorMessage !== undefined))) {
			this.popupErrorDialog.description = errorMessage;

			this.setState({
				openDialogOfErrors: true,
			});
		}

        // Si on a rebuild la modulation, on reprend toutes les données de celle-ci
        if ((prevProps.buildingSampleAndDose !== buildingSampleAndDose) && !buildingSampleAndDose){
            const lastNitrogenInput = lodashGet(lastNitrogenInputDico, `[${lastNitrogenInputSelected}]`, {});

            let supplyType = lodashGet(lastNitrogenInput, 'supplyType', SupplyType.Solid);
            let isSupplyTypeLiquid = (supplyType === SupplyType.Liquid);
            supplyType = (isSupplyTypeLiquid)? SupplyType.Liquid : SupplyType.Solid;

            this.setState({
                repartition: lastNitrogenInput.dispersion * 100,

                isSupplyTypeLiquid: isSupplyTypeLiquid,
                supplyType: supplyType,
                contentType: (isSupplyTypeLiquid)? LiquidUnit : SolidUnit,
                contentTypeByHa: (isSupplyTypeLiquid)? LiquidUnitBySurface : SolidUnitBySurface,

                maxZonesCount: lastNitrogenInput.maxZonesCount,
                dosePreviouslyInput: lastNitrogenInput.dosePreviouslyInput,
            })
        }
    }

    /**
     * Fonction permettant de enregistrer les données de modulation suite à la demande
     */
    onSaveModulationLastInputN = () => {
        const { saveLastNitrogenInputModulation, lastNitrogenInputDico, lastNitrogenInputSelected } = this.props;
        const { supplyType, repartition, } = this.state;
        
        // récupération d'un clone de l'entité du dernier apport
        let lastNitrogenInput = lodashCloneDeep(lodashGet(lastNitrogenInputDico, `[${lastNitrogenInputSelected}]`, {}));
        // Remplace certaines propriétés par celles ayant pu être modifiés par le client
        lastNitrogenInput = {
            ...lastNitrogenInput,
            supplyType: supplyType, // Le client a potentiellement modifié le type d'apport
            dispersion: repartition / 100,
        };

        saveLastNitrogenInputModulation(lastNitrogenInput);
    }

    onCancelModulationLastInputN = (parcelId) => {
        const { goToSelectImage } = this.props;
        goToSelectImage(parcelId);
    }

    onChangeModulationSupply = () => {
        const { isSupplyTypeLiquid } = this.state;

        let newIsSupplyTypeLiquid = !isSupplyTypeLiquid;
        this.setState({
            isSupplyTypeLiquid: newIsSupplyTypeLiquid,
            supplyType: (newIsSupplyTypeLiquid) ? SupplyType.Liquid : SupplyType.Solid,
            contentType: (newIsSupplyTypeLiquid) ? LiquidUnit : SolidUnit,
            contentTypeByHa: (newIsSupplyTypeLiquid) ? LiquidUnitBySurface : SolidUnitBySurface,
        });
    }

    onChangeModulationContent = () => {
        this.setState({
            contentError: false,
        });
    }

    /**
     * si la teneur a changé, il faut :
     * - actualiser les doses (si possible == ancienne et nouvelle valeur ok et différente)
     */
    ApplyChangeAfterContentValided = (content) => {
        const {supplyType, repartition, } = this.state
		const { buildLastNitrogenSampleAndDoses, lastInputNParameter, lastNitrogenInputSelected, lastNitrogenInputDico  } = this.props;

        const lastNitrogenInput = lodashGet(lastNitrogenInputDico, `[${lastNitrogenInputSelected}]`, {});
        // Vérifie la validité de la teneur
        let valueFloat = parseFloat(numberHelper.fixeDecimal(content, 2));
        if (!lodashIsNumber(valueFloat) || lodashIsNaN(valueFloat) || (valueFloat <= 0) || (valueFloat > 100)) {
            this.setState({
                contentError: true,
            });
            return;
        }
        
        //RQ: le nbr de zones ne changera pas !
		let newLastInputNParameter = {
            ...lastInputNParameter,
            zonesCounter: lastNitrogenInput.zonesCounter,
    		dosePreviouslyInput: lastNitrogenInput.dosePreviouslyInput,
            content: valueFloat,
            supplyType: supplyType,
            dispersion: repartition / 100,
            usingAjustedDose: lastNitrogenInput.usingAjustedDose,
		};

		buildLastNitrogenSampleAndDoses(newLastInputNParameter);
    }

    ApplyChangeAfterDoseValided = (dosePreviouslyInput) => {
        const {supplyType , repartition} = this.state
		const { buildLastNitrogenSampleAndDoses, lastInputNParameter, lastNitrogenInputSelected, lastNitrogenInputDico  } = this.props;

        const lastNitrogenInput = lodashGet(lastNitrogenInputDico, `[${lastNitrogenInputSelected}]`, {});
        const nitrogenTotal = (lastNitrogenInput.usingAjustedDose === true)? lastNitrogenInput.newNitrogenTotal : lastNitrogenInput.fertiNitrogenTotal;

        let valueFloat = parseFloat(numberHelper.fixeDecimal(dosePreviouslyInput, 2));
        let isDoseOK = (lodashIsNumber(valueFloat) && !lodashIsNaN(valueFloat) && (valueFloat >= 0))

        if(isDoseOK && (valueFloat <= nitrogenTotal)){
            //RQ: le nbr de zones ne changera pas !
            let newLastInputNParameter = {
                ...lastInputNParameter,
                zonesCounter: lastNitrogenInput.zonesCounter,
                dosePreviouslyInput: valueFloat,
                content: lastNitrogenInput.content,
                supplyType: supplyType,
                dispersion: repartition / 100,
                usingAjustedDose: lastNitrogenInput.usingAjustedDose,
            };

            buildLastNitrogenSampleAndDoses(newLastInputNParameter);

        }
        
        //Enregistre la dose déjà apportée dans le state local même si le calcul n'est pas lancé, pour l'utilisée dans le changement de dose totale
        if (isDoseOK) {
            this.setState({
                dosePreviouslyInput: valueFloat,
            })
        }        
    }

    ApplyChangeAfterTotalDoseChanged = () => {
        const {supplyType , repartition, dosePreviouslyInput, } = this.state
		const { buildLastNitrogenSampleAndDoses, lastInputNParameter, lastNitrogenInputSelected, lastNitrogenInputDico  } = this.props;

        const lastNitrogenInput = lodashGet(lastNitrogenInputDico, `[${lastNitrogenInputSelected}]`, {});

        const newUsingAjustedDose = !lastNitrogenInput.usingAjustedDose; //Alterne entre dose ajustée et dose PPF

        //RQ: le nbr de zones ne changera pas !
        let newLastInputNParameter = {
            ...lastInputNParameter,
            zonesCounter: lastNitrogenInput.zonesCounter,
            dosePreviouslyInput: dosePreviouslyInput,
            content: lastNitrogenInput.content,
            supplyType: supplyType,
            dispersion: repartition / 100,
            usingAjustedDose: newUsingAjustedDose,
        };

        buildLastNitrogenSampleAndDoses(newLastInputNParameter);
    }

	/* Ferme le dialog des messages d'erreur */
	closeDialogOfErrors = () => {
		const { clearLastNitrogenInputError } = this.props;

		this.setState({
			openDialogOfErrors: false,
		});

		clearLastNitrogenInputError();
	}

    /* fonction d'ajout ou de suppression de zones lors des clics sur les boutons +, - */
    addOrRemoveZones = (addOrRemove) => {
        const { buildLastNitrogenSampleAndDoses, lastInputNParameter, lastNitrogenInputSelected, lastNitrogenInputDico } = this.props;
        const { supplyType, repartition, } = this.state;

        const lastNitrogenInput = lodashGet(lastNitrogenInputDico, `[${lastNitrogenInputSelected}]`, {});

        // Vérifie la validité de la teneur
        let valueFloat = parseFloat(numberHelper.fixeDecimal(lastNitrogenInput.content, 2));
        if (!lodashIsNumber(valueFloat) || lodashIsNaN(valueFloat)  || (valueFloat <= 0) || (valueFloat > 100)) return;

        let newZonesCounter = lastNitrogenInput.zonesCounter;
        let zonesCounterInitial = lastNitrogenInput.zonesCounter;

        if (addOrRemove === "remove") {
            if (lastNitrogenInput.zonesCounter >= 2) {
                newZonesCounter = lastNitrogenInput.zonesCounter - 1;
            }
        }
        else if (lastNitrogenInput.zonesCounter < 6) {
            newZonesCounter = lastNitrogenInput.zonesCounter + 1;
        }

        if (newZonesCounter !== zonesCounterInitial) {
            let newLastInputNParameter = {
                ...lastInputNParameter,
                zonesCounter: newZonesCounter,
                content: lastNitrogenInput.content,
                supplyType: supplyType,
                dispersion: repartition / 100,
                dosePreviouslyInput: lastNitrogenInput.dosePreviouslyInput,
                usingAjustedDose: lastNitrogenInput.usingAjustedDose,
            };

            buildLastNitrogenSampleAndDoses(newLastInputNParameter);
        }

    }

    /**
    * Modifie la valeur de repartition pour une coherence visuelle
    * @param {Number} value 
    */
    handleRepartitionChange = (value) => {
        this.setState({ repartition: value });
    }

    /**
    * Permet de changer la répartition de la dose et de rafraichir la modulation seulement après que l'utilisateur ait relaché le bouton de la souris
    * @param {Number} value valeur de la répartition
    */
    handleRepartitionChangeCommitted = (value) => {
        const { supplyType, repartition, } = this.state;
		const { buildLastNitrogenSampleAndDoses, lastInputNParameter, lastNitrogenInputSelected, lastNitrogenInputDico } = this.props;
    
        const lastNitrogenInput = lodashGet(lastNitrogenInputDico, `[${lastNitrogenInputSelected}]`, {});


        if (value === this.previousRepartition) return;

        this.previousRepartition = value;

        //RQ: le nbr de zones ne changera pas !
		let newLastInputNParameter = {
            ...lastInputNParameter,
            zonesCounter: lastNitrogenInput.zonesCounter,
    		dosePreviouslyInput: lastNitrogenInput.dosePreviouslyInput,
            content: lastNitrogenInput.content,
            supplyType: supplyType,
            dispersion: repartition / 100,
            usingAjustedDose: lastNitrogenInput.usingAjustedDose,
		};

		buildLastNitrogenSampleAndDoses(newLastInputNParameter);
    }

    /**
     * Permet la génération d'un pdf avec l'id du client et l'id de la parcel
     */
    onGenerateLastNitrogenFileClick(event, parcelId) {
        if(event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const { lastNitrogenInputDico, generateLastNitrogenInputFile } = this.props;
        
        try {
            const lastInputNSelected = lastNitrogenInputDico[parcelId];
            generateLastNitrogenInputFile(lastInputNSelected);
        }
        catch (err) { }
    }

    render(){
        const { lastNitrogenInputSelected, lastNitrogenInputDico, buildingSampleAndDose, savingSampleAndDose } = this.props;
        const { readableMode, contentType, contentTypeByHa, 
            isSupplyTypeLiquid, contentError, openDialogOfErrors, repartition, dosePreviouslyInput, } = this.state;

        const lastNitrogenInput = lodashGet(lastNitrogenInputDico, `[${lastNitrogenInputSelected}]`, {});

        const zones = lodashCloneDeep(lodashGet(lastNitrogenInput, 'zones', []));

        const disabledBtnRemoveZone = ((buildingSampleAndDose === true) || (savingSampleAndDose === true) || (readableMode === true) || 
                                        (lastNitrogenInput && lastNitrogenInput.zonesCounter && (lastNitrogenInput.zonesCounter <= 1)));
                                        
        const disabledBtnAddZone = ((buildingSampleAndDose === true) || (savingSampleAndDose === true) || (readableMode === true) || 
                                        (lastNitrogenInput && lastNitrogenInput.zonesCounter && 
                                        ((lastNitrogenInput.zonesCounter >= 6) || (lastNitrogenInput.zonesCounter >= lastNitrogenInput.maxZonesCount))));
        
        const usingAjustedDose = lodashGet(lastNitrogenInput, 'usingAjustedDose', false);
        const nitrogenTotal = (usingAjustedDose === true)? lastNitrogenInput.newNitrogenTotal : lastNitrogenInput.fertiNitrogenTotal;

        // Permet de bloquer l'enregistrement ou le téléchargement en cas de dose = 0 pour la modulation
        const disableSaveNewModulation = (((usingAjustedDose === true || usingAjustedDose !== true) && (nitrogenTotal <= dosePreviouslyInput))) ? true : false;
        return(
            <>

                {/* ↓↓ Partie visuel - affichage Pop d'une erreur ↓↓ */}
                {(openDialogOfErrors === true) && (
                    <AlertDialog popup={this.popupErrorDialog} handleChangePopup={this.closeDialogOfErrors} />
                )}

                {((buildingSampleAndDose === true) || (savingSampleAndDose === true)) ? <LinearProgress /> : null}

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                        {/* Map modulation */}
                        <SubCard title={<Typography sx={{fontWeight:"bold"}}>{StringTranslate.subTitleDefinitionZones}</Typography>}>
                            <Grid container rowSpacing={2}>

                                <Grid item xs={12}>
                                    { /* on affiche la date du cliché */}
                                    {!lodashIsNil(lastNitrogenInput.dateImagSource) &&
                                        <Typography variant="h5" sx={{ color: theme.palette.text.primary, pt: 2 }}>
                                            {`${StringTranslate.dateImag} : ${dateHelper.formati18n(new Date(lastNitrogenInput.dateImagSource), 'P')}`}
                                        </Typography>
                                    }   

                                    { /* on affiche le nom de la parcelle */}
                                    <Typography variant="subtitle1" sx={{pt : 2}}>{lastNitrogenInput.parcelName}</Typography>
                                </Grid>
                                {((lastNitrogenInput.maxZonesCount > 0 && lastNitrogenInput.maxZonesCount < 6) && (lastNitrogenInput.zonesCounter === lastNitrogenInput.maxZonesCount)) &&
                                    <Grid item xs={12}>
                                        <Card>
                                            <Alert
                                                severity="warning"
                                                icon={<InfoOutlined />}
                                                sx={{backgroundColor: theme.palette.warning.light}}
                                            >
                                                <Typography>{StringTranslate.badNumberOfAreas}</Typography>
                                            </Alert>
                                        </Card>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Grid item container spacing={1} xs={12} sx={{alignItems:"center"}}>
                                        <Grid item xs={6}>
                                            <Typography variant="body2">{`${StringTranslate.nbZones}`}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ButtonGroup size="large" variant="text" color="inherit" sx={{ border: '1px solid', borderColor: 'grey.400' }}>
                                                <Button
                                                    key="three"
                                                    onClick={() => this.addOrRemoveZones("remove")}
                                                    disabled={disabledBtnRemoveZone}
                                                    sx={{ border: 'none !important', minWidth: '0px !important' }}
                                                >
                                                    <Remove color={(disabledBtnRemoveZone) ? theme.palette.grey[200] : "primary"} fontSize="inherit" />
                                                </Button>
                                                <CustomTextField key="two"
                                                    id="modulation_nb_zones"
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: {
                                                            max: 6, min: 1,
                                                            style: { textAlign: "center" },
                                                        }
                                                    }}
                                                    value={lastNitrogenInput.zonesCounter}
                                                    name="zonesCounter"
                                                />
                                                <Button
                                                    key="one"
                                                    disabled={disabledBtnAddZone}
                                                    onClick={() => this.addOrRemoveZones("add")}
                                                    sx={{ minWidth: '0px !important' }}>
                                                    <Add color={(disabledBtnAddZone)? theme.palette.grey[200] : "primary"} fontSize="inherit" />
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                { /* on affiche la carte */}
                                <Grid item xs={12}>
                                    {/* <img id='imgUrlM' src={lastNitrogenInput.azureImagURL}
                                            alt={StringTranslate.altImgModulation} className="image-doses-modulation" /> */}
                                    {/* On est obligé de se basé sur la date car même si l'image change, l'url reste la même => donc on fait appel au cache 
                                    => Ce n'est plus le cas car on utilise la propriété "no-store" pour permettre l'actualisation correcte de l'image*/}
                                    {(lastNitrogenInput.dataImg) ? (
                                        <img id='imgDataM' src={`data:image/jpeg;base64,${lastNitrogenInput.dataImg}`}
                                            alt={StringTranslate.altImgModulation} className="image-doses-modulation" />
                                    ) : (
                                        <img id='imgUrlM' src={lastNitrogenInput.azureImagURL}
                                            alt={StringTranslate.altImgModulation} className="image-doses-modulation" />
                                    )}
                                </Grid>
                            </Grid>
                        </SubCard>
                    </Grid>
                    
                    {/* Zones de modulation */}
                    <Grid item xs={12} md={6} lg={4}>
                        <SubCard title={<Typography sx={{fontWeight:"bold"}}>{StringTranslate.subTitleDoses}</Typography>}>
                        <Grid container rowSpacing={2}>
                                    { /* liquide ou solide */}
                                    <Grid item xs={12} sx={{display: "flex"}}>
                                        <InputLabel sx={{color: theme.palette.text.primary, width: '100%'}}>{StringTranslate.supplyType}</InputLabel>
                                        <Grid item xs={12}>
                                            {/* supplyType === SupplyType.Liquid a true => StringTranslate.supplyTypeSolid, false => StringTranslate.supplyTypeLiquid */}
                                            <Stack 
                                                direction="row"
                                                sx={{
                                                    mt: 1,
                                                    mb: 2,
                                                    ml: "3px"
                                                }}
                                            >
                                                <ButtonGroup disableElevation variant='contained'>
                                                    <Button 
                                                        size='medium'
                                                        name="supplyType"
                                                        onClick={(value) => this.onChangeModulationSupply(value)}
                                                        sx={{
                                                            bgcolor: theme.palette.primary.main,
                                                            color: theme.palette.common.white,
                                                            opacity: "0.4",
                                                            '&:disabled': {
                                                                bgcolor: (readableMode === true) ? 
                                                                    ( !(isSupplyTypeLiquid) ? theme.palette.grey[500] : theme.palette.grey[200]) 
                                                                    : 
                                                                    theme.palette.primary.main,
                                                                color: (readableMode === true) ? 
                                                                    ( !(isSupplyTypeLiquid) ? theme.palette.common.white : theme.palette.grey[500]) 
                                                                    : 
                                                                    theme.palette.common.white,
                                                                opacity: "1"
                                                            }
                                                        }}
                                                        disabled={(!(isSupplyTypeLiquid)) || (readableMode === true) }
                                                    >
                                                        {StringTranslate.supplyTypeSolid}
                                                    </Button>
                                                    <Button 
                                                        size='medium'
                                                        name="supplyType"
                                                        onClick={(value) => this.onChangeModulationSupply(value)}
                                                        sx={{
                                                            bgcolor: theme.palette.primary.main,
                                                            color: theme.palette.common.white,
                                                            opacity: "0.4",
                                                            '&:disabled': {
                                                                bgcolor: (readableMode === true) ? 
                                                                    ( (isSupplyTypeLiquid) ? theme.palette.grey[500] : theme.palette.grey[200]) 
                                                                    : 
                                                                    theme.palette.primary.main,
                                                                color: (readableMode === true) ? 
                                                                    ( (isSupplyTypeLiquid) ? theme.palette.common.white : theme.palette.grey[500]) 
                                                                    : 
                                                                    theme.palette.common.white,
                                                                opacity: "1"
                                                            }
                                                        }}
                                                        disabled={(isSupplyTypeLiquid) || (readableMode === true) }
                                                    >
                                                        {StringTranslate.supplyTypeLiquid}
                                                    </Button>
                                                </ButtonGroup>
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                    { /* dose medium */}
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs>
                                                <Typography>{`${StringTranslate.mediumDose} : ${(lastNitrogenInput.nitrogenPrescription)} ${AzoteUnitBySurface}`}</Typography>
                                            </Grid>

                                            <Grid item xs >
                                                { /* content */}
                                                <TextField
                                                    type="number"
                                                    id="content"
                                                    error={contentError}
                                                    helperText={(contentError) ? 'Saisie une valeur entre 0 et 100.' : undefined}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                        inputProps: {
                                                            max: 100, min: 0, step: 0.01,
                                                        }
                                                    }}
                                                    label={`${StringTranslate.content}`}
                                                    placeholder={`${StringTranslate.content}`}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                    sx={{
                                                        margin: "0px 3px 10px 3px",
                                                        fontSize: "0.9rem",
                                                        paddingRight: "5px"
                                                    }}
                                                    disabled={(readableMode === true)}
                                                    defaultValue={lastNitrogenInput.content}
                                                    name="content"
                                                    onChange={() => this.onChangeModulationContent()}
                                                    onBlur={(event) => this.ApplyChangeAfterContentValided(event.target.value)}
                                                    onKeyDown={(event) => {
                                                        if (event.key === 'Enter') {
                                                            this.ApplyChangeAfterContentValided(event.target.value);
                                                        }
                                                    }}
                                                    tabIndex={1}
                                                    ref="content" />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/*Gestion de la répartition */}
                                    {(repartition !== null) && (
                                        <>
                                            <Grid item xs={12}>
                                                <Stack direction={'row'}>
                                                    <InputLabel sx={{color: theme.palette.text.primary}}>
                                                        {StringTranslate.repartitionLabel}
                                                    </InputLabel>
                                                    <Tooltip title={StringTranslate.repartitionTip} placement="bottom">
                                                        <Help sx={{ verticalAlign: "middle", pl: 1 }} />
                                                    </Tooltip>
                                                </Stack>
                                            </Grid>

                                            <Grid item xs={12} p={2}>
                                                {(!readableMode) ? (
                                                    <Stack sx={{ mt: 2 }} spacing={1} direction={'row'} alignItems={'center'}>
                                                        <Typography sx={{ transform: 'translateX(-20%)' }} variant={'body2'}>5%</Typography>
                                                            <Slider
                                                                aria-label={StringTranslate.repartitionTip}
                                                                min={5}
                                                                max={50}
                                                                step={5}
                                                                valueLabelDisplay="auto"
                                                                valueLabelFormat={(value) => `${value}%`}
                                                                marks={[
                                                                    {
                                                                        value: 5,
                                                                        label: StringTranslate.lowerRepartitionLabel,
                                                                    },
                                                                    {
                                                                        value: 50,
                                                                        label: StringTranslate.higherRepartitionLabel,
                                                                    }
                                                                ]}
                                                                value={repartition}
                                                                onChange={(_, value) => this.handleRepartitionChange(value)}
                                                                onChangeCommitted={(_, value) => this.handleRepartitionChangeCommitted(value)}
                                                                disabled={readableMode}
                                                            />
                                                        <Typography sx={{ transform: 'translateX(20%)' }} variant={'body2'}>50%</Typography>
                                                    </Stack>
                                                ) : (
                                                    <Typography>{`${repartition}%`}</Typography>
                                                )}
                                            </Grid>
                                        </>
                                    )}

                                    { /* Tableau des doses */}
                                    <Grid item xs={12}>
                                        <TableContainer >
                                            <Table
                                                sx={{
                                                    maxWidth: "650px"
                                                }}
                                                aria-label="caption table"
                                                size="small"
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell>{`${StringTranslate.area} (Ha)`}</TableCell>
                                                        <TableCell>{<><div>{StringTranslate.doseNitrogen}</div> ({AzoteUnitBySurface})</>}</TableCell>
                                                        <TableCell>{<><div>{StringTranslate.doseInputs}</div> ({contentTypeByHa})</>}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {zones !== undefined &&
                                                        zones.map((row, index) => (
                                                            <TableRow key={row.id} className="table-row-modulation">
                                                                <TableCell style={{ background: getColor(row.color), width: '5%' }} />
                                                                <TableCell className="cell-ratio">
                                                                    <Typography>{numberHelper.fixeDecimal(row.area)}</Typography>
                                                                </TableCell>

                                                                <TableCell style={{ width: '30%' }}>
                                                                    <Typography>{numberHelper.fixeDecimal(row.actualDoseUnit)}</Typography>
                                                                </TableCell>

                                                                <TableCell style={{ width: '30%' }}>
                                                                    <Typography>{numberHelper.fixeDecimal(row.actualDose)}</Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>

                                                <TableFooter>
                                                    { /* Aide pour les zones de modulation */}
                                                    {(lastNitrogenInput.cloudyDetected === true) &&
                                                        <TableRow>
                                                            <TableCell colSpan={4}>
                                                                <Tooltip
                                                                    title={`${StringTranslate.helpCloudZones}`}
                                                                    placement="bottom">
                                                                    <Help sx={{ verticalAlign: "middle", pl: 1, color: theme.palette.grey[700]}} />
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    } 
                                                    <TableRow>
                                                        <TableCell colSpan={2}>
                                                            <Typography>{`${StringTranslate.quantityToExpand}`}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>
                                                                {`${numberHelper.fixeDecimal(lastNitrogenInput.quantityToExpandUnit)} ${AzoteUnit}`}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>
                                                                {`${numberHelper.fixeDecimal(lastNitrogenInput.quantityToExpand)} ${contentType}`}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                        
                        </SubCard>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <Grid container rowSpacing={2}>
                            {/* Dose du conseil du moteur (Azote total) */}
                            <Grid item xs={12}>
                                <Card
                                    sx={{
                                        background: theme.palette.info.main,
                                        position: 'relative',
                                        color: theme.palette.common.white,
                                        '&:hover svg': {
                                            opacity: '1',
                                            transform: 'scale(1.1)'
                                        }
                                    }}
                                >
                                    <CardContent>
                                        {/* <Typography
                                            variant="body2"
                                            sx={{
                                                position: 'absolute',
                                                right: 15,
                                                top: 25,
                                                color: theme.palette.common.white,
                                                '&> svg': {
                                                    width: 40,
                                                    height: 40,
                                                    opacity: '0.4',
                                                    transition: 'all .3s ease-in-out'
                                                }
                                            }}
                                        >
                                            <TaskAltIcon />
                                        </Typography> */}
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <Typography variant="h5" color="inherit">{StringTranslate.finalTotalPlotDose}​</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="h1" color="inherit">{nitrogenTotal} {AzoteUnitBySurface}</Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12}>
                                {/* Dose à epandre */}
                                <Card
                                    sx={{
                                        background: theme.palette.secondary.main,
                                        position: 'relative',
                                        color: theme.palette.common.white,
                                        '&:hover svg': {
                                            opacity: '1',
                                            transform: 'scale(1.1)'
                                        }
                                    }}
                                >
                                    <CardContent>
                                        {/* <Typography
                                            variant="body2"
                                            sx={{
                                                position: 'absolute',
                                                right: 15,
                                                top: 25,
                                                color: theme.palette.common.white,
                                                '&> svg': {
                                                    width: 40,
                                                    height: 40,
                                                    opacity: '0.4',
                                                    transition: 'all .3s ease-in-out'
                                                }
                                            }}
                                        >
                                            <TaskAltIcon />
                                        </Typography> */}
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <Typography variant="h5" color="inherit">{StringTranslate.doseToSpreadForLastInputN}​</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="h1" color="inherit">{(lastNitrogenInput.nitrogenPrescription)} {AzoteUnitBySurface}</Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>


                            {/* <Grid item xs={12}>
                                Différence par rapport au PPF
                                <Card
                                    sx={{
                                        background: theme.palette.common.white,
                                        position: 'relative',
                                        color: theme.palette.common.black,
    
                                    }}>
                                    <CardContent>
                                    <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <Typography variant="h5" color="inherit">{StringTranslate.ppfDifference}​</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="h1" color="inherit"></Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid> */}

                            <Grid item xs={12}>
                                {/* Dose déjà apportée */}
                                <SubCard
                                    sx={{
                                        background: theme.palette.background.default,
                                        position: 'relative',
                                        border: '1px solid',
                                        borderColor: theme.palette.info.main,
                                        '&:hover svg': {
                                            opacity: '1',
                                        transform: 'scale(1.1)'
                                        }
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            position: 'absolute',
                                            right: 15,
                                            top: 25,
                                            color: theme.palette.info.main,
                                            '&> svg': {
                                                width: 40,
                                                height: 40,
                                                opacity: '0.4',
                                                transition: 'all .3s ease-in-out'
                                            }
                                        }}
                                    >
                                        <EmojiObjectsIcon />
                                    </Typography>

                                    <Grid container spacing={0} direction="row">
                                        <Grid item sx={{ pt: 2, pr: 1 }}>
                                            <InputLabel sx={{ display:'flex', color: theme.palette.text.primary, width: '100%', justifyContent:'center', }}>{StringTranslate.modulatingDose}</InputLabel>
                                            <Stack 
                                                direction="row"
                                                alignItems="center"
                                                sx={{
                                                    mt: 1,
                                                    mb: 2,
                                                    ml: "3px"
                                                }}
                                            >
                                                <Typography>{lastNitrogenInput.fertiNitrogenTotal} {AzoteUnitBySurface}</Typography>
                                                <ButtonGroup disableElevation variant='contained' sx={{ mx: 1 }}>
                                                    <Button 
                                                        size='medium'
                                                        name="totalDose"
                                                        onClick={(value) => this.ApplyChangeAfterTotalDoseChanged()}
                                                        sx={{
                                                            bgcolor: theme.palette.primary.main,
                                                            color: theme.palette.common.white,
                                                            opacity: "0.4",
                                                            '&:disabled': {
                                                                bgcolor: (readableMode === true) ? 
                                                                    ( !(usingAjustedDose) ? theme.palette.grey[500] : theme.palette.grey[200]) 
                                                                    : 
                                                                    theme.palette.primary.main,
                                                                color: (readableMode === true) ? 
                                                                    ( !(usingAjustedDose) ? theme.palette.common.white : theme.palette.grey[500]) 
                                                                    : 
                                                                    theme.palette.common.white,
                                                                opacity: "1"
                                                            }
                                                        }}
                                                        disabled={(!(usingAjustedDose)) || (readableMode === true) }
                                                    >
                                                        {StringTranslate.ppf}
                                                    </Button>
                                                    <Button 
                                                        size='medium'
                                                        name="totalDose"
                                                        onClick={(value) => this.ApplyChangeAfterTotalDoseChanged()}
                                                        sx={{
                                                            bgcolor: theme.palette.primary.main,
                                                            color: theme.palette.common.white,
                                                            opacity: "0.4",
                                                            '&:disabled': {
                                                                bgcolor: (readableMode === true) ? 
                                                                    ( (usingAjustedDose) ? theme.palette.grey[500] : theme.palette.grey[200]) 
                                                                    : 
                                                                    theme.palette.primary.main,
                                                                color: (readableMode === true) ? 
                                                                    ( (usingAjustedDose) ? theme.palette.common.white : theme.palette.grey[500]) 
                                                                    : 
                                                                    theme.palette.common.white,
                                                                opacity: "1"
                                                            }
                                                        }}
                                                        disabled={(usingAjustedDose) || (readableMode === true) }
                                                    >
                                                        {StringTranslate.adjusted}
                                                    </Button>
                                                </ButtonGroup>
                                                <Typography>{lastNitrogenInput.newNitrogenTotal} {AzoteUnitBySurface}</Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={0} direction="row">
                                        <Grid item sx={{ pt: 2, pr: 1 }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5" sx={{ color: theme.palette.text.primary, pl: 1 }}>{StringTranslate.doseAlreadyProvided}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Stack direction="row" spacing={1}>
                                                    <FormControl sx={{ maxWidth: '20ch' }} variant="outlined">
                                                        <TextField
                                                            id="dose-saved"
                                                            name="dose-saved"
                                                            ref="dose-saved"
                                                            type="number"
                                                            size="small"
                                                            error={disableSaveNewModulation}
                                                            helperText={(disableSaveNewModulation) ? StringTranslate.doseApporterError : undefined}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">{AzoteUnitBySurface}</InputAdornment>,
                                                                inputProps: {
                                                                    max: nitrogenTotal, min: 0, step: 1,
                                                                }
                                                            }}
                                                            // label={`${StringTranslate.doseAlreadyProvided}`}
                                                            variant="outlined"
                                                            defaultValue={dosePreviouslyInput}
                                                            sx={{
                                                                margin: "0px 3px 10px 3px",
                                                                fontSize: "0.9rem",
                                                                paddingRight: "5px"
                                                            }}
                                                            onBlur={(event) => this.ApplyChangeAfterDoseValided(event.target.value)}
                                                            onKeyDown={(event) => {
                                                                if (event.key === 'Enter') {
                                                                    this.ApplyChangeAfterDoseValided(event.target.value);
                                                                }
                                                            }}
                                                            disabled={(buildingSampleAndDose === true) || (savingSampleAndDose === true) || (readableMode === true)}
                                                        />
                                                    </FormControl>
                                                    <Button 
                                                        variant="contained" 
                                                        sx={{ height: '39px' }} 
                                                        //Le bouton n'éxecute rien car toutes les propriétés, sauf le nombre de zones, sont gérées via l'action Redux. Sert juste à perdre le focus d'un champ par exemple
                                                        //onClick={() => {}} 
                                                        startIcon={((buildingSampleAndDose === true)) && <CircularProgress size={20} color="inherit" />}
                                                        disabled={(buildingSampleAndDose === true) || (savingSampleAndDose === true) || (readableMode === true)}
                                                    >
                                                        {StringTranslate.calculateAgain}
                                                        </Button>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </SubCard>
                            </Grid>                            
                        </Grid>
                    </Grid>
                </Grid>

                <CardActions>
                    <Grid container spacing={2}>
                        { /* Bouton de retour PC */}
                        <Grid item xs={4} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                            {(readableMode === false) && (
                                <Button
                                    onClick={() => this.onCancelModulationLastInputN(lastNitrogenInput.parcelId)}
                                    tabIndex={12}
                                    disabled={(buildingSampleAndDose === true) || (savingSampleAndDose === true)}
                                    startIcon={<ArrowBack />}
                                    sx={{
                                        height: '100%'
                                    }}>
                                    {(lastNitrogenInput.hasModulation) ? StringTranslate.changeImageForLastInputNModulation : StringTranslate.goBackStepModulation}
                                </Button>
                            )}
                        </Grid>
                        { /* Bouton de retour Mobile */}
                        <Grid item xs={4} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                            {(readableMode === false) && (
                                <Button
                                    onClick={() => this.onCancelModulationLastInputN(lastNitrogenInput.parcelId)}
                                    tabIndex={12}
                                    disabled={(buildingSampleAndDose === true) || (savingSampleAndDose === true)}
                                    startIcon={<ArrowBack />}
                                    sx={{
                                        height: '100%'
                                    }}>
                                    {StringTranslate.goBackStepModulation}
                                </Button>
                            )}
                        </Grid>

                        { /* Bouton de téléchargement PC */}
                        <Grid item xs={4} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                            <Stack direction="row" spacing={1} justifyContent="center">
                                <Button
                                    //onClick={() => this.onCancelModulationLastInputN()}
                                    variant="contained"
                                    color="secondary"
                                    tabIndex={12}
                                    onClick={(event) =>  this.onGenerateLastNitrogenFileClick(event, lastNitrogenInput.parcelId)}
                                    disabled={(buildingSampleAndDose === true) || (savingSampleAndDose === true) || (disableSaveNewModulation === true)}
                                    startIcon={<FileDownload />}
                                    sx={{
                                        height: '100%',
                                    }}>
                                    {StringTranslate.downloadModulPdf}
                                </Button>
                            </Stack>
                        </Grid>
                        { /* Bouton de téléchargement Mobile */}
                        <Grid item xs={4} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                            <Stack direction="row" spacing={1} justifyContent="center">
                                <Button
                                    //onClick={() => this.onCancelModulationLastInputN()}
                                    variant="contained"
                                    color="secondary"
                                    tabIndex={12}
                                    onClick={(event) =>  this.onGenerateLastNitrogenFileClick(event, lastNitrogenInput.parcelId)}
                                    disabled={(buildingSampleAndDose === true) || (savingSampleAndDose === true) || (disableSaveNewModulation === true)}
                                    startIcon={<FileDownload />}
                                    sx={{
                                        height: '100%',
                                    }}>
                                    {StringTranslate.pdf}
                                </Button>
                            </Stack>
                        </Grid>

                        { /* Bouton de enregistrer PC */}
                        <Grid item xs={4} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                            {(readableMode === false) && (
                                <Stack direction="row" spacing={1} justifyContent="flex-end">
                                    { /* Bouton d'enregistrement */}
                                    <Button variant="contained" color="primary"
                                        onClick={() => this.onSaveModulationLastInputN()}
                                        disabled={(buildingSampleAndDose === true) || (savingSampleAndDose === true) || (disableSaveNewModulation === true)}
                                        startIcon={<ChevronRight />}>
                                        {/* {(parcelIdsSelected.length <= 1) ? `${StringTranslate.saveModulation}` : `${StringTranslate.saveModulationAndGoNext}`} */}
                                        {StringTranslate.saveLastInputNModulationAndGoNext}
                                    </Button>
                                </Stack>
                            )}
                        </Grid>
                        { /* Bouton de enregistrer Mobile */}
                        <Grid item xs={4} sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                            {(readableMode === false) && (
                                <Stack direction="row" spacing={1} justifyContent="flex-end">
                                    { /* Bouton d'enregistrement */}
                                    <Button variant="contained" color="primary"
                                        onClick={() => this.onSaveModulationLastInputN()}
                                        disabled={(buildingSampleAndDose === true) || (savingSampleAndDose === true) || (disableSaveNewModulation === true)}
                                        startIcon={<Save />}>
                                        {/* {(parcelIdsSelected.length <= 1) ? `${StringTranslate.saveModulation}` : `${StringTranslate.saveModulationAndGoNext}`} */}
                                        {StringTranslate.continuer}
                                    </Button>
                                </Stack>
                            )}
                        </Grid>

                    </Grid>

                </CardActions>

            </>
        );
    }
    
}

const mapStateToProps = state => ({
    //Infos provenant du reducer 'parcel' :
    parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),

    //Infos provenant du reducer 'lastNitrogenInput'
    lastNitrogenInputSelected: lodashGet(state, 'lastNitrogenInputData.lastNitrogenInputSelected', -1),
    lastNitrogenInputDico: lodashGet(state, 'lastNitrogenInputData.lastNitrogenInputDico', {}),
    lastInputNParameter: lodashGet(state, 'lastNitrogenInputData.lastInputNParameter', {}),
    buildingSampleAndDose: lodashGet(state, 'lastNitrogenInputData.buildingSampleAndDose', false),
    savingSampleAndDose: lodashGet(state, 'lastNitrogenInputData.savingSampleAndDose', false),
	errorMessage: lodashGet(state, 'lastNitrogenInputData.errorMessage', undefined),
    settings: lodashGet(state, 'settingsData.settings', undefined),
});
const mapDispatchToProps = dispatch => ({

    createAndUpdateLastInputNParameter: (lastInputNDatas) => dispatch(ActionCreateLastInputNParameter(lastInputNDatas)),
	goToStepOfLastNitrogenInput: (nextStepOfLastNitrogenInput) => dispatch(ActionGoToStepOfLastNitrogenInput(nextStepOfLastNitrogenInput)),

    clearLastNitrogenInputError: () => dispatch(ActionClearLastNitrogenInputError()),

    goToSelectImage: (parcelId) => dispatch(ActionGoToSelectImage(parcelId)),
    generateLastNitrogenInputFile: (lastInputNSelected) => dispatch(ActionGenerateFileOfThisLastNitrogen(lastInputNSelected)),
    buildLastNitrogenSampleAndDoses: (lastInputNParameter) => dispatch(ActionBuildLastNitrogenSampleAndDoses(lastInputNParameter)),
    saveLastNitrogenInputModulation: (lastNitrogenInput) => dispatch(ActionSaveLastNitrogenInputModulation(lastNitrogenInput)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LastNitrogenInputResult);