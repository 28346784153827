import React from 'react';
import { createRoot } from 'react-dom/client'; //import React from "react"; import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import L from 'leaflet';
import { Fab, Button, ButtonGroup, Tooltip, Typography, Box } from '@mui/material';
import '../../../assets/css/dp-drawParcel.css';
import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';
import DrawParcelPopup from '../../../components/drawParcelPopup.jsx';
import { StepEnumDidacticielFirstParcel } from '../../../redux/actions/didacticiel.js';
import { ActionSaveParcelWhenDidacticielFirstParcel } from '../../../redux/actions/parcels.js';
import leafletfunction from '../../../assets/i18n/leaflet-draw-i18n.js';
import getTheme from "../../../themes/index.js";
import DrawParcelIcon from "../../../assets/drawParcelIcon.tsx";
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import UndoIcon from '@mui/icons-material/Undo';

let theme = getTheme();

/**
 * classe en lien avec la fonctionnalité DESSIN DUNE NOUVELLE PARCELLE
 */
class DrawParcelButtonControl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            drawingNewParcel: false, // l'utilisateur a cliqué sur le bouton de dessin de parcelle
            parcelDrawned: false, // l'utilisateur vient juste de terminer son dessin d'une parcelle (la popup d'enregistrement s'affiche)
        }

        //↓↓ BIND ↓↓
        this.handleClickDrawParcelButton = this.handleClickDrawParcelButton.bind(this);
        this.startDrawNewParcel = this.startDrawNewParcel.bind(this);
        this.stopDrawNewParcel = this.stopDrawNewParcel.bind(this);
        this.deleteLastPointDrawParcel = this.deleteLastPointDrawParcel.bind(this);
        this.completeShapeDrawParcel = this.completeShapeDrawParcel.bind(this);
        this.onCreateDrawParcel = this.onCreateDrawParcel.bind(this);
        this.onJustDrawNewPoint = this.onJustDrawNewPoint.bind(this);
        this.deleteDrawnParcel = this.deleteDrawnParcel.bind(this);
        this.saveDrawnParcelFromDidacticiel = this.saveDrawnParcelFromDidacticiel.bind(this);

        this.drawerParcel = undefined;
        this.counterVertex = 0;

        L.drawLocal = leafletfunction(); // mise à jour i18n - plugin leaflet draw
    }

    /**
     * fonction callback suite au clic bouton pour dessiner une parcelle
     */
    handleClickDrawParcelButton(event) {
        if (this.state.drawingNewParcel) return; // évite le multi click (si c'est déjà activé)

        this.startDrawNewParcel();
    }

    /**
     * fonction permettant de lancer le dessin d'une nouvelle parcelle (suite au clic bouton)
     */
    startDrawNewParcel() {

        // ↓ on indique à renderMaps et au composant que l'on est en train de dessiner - @@ futur: utilisation redux ? ↓
        this.props.renderMapsCmp.DRAWINGPARCEL = true;
        this.setState({ drawingNewParcel: true });
        if (this.props.setShowButtons) this.props.setShowButtons(false); // on fait disparaître les boutons qui ne sont pas nécessaire lors du dessin

        // ↓ MODIFICATION FONCTIONNEL SUR MOBILE (touch event) - https://github.com/Leaflet/Leaflet.draw/issues/935#issuecomment-577151194 ↓
        L.Draw.Polygon.prototype._onTouch = L.Util.falseFn;
        if (L.Browser.touch && (this.props.renderMapsCmp.map.tap && this.props.renderMapsCmp.map.tap.disable))
            this.props.renderMapsCmp.map.tap.disable();

        // ↓ création d'un objet contenant le fonctionnel de dessin d'un polygône du plugin LEAFLET DRAW ↓
        this.drawerParcelOptions = {
            allowIntersection: false, // Restricts shapes to simple polygons
            drawError: {
                color: theme.palette.contour.selection, // Color the shape will turn when intersects
                message: `${StringTranslate.noauth}`  // Message that will show when intersect
            },
            icon: new L.DivIcon({ // taille des points
                iconSize: new L.Point(15, 15),
                className: 'leaflet-div-icon dp-divIcon'
            }),
        };
        this.drawerParcel = new L.Draw.Polygon(this.props.renderMapsCmp.map, this.drawerParcelOptions);
        this.drawerParcel.enable(); // on lance le fonctionnel de dessin de parcelle (brique leaflet-Draw)
        this.counterVertex = 0;

        // ↓ mise en place des événements ↓
        this.props.renderMapsCmp.map.on(L.Draw.Event.DRAWVERTEX, this.onJustDrawNewPoint);
        this.props.renderMapsCmp.map.on(L.Draw.Event.CREATED, this.onCreateDrawParcel); // lancé lorsque l'utilisateur vient de terminer son polygone (le dessin de sa parcelle)        
        this.props.renderMapsCmp.map.on(L.Draw.Event.DRAWSTOP, this.stopDrawNewParcel); // lancé lorsque l'utilisateur a cliqué sur l'option 'annuler'
    }

    /**
     * fonction permettant d'arrêter le dessin de parcelle (lors du clic sur les boutons annuler,...  )
     */
    stopDrawNewParcel(event) {
        // ↓ est-on déjà en train de sauvegarder une parcelle dessinée => le retour de la requête fera le travail ↓
        if (this.props.savingDrawnParcel || this.drawnParcelSaved) return;

        // ↓ on stoppe le fonctionnel ↓
        if (this.drawerParcel)
            this.drawerParcel.disable();

        this.props.renderMapsCmp.DRAWINGPARCEL = false;
        if (this.props.setShowButtons) this.props.setShowButtons(true); // on fait réapparaître les boutons disparus lors du dessin

        //↓ lors du clic bouton annuler lorsque la parcelle a déjà été dessinée - on la supprime ↓
        this.deleteDrawnParcel();

        //↓ suppression des événements et de l'objet drawer ↓
        this.drawerMarker = null;
        this.props.renderMapsCmp.map.off(L.Draw.Event.DRAWVERTEX, this.onJustDrawNewPoint);
        this.props.renderMapsCmp.map.off(L.Draw.Event.CREATED, this.onCreateDrawParcel);
        this.props.renderMapsCmp.map.off(L.Draw.Event.DRAWSTOP, this.stopDrawNewParcel);

        this.setState({ drawingNewParcel: false, parcelDrawned: false });
    }

    /**
     * fonction callback lors de la création d'une parcelle dessinée (lors du dessin terminé)
     */
    onJustDrawNewPoint(vertex) {
        if (vertex) {
            this.counterVertex += 1;
        }
    }

    /**
     * fonction permettant de supprimer le dernier point lors du dessin d'une nouvelle parcelle
     */
    completeShapeDrawParcel(event) {
        if (this.drawerParcel) {
            if (this.counterVertex > 2) { //minimum un triangle!
                this.drawerParcel.completeShape();
            } else {
                this.stopDrawNewParcel(event);
            }
        }
    }

    /**
     * fonction permettant de supprimer le dernier point lors du dessin d'une nouvelle parcelle
     */
    deleteLastPointDrawParcel(event) {
        if (this.drawerParcel && (this.counterVertex > 0)) {
            this.drawerParcel.deleteLastVertex();
            this.counterVertex -= 1;
        }
    }

    /**
     * fonction callback lors de la création d'une parcelle dessinée (lors du dessin terminé)
     */
    onCreateDrawParcel({ layer }) {
        if (!layer) return;

        // ↓ récupération du layer + création variables  ↓ */
        this.drawnLayer = layer;
        let drawnLayerArea = 0;

        // ↓ ajout du nouveau layer sur la carte - provisoire ↓
        this.props.renderMapsCmp.map.addLayer(this.drawnLayer);

        // ↓ suppression des événements ↓
        this.props.renderMapsCmp.map.off(L.Draw.Event.DRAWVERTEX, this.onJustDrawNewPoint);
        this.props.renderMapsCmp.map.off(L.Draw.Event.CREATED, this.onCreateDrawParcel);
        this.props.renderMapsCmp.map.off(L.Draw.Event.DRAWSTOP, this.stopDrawNewParcel);

        // ↓ Information envoyée au visuel - suppression des boutons de contrôle  ↓
        this.setState({ parcelDrawned: true });

        // ↓ configuration et affichage d'une popup (permettant ensuite d'enregistrer la parcelle) ↓
        let popup = L.DomUtil.create('div', 'popup-draw-container');
        const root = createRoot(popup); // createRoot(container!) if you use TypeScript
        if (root) {
            root.render(
                <DrawParcelPopup
                    {...this.props}
                    drawnLayer={this.drawnLayer}
                    drawnLayerArea={drawnLayerArea}
                    saveDrawnParcel={this.saveDrawnParcelFromDidacticiel}
                    cancel={this.stopDrawNewParcel}
                />);
            }
        this.drawnLayer.bindPopup(popup);
        this.drawnLayer.on('popupclose', this.stopDrawNewParcel);
        this.drawnLayer.openPopup();
    }

    /**
     * fonction permettant de supprimer le nouveau layer précèdemment créé
     */
    deleteDrawnParcel() {
        if (this.drawnLayer && this.props.renderMapsCmp.map.hasLayer(this.drawnLayer)) {
            this.props.renderMapsCmp.map.removeLayer(this.drawnLayer);
        }

        this.drawnLayer = null;
    }

    /**
     * fonction permettant d'enregistrer la parcelle venant d'être dessinée dans le cadre du didacticiel
     * - sauvegarde de la parcelle
     * - incrémentation en BDD - nombre de réalisation du didacticiel
     * - mise à jour de la typo si besoin
     */
    saveDrawnParcelFromDidacticiel(parcelName) {
        const { savingDrawnParcel, saveParcelWhenDidacticielFirstParcel, goToStep, goToMap, counterCompletedTutorial } = this.props;

        if (savingDrawnParcel || !this.drawnLayer) return; // est-on déjà en train de sauvegarder une parcelle dessinée ? as-t-on des données concernant la nouvelle parcelle ?

        let parcelNameToSave = (parcelName && parcelName !== '') ? parcelName : StringTranslate.parcelledess;
        saveParcelWhenDidacticielFirstParcel(this.drawnLayer, parcelNameToSave)
            .then(() => {

                this.drawnParcelSaved = true; // flag permettant de bloquer l'affichage des boutons
                this.drawnLayer.closePopup(); // lancement de la callback attaché au listener popupclose

                (counterCompletedTutorial <= 0) ? goToStep(StepEnumDidacticielFirstParcel.INDICE) : goToMap();
            })
            .catch((error) => this.stopDrawNewParcel())
    }

    /* fonction cycle de vie */
    render() {
        return (
            <Box
                className='leaflet-control'
                sx={{
                    zIndex: "1100"
                }}
            >

                {/* ↓ bouton - fonctionnalité dessin d'une nouvelle parcelle ↓ */}

                <Tooltip title={<Typography color="inherit">{StringTranslate.dessinerparcelle}</Typography>} placement="left">
                    <Fab
                        size="small"
                        onClick={this.handleClickDrawParcelButton}
                        sx={{
                            backgroundColor: theme.palette.common.white,
                        }}
                    >
                        <DrawParcelIcon stroke={theme.palette.primary.main} />
                    </Fab>
                </Tooltip>

                {(this.state.drawingNewParcel && !this.state.parcelDrawned) &&
                    <ButtonGroup
                        aria-label="contained primary button group"
                        variant="contained"
                        color="primary"
                        variant_pz="btns-grp-drawAndobs"
                        sx={{ [theme.breakpoints.up('sm')]: { marginLeft: "-397px" } }}>
                        {/* Boutons à afficher pour le mobile */}
                        <Button onClick={this.stopDrawNewParcel}
                            sx={{
                                [theme.breakpoints.down('sm')]: { display: 'none' }
                            }}>
                            {StringTranslate.annuler}
                        </Button>

                        <Button onClick={this.deleteLastPointDrawParcel}
                            sx={{
                                [theme.breakpoints.down('sm')]: { display: 'none' }
                            }}>
                            {StringTranslate.supppoint}
                        </Button>

                        <Button onClick={this.completeShapeDrawParcel}
                            sx={{
                                [theme.breakpoints.down('sm')]: { display: 'none' }
                            }}>
                            {StringTranslate.terminer}
                        </Button>

                        {/* Boutons à afficher pour le desktop */}
                        <Button onClick={this.stopDrawNewParcel}
                            sx={{
                                [theme.breakpoints.up('sm')]: { display: 'none' }
                            }}>
                            <CancelIcon />
                        </Button>

                        <Button onClick={this.deleteLastPointDrawParcel}
                            sx={{
                                [theme.breakpoints.up('sm')]: { display: 'none' }
                            }}>
                            <UndoIcon />
                        </Button>

                        <Button onClick={this.completeShapeDrawParcel}
                            sx={{
                                [theme.breakpoints.up('sm')]: { display: 'none' }
                            }}>
                            <DoneIcon />
                        </Button>
                    </ButtonGroup>
                }
            </Box>
        )
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    counterCompletedTutorial: state.didacticielData.firstparcel.counterCompleted
});


/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
    saveParcelWhenDidacticielFirstParcel: (parcelToSave, parcelNameToSave) => dispatch(ActionSaveParcelWhenDidacticielFirstParcel(parcelToSave, parcelNameToSave)),
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DrawParcelButtonControl);