import React, { Component } from "react";
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import '../../assets/css/dnc-displayNdviCharts.css';
import NdviScale from './ndviScale';
import NdviChart from './ndviChart';
import { ActionAskAnalysFromImageId } from '../../redux/actions/satImage.js';
import ConstantsLayers from '../../utils/constantsOfLayers.js';
import { ParcelsHelper } from '../../utils/parcelsHelper.js';
//import { ConstantsHistogram } from '../../utils/constantsHistogram.js';
import { Grid } from '@mui/material';

import numberHelper from '../../utils/numberHelper.js';
//import { IsMaybyNativeHoster } from '../../utils/platformHelper';

import SubCard from "../subLayouts/subCard.jsx";

/* Theme Berry */
/*import getTheme from "../../themes/index.js";
const theme = getTheme();*/


//const LimitSizeScreenInMobile = 414;
//const isMobileWindow = ( (window.innerWidth < LimitSizeScreenInMobile) || (window.innerHeight < LimitSizeScreenInMobile) || (IsMaybyNativeHoster() === true) );


///////////////////////////////////////////////////////////////////////////
// Composant appliqué par dessus (position:absolute) le composant RenderMaps pour afficher des diagrammes (NDVI, ...)
///////////////////////////////////////////////////////////////////////////
class DisplayNdviCharts extends Component {
    /* constructeur */
    constructor(props) {
        super(props);

        this.state = {
            showChart: true, // permet de switcher entre la légende et le diagramme
            dataChart: undefined, // données d'histogramme
            showCloudsWarning: false,
        }

        //this.handleShowChart = this.handleShowChart.bind(this);
        this.getImageAnalyse = this.getImageAnalyse.bind(this);
        this.injectAreaInDataChart = this.injectAreaInDataChart.bind(this);
        this.getParcelArea = this.getParcelArea.bind(this);
    }

    /* fonction permettant de basculer entre la visualisation du diagramme et ou de la légende */
    /*handleShowChart() {
        if (this.state.dataChart) // si données d'histo présentes alors OK => on peut switcher
            this.setState({
                showChart: true,
            });
    }*/ //plus utilisé depuis la mise en place des panneau flottant (PC et Mobile)

    /* fonction permettant de récupérer les données d'analyse d'image suivant la parcelle en cours */
    getImageAnalyse(satImage) {
        const { parcelIdSelected, getAnalysFromImageId } = this.props;

        //if (satImage && satImage.id && (satImage.id > 0) && getAnalysFromImageId && (satImage.callBackGetAnalys === undefined)) { //provoque non-affichage de la permière image affichée sur la parcelle sélectionnée
        if (satImage && satImage.id && (satImage.id > 0) && getAnalysFromImageId) {
            // Pour faciliter la détection de l'obtension de l'analyse et permettre son utilisation, je teste une nouvelle manière de faire:
            // -> je fait appel à l'action faisant l'appel WEB API et stockant l'analyse dans l'entité poco imageData... 
            // ET je programme une 'callback pour me le signaler.
            satImage.callBackGetAnalys = (dataAnalyseReturned) => {
                //satImage.callBackGetAnalys = undefined;
                if (dataAnalyseReturned) {
                    if (!satImage.dataAnalyse) //en principe, devrait toujours être le cas !
                        satImage.dataAnalyse = dataAnalyseReturned;

                    this.injectAreaInDataChart(dataAnalyseReturned); // on calcule les différentes surfaces pour chaque couleur d'histogramme
                    
                    /*this.setState({
                        dataChart: dataAnalyseReturned,
                        showChart: true,
                        showCloudsWarning: satImage.maxcc > ConstantsHistogram.LimitSatImageMaxcc ? true : false // demande si l'image sat a un taux d'ennuagement supérieur à la limite renseignée dans satImage.js
                    }); // on montre le diagramme
                    */ //on ne fait plus cela. On attends que Redux nous le signale !
                }
            };
            
            getAnalysFromImageId(parcelIdSelected, satImage.id);
        }
        //else //ne fait rien !
    }

    /* fonction permettant d'injecter les valeurs des différentes surfaces en hectare pour chaque entrée des données d'histogramme */
    injectAreaInDataChart(dataChart) {
        if ((!dataChart) || (!dataChart.percentages)) return;

        const { parcelIdSelected, parcelDico } = this.props;

        const parcelSelected = lodashGet(parcelDico, `[${parcelIdSelected}]`, undefined);
        const areaOfParcel = (parcelSelected) ? ParcelsHelper.getAreaFromParcel(parcelSelected) : 0;
        dataChart.percentages.forEach(obj => { //pour chaque zone de couleur, on calcule et sauvegarde le pourcentage de surface qu'elle représente.
            let number = areaOfParcel * (obj.percentage / 100);
            obj.area = numberHelper.fixeDecimal(number); //garde que 2 décimales ! 
        });
    }

    /* fonction permettant de conaître la surface totale de la parcelle */
    getParcelArea() {
        const { parcelIdSelected, parcelDico } = this.props;

        const parcelSelected = lodashGet(parcelDico, `[${parcelIdSelected}]`, undefined);
        const areaOfParcel = (parcelSelected) ? ParcelsHelper.getAreaFromParcel(parcelSelected) : 0;
        return (areaOfParcel) ? areaOfParcel : 'N/C';
    }

    componentDidMount() {
        const { parcelIdSelected, forewardLayerName, satimageIdOfParcelSelected,
            parcelDico, satimagesByParcelDico } = this.props;
        const { dataChart } = this.state;

        if (parcelIdSelected && (parcelIdSelected > 0) && satimageIdOfParcelSelected && (satimageIdOfParcelSelected > 0)) {
            const parcelSelected = lodashGet(parcelDico, `[${parcelIdSelected}]`, undefined);

            // ↓ Vérifie si la couche en cours de visualisation (NDVI ou satellite) permet une visualisation (ou pas) de l'histogramme ↓
            const layerAvailableToChart = ((!forewardLayerName) ||
                (forewardLayerName === ConstantsLayers.VisibleParcelLayerName) || (forewardLayerName === ConstantsLayers.NdviParcelLayerName)) ? true : false;
            if (parcelSelected && layerAvailableToChart) {
                const currentImageOfParcelSelected = lodashGet(satimagesByParcelDico, `[${parcelIdSelected}].satimageDico[${satimageIdOfParcelSelected}]`, undefined);

                if (currentImageOfParcelSelected) {
                    // ↓↓ si aucune données d'analyse alors je les récupère ↓↓
                    if (currentImageOfParcelSelected.dataAnalyse) {
                        this.injectAreaInDataChart(currentImageOfParcelSelected.dataAnalyse); // on calcule les différentes surfaces pour chaque couleur d'histogramme
                        this.setState({ dataChart: { ...currentImageOfParcelSelected.dataAnalyse } }); // on montre le diagramme en créant un objet (et pas en exploitant l'objet par référence)
                    }
                    else
                        this.getImageAnalyse(currentImageOfParcelSelected); // sinon on récupère les données d'analyse (données formatées)
                } else if (dataChart) {
                    //Ne devrait pas arriver, mais si on n'a pas l'image, on attends son obtension:
                    this.setState({ dataChart: undefined });
                }
                //else //Rien à faire de plus... rien à besoin de changer !
            }
            //else //on ne touche à rien... car l'histogramme n'est pas visible !
        }
    }

    componentDidUpdate(prevProps) {
        const { parcelIdSelected, forewardLayerName, satimageIdOfParcelSelected,
            parcelDico, satimagesByParcelDico /*, panelImageSwiperOpened, analysLoading, analysLoadedCounter*/ } = this.props;
        const { dataChart } = this.state;

        // On vérifie si on n'est pas déjà raccord (données ok, visant même parcelle, même image (donc même date)):
        if (dataChart && dataChart.percentages && (parcelIdSelected > 0) && (dataChart.parcel === parcelIdSelected) && 
            (satimageIdOfParcelSelected > 0) && (dataChart.image === satimageIdOfParcelSelected)) {
            return;
        }


        // En cas de changement de sélection de parcelle: 
        // OU
        // En cas de chargement de l'image sélectionnée (on était en attente de son obtension pour pouvoir générer/obtenir l'analyse associée):
        // OU
        // En cas d'obtention d'une analyse d'image qui n'était pas encore dispo côté client:
        // RQ : Valable aussi en cas de chargement du premier historique (et donc de l'image la plus récente), suite au lancement de l'application:
        if (parcelIdSelected && (parcelIdSelected > 0) && satimageIdOfParcelSelected && (satimageIdOfParcelSelected > 0)) {
            //on commence par obtenir l'image visée (bonne parcelle, bonne date):
            const parcelSelected = lodashGet(parcelDico, `[${parcelIdSelected}]`, undefined);

            // ↓ Vérifie si la couche en cours de visualisation (NDVI ou satellite) permet une visualisation (ou pas) de l'histogramme ↓
            const layerAvailableToChart = ((!forewardLayerName) ||
                (forewardLayerName === ConstantsLayers.VisibleParcelLayerName) || (forewardLayerName === ConstantsLayers.NdviParcelLayerName)) ? true : false;
            if (parcelSelected && layerAvailableToChart) {
                const currentImageOfParcelSelected = lodashGet(satimagesByParcelDico, `[${parcelIdSelected}].satimageDico[${satimageIdOfParcelSelected}]`, undefined);

                if (currentImageOfParcelSelected) {
                    // ↓↓ si on a déjà les données d'analyse alors on l'exploite directement, ; Sinon, on doit demander son obtension et patienter... ↓↓
                    if (currentImageOfParcelSelected.dataAnalyse) {
                        this.injectAreaInDataChart(currentImageOfParcelSelected.dataAnalyse); // on calcule les différentes surfaces pour chaque couleur d'histogramme
                        this.setState({ dataChart: currentImageOfParcelSelected.dataAnalyse }); // on montre le diagramme
                    }
                    else {
                        //on demande l'analyse mais on ne touche pas aux données actuelles (elles seront actualisées par Redux !)
                        this.getImageAnalyse(currentImageOfParcelSelected); // sinon on récupère les données d'analyse (données formatées)
                    }
                } else if (dataChart) {
                    //Ne devrait pas arriver, mais si on n'a pas l'image, on attends son obtension:
                    this.setState({ dataChart: undefined });
                }
                //else //Rien à faire de plus... rien à besoin de changer !
            }
            else if (dataChart) { // => on vide l'analyse actuellement chargée.
                this.setState({ dataChart: undefined });
            }
            //else //Rien à faire de plus... rien à besoin de changer !
        }
        else if (dataChart) { // vue parcellaire => on vide l'analyse actuellement chargée.
            this.setState({ dataChart: undefined });
        }
        //else //Rien à faire de plus... rien à besoin de changer !
    }

    /* cycle de vie react */
    render() {
        const { showChart, dataChart, showCloudsWarning } = this.state;
        const { forewardLayerName, hasParcelSelected } = this.props;
        
        // ↓ Vérifie si la couche en cours de visualisation (NDVI ou satellite) permet une visualisation (ou pas) de l'histogramme ↓
        const layerAvailableToChart = ((forewardLayerName) && (
            (forewardLayerName === ConstantsLayers.VisibleParcelLayerName) || (forewardLayerName === ConstantsLayers.NdviParcelLayerName))) ? true : false;

        return (
            <SubCard title={StringTranslate.imageAnalysis}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        {/* ↓ partie affichage histogramme lors d'une sélection de parcelle ↓ */}
                        {(hasParcelSelected && layerAvailableToChart && dataChart && dataChart.percentages) && (
                            <NdviChart
                                id="NdviChart_Element"
                                datas={dataChart.percentages}
                                showCloudsWarning={showCloudsWarning}
                                showChart={showChart}
                                area={this.getParcelArea()}
                            />
                        )}

                        {/* ↓ partie affichage légende ↓ */}
                        <NdviScale />
                    </Grid>
                </Grid>
            </SubCard>
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = function (state) {
    return {
        //Infos provenant du reducer 'parcels':
        parcelDico: (state && state.parcelsData && state.parcelsData.parcelDico) ? state.parcelsData.parcelDico : undefined,

        //Infos provenant du reducer 'contextApp':
        hasParcelSelected: (state && state.contextAppData && state.contextAppData.parcelIdSelected &&
            Number.isInteger(state.contextAppData.parcelIdSelected) && (state.contextAppData.parcelIdSelected > 0)) ? true : false,
        parcelIdSelected: (state && state.contextAppData) ? state.contextAppData.parcelIdSelected : -1,

        satimageIdOfParcelSelected: (state && state.contextAppData) ? state.contextAppData.satimageIdSelectedOfParcel : -1,
        satimageDateOfParcelSelected: (state && state.contextAppData) ? state.contextAppData.satimageDateSelectedOfParcel : undefined,
        panelImageSwiperOpened: (state && state.contextAppData) ? state.contextAppData.panelImageSwiperOpened : false,

        //Infos provenant du reducer 'settings':
        showNdviCharts: (state && state.settingsData && state.settingsData.settings) ? state.settingsData.settings.showNdviCharts : true,

        baseLayerIndex: (state && state.settingsData) ? state.settingsData.settings.baseLayer : 'satellite',
        baseLayerName: (state && state.settingsData) ? state.settingsData.baseLayertype : ConstantsLayers.VisibleBaseLayerName,
        forewardLayerIndex: (state && state.settingsData) ? state.settingsData.settings.lastLayer : 4,
        forewardLayerName: (state && state.settingsData) ? state.settingsData.forewardLayerSelected : ConstantsLayers.NdviParcelLayerName,

        //Infos provenant du reducer 'satimage':
        satimagesByParcelDico: (state && state.satimageData) ? state.satimageData.satimagesByParcelDico : {},
        parcelDicoCounter: (state && state.satimageData) ? state.satimageData.parcelDicoCounter : 0,
        analysLoading: (state && state.satimageData) ? state.satimageData.loadingAnalys : false,
        analysLoadedCounter: (state && state.satimageData) ? state.satimageData.analysCounter : 0, 
    
        settings: lodashGet(state, 'settingsData.settings', {}), // seul moyen trouvé pour rafraichir le visuel (traduction) du fait que maintenant on rentre dans l'application sans attendre les informations du client
    };
}

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    getAnalysFromImageId: (parcelId, imageId) => dispatch(ActionAskAnalysFromImageId(parcelId, imageId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DisplayNdviCharts);
