///////////////////////////////////////////////////////////////////////////
// Import des éléments utilisés
///////////////////////////////////////////////////////////////////////////
import React, { Component } from "react";
import { LinearProgress, Typography } from "@mui/material";
import { SatimageState } from "../models/stateYearMonthOfSatimages";
import StringTranslate from '../assets/i18n/stringLanguage.jsx';

///////////////////////////////////////////////////////////////////////////
// Composant global définissant la partie visualisation de l'état de l'historique depuis le composant Calendrier
///////////////////////////////////////////////////////////////////////////
class StateCalendar extends Component {    
    constructor(props) {
        super(props);
        this.state = {}
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////
    // fonction permettant d'adpater le contenu suivant l'état en cours (type 'SatimageState')
    //////////////////////////////////////////////////////////////////////////////////////////////////
    getStateContent = (state) => { //type 'SatimageState' !
        switch (state) {
            case SatimageState.stateAskOnProgress:
                return (
                    <div>
                        <Typography sx={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            clear: 'left',
                            borderTop: '1px',
                            Padding: '5px',
                        }}>
                            {`${StringTranslate.obtentionimg}`}
                        </Typography>

                        <Typography sx={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            clear: 'left',
                            borderTop: '1px',
                            Padding: '5px',
                            marginBottom: 1
                        }}>
                            {`${StringTranslate.pourmois}`}
                        </Typography>

                        <LinearProgress classes={{ barColorPrimary: "state-calendar-linearBar" }}/>
                    </div>
                );
            case SatimageState.stateAskNoDate:
                return (
                    <div>
                        <Typography sx={{
                            fontSize: '0.90rem',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            clear: 'left',
                            borderTop: '1px',
                            Padding: '5px',
                            color: '#002966'
                        }}>
                            {`${StringTranslate.noimgexpl}`}
                        </Typography>
                    </div>
                );
            case SatimageState.stateOnError:
                return (
                    <div>
                        <Typography sx={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            clear: 'left',
                            borderTop: '1px',
                            Padding: '5px',
                            color: '#f0ad4e'
                        }}>
                            {`${StringTranslate.errorimg}`}
                        </Typography>
                    </div>
                );
            default:
                return (<div></div>);
        }
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction de rendu visuel - layout général
    ///////////////////////////////////////////////////////////////////////////
    render() {
        const { currentStateYearMonth } = this.props;

        return (currentStateYearMonth) ? this.getStateContent(currentStateYearMonth) : null;
    }
}

export default StateCalendar;
