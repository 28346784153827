import { didacticielFirstparcelWebApiProvider } from '../../utils/webApiProvider.js';
import { DidacticielFirstParcelHelper } from '../../utils/didacticielFirstParcelHelper.js';
import { ActionGoToDidacticielFirstParcel, ActionUnselectParcel } from './contextApp.js';
//import { ActionUpdateTypoClient } from './clientUser.js';
import sendError from '../../utils/errorService.js';


/* Actions First Parcel */
const GO_TO_STEP_FOR_DIDACTICIEL_FIRSTPARCEL = 'GO_TO_STEP_FOR_DIDACTICIEL_FIRSTPARCEL';
const SET_DIDACTICIEL_FIRSTPARCEL_ISOPENED = 'SET_DIDACTICIEL_FIRSTPARCEL_ISOPENED';
const ADD_COUNTER_COMPLETED_DIDACTICIEL_FIRSTPARCEL = 'ADD_COUNTER_COMPLETED_DIDACTICIEL_FIRSTPARCEL';
const SET_DIDACTICIEL_FIRSTPARCEL_DATAS = 'SET_DIDACTICIEL_FIRSTPARCEL_DATAS';

/* énuméré */
export const StepEnumDidacticielFirstParcel = {
    BIENVENUE: 'BIENVENUE',
    CHOIX: 'CHOIX',
    DESSIN: 'DESSIN',
    IMPORT: 'IMPORT',
    GEOFOLIA: 'GEOFOLIA',
    INDICE: 'INDICE',
    SUCCES: 'SUCCES',
}

/* Actions Modelisation */
const SET_DIDACTICIEL_MODELISATION_DATAS = 'SET_DIDACTICIEL_MODELISATION_DATAS';
const GO_TO_STEP_FOR_DIDACTICIEL_MODELISATION = 'GO_TO_STEP_FOR_DIDACTICIEL_MODELISATION';
const ADD_COUNTER_COMPLETED_DIDACTICIEL_MODELISATION = 'ADD_COUNTER_COMPLETED_DIDACTICIEL_MODELISATION';

export const StepEnumDidacticielModelisation = {
    START: 'START',
    CHOOSE_MODELS: 'CHOOSE_MODELS',
    SUCCES: 'SUCCES',
}

/* énuméré */
export const ActionTypeDidacticiel = {
    GO_TO_STEP_FOR_DIDACTICIEL_FIRSTPARCEL: GO_TO_STEP_FOR_DIDACTICIEL_FIRSTPARCEL,
    SET_DIDACTICIEL_FIRSTPARCEL_ISOPENED: SET_DIDACTICIEL_FIRSTPARCEL_ISOPENED,
    ADD_COUNTER_COMPLETED_DIDACTICIEL_FIRSTPARCEL: ADD_COUNTER_COMPLETED_DIDACTICIEL_FIRSTPARCEL,
    SET_DIDACTICIEL_FIRSTPARCEL_DATAS: SET_DIDACTICIEL_FIRSTPARCEL_DATAS,

    SET_DIDACTICIEL_MODELISATION_DATAS: SET_DIDACTICIEL_MODELISATION_DATAS,
    GO_TO_STEP_FOR_DIDACTICIEL_MODELISATION: GO_TO_STEP_FOR_DIDACTICIEL_MODELISATION,
    ADD_COUNTER_COMPLETED_DIDACTICIEL_MODELISATION: ADD_COUNTER_COMPLETED_DIDACTICIEL_MODELISATION,
}

// ↓↓ ACTIONS SIMPLES ↓↓

/* ENREGISTRE L'OUVERTURE OU LA FERMETURE DU DIDACTICIEL FIRSTPARCEL */
export function ActionSetDidacticielFirstParcelOpened(isOpened) {
    return {
        type: SET_DIDACTICIEL_FIRSTPARCEL_ISOPENED,
        isOpened: isOpened
    };
}

/* action permettant de modifier l'étape du didacticiel */
export function ActionGoToStepDidacticielFirstParcel(stepToGo) {
    return {
        type: GO_TO_STEP_FOR_DIDACTICIEL_FIRSTPARCEL,
        stepToGo: stepToGo,
    };
}

/* action permettant d'incrémenter le compteur de validation du didacticiel */
export function ActionAddCounterCompletedDidacticielFirstParcel() {
    return {
        type: ADD_COUNTER_COMPLETED_DIDACTICIEL_FIRSTPARCEL,
    };
}

/* action permettant de modifier les données du didacticiel firstparcel */
export function ActionSetDidacticielFirstParcelDatas(obj) {
    return {
        type: SET_DIDACTICIEL_FIRSTPARCEL_DATAS,
        currentStep: obj.currentStep,
        counterCompleted: obj.counterCompleted
    };
}


export function ActionSetDidacticielModelisationData(datas) {
    return {
        type: SET_DIDACTICIEL_MODELISATION_DATAS,
        currentStep: datas.currentStep,
        counterCompleted: datas.counterCompleted,
    };
}

export function ActionGoToStepForDidacticielModelisation(stepToGo) {
    return {
        type: GO_TO_STEP_FOR_DIDACTICIEL_MODELISATION,
        stepToGo: stepToGo,
    };
}

export function ActionAddCounterCompletedDidacticielModelisation() {
    return {
        type: ADD_COUNTER_COMPLETED_DIDACTICIEL_MODELISATION,
    };
}

// ↓↓ ACTIONS COMPLEXES ↓↓

/**
 * Action permettant de lancer le didacticiel firstparcel automatiquement (ou non) au démarrage de l'application - modification du state
 * Action lancée au démarrage
 */
export const ActionInitDidacticielFirstParcel = (allDatasResponse)  => (dispatch, getState) =>  {
        //const currentState = getState();//Ne sert pas car on vient/en train de charger les données d'authent !

        // ↓ récupération des données ↓
        const clientIsNewerValue = (allDatasResponse && allDatasResponse.client && allDatasResponse.client.isNewer) ? (allDatasResponse.client.isNewer === true) : false; // donnée - est-ce un nouveau client ?
        const parcelDicoCounterValue = (allDatasResponse && allDatasResponse.parcels) ? Object.keys(allDatasResponse.parcels).length :  0; // donnée - y a t il déjà des parcelles actives ?
        const domainIdPvValue = (allDatasResponse && allDatasResponse.client) ? allDatasResponse.client.domainIdPV : null; // donnée - est ce un client geofolia ?
        const currentDidacticielFirstParcel = (allDatasResponse && allDatasResponse.didacticiels) ? allDatasResponse.didacticiels['FIRSTPARCEL'] : null; // donnée - est que le didacticiel a déjà été vu par l'utilisateur et à quelle étape s'est il arrêté ?
        const currentStep = (currentDidacticielFirstParcel && currentDidacticielFirstParcel.currentStep) ? currentDidacticielFirstParcel.currentStep : StepEnumDidacticielFirstParcel.BIENVENUE; // donnée - a quelle étape l'utilisateur s'est arrêté la dernière fois
        const counterCompleted = (currentDidacticielFirstParcel && (currentDidacticielFirstParcel.counterCompletedTutorial >= 0)) ? currentDidacticielFirstParcel.counterCompletedTutorial : 0; // donnée - combien de fois l'utilisateur a t il terminé le didacticiel ?

        /* mise à jour des données du reducer didacticiel suivant les informations provenant de la BDD */
        dispatch( ActionSetDidacticielFirstParcelDatas({
            currentStep: currentStep,
            counterCompleted: counterCompleted
        }) );

        // ↓ l'utilisateur doit-il voir (ou non) didactiticiel firstparcel ? ↓
        let showDidactiticielFirstParcel = DidacticielFirstParcelHelper.canShowTutorial({
            clientIsNewer: clientIsNewerValue,
            parcelDicoCounter: parcelDicoCounterValue,
            domainIdPv: domainIdPvValue,
            currentDidacticielFirstParcel: currentDidacticielFirstParcel,
        });

        // ↓ lancement du didacticiel ↓
        if (showDidactiticielFirstParcel === true) {
            dispatch( ActionStartDidacticielFirstParcel(currentStep) );
        }
}

/**
 * Action permettant de démarrer le didacticiel (redirection url + première étape)
 */
export const ActionStartDidacticielFirstParcel = (stepToGo = StepEnumDidacticielFirstParcel.BIENVENUE)  => (dispatch, getState) =>  {

    dispatch( ActionUnselectParcel() ); // déselection d'une parcelle dans le cadre du lancement du didacticiel lorsque l'utilisateur a déjà des parcelles et qu'une sélection est en cours
    dispatch( ActionGoToStepForDidacticielFirstParcel(stepToGo) ); // on place le didacticiel à l'étape voulu
    dispatch( ActionGoToDidacticielFirstParcel() ); // on bascule directement sur le didacticiel - url
}

/**
 * Action permettant l'appel à l'API pour obtenir les infos sur le didacticiel FirstParcel du client
 */
export const ActionGetCurrentStepDidacticielFirstParcelAsk = (clientId)  => (dispatch) =>  {
        didacticielFirstparcelWebApiProvider.getCurrentStepTutorialFirstParcel(clientId)
            .then((currentStepTurorialFirstParcelSaved) => {
                dispatch( ActionGoToStepDidacticielFirstParcel(currentStepTurorialFirstParcelSaved.currentStep) );
                return currentStepTurorialFirstParcelSaved;
            })
            .catch((error) => {
                //Trace Azure:
                sendError('ActionGetCurrentStepDidacticielFirstParcelAsk - getCurrentStepTutorialFirstParcel', error);
            });
}

/**
 * Action permettant de sauvegarder l'étape en cours à la fois en BDD et ensuite au niveau du reducer
 */
export const ActionGoToStepForDidacticielFirstParcel = (stepToGo) => (dispatch) => {
        didacticielFirstparcelWebApiProvider.saveCurrentStepTutorialFirstParcel(stepToGo);
        dispatch( ActionGoToStepDidacticielFirstParcel(stepToGo) ); // on n'attend pas le retour de l'enregistrement en BDD => meilleure expérience pour un utilisateur ayant une faible connexion internet
        return stepToGo;
}