import React from 'react';
import { select as d3Select, geoMercator as d3GeoMercator, geoPath as d3GeoPath } from 'd3';
import rewind from '@turf/rewind';
import converter from '../../utils/converter.js';
import lodashGet from 'lodash/get';


/* Fonction générant les deux informations nécessaires à la constitution d'un svg représentant le contour d'une parcelle: */
export function GeneratePathOfThumbnailParcel(parcel, index) { //entité reçu de la Web API...
    if (!parcel) {
        return undefined;
    }

    //console.log('GeneratePathOfThumbnailParcel()', parcel.id);

    if (parcel.geometryType === 'Polygon') {
        // récupération du geojson
        const geojson = converter.databaseParcelsTogeojson([parcel]);

        // on s'assure que le géojson est dans le sens clockwise (d3js)
        const geojsonRewinded = rewind(geojson, { reverse: true });

        // on récupère aussi l'info de culture courante:
        const culture = lodashGet(parcel, 'details.culture', null);

        // création du path en lien avec la projection
        const projection = d3GeoMercator().fitSize([28, 28], geojsonRewinded);
        const path = d3GeoPath(projection);

        //crée un faux svg pour récupérer le 'path' produit par 'd3GeoMercator'
        let pathValueBuilded = undefined;
        let svgElt = document.createElement('svg');
        try {
            svgElt.style = { with: '28px', height: '28px', };
            const resultBuildPath = d3Select(svgElt)
                .selectAll("path")
                .data(geojsonRewinded.features)
                .enter()
                .append("path")
                .attr("d", path);
            if (resultBuildPath && resultBuildPath.attr) {
                pathValueBuilded = resultBuildPath.attr('d');
            }
            if ( ((!pathValueBuilded) || (pathValueBuilded === '')) && 
                (svgElt.childElementCount > 0) ) {
                const pathElt = svgElt.children[0];
                if (pathElt && (pathElt.hasAttribute('d') === true)) {
                    pathValueBuilded = pathElt.getAttribute('d');
                }
            }
        }
        catch(errBuild) {
            console.log('errBuild', errBuild);
        }
        if (svgElt && svgElt.parentNode) {
            try {
                svgElt.parentNode.removeChild(svgElt);
            }
            catch(errRemoveChild) {
                console.log('errBuild - errRemoveChild', errRemoveChild);
            }

            svgElt = undefined;
        }

        return { 
            parcelId: parcel.id, 
            parcelName: (parcel && parcel.name && (parcel.name !== '')) ? parcel.name : `N°${index}`, 
            parcelCulture: culture,
            pathOfSvg: pathValueBuilded,
        };
    }
}

/**
 * composant - miniature représentant le polygône d'une parcelle 
 */
export function ThumbnailParcelShapeFromPathInfos(props) { //provenant de l'appel à 'GeneratePathOfThumbnailParcel' !

    return (props && props.parcelId && props.pathOfSvg) ? (
        <svg id={`tblSvg_${props.parcelId}`} 
            className="svg-thumbnail" width={33} height={28}>
            <path d={props.pathOfSvg} />
        </svg>
    ) : (undefined);
}
