import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';
import lodashGet from 'lodash/get';

/* Components MUI */
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, CardContent, CardHeader, Divider } from '@mui/material';

/* Icones */
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { ParcelsHelper } from '../../../utils/parcelsHelper.js';
import { ModulationsHelper } from '../../../utils/modulationsHelper.js';
import DateHelper from '../../../utils/dateHelper.js';

/* Redux */
import {
    ModulationStep,
    ActionGoToShowThisModulation,
    ActionClearModulationValue,
    ActionGenerateFileOfThisModulation,
    ActionBuildListPrescriptions,
    ActionGetAllModulations,
    ActionGoToNextStepOfModulation,
    ActionClearNewModulationCounter,
    ActionGetLastModulationSettings,
    ActionInitializePrescriptions
} from '../../../redux/actions/modulations';

import {
    ActionGoToModulation,
} from '../../../redux/actions/contextApp.js';

/* Theme Berry */
import { styled } from '@mui/material/styles';
import getTheme from "../../../themes/index.js";
import SubCard from "../../subLayouts/subCard";
const theme = getTheme();

const IconWrapper = styled('div')({
    transform: 'rotate(25deg)',
    '&> svg': {
        color: theme.palette.common.white,
        [theme.breakpoints.only('xs')]: {
            marginTop: '15%'
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: '17%'
        },
        width: '79px',
        height: '79px', 
        opacity: '0.35',
        position: "absolute",
        marginLeft: '-16px'
    }
});

///////////////////////////////////////////////////////////////////////////////////////
// fonction de rendu visuel pour la fonctionnalité widget d'une modultation de parcelle 
///////////////////////////////////////////////////////////////////////////////////////
function WidgetModulation(props) {
    const { clientId, getAllModulations } = props;


    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    useEffect(() => {
        if (getAllModulations && (clientId > 0)) {
            getAllModulations(clientId);
        }
    }, [clientId, getAllModulations]);

    const handleClick = (event) => {
        event.persist();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const showModulation = (event, modulationId) => {
        setAnchorEl(null);
        const { parcelIdSelected, showModulation, clearModulationValue, clientId } = props;

        const modulation = {
            parcelId: parcelIdSelected,
            clientId: clientId,
            id: modulationId
        };

        //si une modulation a déjà été sélectionnée auparavant, vider les valeurs de la modulation :
        if (clearModulationValue) {
            clearModulationValue();
        }

        if (showModulation) {
            showModulation(modulation);
        }
    }

    const generatePdf = (modulationId) => {
        setAnchorEl(null);
        const { generateModulationFile, parcelIdSelected, clientId } = props;

        const modulation = {
            parcelId: parcelIdSelected,
            clientId: clientId,
            id: modulationId
        };

        if (generateModulationFile) {
            generateModulationFile(modulation);
        }
    }

    const downloadFile = (modulation, fileTypeDownloadChoiceType) => {

        setAnchorEl(null);

        const { downloadListPrescriptions } = props;

        let modulationToDownload = [];
        modulationToDownload.push(ModulationsHelper.convertItem(modulation));
        if (downloadListPrescriptions) {
            downloadListPrescriptions(modulationToDownload, fileTypeDownloadChoiceType); //1 : SHP, 2 : ISOXML, 3 : RDS
        }
    }

    const handleSelectModulationPageClick = (event, data) => {
        const {clearNewModulationCounter, clientId, initializePrescriptions, rememberModulationSettings, getLastModulationSettings, goToModulation, goToNextStepOfModulation } = props;
        //clearNewBiomassCounter();
        if (window.dataLayer) // google tag manager
            window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'modulationPage' });

        clearNewModulationCounter();

        // puis, on passe à la première étape de la génération d'une modulation (pour la première parcelle parmis celles sélectionnées):
        let readableMode = false;

        //Le client a demandé auparavant de mettre par défaut la dose moyenne, la teneur, les paramètres de la dernière modulation enregistrée
        if (rememberModulationSettings === true) {
            getLastModulationSettings(clientId);
        }

        //Initialiser les prescriptions à [] (allPrescriptions),
        initializePrescriptions();
        let newParcelIdsSelected = [data];
        
        if (goToModulation) {
            goToNextStepOfModulation(ModulationStep.MAP, newParcelIdsSelected, readableMode);
            goToModulation(true, newParcelIdsSelected, false );
        }
    };

    const getContent = () => {
        const { parcelDico, parcelIdSelected, modulationDico } = props;

        if (parcelIdSelected <= 0) return;

        // //↓ données parcels (conséquence => nouveau rendu) ↓
        let parcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelIdSelected);
        if (!parcel) return;

        let modulation = undefined;

        if (modulationDico !== undefined) {
            for (const key in modulationDico) {
                const modulItem = modulationDico[key];
                if (modulItem && (modulItem.parcelId === parcelIdSelected)) {
                    modulation = modulItem;
                }
            }
        }

        if (modulation !== undefined) {
            return (
                <>
                    <CardHeader
                        action={
                            <IconButton aria-label="settings" onClick={(event) => handleClick(event)}>
                                <MoreHorizIcon />
                            </IconButton>
                        }
                        title={StringTranslate.mymodulation}
                    />
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                            'background-color': 'rgb(50, 50, 50)'
                        }}
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                        keepMounted
                    >
                        <MenuItem key={'menu_generate_pdf'} onClick={() => generatePdf(modulation.id)}>
                            <FileDownloadIcon color="action" /><Typography variant="subtitle2">{StringTranslate.downloadModulPdf}</Typography>
                        </MenuItem>
                        <MenuItem key={'menu_download_file1'} onClick={() => downloadFile(modulation, 1)}>
                            <FileDownloadIcon color="action" /><Typography variant="subtitle2">{StringTranslate.downloadModulShp}</Typography>
                        </MenuItem>
                        <MenuItem key={'menu_download_file2'} onClick={() => downloadFile(modulation, 2)}>
                            <FileDownloadIcon color="action" /><Typography variant="subtitle2">{StringTranslate.downloadModulIso}</Typography>
                        </MenuItem>
                        <MenuItem key={'menu_download_file3'} onClick={() => downloadFile(modulation, 3)}>
                            <FileDownloadIcon color="action" /><Typography variant="subtitle2">{StringTranslate.downloadModulRds}</Typography>
                        </MenuItem>
                    </Menu>

                    < Divider />

                    <CardContent>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <TableContainer >
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow key="row head">
                                                {/* ↓ cellules concernant le nom de la parcelle ↓ */}
                                                <TableCell><Typography fontWeight='bold'>{StringTranslate.ColumnMdlDate}</Typography></TableCell>
                                                <TableCell><Typography fontWeight='bold'>Kg/Ha</Typography></TableCell>
                                                <TableCell><Typography fontWeight='bold'>{StringTranslate.ColumnContentRate}</Typography></TableCell>
                                                <TableCell><Typography fontWeight='bold'>{StringTranslate.supplyType}</Typography></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={modulation.id} onClick={(event) => showModulation(event, modulation.id)} hover>
                                                <TableCell>{DateHelper.formati18n(new Date(modulation.dateImagSource), 'P')}</TableCell>
                                                <TableCell>{modulation.doseNominal <= 0 ? modulation.doseMedium : modulation.doseNominal}</TableCell>
                                                <TableCell>{modulation.content}</TableCell>
                                                <TableCell>{modulation.supplyType === 1 ? StringTranslate.supplyTypeLiquid : StringTranslate.supplyTypeSolid}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </CardContent>
                </>
            );

        }
        else if (modulationDico === undefined) {
            return (
                <CardContent>
                    <Grid container spacing={0}>
                        <Grid item xs={10}>
                            <Typography variant="body1" gutterBottom style={{ textAlign: "center" }}>{StringTranslate.loadingModulsRecords}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <CircularProgress size={20} />
                        </Grid>
                    </Grid>
                </CardContent>
            );
        }
        else {
            return (
                <CardContent sx={{ backgroundColor: theme.palette.grey[300] }}>
                    <IconWrapper><InfoOutlinedIcon /></IconWrapper>
                    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={0}>

                        <Grid item xs={12}>
                            <Button
                                aria-label="show-infos"
                                color="primary"
                                variant="contained"
                                onClick={(event) => {handleSelectModulationPageClick(event, parcelIdSelected)}}
                            >
                                {StringTranslate.newModulationWidget}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            );
        }
    }

    return (
        <SubCard title={false} content={false}
        sx={{ zIndex: "1100" }}>
            {getContent()}
        </SubCard>
    );
}


/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = (state) => {
    return {
        parcelIdSelected: lodashGet(state, 'contextAppData.parcelIdSelected', -1),
        parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
        modulationDicoLoading: lodashGet(state, 'modulationsData.loading', false),
        modulationDico: lodashGet(state, 'modulationsData.modulationDico', {}),
        modulationDicoCounter: lodashGet(state, 'modulationsData.modulationDicoCounter', 0),
        clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1),
        settings: lodashGet(state, 'settingsData.settings', {}), // seul moyen trouvé pour rafraichir le visuel (traduction) du fait que maintenant on rentre dans l'application sans attendre les informations du client
    };
}

const mapDispatchToProps = dispatch => ({
    showModulation: (modulationSelectedItem) => dispatch( ActionGoToShowThisModulation(modulationSelectedItem, true) ),
    clearModulationValue: () => dispatch( ActionClearModulationValue() ),
    generateModulationFile: (modulationSelectedItem) => dispatch( ActionGenerateFileOfThisModulation(modulationSelectedItem) ),
    downloadListPrescriptions: (modulationParameterList, exportFormat) => dispatch( ActionBuildListPrescriptions(modulationParameterList, exportFormat) ),
    getAllModulations: (clientId) => dispatch( ActionGetAllModulations(clientId) ),
    getLastModulationSettings: (clientId) => dispatch(ActionGetLastModulationSettings(clientId)),
    initializePrescriptions: () => dispatch(ActionInitializePrescriptions()),
    goToNextStepOfModulation: (nextStepOfModulation, parcelIdsSelected, readableMode, modulationSelectedItemValue = undefined) =>
        dispatch(ActionGoToNextStepOfModulation(nextStepOfModulation, parcelIdsSelected, readableMode, modulationSelectedItemValue)),
    clearNewModulationCounter: () => dispatch(ActionClearNewModulationCounter()),
    goToModulation: () => dispatch(ActionGoToModulation(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WidgetModulation);