import sendError from './errorService.js';


// fichier permettant de faire de la manipulation et des opérations en rapprt avec des nombres (entier ou réel)
const numberHelper = {
    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de limiter le nombre de décimal d'un nombre réel
    // RQ: là surtout pour gérer une variable valant 'undefined'
    // (par défaut, définis à deux décimales)
    ///////////////////////////////////////////////////////////////////////////
    fixeDecimal(number, maxDecimal = 2) {
        if ((number === undefined) || (number === null) || (number === '')) return number;
        if (number === 0) return 0;

        let result = 0;
        try {
            if ((typeof(number) === 'string')) {
                result = parseFloat(number, 10);
            } else {
                result = number;
            }

            result = result.toFixed(maxDecimal);
        }
        catch(e) {
            result = 0;

            if (sendError) {
                sendError('numberHelper-fixeDecimal', { "e": e, "number": number, "maxDecimal": maxDecimal, });
            }
        }

        return result;
    },
    
    testDataValid(dataValue) {
        if ((dataValue === undefined) || (dataValue === null) || (dataValue === '') || 
            (dataValue < 0)) {
            return false;
        }
        
        return true;
    },

    convertFromString(numberStr) {
        if ((numberStr === undefined) || (numberStr === '')) return 0;

        let result = 0;
        try {
            result = parseFloat(numberStr);
        }
        catch(e) {
            result = 0;

            if (sendError) {
                sendError('numberHelper-convertFromString', { "e": e, "numberStr": numberStr, });
            }
        }

        return result;
    },
}

export default numberHelper;
