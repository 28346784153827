///////////////////////////////////////////////////////////////////////////
// Import des éléments utilisés par le composant <Content>
///////////////////////////////////////////////////////////////////////////
import React, { Component } from "react";
import 'react-id-swiper/src/styles/css/swiper.css';
import "../assets/css/imageSwiper.css";
import { es, fr, enUS } from 'date-fns/locale';
import shortenedDateFormat from "../utils/shortenedDateFormat";

// Mui
import { CircularProgress, Typography, Card, Badge, Grid, Stack, Box } from "@mui/material";
import { History } from "@mui/icons-material";

import StringTranslate from '../assets/i18n/stringLanguage.jsx'

import ConstantsProvidersSatellite from '../utils/constantsProvidersSatellite.js';

import getTheme from "../themes/index.js";
import ImagSwiperSlide from "./subLayouts/imagSwiperSlide.jsx";
import SlideRangeMarker from "./biomasse/SlideRangeMarker";

const theme = getTheme(); /* Permet d'obtenir le thme de berry */

/**
 * @class ImageSlide
 * Composant permettant de gérer l'affichage des slides d'images
 * @param {Object} satimage - Objet représentant une image satellite
 * @param {selected} selected - Booléen indiquant si l'image est sélectionnée
 * @param {"OUT_OF_RANGE" | "IN_EXTRA_RANGE" | "IN_RANGE"} range - Indique si l'image est dans la zone de recherche
 */
export default class ImageSlide extends Component {
    /**
     * Permet de récupérer les styles à appliquer au composant
     * @returns {Object} - Objet contenant les styles à appliquer au composant
     */
    getTheme = () => {
        const { selected } = this.props;

        return {
            backgroundColor: selected ? theme.palette.primary[200] : theme.palette.primary.light,
            border: 1,
            borderColor: selected ? theme.palette.primary.main : theme.palette.primary[200],
            borderWidth: 1,
            pointerEvents: "all",
            position: 'relative',
        };
    }

    /**
     * Permet de traduire les dates qui se trouvent sous les images
     * @returns {Object} - Objet contenant les traductions
     */
    getLanguage = () => {
        let currentLanguage = fr;
        if (StringTranslate) {
            const valLanguage = StringTranslate.getLanguage();
            if (valLanguage === "en-GB" || valLanguage === "en-US") {
                currentLanguage = enUS;
            }
            if (valLanguage === "es-ES") {
                currentLanguage = es;
            }
        }
        return currentLanguage;
    }

    /**
     * Permet de récupérer le composant à afficher
     * @returns {JSX.Element} - Retourne le composant à afficher
     */
    getCard = () => {
        const { satimage, progress, range } = this.props;

        // ce n'est pas censé arrivé
        if (!satimage && !progress) return null;

        let imagNode = undefined;
        let dateFormated = undefined;

        if (satimage.azureNdviSmallURL && satimage.date) {
            imagNode = <img src={satimage.azureNdviSmallURL} className="is-slide-img" alt="" />;
            let dateValue = (satimage.date instanceof Date) ? satimage.date : new Date(satimage.date);
            dateFormated = shortenedDateFormat(dateValue, this.getLanguage());

            return (
                <Card
                    sx={this.getTheme()}
                    className="is-slide-content"
                    id={`slide-${satimage.id}`}
                >
                    {imagNode}
                    {
                        dateFormated && (
                            <Box
                                sx={{
                                    lineHeight: "0px",
                                    textAlign: "left",
                                    position: "absolute",
                                    top: "92%",
                                    left: "62%",
                                    transform: "translate(-50%, -50%)",
                                    width: "100%",
                                    direction: "initial"
                                }}
                            >
                                <Grid container>
                                    <Stack direction={'row'} alignItems={'center'}>
                                        {range && (
                                            <Box sx={{ transform: "translateX(-50%)" }}>
                                                <SlideRangeMarker label={range} />
                                            </Box>
                                        )}
                                        <Typography color="primary" variant="body2">{dateFormated}</Typography>
                                    </Stack>
                                </Grid>
                            </Box>
                        )
                    }
                </Card>
            )

        } else {
            return (
                <Card
                    sx={this.getTheme()}
                    className="is-slide-content"
                    id={`slide-${satimage.id}`}
                >
                    <ImagSwiperSlide content={<History color="primary" />} />
                </Card>
            )
        }
    }

    /**
     * Permet de gérer le clic sur le composant
     */
    handleOnClick = () => {
        const { onClick } = this.props;

        if (onClick && typeof onClick === 'function') onClick();
    }

    render() {
        const { satimage } = this.props;

        return (
            <Box
                className="swiper-slide"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative"
                }}
                id={satimage.id}
            >
                <Box className="is-slide-wrapper">
                    {satimage && satimage.sourceProvider === ConstantsProvidersSatellite.SatImageSource.Modelisation ? (
                        <Badge
                            sx={{
                                display: "contents",
                                '& .MuiBadge-badge': {
                                    left: 13,
                                    top: 9,
                                    padding: '0px',
                                    border: '1.5px solid rgba(255, 255, 255, .8)',
                                    backgroundColor: theme.palette.secondary.main
                                },
                            }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            badgeContent={<Typography sx={{ fontSize: '1rem', color: theme.palette.common.white }}>{ConstantsProvidersSatellite.BadgeModelisation}</Typography>}
                        >
                            {this.getCard()}
                        </Badge>
                    ) : (
                        this.getCard()
                    )}
                </Box>
            </Box>
        );
    }
}

/**
 * @class ProgressSlide
 * @description Composant slide de progression
 */
export class ProgressSlide extends Component {
    render() {
        return (
            <div className="swiper-slide">
                <div className="is-slide-wrapper">
                    <Card
                        sx={{
                            backgroundColor: theme.palette.primary.light,
                            border: 1,
                            borderColor: theme.palette.primary[200],
                            borderWidth: 1,
                            pointerEvents: "all",
                            position: 'relative',
                        }}
                        className="is-slide-content"
                    >
                        <ImagSwiperSlide content={<CircularProgress color="primary" />} />
                    </Card>
                </div>
            </div>
        );
    }
}