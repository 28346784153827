import StringTranslate from './stringLanguage.jsx';


//Définition du bouton de dessin de parcelle (suivant le statut):
const leafletfunction = () => {
	return {
		draw: {
			toolbar: {
				actions: {
					title: 'Annuler le dessin d\'une parcelle',
					text: `${StringTranslate.annulerimport}`
				},
				finish: {
					title: 'Terminer le dessin d\'une parcelle',
					text: `${StringTranslate.terminer}`
				},
				undo: {
					title: 'Supprimer le dernier point',
					text: `${StringTranslate.supppoint}`
				},
				buttons: {
					polyline: 'Draw a polyline',
					polygon: `${StringTranslate.dessinerparcelle}`,
					rectangle: 'Draw a rectangle',
					circle: 'Draw a circle',
					marker: 'Draw a marker',
					circlemarker: 'Draw a circlemarker'
				}
			},
			handlers: {
				circle: {
					tooltip: {
						start: 'Click and drag to draw circle.'
					},
					radius: 'Radius'
				},
				circlemarker: {
					tooltip: {
						start: 'Click map to place circle marker.'
					}
				},
				marker: {
					tooltip: {
						start: `${StringTranslate.placemarker}`,
					}
				},
				polygon: {
					tooltip: {
						start: `${StringTranslate.start}`,
						cont: `${StringTranslate.cont}`,
						end: `${StringTranslate.end}`
					}
				},
				polyline: {
					error: '<strong>Error:</strong> shape edges cannot cross!',
					tooltip: {
						start: 'Click to start drawing line.',
						cont: 'Click to continue drawing line.',
						end: 'Click last point to finish line.'
					}
				},
				rectangle: {
					tooltip: {
						start: 'Click and drag to draw rectangle.'
					}
				},
				simpleshape: {
					tooltip: {
						end: 'Release mouse to finish drawing.'
					}
				}
			}
		},
		edit: {
			toolbar: {
				actions: {
					save: {
						title: 'Sauvegarder les modifications',
						text: 'Sauvegarder'
					},
					cancel: {
						title: 'Annuler l\'édition, toute modification sera perdue',
						text: 'Annuler'
					},
					clearAll: {
						title: 'Supprimer toutes les parcelles',
						text: 'Tout supprimer'
					}
				},
				buttons: {
					edit: 'Modifier la parcelle',
					editDisabled: 'Aucune parcelle à modifier',
					remove: 'Supprimer la parcelle',
					removeDisabled: 'Aucune parcelle à supprimer'
				}
			},
			handlers: {
				edit: {
					tooltip: {
						text: 'Drag handles or markers to edit features.',
						subtext: 'Click cancel to undo changes.'
					}
				},
				remove: {
					tooltip: {
						text: 'Click on a feature to remove.'
					}
				}
			}
		}
	}
};

export default leafletfunction;