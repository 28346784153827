//nom des applications externes d'où peuvent provenir les imports parcellaire 
const externalApplicationName = {
    geofolia: "geofolia",
    telepac: "telepac",
    mesparcelles: "mesparcelles",
    autre: "autre",
};

export default externalApplicationName;

export function getListExternalApplicationName() {
    let list = [];
    for (const key in externalApplicationName) {
        list.push(externalApplicationName[key]);  
    }
    return list;
};