import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


function IconeGeofolia(props: SvgIconProps) {
    return ( 
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" strokeWidth="1.8" stroke="none" fill="current">
              <path d="M16,.76A15.19,15.19,0,1,1,.77,16,15.18,15.18,0,0,1,16,.76" fill="#fff"/>
              <path d="M15.91.73A15.19,15.19,0,1,1,.73,15.92,15.19,15.19,0,0,1,15.91.73Z" fill="none" stroke="#d9d9d9" strokeLinejoin='round' strokeWidth="0.943344607144575px"/>
              <g>
                <path d="M7.14,15.94c-.54-2.25.47-5.79,8.62-7.93a20.14,20.14,0,0,1-.07,3.93c1.42.08,1.59,3.39,1.59,3.39a22.38,22.38,0,0,0,2-11.93C5.51,7,4.83,13.25,6.12,16.64A7,7,0,0,0,7.14,15.94Z" fill="#5bab26"/>
                <circle cx="9.83" cy="22.1" r="1.87" fill="#eee"/>
                <circle cx="23.28" cy="23.22" r="1.87" fill="#eee"/>
                <path d="M14.09,21.94l-.42-.12a1,1,0,0,0,.19-1.09.75.75,0,0,1-.36,0s.43-.47-.25-1.18a.82.82,0,0,0-.29.14,1.07,1.07,0,0,0-.6-1.07,2.17,2.17,0,0,1-.33.25s.22-.6-.81-.89l-.17.33S11,17.84,10,17.82l0,.36s-.27-.44-1.22-.27l.06.42a1,1,0,0,0-1.26.17l.22.34s-.53-.36-1.12.43a1.44,1.44,0,0,0,.26.28,1,1,0,0,0-1,.79l.3.19s-.57.07-.62,1l.36.05a1.08,1.08,0,0,0-.3,1.24H6s-.46.32,0,1.18a1,1,0,0,1,.33,0,1,1,0,0,0,.45,1.06,1.19,1.19,0,0,0,.24-.2s-.06.56.78.91a1.14,1.14,0,0,0,.2-.23,1,1,0,0,0,1,.69L9.11,26s.25.59,1.11.38a1.3,1.3,0,0,0,.06-.39.94.94,0,0,0,1.17.06V25.6s.45.35,1.12-.29l-.18-.37a.82.82,0,0,0,1-.57l-.23-.26a.84.84,0,0,0,.71-.84l-.26-.17A.93.93,0,0,0,14.09,21.94Zm-4.3,1.74a1.6,1.6,0,1,1,1.59-1.6A1.6,1.6,0,0,1,9.79,23.68Z" fill="#aaa"/>
                <path d="M26.44,23.11,26.13,23a.75.75,0,0,0,.14-.8.56.56,0,0,1-.26,0s.31-.35-.18-.87a.57.57,0,0,0-.21.11.8.8,0,0,0-.45-.79l-.24.19s.16-.45-.6-.66l-.12.24s-.07-.38-.8-.39l0,.27s-.2-.33-.9-.2l.05.3a.76.76,0,0,0-.92.13l.15.25s-.39-.26-.82.32a.84.84,0,0,0,.19.2.76.76,0,0,0-.74.58l.22.14s-.42.06-.45.77l.26.05a.77.77,0,0,0-.22.91h.26s-.34.23,0,.87a1,1,0,0,1,.25,0,.76.76,0,0,0,.33.79,1,1,0,0,0,.17-.15s0,.41.58.67A.82.82,0,0,0,22,25.8a.73.73,0,0,0,.71.5l.12-.23s.18.44.81.28a.87.87,0,0,0,.05-.29.68.68,0,0,0,.86,0v-.29s.33.25.83-.21l-.14-.28a.61.61,0,0,0,.76-.42l-.17-.19a.62.62,0,0,0,.52-.62L26.1,24A.69.69,0,0,0,26.44,23.11Zm-3.16,1.43a1.33,1.33,0,1,1,1.32-1.32A1.32,1.32,0,0,1,23.28,24.54Z" fill="#aaa"/>
                <path d="M24.09,19.8l-.36-1.62a7.8,7.8,0,0,0-3.5-1c-2.18-.12-3.95-.16-3.95-.16v4.11l0,3.13,3.43-.16a4,4,0,0,1,1.12-3.6A3,3,0,0,1,24.09,19.8Z" fill="#e2001a"/>
                <path d="M14.84,24.34l-.07-.24A15.75,15.75,0,0,1,14.32,22c-.47-3-1.85-4.47-4.35-4.47a4.92,4.92,0,0,0-3.69,1.55.32.32,0,0,1-.5-.41A5.2,5.2,0,0,1,8,17.2a11.49,11.49,0,0,1,.31-2.64c.25-.88.66-1.93,1.24-1.93l2.2.06,3.31.1c.74,0,1.2,1.23,1.33,2.37s.2,4.45.2,4.59v4.51ZM10,16.88c2.84,0,4.47,1.64,5,5a16.24,16.24,0,0,0,.36,1.79l.64,0V19.76s-.08-3.4-.2-4.53-.55-1.77-.69-1.79h0l-3.33-.1-2.18-.07a4.25,4.25,0,0,0-.63,1.47A10.34,10.34,0,0,0,8.66,17,5.91,5.91,0,0,1,10,16.88Z" fill="#e2001a"/>
                <path d="M11.86,17.66a.32.32,0,0,1-.32-.32V13a.32.32,0,0,1,.64,0v4.32A.32.32,0,0,1,11.86,17.66Z" fill="#e2001a"/>
                <path d="M14.05,20.08a2.53,2.53,0,0,0,2.74,0l-.48,3.86-1.17,0Z" fill="#e2001a"/>
              </g>
            </svg>
        </SvgIcon>
    );
}

export default IconeGeofolia;