import React, { Component, createRef } from 'react';
import { DateCalendar } from '@mui/x-date-pickers';
import { PickersDay } from '@mui/x-date-pickers';
import format from 'date-fns/format';
import { Badge, Dialog, Button, Tooltip, Typography, Grid, List, ListItem,ListItemIcon, ListItemText,
				 DialogTitle, IconButton, Divider, Stack } from '@mui/material';  

import {Close as CloseIcon, Cancel as CancelIcon, Cloud as CloudIcon, CalendarToday} from '@mui/icons-material';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import frLocale from "date-fns/locale/fr";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-GB";
/* Traduction */
import StringTranslate from '../assets/i18n/stringLanguage.jsx';
import { MIN_DATE_SENTINEL } from '../redux/actions/satImage.js';
import lodashGet from 'lodash/get';
import ConstantsProvidersSatellite from '../utils/constantsProvidersSatellite.js';
import checkDateAccuracy from '../utils/checkDateAccuracy.js'
import { ReducerSatimageHelper } from '../utils/reducerSatimageHelper.js';
import { SatimageDatasParcelHelper } from '../utils/satimageDatasParcelHelper.js';
import sendError from '../utils/errorService.js';
import { /*StateYearMonthOfSatimages,*/ SatimageState } from "../models/stateYearMonthOfSatimages";
import StateCalendar from '../components/stateCalendar';
import dateHelper from '../utils/dateHelper';

import { 
	ActionCloseCalendarDialog, ActionGoToModelisation, ActionShowCalendarDialog,
	ActionSelectDateImgFromDate
} from '../redux/actions/contextApp.js';
import {
	ActionAskOldHistoForSelectedParcel,
	ActionGetHistoForParcelByMonthYear,
	ActionAskFirstHistoForSelectedParcel
} from '../redux/actions/satImage.js'; 
import { ActionTypeParcels } from '../redux/actions/parcels.js';

import getTheme from "../themes/index.js";
import { connect } from 'react-redux';

let theme = getTheme();
const TODAY_DATE_CALENDAR = new Date();
const calendarInputDefaultValue = "--/--/----";

// Disatnce Minimal que le doigt doit parcourir pour considerer que le client a swiper
const minSwipeDistance = 50;

class CustomCalendar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			yearShowed: undefined,
			monthShowed: undefined,

			// Vue sur laquelle le calendrier est ouvert (openTo est la props du Datepicker permettant de définir cela)
            actualView: /* props.openTo ?? */'day',

            touchPositionStart: null, // Position de départ sur l'axe horizontal lorsque le client touche son écran
            touchPositionEnd: null, // Position de fin sur l'axe horizontal  lorsque le client touche son écran
		};

		this.handleClose = this.handleClose.bind(this);
		this.handleGoToModelisation = this.handleGoToModelisation.bind(this);
		this.customDay = this.customDay.bind(this);
		this.disabledDates = this.disabledDates.bind(this);
		this.handleChangeDate = this.handleChangeDate.bind(this);
		this.includeDates = [];// données permettant de connaitre la liste des dates des images d'historique valides (suivant des valeurs de maxcc <= à la valeur de référence maxcc)
		this.newerDate = TODAY_DATE_CALENDAR;
		this.currentStateYearMonth = SatimageState.stateNotAsk;
		this.isNewerDateForSelectedParcel = true; //par défaut, on se toruve à la date de l'image valide la plus récente
		this.satImageWithLowerOrEqualMaxccList = []; //Liste des images valides
		this.satImageWithHigherMaxccList = []; //Liste des images non valides (ennuagé, pas dispo ...)
		this.localeMap = undefined;
		this.dateFormatedByLanguage = calendarInputDefaultValue
		this.readOnlyValue = false;

		// reférence des flèches pour le changement de mois du calendrier
        this.refNextButton = createRef();
        this.refPreviousButton = createRef();
	}

	/**
	 * 
	 * @param {*} day le nouveau jour sélectionné
	 * 
	 * permet d'enregistré dans redux le nouveau jour sélectionné et de le montrer sur la carto
	 * et fermer le calendrier
	 */
	handleChangeDate(day,selectionState) { //TODO: faut il reprendre des choses de 'handleInputClick' ?
		const { hasParcelSelected, parcelIdSelected } = this.props; //propriétés provenant de la décoration Redux !

		//selectionState : indique si la sélection de la date est complète. 'Partial' , 'finish' ou undefined
		//Pas besoin de continuer si = partial car la date n'est pas complete.
		if(selectionState !== 'partial')
		{
			let imageData = undefined;  // on met les infos de l'image du jour la dedans
	
			if(this.satImageWithLowerOrEqualMaxccList !== undefined)
				imageData = this.satImageWithLowerOrEqualMaxccList.find(satImage => checkDateAccuracy(day, satImage));
	
			if ((imageData !== undefined) && hasParcelSelected && this.props.selectSatimageByDate) {
				this.props.selectSatimageByDate(day,parcelIdSelected);
				this.props.close();
			}
		}
	}

	/**
	 * 
	 * @param {*} day est passé automatiquement par le calendar date
	 * @returns un bool true: la date est pas cliquable, false elle l'est.
	 * Dans cette fonction uniquement les dates avec des images sont cliquables.
	 * ces fonction de tri sont un héritage directe de la fonction calendrier.jsx
	 */
	disabledDates = (day) => {
		let imageData = undefined;  // on met les infos de l'image du jour la dedans

		//Si la liste des images valides a été affectée alors , on assigne imagedata. On recherche dans la liste d'image une valeur qui corrspond au filtre contenu dans find().
		if(this.satImageWithLowerOrEqualMaxccList !== undefined)
			imageData = this.satImageWithLowerOrEqualMaxccList.find(satImage => checkDateAccuracy(day, satImage));

		return imageData === undefined;
	}

	/**
	 * 
	 * @param {*} props les props sont automatiquement passé par le calendrier, 3 sont importants ici: day, outsideCurrentMonth et selected
	 * @returns le visuel de la date
	 * 
	 * les fonctions de tri sont un héritage de calendrier.jsx
	 * 
	 */
	customDay = (props) => {
		const { day, outsideCurrentMonth, ...other } = props;

		// ↓ est-ce que la date recherchée est en lien avec une date d'une entité satImage ?  avec un taux d'ennuagement i(nférieure ou égale) ou supérieure au maxCC ? ↓
		let isDateMaxccLowerOrEqual = false;
		let isDateMaxccHigher = false;
		let tooltipText = ''; // info bulle => récupération du taux d'ennuagement
		let satimageSourceProvider = undefined;
		let satIsOffBeat = undefined;
		let imageData = undefined;  // on met les infos de l'image du jour la dedans
		let dataOrigin = 0; // 0 => rien; 1 => vient de satImageWithLowerOrEqualMaxccList; 2 => vient de satImageWithHigherMaxccList;
	
		
		if(this.satImageWithLowerOrEqualMaxccList !== undefined)
			imageData = this.satImageWithLowerOrEqualMaxccList.find(satImage => checkDateAccuracy(day, satImage));

		//Si imageData est affectée, alors on affecte dataorigin sinon on cherche a assignée imageData.
		//On assigne imageData en recherchant une valeur, dans la liste des images non valide , qui correspond également au filtre contenue dans la méthode find().
		if (imageData !== undefined) {
				dataOrigin = 1;
		} else {
			if(this.satImageWithHigherMaxccList !== undefined)
				imageData = this.satImageWithHigherMaxccList.find(satImage => checkDateAccuracy(day, satImage))
				
				if (imageData !== undefined) {
						dataOrigin = 2;
				}
		}

		//Si imageData est affectée et que dataOrigin l'est aussi , alors on assigne la source de l'image (sentinel, landsat ou modéliser).
		//Sinon , on regarde si le satellite est 
		if (imageData !== undefined) {
				tooltipText = (imageData.maxcc >= 0) ? StringTranslate.formatString(StringTranslate.tauxennuagement, Math.round(imageData.maxcc)) : '';
				if (dataOrigin === 1) {  /* => images issues de satImageWithLowerOrEqualMaxccList*/
						isDateMaxccLowerOrEqual = true;

						if (imageData.sourceProvider === ConstantsProvidersSatellite.SatImageSource.SentinelL2) {
								satimageSourceProvider = ConstantsProvidersSatellite.SatImageSource.SentinelL2;
						}
						else if (imageData.sourceProvider === ConstantsProvidersSatellite.SatImageSource.LandSat8) {
								satimageSourceProvider = ConstantsProvidersSatellite.SatImageSource.LandSat8;
						}
						else if (imageData.sourceProvider === ConstantsProvidersSatellite.SatImageSource.Modelisation) {
								satimageSourceProvider = ConstantsProvidersSatellite.SatImageSource.Modelisation;
						}
						else {
								satimageSourceProvider = undefined;
						}

				} else if (dataOrigin === 2) {  /* => images issues de satImageWithHigherMaxccList*/
						isDateMaxccHigher = true;

						if (imageData.isOffbeat === true) {
								satIsOffBeat = true;
						} else if (imageData.isOffbeat === false) {
								satIsOffBeat = false;
						} else {
								satIsOffBeat = true;
						}
				}
		}

		// theBadgeContent est créer afin de ne pas mettre plein de si dans le visuel,
		// il contient la lettre du badge ou son icone

		let theBadgeContent = "";

		//On affecte le contenu du badge de l'image. si l'image valide on regarde sa source sinon on regarde si l'image est ennuagée ou annulée
		if (isDateMaxccLowerOrEqual) {      
			switch (satimageSourceProvider) {
				case ConstantsProvidersSatellite.SatImageSource.SentinelL2 :
					theBadgeContent = <Typography sx={{fontSize:'0.65rem', color: theme.palette.common.white}}>S</Typography>;
					break;
				case ConstantsProvidersSatellite.SatImageSource.LandSat8 :
					theBadgeContent = <Typography sx={{fontSize:'0.65rem', color: theme.palette.common.white}}>L</Typography>;
					break;
				case ConstantsProvidersSatellite.SatImageSource.Modelisation :
					theBadgeContent = <Typography sx={{fontSize:'0.65rem', color: theme.palette.common.white}}>M</Typography>;
					break;
				default : 
				theBadgeContent = "";
			};
		} else if (isDateMaxccHigher) {
			if (satIsOffBeat) {
				theBadgeContent = <CancelIcon style={{ color: 'grey', width: '18px', height: '18px', opacity: 0.8 }}/>
			} else {
				theBadgeContent = <CloudIcon style={{ color: 'grey', width: '18px', height: '18px', opacity: 0.8 }}/>
			}
		}

		// amIInvisible est un bool qui est vrai quand on ne veux pas afficher le badge et false si on veux l'afficher.
		// elle est créer afin de ne pas encombrer le <Badge></Badge>
		// dans ce cas on ne veux voir des badges que sur les jours ou il y a des images et qui sont dans le mois en cours
		const amIInvisible = ((isDateMaxccLowerOrEqual === false) && (isDateMaxccHigher === false)) || outsideCurrentMonth;

		return ( 
			<Tooltip title={tooltipText} placement="right">
				<Badge
					overlap="circular"
					badgeContent={theBadgeContent}    // donne le contenu du badge
					invisible={amIInvisible}          // permet de rendre invisible le badge

					// sx => on l'utilise ici pour controller l'aspect du badge quand c'est une lettre dedans (donc quand les nuages sont en dessous du taux max accepté)
					sx={{
						'& .MuiBadge-badge':{
							...((isDateMaxccLowerOrEqual) && {                
								backgroundColor: theme.palette.secondary.main,
								color: theme.palette.common.white,
								border: `1.5px solid ${theme.palette.common.white}`
							})
						}
					}}
				>
					<PickersDay
						// PickersDay est l'element qui va controler l'aspect visuel de la date
						day={day}                                  // ici c'est l'élément qui met le numéro du jour
						disabled={amIInvisible}                    // ici c'est l'élément qui controle si le jour est cliquable, 
																											// dans notre cas on ne veux être capable de cliquer que sur les jours avec des clichés d'ou l'utilisation de amIInvisible
						outsideCurrentMonth={outsideCurrentMonth}  // permet de ne pas afficher les jours qui ne sont pas du mois en cours
						{...other}

						// sx =>
						// isDateMaxccLowerOrEqual: on veux du secondary clair pour les jours avec des images valides
						// isDateMaxccHigher: on veux du gris quand c'est un jours de nuage
						// other.selected: on veux du secondary main quand c'est le jour sélectionné
						sx={{
							"&:hover": {opacity: 0.8},
							...(isDateMaxccLowerOrEqual && {
								backgroundColor: theme.palette.secondary.light,
								"&:hover": {backgroundColor: theme.palette.secondary.light},
							}),
							...(isDateMaxccHigher && {
								backgroundColor: theme.palette.grey[200],
								"&:hover": {backgroundColor: theme.palette.grey[200]},
							}),
							...(other.selected && {
								backgroundColor: `${theme.palette.secondary.main} !important`,
							}),
						}}
					/>
				</Badge>
			</Tooltip>
		);
	}

	///////////////////////////////////////////////////////////////////////////
	// Fonction permettant d'inclure les dates contenant une image d'historique
	///////////////////////////////////////////////////////////////////////////
	setIncludeDates = (entityForThisParcel) => {  // type 'SatimageDatasParcel' !
				if (entityForThisParcel && entityForThisParcel.parcelId && Number.isInteger(entityForThisParcel.parcelId) && (entityForThisParcel.parcelId > 0)) {
						this.includeDates = SatimageDatasParcelHelper.selectAllDate(entityForThisParcel); //il faut récupérer les valeurs date de chaque objet SatImage (entité POCO C# 'ImageData')
						this.dateMaxccHigherList = SatimageDatasParcelHelper.selectAllDateWithHigherMaxcc(entityForThisParcel); //il faut récupérer les valeurs date de chaque objet SatImage (entité POCO C# 'ImageData')

						if (this.includeDates && Array.isArray(this.includeDates) && (this.includeDates.length > 0)) {
								this.highlightWithRanges = [
										{
												"react-datepicker__day--highlighted-maxcc-lowerorequal": this.includeDates
										},
										{
												"react-datepicker__day--highlighted-maxcc-higher": this.dateMaxccHigherList
										}
								];
								this.includeDatesCounter = this.includeDates.length;
								this.currentStateYearMonth = SatimageState.stateAskOk;
								this.newerDate = TODAY_DATE_CALENDAR; //sera mis à jour en temps voulu...
						}
						else {
								this.includeDates = [];
								this.dateMaxccHigherList = [];
								this.highlightWithRanges = [];
								this.includeDatesCounter = 0;
								this.currentStateYearMonth = SatimageState.stateAskNoDate;
								this.newerDate = TODAY_DATE_CALENDAR;
						}
				}
				else {
						this.includeDates = [];
						this.dateMaxccHigherList = [];
						this.highlightWithRanges = [];
						this.includeDatesCounter = 0;
						this.currentStateYearMonth = SatimageState.stateNotAsk;
						this.newerDate = TODAY_DATE_CALENDAR;
				}
		}

	selectedDateOfParcelSelected() {
			const { hasParcelSelected, parcelIdSelected, parcelDico, satimagesByParcelDico, satimageDateOfParcelSelected } = this.props; //propriétés provenant de la décoration Redux !
			const { yearShowed, monthShowed } = this.state;

			let currentDate = TODAY_DATE_CALENDAR;
			this.newerDate = TODAY_DATE_CALENDAR;
			this.currentStateYearMonth = SatimageState.stateNotAsk;
			if (hasParcelSelected) {
					//Recherche la date courament sélectionnée pour la parcelle en cours:
					if (parcelDico) {
						this.newerDate = lodashGet(parcelDico[parcelIdSelected], `firstSatimageDate`, undefined);
						const satimageDateSelected = lodashGet(parcelDico[parcelIdSelected], `currentSatimageDate`, undefined);
						if (satimageDateSelected) {
								currentDate = satimageDateSelected;
						} else if (satimageDateOfParcelSelected) {
								currentDate = satimageDateOfParcelSelected;//satimageIdOfParcelSelected également dispo ....
						}//@@On pourrai même en dernier recours, tenter d'utiliser l'id d'image sélectionnée, PUIS A DEFAUT, Sélectionner la première date d'image...

						this.isNewerDateForSelectedParcel = dateHelper.isSameDateDDMMYYYY(currentDate, this.newerDate);
					}

					//Recherche l'état de 'chargement des images', pour l'année/mois en cours de visualisation, pour la parcelle en cours:
					//(A défaut, celui de la date courament sélectionnée)
					let year = yearShowed;
					let month = monthShowed;
					if (((!year) || (!month)) && (currentDate)) {
							year = currentDate.getUTCFullYear();
							month = currentDate.getUTCMonth() + 1;
							this.setState({ yearShowed: year, monthShowed: month });
					}

					if (satimagesByParcelDico) {
							const entityForThisParcel = ReducerSatimageHelper.selectParcelFromId(satimagesByParcelDico, parcelIdSelected); // type 'SatimageDatasParcel' !
							const stateOfthisYearMonth = lodashGet(entityForThisParcel, `stateByYearMonthDico[${year}_${month}]`, undefined); // type 'StateYearMonthOfSatimages' !
							if (stateOfthisYearMonth) {
									this.currentStateYearMonth = stateOfthisYearMonth.stateAsk;
							} else {
									this.currentStateYearMonth = SatimageState.stateNotAsk;
							}
					}
			}
			//else on va renvoyer la date courante !

			return currentDate;
	}

	///////////////////////////////////////////////////////////////////////////
	// Fonction permettant le changement de date lors du clic sur une date dans le calendrier
	///////////////////////////////////////////////////////////////////////////
	handleDate = (date) => {
			const { hasParcelSelected, parcelIdSelected } = this.props; //propriétés provenant de la décoration Redux !

			//↓↓ la date renvoyée est elle dans la liste des dates d'image disponible ? ↓↓
			if (this.includeDates && Array.isArray(this.includeDates) && (this.includeDates.length > 0)) {
					for (var i = 0; i < this.includeDates.length; i++) {
							var dateToCheck = this.includeDates[i];
							if (dateHelper.isSameDateDDMMYYYY(dateToCheck, date)) {
									if (hasParcelSelected) {
											//↓↓ si on est sur une date faisant partie de la liste on modifie la valeur sinon on stoppe la mise à jour ↓↓
											if (this.props.selectSatimageByDate) {
													this.props.selectSatimageByDate(date, parcelIdSelected);
											}

											if (this.refDatePicker && this.refDatePicker.current) {
													this.refDatePicker.current.setOpen(false);
											}
											this.isCalendarAlreadyOpen = false;
									}

									break;
							}
					}
			}
	}
	
	///////////////////////////////////////////////////////////////////////////
	// Fonction permettant de sélectionner la 1ere date d'image d'histo disponible - sinon date d'aujourd'hui - comportement dynamique suivant calendrier ouvert ou fermé
	///////////////////////////////////////////////////////////////////////////
	goToFirstIncludeDates = () => {
		this.selectedDateOfParcelSelected();
			if (this.includeDates && Array.isArray(this.includeDates) && (this.includeDates.length > 0)) {
					if (this.newerDate) {
							this.handleDate(this.newerDate);
					} /*else if (this.includeDates[0] instanceof Date) {
							this.handleDate(this.includeDates[0]);
					}*/ else {
							this.handleDate(TODAY_DATE_CALENDAR);
					}
			} else {
					this.handleDate(TODAY_DATE_CALENDAR);
			}
	}

	///////////////////////////////////////////////////////////////////////////
	// Fonction permettant de savoir quand l'utilisateur se déplace dans le calendrier => impact la génération/obtention d'historique
	///////////////////////////////////////////////////////////////////////////
	handleMonthChange = (date) => {
			const { hasParcelSelected, parcelIdSelected, satimagesByParcelDico, datasClient } = this.props;
			if (!date) return;

			//↓ on répond à la problématique du changement d'heure (heure locale-heure UTC ) ↓
			try {
					date = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
			} catch (e) {
					// Traçage d'une action particulière:
					sendError('handleMonthChange', { "erreur": "erreur lors du passage de l'heure UTC à  l'heure locale", "e": e });
			}

			if (hasParcelSelected && this.props.askOldHistoForSelectedParcel && date) {
					const newYearShowed = date.getUTCFullYear();
					const newMonthShowed = date.getUTCMonth() + 1;

					this.setState({ yearShowed: newYearShowed, monthShowed: newMonthShowed });

					if (satimagesByParcelDico) 
					{
						const entityForThisParcel = ReducerSatimageHelper.selectParcelFromId(satimagesByParcelDico, parcelIdSelected); // type 'SatimageDatasParcel' !
						const stateOfthisYearMonth = lodashGet(entityForThisParcel, `stateByYearMonthDico[${newYearShowed}_${newMonthShowed}]`, undefined); // type 'StateYearMonthOfSatimages' !
						if (stateOfthisYearMonth) {
							this.currentStateYearMonth = stateOfthisYearMonth.stateAsk;
						} 
						else {
							this.currentStateYearMonth = SatimageState.stateNotAsk;
						}
					}

					if (this.currentStateYearMonth === SatimageState.stateOnError) {
							// ↓↓ CAS: en erreur => on relance 1 nouvelle fois la demande d'histo ↓↓
							if (datasClient && (datasClient.authorizeHistoric === true)) {
									this.props.askOldHistoForSelectedParcel(parcelIdSelected, newYearShowed, newMonthShowed, null, true);
							}
							else {
									// si l'historique ne doit pas être générée, on affiche uniquement ce qui existe en base en fonction du mois et de l'année
									this.props.getOldHistoForSelectedParcelFromDB(parcelIdSelected, newMonthShowed, newYearShowed);
							}
					}
					else {
							//⚠️ ↓↓ Algo de génération de l'historique non factorisé - Attention - impact important si modification ↓↓date.getUTCMonth()+1, date.getUTCFullYear());
							if (datasClient && (datasClient.authorizeHistoric === true)) {
									this.props.askOldHistoForSelectedParcel(parcelIdSelected, newYearShowed, newMonthShowed);
							}
							else {
									// si l'historique ne doit pas être générée, on affiche uniquement ce qui existe en base en fonction du mois et de l'année
									this.props.getOldHistoForSelectedParcelFromDB(parcelIdSelected, newMonthShowed, newYearShowed);
							}
					}
			} else {
					const newYearShowed = TODAY_DATE_CALENDAR.getUTCFullYear();
					const newMonthShowed = TODAY_DATE_CALENDAR.getUTCMonth() + 1;
					this.setState({ yearShowed: newYearShowed, monthShowed: newMonthShowed });
			}
	}

	/**
	 * fonction qui ferme le calendrier et envoie l'utilisateur sur la page de modélisation
	 * cablé derrière le bouton "obtenir plus d'image"
	 */
	handleGoToModelisation = () => {
		this.props.close();
		this.props.goToModelisation();
	}

	/**
	 * ferme le calendrier
	 */
	handleClose() {
		this.props.close();
	}

	/*
	* Correspond a la methode "getCustomInput" de l'ancien calendrier.
	* Formate la date en fonction de la langue.
	*/
	calendarCustomInput = () => {
		const {hasParcelSelected} = this.props;
		let selectedDate = TODAY_DATE_CALENDAR;
		this.readOnlyValue = true;
		
		if (hasParcelSelected) {
            selectedDate = this.selectedDateOfParcelSelected();

            //C'est celle de la parcelle qui m'interessé, mais celle pour toute l'appli !
            //const currentLayertype = lodashGet(parcelDico[parcelIdSelected], `currentLayerType`, ConstantsLayers.NdviParcelLayerName); 
            //readOnlyValue = (forewardLayerSelected !== ConstantsLayers.NdviParcelLayerName) && (forewardLayerSelected !== ConstantsLayers.VisibleParcelLayerName);
            this.readOnlyValue = false; //désormais, on autorise l'accès au calendrier à partir du moment où il y a une parcelle de sélectionnée!
            // => si on est sur une vue 'à la parcelle', (seul cas autorisé avant) on utilise le calendrier pour visualiser l'image historisée (Visible ou NDVI) de cette parcelle.
            // => si on est sur une vue 'globale', (cas désormais autorisé) on utilise le calendrier (et les dates de ses images historisées) pour demander à Sentinel-Hub 
            //    la carte entière (Visible ou NDVI) à la même date !
        }

		this.selectedDate = selectedDate;
		this.dateFormatedByLanguage = (selectedDate) ? format(selectedDate, StringTranslate.formatDate) : format(TODAY_DATE_CALENDAR, StringTranslate.formatDate);
    }

	//#region Gestion du swipe

	/**
     * Function trigger quand le client touche son écran (en mode mobile)
     * @param {Event} e 
     */
    onTouchStart = (e) => {
        this.setState({
            // On met à jour la position de départ
            touchPositionStart: e.targetTouches[0].clientX,
            // on réinitialise la position de fin
            touchPositionEnd: null,
        });
    }
    
    /**
     * Function trigger quand le client déplace son doigt sur son écran (en mode mobile)
     * @param {*} e 
     * @returns 
     */
    onTouchMove = (e) => this.setState({ touchPositionEnd: e.targetTouches[0].clientX})
    
    /**
     * Function trigger quand le client relève son doigt de son écran (en mode mobile)
     * @param {*} e 
     * @returns 
     */
    onTouchEnd = () => {
        const { touchPositionStart, touchPositionEnd, actualView } = this.state;

        // Si on a pas la position de départ ou de fin
        // ou que l'on est pas sur la vue des jours
        if (!touchPositionStart || !touchPositionEnd || (actualView !== 'day')) return;

        // On récupère la distance parcouru
        const distance = touchPositionStart - touchPositionEnd;

        // Puis si elle est suffisante pour être considérer comme du swip
        const isLeftSwipe = (distance > minSwipeDistance);
        const isRightSwipe = (distance < -minSwipeDistance);
        if (isLeftSwipe) {
            // Si le client à déplacer son doigt vers la gauche, 
            //on passe au mois suivant (on trigger le bouton next)
            this.refNextButton.current?.click();
        }
        else if (isRightSwipe) {
            // Si le client à déplacer son doigt vers la droite, 
            //on passe au mois précédent (on trigger le bouton previous)
            this.refPreviousButton.current?.click();
        }
    }

	//#endregion gestion du swipe


	///////////////////////////////////////////////////////////////////////////
	// fonction de cycle de vie react.js
	///////////////////////////////////////////////////////////////////////////
	shouldComponentUpdate(nextProps, nextState) {
			const { parcelIdSelected, satimagesByParcelDico, satimageIdOfParcelSelected,
					satimageDateOfParcelSelected, satimageDicoCounterOfParcelSelected,
					satimagesGlobalStateAsk, satimagesStateOfParcelSelected, satImageWithHigherMaxccDicoOfParcelSelected,
					languageSetting } = this.props; //propriétés provenant de la décoration Redux !
			const { yearShowed, monthShowed } = this.state;

			if ((nextState.yearShowed !== yearShowed) || (nextState.monthShowed !== monthShowed)) return true;

			if (!satimagesByParcelDico) return false;

			//↓↓ Pour chaque changement du state du composant MAIS la parcelle couramment sélectionnée l'est toujours et reste la même! ↓↓
			// => on autorise la mise à jour... si le nombre d'images référencées dans le Store redux change !
			if ((parcelIdSelected && (nextProps.parcelIdSelected === parcelIdSelected))) {
					//RQ: La liste des dates valides peut avoir évolué, il faut donc l'actualiser, si besoin:
					const entityForThisParcel = ReducerSatimageHelper.selectParcelFromId(nextProps.satimagesByParcelDico, parcelIdSelected); // type 'SatimageDatasParcel' !
					if (entityForThisParcel && entityForThisParcel.satimageDicoCounter && (entityForThisParcel.satimageDicoCounter !== this.includeDatesCounter)) {
							//RQ : 'entityForThisParcel.satimageDicoCounter' contient déjà la nouvelle valeur alors que 'this.includeDatesCounter' a besoin d'être actualisé 
							// tout comme 'this.includeDates' et 'this.highlightWithRanges' !
							this.setIncludeDates(entityForThisParcel);

							// Même s'il y a des dates plus récentes, on reste sur la date actuellement sélectionnée:
							//Donc, ne fait rien de plus ici!
					}
					//Second cas d'actualisation de la liste des dates: 
					else if (entityForThisParcel && (satimageDicoCounterOfParcelSelected !== this.includeDatesCounter)) {
							this.setIncludeDates(entityForThisParcel);
					}
					//cas d'actualisation de la liste des dates ayant un maxcc trop important
					else if (entityForThisParcel && (satImageWithHigherMaxccDicoOfParcelSelected !== this.satImageWithHigherMaxccDicoOfParcelSelected)) {
							this.setIncludeDates(entityForThisParcel);
					}
					//else //=> rien n'a évoluer !

					return true;
			}

			//↓↓ la date sélectionée n'est pas la même que la date actuallement sélectionnée en cours, on la change ↓↓
			//Exemple: lastParcelDicoAction: new ChangeAction(ChangeType.ADD, ReducerType.PARCEL, ActionTypeParcels.SetSatimageIdToParcel, { parcelId: action.parcelId, satimageId: action.satimageId, layerType: ConstantsLayers.NdviParcelLayerName }),
			if (parcelIdSelected && (nextProps.parcelIdSelected === parcelIdSelected) && nextProps.lastParcelDicoAction &&
					((nextProps.lastParcelDicoAction.action === ActionTypeParcels.SetSatimageIdToParcel) || (nextProps.lastParcelDicoAction.action === ActionTypeParcels.ChangeSatimageIdToParcel)) &&
					nextProps.lastParcelDicoAction.params && (nextProps.lastParcelDicoAction.params.parcelId === parcelIdSelected) &&
					nextProps.lastParcelDicoAction.params.satimageId && (nextProps.lastParcelDicoAction.params.satimageId > 0)) {
					//RQ: l'image associé à cette parcelle a changé ! la date sera actualisé dans le 'render' !
					return true;
			}
			//Exemple: satimageDateOfParcelSelected est présente et différente de celle du calendrier...
			else if (parcelIdSelected && (nextProps.parcelIdSelected === parcelIdSelected) &&
					satimageIdOfParcelSelected && (satimageIdOfParcelSelected > 0) &&
					satimageDateOfParcelSelected && (!dateHelper.isSameDateDDMMYYYY(satimageDateOfParcelSelected, this.selectedDate))) {
					//RQ: l'image associé à cette parcelle a changé ! la date sera actualisé dans le 'render' !
					return true;
			}
			//else //=> rien n'a évoluer !

			//↓↓ Pour le cas du changement d'état d'une parcelle (lors d'une demande de chargement d'images, pour un mois donnée) ↓↓
			if (parcelIdSelected && (nextProps.parcelIdSelected === parcelIdSelected) &&
					satimagesGlobalStateAsk && (nextProps.satimagesGlobalStateAsk !== satimagesGlobalStateAsk)) {
					///l'appel de la méthode 'selectedDateOfParcelSelected' dans le render actualisera le statut sur la parcelle qui nous concerne (celle sélectionnée)
					return true;
			} else if (parcelIdSelected && (nextProps.parcelIdSelected === parcelIdSelected) &&
					satimagesStateOfParcelSelected && (nextProps.satimagesStateOfParcelSelected !== satimagesStateOfParcelSelected)) {
					///l'appel de la méthode 'selectedDateOfParcelSelected' dans le render actualisera le statut sur la parcelle qui nous concerne (celle sélectionnée)
					return true;
			}

			//↓↓ Pour chaque changement de la sélection d'une parcelle, on crée les arrays [] nécessaires au composant 'DatePicker' ↓↓
			if (nextProps.parcelIdSelected !== parcelIdSelected) {
					//si on passe de 'désélectionné' (== 'parcelIdSelected' est undefined) à 'sélection d'une parcelle' OU change de parcelle sélectionnée !
					if (nextProps.parcelIdSelected && Number.isInteger(nextProps.parcelIdSelected) && (nextProps.parcelIdSelected > 0)) {
							let entityForThisParcel = ReducerSatimageHelper.selectParcelFromId(satimagesByParcelDico, nextProps.parcelIdSelected); // type 'SatimageDatasParcel' !
							if (entityForThisParcel) {
									//RQ : 'entityForThisParcel.satimageDicoCounter' contient déjà la nouvelle valeur alors que 'this.includeDatesCounter' a besoin d'être actualisé 
									// tout comme 'this.includeDates' et 'this.highlightWithRanges' !
									this.setIncludeDates(entityForThisParcel);
									//RQ: la parcelle séléectionnée a changé ! la date sera actualisé dans le 'render' !
							} else { //=> si on n'a pas la nouvelle parcelle, on vide toutes les dates !
									this.setIncludeDates(undefined);
							}
					} else { //=> si on passe à 'désélectionné', on vide toutes les dates !
							this.setIncludeDates(undefined);
					}

					return true;
			}

			//↓↓ Pour le cas d'un changement de langue ↓↓
			if (nextProps.languageSetting !== languageSetting) {
					// la valeur de '' va permettre de définir au composant 'DatePicker' de mettre en forme la date correctement !
					return true;
			}

			return false;
	}

	/* fonction cycle de vie react.js */
	componentDidUpdate = (prevProps, previousState) => {
		const { satimageDateOfParcelSelected } = this.props
		//Si dans le calendrier, on est déjà sur la date de l'image la plus récente pour la parcelle, on affiche à l'écran que le client est déjà sur l'image la plus récent
		//sinon on affiche le bouton pour retourner à l'image la plus récente.
		if (previousState.isNewerDateForSelectedParcel !== this.isNewerDateForSelectedParcel) {
				this.setState({ isNewerDateForSelectedParcel: this.isNewerDateForSelectedParcel });
		}

		// CAS - ↓ SUITE À UNE DEMANDE D'HISTORIQUE - ON SE RETROUVE EN ERREUR => on relance 1 nouvelle fois la demande d'histo (2 tentatives max) ↓
		if ((prevProps.satimagesGlobalStateAsk === SatimageState.stateAskOnProgress) && (this.props.satimagesGlobalStateAsk === SatimageState.stateOnError)) {
				if (prevProps.datasClient && (prevProps.datasClient.authorizeHistoric === true)) {
						this.props.askOldHistoForSelectedParcel(this.props.parcelIdSelected, this.state.yearShowed, this.state.monthShowed, null, true);
				}
		}
		// CAS - ↓ SUITE À UN PROBLEME DE SYNCRHO ENTRE LE CAROUSEL ET LE CALENDAR => on vérifie si l'état de la date sélectioner à changer si oui alors on l'actualise
		if(prevProps.satimageDateOfParcelSelected !== satimageDateOfParcelSelected) {
			this.setState({
				satimageDateOfParcelSelected: previousState.satimageDateOfParcelSelected
			});
		}
	}

	componentWillUpdate(nextProps, nextState){
		const { parcelIdSelected, satimagesByParcelDico } = this.props; //propriétés provenant de la décoration Redux !
		const { yearShowed, monthShowed } = this.state;

		const nextYearShowed = nextState.yearShowed;
		const nextMonthShowed = nextState.monthShowed;
		const nextParcelIdSelected = nextProps.parcelIdSelected;
		const nextSatImagesByParcelDico = nextProps.satimagesByParcelDico;
		const nextsatimageDateOfParcelSelected = nextProps.satimageDateOfParcelSelected;
		
		this.calendarCustomInput();

		//Mise à jour lors du changement du couple mois/année
		if((yearShowed !== nextYearShowed) || (monthShowed !== nextMonthShowed))
		{
			const entityForThisParcel = ReducerSatimageHelper.selectParcelFromId(satimagesByParcelDico, parcelIdSelected);
			const stateOfthisYearMonth = lodashGet(entityForThisParcel, `stateByYearMonthDico[${nextYearShowed}_${nextMonthShowed}]`, undefined); // type 'StateYearMonthOfSatimages' !

			if (stateOfthisYearMonth) {
				this.currentStateYearMonth = stateOfthisYearMonth.stateAsk;
			} else {
				this.currentStateYearMonth = SatimageState.stateNotAsk;
			}
		}

		//Mise à jour lors du Changement de parcel
		if ((this.props.parcelIdSelected !== nextParcelIdSelected) && (nextsatimageDateOfParcelSelected))
		{
			let year = nextsatimageDateOfParcelSelected.getUTCFullYear();
			let month = nextsatimageDateOfParcelSelected.getUTCMonth() + 1;

			const entityForThisParcelId = ReducerSatimageHelper.selectParcelFromId(nextSatImagesByParcelDico, nextParcelIdSelected);
			const stateOfthisYearMonth = lodashGet(entityForThisParcelId, `stateByYearMonthDico[${year}_${month}]`, undefined);

			if (stateOfthisYearMonth) {
				this.currentStateYearMonth = stateOfthisYearMonth.stateAsk;
			} else {
				this.currentStateYearMonth = SatimageState.stateNotAsk;
			}
		}

		//On peux se permettre de réaffecter les listes d'images a chaque appel de ComponentWillUpdate car aucune décorations Redux va Rapplé le Render du calendrier 
		//pendant que celui-ci est ouvert.
		this.satImageWithLowerOrEqualMaxccList = ReducerSatimageHelper.getSatImageWithLowerOrEqualMaxccListFromParcelId(satimagesByParcelDico, parcelIdSelected); // récupération d'une liste de satImage valides
		this.satImageWithHigherMaxccList = ReducerSatimageHelper.getSatImageWithHigherMaxccListFromParcelId(satimagesByParcelDico, parcelIdSelected); // récupération d'une liste de satImage non valides (maxcc)
		this.localeMap = ((this.props.language === 'fr-FR' || this.props.language === 'fr-BE') ? frLocale : (this.props.language === "en-GB" ? enLocale : esLocale)); //Concerne la traduction des datePicker

	}

	render() {
		const { calendarIsOpen, satimageDateOfParcelSelected, hasParcelSelected, parcelIdSelected, satimagesByParcelDico, displayLandsatImgs } = this.props;
		const screenSize =  window.innerWidth;
		const themeSize = theme.breakpoints.values.sm;
		const fullScreenCalendar = (screenSize <= themeSize ? true : false);

		// ComponentWillUpdate est deprecated et ne fonctionne plus correctement depuis React 17 donc on doit recuperer les nouvelles images ici :
		this.satImageWithHigherMaxccList = ReducerSatimageHelper.getSatImageWithHigherMaxccListFromParcelId(satimagesByParcelDico, parcelIdSelected);

		return ( 
			<>
				<Button onClick={this.props.openCalendar} disabled={!hasParcelSelected}>
					<CalendarToday size="small" color="secondary" sx={{ marginRight: '5px' }} />
					<Typography color="secondary" classes={{ root: 'calendar-input-date' }}>
                    	{(hasParcelSelected) ? this.dateFormatedByLanguage : ((this.readOnlyValue) ? calendarInputDefaultValue : this.dateFormatedByLanguage)}
                	</Typography>
				</Button>
				<Dialog
				hideBackdrop                    // permet d'enlever l'ombre derrière le calendrier
				open={calendarIsOpen}
				onClose={this.handleClose}
				style={{ position: 'absolute'}}
				fullScreen={fullScreenCalendar} 
				maxWidth={'sm'} // limitation de la largeur
				

				// gestion du positionnement du calendrier
				sx={{
					
					'& .MuiPaper-root':{
						maxWidth: "600px !important",
						position: 'absolute',
						left:{xs:"0px", sm:"0px", md:"240px"},
						top:{xs:"0px", sm:"0px", md:"50px"},
					},
				}}
			>
			
			{/* Conditionnement de l'affichage fullScreen*/}
			{ 
				fullScreenCalendar &&
				<>
					<DialogTitle>
						<Grid container spacing={0}>
								<Grid item xs={8} sx={{alignSelf: "center"}} >
										<Typography variant='h4'>
												Calendrier
										</Typography>
								</Grid>
								<Grid item xs={4} align="right">
										<IconButton
												aria-label="close"
												onClick={this.handleClose}
												sx={{
														color: (theme) => theme.palette.grey[500]
												}}>
													<CloseIcon />
										</IconButton>
								</Grid>
						</Grid>
					</DialogTitle>
					<Divider />
				</>
			}

			<Grid container id="the container">
		
				{/* Grille du calendrier */}
				<Grid item xs={12} md={7} justifyContent="center">
					<LocalizationProvider adapterLocale={this.localeMap} dateAdapter={AdapterDateFns}>
					<DateCalendar
							// disableHighlightToday                      // permet d'enlever le petit rond autour de la date du jour
							disableFuture                                 // désactive les jours après celui d'aujourd'hui et la capacité de naviguer dans le futur avec les flèches
							shouldDisableDate={(day) => this.disabledDates(day)}        			// on lui fourni une fonction qui va pouvoir désactiver certains jours
							minDate={MIN_DATE_SENTINEL}                   // date en dessous de laquelle le calendrier ne peut plus naviguer, ni sélectionner de date
							defaultValue={satimageDateOfParcelSelected}   // valeur par défaut qui est sélectionné lors de l'ouverture du calendrier (attention, ne fonctionne que quand DateCalendar est pas controllé)
							value={satimageDateOfParcelSelected}
							//loading                                     // permet d'activer le visuel de chargement
							//renderLoading                               // permet de customiser le visuel de chargement
							onMonthChange={this.handleMonthChange}        // permet d'appeler une fonction lors du changement de mois
							//onYearChange                                // permet d'appeler une fonction lors du changement d'année
							views={['year', 'month', 'day']}        
							
							// slots /day permet de fournir la customisation du visuel des jours
							slots={{
									day: this.customDay,
							}}
							onChange={this.handleChangeDate}

							// sx => les 5 px sont pour éviter de couper les badges trop grand en haut du calendrier
							sx={{
								"& .MuiDayCalendar-weekContainer" : {
									margin: '5px'
								},
								//les 250px sont pour éviter de couper les badges en bas quand le calendrier affiche des mois sur 6 lignes. 
								"& .MuiDayCalendar-slideTransition" : {
									minHeight: '250px' 
								}
								}}

							// Gestion du swipe
							onViewChange={(view) => this.setState({actualView: view})} // Récupération de la vue afficher (pour la gestion du swipe)      
							slotProps={{
								// Props nécessaire pour la gestion du swipe
								nextIconButton: {
									ref: this.refNextButton,
								},
								previousIconButton: {
									ref: this.refPreviousButton,
								}
							}}
							onTouchStart={this.onTouchStart} 
							onTouchMove={this.onTouchMove}
							onTouchEnd={this.onTouchEnd}
							/>
						</LocalizationProvider>
					</Grid>


					{/* Grille contenant les grilles de la légende et des boutons*/}
						<Grid item xs={12} md={5} sx={{ marginTop: '20px', marginBottom: '20px' }}>
							<Grid container>
								{/* Grille de la légende */}
								<Grid item xs={12} md={12} sx={{ marginLeft: '10px', marginBottom: '10px' }} justifyContent="left">
									<List dense={true}>
										<ListItem variant_pz='legende_calendrier'>
											<ListItemIcon sx={{ minWidth: '20px' }}>
												<Badge badgeContent={"S"} color="secondary" anchorOrigin={{
													vertical: 'top',
													horizontal: 'right',
												}} />
											</ListItemIcon>
											<ListItemText primary="Sentinel" />
										</ListItem>

										{displayLandsatImgs &&
											<ListItem variant_pz='legende_calendrier'>
												<ListItemIcon sx={{ minWidth: '20px' }}>
													<Badge badgeContent={"L"} color="secondary" anchorOrigin={{
														vertical: 'top',
														horizontal: 'right',
													}} />
												</ListItemIcon>
												<ListItemText primary="Landsat" />
											</ListItem>}

										<ListItem variant_pz='legende_calendrier'>
											<ListItemIcon sx={{ minWidth: '20px' }}>
												<Badge badgeContent={"M"} color="secondary" anchorOrigin={{
													vertical: 'top',
													horizontal: 'right',
												}} />
										</ListItemIcon>

										<ListItemText primary={StringTranslate.modelisationCalendarDescription} />
									</ListItem>

									<ListItem variant_pz='legende_calendrier'>
										<ListItemIcon sx={{ minWidth: '20px' }}>
											<Badge badgeContent={<CloudIcon fontSize="small" color="disabled"></CloudIcon>} anchorOrigin={{
												vertical: 'top',
												horizontal: 'right',
											}} />
										</ListItemIcon>

										<ListItemText primary={`${StringTranslate.legendForCloudyImage}`} />{/* Modifié la valeur du texte pour quel ne soit pas en brut */}
									</ListItem>

									<ListItem variant_pz='legende_calendrier'>
										<ListItemIcon sx={{ minWidth: '20px' }}>
											<Badge badgeContent={<CancelIcon fontSize="small" color="disabled"></CancelIcon>} anchorOrigin={{
												vertical: 'top',
												horizontal: 'right',
											}} />
										</ListItemIcon>

										<ListItemText primary={`${StringTranslate.legendForDelayedImage}`} />
									</ListItem>
								</List>
							</Grid>

							{/* Grille des boutons*/}
							<Grid item xs={12} md={12}/* sx={{ marginLeft: 1 }} */ align="center">
								<Stack spacing={1} direction="column" alignContent="center" alignItems="center">
									<Button
										variant="text"
										size="small"
										color="secondary"
										onClick={this.handleGoToModelisation}
										sx={{ width: "80%"}}
									>
										{`${StringTranslate.buttonForMoreImage}`}
									</Button>

									<Button
										variant="contained"
										size="small"
										color="secondary"
										onClick={this.goToFirstIncludeDates}
										sx={{ width: "80%" }}
									>
										{`${StringTranslate.buttonGoBackLastImage}`}
									</Button>
								</Stack>
							</Grid>
						</Grid>
					</Grid>


					{/* Grille contenant la barre de chargement*/}
					<Grid item xs={12} sx={{ marginTop: 1 }}>
						{/* <StateCalendar/> Barre de chargement en bas du calendrier */}
					{ <StateCalendar currentStateYearMonth={this.currentStateYearMonth} /> }
				</Grid>
			</Grid>
								

			</Dialog>
			</>
		);
	}
}

const mapStateToProps = state => ({
		satimagesByParcelDico: lodashGet(state, 'satimageData.satimagesByParcelDico', {}), //(state && state.satimageData) ? state.satimageData.satimagesByParcelDico : {},
		parcelIdSelected: lodashGet(state, 'contextAppData.parcelIdSelected', -1), //(state && state.contextAppData) ? state.contextAppData.parcelIdSelected : -1,
		calendarIsOpen : lodashGet(state, 'contextAppData.calendarIsOpen', false),
		hasParcelSelected: (lodashGet(state, 'contextAppData.parcelIdSelected', -1) > 0) ? true : false, /*(state && state.contextAppData && state.contextAppData.parcelIdSelected &&
				Number.isInteger(state.contextAppData.parcelIdSelected) && (state.contextAppData.parcelIdSelected > 0)) ? true : false,*/
		satimageDateOfParcelSelected: lodashGet(state, 'contextAppData.satimageDateSelectedOfParcel', undefined), //(state && state.contextAppData) ? state.contextAppData.satimageDateSelectedOfParcel : undefined,
		datasClient: lodashGet(state, 'clientUserData.clientDatas', null), //(state && state.clientUserData) ? state.clientUserData.clientDatas : null,
		parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}), //(state && state.parcelsData) ? state.parcelsData.parcelDico : {},
		language: lodashGet(state, 'settingsData.settings.language', StringTranslate.getLanguage()), //la langue contenu dans le store Redux 

		//Infos provenant du reducer 'settings':
        displayLandsatImgs: lodashGet(state, 'settingsData.settings.displayLandsatImgs', true), //(state && state.settingsData && state.settingsData.settings) ? state.settingsData.settings.displayLandsatImgs : true,
});

const mapDispatchToProps = dispatch => ({
	selectSatimageByDate: (satimageDate, parcelId = -1) => dispatch(ActionSelectDateImgFromDate(satimageDate, parcelId)),
	open: () => dispatch(ActionShowCalendarDialog()),
	close: () => dispatch(ActionCloseCalendarDialog()),
	goToModelisation: () => dispatch(ActionGoToModelisation()),
	
	askFirstHistoForSelectedParcel: (parcelIdSelected) => dispatch(ActionAskFirstHistoForSelectedParcel(0, parcelIdSelected)),
	askOldHistoForSelectedParcel: (parcelId, yearValue, monthValue, countCallOfPrevMonth, reload) => dispatch(ActionAskOldHistoForSelectedParcel(parcelId, yearValue, monthValue, countCallOfPrevMonth, reload)),
	getOldHistoForSelectedParcelFromDB: (parcelId, monthValue, yearValue) => dispatch(ActionGetHistoForParcelByMonthYear(parcelId, monthValue, yearValue)),
	openCalendar: () => dispatch(ActionShowCalendarDialog()),
});
 
export default connect(mapStateToProps, mapDispatchToProps)(CustomCalendar);