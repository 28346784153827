import React from 'react';
// mui
import { Dialog, DialogTitle, DialogContent, Stack, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
// Fichier de traduction
import StringTranslate from '../assets/i18n/stringLanguage.jsx';
// Import du theme pour le responsive
import getTheme from "../themes/index.js";
let theme = getTheme();


/**
 * Modal sous form de dialog permettant d'afficher une vidéos d'aide lorsqu'on appuie sur un bouton d'aide
 * @param {boolean} modalIsOpen - Booléen pour ouvrir ou fermer la modal
 * @param {() => void} onClose - Fonction appellé au clic sur la page ou la croix
 * @param {url} modalVideos - url de la vidéo
 * @returns {React.Component} - La modal
 * 
 * @example A mettre dans le fichier d'appel :
 * 
 *  handleOpenModalVideos = () => {
 *      this.setState({ modalIsOpen: true });
 *  }
 * 
 *  handleCloseModalVideos = () => {
 *      this.setState({ modalIsOpen: false });
 *  }
 * 
 *  <Button color="secondary" variant="text" size="small" onClick={props.handleOpenModal}>
 *      {StringTranslate.helpAsk}
 *  </Button>
 * 
 *  <ModalVideos
 *      modalIsOpen={modalIsOpen}
 *      modalVideos={modalVideos}
 *      onClose={() => this.handleCloseModalVideos()}
 *  />
 */
export default class ModalVideos extends React.Component {

    render () { 
        const {modalIsOpen, modalVideos, onClose} = this.props; // Variables passer en props depuis un fichier maître
        const screenSize =  window.innerWidth; // Récupère la taille de la page actuelle
		const themeSize = theme.breakpoints.values.sm; //Récupère la taille correspondante dans le theme
        const fullScreenVideos = (screenSize <= themeSize ? true : false); // Comparaison entre les deux variables
        
        let maxSize = "80%"; // Valeur par défaut du max-width
        let borderRadiusBottomVideos = "8px"; // Valeur par défaut du border-radius-bottomLeft et right

        if(fullScreenVideos === true) {
            maxSize = "100%";
            borderRadiusBottomVideos = "0px";
        }
        return (
            <Dialog 
                open={modalIsOpen}
                onClose={onClose}
                style={{ position: 'absolute'}}
                fullScreen={fullScreenVideos}
                maxWidth={'lg'} 
                sx={{
                    '& .MuiPaper-root': {
                        maxWidth: {maxSize},
                        width: '100%',
                        height: '60%',
                        position: 'absolute',
                        padding: '0px !important',
                    }
                }}
            >
                <DialogTitle sx={{ padding: '0px !important' }}>
                    <Stack> 
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography sx={{
                                marginLeft: '10px',
                                color: (theme) => theme.palette.primary.main,
                                }}>
                                {StringTranslate.titleModalVideos}
                            </Typography>
                            <IconButton
                                aria-label="close"
                                onClick={onClose}
                                sx={{
                                color: (theme) => theme.palette.primary.main,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                    </Stack>
                </DialogTitle>
                <DialogContent sx={{
                    padding: '0px 0px 0px 0px',
                }}>
                    <iframe style={{ borderEndEndRadius: {borderRadiusBottomVideos}, borderEndStartRadius: {borderRadiusBottomVideos}, border: '0px', width: '100%', height: '100%' }} src={modalVideos} title="YouTube video" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </DialogContent>
            </Dialog>
            
        )
    }
}