//import area from '@turf/area';


// fichier permettant de faire de la manipulation et des opérations sur un objet geojson
const geojsonHelper = {
    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant d'enlever des objets parcels contenus dans une liste - passage par référence
    ///////////////////////////////////////////////////////////////////////////
    splitParcelsArrayByIndex(parcels, indexList = null){
        if(!indexList || !Array.isArray(indexList)) return null;
        if(!parcels || !Array.isArray(parcels)) return null;
        
        var parcelsToKeep = [];
        var parcelsToSkip = [];
        parcels.forEach( 
            (parcel, index) => (indexList.includes(index)) ? parcelsToKeep.push(parcel) : parcelsToSkip.push(parcel)
        );
        return [parcelsToKeep,parcelsToSkip];
    },
};

export default geojsonHelper;