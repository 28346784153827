import React from "react";
import { connect } from 'react-redux';
import { Typography, TextField, Grid, Slider, InputLabel, Stack, 
    Divider, IconButton, Button, ButtonGroup, Switch , FormControlLabel } from "@mui/material";
import { HelpOutline } from "@mui/icons-material";

import lodashGet from 'lodash/get';

import StringTranslate from "../../assets/i18n/stringLanguage";
import '../../assets/css/profil.css';
import MainCard from "../subLayouts/mainCard.jsx";

import { ActionSaveSettingsAsk, ActionDisplayLandsatImgs } from '../../redux/actions/settings.js';
import { ActionDeleteParcelsAsk } from '../../redux/actions/parcels.js';
import { ActionGoToProfil, ActionGoToParcels, ActionShowProfilMenuDialog, ProfilIndex } from "../../redux/actions/contextApp";

/* Thème Berry */
import getTheme from '../../themes';


let theme = getTheme();

const MinModulationQualityValue = 0;
const MinModulationQualityPixelDef = 64;
const DefaultModulationQualityValue = 1;
const DefaultModulationQualityPixelDef = 128;
const MediumModulationQualityValue = 2;
const MediumModulationQualityPixelDef = 256;
const MaxModulationQualityValue = 3;
const MaxModulationQualityPixelDef = 512;

const noError = '';
//const validCharsOfDoseShpLabel = Array.from(Array(26)).map((e, i) => String.fromCharCode(i + 65)); //['A', 'B', ..., 'Z']
const validCharsOfDoseShpLabel = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z', 
    'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z',
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const maxCharsForDoseShpLabel = 50;

class Parametres extends React.Component {
    constructor(props) {
        super(props);

        this.marksValue = [
            {
                value: MinModulationQualityValue,
                label: `${StringTranslate.minModulationQualityLabel}`,
                pixelValue: MinModulationQualityPixelDef,
                detailValue: `${StringTranslate.minModulationQualityDetail}`,
            },
            {
                value: DefaultModulationQualityValue,
                label: `${StringTranslate.defaultModulationQualityLabel}`,
                pixelValue: DefaultModulationQualityPixelDef,
                detailValue: `${StringTranslate.defaultModulationQualityDetail}`,
            },
            {
                value: MediumModulationQualityValue,
                label: `${StringTranslate.mediumModulationQualityLabel}`,
                pixelValue: MediumModulationQualityPixelDef,
                detailValue: `${StringTranslate.mediumModulationQualityDetail}`,
            },
            {
                value: MaxModulationQualityValue,
                label: `${StringTranslate.maxModulationQualityLabel}`,
                pixelValue: MaxModulationQualityPixelDef,
                detailValue: `${StringTranslate.maxModulationQualityDetail}`,
            }
        ];

        const initialModulQualityPixelDef = props.modulQuality ? props.modulQuality : DefaultModulationQualityPixelDef;
        const initialModulQualityIndex = this.getModulQualityIndexFromPixelDef(initialModulQualityPixelDef);
        const initValueShpDoseLabel = props.buildShpDoseLabel ? props.buildShpDoseLabel : '';

        this.state = {
            parcelMaxcc: props.parcelMaxcc,
            inErrorMaxcc: false,
            editingMaxCloudy: false,

            // Ma modulation Settings
            modulQualityIndex: initialModulQualityIndex,
            modulQuality: props.modulQuality,
            modulQualityDetail: lodashGet(this.marksValue, `[${initialModulQualityIndex}].detailValue`, ''),
            changingModulQuality: false,
            useV4IsoXml: props.buildIsoXmlV4Setting,
            changingVersionIsoXml: false,
            messageError: noError, 
            inErrorShpLabel: false,
            shpDoseLabel: initValueShpDoseLabel,
            editingShpDoseLabel: false,

            // Les images Landsat8
            displayLandsatImgs: props.displayLandsatImgs, //Images Landsat8 à afficher
        };

        this.saveSettingsCarto = this.saveSettingsCarto.bind(this);
        this.saveSettingsModulation = this.saveSettingsModulation.bind(this);
        this.resetSettings = this.resetSettings.bind(this);

        this.getModulQualityLabel = this.getModulQualityLabel.bind(this);
        this.getCloudCoverageValueLabel = this.getCloudCoverageValueLabel.bind(this);
        this.handleChangeModulationQuality = this.handleChangeModulationQuality.bind(this);
    }

    handleChange = param => (event) => {
        if (param === 'parcelMaxcc') {
            let intValue = parseInt(event.target.value, 10);
            
            this.setState({
                inErrorMaxcc: ((intValue >= 0) && (intValue <= 100)) ? false : true,
                parcelMaxcc: intValue,
                editingMaxCloudy: true,
            });
        }
        else if (param === 'shpDoseLabel') {
            const newValue = event.target.value;
            
            //Vérification des caractères saisis:
            let messageErrorShpLabel = noError;
            if ((newValue !== undefined) && (newValue !== null) && (newValue !== '')) {
                //parcours des caractères, si l'un d'eux n'est pas dans la liste, on sort en erreur !
                for (const charItem of newValue) {
                    if (validCharsOfDoseShpLabel.indexOf(charItem) < 0) {
                        messageErrorShpLabel = StringTranslate.ShpDoseLabelWithInvalidChar;
                    }
                    //else //ne change rien !
                }
                /*test des expression régulières => pas concluant !
                let regex = new RegExp('/^[a-zA-Z0-9]+$/gi'); // pas '\w' car accepte le '_' !
                if (regex.test(newValue) !== true) {
                    messageErrorShpLabel = StringTranslate.ShpDoseLabelWithInvalidChar;
                }
                //else //ne change rien ! */
            } else { //saisie vide !
                messageErrorShpLabel = StringTranslate.ShpDoseLabelNotBeVoid;
            }

            this.setState({
                messageError: messageErrorShpLabel,
                inErrorShpLabel: (messageErrorShpLabel === '') ? false : true,
                shpDoseLabel: newValue,
                editingShpDoseLabel: true,
            });
        }
    }

    saveSettingsCarto = () => {
        let settingsToSave = {
            ...this.props.settings,
            parcelMaxcc: this.state.parcelMaxcc,
        }

        // ↓ sauvegarde en BDD ↓
        this.props.saveSettings(settingsToSave)
            .then(() => this.setState({
                inErrorMaxcc: false,
                editingMaxCloudy: false,
            }))
            .catch((error) => {
                //console.log(error);
            });
    }

    saveSettingsModulation = () => { 
        const { shpDoseLabel, editingShpDoseLabel } = this.state;

        let settingsToSave = {
            ...this.props.settings,
            imageModulationSizeQuality: this.state.modulQuality,
            buildIsoXmlV4: this.state.useV4IsoXml,
        }
        if (editingShpDoseLabel === true) { //Maj du libellé, seulement si était en édition !
            settingsToSave.buildShpDoseLabel = shpDoseLabel;
        }

        // ↓ sauvegarde en BDD ↓
        this.props.saveSettings(settingsToSave)
            .catch((error) => {
                //console.log(error);
            });

        this.setState({
            changingModulQuality: false,
            changingVersionIsoXml: false,
            inErrorShpLabel: false,
            editingShpDoseLabel: false,
            messageError: noError,
        });
    }

    resetSettings = () => {
        const initialModulQualityPixelDef = this.props.modulQuality ? this.props.modulQuality : DefaultModulationQualityPixelDef;
        const initialModulQualityIndex = this.getModulQualityIndexFromPixelDef(initialModulQualityPixelDef);
        const initValueShpDoseLabel = this.props.buildShpDoseLabel ? this.props.buildShpDoseLabel : '';

        this.setState({
            parcelMaxcc: this.props.parcelMaxcc,
            inErrorMaxcc: false,
            editingMaxCloudy: false,
            modulQuality: initialModulQualityPixelDef,
            modulQualityIndex: initialModulQualityIndex,
            modulQualityDetail: lodashGet(this.marksValue, `[${initialModulQualityIndex}].detailValue`, ''),
            changingModulQuality: false,
            useV4IsoXml: this.props.buildIsoXmlV4Setting,
            changingVersionIsoXml: false,
            inErrorShpLabel: false,
            shpDoseLabel: initValueShpDoseLabel,
            editingShpDoseLabel: false,
            messageError: noError,
        });
    }

    /**
     * fonction pour rediriger vers la page "Importer mes parcelles"
     */
    handleSelectParcelsPageClick = (event) => {
        if (this.props.goToParcels) {
            this.props.goToParcels();
        }
    };

    getModulQualityLabel(qualityValue) {
        const indexMark = this.marksValue.findIndex((mark) => mark.value === qualityValue);
        if ((indexMark >= 0) && (indexMark < this.marksValue.length)) {
            return this.marksValue[indexMark].label;
        }
    }

    getModulQualityIndexFromPixelDef(pixelDefValue) {
        const indexMark = this.marksValue.findIndex((mark) => mark.pixelValue === pixelDefValue);
        if ((indexMark >= 0) && (indexMark < this.marksValue.length)) {
            return indexMark;
        }

        return 0; // devrait correspondre à 'DefaultModulationQualityIndex;' !
    }

    handleChangeModulationQuality(event, newValue) {
        let newModulQualityDisplay = `${StringTranslate.defaultModulationQualityDetail}`;
        let newModulQualityPixelDef = DefaultModulationQualityPixelDef;
        let newQualityValue = this.initialModulQualityIndex;

        const indexMark = this.marksValue.findIndex((mark) => mark.value === newValue);
        if ((indexMark >= 0) && (indexMark < this.marksValue.length)) {
            newQualityValue = newValue;
            newModulQualityDisplay = this.marksValue[indexMark].detailValue;
            newModulQualityPixelDef = this.marksValue[indexMark].pixelValue;
        }

        this.setState({
            modulQualityDetail: newModulQualityDisplay,
            modulQuality: newModulQualityPixelDef,
            modulQualityIndex: newQualityValue,
            changingModulQuality: true,
        });
    }

    handleChangeVersionIsoxml(newValue) {
        this.setState({
            useV4IsoXml: newValue,
            changingVersionIsoXml: true,
        });
    }

    getCloudCoverageValueLabel(value) {
        return (value + " %")
    }

    /* Fonction qui permet d'afficher ou non les images landsat8 */
    handleDisplayLandsatImgs(event, value) {
        this.setState({
            displayLandsatImgs: value
        });

        this.props.changeDisplayLandsatImgs(value);
    }

    renderFirstCard = () => {
        const {
            parcelMaxcc, inErrorMaxcc, editingMaxCloudy, displayLandsatImgs
        } = this.state;

    
        return (
            <Grid container spacing={2}>
                {/* ↓↓ Control taux d'ennuagement ↓↓ */}
                <Grid item xs={12} sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                    <Typography variant="subtitle1">{StringTranslate.tauxnuage}</Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                    <Slider sx={{ml: 2, right: 18, mt: 3}}
                        defaultValue={parcelMaxcc}
                        onChange={this.handleChange('parcelMaxcc')}
                        valueLabelFormat={this.getCloudCoverageValueLabel}
                        getAriaValueText={this.getCloudCoverageValueLabel}
                        value={(parcelMaxcc >= 0) ? parcelMaxcc : 0}
                        valueLabelDisplay="on"
                        aria-labelledby="discrete-slider-small-steps"
                        step={1}
                        min={0}
                        max={100}
                        marks={[
                            { value: 0, label: '0%' },
                            { value: 100, label: '100%' }]}
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
                    <InputLabel>{StringTranslate.tauxnuage}</InputLabel>
                    <TextField
                        fullWidth
                        // label={`${StringTranslate.tauxnuage}`}
                        value={(parcelMaxcc >= 0) ? parcelMaxcc : ''}
                        onChange={this.handleChange('parcelMaxcc')}
                        type="number"
                        margin="normal"
                        inputProps={{ min: "0", max: "100", step: "1" }}
                        variant="outlined"
                        error={inErrorMaxcc}
                        helperText={(inErrorMaxcc) ? `${StringTranslate.valeurtolere}` : ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                        <Button
                            type="submit"
                            variant="text"
                            color="error"
                            onClick={this.resetSettings}
                            disabled={!editingMaxCloudy}
                        >
                            {`${StringTranslate.annuler}`}
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={this.saveSettingsCarto}
                            disabled={!editingMaxCloudy || inErrorMaxcc}
                        >
                            {`${StringTranslate.enregistrer}`}
                        </Button>
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>


                {/* ↓↓ Partie sur l'affichage des images Landsat8 ↓↓ */}
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch 
                                color="primary"
                                checked={displayLandsatImgs}
                                onChange={(evt, value) => this.handleDisplayLandsatImgs(evt, value)}
                                inputProps={{ 'aria-label': 'primary checkbox' }} />
                        }
                        label={StringTranslate.displayLandsat8}
                        labelPlacement="end"
                    />

                </Grid>
            </Grid>
        );
    }

    renderSecondCard = () => {
        const { modulQualityIndex, modulQualityDetail, changingModulQuality, 
            useV4IsoXml, changingVersionIsoXml, editingShpDoseLabel, shpDoseLabel, inErrorShpLabel, messageError } = this.state;

        const withoutChange = (!changingModulQuality) && (!changingVersionIsoXml) & (!editingShpDoseLabel) ? true : false;
        return (
            <>
                {/* ↓ partie paramètrage modulation ↓ */}
                {/* dont partie - modulation (qualité d'image impactant sur le poids des polygones) */}

                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">{StringTranslate.qualityImagModul}</Typography>
                    </Grid>

                    {/* ↓ Qualité / précision des polygones ↓ */}
                    <Grid item xs={12}>
                        {/*Rq: la margue haute est de 4 pour laisser la place au tooltip de s'afficher au dessus de la barre de slide...*/}
                        <Slider sx={{ ml: 2, mt: 4, maxWidth: "calc(100% - 48px)" }}
                            defaultValue={modulQualityIndex} value={modulQualityIndex}
                            valueLabelFormat={this.getModulQualityLabel}
                            getAriaValueText={this.getModulQualityLabel}
                            valueLabelDisplay="on"
                            aria-labelledby="discrete-slider-small-steps"
                            step={1}
                            min={0}
                            max={3}
                            onChange={this.handleChangeModulationQuality}
                            marks={[
                                { value: 0, label: StringTranslate.minModulationQualityLabel },
                                { value: 1, label: "" },
                                { value: 2, label: "" },
                                { value: 3, label: StringTranslate.maxModulationQualityLabel }]}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body2" color="primary">{modulQualityDetail}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Stack direction="row" alignContent="center" sx={{ mt: 2, maxWidth: "calc(100% - 48px)" }}>
                            <Typography sx={{ alignSelf: 'center', pr: 2 }}>{StringTranslate.IsoXmlFileVersion}</Typography>
                            <ButtonGroup disableElevation variant='contained' disabled={false}>
                                <Button
                                    size='medium'
                                    onClick={() => this.handleChangeVersionIsoxml(true)}
                                    sx={{
                                        bgcolor: theme.palette.primary.main,
                                        color: theme.palette.common.white,
                                        opacity: "0.4",
                                        '&:disabled': {
                                            bgcolor: theme.palette.primary.main,
                                            color: theme.palette.common.white,
                                            opacity: "1",
                                        }
                                    }}
                                    disabled={(useV4IsoXml)}
                                >{'  V4  '}</Button>
                                <Button
                                    size='medium'
                                    onClick={() => this.handleChangeVersionIsoxml(false)}
                                    sx={{
                                        bgcolor: theme.palette.primary.main,
                                        color: theme.palette.common.white,
                                        opacity: "0.4",
                                        '&:disabled': {
                                            bgcolor: theme.palette.primary.main,
                                            color: theme.palette.common.white,
                                            opacity: "1",
                                        }
                                    }}
                                    disabled={(useV4IsoXml !== true)}
                                >{'  V3  '}</Button>
                            </ButtonGroup>
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <Stack direction="row" alignContent="center" sx={{ mt: 2, maxWidth: "calc(100% - 48px)" }}>
                            <Typography sx={{ alignSelf: 'top', pr: 2 }}>{StringTranslate.ShpDoseLabel}</Typography>
                            <TextField  sx={{ maxWidth: { xs: "80%", sm: "30%" }}}
                                value={shpDoseLabel}
                                onChange={this.handleChange('shpDoseLabel')} 
                                inputProps={{
                                    maxlength: maxCharsForDoseShpLabel
                                }}
                                margin="normal" variant="outlined" size="small" 
                                error={inErrorShpLabel}
                                helperText={messageError}
                            />
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <Button
                                type="submit"
                                variant="text"
                                color="error"
                                onClick={this.resetSettings}
                                disabled={withoutChange === true}
                            >
                                {`${StringTranslate.annuler}`}
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={() => this.saveSettingsModulation()}
                                disabled={(withoutChange === true) || inErrorShpLabel}
                            >
                                {`${StringTranslate.enregistrer}`}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </>
        );
    }

    render() {
        return (

            <Grid container spacing={3} sx={{ height: { sm: "fit-content" } }}>
                {/* Ma Cartographie */}
                <Grid item xs={12} md={6}>
                    <MainCard title={StringTranslate.myexploitation}>
                        {this.renderFirstCard()}
                    </MainCard>
                </Grid>

                {/* Ma Modulation */}
                <Grid item xs={12} md={6}>
                    <MainCard
                        title={StringTranslate.mymodulation}
                        secondary={
                            <IconButton 
                                size="small" 
                                onClick={() => this.props.showProfilMenuDialog(ProfilIndex.aide_ModulationResolutionCarte)}>
                                <HelpOutline />
                            </IconButton>
                        }
                    >
                        {this.renderSecondCard()}
                    </MainCard>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    settings: lodashGet(state, 'settingsData.settings', {}),
    parcelMaxcc: lodashGet(state, 'settingsData.settings.parcelMaxcc', 20),
    parcelDicoCounter: lodashGet(state, 'parcelsData.parcelDicoCounter', 0),
    deletingParcels: lodashGet(state, 'parcelsData.deleting', false),
    enumTypoClient: lodashGet(state, 'clientUserData.clientDatas.enumTypoClient', -1),
    modulQuality: lodashGet(state, 'settingsData.settings.imageModulationSizeQuality', DefaultModulationQualityPixelDef),
    buildIsoXmlV4Setting: lodashGet(state, 'settingsData.settings.buildIsoXmlV4', true),
    buildShpDoseLabel: lodashGet(state, 'settingsData.settings.buildShpDoseLabel', ''),
    displayLandsatImgs: lodashGet(state, 'settingsData.settings.displayLandsatImgs', true),
})

const mapDispatchToProps = dispatch => ({
    saveSettings: (settingsToSave) => dispatch( ActionSaveSettingsAsk(settingsToSave) ),
    deleteParcels: () => dispatch( ActionDeleteParcelsAsk() ),
    subscribe: () => dispatch( ActionGoToProfil() ),
    goToParcels: () => dispatch( ActionGoToParcels() ),
    changeDisplayLandsatImgs: (display) => dispatch( ActionDisplayLandsatImgs(display)),

    //Aide à la modulation
    showProfilMenuDialog: (index) => dispatch(ActionShowProfilMenuDialog(index)),

})
export default connect(mapStateToProps, mapDispatchToProps)(Parametres);