import StringTranslate from '../assets/i18n/stringLanguage.jsx';

export const liensArticles = {
    ble: "https://www.arvalis-infos.fr/view-36074-arvarticle.html",
    colza: "https://www.terre-net.fr/observatoire-technique-culturale/strategie-technique-culturale/article/fertilisation-azotee-comment-fertiliser-son-colza-en-2022-217-203437.html"
}

export const CULTURES = {
    ble: "ble",
    colza: "colza"
}

//Bornes des intervals de valeur accepter par l'algorithme
const intervalsValeurs = {
    prixUniteAzote: {
        min: 0.3,
        max: 2.7
    },
    ble: {
        min: 90,
        max: 320
    },
    colza: {
        min: 300,
        max: 650
    }
}

//Formule: diff azote = Pa * (a1 * Pb^6 + b1 * Pb^5 + c1 * Pb^4 + d1 * Pb^3 + e1 * Pb^2 + f1 * Pb + g1) + (a2 * Pb^6 + b2 * Pb^5 + c2 * Pb^4 + d2 * Pb^3 + e2 * Pb^2 + f2 * Pb + g2)
//Constantes utilisées dans le calcul de la dose d'azote optimal pour le blé
const CONSTANTES_BLE = {
    A1:-0.000000000001097648510059830000,
    A2:0.00000000000031284491916147100000,
    B1:0.000000001451562496628290000000,
    B2:-0.00000000033750809471043000000000,
    C1:-0.000000803709313440057000000000,
    C2:0.00000013967540338764500000000000,
    D1:0.000241779418811733000000000000,
    D2:-0.00002659577610662740000000000000,
    E1:-0.042817493557279400000000000000,
    E2:0.00188801788236187000000000000000,
    F1:4.501058067162610000000000000000,
    F2:0.07662870710615300000000000000000,
    G1:-266.6317615135930000000000000000,
    G2:27.2935257533395000000000000000000,
}

//Formule: y = Pa^3 * (Pc^4 * a1 + Pc^3 * a2 + Pc^2 * a3 + Pc * a4 + a5) + Pa^2 * (Pc^4 * b1 + Pc^3 * b2 + Pc^2 * b3 + Pc * b4 + b5) + Pa * (Pc^4 * c1 + Pc^3 * c2 + Pc^2 * c3 + Pc * c4 + c5) + (Pc^4 * d1 + Pc^3 * d2 + Pc^2 * d3 + Pc * d4 + d5)
//Constantes utilisées dans le calcul de la dose d'azote optimal pour le colza
const CONSTANTES_COLZA = {
    A1:-0.000000001108795666467310000000,
    A2:0.000002246593564653730000000000,
    A3:-0.001690352265839880000000000000,
    A4:0.562033133030443000000000000000,
    A5:-69.130818483556500000000000000000,
    B1:0.000000004254255010641760000000,
    B2:-0.000008625019775073470000000000,
    B3:0.006514387307791810000000000000,
    B4:-2.190213751398880000000000000000,
    B5:276.315001560682000000000000000000,
    C1:-0.000000004773955169351520000000,
    C2:0.000009873432367770880000000000,
    C3:-0.007718743203992240000000000000,
    C4:2.781826661903520000000000000000,
    C5:-422.661978916004000000000000000000,
    D1:0.000000000843727607042063000000,
    D2:-0.000001681941152678550000000000,
    D3:0.001253755261805270000000000000,
    D4:-0.423740159344634000000000000000,
    D5:71.969402225847400000000000000000
}

//Pa = prix de l'unité d'azote: prix engrais / (10*pourcentage azote)
//Pb = prix du blé
//Ua = unités/ha d'azote initial
//return unités/ha d'azote optimizée
const calculDoseAzoteOptiBle = (Pa, Pb, Ua) => {
    const {A1, A2, B1, B2, C1, C2, D1, D2, E1, E2, F1, F2, G1, G2} = CONSTANTES_BLE;
    const diffAzote = Pa * (A1 * Pb**6 + B1 * Pb**5 + C1 * Pb**4 + D1 * Pb**3 + E1 * Pb**2 + F1 * Pb + G1) + (A2 * Pb**6 + B2 * Pb**5 + C2 * Pb**4 + D2 * Pb**3 + E2 * Pb**2 + F2 * Pb + G2);
    return Ua + Math.round(diffAzote);
}

//Pa = prix de l'unité d'azote: prix engrais / (10*pourcentage azote)
//Pc = prix du colza
//Ua = unités/ha d'azote initial
//return unités/ha d'azote optimizée
const calculDoseAzoteOptiColza = (Pa, Pc, Ua) => {
    const {A1, A2, A3, A4, A5, B1, B2, B3, B4, B5, C1, C2, C3, C4, C5, D1, D2, D3, D4, D5} = CONSTANTES_COLZA;
    const diffAzote = Pa**3 * (Pc**4 * A1 + Pc**3 * A2 + Pc**2 * A3 + Pc * A4 + A5) + Pa**2 * (Pc**4 * B1 + Pc**3 * B2 + Pc**2 * B3 + Pc * B4 + B5) + Pa * (Pc**4 * C1 + Pc**3 * C2 + Pc**2 * C3 + Pc * C4 + C5) + (Pc**4 * D1 + Pc**3 * D2 + Pc**2 * D3 + Pc * D4 + D5);
    return Ua + Math.round(diffAzote);
}

//Gère la vérification des données + messages d'erreur
//return le message d'erreur et la valeur
const calculDoseAzoteOpti = (culture, prixVente, prixAchat, concentration, doseAzote) => {
    let returnResult = {
        value:null,
        msgError:undefined,
    };

    if(isNaN(prixAchat) || isNaN(prixVente) || isNaN(concentration) || isNaN(doseAzote) || (concentration === '0')) {
        returnResult.msgError = StringTranslate.calculDoseNitrogenErrorData;
        return returnResult;
    }

    const prixUniteAzote = prixAchat / (10*concentration)
    if(prixUniteAzote > intervalsValeurs.prixUniteAzote.max) {
        returnResult.msgError = StringTranslate.formatString(
            StringTranslate.calculDoseNitrogenErrorPurchaseCost, 
            {
                lowerOrHigherThan: StringTranslate.formatString(
                    StringTranslate.higherThan, 
                    intervalsValeurs.prixUniteAzote.max
                )
            }
        );
        return returnResult;
    }
    if(prixUniteAzote < intervalsValeurs.prixUniteAzote.min) {
        returnResult.msgError = StringTranslate.formatString(
            StringTranslate.calculDoseNitrogenErrorPurchaseCost, 
            {
                lowerOrHigherThan: StringTranslate.formatString(
                    StringTranslate.lowerThan, 
                    intervalsValeurs.prixUniteAzote.min
                )
            }
        );
        return returnResult;
    }

    switch (culture) {
        case CULTURES.ble:
            if((prixVente < intervalsValeurs.ble.min) || (prixVente > intervalsValeurs.ble.max)){
                returnResult.msgError = StringTranslate.formatString(
                    StringTranslate.calculDoseNitrogenErrorSellPrice, 
                    { 
                        culture: StringTranslate.calculDoseNitrogenTypeBle, 
                        priceMin: intervalsValeurs.ble.min, 
                        priceMax: intervalsValeurs.ble.max 
                    }
                );
                return returnResult;
            }
            returnResult.value = calculDoseAzoteOptiBle(prixUniteAzote, prixVente, parseInt(doseAzote));
            break;
        case CULTURES.colza:
            if((prixVente < intervalsValeurs.colza.min) || (prixVente > intervalsValeurs.colza.max)){
                returnResult.msgError = StringTranslate.formatString(
                    StringTranslate.calculDoseNitrogenErrorSellPrice, 
                    { 
                        culture: StringTranslate.calculDoseNitrogenTypeColza, 
                        priceMin: intervalsValeurs.colza.min, 
                        priceMax: intervalsValeurs.colza.max 
                    }
                );
                return returnResult;
            }
            returnResult.value = calculDoseAzoteOptiColza(prixUniteAzote, prixVente, parseInt(doseAzote));
            break;
        default:
            returnResult.msgError = StringTranslate.calculDoseNitrogenErrorCulture;
            return returnResult;
    }

    return returnResult;
}

export default calculDoseAzoteOpti;