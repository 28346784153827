import React from 'react';
import 'react-credit-cards/es/styles-compiled.css';

import { Button, Stack, CircularProgress } from '@mui/material';

import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import {
    injectStripe
} from 'react-stripe-elements';
import sendError from '../../utils/errorService.js';


class StripeSubscriptionProcess extends React.Component {
    constructor(props) {
        super(props);

        this.subscribeCustomer = this.subscribeCustomer.bind(this);
    }

    /* 
     * fonction - SOUSCRIPTION D'UN CUSTOMER À UN PLAN
     */
    subscribeCustomer() {
        const { subscriptionProcess } = this.props;

        let planId = (subscriptionProcess && subscriptionProcess.planId) ? subscriptionProcess.planId : null;
        if (!planId)  {
            sendError('stripeSubscribeProcess.subscribeCustomer-badPlan', { 'err': "retour de création du 'customer' Stripe invalide!", 'clientId': this.props.clientId } );
            this.props.addErrorToSubscriptionProcess( StringTranslate.errorCreateSubcribePlanIdVoid );

            this.props.handleNext();
            return;
        }
        let idPlanDiscounted = (subscriptionProcess && subscriptionProcess.discountPlanId) ? subscriptionProcess.discountPlanId : null;

        this.props.subscribeStripePlan(planId, idPlanDiscounted)
            .then((result) => { // result => souscription (success || failed) => dans tous les cas, l'abonnement est accepté
                //console.log('[subscribeCustomer - result]', 'souscrit !');
                this.props.handleNext(); // on passe à l'écran de succès - étape 4
            })
            .catch((err) => {
                sendError('stripeSubscribeProcess.subscribeCustomer-subscribeStripePlan', { 'err': err, 'clientId': this.props.clientId });
                this.props.addErrorToSubscriptionProcess(StringTranslate.errorStripeSubscribePlan);

                this.props.handleNext();
            });
    }

    render() {
        const { waitingWhileAskSubscribe } = this.props;

        return (
            <Stack direction="row" justifyContent="space-between">
                <Button 
                    type='submit'
                    variant="text" 
                    color="primary"
                    onClick={this.props.handleBack}
                >
                    {`${StringTranslate.revenir}`}
                </Button>
                
                <Stack direction="row">
                    {(waitingWhileAskSubscribe === true) && (<CircularProgress color='primary' variant='indeterminate' sx={{mr: 1}} />)}

                    <Button 
                        type='submit'
                        variant="contained" 
                        color="primary" 
                        onClick={this.subscribeCustomer} 
                        disabled={!this.props.checkedCGV || !this.props.creditCard || this.props.creditCard.isExpired}
                    >
                        {`${StringTranslate.confirmersub}`}
                    </Button>
                </Stack>
            </Stack>
        )
    }

}

export default injectStripe(StripeSubscriptionProcess);