import StringTranslate from '../assets/i18n/stringLanguage.jsx';
import { FixedStringTranslate } from '../assets/i18n/stringLanguage.jsx';

/**
 * Index de la colonne où se trouve le nom des parcelles pour Geofolia
 * @constant {Number}
 */
export const geofoliaNumColumnParcelName = 12;

/**
 * Nom de la colonne où se trouve le nom des parcelles pour telepac
 * @constant {String}
 */
export const telepacParcelNameColumn = 'NUMERO_P';

/**
 * Nom de la colonne où se trouve le nom des ilots pour telepac
 * @constant {String}
 */
export const telepacLotNameColumn = 'NUMERO_I';

//name : correspond au nom de la propriété de l'entité C# "DetailsParcel"
//libeleColumn : nom de la colonne affichée dans le tableau listant les parcelles
//numColumnGeofolia : index de la colonne pour récupérer les information peu importe la langue d'export de Geofolia

/**
 * Fonction permettant de retourner un tableau d'objet contenant les informations nécessaires pour la conversion des détails
 * elle est nécessaire pour la traduction
 * @returns {Array<Object>} Retourne un tableau d'objet contenant les informations nécessaires pour la conversion des détails de parcelles
 */
const dicoFunction = () => {
    let language = StringTranslate.getLanguage();
    FixedStringTranslate.setLanguage(language);
    
    return [
        {
            "name": "campagne",
            "libeleColumn": `${StringTranslate.libelecolumncampagne}`,
            "listName": FixedStringTranslate.listNameCampagne,
            "numColumnGeofolia": 4,
        },
        {
            "name": "culture",
            "libeleColumn": `${StringTranslate.libelecolumnculture}`,
            "listName": FixedStringTranslate.listNameCulture,
            "numColumnGeofolia": 20,
        },
        {
            "name": "variete",
            "libeleColumn": `${StringTranslate.libelecolumnvariete}`,
            "listName": FixedStringTranslate.listNameVariete,
            "numColumnGeofolia": 23,
        },
        {
            "name": "dateSemi",
            "libeleColumn": `${StringTranslate.libelecolumnsemis}`,
            "listName": FixedStringTranslate.listNameSemi,
            "dateSuffixe": FixedStringTranslate.dateSuffixeSemi,
            "dateFormat": FixedStringTranslate.dateFormatSemi,
            "numColumnGeofolia": 28,
        },
        {
            "name": "culturePrecedente",
            "libeleColumn": `${StringTranslate.libelecolumncultureprece}`,
            "listName": FixedStringTranslate.listNameCulturePrecedente,
            "numColumnGeofolia": 36,
        },
        {
            "name": "surface",
            "libeleColumn": `${StringTranslate.libelecolumnsurface}`,
            "listName": FixedStringTranslate.listNameSurface,
            "numColumnGeofolia": 13,
        },
        {
            "name": "exploitation",
            "libeleColumn": `${StringTranslate.libelecolumnexploitation}`,
            "listName": FixedStringTranslate.listNameExploitation,
            "numColumnGeofolia": 3,
        }
    ];
}

export default dicoFunction;