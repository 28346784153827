import { Box /*, Typography*/ } from '@mui/material';

// tab content
export function TabPanel({ children, value, index, prefixId, ...other }) { 
    const idCtrlValue = buildPanelId(prefixId, index);
    const idLbValue = buildLabelId(prefixId, index);

    return (
        <div role="tabpanel" hidden={value !== index} id={idCtrlValue} aria-labelledby={idLbValue} {...other}>
            {(value === index) && (
                <Box sx={{ p: { xs: 0, md: 3} }}>
                    {/* p: { xs: 0, md: 3} => padding de 0 si petit écran ; 3*8px dès les moyens écrans(et plus grand) */}
                    {children}
                </Box>
            )}
        </div>
    );
}

export function buildPropsForTab(prefixId, index) {
    const idCtrlValue = buildPanelId(prefixId, index);
    const idLbValue = buildLabelId(prefixId, index);

    return {
        id: idLbValue,
        'aria-controls': idCtrlValue
    };
}

function buildPanelId(prefixId, index) {
    let prefixIdUse = prefixId;
    if ((!prefixId) || (prefixId === '')) 
        prefixIdUse = 'simple';

    return `${prefixIdUse}-tbPl${index}`;
}

function buildLabelId(prefixId, index) {
    let prefixIdUse = prefixId;
    if ((!prefixId) || (prefixId === '')) 
        prefixIdUse = 'simple';

    return `${prefixIdUse}-tb${index}`;
}
