import { isNil as lodashIsNil, get as lodashGet } from 'lodash';
import { addYears as DateAddYears } from 'date-fns'

// WARNING Pour tout changement de cette classe, il faut modifier dans le back également (si la fonction existe) (ProcessSpotifarm/Utils/ModelisationHelper)
const ModelisationHelper = {
    /**
     * Fonction permettant de récupérer la campagne en cours en fonction de la culture
     * @param {ModelisationAvailableCrop} modelisationCrop 
     */
    getCurrentCampaign(modelisationCrop) {
        let now = new Date();

        let harvestDaySuggested = lodashGet(modelisationCrop, 'recolte.day', undefined);
        let harvestMonthSuggested = lodashGet(modelisationCrop, 'recolte.month', undefined);

        // Si on a pas d'info, on prend l'année civile actuelle
        if (lodashIsNil(harvestDaySuggested) || lodashIsNil(harvestMonthSuggested)) {
            return now.getFullYear();
        }

        // On prend en valeur minimal la date de récolte de l'année civile actuelle
        let harvestDate = new Date(now.getFullYear(), harvestMonthSuggested -1, harvestDaySuggested);
    
        // Si on est après la date de récolte de cette année civile
        // => la campagne est donc celle de l'année d'après
        if (now >= harvestDate) {
            harvestDate = DateAddYears(harvestDate, 1);
        }

        return harvestDate.getFullYear();
    },

    /**
     * Fonction permettant de récupérer la campagne en fonction de la date de semis
     * @param {Date} sowingDate Date de semis
     * @param {ModelisationAvailableCrop} modelisationCrop la culture de modélisation
     * @returns {number} L'année de campagne
     */
    getCampagneFromSowingDate(sowingDate, modelisationCrop) {
        let harvestDaySuggested = lodashGet(modelisationCrop, 'recolte.day', undefined);
        let harvestMonthSuggested = lodashGet(modelisationCrop, 'recolte.month', undefined);
        let yearOffset = lodashGet(modelisationCrop, 'semis.yearOffset', undefined);

        // Si on a pas d'info, on prend l'année de la date de semis - le offset d'année s'il est disponible (0 ou -1 donc soit +0 ou +1)
        if (lodashIsNil(harvestDaySuggested) || lodashIsNil(harvestMonthSuggested) || lodashIsNil(yearOffset)) {
            return sowingDate.getFullYear();
        }

        // On prend la date de récolte de la meme année civile que la date de semis
        let harvestDate = new Date(sowingDate.getFullYear(), harvestMonthSuggested -1, harvestDaySuggested);
        
        // Si la date de semis est après la date de récolte
        // => alors on prend la date de récolte de l'année suivante
        if (sowingDate >= harvestDate){
            harvestDate = DateAddYears(harvestDate, 1);
        }

        return harvestDate.getFullYear();
    },

    /**
     *  Fonction permettant de récupérer la date de semis par défaut d'une culture pour la campagne actuelle
     * @param {ModelisationAvailableCrop} modelisationCrop permet d'accéder à la date de semis recommandée (info provenant de la BdD)
     * @param {number} campaign campagne pour laquelle on veut sa date par défaut
     * @returns {Date} La date de semis par défaut de la culture
     */
    getDefaultSowingDate(modelisationCrop, campaign) {
        let now = new Date();

        let sowingDaySuggested = lodashGet(modelisationCrop, 'semis.day', undefined);
        let sowingMonthSuggested = lodashGet(modelisationCrop, 'semis.month', undefined);
        let yearOffset = lodashGet(modelisationCrop, 'semis.yearOffset', undefined);

        // Si on a pas d'info, on prend la date d'aujourd'hui
        if (lodashIsNil(sowingDaySuggested) || lodashIsNil(sowingMonthSuggested) || lodashIsNil(yearOffset)
            || lodashIsNil(campaign) || (campaign <= 0)) {
            return now;
        }

        // On prend en valeur par défaut la date de semis de la campagne actuelle
        let defaultSowingDate = new Date(campaign + yearOffset, sowingMonthSuggested -1, sowingDaySuggested);

        return defaultSowingDate;
    },

    /**
     *  Fonction permettant de récupérer la date de semis minimale d'une culture pour la campagne actuelle
     * @param {ModelisationAvailableCrop} modelisationCrop permet d'accéder à la date de semis recommandée (info provenant de la BdD)
     * @param {number} campaign campagne pour laquelle on veut sa date minimale
     * @returns {Date} La date de semis minimal de la culture (on ne gère pas la modélisation avec une date de semis plus ancienne)
     */
    getMinSowingDate(modelisationCrop, campaign) {
        let now = new Date();

        let harvestDaySuggested = lodashGet(modelisationCrop, 'recolte.day', undefined);
        let harvestMonthSuggested = lodashGet(modelisationCrop, 'recolte.month', undefined);

        // Si on a pas d'info, on prend une marge de 1 an.
        if (lodashIsNil(harvestDaySuggested) || lodashIsNil(harvestMonthSuggested) 
            || lodashIsNil(campaign) || (campaign <= 0)) {
            now = DateAddYears(now, -1);
            return now;
        }

        // On prend en valeur minimal la date de récolte de la campagne précédente
        let harvestDate = new Date(campaign -1, harvestMonthSuggested -1, harvestDaySuggested);

        return harvestDate;
    },

    /**
     *  Fonction permettant de récupérer la date de semis maximale d'une culture pour la campagne actuelle
     * @param {ModelisationAvailableCrop} modelisationCrop permet d'accéder à la date de semis recommandée (info provenant de la BdD)
     * @param {number} campaign campagne pour laquelle on veut sa date maximale
     * @returns {Date} La date de semis maximale de la culture (on ne gère pas la modélisation avec une date de semis plus récente)
     */
    getMaxSowingDate(modelisationCrop, campaign) {
        let now = new Date();

        let harvestDaySuggested = lodashGet(modelisationCrop, 'recolte.day', undefined);
        let harvestMonthSuggested = lodashGet(modelisationCrop, 'recolte.month', undefined);

        // Si on a pas d'info, on prend une marge de 1 an.
        if (lodashIsNil(harvestDaySuggested) || lodashIsNil(harvestMonthSuggested) 
            || lodashIsNil(campaign) || (campaign <= 0)) {
            now = DateAddYears(now, 1);
            return now;
        }

        // On prend en valeur maximale la date de récolte de a campagne
        let harvestDate = new Date(campaign, harvestMonthSuggested -1, harvestDaySuggested);

        return harvestDate;
    },

    /**
     *  Fonction permettant de récupérer la date de récolte par défaut d'une culture pour une campagne donnée
     * @param {ModelisationAvailableCrop} modelisationCrop permet d'accéder à la date de semis recommandée (info provenant de la BdD)
     * @param {number} campaign campagne pour laquelle on veut sa date de récolte par défaut
     * @returns {Date} La date de récolte par défaut de la culture
     */
    getDefaultHarvestDate(modelisationCrop, campaign) {
        let now = new Date();

        let harvestDaySuggested = lodashGet(modelisationCrop, 'recolte.day', undefined);
        let harvestMonthSuggested = lodashGet(modelisationCrop, 'recolte.month', undefined);

        // Si on a pas d'info, on prend une marge de 1 an.
        if (lodashIsNil(harvestDaySuggested) || lodashIsNil(harvestMonthSuggested) 
            || lodashIsNil(campaign) || (campaign <= 0)) {
            now = DateAddYears(now, 1);
            return now;
        }

        // On prend en valeur maximale la date de récolte de a campagne
        let harvestDate = new Date(campaign, harvestMonthSuggested -1, harvestDaySuggested);

        return harvestDate;
    },
    
    getDateFormated(date, campagneYear) {
        let dateToReturn = new Date((campagneYear + date.yearOffset), date.month-1, date.day)

        return dateToReturn
    },

    /* fonction permettant d'adapter l'item de modélisation reçu - le concept de normalisation des données est ici utilisé */
    convertItem(modelisationItem) {
        if (!modelisationItem) {
            return {};
        }

        //transforme la date (caîne en entité 'Date'):
        modelisationItem.sowingDate = (modelisationItem.sowingDate && (!isNaN(Date.parse(modelisationItem.sowingDate)))) ?
            new Date(modelisationItem.sowingDate) : null;
        modelisationItem.initialisationDate = (modelisationItem.initialisationDate && (!isNaN(Date.parse(modelisationItem.initialisationDate)))) ?
            new Date(modelisationItem.initialisationDate) : null;
      
        return modelisationItem;
    },

    /* fonction permettant de passer d'une liste à un dico - le concept de normalisation des données est ici utilisé */
    convertToDico(modelisationsList) {
        if ((!modelisationsList) || (!Array.isArray(modelisationsList))) {
            return {};
        }

        let dicoModelisation = {};
        modelisationsList.forEach(modelisationItem => {
            dicoModelisation[modelisationItem.id] = ModelisationHelper.convertItem(modelisationItem);
        });

        return dicoModelisation;
    },

    /* fonction permettant d'obtenir la modélisation d'une parcelle en fonction de son Id */
    getModelisationByParcelId(modelisationDico, parcelId) {
        return (Object.values(modelisationDico).find((modelisation) =>
            modelisation.parcelId === parcelId
          ));
    }
    
};

export default ModelisationHelper;