import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get as lodashGet } from 'lodash';

import { Grid, Button, Typography } from '@mui/material';
import { CalendarMonth } from '@mui/icons-material';

import { ActionShowProfilMenuDialog, ProfilIndex } from '../../redux/actions/contextApp';
import { ActionGoToStepOfModelisation, ModelisationSteps } from '../../redux/actions/modelisation';

import StringTranslate from '../../assets/i18n/stringLanguage';
import modelisationImage from '../../assets/images/modelisation/ModelisationImage.png';

///////////////////////////////////////////////////////////////////////////
// Composant affichant la première étape de la modélisation (incitation ou présentation)
///////////////////////////////////////////////////////////////////////////
// prop {boolean} incitation: permet de savoir si on est dans l'incitation (false => présentation de la modélisation)
class StartModelisation extends Component {
    onClickGoToNextStep = () => {
        const { goToNextStep } = this.props;
        goToNextStep();
    }

    onClickIncitation = () => {
        const { showProfilMenuDialog } = this.props;

        // Pour la V1, il faut contacter CDu pour avoir accès à la modélisation
        // TODO à remettre pour la V2
        //showProfilMenuDialog(ProfilIndex.abonnements);
        showProfilMenuDialog(ProfilIndex.contacterLeServiceClient);
    }

    render() {
        const { incitation } = this.props;
        return (
            <>
                <Grid>
                    <Grid container justifyContent="center" alignItems="center">
                        <Typography variant="h2">{StringTranslate.modelisationTutoStartTitle}</Typography>
                        <CalendarMonth fontSize="large" sx={{marginX:1}} />
                    </Grid>
                    <Grid container justifyContent="center" marginY={2}>
                        <Typography>{StringTranslate.modelisationTutoStartExplanation}</Typography>
                    </Grid>
                    <Grid container justifyContent="center" marginY={2}>
                        <img src={modelisationImage} alt="ModelisationImage" />
                    </Grid>
                    {(incitation)? 
                        <Grid container justifyContent="center" alignItems="center">
                            <Button color="primary" variant="contained" 
                                onClick={this.onClickIncitation}
                            >
                                {StringTranslate.modelisationTutoStartIncitation}
                            </Button>
                        </Grid>
                    :
                        <Grid container justifyContent="center" alignItems="center">
                            <Typography>{StringTranslate.modelisationTutoStartHowTo}</Typography>
                            <Button color="primary" variant="contained" 
                                onClick={this.onClickGoToNextStep}
                            >
                                {StringTranslate.modelisationTutoStartButton}
                            </Button>
                        </Grid>
                    }
                </Grid>
            </>
        );
    }
}

const mapStateToProps = state => ({

    language: lodashGet(state, 'settingsData.settings.language', StringTranslate.getLanguage()), // Permet de raffraichir le composant lors d'un changement de langue
});

const mapDispatchToProps = dispatch => ({
    showProfilMenuDialog: (index) => dispatch(ActionShowProfilMenuDialog(index)),

    goToNextStep: () => dispatch(ActionGoToStepOfModelisation(ModelisationSteps.CROP_CHOICE)),
});

export default connect(mapStateToProps,mapDispatchToProps)(StartModelisation);