import * as React from 'react';
import { connect } from 'react-redux';

/* Aides lodash pour obtention d'objet  dans un dico/savoir si un objet est vide/savoir si un objet est une date */
import lodashGet from 'lodash/get';
import lodashFind from 'lodash/find';

/* React components */
import CustomDataGrid from "../customDataGrid";
import { ThumbnailParcelShapeFromPathInfos } from '../thumbnail/ThumbnailParcelShape';
import AlertDialog from '../alertDialog';

/* React icons */
import FertilizationIcon from '../../assets/images/fertilization/fertilizationIcon';

/* Redux */
import { ActionGoToLastNitrogenInputsResults, ActionBuildLastNitrogenInputs, ActionClearLastNitrogenInputError } from '../../redux/actions/lastNitrogenInput';
import { TableType } from '../../redux/actions/settings.js';
import { ProfilIndex, ActionShowProfilMenuDialog } from "../../redux/actions/contextApp.js"
import { TabAbstractFertilizer, ActionSelectFertilizerTab } from '../../redux/actions/fertilizer';

/* mui components */
import { CircularProgress, Box, Button, Typography, Grid, TableContainer, Stack } from '@mui/material';
import DoneIcon from "@mui/icons-material/Done";
import Calculate from "@mui/icons-material/Calculate";
import { ErrorOutline } from '@mui/icons-material';


/* Helper */
import DateHelper from '../../utils/dateHelper';
import { ParcelsHelper } from '../../utils/parcelsHelper';

/* Traduction */
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

/* Lien de contact */
import LinkToContactUs from '../linkToContactUs';

/* theme */
import getTheme from "../../themes/index.js";
let theme = getTheme();


/*RQ: Ce composant affiche la liste des parcelles associées à un conseil azoté sur du blé, 
  mais elle se base pour cela sur la liste des derniers apports générables (dont certains peuvent déjà détenir un précédent résultat) */
class SelectParcelForLastNitrogenInput extends React.Component{
    constructor(props){
        super (props);

		const errorMessage = lodashGet(props, 'errorMessage', undefined);

        this.state = {
			rowsOfTable: this.loadContentTable(), //Lignes contenant les données des derniers apports
			parcelIdsOfLastInputNsSelected: [], //ID des parcelles associées aux derniers apports qui sont sélectionnées !
			openDialogOfErrors: (errorMessage !== undefined),
        };

		// Définition du popup contenant le message d'erreur à diffuser au client:
		this.popupErrorDialog = {
			getTitle: () => { return (<ErrorOutline />); },
			description: errorMessage, //Rq : sera mis à jour en fonction de la prop 'errorMessage' !
			getAdditionalDescription: () => {
				return (
					<Typography variant="subtitle1">
						{StringTranslate.errorDialogText2} <LinkToContactUs displayText={StringTranslate.contactUs} />. 
					</Typography>
				);
			},
			button: StringTranslate.close,
		};
		this.closeDialogOfErrors = this.closeDialogOfErrors.bind(this);

		this.columnsTableDefinition = this.getColumnsTable();
    };

	componentDidUpdate(prevProps) {
		const { errorMessage, lastNitrogenInputDicoIsLoaded, lastNitrogenInputDicoCounter, building } = this.props;
	 
		//gestion de cas d'erreur:
		if (((!prevProps) || (!prevProps.errorMessage) || (prevProps.errorMessage === '') || (prevProps.errorMessage === undefined)) &&
		(errorMessage && (errorMessage !== '') && (errorMessage !== undefined))) {
			this.popupErrorDialog.description = errorMessage;

			this.setState({
				openDialogOfErrors: true,
			});
		}

		//On alimente le tableau des derniers apports en cas de détection d'un changement:
		if (((prevProps.lastNitrogenInputDicoIsLoaded === false) && (lastNitrogenInputDicoIsLoaded === true)) ||
			(prevProps.lastNitrogenInputDicoCounter !== lastNitrogenInputDicoCounter) ||
			((prevProps.building === true) && (building === false))) {
			const rows = this.loadContentTable();

			this.setState({
				rowsOfTable: rows,
			});
		}
	}

	/* Ferme le dialog des messages d'erreur */
	closeDialogOfErrors = () => {
		const { clearLastNitrogenInputError } = this.props;

		this.setState({
			openDialogOfErrors: false,
		});

		clearLastNitrogenInputError();
	}

    //Titre des colonnes de la table des derniers apports
	getColumnsTable() {
		const columns = [
			{ headerName: 'id', field: 'id', hideable: false },
			{
				field: 'parcelName',
				minWidth: 180,
				hideable: false,
				renderHeader: (params) => {
					return (<Typography fontWeight='bold'>{StringTranslate.ColumnPclName}</Typography>)
				},
				renderCell: params => {
					//RQ: 'params.row.thumbnailInfos' peut accidentellement être undefined ; Ex: si par erreur, la Web API fournit un identifiant de parcelle qui ne devrait pas être référencé !
					return (
						<Box style={{ display: "flex", flexDirection: "row", alignItems: "center"  }} >
							{(params && params.row && params.row.thumbnailInfos) ? (
								<ThumbnailParcelShapeFromPathInfos id={`listItemTbl_${params.row.thumbnailInfos.parcelId}`} {...params.row.thumbnailInfos} />
							) : (undefined)}
							<Typography>{params.value}</Typography>
						</Box>
					)
				}
			},
			{
                field: "hasLastInputN",
                minWidth: 110,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography sx={{ textWrap: 'wrap', }} fontWeight='bold' >{StringTranslate.parcelHasLastInputN}</Typography>)
                },
                renderCell: params => (
                    <div style={{ cursor: "default", '&:hover': { cursor: "pointer" }, width: '100%', alignSelf: 'center', alignContent: 'center' }} 
						onClick={() => this.onClickShowLastInputN(params.row.id)}>
                        {(params.value === true) ?
                            <DoneIcon style={{ color: theme.palette.success.main }} />
                            : null}
                    </div>
				),
            },
			{
				field: 'crop',
				minWidth: 180,
				hideable: false,
				renderHeader: (params) => {
					return (<Typography fontWeight='bold'>{StringTranslate.libelecolumnculture}</Typography>)
				}
			},
			{
				field: 'parcelVariety',
				minWidth: 150,
				hideable: false,
				renderHeader: (params) => {
					return (<Typography fontWeight='bold' >{StringTranslate.libelecolumnvariete}</Typography>)
				},
			},
			{
				field: 'parcelCampaign',
				width: 100,
				type: "date",
				hideable: false,
				renderHeader: (params) => {
					return (<Typography fontWeight='bold' >{StringTranslate.libelecolumncampagne}</Typography>)
				},
			},
			{
				field: 'sowingDate',
				width: 110,
				type: "date",
				hideable: false,
				renderHeader: (params) => {
					return (<Typography fontWeight='bold'>{StringTranslate.sowingDate}</Typography>)
				}
			},
			{
				field: 'perfGoal',
				minWidth: 120,
				hideable: false,
				renderHeader: (params) => {
					return (<Typography sx={{ textWrap: 'wrap', }} fontWeight='bold'>{StringTranslate.perfGoal}</Typography>)
				}
			},
			{
				field: 'totalNDose',
				minWidth: 110,
				hideable: false,
				renderHeader: (params) => {
					return (<Typography fontWeight='bold'>{StringTranslate.totalNdose}</Typography>)
				},
			},
		];

		return columns;
	}
	
	//Données des derniers apports pour remplir le tableau
	loadContentTable() {
		const { lastNitrogenInputDico, lastNitrogenInputDicoCounter, culturesForFertilizing, thumbnailParcelDico } = this.props;

		let rowsOfTable = [];
	
		if (lastNitrogenInputDico && (lastNitrogenInputDicoCounter > 0) && culturesForFertilizing) {
			for (const key in lastNitrogenInputDico) {
				const lastNitrogenInputItem = lastNitrogenInputDico[key];
		
				if (lastNitrogenInputItem) {
					const totalNDose = lastNitrogenInputItem.fertiNitrogenTotal;

					if ((lastNitrogenInputItem.parcelId > 0) && (totalNDose > 0) && (!lastNitrogenInputItem.isArchived)) {
						let sowingDateStr = '';
						if (lastNitrogenInputItem.fertiSowingDate && (lastNitrogenInputItem.fertiSowingDate instanceof (Date))) {
							sowingDateStr = DateHelper.formati18n(lastNitrogenInputItem.fertiSowingDate, 'P');
						}
						else if (lastNitrogenInputItem.fertiSowingDate && (DateHelper.getDateFromString(lastNitrogenInputItem.fertiSowingDate) instanceof Date)) {
							sowingDateStr = DateHelper.formati18n(new Date(lastNitrogenInputItem.fertiSowingDate), 'P');
						}

						rowsOfTable.push({
							// Ceci est l'identifieur de ligne pour MUI
							// Comme l'id de l'entité LastNitrogenInput peut être à 0, nous avons besoin d'autre chose pour servir d'identifiant
							id: lastNitrogenInputItem.parcelId,
							lastNitrogenInputId: lastNitrogenInputItem.id,
							clientId: lastNitrogenInputItem.clientId,
							parcelId: lastNitrogenInputItem.parcelId,
							//ee...
							crop: lodashGet(lodashFind(culturesForFertilizing, ['cropType', lastNitrogenInputItem.fertiCropEnum]), 'name', ""), 
							parcelName: lastNitrogenInputItem.parcelName, //l'entité C# nous la donne !
							parcelVariety: lastNitrogenInputItem.variety,
							parcelCampaign: lastNitrogenInputItem.parcelCampaign,

							sowingDate: sowingDateStr,
							perfGoal: lastNitrogenInputItem.fertiPerformanceGoal,
							totalNDose: totalNDose,

							hasLastInputN: (lastNitrogenInputItem.id > 0) ? true : false,

							thumbnailInfos: ParcelsHelper.selectParcelFromDicoById(thumbnailParcelDico, lastNitrogenInputItem.parcelId),

							/* RQ, je n'ai pas besoin de cela ici, mais dans le second onglet !
							newPerfGoal: newPerfGoal,
							newTotalNDose: newTotalNDose,
							adjustment: adjustement,
							lastInputDate: lodashGet(lastNitrogenInputItem, 'creationDate', ""),
							*/
						});
					}
				}
			}	
		}
		
		return rowsOfTable;	
	}

    //Toolbar customisé du tableau comprenant :
    // - les boutons d'action suivants : Recalcul | @@
    // - le bouton d'aide
    customToolbar(props) {
		return (
            <Grid container spacing={2} >
                {/* Zone de recherche */}
                <Grid item xs={12}>
				<Grid container spacing={1}>
                        {/* Pour zone de recherche (au besoin futur) */}
                        <Grid item xs={6} sm={8} md={4} lg={4}>
							{/* vide */}
                        </Grid>

                        {/* Boutons d'action */}
                        <Grid item xs={6} sm={4} md={8}>
                            {/* Boutons en écran PC (>= md) */}
                            <Box sx={{ display: { md: 'block', xs: 'none' }, '& button': { m: 1 } }} style={{ textAlign: 'end' }} >
                                <Button
                                    variant="contained" color="primary"
                                    disabled={((props.lastInputNsSelected.length <= 0) || (props.buildingState === true))}
                                    onClick={(evt, data) => props.buildLastInputNs(evt, props.lastInputNsSelected)}
									startIcon={(props.buildingState === true) ? <CircularProgress size={20} color="inherit" /> : <Calculate />}
                                >
                                    {StringTranslate.buildLastInputNs}
                                </Button>
                            </Box>
                            {/* Boutons en écran Mobile (< md) */}
                            <Stack 
                                sx={{ display: { md: 'none', xs: 'block' }, '& button': { m: 1 }, pt:1 }} 
                                style={{ textAlign: 'center' }} 
                                direction="row" spacing={1}
                            >
                                <Button
                                    disabled={((props.lastInputNsSelected.length <= 0) || (props.buildingState === true))}
                                    variant="contained" color="primary"
                                    size="medium"
                                    aria-label="build Last input(s)"
                                    component="span"
                                    onClick={(evt, data) => props.buildLastInputNs(evt, props.lastInputNsSelected)}
									startIcon={(props.buildingState === true) ? <CircularProgress size={20} color="inherit" /> : <Calculate />}
                                >
									{StringTranslate.startCalculation}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
				
                {/* Bouton d'aide */}
                <Grid item xs={6}>
                	<Box sx={{ '& button': { m: 1 } }} style={{ textAlign: 'start' }} >
						<Button color="secondary" variant="text" onClick={() => props.showProfilMenuDialog()}>
							{StringTranslate.helpAsk}
						</Button>
					</Box>
                </Grid>
            </Grid>
        );
    }

    /* Redirection vers le dernier onglet (second en lien avec le dernier apport d'azote)
	afin de lui montrer les résultats du calcul déjà réalisés*/
	onClickShowLastInputN(idOfLastInputN) {
        const { goToResults } = this.props;

        // Etape d'affichage des résultats obtenues
        goToResults(idOfLastInputN);
    }

	setLastInputNsSelected = (newIdsOfLastInputNsSelected) => {
        this.setState({
            parcelIdsOfLastInputNsSelected: newIdsOfLastInputNsSelected,
        });
    }

	/* Lance la demande de (RE)calcul pour une à plusieurs parcelles, via les items de derniers apports fournis. */
    onBuildLastInputNs(event, selectedLastInputNs) { //A voir ce que contient 'selectedLastInputNs' (les IDs ???) !
        const { lastNitrogenInputDico, buildLastNitrogenInputs } = this.props;
		const { parcelIdsOfLastInputNsSelected } = this.state;

        let lastInputNsToBuild = [];
        parcelIdsOfLastInputNsSelected.forEach(itemParcelId => {
            let itemSelected = lodashGet(lastNitrogenInputDico, `[${itemParcelId}]`, undefined);
            if (itemSelected) {
                lastInputNsToBuild.push(itemSelected);
            }
        });

        if (buildLastNitrogenInputs && lastInputNsToBuild) {
			buildLastNitrogenInputs(lastInputNsToBuild);
		}
    }

	//Pour aller sur l'onglet pour débuter un conseil azoté
	onChangeTab = (event) => {
		const { setValueTabForAbstractFertilizer, } = this.props;
        setValueTabForAbstractFertilizer(TabAbstractFertilizer.NITROGEN_MANAGEMENT);
    }
	 
	render() {
		const { rowsOfTable, parcelIdsOfLastInputNsSelected, openDialogOfErrors } = this.state;
		const { pageSize, building, showProfilMenuDialog } = this.props;

		return(<>

			{/* ↓↓ Partie visuel - affichage Pop d'une erreur ↓↓ */}
			{(openDialogOfErrors === true) && (
				<AlertDialog popup={this.popupErrorDialog} handleChangePopup={this.closeDialogOfErrors} />
			)}

			{/* ↓↓ Partie visuel - Affichage texte explicatif + bouton OU tableau complet ↓↓ */}
			{(rowsOfTable.length === 0) ?
				<Box sx={{ '& button': { mt: 1 } }} style={{ textAlign: 'start' }} >
					<Typography>{StringTranslate.infosToStartLastInputN}</Typography>
					<Button
						variant="contained" color="primary"
						onClick={this.onChangeTab}
						startIcon={<FertilizationIcon sx={{stroke: theme.palette.common.white}}/>}
					>{StringTranslate.startPPF}</Button>
				</Box>
			:
				<TableContainer>
					<Grid style={{ width: '100%', textAlign: "center" }} item xs={12}>
						{/* partie tableau */}
						<CustomDataGrid
							disableSelectionOnClick={true}
							tableName={TableType.lastNitrogenInput}
							pageSize={pageSize}
							//onSelectionModelChange={}
							// Toolbar={this.CustomToolbar}
							columns={this.columnsTableDefinition}
							checkBoxActive={true}
							rows={rowsOfTable}
							Toolbar={this.customToolbar}
							toolbar={{
								showProfilMenuDialog: () => showProfilMenuDialog(ProfilIndex.aide_LastNitrogenInput),
								lastInputNsSelected: parcelIdsOfLastInputNsSelected,
								buildingState: building,
								buildLastInputNs: (evt, listItems) => this.onBuildLastInputNs(evt, listItems),
							}}
							selectionModel={parcelIdsOfLastInputNsSelected}
							onSelectionModelChange={(newParcelIdsOfLastInputNsSelected) => { //Sélection des biomasses par clic checkbox
								this.setLastInputNsSelected(newParcelIdsOfLastInputNsSelected);
							}}
						/>
					</Grid>
				</TableContainer>
			}
		</>)
	}
}


const mapStateToProps = state => ({
	lastNitrogenInputDico: lodashGet(state, 'lastNitrogenInputData.lastNitrogenInputDico', {}),
	lastNitrogenInputDicoCounter: lodashGet(state, 'lastNitrogenInputData.lastNitrogenInputDicoCounter', 0),
	lastNitrogenInputDicoIsLoaded: lodashGet(state, 'lastNitrogenInputData.lastNitrogenInputDicoIsLoaded', false),
	
    culturesForFertilizing: lodashGet(state, 'fertilizerData.culturesForFertilizing', []), 
    thumbnailParcelDico: lodashGet(state, 'parcelsData.thumbnailParcelDico', {}),

	errorMessage: lodashGet(state, 'lastNitrogenInputData.errorMessage', undefined),
	building: lodashGet(state, 'lastNitrogenInputData.building', false),

	pageSize: lodashGet(state, 'settingsData.settings.rowsPerPageForTableParcels', 20),
}); 

const mapDispatchToProps = dispatch => ({
	goToResults: (idOfLastInputN = -1) => dispatch( ActionGoToLastNitrogenInputsResults(idOfLastInputN) ),
    buildLastNitrogenInputs: (selectedLastInputNs) => dispatch( ActionBuildLastNitrogenInputs(selectedLastInputNs) ),
    setValueTabForAbstractFertilizer: (tab) => dispatch( ActionSelectFertilizerTab(tab) ),

	clearLastNitrogenInputError: () => dispatch(ActionClearLastNitrogenInputError()),
	
    showProfilMenuDialog: (index) => dispatch( ActionShowProfilMenuDialog(index) ),
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectParcelForLastNitrogenInput);
