///////////////////////////////////////////////////////////////////////////
// Import des éléments utilisés par le composant <HelpsForImportParcels>
///////////////////////////////////////////////////////////////////////////
import { Typography, Avatar, Grid } from "@mui/material";
import { Help, Create } from '@mui/icons-material';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import '../../assets/css/importParcels.css';
import { ProfilIndex } from "../../redux/actions/contextApp.js";

import getTheme from "../../themes/index.js";

let theme = getTheme();

//////////////////////////////////////////////////////////////////////////////
// Composant gérant les boutons d'aides pour importer de nouvelles parcelles.
//////////////////////////////////////////////////////////////////////////////
function handleClickToMap(goToMap) {
    if (goToMap) {
        goToMap();
    }
}

function handleClickToHelp(showProfilMenuDialog) {
    if (showProfilMenuDialog) {
        showProfilMenuDialog(ProfilIndex.aide_ImporterParcelles);
        // helpAsk - je laisse ce mot afin de retrouver les liens vers le menu d'aide plus facilement
    }
}

export function HelpsForImportParcels({ showProfilMenuDialog, goToMap }) {
    return (
        <>
            <Grid container spacing={2} sx={{ mt: 1 }} onClick={() => { handleClickToHelp(showProfilMenuDialog) }}>
                <Grid item xs={2} sm={1} md={1}>
                    <Avatar
                        variant="rounded"
                        color="inherit"
                        sx={{ bgcolor: theme.palette.primary, color: theme.palette.common.white, ml: 'auto', cursor:"pointer" }}>
                        <Help />
                    </Avatar>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Typography variant="h4" sx={{ mb: 0, cursor:"pointer" }}>
                        {`${StringTranslate.importmenuaide}`}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2, cursor:"pointer" }}>
                        {`${StringTranslate.aideimport}`}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 1 }} onClick={() => handleClickToMap(goToMap)}>
                <Grid item xs={2} sm={1} md={1}>
                    <Avatar
                        variant="rounded"
                        color="inherit"
                        sx={{ bgcolor: theme.palette.primary, color: theme.palette.common.white, ml: 'auto', cursor:"pointer" }}>
                        <Create />
                    </Avatar>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Typography variant="h4" sx={{ mb: 0, cursor:"pointer" }}>
                        {`${StringTranslate.choixdrawtitle}`}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2, cursor:"pointer" }}>
                        {`${StringTranslate.aideimportredirect}`}
                    </Typography>
                </Grid>
            </Grid>


        </>
    );
}

export default HelpsForImportParcels;