import PropTypes from 'prop-types';
import isValid from 'date-fns/isValid';
import { ObservationImage } from './observationImage';

/**
 * Objet observation
 */
export class Observation {
    constructor(props) {
        this.id = props.id || -1;
        this.clientId = props.clientId || -1;
        this.parcelId = props.parcelId || -1;
        this.isActive = ((props.isActive === true) || (props.isActive === false)) ? props.isActive : true;
        this.observationDate = (isValid(new Date(props.observationDate))) ? new Date(props.observationDate) : new Date();
        this.observationType = props.observationType || null;
        this.description = props.description || null;
        this.geometryType = props.geometryType || null;
        this.featureStr4326 = props.featureStr4326 || null;
        this.superficieHa = props.superficieHa || 0;
        this.images = props.images || [];
    }
}

Observation.propTypes = {
    clientId: PropTypes.number,
    parcelId: PropTypes.number,
    observationDate: PropTypes.objectOf(Date),
    observationType: PropTypes.string,
    description: PropTypes.string,
    geometryType: PropTypes.string,
    featureStr4326: PropTypes.string,
    superficieHa: PropTypes.number,
    images: PropTypes.arrayOf(PropTypes.objectOf(ObservationImage))
};