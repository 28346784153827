import React, { Component } from 'react';
import Login from './components/login.jsx';
import { StatesConnection } from './redux/actions/connection.js';
import Dashboard from './views/dashboard/dashboard.jsx';

/**
 * Premier composant react.js - Point d'entrée application REACT
 */
class App extends Component {   
    /* fonction cycle de vie */
    render() {
        const { stateConnexion, history } = this.props;
        return (stateConnexion === StatesConnection.connected) ? <Dashboard history={history} /> : <Login {...this.props} />
	}
}

export default App;