import React from "react";
import { connect } from 'react-redux';
import RenderMaps from "./renderMaps";
import MapInfo from '../../components/mapInfo';
import LoadingContent from '../../components/loadingContent';
import DisplayIncitationFreemiumWithShp from '../../components/marketing/displayIncitationFreemiumWithShp.jsx';
import DisplayIncitationExplorerBeforeEnd from '../../components/marketing/displayIncitationExplorerBeforeEnd.jsx';
import DisplayIncitationToUseGlobalLayer from '../../components/marketing/displayIncitationToUseGlobalLayer.jsx';
import DisplayIncitationToUsePromoCode from '../../components/marketing/displayIncitationToUsePromoCode.jsx';
import DisplayIncitationExplorerEnded from '../../components/marketing/displayIncitationExplorerEnded.jsx';
import PinDropFormDialog from '../../components/carnetDePlaine/PinDropFormDialog/PinDropFormDialog.jsx';
import {
    ActionGoToProfil,
    ActionCloseInviteToPremium, ReasonInviteToPremium,
    ActionSetCurrentPageName,
    PageName,
    ActionShowProfilMenuDialog
} from '../../redux/actions/contextApp.js';
import { ActionAskFirstHistoForAllParcel, ActionSetOnMapTheLastSelectedSatImages } from '../../redux/actions/satImage.js';
import { ActionSaveSettingsAsk } from '../../redux/actions/settings.js';
import { ActionStartDidacticielFirstParcel, StepEnumDidacticielFirstParcel } from '../../redux/actions/didacticiel.js';

import '../../assets/css/rm-render_maps.css';
import IdentificationDialog from "../../components/carnetDePlaine/identification/IdentificationDialog";

///////////////////////////////////////////////////////////////////////////
// Composant général concernant notre carte (gestion et affichage).
///////////////////////////////////////////////////////////////////////////
class AbstractMaps extends React.Component {

    UNSAFE_componentWillMount(prevProps) { // TODO : attention méthode dépréciée
        const {
            hasDeletedAllParcels,
            setCurrentPageName,
            startDidacticiel,
            isTutorialFirstParcelOpened
        } = this.props;

        if ((this.props.currentPageName === PageName.ImportSlots) && !(isTutorialFirstParcelOpened) &&
            hasDeletedAllParcels) {
            /* Suppression du parcellaire TOTAL */
            /* L'utilisateur doit revoir le didacticiel en commençant par l'étape de choix */
            setCurrentPageName(PageName.TutorialFirstParcel, true);
            startDidacticiel(StepEnumDidacticielFirstParcel.CHOIX);
        }
        /*else {
            setCurrentPageName(PageName.Map, false);
        }*/ //Si on arrive ici, c'est qu'on l'a demandé... pas besoin de se rappeler soi-même.
    }

    componentDidMount() {
        const { isTutorialFirstParcelOpened, setCurrentPageName, startDidacticiel, 
            setOnMapTheLastSelectedSatImages, theLastSelectedSatImageToSetOnMapCounter, theLastSelectedSatImageToSetOnMapDico } = this.props;

        if (isTutorialFirstParcelOpened === true) {
            setCurrentPageName(PageName.TutorialFirstParcel, true);
            startDidacticiel();
        }

        /* Si un conseil azoté a été fait auparavant et qu'une date d'image (entrée ou sortie d'hiver) a été choisie,
        on doit refournir les bonnes dates d'images des parcelles sur la carte */
        if ((theLastSelectedSatImageToSetOnMapCounter > 0) || (Object.keys(theLastSelectedSatImageToSetOnMapDico).length > 0)) {
            setOnMapTheLastSelectedSatImages();
        }
    }

    /* fonction cycle de vie react */
    render() {
        const { reasonInviteToPremium, globalLayerOnLoading, pinDropFormDialogOpened, pinDropFormDialogDatas,
            identificationDialogOpened } = this.props;

        return (
        <>
                {/* Partie haute - partie rendu cartographique */}
        
                {/* ↓↓ composant d'affichage de l'incitation au passage au mode Premium ↓↓ */}
                {/* uniquement si client vient de lancer une action/fonctionnalité non-autorisée */}
                <React.Fragment>
                    {(reasonInviteToPremium === ReasonInviteToPremium.StartExplorer) && (<DisplayIncitationFreemiumWithShp {...this.props} />)}
                    {(reasonInviteToPremium === ReasonInviteToPremium.NearEndExplorer) && (<DisplayIncitationExplorerBeforeEnd {...this.props} />)}
                    {((reasonInviteToPremium === ReasonInviteToPremium.ComebackFreemium) || (reasonInviteToPremium === ReasonInviteToPremium.NoLimitParcels)) &&
                        (<DisplayIncitationExplorerEnded {...this.props} />)}
                    {(reasonInviteToPremium === ReasonInviteToPremium.LayersGlobal) && (<DisplayIncitationToUseGlobalLayer {...this.props} />)}
                    {(reasonInviteToPremium === ReasonInviteToPremium.ShowPromo) && (<DisplayIncitationToUsePromoCode {...this.props} />)}
                </React.Fragment>

                {/* ↓↓ composant d'affichage d'informations (loading, progress bar, ...) - position en rapport avec la carte (relative-absolute) ↓↓ */}
                <MapInfo />

                {/* ↓↓ composant carte ↓↓ */}
                <RenderMaps />

                {/* ↓↓ composant d'affichage du formulaire de création d'observation - utilisation smartphone ↓↓ */}
                {((pinDropFormDialogOpened === true) && pinDropFormDialogDatas) && (<PinDropFormDialog pinDropFormDialogDatas={pinDropFormDialogDatas} />)}

                {/* ↓↓ composant d'affichage pour l'identification - utilisation smartphone ↓↓ */}
                {(identificationDialogOpened === true) && (<IdentificationDialog/>)}

                {/* ↓↓ Partie - CHARGEMENT EN COURS (pas de planSouscription ou bien pas encore d'informations client) - (contenu: SPINNER + fond grisé) ↓↓ */}
                {(globalLayerOnLoading === true) && <LoadingContent />}

          </>
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = function (state) {
    return {
        clientDatas: state.clientUserData.clientDatas,
        masterEmail: state.clientUserData.clientDatas.masterEmail,
        //isFirstDisplayGeofoliaFah: state.contextAppData.isFirstDisplayGeofoliaFah,

        settings: state.settingsData.settings,
        showNdviCharts: state.settingsData.settings.showNdviCharts,

        modulationDico: (state && state.modulationsData) ? state.modulationsData.modulationDico : undefined,
        biomassDico: (state && state.biomassData) ? state.biomassData.biomassDico : undefined,

        //Infos provenant du reducer 'contexte':
        //showDisplayGeofoliaFah: (state && state.contextAppData) ? state.contextAppData.showDisplayGeofoliaFah : false,

        globalLayerOnLoading: (state && state.contextAppData) ? state.contextAppData.globalLayerOnLoading : false,

        reasonInviteToPremium: (state && state.contextAppData) ? state.contextAppData.reasonInviteToPremium : null,
        discountCodeForInviteToPremium: (state && state.contextAppData) ? state.contextAppData.discountCodeForInviteToPremium : null,
        pvLinkActivated: (state && state.clientUserData) ? state.clientUserData.pvLinkActivated : null,
        satimageIdSelectedOfParcel: (state && state.contextAppData) ? state.contextAppData.satimageIdSelectedOfParcel : -1,
        
        pinDropFormDialogOpened: state.contextAppData.pinDropFormDialogOpened,
        pinDropFormDialogDatas: state.contextAppData.pinDropFormDialogDatas,

        burgerMenuIsOpen: (state && state.contextAppData) ? state.contextAppData.burgerMenuIsOpen : false,
        parcelIdSelected: state.contextAppData.parcelIdSelected,

        identificationDialogOpened: state.contextAppData.identificationDialogOpened,

        currentPageName: state.contextAppData.CurrentPageName,
        hasDeletedAllParcels: state.parcelsData ? state.parcelsData.hasDeletedAllParcels : false,
        isTutorialFirstParcelOpened: (state && state.didacticielData) ? state.didacticielData.firstparcel.isOpened : false,

        //Nombre de dates d'images de parcelles à refournir quand on retourne sur la carte
        theLastSelectedSatImageToSetOnMapCounter: (state && state.satimageData) ? state.satimageData.theLastSelectedSatImageToSetOnMapCounter : 0,
        theLastSelectedSatImageToSetOnMapDico: (state && state.satimageData) ? state.satimageData.theLastSelectedSatImageToSetOnMapDico : {},
    };
}

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
    askFirstHistoForAllParcel: () => dispatch(ActionAskFirstHistoForAllParcel()),
    updateSettings: (settingsToUpdate) => dispatch(ActionSaveSettingsAsk(settingsToUpdate)),

    //nécessaire pour 'DisplayIncitationPremium':
    goToProfil: () => dispatch(ActionGoToProfil()),
    showProfilMenuDialog: (index) => dispatch(ActionShowProfilMenuDialog(index)),
    closeInviteToPremium: (userChoice) => dispatch(ActionCloseInviteToPremium(userChoice)),

    //nécessaire pour renseigner le nom de la page en cours
    setCurrentPageName: (pageName, showTutorialFirstParcel) => dispatch(ActionSetCurrentPageName(pageName, showTutorialFirstParcel)),
    startDidacticiel: (step) => dispatch(ActionStartDidacticielFirstParcel(step)),
    setOnMapTheLastSelectedSatImages: () => dispatch(ActionSetOnMapTheLastSelectedSatImages()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AbstractMaps);