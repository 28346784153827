import React from 'react';
import { connect } from 'react-redux';

import { Tooltip, Fab, Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Hidden from '@mui/material/Hidden';

import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

import '../../assets/css/dp-drawParcel.css';

import getTheme from "../../themes/index.js";

let theme = getTheme();

/**
 * composant bouton permettant de zoomer/dézoomer sur la carte
 */
function ZoomButtonsControl(props) {

    /**
     * fonction callback lors du zoom sur la carte
     */
    const handleClickZoomIn = (event) => {
        if (event)
            event.stopPropagation(); // permet d'éviter que l'événement clique ne soit remonté à la carte (évite de zoomer)

        props.renderMapsCmp.map.zoomIn();
    }

    /**
     * fonction callback lors du dézoome sur la carte
     */
    const handleClickZoomOut = (event) => {
        if (event)
            event.stopPropagation(); // permet d'éviter que l'événement clique ne soit remonté à la carte (évite de zoomer)

        props.renderMapsCmp.map.zoomOut();
    }

    return (
        /* Les boutons de zoom et dézoom */
        /* ↓ boutons - zoom - dézoom : ne s'affiche pas en mode mobile ↓ */
        <Hidden smDown>
            <Box 
                className='leaflet-control'
                sx={{ marginRight:"10px", zIndex:"1100" }}
            >
                {/* ↓ bouton - zoom ↓ */}
                <div>
                    <Tooltip title={<Typography color="inherit">{StringTranslate.zoomer}</Typography>} placement='left'>
                        <Fab 
                            size="small" 
                            onClick={handleClickZoomIn}
                            sx={{
                                backgroundColor: theme.palette.common.white,
                                color:theme.palette.primary.main,
                                borderBottomRightRadius:"0%",
                                borderBottomLeftRadius:"0%",
                                height:"30px"
                            }}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </div>

                {/* ↓ bouton - dézoom ↓ */}
                <div>
                    <Tooltip title={<Typography color="inherit">{StringTranslate.dezoomer}</Typography>} placement='left'>
                        <Fab 
                            size="small" 
                            onClick={handleClickZoomOut}
                            sx={{
                                backgroundColor: theme.palette.common.white,
                                color:theme.palette.primary.main,
                                borderTopRightRadius:"0%",
                                borderTopLeftRadius:"0%",
                                height:"30px",
                                boxShadow:"none"
                            }}
                        >
                            <RemoveIcon />
                        </Fab>
                    </Tooltip>
                </div>
            </Box>
        </Hidden>
    )
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant */
const mapStateToProps = function (state) {
    return {
        settings: state.settingsData.settings, // seul moyen trouvé pour rafraichir le visuel (traduction) du fait que maintenant on rentre dans l'application sans attendre les informations du client
    };
}

export default connect(mapStateToProps, null)(ZoomButtonsControl);