import { Component } from "react";
import { TableType } from "../../redux/actions/settings";
import CustomDataGrid from "../customDataGrid";
import { connect } from "react-redux";

import { get as lodashGet, isNil as lodashIsNil, filter as lodashFilter, } from 'lodash';

import { UpdateNbRowsPerPageTable } from "../../redux/actions/settings";
import { ActionGoToStepOfModelisation, ActionInitModelisation, ActionSaveChosenParcels,  } from "../../redux/actions/modelisation";
import { ModelisationSteps } from "../../redux/actions/modelisation";
import { ActionShowProfilMenuDialog, ProfilIndex } from '../../redux/actions/contextApp';
import { ParcelsHelper } from "../../utils/parcelsHelper";
import ModelisationHelper from "../../utils/modelisationHelper";

import { InfoOutlined, Delete } from "@mui/icons-material";
import { Typography, Button, Box, Grid, Card, Alert, FormControl, IconButton} from "@mui/material";
import { ThumbnailParcelShapeFromPathInfos } from "../thumbnail/ThumbnailParcelShape";
import StringTranslate from "../../assets/i18n/stringLanguage";
import CustomDatePicker  from '../customDatePicker.jsx';

/* DatePicker */
import frLocale from "date-fns/locale/fr";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-GB";

class ModelisationSpecificInfosManagement extends Component {
    constructor(props) {
        super(props);

        this.currentCampaign = ModelisationHelper.getCurrentCampaign(props.cultureSelected);
        this.columns = this.initialiseColumns();
        this.minSowingDate = ModelisationHelper.getMinSowingDate(props.cultureSelected, this.currentCampaign);
        this.maxSowingDate = ModelisationHelper.getMaxSowingDate(props.cultureSelected, this.currentCampaign);
        this.sowingDateMsgError = StringTranslate.formatString(StringTranslate.modelisationSowingDateError, this.currentCampaign);

        this.state = { 
            tableGridDatas: this.initialiseTableGridDatas(),
            commonSowingDate: ModelisationHelper.getDefaultSowingDate(props.cultureSelected, this.currentCampaign),

            parcelsSelected: [], //parcelles sélectionnées par les checkbox

            commonSowingDateError: false,
        };

        this.localMap = ((StringTranslate.getLanguage() === 'fr-FR' || StringTranslate.getLanguage() === 'fr-BE') ? frLocale : (StringTranslate.getLanguage() === "en-GB" ? enLocale : esLocale)); //Concerne la traduction des datePicker

    }

    shouldComponentUpdate(nextProps, nextState) {
        const { language } = this.props;

        // Si la langue de l'appli à changer, on met à jour les colonnes
        if (language !== nextProps.language) {
            this.columns = this.initialiseColumns();
            this.localMap = ((StringTranslate.getLanguage() === 'fr-FR' || StringTranslate.getLanguage() === 'fr-BE') ? frLocale : (StringTranslate.getLanguage() === "en-GB" ? enLocale : esLocale)); //Concerne la traduction des datePicker
        }

        return true;
    }

    /**
     * Méthode permettant de lancer l'initialisation des modélisations
     */
    handleInitModelisation = () => {
        const { initModelisation, clientId, cultureSelected } = this.props;
        const { tableGridDatas } = this.state;
        
        // Création des entités Modélisation qui seront envoyé au back
        let modelisations = tableGridDatas.map( (data) =>
            ({
                clientId: clientId, 
                parcelId: data.id,
                cropModel: lodashGet(cultureSelected, 'cropType', 0),
                sowingDate: data.dateSemis, 
                campagne: ModelisationHelper.getCampagneFromSowingDate(data.dateSemis, cultureSelected),
            })
        );

        initModelisation(modelisations);
    }

    /**
     * Méthode permettant de savoir s'il reste des parcelles sans date de semis
     * Permet de savoir si on peut lancer l'initialisation
     */
    isAllSowingDateValid() {
        const { tableGridDatas } = this.state;       

        return tableGridDatas.every(m => (m.isSowingDateValid === true));
    }

    /**
     * Méthode permettant de tester si une date de semis est valide ou non
     * @param {Date} sowingDate date de semis
     */
    isSowingDateValid(sowingDate) {
        return ((!lodashIsNil(sowingDate)) && (sowingDate >= this.minSowingDate) && (sowingDate <= this.maxSowingDate));
    }
    
    /**
     * Méthode permettant de mettre à jour la date de semis commune ayant été saisie par le user
     * @param {Date} newCommonSowingDate Nouvelle valeur de la date de semis, saisie, commune
     */
    handleChangeCommonSowingDate(newCommonSowingDate) {
        this.setState({
            commonSowingDate: newCommonSowingDate,
            commonSowingDateError: !this.isSowingDateValid(newCommonSowingDate),
        });
    }

    /**
     * Méthode permettant de mettre à jour la date de semis d'une parcelle
     * @param {number} parcelId id de la parcelle pour laquelle il faut changer la date de semis
     * @param {Date} newSowingDate nouvelle date de semis
     */
    handleChangeSowingDate(parcelId, newSowingDate) {
        const { tableGridDatas } = this.state;

        let newTableGridDatas = [...tableGridDatas];
        let dataToUpdate = newTableGridDatas.find(d => d.id === parcelId);
        dataToUpdate.dateSemis = newSowingDate;
        dataToUpdate.isSowingDateValid = this.isSowingDateValid(newSowingDate);


        this.setState({
            tableGridDatas: newTableGridDatas
        });
    } 

    /**
     * Méthode permettant de mettre à jour toutes les dates de semis des modélisations avec la saisie commune
     */
    handleAllSowingDateChange() {
        const { commonSowingDate, tableGridDatas } = this.state;

        let newTableGridDatas = [...tableGridDatas];
        newTableGridDatas.forEach(d => {
            d.dateSemis = commonSowingDate;
            d.isSowingDateValid = this.isSowingDateValid(commonSowingDate);
        });
        
        this.setState({
            tableGridDatas: newTableGridDatas
        });
    }

    /**
     * Méthode permettant de mettre à jour les dates de semis des modélisations n'en possédant pas (avec la date de saisie commune)
     */
    handleSowingDateChangeForDateMissing() {
        const { commonSowingDate, tableGridDatas } = this.state;

        let newTableGridDatas = [...tableGridDatas];
        newTableGridDatas.forEach(d => {
            if (d.dateSemis === null) {
                d.dateSemis = commonSowingDate;
                d.isSowingDateValid = this.isSowingDateValid(commonSowingDate);
            }
        });
        
        this.setState({
            tableGridDatas: newTableGridDatas
        });
    }

    /**
     * Méthode permettant d'initialiser les lignes qui seront présentes dans le tableau
     */
    initialiseTableGridDatas() {
        const {parcelDico, thumbnailParcelDico, parcelIdsToModelise } = this.props;
        const parcels = ParcelsHelper.getParcelsDicoFromParcelIdList(parcelDico, parcelIdsToModelise);
        let newDatas = [];

        if (!parcelIdsToModelise) {
            return newDatas;
        }

        for (let itemPropName in parcels) {
            const parcel = parcels[itemPropName];

            if (!parcel) continue;

            let sowingDateStr = lodashGet(parcel, "details.dateSemi", null);
            let sowingDate = null;
            if (sowingDateStr !== null) {
                sowingDate = new Date(sowingDateStr);
            }

            newDatas.push({
                id: parcel.id,
                parcel: parcel,

                name: parcel.name,
                thumbnailInfos: ParcelsHelper.selectParcelFromDicoById(thumbnailParcelDico, parcel.id),
                
                dateSemis: sowingDate,
                variete: lodashGet(parcel, "details.variete", null),
                exploitation: lodashGet(parcel, "details.exploitation", null),
                surface: lodashGet(parcel, "details.surface", null),

                isSowingDateValid: this.isSowingDateValid(sowingDate),
            });
        }
        return newDatas;
    }
    
    /**
     * Méthode permettant d'initialiser les colonnes du tableau
     */
    initialiseColumns () {
        // liste des colonnes
        // Colonne ID
        // Colonne nom : nom de la parcelle avec thumbnail
        // Colonne date de Semis    => modifiable
        // Colonne variete : variete
        // Colonne exploitation : nom exploit
        // Colonne surface

        const { parcelIdsToModelise } = this.props;

        let newColumns = [];

        if (parcelIdsToModelise.length === 0) return newColumns;

        /* ↓ ajout colonne non visible - id de la parcelle ↓ */
        // Colonne ID
        newColumns.push({ headerName: "Id", field: "id", editable: true, hideable: true });

        // Colonne nom (Miniature + nom de la parcelle)
        newColumns.push({
            headerName: `${StringTranslate.nomcolumn}`,
            field: "name",
            minWidth: 180,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.nomcolumn}</Typography>)
            },
            renderCell: params => {
                return (<Box style={{ display: "flex", flexDirection: "row", alignItems: "center"  }} >
                    {(params) ? (<ThumbnailParcelShapeFromPathInfos id={`listItemTbl_${params.row.thumbnailInfos.parcelId}`} {...params.row.thumbnailInfos} />) : (undefined)}
                    <Typography>{params.value}</Typography>
                </Box>)
            }
        });

        // Colonne date Semis
        newColumns.push({
            headerName: "dateSemis",
            field: "dateSemis",
            minWidth: 300,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.libelecolumnsemis}</Typography>)
            },
            renderCell: params => {
                return (
                    <Box 
                        style={{ display: "flex", flexDirection: "row", alignItems: "center"  }} 
                        sx={{width:"100%"}}
                    >
                        <FormControl>
                            <CustomDatePicker
                                label={StringTranslate.libelecolumnsemis}
                                value={(params.value !== null) ? new Date(params.value) : null}
                                onChange={(newValue) => this.handleChangeSowingDate(params.row.id, newValue)}
                                helperText={((params.value === null) || params.row.isSowingDateValid) ? null : this.sowingDateMsgError}
                                placeholder={StringTranslate.formatDatePlaceHolder}
                                minDate={this.minSowingDate}
                                maxDate={this.maxSowingDate}
                                size={'small'}
                            />
                        </FormControl>
                    </Box>
                )
            }
        });

        // Colonne culture
        newColumns.push({
            headerName: "variete",
            field: "variete",
            minWidth: 180,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.libelecolumnvariete}</Typography>)
            }
        });

        // Colonne exploitation
        newColumns.push({
            headerName: "exploitation",
            field: "exploitation",
            minWidth: 180,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.libelecolumnexploitation}</Typography>)
            }
        });
        
        // Colonne Surface
        newColumns.push({
            headerName: "surface",
            field: "surface",
            width: 180,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.libelecolumnsurface}</Typography>)
            },
        });

        return newColumns;

    }

    /**
     * Méthode permettant de revenir à l'étape précédente
     */
    handleBackToBeginningModelisation = () => {
        const { previousStep } = this.props;

        previousStep();
    }
    
    /**
     * Méthode permettant de mettre à jour la liste des parcelles sélectionnées dans le tableaux
     * @param {number[]} newSelectionParcels liste des ids de parcelles sélectionner
     */
    setSelectionParcels(newSelectionParcels) {
        this.setState({
            parcelsSelected: newSelectionParcels
        });
    }

    /**
     * Méthode permettant de supprimer les parcelles sélectionnées du tableau
     */
    handleRowsDelete() {
        const { parcelsSelected, tableGridDatas } = this.state;
        const { saveChosenParcels } = this.props;

        let newTableGridDatas = lodashFilter(tableGridDatas, (d) => {
            return !parcelsSelected.includes(d.id);
        });

        if (newTableGridDatas.length <= 0) {
            this.handleBackToBeginningModelisation();
        }
        else {
            saveChosenParcels(newTableGridDatas.map(m => m.id));
            this.setState({
                tableGridDatas: newTableGridDatas,
                parcelsSelected: [],
            });
        }
    }

    /**
     * Méthode déffinissant le visuel de la Toolbar du CustomDataGrid
     * @param {Oject} props correspond à la propriété 'toolbar' du CustomDataGrid
     * - Saisie de la date de semis communes
     * - Btn pour appliquer la saisie commune à toutes les parcelles
     * - Btn pour appliquer qu'aux parcelles sans date de semis
     * - Btn de suppression des parcelles dans le tableau (annule la modélisation)
     */
    CustomToolbar(props) {
        const { commonSowingDate, parcelsSelected, removeSelectedParcels, handleAllSowingDateChange, handleChangeCommonSowingDate,
            handleSowingDateChangeForDateMissing, minSowingDate, maxSowingDate, commonSowingDateError, sowingDateMsgError } = props;

        return (
            <Grid container spacing={2} >
                {/* Zone de recherche */}
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item sx={{ alignItems:'center', p: 1 }} xs={10} sm={8} md={8} lg={8}>
                            <Box>
                                <FormControl
                                    sx={{ m: 1 }}
                                >
                                    <CustomDatePicker
                                        label={StringTranslate.libelecolumnsemis}
                                        value={(commonSowingDate !== null) ? new Date(commonSowingDate) : null}
                                        onChange={(newValue) => handleChangeCommonSowingDate(newValue)}
                                        helperText={sowingDateMsgError}
                                        placeholder={StringTranslate.formatDatePlaceHolder}
                                        minDate={minSowingDate}
                                        maxDate={maxSowingDate}
                                        size={'small'}
                                    />
                                </FormControl>
                                <Button 
                                    sx={{ m: 1 }}
                                    variant="outlined" color="primary"
                                    onClick={() => handleAllSowingDateChange()}
                                    disabled={(commonSowingDate === null) || commonSowingDateError}
                                >
                                    {StringTranslate.modelisationApplySowingDateToAllSlots}
                                </Button>
                                
                                <Button 
                                    sx={{ m: 1 }}
                                    variant="outlined" color="primary"
                                    onClick={() => handleSowingDateChangeForDateMissing()}
                                    disabled={(commonSowingDate === null) || commonSowingDateError}
                                >
                                    {StringTranslate.modelisationApplySowingDateToSlotsWithoutDate}
                                </Button>
                            </Box>
                        </Grid>

                        <Grid item sx={{ alignItems:'center', p: 1 }} xs={2} sm={4} md={4}>
                            <Box sx={{ display: { md: 'block', xs: 'none' } }} style={{ textAlign: 'end', padding:1 }} >
                                <Button
                                    variant="text"
                                    color="error"
                                    onClick={() => removeSelectedParcels()}
                                    disabled={parcelsSelected.length > 0 ? false : true}
                                >
                                    {StringTranslate.modelisationRemoveParcelsSelected}
                                </Button>
                            </Box>

                            <Box sx={{ display: { md: 'none', xs: 'block' } }} style={{ textAlign: 'end' }} >
                                <div>
                                    <IconButton color="error" size="large" aria-label="delete field(s)" component="span"
                                        onClick={() => removeSelectedParcels()}
                                        disabled={parcelsSelected.length > 0 ? false : true}>
                                        <Delete />
                                    </IconButton>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    render() { 
        const { cultureSelected, rowsPerPageForTableModelisations, updateNbRowsPerPageTableModelisations, showProfilMenuDialog } = this.props;
        const { tableGridDatas, parcelsSelected, commonSowingDate, commonSowingDateError } = this.state;

        return ( 
            <>
                {/* barre d'information pour les consignes d'utilisation de la page */}
                <Grid container marginY={2}>
                    <Grid item xs={12}>
                        <Card>
                            <Alert
                                severity="info"
                                icon={<InfoOutlined/>}
                            >
                                <Typography>{StringTranslate.modelisationSpecificInfo1}</Typography>
                                <Typography>{StringTranslate.modelisationSpecificInfo2}</Typography>
                                <Typography>{StringTranslate.modelisationSpecificInfo3}</Typography>
                            </Alert>
                        </Card>
                    </Grid>

                    {/* Bouton d'aide */}
                    <Grid item xs={6}>
                        <Button color="secondary" variant="text" onClick={() => showProfilMenuDialog(ProfilIndex.aide_Modelisation)}>
                            {StringTranslate.helpAsk}
                        </Button>
                    </Grid>
                </Grid>

                <Typography fontWeight="bold" m={2}>{StringTranslate.modelisationCrop} {lodashGet(cultureSelected, 'name', "")}</Typography>
                
                <Grid container>
                    <CustomDataGrid
                        disableSelectionOnClick={true}
                        tableName={TableType.modelisations}

                        pageSize={rowsPerPageForTableModelisations}
                        updateNbRowsPerPageTable={updateNbRowsPerPageTableModelisations}

                        Toolbar={this.CustomToolbar}
                        toolbar={{
                            parcelsSelected: parcelsSelected,
                            removeSelectedParcels: () => this.handleRowsDelete(),

                            commonSowingDate: commonSowingDate,
                            sowingDateMsgError: ((commonSowingDate !== null) && commonSowingDateError) ? this.sowingDateMsgError : null,
                            commonSowingDateError: commonSowingDateError,
                            minSowingDate: this.minSowingDate,
                            maxSowingDate: this.maxSowingDate,
                            handleAllSowingDateChange: () => this.handleAllSowingDateChange(),
                            handleChangeCommonSowingDate: (newValue) => this.handleChangeCommonSowingDate(newValue),
                            handleSowingDateChangeForDateMissing: () => this.handleSowingDateChangeForDateMissing(),
                            localMap: this.localMap,
                        }}

                        rows={tableGridDatas}
                        rowHeight={80}
                        columns={this.columns}

                        checkBoxActive={true}
                        selectionModel={parcelsSelected}
                        onSelectionModelChange={(newSelectionParcels) => { //Sélection des parcelles par clic checkbox
                            this.setSelectionParcels(newSelectionParcels);
                        }}
                    />
                </Grid>
                <Grid container marginTop={3}>
                    <Grid item xs={6}>
                        <Button variant="text" onClick={this.handleBackToBeginningModelisation}>
                            {StringTranslate.revenir}
                        </Button>
                    </Grid>
                    <Grid item xs={6} justifyContent="flex-end" align="right">
                        <Button type="submit" variant="contained"
                            onClick={this.handleInitModelisation}
                            disabled={!this.isAllSowingDateValid()}
                        >
                            {StringTranslate.modelisationGenerateImages}
                        </Button>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = state => ({
    clientId: lodashGet(state,'clientUserData.userDatas.idClient', 0),
    
    rowsPerPageForTableModelisations: lodashGet(state, 'settingsData.settings.rowsPerPageForTableModelisations', 10),
    language: lodashGet(state, 'settingsData.settings.language', StringTranslate.getLanguage()),

    parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
    thumbnailParcelDico: lodashGet(state, 'parcelsData.thumbnailParcelDico', {}),

    parcelIdsToModelise: lodashGet(state, 'modelisationData.parcelIdsSelected', []),
    cultureSelected: lodashGet(state, 'modelisationData.cultureSelected', undefined),
});

const mapDispatchToProps = dispatch => ({
    updateNbRowsPerPageTableModelisations: (rowsPerPage) => dispatch(UpdateNbRowsPerPageTable(rowsPerPage, TableType.modelisations)),
    previousStep: () => dispatch(ActionGoToStepOfModelisation(ModelisationSteps.PARCELS_CHOICE)),      // ou directe vers l'étape de choix des parcelles ?
    initModelisation: (listOfModelisations) => dispatch(ActionInitModelisation(ModelisationSteps.RESULT, listOfModelisations)),
    showProfilMenuDialog: (index) => dispatch(ActionShowProfilMenuDialog(index)),
    saveChosenParcels: (updatedParcelsIdList) => dispatch(ActionSaveChosenParcels(updatedParcelsIdList))
});
 
export default connect(mapStateToProps, mapDispatchToProps)(ModelisationSpecificInfosManagement);