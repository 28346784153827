import React from "react";
import { connect } from 'react-redux';

import {
    Box, Button, Grid, TextField, CircularProgress,
    Select, MenuItem, InputLabel, FormControl, Checkbox, ListItemText,
    OutlinedInput, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, TableContainer,
    IconButton, Divider, Typography
} from "@mui/material";

import CustomDataGrid from "../../components/customDataGrid";
import MainCard from "../../components/subLayouts/mainCard.jsx";

/* images et icones */
import PindropAreaIcon from '../../assets/images/observation/IconeCreationZoneObservation.tsx';
import PlaceIcon from '@mui/icons-material/FmdGood';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';

/* Traduction */
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

/* css */
import '../../assets/css/renderParcels.css';

/* Actions Redux */
import {
    ActionEnableDrawMarkerPindrop,
    ActionEnableDrawAreaPindrop,
    ActionGoToMapAndUnselectParcel,
    ActionGoToMapAndSelectObservation,
    ActionSetCurrentPageName, PageName
} from '../../redux/actions/contextApp.js';
import { UpdateNbRowsPerPageTable, TableType } from '../../redux/actions/settings.js';
import { ActionDeleteObservationIdListToAPI } from '../../redux/actions/observations.js';

/* Helpers */
import stringHelper from '../../utils/stringHelper.js';
import { ParcelsHelper } from '../../utils/parcelsHelper.js';
import DateHelper from '../../utils/dateHelper.js';
import ListHelper from '../../utils/listHelper.js';

// Toolbar customisé du tableau comprenant :
// - la zone de recherche
// - le bouton de suppression des observations sélectionnées
// - le filtre sur les dates, les colonnes et les types d'observation
const CustomToolbar = (props) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={10} sm={8} md={4} lg={4}>
                        {/* Zone de recherche sur toutes les colonnes */}
                        <TextField
                            size="small"
                            value={props.value}
                            onChange={props.onChange}
                            placeholder={StringTranslate.toolbarsearch}
                            InputProps={{
                                startAdornment: <SearchIcon fontSize="small" />,
                                endAdornment: (
                                    <IconButton
                                        title="Clear"
                                        aria-label="Clear"
                                        size="small"
                                        style={{ visibility: props.value ? 'visible' : 'hidden' }}
                                        onClick={props.clearSearch}
                                    >
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                ),
                            }}
                        />
                    </Grid>

                    {/* Bouton de suppression des observations */}
                    <Grid item xs={2} sm={4} md={8}>
                        <Box sx={{ display: { sm: 'none', md: 'block', xs: 'none' } }} style={{ textAlign: 'end' }} >
                            <Button
                                variant="text"
                                color="error"
                                onClick={() => props.deleteSelectedObservations()}
                                disabled={(props.observationsSelected.length > 0) ? false : true}>
                                {(props.deleting) && <CircularProgress />} {`${StringTranslate.deleteObservations}`}
                            </Button>
                        </Box>
                        <Box sx={{ display: { sm: 'block', md: 'none', xs: 'block' } }} style={{ textAlign: 'end' }} >
                            <IconButton color="error" size="large" component="span"
                                onClick={() => props.deleteSelectedObservations()}
                                disabled={props.observationsSelected.length > 0 ? false : true}>
                                {(props.deleting) ? <CircularProgress /> : <DeleteIcon />}
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item>
                        {/* Zone de recherche sur les dates d'observation */}
                        <FormControl sx={{ minWidth: 125 }} size="small">
                            <InputLabel id="demo-select-small">{StringTranslate.periode}</InputLabel>
                            <Select
                                value={props.filterDate}
                                label="Date"
                                onChange={props.handleFilterDate}>
                                <MenuItem value={1}>{StringTranslate.labelToday}</MenuItem>
                                <MenuItem value={2}>{StringTranslate.labelYesterday}</MenuItem>
                                <MenuItem value={3}>{StringTranslate.labelTheLast7days}</MenuItem>
                                <MenuItem value={4}>{StringTranslate.labelThisMonth}</MenuItem>
                                <MenuItem value={5}>{StringTranslate.labelLastMonth}</MenuItem>
                                <MenuItem value={6}>{StringTranslate.labelAllDates}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        {/* Zone de recherche sur les types d'observation */}
                        <FormControl sx={{ width: 300 }} size="small">
                            <InputLabel id="demo-multiple-checkbox-label">{StringTranslate.typesObservations}</InputLabel>

                            <Select
                                labelId="multiple-checkbox-label"
                                id="multiple-checkbox"
                                multiple
                                value={props.typeObservation}
                                onChange={props.handleChangeTypeObservation}
                                input={<OutlinedInput label="Types d'observations" />}
                                renderValue={(selected) => selected.join(', ')}>
                                <MenuItem>
                                    <Checkbox
                                        onClick={props.handleToggleAllObservationTypes}
                                        checked={props.numberOfObservationsTypeChecked(props.typeObservations) === props.typeObservations.length && props.typeObservations.length !== 0}
                                        indeterminate={
                                            props.numberOfObservationsTypeChecked(props.typeObservations) !== props.typeObservations.length && props.numberOfObservationsTypeChecked(props.typeObservations) !== 0
                                        }
                                        disabled={props.typeObservations.length === 0}
                                        inputProps={{
                                            'aria-label': 'all observations type selected',
                                        }} />
                                    <ListItemText primary={StringTranslate.observation_type_0} />
                                </MenuItem>
                                <Divider />
                                {props.typeObservations.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        <Checkbox checked={props.typeObservation.indexOf(type) > -1} />
                                        <ListItemText primary={type} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

/**
 * Composant d'affichage des observations.
 */
function RenderObservations(props) {

    /* Fonction qui renvoie le type d'observation en fonction de son numéro de type pour affichage dans le tableau.
    Il existe 7 types d'observations */
    const getTypeObservation = (typeObservation) => {
        if (typeObservation === 1)
            return StringTranslate.observation_type_1;
        if (typeObservation === 2)
            return StringTranslate.observation_type_2;
        if (typeObservation === 3)
            return StringTranslate.observation_type_3;
        if (typeObservation === 4)
            return StringTranslate.observation_type_4;
        if (typeObservation === 5)
            return StringTranslate.observation_type_5;
        if (typeObservation === 6)
            return StringTranslate.observation_type_6;
        if (typeObservation === 7)
            return StringTranslate.observation_type_7;
    }

    /* données traitées à afficher dans le tablea des observations */
    const datas = Object.values(props.observationDico).reduce(function (accumulateur, observation) {
        let parcel = ParcelsHelper.selectParcelFromDicoById(props.parcelDico, observation.parcelId);
        accumulateur.push({
            id: observation.id,
            observationId: observation.id,
            parcelId: (parcel) ? parcel.id : -1,
            parcelName: (parcel) ? parcel.name : '',
            culture: (parcel && parcel.details) ? parcel.details.culture : '',
            dateObservationInTime: new Date(observation.observationDate).getTime(), // On laisse la date sous cette forme pour pouvoir travailler par la suite notamment pour les filtres
            dateObservation: observation.observationDate,
            typeObservation: getTypeObservation(observation.observationType),
            remarques: (observation.description) ? observation.description : '',
            superficie: (observation.superficieHa > 0) ? observation.superficieHa : '',
        });

        return accumulateur;
    }, []);

    const [openPopupDelete, setOpenPopupDelete] = React.useState(false); //ouverture, fermeture du dialog de suppression des observations

    const [observationsSelected, setObservationsSelected] = React.useState([]); //observations sélectionnées par les checkbox
    const pageSize = props.rowsPerPageForTableObservations; //Nombre de lignes par page au départ
    const [searchFilter, setSearchFilter] = React.useState(''); //Zone de recherche sur toutes les colonnes du tableau 
    const [currentRowsOfTable, setCurrentRowsOfTable] = React.useState([]); //Datas en fonction du texte tapé dans la zone de recherche
    const [filterDateValue, setDateFilter] = React.useState(6); //Filte sur les dates d'observation. Par défaut: 6, toutes les dates
    const [typeObservation, setTypeObservation] =
        React.useState([StringTranslate.observation_type_1, StringTranslate.observation_type_2,
        StringTranslate.observation_type_3, StringTranslate.observation_type_4,
        StringTranslate.observation_type_5, StringTranslate.observation_type_6,
        StringTranslate.observation_type_7]);

    /* Enregistrement dans Redux du nom de la page actuelle */
    props.setCurrentPageName();

    /* Définition des colonnes du tableau des observations */
    const columns = [
        { headerName: '', field: 'id', hideable: false, },
        {
            headerName: '', field: 'observationId', width: 50, hideable: false,
            renderCell: params => {
                return (<div onClick={() => props.goToMapAndSelectObservation(params.value)}>
                    {params.row.superficie !== "" ? <PindropAreaIcon color="secondary"/> : <PlaceIcon color="secondary" />}
                </div>)
            }
        },
        { 
            headerName: `${StringTranslate.columnparcelname}`, field: 'parcelName', minWidth: 150, hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.columnparcelname}</Typography>)
            },
        },
        { 
            headerName: `${StringTranslate.columnculture}`, field: 'culture', minWidth: 150, hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.columnculture}</Typography>)
            },
        },
        {
            headerName: StringTranslate.columnobservationdate,
            field: 'dateObservation',
            minWidth: 140,
            valueFormatter: (params) => {
                return DateHelper.formati18n(params.value, 'PPP')
            },
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.columnobservationdate}</Typography>)
            },
        },
        { 
            headerName: StringTranslate.columnobservationtype, field: 'typeObservation', minWidth: 150, hideable: false, 
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.columnobservationtype}</Typography>)
            },
        },
        { 
            headerName: `${StringTranslate.columnremarques}`, field: 'remarques', minWidth: 200, hideable: false, 
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.columnremarques}</Typography>)
            },
        },
        { 
            headerName: `${StringTranslate.columnsurperficie}`, field: 'superficie', minWidth: 110, hideable: false, 
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.columnsurperficie}</Typography>)
            },
        },
    ];

    /* Liste de tous les types d'observation */
    const typeObservations = [
        StringTranslate.observation_type_1,
        StringTranslate.observation_type_2,
        StringTranslate.observation_type_3,
        StringTranslate.observation_type_4,
        StringTranslate.observation_type_5,
        StringTranslate.observation_type_6,
        StringTranslate.observation_type_7
    ];

    /* Fonction qui permet de sélectionner/désélectionner tous les types d'observation */
    const handleToggleAllObservationTypes = () => {
        if (numberOfObservationsTypeChecked(typeObservations) === typeObservations.length) {
            setTypeObservation(ListHelper.not(typeObservation, typeObservations));
        } else {
            setTypeObservation(ListHelper.union(typeObservation, typeObservations));
        }
    }

    /* Fonction qui calcule le nombre de types d'observation séledctionnées */
    const numberOfObservationsTypeChecked = (items) => ListHelper.intersection(typeObservation, items).length;

    /* Fonction sur les filtres pour :
     - la zone de recherche sur toutes les colonnes,
     - la zone de recherche sur les dates,
     - la zone de recherche sur les types d'observations */
    const requestSearch = (searchValue, searchDate, searchTypeObservation, rowsOfTable) => {

        let filteredRows = rowsOfTable;

        /* Recherche sur les colonnes */
        setSearchFilter(searchValue);
        if (searchValue !== "") {
            try {
                const searchRegex = new RegExp(stringHelper.escapeRegExp(searchValue), 'i');
                filteredRows = rowsOfTable.filter((row) => {
                    return Object.keys(row).some((field) => {
                        return searchRegex.test(row[field].toString());
                    });
                });
            }
            catch(errRegex) { /* Peut arriver si on saisis un truc du genre 'EARL++' ! (il n'aime pas les '++') */ }
        }

        /* Recherche sur les dates d'observation */
        setDateFilter(searchDate);
        if (searchDate < 6) { // 6 correspond à toutes les dates
            let today = new Date();
            const lastMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1;
            const year = lastMonth === 0 ? today.getFullYear() - 1 : today.getFullYear();

            let beginningtoday = today.setHours(0, 0, 0, 0);
            let endtoday = today.setHours(23, 59, 59, 1);
            let beginningYesterdayDate = beginningtoday - (3600 * 1000 * 24);
            let endYesterdayDate = endtoday - (3600 * 1000 * 24);
            let beginningLast7DaysDate = beginningtoday - (3600 * 1000 * 24) * 7;
            var firstDayOfTheMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            var firstDateOfLastMonth = new Date(year, lastMonth, 1);

            filteredRows = filteredRows.filter((row) => {
                return (searchDate === 1 && (Date.parse(row.dateObservation) >= beginningtoday) && (Date.parse(row.dateObservation) <= endtoday)) ||
                    (searchDate === 2 && (Date.parse(row.dateObservation) >= beginningYesterdayDate) && (Date.parse(row.dateObservation) <= endYesterdayDate)) ||
                    (searchDate === 3 && (Date.parse(row.dateObservation) >= beginningLast7DaysDate) && (Date.parse(row.dateObservation) <= endtoday)) ||
                    (searchDate === 4 && (Date.parse(row.dateObservation) >= firstDayOfTheMonth) && (Date.parse(row.dateObservation) <= endtoday)) ||
                    (searchDate === 5 && (Date.parse(row.dateObservation) >= firstDateOfLastMonth) && (Date.parse(row.dateObservation) <= firstDayOfTheMonth)) ||
                    (searchDate === 6)
            });
        }

        /* Recherche sur les types d'observation */

        setTypeObservation(searchTypeObservation);
        if (searchTypeObservation.length < 7) { // Par défaut, tous les types d'observation sont cochées
            filteredRows = filteredRows.filter((row) => {
                return searchTypeObservation.join().includes(row.typeObservation);
            });
        }

        /* Mise à jour des données à afficher dans le tableau */
        setCurrentRowsOfTable(filteredRows);
    }

    //Sélection des observations pour suppression
    const setSelectionObservations = (newSelectionObservations) => {
        setObservationsSelected(newSelectionObservations);
    }

    /* Ouverture du popup de suppression */
    const deleteObservations = () => {
        setOpenPopupDelete(true); //ouverture de la dialog de suppression des observations
    };

    /* - Confirmation de suppression des observations
       - Fermeture de la dialog de suppression des observations */
    const confirmDeleteObservations = () => {
        props.deleteObservationIdList(observationsSelected);
        let newcurrentRowsOfTable = currentRowsOfTable.filter(row => observationsSelected.includes(row.id) === false);

        setOpenPopupDelete(false);
        setCurrentRowsOfTable(newcurrentRowsOfTable);
        setObservationsSelected([]);
    }

    /* Fonction de fermeture de la dialog de suppression */
    const closePopupDelete = () => {
        setOpenPopupDelete(false);
    }

    return (
        <>
            {openPopupDelete &&
                <Dialog open={openPopupDelete} onClose={closePopupDelete} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title-observ">{StringTranslate.deleteObservations}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {StringTranslate.deleteObservationsConfirmation}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closePopupDelete} variant="text" color="error">
                            {StringTranslate.cancelDeleteAction}
                        </Button>
                        <Button onClick={confirmDeleteObservations} variant="contained" color="primary">
                            {StringTranslate.confirmDeleteAction}
                        </Button>
                    </DialogActions>
                </Dialog>
            }

            <MainCard title={StringTranslate.renderobservationstitle}>
                <TableContainer>
                    <Grid style={{ width: '100%' }} item xs={12}>
                        <CustomDataGrid
                            tableName={TableType.observations}
                            pageSize={pageSize}
                            updateNbRowsPerPageTable={props.updateNbRowsPerPageTableObservations}
                            onSelectionModelChange={(newSelectionObservations) => { //Sélection des observations par clic checkbox
                                setSelectionObservations(newSelectionObservations);
                            }}
                            selectionModel={observationsSelected}
                            keepNonExistentRowsSelected={searchFilter !== "" ? true : false}
                            Toolbar={CustomToolbar}
                            toolbar={{
                                observationsSelected: observationsSelected,
                                deleteSelectedObservations: () => deleteObservations(),
                                observationDatas: datas,
                                //Concerne la zone de recherche dans le tableau :
                                value: searchFilter,
                                filterDate: filterDateValue,
                                typeObservation: typeObservation,
                                typeObservations: typeObservations,
                                onChange: (event) => requestSearch(event.target.value, filterDateValue, typeObservation, datas),
                                clearSearch: () => requestSearch('', filterDateValue, typeObservation, datas),
                                handleFilterDate: (event) => requestSearch(searchFilter, event.target.value, typeObservation, datas),
                                handleChangeTypeObservation: (event) => requestSearch(searchFilter, filterDateValue, event.target.value, datas),
                                handleToggleAllObservationTypes: () => handleToggleAllObservationTypes(),
                                numberOfObservationsTypeChecked: (items) => numberOfObservationsTypeChecked(items),
                            }}
                            rows={(searchFilter !== "") || (filterDateValue < 6) || (typeObservation.length < 7) ? currentRowsOfTable : datas}
                            columns={columns}
                            checkBoxActive={true}
                        />
                    </Grid>
                </TableContainer>
            </MainCard>

        </>
    )
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant décorée */
const mapStateToProps = state => ({
    parcelDico: state.parcelsData.parcelDico,
    observationDico: state.observationsData.observationDico,
    observationDicoCounter: state.observationsData.observationDicoCounter,

    rowsPerPageForTableObservations: (state && state.settingsData && state.settingsData.settings && state.settingsData.settings.rowsPerPageForTableObservations !== undefined) ? state.settingsData.settings.rowsPerPageForTableObservations : 20,
});

/* fonction permettant de fournir les fonctions (actions) au composant décorée */
const mapDispatchToProps = dispatch => ({
    deleteObservationIdList: (observationIdListToDelete) => dispatch(ActionDeleteObservationIdListToAPI(observationIdListToDelete)),
    goToMapAndUnselectParcel: () => dispatch(ActionGoToMapAndUnselectParcel()),
    enableDrawMarkerPindrop: () => dispatch(ActionEnableDrawMarkerPindrop()),
    enableDrawAreaPindrop: () => dispatch(ActionEnableDrawAreaPindrop()),
    goToMapAndSelectObservation: (observationId) => dispatch(ActionGoToMapAndSelectObservation(observationId)),
    //nécessaire pour renseigner le nom de la page en cours
    setCurrentPageName: () => dispatch(ActionSetCurrentPageName(PageName.Map)),

    updateNbRowsPerPageTableObservations: (rowsPerPage) => dispatch(UpdateNbRowsPerPageTable(rowsPerPage, TableType.observations)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RenderObservations);