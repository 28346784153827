import React from 'react';
import { connect } from 'react-redux';
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch';
import { Tooltip, Fab, Typography, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

 
import getTheme from '../../themes/index.js';

let theme = getTheme();

/**
 * classe en lien avec le bouton + fonctionnel de la recherche de lieu/ville grâce au plugin leaflet-geosearch
 */
class GeoSearchButtonControl extends React.Component {
    
    constructor(props) {
        super(props);

        this.controlAdded = false;

        // ↓ BIND ↓
        this.handleClickGeoSearchButton = this.handleClickGeoSearchButton.bind(this);
    }

    /* fonction cycle de vie */
    componentDidMount() {
        // ↓↓ Section d'initialisation du fonctionnel de recherche de localité:
        this.provider = new OpenStreetMapProvider({ params: { country: '', countrycodes: [''] }}); // Fournisseur de recherche de localité (par code postal ou nom ville).
        
        this.geoSearchControl = new GeoSearchControl({
            searchLabel: StringTranslate.geoSearchAddress, // optional: string      - default 'Enter address'
            provider: this.provider,
            showPopup: true, // optional: true|false  - default false
            popupFormat: ({ query, result }) => result.label, // optional: function    - default returns result label
            autoClose: true, // optional: true|false  - default false
        });
    }

    /**
     * fonction callback lors du clique du bouton de recherche de lieu/ville
     */
    handleClickGeoSearchButton(event) {

        // ↓ Le controle est il déjà inséré ? si non, on l'insére ↓
        if ((this.controlAdded !== true) &&
            this.props.renderMapsCmp && (this.props.renderMapsCmp.map) && 
            this.geoSearchControl) {
            this.props.renderMapsCmp.map.addControl(this.geoSearchControl); // insertion du contrôle leaflet

            let container = this.geoSearchControl.getContainer(); // récupération du contenu html
            document.getElementById('geoSearchContainer').append(container); // déplacement du contenu html dans notre composant react.js (<div id='geoSearchContainer'></div>)

            this.controlAdded = true;
        }

        // ↓ on lance la fonction originelle qui se trouve sur le bouton de contrôle ↓
        this.geoSearchControl.onClick( event );
    }

    /* fonction cycle de vie */
    render() {

        return (
            <Box 
                className='leaflet-control'
                sx={{
                    zIndex:"1100"
                }}
            >
                    
                {/* ↓ ⚠️ - tag html dans lequel on va introduire le contenu html geoSearchControl (le bouton natif du plugin est display:none) - @@ en attendant de faire son propre visuel ↓ */}
                <div id='geoSearchContainer'></div>
            
                {/* ↓ bouton - fonctionnalité recherche de lieu/ville ↓ */}
                <div>
                    <Tooltip title={<Typography color="inherit">{StringTranslate.commune}</Typography>} placement={"right"}>
                        <Fab 
                            size="small" 
                            onClick={this.handleClickGeoSearchButton}
                            sx={{
                                backgroundColor: theme.palette.common.white,
                                color:theme.palette.primary.main
                            }}
                        >
                            <SearchIcon />
                        </Fab>
                    </Tooltip>
                </div>
            </Box>
        )
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant */
const mapStateToProps = function(state) {
    return {
        settings: state.settingsData.settings, // seul moyen trouvé pour rafraichir le visuel (traduction) du fait que maintenant on rentre dans l'application sans attendre les informations du client
    };
}

export default connect( mapStateToProps, null )(GeoSearchButtonControl);