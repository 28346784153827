///////////////////////////////////////////////////////////////////////////
// Import des éléments utilisés par le composant <AbstractParcels>
///////////////////////////////////////////////////////////////////////////
import React from "react";
import { connect } from 'react-redux';
import ImportParcels from "./importParcels";
import RenderParcels from "./renderParcels";


///////////////////////////////////////////////////////////////////////////
// Composant global concernant la gestion des parcelles de l'utilisateur/client.
///////////////////////////////////////////////////////////////////////////
class AbstractParcels extends React.Component {
    
    ///////////////////////////////////////////////////////////////////////////
    // fonction de rendu visuel - layout général
    ///////////////////////////////////////////////////////////////////////////
    render() {
        const { needImportParcels } = this.props; // Provenant de la décoration de Redux
        
        return (
            (needImportParcels === true) ? <ImportParcels {...this.props} /> : <RenderParcels {...this.props} />
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    //Infos provenant du reducer 'parcels':
    needImportParcels: (state.parcelsData && state.parcelsData.parcelDico && (state.parcelsData.parcelDicoCounter > 0)) ? false : true, // pas besoin de l'import si on a des parcelles... 
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    //RAS !!!
})

export default connect( mapStateToProps, mapDispatchToProps )(AbstractParcels);
