import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


function HomeExploitationIcon(props: SvgIconProps) {
    return ( 
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <svg id="logoExploit"width="32" height="32" viewBox="0 0 32 32" fill="currentColor">
                <path d="M26.93 21.02 L27.93 21.02 L27.93 27.02 L26.93 27.02" />
                <path d="M23.93 21.02 L24.93 21.02 L24.93 27.02 L23.93 27.02" />
                <path d="M29.93 21.02 L30.93 21.02 L30.93 27.02 L29.93 27.02" />
                <path d="M22.93 23.02 L31.93 23.02 L31.93 24.02 L29.93 24.02" />
                <line x1="26.93" y1="21.02" x2="26.93" y2="21.02" style={{ stroke :"10px"}}/>
                <g>
                    <path d="M22.78,20.09a2,2,0,0,1-1.91-1.41l-1.87-6-6.57-5-6.57,5L4,18.68A2,2,0,1,1,.17,17.49l2.06-6.64a2,2,0,0,1,.7-1l8.29-6.26a2,2,0,0,1,2.41,0l8.29,6.26a2,2,0,0,1,.7,1l2.07,6.64A2,2,0,0,1,23.37,20,2.06,2.06,0,0,1,22.78,20.09Z" />
                    <g>
                    <path d="M7.93 13.25 L16.93 13.25 L16.93 16.25 L7.93 16.25" style={{fill: "none"}}/>
                        <path d="M19.91,19l-1.77-5.72L12.43,9,6.72,13.26,4.94,19a3,3,0,0,1-2,2v6.13h5v-8h9v8h5V21A3,3,0,0,1,19.91,19Zm-3-2.73h-9v-3h9Z" />
                    </g>
                </g>
            </svg>
        </SvgIcon>
    );
}

export default HomeExploitationIcon;