import React from 'react';

import { Button, Typography, Stack } from '@mui/material';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import ConstantsContact from '../../utils/constantsContact.js';
import sendError from '../../utils/errorService.js';
import { IsNativeHoster } from '../../utils/platformHelper.js';
import { CircularProgress } from '@mui/material';


/**
 *  Composant représentant l'étape finale de souscription à savoir l'écran de succès ou d'échec
 */
export class SuccessSubscription extends React.Component {
    constructor(props){
        super(props);
        
        this.updateDatasAfterSubscription = this.updateDatasAfterSubscription.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    /* fonction cycle de vie react.js */
    componentDidMount() {
        if (window.fbq) // facebook pixel => un client vient de souscrire
            window.fbq('track', 'Purchase', { value: 17.00, currency: 'EUR' }); //TODO : Ne devrait pas être ici !
    }

    /* 
     * fonction - MISE A JOUR DONNÉES + REDIRECTION ÉTAPE 4
     */
    updateDatasAfterSubscription() {
        // ↓ on récupère les données mises à jour suite à une souscription (client, clientBilling, parcels (parcelles nouvellement activées)) ↓
        if (this.props.getClientDatasAfterSubscription) {
            //sendError('SuccessSubscription - updateDatasAfterSubscription', { info: 'calling...' });
            
            this.props.getClientDatasAfterSubscription() //Rq: appelera entre autre la liste des plans et souscriptions !
                .then(() => {
                    //sendError('SuccessSubscription - updateDatasAfterSubscription', { info: 'ok...' });

                    //RQ : l'actualisation des données client et du parcours d'achat sera déjà réalisé par la méthode appelante !
                    if (this.props.goToMap) {
                        //sendError('SuccessSubscription - updateDatasAfterSubscription', { info: 'going To Map...' });
                        this.props.goToMap(); // on redirige l'utilisateur vers la carte
                    }
                })
                .catch((error) => {
                    sendError('SuccessSubscription - updateDatasAfterSubscription', { 'err': "erreur retour getClientDatasAfterSubscription", 'error': error, 'clientId': this.props.clientId } );
                });
        }
        else if (this.props.goToMap) {
            this.props.goToMap();
        }
    }

    /* fonction lancé depuis le bouton de l'écran de succès à une soucription */
    onClick() {
        this.updateDatasAfterSubscription();
    }

    render() {
        const { waitLoadingClient } = this.props;
        
        const allLinesAbo = (IsNativeHoster() === true) ? StringTranslate.aboValideMobile : StringTranslate.aboValide;
        const aboLines = allLinesAbo.split('\n');
        
        return (
            <div className="ssp-success-container">
                <div className="ssp-success-content">
                    <div className="ssp-success-done">&#10003;</div>{/* Utilisation du caractère le symbole 'validé' */}
                    
                    <Typography variant="h3" component="h2" gutterBottom classes={{ root: 'ssp-success-typo'}}>{`${StringTranslate.felicitation}`}</Typography>
                    {(aboLines && aboLines.length > 1) ? (
                        aboLines.map((itemLine, index) => <Typography key={`abo_lg_${index}`} variant="subtitle2" gutterBottom classes={{ root: 'ssp-success-typo-details'}}>{itemLine}</Typography>)
                    ) : (
                        <Typography variant="subtitle2" gutterBottom classes={{ root: 'ssp-success-typo-details'}}>{allLinesAbo}</Typography>
                    )}

                    <Button variant="contained" color="primary" onClick={this.onClick} classes={{ root: 'ssp-success-button'}}>{`${StringTranslate.continuer}`}</Button>
                    
                    {(waitLoadingClient === true) && (
                        <Stack direction="row">
                            <CircularProgress color='primary' variant='indeterminate' fontSize='small' />
                            <Typography variant="subtitle2">{StringTranslate.reloadingClientDatas}</Typography>
                        </Stack>
                    )}
                </div>
            </div>
        );
    }
}

/**
 * Composant représentant l'étape finale de souscription à savoir l'écran de succès ou d'échec
 */
export class FailSubscription extends React.Component {
    constructor(props) {
        super(props);
        this.onReset = this.onReset.bind(this);
    }

    /* fonction permettant de revenir à la première étape et d'effacer totalement les données de souscription */
    onReset() {
        this.props.resetSubscriptionProcess();
        this.props.handleBackToFirstStep();
        /*this.props.reinitSubscriptionProcess()
            .then(() => {
                //Revient à la première étape d'achat:
                this.props.handleBackToFirstStep();
            })
            .catch((error) => {
                sendError('FailSubscription-onReset', { 'err': "erreur retour reinitSubscriptionProcess", 'error': error, 'clientId': this.props.clientId } );

                this.props.goToMap(); // on redirige l'utilisateur vers la carto...
            });*/ //fonctionne mais pas nécessaire de faire aussi compliqué car le soucis venait du code sous 'handleSelectSpecificStep' (on ne passait pas dans le if...)
    }

    render() {
        const { subscriptionProcess } = this.props;
        let cultureValue = StringTranslate.getLanguage();

        return (<div className="ssp-fail-container">
            <div className="ssp-fail-content">
                <div className="ssp-fail-cross">
                    <CancelOutlinedIcon/>
                    <Typography variant="h3" component="h2" gutterBottom classes={{ root: 'ssp-fail-typo'}} align='center' display='inline'>{`${StringTranslate.oups}`}</Typography>
                </div>
                <Typography variant="subtitle2" gutterBottom>
                    {`${StringTranslate.abofail} ${ConstantsContact.EmailContact}`}
                    <br />
                    {(cultureValue && (cultureValue === 'es-ES')) ? (
                        <a href={ConstantsContact.UrlContactES} target="_blank" rel="noopener noreferrer" onClick={() => (window.dataLayer) ? window.dataLayer.push({'event': 'suiviBouton', 'action': 'unsuscribe'}) : null}>{ConstantsContact.UrlRoot}</a>
                    ) : (
                        <a href={ConstantsContact.UrlContact} target="_blank" rel="noopener noreferrer" onClick={() => (window.dataLayer) ? window.dataLayer.push({'event': 'suiviBouton', 'action': 'unsuscribe'}) : null}>{ConstantsContact.UrlRoot}</a>
                    )}                    
                </Typography>

                <Typography variant="subtitle2" gutterBottom>{`${StringTranslate.abofailDetails} ${subscriptionProcess.error}`}</Typography>
                <Button variant="contained" color="primary" onClick={this.onReset} classes={{ root: 'ssp-fail-button'}}>{`${StringTranslate.continuer}`}</Button>
            </div>
        </div>)
    }
}