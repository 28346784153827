import PropTypes from 'prop-types';
import { /*StateYearMonthOfSatimages,*/ SatimageState } from './stateYearMonthOfSatimages.js';


/**
 * Objet POCO représentant la liste des dates/images, tous mois/année définis, pour la parcelle visée
 */
export class SatimageDatasParcel {
    constructor(props) {
        this.parcelId = props.parcelId || -1;
        this.stateAsk = SatimageState.stateNotAsk;
        this.satimageDico = props.satimageDico || {}; //props.satimageDico || {}; //'dico' et pas 'List'/'Array' car on gère un ID d'image référencé par son Id => donc type 'Dico<imageId, SatImage>' ! 
        this.satimageDicoCounter = (props.satimageDico) ? props.satimageDico.length: 0;
        this.stateByYearMonthDico = {}; //'dico' et pas 'List'/'Array' car on gère un status référencé par l'année + le mois => donc type 'Dico<Year_Month, StateYearMonthOfSatimages>' ! 
        this.satImageWithHigherMaxccDico = props.satImageWithHigherMaxccDico || {}; // dico incluant les données satImages dont le taux d'ennuagement est supérieur au maxCC (paramètrage client) (clé => satImageId)
        this.yearMonthStateDicoCounter = 0;
        this.yearMonthStateInProgressCounter = 0;
        this.missingMonth = props.missingMonth || false;

        //Propriétés ajoutées après-coup:
        //RAS...
        
        //Infos redondantes:
        //RAS...
    }
}
SatimageDatasParcel.defaultProps = {};
SatimageDatasParcel.propTypes = {
    parcelId: PropTypes.number,
    stateAsk: PropTypes.oneOf([SatimageState.stateNotAsk, SatimageState.stateAskOnProgress, SatimageState.stateAskOk, SatimageState.stateOnError]),    
    satimageDico: PropTypes.object,
    satimageDicoCounter: PropTypes.number,
    stateByYearMonthDico: PropTypes.object,
    satImageWithHigherMaxccDico: PropTypes.object,
    yearMonthStateDicoCounter: PropTypes.number,
    yearMonthStateInProgressCounter: PropTypes.number,
    missingMonth: PropTypes.bool,
};
