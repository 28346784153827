import React from "react";
import { connect } from 'react-redux';

/* mui components */
import {
    CircularProgress,
    Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    Radio, RadioGroup, FormControlLabel, FormLabel, FormControl, Grid,
    Box, TextField, Stack
} from '@mui/material';

import AlertDialog from '../../components/alertDialog';
/* mui icons and icons */
import AlertIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import IconPdf from '@mui/icons-material/PictureAsPdf';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';

/* redux actions */
import { ModulationStep, SupplyType, TypeDownloadChoice, ActionClearModulationValue, ActionBuildModulationSample,
    ActionGoToStepOfModulation, ActionGoToNextStepOfModulation, ActionGoToShowThisModulation,
    ActionGenerateFileOfThisModulation, ActionDeleteModulations, ActionCleanErrorModulation,
    ActionBuildListPrescriptions } from '../../redux/actions/modulations';
import { TableType } from '../../redux/actions/settings.js';
import { ProfilIndex,ActionShowProfilMenuDialog } from "../../redux/actions/contextApp.js";

/* helpers */
import { IsNativeHoster } from '../../utils/platformHelper';
import DateHelper from '../../utils/dateHelper.js';
import stringHelper from '../../utils/stringHelper.js';
import numberHelper from '../../utils/numberHelper.js';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

/* thème, css */
import '../../assets/css/renderParcels.css';

import CustomDataGrid from "../../components/customDataGrid";
import ModalVideos from "../modalVideos";
import ConstantsTutorialHelps from "../../utils/constantsTutorialHelps";

//import getTheme from "../../themes/index.js";


//let theme = getTheme();

///Composant affichant la liste des modulations complètes présentes en BdD:
class Modulations extends React.Component { //@@a renommer en "ActualModulations" et 'actualModulations.jsx'

    constructor(props) {
        super(props);

        this.state = {
            openConfirmDeleteModulationDialog: false,
            openErrorModulationDialog: false,

            openConfirmDownloadModulationDialog: false, //ouverture de la dialog de téléchargement des modulations
            modulationsToDownload: [], //Attention ! Ce ne sont pas des résultats de modulation, mais des paramètres de demande !!! donc des 'ModulationParameter' (pas des 'ModulationData')
            typeDownloadChoice: TypeDownloadChoice.shp, //Par défaut, le type de fichier pour le téléchargement est SHP.
            showFirstStepForDownloadingModulations: false, //Si le client a des modulations dont les types d'apport sont différents, on visualise l'étape 1

            showUseComputerForDownload: false, //ouverture de la dialog en mobile, indiquant qu'il faut passer sur l'appli PC !

            idModulationGenerating: -1,
            pageSize: props.rowsPerPageForTableModulations, //Nombre de lignes par page au départ
            modulationsSelected: [], //Modulations sélectionnées par les checkbox
            bothSupplyType: false,
            countTotalDoseToExpand: 0,
            totalDoseSupplyType: SupplyType.Solid,
            searchFilter: '', //Zone de recherche du tableau 
            currentRowsOfTable: [], //Datas en fonction du texte tapé dans la zone de recherche
            modalIsOpen: false, // Valide l'apparition du modal
            videos: ConstantsTutorialHelps.UrlVideoModulation, // Lien url d'une vidéos qu'on passe en props dans le composant modalVideos
        };

        this.handleCloseConfirmDialogForDeleting = this.handleCloseConfirmDialogForDeleting.bind(this);
        this.handleCloseConfirmDialogForDownloading = this.handleCloseConfirmDialogForDownloading.bind(this);
        this.handleChangeTypeDownloadChoice = this.handleChangeTypeDownloadChoice.bind(this);
        this.handleCloseErrorDialog = this.handleCloseErrorDialog.bind(this);
        this.onSelectionModulation = this.onSelectionModulation.bind(this);
        this.handleOpenModalVideos = this.handleOpenModalVideos.bind(this);
        this.handleCloseModalVideos = this.handleCloseModalVideos.bind(this);

        this.downloadAsking = false;
        this.popupErrorDialog = {
            getTitle: () => { return (<AlertIcon />); },
            description: StringTranslate.errorDownloadModulation, //erreur par défaut, qui sera remplacer par la prop 'errorModulation' !
            button: StringTranslate.close,
        };
        //this.showUseComputerForDownload = false; //cf. dans le state car dans ce cas de gestion, seul le composant peut déclencher l'affichage !
        this.popupUseComputerForDownloadDialog = {
            getTitle: () => { return (<WarningIcon />); },
            description: StringTranslate.msgUseComputerForDownloadModulation, //message d'incitation si on tente de télécharger depuis l'appli mobile !
            button: StringTranslate.close,
        };

        this.onGenerateModulationFileClick = this.onGenerateModulationFileClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { downloadWaiting, errorModulation } = this.props;

        //Détection du début de téléchargement:
        if (((!prevProps) || (prevProps.downloadWaiting !== true)) && (downloadWaiting === true)) {
            this.downloadAsking = true;
        }

        //Déclenche l'affichage de l'erreur de téléchargement:
        if ((this.downloadAsking === true) && ((!prevProps) || (prevProps.errorModulation !== errorModulation)) &&
            errorModulation && (errorModulation !== '')) {
            //on actualise le message d'erreur:
            this.popupErrorDialog.description = errorModulation;

            //on ouvre la dialogue...
            this.setState({ openErrorModulationDialog: true, });
        } else if (prevProps && (prevProps.downloadWaiting === true) && (downloadWaiting !== true)) {
            //on actualise l'arrêt du téléchargement... (qui semble s'être bien fini)
            this.downloadAsking = false;
        }

        if (prevProps && (prevProps.generatingFile === true) && (this.props.generatingFile !== true)) { //En cas de fin de génération !
            this.setState({ idModulationGenerating: -1, });
        }
    }

    //Toolbar customisé du tableau comprenant :
    // - la zone de recherche,
    // - les boutons de suppression et de téléchargement
    // - le bouton d'aide
    CustomToolbar(props) {
        const { bothSupplyType, countTotalDoseToExpand, totalDoseSupplyType } = props;
        const unity = (totalDoseSupplyType === SupplyType.Solid) ? "Kg" : "L";

        return (
            <Grid container spacing={2} >
                {/* Zone de recherche */}
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={8} sm={8} md={4} lg={4}>
                            <TextField
                                size="small"
                                value={props.value}
                                onChange={props.onChange}
                                placeholder={StringTranslate.toolbarsearch}
                                InputProps={{
                                    startAdornment: <SearchIcon fontSize="small" />,
                                    endAdornment: (
                                        <IconButton
                                            title="Clear"
                                            aria-label="Clear"
                                            size="small"
                                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                                            onClick={props.clearSearch}
                                        >
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Grid>
                        {/* Boutons de suppression et de téléchargement */}
                        <Grid item xs={4} sm={4} md={8}>
                            {/* Boutons en écran PC (>= md) */}
                            <Box sx={{ display: { md: 'block', xs: 'none' }, '& button': { m: 1 } }} style={{ textAlign: 'end' }} >

                                <Button
                                    variant="text" color="error"
                                    onClick={(evt, data) => props.deleteModulations(evt, props.modulationsSelected)}
                                    disabled={props.modulationsSelected.length <= 0}>
                                    {StringTranslate.deleteModulations}
                                </Button>

                                <Button
                                    variant="contained" color="primary"
                                    onClick={(evt, data) => props.downLoadModulations(evt, props.rowsOfTable.filter(row => props.modulationsSelected.includes(row.id)))}
                                    disabled={props.modulationsSelected.length <= 0}>
                                    {((props.downloadWaiting === true) ?
                                        StringTranslate.ColumnMdlToDownLoading : StringTranslate.ColumnMdlToDownLoad)}
                                </Button>

                            </Box>
                            {/* Boutons en écran Mobile (< md) */}
                            <Stack 
                                sx={{ display: { md: 'none', xs: 'block' }, '& button': { m: 1 }, pt:1 }} 
                                style={{ textAlign: 'end' }}
                                direction="row" spacing={1}
                            >

                                <IconButton color="error" size="large" aria-label="delete modulation(s)" component="span"
                                    onClick={(evt, data) => props.deleteModulations(evt, props.modulationsSelected)}
                                    disabled={props.modulationsSelected.length <= 0}
                                    sx={{p:0}}
                                >
                                    <DeleteForeverSharpIcon />
                                </IconButton>

                                <IconButton color="primary" size="large" aria-label="download pdf(s)" component="span"
                                    disabled={props.modulationsSelected.length <= 0}
                                    onClick={(evt, data) => { props.downLoadModulations(evt, props.rowsOfTable.filter(row => props.modulationsSelected.includes(row.id))) }}
                                    sx={{p:0}}
                                >
                                    <IconPdf />
                                </IconButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>


                {/* Bouton d'aide */}
                <Grid item xs={6} sm={4}>
                    <Button color="secondary" variant="text" size="small" onClick={props.handleOpenModalVideos}>
                        {StringTranslate.helpAsk}
                    </Button>
                </Grid>
                {/* Modal videos */}
                <ModalVideos 
                    modalIsOpen={props.modalIsOpen}
                    modalVideos={props.videos}
                    onClose={() => props.handleCloseModalVideos()}
                />
                
                <Grid item xs={6} sm={8} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                    {(bothSupplyType === true) ? (
                        <><ReportProblemRoundedIcon color="error" /><Typography id="totalDoseToExpand">{` ${StringTranslate.totalDoseToExpandAlert}`}</Typography></>
                    ) : (
                        <Typography id="totalDoseToExpand">{(countTotalDoseToExpand > 0) ? `${StringTranslate.totalDoseToExpand}${numberHelper.fixeDecimal(countTotalDoseToExpand)} (${unity})`: `${StringTranslate.totalDoseToExpand} --`}</Typography>
                    )}
                </Grid>
                
            </Grid>
            
        );
    }
    /* Lance l'affichage sur le panneau du dessous (composant '') les détails de cette modulation */
    onShowModulationRowClick(modulationId) {
        const { modulationDico, clearModulationValue, goToShowThisModulation } = this.props;
        try {
            const modulationSelected = modulationDico[modulationId];
            clearModulationValue(); //si une modulation a déjà été sélectionnée auparavant, vider les valeurs de la modulation :

            goToShowThisModulation(modulationSelected); //Rq: Force le mode "consultation" !
        }
        catch (err) { }
    }

    onGenerateModulationFileClick(event, modulationId) {
        if (event) { //pour ne pas sélectionner la ligne !
            event.preventDefault();
            event.stopPropagation(); // Really this time.
        }

        const { modulationDico, generateModulationFile } = this.props;

        this.setState({ idModulationGenerating: modulationId, });

        try {
            const modulationSelected = modulationDico[modulationId];

            generateModulationFile(modulationSelected);
        }
        catch (err) { }
    }

    /* ↓↓↓ Téléchargement des modulation ↓↓↓ */
    /* Etape 1 qui confirme que le client est d'accord avec le fait 
    qu'il téléchargera un zip avec 2 types d'apport différents.
    Etape 2 : affichage du choix du type de téléchargement (SHP ou ISO XML) */
    onGoToNextStepToDownload = () => {
        this.setState({
            showFirstStepForDownloadingModulations: false,
        })
    }

    /* Lance la procedure de téléchargement de l'ensemble des fichiers constituant les modulations
    Si le client a sélectionné des modulations comportant des types d'apport différents, 
    l'étape 1 sera la confirmation qu'il est d'accord. L'étape 2 sera la confirmation du téléchargement */
    onDownLoadModulations(event, dataLinesSelected) { //Attention ! Il ne s'agit pas des entités de modulation, mais de l'entité spécifique pour l'affichage de ce tableau !!!
        //On vérifie si on est dans l'appli mobile. Si oui, on interdit le téléchargement mais en indiquant comment faire (utiliser l'appli web sur PC).
        if ((this.props.downloadWaiting === true) || (IsNativeHoster() === true)) {
            this.setState({ showUseComputerForDownload: true, });
            return;
        }
        //else //sinon, on autorise le téléchargement !

        //Il faut créer chaque paramètrage (prescription) associé à chaque ligne sélectionnée (celles reçues ici):
        let modulationParameterList = [];
        dataLinesSelected.forEach(itemLine => {
            modulationParameterList.push({
                modulationId: itemLine.id,
                clientId: itemLine.clientId,
                parcelId: itemLine.parcelId,
                imagSourceId: itemLine.imagSourceId,
                dateImagSource: itemLine.dateImagSource,
                providerImagSource: itemLine.providerImagSource,
                zonesCounter: itemLine.zonesCounter,
                supplyType: itemLine.supplyType,
                doseChange: itemLine.doseChange,
                distributionType: itemLine.distributionType,
                doseNominal: itemLine.doseNominal,
                doseMedium: itemLine.doseMedium,
                content: itemLine.content,
                parcelArea: itemLine.parcelArea,
                zones: itemLine.zones ? itemLine.zones : [
                    { actualDose: 0, ratio: 0 },
                    { actualDose: 0, ratio: 0 }
                ],
            });
        });

        const withWarning = ((dataLinesSelected.filter(modulation => modulation.supplyType === SupplyType.Liquid).length > 0) === true &&
            (dataLinesSelected.filter(modulation => modulation.supplyType === SupplyType.Solid).length > 0)) ? true : false;
        this.setState({
            openConfirmDownloadModulationDialog: true,
            showFirstStepForDownloadingModulations: withWarning,
            modulationsToDownload: modulationParameterList, //info de l'avertissement.
        });
    }

    /* Désaffiche la boite de dialog pour le téléchargement des modulations */
    handleCloseConfirmDialogForDownloading = () => {
        this.setState({
            openConfirmDownloadModulationDialog: false
        });
    }

    /* Fonction de confirmation de téléchargement de modulations */
    onConfirmDownloadModulations = () => {
        const { modulationsToDownload, typeDownloadChoice } = this.state;
        const { downloadListPrescriptions, goToModulationStep } = this.props;

        //Lance le téléchargement des modulations sélectionées :
        let formatToExport = TypeDownloadChoice.shp;
        switch (typeDownloadChoice) {
            case TypeDownloadChoice.isoXml: {
                formatToExport = 2;
                break;
            }

            case TypeDownloadChoice.rds: {
                formatToExport = 3;
                break;
            }

            default: { // == TypeDownloadChoice.shp
                formatToExport = 1;
                break;
            }
        }
        downloadListPrescriptions(modulationsToDownload, formatToExport);

        //Déclenche l'affichage de l'écran de fin des générations (qui permet aussi de patienter le temps de la génération):
        goToModulationStep(ModulationStep.CONGRATULATIONS, false);

        this.setState({
            openConfirmDownloadModulationDialog: false,
            modulationsToDownload: [],
            disabledBtnConfirmDownload: true,
        });
    }

    /* Fonction appelée lors du choix du type de téléchargement : SHP ou ISOXML */
    handleChangeTypeDownloadChoice = (event) => {
        this.setState({
            typeDownloadChoice: event.target.value
        });
    }
    /* ↑↑↑ Téléchargement des modulation ↑↑↑ */

    /* ↓↓↓ Suppression des modulation ↓↓↓ */
    /* Demande de suppression des modulatios sélectionnées en fonction des ids donnés en paramètre */
    onDeleteModulationByIds(event, modulationIds) {
        this.setState({
            openConfirmDeleteModulationDialog: true,
        });
    }

    /* Désaffiche la boite de dialog pour la suppression des modulations */
    handleCloseConfirmDialogForDeleting = () => {
        this.setState({
            openConfirmDeleteModulationDialog: false,
        });
    }

    /* Fonction de suppression des modulations */
    onConfirmDeleteModulationByIds = () => {
        const { modulationsSelected, currentRowsOfTable } = this.state;
        const { deleteModulationByIds } = this.props;

        deleteModulationByIds(modulationsSelected);
        
        //Si on sélectionne plusieurs modulations, qu'ensuite, on fait une recherche,
        //il faut que si on supprime des modulations qui sont dans la liste 'currentRowsOfTable' (liste filtrée après la recherche),
        //que ces modulations là ne soient pas gardées dans la liste 'currentRowsOfTable' !
        //Attention au paramètre 'keepNonExistentRowsSelected'.
        let newcurrentRowsOfTable = currentRowsOfTable.filter(row => modulationsSelected.includes(row.id) === false);
        
        this.setState({
            openConfirmDeleteModulationDialog: false,
            modulationsSelected: [],
            currentRowsOfTable: newcurrentRowsOfTable,
        });
    }
    /* ↑↑↑ Suppression des modulation ↑↑↑ */

    handleCloseErrorDialog = () => {
        this.downloadAsking = false;

        this.setState({
            openErrorModulationDialog: false,
            showUseComputerForDownload: false,
        });

        //Supprime l'erreur du state Redux !
        this.props.cleanErrorModulation();
    }

    /* Fonction appelée lors des clics sur les cases à cocher de sélection dans le tableau */
    onSelectionModulation = (modulationIds) => {
        let totalDoseToExpand = 0;
        let selectedSupplyType = undefined;
        let bothSupplyType = false;

        let modulationIdsSelected = [];

        modulationIds.forEach(modulationId => {
            let modulation = this.props.modulationDico[modulationId];

            if (modulation) {
                if (modulation.zones) {
                    let quantityToExpand = 0;
                    modulation.zones.forEach(zoneItem => {
                        quantityToExpand += (zoneItem.ratio * modulation.parcelArea * (
                            (numberHelper.testDataValid(zoneItem.actualDose) && (zoneItem.actualDose > 0)) ? zoneItem.actualDose : 0));
                    });

                    totalDoseToExpand = totalDoseToExpand + parseFloat(quantityToExpand);
                }

                if (selectedSupplyType === undefined) { //pas encore de type d'intrant définis...
                    selectedSupplyType = modulation.supplyType;
                } else if (selectedSupplyType !== modulation.supplyType) {
                    bothSupplyType = true; //les deux types d'intrant sont sélectionnés !
                }
                modulationIdsSelected.push(modulation.id);
            }
            //else //ok, le type actuel est le même que pour l'item de modulation !
        });

        this.setState({
            modulationsSelected: modulationIdsSelected,
            bothSupplyType: bothSupplyType,
            countTotalDoseToExpand: totalDoseToExpand,
            totalDoseSupplyType: selectedSupplyType,
        });
    }

    onRemakeModulationByItem(modulationSelected) { //Plus utiliser, mais à conserver car ce fonctionnel pourrait être demander.
        if (!modulationSelected) return;

        let readableMode = false;
        let newParcelIdsSelected = [];
        newParcelIdsSelected.push(modulationSelected.parcelId);

        this.props.goToNextStepOfModulation(ModulationStep.DOSES, newParcelIdsSelected, readableMode, modulationSelected);
    }

    getContentTable() {
        const { modulationDico, modulationDicoCounter } = this.props;

        let rowsOfTable = [];
        if (modulationDico && (modulationDicoCounter > 0)) {

            for (const key in modulationDico) {
                const modulationItem = modulationDico[key];
                if (modulationItem && modulationItem.zones) {

                    let quantityToExpand = 0;
                    modulationItem.zones.forEach(zoneItem => {
                        quantityToExpand += (zoneItem.ratio * modulationItem.parcelArea * (
                            (numberHelper.testDataValid(zoneItem.actualDose) && (zoneItem.actualDose > 0)) ? zoneItem.actualDose : 0));
                    });

                    //RQ: Même si certaines données ne sont pas affichées, elles sont exploitées dans du fonctionnel 
                    // (Ex: sélection de modulation pour téléchargement -> Cf. 'onDownLoadModulations')
                    rowsOfTable.push(
                        {
                            id: modulationItem.id,
                            parcelId: modulationItem.parcelId,
                            clientId: modulationItem.clientId,
                            parcelName: modulationItem.parcelName,
                            parcelCulture: modulationItem.culture,
                            parcelCampagne: modulationItem.campagne,
                            imagSourceId: modulationItem.imagSourceId,
                            dateImagSource: modulationItem.dateImagSource,
                            providerImagSource: modulationItem.providerImagSource,
                            doseChange: modulationItem.doseChange,
                            doseNominal: (modulationItem.doseChange === true) ? modulationItem.doseNominal : modulationItem.doseMedium,
                            parcelExploitation: modulationItem.exploitation,
                            content: modulationItem.content,
                            supplyType: modulationItem.supplyType,
                            modulationDate: DateHelper.formati18n(new Date(modulationItem.creationDate), 'P'),
                            mediumDose: (modulationItem.doseChange === true) ?
                                numberHelper.fixeDecimal(modulationItem.doseNominal) :
                                numberHelper.fixeDecimal(modulationItem.doseMedium),
                            totalQuantity: numberHelper.fixeDecimal(quantityToExpand),
                            contentRate: (modulationItem.content && (modulationItem.content > 0.0)) ?
                                `${numberHelper.fixeDecimal(modulationItem.content)}%` :
                                '--%',
                            zones: modulationItem.zones,
                            zonesCounter: modulationItem.zonesCounter,
                            //A ce jour (20/04/2021), ces props ne sont pas affichées mais utiles pour le téléchargement !
                            distributionType: modulationItem.distributionType,
                            doseMedium: modulationItem.doseMedium,
                            parcelArea: modulationItem.parcelArea,
                            pdf: modulationItem.id,
                        }
                    );
                }
            }
        }
        return rowsOfTable;
    }

    //Titre des colonnes de la table des biomasses
    getColumnsTable() {

        const columns = [

            { headerName: 'id', field: 'id', hideable: false },
            {
                field: 'parcelName',
                minWidth: 150,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.ColumnPclName}</Typography>)
                },
                renderCell: (params) => {
                    return (<Typography className="select-modulation" onClick={() => this.onShowModulationRowClick(params.row.id)}>{params.value}</Typography>)
                }
            },
            {
                //headerName: StringTranslate.ColumnMdlDate,
                field: 'modulationDate',
                minWidth: 130,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.ColumnMdlDate}</Typography>)
                },
                renderCell: (params) => {
                    return (<Typography className="select-modulation" onClick={() => this.onShowModulationRowClick(params.row.id)}>{params.value}</Typography>)
                }
            },
            {
                //headerName: `${StringTranslate.ColumnMdlMediumDose} U/Ha`,
                field: 'mediumDose',
                minWidth: 150,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.ColumnMdlMediumDose}</Typography>)
                },
                renderCell: (params) => {
                    return (<Typography className="select-modulation" onClick={() => this.onShowModulationRowClick(params.row.id)}>{params.value}</Typography>)
                }
            },
            {
                //headerName: StringTranslate.quantityToExpand,
                minWidth: 200,
                field: 'totalQuantity',
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.quantityToExpand}</Typography>)
                },
                renderCell: (params) => {
                    return (
                        <Typography className="select-modulation" onClick={() => this.onShowModulationRowClick(params.row.id)}>
                            {params.value} {params.row.supplyType === SupplyType.Solid ? "Kg" : "L"}
                        </Typography>
                    )
                }
            },
            {
                //headerName: StringTranslate.ColumnContentRate,
                field: 'contentRate',
                minWidth: 90,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.ColumnContentRate}</Typography>)
                },
                renderCell: (params) => {
                    return (<Typography className="select-modulation" onClick={() => this.onShowModulationRowClick(params.row.id)}>{params.value}</Typography>)
                }
            },
            {
                //headerName: StringTranslate.pdf,
                field: 'pdf',
                minWidth: 60,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.pdf}</Typography>)
                },
                renderCell: (params) => {
                    return (
                        <>
                            {((this.props.generatingFile === true) && (this.props.idModulationGenerating === params.value)) ?
                                <CircularProgress size={20} color="primary" /> :
                                <IconButton color="primary"
                                    onClick={(event) => this.onGenerateModulationFileClick(event, params.value /*== params.row.id*/)}>
                                    <IconPdf />
                                </IconButton>}
                        </>
                    )
                }
            }
        ];

        return columns;
    }

    requestSearch = (searchValue, rowsOfTable) => {
        this.setState({
            searchFilter: searchValue
        });

        try {
            const searchRegex = new RegExp(stringHelper.escapeRegExp(searchValue), 'i');
            const filteredRows = rowsOfTable.filter((row) => {
                return Object.keys(row).some((field) => {
                    let textValue = "" + row[field];
                    return searchRegex.test(textValue.toString());
                });
            });

            this.setState({
                currentRowsOfTable: filteredRows
            });
        }
        catch(errRegex) { /* Peut arriver si on saisis un truc du genre 'EARL++' ! (il n'aime pas les '++') */ }
    }

    /**
     * Permet d'ouvrir le modal, il met à jours le state local à true
     */
    handleOpenModalVideos = () => {
        this.setState({ modalIsOpen: true });
    }
    /**
     * Permet de fermer le modal, il met à jours le state local à false
     */
    handleCloseModalVideos = () => {
        this.setState({ modalIsOpen: false });
    }

    render() {
        const { loadWaiting, errorModulation, showProfilMenuDialog, deleting, downloadWaiting, generatingFile } = this.props;
        const { openConfirmDeleteModulationDialog, openConfirmDownloadModulationDialog, openErrorModulationDialog, typeDownloadChoice,
            showFirstStepForDownloadingModulations, showUseComputerForDownload, idModulationGenerating, pageSize, modulationsSelected,
            searchFilter, currentRowsOfTable, 
            bothSupplyType, countTotalDoseToExpand, totalDoseSupplyType, modalIsOpen, videos } = this.state;

        const rowsOfTable = this.getContentTable();

        return (
            <>
                {/* ↓↓ Partie visuel - affichage Popup pour la suppression des modulations ↓↓ */}
                <Dialog open={openConfirmDeleteModulationDialog}
                    onClose={this.handleCloseConfirmDialogForDeleting}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{StringTranslate.titleDeleteModulations}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{StringTranslate.confirmDeleteModulationsSelected}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseConfirmDialogForDeleting} variant="text" color="error" autoFocus>
                            {StringTranslate.cancelDeleteAction}
                        </Button>

                        <Button onClick={this.onConfirmDeleteModulationByIds} variant="contained" color="primary">
                            {StringTranslate.confirmDeleteAction}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* ↓↓ Partie visuel - affichage Popup pour le téléchargement des modulations ↓↓ */}
                <Dialog open={openConfirmDownloadModulationDialog}
                    onClose={this.handleCloseConfirmDialogForDownloading}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{StringTranslate.ColumnMdlToDownLoad}</DialogTitle>
                    {(showFirstStepForDownloadingModulations === true) ?
                        <>
                            <DialogContent>
                                {/* Etape 1 : Attention, 2 types d'apports différents, on affiche l'étape suivante qui est le choix entre l'IsoXml et le shp */}
                                <DialogContentText>{StringTranslate.twoSupplyTypesSelected}</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseConfirmDialogForDownloading} variant="text" color="error" autoFocus>
                                    {StringTranslate.goBackToModulationTable}
                                </Button>
                                <Button onClick={this.onGoToNextStepToDownload} variant="contained" color="primary">
                                    {StringTranslate.continueStep1}
                                </Button>
                            </DialogActions>
                        </> :
                        <>
                            <DialogContent>
                                {/* Etape 2 : Choix entre l'IsoXml et le shp */}
                                <Grid container>
                                    <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                    <FormLabel component="legend">{StringTranslate.dialogSubtitleTypeShape1}</FormLabel>
                                    <RadioGroup aria-label="downloadChoice" name="downloadChoice" value={typeDownloadChoice} onChange={this.handleChangeTypeDownloadChoice}>
                                        <FormControlLabel value={TypeDownloadChoice.shp} control={<Radio color="primary" />} label={StringTranslate.downloadChoiceShp} />
                                        <FormControlLabel value={TypeDownloadChoice.isoXml} control={<Radio color="primary" />} label={StringTranslate.downloadChoiceIsoXml} />
                                        <FormControlLabel value={TypeDownloadChoice.rds} control={<Radio color="primary" />} label={StringTranslate.downloadChoiceRds} />
                                    </RadioGroup>
                                </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button 
                                            color="secondary" variant="text" size="small" 
                                            onClick={() => showProfilMenuDialog(ProfilIndex.aide_ModulationFormatFichier)}
                                            sx={{mb:2}}
                                        >
                                            {StringTranslate.helpAskModulation}
                                        </Button>
                                    </Grid>
                                </Grid> 
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseConfirmDialogForDownloading} variant="text" color="error" autoFocus>
                                    {StringTranslate.cancelDeleteAction}
                                </Button>

                                <Button onClick={this.onConfirmDownloadModulations} variant="contained" color="primary">
                                    {StringTranslate.confirmDeleteAction}
                                </Button>

                            </DialogActions>
                        </>
                    }
                </Dialog>

                {/* ↓↓ Partie visuel - affichage Pop d'une erreur ↓↓ */}
                {((this.downloadAsking === true) && errorModulation && (errorModulation !== '')) &&
                    (openErrorModulationDialog === true) && (
                    <AlertDialog popup={this.popupErrorDialog} handleChangePopup={this.handleCloseErrorDialog} />
                )}
                {/* Dialog d'incitation à utiliser le PC et l'appli web pour le téléchargement de modulation(s) */}
                {(showUseComputerForDownload) && (showUseComputerForDownload === true) && (
                    <AlertDialog popup={this.popupUseComputerForDownloadDialog} handleChangePopup={this.handleCloseErrorDialog} />
                )}

                <CustomDataGrid
                    tableName={TableType.modulations}
                    pageSize={pageSize}
                    updateNbRowsPerPageTable={this.props.updateNbRowsPerPageTableModulations}
                    onSelectionModelChange={(newSelectionModulations) => { //Sélection des modulations par clic checkbox
                        this.onSelectionModulation(newSelectionModulations);
                    }}
                    keepNonExistentRowsSelected={searchFilter !== "" ? true : false}
                    selectionModel={modulationsSelected}
                    Toolbar={this.CustomToolbar}
                    toolbar={{
                        showProfilMenuDialog: () => showProfilMenuDialog(ProfilIndex.aide_ModulationFormatFichier),
                        handleOpenModalVideos: () => this.handleOpenModalVideos(),
                        handleCloseModalVideos: () => this.handleCloseModalVideos(),
                        deleteModulations: (evt, data) => this.onDeleteModulationByIds(evt, data),
                        generateModulationFile: (evt, data) => this.onGenerateModulationFileClick(data.id),
                        downLoadModulations: (evt, data) => this.onDownLoadModulations(evt, data),
                        formatStringButton: (generalText, textForOneItem, data) => this.formatStringButton(generalText, textForOneItem, data),
                        modulationsSelected: modulationsSelected,
                        generatingFile: generatingFile,
                        idModulationGenerating: idModulationGenerating,
                        rowsOfTable: rowsOfTable,
                        downloadWaiting: downloadWaiting,
                        bothSupplyType: bothSupplyType, 
                        countTotalDoseToExpand: countTotalDoseToExpand, 
                        totalDoseSupplyType: totalDoseSupplyType,
                        modalIsOpen: modalIsOpen,
                        videos: videos,
                        //Concerne la zone de recherche dans le tableau :
                        value: searchFilter,
                        onChange: (event) => this.requestSearch(event.target.value, rowsOfTable),
                        clearSearch: () => this.requestSearch('', rowsOfTable),
                    }}
                    loadWaiting={loadWaiting}
                    loading={deleting}
                    rows={(searchFilter !== "") ? currentRowsOfTable : rowsOfTable}
                    columns={this.getColumnsTable()}
                    checkBoxActive={true}
                />

            </>
        );
    }
}

const mapStateToProps = state => ({
    //Infos provenant du reducer 'modulations':
    modulationDico: state.modulationsData.modulationDico,
    modulationDicoCounter: state.modulationsData.modulationDicoCounter,
    modulationSelected: state.modulationsData.modulationSelected,
    parcelIdsSelected: state.modulationsData.parcelIdsSelected,
    readableMode: state.modulationsData.readableMode,
    downloadWaiting: (state && state.modulationsData) ? state.modulationsData.downloading : false,
    errorModulation: (state && state.modulationsData) ? state.modulationsData.errorMessage : undefined,
    deleting: (state && state.modulationsData) ? state.modulationsData.deleting : false,
    generatingFile: (state && state.modulationsData) ? state.modulationsData.generatingFile : false,
    rowsPerPageForTableModulations: (state && state.settingsData && state.settingsData.settings && state.settingsData.settings.rowsPerPageForTableModulations !== undefined) ? state.settingsData.settings.rowsPerPageForTableModulations : 20,
})

const mapDispatchToProps = dispatch => ({
    showProfilMenuDialog: (index) => dispatch(ActionShowProfilMenuDialog(index)),
    clearModulationValue: () => dispatch(ActionClearModulationValue()),
    buildModulationSample: (clientId, modulationParameter) => dispatch(ActionBuildModulationSample(clientId, modulationParameter)),
    goToModulationStep: (modulationStep, readableMode) => dispatch(ActionGoToStepOfModulation(modulationStep, readableMode)),
    goToNextStepOfModulation: (nextStepOfModulation, parcelIdsSelected, readableMode, modulationSelectedItemValue = undefined) =>
        dispatch(ActionGoToNextStepOfModulation(nextStepOfModulation, parcelIdsSelected, readableMode, modulationSelectedItemValue)),
    goToShowThisModulation: (modulationSelectedItem) => dispatch(ActionGoToShowThisModulation(modulationSelectedItem)),
    generateModulationFile: (modulationSelectedItem) => dispatch(ActionGenerateFileOfThisModulation(modulationSelectedItem)),
    deleteModulationByIds: (modulationIds) => dispatch(ActionDeleteModulations(modulationIds)),
    cleanErrorModulation: () => dispatch(ActionCleanErrorModulation()),
    downloadListPrescriptions: (modulationParameterList, exportFormat) => dispatch(ActionBuildListPrescriptions(modulationParameterList, exportFormat)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modulations);
