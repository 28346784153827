import React from 'react';
import { createRoot } from 'react-dom/client'; //import React from "react"; import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import L from 'leaflet';
import { ButtonGroup, Button, SpeedDialAction, ThemeProvider, Dialog, DialogTitle, DialogContent, Grid, DialogContentText, TextField, DialogActions, CircularProgress, Typography } from '@mui/material';
import '../../assets/css/dp-drawParcel.css';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import leafletfunction from '../../assets/i18n/leaflet-draw-i18n.js';
import DrawParcelPopup from '../../components/drawParcelPopup.jsx';
import { ActionSaveDrawnParcel, ActionDrawingParcel } from '../../redux/actions/parcels.js';
import {
    ActionGoToMapAndUnselectParcel,
    ActionResetAndApplyParcelFilter,
    ActionGoToMapAndSelectParcel, 
    ActionShowInviteToPremium, 
    ReasonInviteToPremium
} from '../../redux/actions/contextApp.js';
import { ActionShowSnackbarCreateZoneOrMarker } from '../../redux/actions/observations';

/* Icones */
import DrawParcelIcon from "../../assets/drawParcelIcon.tsx";
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import UndoIcon from '@mui/icons-material/Undo';

/* Thème */
import getTheme from "../../themes/index.js";
let theme = getTheme();

/**
 * classe en lien avec la fonctionnalité DESSIN DUNE NOUVELLE PARCELLE
 */
class DrawParcelButtonControl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            drawingNewParcel: false, // l'utilisateur a cliqué sur le bouton de dessin de parcelle

            // Gestion popup mobile
            parcelName: StringTranslate.parcelledess,
            saving: false,
            open: false,
        }

        //↓↓ BIND ↓↓
        this.handleClickDrawParcelButton = this.handleClickDrawParcelButton.bind(this);
        this.startDrawNewParcel = this.startDrawNewParcel.bind(this);
        this.onJustDrawNewPoint = this.onJustDrawNewPoint.bind(this);
        this.stopDrawNewParcel = this.stopDrawNewParcel.bind(this);
        this.deleteLastPointDrawParcel = this.deleteLastPointDrawParcel.bind(this);
        this.completeShapeDrawParcel = this.completeShapeDrawParcel.bind(this);
        this.onCreateDrawParcel = this.onCreateDrawParcel.bind(this);
        this.deleteDrawnParcel = this.deleteDrawnParcel.bind(this);
        this.saveDrawnParcel = this.saveDrawnParcel.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onSave = this.onSave.bind(this);

        this.drawerParcel = undefined;
        this.counterVertex = 0;
    }

    /* fonction cycle de vie react.js */
    componentDidMount() {
        L.drawLocal = leafletfunction(); // mise à jour i18n - plugin leaflet draw
    }

    /**
     * fonction callback suite au clic bouton pour dessiner une parcelle
     */
    handleClickDrawParcelButton(event) {
        if (this.state.drawingNewParcel) return; // évite le multi click (si c'est déjà activé)

        const { parcelDicoCounter, maxParcelTypoClient, showInviteToPremium } = this.props;

        // ↓ CAS - GETION DE DROITS - incitation au passage en mode Premium (pour débloquer le nombre de parcelle): ↓
        if (parcelDicoCounter >= maxParcelTypoClient) {
            // si l'écran d'incitation est déjà en cours de présentation:
            if ((showInviteToPremium === true) || (!this.props.inviteToPremium)) {
                return; // => on n'incite pas mais n'autorisera pas non-plus
            }
            else { // => sinon, on lance la demande de présentation de l'incitation !
                this.props.inviteToPremium(ReasonInviteToPremium.NoLimitParcels);
                return; // ET on ne fait rien d'autre...
            }
        }
        // ↓ sinon on autorise le dessin ! ↓
        else {
            this.startDrawNewParcel();
        }
    }

    /**
     * fonction permettant de lancer le dessin d'une nouvelle parcelle (suite au clic bouton)
     */
    startDrawNewParcel() {

        // si création d'observation en cours, on ne peut pas dessiner de parcelle
        if (this.props.renderMapsCmp.PINDROPSTART === true) return;

        // ↓ on indique à renderMaps et au composant que l'on est en train de dessiner ↓
        this.props.renderMapsCmp.DRAWINGPARCEL = true;

        // prévient le parent ('SpeedDial') qu'il ne doit pas se fermer: 
        if (this.props.onSpeedDialCanClose) { 
            this.props.onSpeedDialCanClose(false);
        }
        
        this.setState({ drawingNewParcel: true });
        this.props.drawingParcel(true);

        // ↓ avant de commencer - mise en place des conditions de dessin de parcelle ↓
        this.props.goToMapAndUnselectParcel();
        this.props.resetParcelFilter();

        // Affiche une popup d'aide pour mobile pour placer le premier point
        if (window.innerWidth <= 600)
            this.props.showSnackbarCreateZoneOrMarker(StringTranslate.placeFirstPointForPolygonOnMap);

        // ↓ MODIFICATION FONCTIONNEL SUR MOBILE (touch event) - https://github.com/Leaflet/Leaflet.draw/issues/935#issuecomment-577151194 ↓
        L.Draw.Polygon.prototype._onTouch = L.Util.falseFn;
        if (L.Browser.touch && (this.props.renderMapsCmp.map.tap && this.props.renderMapsCmp.map.tap.disable))
            this.props.renderMapsCmp.map.tap.disable();

        // ↓ création d'un objet contenant le fonctionnel de dessin d'un polygône du plugin LEAFLET DRAW ↓
        this.drawerParcelOptions = {
            allowIntersection: false, // Restricts shapes to simple polygons
            drawError: {
                color: theme.palette.contour.selection, // Color the shape will turn when intersects
                message: `${StringTranslate.noauth}`  // Message that will show when intersect
            },
            icon: new L.DivIcon({ // taille des points
                iconSize: new L.Point(15, 15),
                className: 'leaflet-div-icon dp-divIcon'
            }),
        };
        this.drawerParcel = new L.Draw.Polygon(this.props.renderMapsCmp.map, this.drawerParcelOptions);
        this.drawerParcel.enable(); // on lance le fonctionnel de dessin de parcelle (brique leaflet-Draw)
        this.counterVertex = 0;

        // ↓ mise en place des événements ↓
        this.props.renderMapsCmp.map.on(L.Draw.Event.DRAWVERTEX, this.onJustDrawNewPoint); // lancé lorsque l'utilisateur vient de poser un point de la future parcelle
        this.props.renderMapsCmp.map.on(L.Draw.Event.CREATED, this.onCreateDrawParcel); // lancé lorsque l'utilisateur vient de terminer son polygone (le dessin de sa parcelle)
        this.props.renderMapsCmp.map.on(L.Draw.Event.DRAWSTOP, this.stopDrawNewParcel); // lancé lorsque l'utilisateur a cliqué sur l'option 'annuler'
    }

    /**
     * fonction permettant d'arrêter le dessin de parcelle (lors du clic sur les boutons annuler,...  )
     */
    stopDrawNewParcel(event) {
        // ↓ est-on déjà en train de sauvegarder une parcelle dessinée => le retour de la requête fera le travail ↓
        if (this.props.savingDrawnParcel) return;

        // ↓ on stoppe le fonctionnel ↓
        if (this.drawerParcel)
            this.drawerParcel.disable();

        this.props.renderMapsCmp.DRAWINGPARCEL = false;

        //↓ lors du clic bouton annuler lorsque la parcelle a déjà été dessinée - on la supprime ↓
        this.deleteDrawnParcel();

        /* Permet de supprimer la popup d'aide pour mobile */
        this.props.showSnackbarCreateZoneOrMarker(null);

        //↓ suppression des événements et de l'objet drawer ↓
        this.drawerParcel = null;
        this.props.renderMapsCmp.map.off(L.Draw.Event.DRAWVERTEX, this.onJustDrawNewPoint);
        this.props.renderMapsCmp.map.off(L.Draw.Event.CREATED, this.onCreateDrawParcel);
        this.props.renderMapsCmp.map.off(L.Draw.Event.DRAWSTOP, this.stopDrawNewParcel);

        this.setState({ drawingNewParcel: false });
        this.props.drawingParcel(false);

        // prévient le parent ('SpeedDial' qu'il peut se fermer): 
        if (this.props.onSpeedDialCanClose) { 
            this.props.onSpeedDialCanClose(true);
        }
    }

    /**
     * fonction permettant de supprimer le dernier point lors du dessin d'une nouvelle parcelle
     */
    completeShapeDrawParcel(event) {
        if (this.drawerParcel) {
            if (this.counterVertex > 2) { //minimum un triangle!
                this.drawerParcel.completeShape();
            } else {
                this.stopDrawNewParcel(event);
            }
        }

        // prévient le parent ('SpeedDial' qu'il peut se fermer): 
        if (this.props.onSpeedDialCanClose) { 
            this.props.onSpeedDialCanClose(true);
        }
    }

    /**
     * fonction permettant de supprimer le dernier point lors du dessin d'une nouvelle parcelle
     */
    deleteLastPointDrawParcel(event) {
        if (this.drawerParcel && (this.counterVertex > 0)) {
            this.drawerParcel.deleteLastVertex();
            this.counterVertex -= 1;
        }

        // on n'a pas à prévenir le parent ('SpeedDial') qu'il pourrait se fermer, car on reste en cours de dessin !
    }

    /**
     * fonction callback lors de la création d'une parcelle dessinée (lors du dessin terminé)
     */
    onJustDrawNewPoint(vertex) {
        if (vertex) {
            this.counterVertex += 1;

            /* Permet de supprimer la popup d'aide suite à la création du premier point */
            const layerIds = Object.keys(vertex.layers._layers);
            if (layerIds.length === 1) {
                this.props.showSnackbarCreateZoneOrMarker(null);
            }

            /*Juste pour tester (et fonctionne): Chnager la couleur du premier point d'un parcelle en cours de dessin
            if (this.counterVertex === 1) {
                vertex.layers._layers[Object.keys(vertex.layers._layers)[0]]._icon.style.backgroundColor = "Red";
            }
            */
        }
    }

    /**
     * fonction callback lors de la création d'une parcelle dessinée (lors du dessin terminé)
     */
    onCreateDrawParcel({ layer }) {
        if (!layer) return;

        // ↓ récupération du layer + création variables  ↓ */
        this.drawnLayer = layer;
        let drawnLayerArea = 0;

        // ↓ ajout du nouveau layer sur la carte - provisoire ↓
        this.props.renderMapsCmp.map.addLayer(this.drawnLayer);

        // ↓ suppression des événements ↓
        this.props.renderMapsCmp.map.off(L.Draw.Event.DRAWVERTEX, this.onJustDrawNewPoint);
        this.props.renderMapsCmp.map.off(L.Draw.Event.CREATED, this.onCreateDrawParcel);
        this.props.renderMapsCmp.map.off(L.Draw.Event.DRAWSTOP, this.stopDrawNewParcel);

        /* si l'écran est trop petit pour visualiser le popup d'origine, on affiche la dialog popup */
        if (window.matchMedia('(min-width: 541px)').matches || window.matchMedia('(min-heigt: 541px)').matches) {
            // ↓ configuration et affichage d'une popup (permettant ensuite d'enregistrer la parcelle) ↓
            let popup = L.DomUtil.create('div', 'popup-draw-container');
            
            const root = createRoot(popup); // createRoot(container!) if you use TypeScript
            if (root) {
                root.render(
                    <ThemeProvider theme={theme}>
                        <DrawParcelPopup
                            {...this.props}
                            drawnLayer={this.drawnLayer}
                            drawnLayerArea={drawnLayerArea}
                            saveDrawnParcel={this.saveDrawnParcel}
                            cancel={this.stopDrawNewParcel}
                        />
                    </ThemeProvider>);
            }
            this.drawnLayer.bindPopup(popup);
            this.drawnLayer.on('popupclose', this.stopDrawNewParcel);
            this.drawnLayer.openPopup();
        }
        else {
            this.setState({open: true});
        }
    }

    /**
     * fonction permettant de supprimer le nouveau layer précèdemment créé
     */
    deleteDrawnParcel() {
        if (this.drawnLayer && this.props.renderMapsCmp.map.hasLayer(this.drawnLayer)) {
            this.props.renderMapsCmp.map.removeLayer(this.drawnLayer);
        }

        this.drawnLayer = null;
    }

    /**
     * fonction permettant permettant d'enregistrer la parcelle venant d'être dessinée
     */
    saveDrawnParcel(parcelName) {
        if (this.props.savingDrawnParcel) return; // est-on déjà en train de sauvegarder une parcelle dessinée ?

        if (this.drawnLayer) {
            let parcelNameToSave = (parcelName && parcelName !== '') ? parcelName : StringTranslate.parcelledess;
            this.props.saveDrawnParcel(this.drawnLayer, parcelNameToSave)
                .then((parcelId) => {
                    /* si l'écran est trop petit pour visualiser le popup d'origine, on affiche la dialog popup */
                    if (window.matchMedia('(min-width: 541px)').matches || window.matchMedia('(min-heigt: 541px)').matches) {
                        //↓ suppression du layer via la fermeture de sa popup (voir callback - 'popupclose') ↓
                        this.drawnLayer.closePopup();
                    } else {
                        this.stopDrawNewParcel();
                    }

                    //↓ sélection de la parcelle dessinée ↓
                    if (parcelId > 0) this.props.goToMapAndSelectParcel(parcelId);
                })
                .catch((error) => this.stopDrawNewParcel())
        }
    }

    /* fonction cycle de vie */
    componentDidUpdate(prevProps) {
        //↓↓ CAS - CHANGEMENT DE LANGUE - du fait que maintenant on rentre dans l'application sans attendre les informations du settings... ↓↓
        if (prevProps.settings !== this.props.settings) {
            L.drawLocal = leafletfunction();
        }
    }

    onSave(event) {
        const { parcelName, } = this.state;
        this.setState({saving: true});
        this.saveDrawnParcel(parcelName);
        this.setState({open: false, saving: false});
    }

    onClose(event) {
        this.setState({open: false});
        this.stopDrawNewParcel();
    }

    /* fonction cycle de vie */
    render() {
        const { parcelName, saving, open } = this.state;
        const action =
        {
            icon: <DrawParcelIcon stroke={theme.palette.primary.main} />,
            name: `${StringTranslate.dessinerparcelle}`,
            onClick: this.handleClickDrawParcelButton,
            tooltip: `${StringTranslate.dessinerparcelle}`,
        };

        return (
            <>
                <Dialog
                    open={open}
                    maxWidth={'xl'}
                >
                    <DialogTitle>
                        {StringTranslate.bravotitle}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DialogContentText>{StringTranslate.bravo}</DialogContentText>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="ICItRUC"
                                    label={StringTranslate.nomparcelle}
                                    value={parcelName}
                                    disabled={saving}
                                    sx={{
                                        width: '100%'
                                    }}
                                    onChange={(event) => this.setState({parcelName: event.target.value})}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions id="drawParcelPopUp">
                        <Button variant="text" color="error" onClick={this.onClose} disabled={saving}>
                            {StringTranslate.annulerimport}
                        </Button>
                        <Button variant="contained" color="primary" onClick={this.onSave} disabled={saving} startIcon={(saving) && <CircularProgress color="inherit" size={24} />}>
                            {StringTranslate.enregistrer}
                        </Button>
                    </DialogActions>
                </Dialog>

                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={<Typography color="inherit">{action.tooltip}</Typography>}
                    onClick={action.onClick}
                    tooltipPlacement="bottom"
                    sx={{
                        ...((this.props.class === true) && {
                            opacity: "100",
                            transform: "none",
                            m:0,
                            ml:1
                        })
                    }} />

                {/* TODO : Exploiter le même mécanisme que dans le composant 'PinDropControl' pour définir l'affichage mobile / PC */}
                {(this.state.drawingNewParcel) && 
                    ((window.matchMedia('(min-width: 541px)').matches || window.matchMedia('(min-heigt: 541px)').matches)?
                        <ButtonGroup
                            aria-label="contained primary button group"
                            variant="contained"
                            color="primary"
                            variant_pz="btns-grp-drawAndobs"
                        >
                            {/* Boutons à afficher pour le desktop */}
                            <Button onClick={this.stopDrawNewParcel}
                                sx={{ 
                                    [theme.breakpoints.down('md')]: { display: 'none' }
                                }}>
                                {StringTranslate.annuler}
                            </Button>
                            <Button onClick={this.deleteLastPointDrawParcel}
                                sx={{
                                    [theme.breakpoints.down('md')]: { display: 'none' }
                                }}>
                                {StringTranslate.supppoint}
                            </Button>
                            <Button 
                                onClick={this.completeShapeDrawParcel}
                                sx={{
                                    [theme.breakpoints.down('md')]: { display: 'none' }
                                }}>
                                {StringTranslate.terminer}
                            </Button>
                        </ButtonGroup>
                    :
                        <ButtonGroup
                            aria-label="contained primary button group"
                            variant="contained"
                            color="primary"
                            variant_pz="btns-grp-drawAndobs"
                        >
                            {/* Boutons à afficher pour le mobile */}
                            <Button 
                                onClick={this.stopDrawNewParcel}
                                sx={{ 
                                    [theme.breakpoints.up('md')]: { display: 'none' }
                                }}>
                                <CancelIcon />    
                            </Button> 
                            <Button onClick={this.deleteLastPointDrawParcel}
                                sx={{
                                    [theme.breakpoints.up('md')]: { display: 'none' }
                                }}>
                                <UndoIcon />
                            </Button>
                            <Button 
                                onClick={this.completeShapeDrawParcel}
                                sx={{
                                    [theme.breakpoints.up('md')]: { display: 'none' }
                                }}>
                                <DoneIcon />
                            </Button>
                        </ButtonGroup>
                    )
                }
            </>
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant */
const mapStateToProps = function (state) {
    return {
        settings: state.settingsData.settings, // seul moyen trouvé pour rafraichir le visuel (traduction) du fait que maintenant on rentre dans l'application sans attendre les informations du client
        parcelDicoCounter: state.parcelsData.parcelDicoCounter,
        maxParcelTypoClient: (state.clientUserData.clientDatas && (state.clientUserData.clientDatas.maxParcelTypoClient > 0)) ? state.clientUserData.clientDatas.maxParcelTypoClient : Number.MAX_SAFE_INTEGER,
        savingDrawnParcel: state.parcelsData.savingDrawnParcel,
        showInviteToPremium: (state && state.contextAppData) ? state.contextAppData.showInviteToPremium : false,
    };
}

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
    drawingParcel: (isDrawingParcel) => dispatch( ActionDrawingParcel(isDrawingParcel) ),
    goToMapAndUnselectParcel: () => dispatch(ActionGoToMapAndUnselectParcel()),
    resetParcelFilter: () => dispatch(ActionResetAndApplyParcelFilter()),
    saveDrawnParcel: (layer, name) => dispatch(ActionSaveDrawnParcel(layer, name)),
    goToMapAndSelectParcel: (parcelId) => dispatch(ActionGoToMapAndSelectParcel(parcelId)),
    inviteToPremium: (reasonCase) => dispatch(ActionShowInviteToPremium(reasonCase)),
    showSnackbarCreateZoneOrMarker: (text) => dispatch(ActionShowSnackbarCreateZoneOrMarker(text)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DrawParcelButtonControl);