import React from "react";
import { connect } from 'react-redux';
import { 
    Button, TextField, Autocomplete,
    Grid, Stack, InputLabel, Alert
        } from "@mui/material";

import WarningIcon from '@mui/icons-material/Warning';

import StringTranslate from "../../assets/i18n/stringLanguage";
import { ActionSelectPlan, ActionGetPlansSubscriptions, ActionSaveUpdatedClientBilling, ActionSetIsFormValid} from "../../redux/actions/clientUser";
import { ActionSaveSettingsAsk } from '../../redux/actions/settings.js';

import countryLookupDatas from '../../datas/countryPickerList.json';
import { validateCommonValue, validatePostalCode, validatePhoneValue } from './userInfo';
import '../../assets/css/profil.css'
import { geoSearch } from '../../utils/api_search_geo.js';
import sendError from '../../utils/errorService.js';

import MainCard from "../subLayouts/mainCard";
import SubCard from "../subLayouts/subCard";

import getTheme from "../../themes/index.js";

let theme = getTheme();

class AbonnementAdresseFact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            completeAddress: props.completeAddress,
            country: (props.completeAddress && props.completeAddress.country) ? props.completeAddress.country : '',
            inputCountryValue: '',
            codeCountry: props.codeCountry,
            postalCode: (props.completeAddress && props.completeAddress.postalCode) ? props.completeAddress.postalCode : '',
            city: (props.completeAddress && props.completeAddress.cityName) ? props.completeAddress.cityName : '',
            inputCityValue: '',
            adressePostale: (props.completeAddress && props.completeAddress.label) ? props.completeAddress.label : '',
            
            nom: (props.naming && props.naming.lastName) ? props.naming.lastName : '',
            prenom: (props.naming && props.naming.firstName) ? props.naming.firstName : '',
            societe: (props.naming && props.naming.compagnyName) ? props.naming.compagnyName : '',
            phone: (props.phone) ? props.phone : '',
            
            onEdit_All: false, //gardeFou : false,

            diabledVilleTextField: false,
            cities: [],

            requiredDatasNeed: false, 
            lastChange: undefined,
        };

        this.countryList = (props && props.language) ? countryLookupDatas[props.language] : countryLookupDatas['FR'];
        
        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    validateDatas(dataName, newValue) {    
        const { nom, prenom, phone, 
            adressePostale, city, country, postalCode, codeCountry } = this.state;

        //Rq : pas de contrôle sur le nom de la société !
        if (!dataName) {
            return ((validateCommonValue(prenom) === true) && (validateCommonValue(nom) === true) && (validatePhoneValue(phone) === true) && 
                (validateCommonValue(adressePostale) === true) && (validatePostalCode(postalCode, codeCountry) === true) &&
                (validateCommonValue(city) === true) && (validateCommonValue(country) === true));
        } else {
            // sinon, la nouvelle valeur du champ modifié, n'a pas encore été actualisé sur le state local, donc on exploite la nouvelle donnée fournie:
            if (dataName === 'firstName') {
                return ((validateCommonValue(newValue) === true) && (validateCommonValue(nom) === true) && (validatePhoneValue(phone) === true) && 
                    (validateCommonValue(adressePostale) === true) && (validatePostalCode(postalCode, codeCountry) === true) &&
                    (validateCommonValue(city) === true) && (validateCommonValue(country) === true));
            } else if (dataName === 'lastName') {
                return ((validateCommonValue(prenom) === true) && (validateCommonValue(newValue) === true) && (validatePhoneValue(phone) === true) && 
                    (validateCommonValue(adressePostale) === true) && (validatePostalCode(postalCode, codeCountry) === true) &&
                    (validateCommonValue(city) === true) && (validateCommonValue(country) === true));
            } else if (dataName === 'phone') {
                return ((validateCommonValue(prenom) === true) && (validateCommonValue(nom) === true) && (validatePhoneValue(newValue) === true) && 
                    (validateCommonValue(adressePostale) === true) && (validatePostalCode(postalCode, codeCountry) === true) &&
                    (validateCommonValue(city) === true) && (validateCommonValue(country) === true));
            } 
            // ↓ infos de facturation: ↓
            else if (dataName === 'label') {
                return ((validateCommonValue(prenom) === true) && (validateCommonValue(nom) === true) && (validatePhoneValue(phone) === true) && 
                    (validateCommonValue(newValue) === true) && (validatePostalCode(postalCode, codeCountry) === true) &&
                    (validateCommonValue(city) === true) && (validateCommonValue(country) === true));
            } else if (dataName === 'postalCode') {
                return ((validateCommonValue(prenom) === true) && (validateCommonValue(nom) === true) && (validatePhoneValue(phone) === true) && 
                    (validateCommonValue(adressePostale) === true) && (validatePostalCode(newValue, codeCountry) === true) &&
                    (validateCommonValue(city) === true) && (validateCommonValue(country) === true));
            } else if (dataName === 'city') {
                return ((validateCommonValue(prenom) === true) && (validateCommonValue(nom) === true) && (validatePhoneValue(phone) === true) && 
                    (validateCommonValue(adressePostale) === true) && (validatePostalCode(postalCode, codeCountry) === true) &&
                    (validateCommonValue(newValue) === true) && (validateCommonValue(country) === true));
            } else if (dataName === 'country') {
                return ((validateCommonValue(prenom) === true) && (validateCommonValue(nom) === true) && (validatePhoneValue(phone) === true) && 
                    (validateCommonValue(adressePostale) === true) && (validatePostalCode(postalCode, codeCountry) === true) &&
                    (validateCommonValue(city) === true) && (validateCommonValue(newValue) === true));
            }

            return true;
        }
    }

    /* fonction cycle de vie react.js */
    componentDidMount() {
        //Au premier affichage, on lance une vérification des données déjà renseignées, afin de vérifier si déjà valide.
        const isValidEarly = this.validateDatas(undefined, undefined);
        this.setState({ requiredDatasNeed: !isValidEarly, onEdit_All: !isValidEarly, });

        if (window) {
            if (isValidEarly !== true) 
                window.scrollTo(0, 0); //Permet de se positionner en haut de la saisie pour forcer l'utilisateur à descendre et compléter chaque info nécessaire.
            else 
                window.scrollTo(0, window.innerHeight); //si les infos sont toutes là, on peut se diriger tout de suite en bas de la page...
        }
    }

    /*componentDidUpdate(prevProps, prevState) {
        if ((this.state.onEdit_All === true) && (
            () || () || () 
        )) {
            this.setState({ requiredDatasNeed: !this.validateDatas(), });
        }
        //else // RAS !!!!
    }*/
    
    /* fonction permettant de requêter une API pour récupérer le nom des villes */
    searchCitiesFromPostalCode(postalCode) {
        if (!postalCode) return;

        const { codeCountry } = this.state;

        geoSearch.getCitiesFromPostalCode(codeCountry, postalCode)
            .then((result) => {                
                if (result) {
                    if (result.length === 1) {
                        //const name = 'postalCode';
                        const newValue = result[0];
                        // Rq: 'validateDatas' ne sait pas prendre deux nouveauté en un seul appel, donc on appelle deux fois 'setState'
                        this.setState({ 
                            cities: result, //city: newValue, 
                            onEdit_All: true,
                            requiredDatasNeed: false, //!this.validateDatas(name, newValue), //pas la peine de contrôler maintenant, car il faut attendre le contrôle sur la ville !
                        }, () => {
                            const name2 = 'postalCode';
                            this.setState({ 
                                city: newValue, 
                                onEdit_All: true,
                                requiredDatasNeed: !this.validateDatas(name2, newValue), 
                            });
                        }); // force la seule ville avec ce code postal !
                    } else if (result.length > 1) {
                        this.setState({ cities: result, }); //touche pas à city qui a peut être été alimenté !
                    } else {
                        this.setState({ cities: (result) ? result : [], }); //touche pas à city qui a peut être été alimenté !
                    }
                } else {
                    this.setState({ cities: [], }); //touche pas à city qui a peut avoir été alimenté !
                }
            })
            .catch( (err) => {
                sendError('abonnementAdresseFact - searchCitiesFromPostalCode', { 
                    "error": err, 
                    "postalCode": postalCode, 
                    "codeCountry": codeCountry, 
                });
            });
    }

    saveInfosFacturation() {
        // ↓↓ création nouvel objet ↓↓
        let { clientDatas } = this.props;
        const { societe, prenom, nom, phone, 
            adressePostale, postalCode, city, country } = this.state;

        let newClientDatas = {
            ...clientDatas,
            naming: {
                compagnyName: societe,
                firstName: prenom,
                lastName: nom,
            },
            phone: phone,
            address: {
                label: adressePostale,
                postalCode: postalCode,
                cityName: city,
                country: country,
            }
        };

        this.saveCodeCountryToSettings();

        // ↓↓ enregistrement BDD + redux ↓↓ 
        this.props.saveUpdatedClientBilling(newClientDatas); //même si erreur (logguée en principe), on essayera de passer à l'étape suivante (pour éviter de louper une vente).

        // ↓↓ Redux - SubscriptionProcess ↓↓
        this.props.setIsFormValid(true);
        
        this.setState({ 
            onEdit_All: false, 
            requiredDatasNeed: false, 
            lastChange: undefined, 
        });  
    }

    /* fonction permettant de revenir à l'étape précèdente */
    handleBack() {
        //↓ on enregistre les données (si elles ont été modifiées) ↓
        this.saveInfosFacturation(); 

        // ↓ on passe à l'étape précèdente ↓ 
        this.props.handlePreviousStep();
    }

    /* fonction permettant d'enregistrer le formulaire et d'aller à l'étape suivante */

    handleNext() {
        //↓ on enregistre les données (si elles ont été modifiées) ↓
        this.saveInfosFacturation(); 

        // ↓ on passe à l'étape suivante ↓
        this.props.handleNextStep();
    }
    
    /* fonction commune à tous les changements de valeurs provenant des différents champs du formulaire */
    handleInputChange = (name) => ( event ) => {
        if (event !== null) {
            const newValue = event.target.value;
            // ↓ on passera le formulaire en édition en mettant à jour la valeur ↓
            //Rq: on on vérifiera sa cohérence (la validité de l'ensemble, lors de l'update...)
            
            // ↓ infos utilisateur: ↓
            if (name === 'compagnyName') {
                this.setState({ 
                    societe: newValue, 
                    onEdit_All: true, 
                    lastChange: name,
                    requiredDatasNeed: !this.validateDatas(name, newValue), 
                });
            } else if (name === 'firstName') {
                this.setState({ 
                    prenom: newValue, 
                    onEdit_All: true, 
                    lastChange: name,
                    requiredDatasNeed: !this.validateDatas(name, newValue), 
                });
            } else if (name === 'lastName') {
                this.setState({ 
                    nom: newValue, 
                    onEdit_All: true, 
                    lastChange: name,
                    requiredDatasNeed: !this.validateDatas(name, newValue), 
                });
            } else if (name === 'phone') {
                this.setState({ 
                    phone: newValue, 
                    onEdit_All: true, 
                    lastChange: name,
                    requiredDatasNeed: !this.validateDatas(name, newValue), 
                });
            } 
            // ↓ infos de facturation: ↓
            else if (name === 'label') {
                this.setState({ 
                    adressePostale: newValue, 
                    onEdit_All: true, 
                    lastChange: name,
                    requiredDatasNeed: !this.validateDatas(name, newValue), 
                });
            } else if (name === 'postalCode') {
                const { codeCountry } = this.state;

                let postalCodeOk = validatePostalCode(newValue, codeCountry);
                let newDisabledVilleTextField = !postalCodeOk;
                if ((codeCountry === "FR") || (codeCountry === "ES")) {
                    if (newValue.length !== 5) {
                        newDisabledVilleTextField = true;

                        postalCodeOk = false;
                    } 
                    //else les variables sont déjà définit !
                } 
                //else les variables sont déjà définit !

                this.setState({
                    postalCode: event.target.value,
                    onEdit_All: true,
                    lastChange: name,
                    requiredDatasNeed: !this.validateDatas(name, newValue), 
                    diabledVilleTextField: newDisabledVilleTextField,
                });

                if (postalCodeOk === true) {
                    this.searchCitiesFromPostalCode(newValue);
                } else if ((!newValue) || (newValue === '')) {
                    this.setState({ cities: [], });
                }
            } /*else if (name === 'cityName') { //Cf 'handleChangeCity' !
                let theNewCityName = event.target.value;
                if (theNewCityName === 0 ) {
                    theNewCityName = event.target.textContent;
                }

                this.setState({
                    city: theNewCityName, 
                    onEdit_All: true, 
                    lastChange: name,
                });
            }*/
            //idem pour le pays via 'handleChangeCountry' !
        }
    };

    saveCodeCountryToSettings() {
        const  { codeCountry, completeAddress, settings } = this.props;
        const oldCountry = (completeAddress && completeAddress.country) ? completeAddress.country : '';

        //enregistre en BdD les changement UNIQUEMENT s'il y en a eu ... sur le pays !
        if ((oldCountry !== this.state.country) || (codeCountry !== this.state.codeCountry)) {
            let settingsToSave = {
                ...settings,
                codeCountry: this.state.codeCountry,
            };
                
            // ↓ sauvegarde en BDD ↓
            this.props.saveSettings(settingsToSave);
        }
        //else //pas d'enregistrement !
    }

    handleChangeCountry = (event, newValue) => {
        const { codeCountry } = this.state;

        //si on arrive ici, c'est que le choix parmis celles autorisées est correct !
        //on recherche le code pays associé avant d'actualiser les données:
        let newCodeCountry = codeCountry;
        if (this.countryList) {
            try {
                const countriesFund = this.countryList.filter((countryItem) => (countryItem.Name === newValue)? true : false);
                if (countriesFund && (countriesFund.length > 0)) {
                    const valueObj = JSON.parse(countriesFund[0].Value);
                    if (valueObj && (valueObj.codeCountry)) {
                        newCodeCountry = valueObj.codeCountry;
                    }
                }
            }
            catch (errCode) {
                //ras. tampis !
            }
        }
        //else // ne change rien !

        const name = 'country';
        this.setState({ 
            country: newValue,
            onEdit_All: true,
            lastChange: name,
            requiredDatasNeed: !this.validateDatas(name, newValue), 
            codeCountry: newCodeCountry, 
        });
    }

    setCountryValue = (event, newInputValue) => {
        this.setState({ inputCountryValue: newInputValue, lastChange: 'countryInput', });

        //Rq: Pour le moment on n'en fait rien... mais on pourrai s'en servir pour auto-sélectionner le seul item correspondant à la saisie en cours...
        // => idéalement en récupérant la liste filtré par le composant 'Autocomplete'
    }

    handleChangeCity = (event, newValue) => {
        let theNewCityName = newValue;
        if (validateCommonValue(theNewCityName) !== true) {
            theNewCityName = event.target.value;
        }
        if (theNewCityName === 0 ) {
            theNewCityName = event.target.textContent;
        }

        const name = 'city';
        this.setState({
            city: theNewCityName,
            onEdit_All: true,
            lastChange: name, 
            requiredDatasNeed: !this.validateDatas(name, theNewCityName), 
        });
    }

    setCityValue = (event, newInputValue) => {
        this.setState({ inputCityValue: newInputValue, onEdit_All: true, lastChange: 'cityInput', });

        //Rq: Pour le moment on n'en fait rien... mais on pourrai s'en servir pour auto-sélectionner le seul item correspondant à la saisie en cours...
        // => idéalement en récupérant la liste filtré par le composant 'Autocomplete'
        // ET AUSSI rechercher des villes pouvant correspondre à la saisie (d'après l'API externe).
    }

    handleBlurCity = (event) => {
        const { city, inputCityValue, lastChange } = this.state;

        const ctrlCity = validateCommonValue(city);
        const ctrlCityInput = validateCommonValue(inputCityValue);

        //si la sélection n'a pas été fait mais que la saisie est ok, alors on valide !
        if ( ((ctrlCity !== true) || (lastChange === 'cityInput')) && (ctrlCityInput === true)) {
            //TODO : A l'idéal, il faudrait différentier le cas où aucune ville n'est référencée => pour considérer la saisie ok !
            // avec le cas où on a une liste => on valide que si la saisie corresponds à l'une des villes.
            const name = 'city';
            this.setState({ 
                city: inputCityValue, 
                requiredDatasNeed: !this.validateDatas(name, inputCityValue), 
            });
        } 
        // Sinon, si la saisie vient de vider le choix de la ville, on invalide.
        else if ((lastChange === 'cityInput') && (inputCityValue === '')) {
            this.setState({ 
                city: inputCityValue, 
                requiredDatasNeed: true, //!this.validateDatas(name, inputCityValue),  //pas besoin de relancer, on le sait déjà que ce n'est pas ok !
            });
        }
    }

    render() {
        const { societe, nom, prenom, phone, 
            adressePostale, city, inputCityValue, country, inputCountryValue, codeCountry, postalCode,
            onEdit_All, cities, diabledVilleTextField, requiredDatasNeed } = this.state;
        const { errorMessage } = this.props;
        
        const lNameCtrlResult = validateCommonValue(nom);
        const fNameCtrlResult = validateCommonValue(prenom);
        const phoneCtrlResult = validatePhoneValue(phone);
        const labelCtrlResult = validateCommonValue(adressePostale);
        const postalCodeCtrlResult = validatePostalCode(postalCode, codeCountry);
        //const cityCtrlResult = validateCommonValue(city);

        return (
            <MainCard>
                <Grid container spacing={3}>
                {/* ↓ partie - AFFICHAGE des plans ↓ */}
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <SubCard title={StringTranslate.infoUsers}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <InputLabel>{StringTranslate.labelSociete}</InputLabel>
                                            <TextField
                                                value={societe}
                                                id="compagnyName"
                                                placeholder={`${StringTranslate.labelSociete}`}
                                                autoComplete={`${StringTranslate.acsociete}`}
                                                variant="outlined"
                                                onChange={this.handleInputChange('compagnyName')}
                                                inputProps={{ maxLength: 80 }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel>{StringTranslate.labelNom}</InputLabel>
                                            <TextField
                                                value={nom}
                                                required
                                                id="lastName"
                                                placeholder={`${StringTranslate.labelNom}`}
                                                autoComplete={`${StringTranslate.acnom}`}
                                                variant="outlined"
                                                onChange={this.handleInputChange('lastName')}
                                                inputProps={{ maxLength: 80 }}
                                                error={(lNameCtrlResult !== true) && onEdit_All}
                                                helperText={(lNameCtrlResult !== true) ? `${StringTranslate.mandatoryField}` : ''}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel>{StringTranslate.labelPrenom}</InputLabel>
                                            <TextField
                                                value={prenom}
                                                required
                                                id="firstName"
                                                placeholder={`${StringTranslate.labelPrenom}`}
                                                fullWidth
                                                autoComplete={`${StringTranslate.acprenom}`}
                                                variant="outlined"
                                                onChange={this.handleInputChange('firstName')}
                                                inputProps={{ maxLength: 80 }}
                                                error={(fNameCtrlResult !== true) && onEdit_All}
                                                helperText={(fNameCtrlResult !== true) ? `${StringTranslate.mandatoryField}` : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel>{StringTranslate.labelTelephone}</InputLabel>
                                            <TextField
                                                value={phone}
                                                required
                                                id="phone"
                                                placeholder={`${StringTranslate.labelTelephone}`}
                                                fullWidth
                                                autoComplete={`${StringTranslate.actelephone}`}
                                                variant="outlined"
                                                onChange={this.handleInputChange('phone')}
                                                inputProps={{ maxLength: 20 }}
                                                error={(phoneCtrlResult !== true) && onEdit_All}
                                                helperText={(phoneCtrlResult !== true) ? `${StringTranslate.mandatoryField}` : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                </SubCard>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SubCard title={StringTranslate.adresseLabelCard}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <InputLabel>{StringTranslate.labelAdresse}</InputLabel>
                                            <TextField
                                                value={adressePostale}
                                                required
                                                id="label"
                                                placeholder={`${StringTranslate.labelAdresse}`}
                                                fullWidth
                                                autoComplete={`${StringTranslate.acadresse}`}
                                                variant="outlined"
                                                onChange={this.handleInputChange('label')}
                                                inputProps={{ maxLength: 100 }}
                                                error={(labelCtrlResult !== true) && onEdit_All}
                                                helperText={(labelCtrlResult !== true) ? `${StringTranslate.mandatoryField}` : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel>{StringTranslate.pays}</InputLabel>
                                            {/*<Autocomplete ... error={(validateCommonValue(country) !== true) && onEdit_All} ... /> non !*/}
                                            <Autocomplete
                                                fullWidth disableClearable
                                                id="autocomplete_Country_ID2"
                                                options={this.countryList.map((countryItem) => countryItem.Name)}
                                                value={country}
                                                renderInput= {(params) => <TextField variant="outlined" {...params} label=""/>}
                                                onChange={(event, newValue) => { this.handleChangeCountry(event, newValue); }}
                                                inputValue={inputCountryValue}
                                                onInputChange={(event, newInputValue) => { this.setCountryValue(event, newInputValue); }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel>{StringTranslate.labelCp}</InputLabel>
                                            <TextField
                                                value={postalCode}
                                                required
                                                id="postalCode"
                                                placeholder={`${StringTranslate.labelCp}`}
                                                fullWidth
                                                variant="outlined"
                                                onChange={this.handleInputChange('postalCode')}
                                                inputProps={{ maxLength: 8 }}
                                                error={((postalCodeCtrlResult !== true) || diabledVilleTextField) && onEdit_All}
                                                helperText={(postalCodeCtrlResult !== true) ? `${StringTranslate.mandatoryField}` : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel>{StringTranslate.ville}</InputLabel>                        
                                            {/*<Autocomplete
                                                value={city}
                                                freeSolo
                                                disableClearable
                                                fullWidth
                                                id="asynchronous autocomplete"
                                                onChange={this.handleInputChange('cityName')}
                                                onInputChange={this.handleInputChange('cityName')}
                                                options={cities}
                                                renderInput= {
                                                    (params) => 
                                                        <TextField {...params} variant="outlined" label=""/>}
                                            />*/}
                                            {/*<Autocomplete ... error={(cityCtrlResult !== true) && onEdit_All} ... /> non !*/}
                                          <Autocomplete 
                                                freeSolo fullWidth
                                                disableClearable
                                                id="autocomplete_City_ID2"
                                                value={city}
                                                renderInput={(params) => <TextField {...params} variant="outlined" label=""/>}
                                                onChange={(event, newValue) => { this.handleChangeCity(event, newValue); }} 
                                                inputValue={inputCityValue}
                                                onInputChange={(event, newInputValue) => { this.setCityValue(event, newInputValue); }}
                                                onBlur={(event) => { this.handleBlurCity(event); }}
                                                options={cities}
                                                noOptionsText={StringTranslate.AnyCityFromCurrentPostalcode}
                                            /> 
                                        </Grid>
                                    </Grid>
                                </SubCard>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {/* ↓ affichage texte concernant la TVA ↓ */}
                        {(country !== StringTranslate.france) && (
                            <Alert severity="error"
                                icon={<WarningIcon />}
                                sx={{ color: theme.palette.warning.main }}/*  sx={{ color: theme.palette.error.main }} */>
                                {StringTranslate.tvanonrecuperable}
                            </Alert>)}
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between">
                            {/* ↓ zone d'affichage des boutons ↓  */}
                            <Button 
                                type='submit'
                                variant="text" 
                                color="primary"
                                onClick={this.handleBack}
                            >
                                {`${StringTranslate.revenir}`}
                            </Button>
                            
                            {/* je me demande si 'onEdit_All' est nécessaire sur la prop 'disabled' !? */}
                            <Button 
                                type='submit'
                                variant="contained" 
                                color="primary" 
                                onClick={this.handleNext} 
                                disabled={(onEdit_All && requiredDatasNeed)} 
                            >
                                {`${StringTranslate.valider}`}
                            </Button>                        
                        </Stack>
                    </Grid>
                    
                    {/* ↓↓ Partie Zone erreur ↓↓ */}
                    {(errorMessage && (errorMessage !== '')) && (
                        <Grid item xs={12}>
                            <Alert
                                severity="error"
                                icon={<WarningIcon />}
                                sx={{ color: theme.palette.error.main }}
                            >
                                {errorMessage}
                            </Alert>
                        </Grid>
                    )}
                </Grid>
            </MainCard>
        );
    }
}

const mapStateToProps = state => ({
    clientUserData: state.clientUserData,
    completeAddress: state.clientUserData.clientDatas.address,
    clientDatas: state.clientUserData.clientDatas,
    naming: state.clientUserData.clientDatas.naming,
    phone: state.clientUserData.clientDatas.phone,
    codeCountry: state.settingsData.settings.codeCountry,
    errorMessage: state.clientUserData.errorMessage, 
    settings: state.settingsData.settings,
})

const mapDispatchToProps = dispatch => ({
    selectPlan: (planId, discountPlanId) => dispatch( ActionSelectPlan(planId, discountPlanId) ),
    getPlansSubscriptions: () => dispatch( ActionGetPlansSubscriptions() ),
    saveUpdatedClientBilling: (clientDatas) => dispatch( ActionSaveUpdatedClientBilling(clientDatas) ),
    setIsFormValid: (bool) => dispatch( ActionSetIsFormValid(bool) ),
    saveSettings: (settingsToSave) => dispatch( ActionSaveSettingsAsk(settingsToSave) ),
})

export default connect(mapStateToProps, mapDispatchToProps)(AbonnementAdresseFact);