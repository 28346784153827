import React from "react";

import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';

import SeedingIcon from '../../../assets/seeding-logo.tsx'

import '../../../assets/css/swipeablePanel.css';

import getTheme from "../../../themes/index.js";

let theme = getTheme();

/* Constantes représentant les différentes couleurs NDVI */
const colorCst = {
    leveeTresFaible: theme.palette.ndviColors.levee, //rose
    leveeFaible1: "linear-gradient(to right, " + theme.palette.ndviColors.levee + " 0%, " + theme.palette.ndviColors.faible + " 100%)", //rose à rouge
    leveeMoyenne1: "linear-gradient(to right, " + theme.palette.ndviColors.faible + " 0%, " + theme.palette.ndviColors.faibleMoyenne + " 100%)", //rouge à orange
    leveeForte1: "linear-gradient(to right, " + theme.palette.ndviColors.faibleMoyenne + " 0%, " + theme.palette.ndviColors.moyenne + " 100%)", //orange à jaune
    croissanceFaible1: "linear-gradient(to right, " + theme.palette.ndviColors.moyenne + " 0%, " + theme.palette.ndviColors.moyenneForte + " 100%)", //jaune à vert clair
    croissanceMoyenne1: "linear-gradient(to right, " + theme.palette.ndviColors.moyenneForte + " 0%, " + theme.palette.ndviColors.forte + " 100%)", //vert clair à vert
    croissanceForte: theme.palette.ndviColors.forte, //vert
    croissanceMoyenne2: "linear-gradient(to left, " + theme.palette.ndviColors.moyenneForte + " 0%, " + theme.palette.ndviColors.forte + " 100%)", //vert à vert clair
    croissanceFaible2: "linear-gradient(to left, " + theme.palette.ndviColors.moyenne + " 0%, " + theme.palette.ndviColors.moyenneForte + " 100%)", //vert clair à jaune
    maturiteFaible: "linear-gradient(to left, " + theme.palette.ndviColors.faibleMoyenne + " 0%, " + theme.palette.ndviColors.moyenne + " 100%)",//jaune à orange
    maturiteMoyenne: "linear-gradient(to left, " + theme.palette.ndviColors.faible + " 0%, " + theme.palette.ndviColors.faibleMoyenne + " 100%)",//orange à rouge
    maturiteForte: "linear-gradient(to left, " + theme.palette.ndviColors.levee + " 0%, " + theme.palette.ndviColors.faible + " 100%)",//rouge à rose
    nuageux: "gainsboro", //gris
};

///////////////////////////////////////////////////////////////
// le composant widget pour afficher des diagrammes (NDVI, ...)
///////////////////////////////////////////////////////////////
function WidgetNdviCharts(props) {

    return (
      <Table size="small" className="chart-ndvi">
        <TableBody>
         
            <TableRow>
              <TableCell rowSpan={2}> <SeedingIcon sx={{"stroke": theme.palette.grey[700]}} /></TableCell>
              <TableCell></TableCell>
              <TableCell style={{background: colorCst.leveeTresFaible}}></TableCell>
              <TableCell style={{background: colorCst.leveeFaible1}}></TableCell>
              <TableCell style={{background: colorCst.leveeMoyenne1}}></TableCell>
              <TableCell style={{background: colorCst.leveeForte1}}></TableCell>
              <TableCell style={{background: colorCst.croissanceFaible1}}></TableCell>
              <TableCell style={{background: colorCst.croissanceMoyenne1}}></TableCell>
              <TableCell style={{background: colorCst.croissanceForte}}></TableCell>
              <TableCell style={{background: colorCst.croissanceMoyenne2}}></TableCell>
              <TableCell style={{background: colorCst.croissanceFaible2}}></TableCell>
              <TableCell style={{background: colorCst.maturiteFaible}}></TableCell>
              <TableCell style={{background: colorCst.maturiteMoyenne}}></TableCell>
              <TableCell style={{background: colorCst.maturiteForte}}></TableCell>
              <TableCell style={{background: colorCst.leveeTresFaible}}></TableCell>
              <TableCell></TableCell>
              <TableCell style={{background: colorCst.nuageux}}></TableCell>
              
            </TableRow>
          
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="left" colSpan={3}><Typography variant="caption" display="block" className="widget-color" gutterBottom>{StringTranslate.lifting}</Typography></TableCell>
            <TableCell /><TableCell />
            <TableCell align="center" colSpan={3}><Typography variant="caption" display="block" className="widget-color" gutterBottom>{StringTranslate.growth}</Typography></TableCell>
            <TableCell /><TableCell />
            <TableCell align="right" colSpan={3}><Typography variant="caption" display="block" className="widget-color" gutterBottom>{StringTranslate.maturity}</Typography></TableCell>
            <TableCell />
            <TableCell align="center"><Typography variant="caption" display="block" className="widget-color" gutterBottom>{StringTranslate.cloud}</Typography></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );

}

export default WidgetNdviCharts;
