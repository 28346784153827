import React from 'react';
import Card from 'react-credit-cards';
import '../../assets/css/sic-stripe_info_card.css';
import '../../assets/css/profil.css'
import { Button, Grid, Stack, FormHelperText, InputLabel, Alert, CircularProgress } from '@mui/material';
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from 'react-stripe-elements';
import compareAsc from 'date-fns/compareAsc';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import L from 'leaflet';
import sendError from '../../utils/errorService.js';
import getTheme from "../../themes/index.js";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningIcon from '@mui/icons-material/Warning';


let theme = getTheme();


/* i18n */
const strings_FR = {
    card: {
        valid: `${StringTranslate.valid}`
    },
    placeholders: {
        name: `${StringTranslate.cbNamemaj}`
    },

};

/* constante (style) en lien avec les les éléments react-stripe-elements */
const options = {
    style: {
        base: {
            letterSpacing: '0.025em',
        },
        invalid: {
            color: theme.palette.error.main,
        }
    }
};

/**
 * Composant React.js pour la visualisation/lodification des champs Adress (nom,prénom, tel,...)
 */
export class StripeInfoCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            creditCard: (props.clientDatas && props.clientDatas.creditCard) ? props.clientDatas.creditCard : undefined,
            number: (props.clientDatas && props.clientDatas.creditCard && props.clientDatas.creditCard.lastNumberCc) ? `************${props.clientDatas.creditCard.lastNumberCc}` : '****************',
            expiry: (props.clientDatas && props.clientDatas.creditCard && props.clientDatas.creditCard.expirDateCc) ? this.clearNumber(props.clientDatas.creditCard.expirDateCc) : '',
            expiryError: false, // données en lien avec l'expiration de la carte bancaire
            errorNumber: undefined,
            name: '',
            errorExpiry: undefined,
            cvc: '***',
            errorCvc: undefined,
            focused: undefined, // en lien avec le focus de la carte bancaire
            onEdit: false, // est-il en mode édition
            onSubmit: false, // est on en train d'enregistrer les données côté serveur ?
            errorSubmit: undefined, // message d'erreur lors de l'enregistrement côté serveur
            disabled: true, // en lien avec le bouton submit
        };
        this.cmpRef = React.createRef(); // référence au composant se trouvant dans DOM - va permettre cabler un événement lors du press ENTER du clavier
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.saveCard = this.saveCard.bind(this);
        this.onEnterClick = this.onEnterClick.bind(this);
    }

    /* fonction permettant de mettre à jour les données du state (données du formulaire) */
    updateDataState() {
        const { clientDatas } = this.props;
        this.setState({
            creditCard: (clientDatas && clientDatas.creditCard) ? clientDatas.creditCard : undefined,
            number: (clientDatas && clientDatas.creditCard && clientDatas.creditCard.lastNumberCc) ? `************${clientDatas.creditCard.lastNumberCc}` : '****************',
            expiry: (clientDatas && clientDatas.creditCard && clientDatas.creditCard.expirDateCc) ? this.clearNumber(clientDatas.creditCard.expirDateCc) : '',
        });
    }

    /* fonction permettant de vérifier la date d'expiration de la date bancaire - affichage d'un message d'erreur auquel cas */
    checkExpiry() {
        const { expiry, onEdit } = this.state;

        if (onEdit) // si on est en édition
            this.setState({ expiryError: false });
        else if ((!expiry || expiry.length !== 6)) // si (pas de date d'expiration ou date d'expiration non valide)
            this.setState({ expiryError: false });
        else { // sinon pas d'édition et date d'expiration valide
            let month = expiry.slice(0, 2).replace(/^0+/, '');
            let year = expiry.slice(-4);
            if (compareAsc(new Date(year, month, 1), new Date()) !== 1) // si la différence enrtre les deux dates est différent de 1 - (1 => date1 > date2)
                this.setState({ expiryError: true });
            else
                this.setState({ expiryError: false });
        }
    }

    /* fonction permettant de valider le formulaire */
    onValidate = () => {
        let bool = (this.cardNumberReady && this.cardExpiryReady && this.cardCvcReady) ? false : true; // bolléen de désactivation du bouton
        this.setState({ disabled: bool });
    }

    /* fonction de callback liée aux changements de valeur du champ (formulaire) */
    handleChangeCardNumber = (ev) => {
        this.cardNumberReady = (!ev.error) && (ev.complete);
        this.setState({ errorNumber: (ev.error ? ev.error.message : undefined) });
        this.onValidate();
    }

    /* fonction de callback liée aux changements de valeur du champ (formulaire) */
    handleChangeCardExpiry = (ev) => {
        this.cardExpiryReady = (!ev.error) && (ev.complete);
        this.setState({ errorExpiry: (ev.error ? ev.error.message : undefined) });
        this.onValidate();
    }

    /* fonction de callback liée aux changements de valeur du champ (formulaire) */
    handleChangeCardCvc = (ev) => {
        this.cardCvcReady = (!ev.error) && (ev.complete);
        this.setState({ errorCvc: (ev.error ? ev.error.message : undefined) });
        this.onValidate();
    }

    /* fonction permettant de récupérer la valeur exacte (depuis une valeur de type '12 / 2020') */
    clearNumber(value = '') {
        let clearedValue = value.replace(/\D+/g, '');
        return (clearedValue.length === 6) ? clearedValue : `0${clearedValue}`; // on ajout un 0 si on se retrouve dans le cas (1 / 2020 => 12020 => 012020)
    }

    /* fonction permettant de mettre d'afficher le contenu d'édition - ajout nouvelle carte */
    handleUpdate() {
        this.setState({ onEdit: true, number: '****************', expiry: 'XXXX' });
    }

    /* fonction permettant de revenir à l'affichage précèdent - perte des informations */
    handleCancel() {
        this.setState({ 
            onEdit: false, focused: 'number', disabled: true, onSubmit: false, 
            errorSubmit: undefined, errorNumber: undefined, errorExpiry: undefined, errorCvc: undefined 
        });
        this.cardNumberReady = this.cardExpiryReady = this.cardCvcReady = undefined;
        this.updateDataState();
    }

    /* callback en lien avec l'animation de la carte bancaire */
    handleFocusElement = (value) => {
        if (!L.Browser.ie) // problème d'affichage avec IE
            this.setState({ focused: value });
    }

    /**
     *  fonction permettant de sauvegarder une nouvelle carte
     *  Dès l'obtention d'un nouveau token , on met à jour le customer
     * */
    saveCard(tokenCardId) {
        this.props.createStripeCustomer(tokenCardId) // récupération d'un nouveau token de carte de crédit
            .then((result) => { // if result = true alors on récupère les nouvelles informations
                if (!result) {
                    sendError('stripeInfoCard - createStripeCustomer-badResult', { 
                        'err': "retour de création du 'customer' Stripe invalide!", 
                        'clientId': this.props.clientDatas.clientId });
                    this.setState({
                        errorSubmit: `${StringTranslate.errorSaveCustomer}`, // permet d'afficher le message d'erreur
                        onSubmit: false
                    });
                } else {
                    this.props.getClientBilling(); // récupération des nouvelles données clientBilling
                }

                this.handleCancel();
            })
            .catch((err) => {
                sendError('stripeInfoCard - createStripeCustomer', err);

                this.setState({
                    errorSubmit: `${StringTranslate.errorSaveCustomer}`, // permet d'afficher le message d'erreur
                    onSubmit: false
                });
            });
    }

    /* fonction permettant d'envoyer les informations bancaires et de récupérer le token associé à la carte de crédit */
    handleSubmit = (event) => {
        if (event) event.preventDefault();

        const { stripe } = this.props; // contrôle
        if (!stripe) return; // si null on ne va pas plus loin

        this.setState({ disabled: true, onSubmit: true }); // on désactive le boutton pour éviter le multi click et on on enlève le bouton annuler pour une spinning icon

        stripe.createToken() // enregistrement de la carte bancaire - récupération du token
            .then((result) => {
                if (result.token && result.token.id) // a Token was created successfully.
                    this.saveCard(result.token.id); // sauvegarde des données et lancement processus abonnement dans le composant supérieur
                else {
                    this.setState({
                        errorSubmit: `${StringTranslate.errorSaveCBOfCustomer}`, // permet d'afficher le message d'erreur
                        onSubmit: false
                    });
                }
            })
            .catch((err) => {
                sendError('stripeInfoCard - createToken', err);

                this.setState({
                    errorSubmit: `${StringTranslate.errorSaveCBOfCustomer}`, // permet d'afficher le message d'erreur
                    onSubmit: false
                });
            });
    };

    /* fonction en lien avec le cablage d'un événement sur le press ENTER du clavier */
    onEnterClick(event) {
        const key = event.which || event.keyCode;
        const { onEdit, errorNumber, errorExpiry, errorCvc } = this.state;
        if (key === 13 && onEdit && (!errorNumber && !errorExpiry && !errorCvc)) { // 13 is enter
            this.handleSubmit();
        }
    }

    /* fonction de cycle de vie react */
    componentDidMount() {
        this.cmpRef.current.addEventListener('keypress', this.onEnterClick); // cablage d'un événement sur le press ENTER du clavier
        this.checkExpiry(); // la vérification de la date d'expiration
    }

    /* fonction de cycle de vie react */
    componentDidUpdate(prevProps, prevState) {
        const { clientDatas, creditCard } = this.props;
        const { onEdit } = this.state;

        // si on est pas en train de modifier la CB actuelle:
        if (onEdit !== true) {
            // En cas de changement (depuis un autre composant) sur les infos clients (et en présence des infos de CB):
            if ((clientDatas && clientDatas.creditCard) && ( //l'objet clientdatas (et la CB) est actuellement ok...
                (prevProps.clientDatas !== clientDatas) || //l'objet clientdatas n'était pas ok avant !
                ((prevProps.clientDatas !== undefined) && (prevProps.clientDatas.creditCard !== clientDatas.creditCard)) )) {
                this.updateDataState();
            }
            // En cas de changement (ici ou autre composant) sur les infos du client: (carte bleue uniquement)
            else if (prevProps.creditCard !== creditCard) {
                this.updateDataState();
            }
            //si on vient d'annuler ou finaliser les changements saisies (mais non-validé)
            else if (prevState.onEdit !== onEdit) {
                this.updateDataState();

                // la vérification de la date d'expiration
                this.checkExpiry();
            }
        } 
        //else //en édition, on ne devrait pas recevoir de modification de la CB depuis un autre composant !
        // et les éléments de saisie Stripe seront les seuls à afficher les données de la CB...
    }

    /* fonction de cycle de vie react - suppression cablage d'un événement sur le press ENTER du clavier */
    componentWillUnmount() {
        this.cmpRef.current.removeEventListener('keypress', this.onEnterClick);
    }

    render() {
        const { number, name, expiry, cvc, focused, onEdit, disabled, onSubmit } = this.state;
        const { errorNumber, errorExpiry, errorCvc, errorSubmit, expiryError } = this.state;
        const { creditCard } = this.props;

        strings_FR.card.valid = `${StringTranslate.valid}`;
        strings_FR.placeholders.name = '';//`${StringTranslate.cbNamemaj}`;

        return (
            <Grid ref={this.cmpRef} container spacing={2} alignItems="center">

                {/* ↓↓ Partie Zone erreur - la carte est actuellement périmée ↓↓ */}
                {(expiryError) && <Grid item xs={12}>
                    <Alert
                        severity="error"
                        icon={<InfoOutlinedIcon />}
                        sx={{ color: theme.palette.error.main }}
                    >
                        {`${StringTranslate.expiryError}`}
                    </Alert>
                </Grid>}

                {/* ↓↓ Partie Carte ↓↓ */}
                <Grid item xs={12}>
                    <Card
                        number={number}
                        name={name}
                        expiry={expiry}
                        cvc={cvc}
                        focused={focused}
                        locale={strings_FR.card}
                        placeholders={strings_FR.placeholders}
                        preview={(!onEdit) ? true : false}
                        issuer='unknown'
                    />
                </Grid>
                {/* ↓↓ Partie Formulaire ↓↓ */}
                {(onEdit) &&
                    (<Grid item xs={12}>

                        {/* ↓↓ élément représentant un champ à compléter ↓↓ */}
                        <InputLabel>{`${StringTranslate.numberelement}`}</InputLabel>
                        <CardNumberElement
                            className='text-field-stripe'
                            onChange={this.handleChangeCardNumber}
                            onReady={(el) => el.focus()}
                            onFocus={() => this.handleFocusElement('number')}
                            {...options}
                        />
                        <FormHelperText sx={{ color: theme.palette.error.dark, mb: 2 }}>{errorNumber}</FormHelperText>

                        {/* ↓↓ élément représentant un champ à compléter ↓↓ */}
                        <InputLabel>{`${StringTranslate.expiryelement}`}</InputLabel>
                        <CardExpiryElement
                            className='text-field-stripe'
                            onChange={this.handleChangeCardExpiry}
                            onFocus={() => this.handleFocusElement('expiry')}
                            {...options}
                        />
                        <FormHelperText sx={{ color: theme.palette.error.dark, mb: 2 }}>{errorExpiry}</FormHelperText>

                        {/* ↓↓ élément représentant un champ à compléter ↓↓ */}
                        <InputLabel>{`${StringTranslate.cvcelement}`}</InputLabel>
                        <CardCVCElement
                            className='text-field-stripe'
                            onChange={this.handleChangeCardCvc}
                            onFocus={() => this.handleFocusElement('cvc')}
                            {...options}
                        />
                        <FormHelperText sx={{ color: theme.palette.error.dark, mb: 2 }}>{errorCvc}</FormHelperText>

                    </Grid>)
                }

                {/* ↓↓ Partie Zone erreur ↓↓ */}
                {(errorSubmit) && <Grid item xs={12}>
                    <Alert
                        severity="error"
                        icon={<WarningIcon />}
                        sx={{ color: theme.palette.error.main }}
                    >
                        {errorSubmit}
                    </Alert>
                </Grid>}

                <Grid item xs={12}>
                    {/* ↓↓ Partie Actions Boutons ↓↓ */}
                    {(onEdit !== true) ?
                        (
                            <Stack direction="row" spacing={2} justifyContent="flex-end">
                                <Button
                                    type='submit'
                                    variant="contained"
                                    color="primary"
                                    disabled={onSubmit}
                                    startIcon={(onSubmit) && <CircularProgress color="inherit" size={24} />}
                                    onClick={this.handleUpdate}
                                >
                                    {(creditCard) ? `${StringTranslate.changecard}` : `${StringTranslate.savecard}`}
                                </Button>
                            </Stack>
                        ) :
                        (
                            <Stack direction="row" spacing={2} justifyContent="flex-end">
                                <Button
                                    type='submit'
                                    variant="text"
                                    color="error"
                                    disabled={onSubmit}
                                    onClick={this.handleCancel}
                                >
                                    {`${StringTranslate.annuler}`}
                                </Button>
                                <Button
                                    type='submit'
                                    variant="contained"
                                    color="primary"
                                    classes={{ root: 'sic-action-update' }}
                                    onClick={this.handleSubmit}
                                    disabled={disabled || onSubmit}
                                    /* ↓↓ Partie Spinner - lors du submit ↓↓ */
                                    startIcon={(onSubmit) && <CircularProgress color="inherit" size={24} />}
                                >
                                    {`${StringTranslate.enregistrer}`}
                                </Button>
                            </Stack>
                        )
                    }
                </Grid>
            </Grid>
        )
    }
}

export default injectStripe(StripeInfoCard);