const urlBase = 'https://api.zippopotam.us/';
const urlBase2 = 'https://geo.api.gouv.fr';
const urlBase3 = 'https://nominatim.openstreetmap.org/search';
// const urlBase3 = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest'; // ESRI

/***********************************************
 * CAS => TOUS LES AUTRES PAYS 
 * API(s) - https://zippopotam.us/# et openstreetmap
 * Attention - L'API gratuite permettant de rechercher des villes suivant le code postal et le code iso du pays
 **********************************************/
const search_geo_worldwide = {
    
    /**
     *  fonction permettant de récupérer une liste de nom de villes suivant un code postal suivant son code pays
     */
    getCitiesListFromPostalCodeSearch: function(countryCode, postalCode) {
        let promise = function (resolve, reject) {
            if (!countryCode || !postalCode) {
                reject('les données d\'entrée ne sont pas valides')
            };
            
            let request = `${urlBase}${countryCode}/${postalCode}`;
            fetch(request, { method: 'GET'})
                .then( (response) => {
                    // ↓↓ examine the state of the response ↓↓
                    if (response.status !== 200) {
                        reject(response.status);
                        return;
                    }

                    // ↓↓ Examine the text in the response ↓↓        
                    response.json()
                        .then((data) => resolve(search_geo_worldwide_tools.extractCityESRI(data)))
                        .catch( (err) => reject(err));

                })
                .catch( (err) => reject(err));
        }
        
        return new Promise(promise);
    },

     /**
     *  fonction permettant de récupérer le noms de villes suivant une suggestion de ville 
     */
    getCitiesListFromCitySearch: function(search, countryCode = '') {
        let promise = function (resolve, reject) {
            if (!search || search === '') {
                reject('les données d\'entrée ne sont pas valides')
            };

            let request = `${urlBase3}?countrycodes=${countryCode}&format=json&q=${search}`;
            fetch(request, { method: 'GET'})
                .then( (response) => {
                    // ↓↓ examine the state of the response ↓↓
                    if (response.status !== 200) {
                        reject(response.status);
                        return;
                    }

                    // ↓↓ Examine the text in the response ↓↓        
                    response.json()
                        .then((data) => resolve(search_geo_worldwide_tools.extractCityOpenStreetMap(data)))
                        .catch( (err) => reject(err));

                })
                .catch( (err) => reject(err));
        }
        
        return new Promise(promise);
    },
}

const search_geo_worldwide_tools = {
    
    /* fonction permettant de formater la réponse et renvoyer une liste de nom de villes */
    extractCityESRI: function(datas) {
        let cities = [];
        datas.places.forEach(place => {
            cities.push(place['place name']);
        });
        return cities;
    },

    /* fonction permettant de formater la réponse et renvoyer une liste de nom de villes */
    extractCityOpenStreetMap: function(datas) {
        let cities = [];
        datas.forEach(data => cities.push(data.display_name));
        return cities;
    },
}

/****************************************************************************************
 * CAS => France FR
 * API - https://api.gouv.fr/api/api-geo.html
 * Attention - L'API est disponible à hauteur de 10 appels par seconde et par adresse IP.
 ***************************************************************************************/

/**
 * Objet de connexion à l'API api.gouv.fr - Récupération nom des villes par code postal, etc...
 */
const geo_gouv_api_provider_FR = {
    
    /**
     *  fonction permettant de récupérer le noms de villes suivant un code postal
     */
    getCityNameFromPostalCode: function(postalCode) {
        let promise = function (resolve, reject) {
            if (!postalCode) {
                reject('les données d\'entrée ne sont pas valides')
            };
            
            let param = `codePostal=${postalCode}`;
            let request = `${urlBase2}/communes?${param}&fields=nom&format=json`;
            fetch(request, { method: 'GET', headers: {'Content-Type': 'application/json;charset=utf-8'}})
                .then( (response) => {
                    // ↓↓ examine the state of the response ↓↓
                    if (response.status !== 200) {
                        reject(response.status);
                        return;
                    }
                    
                    // ↓↓ Examine the text in the response ↓↓        
                    response.json()
                        .then((data) => resolve(geo_gouv_tools.extractCity(data)))
                        .catch( (err) => reject(err));

                })
                .catch( (err) => reject(err));
        }
        
        return new Promise(promise);
    },
}

const geo_gouv_tools = {
    
    /* fonction permettant de formater la réponse et renvoyer une liste de nom de villes */
    extractCity: function(datas) {
        let cities = [];
        datas.forEach(obj => {
            cities.push(obj.nom);
        });
        return cities;
    },
}

/****************************************************************************************
 * Objet javascript EXPORTE permettant la récupération d'informations suivant le code postal ou la ville
 ***************************************************************************************/
const geo_search = {

    /* fonction permettant de rechercher la ou les villes suivant un code pays et un code postal  */
    getCitiesFromPostalCode: function(countryCode, postalCode) {
        let promise = function (resolve, reject) {
            if ((!countryCode || countryCode === '') || !postalCode) {
                resolve([]); // on renvoie un objet vide // on pourrait renvoyer une erreur
            };

            if (countryCode === 'FR') {
                geo_gouv_api_provider_FR.getCityNameFromPostalCode(postalCode)
                    .then(result => resolve(result))
                    .catch( (err) => reject(err));
            }
            else { // pour tous les autres pays
                search_geo_worldwide.getCitiesListFromPostalCodeSearch(countryCode, postalCode)
                    .then(result => resolve(result))
                    .catch( (err) => reject(err));
            }
        };
        
        return new Promise(promise);
    },

    /* fonction permettant de rechercher la ou les villes suivant un code pays et un code postal  */
    getCitiesFromCitySearch: function(search, countryCode = '') {
        let promise = function (resolve, reject) {
            if (!search || search === '') {
                resolve([]); // on renvoie un objet vide // on pourrait renvoyer une erreur
            };

            search_geo_worldwide.getCitiesListFromCitySearch(search, countryCode)
                .then(result => resolve(result))
                .catch( (err) => reject(err));
        };
        return new Promise(promise);
    },
}

export { geo_search as geoSearch };