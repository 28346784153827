import { ActionTypeModelisation, ModelisationSteps } from "../actions/modelisation";

/* state initial */
const initialState = {
    modelisationStep: ModelisationSteps.WELCOME_START_MODELISATION,

    modelisationDico: {},
    modelisationDicoCounter: 0,             // nombre de modélisations initialisées ou terminées

    culturesForModelisation: [],            // liste des cultures disponibles pour la modélisation
    cultureSelected: undefined,             // culture sélectionné pour les nouvelles modélisation
    
    parcelIdsSelected: [],                  // liste des parcelles sélectionnées pour le processus

    loadingModelisations: false,            // variable pour le chargement de modélisations en cours
    alreadyAskLoadingModelisations: false,  // si les modélisations ont déjà été chargées une fois, rien ne sert de les télécharger une nouvelle fois
    
    modelisationProcessing: [], // liste des modélisation en train d'être initialisée

    errorMessage: "",
};

function modelisationManagerAction(state = initialState, action) {
    switch (action.type) {
        case ActionTypeModelisation.GO_TO_STEP_OF_MODELISATION: {
            return Object.assign({}, state, {
                modelisationStep: action.modelisationStep,
            });
        }

        case ActionTypeModelisation.SAVE_CHOSEN_CROP: {
            return Object.assign({}, state, {
                cultureSelected: action.chosenCrop,
            });
        }

        case ActionTypeModelisation.SAVE_CHOSEN_PARCELS: {
            return Object.assign({}, state, {
                parcelIdsSelected: action.parcelIdsSelected,
            });
        }

        case ActionTypeModelisation.GET_CULTURES_MODELISATION: {
            return Object.assign({}, state, {
                culturesForModelisation: action.culturesForModelisation,
            });
        }

        case ActionTypeModelisation.LOADING_MODELISATIONS: {
            return Object.assign({}, state, {
                loadingModelisations: true,
                alreadyAskLoadingModelisations: true
            });
        }

        case ActionTypeModelisation.LOADED_MODELISATIONS: {
            return Object.assign({}, state, {
                modelisationDico: action.modelisationDico,
                modelisationDicoCounter: Object.values(action.modelisationDico).length,
                loadingModelisations: false
            });
        }

        case ActionTypeModelisation.ERROR_LOAD_MODELISATION: {
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
                loadingModelisations: false
            });
        }

        case ActionTypeModelisation.SUCCESS_INIT_MODELISATIONS: {
            let newModelisationDico = Object.assign({}, state.modelisationDico, action.modelisationsInitialized);
            return Object.assign({}, state, {
                modelisationDico: newModelisationDico,
                modelisationDicoCounter: Object.values(newModelisationDico).length,
                modelisationProcessing: Object.keys(action.modelisationsInitialized),
            });
        }

        case ActionTypeModelisation.SET_MODELISATION_PROCESSING: {
            return Object.assign({}, state, {
                modelisationProcessing: action.modelisationProcessing,
            });
        }

        default:
            return state;
    }

}
export default modelisationManagerAction;