import React from "react";
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';

/* Composants MUI */
import { createFilterOptions } from '@mui/material/Autocomplete';
import {
    InputAdornment, Button, CircularProgress, Grid, Typography, Autocomplete,
    Box, Dialog, DialogActions, DialogContent, DialogTitle, TableContainer,
    FormControl, TextField, Alert, AlertTitle
} from '@mui/material';

/* Composants React */
import { ThumbnailParcelShapeFromPathInfos } from '../../thumbnail/ThumbnailParcelShape.jsx';
import CustomDataGrid from "../../customDataGrid";
import LinkToContactUs from '../../linkToContactUs';

import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';

/* DatePicker */
import CustomDatePicker  from '../../customDatePicker.jsx';

/* Icones */
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import DoneIcon from '@mui/icons-material/Done';

/* Helpers et Dico */
import stringHelper from '../../../utils/stringHelper';
import DateHelper from '../../../utils/dateHelper';
import { ParcelsHelper } from '../../../utils/parcelsHelper';
import dicoFunction from '../../../datas/dicoDetails';
import { HarvestHelper } from  '../../../utils/harvestHelper';

/* Redux */
import { TableType } from '../../../redux/actions/settings';
import { ProfilIndex, ActionShowProfilMenuDialog } from "../../../redux/actions/contextApp";
import { ActionSaveParcel } from '../../../redux/actions/parcels.js';
import { HarvestStep, ActionGoToStepOfHarvest, ActionSelectIdsParcelsToStartManagement } from '../../../redux/actions/harvest';

/* css */
import '../../../assets/css/renderParcels.css';
import '../../../assets/css/pdp-pinDropPopup.css';

/* theme Berry */
import getTheme from "../../../themes/index.js";


let theme = getTheme();

/* Concerne les options pour les cultures, cultures précédentes, exploitation */
const filter = createFilterOptions();

/**
 * Composant d'affichage des parcelles actuelles.
 */
class ParcelsChoice extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            openUpdateParcel: false, //ouvre le popup dialog pour modifier une parcelle

            datas: this.initialiseDatas(props.parcelIdFilteredList), //parcelles
            varieties: this.initialiseVarieties(), // initialisation des variétés : bouton "ajouter une variété" + variétés existantes dans les parcelles
            farms: this.initialiseFarms(), // initialisation des exploitations : bouton "ajouter une exploitation" + exploitations existantes dans les parcelles 
            //sauvegarde la langue en cours lors de la création du composant: (Car pas présente dans le store Redux)
            language: StringTranslate.getLanguage(),
            cultures: this.initialiseCultures(),

            rowsPerPageForTableParcels: props.rowsPerPageForTableParcels, //nombre de lignes par page dans le tableau enregistré

            //Si une culture a déjà été sélectionnée auparavant, garder la culture et garder les parcelles sélectionnées auparavant
            parcelsSelected: (props.parcelIdsSelected !== undefined) && (props.parcelIdsSelected.length > 0) ? props.parcelIdsSelected : [], //parcelles sélectionnées par les checkbox
            pageSize: props.rowsPerPageForTableParcels, //Nombre de lignes par page au départ
            searchFilter: '', //Zone de recherche du tableau
            currentRowsOfTable: [], //Datas en fonction du texte tapé dans la zone de recherche

            //Valeur affectée aux différentes données d'une parcelle en cours de modification:
            id: 0,
            name: null,
            campagne: null,
            culture: null,
            culturePrecedente: null,
            dateSemi: null,
            exploitation: null,
            surface: null,
            variete: null,
            thumbnailInfos: null,
        };

        // liste des noms de colonnes affichées
        this.columns = this.initialiseColumns(props.parcelIdFilteredList); //RQ: pas dans le 'state' car on en utilise des propriétés !
        this.CustomToolbar = this.CustomToolbar.bind(this);
    }

    componentDidMount() {
        const { datas } = this.state;

        //On refait le tri dans les parcelles
        this.sortDatasByCrop(datas);
     }

    componentDidUpdate(prevProps, prevState) {
        //@@Réagir aux modifications sur les parcelles : Modification d'une ou plusieurs données d'une parcelle !
        const { parcelIdFilteredList, updatingParcelName, updatingParcelInfos, harvestDicoCounter } = this.props;
        const { language } = this.state;

        /* Lorsqu'il y a une modification des données d'une parcelle (RQ: n'a pas être réalisé qu'ici!), il faut actualiser la liste des 'datas': */
        /* RQ: c'est plus rapide de recharger toutes les parcelles que de rechercher quelle parcelle a été modifié et quelle(s) info(s) de cette parcelle! */
        if (((prevProps.updatingParcelName === true) && (updatingParcelName === false)) ||
            ((prevProps.updatingParcelInfos === true) && (updatingParcelInfos === false)) ||
            (prevProps.harvestDicoCounter !== harvestDicoCounter)) { //RQ: lorsque l'on passe de 'true' à 'false' c'est que le traitement est finis (même si pourrai être KO)
            

            const newCultureList = this.initialiseCultures();
            const newFarmsList = this.initialiseFarms();
            const newVarietiesList = this.initialiseVarieties();

            const newDatas = this.initialiseDatas(parcelIdFilteredList);
            
            this.setState({
                datas: newDatas,
                openUpdateParcel: false,
                cultures: newCultureList,
                farms: newFarmsList,
                varieties: newVarietiesList,
            });

            //Trie les données du tableau
            this.sortDatasByCrop(newDatas);
        }

        

        /* Lorsqu'il y a un changement de langue (même si on se doute que l'utilisateur ne changera pas de langue tous les 4 matins !), il faut actualiser certaines listes: */
        const currentLanguage = StringTranslate.getLanguage();
        if ((!language) || (language === '') || (language !== currentLanguage)) {
            //RQ: C'est par ce que la liste des cultures comprend une liste fixe de cultures, qui sont naturellement traduites!
            const newCultures = this.initialiseCultures(); //initialisation des cultures : cultures fixes + cultures existantes dans les parcelles
            // liste des noms de colonnes affichées. 
            this.columns = this.initialiseColumns(parcelIdFilteredList); //RQ: ca va que l'on actualise 'cultures' sans quoi le rendu n'aurai pas lieu et on n'utiliserai pas la nouvelle liste de noms de colonne !

            this.setState({ cultures: newCultures, language: currentLanguage, });
        }
    }

    //renvoie les exploitations appartenant aux parcelles existantes
    initialiseFarms() {
        const { parcelDico } = this.props;

        let farms = [];

        //Ajout des nom d'exploitations trouvés dans la définition des parcelles:
        ParcelsHelper.searchFarms(parcelDico)
            .forEach(farm => {
                if (!farms.find(f => f.title === farm))
                    farms.push({ title: farm });
            });

        return Array.from(new Set(farms));
    }

    // renvoie les variétés appartenant aux parcelles existantes
    initialiseVarieties() {
        const { parcelDico } = this.props;
        let varieties = [];

        //Ajout des variétés trouvées dans la définition des parcelles:
        ParcelsHelper.searchVarieties(parcelDico)
            .forEach(variety => {
                if (!varieties.find(v => v.title === variety)) {
                    varieties.push({ title: variety });
                }
            });

        return varieties.sort((a, b) => a.title > b.title ? 1 : -1);
    }

    //renvoie les cultures appartenant aux parcelles existantes
    initialiseCultures() {
        const { parcelDico } = this.props;

        return ParcelsHelper.mergeCultures(parcelDico);
    }

    /* Edition d'une parcelle sélectionnée dans le tableau */
    handleEditClick = (parcel, thumbnailInfos) => {
        this.setState({
            id: parcel.id,
            openUpdateParcel: true,

            name: parcel.name,
            campagne: parcel.details.campagne,
            culture: parcel.details.culture,
            culturePrecedente: parcel.details.culturePrecedente,
            dateSemi: parcel.details.dateSemi,
            exploitation: parcel.details.exploitation,
            surface: parcel.details.surface,
            variete: parcel.details.variete,
            details: parcel.details,
            thumbnailInfos: thumbnailInfos,
        });

    }

    /* Fermeture de la popup de l'édition d'une parcelle */
    handleCloseUpdateParcel = () => {
        this.setState({
            openUpdateParcel: false,
        });
    }

    /**
     * Validation de l'édition d'une parcelle sélectionnée 
     */
    handleUpdateParcel = () => {
        const {
            name, campagne, culture,
            culturePrecedente, dateSemi, exploitation,
            variete, id /* id de la parcelle sélectionnée pour modification de données */
        } = this.state;

        const { parcelDico, updateParcel } = this.props;

        if (parcelDico) {
            const parcelToUpdate = lodashGet(parcelDico, `[${id}]`, undefined);

            //Le nom de la parcelle est obligatoire pour mettre à jour les données de la parcelle.
            if (name !== "") {

                parcelToUpdate.details.campagne = campagne;
                parcelToUpdate.details.culture = culture;
                parcelToUpdate.details.culturePrecedente = culturePrecedente;
                parcelToUpdate.details.dateSemi = dateSemi;
                parcelToUpdate.details.exploitation = exploitation;
                parcelToUpdate.details.variete = variete;
                parcelToUpdate.name = name;

                //Appel à l'action Redux d'enregistrement des modifications sur la parcelle:
                updateParcel(id, parcelToUpdate);
            }
        }
    }

    /**
     * Initialisation des colonnes de parcelles.
     * Il y a une traduction sur les listes fixes de cultures, exploitations et variétés
     */
    initialiseColumns(parcelIdFilteredList) {
        const { parcelDico } = this.props;

        let newColumns = [];
        if (parcelIdFilteredList && parcelIdFilteredList[0]) {

            /* ↓ ajout colonne visible - (booléen parcelle modulée) ↓ */
            newColumns.push({
                headerName: `Déjà généré ?`,
                field: "isGenerated",
                width: 80,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.harvestAlreadyGenerated}</Typography>)
                },
                renderCell: params => (
                    (params.value === true) ?
                        <DoneIcon style={{ color: theme.palette.success.main }} />
                        : <></>
                ),
            });

            newColumns.push({
                field: 'actions',
                editable: false,
                hideable: false,
                headerName: '',
                width: 60,
                renderCell: params => {
                    return (
                        <IconButton onClick={() => this.handleEditClick(params.value, params.row.thumbnailInfos)}>
                            <EditIcon color="inherit" />
                        </IconButton>
                    )
                }
            });

            /* ↓ ajout colonne visible - (Miniature + nom de la parcelle) ↓ */
            newColumns.push({
                headerName: `${StringTranslate.nomcolumn}`,
                field: "nom",
                minWidth: 180,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.nomcolumn}</Typography>)
                },
                renderCell: params => {
                    return (
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                            {(params) ? (<ThumbnailParcelShapeFromPathInfos id={`listItemTbl_${params.row.thumbnailInfos.parcelId}`} {...params.row.thumbnailInfos} />) : (undefined)}
                            <Typography>{params.value}</Typography>
                        </Box>
                    )
                }
            });

            const firstParcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelIdFilteredList[0]);
            if (firstParcel && firstParcel.details) {
                const dico = dicoFunction();
                for (let detail in firstParcel.details) {
                    dico.forEach(column => {
                        if (detail === column.name) {
                            if (column.name === "variete") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    minWidth: 150,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "exploitation") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    minWidth: 150,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "dateSemi") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    width: 110,
                                    type: "date",
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "campagne") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    width: 100,
                                    type: "date",
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "culture" || column.name === "culturePrecedente") {
                                newColumns.push({
                                    headerName: (column.name === "culture" ?
                                        `${StringTranslate.libelecolumnculture}` :
                                        `${StringTranslate.libelecolumncultureprece}`),
                                    field: column.name,
                                    minWidth: 150,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "surface") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    width: 100,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                        }
                    });
                }
            }
        }
        return newColumns;
    }

    /* fonction permettant d'extraire les données qui seront utilisées dans le visuel */
    initialiseDatas(parcelIdFilteredList) {
        const { parcelDico, thumbnailParcelDico, harvestDico, harvestDicoCounter } = this.props;
        let parcels = ParcelsHelper.getParcelsDicoFromParcelIdList(parcelDico, parcelIdFilteredList);
        let newDatas = [];

        for (var itemPropName in parcels) {
            let temp = [];
            const parcel = parcels[itemPropName];

            if (parcel) {
                const thumbnailParcelItem = ParcelsHelper.selectParcelFromDicoById(thumbnailParcelDico, parcel.id);

                temp.push(parcel.id);
                temp.push(parcel.name);
                if (parcel.details) {
                    for (let itemDetailName in parcel.details) {
                        const propValue = parcel.details[itemDetailName];
                        let value = (propValue) ? propValue : '';
                        if (itemDetailName.toLowerCase() === 'datesemi') {
                            if (propValue && (propValue instanceof (Date))) {
                                value = DateHelper.formati18n(propValue, 'P');
                            }
                            else if (propValue && (DateHelper.getDateFromString(propValue) instanceof Date)) {
                                value = DateHelper.formati18n(new Date(propValue), 'P');
                            }
                        }
                        else if (itemDetailName.toLowerCase() === 'surface') {
                            if(parcel.area && (parcel.area > 0)) {
                                value = parcel.area;
                            }
                        }
                        temp.push(value);
                    }
                }

                let isGenerated = false;
                if (harvestDicoCounter > 0) {
                    let harvest = HarvestHelper.selectHarvestFromDicoByParcelId(harvestDico, parcel.id);
                    isGenerated = ((harvest !== undefined) && (harvest.estimatedDate !== undefined) && (harvest.estimatedDate !== null)) ? true : false;
                } 
                newDatas.push({
                    id: parcel.id,
                    actions: parcel,
                    parcel: parcel,
                    isGenerated: isGenerated,
                    nom: parcel.name,
                    campagne: temp[2],
                    culture: parcel.details.length < 4 ? null : temp[3],
                    culturePrecedente: parcel.details.length < 5 ? null : temp[4],
                    dateSemi: parcel.details.length < 6 ? null : temp[5],
                    exploitation: parcel.details.length < 7 ? null : temp[6],
                    surface: parcel.details.length < 8 ? null : temp[7],
                    variete: parcel.details.length < 9 ? null : temp[8],
                    thumbnailInfos: thumbnailParcelItem,
                });

            }
        }
        return newDatas;
    }

    /* Fonction correspondant à la zone de recherche uniqueent sur la culture */
    requestSearch(searchValue, rowsOfTable) {
        this.setState({
            searchFilter: searchValue
        });

        try {
            const searchRegex = new RegExp(stringHelper.escapeRegExp(searchValue), 'i');
            const filteredRows = rowsOfTable.filter((row) => {
                return Object.keys(row).some((field) => {
                    let textValue = "" + row[field];
                    return searchRegex.test(textValue.toString());
                });
            });

            this.setState({
                currentRowsOfTable: filteredRows
            });
        }
        catch (errRegex) { /* Peut arriver si on saisis un truc du genre 'EARL++' ! (il n'aime pas les '++') */ }
    }

    //Sélection des parcelles pour commencer la pesée de colza
    setSelectionParcels = (newSelectionParcels) => {
        this.setState({
            parcelsSelected: newSelectionParcels
        });
    }

    //Toolbar customisé du tableau comprenant :
    // - la zone de recherche
    // - le bouton pour commencer la récolte
    CustomToolbar = (props) => {
        const { showProfilMenuDialog, loadingHarvests, loadingVarietiesCharacteristics } = this.props;
        return (
            <Grid container spacing={2} sx={{ mt: 2 }}>
                {/* Zone de recherche / zone du bouton de validation*/}
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Grid container spacing={1}>
                        {/* Zone de recherche */}
                        <Grid item xs={8} sm={8} md={4} lg={4}>
                            <TextField
                                value={props.value}
                                onChange={props.onChange}
                                placeholder={StringTranslate.toolbarsearch}
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>),
                                    endAdornment: (
                                        <IconButton
                                            title="Clear"
                                            aria-label="Clear"
                                            size="small"
                                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                                            onClick={props.clearSearch}
                                        >
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Grid>

                        {/* Zone de validation des parcelles sélectionnées */}
                        <Grid item xs={4} sm={4} md={8}>
                            <Box style={{ textAlign: 'end' }} >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => props.startManagement(props.parcelsSelected)}
                                    startIcon={(loadingHarvests || loadingVarietiesCharacteristics) ? <CircularProgress color="inherit" size={20} /> : null}
                                    disabled={(props.parcelsSelected.length > 0) ? loadingHarvests || loadingVarietiesCharacteristics : true}>
                                    {(loadingHarvests || loadingVarietiesCharacteristics) ? StringTranslate.loadingHarvestData : StringTranslate.validSlots}
                                </Button>
                            </Box>

                        </Grid>
                    </Grid>
                </Grid>
                {/* Bouton d'aide */}
                <Grid item xs={12}>
                    <Button color="secondary" variant="text" size="small" onClick={() => showProfilMenuDialog(ProfilIndex.aide_HarvestCorn)}>
                        {StringTranslate.helpAsk}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    /* Redirection vers l'étape 2 pour remplir les données de récolte après avoir sélectionné le(s) parcelle(s) */
    onStartManagement(data) {

        const { goToStep, selectIdParcelsToStartManagement } = this.props;

        let newParcelIdsSelected = [];

        data.forEach(element => {
            newParcelIdsSelected.push(element);
        });

        selectIdParcelsToStartManagement(newParcelIdsSelected);

        goToStep(HarvestStep.MANAGEMENT);
    }

    /* Fonction pour trier les données du tableau en fonction de la culture choisie. */
    sortDatasByCrop = (datas) => {
        const { parcelIdFilteredList } = this.props;
        const { parcelsSelected } = this.state;

        if (parcelIdFilteredList !== undefined && parcelIdFilteredList !== null) {
            let parcelsList = [...datas];

            let parcelsListCultureFound = [];
            let parcelsListCultureNotFound = [];

            let parcelIdsSelected = [];

            parcelsList.forEach(parcel => {
                if (parcel.culture !== null || parcel.culture !== undefined) {
                    if (parcel.culture.toLowerCase().includes('mais') || parcel.culture.toLowerCase().includes('maïs')) {
                        parcelsListCultureFound.push(parcel);
                    }
                    else {
                        parcelsListCultureNotFound.push(parcel);
                    }
                }
                else {
                    parcelsListCultureNotFound.push(parcel);
                }
            });

            parcelsList = [...parcelsListCultureFound, ...parcelsListCultureNotFound];

            parcelsListCultureFound.forEach(parcel => {
                parcelIdsSelected.push(parcel.id);
            });

            this.setState({
                datas: parcelsList,
                parcelsSelected: (parcelIdsSelected.length <= 0) ? parcelsSelected : parcelIdsSelected,
            });
        }

    };
    
   

    /* Mise à jour des infos de la parcelle */
    handleChangeParcel = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleChangeParcelCampagne = (newCampagne) => {
        this.setState({
            campagne: new Date(newCampagne).getFullYear()
        });
    }

    handleChangeParceldateSemi = (newDateSemi) => {
        this.setState({
            dateSemi: newDateSemi
        });
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction de rendu visuel - layout général
    ///////////////////////////////////////////////////////////////////////////
    render() {

        const {
            errorHarvest, showProfilMenuDialog,
            updatingParcelInfos, loadingHarvests, loadingVarietiesCharacteristics } = this.props; // fonctions mapDispatchToProps

        const {
            datas, searchFilter, currentRowsOfTable, pageSize, parcelsSelected,
            openUpdateParcel, 
            name, thumbnailInfos, campagne, culture, dateSemi, exploitation, variete, culturePrecedente,
            cultures, farms, varieties 
        } = this.state; // etat du dialog popup

        return (
            <>
                {/* Dialog de modification des infos de la parcelle */}
                {openUpdateParcel ?
                    <Dialog
                        sx={{ '& .MuiDialog-paper': { maxHeight: '80%' } }}
                        maxWidth="xs"
                        open={openUpdateParcel}
                        onClose={this.handleCloseUpdateParcel}>
                        <DialogTitle>
                            <Typography>
                                {(thumbnailInfos) ? (<ThumbnailParcelShapeFromPathInfos id="parcelShape" {...thumbnailInfos} />) : (undefined)}
                                {StringTranslate.updateCrop} {name}
                            </Typography>
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled={updatingParcelInfos}
                                            required
                                            error={(name === "") ? true : false}
                                            label={StringTranslate.ColumnPclName}
                                            id="standard-size-small"
                                            name="name"
                                            value={name}
                                            onChange={this.handleChangeParcel}
                                            helperText={(name === "") ? StringTranslate.champobligatoire : null}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl>
                                        <CustomDatePicker
                                            label={StringTranslate.libelecolumncampagne}
                                            name="campagne"
                                            dateFormat="yyyy"
                                            views={['year']}
                                            disabled={updatingParcelInfos}
                                            value={new Date(new Date().setFullYear(campagne))}
                                            onChange={(newValue) => this.handleChangeParcelCampagne(newValue)}
                                            placeholder={StringTranslate.formatDatePlaceHolder}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Autocomplete key="culture"
                                            disabled={updatingParcelInfos}
                                            name={StringTranslate.libelecolumnculture}
                                            value={culture}
                                            onChange={
                                                (event, newValue) => {
                                                    if (newValue && newValue.inputValue) {
                                                        this.setState({ culture: newValue.inputValue });
                                                    } else if (newValue && newValue.title) {
                                                        this.setState({ culture: newValue.title });
                                                    } else if (newValue === null) {
                                                        this.setState({ culture: '' });
                                                    }
                                                }
                                            }
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);

                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => inputValue === option.title);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        inputValue,
                                                        title: `${StringTranslate.ajouter3} "${inputValue}"`,
                                                    });
                                                }

                                                return filtered;
                                            }}
                                            options={cultures}
                                            getOptionLabel={(option) => {
                                                // Ajouter "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                // Value selected with enter, right from the input
                                                if (typeof option.title === 'string') {
                                                    return option.title;
                                                }
                                                // Regular option
                                                return option;
                                            }}
                                            renderOption={(props, option) =>
                                                <li {...props} key={option.title}>
                                                    {(option.title.includes(StringTranslate.ajouter3)) ?
                                                        <Button color="primary" variant="contained">{option.title}</Button> :
                                                        option.title}
                                                </li>}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField {...params} label={StringTranslate.libelecolumnculture} />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Autocomplete key="culturepre"
                                            disabled={updatingParcelInfos}
                                            value={culturePrecedente}
                                            name={StringTranslate.libelecolumncultureprece}
                                            onChange={
                                                (event, newValue) => {
                                                    if (newValue && newValue.inputValue) {
                                                        this.setState({ culturePrecedente: newValue.inputValue });
                                                    } else if (newValue && newValue.title) {
                                                        this.setState({ culturePrecedente: newValue.title });
                                                    } else if (newValue === null) {
                                                        this.setState({ culturePrecedente: '' });
                                                    }
                                                }
                                            }
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);
                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => inputValue === option.title);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        inputValue,
                                                        title: `${StringTranslate.ajouter3} "${inputValue}"`,
                                                    });
                                                }
                                                return filtered;
                                            }}
                                            options={cultures}
                                            getOptionLabel={(option) => {
                                                // Ajouter "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                // Value selected with enter, right from the input
                                                if (typeof option.title === 'string') {
                                                    return option.title;
                                                }
                                                // Regular option
                                                return option;
                                            }}
                                            renderOption={(props, option) =>
                                                <li {...props}>
                                                    {(option.title.includes(StringTranslate.ajouter3)) ?
                                                        <Button color="primary" variant="contained">{option.title}</Button> :
                                                        option.title}
                                                </li>}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField {...params} label={StringTranslate.libelecolumncultureprece} />
                                            )}
                                        />

                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl>
                                        <CustomDatePicker
                                            name="dateSemi"
                                            dateFormat="dd-MM-yyyy"
                                            disabled={updatingParcelInfos}
                                            label={StringTranslate.libelecolumnsemis}
                                            value={(dateSemi !== null) ? new Date(dateSemi) : null}
                                            onChange={(newValue) => this.handleChangeParceldateSemi(newValue)}
                                            placeholder={StringTranslate.formatDatePlaceHolder}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disabled={updatingParcelInfos}
                                            name="exploitation"
                                            value={exploitation}
                                            onChange={
                                                (event, newValue) => {
                                                    if (!newValue) {
                                                        this.setState({ exploitation: '' });
                                                    }
                                                    else if (newValue && newValue.inputValue) {
                                                        this.setState({ exploitation: newValue.inputValue });
                                                    } else if (newValue && newValue.title) {
                                                        this.setState({ exploitation: newValue.title });
                                                    }
                                                }
                                            }
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);

                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => inputValue === option.title);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        inputValue,
                                                        title: `${StringTranslate.ajouter3} "${inputValue}"`,
                                                    });
                                                }

                                                return filtered;
                                            }}
                                            options={farms}
                                            getOptionLabel={(option) => {
                                                // Ajouter "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                // Value selected with enter, right from the input
                                                if (typeof option.title === 'string') {
                                                    return option.title;
                                                }
                                                // Regular option
                                                return option;
                                            }}
                                            renderOption={(props, option) =>
                                                <li {...props}>
                                                    {(option.title.includes(StringTranslate.ajouter3)) ?
                                                        <Button color="primary" variant="contained">{option.title}</Button> :
                                                        option.title}
                                                </li>}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField {...params} label={StringTranslate.libelecolumnexploitation} />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disabled={updatingParcelInfos}
                                            name="variete"
                                            value={variete}
                                            onChange={
                                                (event, newValue) => {
                                                    if (newValue && newValue.inputValue) {
                                                        this.setState({ variete: newValue.inputValue });
                                                    } else if (newValue && newValue.title) {
                                                        this.setState({ variete: newValue.title });
                                                    } else if (newValue === null) {
                                                        this.setState({ variete: '' });
                                                    }
                                                }
                                            }
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);

                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => inputValue === option.title);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        inputValue,
                                                        title: `${StringTranslate.ajouter3} "${inputValue}"`,
                                                    });
                                                }

                                                return filtered;
                                            }}
                                            options={varieties}
                                            getOptionLabel={(option) => {
                                                // Ajouter "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                // Value selected with enter, right from the input
                                                if (typeof option.title === 'string') {
                                                    return option.title;
                                                }
                                                // Regular option
                                                return option;
                                            }}
                                            renderOption={(props, option) =>
                                                <li {...props}>
                                                    {(option.title.includes(StringTranslate.ajouter3)) ?
                                                        <Button variant="contained">{option.title}</Button> :
                                                        option.title}
                                                </li>}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField {...params} label={StringTranslate.libelecolumnvariete} />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>

                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="text" color="error" onClick={this.handleCloseUpdateParcel}>{StringTranslate.cancelDeleteAction}</Button>
                            <Button
                                color="primary"
                                disabled={updatingParcelInfos}
                                autoFocus
                                startIcon={updatingParcelInfos ? <CircularProgress color="inherit" size={25} /> : null}
                                variant="contained"
                                onClick={this.handleUpdateParcel}>
                                {updatingParcelInfos ?
                                    StringTranslate.downloadModulationInProgress :
                                    StringTranslate.enregistrer}
                            </Button>
                        </DialogActions>
                    </Dialog> : null}


                    {/* ↓↓ partie - zone de visualisation des parcelles ↓↓ */}
                        {((errorHarvest === undefined) || (errorHarvest === null) || (errorHarvest === '')) ?
                        <TableContainer>
                            <Grid style={{ width: '100%' }} item xs={12}>
                                {/* partie tableau */}
                                <CustomDataGrid
                                    disableSelectionOnClick={true}
                                    tableName={TableType.parcels}
                                    pageSize={pageSize}
                                    selectionModel={parcelsSelected}
                                    onSelectionModelChange={(newSelectionParcels) => { //Sélection des parcelles par clic checkbox
                                        this.setSelectionParcels(newSelectionParcels);
                                    }}
                                    keepNonExistentRowsSelected={searchFilter !== "" ? true : false}
                                    Toolbar={this.CustomToolbar}
                                    toolbar={{
                                        startManagement: () => this.onStartManagement(parcelsSelected),
                                        parcelsSelected: parcelsSelected,
                                        parcelDatas: datas,
                                        //Concerne la zone de recherche dans le tableau :
                                        value: searchFilter,
                                        onChange: (event) => this.requestSearch(event.target.value, datas),
                                        clearSearch: () => this.requestSearch('', datas),

                                        showProfilMenuDialog: () => showProfilMenuDialog(ProfilIndex.aide_Fumure),
                                    }}
                                    rows={(searchFilter !== "") ? currentRowsOfTable : datas }
                                    columns={this.columns}
                                    checkBoxActive={true}
                                    isRowSelectable={(params) => (loadingHarvests === false) && (loadingVarietiesCharacteristics === false)}
                                />
                            </Grid>
                        </TableContainer> :
                        <Alert
                            severity="error"
                            icon={<InfoOutlined />}
                            sx={{ color: theme.palette.error.main }}
                        >
                            <AlertTitle>{StringTranslate.errorsuite}</AlertTitle>
                            {errorHarvest}
                            {StringTranslate.errorDialogText2} <LinkToContactUs displayText={StringTranslate.contactUs} />.
                        </Alert>}
              
            </>
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    parcelIdsSelected: lodashGet(state, 'harvestData.parcelIdsSelected', []),
    updatingParcelName: lodashGet(state, 'parcelsData.updatingParcelName', false),
    updatingParcelInfos: lodashGet(state, 'parcelsData.updatingParcel', false),
    errorHarvest: lodashGet(state, 'harvestData.errorMessage', undefined),
    loadingHarvests: lodashGet(state, 'harvestData.loadingHarvests', false),
    loadingVarietiesCharacteristics: lodashGet(state, 'harvestData.loadingVarietiesCharacteristics', false),
    harvestDico: lodashGet(state, 'harvestData.harvestDico', {}),
    harvestDicoCounter: lodashGet(state, 'harvestData.harvestDicoCounter', 0),

    parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
    thumbnailParcelDico: lodashGet(state, 'parcelsData.thumbnailParcelDico', {}),
    parcelIdFilteredList: lodashGet(state, 'parcelsData.parcelIdFilteredList', {}),
    parcelIdFilteredListCounter: lodashGet(state, 'parcelsData.parcelIdFilteredListCounter', 0),

    rowsPerPageForTableParcels: lodashGet(state, 'state.settingsData.settings.rowsPerPageForTableParcels', 20),
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    showProfilMenuDialog: (index) => dispatch( ActionShowProfilMenuDialog(index) ),

    updateParcel: (parcelId, parcel) => dispatch(ActionSaveParcel(parcelId, parcel)),
    goToStep: (nextStep) => dispatch(ActionGoToStepOfHarvest(nextStep)),
    selectIdParcelsToStartManagement: (newParcelIdsSelected) => dispatch(ActionSelectIdsParcelsToStartManagement(newParcelIdsSelected)),
    
})

export default connect(mapStateToProps, mapDispatchToProps)(ParcelsChoice);