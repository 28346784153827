import { fromByteArray } from "base64-js";
import Compressor from "compressorjs";
import ConstantsObservation from "./constantsObservation";

/**
 * Helper pour la gestion des fichiers 
 * 
 * @property {Promise} getDataFromJpegImage - Fonction asyncrone permettant de compresser puis de récupérer les informations de l'image
 * @property {string} getUriFromDataForJpegImage - Foncton permettant de récupérer l'uri d'une image à partir de sa data
 */
const fileHelper = {
    /**
     * Fonction asyncrone permettant de compresser puis de récupérer les informations de l'image
     * 
     * @async
     * @function getDataFromJpegImage
     * @param fileflux: le flux du fichier contenue dans l'event du onChange de l'input (event.target.files[0])
     * @returns \{
     *  imageData: le tableau de byte de l'image, 
     *  imageUri: l'uri de l'image à partir du tableau de byte,
     *  width: la largeur de l'image, 
     *  height: la hauteur de l'image,
     * }
     */
    getDataFromJpegImage: async (fileflux) => {
        return new Promise((resolve, reject)=>{
            let fileReader = new FileReader();

            // fonction appelé à la lecture du fichier
            fileReader.onload = () => {
                //Objet image permettant de récupérer les informations de l'image
                let img = new Image();
                // récupération d'un tableau de byte
                let imgByteArray = new Uint8Array(fileReader.result); 
                // création de l'uri de l'image à partir du tableau de byte
                let imgUri = fileHelper.getUriFromDataForJpegImage(imgByteArray); 
                // fonction appelé à la création de l'image
                img.onload = () => {
                    resolve({
                        imageData: [...imgByteArray],
                        imageUri: imgUri,
                        width: img.width,
                        height: img.height,
                    });
                }
                img.onerror = () => {
                    reject();
                }
                // Création de l'image à partir de l'uri
                img.src = imgUri;
            }
            fileReader.onerror = () => {
                reject();
            }
        
            // compression de l'image avant lecture
            new Compressor(fileflux, {
                // Définition des dimensions maximales
                maxHeight: ConstantsObservation.MaxSize,
                maxWidth: ConstantsObservation.MaxSize,
                // Fonction appelé après compression
                success(result) {
                    // lecture du fichier
                    fileReader.readAsArrayBuffer(result); 
                },
                error() {
                    reject();
                }
            });
        });
    },

    /**
     * Foncton permettant de récupérer l'uri d'une image à partir de sa data
     * 
     * @function getUriFromDataForJpegImage
     * @param imageData : le tableau de byte de l'image
     * @returns l'uri de l'image
     */
    getUriFromDataForJpegImage: (imageData) => {
        return "data:image/jpeg;base64," + fromByteArray(imageData);
    },
}

export default fileHelper;