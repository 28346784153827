import React from 'react';

/* MUI components */
import { Button, Typography, Grid, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

/* React components */
import MainCard from '../subLayouts/mainCard';
import CustomIFrame from '../customIFrame.jsx';

/* Icons */
import LogoSpotifarm from '../../assets/logoSpotifarm/FeuilleSpotifarmLogoCouleur';

/* css */
import '../../assets/css/planCard.css';

/* Utils */
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import ConstantsTutorialHelps from '../../utils/constantsTutorialHelps.js';

/* Berry theme */
import getTheme from "../../themes/index.js";
let theme = getTheme();


/**
 * Composant d'affichage global d'un plan ADV
 */
class ADVPlanCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showContact: false,
        }

        this.onSelect = this.onSelect.bind(this);
    }

    /* fonction permettant de sélectionner un plan */
    onSelect() {
        this.setState({
            showContact: !this.state.showContact,
        });
    }

    handleCloseContact = () => {
        this.setState({
            showContact: false,
        });
    };

    render() {
        const { planDatas, subscribedResume } = this.props;
        const { showContact } = this.state;

        return (
            <>
                {showContact &&
                    <Dialog
                        open={showContact}
                        onClose={this.handleCloseContact}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth={true}
                        maxWidth={'md'}
                    >
                        <DialogTitle id="alert-dialog-title">
                            {StringTranslate.askForSubscription}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {StringTranslate.contactForSubscription}
                            </DialogContentText>
                            <Box>
                                <CustomIFrame url={ConstantsTutorialHelps.UrlContactFormForSuscription} height="400px" />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="text" color="primary" onClick={this.handleCloseContact}>Fermer</Button>
                        </DialogActions>
                    </Dialog>
                }
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    <MainCard
                        boxShadow
                        sx={{
                            pt: 1.75,
                            border: (subscribedResume === true) ? '2px solid' : '1px solid',
                            borderColor: (subscribedResume === true) ? theme.palette.secondary.main : 'rgba(133, 145, 161, 0.46)',
                            bgcolor: (subscribedResume === true) ? theme.palette.secondary.light : null
                        }}
                    >
                        <Grid container textAlign="center" spacing={2}>
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '50%',
                                        width: 80,
                                        height: 80,
                                        background: theme.palette.primary.light,
                                        color: theme.palette.primary.main,
                                        '& > svg': {
                                            width: 35,
                                            height: 35
                                        }
                                    }}
                                >
                                    <LogoSpotifarm />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontSize: '1.5625rem',
                                        fontWeight: 500,
                                        position: 'relative',
                                        mb: 1.875,
                                        '&:after': {
                                            content: '""',
                                            position: 'absolute',
                                            bottom: -15,
                                            left: 'calc(50% - 25px)',
                                            width: 50,
                                            height: 4,
                                            background: theme.palette.primary.main,
                                            borderRadius: '3px'
                                        }
                                    }}
                                >
                                    {planDatas.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">{planDatas.description}</Typography>
                            </Grid>
                            <Grid item xs={12} textAlign="left">
                                {planDatas.subDescription}
                            </Grid>

                            <Grid item xs={12} textAlign="center">
                                <Button
                                    variant={(subscribedResume === true) ? "text" : "outlined"}
                                    disabled={subscribedResume}
                                    sx={{
                                        "&:disabled": {
                                            color: theme.palette.secondary.main
                                        }
                                    }}
                                    onClick={this.onSelect}
                                >
                                    {(subscribedResume === true) ? StringTranslate.suscribetext : StringTranslate.modifier}
                                </Button>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
            </>
        )
    };
}

export default ADVPlanCard;
