import { ActionTypeObservations, StepEnumIdentification } from '../actions/observations.js';
import { ObservationImage } from '../../models/observationImage.js';
import { Observation } from '../../models/observation.js';

/* state initial */
const initialState = {
    loading: false, // en cours de chargement
    saving: false, // en cours de sauvegarde
    observationDico: {}, // dico { ObservationId: Observation }
    observationDicoCounter: 0, // nombre d'entrée dans le dico contenant les obervations
    observationIdListByParcelIdDico: 0, // dico { parcelId: [observationId1,observationId2,...] } - données utiles pour renderMaps
    observationTypeDico: {}, // dico { ObservationTypeId: ObservationType } - RQ: pour le moment, on stoque les données dans le reducer observation
    showAll: true, // affichage de toutes les observations ?
    lastObservationIdSaved: undefined, // id de la derniere observation ayant été créée ou modifier
    msgErrorDuringSave: null, // type de message d'erreur à afficher à l'utilisateur suite à une erreur lors de la sauvegarde d'observation
    textSnackbarCreateZoneOrMarker: null, //text à afficher dans la popup indiquant à l'utilisateur de cliquer sur la map pour placer un marker ou le premier point d'un polygon(zone d'observation ou parcelle) (uniquement sur mobile)
    
    createObservation: undefined, //Fonction permettant de créer une observation
    closeIdentificationDialog: undefined, //Fonction permettant de fermer la fenetre d'identification
    stepIdentification: StepEnumIdentification.CHOIX_IMAGE, //étape d'identification à afficher
    identificationImages: [], //Liste d'image pour l'identification en cours
    identificationResult: null, //résultat obtenu suite à l'identification
    identificationResultChoosen: null, //résultat retenue par l'utilisateur
    searching: false, //en cours de recherche
    errorDetectedDuringIdentification: false, // problème lors requête API de l'identification ?
    imagesToSelect: undefined, //les images de l'observation pas encore utilisées pour l'identification
    observationImages: undefined, //les images de l'observation pour l'identification
}

/* fonction principal du reducer */
function observationsManagerAction(state = initialState, action) {
    
    switch (action.type) {
        
        case( ActionTypeObservations.LOADING_OBSERVATIONS ) : {
            return {
                ...state,
                loading: true,
            }
        }

        case( ActionTypeObservations.SAVING_OBSERVATION ) : {
            return {
                ...state,
                saving: true,
            }
        }

        case( ActionTypeObservations.ADD_OBSERVATION ) : {
            
            // ↓ création d'un nouvel objet contenant les informations observationIdList par parcelId ↓
            let observationIdListByParcelIdToAdd = state.observationIdListByParcelIdDico;
            if (action.linkedParcelId > 0) {
                if ( Array.isArray(state.observationIdListByParcelIdDico[action.linkedParcelId]) )
                    observationIdListByParcelIdToAdd = { // on respecte le principe d'immutabilité
                        ...state.observationIdListByParcelIdDico,
                        [action.linkedParcelId]: [
                            ...state.observationIdListByParcelIdDico[action.linkedParcelId],
                            action.observationToAdd.id
                        ],
                    }
                else {
                    observationIdListByParcelIdToAdd = {
                        ...state.observationIdListByParcelIdDico,
                        [action.linkedParcelId]: [action.observationToAdd.id]
                    }
                }
            }           

            return {
                ...state,
                observationDico: {
                    ...state.observationDico,
                    [action.observationToAdd.id]: action.observationToAdd,
                },
                observationDicoCounter: state.observationDicoCounter + 1,
                observationIdListByParcelIdDico: observationIdListByParcelIdToAdd,
                loading: false,
                lastObservationIdSaved: action.observationToAdd.id,
            }
        }

        case( ActionTypeObservations.ADD_OBSERVATION_IMAGE ) : {

            let observationUpdated = state.observationDico[action.observationImage.observationId];
            let imagesListUpdated = observationUpdated.images;

            imagesListUpdated[action.imageIndex] = new ObservationImage({
                ...action.observationImage
            });

            observationUpdated = new Observation({
                ...observationUpdated,
                images: [
                    ...imagesListUpdated
                ],
            });

            return {
                ...state,
                observationDico: {
                    ...state.observationDico,
                    [action.observationImage.observationId]: observationUpdated
                }
            }
        }

        case( ActionTypeObservations.ADD_OBSERVATION_DICO ) : {

            // ↓ création d'un nouvel objet contenant les informations observationIdList par parcelId ↓
            let observationIdListByParcelIdToUpdate = state.observationIdListByParcelIdDico;
            Object.values(action.observationDicoToAdd).forEach((observation) => {
                if ( Array.isArray(observationIdListByParcelIdToUpdate[observation.parcelId]) )
                        observationIdListByParcelIdToUpdate = { // on respecte le principe d'immutabilité
                            ...observationIdListByParcelIdToUpdate,
                            [observation.parcelId]: [
                                // ...state.observationIdListByParcelIdDico[observation.parcelId],
                                ...observationIdListByParcelIdToUpdate[observation.parcelId],
                                observation.id
                            ],
                        }
                else {
                    if (observation.parcelId > 0) {
                        observationIdListByParcelIdToUpdate = {
                            ...observationIdListByParcelIdToUpdate,
                            [observation.parcelId]: [observation.id]
                        }
                    }
                }
            });

            return {
                ...state,
                observationDico: {
                    ...state.observationDico,
                    ...action.observationDicoToAdd,
                },
                observationDicoCounter: state.observationDicoCounter + action.observationDicoToAddCounter,
                observationIdListByParcelIdDico: observationIdListByParcelIdToUpdate,
                loading: false,
                saving: false,
            }
        }

        case( ActionTypeObservations.DELETE_OBSERVATION_ID_LIST ) : {

            // ↓ modification du dico d'observation et du dico d'observation par parcelId ↓
            action.observationIdList.forEach( observationId => {
                    
                    let parcelIdLinked = -1;
                    if ( state.observationDico[observationId] ) {
                        parcelIdLinked = state.observationDico[observationId].parcelId;
                        delete state.observationDico[observationId]; // on supprime l'entrée
                    }
                    
                     // ↓ si on a une parcelle liée et qu'on a une entrée existante => on la retire du dico ↓
                    if ((parcelIdLinked > 0) && Array.isArray(state.observationIdListByParcelIdDico[parcelIdLinked]) ) {
                            
                            // ↓ Pour cela - on va filtrer 
                            state.observationIdListByParcelIdDico[parcelIdLinked] = [
                                ...state.observationIdListByParcelIdDico[parcelIdLinked].filter( id => observationId !== id )
                            ];
                            // ↓ si on a une liste de vide => on supprime l'entrée key-value ↓
                            if (state.observationIdListByParcelIdDico[parcelIdLinked] <= 0) {
                                delete state.observationIdListByParcelIdDico[parcelIdLinked];
                            }
                    }
            });
            
            
            return {
                ...state,
                observationDico: {
                    ...state.observationDico,
                },
                observationDicoCounter: state.observationDicoCounter - action.observationIdListCounter,
                observationIdListByParcelIdDico: {
                    ...state.observationIdListByParcelIdDico,
                },
                loading: false,
                saving: false,
            }
        }

        case( ActionTypeObservations.DELETE_OBSERVATION_BY_PARCEL_ID_LIST ) : {

            // ↓ Pour chaque parcelId de la liste, on récupère les observationId pour ensuite les supprimer ↓ 
            action.parcelIdList.forEach( parcelId => {
                let observationIdListToDelete = state.observationIdListByParcelIdDico[parcelId];
                if ( Array.isArray(observationIdListToDelete) ) {
                    
                        observationIdListToDelete.forEach( observationId => { 
                            if ( state.observationDico[observationId] ) {
                                delete state.observationDico[observationId]; // on supprime l'entrée
                                state.observationDicoCounter = state.observationDicoCounter - 1; // on enlève 1
                            }
                        });

                }
                delete state.observationIdListByParcelIdDico[parcelId];
            });
            
            return {
                ...state,
                observationDico: {
                    ...state.observationDico,
                },
                observationDicoCounter: state.observationDicoCounter,
                observationIdListByParcelIdDico: {
                    ...state.observationIdListByParcelIdDico,
                },
                loading: false,
                saving: false,
            }
        }

        case( ActionTypeObservations.SHOW_ALL_OBSERVATION ) : {
            return {
                ...state,
                showAll: action.bool,
            }
        }

        case( ActionTypeObservations.RESET_OBSERVATION_DATAS ) : {
            return {
                loading: false,
                saving: false,
                observationDico: {},
                observationDicoCounter: 0,
                observationIdListByParcelIdDico: {},
                showAll: true,
            }
        }

        // ↓ suppression de toutes les observations ayant une association avec une parcelle ↓
        case( ActionTypeObservations.DELETE_ALL_LINKED_OBSERVATION ) : {
            
            // ↓ Pour chaque couple [cle,valeur] de la liste, on récupère les observationId pour ensuite les supprimer ↓ 
            for (var [parcelId, observationIdListToDelete] of Object.entries(state.observationIdListByParcelIdDico)){
                if ( Array.isArray(observationIdListToDelete) ) {
                    observationIdListToDelete.forEach( observationId => { 
                        if ( state.observationDico[observationId] ) {
                            delete state.observationDico[observationId]; // on supprime l'entrée
                            state.observationDicoCounter = state.observationDicoCounter - 1; // on enlève 1
                        }
                    })
                }
                delete state.observationIdListByParcelIdDico[parcelId];
            }
            
            return {
                ...state,
                observationDico: {
                    ...state.observationDico,
                },
                observationDicoCounter: state.observationDicoCounter,
                observationIdListByParcelIdDico: {
                    ...state.observationIdListByParcelIdDico,
                },
                loading: false,
                saving: false,
            }
        }

        /* ↓ ajout des types d'observation - RQ: pour le moment, ce cas ne se produit qu'1 seule fois au démarrage de l'application ↓ */
        case( ActionTypeObservations.ADD_OBSERVATION_TYPE_DICO ) : {

            return {
                ...state,
                observationTypeDico: {
                    ...action.observationTypeDicoToAdd,
                },
            }
        }

        case ( ActionTypeObservations.RESET_LAST_OBSERVATION_ID_SAVED ) : {

            return {
                ...state,
                lastObservationIdSaved: undefined,
                msgErrorDuringSave: null,
            }
        }

        case ( ActionTypeObservations.SET_MSG_ERROR_DURING_SAVE ) : {
            
            return {
                ...state,
                msgErrorDuringSave: action.msgError,
            }
        }

        case (ActionTypeObservations.OBSERVATION_SAVED) : {
            return {
                ...state,
                saving: false,
            }
        }

        case (ActionTypeObservations.SHOW_SNACKBAR_CREATE_OBSERVATION) : {
            return {
                ...state,
                textSnackbarCreateZoneOrMarker: action.text,
            }
        }

        case (ActionTypeObservations.GO_TO_STEP_IDENTIFICATION): {
            return {
                ...state,
                stepIdentification: action.step,
            }
        }

        case (ActionTypeObservations.SET_IDENTIFICATION_RESULT): {
            return {
                ...state,
                identificationResult: action.result,
                searching: false,
                stepIdentification: StepEnumIdentification.RESULTAT,
            }
        }

        case (ActionTypeObservations.SET_IDENTIFICATION_IMAGE_AT_INDEX): {
            let images = [...state.identificationImages];
            images[action.index] = action.image;

            return {
                ...state,
                identificationImages: images,
            }
        }

        case (ActionTypeObservations.ERROR_DETECTED_DURING_IDENTIFICATION): {
            return {
                ...state, 
                errorDetectedDuringIdentification: true,
                searching: false,
            }
        }

        case (ActionTypeObservations.GO_TO_IDENTIFICATION): {
            return {
                ...state,
                stepIdentification: StepEnumIdentification.CHOIX_IMAGE,
                identificationImages: action.images,
                createObservation: action.createObservation,
                closeIdentificationDialog: action.closeIdentificationDialog,
            }
        }

        case (ActionTypeObservations.START_TO_SEARCH_IDENTIFICATION): {
            return {
                ...state,
                identificationImages: action.images,
                imagesToSelect: action.imagesToSelect,
                searching: true,
                errorDetectedDuringIdentification: false,
            }
        }

        case (ActionTypeObservations.RESET_IDENTIFICATION_DATA): {
            return {
                ...state,
                identificationImages: [],
                identificationResult: null,
                stepIdentification: StepEnumIdentification.CHOIX_IMAGE,
                searching: false,
                errorDetectedDuringIdentification: false,
                createObservation: undefined,
                identificationResultChoosen: null,
                imagesToSelect: undefined,
                observationImages: undefined,
            }
        }

        case (ActionTypeObservations.CHOOSE_IDENTIFICATION_RESULT): {
            return {
                ...state,
                identificationResultChoosen: action.result,
                identificationResult: null,
            }
        }

        case (ActionTypeObservations.REMOVE_IDENTIFICATION_IMAGE): {
            let newIdentificationImages = state.identificationImages.filter(image=>image.imageUrl !== action.url)
            return {
                ...state,
                identificationImages: [...newIdentificationImages],
            }
        }

        case (ActionTypeObservations.SET_OBSERVATION_IMAGES_FOR_IDENTIFICATION): {
            return {
                ...state,
                observationImages: action.observationImages,
                imagesToSelect: action.observationImages,
                closeIdentificationDialog: action.closeIdentificationDialog,
            }
        }

        default:
            return state;
    }

}

export default observationsManagerAction;