import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function CornIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                    fill="#000000" stroke="none">
                    <path d="M4227 5110 c-249 -40 -635 -250 -1097 -598 -91 -68 -170 -128 -176
-133 -7 -6 -22 20 -44 77 -78 202 -292 444 -369 419 -38 -12 -53 -43 -62 -126
-12 -101 -44 -201 -110 -333 -55 -110 -199 -332 -213 -329 -4 1 -25 54 -47
117 -47 142 -121 292 -200 405 -116 168 -158 197 -213 149 -21 -19 -39 -67
-90 -244 -222 -759 -483 -1473 -706 -1931 -104 -213 -174 -336 -279 -488 -172
-248 -251 -413 -308 -643 -34 -137 -38 -341 -9 -466 l18 -78 -136 -137 c-177
-179 -181 -187 -181 -381 0 -138 1 -148 28 -201 32 -66 99 -132 165 -162 41
-19 65 -22 192 -22 188 0 205 7 352 152 l108 107 33 -13 c223 -92 412 -124
602 -101 182 22 327 75 483 176 88 57 117 85 493 470 302 309 576 529 929 743
425 258 962 462 1333 506 80 10 109 35 105 89 -3 38 -7 44 -73 89 -154 108
-393 202 -629 250 -64 13 -116 28 -116 33 1 5 56 74 124 154 611 722 928 1237
977 1589 13 87 5 225 -17 321 -56 245 -277 466 -524 524 -81 20 -266 28 -343
16z m95 -268 c-9 -7 -68 -42 -129 -76 l-112 -63 -61 78 -61 79 43 18 c70 30
129 51 188 66 l55 15 47 -52 c44 -48 46 -53 30 -65z m207 102 c6 -6 -1 -11
-26 -18 -16 -5 -25 -2 -33 13 -10 19 -8 20 22 14 18 -3 35 -7 37 -9z m191
-112 c0 -4 -16 -23 -35 -42 -34 -33 -35 -33 -55 -15 -24 22 -18 29 35 49 48
17 55 18 55 8z m-830 -136 c47 -60 59 -81 49 -87 -8 -4 -57 -37 -109 -74 -52
-37 -99 -69 -105 -71 -14 -6 -30 13 -94 105 l-50 74 47 30 c98 63 177 107 188
104 6 -1 40 -38 74 -81z m608 -9 l42 -43 -105 -104 -105 -105 -73 73 -72 74
24 16 c26 19 233 132 241 132 3 0 25 -19 48 -43z m342 30 c0 -2 -7 -24 -16
-50 -14 -41 -18 -46 -35 -37 -26 14 -24 29 8 62 23 24 43 36 43 25z m-2175
-134 c49 -68 92 -152 121 -235 13 -39 24 -75 24 -79 0 -4 -55 -54 -122 -111
-68 -57 -203 -174 -300 -260 l-176 -157 -6 37 c-14 88 -13 94 51 177 174 229
322 500 348 638 4 20 11 37 16 37 5 0 24 -21 44 -47z m860 -131 c37 -52 65
-95 63 -96 -1 -1 -54 -42 -116 -90 -90 -71 -115 -86 -127 -76 -7 6 -41 51 -74
100 l-61 88 38 30 c77 60 196 142 203 140 4 -2 37 -45 74 -96z m1175 52 l39
-36 -31 -61 c-27 -54 -105 -195 -114 -206 -2 -2 -38 27 -80 64 l-76 68 104
104 c56 56 106 103 110 103 4 0 26 -16 48 -36z m-2849 -92 c90 -157 157 -362
196 -597 25 -153 25 -603 0 -785 -26 -187 -54 -338 -94 -503 -30 -128 -37
-148 -56 -152 -12 -3 -83 -18 -157 -34 -203 -45 -534 -124 -730 -175 -96 -25
-176 -44 -177 -42 -2 2 15 34 38 71 246 405 564 1212 845 2143 66 221 54 215
135 74z m3099 108 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
10 -4 10 -10z m-817 -106 c42 -45 77 -85 77 -90 0 -5 -45 -54 -99 -108 -116
-115 -93 -118 -218 23 l-83 93 117 84 c65 46 120 82 123 81 3 -1 41 -39 83
-83z m826 -130 c8 -41 -69 -284 -91 -284 -11 0 -135 87 -143 100 -3 5 27 67
67 138 l73 130 45 -33 c25 -18 47 -41 49 -51z m-1800 -104 c40 -55 69 -105 66
-110 -6 -10 -244 -214 -253 -217 -6 -2 -77 97 -136 189 l-17 26 128 106 c70
58 131 105 134 106 4 0 39 -45 78 -100z m640 -67 c50 -56 91 -105 91 -110 0
-5 -45 -53 -101 -108 l-101 -100 -100 105 c-55 58 -108 117 -118 131 l-19 25
117 92 c89 70 120 88 128 79 6 -7 52 -58 103 -114z m620 30 l90 -76 -70 -101
c-38 -56 -77 -111 -87 -123 l-17 -22 -52 43 c-29 24 -78 67 -110 95 l-57 51
104 105 c58 58 106 105 107 105 2 0 43 -35 92 -77z m309 -238 c39 -27 72 -53
72 -58 0 -14 -143 -247 -153 -247 -21 0 -186 124 -179 134 4 7 42 62 85 124
53 75 83 110 91 105 7 -5 44 -30 84 -58z m-1949 -56 c41 -60 72 -111 70 -112
-2 -1 -59 -53 -125 -115 -67 -62 -124 -112 -127 -112 -6 0 -76 101 -130 187
l-27 42 126 111 c69 60 129 110 133 110 3 -1 39 -50 80 -111z m628 18 c39 -45
92 -102 117 -127 25 -24 46 -49 46 -55 0 -6 -54 -64 -120 -130 l-119 -119 -27
24 c-42 39 -234 254 -234 262 0 8 251 228 260 228 3 0 37 -37 77 -83z m698
-52 c37 -33 84 -74 103 -91 l35 -31 -88 -111 c-56 -72 -92 -109 -99 -104 -43
32 -179 144 -214 176 l-43 40 102 102 c87 88 105 101 119 91 9 -7 47 -39 85
-72z m-1701 -304 l77 -114 -118 -119 c-65 -65 -123 -118 -131 -118 -10 0 -12
41 -11 190 l1 190 50 45 c28 25 52 44 53 43 1 -2 37 -54 79 -117z m728 -97
c43 -44 78 -85 78 -90 0 -5 -54 -63 -120 -129 l-120 -119 -51 49 c-28 28 -91
96 -140 153 l-89 102 128 120 127 120 55 -63 c30 -34 89 -98 132 -143z m1330
120 c51 -36 95 -67 97 -69 6 -6 -141 -206 -155 -212 -18 -7 -206 117 -200 132
4 12 158 213 163 214 1 1 44 -29 95 -65z m-699 -16 c40 -35 100 -85 135 -112
34 -27 62 -53 62 -58 0 -11 -212 -258 -222 -258 -9 0 -198 150 -256 204 l-43
40 123 123 c67 68 124 123 126 123 2 0 36 -28 75 -62z m-1145 -291 c19 -23 78
-90 133 -149 54 -58 99 -110 99 -115 0 -4 -54 -62 -120 -128 l-120 -119 -51
49 c-53 52 -210 230 -224 254 -6 10 26 47 116 132 68 65 126 118 129 118 3 0
20 -19 38 -42z m1610 -211 c-45 -55 -94 -115 -109 -133 l-27 -31 -107 67 c-59
37 -108 71 -109 75 0 3 48 64 108 135 l109 128 108 -70 108 -69 -81 -102z
m-835 149 c34 -31 101 -86 149 -123 48 -37 87 -71 88 -75 0 -7 -212 -241 -228
-251 -8 -5 -222 162 -285 222 l-37 36 122 123 c68 68 124 123 126 123 2 0 31
-25 65 -55z m-1135 -298 c19 -23 80 -92 137 -153 l104 -111 -77 -76 -77 -76
-125 -20 c-68 -11 -126 -18 -129 -15 -2 2 7 55 22 117 14 61 35 162 46 224 20
110 36 154 56 152 5 0 25 -19 43 -42z m1621 -64 c58 -37 108 -68 110 -69 2 -2
-38 -50 -89 -108 l-91 -106 -72 7 c-62 5 -82 12 -140 50 l-68 43 113 125 c62
69 117 125 122 125 5 0 56 -30 115 -67z m-836 -6 c40 -34 112 -93 160 -131
l89 -69 -29 -29 c-29 -28 -32 -29 -184 -38 -85 -5 -174 -12 -199 -15 -43 -6
-47 -4 -103 46 l-59 52 124 124 c67 67 124 123 126 123 2 0 36 -28 75 -63z
m602 -422 c184 -6 385 -35 559 -80 120 -31 300 -95 319 -114 5 -5 -48 -22
-118 -40 -618 -155 -1220 -483 -1775 -967 -129 -112 -299 -281 -453 -449 -170
-186 -264 -263 -402 -330 -163 -80 -209 -90 -405 -90 -197 0 -277 18 -435 94
-177 87 -319 229 -405 406 -74 152 -85 201 -84 395 0 187 10 238 85 426 33 82
132 270 154 292 10 11 687 182 971 246 377 85 813 157 1166 191 193 19 488 33
598 28 41 -1 143 -5 225 -8z m-3222 -1702 c56 -97 143 -201 224 -267 34 -28
62 -56 62 -62 1 -15 -138 -148 -176 -168 -24 -12 -60 -16 -146 -16 -102 0
-118 2 -148 23 -18 12 -44 38 -56 56 -21 30 -23 46 -23 148 0 86 4 122 16 146
13 25 190 217 200 217 1 0 22 -35 47 -77z"/>
                    <path d="M1784 3969 c-16 -18 -18 -35 -15 -167 1 -80 5 -150 7 -154 8 -13 -15
-384 -31 -494 -22 -154 -60 -303 -107 -418 -38 -92 -40 -102 -27 -125 8 -14
27 -32 42 -41 24 -13 30 -13 54 0 36 20 58 61 103 188 72 205 109 443 117 762
6 257 -6 421 -32 450 -25 27 -86 27 -111 -1z"/>
                    <path d="M1397 3020 c-18 -14 -49 -81 -111 -235 -47 -118 -86 -222 -86 -231 0
-22 55 -74 78 -74 46 0 69 37 156 255 47 118 86 222 86 231 0 22 -55 74 -78
74 -11 0 -31 -9 -45 -20z"/>
                    <path d="M3758 2229 c-259 -23 -578 -121 -818 -252 -286 -156 -500 -312 -920
-670 -96 -82 -207 -172 -248 -199 -85 -57 -101 -82 -82 -128 7 -17 22 -36 33
-41 52 -29 128 21 447 291 292 248 399 331 589 458 363 243 717 372 1061 389
119 6 126 8 152 34 36 35 35 63 -1 100 -33 32 -46 33 -213 18z"/>
                    <path d="M2540 2139 c-188 -48 -470 -224 -687 -426 -59 -55 -66 -83 -32 -127
15 -19 30 -26 54 -26 27 0 52 16 141 91 128 107 228 179 339 242 91 53 231
107 276 107 36 0 89 47 89 80 0 29 -53 80 -82 79 -13 0 -57 -9 -98 -20z"/>
                    <path d="M1585 1481 c-98 -59 -135 -93 -135 -121 0 -26 51 -80 75 -80 28 0
156 72 197 111 22 21 38 45 38 58 0 27 -51 81 -77 81 -10 0 -55 -22 -98 -49z"/>
                    <path d="M830 932 c-37 -38 -38 -65 -2 -101 22 -23 43 -30 112 -42 95 -15 304
-18 394 -6 96 14 136 78 83 131 -24 24 -26 24 -193 19 -154 -5 -227 0 -336 23
-22 5 -34 0 -58 -24z"/>
                </g>
            </svg>
        </SvgIcon>
    );
}

export default CornIcon;
