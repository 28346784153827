import React from 'react';
import '../assets/css/login.css';
import StringTranslate from '../assets/i18n/stringLanguage.jsx';
import { isIE } from '../utils/authentUtils';
import { StatesConnection } from '../redux/actions/connection.js';

import { styled } from '@mui/material/styles';

import {
    Box, Typography, TextField, FormControl,
    Select, MenuItem, Button, Stack, Card, CardContent, Divider,
    CardActions, LinearProgress, Paper, InputLabel, FormHelperText, CircularProgress
} from '@mui/material';
import { keyframes } from '@mui/system';

import countryLookupDatas from '../datas/countryPickerList.json';
import { LanguageHelper } from '../utils/languageHelper.js';
import getTheme from "../themes/index.js";
import WorldWideIcon from '../assets/images/worldwide-icon.tsx';
import LogoSpotifarmTitreEtFeuille from '../assets/logoSpotifarm/LogoSpotifarmTitreEtFeuille';
import satelliteSpotifarm from '../assets/images/satellite-icon.png';

let theme = getTheme();

const orbit = keyframes`
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
`;

const invert_orbit = keyframes`
    0% {
        transform: rotateZ(360deg) rotateY(30deg) rotateX(-75deg);
    }
    100% {
        transform: rotateZ(0deg) rotateY(30deg) rotateX(-75deg);
    }
`;

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'center',
}));

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            phone: '',
            errorPhone: false,
            country: '',
            errorCountry: false,
            lookupCountryOpened: null,
            saving: false,
        }

        this.browserLanguage = LanguageHelper.getAlpha2BrowserLanguage();

        this.handlePhone = this.handlePhone.bind(this);
        this.handleCountry = this.handleCountry.bind(this);
        this.handleOpenLookupCountry = this.handleOpenLookupCountry.bind(this);
        this.handleClickSubmit = this.handleClickSubmit.bind(this);
    };

    /* callback en lien avec le champ numéro de téléphone */
    handlePhone = ({ target }) => {
        this.setState({
            phone: target.value,
            errorPhone: (!target.value) ? true : false,
        })
    }

    /* callback en lien avec le champ sélection du pays */
    handleCountry = ({ target }) => {
        this.setState({
            country: target.value,
            lookupCountryOpened: null,
            errorCountry: (!target.value) ? true : false,
        });
    }

    /* callback ouverture de la lookup sélection de pays*/
    handleOpenLookupCountry = () => {
        this.setState({ lookupCountryOpened: true });
    }

    /* callback en lien avec l'enregistrement des données du formulaire (inscription via réseaux sociaux) */
    handleClickSubmit = () => {
        const { phone, country } = this.state;

        if (!phone)
            this.setState({ errorPhone: true })
        else if (!country)
            this.setState({ errorCountry: true })
        else {
            this.setState({ saving: true });
            this.props.actionUpdateNewAccountSocialProvider(phone, country);
        }
    }

    render() {
        const { phone, errorPhone, country, errorCountry, lookupCountryOpened, saving } = this.state;
        const { stateConnexion, showSocialAccountSignupForm } = this.props;

        //↓ sinon on gère les autres cas ↓
        return (
            <Box
                className='login-spotifarm'
                sx={{
                    [theme.breakpoints.down('md')]: { backgroundColor: theme.palette.background.paper },
                    [theme.breakpoints.up('md')]: { backgroundColor: theme.palette.grey[100] },
                }}>
                <Card className='login-card-spotifarm'
                    sx={{
                        [theme.breakpoints.down('md')]: { border: 'none' },
                        [theme.breakpoints.up('md')]:
                        {
                            border: '1px solid',
                            borderColor: theme.palette.primary.light,
                            ':hover': {
                                boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)'
                            },
                        },
                    }}>
                    <CardContent>
                        <Stack spacing={1}>
                            <LogoSpotifarmTitreEtFeuille />
                            <Divider />
                            <Item>
                                {(showSocialAccountSignupForm === true) ?
                                    (<>
                                        {/* ↓ phrase d'introduction ↓ */}
                                        <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
                                            {`${StringTranslate.socialformtitle} :`}
                                        </Typography>

                                        {/* ↓ champ téléphone ↓ */}
                                        <TextField
                                            id="txt-phone"
                                            size="small"
                                            fullWidth
                                            disabled={(saving)}
                                            value={phone}
                                            onChange={this.handlePhone}
                                            label={StringTranslate.telephone}
                                            variant="outlined"
                                            InputProps={{ inputProps: { maxLength: 20 } }}
                                            error={errorPhone}
                                            helperText={StringTranslate.champobligatoire}
                                            margin="normal"
                                        />

                                        {/* ↓ champ sélection pays ↓ */}
                                        <FormControl
                                            size="small"
                                            fullWidth
                                            disabled={(saving)}
                                            onClick={this.handleOpenLookupCountry}
                                            variant="outlined"
                                            error={errorCountry}
                                            margin="normal"
                                        >
                                            <InputLabel id="label-country">{StringTranslate.pays}</InputLabel>
                                            <Select
                                                value={country}
                                                onChange={this.handleCountry}
                                                open={lookupCountryOpened}
                                            >
                                                {countryLookupDatas[this.browserLanguage].map((countryObj, index) => <MenuItem key={index} value={countryObj.Value}>{countryObj.Name}</MenuItem>)}
                                            </Select>
                                            <FormHelperText>{StringTranslate.champobligatoire}</FormHelperText>
                                        </FormControl>
                                    </>
                                    ) :
                                    (<React.Fragment>
                                        {!isIE() ?
                                            (
                                                <Box
                                                    id="orbit-system"
                                                    className="login-card-spotifarm-satellite"
                                                >
                                                    <Box
                                                        sx={{
                                                            position: "relative",
                                                            width: "100%",
                                                            height: "100%",
                                                            transform: "rotateX(75deg) rotateY(-30deg)",
                                                            transformStyle: "preserve-3d",
                                                            "-webkit-transform": "rotateX(75deg) rotateY(-30deg)",
                                                            "-webkit-transform-style": "preserve-3d"
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                position: "absolute",
                                                                top: "50%",
                                                                left: "50%",
                                                                transformStyle: "preserve-3d",
                                                                "-webkit-transform-style": "preserve-3d",
                                                                width: "16em",
                                                                height: "16em",
                                                                marginTop: "-8em",
                                                                marginLeft: "-8em",
                                                                borderRadius: "50%",
                                                                animation: `${orbit} 10s infinite linear`
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    position: "absolute",
                                                                top:"100%",
                                                                left:"50%",
                                                                transformStyle:"preserve-3d",
                                                                "-webkit-transform-style":"preserve-3d",
                                                                width:"3em",
                                                                height:"3em",
                                                                marginTop:"-1.5em",
                                                                marginLeft:"-1.5",
                                                                backgroundImage: `url(${satelliteSpotifarm})`,
                                                                backgroundRepeat:"no-repeat",
                                                                backgroundSize:"3em 3em",
                                                                animation:`${invert_orbit} 10s infinite linear`
                                                            }}
                                                        />
                                                        </Box>
                                                        <WorldWideIcon
                                                            id="worldWide1"
                                                            sx={{
                                                                position: "absolute",
                                                                top: "50%",
                                                                left: "50%",
                                                                transformStyle: "preserve-3d",
                                                                "-webkit-transform-style": "preserve-3d",
                                                                width: "8em",
                                                                height: "8em",
                                                                marginTop: "-4.5em",
                                                                marginLeft: "-4.3em",
                                                                backgroundRepeat: "no-repeat",
                                                                backgroundSize: "8em 8em",
                                                                backgroundColor: '#ffffffe3',
                                                                borderRadius: "50%",
                                                                transform: "rotateY(30deg) rotateX(-75deg)",
                                                                "-webkit-transform": "rotateY(30deg) rotateX(-75deg)"
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>) :
                                            (
                                                <Box id="orbit-system-for-IE" className="login-card-spotifarm-satellite">
                                                    <Box
                                                        sx={{
                                                            position: "relative",
                                                            width: "100%",
                                                            height: "100%",
                                                            transform: "rotateX(75deg) rotateY(-30deg)",
                                                            transformStyle: "preserve-3d",
                                                            "-webkit-transform": "rotateX(75deg) rotateY(-30deg)",
                                                            "-webkit-transform-style": "preserve-3d"
                                                        }}
                                                    >
                                                        <WorldWideIcon
                                                            id="worldWide2"
                                                            sx={{
                                                                position: "absolute",
                                                                top: "50%",
                                                                left: "50%",
                                                                transformStyle: "preserve-3d",
                                                                "-webkit-transform-style": "preserve-3d",
                                                                width: "8em",
                                                                height: "8em",
                                                                marginTop: "-4.5em",
                                                                marginLeft: "-4.3em",
                                                                backgroundRepeat: "no-repeat",
                                                                backgroundSize: "8em 8em",
                                                                backgroundColor: '#ffffffe3',
                                                                borderRadius: "50%",
                                                                transform: "rotateY(30deg) rotateX(-75deg)",
                                                                "-webkit-transform": "rotateY(30deg) rotateX(-75deg)"
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>)
                                        }
                                    </React.Fragment>)
                                }
                            </Item>
                        </Stack>
                    </CardContent>

                    <CardActions sx={{ display: 'block' }}>
                        <Item>
                            <Divider sx={{ mb: 2 }} />

                            {(showSocialAccountSignupForm === true) ?
                                /* ↓ bouton de sauvegarde ↓ */
                                <Button
                                    disabled={(saving)}
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    sx={{ mb: 1 }}
                                    onClick={this.handleClickSubmit}
                                    /* ↓ visuel en cours de sauvegarde ↓ */
                                    startIcon={(saving) ? <CircularProgress color="inherit" size={24} /> : null}>
                                    {StringTranslate.successbtnvalidation}
                                </Button> : null}

                            <LinearProgress />
                            <Typography variant="caption">
                                {(stateConnexion === StatesConnection.connecting) && `${StringTranslate.reco2}`}
                                {(stateConnexion === StatesConnection.disconnecting) && `${StringTranslate.deco}`}
                                {(stateConnexion === StatesConnection.resetingPwd) && `${StringTranslate.redirectmdp}`}
                            </Typography>
                        </Item>
                    </CardActions>

                </Card>
            </Box>
        );
    }
}

export default Login;