import React from 'react';
import { SpeedDial, SpeedDialIcon, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PinDropControl from '../carnetDePlaine/PinDropControl.jsx';
import DrawParcelButtonControl from './DrawParcelButtonControl';


/**
 * Composant menu mobile regroupant le dessin de parcelles et l'ajout des observations
 */
class DrawParcelAndPinDropObservation extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
		};

		this.canClose = true; //Rq: pas sous la forme d'une props du state local, car parfois pas accès rapide à l'actualisation ! (mobile surtout)
		
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCanClose = this.handleCanClose.bind(this);
	}
	
	setOpen(newValue) {
		this.setState({ open: newValue, });
	}

	handleOpen() {
		this.setOpen(true);
	};

	handleClose() { //appel réalisé que par les composants enfants dans les différents cas qui le nécessite.
		//On l'oblige à rester ouvert ! c'est aux composants enfants de l'autoriser à se fermer et lui signaler sa fermeture...
		if (this.canClose === true) { //si autorisé:
			this.setOpen(false);
		}
		else { 
			this.setOpen(true); //pas nécessaire d'être remis à 'vrai', mais pour bien l'avoir à l'esprit !
		}
	};

	handleCanClose(newStateCanClose) {
		if (newStateCanClose === true) {
			this.canClose = true; //setCanClose(true);
			this.setOpen(false);
		} else {
			this.canClose = false; //setCanClose(false);
			this.setOpen(true); //force son ouverture !
		}
	};

	render() {
		//RQ : Bogue sous IPhone sur le composant 'SpeedDial'. Bogue de @mui/material ! => on ne se lance pas sur un changement de composant afin de corriger cela maintenant !
		//Cf. https://github.com/mui/material-ui/issues/32373 
		return (
			<Box className='leaflet-control'
				sx={{
					zIndex:"1100"
				}}
			>
				<SpeedDial id="speedDial ici"
					ariaLabel="SpeedDial"
					size="small"
					hidden={false}
					icon={
						<SpeedDialIcon openIcon={<EditIcon />}/>
					}
					onOpen={this.handleOpen} 
					onClose={this.handleClose}
					open={this.state.open}
					direction={'left'}
					FabProps={{ 
						size: "small",
					}}
				>
					<PinDropControl renderMapsCmp={this.props.renderMapsCmp} class={this.state.open} onSpeedDialCanClose={this.handleCanClose} />
					<DrawParcelButtonControl renderMapsCmp={this.props.renderMapsCmp} class={this.state.open} onSpeedDialCanClose={this.handleCanClose} />
				</SpeedDial>
			</Box>
		);
	}
}

export default DrawParcelAndPinDropObservation;
