import { combineReducers } from 'redux';
import connectAction from './reducerConnection.js';
import clientUserReducer from './reducerClientUser.js';
import settingsManagerAction from './reducerSettings.js';
import parcelsManagerAction from './reducerParcels.js';
import satimageManagerAction from './reducerSatImage.js';
import observationsManagerAction from './reducerObservations.js';
import contextAppManagerAction from './reducerContextApp.js';
import didacticielManagerAction from './reducerDidacticiel.js';
import modulationsManagerAction from './reducerModulations.js';
import biomassesManagerAction from './reducerBiomass.js';
import { ActionTypeContextApp } from '../actions/contextApp.js';
import fertilizerManagerAction from './reducerFertilizer.js';
import modelisationManagerAction from './reducerModelisation.js';
import lastNitrogenInputManagerAction from './reducerLastNitrogenInput.js';
import harvestManagerAction from './reducerHarvest.js';


const appReducer = combineReducers({
    connectionData: connectAction,
    clientUserData: clientUserReducer,
    settingsData: settingsManagerAction,
    parcelsData: parcelsManagerAction,
    satimageData: satimageManagerAction,
    observationsData: observationsManagerAction,
    contextAppData: contextAppManagerAction,
    didacticielData: didacticielManagerAction,
    modulationsData: modulationsManagerAction,
    biomassData: biomassesManagerAction,
    fertilizerData: fertilizerManagerAction,
    modelisationData: modelisationManagerAction,
    lastNitrogenInputData: lastNitrogenInputManagerAction,
    harvestData: harvestManagerAction
});

const rootReducer = (state, action) => {
    if (action.type === ActionTypeContextApp.resetAppDatas) {
        state = undefined;
    }
    
    return appReducer(state, action)
}

export default rootReducer;