import { /*deviceDetect,*/ isMobile, isAndroid, isIOS, osName } from 'react-device-detect';
import ConstantsHosters from './constantsByConfig';
//import sendError from './errorService.js';


/* Pour une raison inconnue, le package '' n'arrive pas à déterminer l'OS lorsque l'appli web est chargée dans l'appli native ! */
/*const detectionAware = () => {
    let errorMsg = '';
    try {
        deviceDetect();
    }
    catch(errDetection) {
        errorMsg = errDetection;
    }

    console.log('detectionAware... (isMobile, osName, isAndroid, isIOS) =>', isMobile, osName, isAndroid, isIOS);
    
    let uadPlatform = 'N/A';
    let uadMobile = 'N/A';
    if (navigator && navigator.userAgentData) {
        uadPlatform = navigator.userAgentData.platform;
        uadMobile = navigator.userAgentData.mobile;
        console.log('navigator.userAgentData (platform,mobile) =>', uadPlatform, uadMobile);
    }
    
    let uaMobile = 'N/A';
    if (navigator && navigator.userAgent) {
        uaMobile = navigator.userAgent;
        console.log('navigator.userAgent (platform,mobile) =>', uaMobile);
    }

    if (sendError) {
        sendError('detectionAware', {
            "errorMsg": errorMsg,
            "isMobile": isMobile,
            "osName": osName,
            "isAndroid": isAndroid,
            "isIOS": isIOS,
            "uadPlatform": uadPlatform,
            "uadMobile": uadMobile,
            "uaMobile": uaMobile,
            "windowDatasToInit": window.datasToInit,
        });
    }

    return (osName && (osName !== 'none')) ? true : false;
};*/

/* fonctions permettant de détecter si l'application web est lancée/hégergé au sein de l'application native
    sinon, c'est via un navigateur... */
export const HasNativePropsFromScript = () => {
    if (ConstantsHosters.simulateLoadIntoNativeApp === true) { //pour tester la comm avec le natif !
        return true;
    }
    
    // RQ : 'window.intoNativeApp' injecté par le script de lancement de la WebView !
    return (window.intoNativeApp || window.datasToInit) ? true : false;
};
export const HasNativeWebviewObject = () => {
    if (ConstantsHosters.simulateLoadIntoNativeApp === true) { //pour tester la comm avec le natif !
        return true;
    }
    
    // RQ : 'window.ReactNativeWebView' est injecté par la WebView elle-même.
    return (window.ReactNativeWebView) ? true : false;
};
export const IsNativeHoster = () => {
    // RQ : 'window.intoNativeApp' injecté par le script de lancement de la WebView !
    //      'window.ReactNativeWebView' est injecté par la WebView elle-même.
    return (HasNativePropsFromScript() && HasNativeWebviewObject()) ? true : false;
};
export const IsMaybyNativeHoster = () => {
    // RQ : 'window.intoNativeApp' injecté par le script de lancement de la WebView !
    //      'window.ReactNativeWebView' est injecté par la WebView elle-même.
    return (HasNativePropsFromScript() || HasNativeWebviewObject()) ? true : false;
};

export const IsOnMobile = () => {
    if (ConstantsHosters.simulateLoadIntoNativeApp === true) { //pour tester la comm avec le natif !
        return true;
    }
    
    return (isMobile === true) ? true : false;
};

export const IsAndroidOS = () => {
    /*if (sendError) {
        sendError('platformHelper - IsIOS', {
            "simulateNativeApp": ConstantsHosters.simulateLoadIntoNativeApp,
            "detectionAwareFct": detectionAware(),
            "isMobile": isMobile,
            "osName": osName,
            "isAndroid": isAndroid,
            "isIOS": isIOS,
            "windowDatasToInit": window.datasToInit,
        });
    }*/
    
    if (ConstantsHosters.simulateLoadIntoNativeApp === true) { //pour tester la comm avec le natif !
        return true;
    }

    if (IsMaybyNativeHoster() && window.datasToInit && window.datasToInit.platformOS) { //autre moyen: via ce que nous à transmis l'appli native...
        return (window.datasToInit.platformOS.toLowerCase() === 'android') ? true : false;
    } else {
        return (isAndroid === true) ? true : false;
    }
};

export const IsIOS = () => {
    /*if (sendError) {
        sendError('platformHelper - IsIOS', {
            "simulateNativeApp": ConstantsHosters.simulateLoadIntoNativeApp,
            "detectionAwareFct": (osName && (osName !== 'none')) ? true : false, //: detectionAware(),
            "isMobile": isMobile,
            "osName": osName,
            "isAndroid": isAndroid,
            "isIOS": isIOS,
            "windowDatasToInit": window.datasToInit,
        });
    }*/
    
    if (ConstantsHosters.simulateLoadIntoNativeApp === true) { //pour tester la comm avec le natif !
        return false;
    }

    if (IsMaybyNativeHoster() && window.datasToInit && window.datasToInit.platformOS) { //autre moyen: via ce que nous à transmis l'appli native...
        return (window.datasToInit.platformOS.toLowerCase() === 'ios') ? true : false;
    } else {
        return (isIOS === true) ? true : false;
    }
};

export const GetOSName = () => {
    /*if (sendError) {
        sendError('platformHelper - IsIOS', {
            "simulateNativeApp": ConstantsHosters.simulateLoadIntoNativeApp,
            "detectionAwareFct": detectionAware(),
            "isMobile": isMobile,
            "osName": osName,
            "isAndroid": isAndroid,
            "isIOS": isIOS,
        });
    }*/
    
    if (ConstantsHosters.simulateLoadIntoNativeApp === true) { //pour tester la comm avec le natif !
        return 'Android';
    }

    if (IsMaybyNativeHoster() && window.datasToInit && window.datasToInit.platformOS) { //autre moyen: via ce que nous à transmis l'appli native...
        return window.datasToInit.platformOS;
    } else {
        return osName;
    }
};
