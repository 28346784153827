import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers/rootReducer.js';
import ConstantsHosters from '../../utils/constantsByConfig.js';

const storeManager = {
    store: null, // le store redux contenant les donn�es

    composeEnhancers: (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && ConstantsHosters.iWantDevToolsEnabled) ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose, // mise en place extension

    /* fonction permettant d'initailiser le store redux */
    configureStore: function(preloadedState = {}, history, userAgentAuthent) {
        storeManager.store = createStore(
            rootReducer,
            preloadedState,
            storeManager.composeEnhancers(
                applyMiddleware(thunkMiddleware.withExtraArgument({ history, userAgentAuthent })),
            )
        )
        return storeManager.store;
    }
};

export default storeManager;