import React from 'react';
import StringTranslate from '../assets/i18n/stringLanguage.jsx';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

import { ActionUpdateFilterAndApply, ActionResetAndApplyParcelFilter, ActionBurgerMenuCollapse, ActionSetIsFilterOpened, } from '../redux/actions/contextApp.js';

import {
	ListItemText, Checkbox, Tooltip, Typography,
	TextField, MenuItem, Button, Grid, Select,
	InputLabel, FormControl, InputAdornment, ListItemButton,
	ListItemIcon, Collapse, OutlinedInput
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import ArrowUp from '@mui/icons-material/KeyboardArrowUp';
import ArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { AreaComparators, AreaComparatorAllCode, AreaComparatorUpperCode, generateLabelAreaComparator } from '../models/parcelFilterParams.js';
import '../assets/css/parcelFilter.css';


//Définition du rendu des listes (de choix des exploitations, comme celle du choix des cultures)
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: 180,
			width: 250,
		},
	},
};

/**
 * Composant permettant de filtrer sur la liste de parcelle
 */
class ParcelFilter extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			//farmNameFilter: (props && props.filterFarmName) ? props.filterFarmName : filterAllFarmName, // filtre sur le nom de parcelle 
			farmListFilter: props.filterFarmList, // filtre sur le nom de parcelle 
			parcelNameFilter: props.filterParcelName, // filtre sur le nom de parcelle 
			cropListFilter: props.filterCropList, // filtre sur une liste de culture de parcelle
			areaComparatorFilter: props.filterAreaComparator, // filtre sur la surface d'une parcelle (comparateur)
			areaValueFilter: props.filterAreaValue, // filtre sur la surface d'une parcelle (chiffre en Hectare)

			showSurfaceMessageWarning: false, //affiche ou non le message indiquant qu'il faut saisir une surface 

			selectFarmIsOpen: false,
			selectCropIsOpen: false,
		};

		this.canApplyChangeFarmNameFilter = true;
		this.canApplyChangeCropNameFilter = true;

		this.handleOpenFilter = this.handleOpenFilter.bind(this);
		this.handleChangeParcelNameFilter = this.handleChangeParcelNameFilter.bind(this);
		this.handleChangeAreaComparatorFilter = this.handleChangeAreaComparatorFilter.bind(this);
		this.handleChangeAreaValueFilter = this.handleChangeAreaValueFilter.bind(this);
		this.handleResetFilter = this.handleResetFilter.bind(this);
		this.handleApplyFilter = this.handleApplyFilter.bind(this);
		this.handleEnableDisableFilter = this.handleEnableDisableFilter.bind(this);
		this.handleOpenFarmFilter = this.handleOpenFarmFilter.bind(this);
		this.handleOpenCropFilter = this.handleOpenCropFilter.bind(this);
		this.handleCloseFarmFilter = this.handleCloseFarmFilter.bind(this);
		this.handleCloseCropFilter = this.handleCloseCropFilter.bind(this);
	}

	/**
	 * Permet de changer la valeur isFilterOpened afin d'afficher la zone d'édition du filtre
	 */
	handleOpenFilter() {
		if (window.dataLayer) // google tag manager
			window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'ouverture filtre' });

		this.props.setIsFilterOpened(!this.props.isFilterOpened);
	};

	handleOpenFarmFilter(event) {
		this.setState({ selectFarmIsOpen: true });

		if (event) event.stopPropagation();
	}

	handleOpenCropFilter(event) {
		this.setState({ selectCropIsOpen: true });

		if (event) event.stopPropagation();
	}

	handleCloseFarmFilter(event) {
		this.setState({ selectFarmIsOpen: false });

		if (event) event.stopPropagation();
	}

	handleCloseCropFilter(event) {
		this.setState({ selectCropIsOpen: false });

		if (event) event.stopPropagation();
	}

	/**
	 * Méthode permettant d'actualiser les noms d'exploitation sélectionnées, afin de filtre la(les) parcelle(s) recherchée(s)
	 * RQ: lorsque l'on clique sur la case à cocher 'toutes les exploits', on appelle 'handleClickAllFarmName' mais plus cette méthode !
	 */
	handleChangeFarmFilter(event) {
		if (event && event.target && event.target.value && (this.canApplyChangeFarmNameFilter === true)) {
			const newValue = event.target.value;

			//En multi-sélection uniquement, il faut vérifier le cas de la désélection d'une case outre 
			// celle représentant 'toutes les exploits', afin de désélectionner celle-ci (justement):
			const { farmNameList, saveAndApplyParcelFilter } = this.props;
			//Constante définissant qu'il n'a pas été choisi de nom d'exploitation sur laquelle les parcelles sont filtrées
			const filterAllFarmName = StringTranslate.allFarmFilter;
			//const filterFarmWithoutName = StringTranslate.noFarmNameFilter;
			const { parcelNameFilter, cropListFilter, areaComparatorFilter, areaValueFilter } = this.state;

			let newListFarmName = [...newValue];
			const newValueCounter = newValue.length;
			const farmNameListCounter = farmNameList.length;
			const indexOfAllFarm = newValue.indexOf(filterAllFarmName);
			// si le nombre de case cochées est inférieur à la totalité des cases, c'est que l'on a pas toutes les exploits...
			if ((indexOfAllFarm > -1) && (newValueCounter < (farmNameListCounter + 2))) { //+2 pour les cases 'toutes' et 'sans nom'				
				newListFarmName.splice(indexOfAllFarm, 1); //on retire la case 'toutes' de la liste de celles cochées !
				this.setState({ farmListFilter: newListFarmName, });
			}
			// Si on a toutes les cases à l'exception de celle 'toutes les exploits'
			else if ((indexOfAllFarm < 0) && (newValueCounter === (farmNameListCounter + 1))) {
				newListFarmName.push(filterAllFarmName); //on l'ajoute la case 'toutes' de la liste de celles cochées !
				this.setState({ farmListFilter: newListFarmName, });
			} else { // On est en ajout...mais il en manque plusieurs (par rapport à la totalité) !
				this.setState({ farmListFilter: newValue, }); //'newValue' = 'newListFarmName' !
			}

			// On applique directement le filtre sur les exploitations choisies 
			if (saveAndApplyParcelFilter) {
				let filter = { 
					isFilterApplied: true,
					filterFarmList: [],
					filterParcelName: parcelNameFilter, 
					filterCropList: cropListFilter, 
					filterAreaComparator: areaComparatorFilter, 
					filterAreaValue: areaValueFilter,
				};
				if ((!newListFarmName) || (!Array.isArray(newListFarmName)) || (newListFarmName.length <= 0)) { //pour multi-sélection !
					saveAndApplyParcelFilter(filter); // Redux
				} else {
					filter.filterFarmList = newListFarmName;
					saveAndApplyParcelFilter(filter); // Redux
				}
			}
		}

		this.canApplyChangeFarmNameFilter = true;
	}

	handleClickAllFarmName(event) {
		const { farmListFilter, parcelNameFilter, cropListFilter, areaComparatorFilter, areaValueFilter } = this.state;
		const { farmNameList, saveAndApplyParcelFilter } = this.props;

		if (event) event.stopPropagation();

		//En multi-sélection uniquement, il faut spécialiser le cas de la sélection de la case 'toutes les exploits':
		//Constante définissant qu'il n'a pas été choisi de nom d'exploitation sur laquelle les parcelles sont filtrées
		const filterAllFarmName = StringTranslate.allFarmFilter;
		const filterFarmWithoutName = StringTranslate.noFarmNameFilter;

		let filter = { 
			isFilterApplied: true,
			filterFarmList: [],
			filterParcelName: parcelNameFilter, 
			filterCropList: cropListFilter, 
			filterAreaComparator: areaComparatorFilter, 
			filterAreaValue: areaValueFilter,
		};
		// Si on vient de désélectionner le choix 'Toutes les exploits', 
		// on ré-initialise le filtre de choix des exploitations pour ne rien filtrer:
		if (farmNameList && (farmNameList.length > 0) &&
			farmListFilter && (farmListFilter.length > 0) && (farmListFilter.indexOf(filterAllFarmName) > -1) &&
			event && event.target && (event.target.checked === false)) {
			this.canApplyChangeFarmNameFilter = false;
			// On désélectionne tout le monde:
			this.setState({ farmListFilter: [], });

			// On réinitialise le filtre sur les exploitations: 
			saveAndApplyParcelFilter(filter); // Redux
		}
		// Si on vient de cocher la case 'Toutes les exploits', il faut toutes les inclures
		else if (farmNameList && (farmNameList.length > 0) &&
			event && event.target && (event.target.checked === true)) {
			// On sélectionne tout le monde:
			let newListFarmName = [...farmNameList, filterAllFarmName, filterFarmWithoutName];
			this.setState({ farmListFilter: newListFarmName, });

			// On applique acualise le filtre sur toutes les exploitations: 
			filter.filterFarmList = newListFarmName;
			saveAndApplyParcelFilter(filter); // Redux
		}
	}

	/**
	 * Méthode permettant d'actualiser le nom (débuté) de parcelle entré par l'utilisateur, de la(les) parcelle(s) recherchée(s)
	 */
	handleChangeParcelNameFilter(event) {
		if (event && event.target) {
			const newValueFilterName = event.target.value;
			this.setState({
				parcelNameFilter: newValueFilterName
			});

			
			// On ajoute le filtre sur le nom de parcelle à celui déjà existant
			const { farmListFilter, cropListFilter, areaComparatorFilter, areaValueFilter } = this.state;
			let filter = { 
				isFilterApplied: true,
				filterFarmList: farmListFilter,
				filterParcelName: newValueFilterName, 
				filterCropList: cropListFilter, 
				filterAreaComparator: areaComparatorFilter, 
				filterAreaValue: areaValueFilter,
			};
			this.props.saveAndApplyParcelFilter(filter); // Redux
		}
	};

	/**
	 * Méthode permettant d'actualiser les cultures sélectionnées, afin de filtre la(les) parcelle(s) recherchée(s)
	 * RQ: lorsque l'on clique sur la case à cocher 'toutes les cultures', on appelle 'handleClickAllCropName' mais plus cette méthode !
	 */
	handleChangeCropFilter(event) {
		if (event && event.target && event.target.value && (this.canApplyChangeCropNameFilter === true)) {
			const newValue = event.target.value;

			//En multi-sélection uniquement, il faut vérifier le cas de la désélection d'une case outre 
			// celle représentant 'toutes les cultures', afin de désélectionner celle-ci (justement):
			const { cropNameList, saveAndApplyParcelFilter } = this.props;
			//Constante définissant qu'il n'a pas été choisi de nom d'exploitation sur laquelle les parcelles sont filtrées
			const filterAllCropName = StringTranslate.allCropFilter;
			//const filterCropWithoutName = StringTranslate.noCropNameFilter;

			let newListCropName = [...newValue];
			const newValueCounter = newValue.length;
			const cropNameListCounter = cropNameList.length;
			const indexOfAllCrop = newValue.indexOf(filterAllCropName);
			// si le nombre de case cochées est inférieur à la totalité des cases, c'est que l'on a pas toutes les cultures...
			if ((indexOfAllCrop > -1) && (newValueCounter < (cropNameListCounter + 2))) { //+2 pour les cases 'toutes' et 'sans nom'				
				newListCropName.splice(indexOfAllCrop, 1); //on retire la case 'toutes' de la liste de celles cochées !
				this.setState({ cropListFilter: newListCropName, });
			}
			// Si on a toutes les cases à l'exception de celle 'toutes les cultures'
			else if ((indexOfAllCrop < 0) && (newValueCounter === (cropNameListCounter + 1))) {
				newListCropName.push(filterAllCropName); //on l'ajoute la case 'toutes' de la liste de celles cochées !
				this.setState({ cropListFilter: newListCropName, });
			} else { // On est en ajout...mais il en manque plusieurs (par rapport à la totalité) !
				this.setState({ cropListFilter: newValue, }); //'newValue' = 'newListCropName' !
			}

			// On applique directement le filtre sur les cultures choisies 
			if (saveAndApplyParcelFilter) {
				const { farmListFilter, parcelNameFilter, areaComparatorFilter, areaValueFilter } = this.state;
				let filter = { 
					isFilterApplied: true,
					filterFarmList: farmListFilter,
					filterParcelName: parcelNameFilter, 
					filterCropList: [], 
					filterAreaComparator: areaComparatorFilter, 
					filterAreaValue: areaValueFilter,
				};
				if ((!newListCropName) || (!Array.isArray(newListCropName)) || (newListCropName.length <= 0)) { //pour multi-sélection !
					saveAndApplyParcelFilter(filter); // Redux
				} else {
					filter.filterCropList = newListCropName;
					saveAndApplyParcelFilter(filter); // Redux
				}
			}
		}

		this.canApplyChangeCropNameFilter = true;
	}

	handleClickAllCropName(event) {
		const { cropListFilter, farmListFilter, parcelNameFilter, areaComparatorFilter, areaValueFilter } = this.state;
		const { cropNameList, saveAndApplyParcelFilter } = this.props;

		if (event) event.stopPropagation();

		//En multi-sélection uniquement, il faut spécialiser le cas de la sélection de la case 'toutes les cultures':
		//Constante définissant qu'il n'a pas été choisi de nom d'exploitation sur laquelle les parcelles sont filtrées
		const filterAllCropName = StringTranslate.allCropFilter;
		const filterCropWithoutName = StringTranslate.noCropNameFilter;

		let filter = { 
			isFilterApplied: true,
			filterFarmList: farmListFilter,
			filterParcelName: parcelNameFilter, 
			filterCropList: [], 
			filterAreaComparator: areaComparatorFilter, 
			filterAreaValue: areaValueFilter,
		};

		// Si on vient de désélectionner le choix 'Toutes les cultures', 
		// on ré-initialise le filtre de choix des exploitations pour ne rien filtrer:
		if (cropNameList && (cropNameList.length > 0) &&
			cropListFilter && (cropListFilter.length > 0) && (cropListFilter.indexOf(filterAllCropName) > -1) &&
			event && event.target && (event.target.checked === false)) {
			this.canApplyChangeCropNameFilter = false;
			// On désélectionne tout le monde:
			this.setState({ cropListFilter: [], });

			// On réinitialise le filtre sur les cultures: 
			saveAndApplyParcelFilter(filter); // Redux
		}
		// Si on vient de cocher la case 'Toutes les cultures', il faut toutes les inclures
		else if (cropNameList && (cropNameList.length > 0) &&
			event && event.target && (event.target.checked === true)) {
			// On sélectionne tout le monde:
			let newListCropName = [...cropNameList, filterAllCropName, filterCropWithoutName];
			this.setState({ cropListFilter: newListCropName, });

			// On applique acualise le filtre sur toutes les cultures: 
			filter.filterCropList = newListCropName;
			saveAndApplyParcelFilter(filter); // Redux
		}
	}

	/**
	 * Permet de changer la valeur du comparateur dans l'onglet filtre
	 */
	handleChangeAreaComparatorFilter(event) {
		const { areaValueFilter } = this.state;
		
		let value = event.target.value
		
		// Si un comparateur autre que 'Toute' est sélectionnée alors qu'il n'y a pas de valeur de renseignée
		if ((value !== AreaComparatorAllCode) && (!areaValueFilter)) {
			// Affiche un msg pour indiquer qu'il faut saisir une valeur
			this.setState({
				areaComparatorFilter: value,
				showSurfaceMessageWarning: true,
			})
		}
		else {
			// Sinon on sauvegarde
			this.setState({
				showSurfaceMessageWarning: false,
				areaComparatorFilter: value,
				areaValueFilter: (value === AreaComparatorAllCode)? 0 : areaValueFilter,
			}, () => { this.handleApplyFilter(); });
		}
	}

	/**
	 * Permet de changer la valeur du comparateur de surface d'hectare dans l'onglet filtre
	 */
	handleChangeAreaValueFilter(event) {
		let areaValue = 0;
		try {
			areaValue = parseInt(event.target.value, 10);
		} catch (error) { }

		const { areaComparatorFilter } = this.state;

		// règle de gestion
		let newAreaComparatorFilter = areaComparatorFilter;
		if ((areaComparatorFilter === AreaComparatorAllCode) && (areaValue > 0)) // change le comparateur à > s'il vaut 'all' !
			newAreaComparatorFilter = AreaComparatorUpperCode;
		else if ((areaComparatorFilter !== AreaComparatorAllCode) && (!areaValue))
			newAreaComparatorFilter = AreaComparatorAllCode;
		else { }

		this.setState({
			showSurfaceMessageWarning: false,
			areaComparatorFilter: newAreaComparatorFilter,
			areaValueFilter: areaValue,
		}, () => { this.handleApplyFilter(); });
	};

	/**
	 * Fonction permettant de remettre les différentes données du filtre dans leur état originel
	 */
	handleResetFilter(event) {
		this.setState({
			farmListFilter: [],
			parcelNameFilter: '',
			cropListFilter: [],
			areaComparatorFilter: AreaComparatorAllCode,
			areaValueFilter: 0,
		});

		this.props.resetAndApplyParcelFilter(); // Redux
	};

	/**
	 * Fonction qui permet d'enregistrer et d'appliquer le filtre
	 */
	handleApplyFilter(event) {
		const { farmListFilter ,parcelNameFilter, cropListFilter, areaComparatorFilter, areaValueFilter } = this.state;
		const { saveAndApplyParcelFilter, } = this.props;

		let newFilterDatas = { //RQ: Est-ce nécessaire de ré-appliquer le filtre sur le choix des exploit ?
			isFilterApplied: true,
			filterFarmList: farmListFilter, //En principe: Non, car il est automatiquement appliqué lors d'une modification !
			filterParcelName: parcelNameFilter,
			filterCropList: cropListFilter,
			filterAreaComparator: areaComparatorFilter,
			filterAreaValue: areaValueFilter,
		};
		saveAndApplyParcelFilter(newFilterDatas); // Redux
	};

	handleEnableDisableFilter(event) {
		if (event) {
			event.preventDefault(); // Let's stop this event.
			event.stopPropagation(); // Really this time.
		}

		const { farmListFilter ,parcelNameFilter, cropListFilter, areaComparatorFilter, areaValueFilter } = this.state;
		const { hasFilterDefined, isFilterApplied, saveAndApplyParcelFilter } = this.props;
		if (hasFilterDefined === false) return;

		let newFilterDatas = { //RQ: Est-ce nécessaire de ré-appliquer le filtre sur le choix des exploit ?
			isFilterApplied: !isFilterApplied,
			filterFarmList: farmListFilter, //En principe: Non, car il est automatiquement appliqué lors d'une modification !
			filterParcelName: parcelNameFilter,
			filterCropList: cropListFilter,
			filterAreaComparator: areaComparatorFilter,
			filterAreaValue: areaValueFilter,
		};
		saveAndApplyParcelFilter(newFilterDatas); // Redux
	}

	/* fonction générant le contenu dynamique des entréesde choix d'exploitation sur laquelle filtrer les parcelles */
	customItemsOfFarmName(farmNameList) {
		//Constante définissant qu'il n'a pas été choisi de nom d'exploitation sur laquelle les parcelles sont filtrées
		const filterAllFarmName = StringTranslate.allFarmFilter;
		const filterFarmWithoutName = StringTranslate.noFarmNameFilter;

		const { farmListFilter } = this.state;

		if ((!farmNameList) || (farmNameList.length <= 0)) { //Ne devrait jamais arriver car on masque le filtre si vide...
			//return (<MenuItem key={-1} value={filterAllFarmName}>{filterAllFarmName}</MenuItem>); //cas mono-sélection !
			return (<MenuItem key={-1} value={filterAllFarmName}>
				<Checkbox checked={farmListFilter && (farmListFilter.indexOf(filterAllFarmName) > -1)} />
				<ListItemText primary={filterAllFarmName} />
			</MenuItem>);
		}

		//peut pas utiliser ceci car besoin d'ajouter un premier élément disant 'toutes' (=pas de filtre)
		//return farmNameList.map((farmName, index) => <MenuItem key={index} value={farmName}>{farmName}</MenuItem>);
		let itemsOfFarmName = [];
		//itemsOfFarmName.push(<MenuItem key={-1} value={filterAllFarmName}>{filterAllFarmName}</MenuItem>); //cas mono-sélection !
		itemsOfFarmName.push(<MenuItem key={-1} value={filterAllFarmName}>
			<Checkbox checked={farmListFilter && (farmListFilter.indexOf(filterAllFarmName) > -1)}
				onClick={this.handleClickAllFarmName.bind(this)} />
			<ListItemText primary={filterAllFarmName} />
		</MenuItem>);
		farmNameList.forEach((farmName, index) => {
			//itemsOfFarmName.push(<MenuItem key={index} value={farmName}>{farmName}</MenuItem>); //cas mono-sélection !
			// Si la parcelle n'a pas de nom d'exploitation:
			if ((!farmName) || (farmName === '') || (farmName.length <= 0)) {
			} else if ((farmName === filterAllFarmName) || (farmName === filterFarmWithoutName)) {
				//RAS !
			} else { //sinon, c'est que le nom existe, donc on l'ajoute pour permettre de le sélectionner:
				itemsOfFarmName.push(<MenuItem key={index} value={farmName}>
					<Checkbox checked={farmListFilter && (farmListFilter.indexOf(farmName) > -1)} />
					<ListItemText primary={farmName} />
				</MenuItem>);
			}
		});
		// On ajoute le choix 'sans exploitation précisée':
		itemsOfFarmName.push(<MenuItem key={-2} value={filterFarmWithoutName}>
			<Checkbox checked={farmListFilter && (farmListFilter.indexOf(filterFarmWithoutName) > -1)} />
			<ListItemText primary={filterFarmWithoutName} />
		</MenuItem>);

		return itemsOfFarmName;
	}

	renderSelectedFarms = (valuesSelected, totalFarmList) => {
		if ((!valuesSelected) || (!Array.isArray(valuesSelected))) return '';

		//Constante définissant qu'il n'a pas été choisi de nom d'exploitation sur laquelle les parcelles sont filtrées
		const filterAllFarmName = StringTranslate.allFarmFilter;
		const countFarmsSelected = valuesSelected.length;
		// Cas d'une seule exploitation sélectionnée:
		if (countFarmsSelected === 1) {
			return valuesSelected[0];
			// Cas où toutes les exploitations sont sélectionnées:
		} else if (totalFarmList && ((totalFarmList.length + 2) <= countFarmsSelected)) { //+2 pour les cases 'toutes' et 'sans nom'				
			return filterAllFarmName;
		} else { //reste le cas de 'quelques unes des exploitations sont sélectionnées...
			return `${countFarmsSelected} ${StringTranslate.selectedFarmsCounter}`;
		}
	}

	/* fonction générant le contenu dynamique des entréesde choix d'exploitation sur laquelle filtrer les parcelles */
	customItemsOfCropName(cropNameList) {
		//Constante définissant qu'il n'a pas été choisi de nom d'exploitation sur laquelle les parcelles sont filtrées
		const filterAllCropName = StringTranslate.allCropFilter;
		const filterCropWithoutName = StringTranslate.noCropNameFilter;

		const { cropListFilter } = this.state;

		if ((!cropNameList) || (cropNameList.length <= 0)) { //Ne devrait jamais arriver car on masque le filtre si vide...
			return (<MenuItem key={-1} value={filterAllCropName}>
				<Checkbox checked={cropListFilter && (cropListFilter.indexOf(filterAllCropName) > -1)} />
				<ListItemText primary={filterAllCropName} />
			</MenuItem>);
		}

		let itemsOfCropName = [];
		itemsOfCropName.push(<MenuItem key={-1} value={filterAllCropName}>
			<Checkbox checked={cropListFilter && (cropListFilter.indexOf(filterAllCropName) > -1)}
				onClick={this.handleClickAllCropName.bind(this)} />
			<ListItemText primary={filterAllCropName} />
		</MenuItem>);
		cropNameList.forEach((cropName, index) => {
			// Si la parcelle n'a pas de nom d'exploitation:
			if ((!cropName) || (cropName === '') || (cropName.length <= 0)) {
				//RAS !
			} else if ((cropName === filterAllCropName) || (cropName === filterCropWithoutName)) {
				//RAS !
			} else { //sinon, c'est que le nom existe, donc on l'ajoute pour permettre de le sélectionner:
				itemsOfCropName.push(<MenuItem key={index} value={cropName}>
					<Checkbox checked={cropListFilter && (cropListFilter.indexOf(cropName) > -1)} />
					<ListItemText primary={cropName} />
				</MenuItem>);
			}
		});
		// On ajoute le choix 'sans culture précisée':
		itemsOfCropName.push(<MenuItem key={-2} value={filterCropWithoutName}>
			<Checkbox checked={cropListFilter && (cropListFilter.indexOf(filterCropWithoutName) > -1)} />
			<ListItemText primary={filterCropWithoutName} />
		</MenuItem>);

		return itemsOfCropName;
	}

	renderSelectedCrops = (valuesSelected, totalCropList) => {
		if ((!valuesSelected) || (!Array.isArray(valuesSelected))) return '';

		//Constante définissant qu'il n'a pas été choisi de nom d'exploitation sur laquelle les parcelles sont filtrées
		const filterAllCropName = StringTranslate.allCropFilter;
		const countCropsSelected = valuesSelected.length;
		// Cas d'une seule culture sélectionnée:
		if (countCropsSelected === 1) {
			return valuesSelected[0];
			// Cas où toutes les cultures sont sélectionnées:
		} else if (totalCropList && ((totalCropList.length + 2) <= countCropsSelected)) { //+2 pour les cases 'toutes' et 'sans nom'				
			return filterAllCropName;
		} else { //reste le cas de 'quelques unes des exploitations sont sélectionnées...
			return `${countCropsSelected} ${StringTranslate.selectedCropsCounter}`;
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { burgerMenuIsOpen } = this.props;

		// cas de la fermeture du menu de gauche, rétractable en mode mobile, (celui représentant la side-bar en mode PC):
		if ((prevProps.burgerMenuIsOpen !== burgerMenuIsOpen) && (burgerMenuIsOpen === false)) {
			// on ferme alors le visuel des filtres par exploit et par culture...
			this.setState({ selectFarmIsOpen: false, selectCropIsOpen: false });
		}
	}

	/* fonction cycle de vie react.js */
	render() {
		const { parcelNameFilter, areaComparatorFilter, areaValueFilter,
			cropListFilter, farmListFilter, selectFarmIsOpen, selectCropIsOpen, showSurfaceMessageWarning } = this.state;
		const { cropNameList, farmNameList, hasFilterDefined, isFilterApplied,
			filterCropList, filterFarmList, isFilterOpened } = this.props;

		// Libellé de comparaison de la surface de parcelle:
		const labelAreaComparator = generateLabelAreaComparator();
		// Visuelsdes entrées cochables correspondants aux noms d'exploitations:
		const menuItemsOfFarms = this.customItemsOfFarmName(farmNameList);
		// Visuelsdes entrées cochables correspondants aux cultures présentes:
		const menuItemsOfCrops = this.customItemsOfCropName(cropNameList);

		return (
			<>
				<ListItemButton onClick={this.handleOpenFilter}>
					<ListItemIcon>
						<TuneIcon sx={{ m: 'auto' }} />
						{(hasFilterDefined) && (<Tooltip title={`${StringTranslate.tooltipDisableFilter}`} placement="right-start">
							<Checkbox checked={isFilterApplied} size='small' onClick={this.handleEnableDisableFilter} />
						</Tooltip>)}
					</ListItemIcon>
					<ListItemText primary={`${StringTranslate.filtre}`} />
					{isFilterOpened ? <ArrowUp /> : <ArrowDown />}
				</ListItemButton>

				<Collapse in={isFilterOpened} timeout="auto" unmountOnExit>

					{/* ZONE - FILTRE - CONTENU */}
					{(isFilterOpened) && (
						<Grid container columnSpacing={1} rowSpacing={2} sx={{ marginTop: 0.5 }}>
							{/* FILTRE - SELECTION D'UNE EXPLOITATION */}
							{(filterFarmList && farmNameList && Array.isArray(farmNameList) && (farmNameList.length > 1)) && (
								<Grid item xs={12}>
									<FormControl sx={{ width: '100%' }}>
										<InputLabel id="simple-select-label-farm"
											sx={{ top: "-6px !important" }}	// Sans ce décalage, le titre n'est pas centré dans le champ
										>
											{StringTranslate.farmsLabel}
										</InputLabel>
										{/* version multi-sélection: */}
										<Select labelId="simple-select-label-farm" id="simple-select-farm" multiple
											value={farmListFilter}
											renderValue={selected => this.renderSelectedFarms(selected, farmNameList)}
											input={<OutlinedInput
												size="small"
												label={StringTranslate.farmsLabel}
											/>}
											onChange={this.handleChangeFarmFilter.bind(this)}
											MenuProps={MenuProps}
											onOpen={this.handleOpenFarmFilter}
											onClose={this.handleCloseFarmFilter}
											open={selectFarmIsOpen}
										>
											{menuItemsOfFarms}
										</Select>
									</FormControl>
								</Grid>
							)}

							{/* ZONE - RECHERCHE DE PARCELLE VIA CHAMPS DE RECHERCHE */}
							<Grid item xs={12}>

								{/* champ de saisie */}
								<TextField
									type="search"
									label={StringTranslate.nameLabel}
									value={parcelNameFilter ? parcelNameFilter : ''}
									onChange={this.handleChangeParcelNameFilter}
									size="small"
									InputProps={{
										endAdornment:
											<InputAdornment position="end">
												<SearchIcon />
											</InputAdornment>
									}}
								/>
							</Grid>

							{/* FILTRE - SELECTION DES CULTURES */}
							{(filterCropList && cropNameList && Array.isArray(cropNameList) && (cropNameList.length > 0)) && (
								<Grid item xs={12}>
									<FormControl sx={{ width: '100%' }}>
										<InputLabel
											id="simple-select-label-crop"
											sx={{ top: "-6px !important" }}	// Sans ce décalage, le titre n'est pas centré dans le champ
										>
											{StringTranslate.cropsLabel}
										</InputLabel>
										{/* version multi-sélection: */}
										<Select
											labelId="simple-select-label-crop"
											id="simple-select-crop"
											multiple
											value={cropListFilter}
											renderValue={selected => this.renderSelectedCrops(selected, cropNameList)}
											input={
												<OutlinedInput
													size="small"
													label={StringTranslate.cropsLabel}
												/>
											}
											onChange={this.handleChangeCropFilter.bind(this)}
											MenuProps={MenuProps}
											onOpen={this.handleOpenCropFilter}
											onClose={this.handleCloseCropFilter}
											open={selectCropIsOpen}
										>
											{menuItemsOfCrops}
										</Select>
									</FormControl>
								</Grid>
							)}

							<Grid item xs={12}>
								<Grid sx={{ flexGrow: 1 }} container spacing={0.5}>
									{/* FILTRE - SURFACE PARCELLE */}
									<Grid item sx={{ marginTop: 1 }}>
										<Typography>{`${StringTranslate.surface}`}</Typography>
									</Grid>
									<Grid item>
										<TextField
											sx={{
												maxWidth: '74px'
											}}
											size="small"
											select
											value={areaComparatorFilter}
											onChange={this.handleChangeAreaComparatorFilter}
										>
											{AreaComparators.map((option, index) => (
												<MenuItem key={option.code} value={option.code}>
													<Typography>{labelAreaComparator[index]}</Typography>
												</MenuItem>
											))}
										</TextField>
									</Grid>
									<Grid item>
										<TextField
											sx={{
												maxWidth: '73px'
											}}
											size="small"
											type="number"
											value={areaValueFilter ? areaValueFilter : ''}
											placeholder={StringTranslate.SampleParcelAreaValue}
											onChange={this.handleChangeAreaValueFilter}
										/>
									</Grid>
								</Grid>
								{showSurfaceMessageWarning && <Grid sx={{ flexGrow: 1 }} container spacing={0.5}>
									<Typography
										color={(theme)=>theme.palette.error.light}
									>{`${StringTranslate.filterAreaHelper}`}</Typography>
								</Grid>}
							</Grid>

							{/* FILTRE - BUTTON RESET */}
							<Grid item xs={12} justifyContent="end" textAlign="end" >
								<Button sx={{ mr: 1 }}
									variant="text"
									size="small"
									onClick={this.handleResetFilter}
									disabled={!isFilterApplied}
								>
									{`${StringTranslate.reinitialiser}`}
								</Button>
							</Grid>
						</Grid>
					)}
				</Collapse>
			</>
		)
	}
}

const getIfFilterWasDefined = (state) => {
    if ((!state) || (!state.contextAppData)) return false;
	let contextAppDataState = state.contextAppData;

    // si le nom d'une exploit est fourni OU le nom partielle d'une parcelle OU une contrainte de taille OU un(des)choix de culture :
    return (contextAppDataState.filterFarmList && (Array.isArray(contextAppDataState.filterFarmList)) && (contextAppDataState.filterFarmList.length > 0)) ||
        //(contextAppDataState.filterFarmName && (contextAppDataState.filterFarmName !== '') && (contextAppDataState.filterFarmName.length > 0)) || //casmono-sélection !
        (contextAppDataState.filterParcelName && (contextAppDataState.filterParcelName !== '') && (contextAppDataState.filterParcelName.length > 0)) ||
        (contextAppDataState.filterCropList && (contextAppDataState.filterCropList.length > 0)) ||
        (contextAppDataState.filterAreaComparator && (contextAppDataState.filterAreaComparator !== AreaComparatorAllCode));
}

//Définition des propriétés "décorant" le composant 'App':
const mapStateToProps = state => ({
    //Infos provenant du reducer 'contextApp':
    burgerMenuIsOpen: lodashGet(state, 'contextAppData.burgerMenuIsOpen', false),
	
    isFilterOpened: lodashGet(state, 'contextAppData.isFilterOpened', false),
    isFilterApplied: lodashGet(state, 'contextAppData.isFilterApplied', false),
    hasFilterDefined: getIfFilterWasDefined(state),
    //filterFarmName: (state && state.contextAppData) ? state.contextAppData.filterFarmName : undefined, //cas mono-sélection ! 
    filterFarmList: lodashGet(state, 'contextAppData.filterFarmList', []),
    filterParcelName: lodashGet(state, 'contextAppData.filterParcelName', ''),
    filterCropList: lodashGet(state, 'contextAppData.filterCropList', []),
    filterAreaComparator: lodashGet(state, 'contextAppData.filterAreaComparator', AreaComparatorAllCode),
    filterAreaValue: lodashGet(state, 'contextAppData.filterAreaValue', 0),
    farmNameList: lodashGet(state, 'contextAppData.farmNameList', []),
	cropNameList: lodashGet(state, 'contextAppData.cropNameList', []),
	settings: lodashGet(state, 'settingsData.settings', {}),
});

//Définition des méthodes "décorant" le composant 'App':
const mapDispatchToProps = dispatch => ({
    colapseBurgerMenu: () => dispatch(ActionBurgerMenuCollapse()),

	setIsFilterOpened: (isOpen) => dispatch(ActionSetIsFilterOpened(isOpen)),
    saveAndApplyParcelFilter: (newFilter) => dispatch(ActionUpdateFilterAndApply(newFilter)),
    resetAndApplyParcelFilter: () => dispatch(ActionResetAndApplyParcelFilter()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParcelFilter);