import React from 'react';
import { connect } from 'react-redux';

import {
    Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, CircularProgress, Grid,
    Stack
} from '@mui/material';

import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

import { ActionOpenCongratulationsDialog } from '../../redux/actions/modulations';


/* Après la mise à jour de Material UI Core, une erreur avec le "disableBackdropClick" est survenue.
Pour remedier à cette situation : */
function DialogMigrate({
    children,
    disableBackdropClick,
    disableEscapeKeyDown,
    onClose,
    ...rest
}) {
    const handleClose = (event, reason) => {
        if (disableBackdropClick && reason === "backdropClick") {
            return false;
        }

        if (disableEscapeKeyDown && reason === "escapeKeyDown") {
            return false;
        }

        if (typeof onClose === "function") {
            onClose();
        }
    };

    return (
        <Dialog onClose={handleClose} {...rest}>
            {children}
        </Dialog>
    );
}

/* Dernière étape de la modulation : Etape de succès */
function CongratulationsModulation(props) {

    /* Fermeture de la popup de félicitation */
    const handleCongratulationsClose = (event, reason) => {
        props.openOrCloseCongratulationsDialog(false);

        //@@faut-il rebasculer le client sur la visualisation des modulations existantes ?
        //@@Par contre, c'est sûre qu'il faut relancer leur obtension... A moins que l'on est déjà actualisé la liste actuelle !
    }

    return (
        <DialogMigrate
            disableBackdropClick
            disableEscapeKeyDown
            open={props.openCongratulationsDialog}
            onClose={(event) => handleCongratulationsClose(event)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{StringTranslate.bravotitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {StringTranslate.messageEndModulation}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="right" justifyContent="right">
                            <Button
                                disabled={props.buildWaiting}
                                variant="text"
                                onClick={handleCongratulationsClose}
                                color="primary"
                                startIcon={props.buildWaiting ? <CircularProgress
                                    color="inherit" size={24}
                                /> : null}>
                                {props.buildWaiting ?
                                    StringTranslate.downloadModulationInProgress
                                    :
                                    StringTranslate.close}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogActions>
        </DialogMigrate>
    );
}

const mapStateToProps = state => ({
    //Infos provenant du reducer 'modulations':
    openCongratulationsDialog: state.modulationsData.openCongratulationsDialog ? state.modulationsData.openCongratulationsDialog : false,
    buildWaiting: (state && state.modulationsData) ? state.modulationsData.building : false,
})

const mapDispatchToProps = dispatch => ({
    openOrCloseCongratulationsDialog: (openCongratulationsDialog) => dispatch(ActionOpenCongratulationsDialog(openCongratulationsDialog)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CongratulationsModulation);