import React, { useState } from 'react';
import StringTranslate from '../assets/i18n/stringLanguage.jsx';

import {
    Button, Typography, TextField,
    DialogActions, DialogTitle, DialogContent,
    CircularProgress, Grid
} from '@mui/material';

import '../assets/css/dp-drawParcel.css';

import getTheme from '../themes/index.js';

let theme = getTheme();

/**
 * Composant représentant la popup de validation (suppression/sauvegarde) juste après avoir dessiner une parcelle
 */
function DrawParcelPopup(props) {

    const [parcelName, setParcelName] = useState(StringTranslate.parcelledess);
    const [saving, setSaving] = useState(false);

    const onSave = (event) => {
        setSaving(true);
        props.saveDrawnParcel(parcelName);
    }

    const onClose = (event) => {
        props.cancel();
    }

    return (
        <>
            <DialogTitle>
                {StringTranslate.bravotitle}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body1">{StringTranslate.bravo}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="autreTextField"
                            label={StringTranslate.nomparcelle}
                            value={parcelName}
                            disabled={(saving)}
                            variant="outlined"
                            sx={{
                                width: '100%'
                            }}
                            onChange={(event) => setParcelName(event.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions id="drawParcelPopUp">
                <Button 
                    variant="text" 
                    color="error" 
                    onClick={onClose} 
                    disabled={saving}
                >
                    {StringTranslate.annulerimport}
                </Button>
                <Button 
                    variant="contained" 
                    sx={{
                        backgroundColor : theme.palette.primary.main,
                        '&:hover':{
                            backgroundColor : theme.palette.primary.dark,
                        }
                    }}
                    disabled={saving} 
                    startIcon={(saving) && <CircularProgress color="inherit" size={24} />} 
                    onClick={onSave} 
                >
                    {StringTranslate.enregistrer}
                </Button>
            </DialogActions>
        </>
    );
}

export default DrawParcelPopup;