import React, { useState, useRef } from 'react';
import { Box, Slide, Grid } from '@mui/material';
import ZoomButtonsControl from "../../carte/ZoomButtonsControl.jsx";
import ButtonDrawParcel from './ButtonDrawParcel.jsx';
import ButtonPlaceSearch from './ButtonPlaceSearch.jsx';
import ButtonStartDraw from './ButtonStartDraw.jsx';
import ButtonBackToMenu from './ButtonBackToMenu.jsx';
import CardExplainMoveMap from './CardExplainMoveMap.jsx';
import CardExplainZoomMap from './CardExplainZoomMap.jsx';

/**
 * Composant affichage d'une zone de boutons permttant d'interagir avec la carte et effectuer le dessin d'une parcelle
 */
function SubStepDessin(props) {

    const drawParcelButtonControlCmp = useRef(null); // utilisation reférence => lien établi entre le composant drawParcelButtonControl avec le composant StartDrawButton

    const [showButtons, setShowButtons] = useState(true); // données permettant d'afficher/ou non des boutons

    return (
        <Box
            className='dfp-leaflet-control-container'
            sx={{
                width:"0px",
                backgroundColor:"transparent"
            }}
        >
            
            <div className="leaflet-top dfp-custom-leaflet-top-center">
                <Slide direction="down" in={showButtons} mountOnEnter unmountOnExit>
                    <div>
                        {/* ↓ contrôle (boutons) en lien avec la recherche de localité ↓ */}
                        <ButtonPlaceSearch {...props} />
                    </div>
                </Slide>
            </div>

            <div className="leaflet-top leaflet-right leaflet-PosNone">
                {/* ↓ contrôle (boutons) en lien avec le zoom/dézoom de la carte ↓ */}
                {<ZoomButtonsControl {...props} />}

                {/* ↓ contrôle (bouton) en lien avec le dessin d'une nouvelle parcelle ↓ */}
                {<ButtonDrawParcel {...props} ref={drawParcelButtonControlCmp} setShowButtons={setShowButtons} />}
            </div>

            <div className="leaflet-bottom dfp-custom-leaflet-bottom-center">
                <Slide direction="up" in={showButtons} mountOnEnter unmountOnExit>
                    <Grid container spacing={1} columns={{ xs: 16, sm: 16, md: 4 }} >
                        <Grid item xs={8} sm={8} md={1} sx={{width:'195px', height: '100%'}}>
                            {/* ↓ contrôle - retour à l'étape de choix ↓ */}
                            <ButtonBackToMenu {...props} />
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} sx={{width:'195px', height: '100%', display: { xs: 'none', sm: 'none', md: 'block' }}}>
                            {/* ↓ affichage - contenus informatifs permettant d'utiliser le zoom de la carte - uniquement en mode desktop ↓ */}
                            <CardExplainMoveMap />
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} sx={{width:'195px', height: '100%', display: { xs: 'none', sm: 'none', md: 'block' }}}>
                            {/* ↓ affichage - contenus informatifs permettant d'utiliser le zoom de la carte - uniquement en mode desktop ↓ */}
                            <CardExplainZoomMap />
                        </Grid>
                        <Grid item xs={8} sm={8} md={1} sx={{width:'195px', height: '100%'}}>
                            {/* ↓ contrôle - permet de lancer le dessin de parcelle ↓ */}
                            <ButtonStartDraw {...props} drawParcelButtonControlCmp={drawParcelButtonControlCmp} />
                        </Grid>
                    </Grid>
                </Slide>
            </div>

        </Box>
    )
}

export default SubStepDessin;