import React from 'react';
import { connect } from 'react-redux';
import App from "../../app.js";
import { CommProviderWithNativeApp } from '../../native/commWithNativeApp.js';
import { 
    ActionSetAccount,
    ActionSetAccessTokenAndSearchClient, 
    ActionUpdateNewAccountSocialProvider,
    StatesConnection 
} from '../actions/connection';
import { ActionUpdatePurchasedStorePlan, ActionShowErrorOfPurchaseStore, ActionStopStorePlan, ActionControlPurchasesStorePlan } from '../actions/clientUser';
import { getTokenRequest, saveUserEmailInLocalStorage, extractEmailFrom } from '../../utils/authentUtils.js';
import StoreFunctions from '../../utils/storeFunctions.js';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import sendError from '../../utils/errorService.js';


/* Composant d’ordre supérieur - décorateur du composant App - https://fr.reactjs.org/docs/higher-order-components.html */ 
//function withAuthentNativeApp(AppComponent) {

    class HOComponent extends React.Component {
        constructor(props) {
            super(props);

            this.commWithNativeApp = undefined;

            this.authRedirectCallBack = this.authRedirectCallBack.bind(this);
            this.callBackActions = this.callBackActions.bind(this);
            
            if (props && props.userAgentAuthent) {
                //Mise en place de la liaison / communication avec l'application native:
                this.commWithNativeApp = new CommProviderWithNativeApp(props.userAgentAuthent, this.callBackActions);

                //Enregistrement callback => peut être appelée et peut lancer des actions dans la foulée
                props.userAgentAuthent.handleRedirectCallback( this.authRedirectCallBack );
                
                // fournis l'objet de comm à l'agent d'authentification:
                props.userAgentAuthent.commProviderWithNativeApp = this.commWithNativeApp;
            } else {
                //alert('withAuthentNativeApp - ctor : props.userAgentAuthent is null !!!');
                //Trace Azure:
                sendError('NativeApp - WANA.ctor', { "alert": 'props.userAgentAuthent is null !!!', });
            }
        }

        /* méthode de cycle de vie */
        componentDidMount() {
            /*//Pour test / débogage:
            //alert(`withAuthentNativeApp - componentDidMount`);
            if (this.commWithNativeApp) 
                this.commWithNativeApp.sendAlertToNativeApp(`withAuthentNativeApp - componentDidMount`);
            */
            
            const { userAgentAuthent } = this.props; // l'initialisation de l'objet gérant l'authentification (via l'appli native et la Web API) se fait dans le fichier index.js

            //Fonctionnel en lien avec l'application native : Si elle n'est pas là, cela pose problème ! 
            if ((!window.ReactNativeWebView) || (!userAgentAuthent)) {
                //alert(`${StringTranslate.commNativeWithoutWebView}`); 

                //Trace Azure:
                sendError('NativeApp - WANA.componentDidMount', { "alert": `${StringTranslate.commNativeWithoutWebView}`, });
            }

            /*
            //Mise en place de la liaison / communication avec l'application native:
            this.commWithNativeApp = new CommProviderWithNativeApp(userAgentAuthent);

        	//Enregistrement callback => peut être appelée et peut lancer des actions dans la foulée
            userAgentAuthent.handleRedirectCallback( this.authRedirectCallBack );
            
            // fournis l'objet de comm à l'agent d'authentification:
            userAgentAuthent.commProviderWithNativeApp = this.commWithNativeApp;
            */

            //En principe, lorsque l'appli web sera totalement chargée dans la webView de l'appli native, cette dernière devrait émettre le message 
            // avec l'info de sa version....(lançant ainsi l'initialisation de l'authentification).
            // Si ce n'est pas le cas, il faudra lui demander...après un certain temps d'attente !
            setTimeout(() => {
                //Il faut tester que l'on n'a pas déjà lancer la procèdure ! (pour éviter de le faire deux fois):
                if (this.props.stateConnexion !== StatesConnection.connected) { 
                    //On lance la procédure d'authentification:
                    let tokenRequest = getTokenRequest(userAgentAuthent); // obtention des données de requête (scopes, autorité, loginHint, ...
                    userAgentAuthent.loginRedirect(tokenRequest);
                } //else //on est déjà ok !
            }, 30000); //1min.

            //Si on a reçu les infos de version de l'appli native, on lance tout de suite l'initialisation de l'authent:
            if (window.datasToInit) { 
                userAgentAuthent.initAuthentProcess(window.datasToInit); //contient au minimum
            }
        }

        componentDidUpdate(prevProps, prevState) {
            const { stateConnexion, emailOfClient, 
                userAgentAuthent, needUpdateToken, clientIsNewer } = this.props;

            // Cas de l'authentification enfin validée:
            if (prevProps && (prevProps.stateConnexion !== stateConnexion) && (stateConnexion === StatesConnection.connected) &&
                emailOfClient && (emailOfClient !== '')) {
                if (window.dataLayer) {
                    window.dataLayer.push({ 'userId': emailOfClient });
                }
            }

            // Cas de la détection de la nécessité d'actualiser le jeton d'authent (normalement, pas plus d'une fois par jour):            
            if (prevProps && (prevProps.needUpdateToken !== needUpdateToken) && (needUpdateToken === true)) {
                // on approche la fin de validité du jeton...
                let tokenRequest = getTokenRequest(userAgentAuthent); // obtention des données de requête (scopes, autorité, loginHint, ...
                userAgentAuthent.loginRedirect(tokenRequest);
            }

            // Cas: TRACKING - détection d'authentification d'un nouveau client
            if (prevProps && (prevProps.clientIsNewer !== clientIsNewer) && (clientIsNewer === true)) {
                if (window.dataLayer) // google tag manager
                    window.dataLayer.push({'event': 'nouveauClient', 'action': 'premiereConnexion'});
                if (window.fbq) // facebook pixel
                    window.fbq('track', 'CompleteRegistration', { 
                        value: 0.0,
                        currency: 'EUR',
                        content_name: emailOfClient,
                        status: 'complete'
                });
            }
        }

        /* méthode de callback utilisée par msal.js lors d'une redirection - msal.js */
        authRedirectCallBack = (error, response) => {
            // ↓↓ dans le cas d'une réponse POSITIVE ↓↓
            const { userAgentAuthent, actionSetAccount, actionSetAccessTokenAndSearchClient } = this.props;
            if ( response ) {
            
                const account = userAgentAuthent.getAccount(); // a-t-on un compte client ? 
                if (account) { // on a un compte - l'utilisateur est authentifié à ce stade
                    actionSetAccount(account); // transmis à redux
                    saveUserEmailInLocalStorage(account); // enregistrement email (si email+datas actuels différents du nouvel email => suppression)

                    /* //N'arrivera pas car l'authent via les données de l'appli native et via l'authent ROPC (comme la liaison Géofolia) ne retourne que le type 'access_token' !
                    if (response.tokenType === 'id_token') { // - SOIT réponse de type id_token
                            actionSetIdToken(response.idToken); // transmis à redux
                            let tokenRequest = getTokenRequest(userAgentAuthent); // obtention des données de requête (scopes, autorité, loginHint, ...)
                            
                            if (window.ReactNativeWebView) { // @@ actuellement le récupération d'1 access_token via redirection ne fonctionne pas dans une webview - react native
                                userAgentAuthent.acquireTokenSilent(tokenRequest)
                                    .then((response) => this.authRedirectCallBack(null, response))
                                    .catch((error) => this.authRedirectCallBack(error, null));
                            }
                            else {
                                userAgentAuthent.acquireTokenRedirect(tokenRequest);
                            }
                    }
                    
                    else*/ if (response.tokenType === 'access_token') { // - SOIT réponse de type access_token
                            if (response.accessToken) {
                                actionSetAccessTokenAndSearchClient(response); // transmis à redux
                            }
                            else { // si le jeton n'est pas là, c'est étrange. On relance l'authent après avoir supprimer la donnée majeure !
                                userAgentAuthent.datasOfToken = undefined;

                                let tokenRequest = getTokenRequest(userAgentAuthent); // obtention des données de requête (scopes, autorité, loginHint, ...)
                                userAgentAuthent.loginRedirect(tokenRequest); // si pas de compte alors on redirige vers la page b2cLogin pour récupératon d'un token
                            }
                    }
                } else { // pas de compte, c'est étrange. On relance l'authent après avoir supprimer la donnée majeure !
                    userAgentAuthent.datasOfToken = undefined;

                    let tokenRequest = getTokenRequest(userAgentAuthent); // obtention des données de requête (scopes, autorité, loginHint, ...)
                    userAgentAuthent.loginRedirect(tokenRequest); // si pas de compte alors on redirige vers la page b2cLogin pour récupératon d'un token
                }
            }            
            
            // ↓↓ dans le cas d'une réponse NEGATIVE (annulation, ...) ↓↓
            if ( error ) {
                //Trace Azure:
                sendError('authRedirectCallBack', error);
                
                StoreFunctions.clearAllUserDatas(); // suppression données ancien email client 

                //RQ: La transmission de l'erreur d'authent au natif, va faire que l'appli native va représenter la page d'authent...
                // Si ce n'est pas le cas, il faudra lui demander...après un certain temps d'attente !
                setTimeout(() => {
                    //Il faut tester que l'on n'a pas déjà lancer la procèdure ! (pour éviter de le faire deux fois):
                    if (this.props.stateConnexion !== StatesConnection.connected) {
                        //On lance la procédure d'authentification:
                        let tokenRequest = getTokenRequest(userAgentAuthent); // obtention des données de requête (scopes, autorité, loginHint, ...
                        userAgentAuthent.loginRedirect(tokenRequest);
                    } //else //on est déjà ok !
                }, 60000); //1min.
            }
        }

        //méthode permettant à l'application Web de déclencher des actions Redux, en réponse à des messages de la part de l'application Web) :
        callBackActions(messageOfNativeApp) {
            if ((!messageOfNativeApp) || (messageOfNativeApp.type === undefined) || (messageOfNativeApp.type <= 0)) return;

            switch (messageOfNativeApp.type) {
                case 7: { //mt7ReturnPurchaseResult: l'application native nous donne un retour sur l'achat (sur le store du mobile) qui a été déclenché 
                    if (messageOfNativeApp.datas) {
                        if (messageOfNativeApp.datas.errorInfos) { //on a reçu un échec ! On dispose de 'code', 'message', 'productId !
                            sendError('callBackActions - errorInfos', messageOfNativeApp.datas.errorInfos);

                            // on signale l'erreur liée à l'achat:
                            let errorInfoCode = '-1';
                            try {
                                errorInfoCode = (typeof(messageOfNativeApp.datas.errorInfos) === 'string') ? 
                                    messageOfNativeApp.datas.errorInfos : messageOfNativeApp.datas.errorInfos.code;
                            }
                            catch(errCodeInfo) {
                                errorInfoCode = '-2';
                            }
                            this.props.actionShowErrorOfPurchaseStore(
                                `${StringTranslate.formatString(StringTranslate.errorPurchaseMessageFromNativeApp, errorInfoCode)}`);
                        } else if (messageOfNativeApp.datas.purchaseInfos) { //on a reçu en retour positif de l'achat In-App !
                            // on demande le dévérouillage des accès associé à l'achat:
                            //RQ: pour anticiper les changements dû à la prochaine version de l'app mobile, il faut vérifier la date de transaction:
                            const purchaseDatas = messageOfNativeApp.datas.purchaseInfos;
                            if ((purchaseDatas.transactionDate) && (typeof (purchaseDatas.transactionDate) !== 'string') && 
                                (messageOfNativeApp.datas.transacDateString)) {
                                purchaseDatas.transactionDate = messageOfNativeApp.datas.transacDateString;   
                                }
                            this.props.actionUpdatePurchasedStorePlan(purchaseDatas, messageOfNativeApp.datas.platformOS);
                        }
                        else {
                            // on signale le manque d'infos sur l'issue de l'achat:
                            this.props.actionShowErrorOfPurchaseStore(`${StringTranslate.purchaseResultWithoutInfosFromNativeApp}`);
                        }
                    } else { //pas normal !
                        sendError('callBackActions - PurchaseResult', 'no-datas !');

                        // on signale le manque d'infos sur l'issue de l'achat:
                        this.props.actionShowErrorOfPurchaseStore(`${StringTranslate.purchaseResultWithoutInfosFromNativeApp}`);
                    }
                    break;
                }

                case 8: { //mt8StopPlanStore: l'application native nous signale l'arrêt d'un plan du store (ou l'erreur d'achat depuis le store)
                    if (messageOfNativeApp.datas) {
                        const purchaseDatas = messageOfNativeApp.datas.purchaseInfos;
                        if (purchaseDatas) {
                            this.props.actionStopStorePlan(purchaseDatas, messageOfNativeApp.datas.platformOS);
                        } else { //pas normal !
                            sendError('callBackActions - StopPlanStore', 'datas-void !');

                            //tampis !
                        }
                    } else { //pas normal !
                        sendError('callBackActions - StopPlanStore', 'no-datas !');

                        //tampis !
                    }
                    break;
                }

                case 9: { //mt9: l'application native nous demande de vérifier la cohérence entre les soucriptions des Stores Mobile et la typo client actuelle:
                    if (messageOfNativeApp.datas) {
                        const purchaseDatasList = messageOfNativeApp.datas.purchaseInfosList;
                        if (purchaseDatasList) {
                            this.props.actionCtrlPurchasesStorePlan(purchaseDatasList, messageOfNativeApp.datas.platformOS); 
                        } else { //pas normal !
                            sendError('callBackActions - ControlPlanStore', 'datas-void !');

                            //tampis !
                        }
                    } else { //pas normal !
                        sendError('callBackActions - ControlPlanStore', 'no-datas !');

                        //tampis !
                    }
                    break;
                }

                default: break;
            }
        }

        render() {
            //return <AppComponent {...this.props} />;
            return <App {...this.props} />;
        }
    };

    /* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
    const mapStateToProps = state => ({
        idToken: state.connectionData.idToken,
        accessToken: state.connectionData.accessToken,
        stateConnexion: state.connectionData.stateConnexion,
        //Pour être avertis de la nécessité d'actualiser le jeton d'authent:
        //expiration: (state.connectionData.expiration) ? state.connectionData.expiration: 1, 
        needUpdateToken: (state.connectionData.expiration) ? state.connectionData.needUpdateToken : undefined, 

        clientIsNewer: (state.clientUserData && state.clientUserData.clientIsNewer) ? state.clientUserData.clientIsNewer : false, 
                
        clientId: (state.clientUserData) ? state.clientUserData.clientDatas.id : -1,
        //TODO: A remplacer par 'state.connectionData.emailOfAccount' lorsqu'il sera en place (appel à set-account-email)
        emailOfClient: (state.connectionData) ? extractEmailFrom(state.connectionData.account) : '', 

        showSocialAccountSignupForm: state.contextAppData.showSocialAccountSignupForm,
    })

    /* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
    const mapDispatchToProps = dispatch => ({
        actionSetAccount: account => dispatch( ActionSetAccount(account) ),
        actionSetAccessTokenAndSearchClient: authent => dispatch( ActionSetAccessTokenAndSearchClient(authent) ),
        actionUpdateNewAccountSocialProvider: (phone, countryStr) => dispatch( ActionUpdateNewAccountSocialProvider(phone, countryStr) ),
        actionUpdatePurchasedStorePlan: (purchaseDatas, platformOS) => dispatch( ActionUpdatePurchasedStorePlan(purchaseDatas, platformOS) ),
        actionShowErrorOfPurchaseStore: errorDatas => dispatch( ActionShowErrorOfPurchaseStore(errorDatas) ),
        actionStopStorePlan: (purchaseDatas, platformOS) => dispatch( ActionStopStorePlan(purchaseDatas, platformOS) ),
        actionCtrlPurchasesStorePlan: (purchaseDatasList, platformOS) => dispatch(ActionControlPurchasesStorePlan(purchaseDatasList, platformOS) ),
    })

    /*return connect( mapStateToProps, mapDispatchToProps )(HOComponent);
}

export default withAuthentNativeApp;*/
export default connect( mapStateToProps, mapDispatchToProps )(HOComponent);