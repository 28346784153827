import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import getTheme from '../../../themes/index';

let theme = getTheme();


function IconeObservationAdventice(props: SvgIconProps) {
    return ( 
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" strokeWidth="1.8" stroke="none" fill="current">
              <g>
                <path d="M7,18.56c-.24-.35-.9-1.37-1.06-1.67a11.27,11.27,0,1,1,21.29-5.15A11.18,11.18,0,0,1,26,16.89c-.15.3-.82,1.32-1.06,1.69L16,31.17Z" fill={theme.palette.primary.main}/>
                <path d="M16,1a10.78,10.78,0,0,1,9.58,15.68c-.12.24-.68,1.1-1,1.61L16,30.3l-8.55-12c-.36-.54-.91-1.4-1-1.64A10.78,10.78,0,0,1,16,1m0-1A11.78,11.78,0,0,0,5.52,17.12c.21.39,1.1,1.73,1.1,1.73L16,32l9.37-13.18s.89-1.34,1.1-1.73A11.78,11.78,0,0,0,16,0Z" fill={theme.palette.primary.main}/>
              </g>
              <g>
                <line x1="13.15" y1="3.83" x2="14.36" y2="5.89" fill="none" stroke={theme.palette.common.white} strokeMiterlimit="10" strokeWidth="0.2359296000000001px"/>
                <line x1="12.68" y1="4.76" x2="14.09" y2="6.33" fill="none" stroke={theme.palette.common.white} strokeMiterlimit="10" strokeWidth="0.2359296000000001px"/>
                <line x1="14.02" y1="3.29" x2="14.68" y2="5.53" fill="none" stroke={theme.palette.common.white} strokeMiterlimit="10" strokeWidth="0.2359296000000001px"/>
                <line x1="14.83" y1="3.17" x2="15.1" y2="5.61" fill="none" stroke={theme.palette.common.white} strokeMiterlimit="10" strokeWidth="0.2359296000000001px"/>
                <line x1="15.79" y1="3.33" x2="15.69" y2="5.67" fill="none" stroke={theme.palette.common.white} strokeMiterlimit="10" strokeWidth="0.2359296000000001px"/>
                <line x1="16.8" y1="3.69" x2="16.07" y2="5.97" fill="none" stroke={theme.palette.common.white} strokeMiterlimit="10" strokeWidth="0.2359296000000001px"/>
                <line x1="17.65" y1="4.42" x2="15.91" y2="6.49" fill="none" stroke={theme.palette.common.white} strokeMiterlimit="10" strokeWidth="0.2359296000000001px"/>
                <path d="M13.06,8A2.51,2.51,0,0,0,16,10.49a2.61,2.61,0,0,0,2.42-2.6C18.27,6.7,16.94,4.74,15.3,5A2.73,2.73,0,0,0,13.06,8Z" fill={theme.palette.common.white}/>
                <g>
                  <path d="M13.18,12.85A10.86,10.86,0,0,0,10,10.74a.77.77,0,0,1,.19.9c2.34.06,1.82,2.08,1.82,2.08a4.36,4.36,0,0,1,3.17,1.65A17.73,17.73,0,0,0,13.18,12.85Z" fill={theme.palette.common.white}/>
                  <path d="M13.35,12.69a18.08,18.08,0,0,1,2.17,2.84,2.66,2.66,0,0,1,0-2.13c-2-.15-1.57-1.78-1.57-1.78-2.16.38-2.06-1.45-2.06-1.45a1.35,1.35,0,0,1-1.43.56A10.55,10.55,0,0,1,13.35,12.69Z" fill={theme.palette.common.white}/>
                </g>
                <g>
                  <path d="M23.08,10.59a13.85,13.85,0,0,0-3.24,1.55,10.13,10.13,0,0,0-2.42,2.56,4,4,0,0,1,1.25-.9A4.86,4.86,0,0,1,21,13.36s-.69-2.16,1.85-1.92A1,1,0,0,1,23.08,10.59Z" fill={theme.palette.common.white}/>
                  <path d="M17.29,14.46A10,10,0,0,1,19.71,12a13.77,13.77,0,0,1,3.1-1.52,1.59,1.59,0,0,1-1.48-.79,1.48,1.48,0,0,1-2.16,1.11,1.64,1.64,0,0,1-2,1.58A4.78,4.78,0,0,1,17.29,14.46Z" fill={theme.palette.common.white}/>
                </g>
                <path d="M16.09,17a14.54,14.54,0,0,0,.14-6.68" fill="none" stroke={theme.palette.common.white} strokeMiterlimit="10" strokeWidth="0.7077888000000004px"/>
              </g>
            </svg>
        </SvgIcon>
    );
}

export default IconeObservationAdventice;