import * as React from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

/* MUI Components */
import { Box, Stepper, Step, StepLabel } from '@mui/material';

/* React Components */
import CommonInfosManagement from './nitrogenStepsManagement/commonInfosManagement.jsx';
import SpecificInfosManagement from './nitrogenStepsManagement/specificInfosManagement.jsx';
import CultureAndParcelsChoice from './nitrogenStepsManagement/cultureAndParcelsChoice.jsx';
import NitrogenResult from './nitrogenStepsManagement/nitrogenResult.jsx';

/* Redux */
import { FertilizerStep, } from '../../redux/actions/fertilizer';

/* Traduction */
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import DataGridSkeleton from '../dataGridSkeleton.jsx';

function NitrogenStepsManagement(props) {
    /* Les différentes étapes */
    const steps = [StringTranslate.parcellesListBtnOnSidebar, StringTranslate.entryGrouped, StringTranslate.nitrogenManagementShort];
    /* Step 0 : Parcelles
       Step 1 : Saisie groupée
       Step 2 : pilotage (saisie unique) */
    const getStep = () => {
        
        switch (props.fertilizerStep) {

            case FertilizerStep.CULTURE_AND_PARCELS_CHOICE: return 0;
            case FertilizerStep.COMMON_INFOS_MANAGEMENT: return 1;
            case FertilizerStep.MANAGEMENT_NITROGEN: return 2;
            case FertilizerStep.RESULT: return 3;
            default: return 0;

        }

    };
    

    return (
        (props.loading === true) ?
                <DataGridSkeleton/> //On rajoute un DataGridSkeleton pour être sûr d'avoir les données de conseil avant d'afficher quelque chose au client
            : 
                <Box sx={{ width: '100%' }}>
                    {((props.fertilizerStep !== FertilizerStep.RESULT)) && 
                        <Stepper 
                            activeStep={getStep()} 
                            alternativeLabel={window.innerWidth < 456}>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>}

                    <Box sx={{mt: 3}}>
                        {(props.fertilizerStep === FertilizerStep.CULTURE_AND_PARCELS_CHOICE) && <CultureAndParcelsChoice />}
                        {(props.fertilizerStep === FertilizerStep.COMMON_INFOS_MANAGEMENT) && <CommonInfosManagement />}
                        {(props.fertilizerStep === FertilizerStep.MANAGEMENT_NITROGEN) && (props.showFertilizerMap === false) && <SpecificInfosManagement />}
                        {(props.fertilizerStep === FertilizerStep.RESULT) && <NitrogenResult />}
                        {/* On ne déclare pas 'NitrogenMap' ici, car pour afficher la carto, on le fait en dehors de l'assistant ! */}
                    </Box>

                </Box>  
    );
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    //Infos provenant du reducer 'fertilizerData':
    fertilizerStep: lodashGet(state, 'fertilizerData.fertilizerStep', FertilizerStep.CULTURE_AND_PARCELS_CHOICE),
    showFertilizerMap: lodashGet(state, 'fertilizerData.showFertilizerMap', false),
	loading: lodashGet(state, 'fertilizerData.loading', false),

    //Infos provenant du reducer 'settingsData':
    language: lodashGet(state, 'settingsData.settings.language', StringTranslate.getLanguage()),
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    //RAS
})

export default connect(mapStateToProps, mapDispatchToProps)(NitrogenStepsManagement);
