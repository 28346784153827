import React from "react";
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';

/* Composants MUI */
import {
    Grid, Typography, Box, TableContainer, TextField,
    InputAdornment, IconButton, CircularProgress, Button, Dialog,
    DialogTitle, DialogContent, DialogContentText, DialogActions, 
    FormControlLabel, Switch
} from '@mui/material';
import DataGridSkeleton from '../dataGridSkeleton.jsx';

/* Composants React */
import CustomDataGrid from "../customDataGrid";
import LinkToContactUs from '../linkToContactUs';
import VarietyForm from './varietyForm.jsx';
import AlertDialog from "../alertDialog.jsx";

import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

/* Icones */
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { Add } from "@mui/icons-material";
import ErrorOutline from '@mui/icons-material/ErrorOutline';

/* Helpers et Dico */
import stringHelper from "../../utils/stringHelper.js";

/* Redux */
import { TableType, UpdateNbRowsPerPageTable } from '../../redux/actions/settings';
import { ProfilIndex, ActionShowProfilMenuDialog } from "../../redux/actions/contextApp";
import { ActionDeleteVarieties, CornType, ActionUpdateVariety, ActionOpenPopupVarietyForm, ActionClearHarvestMessageError } from "../../redux/actions/harvest";

/* css */
import '../../assets/css/renderParcels.css';
import '../../assets/css/pdp-pinDropPopup.css';

/* theme Berry */
// import getTheme from "../../themes/index.js";

// let theme = getTheme();

class HarvestList extends React.Component {
    constructor(props) {
        super(props);

        this.nbInfosMax = 0; //Nombre d'info max de variété au total

        const errorHarvest = lodashGet(props, 'errorHarvest', undefined);

        this.state = {
            searchFilter: '', //Zone de recherche du tableau
            currentRowsOfTable: [], //Datas en fonction du texte tapé dans la zone de recherche

            displayAllVarieties: false, //Par défaut, on affiche uniquement les variétés du client et celles qu'il a choisit dans le référentiel
            datas: this.initialiseDatas(true, false), //variétés. True pour dire qu'il faut trier le tableau à l'arrivée
            varietiesSelected: [], //Correspond aux varietyIds des variétés sélectionnées

            //sauvegarde la langue en cours lors de la création du composant: (Car pas présente dans le store Redux)
            language: props.language,
            openConfirmDeleteVarietiesDialog: false, //Permet d'afficher ou désafficher la dialog de suppression.

            varietyId: 0,
            label: null,
            manufacturer: null,
            harvestType: CornType.None,
            flowering: null,

            percentageHarvestTarget: '',
            sumDegreeDayHarvestTarget: '',
            varietyInfos: [],

            openDialogOfErrors: (errorHarvest !== undefined),
        };

        // liste des noms de colonnes affichées
        this.columns = this.initialiseColumns(this.nbInfosMax); //RQ: pas dans le 'state' car on en utilise des propriétés !

        this.popupErrorDialog = {
			getTitle: () => { return (<ErrorOutline />); },
			description: errorHarvest, //Rq : sera mis à jour en fonction de la prop 'errorHarvest' !
			getAdditionalDescription: () => {
				return (
                    <>
                        <Typography variant="subtitle1">
                            {StringTranslate.errorHarvestUsedVariety}
                        </Typography>
                        <Typography variant="subtitle1">
                            {StringTranslate.errorDialogText2} <LinkToContactUs displayText={StringTranslate.contactUs} />. 
                        </Typography>
                    </>

				);
			},
			button: StringTranslate.close,
		};
    }

    componentDidUpdate(prevProps, prevState) {
        const { language, displayAllVarieties } = this.state;
        const { harvestVarietyCharacteristicsDico, 
            harvestDico, addingVariety, errorHarvest } = this.props;


        //gestion de cas d'erreur:
        if (((!prevProps) || (!prevProps.errorHarvest) || (prevProps.errorHarvest === '') || (prevProps.errorHarvest === undefined)) &&
        (errorHarvest && (errorHarvest !== '') && (errorHarvest !== undefined))) {
            this.popupErrorDialog.description = errorHarvest;

            this.setState({
                openDialogOfErrors: true,
            });
        }

        /* Lorsqu'il y a un changement de langue (même si on se doute que l'utilisateur ne changera pas de langue tous les 4 matins !), il faut actualiser certaines listes: */
        const currentLanguage = StringTranslate.getLanguage();
        if ((!language) || (language === '') || (language !== currentLanguage)) {

            // liste des noms de colonnes affichées. 
            this.columns = this.initialiseColumns(this.nbInfosMax);
            this.setState({ language: currentLanguage, });
        }

        if ((harvestVarietyCharacteristicsDico !== prevProps.harvestVarietyCharacteristicsDico) || 
            (harvestDico !== prevProps.harvestDico) || 
            ((prevProps.addingVariety === true) && (addingVariety === false)) ||
            (displayAllVarieties !== prevState.displayAllVarieties)) {
            const newDatas = this.initialiseDatas(true, displayAllVarieties);
            this.columns = this.initialiseColumns(this.nbInfosMax);
            this.setState({
                datas: newDatas,
            });
        }
    }

    //Toolbar customisé du tableau comprenant :
    // - la zone de recherche
    // - Bouton de suppression de récolte dans le tableau
    // - Bouton d'aide
    CustomToolbar = (props) => {
        const { loadingVarietiesCharacteristics, deletingVarieties, showProfilMenuDialog } = this.props;
        const { varietiesSelected, displayAllVarieties } = this.state;

        return (
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ ml: 1 }}>
                    <Grid container spacing={2}>
                        {/* Zone de recherche */}
                        <Grid item xs={8} sm={8} md={4} lg={4}>
                            {/* Zone de recherche */}
                            <TextField
                                value={props.value}
                                onChange={props.onChange}
                                placeholder={StringTranslate.toolbarsearch}
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>),
                                    endAdornment: (
                                        <IconButton
                                            title="Clear"
                                            aria-label="Clear"
                                            size="small"
                                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                                            onClick={props.clearSearch}
                                        >
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Grid>

                        {/* Bouton de suppression */}
                        <Grid item xs={4} sm={4} md={8}>
                            <Box sx={{ display: { md: 'none', xs: 'block' }, '& button': { m: 1 }, pt: 1 }}
                                style={{ textAlign: 'end' }} >
                                <IconButton color="error" size="large" aria-label="delete variety(ies)" component="span"
                                    onClick={(evt, data) => props.deleteVarieties(data)}
                                    disabled={((loadingVarietiesCharacteristics === true) || (deletingVarieties === true) || (varietiesSelected.length <= 0)) ? true : false}
                                    sx={{ p: 0 }}
                                >
                                    {((loadingVarietiesCharacteristics === true) || (deletingVarieties === true)) ? <CircularProgress color="inherit" size={20} /> : <DeleteForeverSharpIcon />}
                                </IconButton>
                            </Box>

                            <Box sx={{ display: { md: 'block', xs: 'none' }, '& button': { m: 1 } }} style={{ textAlign: 'end' }} >
                                <Button
                                    variant="text"
                                    color="error"
                                    onClick={(evt, data) => props.deleteVarieties(data)}
                                    startIcon={((loadingVarietiesCharacteristics === true) || (deletingVarieties === true)) ? <CircularProgress color="inherit" size={20} /> : null}
                                    disabled={((loadingVarietiesCharacteristics === true) || (deletingVarieties === true) || (varietiesSelected.length <= 0)) ? true : false}
                                >
                                    {StringTranslate.deleteVarieties}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {/*Bouton d'ajout de variété */}
                <Grid item xs={12}>
                    <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={() => this.handleAddClick()}
                    >
                        {StringTranslate.addVariety}
                    </Button>
                </Grid>
                {/*Switch pour afficher toutes les variétés du client et du référentiel */}
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                color="primary"
                                checked={displayAllVarieties}
                                onChange={(evt, value) => this.handleDisplayAllVarieties(evt, value)}
                                inputProps={{ 'aria-label': 'primary checkbox' }} />
                        }
                        label={StringTranslate.showAllVarieties}
                        labelPlacement="end"
                    />
                </Grid>
                {/* Bouton d'aide */}
                <Grid item xs={12}>
                    <Button color="secondary" variant="text" size="small" onClick={() => showProfilMenuDialog(ProfilIndex.aide_HarvestCorn)}>
                        {StringTranslate.helpAsk}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    /* Fonction qui permet d'afficher les variétés du client ou les variétés du référentiel */
    handleDisplayAllVarieties(event, value) {
        this.setState({
            displayAllVarieties: value
        });
    }

    setSelectionVarieties = (newSelectionVarieties) => {
        this.setState({
            varietiesSelected: newSelectionVarieties,
        });
    }

    /* Fonction correspondant à la zone de recherche */
    requestSearch(searchValue, rowsOfTable) {
        this.setState({
            searchFilter: searchValue
        });

        try {
            const searchRegex = new RegExp(stringHelper.escapeRegExp(searchValue), 'i');
            const filteredRows = rowsOfTable.filter((row) => {
                return Object.keys(row).some((field) => {
                    let textValue = "" + row[field];
                    return searchRegex.test(textValue.toString());
                });
            });

            this.setState({
                currentRowsOfTable: filteredRows
            });
        }
        catch (errRegex) { /* Peut arriver si on saisis un truc du genre 'EARL++' ! (il n'aime pas les '++') */ }
    }

    /**
     * Initialisation des colonnes de parcelles.
     * Il y a une traduction sur les listes fixes de cultures, exploitations et variétés
     */
    initialiseColumns() {

        let newColumns = [];

        /* ↓ ajout colonne visible - (modification des données) ↓ */
        newColumns.push({
            field: 'actions',
            editable: false,
            hideable: false,
            headerName: '',
            width: 60,
            renderCell: params => {
                return (
                    (params.value.clientId !== null) ?
                        <IconButton onClick={() => this.handleEditClick(params.value)}>
                            <EditIcon color="inherit" />
                        </IconButton> : <></>
                )
            }
        });

        /* ↓ ajout colonne visible - (libelle de la variété) ↓ */
        newColumns.push({
            headerName: `Variete`,
            field: "label",
            minWidth: 120,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.harvestVariety}</Typography>)
            },
            renderCell: params => {
                return (<Typography>{params.value}</Typography>)
            }
        });

        /* ↓ ajout colonne visible - (fabriquant) ↓ */
        newColumns.push({
            headerName: `Fabriquant`,
            field: "manufacturer",
            minWidth: 110,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.manufacturer}</Typography>)
            },
            renderCell: params => {
                return (<Typography>{params.value}</Typography>)
            }
        });

        /* ↓ ajout colonne visible - (grain) ↓ */
        newColumns.push({
            headerName: `Grain`,
            field: "isGrain",
            minWidth: 40,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.grain}</Typography>)
            },
            renderCell: params => {
                return ((params.value === true) ? <CheckIcon color="success" /> : <></>)
            }
        });

        /* ↓ ajout colonne visible - (ensilage) ↓ */
        newColumns.push({
            headerName: `Ensilage`,
            field: "isEnsilage",
            minWidth: 40,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.ensilage}</Typography>)
            },
            renderCell: params => {
                return ((params.value === true) ? <CheckIcon color="success" /> : <></>)
            }
        });

        /* ↓ ajout colonne visible - (flowering) ↓ */
        newColumns.push({
            headerName: `flowering`,
            field: "flowering",
            minWidth: 110,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.degreeDaysToFlowering}</Typography>)
            },
            renderCell: params => {
                return (<Typography>{params.value}</Typography>)
            }
        });

            for (let i = 0; i < this.nbInfosMax; i++) {
                newColumns.push({
                    headerName: `value ${i}`,
                    field: `varietyInfos[${i}]`,
                    minWidth: 160,
                    hideable: false,
                    renderHeader: (params) => {
                        return (<Typography fontWeight='bold' >{StringTranslate.objective}{i + 1}</Typography>)
                    },
                    renderCell: params => {
                        return (
                            <Typography>
                                {(lodashGet(params, `row.varietyInfos[${i}].harvestType`, undefined) === undefined) ?
                                    "" :
                                    ((params.row.varietyInfos[i].harvestType === CornType.Ensilage) ? `${params.row.varietyInfos[i].percentageHarvestTarget}% ${StringTranslate.dryMaterialInitials} (${params.row.varietyInfos[i].sumDegreeDayHarvestTarget}${StringTranslate.degreeDays})` : `${params.row.varietyInfos[i].percentageHarvestTarget}% H (${params.row.varietyInfos[i].sumDegreeDayHarvestTarget}${StringTranslate.degreeDays})`)
                                }
                            </Typography>)
                    }
                }
                );
            }

        return newColumns;
    }

    /* fonction permettant d'extraire les données qui seront utilisées dans le visuel */
    initialiseDatas(askToSortData = false, displayAllVarieties = false) {
        const { harvestVarietyCharacteristicsDico, harvestVarietyCharacteristicsDicoCounter, harvestDico, harvestDicoCounter } = this.props;
        
        let harvestVarietiesCharacteristics = [];
        let harvests = [];

        if (displayAllVarieties === true) {
            // On veut afficher toutes les variétés
            harvestVarietiesCharacteristics = Object.values(harvestVarietyCharacteristicsDico);
        }
        else {
            // On veut afficher les variétés personnalisées du client et celles qu'il a utilisé dans l'appli mais pas personnalisées
            harvestVarietiesCharacteristics = Object.values(harvestVarietyCharacteristicsDico).filter((value) => value.clientId > 0);
            
            if ((harvestVarietyCharacteristicsDicoCounter > 0) && harvestDicoCounter > 0) {
                harvests = Object.values(harvestDico).filter((value) => value.harvestVarietyId > 0);

                for (const harvest of harvests) {
                    if (harvest.harvestVarietyId > 0) {
                        if (harvestVarietiesCharacteristics.find((value) => value.id === harvest.harvestVarietyId)) {
                            //on ne fait rien car la variété est déjà dans la liste à afficher
                        }
                        else {
                            harvestVarietiesCharacteristics.push(harvestVarietyCharacteristicsDico[harvest.harvestVarietyId]);
                        }
                    }
                }
            }
        }

        let newDatas = [];

        for (const variety of harvestVarietiesCharacteristics) {

            let varietyInfos = lodashGet(variety, "varietyInfos", {});

            let varietyInfosLength = 0;

            let isGrain = false;
            let isEnsilage = false;

            if (varietyInfos !== null) {
                varietyInfosLength = varietyInfos.length;

                if (varietyInfos.find(info => info.harvestType === CornType.Ensilage)) {
                    isEnsilage = true;
                }
    
                if (varietyInfos.find(info => info.harvestType === CornType.Grain)) {
                    isGrain = true;
                }
            }

            newDatas.push({
                id: lodashGet(variety, "id", -1),
                clientId: lodashGet(variety, "clientId", -1),
                actions: variety,
                //varietyId: lodashGet(varietyInfos, "varietyId", -1),
                label: lodashGet(variety, "label", undefined),
                manufacturer: lodashGet(variety, "manufacturer", undefined),
                varietyInfos: varietyInfos,
                flowering: lodashGet(variety, "flowering", undefined),
                isGrain: isGrain,
                isEnsilage: isEnsilage
            });

            if (this.nbInfosMax < varietyInfosLength) {
                this.nbInfosMax = varietyInfosLength;
            }
        }

        if ((askToSortData === true) && (newDatas.length > 1)) {
            newDatas = this.sortVarietiesByLabel(newDatas);
        }

        return newDatas;
    }

    /* fonction permettant d'ajouter une variété personnalisée du client */
    handleAddClick = () => {
        this.setState({
            variety: [],

            varietyId: 0,
            label: null,
            manufacturer: null,
            flowering: null,
            varietyInfos: [],
            action: "add"
        })

        this.props.openPopupVarietyForm(true);
    }

    /* fonction permettant de mettre à jour les données de la variété personnalisée du client */
    handleEditClick(variety) {
        this.setState({
            variety: variety,

            varietyId: variety.id,
            label: variety.label,
            manufacturer: variety.manufacturer,
            flowering: variety.flowering,
            varietyInfos: [...variety.varietyInfos],
            action: "update"

        });

        this.props.openPopupVarietyForm(true);
    }

    sortVarietiesByLabel = (datas) => {
        return [...datas].sort((a, b) => a.label > b.label ? 1 : -1);
    }

    //Désaffichage de la dialog de suppression
    handleCloseConfirmDialog = () => {
        this.setState({
            openConfirmDeleteVarietiesDialog: false
        });
    }

    /* Fonction de suppression des variétés */
    onConfirmDeleteVarieties = () => {
        const { deleteVarieties, harvestVarietyCharacteristicsDico } = this.props;
        const { varietiesSelected } = this.state;

        //Suppression des variétés personnalisées uniquement du client :
        if ((varietiesSelected !== undefined) && (varietiesSelected !== null)) {
            let ids = [];
            varietiesSelected.forEach(varietySelected => {
                if (lodashGet(harvestVarietyCharacteristicsDico, `[${varietySelected}].clientId`, -1) > 0) {
                    ids.push(varietySelected);
                }
            })
    
            if (ids.length > 0) {
                deleteVarieties(ids);
            }

            this.setState({
                openConfirmDeleteVarietiesDialog: false,
                varietiesSelected: []
            });
        }
    }

    deleteVarieties = (datas) => {
        this.setState({
            openConfirmDeleteVarietiesDialog: true,
        });
    }

    /* Ferme le dialog des messages d'erreur */
	closeDialogOfErrors = () => {
		const { clearHarvestMessageError } = this.props;

		this.setState({
			openDialogOfErrors: false,
		});

        if (clearHarvestMessageError)
		    clearHarvestMessageError();
	}

    ///////////////////////////////////////////////////////////////////////////
    // fonction de rendu visuel - layout général
    ///////////////////////////////////////////////////////////////////////////
    render() {

        const {
            updateNbRowsPerPageTableHarvests,
            rowsPerPageForTableHarvests, loadingVarietiesCharacteristics,
            openPopupVariety, deletingVarieties, loadingHarvests
        } = this.props; // fonctions mapDispatchToProps

        const {
            datas, openConfirmDeleteVarietiesDialog, currentRowsOfTable,
            searchFilter, variety, action, openDialogOfErrors
        } = this.state; // etat du dialog popup

        return (
            <>
                {/* ↓↓ Partie visuel - affichage Pop d'une erreur ↓↓ */}
                {(openDialogOfErrors === true) && (
                    <AlertDialog popup={this.popupErrorDialog} handleChangePopup={this.closeDialogOfErrors} />
                )}

                {/* ↓↓ Partie visuel - affichage Popup pour la modification d'une variété ↓↓ */}
                {openPopupVariety ?
                    <VarietyForm variety={variety} action={action}/>
                     : null}

                {/* ↓↓ Partie visuel - affichage Popup pour la suppression d'une ou plusieurs variétés ↓↓ */}
                <Dialog open={openConfirmDeleteVarietiesDialog}
                    onClose={this.handleCloseConfirmDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{StringTranslate.askDeleteHarvestVarietyTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{StringTranslate.askDeleteHarvestVarieties}</DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.handleCloseConfirmDialog} variant="text" color="error" autoFocus>
                            {StringTranslate.cancelDeleteAction}
                        </Button>
                        <Button onClick={this.onConfirmDeleteVarieties} variant="contained" color="primary">
                            {StringTranslate.confirmDeleteAction}
                        </Button>
                    </DialogActions>
                </Dialog>

                {(loadingVarietiesCharacteristics === true) || (loadingHarvests === true) ? <DataGridSkeleton /> :
                    /* ↓↓ partie - zone de visualisation des parcelles ↓↓ */
                    <TableContainer>
                        <Grid style={{ width: '100%' }} item xs={12}>
                            {/* partie tableau */}
                            <CustomDataGrid
                                disableSelectionOnClick={true}
                                tableName={TableType.harvestVarieties}
                                pageSize={rowsPerPageForTableHarvests}
                                keepNonExistentRowsSelected={searchFilter !== "" ? true : false}
                                updateNbRowsPerPageTable={updateNbRowsPerPageTableHarvests}      // ==> pas utilsé dans customDataGrid
                                onSelectionModelChange={(newSelectionVarieties) => {
                                    this.setSelectionVarieties(newSelectionVarieties);
                                }}
                                Toolbar={this.CustomToolbar}
                                toolbar={{
                                    deleteVarieties: (event, data) => this.deleteVarieties(data),
                                    value: searchFilter,
                                    onChange: (event) => this.requestSearch(event.target.value, datas),
                                    clearSearch: () => this.requestSearch('', datas),
                                }}
                                loading={(deletingVarieties === true)}
                                rows={(searchFilter !== "") ? currentRowsOfTable : datas}
                                columns={this.columns}
                                checkBoxActive={true}
                                isRowSelectable={(params) => params.row.clientId !== null}
                            />
                        </Grid>
                    </TableContainer> 
                }

            </>
        );
    }

}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    //Infos provenant du reducer 'parcelDatas':
    updatingParcelName: lodashGet(state, 'parcelsData.updatingParcelName', false),
    parcelIdFilteredList: lodashGet(state, 'parcelsData.parcelIdFilteredList', {}),

    //Infos provenant du reducer 'harvest':
    errorHarvest: lodashGet(state, 'harvestData.errorMessage', undefined),
    harvestVarietyCharacteristicsDico: lodashGet(state, 'harvestData.harvestVarietyCharacteristicsDico', {}),
    harvestVarietyCharacteristicsDicoCounter: lodashGet(state, 'harvestData.harvestVarietyCharacteristicsDicoCounter', 0),
    loadingVarietiesCharacteristics: lodashGet(state, 'harvestData.loadingVarietiesCharacteristics', false),
    loadingHarvests: lodashGet(state, 'harvestData.loadingHarvests', false),
    updatingVariety: lodashGet(state, 'harvestData.updatingVariety', false),
    openPopupVariety: lodashGet(state, 'harvestData.openPopupVarietyForm', false),
    harvestDico: lodashGet(state, 'harvestData.harvestDico', {}),
    harvestDicoCounter: lodashGet(state, 'harvestData.harvestDicoCounter', 0),
    addingVariety: lodashGet(state, 'harvestData.addingVariety', false),
    deletingVarieties: lodashGet(state, 'harvestData.deletingVarieties', false),

    //Infos provenant du reducer 'settings':
    rowsPerPageForTableHarvests: lodashGet(state, 'settingsData.settings.rowsPerPageForTableHarvests', 20),
    language: lodashGet(state, 'settingsData.settings.language', StringTranslate.getLanguage()),

    //Infos provenant du reducer 'clientUser':
    clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1),
    authorizeCornHarvest: lodashGet(state, 'clientUserData.clientDatas.authorizeCornHarvest', true),
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    deleteVarieties: (varietyIdsToDelete) => dispatch(ActionDeleteVarieties(varietyIdsToDelete)),
    showProfilMenuDialog: (index) => dispatch(ActionShowProfilMenuDialog(index)),
    updateNbRowsPerPageTableHarvests: (rowsPerPage) => dispatch(UpdateNbRowsPerPageTable(rowsPerPage, TableType.harvests)),
    updateVariety: (variety) => dispatch(ActionUpdateVariety(variety)),
    openPopupVarietyForm: (open) => dispatch(ActionOpenPopupVarietyForm(open)),
    clearHarvestMessageError: () => dispatch(ActionClearHarvestMessageError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(HarvestList);