
const TelNumberPromize = '0344067177';
const UrlRootSpotiFarm = 'https://www.spotifarm.fr';
const UrlMainSpotiFarm = 'https://www.spotifarm.fr';
const UrlHelpImportParcelSpotiFarm = 'https://www.spotifarm.fr/importation-parcelles-spotifarm';
const UrlCGVSpotiFarm = 'https://www.spotifarm.fr/conditions-generales-spotifarm';
const UrlPGDSpotiFarm = 'https://www.spotifarm.fr/mentions-legales';
const UrlMainSpotiFarmES = 'https://www.spotifarm.es/es-es';
const UrlHelpImportParcelESSpotiFarm = 'https://www.spotifarm.es/es-es/importar-parcelas-spotifarm';
const UrlContactSpotiFarm = 'https://www.spotifarm.fr/contact';
const UrlContactSpotiFarmES = 'https://www.spotifarm.fr/es-es/contacto';
const EmailPromize = 'contact@spotifarm.fr';


export const ConstantsContact = {
    TelContact: TelNumberPromize,
    UrlRoot: UrlRootSpotiFarm,
    UrlMain: UrlMainSpotiFarm,
    UrlHelpImportParcel: UrlHelpImportParcelSpotiFarm,
    UrlCGV: UrlCGVSpotiFarm,
    UrlPGD: UrlPGDSpotiFarm,
    UrlMainES: UrlMainSpotiFarmES,
    UrlHelpImportParcelES: UrlHelpImportParcelESSpotiFarm,
    UrlContact: UrlContactSpotiFarm,
    UrlContactES: UrlContactSpotiFarmES,
    EmailContact: EmailPromize,
};

export default ConstantsContact;
