import projection from './projection';
import countryCode from './countryCode';
import externalApplicationName from './externalApplicationName';

//association du nom de l'application d'où provient l'import de parcellaire et la langue d'export
//permet de trouver la projection adéquate lors d'import de parcellaire sans projection
const projectionByAppAndCountry = {

    [externalApplicationName.geofolia]: {
        [countryCode.FR]: projection['EPSG:GEOFOLIA'],
        [countryCode.BE]: projection['EPSG:31370'],
        [countryCode.ES]: projection['EPSG:2062'],
        default: projection['EPSG:2975'],
    },

    [externalApplicationName.telepac]: {
        [countryCode.FR]: projection['EPSG:2154'],
        [countryCode.BE]: projection['EPSG:31370'],
        [countryCode.ES]: projection['EPSG:2062'],
        default: projection['EPSG:2154'],
    },

    [externalApplicationName.mesparcelles]: {
        [countryCode.FR]: projection['EPSG:2154'],
        [countryCode.BE]: projection['EPSG:31370'],
        [countryCode.ES]: projection['EPSG:2062'],
        default: projection['EPSG:2154'],
    },

    [externalApplicationName.autre]: {
        [countryCode.FR]: projection['EPSG:2154'],
        [countryCode.BE]: projection['EPSG:31370'],
        [countryCode.ES]: projection['EPSG:2062'],
        default: projection['EPSG:4326'],
    },
};

export default projectionByAppAndCountry;
