import React, { Component } from "react";
import { BarChart, Bar, Cell, YAxis } from 'recharts';
import '../../assets/css/dnc-displayNdviCharts.css';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import { Box } from "@mui/material";
import getTheme from "../../themes/index.js";

let theme = getTheme();

/* constante - données utilisées pour la légende - données fixes */
const dataLegend = [
    {
        "number": 100,
        "color": theme.palette.ndviColors.visible,
    },
    {
        "number": 100,
        "color": theme.palette.ndviColors.levee,
    },
    {
        "number": 100,
        "color": theme.palette.ndviColors.faible,
    },
    {
        "number": 100,
        "color": theme.palette.ndviColors.faibleMoyenne,
    },
    {
        "number": 100,
        "color": theme.palette.ndviColors.moyenne,
    },
    {
        "number": 100,
        "color": theme.palette.ndviColors.moyenneForte,
    },
    {
        "number": 100,
        "color": theme.palette.ndviColors.forte,
    },
];

///////////////////////////////////////////////////////////////////////////
// Composant appliqué sur la carte pour afficher l'échelle (NDVI)
///////////////////////////////////////////////////////////////////////////
class NdviScale extends Component {
    
    /* cycle de vie react */
    render() {
        return (
            <div className='dnc-display-scale'>

                {/* ↓↓ partie 01 - header ↓↓ */}
                <Box
                    sx={{
                        height:"5px",
                        textAlign:"center",
                        fontSize:"12px",
                        fontWeight:"600",
                        color:theme.palette.grey[500],
                        lineHeight:"1.3"
                    }}
                />
                
                {/* ↓↓ partie 02 - histogramme ↓↓ */}
                <Box sx={{ margin:"auto" }}>
                    <BarChart
                        width={210}
                        height={10}
                        data={[...dataLegend]}
                        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                        barCategoryGap={0}
                    >
                        <YAxis width={30} domain={[0,10]} hide={true} />
                        <Bar 
                            dataKey="number" // la valeur à lire dans le set de données
                            radius={[1, 1, 1, 1]} // arrondi sur chaque bar
                        >
                            {dataLegend.map((entry, index) => (<Cell key={index} fill={entry.color} />))}
                        </Bar>
                    </BarChart>
                </Box>
                
                {/* ↓↓ partie 03 - description ↓↓ */}
                <div className='dnc-scale-part03'>
                    <Box
                        sx={{
                            width:"140px",
                            textAlign:"left"
                        }}
                    >
                        {StringTranslate.scalelow}
                    </Box>
                    <Box
                        sx={{
                            textAlign:"right",
                        }}
                    >
                        {StringTranslate.scalehigh}
                    </Box>
                </div>
                
            </div>
        )

    }
}

export default NdviScale;