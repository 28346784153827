import RequestCalls from './request.js'; //permet d'appeler l'API web
import ConfigApi from './configApi.js'; //constantes des appels API web
import { LanguageHelper } from './languageHelper.js';
import sendError from './errorService.js';
import StringTranslate from '../assets/i18n/stringLanguage.jsx';// et utilisé comme ceci: ${StringTranslate.xxx}
import { ParcelsHelper } from './parcelsHelper.js';
import { SatimageHelper } from './satimageHelper.js';
import { ModulationsHelper } from './modulationsHelper.js';
import { BiomassesHelper } from './biomassesHelper.js';
import { HarvestHelper } from './harvestHelper.js';
import { harvestVarietyCharacteristicsHelper } from './harvestVarietyCharacteristicsHelper.js';
import FertilizerHelper from './fertilizerHelper.js';
import ModelisationHelper from './modelisationHelper.js';
import lodashGet from 'lodash/get';
import lodashIsNil from 'lodash/isNil';
import lodashForEach from 'lodash/forEach';
import { TimeUtilsHelper } from './timeUtilsHelper.js';
import { ActionNeedRenewToken } from '../redux/actions/connection.js';
import { Observation } from '../models/observation.js';
import converter from '../utils/converter.js';
import { DidacticielFirstParcelHelper } from './didacticielFirstParcelHelper.js';
import { extractEmailFrom } from '../utils/authentUtils';
/*import { isSocialAccount } from './authentUtils.js';
import { ActionShowSocialAccountSignupForm } from '../redux/actions/contextApp.js';*/
import { ObservationImage } from '../models/observationImage.js';
import { IdentificationImage } from '../models/identificationImage.js';
import { IsNativeHoster } from './platformHelper';
import { format } from 'date-fns';
import errorCodeHelper from './errorCodeHelper.js';
import lastNitrogenInputHelper from './lastNitrogenInputHelper.js';

/**
 * Objet contenant les données permettant une connexion sécurisée et valide à notre API C#
 */
export const API = {
    clientId: null,
    token: null,
}

/**
 * Objet d'outils divers
 */
const tools = {
    /**
     * fonction permettant de formatter la valeur du numéro de téléphone (20 digits max, sans lettre) 
     */
    formatPhoneValue: function (value = '') {
        let val = value.replace(/^\+/, '00'); // remplace +33 par 0033
        let valWithoutCharacter = val.replace(/\D+/g, ''); // enlève les lettres
        return valWithoutCharacter.substring(0, 19); // 20 digits max
    },

    testForUpdateToken: function(dispatch, getState) { //@@CAn : A tester pour vérifier si fonctionne aussi avec l'authent depuis l'appli native !  
        if ((!dispatch) || (!getState)) return;

        const currentGlobalState = getState();
        if (!currentGlobalState) return;

        let expirationValue = lodashGet(currentGlobalState, 'connectionData.expiration', 0);
        if (expirationValue <= 0) {
            const rebuildExpiration = lodashGet(currentGlobalState, 'connectionData.expiresOn', 0);
            if (rebuildExpiration instanceof Date) {
                expirationValue = TimeUtilsHelper.thisDateNumber(rebuildExpiration);
            }
            //else 'expirationValue' reste à 0 !
        }

        const expirationAsNumber = Number(expirationValue);
        // If expiration is within offset, it will force renew
        //const offset = (this.config && this.config.system && this.config.system.tokenRenewalOffsetSeconds) ? this.config.system.tokenRenewalOffsetSeconds : 300; //300 = 5mins !
        const offset = 0; //attente de l'expiration, pas d'anticipation car on force à recharger toute l'application !
        if ((expirationAsNumber > 0) && ((expirationAsNumber + offset) <= TimeUtilsHelper.dateNowNumber())) {
            // je vais essayer de modifier le state et voir s'il réagit !
            //currentGlobalState.connectionData.needUpdateToken = true;
            dispatch( ActionNeedRenewToken() ); //Error : "Actions must be plain objects. Use custom middleware for async actions."
        } //else //cas ok !
    },

    dateToStringConverter: function (dateValue) {
        if ((dateValue !== null) && (dateValue !== undefined)) {
            try 
            {
                let thisDate = null;
                if (!(dateValue instanceof Date)) {
                    thisDate = new Date(dateValue);
                } else {
                    thisDate = dateValue;
                }
                return format(thisDate, 'yyyy-MM-dd');
            }
            catch (e) { 
                return dateValue;
            }
        } else {
            return null
        }
    },

    reformatSpecificInfos: function (specificInfosData) {        
        specificInfosData.harvestDate = tools.dateToStringConverter(specificInfosData.harvestDate);
        specificInfosData.sowingDate = tools.dateToStringConverter(specificInfosData.sowingDate);

        specificInfosData.previousHarvestDate = tools.dateToStringConverter(specificInfosData.previousHarvestDate);
        specificInfosData.previousPloughingDate = tools.dateToStringConverter(specificInfosData.previousPloughingDate);

        specificInfosData.organicInputDate = tools.dateToStringConverter(specificInfosData.organicInputDate);

        specificInfosData.relicDate = tools.dateToStringConverter(specificInfosData.relicDate);

        specificInfosData.beginningWinterDate = tools.dateToStringConverter(specificInfosData.beginningWinterDate);
        specificInfosData.endingWinterDate = tools.dateToStringConverter(specificInfosData.endingWinterDate);



        // transformer les valeur "" des textfields en null afin de ne pas être transformé en 0 par le fetch
        specificInfosData.performanceGoal = tools.defaultStringToNull(specificInfosData.performanceGoal);
        specificInfosData.pebbleRate = tools.defaultStringToNull(specificInfosData.pebbleRate);
        specificInfosData.ploughingDepth = tools.defaultStringToNull(specificInfosData.ploughingDepth);
        specificInfosData.organicInputQuantity = tools.defaultStringToNull(specificInfosData.organicInputQuantity);
        specificInfosData.waterQuantity = tools.defaultStringToNull(specificInfosData.waterQuantity);
        specificInfosData.waterNitrateContent = tools.defaultStringToNull(specificInfosData.waterNitrateContent);
        specificInfosData.sNO3NH4 = tools.defaultStringToNull(specificInfosData.sNO3NH4);
        specificInfosData.content = tools.defaultStringToNull(specificInfosData.content);
        specificInfosData.doseExtended = tools.defaultStringToNull(specificInfosData.doseExtended);
        specificInfosData.doseSaved = tools.defaultStringToNull(specificInfosData.doseSaved);
        specificInfosData.previousPerformance = tools.defaultStringToNull(specificInfosData.previousPerformance);
        specificInfosData.previousNitrogenFertilization = tools.defaultStringToNull(specificInfosData.previousNitrogenFertilization);

        return specificInfosData;
    },

    reformatCommonInfos: function (commonInfosManagementToSave) {
        commonInfosManagementToSave.harvestDate = tools.dateToStringConverter(commonInfosManagementToSave.harvestDate);
        commonInfosManagementToSave.sowingDate = tools.dateToStringConverter(commonInfosManagementToSave.sowingDate);
        
        return commonInfosManagementToSave
    },

    defaultStringToNull: function (value) {
        // fonction pour transformer les valeur "" des textfields en null 
        // afin de ne pas être transformé en 0 par le fetch
        return (value === "") ? null : value;
    },

}

/**
 * Objet de connexion à l'API spotifarm dans le cadre - données settings
 */
const settings_web_api_provider = {

    /**
    *  Fonction permettant de mettre à jour les données settings d'un user
    */
    updateSettings: function (dispatch, getState, newSettingsValue) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noUpdateDatasWithoutStoreAcces);
                return;
            }
            if (!newSettingsValue) {
                reject(StringTranslate.noUpdateDatasWithoutDatas);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (idOfClient <= 0) {
                reject(StringTranslate.noUpdateDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noUpdateDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la mise à jour duparamétrage, du client connecté :
            let requestURL = ConfigApi.ConstAndDefault.UrlWebApiSettings;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURL, { method: 'PUT', headers: headersAuthent, params: paramSearch, body: newSettingsValue })
                .then((response) => resolve(response)) //La réponse est juste 'ok' ou 'pas ok'.
                .catch((err) => {
                    if (sendError) {
                        sendError('updateSettings', {
                            "erreur": "une erreur s'est produite lors de la mise à jour du paramétrage client côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }
                    reject(StringTranslate.badUpdateDatas);
                });
        }

        return new Promise(promise);
    },

    /**
    *  Fonction permettant de mettre à jour le souvenir des anciens paramètres de la modulation pour les prochaines
    */
    updateRememberModulationSettings(dispatch, getState, rememberValue) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noUpdateDatasWithoutStoreAcces);
                return;
            }
            if (rememberValue === undefined) {
                reject(StringTranslate.noUpdateDatasWithoutDatas);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (idOfClient <= 0) {
                reject(StringTranslate.noUpdateDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noUpdateDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la mise à jour duparamétrage, du client connecté :
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiSettings}Modulation/${idOfClient}`;
          
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: rememberValue })
                .then((response) => resolve(response)) //La réponse est juste 'ok' ou 'pas ok'.
                .catch((err) => {
                    if (sendError) {
                        sendError('updateSettings', {
                            "erreur": "une erreur s'est produite lors de la mise à jour du paramétrage client côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }
                    reject(StringTranslate.badUpdateDatas);
                });
        }

        return new Promise(promise);
    },

    /**
    *  Fonction permettant de mettre à jour le souvenir de l'affichage des images landsat8
    */
    updateDisplayLandsatSettings(dispatch, getState, displayLandsatValue) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noUpdateDatasWithoutStoreAcces);
                return;
            }
            if (displayLandsatValue === undefined) {
                reject(StringTranslate.noUpdateDatasWithoutDatas);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (idOfClient <= 0) {
                reject(StringTranslate.noUpdateDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noUpdateDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la mise à jour duparamétrage, du client connecté :
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiSettings}Landsat/${idOfClient}`;
          
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: displayLandsatValue })
                .then((response) => resolve(response)) //La réponse est juste 'ok' ou 'pas ok'.
                .catch((err) => {
                    if (sendError) {
                        sendError('updateSettings', {
                            "erreur": "une erreur s'est produite lors de la mise à jour du paramétrage client côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }
                    reject(StringTranslate.badUpdateDatas);
                });
        }

        return new Promise(promise);
    },

    /**
    *  Fonction permettant de mettre à jour le nombre de lignes par page dans le tableau en paramètre
    */
    updateRowsPerPageTable(dispatch, getState, rowsPerPage, tableType) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noUpdateDatasWithoutStoreAcces);
                return;
            }
            if (rowsPerPage === undefined || tableType === undefined) {
                reject(StringTranslate.noUpdateDatasWithoutDatas);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (idOfClient <= 0) {
                reject(StringTranslate.noUpdateDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noUpdateDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la mise à jour du nombre de lignes par page, du client connecté :
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiSettings}${idOfClient}/RowsPerPageTable/${rowsPerPage}`;
          
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: tableType })
                .then((response) => resolve(response)) //La réponse est juste 'ok' ou 'pas ok'.
                .catch((err) => {
                    if (sendError) {
                        sendError('updateSettings', {
                            "erreur": "une erreur s'est produite lors de la mise à jour du paramétrage (nombre de lignes par page dans un tableau) client côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }
                    reject(StringTranslate.badUpdateDatas);
                });
        }

        return new Promise(promise);
    },

    updateMapParcelFilter(dispatch, getState, filter) {
        let promise = new Promise((resolve, reject) => {
            if (!getState) {
                reject(StringTranslate.noUpdateDatasWithoutStoreAcces);
                return;
            }
            if (filter === undefined) {
                reject(StringTranslate.noUpdateDatasWithoutDatas);
                return;
            }

            let serializedFilter = JSON.stringify(filter);

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (idOfClient <= 0) {
                reject(StringTranslate.noUpdateDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noUpdateDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la mise à jour du nombre de lignes par page, du client connecté :
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiSettings}${idOfClient}/MapParcelFilter`;
          
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: serializedFilter })
                .then((response) => resolve(response)) //La réponse est juste 'ok' ou 'pas ok'.
                .catch((err) => {
                    if (sendError) {
                        sendError('updateMapParcelFilter', {
                            "erreur": "une erreur s'est produite lors de la mise à jour du paramétrage (filtre des parcelles dans la cartographie) client côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }
                    reject(StringTranslate.badUpdateDatas);
                });
        });

        return promise;
    },
}

/**
 * Objet de connexion à l'API spotifarm dans le cadre - données client
 */
const parcel_web_api_provider = {
    /**
     *  Fonction permettant de rechercher les parcelles de l'utilisateur connecté à SpotiFarm.
     */
    loadParcels: function (dispatch, getState) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadParcelDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiParcels}Client/${idOfClient}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit une liste d'entité 'Parcel'
                    if (response) { // si reponse - le parcellaire existe en BDD
                        const dicoOfResponse = ParcelsHelper.convertToDico(response); // liste des parcelles => transformation dico (parcelId - parcel)
                        resolve(dicoOfResponse);
                    } else {
                        reject(StringTranslate.badLoadParcelDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadParcels', {
                            "erreur": "une erreur s'est produite lors de la recherche du parcellaire côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadParcelDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadParcels', {
                            "erreur": "une erreur s'est produite lors de la recherche du parcellaire côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadParcelDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de rechercher les parcelles archivées par rapport aux fumures de l'utilisateur connecté à SpotiFarm.
     */
    loadParcelsArchivedForFertilizer: function (dispatch, getState) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadParcelDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiParcels}Archived/Client/${idOfClient}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit une liste d'entité 'Parcel'
                    if (response) { // si reponse - le parcellaire existe en BDD
                        const dicoOfResponse = ParcelsHelper.convertToDico(response); // liste des parcelles => transformation dico (parcelId - parcel)
                        resolve(dicoOfResponse);
                    } else {
                        reject(StringTranslate.badLoadParcelDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadParcels', {
                            "erreur": "une erreur s'est produite lors de la recherche des parcelles archivées côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadParcelDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadParcels', {
                            "erreur": "une erreur s'est produite lors de la recherche des parcelles archivées côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadParcelDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de contrôler un PRJ (pour l'import d'un SHP).
     */
    checkProjection: function (dispatch, getState, projectionDatas) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noCheckDatasWithoutStoreAcces);
                return;
            }

            if (!projectionDatas) {
                reject(StringTranslate.noCheckDatasWithoutDatas);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (idOfClient <= 0) {
                reject(StringTranslate.noCheckDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noCheckDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la vérification de la projection fournis :
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiParcels}Control/Proj`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: projectionDatas })
                .then((response) => resolve(response)) // on reçoit un string => Soit vide: signifiant que le contrôle est OK ; Sinon, c'est la définition de la projectoion corrigée                
                .catch((err) => {
                    if (sendError) {
                        sendError('checkProjection', {
                            "erreur": "une erreur s'est produite lors du contrôle du PRJ côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }
                    reject(StringTranslate.badCheckPRJDatas);
                });
        };

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de corriger le ZIP (d'un import SHP).
     */
    correctShapeZip: function (dispatch, getState, zipForCorrection) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noCheckDatasWithoutStoreAcces);
                return;
            }

            if (!zipForCorrection) {
                reject(StringTranslate.noCheckDatasWithoutDatas);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (idOfClient <= 0) {
                reject(StringTranslate.noCheckDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noCheckDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la vérification de la projection fournis :
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiParcels}Control/Zip`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: zipForCorrection })
                .then((response) => resolve(response)) // on reçoit une nouvelle entité POCO 'ZipParcels' contenant le flux modifié (si la projection contenue le nécessitait)
                .catch((err) => {
                    if (sendError) {
                        sendError('correctShapeZip', {
                            "erreur": "une erreur s'est produite lors de la correction du ZIP(shp) côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }
                    reject(StringTranslate.badCorrectZipOfShpDatas);
                });
        };

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de corriger le SHP (d'un import).
     *  RQ: on fournis aussi la projection...
     *  ET en retour, on a aussi la version corrigée (si il y eu besoin)
     */
    correctShapeShp: function (dispatch, getState, shpForCorrection) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noCheckDatasWithoutStoreAcces);
                return;
            }

            if (!shpForCorrection) {
                reject(StringTranslate.noCheckDatasWithoutDatas);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (idOfClient <= 0) {
                reject(StringTranslate.noCheckDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noCheckDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la vérification de la projection fournis :
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiParcels}Control/Shp`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: shpForCorrection })
                .then((response) => resolve(response)) // on reçoit une nouvelle entité POCO 'ShpParcels' contenant le flux modifié ET la projection revue (si la projection contenue le nécessitait)
                .catch((err) => {
                    if (sendError) {
                        sendError('correctShapeShp', {
                            "erreur": "une erreur s'est produite lors de la correction du ZIP(shp) côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }
                    reject(StringTranslate.badCorrectZipOfShpDatas);
                });
        };

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de supprimer le parcellaire (et toutes les données associées) de l'utilisateur connecté à SpotiFarm.
     */
    deleteParcels: function (dispatch, getState) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noDeleteDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noDeleteParcelDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noDeleteDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiParcels}Client/${idOfClient}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'DELETE', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit 'vrai' !
                    resolve(response);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('deleteParcels', {
                            "erreur": "une erreur s'est produite lors de la suppression du parcellaire côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    reject(StringTranslate.badDeleteParcelDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     * fonction permettant de supprimer une liste de parcelle en BDD (ainsi que toutes ses infromations liées)
     * @param {*} parcelIdListToDelete => liste d'Id de parcelle à supprimer
     */
    deleteParcelIdList(parcelIdListToDelete = null) {

        let promise = function (resolve, reject) {

            if (!parcelIdListToDelete || !Array.isArray(parcelIdListToDelete) || (parcelIdListToDelete.length <= 0)) {
                reject('la liste de parcelle à supprimer est nulle ou vide');
                return;
            }

            let paramSearch = {
                idClient: API.clientId,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${API.token}`,
            };

            // @@ testForUpdateToken
            // tools.testForUpdateToken(dispatch, getState);

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiParcels}Delete/${API.clientId}`; // création de l'URL
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: parcelIdListToDelete })
                .then((response) => {
                    if (response && (response.isSuccess === true)) { // toutes les parcelles ont été supprimées (isSuccess)
                        resolve(response.parcelIdListDeleted);
                    }
                    else if (response && (response.isSuccess === false)) { // les parcelles ont été supprimées partiellement
                        sendError('observation_web_api_provider-deleteParcelIdList', {
                            "erreur": "La suppression d'une liste d'ID de parcelle s'est faite partiellement",
                            "err1": JSON.stringify(response.parcelIdDicoInError),
                            "idClient": API.clientId,
                        });
                        resolve(response.parcelIdListDeleted);
                    }
                    else {
                        sendError('observation_web_api_provider-deleteParcelIdList', {
                            "erreur": "La suppression d'une liste d'ID de parcelle a echoué; La réponse retournée n'est pas valide",
                            "idClient": API.clientId,
                        });
                        resolve([]);
                    }
                })
                .catch((err) => {
                    sendError('observation_web_api_provider-deleteParcelIdList', {
                        "erreur": "une erreur s'est produite lors de la suppression d'une liste d'ID de parcelle",
                        "err": err,
                        "idClient": API.clientId,
                    });
                    reject(err);
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant d'enregistrer le parcellaire de l'utilisateur connecté à SpotiFarm.
     */
    importParcels: function (dispatch, getState, parcelListValue, disableParcels = true) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            if (!parcelListValue) {
                reject(StringTranslate.noLoadDatasWithoutDatas);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadParcelDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiParcels}All`;
            if (disableParcels) {
                requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiParcels}Disable/All`;
            }
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'POST', headers: headersAuthent, params: paramSearch, body: parcelListValue })
                .then((response) => { // on reçoit une liste d'entité 'Parcel' actualisé notament avec leur ID valide
                    if (response && response.parcelsAdded) { // si reponse - le parcellaire existe en BDD
                        response.parcelsAdded = ParcelsHelper.convertToDico(response.parcelsAdded); // liste des parcelles => transformation dico (parcelId - parcel)
                        response.observationsActivated = converter.observationListToDico(response.observationsActivated); // liste des observations ré-activées et ré-associées suite à l'import
                        // 'response.errorsDetected' contient les erreurs si on veut aller plus loin dans la gestion des erreurs d'import
                        resolve(response);
                    } else {
                        reject(StringTranslate.badLoadParcelDatas);
                    }
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('importParcels', {
                            "erreur": "une erreur s'est produite lors de l'enregistrement du parcellaire côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                            "disableParcels": disableParcels,
                        });
                    }

                    reject(StringTranslate.badLoadParcelDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de sauvegrader une parcelle en BDD (ex: parcelle dessinée)
     */
    saveParcel: function (dispatch, getState, parcelToSave) {
        let promise = function (resolve, reject) {

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadParcelDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            // on prépare la requête
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiParcels}All`;
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: [parcelToSave] })
                .then((response) => {
                    if (response && response.parcelsAdded && response.parcelsAdded[0]) {
                        response.parcelSaved = response.parcelsAdded[0];
                        response.observationsActivated = converter.observationListToDico(response.observationsActivated); // si null => {}
                        resolve(response);
                    }
                    else {
                        reject(StringTranslate.badLoadParcelDatas);
                    }
                })
                .catch((err) => {
                    sendError('saveParcel', {
                        "erreur": "une erreur s'est produite lors de l'enregistrement d'UNE parcelle côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                    });
                    reject(StringTranslate.badLoadParcelDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     * Fonction permettant d'enregistrer en BDD le nouveau d'une parcelle
     */
    saveParcelName: function (dispatch, getState, parcelName, parcelId) {
        let promise = function (resolve, reject) {

            // - ↓↓ création du header ↓↓
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadParcelDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            // - ⇓⇓ Envoi de la requête  ⇓⇓
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiParcels}${parcelId}`; // on récupère la base URL
            return RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: parcelName }) //requête http POST
                .then((response) => resolve(response))
                .catch((err) => {
                    sendError('handleSubmit - parcelName', { "err": err, "parcelId": parcelId, "parcelName": parcelName });
                    reject(err);
                });
        }

        return new Promise(promise);
    },

    /**
     * Fonction de modification des champs d'une parcelle en BDD
     */
    updateParcel: function (dispatch, getState, parcelId, newParcel) {
        let promise = function (resolve, reject) {

            // - ↓↓ création du header ↓↓
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noUpdateDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noUpdateDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            newParcel.clientId = idOfClient;

            // - ⇓⇓ Envoi de la requête  ⇓⇓
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiParcels}UpdateParcel`; // on récupère la base URL
            return RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: newParcel }) //requête http POST
                .then((response) => resolve(response))
                .catch((err) => {
                    sendError('handleSubmit - parcel', { "err": err, "parcelId": parcelId, "parcel": newParcel });
                    reject(err);
                });
        }

        return new Promise(promise);
    },

    /**
     * fonction permettant de sauvegarder la parcelle dessinée lors de l'utilisation du didacticiel firstparcel
     * ⚠️ - mise à jour typo + incrémentation compteur tuto si besoin
     */
    saveParcelWhenDidacticielFirstParcel: function(parcelToSave) {
        let promise = function (resolve, reject) {
            
            let paramSearch = { 
                idClient: API.clientId,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${API.token}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiParcels}SaveParcelWhenDidacticielFirstParcel`;
            RequestCalls.requestWithAuthent(requestURL , { method: 'POST', headers: headersAuthent, params: paramSearch, body: [parcelToSave] })
                .then((response) => {
                    if (response && response.parcelsAdded && response.parcelsAdded[0]) {
                        response.parcelSaved = response.parcelsAdded[0];
                        response.observationsActivated = converter.observationListToDico(response.observationsActivated); // si null => {}
                        resolve(response);
                    }
                    else {
                        reject(StringTranslate.badLoadParcelDatas);
                    }
                })
                .catch( (err) => reject(err));
        }
        
        return new Promise(promise);
    },

    /**
     * fonction permettant de sauvegarder les parcelles importées lors de l'utilisation du didacticiel firstparcel
     * ⚠️ - mise à jour typo + incrémentation compteur tutoriel
     */
    saveParcelListImportWhenDidacticielFirstParcel: function(parcelsToSave) {
        let promise = function (resolve, reject) {
            
            let paramSearch = { 
                idClient: API.clientId,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${API.token}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiParcels}SaveParcelListImportWhenDidacticielFirstParcel`;
            RequestCalls.requestWithAuthent(requestURL , { method: 'POST', headers: headersAuthent, params: paramSearch, body: parcelsToSave })
                .then((response) => {
                    if (response && response.parcelsAdded && response.parcelsAdded[0]) {
                        response.parcelsSaved = ParcelsHelper.convertToDico(response.parcelsAdded); // liste des parcelles => transformation dico (parcelId - parcel)
                        response.observationsActivated = converter.observationListToDico(response.observationsActivated); // si null => {}
                        resolve(response);
                    }
                    else {
                        reject(StringTranslate.badLoadParcelDatas);
                    }
                })
                .catch( (err) => reject(err));
        }
        
        return new Promise(promise);
    },

    /**
     * Fonction permettant de mettre à jour la localisation d'une parcelle
     * @param {int} idClient 
     * @param {int} idParcel 
     * @param {Localisation} localisation objet contenant les informations de localisation d'une parcelle
     */
    updateParcelLocalisation: function(dispatch, getState, idParcel, localisation) {
        let promise = function (resolve, reject) {
            /* Création du header */
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noUpdateDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noUpdateDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            // vérifie le code postal fournis :
            if (localisation && (typeof localisation.codePostal === 'string')) { 
                localisation.codePostal = parseInt(localisation.codePostal);
            }

            // - ⇓⇓ Envoi de la requête  ⇓⇓
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiParcels}Update/Localisation/${idOfClient}/${idParcel}`; // on récupère la base URL
            return RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: localisation }) //requête http POST
                .then((response) => resolve(response))
                .catch((err) => {
                    sendError('handleSubmit - parcel', { "err": err, "clientId": idOfClient, "parcelId": idParcel, "localisation": localisation });
                    reject(err);
                });
        }

        return new Promise(promise);
    },

    /**
     * Fonction permettant de modifier les informations, liés à la modélisation, d'une parcelle 
     * @param {Parcel} parcel 
     */
    updateParcelForModelisation(parcel) {
        let promise = function (resolve, reject) {

            let paramSearch = {
                idClient: API.clientId,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${API.token}`,
            };

            // @@ testForUpdateToken

            // let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiParcels}Update/Modelisation`; // on récupère la base URL            
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiParcels}Create/Modelisation`; // on récupère la base URL
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: parcel })
                .then((isSuccess) => {
                    if (isSuccess !== undefined) {
                        resolve();
                    } else {
                        let error = "une erreur s'est produite lors de la modification des données de modélisation d'une parcelle. Retour valide mais pas de données";
                        sendError('parcel_web_api_provider-UpdateParcelForModelisation', { "erreur": error, "idClient": API.clientId });
                        reject(error);
                    }
                })
                .catch((err) => {
                    sendError('parcel_web_api_provider-UpdateParcelForModelisation', {
                        "erreur": "une erreur s'est produite lors de l'enregistrement des données d'une parcelle pour la modélisation",
                        "err": err,
                        "idClient": API.clientId,
                    });
                    reject(err);
                });
        }

        return new Promise(promise);
    },

    /**
     * Fonction permettant de réinitialiser les information de modélisation pour plusieurs parcelles
     * @param {number[]} parcelsId 
     * @param {boolean} deleteModelizedImages
     */
    resetModulationInformation(parcelsId, deleteModelizedImages) {
        let promise = function (resolve, reject) {

            let paramSearch = {
                idClient: API.clientId,
                deleteModelizedImages: deleteModelizedImages,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${API.token}`,
            };

            // @@ testForUpdateToken

            // on récupère la base URL
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiParcels}Modelisation/Reset`;
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: parcelsId })
                .then((response) => {
                    if (response && response.Success && response.Error) {
                        resolve(response);
                    } else {
                        let error = "une erreur s'est produite lors de la réinitialisation des données de modélisation de plusieurs parcelles. Retour valide mais pas de données";
                        sendError('parcel_web_api_provider-resetModulationInformation', { "erreur": error, "idClient": API.clientId });
                        reject(error);
                    }
                })
                .catch((err) => {
                    sendError('parcel_web_api_provider-resetModulationInformation', {
                        "erreur": "une erreur s'est produite lors de la réinitialisation des données de modélisation des parcelles",
                        "err": err,
                        "idClient": API.clientId,
                    });
                    reject(err);
                });
        }

        return new Promise(promise);
    },
}

/**
 * Objet de connexion à l'API spotifarm dans le cadre - données client
 */
const history_web_api_provider = { //@@Il faudra lors du processus de "FirstHisto" alterner comme ceci : demander image du mois Sentinel, puis images du mois LandSat
                                   //ET seulement si on a aucune image de sentinel et landsat, alors on demande les images du mois précédent sentinel... et si toujours rien, faire la même demande pour landsat ; Etc...    
    /**
     *  Fonction permettant de rechercher/créer les données de l'utilisateur connecté à SpotiFarm.
     *  nbImageAskedFirst : Nombre d'images à afficher au démarrage de l'appli avant la génération 
     *  complète du mois en cours.
     * 
     *  Si nbImageAskedFirst = 0 alors la génération et l'affichage du mois en cours seront complets
     */
    generateHistory: function (dispatch, getState, parcelId, nbImageAskedFirst, clickedParcel = false) { //@@Attention ! cet appel devra spécifier la source (provider) des images souhaitées.
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (!(idOfClient > 0) || !(parcelId > 0)) {
                reject(StringTranslate.noGenerateHistoDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            // Force le mois en cours:
            const dateAsked = new Date();
            const month = dateAsked.getUTCMonth() + 1; //+1 car commence à zéro !
            const year = dateAsked.getUTCFullYear();

            //On demande la génération des images pour la parcelle visée, du client connecté :
            let requestURLfirstHisto = `${ConfigApi.ConstAndDefault.UrlWebApiHistory}${parcelId}`;
            let paramSearch = {
                idClient: idOfClient,
                YearAsk: year,
                MonthAsk: month,
                NbImagesAskedFirst: (nbImageAskedFirst >= 0) ? nbImageAskedFirst : 0,
                ClickedParcel: clickedParcel,
                ImageProviderType: "0"
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLfirstHisto, { method: 'POST', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit une liste d'entité POCO C# 'ImageData' ! (dont l'une d'elle, la+ récente, devrait avoir les flux d'image)
                    if (response && response.imageDataList) { // si reponse - il y a des images en BDD de cette parcelle pour le mois en cours

                        response.mostRecentSatImage = (response.imageDataList[0]) ? response.imageDataList[0] : null; // l'objet satImage le + récent (liste triée par l API)
                        response.imageDataList = SatimageHelper.convertToDico(response.imageDataList); // liste des parcelles => transformation dico (imageId - parcel)
                        response.satImageWithHigherMaxccDico = SatimageHelper.convertToDico(response.satImageWithHigherMaxccList); // liste d'entités satImage ayant un taux d'ennuagement supérieure au maxcc du client => transformation dico (imageId - parcel)

                        resolve(response);
                    } else {
                        reject(StringTranslate.badGenerateHistoDatas);
                    }
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('generateHistory', {
                            "erreur": "une erreur s'est produite lors de la génération d'un historique côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }
                    reject(StringTranslate.badGenerateHistoDatas);
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de générer l'historique d'une parcelle suivant le mois et l'année
     *  nbImageAskedFirst : Nombre d'images à afficher au démarrage de l'appli avant la génération 
     *  complète du mois en cours.
     * 
     *  Si nbImageAskedFirst = 0 alors la génération et l'affichage du mois en cours seront complets
     */
    generateOldHistory: function (dispatch, getState, parcelId, year, month, nbImageAskedFirst) { //@@Attention ! cet appel devra spécifier la source (provider) des images souhaitées.     
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (!(idOfClient > 0) || !(parcelId > 0)) {
                reject(StringTranslate.noGenerateHistoDatasWithoutClientId);
                return;
            }
            if (!(month > 0) || !(year > 0)) {
                reject(StringTranslate.noGenerateHistoDatasWithoutMonthYear);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la génération des images pour la parcelle visée, du client connecté :
            let requestURLOldHisto = `${ConfigApi.ConstAndDefault.UrlWebApiHistory}GenOldHisto/${parcelId}`;
            let paramSearch = {
                idClient: idOfClient,
                YearAsk: year,
                MonthAsk: month,
                NbImagesAskedFirst: (nbImageAskedFirst >= 0) ? nbImageAskedFirst : 0,
                ImageProviderType: "0"
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };
            
            RequestCalls.requestWithAuthent(requestURLOldHisto, { method: 'POST', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit une liste d'entité POCO C# 'ImageData' ! (dont l'une d'elle, la+ récente, devrait avoir les flux d'image) 
                if (response && response.imageDataList) { // si reponse - il y a des images en BDD de cette parcelle pour le mois en cours
                        let mostRecentSatImage = response.imageDataList[0];
                        response.mostRecentSatImage = (mostRecentSatImage) ? mostRecentSatImage : null; // l'objet satImage le + récent (liste triée par l API)
                        response.imageDataList = SatimageHelper.convertToDico(response.imageDataList); // liste des parcelles => transformation dico (imageId - parcel)
                        response.satImageWithHigherMaxccDico = SatimageHelper.convertToDico(response.satImageWithHigherMaxccList); // liste d'entités satImage ayant un taux d'ennuagement supérieure au maxcc du client => transformation dico (imageId - parcel)

                        resolve(response);
                    } else {
                        reject(StringTranslate.badGenerateHistoDatas);
                    }
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('generateOldHistory', {
                            "erreur": "une erreur s'est produite lors de la génération d'un mois d'historique côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }
                    reject(StringTranslate.badGenerateHistoDatas);
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de demande l'actualisation de l'historique d'une parcelle... suite à une modification (en augmentation) du taux d'enuagement autorisé.
     */
    updateHistory: function (dispatch, getState, parcelId) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (!(idOfClient > 0) || !(parcelId > 0)) {
                reject(StringTranslate.noGenerateHistoDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la génération des images pour la parcelle visée, du client connecté :
            var requestURLupdateHisto = `${ConfigApi.ConstAndDefault.UrlWebApiHistory}UpdateCc/${parcelId}`; // on récupère la base URL
            let paramUpdate = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLupdateHisto, { method: 'POST', headers: headersAuthent, params: paramUpdate })
                .then((response) => { // on reçoit une liste d'entité POCO C# 'ImageData' ! (dont l'une d'elle, la+ récente, devrait avoir les flux d'image)
                    if (response) { // si reponse - il y a des images en BDD de cette parcelle pour le mois en cours
                        response = SatimageHelper.convertToDico(response); // liste des parcelles => transformation dico (imageId - parcel)
                        //convertit aussi les flux des images NDVI, visible et vignette en objet image au sens JS !

                        // const mostRecentImag = SatimageHelper.getMostRecentSatimageFromList(response);
                        // if (mostRecentImag && mostRecentImag.data) {
                        //     const myImageNDVI = new Image(512, 512);
                        //     myImageNDVI.src = `data:image/png;base64,${mostRecentImag.data}`;
                        //     mostRecentImag.data = myImageNDVI; // on affecte l'image à notre objet
                        // }
                        // if (mostRecentImag && mostRecentImag.dataLight) {
                        //     const myImageLight = new Image(512, 512);
                        //     myImageLight.src = `data:image/png;base64,${mostRecentImag.dataLight}`;
                        //     mostRecentImag.dataLight = myImageLight; // on affecte l'image à notre objet
                        // }

                        resolve(response);
                    } else {
                        reject(StringTranslate.badGenerateHistoDatas);
                    }
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('updateHistory', {
                            "erreur": "une erreur s'est produite lors de la mise à jour de l'historique côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }
                    reject(StringTranslate.badGenerateHistoDatas);
                });
        }

        return new Promise(promise);
    },

    /**
    *  Fonction permettant de faire un appel serveur pour récupérer l'image historique d'une parcelle suivant son Id
    */
    getImageFromServer(dispatch, getState, imageId) {
        let promise = function (resolve, reject) {
            if (!(imageId > 0)) {
                reject('imageId non valide');
                return;
            }
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (!(idOfClient > 0)) {
                reject(StringTranslate.noGenerateHistoDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiHistory}ImageAt/${imageId}`;
            RequestCalls.requestWithAuthent(requestURL, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // analyse de la réponse
                    if (response) { // reçoit l'entité POCO C# 'ImageData'.
                        resolve(response);
                    } else {
                        return new Error('la réponse est vide');
                    }
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('getImageFromServer', {
                            "erreur": 'err - getImageFromServer',
                            "error": err,
                            "imageId": imageId
                        });
                    }
                    reject('err - getImageFromServer');
                });
        }

        return new Promise(promise);
    },

    /**
    *  Fonction permettant de faire un appel serveur pour récupérer l'analyse (d'une image d'une parcelle) suivant son Id
    */
    getAnalyseOfImageFromServer(dispatch, getState, imageId) {
        let promise = function (resolve, reject) {
            if (!(imageId > 0)) {
                reject('imageId non valide');
                return;
            }
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (!(idOfClient > 0)) {
                reject(StringTranslate.noGetAnalysDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noGetAnalysWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiHistory}AnalysOf/${imageId}`;
            RequestCalls.requestWithAuthent(requestURL, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // analyse de la réponse
                    if (response && response.colorPercentage) { // reçoit l'entité POCO C# 'DataAnalyse' et la propriété qui nous intéresse.
                        // on lui ajoute une propriété sous forme de Dico, concernant le pourcentage de pixels de chacune des couleurs:
                        response.percentages = Object.entries(response.colorPercentage).map(([k, v]) => ({ "color": k, "percentage": v }));
                        resolve(response);
                    } else {
                        reject(StringTranslate.badGetAnalysDatas);
                    }
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('getAnalyseOfImageFromServer', {
                            "erreur": 'err - getAnalyseOfImageFromServer',
                            "error": err,
                            "imageId": imageId
                        });
                    }
                    reject(StringTranslate.badGetAnalysDatas);
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de récupérer toutes les données satImage stoquées en BDD pour un mois donné et une parcelId ciblée
     */
    getHistoryForSpecificMonthYearByParcelId: function (dispatch, getState, monthAsked, yearAsked, parcelId) { //@@Attention ! cet appel devra spécifier la source (provider) des images souhaitées.
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (!(idOfClient > 0) || !(parcelId > 0)) {
                reject(StringTranslate.noGenerateHistoDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la génération des images pour la parcelle visée, du client connecté :
            let url = `${ConfigApi.ConstAndDefault.UrlWebApiHistory}getSatImageListByMonthYear/${parcelId}/${monthAsked}/${yearAsked}`;
            let paramSearch = {
                idClient: idOfClient,
                month: monthAsked,
                year: yearAsked
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };
  
            RequestCalls.requestWithAuthent(url, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit une liste d'entité POCO C# 'ImageData' ! (dont l'une d'elle, la+ récente, devrait avoir les flux d'image)
                    if (response && response.imageDataList) { // si reponse - il y a des images en BDD de cette parcelle pour le mois en cours

                        response.mostRecentSatImage = (response.imageDataList[0]) ? response.imageDataList[0] : null; // l'objet satImage le + récent (liste triée par l API)
                        response.imageDataList = SatimageHelper.convertToDico(response.imageDataList); // liste des parcelles => transformation dico (imageId - parcel)
                        response.satImageWithHigherMaxccDico = SatimageHelper.convertToDico(response.satImageWithHigherMaxccList); // liste d'entités satImage ayant un taux d'ennuagement supérieure au maxcc du client => transformation dico (imageId - parcel)

                        resolve(response);
                    } else {
                        reject(StringTranslate.badGenerateHistoDatas);
                    }
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('getLockedHistory', {
                            "erreur": "une erreur s'est produite lors de la récupération d'un historique bloqué côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }
                    reject(StringTranslate.badGenerateHistoDatas);
                });
        }

        return new Promise(promise);
    },

    getSatImageFiltered: function (dispatch, getState, parcelId, imageFilter) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (!(idOfClient > 0) || !(parcelId > 0)) {
                reject(StringTranslate.noGenerateHistoDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la récupération des images pour la parcelle visée, du client connecté :
            let url = `${ConfigApi.ConstAndDefault.UrlWebApiHistory}GetSatImageFiltered/${idOfClient}/${parcelId}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(url, { method: 'POST', headers: headersAuthent, params: paramSearch, body: imageFilter })
                .then((response) => { // on reçoit une liste d'entité POCO C# 'ImageData' !
                    if (response) {

                        let imageDataList = SatimageHelper.convertToDico(response); // liste des parcelles => transformation dico (imageId - parcel)

                        resolve(imageDataList);
                    } else {
                        reject(StringTranslate.badGenerateHistoDatas);
                    }
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('getLockedHistory', {
                            "erreur": "une erreur s'est produite lors de la récupération de certaines images côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                            "idOfParcel": parcelId
                        });
                    }
                    reject(StringTranslate.badGenerateHistoDatas);
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de mettre à jour la raison de désactivation d'une image
     */
    updateSatImageDeactivationReason: function (dispatch, getState, parcelId, satImageId, reasonDeactivate) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (!(idOfClient > 0) || !(parcelId > 0)) {
                reject(StringTranslate.noGenerateHistoDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la génération des images pour la parcelle visée, du client connecté :
            var requestURLupdateHisto = `${ConfigApi.ConstAndDefault.UrlWebApiHistory}UpdateDeactivationReason/${idOfClient}/${parcelId}/${satImageId}/${reasonDeactivate}`; // on récupère la base URL
            let paramUpdate = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLupdateHisto, { method: 'POST', headers: headersAuthent, params: paramUpdate })
                .then((response) => { 
                    if (response) { 
                        resolve(response);
                    } else {
                        reject(StringTranslate.noUpdateDatasWithoutDatas);
                    }
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('updateSatImageDeactivationReason', {
                            "erreur": "une erreur s'est produite lors de la mise à jour de la raison de désactivation de l'image côté WebApi",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }
                    reject(StringTranslate.noUpdateDatasWithoutDatas);
                });
        }

        return new Promise(promise);
    }
}


/**
 * Objet de connexion à l'API spotifarm dans le cadre - données client
 */
const client_web_api_provider = {
    /**
     *  Fonction permettant de rechercher/créer les données de l'utilisateur connecté à SpotiFarm.
     */
    searchOrCreateClientDatasConnected: function (dispatch, getState) {
        let promise = function (resolve, reject) {
            //console.log(`searchOrCreateClientDatasConnected - start this promise`);

            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'email client, ainsi que du jeton d'accès:
            let emailOfClient = '';
            const currentStoreState = getState();
            let accountRef = undefined;
            let idtokenRef = undefined;
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                accountRef = currentStoreState.connectionData.account;
                emailOfClient = currentStoreState.connectionData.emailOfAccount;
              
                if (accountRef) {
                    idtokenRef = accountRef.idTokenClaims;

                    if (lodashIsNil(emailOfClient) || (emailOfClient === '')) {
                        //console.log(`searchOrCreateClientDatasConnected - accountRef=${JSON.stringify(accountRef)}`);

                        /*if (idtokenRef && idtokenRef.emails && Array.isArray(idtokenRef.emails) && idtokenRef.emails.length > 0) {
                            emailOfClient = idtokenRef.emails[0];
                        }
                        */ //revient à cela, qui aurai déjà dû fonctionner (TODO : dès lors que je ferai l'appel à 'ActionSetAccountAndEmail' au lieu de 'ActionSetAccount' pour les applis mobile)!
                        emailOfClient = extractEmailFrom(accountRef);
                    }
                    //else ok !
                } 
                //else console.log(`searchOrCreateClientDatasConnected - accountRef=null !`);

                tokenValue = currentStoreState.connectionData.accessToken;
                if (!tokenValue) {
                    tokenValue = API.token;
                }
            }
            if ((!accountRef) || (!emailOfClient)) {
                //console.log(`searchOrCreateClientDatasConnected - emailOfClient=null !`);

                reject(StringTranslate.noLoadClientDatasWithoutEmail);
                return;
            }
            if (!tokenValue) {
                //console.log(`searchOrCreateClientDatasConnected - tokenValue=null !`);
                
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //Il faut rechercher l'utilisateur et le client associé dans notre base. 
            // => N'utilise plus la simple recherche (et obtention) de l'utilisateur, mais l'obtention du client, utilisateur, paramétrage et parcellaire) :
            let requestURLuser = `${ConfigApi.ConstAndDefault.UrlWebApiClients}AllData/FromLogin/username`;
            let paramSearch = {
                email: emailOfClient,
                idClient: 9999999, // C'est justement une des principales informations que l'on recherche !!! 
                //Mais on le renseigne pour ne pas que 'RequestCalls.requestWithAuthent' sorte en erreur !
            };
            //si l'application Web est chargée depuis l'application native, on ajoute un paramètre spécifique (notament pour différencier le compteur de connexion)
            if (IsNativeHoster() === true) {
                paramSearch.intoNativeApp = 1;
            }

            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            //console.log(`authRedirectCallBack - call '${requestURLuser}'`);
            RequestCalls.requestWithAuthent(requestURLuser, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit l'entité 'AllDataClient'
                    // si reponse - l'utilisateur existe en BDD
                    if (response) {

                        response.parcels = ParcelsHelper.convertToDico(response.parcels); // liste des parcelles => transformation dico (parcelId - parcel)

                        response.observations = converter.observationListToDico(response.observations); // observations => transformation dico (observationId - observation)

                        response.observationTypes = converter.observationTypeListToDico(response.observationTypes); // observations => transformation dico (observationTypeId - observationType)

                        response.didacticiels = DidacticielFirstParcelHelper.convertToDico(response.tutorials); // tutorials => transformation dico (tutorial-title - tutorialdatas)

                        resolve(response);
                    } 
                    /*// si reponse - cas création d'une nouvelle inscription via les réseaux sociaux
                    else if ((isSocialAccount(accountRef) === true) && (currentStoreState.contextAppData.showSocialAccountSignupForm === false)) { // est ce un réseau social ? + l'utilisateur est-il face du formulaire (et donc il vient de la valider) ?
                        
                        // => affichage formulaire avec champs pays, téléphone (Attention - searchOrCreateClientDatasConnected va être une nouvelle fois à l'issue de la validation du formulaire)
                        dispatch( ActionShowSocialAccountSignupForm() );
                    }*/ //retirer depuis la mise en place de la nouvell authent via les réseaux sociaux.
                    // sinon création du nouvel utilisateur en BDD
                    else {

                        //Si pas trouvé, on les (client et premier utilisateur) crée en BdD.
                        requestURLuser = `${ConfigApi.ConstAndDefault.UrlWebApiClients}AllData`; //ajoute le client, l'utilisateur et son paramétrage ET renvoie le tout !

                        // ↓ Récupération de informations du pays sélectionné lors de l'inscription - fichier de remplacement utilisé ↓
                        let json, nameCountry, codeCountry;
                        if (idtokenRef && idtokenRef.country) {
                            // Soit on reçoit directement unobjet contenant les deux propriétés (name et codeCountry):
                            if (idtokenRef.country.name && idtokenRef.country.codeCountry) { //cas de l'authent native !
                                json = idtokenRef.country;
                            }
                            else {
                                // Soit il s'agit de la sérialisation d'un objet définissant ces deux propriétés (name et codeCountry):
                                try {
                                    json = JSON.parse(idtokenRef.country); //il faut désérialiser l'objet lorsqu'il s'agit d'une inscription via la page MS.
                                } catch (error) { /* RAS... */}
                            }
                        }
                        //else tampis !!!
                        // RQ: si on se connecte via un autre fournisseur, on reçoit directement l'info du pays (string) et on ne dispose pas du code pays !
                        nameCountry = (json && json.name) ? json.name : 'France';
                        codeCountry = (json && json.codeCountry) ? json.codeCountry : 'FR';

                        // ↓ On va créer artificiellement la culture complète (langue)  ↓
                        let languageNavigator = LanguageHelper.defaultAlpha2Language;
                        try {
                            let browserLanguage = LanguageHelper.getLanguage();
                            let position = (browserLanguage) ? browserLanguage.indexOf("-") : null;
                            languageNavigator = (browserLanguage) ? ((position && position !== -1) ? browserLanguage.slice(0, position) : browserLanguage) : LanguageHelper.defaultAlpha2Language;
                        } catch (error) { }

                        let firstname = lodashGet(idtokenRef, 'given_name', ''); //idtokenRef.given_name ? idtokenRef.given_name : '';

                        //Si family_name est vide (ce qui est le cas quand on créé un compte facebook ou google), on récupère
                        //le nom complet puis on retire le given_name (qui est le prénom) pour obtenir le nom de famille.
                        let familyName = '';
                        if (idtokenRef) {
                            familyName = idtokenRef.family_name ? 
                                idtokenRef.family_name : 
                                (((idtokenRef.name !== '') && 
                                (idtokenRef.name !== undefined) && 
                                (idtokenRef.name !== null) && 
                                (idtokenRef.name !== "unknown")) ? 
                                    idtokenRef.name.split(firstname)[1].trim() : 
                                    '');
                        }

                        let name = (firstname !== '') ? firstname : '';
                        name = (familyName !== '') ? name + " " + familyName : name;
                        
                        const newClient = { //@@créer le model associé !
                            id: 0, //on va le recevoir...
                            name: name,
                            surname: lodashGet(idtokenRef, 'surname', ''), //idtokenRef.surname,
                            naming: { 
                                firstname: firstname,
                                lastname: familyName,
                            },
                            date: new Date(),
                            detail: `${firstname} - ${familyName}`,
                            masterUser: familyName,
                            masterEmail: emailOfClient,
                            cityCode: accountRef.postalCode,
                            cityName: accountRef.city,
                            address: { country: nameCountry }, // obtention du nom du pays
                            phone: (idtokenRef && idtokenRef.extension_Telephone) ? tools.formatPhoneValue(idtokenRef.extension_Telephone) : '', // custom attribute in Azure
                            codeCountry: codeCountry, // obtention du code pays
                            languageNavigator: `${languageNavigator}-${codeCountry}`, // obtention de l'iso code de la langue
                            domainIdPV: accountRef.domainIdPV, // obtention du domain Id PV. Un client est un client Géofolia quand Id > 0 
                            totalCounterParcelManaged: accountRef.totalCounterParcelManaged, //
                            idTypoClient: accountRef.idTypoClient, //RQ cette propriété est ignorée car dans le cas d'ajout d'un client, il a toujours la typo Freemium d'affectée !
                            enumTypoClient: accountRef.enumTypoClient //RQ cette propriété est ignorée car dans le cas d'ajout d'un client, il a toujours la typo Freemium d'affectée ! 
                        };

                        // ↓ on remet à jour l'objet stringTranslate suivant le nouvelle culture ↓
                        LanguageHelper.updateLanguage(newClient.languageNavigator);

                        paramSearch = {
                            idClient: 9999999, // C'est justement une des principales informations que l'on s'attends à récupérer !!! 
                            //Mais on le renseigne pour ne pas que 'RequestCalls.requestWithAuthent' sorte en erreur !
                        };
                      
                        RequestCalls.requestWithAuthent(requestURLuser, { method: 'POST', headers: headersAuthent, params: paramSearch, body: newClient })
                            .then((response) => { // on reçoit l'entité 'AllDataClient' (créée à partir de 'ClientAndUsers')
                                if (response) { // si reponse - l'utilisateur vient d'être créé en BDD
                                    response.parcels = {}; // la liste des parcelles est vide puisque l'on crée => transformation dico vide !!!
                                    if (response.client) {
                                        response.client.isNewer = true; //propriété qui n'existe pas côté .Net mais que je crée pourinformer l'appli !
                                    }
                                    resolve(response);
                                    return;
                                } else {
                                    reject(StringTranslate.noCreateClientDatas);
                                }
                                return;
                            }, (error) => {
                                if (sendError) {
                                    sendError('searchOrCreateClientDatasConnected', {
                                        "erreur": "une erreur s'est produite lors de la création d'un user côté API",
                                        "error": error,
                                        "newClient.name": newClient.name,
                                        "newClient.masterEmail": newClient.masterEmail,
                                    });
                                }

                                //reject(error); //pas parlant pour l'utilisateur !
                                reject(StringTranslate.noCreateClientDatas);
                                return;
                            })
                            .catch((err) => {
                                if (sendError) {
                                    sendError('searchOrCreateClientDatasConnected', {
                                        "erreur": "une erreur s'est produite lors de la création d'un user côté API",
                                        "err": err,
                                        "newClient.name": newClient.name,
                                        "newClient.masterEmail": newClient.masterEmail,
                                    });
                                }

                                //reject(err); //pas parlant pour l'utilisateur !
                                reject(StringTranslate.noCreateClientDatas);
                                return;
                            });
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('searchOrCreateClientDatasConnected', {
                            "erreur": "une erreur s'est produite lors de la recherche du user côté API",
                            "error": error,
                            "accountRef": accountRef,
                            "email": (paramSearch) ? paramSearch.email : null
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badSearchClientDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('searchOrCreateClientDatasConnected', {
                            "erreur": "une erreur s'est produite lors de la recherche du user côté API",
                            "err": err,
                            "accountRef": accountRef,
                            "email": (paramSearch) ? paramSearch.email : null
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badSearchClientDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    //---------------------------------------- Revoir l'utilité de ceux ci-après ----------------------------------------//

    /**
    *  Fonction permettant de mettre à jour les données user
    */
    updateClientDatas: function (dispatch, getState, clientDatasToSave) {
        let promise = (resolve, reject) => {
            if (!clientDatasToSave) {
                reject('les données d\'entrée ne sont pas valides');
                return;
            }

            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (!(idOfClient > 0)) {
                reject(StringTranslate.noGenerateHistoDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            let client = client_tools.formatToWebApp(clientDatasToSave);
            if (!client) reject('les données d\'entrée ne sont pas valides');
            const newDataClient = Object.assign(client, { idClient: idOfClient }); //cumul info typo+stripe avec nom+prenom+sociétéclientNaming

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiBillings}Client/${idOfClient}`; // on récupère la base URL
            RequestCalls.requestWithAuthent(requestURL, { method: 'PUT', headers: headersAuthent, params: paramSearch, body: newDataClient }) //requête http POST
                .then((response) => resolve(response))
                .catch((err) => {
                    sendError('updateClientDatas', {
                        "erreur": "une erreur s'est produite lors de l'enregistrement de nouvelles données client (clientBilling, ...)",
                        "err": err,
                        "idOfClient": idOfClient,
                    });
                    reject(err);
                    return;
                });
        }

        return new Promise(promise);
    },

    updateClientGodfather(dispatch, getState, clientId, parrainEmail) {
        let promise = (resolve, reject) => {
            if ((clientId === undefined) || (clientId <= 0) || (!parrainEmail) || (parrainEmail === '')) {
                reject('les données d\'entrée ne sont pas valides');
                return;
            }

            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if ((!(idOfClient > 0)) || (idOfClient !== clientId)) {
                reject(StringTranslate.noGenerateHistoDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };
            let bodyDatas = {
                idClient: idOfClient,
                emailGodfather: parrainEmail,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiClients}${idOfClient}/Godfather`; // on récupère la base URL
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: bodyDatas }) //requête http POST
                .then((response) => resolve(response))
                .catch((err) => {
                    sendError('updateClientGodfather', {
                        "erreur": "une erreur s'est produite lors de l'enregistrement du parrain de ce nouveau client",
                        "err": err,
                        "idOfClient": idOfClient,
                    });
                    reject(err);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
    *  Fonction permettant d'enregistrer en BdD ce qu'a fait l'utilisateur pour sortir de l'incitation
    */
    saveResultIncitation: function (dispatch, getState, reasonValue, userChoiceValue) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noUpdateDatasWithoutStoreAcces);
                return;
            }
            if ((!reasonValue) || (!userChoiceValue)) {
                reject(StringTranslate.noUpdateDatasWithoutDatas);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            let idOfUser = lodashGet(currentStoreState, 'clientUserData.userDatas.id', -1);
            if (idOfClient <= 0) {
                reject(StringTranslate.noUpdateDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noUpdateDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la mise à jour duparamétrage, du client connecté :
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiClients}NewResultIncitation`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };
            const bodyUserChoice = { //représente l'entité 'ResultIncitation' C# !
                clientId: idOfClient,
                userId: idOfUser,
                reason: reasonValue,
                actionClick: userChoiceValue,
            }

            RequestCalls.requestWithAuthent(requestURL, { method: 'PUT', headers: headersAuthent, params: paramSearch, body: bodyUserChoice })
                .then((response) => resolve(response)) //La réponse est juste 'ok' ou 'pas ok'.
                .catch((err) => {
                    if (sendError) {
                        sendError('saveResultIncitation', {
                            "erreur": "une erreur s'est produite lors de la mise à jour de l'utilisateur client côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }
                    reject(StringTranslate.badUpdateDatas);
                });
        }

        return new Promise(promise);
    },

    //---------------------------------------- Revoir l'utilité de ceux ci-après ----------------------------------------//

    /**
    *  Fonction permettant de mettre à jour les données user
    */
    removeClientAndDatas: function (dispatch, getState) {
        let promise = (resolve, reject) => {
            if (!getState) {
                reject(StringTranslate.noRemoveClientWithoutStoreAccess);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (!(idOfClient > 0)) {
                reject(StringTranslate.noRemoveClientWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noRemoveClientWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            const emailOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.masterEmail', '');
            const clientToRemove = { 
                idClient: idOfClient,
                emailClient: emailOfClient,
            }
            let requestURL = `${ConfigApi.ConstAndDefault.BaseUrlWebApi}Marketing/DeleteAllOfClient`; // on récupère la base URL (RQ: pas de constante pour éviter son utilisation)
            RequestCalls.requestWithAuthent(requestURL, { method: 'PUT', headers: headersAuthent, params: paramSearch, body: clientToRemove }) //requête http PUT
                .then((response) => resolve(response))
                .catch((err) => {
                    sendError('removeClientAndDatas', {
                        "erreur": "une erreur s'est produite lors de la suppression définitive du client et de ses données",
                        "err": err,
                        "idOfClient": idOfClient,
                        "emailOfClient": emailOfClient,
                    });
                    reject(err);
                    return;
                });
        }

        return new Promise(promise);
    },
}

const client_tools = {
    /* fonction permettant de formatter les données vers un format reconnu par la WEB API */
    formatToWebApp(datas = null) {
        let clientInformations = null;

        try {
            clientInformations = {
                naming: {
                    firstName: (datas.naming) ? datas.naming.firstName : null,
                    lastName: (datas.naming) ? datas.naming.lastName : null,
                    compagnyName: (datas.naming) ? datas.naming.compagnyName : null,
                },
                address: {
                    label: (datas.address) ? datas.address.label : null,
                    postalCode: (datas.address) ? datas.address.postalCode : null,
                    cityName: (datas.address) ? datas.address.cityName : null,
                    country: (datas.address) ? datas.address.country : null,
                },
                phone: datas.phone,
            };
        } catch (error) { 
            //console.log(error); 
        }

        return clientInformations;
    }
}

/**
 * Objet de connexion à l'API spotifarm dans le cadre - de l'achat In-App (de l'application native)
 */
const store_web_api_provider = {

    /**
     *  Fonction permettant d'activer les droits au client, vis-à-vis du plan acheté sur le Store
     */
     upgradeClientAfterPurchase: function (dispatch, getState, datasPurchase) { //@@vérifier toutes les méthodes ne disposant pas de 'dispatch, getState' !
        let promise = function (resolve, reject) {
            if ((!datasPurchase) || (!datasPurchase.productId) || (datasPurchase.productId === '')) { /*|| (!planIdStripe) || (planIdStripe === '')) { */ //le plan stripe pourra être retrouvé côté Web API !
                reject('les données d\'entrée ne sont pas valides')
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', API.token);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', API.clientId);
            if (!(idOfClient > 0)) {
                reject(StringTranslate.noGenerateHistoDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            let paramSearch = { idClient: idOfClient };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            if ((!datasPurchase.idClient) || (datasPurchase.idClient <= 0)) {
                datasPurchase.idClient = idOfClient;
            }

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiBillings}UpgradeClientPurchaseStore/${idOfClient}`; // on récupère la base URL
            let body = datasPurchase;
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: body })
                .then((response) => { //on reçoit un object typé 'PurchaseStoreDatas' côté C# ! même object que 'datasPurchase' mais avec 'idStripePlan' de renseigné !
                    resolve(response);
                })
                .catch((err) => {
                    sendError('upgradeClientPurchaseStore', {
                        "erreur": "une erreur s'est produite lors de l'appel à l'actualisation du client ayant acheté via le Store de l'appli native",
                        "err": err,
                        "APIclientId": idOfClient,
                        "planIdStore": datasPurchase.productId,
                        "planIdStripe": datasPurchase.planIdStripe,
                        "osName": datasPurchase.osName,
                        "StoreName": datasPurchase.StoreName,
                    });

                    reject(err);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de désactiver les droits au client, vis-à-vis d'une souscription arrêtée sur le Store
     */
    downgradeClient: function (dispatch, getState, datasPurchase) { 
        let promise = function (resolve, reject) {
            if ((!datasPurchase) || (!datasPurchase.productId) || (datasPurchase.productId === '')) { 
                reject('les données d\'entrée ne sont pas valides')
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', API.token);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', API.clientId);
            if (!(idOfClient > 0)) {
                reject(StringTranslate.noGenerateHistoDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            let paramSearch = { idClient: idOfClient };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            if ((!datasPurchase.idClient) || (datasPurchase.idClient <= 0)) {
                datasPurchase.idClient = idOfClient;
            }

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiBillings}DowngradeClientStore/${idOfClient}`; // on récupère la base URL
            let body = datasPurchase;
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: body })
                .then((response) => { //on reçoit un object typé 'PurchaseStoreDatas' côté C# ! même object que 'datasPurchase' mais avec 'idStripePlan' de renseigné !
                    resolve(response);
                })
                .catch((err) => {
                    sendError('DowngradeClientStore', {
                        "erreur": "une erreur s'est produite lors de l'appel à l'actualisation du client, dont la souscription depuis le Store a été arrêté (depuis l'appli native)",
                        "err": err,
                        "APIclientId": idOfClient,
                        "planIdStore": datasPurchase.productId,
                        "osName": datasPurchase.osName,
                        "StoreName": datasPurchase.StoreName,
                    });

                    reject(err);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de contrôler les droits au client, vis-à-vis de souscription(s) connue(s) sur le Store
     */
    controlClient: function (dispatch, getState, datasPurchases) { 
        let promise = function (resolve, reject) {
            if ((!datasPurchases) || (!datasPurchases.productIds) || (!Array.isArray(datasPurchases.productIds))) { 
                reject('les données d\'entrée ne sont pas valides')
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', API.token);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', API.clientId);
            if (!(idOfClient > 0)) {
                reject(StringTranslate.noGenerateHistoDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            let paramSearch = { idClient: idOfClient };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            if ((!datasPurchases.idClient) || (datasPurchases.idClient <= 0)) {
                datasPurchases.idClient = idOfClient;
            }

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiBillings}ControlClientStore/${idOfClient}`; // on récupère la base URL
            let body = datasPurchases;
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: body })
                .then((response) => { //on reçoit un object typé 'PurchaseStoreDatas' côté C# ! même object que 'datasPurchase' mais avec 'idStripePlan' de renseigné !
                    resolve(response);
                })
                .catch((err) => {
                    sendError('DowngradeClientStore', {
                        "erreur": "une erreur s'est produite lors de l'appel à l'actualisation du client, d'après la(les) souscription(s) du Store (depuis l'appli native)",
                        "err": err,
                        "APIclientId": idOfClient,
                        "planIdListStore": datasPurchases.productIds,
                        "osName": datasPurchases.osName,
                        "StoreName": datasPurchases.StoreName,
                    });

                    reject(err);
                    return;
                });
        }

        return new Promise(promise);
    },
}

/**
 * Objet de connexion à l'API spotifarm dans le cadre - stripe
 */
const stripe_web_api_provider = {

    /**
     *  Fonction permettant de vérifier un code promo
     */
    checkDiscountCode: function (code, planId) { //@@A traiter pour recevoir dispatch et getState !
        let promise = function (resolve, reject) {
            if ((!code || code === '') || (!planId || planId === '') || (API.clientId <= 0) || (!API.token || API.token === '')) {
                reject('les données d\'entrée ne sont pas valides')
                return;
            }

            let paramSearch = { idClient: API.clientId };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${API.token}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiBillings}DiscountPlan/${API.clientId}`; // on récupère la base URL
            let body = { "code": code, "planId": planId };
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: body })
                .then((response) => resolve(response))
                .catch((err) => {
                    sendError('checkDiscountCode', {
                        "erreur": "une erreur s'est produite lors de l'appel au contrôle d'un code promotionnel",
                        "err": err,
                        "APIclientId": API.clientId,
                        "codeDiscount": code,
                        "planId": planId,
                    });

                    reject(err);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de récupérer la liste des plans avec la précision sur celui (éventuellement souscrit)
     */
    getPlansSubscribs: function (dispatch, getState) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (!(idOfClient > 0)) {
                reject(StringTranslate.noGenerateHistoDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiBillings}PlansAndSubscribs`; // on récupère la base URL
            RequestCalls.requestWithAuthent(requestURL, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => {
                    let plans = stripe_tools.normalizePlanSubscriptionArrayResponse(response); // récupération des plans sous forme de tableau
                    resolve(plans);
                })
                .catch((err) => {
                    sendError('getPlansSubscribs', {
                        "erreur": "une erreur s'est produite lors de la récupération des plans et souscriptions",
                        "err": err,
                        "idOfClient": idOfClient,
                    });
                    reject(err);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de créer un customer au sens stripe (association idClient-tokenCartSstripe) - avant une demande souscription 
     *  https://stripe.com/docs/billing/quickstart#create-customer
     */
    createCustomer: function(dispatch, getState, tokenId) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (!(idOfClient > 0)) {
                reject(StringTranslate.noGenerateHistoDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            if ((!tokenId) || (tokenId === '')) {
                reject('les données d\'entrée ne sont pas valides');
                return;
            }

            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            // @@testForUpdateToken

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiBillings}${idOfClient}`; // on récupère la base URL
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: tokenId }) //requête http POST
                .then((response) => resolve(response))
                .catch((err) => {
                    sendError('createCustomer', {
                        "erreur": "une erreur s'est produite lors de la demande d'ajout d'un client dans Stripe",
                        "err": err,
                        "APIclientId": idOfClient,
                        "tokenId": tokenId,
                    });
                    
                    reject(err);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     * fonction permettant de souscrire un customer à un plan 
     * https://stripe.com/docs/billing/quickstart 
     */
    subscribeCustomer: function(dispatch, getState, planId, idDiscount = null) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (!(idOfClient > 0)) {
                reject(StringTranslate.noGenerateHistoDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            if ((!planId) || (planId === '')) {
                reject('les données d\'entrée ne sont pas valides');
                return;
            }

            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiBillings}Subscription/${idOfClient}`; // on récupère la base URL
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: { planId: planId, idDiscount: idDiscount } }) //requête http POST
                .then((response) => resolve(response))
                .catch((err) => {
                    sendError('subscribeCustomer', {
                        "erreur": "une erreur s'est produite lors de la demande de souscription Stripe (plan <-> Customer)",
                        "err": err,
                        "APIclientId": idOfClient,
                        "planId": planId,
                        "idDiscount": idDiscount,
                    });
                    
                    reject(err);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     * fonction permettant d'arrêter la souscription du client SpotiFarm, par le biais du client stripe associé, en relation avec le plan spécifié !
     * @param {*} idPlan 
     * @param {*} idSubscrib 
     */
    unSubscribeCustomer: function(idPlan, idSubscrib, idClient) { //Pas appelée à ce jour !
        let promise = function (resolve, reject) {
            if ((!idPlan || idPlan === '') || (!idSubscrib || idSubscrib === '') || idClient <= 0)
                reject('les données d\'entrée ne sont pas valides');

            // @@ testForUpdateToken

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiBillings}EndSubscription/${idClient}`; // on récupère la base URL
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: { idClient: idClient }, body: idSubscrib }) //requête http POST
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }

        return new Promise(promise);
    },

    /**
     * fonction permettant de récupérer la liste des factures issues d'une souscription
     */
    getInvoices: function(idClient, limitInvoices = 0) { //Pas appelée à ce jour !
        let promise = function (resolve, reject) {
            if (idClient <= 0)
                reject('les données d\'entrée ne sont pas valides');

            // @@ testForUpdateToken

            const paramsValue = { limitInvoices: limitInvoices }; // paramètre de limitation
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiBillings}/Client/${idClient}`; // on récupère la base URL
            RequestCalls.requestWithAuthent(requestURL, { method: 'GET', headers: { idClient: idClient }, params: paramsValue }) //requête http POST
                .then((response) => resolve((response.lastSCharges) ? response.lastSCharges : []))
                .catch((err) => reject(err));
        }

        return new Promise(promise);
    },

    /**
     * fonction permettant de récupérer les informations de facturation de manière générale (liste des factures, infos facturation, carte bancaire enregistrée, ...)
     */
    getStripeCustomerInformations: function(dispatch, getState, limitInvoices = 0) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
            let idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            if (!(idOfClient > 0)) {
                reject(StringTranslate.noGenerateHistoDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            let paramSearch = {
                idClient: idOfClient,
                limitInvoices: limitInvoices,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiBillings}/Client/${idOfClient}`; // on récupère la base URL
            RequestCalls.requestWithAuthent(requestURL, { method: 'GET', headers: headersAuthent, params: paramSearch }) //requête http POST
                .then((response) => resolve(response))
                .catch((err) => reject(err));
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant d'obtenir le fichier Pdf de cette facture stripe 
     */
    LoadInvoiceFile: function(dispatch, getState, invoiceSelected, askObject = false, askStore = false) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0) || 
                (!invoiceSelected) /*|| (idOfClient !== invoiceSelected.clientId)*/) { //Rq pas de propriété référencant le client dans "l'entité" transmise par la Web API!
                reject(StringTranslate.noLoadInvoiceDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            // Désormais, on permet à l'utilisateur d'obtenir une visualisation des PDF ! Pour cela, on télécharge le fichier puis demande son ouverture dans un nouvel onglet...
            // RQ : Dans le cas de l'appli web hébergée dans l'appli mobile, on forcera à obtenir le flux brute pour le transmettre à l'appli mobile pour gérer l'affichage.
            // (pas le retour HTTP de type fichier téléchagé car cela déclenche des actions non-souhaitées de la part de la Web View)

            // on prépare la requête
            let paramSearch = {
                idClient: idOfClient,
                //par défaut, on télécharge le fichier Pdf... au sens où l'on ne demande pas l'entité contenant le flux brute du fichier
                resultObject: ((askStore !== true) && (askObject === true)) ? 1 : 0, //DONC, on ne demande pas la récupération de l'entité...
                storeFile: (askStore === true) ? 1 : 0, //ET DONC, on ne demande pas non-plus son stockage dans Azure...
            };
            //Cas de l'héberguement par notre application mobile => On demande à retourner l'objet/fichier directement ! 
            const isOnNativeApp = (IsNativeHoster() === true) ? true : false;
            //A partir du moment où l'on est hébergé par l'appli mobile, on force le stockage sur le compte Azure ! 
            // -> L'utilisateur pourra alors faire le choix de télécharger/imprimer/partager depuis l'affichage du fichier.
            if (isOnNativeApp === true) { 
                //demande le stockage du fichier (pour qu'il puisse être affiché sur le mobile).
                paramSearch.resultObject = 0;
                paramSearch.storeFile = 1;
            }

            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiBillings}DownloadInvoice/${idOfClient}/${invoiceSelected.number}`;
            RequestCalls.requestWithAuthent(requestURL, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((blobAndMetasOrObjectOrUrl) => { 
                    //SI on est en mode 'appli mobile' OU demande de stockage du fichier (pour affichage), 
                    // on reçoit une URL du lieu de stockage dans Azure !
                    if (paramSearch.storeFile === 1) { 
                        if (isOnNativeApp === true) { //Si depuis l'appli native
                            //On va transmettre à l'appli Native cette URL pour lancer l'affichage !!!
                            resolve(blobAndMetasOrObjectOrUrl);
                        } else {
                            try {
                                /*const link = document.createElement('a');
                                link.href = blobAndMetasOrObjectOrUrl;
                                link.setAttribute('target', "_blank");
                                link.setAttribute('rel', "noopener noreferrer");

                                try {
                                    // Append to html page
                                    document.body.appendChild(link);
                                    
                                    // Force download
                                    link.click();
                                }
                                finally {
                                    // Clean up and remove the link
                                    link.parentNode.removeChild(link);

                                    resolve(true);
                                }*/ //ne semble pas toujours fonctionner dans une promesse (ou bloquer en tant que popup) !

                                window.open(blobAndMetasOrObjectOrUrl, "_blank", "noopener noreferrer");
                                resolve(true);
                            }
                            catch(errLink) {
                                if (sendError) {
                                    sendError('LoadInvoiceFile', {
                                        "erreur": "une erreur s'est produite suite au téléchargement du PDF de cette facture Stripe côté API",
                                        "errLink": errLink,
                                        "urlRequest": requestURL,
                                    });
                                }

                                reject(StringTranslate.badShowInvoice);
                            }
                        }
                    } else if (paramSearch.resultObject === 1) { //Sinon, si on a demandé l'entité, 
                        //On l'a renvoie à l'appelant:
                        resolve(blobAndMetasOrObjectOrUrl);
                    } else { // Soit on reçoit le flux du fichier accompagné de ses caractèristiques !
                        //Cf. https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e :
                        if (blobAndMetasOrObjectOrUrl && blobAndMetasOrObjectOrUrl.data) {
                            blobAndMetasOrObjectOrUrl.data.blob()
                                .then((dataBlobed) => {
                                    let isOk = true;
                                    try {
                                        // Create blob link to download (Attention : Affiche le flux brute, non-interprêté, si on ne spécifie pas le 'type') :
                                        const url = window.URL.createObjectURL(new Blob([dataBlobed], { type: 'application/pdf' }));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', blobAndMetasOrObjectOrUrl.fileName);

                                        try {
                                            // Append to html page
                                            document.body.appendChild(link);
                                            
                                            // Force download
                                            link.click();
                                        }
                                        finally {
                                            // Clean up and remove the link
                                            link.parentNode.removeChild(link);

                                            resolve(true);
                                        }
                                    }
                                    catch (errorDetected) {
                                        isOk = false;
                                        if (sendError) {
                                            sendError('LoadInvoiceFile', {
                                                "erreur": "une erreur s'est produite suite au téléchargement du PDF de cette facture Stripe côté API",
                                                "errorDetected": errorDetected,
                                                "urlRequest": requestURL,
                                            });
                                        }

                                        reject(StringTranslate.badShowInvoiceDatas);
                                    }

                                    if (isOk) resolve(isOk);
                                })
                                .catch((errorBlob) => {
                                    if (sendError) {
                                        sendError('LoadInvoiceFile', {
                                            "erreur": "une erreur s'est produite lors de l'obtension de la facture PDF Stripe côté API",
                                            "errorBlob": errorBlob,
                                            "urlRequest": requestURL,
                                        });
                                    }

                                    //Fin des téléchargements:
                                    //Retourne que l'on n'a pas atteint notre but:
                                    reject(StringTranslate.badLoadInvoiceDatas);
                                });
                        }
                        else //on ne fait rien !
                            reject(StringTranslate.badLoadInvoiceDatas);
                    }
                })
                .catch((err) => {
                    sendError('LoadInvoiceFile', {
                        "erreur": "une erreur s'est produite lors de la demande d'obtension de la facture PDF Stripe côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                        "idOfParcel": invoiceSelected.parcelId,
                    });

                    reject(StringTranslate.badLoadInvoiceDatas);
                    return;
                });
        }

        return new Promise(promise);
    }
}

const stripe_tools = {

    /* fonction qui permet de normaliser un array en dico de valeur */
    normalizePlanSubscriptionArrayResponse: function (datasToNormalize) {
        if (!datasToNormalize || !Array.isArray(datasToNormalize)) return null;

        let normalizedDatas = {};
        datasToNormalize.forEach((plan) => {
            if (plan) {
                //Depuis l'application mobile, on conditionnera l'exploitation de ce plan, en le retenant uniquement : 
                // - si le plan contient les métadonnées associées à la liaison avec les plans des Stores !
                if (IsNativeHoster() === true) { //Mode Mobile => ajout conditionné...
                    const valueSku1 = lodashGet(plan, 'metadata["skuAndroid"]', undefined);
                    const valueSku2 = lodashGet(plan, 'metadata["skuIOS"]', undefined);
                    if (valueSku1 && (valueSku1 !== '') && valueSku2 && (valueSku2 !== '')) { //liaison avec les Stores OK => ajout !
                        normalizedDatas[plan.id] = plan;
                    }
                }
                else { //Mode Web => ajout systèmatique !
                    normalizedDatas[plan.id] = plan;
                }
            }
            //else // pas normal.
        });

        return normalizedDatas;
    }
}

/**
 * Objet de connexion à l'API spotifarm dans le cadre des observations
 */
const observation_web_api_provider = {

    /**
     * fonction permettant de sauvegarder une observation en BDD
     */
    saveObservation(newObservationToSave = null) {

        let promise = function (resolve, reject) {

            let paramSearch = {
                idClient: API.clientId,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${API.token}`,
            };

            // @@ testForUpdateToken

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiObservation}`; // on récupère la base URL
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: newObservationToSave })
                .then((response) => {
                    if (response) {
                        let observationSaved = new Observation(response);
                        resolve(observationSaved);
                    }
                    else {
                        let error = "une erreur s'est produite lors de la sauvegarde d'une nouvelle observation. Retour valide mais pas de données observation";
                        sendError('observation_web_api_provider-saveObservation', { "erreur": error, "idClient": API.clientId });
                        reject(error);
                    }
                })
                .catch((err) => {
                    sendError('observation_web_api_provider-saveObservation', {
                        "erreur": "une erreur s'est produite lors de la sauvegarde d'une nouvelle observation",
                        "err": err,
                        "idClient": API.clientId,
                    });
                    reject(err);
                });
        }

        return new Promise(promise);
    },

    /**
     * fonction permettant de mettre a jour une observation en BDD
     */
    updateObservation(newObservationToSave = null) {

        let promise = function (resolve, reject) {

            let paramSearch = {
                idClient: API.clientId,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${API.token}`,
            };

            // @@ testForUpdateToken

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiObservation}Update`; // on récupère la base URL
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: newObservationToSave })
                .then((response) => {
                    if (response) {
                        let observationSaved = new Observation(response);
                        resolve(observationSaved);
                    }
                    else {
                        let error = "une erreur s'est produite lors de la mise à jour de l'observation. Retour valide mais pas de données observation"
                        sendError('observation_web_api_provider-updateObservation', { "erreur": error, "idClient": API.clientId });
                        reject(error);
                    }
                })
                .catch((err) => {
                    sendError('observation_web_api_provider-updateObservation', {
                        "erreur": "une erreur s'est produite lors de la mise à jour de l'observation",
                        "err": err,
                        "idClient": API.clientId,
                    });
                    reject(err);
                });
        }

        return new Promise(promise);
    },

    /**
     * fonction permettant d'ajouter une image à une observation
     */
    addObservationImage(observationImage = null) {
        let promise = function (resolve, reject) {

            let paramSearch = {
                idClient: API.clientId,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${API.token}`,
            };

            // @@ testForUpdateToken

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiObservation}Image`; // on récupère la base URL
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: observationImage })
                .then((response) => {
                    if (response) {
                        let observationImageSaved = new ObservationImage(response);
                        resolve(observationImageSaved);
                    }
                    else {
                        let error = "une erreur s'est produite lors de l'ajout' de l'image d'observation. Retour valide mais pas de données observationImage"
                        sendError('observation_web_api_provider-AddNewImage', { "erreur": error, "idClient": API.clientId });
                        reject(error);
                    }
                })
                .catch((err) => {
                    sendError('observation_web_api_provider-AddNewImage', {
                        "erreur": "une erreur s'est produite lors de l'ajout de l'image d'observation",
                        "err": err,
                        "idClient": API.clientId,
                    });
                    reject(err);
                });
        }

        return new Promise(promise);
    },

    /**
     * fonction permettant d'ajouter une image à une observation à partir d'une image d'identification
     */
    addObservationImageFromIdentificationImage(identificationImage = null, observationId = 0) {
        let promise = function (resolve, reject) {

            let paramSearch = {
                idClient: API.clientId,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${API.token}`,
            };

            // @@ testForUpdateToken

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiObservation}Image/addIdentificationImage/${observationId}`; // on récupère la base URL
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: identificationImage })
                .then((response) => {
                    if (response) {
                        let observationImageSaved = new ObservationImage(response);
                        resolve(observationImageSaved);
                    }
                    else {
                        let error = "une erreur s'est produite lors de l'ajout' de l'image d'observation via une image d'identification. Retour valide mais pas de données observationImage"
                        sendError('observation_web_api_provider-AddObservationImageFromIdentificationImage', { "erreur": error, "idClient": API.clientId });
                        reject(error);
                    }
                })
                .catch((err) => {
                    sendError('observation_web_api_provider-AddObservationImageFromIdentificationImage', {
                        "erreur": "une erreur s'est produite lors de l'ajout de l'image d'observation via une image d'identification",
                        "err": err,
                        "idClient": API.clientId,
                    });
                    reject(err);
                });
        }

        return new Promise(promise);
    },

    /**
     * fonction permettant de désactiver une liste d'observation en BDD (la désactivation est une forme de suppression ici)
     */
    disableObservationList(oservationIdListToDisable = null) {

        let promise = function (resolve, reject) {

            if (!oservationIdListToDisable || !Array.isArray(oservationIdListToDisable) || (oservationIdListToDisable.length <= 0)) {
                reject('la liste d\'observation à désactiver est nulle ou vide');
                return;
            }

            let paramSearch = {
                idClient: API.clientId,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${API.token}`,
            };

            // @@ testForUpdateToken

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiObservation}disable`; // on récupère la base URL
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: oservationIdListToDisable })
                .then((response) => {
                    if (response && response.observationIdListDisabled) {
                        resolve(response.observationIdListDisabled);
                    }
                    else {
                        sendError('observation_web_api_provider-disableObservationList', {
                            "erreur": "une erreur s'est produite lors de la désactivation d'une liste d'ID d'observation",
                            "err": 'response nulle',
                            "idClient": API.clientId,
                        });
                        reject("une erreur s'est produite lors de la désactivation d'une liste d'ID d'observation");
                    }
                })
                .catch((err) => {
                    sendError('observation_web_api_provider-disableObservationList', {
                        "erreur": "une erreur s'est produite lors de la désactivation d'une liste d'ID d'observation",
                        "err": err,
                        "idClient": API.clientId,
                    });
                    reject(err);
                });
        }

        return new Promise(promise);
    },

    /**
     * fonction permettant de supprimer une liste d'observation en BDD (la désactivation est une forme de suppression ici)
     */
    deleteObservationList(observationIdListToDelete = null) {

        let promise = function (resolve, reject) {

            if (!observationIdListToDelete || !Array.isArray(observationIdListToDelete) || (observationIdListToDelete.length <= 0)) {
                reject('la liste d\'observation à supprimer est nulle ou vide');
                return;
            }

            let paramSearch = {
                idClient: API.clientId,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${API.token}`,
            };

            // @@ testForUpdateToken

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiObservation}delete`; // on récupère la base URL
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: observationIdListToDelete })
                .then((response) => {
                    if (response && response.observationIdListDeleted) {
                        resolve(response.observationIdListDeleted);
                    }
                    else {
                        sendError('observation_web_api_provider-deleteObservationList', {
                            "erreur": "une erreur s'est produite lors de la suppression d'une liste d'ID d'observation",
                            "err": 'response nulle',
                            "idClient": API.clientId,
                        });
                        reject("une erreur s'est produite lors de la suppression d'une liste d'ID d'observation");
                    }
                })
                .catch((err) => {
                    sendError('observation_web_api_provider-deleteObservationList', {
                        "erreur": "une erreur s'est produite lors de la suppression d'une liste d'ID d'observation",
                        "err": err,
                        "idClient": API.clientId,
                    });
                    reject(err);
                });
        }

        return new Promise(promise);
    },

    /**
     * fonction permettant de sauvegarder temporairement une image d'identification sur Azure
     */
    addIdentificationImage(identificationImage = null) {

        let promise = function (resolve, reject) {

            if (!identificationImage || !(identificationImage instanceof IdentificationImage)) {
                reject('Il faut une image d\'identification.');
                return;
            }

            let paramSearch = {
                idClient: API.clientId,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${API.token}`,
            };

            // @@ testForUpdateToken

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiObservation}Identification/addImage`; // on récupère la base URL
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: identificationImage })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    }
                    else {
                        sendError('observation_web_api_provider-addIdentificationImage', {
                            "erreur": "une erreur s'est produite lors de la sauvegarde de l'image d'identification",
                            "err": 'response nulle',
                            "idClient": API.clientId,
                        });
                        reject("une erreur s'est produite lors de la sauvegarde de l'image d'identification");
                    }
                })
                .catch((err) => {
                    sendError('observation_web_api_provider-addIdentificationImage', {
                        "erreur": "une erreur s'est produite lors de la sauvegarde de l'image d'identification",
                        "err": err,
                        "idClient": API.clientId,
                    });
                    reject(err);
                });
        }

        return new Promise(promise);
    },

    /**
     * fonction permettant de supprimer les images d'identification sur azure
     */
    deleteIdentificationImage() {

        let promise = function (resolve, reject) {

            let paramSearch = {
                idClient: API.clientId,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${API.token}`,
            };
            // @@ testForUpdateToken

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiObservation}Identification/delete/${API.clientId}`; // on récupère la base URL
            RequestCalls.requestWithAuthent(requestURL, { method: 'DELETE', headers: headersAuthent, params: paramSearch })
                .then((response) => {
                    if (response) {
                        resolve();
                    }
                    else {
                        sendError('observation_web_api_provider-deleteIdentificationImage', {
                            "erreur": "une erreur s'est produite lors de la suppression des images d'identification",
                            "err": 'response nulle',
                            "idClient": API.clientId,
                        });
                        reject("une erreur s'est produite lors de la suppression des images d'identification");
                    }
                })
                .catch((err) => {
                    sendError('observation_web_api_provider-deleteIdentificationImage', {
                        "erreur": "une erreur s'est produite lors de la suppression des images d'identification",
                        "err": err,
                        "idClient": API.clientId,
                    });
                    reject(err);
                });
        }

        return new Promise(promise);
    },


    /**
     * fonction permettant de d'identifier une plante grâce à une liste d'image d'identification
     */
    searchPlant(identificationImages) {

        let promise = function (resolve, reject) {

            if (!identificationImages || !Array.isArray(identificationImages) || (identificationImages.length <= 0)) {
                console.log(identificationImages)
                reject('Pour reconnaître une plante, il faut au moins une image d\'identification.');
                return;
            }

            let paramSearch = {
                idClient: API.clientId,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${API.token}`,
            };

            // @@ testForUpdateToken

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiObservation}Identification`; // on récupère la base URL
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: identificationImages })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    }
                    else {
                        sendError('observation_web_api_provider-searchPlant', {
                            "erreur": "une erreur s'est produite lors de l'identification de la plante",
                            "err": 'response nulle',
                            "idClient": API.clientId,
                        });
                        reject("une erreur s'est produite lors de l'identification de la plante");
                    }
                })
                .catch((err) => {
                    sendError('observation_web_api_provider-searchPlant', {
                        "erreur": "une erreur s'est produite lors de l'identification de la plante",
                        "err": err,
                        "idClient": API.clientId,
                    });
                    reject(err);
                });
        }

        return new Promise(promise);
    },
}

/**
 * Objet de connexion à l'API spotifarm dans le cadre du didacticiel FirstParcel
 */
const didacticiel_firstparcel_web_api_provider = {
    /**
     * Fonction permettant de connaitre l'étape en cours du didacticiel FirstParcel
     */
    getCurrentStepTutorialFirstParcel: function (clientId) {
        let promise = function (resolve, reject) {
            let paramSearch = {
                idClient: clientId
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${API.token}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiTutorialFP}GetCurrentStep/${paramSearch.idClient}`;
            RequestCalls.requestWithAuthent(requestURL, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => {
                    try {
                        resolve(response);
                    }
                    catch (err) {
                        reject(err);
                    }
                })
                .catch((err) => reject(err));

        }
        return new Promise(promise);
    },


    /**
     * fonction permettant de sauvegarder le didacticiel complété en BDD
     */
    saveCurrentStepTutorialFirstParcel: function (stepToGo, status) {
        let promise = function (resolve, reject) {
            let paramSearch = {
                idClient: API.clientId,
                currentStep: stepToGo,
                status: status
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${API.token}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiTutorialFP}SaveCurrentStep/${API.clientId}`;
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch })
                .then((response) => {
                    try {
                        resolve(response);
                    }
                    catch (err) {
                        reject(err);
                    }
                })
                .catch((err) => reject(err));
        }

        return new Promise(promise);
    },

    /**
     * fonction permettant de sauvegarder le didacticiel complété en BDD
     */
    SetDidacticielAsCompleted: function(stepToGo, status) {
        let promise = function (resolve, reject) {
            let paramSearch = { 
                idClient: API.clientId,
                currentStep: stepToGo,
                status: status
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${API.token}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiTutorialFP}SetDidacticielAsCompleted/${API.clientId}`;
            RequestCalls.requestWithAuthent(requestURL , { method: 'POST', headers: headersAuthent, params: paramSearch })
                .then((response) => {
                    try {
                        resolve(response);
                    }
                    catch(err) { 
                        reject(err);
                    }
                })
                .catch( (err) => reject(err));
        }
        
        return new Promise(promise);
    },
}

/**
 * Objet de connexion à l'API spotifarm sur des données générales
 */
const global_web_api_provider = {

    /**
     * fonction permettant de récupérer les infos de version : Version de l'appli web (= de la Web API aussi) ET la version minimale de l'appli native compatible
     */
    getVersioning() {
        let promise = function (resolve, reject) {
            let paramSearch = {
                idClient: 8888888, // on en fournis une fausse car la méthoide d'API n'est pas protégée !!!
            };
            let headersAuthent = {
                withCredentials: false,
                //credentials: 'include',
                //Authorization: `Bearer ${API.token}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiVersioning}`; // on récupère la base URL
            RequestCalls.request(requestURL, { method: 'GET', headers: headersAuthent, params: paramSearch, })
                .then((response) => { //Object C# de type 'Versioning' !
                    if (response) {
                        resolve(response);
                    }
                    else {
                        let error = "une erreur s'est produite lors de la demande de récupération du versioning applicatif"
                        sendError('global_web_api_provider-versioning', { "erreur": error, "idClient": API.clientId });
                        reject(error);
                    }
                })
                .catch((err) => {
                    sendError('global_web_api_provider-versioning', {
                        "erreur": "une erreur s'est produite lors de la demande de récupération du versioning applicatif",
                        "err": err,
                    });
                    reject(err);
                });
        }

        return new Promise(promise);
    },

    authenticateFromNativeApp(account, needUpdatePwd) {
        let promise = function (resolve, reject) {
            let paramSearch = {
                idClient: 9999999, // C'est justement une des principales informations que l'on recherche !!! 
            };
            let headersAuthent = {
                withCredentials: false,
                //credentials: 'include',
                //Authorization: `Bearer ${API.token}`,
            };

            if (needUpdatePwd === true) {
                paramSearch.updatePwd = 1;
            }

            if (account) {
                let nameCountry = 'France';
                //let codeCountry = 'FR';
                if (account.country && (typeof(account.country) !== 'string')) {
                    try {
                        if (account.country.name && (account.country.name !== '')) {
                            nameCountry = account.country.name;
                        }
                        //if (account.country.codeCountry && (account.country.codeCountry !== '')) {
                        //    codeCountry = account.country.codeCountry;
                        //}
                    } catch (error) { }

                    account.country = nameCountry;
                    //account.countryCode = codeCountry; //pas exploité à la création de compte dans l'AD Azure... Uniquement lors de la création des données en BdD !
                }
            }

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiAccounts}NativeAuthenticate`; // on récupère la base URL
            RequestCalls.request(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: account, })
                .then((response) => { //Object C# de type 'AccountAndToken' !
                    if (response) {
                        resolve(response);
                    }
                    else {
                        let error = "une erreur s'est produite lors de l'authentification via les données de l'application native"
                        sendError('global_web_api_provider-authenticateFromNativeApp', { "erreur": error, "idClient": API.clientId });
                        reject(error);
                    }
                })
                .catch((err) => {
                    sendError('global_web_api_provider-authenticateFromNativeApp', {
                        "erreur": "une erreur s'est produite lors de la demande d'authentification via les données de l'application native",
                        "err": err,
                    });
                    reject(err);
                });
        }

        return new Promise(promise);
    }
}

/**
 * Objet de connexion à l'API spotifarm dans le cadre de la modulation
 */
const modulation_web_api_provider = {
    /**
     *  Fonction permettant de rechercher les modulation de l'utilisateur connecté à SpotiFarm.
     */
    loadModulations: function (dispatch, getState, idClient) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0) || (idOfClient !== idClient)) {
                reject(StringTranslate.noLoadModulationDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiModulations}Client/${idOfClient}`;
        
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit une liste d'entité 'ModulationData'
                    if (response) { // si reponse - les modulations existent en BDD
                        // liste des modulations => transformation dico (parcelId - modulationData)
                        const dicoFromListOfModulation = ModulationsHelper.convertToDico(response); 
                        resolve(dicoFromListOfModulation);
                    } else {
                        reject(StringTranslate.badLoadModulationDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadModulations', {
                            "erreur": "une erreur s'est produite lors de la recherche des modulations côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadModulationDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadModulations', {
                            "erreur": "une erreur s'est produite lors de la recherche des modulations côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadModulationDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant d'obtenir le flux de l'image associée à une modulation 
     */
    loadImagModulation: function (dispatch, getState, modulationSelected) {
        let promise = function (resolve, reject) {

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0) || 
                (!modulationSelected) || (idOfClient !== modulationSelected.clientId)) {
                reject(StringTranslate.noLoadModulationDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            // on prépare la requête
            let paramSearch = {
                idClient: idOfClient,
                resultObject: 1, //=> Demande de retourner l'objet directement ! (pas le retour HTTP de type fichier téléchagé)
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiModulations}DownloadImag/${modulationSelected.clientId}/${modulationSelected.parcelId}`;
            RequestCalls.requestWithAuthent(requestURL, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => {
                    if (response && (response.id === modulationSelected.id) && 
                    response.dataImg && (response.sizeImg > 0)) {
                        /*const newModulationSelected = response;
                        //transforme la date (caîne en entité 'Date'):
                        newModulationSelected.creationDate = (newModulationSelected.creationDate && (!isNaN(Date.parse(newModulationSelected.creationDate)))) ?
                            new Date(newModulationSelected.creationDate) : new Date();
                        newModulationSelected.dateImagSource = (newModulationSelected.dateImagSource && (!isNaN(Date.parse(newModulationSelected.dateImagSource)))) ?
                            new Date(newModulationSelected.dateImagSource) : new Date();*/
                        const newModulationSelected = ModulationsHelper.convertItem(response);
                        
                        resolve(newModulationSelected);
                    } else {
                        if (sendError) {
                            sendError('loadImagFile', {
                                "erreur": "le retour du téléchargement de l'image d'une la modulation n'a pas fournis entité attendue",
                                "err": (response && response.dataImg) ? "'modulation.dataImg' is void!" : "'modulation' is undefined!",
                                "idOfClient": idOfClient,
                                "idOfParcel": modulationSelected.parcelId,
                            });
                        }

                        reject(StringTranslate.badLoadModulationDatas);
                        return;
                    }
                })
                .catch((err) => {
                    sendError('loadImagFile', {
                        "erreur": "une erreur s'est produite lors de l'obtension de l'image d'une modulation côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                        "idOfParcel": modulationSelected.parcelId,
                    });

                    reject(StringTranslate.badLoadModulationDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de générer le fichier Pdf de cette modulation 
     */
     GenerateModulationPdf: function (dispatch, getState, modulationSelected, askObject = false, askStore = false) {
        let promise = function (resolve, reject) {

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0) || 
                (!modulationSelected) || (idOfClient !== modulationSelected.clientId)) {
                reject(StringTranslate.noLoadModulationDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            // Désormais, on permet à l'utilisateur d'obtenir une visualisation des PDF ! Pour cela, on télécharge le fichier puis demande son ouverture dans un nouvel onglet...
            // RQ : Dans le cas de l'appli web hébergée dans l'appli mobile, on forcera à obtenir le flux brute pour le transmettre à l'appli mobile pour gérer l'affichage.
            // (pas le retour HTTP de type fichier téléchagé car cela déclenche des actions non-souhaitées de la part de la Web View)

            // on prépare la requête
            let paramSearch = {
                idClient: idOfClient,
                //par défaut, on télécharge le fichier Pdf... au sens où l'on ne demande pas l'entité contenant le flux brute du fichier
                resultObject: ((askStore !== true) && (askObject === true)) ? 1 : 0, //DONC, on ne demande pas la récupération de l'entité...
                storeFile: (askStore === true) ? 1 : 0, //ET DONC, on ne demande pas non-plus son stockage dans Azure...
            };
            //Cas de l'héberguement par notre application mobile => On demande à retourner l'objet/fichier directement ! 
            const isOnNativeApp = (IsNativeHoster() === true) ? true : false;
            //A partir du moment où l'on est hébergé par l'appli mobile, on force le stockage sur le compte Azure ! 
            // -> L'utilisateur pourra alors faire le choix de télécharger/imprimer/partager depuis l'affichage du fichier.
            if (isOnNativeApp === true) { 
                //demande le stockage du fichier (pour qu'il puisse être affiché sur le mobile).
                paramSearch.resultObject = 0;
                paramSearch.storeFile = 1;
            }

            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiModulations}Pdf/${modulationSelected.clientId}/${modulationSelected.id}`;
            RequestCalls.requestWithAuthent(requestURL, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((blobAndMetasOrObjectOrUrl) => {
                    //SI on est en mode 'appli mobile' OU demande de stockage du fichier (pour affichage), 
                    // on reçoit une URL du lieu de stockage dans Azure !
                    if (paramSearch.storeFile === 1) { 
                        if (isOnNativeApp === true) { //Si depuis l'appli native
                            //On va transmettre à l'appli Native cette URL pour lancer l'affichage !!!
                            resolve(blobAndMetasOrObjectOrUrl);
                        } else {
                            try {
                                /*const link = document.createElement('a');
                                link.href = blobAndMetasOrObjectOrUrl;
                                link.setAttribute('target', "_blank");
                                link.setAttribute('rel', "noopener noreferrer");

                                try {
                                    // Append to html page
                                    document.body.appendChild(link);
                                    
                                    // Force download
                                    link.click();
                                }
                                finally {
                                    // Clean up and remove the link
                                    link.parentNode.removeChild(link);

                                    resolve(true);
                                }*/ //ne semble pas toujours fonctionner dans une promesse (ou bloquer en tant que popup) !

                                window.open(blobAndMetasOrObjectOrUrl, "_blank", "noopener noreferrer");
                                resolve(true);
                            }
                            catch(errLink) {
                                if (sendError) {
                                    sendError('GenerateModulationPdf', {
                                        "erreur": "une erreur s'est produite suite au téléchargement du PDF de cette modulation côté API",
                                        "errLink": errLink,
                                        "urlRequest": requestURL,
                                    });
                                }

                                reject(StringTranslate.badShowPdfModulation);
                            }
                        }
                    } else if (paramSearch.resultObject === 1) { //Sinon, si on a demandé l'entité, 
                        //On l'a renvoie à l'appelant:
                        resolve(blobAndMetasOrObjectOrUrl);
                    } else { // Soit on reçoit le flux du fichier accompagné de ses caractèristiques !
                        //Cf. https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e :
                        if (blobAndMetasOrObjectOrUrl && blobAndMetasOrObjectOrUrl.data) {
                            blobAndMetasOrObjectOrUrl.data.blob()
                                .then((dataBlobed) => {
                                    let isOk = true;
                                    try {
                                        // Create blob link to download (Attention : Affiche le flux brute, non-interprêté, si on ne spécifie pas le 'type') :
                                        const url = window.URL.createObjectURL(new Blob([dataBlobed], { type: 'application/pdf' }));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', blobAndMetasOrObjectOrUrl.fileName);

                                        try {
                                            // Append to html page
                                            document.body.appendChild(link);
                                            
                                            // Force download
                                            link.click();
                                        }
                                        finally {
                                            // Clean up and remove the link
                                            link.parentNode.removeChild(link);

                                            resolve(true);
                                        }
                                    }
                                    catch (errorDetected) {
                                        isOk = false;
                                        if (sendError) {
                                            sendError('GenerateModulationPdf', {
                                                "erreur": "une erreur s'est produite suite au téléchargement du PDF de cette modulation côté API",
                                                "errorDetected": errorDetected,
                                                "urlRequest": requestURL,
                                            });
                                        }

                                        reject(StringTranslate.badShowPdfModulation);
                                    }

                                    if (isOk) resolve(isOk);
                                })
                                .catch((errorBlob) => {
                                    if (sendError) {
                                        sendError('GenerateModulationPdf', {
                                            "erreur": "une erreur s'est produite lors de la génération du fichier PDF de cette modulation côté API",
                                            "errorBlob": errorBlob,
                                            "urlRequest": requestURL,
                                        });
                                    }

                                    //Fin des téléchargements:
                                    //Retourne que l'on n'a pas atteint notre but:
                                    reject(StringTranslate.badGeneratePdfModulation);
                                });
                        }
                        else //on ne fait rien !
                            reject(StringTranslate.badGeneratePdfModulation);
                    }
                })
                .catch((err) => {
                    sendError('GenerateModulationPdf', {
                        "erreur": "une erreur s'est produite lors de la demande de génération du fichier PDF de cette modulation côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                        "idOfParcel": modulationSelected.parcelId,
                    });

                    reject(StringTranslate.badGeneratePdfModulation);
                    return;
                });
        }

        return new Promise(promise);
    },
    
    /**
     *  Fonction permettant de récupérer les settings de la dernière modulation l'utilisateur.
     */
    getLastModulationSettings: function (dispatch, getState, idClient) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadModulationDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiModulations}Last/Client/${idOfClient}`;
        
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit une entité 'ModulationData'
                    if (response) { // si reponse - la modulation existe en BDD
                        // les settings de la modulation
                        //resolve(response);
                        const thisModulationSettings = ModulationsHelper.convertSettingsItem(response);
                        resolve(thisModulationSettings);
                    } else {
                        reject(StringTranslate.badLoadModulationDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadModulation', {
                            "erreur": "une erreur s'est produite lors de la recherche des settings de la modulation côté API",
                            "error": error,
                            "idOfClient": idOfClient
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadModulationDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadModulation', {
                            "erreur": "une erreur s'est produite lors de la recherche des settings de la modulation côté API",
                            "err": err,
                            "idOfClient": idOfClient
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadModulationDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  fonction permettant de rechercher la modulation de l'utilisateur connecté à SpotiFarm.
     */
    loadModulationByParcelId: function (dispatch, getState, parcelId) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((!idOfClient) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadModulationDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiModulations}Client/${idOfClient}/Parcel/${parcelId}`;

            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit une entité 'ModulationData'
                    if (response) { // si reponse - la modulation existe en BDD
                        // la modulations 
                        //resolve(response);
                        const thisModulation = ModulationsHelper.convertItem(response);
                        resolve(thisModulation);
                    } else {
                        reject(StringTranslate.badLoadModulationDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadModulation', {
                            "erreur": "une erreur s'est produite lors de la recherche de la modulation côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                            "parcelId": parcelId
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadModulationDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadModulation', {
                            "erreur": "une erreur s'est produite lors de la recherche de la modulation côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                            "parcelId": parcelId
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadModulationDatas);
                    return;
                });
        }

        return new Promise(promise);
    }, 
            
    /**
     *  Fonction permettant de rechercher la modulation de l'utilisateur connecté à SpotiFarm.
     */
    loadModulation: function (dispatch, getState, idModulation) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadModulationDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiModulations}Client/${idOfClient}/${idModulation}`;
        
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit une entité 'ModulationData'
                    if (response) { // si reponse - la modulation existe en BDD
                        // la modulations 
                        //resolve(response);
                        const thisModulation = ModulationsHelper.convertItem(response);
                        resolve(thisModulation);
                    } else {
                        reject(StringTranslate.badLoadModulationDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadModulation', {
                            "erreur": "une erreur s'est produite lors de la recherche de la modulation côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                            "idModulation": idModulation
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadModulationDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadModulation', {
                            "erreur": "une erreur s'est produite lors de la recherche de la modulation côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                            "idModulation": idModulation
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadModulationDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de sauvegrader une modulation en BDD 
     */
    saveModulation: function (dispatch, getState, modulationToSave, withDoses = false) {
        let promise = function (resolve, reject) {

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0) || (idOfClient !== modulationToSave.clientId)) {
                reject(StringTranslate.noLoadModulationDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            // on prépare la requête
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            //On reçoit de la part de l'appli ReactJs une chaîne. Mais exploit un boolean dans la Web API !
            if (modulationToSave) { //@@A changer pour exploiter partout un boolean !
                if (modulationToSave.supplyType === 'Liquid') {
                    modulationToSave.supplyType = true;
                } else {
                    modulationToSave.supplyType = false; // === 'Solid' !
                }
            }

            //Définit si on nous demande l'enregistrement des données permettant de produire l'image...
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiModulations}AddNewDataImag/${idOfClient}`;
            if (withDoses === true) //ou l'image et les doses...
                requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiModulations}AddNewDataDoses/${idOfClient}`;

            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: modulationToSave })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    }
                    else {
                        reject(StringTranslate.badSaveModulationDatas);
                    }
                })
                .catch((err) => {
                    sendError('saveParcel', {
                        "erreur": "une erreur s'est produite lors de l'enregistrement d'UNE modulation côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                    });
                    reject(StringTranslate.badSaveModulationDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de supprimer une modulation (et toutes les données associées) de l'utilisateur connecté à SpotiFarm.
     */
    deleteModulation: function (dispatch, getState, modulationId) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noDeleteDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noDeleteDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noDeleteDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiModulations}Delete/${idOfClient}/${modulationId}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'DELETE', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit 'vrai' (ou 'faux') !
                    if (response === true) {
                        resolve(response);
                    } else {
                        reject(StringTranslate.badDeleteModulationDatas);
                    }
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('deleteModulation', {
                            "erreur": "une erreur s'est produite lors de la suppression de la modulation côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                            "modulationId": modulationId
                        });
                    }

                    reject(StringTranslate.badDeleteModulationDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de supprimer une ou plusieurs modulations (et toutes les données associées) de l'utilisateur connecté à SpotiFarm.
     */
    deleteModulations: function (dispatch, getState, modulationIds) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noDeleteDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noDeleteDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noDeleteDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiModulations}Delete/${idOfClient}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'DELETE', headers: headersAuthent, params: paramSearch, body: modulationIds })
                .then((response) => { // on reçoit 'vrai' (ou 'faux') !
                    if (response === true) {
                        resolve(response);
                    } else {
                        reject(StringTranslate.badDeleteModulationDatas);
                    }
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('deleteModulations', {
                            "erreur": "une erreur s'est produite lors de la suppression des modulations côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                            "modulationIds": modulationIds
                        });
                    }

                    reject(StringTranslate.badDeleteModulationDatas);
                    return;
                });
        }

        return new Promise(promise);
    },


    /**
     * Fonction permettant de générer, à partir des paramètres, 
     * la modulation (image) d'une parcelle d'un client et dont le retour contient l'image et les doses de zones.
     * @param dispatch 
     * @param getState 
     * @param idClient
     * @param modulationParameter 
     */
    buildModulationSample: function (dispatch, getState, idClient, modulationParameter) {
        
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0) || (idOfClient !== idClient)) {
                reject(StringTranslate.noLoadModulationDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiModulations}BuildSample/${idOfClient}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            //On reçoit de la part de l'appli ReactJs une chaîne. Mais exploit un boolean dans la Web API !
            if (modulationParameter) { //@@A changer pour exploiter partout un boolean !
                if (modulationParameter.supplyType === 'Liquid') {
                    modulationParameter.supplyType = true;
                } else {
                    modulationParameter.supplyType = false; // === 'Solid' !
                }
            }

            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: modulationParameter })
                .then((response) => { // on reçoit une liste d'entité 'Modulation'
                    if (response) { // si reponse - les modulations existent en BDD
                        if (response && response.dateImagSource) { //convertit en date !
                            response.dateImagSource =  (response.dateImagSource instanceof Date) ? response.dateImagSource : new Date(response.dateImagSource);
                        }
                        resolve(response);
                    } else {
                        reject(StringTranslate.badPartialBuildModulation);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('buildModulationSample', {
                            "erreur": "une erreur s'est produite lors de la génération de la modulation côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badPartialBuildModulation);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('buildModulationSample', {
                            "erreur": "une erreur s'est produite lors de la génération de la modulation côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badPartialBuildModulation);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     * Fonction permettant de générer, à partir des paramètres, 
     * la modulation (image) d'une parcelle d'un client et dont le retour contient l'image et les doses de zones.
     * @param dispatch 
     * @param getState 
     * @param idClient
     * @param modulationParameter 
     */
    buildModulationSampleAndDoses: function (dispatch, getState, idClient, modulationParameter) {
        
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0) || (idOfClient !== idClient)) {
                reject(StringTranslate.noLoadModulationDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiModulations}BuildSampleAndDoses/${idOfClient}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            //On reçoit de la part de l'appli ReactJs une chaîne. Mais exploit un boolean dans la Web API !
            if (modulationParameter) { //@@A changer pour exploiter partout un boolean !
                if (modulationParameter.supplyType === 'Liquid') {
                    modulationParameter.supplyType = true;
                } else {
                    modulationParameter.supplyType = false; // === 'Solid' !
                }
            }

            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: modulationParameter })
                .then((response) => { // on reçoit une liste d'entité 'Modulation'
                    if (response) { // si reponse - les modulations existent en BDD
                        if (response.dateImagSource) { //convertit en date !
                            response.dateImagSource =  (response.dateImagSource instanceof Date) ? response.dateImagSource : new Date(response.dateImagSource);
                        }
                        response.supplyType = response.supplyType === true ? 'Liquid' : 'Solid';

                        resolve(response);
                    } else {
                        reject(StringTranslate.badPartialBuildModulation);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('buildModulationSampleAndDoses', {
                            "erreur": "une erreur s'est produite lors de la génération de la modulation côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badPartialBuildModulation);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('buildModulationSampleAndDoses', {
                            "erreur": "une erreur s'est produite lors de la génération de la modulation côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badPartialBuildModulation);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     * Méthode générant, à partir des paramètres, 
     * la modulation d'une parcelle d'un client et dont le retour 
     * contient l'image
     * @param dispatch 
     * @param getState 
     * @param modulationPrescriptionList 
     * @param outputFormat 
     */
     buildListPrescriptions: function (dispatch, getState, modulationPrescriptionList, exportFormat) { 
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadModulationDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //Export Shape par défaut !
            if ((!exportFormat) || (exportFormat <= 0)) {
                exportFormat = 1;
            }

            //On demande la liste des parcelles associées au client connecté :
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiModulations}BuildListPrescriptions/${idOfClient}`;
            let paramSearch = {
                idClient: idOfClient,
                outputFormat: exportFormat,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            //On reçoit de la part de l'appli ReactJs une chaîne. Mais exploit un boolean dans la Web API !
            if (modulationPrescriptionList) {
                modulationPrescriptionList.forEach(modulationParameter => { //@@A changer pour exploiter partout un boolean !
                    if (modulationParameter.supplyType === 'Liquid') {
                        modulationParameter.supplyType = true;
                    } else {
                        modulationParameter.supplyType = false; // === 'Solid' !
                    }
                })
            }
            
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: modulationPrescriptionList })
                .then((blobAndMetas) => { // on reçoit le flux du fichier accompagné de ses caractèristiques !
                    //Cf. https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e :
                        let isOk = true;
                    if (blobAndMetas && blobAndMetas.data) {
                        blobAndMetas.data.blob()
                            .then((dataBlobed) => {
                                try {
                                    // Create blob link to download
                                    const url = window.URL.createObjectURL(new Blob([dataBlobed]));
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', blobAndMetas.fileName);
                                    try {
                                        // Append to html page
                                        document.body.appendChild(link);
                                        
                                        // Force download
                                        link.click();
                                    }
                                    finally {
                                        // Clean up and remove the link
                                        link.parentNode.removeChild(link);
                                    }
                                }
                                catch (errorDetected) {
                                    isOk = false;
                                    if (sendError) {
                                        sendError('BuildListPrescriptions', {
                                            "erreur": "une erreur s'est produite suite au téléchargement de la modulation côté API",
                                            "err": errorDetected,
                                            "urlRequest": requestURL,
                                        });
                                    }
                                }

                                //Fin des téléchargements:
                                //Retourne que c'est Ok OU que l'on n'a pas atteint notre but:
                                resolve((isOk === true) ? true : false);
                            })
                            .catch((errorBlob) => {
                                if (sendError) {
                                    sendError('BuildListPrescriptions', {
                                        "erreur": "une erreur s'est produite lors de l'obtention du flux binaire lors du téléchargement de la modulation côté API",
                                        "err": errorBlob,
                                        "urlRequest": requestURL,
                                    });
                                }

                                isOk = false;

                                //Fin des téléchargements:
                                //Retourne que l'on n'a pas atteint notre but:
                                resolve(false);
                            });
                    } else {
                        if (sendError) {
                            sendError('BuildListPrescriptions', {
                                "erreur": "le retour du téléchargement de la modulation n'a pas fournis entité attendue",
                                "err": (blobAndMetas) ? "'blobAndMetas.data' is void!" : "'blobAndMetas' is undefined!",
                                "urlRequest": requestURL,
                            });
                        }

                        isOk = false;

                        //Fin des téléchargements:
                        //Retourne que l'on n'a pas atteint notre but:
                        resolve(false);
                    }
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('BuildListPrescriptions', {
                            "erreur": "une erreur s'est produite lors du téléchargement de la modulation côté API",
                            "err": err,
                            "urlRequest": requestURL,
                        });
                    }

                    reject(false);
                    return;
                });
        }

        return new Promise(promise);
    },
}

/**
 * Objet de connexion à l'API spotifarm dans le cadre de la pesée de biomasse colza
 */
const biomass_web_api_provider = {
    /**
     *  Fonction permettant d'obtenir le flux de l'image associée à une pesée de biomasse colza 
     */
     loadImagBiomass: function (dispatch, getState, biomassSelected) {
        let promise = function (resolve, reject) {

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0) || 
                (!biomassSelected) || (idOfClient !== biomassSelected.parameter.clientId)) {
                reject(StringTranslate.noLoadWeightingBiomassDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            // on prépare la requête
            let paramSearch = {
                idClient: idOfClient,
                resultObject: 1, //=> Demande de retourner l'objet directement ! (pas le retour HTTP de type fichier)
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiWeightingBiomasses}DownloadImag/${biomassSelected.parameter.clientId}/${biomassSelected.id}`;
            RequestCalls.requestWithAuthent(requestURL, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => {
                    if (response && (response.id === biomassSelected.id) && 
                    response.dataImg && (response.sizeImg > 0)) {
                  
                        const biomassSelected = BiomassesHelper.convertItem(response);
                        
                        resolve(biomassSelected);
                    } else {
                        if (sendError) {
                            sendError('loadImagBiomass', {
                                "erreur": "le retour du téléchargement de l'image d'une la pesée de biomasse colza n'a pas fournis entité attendue",
                                "err": (response && response.dataImg) ? "'weightingBiomass.dataImg' is void!" : "'weightingBiomass' is undefined!",
                                "idOfClient": idOfClient,
                                "idOfParcel": biomassSelected.parameter.parcelId,
                            });
                        }

                        reject(StringTranslate.badLoadWeightingBiomassDatas);
                        return;
                    }
                })
                .catch((err) => {
                    sendError('loadImagBiomass', {
                        "erreur": "une erreur s'est produite lors de l'obtension de l'image d'une pesée de biomasse colza côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                        "idOfParcel": biomassSelected.parameter.parcelId,
                    });

                    reject(StringTranslate.badLoadWeightingBiomassDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de rechercher les pesées de biomasse colza de l'utilisateur connecté à SpotiFarm.
     */
     loadBiomasses: function (dispatch, getState, idClient) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0) || (idOfClient !== idClient)) {
                reject(StringTranslate.noLoadWeightingBiomassDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiWeightingBiomasses}Client/${idOfClient}`;
        
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit une liste d'entité 'ModulationData'
                    if (response) { // si reponse - les pesées de biomasse colza existent en BDD
                        // liste des pesées de biomasse colza => transformation dico (parcelId - biomassData)
                        const dicoFromListOfBiomass = BiomassesHelper.convertToDico(response); 
                        resolve(dicoFromListOfBiomass);
                    } else {
                        reject(StringTranslate.badLoadWeightingBiomassDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadBiomasses', {
                            "erreur": "une erreur s'est produite lors de la recherche des pesées de biomasse colza côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadWeightingBiomassDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadBiomasses', {
                            "erreur": "une erreur s'est produite lors de la recherche des pesées de biomasse colza côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadWeightingBiomassDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de supprimer une pesée de colza (et toutes les données associées) de l'utilisateur connecté à SpotiFarm.
     */
    deleteBiomass: function (dispatch, getState, biomassId) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noDeleteDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noDeleteDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noDeleteDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiWeightingBiomasses}Delete/${idOfClient}/${biomassId}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'DELETE', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit 'vrai' (ou 'faux') !
                    if (response === true) {
                        resolve(response);
                    } else {
                        reject(StringTranslate.badDeleteBiomassDatas);
                    }
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('deleteBiomass', {
                            "erreur": "une erreur s'est produite lors de la suppression de la biomasse côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                            "biomassId": biomassId
                        });
                    }

                    reject(StringTranslate.badDeleteBiomassDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de supprimer une ou plusieurs modulations (et toutes les données associées) de l'utilisateur connecté à SpotiFarm.
     */
    deleteBiomasses: function (dispatch, getState, biomassIds) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noDeleteDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noDeleteDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noDeleteDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiWeightingBiomasses}Delete/${idOfClient}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'DELETE', headers: headersAuthent, params: paramSearch, body: biomassIds })
                .then((response) => { // on reçoit 'vrai' (ou 'faux') !
                    if (response === true) {
                        resolve(response);
                    } else {
                        reject(StringTranslate.badDeleteBiomassDatas);
                    }
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('deleteBiomasses', {
                            "erreur": "une erreur s'est produite lors de la suppression des biomasses côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                            "biomassIds": biomassIds
                        });
                    }

                    reject(StringTranslate.badDeleteBiomassDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    buildBiomassSampleAndQuantities: function (dispatch, getState, idClient, biomassParameter) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0) || (idOfClient !== idClient)) {
                reject(StringTranslate.noLoadBiomassDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiWeightingBiomasses}BuildSampleAndQuantities/${idOfClient}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: biomassParameter })
                .then((response) => { // on reçoit une liste d'entité 'Biomass'
                    if (response) { // si reponse - les biomasses existent en BDD
                        if (response && response.dateImagSource) { //convertit en date !
                            response.dateImagSource =  (response.dateImagSource instanceof Date) ? response.dateImagSource : new Date(response.dateImagSource);
                        }
                        resolve(response);
                    } else {
                        reject(StringTranslate.badPartialBuildBiomass);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('buildBiomassSampleAndQuantities', {
                            "erreur": "une erreur s'est produite lors de la génération de la biomasse côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badPartialBuildBiomass);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('buildBiomassSampleAndQuantities', {
                            "erreur": "une erreur s'est produite lors de la génération de la biomasse côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badPartialBuildBiomass);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de générer le fichier Pdf de la(les) pesée(s) de biomasse pour une parcelle donnée 
     */
     GenerateBiomassPdf: function (dispatch, getState, biomassSelected, askObject = false, askStore = false) {
        let promise = function (resolve, reject) {

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0) || 
                (!biomassSelected) || (!biomassSelected.parameter) || (idOfClient !== biomassSelected.parameter.clientId)) {
                reject(StringTranslate.noGeneratePdfBiomassWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            // Désormais, on permet à l'utilisateur d'obtenir une visualisation des PDF ! Pour cela, on télécharge le fichier puis demande son ouverture dans un nouvel onglet...
            // RQ : Dans le cas de l'appli web hébergée dans l'appli mobile, on forcera à obtenir le flux brute pour le transmettre à l'appli mobile pour gérer l'affichage.
            // (pas le retour HTTP de type fichier téléchagé car cela déclenche des actions non-souhaitées de la part de la Web View)

            // on prépare la requête
            let paramSearch = {
                idClient: idOfClient,
                //par défaut, on télécharge le fichier Pdf... au sens où l'on ne demande pas l'entité contenant le flux brute du fichier
                resultObject: ((askStore !== true) && (askObject === true)) ? 1 : 0, //DONC, on ne demande pas la récupération de l'entité...
                storeFile: (askStore === true) ? 1 : 0, //ET DONC, on ne demande pas non-plus son stockage dans Azure...
            };
            //Cas de l'héberguement par notre application mobile => On demande à retourner l'objet/fichier directement ! 
            const isOnNativeApp = (IsNativeHoster() === true) ? true : false;
            //A partir du moment où l'on est hébergé par l'appli mobile, on force le stockage sur le compte Azure ! 
            // -> L'utilisateur pourra alors faire le choix de télécharger/imprimer/partager depuis l'affichage du fichier.
            if (isOnNativeApp === true) { 
                //demande le stockage du fichier (pour qu'il puisse être affiché sur le mobile).
                paramSearch.resultObject = 0;
                paramSearch.storeFile = 1;
            }

            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiWeightingBiomasses}Pdf/${idOfClient}/${biomassSelected.parameter.parcelId}`;
            RequestCalls.requestWithAuthent(requestURL, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((blobAndMetasOrObjectOrUrl) => { 
                    //SI on est en mode 'appli mobile' OU demande de stockage du fichier (pour affichage), 
                    // on reçoit une URL du lieu de stockage dans Azure !
                    if (paramSearch.storeFile === 1) { 
                        if (isOnNativeApp === true) { //Si depuis l'appli native
                            //On va transmettre à l'appli Native cette URL pour lancer l'affichage !!!
                            resolve(blobAndMetasOrObjectOrUrl);
                        } else {
                            try {
                                /*const link = document.createElement('a');
                                link.href = blobAndMetasOrObjectOrUrl;
                                link.setAttribute('target', "_blank");
                                link.setAttribute('rel', "noopener noreferrer");
                                
                                try {
                                    // Append to html page
                                    document.body.appendChild(link);
                                    
                                    // Force download
                                    link.click();
                                }
                                finally {
                                    // Clean up and remove the link
                                    link.parentNode.removeChild(link);
                                
                                    resolve(true);
                                }*/ //ne semble pas toujours fonctionner dans une promesse (ou bloquer en tant que popup) !

                                window.open(blobAndMetasOrObjectOrUrl, "_blank", "noopener noreferrer");
                                resolve(true);
                            }
                            catch(errLink) {
                                if (sendError) {
                                    sendError('GenerateBiomassPdf', {
                                        "erreur": "une erreur s'est produite suite au téléchargement du PDF de cette(ces) pesée(s) côté API",
                                        "errLink": errLink,
                                        "urlRequest": requestURL,
                                    });
                                }

                                reject(StringTranslate.badShowPdfBiomass);
                            }
                        }
                    } else if (paramSearch.resultObject === 1) { //Sinon, si on a demandé l'entité, 
                        //On l'a renvoie à l'appelant:
                        resolve(blobAndMetasOrObjectOrUrl);
                    } else { // Soit on reçoit le flux du fichier accompagné de ses caractèristiques !
                        //Cf. https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e :

                        if (blobAndMetasOrObjectOrUrl && blobAndMetasOrObjectOrUrl.data) {
                            blobAndMetasOrObjectOrUrl.data.blob()
                                .then((dataBlobed) => {
                                    let isOk = true;
                                    try {
                                        // Create blob link to download (Attention : Affiche le flux brute, non-interprêté, si on ne spécifie pas le 'type') :
                                        const url = window.URL.createObjectURL(new Blob([dataBlobed], { type: 'application/pdf' }));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', blobAndMetasOrObjectOrUrl.fileName);

                                        try {
                                            // Append to html page
                                            document.body.appendChild(link);
                                            
                                            // Force download
                                            link.click();
                                        }
                                        finally {
                                            // Clean up and remove the link
                                            link.parentNode.removeChild(link);
                                        }
                                    }
                                    catch (errorDetected) {
                                        isOk = false;
                                        if (sendError) {
                                            sendError('GenerateBiomassPdf', {
                                                "erreur": "une erreur s'est produite suite au téléchargement du PDF de cette(ces) pesée(s) côté API",
                                                "errorDetected": errorDetected,
                                                "urlRequest": requestURL,
                                            });
                                        }

                                        reject(StringTranslate.badShowPdfBiomass);
                                    }

                                    if (isOk) resolve(isOk);
                                })
                                .catch((errorBlob) => {
                                    if (sendError) {
                                        sendError('GenerateBiomassPdf', {
                                            "erreur": "une erreur s'est produite lors de la génération du fichier PDF de la(les) pesée(s) d'une parcelle côté API",
                                            "errorBlob": errorBlob,
                                            "urlRequest": requestURL,
                                        });
                                    }

                                    //Fin des téléchargements:
                                    //Retourne que l'on n'a pas atteint notre but:
                                    reject(StringTranslate.badGeneratePdfBiomass);
                                });
                        }
                        else //on ne fait rien !
                            reject(StringTranslate.badGeneratePdfBiomass);
                    }
                })
                .catch((err) => {
                    sendError('GenerateBiomassPdf', {
                        "erreur": "une erreur s'est produite lors de la demande de génération du fichier PDF de la(les) pesée(s) d'une parcelle côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                        "idOfParcel": biomassSelected.parcelId,
                    });

                    reject(StringTranslate.badGeneratePdfBiomass);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de générer le fichier Pdf récapitulant les infos essentielles des pesées existantes pour ce client. 
     */
     GenerateBiomassPdfResume: function (dispatch, getState, idsBiomassSelected, askObject = false, askStore = false) {
        let promise = function (resolve, reject) {

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noGeneratePdfBiomassWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            // Désormais, on permet à l'utilisateur d'obtenir une visualisation des PDF ! Pour cela, on télécharge le fichier puis demande son ouverture dans un nouvel onglet...
            // RQ : Dans le cas de l'appli web hébergée dans l'appli mobile, on forcera à obtenir le flux brute pour le transmettre à l'appli mobile pour gérer l'affichage.
            // (pas le retour HTTP de type fichier téléchagé car cela déclenche des actions non-souhaitées de la part de la Web View)

            // on prépare la requête
            let paramSearch = {
                idClient: idOfClient,
                //par défaut, on télécharge le fichier Pdf... au sens où l'on ne demande pas l'entité contenant le flux brute du fichier
                resultObject: ((askStore !== true) && (askObject === true)) ? 1 : 0, //DONC, on ne demande pas la récupération de l'entité...
                storeFile: (askStore === true) ? 1 : 0, //ET DONC, on ne demande pas non-plus son stockage dans Azure...
            };
            //Cas de l'héberguement par notre application mobile => On demande à retourner l'objet/fichier directement ! 
            const isOnNativeApp = (IsNativeHoster() === true) ? true : false;
            //A partir du moment où l'on est hébergé par l'appli mobile, on force le stockage sur le compte Azure ! 
            // -> L'utilisateur pourra alors faire le choix de télécharger/imprimer/partager depuis l'affichage du fichier.
            if (isOnNativeApp === true) { 
                //demande le stockage du fichier (pour qu'il puisse être affiché sur le mobile).
                paramSearch.resultObject = 0;
                paramSearch.storeFile = 1;
            }

            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiWeightingBiomasses}PdfRecapitulatif/${idOfClient}`;
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: idsBiomassSelected })
                .then((blobAndMetasOrObjectOrUrl) => {
                    //SI on est en mode 'appli mobile' OU demande de stockage du fichier (pour affichage), 
                    // on reçoit une URL du lieu de stockage dans Azure !
                    if (paramSearch.storeFile === 1) { 
                        if (isOnNativeApp === true) { //Si depuis l'appli native
                            //On va transmettre à l'appli Native cette URL pour lancer l'affichage !!!
                            resolve(blobAndMetasOrObjectOrUrl);
                        } else {
                            try {
                                /*const link = document.createElement('a');
                                link.href = blobAndMetasOrObjectOrUrl;
                                link.setAttribute('target', "_blank");
                                link.setAttribute('rel', "noopener noreferrer");
                                
                                try {
                                    // Append to html page
                                    document.body.appendChild(link);
                                    
                                    // Force download
                                    link.click();
                                }
                                finally {
                                    // Clean up and remove the link
                                    link.parentNode.removeChild(link);

                                    resolve(true);
                                }*/ //ne semble pas toujours fonctionner dans une promesse (ou bloquer en tant que popup) !

                                window.open(blobAndMetasOrObjectOrUrl, "_blank", "noopener noreferrer");
                                resolve(true);
                            }
                            catch(errLink) {
                                if (sendError) {
                                    sendError('GenerateBiomassPdfResume', {
                                        "erreur": "une erreur s'est produite suite au téléchargement du PDF de cette pesée côté API",
                                        "errLink": errLink,
                                        "urlRequest": requestURL,
                                    });
                                }

                                reject(StringTranslate.badShowPdfBiomassResume);
                            }
                        }
                    } else if (paramSearch.resultObject === 1) { //Sinon, si on a demandé l'entité, 
                        //On l'a renvoie à l'appelant:
                        resolve(blobAndMetasOrObjectOrUrl);
                    } else { // Soit on reçoit le flux du fichier accompagné de ses caractèristiques !
                        //Cf. https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e :
                        if (blobAndMetasOrObjectOrUrl && blobAndMetasOrObjectOrUrl.data) {
                            blobAndMetasOrObjectOrUrl.data.blob()
                                .then((dataBlobed) => {
                                    let isOk = true;
                                    try {
                                        // Create blob link to download (Attention : Affiche le flux brute, non-interprêté, si on ne spécifie pas le 'type') :
                                        const url = window.URL.createObjectURL(new Blob([dataBlobed], { type: 'application/pdf' }));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', blobAndMetasOrObjectOrUrl.fileName);

                                        try {
                                            // Append to html page
                                            document.body.appendChild(link);
                                            
                                            // Force download
                                            link.click();
                                        }
                                        finally {
                                            // Clean up and remove the link
                                            link.parentNode.removeChild(link);

                                            resolve(true);
                                        }
                                    }
                                    catch (errorDetected) {
                                        isOk = false;
                                        if (sendError) {
                                            sendError('GenerateBiomassPdfResume', {
                                                "erreur": "une erreur s'est produite suite au téléchargement du PDF de ce récapitulatif de pesée(s) côté API",
                                                "errorDetected": errorDetected,
                                                "urlRequest": requestURL,
                                            });
                                        }

                                        reject(StringTranslate.badShowPdfBiomassResume);
                                    }

                                    if (isOk) resolve(isOk);
                                })
                                .catch((errorBlob) => {
                                    if (sendError) {
                                        sendError('GenerateBiomassPdfResume', {
                                            "erreur": "une erreur s'est produite lors de la génération du fichier PDF récapitulatif pesées d'un client côté API",
                                            "errorBlob": errorBlob,
                                            "urlRequest": requestURL,
                                        });
                                    }

                                    //Fin des téléchargements:
                                    //Retourne que l'on n'a pas atteint notre but:
                                    reject(StringTranslate.badGeneratePdfBiomassResume);
                                });
                        }
                        else //on ne fait rien !
                            reject(StringTranslate.badGeneratePdfBiomassResume);
                    }
                })
                .catch((err) => {
                    sendError('GenerateBiomassPdfResume', {
                        "erreur": "une erreur s'est produite lors de la demande de génération du fichier PDF récapitulatif pesées d'un client côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                        "idsBiomass": idsBiomassSelected,
                    });

                    reject(StringTranslate.badGeneratePdfBiomassResume);
                    return;
                });
        }

        return new Promise(promise);
    },

}

/**
 * Objet de connexion à l'API spotifarm dans le cadre de la fumure
 */
const fertilizer_web_api_provider = {

    //Demande de la plage de dates de clôture de Azofert
    loadAzofertDatesRange: function (dispatch, getState) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLfertilizers = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}GetAzofertDatesRange/${idOfClient}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLfertilizers, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit une liste des cultures pour démarrer une fumure
                    if (response) { 
                        resolve(response); //liste des cultures pour la fumure
                    } else {
                        reject(StringTranslate.badLoadFertilizerDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadAzofertDatesRange', {
                            "erreur": "Une erreur est survenue lors du chargement de la plage de dates de clôture de Azofert.",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadAzofertDatesRange', {
                            "erreur": "une erreur s'est produite lors de la demande de la plage de dates de clôture de Azofert côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /* ETAPE 1 */
    //Load cultures pour commencer la fumure
    loadCultures: function (dispatch, getState) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLfertilizers = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}AvailableCrops/${idOfClient}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLfertilizers, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit une liste des cultures pour démarrer une fumure
                    if (response) { 
                        resolve(response); //liste des cultures pour la fumure
                    } else {
                        reject(StringTranslate.badLoadFertilizerCrops);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadCultures', {
                            "erreur": "Une erreur est survenue lors du chargement des cultures.",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerCrops);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadCultures', {
                            "erreur": "une erreur s'est produite lors de la recherche de la liste des cultures côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerCrops);
                    return;
                });
        }

        return new Promise(promise);
    },
    
    /* ETAPE 2 */
    //load complet infos lists (objectif de production, résidus et fréquence intermédiaire) pour l'écran de saisies spécifiques à la parcelle.
    //Rq: dont certaines listes (==référentiels) sont liées au moteur associé à la parcelle, SAUF les données gérées par la saisie des données communes 
    // qui sont désormais (Fumure V2, campagne 2024) liées aux référentiels Spotifarm !
    loadFertilizerLists: function (dispatch, getState, fertilizerEngine,currentCropYear) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLfertilizers = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}FertilizerLists/${fertilizerEngine}`;
            let paramSearch = {
                idClient: idOfClient,
                currentCropYear: currentCropYear

            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLfertilizers, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { 
                    if (response) { 
                        resolve(response); //liste des objectifs de production
                    } else {
                        reject(StringTranslate.badLoadFertilizerDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadCultures', {
                            "erreur": "une erreur s'est produite lors de la recherche de la liste des objectifs de production côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadCultures', {
                            "erreur": "une erreur s'est produite lors de la recherche de la liste des objectifs de production côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    //load common infos lists from promize (== "données gérées par la saisie des données communes et qui sont désormais (Fumure V2, campagne 2024) liées aux référentiels Spotifarm !")
    loadFertilizerCommonLists: function (dispatch, getState) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLfertilizers = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}FertilizerCommonLists`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLfertilizers, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { 
                    if (response) { 
                        resolve(response); //liste des objectifs de production
                    } else {
                        reject(StringTranslate.badLoadFertilizerDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadFertilizerCommonLists', {
                            "erreur": "une erreur s'est produite lors de la recherche des listes communes côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadFertilizerCommonLists', {
                            "erreur": "une erreur s'est produite lors de la recherche des listes communes côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerDatas);
                    return;
                });
        }

        return new Promise(promise);
    },
    
    //load common infos by culture 
    //Rq: les identifiants des données gérées par la saisie des données communes et qui visent désormais (Fumure V2, campagne 2024) un item des référentiels Spotifarm !
    loadCommonInfosManagementByCulture: function (dispatch, getState, enumOfCrop) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLfertilizers = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}GetCommonDatas/${idOfClient}/${enumOfCrop}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLfertilizers, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit les infos communes du client en fonction de la culture
                    if (response) { 
                        resolve(response); //infos communes du client en fonction de la culture
                        //on reçoit :
                        //id, idClient, idCulture, harvestDate, sowingDate, isProteinGoal, performanceGoal, intermediaiteCultureFrequency, tailingsMangangement
                    } else {
                        //La première fois qu'un client lance la fumure, il n'a encore rien sauvegarder. Donc c'est normal de ne rien avoir en retour !
                        //reject(StringTranslate.badLoadFertilizerCommonInfosByCultureDatas);
                        resolve(undefined);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadCultures', {
                            "erreur": "une erreur s'est produite lors de la recherche des infos communes en fonction de la culture côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerCommonInfosByCultureDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadCultures', {
                            "erreur": "une erreur s'est produite lors de la recherche des infos communes en fonction de la culture côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    //reject(StringTranslate.badLoadFertilizerCommonInfosByCultureDatas); //Tampis si on n'a pas réussit à obtenir sa précédente saisie. On ne stresse pas le client avec cela (il recommencera la prochaine fois!)
                    return;
                });
        }

        return new Promise(promise);
    },

    //save / update common infos by culture 
    saveOrUpdateCommonInfosManagement: function (dispatch, getState, commonInfosManagementToSave) {
        let promise = function (resolve, reject) {
            
            // Attention, si on ne transforme pas les dates en chaines de charactères nous même, alors fetch le fait.
            // quand fetch le fait il transforme notre date au format GMT ce qui fait une perte d'au moins 1 heure,
            // ce qui peut conduire à enregistrer la date de la veille (ex: 2023-02-10 à 00:00:00 devient 2023-02-09 à 23:00:00)
            tools.reformatCommonInfos(commonInfosManagementToSave);

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noSaveOrUpdateFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            // on prépare la requête
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            //Définit si on nous demande l'enregistrement des données permettant de produire l'image...
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}SaveCommonDatas/${idOfClient}/${commonInfosManagementToSave.enumOfCrop}`;
            
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: commonInfosManagementToSave })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    }
                    /*else {
                        reject(StringTranslate.badSaveCommonInfosManagementDatas);
                    }*/ //Tampis si on n'a pas réussit à faire la sauvegarde. On ne stresse pas le client avec cela (il recommencera la prochaine fois!)
                })
                .catch((err) => {
                    sendError('saveCommonInfosManagement', {
                        "erreur": "une erreur s'est produite lors de l'enregistrement des infos communes côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                    });
                    //reject(StringTranslate.badSaveCommonInfosManagementDatas); // Tampis si on n'a pas réussit à faire la sauvegarde. On ne stresse pas le client avec cela (il recommencera la prochaine fois!)
                    return;
                });
        }

        return new Promise(promise);
    },

    /* ETAPE 3 */
    //save / update spécific infos by culture 
    saveOrUpdateSpecificInfos: function (dispatch, getState, specificInfosData) {
        let promise = function (resolve, reject) {

            // Attention, si on ne transforme pas les dates en chaines de charactères nous même, alors fetch le fait.
            // quand fetch le fait il transforme notre date au format GMT ce qui fait une perte d'au moins 1 heure,
            // ce qui peut conduire à enregistrer la date de la veille (ex: 2023-02-10 à 00:00:00 devient 2023-02-09 à 23:00:00)
            tools.reformatSpecificInfos(specificInfosData);

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noSaveOrUpdateFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            // on prépare la requête
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}SaveSpecificDatas/${idOfClient}`;
            
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: specificInfosData })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    }
                    else {
                        reject(StringTranslate.badSaveSpecificInfosManagementDatas);
                    }
                })
                .catch((err) => {
                    sendError('saveOrUpdateSpecificInfos', {
                        "erreur": "une erreur s'est produite lors de l'enregistrement des infos spécifiques côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                    });
                    reject(StringTranslate.badSaveSpecificInfosManagementDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    //save / update spécific infos beginning winter date
    saveOrUpdateSpecificInfosWinterDate: function (dispatch, getState, specificInfosData) {
        let promise = function (resolve, reject) {

            // Attention, si on ne transforme pas les dates en chaines de charactères nous même, alors fetch le fait.
            // quand fetch le fait il transforme notre date au format GMT ce qui fait une perte d'au moins 1 heure,
            // ce qui peut conduire à enregistrer la date de la veille (ex: 2023-02-10 à 00:00:00 devient 2023-02-09 à 23:00:00)
            tools.reformatSpecificInfos(specificInfosData);

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noSaveOrUpdateFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            // on prépare la requête
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}UpdateSpecificDatasBeginningWinterDate/${idOfClient}`;
            
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: specificInfosData })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    }
                    else {
                        reject(StringTranslate.badSaveSpecificInfosManagementDatas);
                    }
                })
                .catch((err) => {
                    sendError('UpdateSpecificDatasBeginningWinterDate', {
                        "erreur": "une erreur s'est produite lors de l'enregistrement des infos spécifiques côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                    });
                    reject(StringTranslate.badSaveSpecificInfosManagementDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    //Load fertilizer Engine 
    loadFertilizerEngine: function (dispatch, getState, parcelId) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLfertilizers = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}FertilizerEngine/${idOfClient}/${parcelId}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLfertilizers, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { 
                    if (response) { 
                        resolve(response); 
                    } else {
                        reject(StringTranslate.badLoadFertilizerDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadFertilizerEngine', {
                            "erreur": "une erreur s'est produite lors de la recherche du moteur de fumure côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadFertilizerEngine', {
                            "erreur": "une erreur s'est produite lors de la recherche du moteur de fumure côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    //load complete infos for selected parcels 

    //load Soil Kind list
    loadSoilKind: function (dispatch, getState, parcelId) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLfertilizers = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}${idOfClient}/${parcelId}/SoilKindList`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLfertilizers, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit une liste de type de sol
                    if (response) { 
                        resolve(response); //liste des types de sols
                    } else {
                        reject(StringTranslate.badLoadFertilizerDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadSoilKind', {
                            "erreur": "une erreur s'est produite lors de la recherche des différents apports organiques côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadSoilKind', {
                            "erreur": "une erreur s'est produite lors de la recherche des différents apports organiques côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    //load Varieties list
    loadVarieties: function (dispatch, getState, fertilizerEngine = "Azofert", parcelId, availableCropEnum, isImprovingCrop, productionTarget) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            // availableCropEnum => 1:blé, 2:orge, 3:colza
            // productionTarget => 1:rendement, 2:protéine          => si pas blé: 1
            let varietyFilter = {
                engine: fertilizerEngine,
                cropType: availableCropEnum,
                productionTarget: productionTarget,
                isImprovingCrop: isImprovingCrop,
            };

            //On demande la liste des parcelles associées au client connecté :
            let requestURLfertilizers = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}${idOfClient}/${parcelId}/VarietiesForCrop`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLfertilizers, { method: 'POST', headers: headersAuthent, params: paramSearch, body: varietyFilter })
                .then((response) => { // on reçoit une liste de variétés
                    if (response) { 
                        resolve(response); //liste des variétés
                    } else {
                        reject(StringTranslate.badLoadFertilizerDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadVarieties', {
                            "erreur": "une erreur s'est produite lors de la recherche des différents apports organiques côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadVarieties', {
                            "erreur": "une erreur s'est produite lors de la recherche des différents apports organiques côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     * Fonction permettant d'essayer de charger la varieté d'une parcelle données si celle-ci est rentrée manuellement par le client.
     * @param {*} dispatch 
     * @param {*} getState 
     * @param {int} parcelId 
     * @param {int} availableCropEnum
     * @param {boolean} isImprovingCrop
     * @param {int} productionTarget
     */
    loadVarietyForParcel: function (dispatch, getState, fertilizerEngine = "Azofert", parcelId, availableCropEnum, isImprovingCrop, productionTarget) {        
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            let varietyFilter = {
                engine: fertilizerEngine,
                cropType: availableCropEnum,
                productionTarget: productionTarget,
                isImprovingCrop: isImprovingCrop,
            };

            //On demande la liste des parcelles associées au client connecté :
            let requestURLfertilizers = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}LoadVarietyForParcel/${idOfClient}/${parcelId}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLfertilizers, { method: 'POST', headers: headersAuthent, params: paramSearch, body: varietyFilter })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject();
                    }
                }, (err) => {
                    if (sendError) {
                        sendError('loadVarietyForParcel', {
                            "erreur": "",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    reject();
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadVarietyForParcel', {
                            "erreur": "",
                            "err": err,
                            "idOfClient": idOfClient,
                        })
                    }

                    reject();
                    return;
                }
            );
        }

        return new Promise(promise);
    },

    /* Suppression de fumures */
    /**
     *  Fonction permettant de supprimer une fumure (et toutes les données associées) de l'utilisateur connecté à SpotiFarm.
     */
    deleteFertilizer: function (dispatch, getState, parcelId) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noDeleteDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noDeleteDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noDeleteDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}Delete/${idOfClient}/${parcelId}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'DELETE', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit 'vrai' (ou 'faux') !
                    if (response === true) {
                        resolve(response);
                    } else {
                        reject(StringTranslate.badDeleteFertilizerDatas);
                    }
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('deleteFertilizer', {
                            "erreur": "une erreur s'est produite lors de la suppression de la fumure côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                            "parcelId": parcelId
                        });
                    }

                    reject(StringTranslate.badDeleteFertilizerDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de supprimer une ou plusieurs fumures (et toutes les données associées) de l'utilisateur connecté à SpotiFarm.
     */
    deleteFertilizers: function (dispatch, getState, parcelIds) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noDeleteDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noDeleteDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noDeleteDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}Delete/${idOfClient}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'DELETE', headers: headersAuthent, params: paramSearch, body: parcelIds })
                .then((response) => { // on reçoit 'vrai' (ou 'faux') !
                    if (response === true) {
                        resolve(response);
                    } else {
                        reject(StringTranslate.badDeleteFertilizerDatas);
                    }
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('deleteFertilizers', {
                            "erreur": "une erreur s'est produite lors de la suppression des données de fumure côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                            "parcelIds": parcelIds
                        });
                    }

                    reject(StringTranslate.badDeleteFertilizerDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /* Mes fumures */
    /**
     *  Fonction permettant de rechercher les fumures de l'utilisateur connecté à SpotiFarm.
     */
     loadFertilizers: function (dispatch, getState, idClient) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0) || (idOfClient !== idClient)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}GetSpecificDatas/${idOfClient}`;
        
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit une liste d'entité 'SpecificInfosManagement'
                    if (response) { // si reponse - les données de fumure existent en BDD
                        // liste des données de fumure => transformation dico (parcelId - fertilizerData)
                        const dicoFromListOfFertilizers = FertilizerHelper.convertToDico(response); 
                        resolve(dicoFromListOfFertilizers);
                    } else {
                        reject(StringTranslate.badLoadFertilizerDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadFertilizers', {
                            "erreur": "une erreur s'est produite lors de la recherche des données de fumure côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadFertilizers', {
                            "erreur": "une erreur s'est produite lors de la recherche des données de fumure côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de rechercher les fumures antérieures de l'utilisateur connecté à Spotifarm.
     */
    loadPreviousFertilizers: function (dispatch, getState, idClient) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0) || (idOfClient !== idClient)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}GetPreviousSpecificDatas/${idOfClient}`;
        
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit une liste d'entité 'SpecificInfosManagement'
                    if (response) { // si reponse - les données de fumure existent en BDD
                        // liste des données de fumure => transformation dico (parcelId - fertilizerData)
                        const dicoFromListOfFertilizers = FertilizerHelper.convertToDico(response); 
                        resolve(dicoFromListOfFertilizers);
                    } else {
                        reject(StringTranslate.badLoadFertilizerDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadFertilizersArchived', {
                            "erreur": "une erreur s'est produite lors de la recherche des données de fumure côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadFertilizersArchived', {
                            "erreur": "une erreur s'est produite lors de la recherche des données de fumure côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     * Fonction permettant de générer, à partir des paramètres, 
     * la fumure (image) d'une parcelle d'un client et dont le retour contient l'image et les doses de zones.
     * @param dispatch 
     * @param getState 
     * @param idClient
     * @param specificInfosData 
     */
     buildNitrogenSampleAndDoses: function (dispatch, getState, idClient, specificInfosData) {
        let promise = function (resolve, reject) {

            // Attention, si on ne transforme pas les dates en chaines de charactères nous même, alors fetch le fait.
            // quand fetch le fait il transforme notre date au format GMT ce qui fait une perte d'au moins 1 heure,
            // ce qui peut conduire à enregistrer la date de la veille (ex: 2023-02-10 à 00:00:00 devient 2023-02-09 à 23:00:00)
            tools.reformatSpecificInfos(specificInfosData);

            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0) || (idOfClient !== idClient)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}BuildNitrogenSampleAndDoses/${idOfClient}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: specificInfosData })
                .then((response) => { // on reçoit une liste d'entité 'Modulation'
                    if (response) { // si reponse - les modulations existent en BDD
                        const newResponse = FertilizerHelper.convertItem(response); //convertit les dates !
                        resolve(newResponse);
                    } else {
                        reject(StringTranslate.badPartialBuildFertilizer);
                    }
                    return;
                }, (error) => {
                    const errorCode = lodashGet(error, 'message.errorCode', undefined);

                    if (sendError) {
                        sendError('buildNitrogenSampleAndDoses', {
                            "erreur": "une erreur s'est produite lors de la génération de la fumure côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                            "errorCode": errorCode,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    let msg = StringTranslate.badPartialBuildFertilizer;
                    if (!lodashIsNil(errorCode)){
                        msg = errorCodeHelper[errorCode];
                    }

                    reject(msg);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('buildNitrogenSampleAndDoses', {
                            "erreur": "une erreur s'est produite lors de la génération de la fumure côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badPartialBuildFertilizer);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     * Fonction permettant de récupérer en base la fumure dont l'image et les zones pour un mode lecture dans
     * l'écran des résultats
     * @param {*} dispatch 
     * @param {*} getState 
     * @param {*} idClient 
     * @param {*} idFertilizer 
     * @returns 
     */
    loadFertilizer: function (dispatch, getState, idClient, idFertilizer) {
        
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0) || (idOfClient !== idClient)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}LoadFertilizer/${idOfClient}/${idFertilizer}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURL, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { 
                    if (response) {
                        const newResponse = FertilizerHelper.convertItem(response); //convertit les dates !
                        resolve(newResponse);
                    } else {
                        reject(StringTranslate.badLoadFertilizerDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadFertilizer', {
                            "erreur": "une erreur s'est produite lors de la demande en base de la fumure côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadFertilizer', {
                            "erreur": "une erreur s'est produite lors de la demande en base de la fumure côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de mettre à jour le nom de la culture de la parcelle 
     * en fonction de la culture choisie pour la fumure.
     */
    updateCropNameInParcel(dispatch, getState, parcelId, cultureParameter) {
        let promise = function (resolve, reject) {

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noSaveOrUpdateFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            // on prépare la requête
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}UpdateCrop/${idOfClient}/${parcelId}`;
            
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: cultureParameter })
                .then((response) => {
                    if (response) {
                        resolve(response);
                    }
                    else {
                        reject(StringTranslate.badSaveSpecificInfosManagementDatas);
                    }
                })
                .catch((err) => {
                    sendError('updateCropNameInParcel', {
                        "erreur": "une erreur s'est produite lors de la mise à jour de la culture de la parcelle côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                    });
                    reject(StringTranslate.badSaveSpecificInfosManagementDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de mettre à jour les doses et le résultat dans l'étape de résultat
     */
    updateFertilizerDoses(dispatch, getState, fertilizerParameter) {
        let promise = function (resolve, reject) {

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noSaveOrUpdateFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            // on prépare la requête
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}UpdateFertilizerDoses/${idOfClient}`;
            
            RequestCalls.requestWithAuthent(requestURL, { method: 'PUT', headers: headersAuthent, params: paramSearch, body: fertilizerParameter })
                .then((response) => {
                    if (response) {
                        const newResponse = FertilizerHelper.convertItem(response); //convertit les dates !
                        resolve(newResponse);
                    }
                    else {
                        reject(StringTranslate.badUpdateFertilizerDatas);
                    }
                })
                .catch((err) => {
                    sendError('updateFertilizerDoses', {
                        "erreur": "une erreur s'est produite lors de la mise à jour de la FUMURE côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                    });
                    reject(StringTranslate.badUpdateFertilizerDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     * Méthode générant, à partir des paramètres, 
     * la fumure d'une parcelle d'un client et dont le retour 
     * contient l'image
     * @param dispatch 
     * @param getState 
     * @param fertilizerPrescriptionList 
     * @param outputFormat 
     */
     buildListPrescriptions: function (dispatch, getState, fertilizerPrescriptionList, exportFormat) { 
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);
            
            //Export Shape par défaut !
            if ((!exportFormat) || (exportFormat <= 0)) {
                exportFormat = 1;
            }

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}BuildNitrogenPrescriptions/${idOfClient}`;

            switch(exportFormat) {
                case 1: //shp
                case 2: //isoxml
                case 3: //rds
                    requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}BuildNitrogenPrescriptions/${idOfClient}`;
                    break;
                case 4: //map
                    requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}GetPdfMapModulationsZipped/${idOfClient}`;
                    break;
                case 5: //ppf
                    requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}GetPdfFerilizerPlansZipped/${idOfClient}`;
                    break;
                default: //shp
                    requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}BuildNitrogenPrescriptions/${idOfClient}`;
                    break;
            };
            
            let paramSearch = {
                idClient: idOfClient,
                outputFormat: exportFormat,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };
            
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: fertilizerPrescriptionList })
                .then((blobAndMetas) => { // on reçoit le flux du fichier accompagné de ses caractèristiques !
                    //Cf. https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e :
                        let isOk = true;
                    if (blobAndMetas && blobAndMetas.data) {
                        blobAndMetas.data.blob()
                            .then((dataBlobed) => {
                                try {
                                    // Create blob link to download
                                    const url = window.URL.createObjectURL(new Blob([dataBlobed]));
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', blobAndMetas.fileName);
                                    try {
                                        // Append to html page
                                        document.body.appendChild(link);
                                        
                                        // Force download
                                        link.click();
                                    }
                                    finally {
                                        // Clean up and remove the link
                                        link.parentNode.removeChild(link);
                                    }
                                }
                                catch (errorDetected) {
                                    isOk = false;
                                    if (sendError) {
                                        sendError('BuildListPrescriptions', {
                                            "erreur": "une erreur s'est produite suite au téléchargement de la fumure côté API",
                                            "err": errorDetected,
                                            "urlRequest": requestURL,
                                        });
                                    }
                                }

                                //Fin des téléchargements:
                                //Retourne que c'est Ok OU que l'on n'a pas atteint notre but:
                                resolve((isOk === true) ? true : false);
                            })
                            .catch((errorBlob) => {
                                if (sendError) {
                                    sendError('BuildListPrescriptions', {
                                        "erreur": "une erreur s'est produite lors de l'obtention du flux binaire lors du téléchargement de la fumure côté API",
                                        "err": errorBlob,
                                        "urlRequest": requestURL,
                                    });
                                }

                                isOk = false;

                                //Fin des téléchargements:
                                //Retourne que l'on n'a pas atteint notre but:
                                resolve(false);
                            });
                    } else {
                        if (sendError) {
                            sendError('BuildListPrescriptions', {
                                "erreur": "le retour du téléchargement de la fumure n'a pas fournis entité attendue",
                                "err": (blobAndMetas) ? "'blobAndMetas.data' is void!" : "'blobAndMetas' is undefined!",
                                "urlRequest": requestURL,
                            });
                        }

                        isOk = false;

                        //Fin des téléchargements:
                        //Retourne que l'on n'a pas atteint notre but:
                        resolve(false);
                    }
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('BuildListPrescriptions', {
                            "erreur": "une erreur s'est produite lors du téléchargement de la fumure côté API",
                            "err": err,
                            "urlRequest": requestURL,
                        });
                    }

                    reject(false);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant d'obtenir le fichier Pdf (PPF) de cette fumure 
     */
    buildFertilizerFile: function (dispatch, getState, fertilizerId) {
        let promise = function (resolve, reject) {

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadModulationDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            // Désormais, on permet à l'utilisateur d'obtenir une visualisation des PDF ! Pour cela, on télécharge le fichier puis demande son ouverture dans un nouvel onglet...
            // RQ : Dans le cas de l'appli web hébergée dans l'appli mobile, on forcera à obtenir le flux brute pour le transmettre à l'appli mobile pour gérer l'affichage.
            // (pas le retour HTTP de type fichier téléchagé car cela déclenche des actions non-souhaitées de la part de la Web View)

            // on prépare la requête
            let paramSearch = {
                idClient: idOfClient,
                //par défaut, on télécharge le fichier Pdf... au sens où l'on ne demande pas l'entité contenant le flux brute du fichier
                resultObject: 0, //DONC, on ne demande pas la récupération de l'entité...
                storeFile: 0, //ET DONC, on ne demande pas non-plus son stockage dans Azure...
            };
            //Cas de l'héberguement par notre application mobile => On demande à retourner l'objet/fichier directement ! 
            const isOnNativeApp = (IsNativeHoster() === true) ? true : false;
            //A partir du moment où l'on est hébergé par l'appli mobile, on force le stockage sur le compte Azure ! 
            // -> L'utilisateur pourra alors faire le choix de télécharger/imprimer/partager depuis l'affichage du fichier.
            if (isOnNativeApp === true) { 
                //demande le stockage du fichier (pour qu'il puisse être affiché sur le mobile).
                paramSearch.resultObject = 0;
                paramSearch.storeFile = 1;
            }

            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}GetPdfFertilizerPlan/${idOfClient}/${fertilizerId}`;
            RequestCalls.requestWithAuthent(requestURL, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((blobAndMetasOrObjectOrUrl) => {
                    //SI on est en mode 'appli mobile' OU demande de stockage du fichier (pour affichage), 
                    // on reçoit une URL du lieu de stockage dans Azure !
                    if (paramSearch.storeFile === 1) { 
                        if (isOnNativeApp === true) { //Si depuis l'appli native
                            //On va transmettre à l'appli Native cette URL pour lancer l'affichage !!!
                            resolve(blobAndMetasOrObjectOrUrl);
                        } else {
                            try {
                                window.open(blobAndMetasOrObjectOrUrl, "_blank", "noopener noreferrer");
                                resolve(true);
                            }
                            catch(errLink) {
                                if (sendError) {
                                    sendError('buildFertilizerFile', {
                                        "erreur": "une erreur s'est produite suite au téléchargement du PDF de cette fumure côté API",
                                        "errLink": errLink,
                                        "urlRequest": requestURL,
                                    });
                                }

                                reject(StringTranslate.badShowPdfFertilizer);
                            }
                        }
                    } /*else if (paramSearch.resultObject === 1) { //Sinon, si on a demandé l'entité, MAIS pas le cas possible dans cet appel !
                        //On l'a renvoie à l'appelant:
                        resolve(blobAndMetasOrObjectOrUrl);
                    }*/ else { // Soit on reçoit le flux du fichier accompagné de ses caractèristiques !
                        //Cf. https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e :
                        if (blobAndMetasOrObjectOrUrl && blobAndMetasOrObjectOrUrl.data) {
                            blobAndMetasOrObjectOrUrl.data.blob()
                                .then((dataBlobed) => {
                                    let isOk = true;
                                    try {
                                        // Create blob link to download (Attention : Affiche le flux brute, non-interprêté, si on ne spécifie pas le 'type') :
                                        const url = window.URL.createObjectURL(new Blob([dataBlobed], { type: 'application/pdf' }));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', blobAndMetasOrObjectOrUrl.fileName);

                                        try {
                                            // Append to html page
                                            document.body.appendChild(link);
                                            
                                            // Force download
                                            link.click();
                                        }
                                        finally {
                                            // Clean up and remove the link
                                            link.parentNode.removeChild(link);

                                            resolve(true);
                                        }
                                    }
                                    catch (errorDetected) {
                                        isOk = false;
                                        if (sendError) {
                                            sendError('buildFertilizerFile', {
                                                "erreur": "une erreur s'est produite suite au téléchargement du PDF de cette fumure côté API",
                                                "errorDetected": errorDetected,
                                                "urlRequest": requestURL,
                                            });
                                        }

                                        reject(StringTranslate.badShowPdfFertilizer);
                                    }

                                    if (isOk) resolve(isOk);
                                })
                                .catch((errorBlob) => {
                                    if (sendError) {
                                        sendError('buildFertilizerFile', {
                                            "erreur": "une erreur s'est produite lors de la génération du fichier PDF de cette fumure côté API",
                                            "errorBlob": errorBlob,
                                            "urlRequest": requestURL,
                                        });
                                    }

                                    //Fin des téléchargements:
                                    //Retourne que l'on n'a pas atteint notre but:
                                    reject(StringTranslate.badGeneratePdfFertilizer);
                                });
                        }
                        else //on ne fait rien !
                            reject(StringTranslate.badGeneratePdfFertilizer);
                    }
                })
                .catch((err) => {
                    sendError('buildFertilizerFile', {
                        "erreur": "une erreur s'est produite lors de la demande de génération du fichier PDF de cette fumure côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                        "idOfParcel": fertilizerId,
                    });

                    reject(StringTranslate.badGeneratePdfFertilizer);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de générer le fichier Pdf de cette fumure 
     */
    GenerateFertilizerPdf: function (dispatch, getState, fertilizer, askObject = false, askStore = false) {
        let promise = function (resolve, reject) {

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadModulationDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            // Désormais, on permet à l'utilisateur d'obtenir une visualisation des PDF ! Pour cela, on télécharge le fichier puis demande son ouverture dans un nouvel onglet...
            // RQ : Dans le cas de l'appli web hébergée dans l'appli mobile, on forcera à obtenir le flux brute pour le transmettre à l'appli mobile pour gérer l'affichage.
            // (pas le retour HTTP de type fichier téléchagé car cela déclenche des actions non-souhaitées de la part de la Web View)

            // on prépare la requête
            let paramSearch = {
                idClient: idOfClient,
                //par défaut, on télécharge le fichier Pdf... au sens où l'on ne demande pas l'entité contenant le flux brute du fichier
                resultObject: ((askStore !== true) && (askObject === true)) ? 1 : 0, //DONC, on ne demande pas la récupération de l'entité...
                storeFile: (askStore === true) ? 1 : 0, //ET DONC, on ne demande pas non-plus son stockage dans Azure...
            };
            //Cas de l'héberguement par notre application mobile => On demande à retourner l'objet/fichier directement ! 
            const isOnNativeApp = (IsNativeHoster() === true) ? true : false;
            //A partir du moment où l'on est hébergé par l'appli mobile, on force le stockage sur le compte Azure ! 
            // -> L'utilisateur pourra alors faire le choix de télécharger/imprimer/partager depuis l'affichage du fichier.
            if (isOnNativeApp === true) { 
                //demande le stockage du fichier (pour qu'il puisse être affiché sur le mobile).
                paramSearch.resultObject = 0;
                paramSearch.storeFile = 1;
            }

            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}Pdf/${idOfClient}/${fertilizer.idParcel}`;
            RequestCalls.requestWithAuthent(requestURL, { method: 'GET', headers: headersAuthent, params: paramSearch/* , body: fertilizer */ })
                .then((blobAndMetasOrObjectOrUrl) => {
                    //SI on est en mode 'appli mobile' OU demande de stockage du fichier (pour affichage), 
                    // on reçoit une URL du lieu de stockage dans Azure !
                    if (paramSearch.storeFile === 1) { 
                        if (isOnNativeApp === true) { //Si depuis l'appli native
                            //On va transmettre à l'appli Native cette URL pour lancer l'affichage !!!
                            resolve(blobAndMetasOrObjectOrUrl);
                        } else {
                            try {
                                window.open(blobAndMetasOrObjectOrUrl, "_blank", "noopener noreferrer");
                                resolve(true);
                            }
                            catch(errLink) {
                                if (sendError) {
                                    sendError('GenerateFertilizerPdf', {
                                        "erreur": "une erreur s'est produite suite au téléchargement du PDF de cette fumure côté API",
                                        "errLink": errLink,
                                        "urlRequest": requestURL,
                                    });
                                }

                                reject(StringTranslate.badShowPdfFertilizer);
                            }
                        }
                    } else if (paramSearch.resultObject === 1) { //Sinon, si on a demandé l'entité, 
                        //On l'a renvoie à l'appelant:
                        resolve(blobAndMetasOrObjectOrUrl);
                    } else { // Soit on reçoit le flux du fichier accompagné de ses caractèristiques !
                        //Cf. https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e :
                        if (blobAndMetasOrObjectOrUrl && blobAndMetasOrObjectOrUrl.data) {
                            blobAndMetasOrObjectOrUrl.data.blob()
                                .then((dataBlobed) => {
                                    let isOk = true;
                                    try {
                                        // Create blob link to download (Attention : Affiche le flux brute, non-interprêté, si on ne spécifie pas le 'type') :
                                        const url = window.URL.createObjectURL(new Blob([dataBlobed], { type: 'application/pdf' }));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', blobAndMetasOrObjectOrUrl.fileName);

                                        try {
                                            // Append to html page
                                            document.body.appendChild(link);
                                            
                                            // Force download
                                            link.click();
                                        }
                                        finally {
                                            // Clean up and remove the link
                                            link.parentNode.removeChild(link);

                                            resolve(true);
                                        }
                                    }
                                    catch (errorDetected) {
                                        isOk = false;
                                        if (sendError) {
                                            sendError('GenerateFertilizerPdf', {
                                                "erreur": "une erreur s'est produite suite au téléchargement du PDF de cette fumure côté API",
                                                "errorDetected": errorDetected,
                                                "urlRequest": requestURL,
                                            });
                                        }

                                        reject(StringTranslate.badShowPdfFertilizer);
                                    }

                                    if (isOk) resolve(isOk);
                                })
                                .catch((errorBlob) => {
                                    if (sendError) {
                                        sendError('GenerateFertilizerPdf', {
                                            "erreur": "une erreur s'est produite lors de la génération du fichier PDF de cette fumure côté API",
                                            "errorBlob": errorBlob,
                                            "urlRequest": requestURL,
                                        });
                                    }

                                    //Fin des téléchargements:
                                    //Retourne que l'on n'a pas atteint notre but:
                                    reject(StringTranslate.badGeneratePdfFertilizer);
                                });
                        }
                        else //on ne fait rien !
                            reject(StringTranslate.badGeneratePdfFertilizer);
                    }
                })
                .catch((err) => {
                    sendError('GenerateFertilizerPdf', {
                        "erreur": "une erreur s'est produite lors de la demande de génération du fichier PDF de cette fumure côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                        "idOfParcel": fertilizer.idParcel,
                    });

                    reject(StringTranslate.badGeneratePdfFertilizer);
                    return;
                });
        }

        return new Promise(promise);
    },

    LoadArchivedData: function (dispatch, getState, idParcel, cropYear = undefined) {
        let promise = function (resolve, reject) {

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadModulationDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            // Désormais, on permet à l'utilisateur d'obtenir une visualisation des PDF ! Pour cela, on télécharge le fichier puis demande son ouverture dans un nouvel onglet...
            // RQ : Dans le cas de l'appli web hébergée dans l'appli mobile, on forcera à obtenir le flux brute pour le transmettre à l'appli mobile pour gérer l'affichage.
            // (pas le retour HTTP de type fichier téléchagé car cela déclenche des actions non-souhaitées de la part de la Web View)

            // on prépare la requête
            let paramSearch = {
                idClient: idOfClient,
                //par défaut, on télécharge le fichier Pdf... au sens où l'on ne demande pas l'entité contenant le flux brute du fichier
            };
            //Cas de l'héberguement par notre application mobile => On demande à retourner l'objet/fichier directement ! 
            const isOnNativeApp = (IsNativeHoster() === true) ? true : false;
            //A partir du moment où l'on est hébergé par l'appli mobile, on force le stockage sur le compte Azure ! 
            // -> L'utilisateur pourra alors faire le choix de télécharger/imprimer/partager depuis l'affichage du fichier.
            if (isOnNativeApp === true) { 
                //demande le stockage du fichier (pour qu'il puisse être affiché sur le mobile).
                paramSearch.resultObject = 0;
                paramSearch.storeFile = 1;
            }

            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiFertilizers}LoadArchivedData/${idOfClient}/${idParcel}/${cropYear}`;
            RequestCalls.requestWithAuthent(requestURL, { method: 'GET', headers: headersAuthent, params: paramSearch/* , body: fertilizer */ })
                .then((response) => { // on reçoit une liste d'entité 'SpecificInfosManagement'
                    if (response) { // si reponse - les données de fumure existent en BDD
                        // liste des données de fumure => transformation dico (parcelId - fertilizerData)
                        //const dicoFromListOfFertilizers = FertilizerHelper.convertToDico(response); 
                        //resolve(dicoFromListOfFertilizers);
                        resolve(response);
                    } else {
                        reject(StringTranslate.badLoadFertilizerDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('LoadArchivedData', {
                            "erreur": "une erreur s'est produite lors de la recherche des données de fumure côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('LoadArchivedData', {
                            "erreur": "une erreur s'est produite lors de la recherche des données de fumure côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerDatas);
                    return;
                });
        }

        return new Promise(promise);
    },
    //#endregion
}

const modelisation_web_api_provider = {
    /* ETAPE 1 : Choix culture et parcelles */
    //#region étape 1
    loadCultures: function (dispatch, getState) {
        
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            //#region Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }
            //#endregion Recherche de l'id client, ainsi que du jeton d'accès

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLfertilizers = `${ConfigApi.ConstAndDefault.UrlWebApiModelisation}AvailableCrops`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLfertilizers, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit une liste des cultures pour démarrer une fumure
                    if (response) { 
                        resolve(response); //liste des cultures pour la fumure
                    } else {
                        reject(StringTranslate.badLoadFertilizerCrops);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadCultures', {
                            "erreur": "Une erreur est survenue lors du chargement des cultures.",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerCrops);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadCultures', {
                            "erreur": "une erreur s'est produite lors de la recherche de la liste des cultures côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadFertilizerCrops);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de rechercher les modélisations de l'utilisateur connecté à SpotiFarm.
     */
    loadModelisations: function (dispatch, getState, parcelIds = undefined) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiModelisation}GetModelisations/${idOfClient}`;
        
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            let options = { method: 'GET', headers: headersAuthent, params: paramSearch };
            if (!lodashIsNil(parcelIds)) {
                options = { method: 'POST', headers: headersAuthent, params: paramSearch, body: parcelIds }
            }

            RequestCalls.requestWithAuthent(requestURLparcels, options)
                .then((response) => { // on reçoit une liste d'entité 'Modelisation'
                    if (response) { // si reponse - les données de fumure existent en BDD
                        // liste des données de modélisation (une par parcelle en disposant)
                        const dicoFromListOfModelisations = ModelisationHelper.convertToDico(response); 
                        resolve(dicoFromListOfModelisations);
                    } else {
                        reject(StringTranslate.badLoadModelisationDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadModelisations', {
                            "erreur": "une erreur s'est produite lors de la recherche des données de modélisation côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadModelisationDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadModelisations', {
                            "erreur": "une erreur s'est produite lors de la recherche des données de modélisations côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadModelisationDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     * Fonction permettant de demander la génération d'historique de parcelles pour la modélisation
     */
    askToGenerateForModelisation: function (dispatch, getState, listOfModelisations) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiModelisation}AskToGenHisto/${idOfClient}`;
        
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'POST', headers: headersAuthent, params: paramSearch, body: listOfModelisations  })
                .then((response) => { // on reçoit une liste d'entité 'Modelisation'
                    if (response) {
                        resolve();
                    } else {
                        reject(StringTranslate.badinitModelisationDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadModelisations', {
                            "erreur": "une erreur s'est produite lors de la demande de génération d'historique pour la modélisation côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badinitModelisationDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadModelisations', {
                            "erreur": "une erreur s'est produite lors de la demande de génération d'historique pour la modélisation côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badinitModelisationDatas);
                    return;
                });

        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de initialiser les parcelles pour la modélisation.
     */
     initModelisations: function (dispatch, getState, idClient, listOfModelisations) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0) || (idOfClient !== idClient)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiModelisation}InitParcels/${idOfClient}`;
        
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'POST', headers: headersAuthent, params: paramSearch, body: listOfModelisations  })
                .then((response) => { // on reçoit une liste d'entité 'Modelisation'
                    if (response) { // si reponse - les données de fumure existent en BDD
                        // liste des données de modélisation (une par parcelle en disposant)
                        const dicoFromListOfModelisations = ModelisationHelper.convertToDico(response); 
                        resolve(dicoFromListOfModelisations);
                    } else {
                        reject(StringTranslate.badinitModelisationDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadModelisations', {
                            "erreur": "une erreur s'est produite lors de l'initialisation de la modélisation côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badinitModelisationDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadModelisations', {
                            "erreur": "une erreur s'est produite lors de l'initialisation de la modélisations côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badinitModelisationDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    deleteModelisation: function (dispatch, getState, parcelIds) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiModelisation}Delete/${idOfClient}`;
        
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'POST', headers: headersAuthent, params: paramSearch, body: parcelIds  })
                .then((response) => { // on reçoit une liste d'entité 'Modelisation'
                    if (response) { // si reponse - les données de fumure existent en BDD
                        // liste des données de modélisation (une par parcelle en disposant)
                        const dicoFromListOfModelisations = ModelisationHelper.convertToDico(response); 
                        resolve(dicoFromListOfModelisations);
                    } else {
                        reject(StringTranslate.badinitModelisationDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('deleteModelisations', {
                            "erreur": "une erreur s'est produite lors de la suppression de la modélisation côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badinitModelisationDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('deleteModelisations', {
                            "erreur": "une erreur s'est produite lors de la suppression de la modélisation côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badinitModelisationDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    //#endregion étape 1
}

/**
 * Objet de connexion à l'API spotifarm dans le cadre de la récolte (maïs)
 */
const harvest_web_api_provider = {

    /* Mes récoltes */
    /**
     *  Fonction permettant de rechercher les récoltes de l'utilisateur connecté à SpotiFarm.
     */
     loadHarvests: function (dispatch, getState, idClient, cropEnum) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0) || (idOfClient !== idClient)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiHarvests}${idOfClient}/${cropEnum}`;
        
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit une liste d'entité 'Harvest'
                    if (response) { // si reponse - les données de récolte existent en BDD
                        // liste des données de récolte => transformation dico (parcelId - harvestData)
                        const dicoFromListOfHarvests = HarvestHelper.convertToDico(response); 
                        resolve(dicoFromListOfHarvests);
                    } else {
                        reject(StringTranslate.badLoadHarvestDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadHarvests', {
                            "erreur": "une erreur s'est produite lors de la recherche des données de récolte côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadHarvestDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadHarvests', {
                            "erreur": "une erreur s'est produite lors de la recherche des données de récolte côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadHarvestDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de rechercher les variétés de culture de l'utilisateur connecté à SpotiFarm.
     */
    loadVarietiesCharacteristics: function (dispatch, getState, idClient, cropEnum) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0) || (idOfClient !== idClient)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiHarvests}VarietiesCharacteristics/${idOfClient}/${cropEnum}`;
        
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit une liste d'entité 'harvestVarietyCharacteristics'
                    if (response) { // si reponse - les variétés de culture existent en BDD
                        // liste des variétés de culture => transformation dico (harvestVarietyCharacteristics)
                        const dicoFromListOfVarietiesCharacteristics = harvestVarietyCharacteristicsHelper.convertToDico(response); 
                        resolve(dicoFromListOfVarietiesCharacteristics);
                    } else {
                        reject(StringTranslate.badLoadHarvestDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadVarietiesCharacteristics', {
                            "erreur": "une erreur s'est produite lors de la recherche des variétés de culture côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadHarvestDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadVarietiesCharacteristics', {
                            "erreur": "une erreur s'est produite lors de la recherche des variétés de culture côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadHarvestDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     * Fonction pour charger des informations sur une variété de récolte.
     * @param {function} dispatch
     * @param {function} getState
     * @param {number} idClient - Identifiant du client.
     * @param {number} idParcel - Identifiant de la parcelle.
     * @param {string} cropEnum - Énumération de la culture.
     * @returns {Promise<Object>} - Promesse qui renvoi la varieté ou une erreur.
     */
    loadVariety: function (dispatch, getState, idClient, idParcel, cropEnum) {
        let promise = function (resolve, reject) {
            if(!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            const currentStoreState = getState();
            let tokenValue = undefined;

            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }

            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadHarvestDataWithoutClientId);
                return;
            }

            tools.testForUpdateToken(dispatch, getState);

            let requestUrl = `${ConfigApi.ConstAndDefault.UrlWebApiHarvests}LoadVariety/${idClient}/${idParcel}/${cropEnum}`;

            // on prépare la requête
            let paramSearch = {
                idClient: idOfClient,
            };

            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            }

            RequestCalls.requestWithAuthent(requestUrl, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => {
                    resolve(response);
                    return;
                })
                .catch((err) => {
                    sendError('LoadVariety', {
                        "erreur": "une erreur s'est produite lors du rapprochement entre la culture de la parcelle et celles du référenciel de récolte de maïs côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                    });

                    reject(err);
                    return;
                });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de lancer le calcul pour obtnir une date de récolte approximative pour les parcelles de maïs sélectionnées.
     */
    generateResult: function (dispatch, getState, idClient, harvests) {
        let promise = function (resolve, reject) {

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadHarvestDataWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            // on prépare la requête
            let paramSearch = {
                idClient: idOfClient,
            };
            
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiHarvests}Generate/${idClient}`;
            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch , body: harvests })
                .then((results) => {
                    if (results) { // si results - les données de récolte existent en BDD
                        // liste des données de récolte => transformation dico (parcelId - harvestData)
                        const dicoFromListOfHarvests = HarvestHelper.convertToDico(results); 
                        resolve(dicoFromListOfHarvests);
                    } else {
                        reject(StringTranslate.badLoadHarvestDatas);
                    }
                })
                .catch((err) => {
                    sendError('GenerateResult', {
                        "erreur": "une erreur s'est produite lors de la demande de dates approximatives de récolte de maïs côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                    });

                    reject(StringTranslate.badGetAnalysDatas);
                    return;
                });
        }

        return new Promise(promise);
    },

    saveHarvests: function (dispatch, getState, harvests){
        let promise = function(resolve, reject){
            if(!getState){
                reject(StringTranslate.noDeleteDatasWithoutStoreAcces);
                return;
            }

            const currentStoreState = getState();
            let tokenValue = undefined;
            if(currentStoreState && currentStoreState.connectionData){
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noDeleteDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noDeleteDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            let requestURLharvests = `${ConfigApi.ConstAndDefault.UrlWebApiHarvests}Save/${idOfClient}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLharvests, { method: 'POST', headers: headersAuthent, params: paramSearch, body: harvests })
            .then((response) => { // on reçoit un dico avec les récoltes sauvegarder
                if (response) {
                    resolve(response);
                } else {
                    reject(StringTranslate.errorSaveHarvests);
                }
                return;
            })
            .catch((err) => {
                if (sendError) {
                    sendError('saveHarvests', {
                        "erreur": "une erreur s'est produite lors de la sauvegarde des récoltes côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                        "harvests": harvests
                    });
                }

                reject(StringTranslate.errorSaveHarvests);
                return;
            });
        }

        return new Promise(promise);
    },

    deleteHervests: function (dispatch, getState, harvestIds){
        let promise = function(resolve, reject){
            if(!getState){
                reject(StringTranslate.noDeleteDatasWithoutStoreAcces);
                return;
            }

            const currentStoreState = getState();
            let tokenValue = undefined;
            if(currentStoreState && currentStoreState.connectionData){
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noDeleteDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noDeleteDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            let requestURLharvests = `${ConfigApi.ConstAndDefault.UrlWebApiHarvests}Delete/${idOfClient}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLharvests, { method: 'DELETE', headers: headersAuthent, params: paramSearch, body: harvestIds })
            .then((response) => { // on reçoit 'vrai' (ou 'faux') !
                if (response === true) {
                    resolve(response);
                } else {
                    reject(StringTranslate.badDeleteFertilizerDatas);
                }
                return;
            })
            .catch((err) => {
                if (sendError) {
                    sendError('deleteHarvests', {
                        "erreur": "une erreur s'est produite lors de la suppression des récoltes côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                        "harvestIds": harvestIds
                    });
                }

                reject(StringTranslate.badDeleteFertilizerDatas);
                return;
            });
        }

        return new Promise(promise);
    },

     /**
     *  Fonction permettant de supprimer les variétés de maïs sélectionnées.
     */
    deleteVarieties: function (dispatch, getState, varietyIds){
        let promise = function(resolve, reject){
            if(!getState){
                reject(StringTranslate.noDeleteDatasWithoutStoreAcces);
                return;
            }

            const currentStoreState = getState();
            let tokenValue = undefined;
            if(currentStoreState && currentStoreState.connectionData){
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noDeleteDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noDeleteDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            let requestURLharvests = `${ConfigApi.ConstAndDefault.UrlWebApiHarvests}DeleteVarieties/${idOfClient}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLharvests, { method: 'DELETE', headers: headersAuthent, params: paramSearch, body: varietyIds })
            .then((response) => { // on reçoit 'vrai' (ou 'faux') !
                if (response === true) {
                    resolve(response);
                } else {
                    reject(StringTranslate.badDeleteHarvestVarietyDatas);
                }
                return;
            })
            .catch((err) => {
                if (sendError) {
                    sendError('deleteVarieties', {
                        "erreur": "une erreur s'est produite lors de la suppression des variétés de maïs côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                        "varietyIds": varietyIds
                    });
                }

                reject(StringTranslate.badDeleteHarvestVarietyDatas);
                return;
            });
        }

        return new Promise(promise);
    },

    /**
     *  Fonction permettant de mettre à jour la variété de maïs passée en paramètre.
     */
    updateVariety: function (dispatch, getState, variety) {
        let promise = function(resolve, reject){
            if(!getState){
                reject(StringTranslate.noDeleteDatasWithoutStoreAcces);
                return;
            }

            const currentStoreState = getState();
            let tokenValue = undefined;
            if(currentStoreState && currentStoreState.connectionData){
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noUpdateDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noUpdateDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            let requestURLharvests = `${ConfigApi.ConstAndDefault.UrlWebApiHarvests}UpdateVariety/${idOfClient}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLharvests, { method: 'POST', headers: headersAuthent, params: paramSearch, body: variety })
            .then((response) => { // on reçoit 'vrai' (ou 'faux') !
                if (response) {
                    resolve(response);
                } else {
                    reject(StringTranslate.badUpdateDatas);
                }
                return;
            })
            .catch((err) => {
                if (sendError) {
                    sendError('updateVariety', {
                        "erreur": "une erreur s'est produite lors de la modification de la variété côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                        "variety": variety
                    });
                }

                reject(StringTranslate.badUpdateDatas);
                return;
            });
        }

        return new Promise(promise);
    },

    addVariety: function(dispatch, getState, newVariety){
        let promise = function(resolve, reject){
            if(!getState){
                reject(StringTranslate.noDeleteDatasWithoutStoreAcces);
                return;
            }

            const currentStoreState = getState();
            let tokenValue = undefined;
            if(currentStoreState && currentStoreState.connectionData){
                tokenValue = currentStoreState.connectionData.accessToken;
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noUpdateDatasWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noUpdateDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            let requestURLharvests = `${ConfigApi.ConstAndDefault.UrlWebApiHarvests}AddVariety/${idOfClient}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLharvests, { method: 'POST', headers: headersAuthent, params: paramSearch, body: newVariety })
            .then((response) => { // on reçoit 'vrai' (ou 'faux') !
                if (response && response.id) {
                    resolve(response);
                } else {
                    reject(StringTranslate.badAddingDatas);
                }
                return;
            })
            .catch((err) => {
                if (sendError) {
                    sendError('addVariety', {
                        "erreur": "une erreur s'est produite lors de l'ajout de la variété côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                        "variety": newVariety
                    });
                }

                reject(StringTranslate.badUpdateDatas);
                return;
            });
        }
        
        return new Promise(promise);
    },

    /**
     * Fonction pour charger les informations sur les objectif de récolte Maïs.
     * @param {function} dispatch
     * @param {function} getState
     * @returns {Promise<Object>} - Promesse qui renvoi les données ou l'erreur.
     */
    loadHarvestTargetInfos: function (dispatch, getState) {
        let promise = function (resolve, reject) {
            if(!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            const currentStoreState = getState();
            let tokenValue = undefined;

            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken;
            }

            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            let idOfClient = -1; //Identifiant du client (même s'il s'agit de données commune à tout client).
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noUpdateDatasWithoutClientId);
                return;
            }

            tools.testForUpdateToken(dispatch, getState);

            let requestUrl = `${ConfigApi.ConstAndDefault.UrlWebApiHarvests}GetFieldDryingConstantes`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            }

            RequestCalls.requestWithAuthent(requestUrl, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { //liste d'entité C# 'HarvestFieldDryingBaseInterval' !
                    resolve(response);
                    return;
                })
                .catch((err) => {
                    reject(err);
                    return;
                });
        }

        return new Promise(promise);
    },
}


const last_nitrogen_input_web_api_provider = {
    /* Mes derniers apports */
    /**
     *  Fonction permettant de rechercher les derniers apports de l'utilisateur connecté à SpotiFarm.
     */
    loadLastNitrogenInputs: function (dispatch, getState) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            let idOfClient = -1;
            if (currentStoreState) {
                tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
                idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadFertilizerDataWithoutClientId);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURLparcels = `${ConfigApi.ConstAndDefault.UrlWebApiLastNitrogenInput}${idOfClient}`;
        
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURLparcels, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((response) => { // on reçoit un Dico<parcelId,LastNitrogenInput>
                    if (response) {
                        //Transforme les entité pour qu'elles soient exploitable par le front
                        //                      (value,             key)
                        lodashForEach(response, (lastNitrogenInput, parcelId) => {
                            lastNitrogenInputHelper.getFromWebApiEntity(lastNitrogenInput);
                        });
                        resolve(response);
                    } else {
                        reject(StringTranslate.badLoadLastNitrogenInputDatas);
                    }
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('loadLastNitrogenInputs', {
                            "erreur": "une erreur s'est produite lors de la recherche des derniers apports azotés côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadLastNitrogenInputDatas);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('loadLastNitrogenInputs', {
                            "erreur": "une erreur s'est produite lors de la recherche des derniers apports azotés côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badLoadLastNitrogenInputDatas);
                    return;
                });
        };

        return new Promise(promise);
    },

    buildLastNitrogenInput: function (dispatch, getState, thisLastInputN) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }
            if (!thisLastInputN) {
                reject(StringTranslate.noBuildLastNitrogenInputWithoutDatas);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            let idOfClient = -1;
            if (currentStoreState) {
                tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
                idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noBuildLastNitrogenInputWithoutClientId);
                return;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noBuildLastNitrogenInputWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiLastNitrogenInput}Generation/${idOfClient}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: thisLastInputN })
                .then((response) => { // on reçoit la nouvelle entité '3ièeme apport'
                    if(response){
                        //Transforme l'entité pour qu'elle soit exploitable par le front
                        lastNitrogenInputHelper.getFromWebApiEntity(response);
                    }
                    resolve(response);
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('buildLastNitrogenInput', {
                            "erreur": "une erreur s'est produite lors de la génération d'un conseil 'dernier apport N' côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                            "idOfParcel": thisLastInputN.parcelId,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.badBuildLastNitrogenInput);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('buildLastNitrogenInput', {
                            "erreur": "une erreur s'est produite lors de la génération d'un conseil 'dernier apport N' côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                            "idOfParcel": thisLastInputN.parcelId,
                        });
                    }
                });
        };

        return new Promise(promise);
    },

    deleteLastNitrogenInputs: function (dispatch, getState, parcelIdsToDelete) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }
            if (!parcelIdsToDelete) {
                reject(StringTranslate.noBuildLastNitrogenInputWithoutDatas);
                return;
            }

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            let idOfClient = -1;
            if (currentStoreState) {
                tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
                idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noBuildLastNitrogenInputWithoutClientId);
                return;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noBuildLastNitrogenInputWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiLastNitrogenInput}Remove/${idOfClient}`;
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: parcelIdsToDelete })
                .then((response) => { // on reçoit toutes les entités 3Apports pour mettre à jour la donnée plus simplement
                    if(response){
                        //Transforme les entité pour qu'elles soient exploitable par le front
                        //                      (value,             key)
                        lodashForEach(response, (lastNitrogenInput, parcelId) => {
                            lastNitrogenInputHelper.getFromWebApiEntity(lastNitrogenInput);
                        });
                    }
                    resolve(response);
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('buildLastNitrogenInput', {
                            "erreur": "une erreur s'est produite lors de la suppression d'un conseil 'dernier apport N' côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                            "idOfParcel": parcelIdsToDelete,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.lastInputNDeleteError);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('buildLastNitrogenInput', {
                            "erreur": "une erreur s'est produite lors de la suppression d'un conseil 'dernier apport N' côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                            "idOfParcel": parcelIdsToDelete,
                        });
                    }

                    reject(StringTranslate.lastInputNDeleteError);
                    return;
                });
        };

        return new Promise(promise);
    },

    buildLastNitrogenSampleAndDoses: function (dispatch, getState, lastInputNParameter){
        let promise = function (resolve, reject){
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }
            if (!lastInputNParameter) {
                reject(StringTranslate.noBuildLastNitrogenInputWithoutDatas);
                return;
            }

            const currentStoreState = getState();
            let tokenValue = undefined;
            let idOfClient = -1;
            if (currentStoreState) {
                tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
                idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noBuildLastNitrogenInputWithoutClientId);
                return;
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noBuildLastNitrogenInputWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiLastNitrogenInput}BuildLastNitrogenSampleAndDoses/${idOfClient}`;
            
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            // Transformation de la donnée pour quelle soit compréhensible par le back
            lastNitrogenInputHelper.transformToWebApiEntity(lastInputNParameter);

            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: lastInputNParameter })
                .then((response) => {
                    // Transformation de la donnée pour quelle soit exploitable par le front
                    lastNitrogenInputHelper.getFromWebApiEntity(response);
                    resolve(response)
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('buildLastNitrogenSampleAndDoses', {
                            "erreur": "une erreur s'est produite lors de la modulation d'un conseil 'dernier apport N' côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                            "lastInputNParameter": lastInputNParameter,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.noResultLastNitrogenInputBuildSampleAndDoses);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('buildLastNitrogenSampleAndDoses', {
                            "erreur": "une erreur s'est produite lors de la modulation d'un conseil 'dernier apport N' côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                            "lastInputNParameter": lastInputNParameter,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.noResultLastNitrogenInputBuildSampleAndDoses);
                    return;
                });
        };

        return new Promise(promise);
    },

    buildLastNitrogenListPrescriptions: function (dispatch, getState, lastInputNIds, exportFormat){
        let promise = function (resolve, reject){
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }
            if (!lastInputNIds) {
                reject(StringTranslate.noBuildLastNitrogenInputWithoutDatas);
                return;
            }

            const currentStoreState = getState();
            let tokenValue = undefined;
            let idOfClient = -1;
            if (currentStoreState) {
                tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
                idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noBuildLastNitrogenInputWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //Export Shape par défaut !
            if ((!exportFormat) || (exportFormat <= 0)) {
                exportFormat = 1;
            }

            //On demande la liste des parcelles associées au client connecté :
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiLastNitrogenInput}BuildLastNitrogenListPrescriptions/${idOfClient}`;
            
            let paramSearch = {
                idClient: idOfClient,
                outputFormat: exportFormat,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: lastInputNIds })
                .then((blobAndMetas) => { // on reçoit le flux du fichier accompagné de ses caractèristiques !
                    //Cf. https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e :
                    let isOk = true;
                    if (blobAndMetas && blobAndMetas.data) {
                        blobAndMetas.data.blob()
                            .then((dataBlobed) => {
                                try {
                                    // Create blob link to download
                                    const url = window.URL.createObjectURL(new Blob([dataBlobed]));
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', blobAndMetas.fileName);
                                    try {
                                        // Append to html page
                                        document.body.appendChild(link);
                                        
                                        // Force download
                                        link.click();
                                    }
                                    finally {
                                        // Clean up and remove the link
                                        link.parentNode.removeChild(link);
                                    }
                                }
                                catch (errorDetected) {
                                    isOk = false;
                                    if (sendError) {
                                        sendError('buildLastNitrogenListPrescriptions', {
                                            "erreur": "une erreur s'est produite suite au téléchargement de la prescription de conseils 'dernier apport N' côté API",
                                            "err": errorDetected,
                                            "urlRequest": requestURL,
                                        });
                                    }
                                }

                                //Fin des téléchargements:
                                //Retourne que c'est Ok OU que l'on n'a pas atteint notre but:
                                resolve((isOk === true) ? true : false);
                            })
                            .catch((errorBlob) => {
                                if (sendError) {
                                    sendError('buildLastNitrogenListPrescriptions', {
                                        "erreur": "une erreur s'est produite lors de l'obtention du flux binaire lors du téléchargement de la prescription de conseils 'dernier apport N' côté API",
                                        "err": errorBlob,
                                        "urlRequest": requestURL,
                                    });
                                }

                                isOk = false;

                                //Fin des téléchargements:
                                //Retourne que l'on n'a pas atteint notre but:
                                resolve(isOk);
                            });
                    } else {
                        if (sendError) {
                            sendError('buildLastNitrogenListPrescriptions', {
                                "erreur": "le retour du téléchargement de la prescription de conseils 'dernier apport N' n'a pas fournis entité attendue",
                                "err": (blobAndMetas) ? "'blobAndMetas.data' is void!" : "'blobAndMetas' is undefined!",
                                "urlRequest": requestURL,
                            });
                        }

                        isOk = false;

                        //Fin des téléchargements:
                        //Retourne que l'on n'a pas atteint notre but:
                        resolve(isOk);
                    }
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('buildLastNitrogenListPrescriptions', {
                            "erreur": "une erreur s'est produite lors de la prescription de conseils 'dernier apport N' côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                            "lastInputNIds": lastInputNIds,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.noResultLastNitrogenInputBuildSampleAndDoses);

                    return;
                });
        };

        return new Promise(promise);
    },

    saveLastNitrogenSampleAndDoses: function (dispatch, getState, lastNitrogenInput) {
        let promise = function (resolve, reject) {
            if (!getState) {
                reject(StringTranslate.noLoadDatasWithoutStoreAcces);
                return;
            }
            if (lodashIsNil(lastNitrogenInput)) {
                reject(StringTranslate.noBuildLastNitrogenInputWithoutDatas);
                return;
            }

            const currentStoreState = getState();
            let tokenValue = undefined;
            let idOfClient = -1;
            if (currentStoreState) {
                tokenValue = lodashGet(currentStoreState, 'connectionData.accessToken', undefined);
                idOfClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
            }
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noBuildLastNitrogenInputWithoutClientId);
                return;
            }
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            //On demande la liste des parcelles associées au client connecté :
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiLastNitrogenInput}SaveLastNitrogenSampleAndDoses/${idOfClient}`;
            
            let paramSearch = {
                idClient: idOfClient,
            };
            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            // Transformation de la donnée pour quelle soit compréhensible par le back
            lastNitrogenInputHelper.transformToWebApiEntity(lastNitrogenInput);

            RequestCalls.requestWithAuthent(requestURL, { method: 'POST', headers: headersAuthent, params: paramSearch, body: lastNitrogenInput })
                .then((response) => {
                    // Transformation de la donnée pour quelle soit exploitable par le front
                    lastNitrogenInputHelper.getFromWebApiEntity(response);
                    resolve(response)
                    return;
                }, (error) => {
                    if (sendError) {
                        sendError('buildLastNitrogenSampleAndDoses', {
                            "erreur": "une erreur s'est produite lors de la sauvegarde d'une modulation d'un conseil 'dernier apport N' côté API",
                            "error": error,
                            "idOfClient": idOfClient,
                            "lastNitrogenInput": lastNitrogenInput,
                        });
                    }

                    //reject(error); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.noResultLastNitrogenInputSavedSampleAndDoses);
                    return;
                })
                .catch((err) => {
                    if (sendError) {
                        sendError('buildLastNitrogenSampleAndDoses', {
                            "erreur": "une erreur s'est produite lors de la sauvegarde d'une modulation d'un conseil 'dernier apport N' côté API",
                            "err": err,
                            "idOfClient": idOfClient,
                            "lastNitrogenInput": lastNitrogenInput,
                        });
                    }

                    //reject(err); //pas parlant pour l'utilisateur !
                    reject(StringTranslate.noResultLastNitrogenInputSavedSampleAndDoses);
                    return;
                });
        };

        return new Promise(promise);
    },

    // Création du pdf pour le dernier apport
    generateLastNitrogenInputPdf: function (dispatch, getState, lastInputNSelected, askObject = false, askStore = false) {
        let promise = function (resolve, reject) {

            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken; // jeton d'access token
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            // Check du client
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0) || 
                (!lastInputNSelected) || (idOfClient !== lastInputNSelected.clientId)) {
                reject(StringTranslate.noLoadModulationDatasWithoutClientId);
                return;
            }
            //Si token n'est pas bon alors on retourne
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

            // Désormais, on permet à l'utilisateur d'obtenir une visualisation des PDF ! Pour cela, on télécharge le fichier puis demande son ouverture dans un nouvel onglet...
            // RQ : Dans le cas de l'appli web hébergée dans l'appli mobile, on forcera à obtenir le flux brute pour le transmettre à l'appli mobile pour gérer l'affichage.
            // (pas le retour HTTP de type fichier téléchagé car cela déclenche des actions non-souhaitées de la part de la Web View)

            // on prépare la requête
            let paramSearch = {
                idClient: idOfClient,
                //par défaut, on télécharge le fichier Pdf... au sens où l'on ne demande pas l'entité contenant le flux brute du fichier
                resultObject: ((askStore !== true) && (askObject === true)) ? 1 : 0, //DONC, on ne demande pas la récupération de l'entité...
                storeFile: (askStore === true) ? 1 : 0, //ET DONC, on ne demande pas non-plus son stockage dans Azure...
            };
            //Cas de l'héberguement par notre application mobile => On demande à retourner l'objet/fichier directement ! 
            const isOnNativeApp = (IsNativeHoster() === true) ? true : false;
            //A partir du moment où l'on est hébergé par l'appli mobile, on force le stockage sur le compte Azure ! 
            // -> L'utilisateur pourra alors faire le choix de télécharger/imprimer/partager depuis l'affichage du fichier.
            if (isOnNativeApp === true) { 
                //demande le stockage du fichier (pour qu'il puisse être affiché sur le mobile).
                paramSearch.resultObject = 0;
                paramSearch.storeFile = 1;
            }

            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };
            // Appel de l'api
            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiLastNitrogenInput}Pdf/${lastInputNSelected.clientId}/${lastInputNSelected.parcelId}`;
            RequestCalls.requestWithAuthent(requestURL, { method: 'GET', headers: headersAuthent, params: paramSearch })
                .then((blobAndMetasOrObjectOrUrl) => {
                    //SI on est en mode 'appli mobile' OU demande de stockage du fichier (pour affichage), 
                    // on reçoit une URL du lieu de stockage dans Azure !
                    if (paramSearch.storeFile === 1) { 
                        if (isOnNativeApp === true) { //Si depuis l'appli native
                            //On va transmettre à l'appli Native cette URL pour lancer l'affichage !!!
                            resolve(blobAndMetasOrObjectOrUrl);
                        } else {
                            try {
                                window.open(blobAndMetasOrObjectOrUrl, "_blank", "noopener noreferrer");
                                resolve(true);
                            }
                            catch(errLink) {
                                if (sendError) {
                                    sendError('GenerateLastNitrogenInputPdf', {
                                        "erreur": "une erreur s'est produite suite au téléchargement du PDF de dernier apport côté API",
                                        "errLink": errLink,
                                        "urlRequest": requestURL,
                                    });
                                }

                                reject(StringTranslate.badShowPdfLastNitrogenInput);
                            }
                        }
                    } else if (paramSearch.resultObject === 1) { //Sinon, si on a demandé l'entité, 
                        //On l'a renvoie à l'appelant:
                        resolve(blobAndMetasOrObjectOrUrl);
                    } else { // Soit on reçoit le flux du fichier accompagné de ses caractèristiques !
                        //Cf. https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e :
                        if (blobAndMetasOrObjectOrUrl && blobAndMetasOrObjectOrUrl.data) {
                            blobAndMetasOrObjectOrUrl.data.blob()
                                .then((dataBlobed) => {
                                    let isOk = true;
                                    try {
                                        // Create blob link to download (Attention : Affiche le flux brute, non-interprêté, si on ne spécifie pas le 'type') :
                                        const url = window.URL.createObjectURL(new Blob([dataBlobed], { type: 'application/pdf' }));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', blobAndMetasOrObjectOrUrl.fileName);

                                        try {
                                            document.body.appendChild(link);
                                            
                                            // Forcer le téléchargement
                                            link.click();
                                        }
                                        finally {
                                            // Suppression du lien
                                            link.parentNode.removeChild(link);

                                            resolve(true);
                                        }
                                    }
                                    catch (errorDetected) {
                                        isOk = false;
                                        if (sendError) {
                                            sendError('GenerateLastNitrogenInputPdf', {
                                                "erreur": "une erreur s'est produite suite au téléchargement du PDF de dernier apport côté API",
                                                "errorDetected": errorDetected,
                                                "urlRequest": requestURL,
                                            });
                                        }

                                        reject(StringTranslate.badShowPdfLastNitrogenInput);
                                    }

                                    if (isOk) resolve(isOk);
                                })
                                .catch((errorBlob) => {
                                    if (sendError) {
                                        sendError('GenerateLastNitrogenInputPdf', {
                                            "erreur": "une erreur s'est produite lors de la génération du fichier PDF de dernier apport côté API",
                                            "errorBlob": errorBlob,
                                            "urlRequest": requestURL,
                                        });
                                    }

                                    //Fin des téléchargements:
                                    //Retourne que l'on n'a pas atteint notre but:
                                    reject(StringTranslate.badGeneratePdfModulation);
                                });
                        }
                        else //on ne fait rien !
                            reject(StringTranslate.badGeneratePdfModulation);
                    }
                })
                .catch((err) => {
                    sendError('GenerateLastNitrogenInputPdf', {
                        "erreur": "une erreur s'est produite lors de la demande de génération du fichier Pdf de dernier apport côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                        "idOfParcel": lastInputNSelected.parcelId,
                    });

                    reject(StringTranslate.badGeneratePdfModulation);
                    return;
                });
        }

        return new Promise(promise);
    },

    // Création du pdf de récap pour le dernier apport
    GenerateLastNitrogenPdfResume: function (dispatch, getState, idsParcel, askObject = false, askStore = false) {
        let promise = function (resolve, reject) {
            // Recherche de l'id client, ainsi que du jeton d'accès:
            const currentStoreState = getState();
            let tokenValue = undefined;
            if (currentStoreState && currentStoreState.connectionData) {
                tokenValue = currentStoreState.connectionData.accessToken; // jeton d'access token
            }
            let idOfClient = -1;
            if (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
                idOfClient = currentStoreState.clientUserData.clientDatas.id;
            }
            // Check du client
            if ((idOfClient === undefined) || (!Number.isInteger(idOfClient)) || (idOfClient <= 0)) {
                reject(StringTranslate.noLoadModulationDatasWithoutClientId);
                return;
            }
            //Si token n'est pas bon alors on retourne
            if (!tokenValue) {
                reject(StringTranslate.noLoadDatasWithoutToken);
                return;
            }

            //Lance la vérification du besoin de mise à jour de jeton d'authent, si proche expiration:
            tools.testForUpdateToken(dispatch, getState);

             // Désormais, on permet à l'utilisateur d'obtenir une visualisation des PDF ! Pour cela, on télécharge le fichier puis demande son ouverture dans un nouvel onglet...
            // RQ : Dans le cas de l'appli web hébergée dans l'appli mobile, on forcera à obtenir le flux brute pour le transmettre à l'appli mobile pour gérer l'affichage.
            // (pas le retour HTTP de type fichier téléchagé car cela déclenche des actions non-souhaitées de la part de la Web View)

            // on prépare la requête
            let paramSearch = {
                idClient: idOfClient,
                //par défaut, on télécharge le fichier Pdf... au sens où l'on ne demande pas l'entité contenant le flux brute du fichier
                resultObject: ((askStore !== true) && (askObject === true)) ? 1 : 0, //DONC, on ne demande pas la récupération de l'entité...
                storeFile: (askStore === true) ? 1 : 0, //ET DONC, on ne demande pas non-plus son stockage dans Azure...
            };
            //Cas de l'héberguement par notre application mobile => On demande à retourner l'objet/fichier directement ! 
            const isOnNativeApp = (IsNativeHoster() === true) ? true : false;
            //A partir du moment où l'on est hébergé par l'appli mobile, on force le stockage sur le compte Azure ! 
            // -> L'utilisateur pourra alors faire le choix de télécharger/imprimer/partager depuis l'affichage du fichier.
            if (isOnNativeApp === true) { 
                //demande le stockage du fichier (pour qu'il puisse être affiché sur le mobile).
                paramSearch.resultObject = 0;
                paramSearch.storeFile = 1;
            }

            let headersAuthent = {
                withCredentials: true,
                credentials: 'include',
                Authorization: `Bearer ${tokenValue}`,
            };

            let requestURL = `${ConfigApi.ConstAndDefault.UrlWebApiLastNitrogenInput}PdfRecapitulatif/${idOfClient}`;
            RequestCalls.requestWithAuthent(requestURL, {method: 'POST', headers: headersAuthent, params: paramSearch, body: idsParcel })
                .then((blobAndMetasOrObjectOrUrl) => {
                    if (paramSearch.storeFile === 1) {
                        if (isOnNativeApp === true) {
                            resolve(blobAndMetasOrObjectOrUrl);
                        } else {
                            try {
                                /*const link = document.createElement('a');
                                link.href = blobAndMetasOrObjectOrUrl;
                                link.setAttribute('target', "_blank");
                                link.setAttribute('rel', "noopener noreferrer");
                                
                                try {
                                    // Append to html page
                                    document.body.appendChild(link);
                                    
                                    // Force download
                                    link.click();
                                }
                                finally {
                                    // Clean up and remove the link
                                    link.parentNode.removeChild(link);
                                
                                    resolve(true);
                                }*/ //ne semble pas toujours fonctionner dans une promesse (ou bloquer en tant que popup) !

                                window.open(blobAndMetasOrObjectOrUrl, "_blank", "noopener noreferrer");
                                resolve(true);
                            }
                            catch(errLink) {
                                if (sendError) {
                                    sendError('GenerateLastNitrogenInputPdfResume', {
                                        "erreur": "une erreur s'est produite suite au téléchargement du PDF de dernier apport côté API",
                                        "errorLink": errLink,
                                        "urlRequest": requestURL,
                                    });
                                }
                                reject(StringTranslate.badShowPdfLastNitrogenInputResume);
                            }
                        } 
                    } else if (paramSearch.resultObject === 1) {
                        // On l'a renvoie à l'appelant
                        resolve(blobAndMetasOrObjectOrUrl);
                    } else { // Soit on reçoit le flux du fichier accompagné de ses caractèristiques !
                        //Cf. https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e :
                        if (blobAndMetasOrObjectOrUrl && blobAndMetasOrObjectOrUrl.data) {
                            blobAndMetasOrObjectOrUrl.data.blob()
                                .then((dataBlobed) => {
                                    let isOk = true;

                                    try {
                                        // Create blob link to download (Attention : Affiche le flux brute, non-interprêté, si on ne spécifie pas le 'type') :
                                        const url = window.URL.createObjectURL(new Blob([dataBlobed], { type: 'application/pdf' }));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', blobAndMetasOrObjectOrUrl.fileName);

                                        try {
                                            // Append to html page
                                            document.body.appendChild(link);
                                            
                                            // Force download
                                            link.click();
                                        }
                                        finally {
                                            // Clean up and remove the link
                                            link.parentNode.removeChild(link);
                                        }
                                    }
                                    catch (errorDetected) {
                                        isOk = false;
                                        if (sendError) {
                                            sendError('GenerateLastNitrogenInputPdfResume', {
                                                "erreur": "une erreur s'est produite suite au téléchargement du PDF de dernier apport côté API",
                                                "errorDetected": errorDetected,
                                                "urlRequest": requestURL,
                                            });
                                        }

                                        reject(StringTranslate.badShowPdfLastNitrogenInputResume);
                                    }

                                    if (isOk) resolve(isOk);
                                })
                                .catch((errorBlob) => {
                                    if (sendError) {
                                        sendError('GenerateLastNitrogenInputPdfResume', {
                                             "erreur": "une erreur s'est produite lors de la génération du fichier PDF de dernier apport côté API",
                                             "errorBlob": errorBlob,
                                             "urlRequest": requestURL,
                                        })
                                    }

                                    //Fin des téléchargements:
                                    //Retourne que l'on n'a pas atteint notre but:
                                    reject(StringTranslate.badGeneratePdfLastNitrogenInputPdfResume)
                                })
                        }
                        else //on ne fait rien !
                            reject(StringTranslate.badGeneratePdfLastNitrogenInputPdfResume);
                    }
                })
                .catch((err) => {
                    sendError('GenerateLastNitrogenInputPdfResume', {
                        "erreur": "une erreur s'est produite lors de la demande de génération du fichier PDF récapitulatif de dernier apport d'un client côté API",
                        "err": err,
                        "idOfClient": idOfClient,
                        "idsParcel": idsParcel,
                    });

                    reject(StringTranslate.badGeneratePdfBiomassResume);
                    return;
                })
        }
        return new Promise(promise);  
    }
}

export { 
    client_web_api_provider as clientWebApiProvider, 
    settings_web_api_provider as settingsWebApiProvider, 
    parcel_web_api_provider as parcelWebApiProvider, 
    history_web_api_provider as historyWebApiProvider, 
    stripe_web_api_provider as stripeWebApiProvider,
    observation_web_api_provider as observationWebApiProvider,
    didacticiel_firstparcel_web_api_provider as didacticielFirstparcelWebApiProvider,
    global_web_api_provider as globalWebApiProvider,
    modulation_web_api_provider as modulationWebApiProvider,
    biomass_web_api_provider as biomassWebApiProvider,
    store_web_api_provider as storeNativeAppWebApiProvider,
    fertilizer_web_api_provider as fertilizerWebApiProvider,
    modelisation_web_api_provider as modelisationWebApiProvider,
    harvest_web_api_provider as harvestWebApiProvider,
    last_nitrogen_input_web_api_provider as lastNitrogenInputWebApiProvider,
};