import { ActionTypeClientUser } from '../actions/clientUser.js';
import lodashGet from 'lodash/get';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';// et utilisé comme ceci: ${StringTranslate.xxx}


const initialState = {
    isWaitingToSearchClient: false, // permet de faire patienter l'utilisateur...
    updatingClientDatas: false,
    gettingPlansSubscriptions: false,

    clientDatas: {}, //Reçoit l'entité C# 'Client'.
    userDatas: {}, //Reçoit l'entité C# 'User'.
    clientIsNewer: false,
    pvLinkActivated: false,
    clientNeverShowWellcome: false,
    discountCodeToDisplay: undefined,
    forceIncitation: false,
    incitationType: undefined,
    
    planSubscriptionDico: {}, // liste des plans munis de leur souscription
    subscriptionProcess: { // objet représentant les données d'un client lors d'une souscription en cours de progression (plan sélectionné, code promo, token de carte de crédit, ...)
        planId: undefined,
        discountPlanId: undefined,
        isFormValid: false,
        asking: false, //pour un curseur d'attente lorsque l'on a demandé à Stripe ou au Store mobile...
        error: undefined,
        storePlanIdByOs: undefined, // objet de type : { appleId: 'id fo plan on Apple Store', googleId: 'id fo plan on Google Store' } !
        showPurchaseOnStoreResult: false,
    },
    isNewSubscription: false, // donnée représentant une souscription qui vient de se produire (effectuée et validée) (en lien avec le composant MapInfo pour affichage d'u visuel particulier)

    errorMessage: undefined,
    
    downloadingFile: false, //permettra de mettre un waiting cursor lors de le téléchargement d'une facture.
};

function clientUserReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypeClientUser.SearchingClient: {
            return Object.assign({}, state, {
                isWaitingToSearchClient: true,
            });
        }
            
        case ActionTypeClientUser.ClientFund: {
            if (action.clientDatas && action.clientDatas.client && action.clientDatas.client.id && (action.clientDatas.client.id > 0)) {
                //RQ: 'clientDatas.forceIncitation' et 'clientDatas.specificTypeIncitation' reflète les valeurs des mêmes propriétés disponibles sur 'userDatas' !
                // Mais ce serai mieux d'utiliser que les unes ou les autres...
                const forceIncitationValue = (action.clientDatas.user && (action.clientDatas.user.forceIncitation === true)) ? true : false;

                return Object.assign({}, state, {
                    isWaitingToSearchClient: false,
                    clientDatas: action.clientDatas.client,
                    userDatas: action.clientDatas.user,
                    //infos déduites:
                    clientIsNewer: (action.clientDatas.client.isNewer === true) ? true : false,
                    clientNeverShowWellcome: ( action.clientDatas.user && 
                        ((action.clientDatas.user.voidIncitation === undefined) || (action.clientDatas.user.voidIncitation === true)) ) ? true : false,
                    discountCodeToDisplay: (action.clientDatas.user) ? action.clientDatas.user.discountCode : undefined,
                    pvLinkActivated: (action.clientDatas.client.pvLinkActivated === true) ? true : false,
                    forceIncitation: forceIncitationValue,
                    incitationType: (forceIncitationValue === true) ? action.clientDatas.user.specificTypeIncitation : undefined,
                });
            } else { //Ne devrait pas arriver !
                return Object.assign({}, state, {
                    isWaitingToSearchClient: false,
                    errorMessage : StringTranslate.noLoadClientDatasOrId,
                });
            }
        }
            
        case ActionTypeClientUser.ErrorSearchClient: {
            return Object.assign({}, state, {
                isWaitingToSearchClient: false,
                errorMessage : action.error,
            });
        }

        case ActionTypeClientUser.AddClientBilling: {
            if (action.clientBilling)
                return {
                    ...state,
                    isWaitingToSearchClient: false, 
                    errorMessage: undefined,
                    clientDatas: {
                        ...state.clientDatas,
                        hasStripeLink: action.clientBilling.hasStripeLink,
                        buyByStripe: action.clientBilling.buyByStripe,
                        hasSourceValid: action.clientBilling.hasSourceValid,
                        planSubscriptions: action.clientBilling.planSubscriptions,
                        lastBillingDate: action.clientBilling.lastBillingDate,
                        totalInvoices: action.clientBilling.totalInvoices,
                        hasMoreInvoices: action.clientBilling.hasMoreInvoices,
                        lastUCharges: action.clientBilling.lastUCharges,
                        lastSCharges: action.clientBilling.lastSCharges,
                        naming: action.clientBilling.naming,
                        address: action.clientBilling.address,
                        phone: action.clientBilling.phone,
                        creditCard: action.clientBilling.creditCard,
                    }
            }
            else {
                return {
                    ...state,
                    isWaitingToSearchClient: false,
                    errorMessage: undefined,
                }
            }
        }

        case ActionTypeClientUser.updatingClientBilling: {
            return Object.assign({}, state, {
                updatingClientDatas: true,
            });
        }

        case ActionTypeClientUser.updateClientBilling: {
            return {
                ...state,
                updatingClientDatas: false,
                errorMessage: undefined,
                clientDatas: {
                    ...state.clientDatas,
                    naming: action.clientBilling.naming,
                    address: action.clientBilling.address,
                    phone: action.clientBilling.phone,
                    creditCard: action.clientBilling.creditCard,
                }
            };
        }

        case ActionTypeClientUser.errorUpdateClientBilling: {
            return {
                ...state,
                errorMessage: action.error,
                updatingClientDatas: false,
            };
        }

        case ActionTypeClientUser.gettingPlansSubscriptions: {
            return {
                ...state,
                gettingPlansSubscriptions: true,
            };
        }

        case ActionTypeClientUser.addPlansSubscriptions: {
            return {
                ...state,
                gettingPlansSubscriptions: false,
                errorMessage: undefined,
                planSubscriptionDico: action.planSubscriptionDico
            };
        }

        case ActionTypeClientUser.errorGetPlansSubscriptions: {
            return {
                ...state,
                gettingPlansSubscriptions: false,
                errorMessage: action.error,
            };
        }

        case ActionTypeClientUser.selectPlan: {
            let discountPlanIdFund = action.discountPlanId;
            if (!discountPlanIdFund) {
                try {
                    discountPlanIdFund = lodashGet(state, `planSubscriptionDico[${action.planId}].discountPlanDatas.idDiscount`, undefined);
                } catch (e) {
                    // RAS !
                }
            }
            return {
                ...state,
                gettingPlansSubscriptions: false,
                subscriptionProcess: {
                    ...state.subscriptionProcess,
                    planId: action.planId,
                    discountPlanId: discountPlanIdFund,
                }
            };
        }

        case ActionTypeClientUser.updatePlanWithStoreIds: {
            return {
                ...state,
                subscriptionProcess: {
                    ...state.subscriptionProcess,
                    storePlanIdByOs: action.storePlanIdByOs,
                }
            };
        }

        case ActionTypeClientUser.showPurchaseStoreResult: {
            if (state.subscriptionProcess) { 
                return {
                    ...state,
                    errorMessage: (action.isOk === true) ? undefined : state.errorMessage,
                    subscriptionProcess: {
                        ...state.subscriptionProcess,
                        asking: false, 
                        showPurchaseOnStoreResult: true,
                        error: (action.isOk === true) ? undefined : state.subscriptionProcess.error,
                    }
                };
            } else {
                return {
                    ...state,
                    errorMessage: (action.isOk === true) ? undefined : state.errorMessage,
                    subscriptionProcess: {
                        asking: false, 
                        showPurchaseOnStoreResult: true,
                        error: undefined, //puisque 'state.subscriptionProcess' n'existe pas donc pas d'accès à 'state.subscriptionProcess.error',
                    }
                };
            }
        }

        case ActionTypeClientUser.closePurchaseStoreResult: {
            if (state.subscriptionProcess) { 
                return {
                    ...state,
                    errorMessage: undefined,
                    subscriptionProcess: {
                        ...state.subscriptionProcess,
                        asking: false, 
                        showPurchaseOnStoreResult: false,
                    }
                };
            } else {
                return {
                    ...state,
                    errorMessage: undefined,
                    subscriptionProcess: undefined,
                };
            }
        }

        case ActionTypeClientUser.addDiscountedPlan: {
            return {
                ...state,
                planSubscriptionDico: {
                    ...state.planSubscriptionDico,
                    [action.planId]: {
                        ...state.planSubscriptionDico[action.planId],
                        discountPlanDatas: action.discountPlan,
                    }
                }
            }; 
        }

        case ActionTypeClientUser.setIsFormValid: {
            return {
                ...state,
                subscriptionProcess: {
                    ...state.subscriptionProcess,
                    isFormValid: action.isFormValid,
                }
            }
        }

        case ActionTypeClientUser.errorSubscriptionProcess: {
            if (state.subscriptionProcess) { 
                return {
                    ...state,
                    subscriptionProcess: {
                        ...state.subscriptionProcess,
                        asking: false, 
                        error: action.error,
                    }
                };
            } else {
                return {
                    ...state,
                    subscriptionProcess: {
                        asking: false, 
                        error: action.error,
                    }
                };
            }
        }

        case ActionTypeClientUser.askSubscriptionProcess: {
            if (state.subscriptionProcess) { 
                return {
                    ...state,
                    subscriptionProcess: {
                        ...state.subscriptionProcess,
                        asking: true, 
                    }
                };
            } else {
                return {
                    ...state,
                    subscriptionProcess: {
                        asking: true, 
                    }
                };
            }
        }

        case ActionTypeClientUser.endSubscriptionProcess: {
            if (state.subscriptionProcess) { 
                return {
                    ...state,
                    subscriptionProcess: {
                        ...state.subscriptionProcess,
                        asking: false, 
                    }
                };
            } else {
                return {
                    ...state,
                    subscriptionProcess: {
                        asking: false, 
                    }
                };
            }
        }

        case ActionTypeClientUser.setIsNewSubscription: {
            return {
                ...state,
                isNewSubscription: action.bool,
            }
        }

        case ActionTypeClientUser.resetSubscriptionProcess: {
            return {
                ...state,
                subscriptionProcess: {
                    planId: undefined,
                    discountPlanId: undefined,
                    isFormValid: false,
                    error: undefined,
                    storePlanIdByOs: undefined, 
                    showPurchaseOnStoreResult: false,
                }
            }
        }

        case ActionTypeClientUser.errorGetClientBilling: {
            return {
                ...state,
                errorMessage: action.error,
            };
        }

        case ActionTypeClientUser.updateTypoClient: {
            return {
                ...state,
                clientDatas: {
                    ...state.clientDatas,
                    //modifie l'ancienne typo en utilisant celle actuelle:
                    idLastTypoClient: state.clientDatas.idTypoClient,
                    enumLastTypoClient: state.clientDatas.enumTypoClient,

                    idTypoClient: action.typoClientToUpdate.id,
                    enumTypoClient: action.typoClientToUpdate.enumTypoClient,
                    codeTypoClient: action.typoClientToUpdate.code,
                    labelTypoClient: action.typoClientToUpdate.label,
                    maxParcelTypoClient: action.typoClientToUpdate.maxParcels,
                    authorizeHistoric: action.typoClientToUpdate.authorizeHistoric,
                    authorizeModulation: action.typoClientToUpdate.authorizeModulation,
                    authorizeBiomass: action.typoClientToUpdate.authorizeBiomass,
                    authorizeModelisation: action.typoClientToUpdate.authorizeModelisation,
                    authorizeFertilizer: action.typoClientToUpdate.authorizeFertilizer,
                    daysMax: action.typoClientToUpdate.daysMax,
                }
            }
        }

        case ActionTypeClientUser.downloadingInvoice: {
            return Object.assign({}, state, {
                downloadingFile: true,
            });
        }

        case ActionTypeClientUser.invoiceDownloaded: {
            return Object.assign({}, state, {
                downloadingFile: false,
                errorMessage: undefined,
            });
        }

        default:
            return state;
    }
}

export default clientUserReducer;
