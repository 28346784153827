import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


function EuroReportFactureIcon(props: SvgIconProps) {
    return ( 
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" strokeWidth="1.8" stroke="current" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path stroke="current" d="M6 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
              <rect stroke="current" x="6" y="3" width="6" height="4" rx="2" />
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path stroke="current" d="M12.2 9a4 3 0 1 0 0 9" />
              <path stroke="current" d="M11 12.5h-6m0 2h6" />
            </svg>
        </SvgIcon>
    );
}

export default EuroReportFactureIcon;