import React, { Component } from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

/* MUI Components */
import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Divider, Fab, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';

/* MUI Icones */
import CloudIcon from '@mui/icons-material/Cloud';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import IconeModulation from '../../../assets/images/modulation/iconeModulation.tsx';

/* Utils */
import ConstantsLayers from '../../../utils/constantsOfLayers';

/* Actions */
import { ActionBuildLastNitrogenSampleAndDoses, ActionGoToStepOfLastNitrogenInput, ActionSelectLayerForLastNitrogenInput, LastNitrogenInputStep } from '../../../redux/actions/lastNitrogenInput';

/* Assets */
import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';

import { DEFAULT_MODULATION_DISPERSION } from '../../../utils/modulationsHelper';

/* MUI Theme */
import getTheme from "../../../themes/index.js";
let theme = getTheme();

class MapButtonsPaneLastNitrogenInput extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isSelectedVisibleLayer: false,
        };
    }
    

    componentDidMount() {
        const { selectLayerForLastNitrogenInput, } = this.props;

        // On prévient qu'il faut changer de fond de carte: 
        if (selectLayerForLastNitrogenInput) {
            // Valeurs possibles pour 'forewardLayerSelected' : NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
            selectLayerForLastNitrogenInput(ConstantsLayers.VisibleBaseLayerName, ConstantsLayers.NdviParcelLayerName);
        }
    }

    /**
     * fonction callback lors du click du bouton d'un choix de couche générale (par dessus celle de fond) dont son nom est passé en paramètre
     */
    onSelectGeneralLayer() {
        // ↓↓ suivant le nom du layer passé en paramètre ↓↓
        const { isSelectedVisibleLayer } = this.state;
        const { selectLayerForLastNitrogenInput, } = this.props;

        let newLastLayer = undefined;

        if (isSelectedVisibleLayer === false) {
            newLastLayer = ConstantsLayers.VisibleGlobalLayerName;
        }
        else {
            newLastLayer = ConstantsLayers.NdviParcelLayerName;
        }

        this.setState({
            isSelectedVisibleLayer: isSelectedVisibleLayer === true ? false : true
        });

        // On prévient qu'il faut changer de fond de carte: 
        if (selectLayerForLastNitrogenInput) {
            // Valeurs possibles pour 'forewardLayerSelected' : NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
            selectLayerForLastNitrogenInput(ConstantsLayers.VisibleBaseLayerName, newLastLayer);
        }
    }

    onConfirmImage() {
        const { buildLastNitrogenSampleAndDoses, clientId, parcelIdSelected, 
            satImageIdSelectedOfParcel, satImageDateSelectedOfParcel, satImageProviderSelectedOfParcel, lastInputNParameter, lastNitrogenInputDico } = this.props;

            const lastNitrogenInput = lodashGet(lastNitrogenInputDico, `[${parcelIdSelected}]`, {});


        if ((lastNitrogenInput.hasModulation === false) && (lastInputNParameter.parcelId !== lastNitrogenInput.parcelId)) {

            let lastInputNParameter = {
                clientId: clientId, 
                parcelId: parcelIdSelected, 
                imagSourceId: satImageIdSelectedOfParcel, 
                dateImagSource: satImageDateSelectedOfParcel, 
                providerImagSource: satImageProviderSelectedOfParcel, 
                zonesCounter: 2,
                dispersion: DEFAULT_MODULATION_DISPERSION / 100,
                // On ne renseigne pas ces deux données, le back ira lire les infos en BdD
                //dosePreviouslyInput: doseSaved,
                //content: content,
            };
            buildLastNitrogenSampleAndDoses(lastInputNParameter);
        } else {
            //Dans le cas d'une modification d'une modulation existante on récupère les paramètres déjà existants
            let newLastInputNParameter = {
                ...lastInputNParameter,
                parcelId: parcelIdSelected, 
                imagSourceId: satImageIdSelectedOfParcel, 
                dateImagSource: satImageDateSelectedOfParcel, 
                providerImagSource: satImageProviderSelectedOfParcel,
                zonesCounter: 2,
                dosePreviouslyInput: lastNitrogenInput.dosePreviouslyInput,
                content: lastNitrogenInput.content,
                dispersion: lastNitrogenInput.dispersion,
            };
            buildLastNitrogenSampleAndDoses(newLastInputNParameter);
        }
    }

    onGoBack() {
        const { goToStepOfLastNitrogenInput, } = this.props;

		goToStepOfLastNitrogenInput(LastNitrogenInputStep.CHOIX_LAST_NITROGEN_INPUTS);
    }

    render() {
        const { satImageIdSelectedOfParcel, building, } = this.props;
        const { isSelectedVisibleLayer, } = this.state;

        return (
            <>
                {/* ↓ contrôle (bouton) en lien avec la sélection de layer - mobile OU tablette ↓ */}
                <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>

                    {/* Bouton de retour vers la pages des modulations */}
                    <Box className='leaflet-top leaflet-right leaflet-PosNone'>

                        <Tooltip title={<Typography color="inherit">{isSelectedVisibleLayer ? StringTranslate.ComeBackToIndicevege : StringTranslate.DetectClouds}</Typography>} placement="left">
                            <Fab
                                className='leaflet-control'
                                size="small"
                                onClick={() => this.onSelectGeneralLayer()}
                                sx={{
                                    backgroundColor: theme.palette.background.paper,
                                    color: theme.palette.primary.dark
                                }}>
                                {isSelectedVisibleLayer ? <CloudOffIcon /> : <CloudIcon />}
                            </Fab>
                        </Tooltip>

                    </Box>

                    <div className='leaflet-bottom leaflet-PosNone leaflet-center-buttons-navigation'>

                        <Card sx={{ marginBottom: "118px", marginRight: "5px", marginLeft: "5px" }}>
                            <CardActions>
                                <Grid container className={(building === true) ? "disabledAll" : ""}>

                                    <Grid item xs={4}>

                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={() => this.onGoBack()}
                                            tabIndex={12}
                                            sx={{
                                                zIndex: 800,
                                                pointerEvents: "fill",
                                            }}>
                                            <ArrowBackIcon />
                                        </Button>
                                    </Grid>

                                    <Grid item xs={8} align="right">

                                        <Stack direction="row" spacing={1} justifyContent="flex-end">
                                            <Button
                                                variant="text"
                                                color="primary"
                                                startIcon={
                                                    (building === false) ?
                                                        <IconeModulation />
                                                        : <CircularProgress
                                                            color="inherit"
                                                            size={24}
                                                        />}
                                                sx={{
                                                    bgcolor: theme.palette.background.paper,
                                                    color: theme.palette.primary.main,
                                                    zIndex: 800,
                                                    pointerEvents: "fill",
                                                }}
                                                onClick={() => this.onConfirmImage()}
                                                disabled={(satImageIdSelectedOfParcel === undefined) || (satImageIdSelectedOfParcel <= 0) || (building === true)}
                                            >
                                                {StringTranslate.valider}
                                            </Button>
                                        </Stack>

                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Card>
                    </div>

                </Box>

                {/* ↓ contrôle (bouton) en lien avec la sélection de layer - PC↓ */}
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>

                    <Card 
                        className="leaflet-bottom leaflet-left leaflet-PosNone"
                        variant_pz="card-md-bio">

                        <Box sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                            <CardHeader sx={{
                                    '& .MuiCardHeader-action': { mr: 0 }
                                }} 
                                title={<Typography variant="h5">{StringTranslate.startModulationImageChoice}</Typography>} 
                            />
                            <Divider />
                        </Box>

                        <CardContent>

                            <Box sx={{ display: { xs: "block", sm: "block", md: "block", lg: "none" } }}>
                                <Typography variant="subtitle1">{StringTranslate.startModulationImageChoice}</Typography>
                            </Box>

                            { /* Layer */}
                            <Grid container alignItems="center" spacing={0} justifyContent="space-between">
                                <Grid item xs={8}>
                                    <Typography variant="subtitle2" >
                                        {StringTranslate.lastNInputImageChoiceHelper}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton
                                        sx={{
                                            bgcolor: theme.palette.primary.light,
                                            color: theme.palette.primary.main,
                                            '& .MuiSvgIcon-root': { fontSize: '1.5rem' }
                                        }}
                                        size="large"
                                        onClick={() => this.onSelectGeneralLayer()}>
                                        {(isSelectedVisibleLayer === true) ?
                                            <CloudOffIcon /> : <CloudIcon />}
                                    </IconButton>
                                </Grid>
                            </Grid>

                        </CardContent>

                        <CardActions>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Button variant="text" color="primary"
                                        onClick={() => this.onGoBack()}
                                        tabIndex={12}
                                        disabled={(building === true)}
                                    >
                                        {`${StringTranslate.goBackStepBiomass}`}
                                    </Button>
                                </Grid>

                                <Grid item xs={8} align="right">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={(building === true) && (
                                            <CircularProgress color="inherit"
                                                size={24} />)}
                                        onClick={() => this.onConfirmImage()}
                                        disabled={(satImageIdSelectedOfParcel === undefined) || (satImageIdSelectedOfParcel <= 0) || (building === true)}
                                    >
                                        {`${StringTranslate.next}`}
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardActions>

                    </Card>
                </Box >
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        //Infos provenant du reducer 'clientUser':
        clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1),

        //Infos provenant du reducer 'contextApp':
        parcelIdSelected: lodashGet(state, 'contextAppData.parcelIdSelected', undefined),
        satImageIdSelectedOfParcel: lodashGet(state, 'contextAppData.satimageIdSelectedOfParcel', -1),
        satImageDateSelectedOfParcel: lodashGet(state, 'contextAppData.satimageDateSelectedOfParcel', undefined),
        satImageProviderSelectedOfParcel: lodashGet(state, 'contextAppData.providerSrcImageSelectedOfParcel', undefined),

        //Infos provenant du reducer 'lastNitrogenInputData':
        building: lodashGet(state, 'lastNitrogenInputData.buildingSampleAndDose', false),
        lastNitrogenInputDico: lodashGet(state, 'lastNitrogenInputData.lastNitrogenInputDico', {}),
        lastInputNParameter: lodashGet(state, 'lastNitrogenInputData.lastInputNParameter', {}),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        goToStepOfLastNitrogenInput: (nextStepOfLastNitrogenInput) => dispatch(ActionGoToStepOfLastNitrogenInput(nextStepOfLastNitrogenInput)),
        buildLastNitrogenSampleAndDoses: (lastInputNParameter) => dispatch(ActionBuildLastNitrogenSampleAndDoses(lastInputNParameter)),
        
        selectLayerForLastNitrogenInput: (baseLayertypeValue, forewardLayertypeValue) => dispatch(ActionSelectLayerForLastNitrogenInput(baseLayertypeValue, forewardLayertypeValue)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapButtonsPaneLastNitrogenInput);