import React from "react";
import lodashGet from 'lodash/get';
import lodashFind from 'lodash/find';
import lodashIsNil from 'lodash/isNil';
import { connect } from 'react-redux';

/* Composants MUI */
// import { createFilterOptions } from '@mui/material/Autocomplete';
import {
    InputAdornment, Button, CircularProgress, Grid, Typography, /*Autocomplete,*/
    Box, Dialog, DialogActions, DialogContent, DialogContentText,  DialogTitle, TableContainer,
    FormControl, TextField, InputLabel, Select, MenuItem, Divider, Alert, Card, AlertTitle,
    List, ListItem, ListItemText
} from '@mui/material';

/* Composants React */
import { ThumbnailParcelShapeFromPathInfos } from '../../thumbnail/ThumbnailParcelShape.jsx';
import CustomDataGrid from "../../customDataGrid";
import LinkToContactUs from '../../linkToContactUs';

import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';

/* DatePicker */
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';

/* Icones */
// import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import DoneIcon from '@mui/icons-material/Done';


/* Helpers et Dico */
import stringHelper from '../../../utils/stringHelper.js';
import DateHelper from '../../../utils/dateHelper.js';
import { ParcelsHelper } from '../../../utils/parcelsHelper.js';
import dicoFunction from '../../../datas/dicoDetails.js';
import FertilizerHelper from "../../../utils/fertilizerHelper";

/* Redux */
import { TableType } from '../../../redux/actions/settings.js';
import { ProfilIndex, ActionShowProfilMenuDialog } from "../../../redux/actions/contextApp.js";
import { ActionSaveParcel } from '../../../redux/actions/parcels.js';
import { ActionGetCommonInfosAndListsByCulture, ActionSelectParcelsForFertilizing, ActionClearFertilizerValue, ActionGetAllFertilizers } from '../../../redux/actions/fertilizer';
import { ActionDeleteLastNitrogenInput } from "../../../redux/actions/lastNitrogenInput";

/* css */
import '../../../assets/css/renderParcels.css';
import '../../../assets/css/pdp-pinDropPopup.css';

/* theme Berry */
import getTheme from "../../../themes/index.js";
let theme = getTheme();

/* Concerne les options pour les cultures, cultures précédentes, exploitation */
// const filter = createFilterOptions();

/**
 * Composant d'affichage des parcelles actuelles.
 */
class CultureAndParcelsChoice extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openConfirmDialog: false, //ouvre le popup dialog pour confirmer la suppression de toutes les parcelles
            openDeleteParcels: false, //ouvre le popup dialog pour confirmer la suppression d'une ou plusieurs parcelles
            openUpdateParcel: false, //ouvre le popup dialog pour modifier une parcelle
            openDeleteLastInputNDialog: false, //ouvre la pop-up dialog pour la suppression des derniers apports
            parcelsToDeleteWithModulation: false, //au moins une parcelle pour la suppression est liée à une modulation
            parcelNameWithLastNInput: [], // Nom de la parcelle avec dernier apport
            isDeleting: false, //suppression des parcelles en cours...

            datas: this.initialiseDatas(props.parcelIdFilteredList), //parcelles
            cultures: this.initialiseCultures(), //initialisation des cultures : cultures fixes + cultures existantes dans les parcelles
            varieties: this.initialiseVarieties(), // initialisation des variétés : bouton "ajouter une variété" + variétés existantes dans les parcelles
            farms: this.initialiseFarms(), // initialisation des exploitations : bouton "ajouter une exploitation" + exploitations existantes dans les parcelles 
            //sauvegarde la langue en cours lors de la création du composant: (Car pas présente dans le store Redux)
            language: StringTranslate.getLanguage(),

            rowsPerPageForTableParcels: props.rowsPerPageForTableParcels, //nombre de lignes par page dans le tableau enregistré

            //Si une culture a déjà été sélectionnée auparavant, garder la culture et garder les parcelles sélectionnées auparavant
            parcelsSelected: (props.parcelIdsSelected !== undefined) && (props.parcelIdsSelected.length > 0) && (props.cultureSelected !== undefined) ? props.parcelIdsSelected : [], //parcelles sélectionnées par les checkbox
            pageSize: props.rowsPerPageForTableParcels, //Nombre de lignes par page au départ
            searchFilter: '', //Zone de recherche du tableau
            currentRowsOfTable: [], //Datas en fonction du texte tapé dans la zone de recherche

            //Valeur affectée aux différentes données d'une parcelle en cours de modification:
            id: 0,
            name: null,
            campagne: null,
            culture: null,
            culturePrecedente: null,
            dateSemi: null,
            exploitation: null,
            surface: null,
            variete: null,
            thumbnailInfos: null,

            localeMap: ((StringTranslate.getLanguage() === 'fr-FR' || StringTranslate.getLanguage() === 'fr-BE') ? frLocale : (StringTranslate.getLanguage() === "en-GB" ? enLocale : esLocale)), //Concerne la traduction des datePicker

            //Si une culture a déjà été sélecionnée auparavant, garder la culture
            cultureForFertilizing: (props.cultureSelected !== undefined) ? props.cultureSelected : '', //culture à choisir pour le tri dans les parcelles.
        };

        // liste des noms de colonnes affichées
        this.columns = this.initialiseColumns(props.parcelIdFilteredList); //RQ: pas dans le 'state' car on en utilise des propriétés !
        this.CustomToolbar = this.CustomToolbar.bind(this);
    }

    componentDidMount() {
        const { cultureForFertilizing, datas } = this.state;
        const { fertilizerDicoIsLoaded, getAllFertilizers, parcelsArchivedForFertilizerLoaded, clientId } = this.props;

        if (cultureForFertilizing !== '') {
            //On refait le tri dans les parcelles
            this.sortDatasByCrop(datas, cultureForFertilizing);
        }

        //Les fumures actuelles et archivées sont chargées à cette étape.

		//Les fumures actuelles et archivées:
		if ((fertilizerDicoIsLoaded === false) && (parcelsArchivedForFertilizerLoaded === false)) {
			getAllFertilizers(clientId);
		}
    }

    componentDidUpdate(prevProps, prevState) {
        //@@Réagir aux modifications sur les parcelles : Modification d'une ou plusieurs données d'une parcelle !
        const { parcelIdFilteredList, updatingParcelName, updatingParcelInfos, fertilizerDicoIsLoaded, } = this.props;
        const { language, cultureForFertilizing } = this.state;

        /* Lorsqu'il y a une modification des données d'une parcelle (RQ: n'a pas être réalisé qu'ici!), il faut actualiser la liste des 'datas': */
        /* RQ: c'est plus rapide de recharger toutes les parcelles que de rechercher quelle parcelle a été modifié et quelle(s) info(s) de cette parcelle! */
        if (((prevProps.updatingParcelName === true) && (updatingParcelName === false)) ||
            ((prevProps.updatingParcelInfos === true) && (updatingParcelInfos === false)) ||
            ((prevProps.fertilizerDicoIsLoaded === false) && (fertilizerDicoIsLoaded === true))) { //RQ: lorsque l'on passe de 'true' à 'false' c'est que le traitement est finis (même si pourrai être KO)
            
            const newCultureList = this.initialiseCultures();
            const newVarietiesList = this.initialiseVarieties();
            const newFarmList = this.initialiseFarms();
            const newDatas = this.initialiseDatas(parcelIdFilteredList);


            this.setState({
                datas: newDatas,
                openUpdateParcel: false,
                cultures: newCultureList,
                varieties: newVarietiesList,
                farms: newFarmList,
            });

            //Trie les données du tableau en fonction de la culture choisie
            this.sortDatasByCrop(newDatas, cultureForFertilizing);
        }

        /* Lorsqu'il y a un changement de langue (même si on se doute que l'utilisateur ne changera pas de langue tous les 4 matins !), il faut actualiser certaines listes: */
        const currentLanguage = StringTranslate.getLanguage();
        if ((!language) || (language === '') || (language !== currentLanguage)) {
            //RQ: C'est par ce que la liste des cultures comprend une liste fixe de cultures, qui sont naturellement traduites!
            const newCultures = this.initialiseCultures(); //initialisation des cultures : cultures fixes + cultures existantes dans les parcelles
            // liste des noms de colonnes affichées. 
            this.columns = this.initialiseColumns(parcelIdFilteredList); //RQ: ca va que l'on actualise 'cultures' sans quoi le rendu n'aurai pas lieu et on n'utiliserai pas la nouvelle liste de noms de colonne !

            this.setState({ cultures: newCultures, language: currentLanguage, });
        }
    }

    //renvoie les exploitations appartenant aux parcelles existantes
    initialiseFarms() {
        const { parcelDico } = this.props;

        let farms = [];

        //Ajout des nom d'exploitations trouvés dans la définition des parcelles:
        ParcelsHelper.searchFarms(parcelDico)
            .forEach(farm => {
                if (!farms.find(f => f.title === farm))
                    farms.push({ title: farm });
            });

        return Array.from(new Set(farms));
    }

    // renvoie les variétés appartenant aux parcelles existantes
    initialiseVarieties() {
        const { parcelDico } = this.props;
        let varieties = [];

        //Ajout des variétés trouvées dans la définition des parcelles:
        ParcelsHelper.searchVarieties(parcelDico)
            .forEach(variety => {
                if (!varieties.find(v => v.title === variety)) {
                    varieties.push({ title: variety });
                }
            });

        return varieties.sort((a, b) => a.title > b.title ? 1 : -1);
    }

    //renvoie les cultures appartenant aux parcelles existantes
    initialiseCultures() {
        const { parcelDico } = this.props;

        return ParcelsHelper.mergeCultures(parcelDico);
    }

    // /* Edition d'une parcelle sélectionnée dans le tableau */
    // handleEditClick = (parcel, thumbnailInfos) => {
    //     this.setState({
    //         id: parcel.id,
    //         openUpdateParcel: true,

    //         name: parcel.name,
    //         campagne: parcel.details.campagne,
    //         culture: parcel.details.culture,
    //         culturePrecedente: parcel.details.culturePrecedente,
    //         dateSemi: parcel.details.dateSemi,
    //         exploitation: parcel.details.exploitation,
    //         surface: parcel.details.surface,
    //         variete: parcel.details.variete,
    //         details: parcel.details,
    //         thumbnailInfos: thumbnailInfos,
    //     });

    // }

    /* Fermeture de la popup de l'édition d'une parcelle */
    handleCloseUpdateParcel = () => {
        this.setState({
            openUpdateParcel: false,
        });
    }

    /* Validation de l'édition d'une parcelle sélectionnée */
    handleUpdateParcel = () => {
        const {
            name, campagne, culture,
            culturePrecedente, dateSemi, exploitation,
            variete, id /* id de la parcelle sélectionnée pour modification de données */
        } = this.state;

        const { parcelDico, updateParcel } = this.props;

        if (parcelDico) {
            const parcelToUpdate = lodashGet(parcelDico, `[${id}]`, undefined);

            //Le nom de la parcelle est obligatoire pour mettre à jour les données de la parcelle.
            if (name !== "") {

                parcelToUpdate.details.campagne = campagne;
                parcelToUpdate.details.culture = culture;
                parcelToUpdate.details.culturePrecedente = culturePrecedente;
                parcelToUpdate.details.dateSemi = dateSemi;
                parcelToUpdate.details.exploitation = exploitation;
                parcelToUpdate.details.variete = variete;
                parcelToUpdate.name = name;

                //Appel à l'action Redux d'enregistrement des modifications sur la parcelle:
                updateParcel(id, parcelToUpdate);
            }
        }
    }

    /**
     * Initialisation des colonnes de parcelles.
     * Il y a une traduction sur les listes fixes de cultures, exploitations et variétés
     */
    initialiseColumns(parcelIdFilteredList) {
        const { parcelDico } = this.props;

        let newColumns = [];
        if (parcelIdFilteredList && parcelIdFilteredList[0]) {

            //On ne souhaite plus pouvoir éditer les parcelles dans ce tableau
            // newColumns.push({
            //     field: 'actions',
            //     editable: false,
            //     hideable: false,
            //     headerName: '',
            //     width: 60,
            //     renderCell: params => {
            //         return (
            //             <IconButton onClick={() => this.handleEditClick(params.value, params.row.thumbnailInfos)}>
            //                 <EditIcon color="inherit" />
            //             </IconButton>
            //         )
            //     }
            // });

            /* ↓ ajout colonne visible - (Miniature + nom de la parcelle) ↓ */
            newColumns.push({
                headerName: `${StringTranslate.nomcolumn}`,
                field: "nom",
                minWidth: 180,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.nomcolumn}</Typography>)
                },
                renderCell: params => {
                    return (
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                            {(params) ? (<ThumbnailParcelShapeFromPathInfos id={`listItemTbl_${params.row.thumbnailInfos.parcelId}`} {...params.row.thumbnailInfos} />) : (undefined)}
                            <Typography>{params.value}</Typography>
                        </Box>
                    )
                }
            },
            {
                field: "hasFertilizer",
                minWidth: 80,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography sx={{ textWrap: 'wrap', }} fontWeight='bold' >{StringTranslate.parcelHasFertilizer}</Typography>)
                },
                renderCell: params => (
                    <div style={{ cursor: "default", '&:hover': { cursor: "pointer" }, width: '100%', alignSelf: 'center', alignContent: 'center' }}>
                        {(params.value === true) ?
                            <DoneIcon style={{ color: theme.palette.success.main }} />
                            : null}
                    </div>
				),
            },
            {
                field: "hasLastInputN",
                minWidth: 80,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography sx={{ textWrap: 'wrap', }} fontWeight='bold' >{StringTranslate.parcelHasLastInputN}</Typography>)
                },
                renderCell: params => (
                    <div style={{ cursor: "default", '&:hover': { cursor: "pointer" }, width: '100%', alignSelf: 'center', alignContent: 'center' }}>
                        {(params.value === true) ?
                            <DoneIcon style={{ color: theme.palette.success.main }} />
                            : null}
                    </div>
				),
            },
            );

            const firstParcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelIdFilteredList[0]);
            if (firstParcel && firstParcel.details) {
                const dico = dicoFunction();
                for (let detail in firstParcel.details) {
                    dico.forEach(column => {
                        if (detail === column.name) {
                            if (column.name === "variete") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    minWidth: 150,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "exploitation") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    minWidth: 150,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "dateSemi") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    width: 110,
                                    type: "date",
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "campagne") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    width: 100,
                                    type: "date",
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "culture" || column.name === "culturePrecedente") {
                                newColumns.push({
                                    headerName: (column.name === "culture" ?
                                        `${StringTranslate.libelecolumnculture}` :
                                        `${StringTranslate.libelecolumncultureprece}`),
                                    field: column.name,
                                    minWidth: 150,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "surface") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    width: 100,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                        }
                    });
                }
            }
        }
        return newColumns;
    }

    /* fonction permettant d'extraire les données qui seront utilisées dans le visuel */
    initialiseDatas(parcelIdFilteredList) {
        const { parcelDico, thumbnailParcelDico/* , nameExploitation */, fertilizerDico, } = this.props;
        let parcels = ParcelsHelper.getParcelsDicoFromParcelIdList(parcelDico, parcelIdFilteredList);
        let newDatas = [];

        for (var itemPropName in parcels) {
            let temp = [];
            const parcel = parcels[itemPropName];

            if (parcel) {
                const thumbnailParcelItem = ParcelsHelper.selectParcelFromDicoById(thumbnailParcelDico, parcel.id);

                let hasFertilizer = false;
                let hasLastInputN = false;

                const fertilizerData = lodashFind(fertilizerDico, (f) => f.idParcel === parcel.id);
                if (!lodashIsNil(fertilizerData)) {
                    hasFertilizer = fertilizerData.isComplet;
                    hasLastInputN = fertilizerData.hasLastNitrogenInput;
                }

                temp.push(parcel.id);
                temp.push(parcel.name);
                if (parcel.details) {
                    for (let itemDetailName in parcel.details) {
                        const propValue = parcel.details[itemDetailName];
                        let value = (propValue) ? propValue : '';
                        if (itemDetailName.toLowerCase() === 'datesemi') {
                            if (propValue && (propValue instanceof (Date))) {
                                value = DateHelper.formati18n(propValue, 'P');
                            }
                            else if (propValue && (DateHelper.getDateFromString(propValue) instanceof Date)) {
                                value = DateHelper.formati18n(new Date(propValue), 'P');
                            }
                        }
                        else if (itemDetailName.toLowerCase() === 'surface') {
                            if(parcel.area && (parcel.area > 0)) {
                                value = parcel.area;
                            }
                        }
                        temp.push(value);
                    }
                }
                newDatas.push({
                    id: parcel.id,
                    actions: parcel,
                    parcel: parcel,
                    nom: parcel.name,
                    campagne: temp[2],
                    culture: parcel.details.length < 4 ? null : temp[3],
                    culturePrecedente: parcel.details.length < 5 ? null : temp[4],
                    dateSemi: parcel.details.length < 6 ? null : temp[5],
                    exploitation: parcel.details.length < 7 ? null : temp[6],
                    /* exploitation: (parcel.details.length < 7) ? 
                        nameExploitation : 
                        ((temp[6] === null) || (temp[6] === undefined) || (temp[6] === '')) ? 
                            nameExploitation : 
                            temp[6], */ //On affiche le nom de l'exploitation du parcellaire si la parcelle n'a pas de nom d'exploitation
                    surface: parcel.details.length < 8 ? null : temp[7],
                    variete: parcel.details.length < 9 ? null : temp[8],
                    thumbnailInfos: thumbnailParcelItem,

                    hasFertilizer:  hasFertilizer,
                    hasLastInputN: hasLastInputN,
                });

            }
        }
        return newDatas;
    }

    /* Fonction correspondant à la zone de recherche uniqueent sur la culture */
    requestSearch(searchValue, rowsOfTable) {
        this.setState({
            searchFilter: searchValue
        });

        try {
            const searchRegex = new RegExp(stringHelper.escapeRegExp(searchValue), 'i');
            const filteredRows = rowsOfTable.filter((row) => {
                return Object.keys(row).some((field) => {
                    let textValue = "" + row[field];
                    return searchRegex.test(textValue.toString());
                });
            });

            this.setState({
                currentRowsOfTable: filteredRows
            });
        }
        catch (errRegex) { /* Peut arriver si on saisis un truc du genre 'EARL++' ! (il n'aime pas les '++') */ }
    }

    //Sélection des parcelles pour commencer la pesée de colza
    setSelectionParcels = (newSelectionParcels) => {
        this.setState({
            parcelsSelected: newSelectionParcels
        });
    }

    //Toolbar customisé du tableau comprenant :
    // - la culture à sélectionner
    // - la zone de recherche
    // - le bouton pour commencer la fumure
    CustomToolbar = (props) => {
        const { cultureForFertilizing } = this.state;
        const { showProfilMenuDialog } = this.props;
        return (
            <Grid container spacing={2} sx={{ mt: 2 }}>
                {/* Affichage de l'information disant qu'il faut slectionner une culture */}
                {((cultureForFertilizing === undefined) || (cultureForFertilizing === null) || (cultureForFertilizing === 0) || (cultureForFertilizing === '')) &&
                    (<Grid item xs={12}>
                        <Card>
                            <Alert
                                severity="info"
                                icon={<InfoOutlined />}
                            >
                                <Typography>{StringTranslate.selectCultureBeforeStarting}</Typography>
                            </Alert>
                        </Card>
                    </Grid>)}
                { }
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={10} sm={8} md={4} lg={4}>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <FormControl sx={{ minWidth: 150 }} size="small" >
                                        <InputLabel id="select-culture">
                                            <Grid container>
                                                <Grid item sx={{ mr: 1 }}>
                                                    {((props.askingCommonInfosAndListsByCulture === true) && (props.culturesForFertilizing.length <= 0)) && (
                                                        <CircularProgress color="inherit" size={20} />
                                                    )}
                                                </Grid>
                                                <Grid item>
                                                    {StringTranslate.libelecolumnculture}
                                                </Grid>
                                            </Grid>
                                        </InputLabel>
                                        <Select
                                            labelId="select-culture"
                                            id="select"
                                            value={props.cultureForFertilizing}
                                            label="Culture *"
                                            onChange={props.handleChangeCultureForFertilizing}
                                            disabled={(props.culturesForFertilizing.length <= 0)}
                                        >
                                            {props.culturesForFertilizing.map((culture) =>
                                                <MenuItem key={culture.id} value={culture}>{culture.name}</MenuItem>
                                            )}

                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Bouton d'aide */}
                <Grid item xs={12}>
                    <Button color="secondary" variant="text" size="small" onClick={() => showProfilMenuDialog(ProfilIndex.aide_Fumure)}>
                        {StringTranslate.helpAsk}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                {/* Zone de recherche / zone du bouton de validation*/}
                {cultureForFertilizing !== undefined && cultureForFertilizing !== null && cultureForFertilizing !== 0 && cultureForFertilizing !== '' &&
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Grid container spacing={1}>
                            {/* Zone de recherche */}
                            <Grid item xs={8} sm={8} md={4} lg={4}>
                                <TextField
                                    value={props.value}
                                    onChange={props.onChange}
                                    placeholder={StringTranslate.toolbarsearch}
                                    size="small"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon fontSize="small" />
                                            </InputAdornment>),
                                        endAdornment: (
                                            <IconButton
                                                title="Clear"
                                                aria-label="Clear"
                                                size="small"
                                                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                                                onClick={props.clearSearch}
                                            >
                                                <ClearIcon fontSize="small" />
                                            </IconButton>
                                        ),
                                    }}
                                />
                            </Grid>

                            {/* Zone de validation des parcelles sélectionnées */}
                            <Grid item xs={4} sm={4} md={8}>
                                <Box style={{ textAlign: 'end' }} >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={(props.askingCommonInfosAndListsByCulture === true) ? <CircularProgress color="inherit" size={20} /> : null}
                                        onClick={() => props.startManagement(props.parcelsSelected)}
                                        disabled={(props.parcelsSelected.length > 0) && (props.askingCommonInfosAndListsByCulture === false) ? false : true}>
                                        {StringTranslate.validSlots}
                                    </Button>
                                </Box>

                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        );
    }

    /* Redirection vers l'étape de la saisie groupée après avoir sélectionné le(s) parcelle(s) */
    onStartManagement(parcelsSelected, datas) {
        const { selectParcelIdsForFertilizing, clearFertilizerValue, getCommonInfosAndListsByCulture, fertilizerDico} = this.props;
        const { cultureForFertilizing } = this.state;

        let newParcelIdsSelected = [];

        parcelsSelected.forEach(element => {
            newParcelIdsSelected.push(element);
        });

        if (cultureForFertilizing !== undefined) {
            //resetCommonInfosByCulture:
            //On ne réinitialise pas les parcelles sélectionnées et la culture choisie auparavant !
            clearFertilizerValue();

            //Sélection des parcelles id
            selectParcelIdsForFertilizing(newParcelIdsSelected, cultureForFertilizing);

            //Vérification que les parcelles selectionnées n'ont pas de dernier apports associés
            //Si ce n'est pas le cas alors obtention des infos communes du client en fonction de la culture
            //Puis redirection vers la page des infos communes des parcelles pour la fumure            
            const fertilizerHasLastNitrogenInput = FertilizerHelper.hasLastNitrogenInput(newParcelIdsSelected, datas, fertilizerDico);

            if (!fertilizerHasLastNitrogenInput.matchFound) {

                getCommonInfosAndListsByCulture(cultureForFertilizing); //dans cette fonction, on est redirigé vers la prochaine étape

            } else {
                //Dans le cas contraire affiche la pop-up d'avertissement client pour suppression
                this.setState({
                    openDeleteLastInputNDialog: true, // Affichage de la popup
                    parcelNameWithLastNInput: fertilizerHasLastNitrogenInput.parcelName // Nom de la parcelle avec dernier apport
                });
            }
        }
        else {

            //il ne se passe rien, on reste sur la page en cours.
        }
    }

    /* Fonction pour changer de culture pour la fumure. 
    le tableau est trié avec les parcelles dont la culture correspond à la culture choisie en premier */
    handleChangeCultureForFertilizing = (culture) => {
        const { parcelIdFilteredList } = this.props;
        const { datas } = this.state;

        if (parcelIdFilteredList !== undefined && parcelIdFilteredList !== null) {
            let parcelsList = [...datas];

            let parcelsListCultureFound = [];
            let parcelsListCultureNotFound = [];

            let parcelIdsSelected = [];

            parcelsList.forEach(parcel => {
                if (parcel.culture !== undefined || parcel.culture !== null) {
                    if (FertilizerHelper.hasFertilizerCropSelected(parcel.culture, culture.cropType) === true) {
                        parcelsListCultureFound.push(parcel);
                    }
                    else {
                        parcelsListCultureNotFound.push(parcel);
                    }
                }
                else {
                    parcelsListCultureNotFound.push(parcel);
                }
            });

            parcelsList = [...parcelsListCultureFound, ...parcelsListCultureNotFound];

            parcelsListCultureFound.forEach(parcel => {
                parcelIdsSelected.push(parcel.id);
            });

            this.setState({
                datas: parcelsList,
                cultureForFertilizing: culture,
                parcelsSelected: parcelIdsSelected,
            });
        }

    };

    /* Fonction pour trier les données du tableau en fonction de la culture choisie. */
    sortDatasByCrop = (datas, culture) => {
        const { parcelIdFilteredList } = this.props;
        const { parcelsSelected } = this.state;

        if (parcelIdFilteredList !== undefined && parcelIdFilteredList !== null) {
            let parcelsList = [...datas];

            let parcelsListCultureFound = [];
            let parcelsListCultureNotFound = [];

            let parcelIdsSelected = [];

            parcelsList.forEach(parcel => {
                if (parcel.culture !== null || parcel.culture !== undefined) {
                    if (FertilizerHelper.hasFertilizerCropSelected(parcel.culture, culture.cropType) === true) {
                        parcelsListCultureFound.push(parcel);
                    }
                    else {
                        parcelsListCultureNotFound.push(parcel);
                    }
                }
                else {
                    parcelsListCultureNotFound.push(parcel);
                }
            });

            parcelsList = [...parcelsListCultureFound, ...parcelsListCultureNotFound];

            parcelsListCultureFound.forEach(parcel => {
                parcelIdsSelected.push(parcel.id);
            });

            this.setState({
                datas: parcelsList,
                parcelsSelected: (parcelIdsSelected.length <= 0) ? parcelsSelected : parcelIdsSelected,
            });
        }

    };

    /* Mise à jour des infos de la parcelle */
    handleChangeParcel = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleChangeParcelCampagne = (newCampagne) => {
        this.setState({
            campagne: new Date(newCampagne).getFullYear()
        });
    }

    handleChangeParceldateSemi = (newDateSemi) => {
        this.setState({
            dateSemi: newDateSemi
        });
    }

    /*Fermeture de la pop-up de suppression des derniers apports */
    handleCloseLastNitrogenInput = () => {
        this.setState({
            openDeleteLastInputNDialog: false
        })
    }

    handleDeleteLastInputNForFertilizing = (parcelIdsSelected, cultureSelected) => {
        const { deleteLastNitrogenInput, getCommonInfosAndListsByCulture } = this.props;

        deleteLastNitrogenInput(parcelIdsSelected);
        getCommonInfosAndListsByCulture(cultureSelected);
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction de rendu visuel - layout général
    ///////////////////////////////////////////////////////////////////////////
    render() {

        const {
            culturesForFertilizing, askingCommonInfosAndListsByCulture, errorFertilizer, showProfilMenuDialog,
            /*updatingParcelInfos,*/ updateNbRowsPerPageTableParcels, parcelIdsSelected, cultureSelected,
        } = this.props; // fonctions mapDispatchToProps

        const {
            datas, searchFilter, currentRowsOfTable, pageSize, parcelsSelected, openDeleteLastInputNDialog,
            /*openUpdateParcel, localeMap,*/ cultureForFertilizing, parcelNameWithLastNInput,
            /*name, thumbnailInfos, campagne, culture, dateSemi, exploitation, variete, culturePrecedente,*/
            /* name, thumbnailInfos, campagne, culture, dateSemi, exploitation, variete, culturePrecedente : infos de la parcelle */
            /*cultures, farms, varieties  cultures, farms, varieties : listes des cultures, variétés, exploitations */ 
        } = this.state; // etat du dialog popup

        return (
            <>
                {/* Dialog de modification des infos de la parcelle
                {openUpdateParcel ?
                    <Dialog
                        sx={{ '& .MuiDialog-paper': { maxHeight: '80%' } }}
                        maxWidth="xs"
                        open={openUpdateParcel}
                        onClose={this.handleCloseUpdateParcel}>
                        <DialogTitle>
                            <Typography>
                                {(thumbnailInfos) ? (<ThumbnailParcelShapeFromPathInfos id="parcelShape" {...thumbnailInfos} />) : (undefined)}
                                {StringTranslate.updateCrop} {name}
                            </Typography>
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled={updatingParcelInfos}
                                            required
                                            error={(name === "") ? true : false}
                                            label={StringTranslate.ColumnPclName}
                                            id="standard-size-small"
                                            name="name"
                                            value={name}
                                            onChange={this.handleChangeParcel}
                                            helperText={(name === "") ? StringTranslate.champobligatoire : null}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl>
                                        <LocalizationProvider adapterLocale={localeMap} dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                name="campagne"
                                                disabled={updatingParcelInfos}
                                                dateFormat="yyyy"
                                                views={['year']}
                                                orientation="portrait"
                                                label={StringTranslate.libelecolumncampagne}
                                                value={new Date(new Date().setFullYear(campagne))}
                                                onChange={(newValue) => this.handleChangeParcelCampagne(newValue)}
                                                slotProps={{
                                                    textField: {
                                                        helperText: null,
                                                        placeholder: StringTranslate.formatDatePlaceHolder
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Autocomplete key="culture"
                                            disabled={updatingParcelInfos}
                                            name={StringTranslate.libelecolumnculture}
                                            value={culture}
                                            onChange={
                                                (event, newValue) => {
                                                    if (newValue && newValue.inputValue) {
                                                        this.setState({ culture: newValue.inputValue });
                                                    } else if (newValue && newValue.title) {
                                                        this.setState({ culture: newValue.title });
                                                    } else if (newValue === null) {
                                                        this.setState({ culture: '' });
                                                    }
                                                }
                                            }
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);

                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => inputValue === option.title);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        inputValue,
                                                        title: `${StringTranslate.ajouter3} "${inputValue}"`,
                                                    });
                                                }

                                                return filtered;
                                            }}
                                            options={cultures}
                                            getOptionLabel={(option) => {
                                                // Ajouter "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                // Value selected with enter, right from the input
                                                if (typeof option.title === 'string') {
                                                    return option.title;
                                                }
                                                // Regular option
                                                return option;
                                            }}
                                            renderOption={(props, option) =>
                                                <li {...props} key={option.title}>
                                                    {(option.title.includes(StringTranslate.ajouter3)) ?
                                                        <Button color="primary" variant="contained">{option.title}</Button> :
                                                        option.title}
                                                </li>}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField {...params} label={StringTranslate.libelecolumnculture} />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Autocomplete key="culturepre"
                                            disabled={updatingParcelInfos}
                                            value={culturePrecedente}
                                            name={StringTranslate.libelecolumncultureprece}
                                            onChange={
                                                (event, newValue) => {
                                                    if (newValue && newValue.inputValue) {
                                                        this.setState({ culturePrecedente: newValue.inputValue });
                                                    } else if (newValue && newValue.title) {
                                                        this.setState({ culturePrecedente: newValue.title });
                                                    } else if (newValue === null) {
                                                        this.setState({ culturePrecedente: '' });
                                                    }
                                                }
                                            }
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);
                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => inputValue === option.title);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        inputValue,
                                                        title: `${StringTranslate.ajouter3} "${inputValue}"`,
                                                    });
                                                }
                                                return filtered;
                                            }}
                                            options={cultures}
                                            getOptionLabel={(option) => {
                                                // Ajouter "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                // Value selected with enter, right from the input
                                                if (typeof option.title === 'string') {
                                                    return option.title;
                                                }
                                                // Regular option
                                                return option;
                                            }}
                                            renderOption={(props, option) =>
                                                <li {...props}>
                                                    {(option.title.includes(StringTranslate.ajouter3)) ?
                                                        <Button color="primary" variant="contained">{option.title}</Button> :
                                                        option.title}
                                                </li>}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField {...params} label={StringTranslate.libelecolumncultureprece} />
                                            )}
                                        />

                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl>
                                        <LocalizationProvider adapterLocale={localeMap} dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                name="dateSemi"
                                                orientation="portrait"
                                                dateFormat="dd-MM-yyyy"
                                                disabled={updatingParcelInfos}
                                                label={StringTranslate.libelecolumnsemis}
                                                value={(dateSemi !== null) ? new Date(dateSemi) : null}
                                                onChange={(newValue) => this.handleChangeParceldateSemi(newValue)}
                                                slotProps={{
                                                    textField: {
                                                        helperText: null,
                                                        placeholder: StringTranslate.formatDatePlaceHolder
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disabled={updatingParcelInfos}
                                            name="exploitation"
                                            value={exploitation}
                                            onChange={
                                                (event, newValue) => {
                                                    if (!newValue) {
                                                        this.setState({ exploitation: '' });
                                                    }
                                                    else if (newValue && newValue.inputValue) {
                                                        this.setState({ exploitation: newValue.inputValue });
                                                    } else if (newValue && newValue.title) {
                                                        this.setState({ exploitation: newValue.title });
                                                    }
                                                }
                                            }
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);

                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => inputValue === option.title);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        inputValue,
                                                        title: `${StringTranslate.ajouter3} "${inputValue}"`,
                                                    });
                                                }

                                                return filtered;
                                            }}
                                            options={farms}
                                            getOptionLabel={(option) => {
                                                // Ajouter "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                // Value selected with enter, right from the input
                                                if (typeof option.title === 'string') {
                                                    return option.title;
                                                }
                                                // Regular option
                                                return option;
                                            }}
                                            renderOption={(props, option) =>
                                                <li {...props}>
                                                    {(option.title.includes(StringTranslate.ajouter3)) ?
                                                        <Button color="primary" variant="contained">{option.title}</Button> :
                                                        option.title}
                                                </li>}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField {...params} label={StringTranslate.libelecolumnexploitation} />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disabled={updatingParcelInfos}
                                            name="variete"
                                            value={variete}
                                            onChange={
                                                (event, newValue) => {
                                                    if (newValue && newValue.inputValue) {
                                                        this.setState({ variete: newValue.inputValue });
                                                    } else if (newValue && newValue.title) {
                                                        this.setState({ variete: newValue.title });
                                                    } else if (newValue === null) {
                                                        this.setState({ variete: '' });
                                                    }
                                                }
                                            }
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);

                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => inputValue === option.title);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        inputValue,
                                                        title: `${StringTranslate.ajouter3} "${inputValue}"`,
                                                    });
                                                }

                                                return filtered;
                                            }}
                                            options={varieties}
                                            getOptionLabel={(option) => {
                                                // Ajouter "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                // Value selected with enter, right from the input
                                                if (typeof option.title === 'string') {
                                                    return option.title;
                                                }
                                                // Regular option
                                                return option;
                                            }}
                                            renderOption={(props, option) =>
                                                <li {...props}>
                                                    {(option.title.includes(StringTranslate.ajouter3)) ?
                                                        <Button variant="contained">{option.title}</Button> :
                                                        option.title}
                                                </li>}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField {...params} label={StringTranslate.libelecolumnvariete} />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>

                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="text" color="error" onClick={this.handleCloseUpdateParcel}>{StringTranslate.cancelDeleteAction}</Button>
                            <Button
                                color="primary"
                                disabled={updatingParcelInfos}
                                autoFocus
                                startIcon={updatingParcelInfos ? <CircularProgress color="inherit" size={25} /> : null}
                                variant="contained"
                                onClick={this.handleUpdateParcel}>
                                {updatingParcelInfos ?
                                    StringTranslate.downloadModulationInProgress :
                                    StringTranslate.enregistrer}
                            </Button>
                        </DialogActions>
                    </Dialog> : null} */}

                    {/* partie - Pop-up pour confirmation suppression dernier apports */}
                        <Dialog
                        open={openDeleteLastInputNDialog}
                        onClose={this.handleCloseLastNitrogenInput}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {StringTranslate.fertilizerHavingLastNitrogenInputPopupTitle}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-parcels-name">
                                    <List dense> {/*Liste affichant la (les) parcelle(s) concernée(s) par une cohésion*/}
                                        {parcelNameWithLastNInput.map((parcelName, index) => (
                                            <ListItem key={index}>
                                                <ListItemText primary={parcelName} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description">
                                    {StringTranslate.fertilizerHavingLastNitrogenInputPopupContent}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseLastNitrogenInput} variant="text" color="error" autoFocus>
                                    {StringTranslate.cancelDeleteAction}
                                </Button>
                                <Button onClick={() => this.handleDeleteLastInputNForFertilizing(parcelIdsSelected, cultureSelected)} variant="contained" color="primary">
                                    {StringTranslate.confirmDeleteAction}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        

                    {/* ↓↓ partie - zone de visualisation des parcelles ↓↓ */}
                        {((errorFertilizer === undefined) || (errorFertilizer === null) || (errorFertilizer === '')) ?
                        <TableContainer>
                            <Grid style={{ width: '100%' }} item xs={12}>
                                {/* partie tableau */}
                                <CustomDataGrid
                                    disableSelectionOnClick={true}
                                    tableName={TableType.parcels}
                                    pageSize={pageSize}
                                    selectionModel={parcelsSelected}
                                    updateNbRowsPerPageTable={updateNbRowsPerPageTableParcels}      // ==> pas utilsé dans customDataGrid
                                    onSelectionModelChange={(newSelectionParcels) => { //Sélection des parcelles par clic checkbox
                                        this.setSelectionParcels(newSelectionParcels);
                                    }}
                                    keepNonExistentRowsSelected={searchFilter !== "" ? true : false}
                                    Toolbar={this.CustomToolbar}
                                    toolbar={{
                                        startManagement: () => this.onStartManagement(parcelsSelected, datas),
                                        parcelsSelected: parcelsSelected,
                                        parcelDatas: datas,
                                        //Concerne la zone de recherche dans le tableau :
                                        value: searchFilter,
                                        onChange: (event) => this.requestSearch(event.target.value, datas),
                                        clearSearch: () => this.requestSearch('', datas),

                                        cultureForFertilizing: cultureForFertilizing,
                                        culturesForFertilizing: culturesForFertilizing,
                                        handleChangeCultureForFertilizing: (event) => this.handleChangeCultureForFertilizing(event.target.value),

                                        askingCommonInfosAndListsByCulture: askingCommonInfosAndListsByCulture,

                                        showProfilMenuDialog: () => showProfilMenuDialog(ProfilIndex.aide_Fumure),
                                    }}
                                    rows={(searchFilter !== "") ? currentRowsOfTable : (cultureForFertilizing !== undefined && cultureForFertilizing !== null && cultureForFertilizing !== 0 && cultureForFertilizing !== '') ? datas : []}
                                    columns={this.columns}
                                    checkBoxActive={true}
                                />
                            </Grid>
                        </TableContainer> :
                        <Alert
                            severity="error"
                            icon={<InfoOutlined />}
                            sx={{ color: theme.palette.error.main }}
                        >
                            <AlertTitle>{StringTranslate.errorsuite}</AlertTitle>
                            {errorFertilizer}
                            {StringTranslate.errorDialogText2} <LinkToContactUs displayText={StringTranslate.contactUs} />.
                        </Alert>}
              

            </>
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({

    clientId: lodashGet(state, 'clientUserData.clientDatas.id', 0),
    
    culturesForFertilizing: lodashGet(state, 'fertilizerData.culturesForFertilizing', []),
    askingCommonInfosAndListsByCulture: lodashGet(state, 'fertilizerData.askingCommonInfosAndListsByCulture', false),
    cultureSelected: lodashGet(state, 'fertilizerData.cultureSelected', undefined),
    parcelIdsSelected: lodashGet(state, 'fertilizerData.parcelIdsSelected', []),
    errorFertilizer: lodashGet(state, 'fertilizerData.errorMessage', undefined),

    //Dictionnaire des fumures existantes
    fertilizerDico : lodashGet(state, 'fertilizerData.fertilizerDico', {}),
    fertilizerDicoIsLoaded: lodashGet(state, 'fertilizerData.fertilizerDicoIsLoaded', false),
    parcelsArchivedForFertilizerLoaded: lodashGet(state, 'parcelsData.parcelsArchivedForFertilizerLoaded', false),
    
    parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
    parcelIdFilteredList: lodashGet(state, 'parcelsData.parcelIdFilteredList', []),
    updatingParcelName: lodashGet(state, 'parcelsData.updatingParcelName', false),
    updatingParcelInfos: lodashGet(state, 'parcelsData.updatingParcel', false),
    thumbnailParcelDico: lodashGet(state, 'parcelsData.thumbnailParcelDico', {}),
    
    rowsPerPageForTableParcels: lodashGet(state, 'settingsData.settings.rowsPerPageForTableParcels', 20),
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    updateParcel: (parcelId, parcel) => dispatch(ActionSaveParcel(parcelId, parcel)),
    
    getAllFertilizers: (clientId) => dispatch(ActionGetAllFertilizers(clientId)),
    
    selectParcelIdsForFertilizing: (parcelIdsSelected, cultureSelected) => dispatch(ActionSelectParcelsForFertilizing(parcelIdsSelected, cultureSelected)),
    getCommonInfosAndListsByCulture: (culture) => dispatch(ActionGetCommonInfosAndListsByCulture(culture)),

    //Méthodes pour récuperer le dictionnaire des derniers apports et supprimer les derniers apports associés aux parcelles selectionnées
    deleteLastNitrogenInput: (parcelIdsSelected) => {dispatch(ActionDeleteLastNitrogenInput(parcelIdsSelected))},

    showProfilMenuDialog: (index) => dispatch(ActionShowProfilMenuDialog(index)),
    clearFertilizerValue: () => dispatch(ActionClearFertilizerValue())
})

export default connect(mapStateToProps, mapDispatchToProps)(CultureAndParcelsChoice);