import React from "react";
import { Box, DialogContentText } from "@mui/material";
import stepbienvenuesvg from '../../../assets/images/didacticiel/step_bienvenue.svg';
import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';
import "../../../index.css";
import "../../../assets/css/dfp-didacticielFirstParcel.css";

/**
 * Composant représentant l'étape de Bienvenue - didacticiel première parcelle
 */
function StepBienvenue(props) {

    return (
        /* ↓ ZONE - LOGO ↓ */
        <div className="dfp-content-welcome" >
            <Box sx={{ marginBottom: '20px', minWidth: 120 }} >
                <img src={stepbienvenuesvg} alt="welcome" className="dfp-img-welcome" />
            </Box>

            <DialogContentText>{StringTranslate.welcomespotifarmercontent1} <strong>{StringTranslate.welcomespotifarmercontent2}</strong> {StringTranslate.welcomespotifarmercontent3}</DialogContentText>
        </div >

    )
}

export default StepBienvenue;