import React, { Component } from "react";

/* Composants MUI */
import { DialogContent, Dialog } from "@mui/material";

/* Composants React */
import AbstractIdentification from "./AbstractIdentification";

/* css */
import styles from '../PinDropFormDialog/PinDropFormDialog.module.css';
import '../../../assets/css/identificationDialog.css';

/**
 * Composant appliqué par dessus le composant RenderMaps pour gérer l'identification
 */
class IdentificationDialog extends Component {
    /* cycle de vie react */
    render() {
        return (<div className={styles.display}>
            <div id="identificationDialog" className={styles.container}>

                {/* ↓↓ Boite de dialogue en lien avec l'identification ↓↓ */}
                <Dialog
                    aria-labelledby="customized-dialog-title"
                    open={true}
                    container={() => document.getElementById('identificationDialog')} // target le container ayant la position relative
                    style={{ position: 'absolute' }}
                    BackdropProps={{ classes: { root: styles.backdrop } }}
                    fullScreen={true}
                    maxWidth={'xs'} // limitation de la largeur
                >
                    <DialogContent>
                        <AbstractIdentification />
                    </DialogContent>
                </Dialog>
            </div>
        </div>)
    }
}

export default IdentificationDialog;