import React from 'react';
import { connect } from 'react-redux';
import L from 'leaflet';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import sendError from '../../utils/errorService.js';
import { Tooltip, Fab, Typography, Box } from '@mui/material';
 
import { ActionSetGeolocalisationCircleLayers } from '../../redux/actions/contextApp.js';
import getTheme from '../../themes/index.js';

let theme = getTheme();

/**
 * classe en lien avec le bouton + fonctionnel de la géolocalisation
 */
class GeolocationButtonControl extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            isGeolocationOn: false, // la géolocalisation est elle activée ?
        }

        // ↓↓ BIND ↓↓
        this.handleClickGeolocationButton = this.handleClickGeolocationButton.bind(this);
        this.startGeolocation = this.startGeolocation.bind(this);
        this.stopGeolocation = this.stopGeolocation.bind(this);
        this.onLocationFound = this.onLocationFound.bind(this);
        this.onLocationError = this.onLocationError.bind(this);

        //↓↓ DONNEES - géolocalisation ↓↓
        this.geolocationCircleLayers = null; //object leaflet qui sert de marker lors de la géolocalisation
        this.geolocationLatLng = null; // position exacte GPS
    }

    /* cycle de vie react.js */
    componentWillUnmount() {
        this.stopGeolocation(); // ⚠️ - car géolocalisation non fonctionnelle si changement de page lorsque elle est en marche
    }

    /**
     * fonction callback lors du clique du bouton de géolocalisation 
     */
    handleClickGeolocationButton(event) {
        if (event) 
            event.stopPropagation(); // permet d'éviter que l'événement clique ne soit remonté à la carte (évite de zoomer)

        if (!this.state.isGeolocationOn) 
            this.startGeolocation();
        else 
            this.stopGeolocation();
    }

    /**
     * fonction permettant d'activer la géolocalisation
     */
    startGeolocation() {
        if (window.dataLayer) // google tag manager
            window.dataLayer.push({'event': 'suiviBouton', 'action': 'géolocalisation'});

        // ↓ on cable les différents listeners ↓
        this.props.renderMapsCmp.map.on('locationfound', this.onLocationFound);
        this.props.renderMapsCmp.map.on('locationfound', this.onLocationFound);

        this.props.renderMapsCmp.map.locate({
            watch: true, //watch => starts continous watching of location changes
            timeout: 60000,
            enableHighAccuracy: true
        }); 

        this.setState({isGeolocationOn:true});
    }

    /**
     * fonction permettant de stopper la géolocalisation
     */
    stopGeolocation() {
        this.props.renderMapsCmp.map.stopLocate(); // fin de la géolocalisation

        // ↓ on décable les différents listeners ↓
        this.props.renderMapsCmp.map.off('locationfound', this.onLocationFound);
        this.props.renderMapsCmp.map.off('locationfound', this.onLocationFound);

        // ↓ retrait des layers ↓
        if (this.geolocationCircleLayers && this.props.renderMapsCmp.map.hasLayer(this.geolocationCircleLayers)) {
            this.props.renderMapsCmp.map.removeLayer(this.geolocationCircleLayers); // retrait du groupe de layer représentant le point sur la carte
        }
        this.geolocationCircleLayers = null;
        this.geolocationLatLng = null;
        this.props.setGeolocalisationCircleLayers(undefined);
        this.setState({isGeolocationOn: false});
    }

    /**
     * fonction executée lorsqu'un point GPS a été trouvé
     */
    onLocationFound(e) {
        if(!e.latlng) return null;

        this.geolocationLatLng = e.latlng; // affectation du premier point

        if (!this.geolocationCircleLayers) {
            // ↓↓ création ↓↓
            this.geolocationCircleLayers = L.layerGroup([
                L.circle(this.geolocationLatLng,{radius: 20,fillOpacity:0.5,color: theme.palette.primary.main ,weight:1}),
                L.circle(this.geolocationLatLng,{radius: 3,fillOpacity:1,color: theme.palette.primary.main })
            ]).addTo(this.props.renderMapsCmp.map); // on ajoute le layer
            
            //↓↓ on zoome sur le point ↓↓
            this.props.renderMapsCmp.map.setView(this.geolocationLatLng, 18);
        }
        else {
            this.geolocationCircleLayers.eachLayer( (layer) => { layer.setLatLng(this.geolocationLatLng) });
        }
        // stockage des layers pour utilisation de la position de l'utilisateur
        this.props.setGeolocalisationCircleLayers(this.geolocationCircleLayers);
    }

    /**
     * fonction executée lors d'une erreur liée à la géolocalisation
     */
    onLocationError(error) {
        this.stopGeolocation(); // on stoppe la géolocalisation
        sendError('renderMaps-onLocationError', { "err":error }); // on envoie un message au serveur Azure
    }

    /* fonction cycle de vie */
    render() {
        const { isGeolocationOn } = this.state;

        return (
            <Box 
                className='leaflet-control'
                sx={{
                    zIndex:"1100"
                }}
            >
        
                {/* ↓ bouton - fonctionnalité géolocalisation ↓ */}
                <div>
                    <Tooltip title={<Typography color="inherit">{(isGeolocationOn) ? StringTranslate.tooltipstopgeolocation : StringTranslate.tooltipstartgeolocation}</Typography>} placement="left">
                        <Fab 
                            size="small" 
                            onClick={this.handleClickGeolocationButton}
                            sx={{
                                backgroundColor: theme.palette.common.white,
                                ...( isGeolocationOn && {
                                    backgroundColor: theme.palette.primary.main,
                                    '&:hover': {
                                        backgroundColor:theme.palette.primary.dark
                                    }
                                }),
                            }}
                        >
                            <GpsFixedIcon 
                                sx={{
                                    ...( isGeolocationOn && {
                                        color: theme.palette.primary.light,
                                    }),
                                    ...( (!isGeolocationOn) && {
                                        color:theme.palette.primary.main
                                    })
                                }}
                            />
                        </Fab>
                    </Tooltip>
                </div>

            </Box>
        )
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant */
const mapStateToProps = function(state) {
    return {
        settings: state.settingsData.settings, // seul moyen trouvé pour rafraichir le visuel (traduction) du fait que maintenant on rentre dans l'application sans attendre les informations du client
    };
}

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
    setGeolocalisationCircleLayers: (geolocationCircleLayers) => dispatch(ActionSetGeolocalisationCircleLayers(geolocationCircleLayers)),
})

export default connect( mapStateToProps, mapDispatchToProps )(GeolocationButtonControl);