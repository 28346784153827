import React from 'react';

import { Dialog, DialogContent, Fade } from '@mui/material';

import PlaceSearchButton from './ButtonPlaceSearch.jsx';

/* transition */
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade direction="up" ref={ref} {...props}  timeout={{ enter:1000, exit:2000 }} />;
});

/**
 * Composant 
 */
function SubStepPlaceSearch(props) {

    return (
        <Dialog
            open={true}
            fullWidth={true}
            maxWidth={'sm'}
            TransitionComponent={Transition}
            PaperProps={{
                style: {
                  backgroundColor: 'transparent',
                  boxShadow: 'none'
                },
              }}>
            <DialogContent             
                sx={{
                    padding: '0px !important',
                    height: '360px',
                }}>
                <PlaceSearchButton {...props} showTextForCancel={true} autoFocus={true} showIconButtonCancel={true}/>
            </DialogContent>
        </Dialog>
    )
}

export default SubStepPlaceSearch;