import React from 'react';
import { connect } from 'react-redux';
import { get as lodashGet, isNil as lodashIsNil } from 'lodash';

import {
    Button, Typography, CircularProgress, Grid, Stack,
    Box, Card, CardContent, CardActions, CardHeader, Divider, Tooltip, Fab,
    IconButton, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText,
    Chip
} from '@mui/material';

import AlertDialog from '../../alertDialog';

import ConstantsLayers from '../../../utils/constantsOfLayers.js';
import {BiomassesHelper} from '../../../utils/biomassesHelper';
import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';

/* Icones */
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloudIcon from '@mui/icons-material/Cloud';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BalanceIcon from '@mui/icons-material/Balance';

/* css */
import '../../../assets/css/modulation.css';

/* Actions redux */
import { BiomassStep, ActionSelectLayerForBiomass, ActionGoToNextStepOfBiomass, buildBiomassSampleAndQuantities,
    ActionClearBiomassValue, ActionCleanErrorBiomass } from '../../../redux/actions/biomass';
import { ActionGoToBiomass, ActionUnselectParcel } from '../../../redux/actions/contextApp';

/* theme */
import getTheme from "../../../themes/index.js";
import { SatImageSourceProvider } from '../../../utils/constantsProvidersSatellite';
import SlideRangeMarker from '../SlideRangeMarker';
import { Info as InfoIcon } from '@mui/icons-material';
import ModalBiomassRange from './modalBiomassRange';

let theme = getTheme();

const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};

/**
 * Composant représentant la couche de boutons sur la carte
 */
class MapButtonsPaneBiomass extends React.Component {
    constructor(props) {
        super(props);

        const parcel = lodashGet(props, `parcelDico[${props.parcelIdSelected}]`, undefined);
        const dateBeginningWinter = lodashGet(parcel, 'imagSourceDateBeginningWinter', undefined);
        const dateEndingWinter = lodashGet(parcel, 'imagSourceDateEndingWinter', undefined);
        
        // définie si l'on gère la date d'entrée ou sortie hivers
        const isBeginningWinter = 
            // Si on a demander à gérer la sortie d'hivers
            ((props.hasAskedForEndingBiomass === true) ?
                false 
            : 
                // Si on a pas de date d'entrée hivers
                (lodashIsNil(dateBeginningWinter) ?
                    true
                : 
                    // Si on a pas de date de sortie hivers
                    (lodashIsNil(dateEndingWinter) ? 
                        false 
                    : 
                        true
                    )
                )
            );
        

        this.state = {
            isSlectedVisibleLayer: props.forewardLayerSelected === ConstantsLayers.VisibleGlobalLayerName ? true : false,
            openDialogOfErrors: (props.errorBiomass !== '') ? true : false,
            isBeginningWinter: isBeginningWinter,
            openCancelParcel: false,
            parcel: parcel,
            modalOpen: false,
        };

        // Récupère la clef du StringTranslate du message à afficher pour l'aide du choix des dates
        this.dateRangeMsgKey = isBeginningWinter ? 
                BiomassesHelper.getRangeDateMsgKeyByDepartementForBeginningWinter(parcel) 
            : 
                BiomassesHelper.getRangeDateMsgKeyByDepartementForEndingWinter(parcel) 
        ;
        // Permet de savoir si nous disposons des informations de date pour cette parcelle (Ex: si situé à l'étranger, pas de date)
        this.hasNoDateRange = lodashIsNil(this.dateRangeMsgKey);
        
        this.popupErrorDialog = {
            getTitle: () => { return (<ErrorOutlineIcon />); },
            description: props.errorBiomass,
            button: StringTranslate.close,
        };
    }

    onSelectImage() {
        const { clientId, parcelIdSelected, satimageIdSelectedOfParcel, satimageDateSelectedOfParcel, providerSrcImageSelectedOfParcel,
            clearBiomassValue, buildBiomassSampleAndQuantities } = this.props;

        // Paramètres de la pesée de biomasse pour connaitre les quantités
        let biomassParameter = {
            clientId: clientId,
            parcelId: parcelIdSelected,
            imagSourceId: satimageIdSelectedOfParcel,
            dateImagSource: satimageDateSelectedOfParcel,
            imagSourceProvider: providerSrcImageSelectedOfParcel,
            zonesCounter: 5,
            isBeginningWinter: this.state.isBeginningWinter,
        };

        clearBiomassValue(); //si une biomasse a déjà été sélectionnée auparavant, vider les valeurs de la biomasse :

        // Lance la première génération de biomasse pour cette parcelle :
        buildBiomassSampleAndQuantities(clientId, biomassParameter);
        //Rq: si le retour est positif, on ira automatiquement sur l'étape des quantités de biomasse !!!
    }
    
    /* Retour à l'étape de la carte pour la prochaine parcelle sélectionnée */
    onGoToNextPlot = () => {
        const { parcelIdsSelected, goToNextStepOfBiomass, parcelDico, hasAskedForEndingBiomass } = this.props;
        if ((!parcelIdsSelected) || (parcelIdsSelected.length <= 0)) return;

        let newParcelIdsSelected = [];
        for (var i = 1; i < parcelIdsSelected.length; i++) { //On exclut volontairement l'item à l'index zéro (car on vient de le terminer).
            newParcelIdsSelected.push(parcelIdsSelected[i]);
        }

        // mise à jour de la variable "isBeginningWinter" 
        const newId = newParcelIdsSelected[0];
        const thisParcel = (parcelDico && (newId > 0)) ? parcelDico[newId] : undefined;
        const dateBeginningWinter = (thisParcel) ? thisParcel.imagSourceDateBeginningWinter : undefined;
        const dateEndingWinter = (thisParcel) ? thisParcel.imagSourceDateEndingWinter : undefined;

        this.setState({
            isBeginningWinter: (hasAskedForEndingBiomass === true) ? false : (((dateBeginningWinter !== null) && (dateBeginningWinter !== undefined)) ?
                (((dateEndingWinter !== null) && (dateEndingWinter !== undefined)) ? true : false) : true),
            openCancelParcel: false
        });

        goToNextStepOfBiomass(BiomassStep.MAP, newParcelIdsSelected, false);
    }

    componentDidMount() {
        const { selectLayerForBiomass } = this.props;

        this.setState({
            isSlectedVisibleLayer: false
        });

        // On prévient qu'il faut changer de fond de carte: 
        if (selectLayerForBiomass) {
            // Valeurs possibles pour 'forewardLayerSelected' : NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
            selectLayerForBiomass(ConstantsLayers.VisibleBaseLayerName, ConstantsLayers.NdviParcelLayerName);
        }
    }

    componentWillUnmount() {
        const { selectLayerForBiomass } = this.props;

        // On prévient qu'il faut changer de fond de carte: 
        if (selectLayerForBiomass) {
            // Valeurs possibles pour 'forewardLayerSelected' : NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
            selectLayerForBiomass(ConstantsLayers.VisibleBaseLayerName, ConstantsLayers.NdviParcelLayerName);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { errorBiomass } = this.props;

        if (((!prevProps) || (!prevProps.errorBiomass) || (prevProps.errorBiomass === '')) &&
            (errorBiomass && (errorBiomass !== ''))) {
            this.popupErrorDialog.description = errorBiomass;
            this.setState({
                openDialogOfErrors: true,
            });
        }
    }

    /* Ferme le dialog des messages d'erreur */
    closeDialogOfErrors = () => {
        const { cleanErrorBiomass } = this.props;

        this.setState({
            openDialogOfErrors: false,
        });

        if (cleanErrorBiomass) {
            cleanErrorBiomass();
        }
    }

    /* Retour vers "Mes modulations" */
    onGoBackToBiomasses() {
        const { goToBiomass, unselectParcel } = this.props;

        if (goToBiomass) {
            goToBiomass();
            unselectParcel();   // fonction permettant de ne pas avoir de parcelle sélectionné lors du retour sur l'écran de biomasse
        }
    }

    /**
     * fonction callback lors du click du bouton d'un choix de couche générale (par dessus celle de fond) dont son nom est passé en paramètre
     */
    onSelectGeneralLayer() {
        // ↓↓ suivant le nom du layer passé en paramètre ↓↓
        const { isSlectedVisibleLayer } = this.state;
        const { selectLayerForBiomass } = this.props;

        let newLastLayer = undefined;

        if (isSlectedVisibleLayer === false) {
            newLastLayer = ConstantsLayers.VisibleGlobalLayerName;
        }
        else {
            newLastLayer = ConstantsLayers.NdviParcelLayerName;
        }

        //On ne modifie pas le paramétrage client dans ce cas !
        // pour ne pas qu'il se retroune sur le layer vue globale visible sur la page "Carto".

        this.setState({
            isSlectedVisibleLayer: isSlectedVisibleLayer === true ? false : true
        });

        // On prévient qu'il faut changer de fond de carte: 
        if (selectLayerForBiomass) {
            // Valeurs possibles pour 'forewardLayerSelected' : NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
            selectLayerForBiomass(ConstantsLayers.VisibleBaseLayerName, newLastLayer);
        }
    }

    /* Fonction qui permet d'ouvrir ou de fermer la dialog de confirmation d'annulation pour passer
    à la parcelle suivante dans le cas où on a sélectionné plusieurs parcelles */
    handleOpenCancelParcel = (open) => {
        this.setState({
            openCancelParcel: open
        });
    }

    render() {
        const { isSlectedVisibleLayer, openDialogOfErrors, isBeginningWinter, openCancelParcel, parcel } = this.state;
        const { satimageIdSelectedOfParcel, buildWaiting, parcelIdsSelected } = this.props;

        return (
            <>
                {/* Dialog des erreurs */}
                {openDialogOfErrors === true &&
                    <AlertDialog popup={this.popupErrorDialog} handleChangePopup={this.closeDialogOfErrors} />
                }

                { /* ↓↓ partie - Dialog pour confirmer l'annulation de la parcelle en cours et passer à la parcelle suivante - ↓↓ */}
                {openCancelParcel ?
                    <Dialog open={openCancelParcel} onClose={() => this.handleOpenCancelParcel(false)} aria-labelledby="form-dialog-titleCancel-observ">
                        <DialogTitle id="form-dialog-titleCancel-observ">{StringTranslate.goToNextFieldSkipTitle}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>{StringTranslate.goToNextFieldSkipContent}</DialogContentText>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleOpenCancelParcel(false)} variant="text" color="error">
                                {StringTranslate.cancelDeleteAction}
                            </Button>
                            <Button onClick={() => this.onGoToNextPlot(true)} variant="contained" color="primary">
                                {StringTranslate.confirmDeleteAction}
                            </Button>
                        </DialogActions>
                    </Dialog> : null}

                {/* ↓ contrôle (bouton) en lien avec la sélection de layer - mobile OU tablette/PC ↓ */}
                <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                    {/* Modal explicatif des ranges */}
                    <ModalBiomassRange
                        open={this.state.modalOpen}
                        parcel={parcel}
                        isBeginningWinter={isBeginningWinter}
                        onClose={() => this.setState({ modalOpen: false })}
                    />
                    <Box className="leaflet-top leaflet-right leaflet-PosNone">

                        <Tooltip title={<Typography color="inherit">{isSlectedVisibleLayer ? StringTranslate.ComeBackToIndicevege : StringTranslate.DetectClouds}</Typography>} placement="left">
                            <Fab
                                className='leaflet-control'
                                size="small"
                                onClick={() => this.onSelectGeneralLayer()}
                                sx={{
                                    backgroundColor: theme.palette.background.paper,
                                    color: theme.palette.primary.dark
                                }}>
                                {isSlectedVisibleLayer ? <CloudOffIcon /> : <CloudIcon />}
                            </Fab>
                        </Tooltip>

                    </Box>

                    <div className='leaflet-bottom leaflet-PosNone leaflet-center-buttons-navigation'>

                        <Card sx={{ marginBottom: "118px", marginRight: "5px", marginLeft: "5px" }}>
                            <CardActions>
                                <Grid container className={(buildWaiting === true) ? "disabledAll" : ""}>

                                    <Grid item xs={3} container alignItems={'center'}>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={() => this.onGoBackToBiomasses()}
                                            tabIndex={12}
                                            sx={{
                                                zIndex: 800,
                                                pointerEvents: "fill",
                                            }}
                                        >
                                            <ArrowBackIcon />
                                        </Button>
                                    </Grid>
                                    <Grid item container xs={3} alignItems={'center'} justifyContent={'center'}>
                                        <Chip
                                            label={StringTranslate.biomassRangeMobileHelper}
                                            variant={'outlined'}
                                            color='primary'
                                            onClick={() => this.setState({ modalOpen: true })}
                                            icon={<InfoIcon />}
                                            sx={{
                                                zIndex: 800,
                                                cursor: "pointer",
                                                pointerEvents: "fill",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} align="right">
                                        <Stack direction="row" spacing={1} justifyContent="flex-end">
                                            <Button
                                                variant="text"
                                                color="error"
                                                sx={{
                                                    zIndex: 800,
                                                    pointerEvents: "fill",

                                                    display:"block",
                                                    ...( (parcelIdsSelected.length > 1) && {
                                                        display:"none"
                                                    })
                                                }}
                                                onClick={() => {this.handleOpenCancelParcel(true)}}
                                                disabled={(satimageIdSelectedOfParcel === undefined) || (satimageIdSelectedOfParcel <= 0)}
                                            >
                                                {StringTranslate.parcelleSuivanteMobile}
                                            </Button>

                                            <Button
                                                variant="text"
                                                color="primary"
                                                startIcon={
                                                    (buildWaiting === false) ?
                                                        <BalanceIcon />
                                                        : <CircularProgress
                                                            color="inherit"
                                                            size={24}
                                                        />}
                                                sx={{
                                                    bgcolor: theme.palette.background.paper,
                                                    color: theme.palette.primary.main,
                                                    zIndex: 800,
                                                    pointerEvents: "fill",
                                                }}
                                                onClick={() => this.onSelectImage()}
                                                disabled={(satimageIdSelectedOfParcel === undefined) || (satimageIdSelectedOfParcel <= 0)}
                                            >
                                                {StringTranslate.valider}
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Card>
                    </div>
                </Box>

                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                    <Card 
                        className="leaflet-bottom leaflet-left leaflet-PosNone" 
                        variant_pz="card-md-bio">

                        <Box sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                            <CardHeader sx={headerSX} title={<Typography variant="h5">{isBeginningWinter === true ? StringTranslate.selectBiomassBeginningWinter : StringTranslate.selectBiomassEndingWinter}</Typography>} />
                            <Divider />
                        </Box>

                        <CardContent className={(buildWaiting === true) ? "disabledAll" : ""}>
                            <Box sx={{ display: { xs: "block", sm: "block", md: "block", lg: "none" } }}>
                                <Typography variant="subtitle1">{isBeginningWinter === true ? StringTranslate.selectBiomassBeginningWinter : StringTranslate.selectBiomassEndingWinter}</Typography>
                            </Box>

                            { /* Layer */}
                            <Grid container alignItems="center" spacing={0} justifyContent="space-between">
                                <Grid item xs={8}>
                                    {isSlectedVisibleLayer ?
                                        <>
                                            <Typography variant="subtitle2" sx={{ display: { xs: "block", sm: "block", md: "block", lg: "none" } }}>
                                                {StringTranslate.goBackToVegetationIndex}
                                            </Typography>
                                            <Typography variant="subtitle2" sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                                                {StringTranslate.ObserveVisibleLayer}
                                            </Typography>
                                        </>
                                        :
                                        <>
                                            <Typography variant="subtitle2" sx={{ display: { xs: "block", sm: "block", md: "block", lg: "none" } }}>
                                                {StringTranslate.verifClouds}
                                            </Typography>
                                            {this.hasNoDateRange ? 
                                                (
                                                    <Typography variant="subtitle2" sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                                                        {StringTranslate.ObserveCloudLayer}
                                                    </Typography>
                                                )
                                            :
                                                (
                                                    <Stack direction={'column'}>
                                                        <Stack spacing={1} direction={'row'} alignItems={'baseline'}>
                                                            <SlideRangeMarker label={'IN_RANGE'}/>
                                                            <Stack direction={'column'}>
                                                                <Typography variant="subtitle2">{BiomassesHelper.getRangeDatesText(parcel, isBeginningWinter)}</Typography>
                                                                <Typography variant="subtitle2">{StringTranslate.biomassRangeText}</Typography>
                                                            </Stack>
                                                        </Stack>
                                                        <Stack spacing={1} direction={'row'} alignItems={'baseline'}>
                                                            <SlideRangeMarker label={'IN_EXTRA_RANGE'}/>
                                                            <Stack direction={'column'}>
                                                                <Typography variant="subtitle2">{BiomassesHelper.getExtraRangeDatesText(parcel, isBeginningWinter)}</Typography>
                                                                <Typography variant="subtitle2">{StringTranslate.biomassExtraRangeText}</Typography>
                                                            </Stack>
                                                        </Stack>
                                                        <Stack spacing={1} direction={'row'} alignItems={'baseline'}>
                                                            <SlideRangeMarker />
                                                            <Typography variant="subtitle2">{StringTranslate.biomassOutOfRangeText}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                )
                                            }
                                        </>}
                                </Grid>
                                <Grid item xs={2}>
                                    <Tooltip 
                                        title={StringTranslate.ObserveCloudLayer} 
                                        // Si pas de date, pas besoin du tooltip (Il est déjà afficher à gauche du bouton)
                                        disableHoverListener={this.hasNoDateRange} 
                                        disableFocusListener={this.hasNoDateRange} 
                                    >
                                        <IconButton
                                            sx={{
                                                bgcolor: theme.palette.primary.light,
                                                color: theme.palette.primary.main,
                                                '& .MuiSvgIcon-root': { fontSize: '1.5rem' }
                                            }}
                                            size="large"
                                            onClick={() => this.onSelectGeneralLayer()}
                                            >
                                            {(isSlectedVisibleLayer === true) ?
                                                <CloudOffIcon /> : <CloudIcon />}
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                        </CardContent>

                        <CardActions>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Button variant="text" color="primary"
                                        onClick={() => this.onGoBackToBiomasses()}
                                        tabIndex={12}
                                        disabled={(buildWaiting === true)}
                                    >
                                        {`${StringTranslate.goBackStepBiomass}`}
                                    </Button>
                                </Grid>

                                <Grid item  xs={8} align="right">
                                    <Stack direction="row" justifyContent="flex-end">
                                        <Grid container>
                                            <Grid item xs={8}>
                                                <Button
                                                    variant="text"
                                                    color="error"
                                                    sx={{
                                                        display:"block",
                                                        ...( (!(parcelIdsSelected.length > 1)) && {
                                                            display:"none"
                                                        })
                                                    }}
                                                    onClick={() => {this.handleOpenCancelParcel(true)}}
                                                    disabled={(satimageIdSelectedOfParcel === undefined) || (satimageIdSelectedOfParcel <= 0) || (buildWaiting === true)}
                                                >
                                                    { StringTranslate.parcelleSuivanteDesktop}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={(buildWaiting === true) && (
                                                        <CircularProgress color="inherit"
                                                            size={24} />)}
                                                    onClick={() => this.onSelectImage()}
                                                    disabled={(satimageIdSelectedOfParcel === undefined) || (satimageIdSelectedOfParcel <= 0) || (buildWaiting === true)}
                                                >
                                                    {`${StringTranslate.next}`}
                                                </Button>                                                
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Box>

            </>
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    //Infos provenant du reducer 'clientUser':
    clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1),

    //Infos provenant du reducer 'parcels':
    parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),

    //Infos provenant du reducer 'contextApp':
    parcelIdSelected: lodashGet(state, 'contextAppData.parcelIdSelected', -1),
    satimageIdSelectedOfParcel: lodashGet(state, 'contextAppData.satimageIdSelectedOfParcel', -1),
    satimageDateSelectedOfParcel: lodashGet(state, 'contextAppData.satimageDateSelectedOfParcel', undefined),
    providerSrcImageSelectedOfParcel: lodashGet(state, 'contextAppData.providerSrcImageSelectedOfParcel', SatImageSourceProvider.Unkown),

    //Infos provenant du reducer 'biomass':
    parcelIdsSelected: lodashGet(state, 'biomassData.parcelIdsSelected', []),
    hasAskedForEndingBiomass: lodashGet(state , 'biomassData.hasAskedForEndingBiomass', false),
    buildWaiting: lodashGet(state , 'biomassData.building', false),
    errorBiomass: lodashGet(state, 'biomassData.errorMessage', undefined),

    language: lodashGet(state, 'settingsData.settings.language', StringTranslate.getLanguage()), // Permet de raffraichir le composant lors d'un changement de langue
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    cleanErrorBiomass: () => dispatch(ActionCleanErrorBiomass()),
    unselectParcel: () => dispatch(ActionUnselectParcel()),
    goToBiomass: () => dispatch(ActionGoToBiomass()),
    clearBiomassValue: () => dispatch(ActionClearBiomassValue()),
    buildBiomassSampleAndQuantities: (idClient, biomassParameter) => dispatch(buildBiomassSampleAndQuantities(idClient, biomassParameter)),
    goToNextStepOfBiomass: (nextStepOfBiomass, parcelIdsSelected, readableMode, biomassSelectedItemValue = undefined) =>
        dispatch(ActionGoToNextStepOfBiomass(nextStepOfBiomass, parcelIdsSelected, readableMode, biomassSelectedItemValue)),
    selectLayerForBiomass: (baseLayertypeValue, forewardLayertypeValue) => dispatch(ActionSelectLayerForBiomass(baseLayertypeValue, forewardLayertypeValue)),
})

export default connect( mapStateToProps, mapDispatchToProps )(MapButtonsPaneBiomass);
