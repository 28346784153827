import React from 'react';
import { connect } from 'react-redux';

import LayerSelectionButtonControl from './LayerSelectionButtonControl.jsx';
import LayerCloudSelectionButtonControl from './LayerCloudSelectionButtonControl.jsx';
import GeolocationButtonControl from './GeolocationButtonControl.jsx';
import GeoSearchButtonControl from './GeoSearchButtonControl.jsx';
import DrawParcelAndPinDropObservation from './DrawParcelAndPinDropObservation.jsx';
import SwipeablePanelForDesktop from './SwipeablePanelForDesktop.jsx';
import SwipeablePanelForMobile from './SwipeablePanelForMobile.jsx';
import WidgetNdviCharts from './widgetPanel/widgetNdviCharts.jsx';
import ZoomButtonsControl from './ZoomButtonsControl.jsx';
import ConstantsContent from "../../utils/constantsContent";

import { Card, Box, CardContent } from '@mui/material';

import '../../assets/css/swipeablePanel.css';

import getTheme from "../../themes/index.js";

let theme = getTheme();

/**
 * Composant représentant la couche de boutons sur la carte
 */
class MapButtonsPane extends React.Component {

    render() {

        const { renderMapsCmp, parcelIdSelected, burgerMenuIsOpen, isOpenMenuBurgerDesktop } = this.props;
 
        return (
            <div className="leaflet-control-container">

                <div className="leaflet-top leaflet-left leaflet-PosNone"></div>

                <Box 
                    className="leaflet-top leaflet-right leaflet-PosNone" 
                    sx={{
                        [theme.breakpoints.up('md')]: { mr:3 },
                        mt: 9,
                    }}
                >
                    {/* ↓ contrôle (bouton) en lien avec la recherche d'un lieu/ville ↓ */}
                    <GeoSearchButtonControl renderMapsCmp={renderMapsCmp} />

                    {/* ↓ contrôle (bouton + fonctionnel) en lien avec la géolocalisation ↓ */}
                    <GeolocationButtonControl renderMapsCmp={renderMapsCmp}></GeolocationButtonControl>

                    {/* ↓ contrôle (bouton + fonctionnel) en lien avec le zoom/dezoom ↓ */}
                    <ZoomButtonsControl {...this.props} />

                    {/* ↓ contrôle (bouton) en lien avec la sélection de layer ↓ */}
                    <LayerSelectionButtonControl />

                    {/* ↓ contrôle (bouton) en lien avec le dessin d'une nouvelle parcelle, ou une nouvelle observation ↓ */}
                    <DrawParcelAndPinDropObservation renderMapsCmp={renderMapsCmp}/>

                    <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' }} }>
                        {/* ↓ contrôle (bouton) en lien avec l'affichage des nuages ou non ↓ */}
                        <LayerCloudSelectionButtonControl renderMapsCmp={renderMapsCmp} />
                    </Box>
                </Box>
                <Box 
                    className="leaflet-bottom leaflet-PosNone"
                    sx={{
                        [theme.breakpoints.up("md")]: { left: isOpenMenuBurgerDesktop ? 'calc(50% - 130px);' : `calc(50% - ${ConstantsContent.DrawerWidth - ConstantsContent.MarginCard}px)` },
                        [theme.breakpoints.down("md")]: { left: `calc(50% - ${ConstantsContent.DrawerWidth}px)` },
                        [theme.breakpoints.down("sm")]: { left: '1%' },
                        paddingRight:'5px',

                    }}>
                    { (parcelIdSelected <= 0) && (this.props.isDrawingParcel === false) &&
                        <Card className='leaflet-control'>
                            <CardContent>
                                <WidgetNdviCharts />
                            </CardContent>
                        </Card>
                    }
                </Box>
            
                <div className="leaflet-bottom leaflet-left leaflet-PosNone ">
               
                    <Box 
                        sx={{ 
                            transition: theme.transitions.create(['margin'], {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.shorter
                            }),
                            display:{xs:'none', sm: 'none', md:'block'},
                            
                            ml: isOpenMenuBurgerDesktop ? `${ConstantsContent.DrawerWidth + 12}px` : "30px",
                        }}
                    >
                        <SwipeablePanelForDesktop {...this.props} />
                    </Box>
                </div>

                {/* //TODO : A revoir car pas top de conditionner l'affichage différemment de 'SwipeablePanelForDesktop' ! */}
                {(burgerMenuIsOpen === false) && (parcelIdSelected > 0) &&
                    (window.innerWidth < 900) && <SwipeablePanelForMobile {...this.props} />
                }

            </div>
        )
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant */
const mapStateToProps = function(state) {
    return {
        isDrawingParcel: state.parcelsData.isDrawingParcel,
        parcelDico: state.parcelsData.parcelDico,
        
        parcelIdSelected: state.contextAppData.parcelIdSelected,
        satimageIdSelectedOfParcel: (state && state.contextAppData) ? state.contextAppData.satimageIdSelectedOfParcel : -1,
        satimageDateSelectedOfParcel: (state && state.contextAppData) ? state.contextAppData.satimageDateSelectedOfParcel : undefined,
        burgerMenuIsOpen: (state && state.contextAppData) ? state.contextAppData.burgerMenuIsOpen : false,
        isOpenMenuBurgerDesktop: (state && state.contextAppData) ? state.contextAppData.isOpenMenuBurgerDesktop : false,

        showNdviCharts: state.settingsData.settings.showNdviCharts,

        modulationDico: state.modulationsData.modulationDico,
        modulationDicoCounter: state.modulationsData.modulationDicoCounter,
    };
}

export default  connect( mapStateToProps, null )(MapButtonsPane);