import React, { useState } from 'react';
import SubStepGeolocation from './subStepGeolocation.jsx';
import SubStepPlaceSearch from './subStepPlaceSearch.jsx';
import SubStepDessin from './subStepDessin.jsx';

/* enuméré décrivant les diverses sous étapes de l'étape dessin */
const SubStepDessinEnum = {
    GEOLOCATION: 'GEOLOCATION',
    PLACESEARCH: 'PLACESEARCH',
    DESSIN: 'DESSIN',
}

/**
 * Composant représentant la couche de boutons sur la carte - didacticiel première parcelle
 * Scénario étape dessin:
 * - lancement automatique du GPS
 * - si localisation trouvée, on passe directement à la vue carto
 * - si localisation non trouvée, on passe sur une modale de recherche de localité 
 * - si refus de la recherche de la localité ( ou non trouvée) on passe à la vue carto
 */
function StepDessin(props) {

    const [currentSubStepDessin, setSubStepDessin] = useState(SubStepDessinEnum.GEOLOCATION);

    switch (currentSubStepDessin) {
        case SubStepDessinEnum.GEOLOCATION:
            return <SubStepGeolocation {...props} setSubStepDessin={setSubStepDessin} subStepDessinEnum={SubStepDessinEnum} />

        case SubStepDessinEnum.PLACESEARCH:
            return <SubStepPlaceSearch {...props} setSubStepDessin={setSubStepDessin} subStepDessinEnum={SubStepDessinEnum} hideGeolocationButton={true} />;

        case SubStepDessinEnum.DESSIN:
            return <SubStepDessin {...props} />
    
        default:
            return null;
    }
}

export default StepDessin;