
const ListHelper = {

    not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1);
    },
    
    intersection(a, b) {
        return a.filter((value) => b.indexOf(value) !== -1);
    },
    
    union(a, b) {
        return [...a, ...b.filter((value) => a.indexOf(value) === -1)];
    },

}

export default ListHelper;