import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


function CursorGrab(props: SvgIconProps) {
    return ( 
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" strokeWidth="1.8" stroke="none" fill="current">
              <path d="M25.65,10.51A2.74,2.74,0,0,0,22.91,9a3.65,3.65,0,0,0-.74.08V8.71A3,3,0,0,0,19.1,6a3.5,3.5,0,0,0-1.33.25A2.84,2.84,0,0,0,15.29,5a2.88,2.88,0,0,0-2.13.83,3.21,3.21,0,0,0-.84,1.93,2.91,2.91,0,0,0-2.13-.81A3,3,0,0,0,7.87,8a2.6,2.6,0,0,0-.54,2.14,3.86,3.86,0,0,0,.57,1.4,3.61,3.61,0,0,1,.39.85c.1.35.18.69.24,1a3.08,3.08,0,0,0-2.1.37,2.41,2.41,0,0,0-1.07,1.79,5.74,5.74,0,0,0,1.81,4.39c1,1,1.88,2,2.61,2.72.54.56,1,1,1.28,1.34a2.32,2.32,0,0,1,.4,1.26v1.13H23.73l.07-1.06a16.08,16.08,0,0,1,.83-4.16A29.45,29.45,0,0,0,26,13,5.9,5.9,0,0,0,25.65,10.51ZM23.33,20.63A16.42,16.42,0,0,0,22.42,25H12.85A3.5,3.5,0,0,0,12.09,23c-.3-.32-.75-.79-1.31-1.36C10.06,20.93,9.16,20,8.19,19a4.4,4.4,0,0,1-1.44-3.26,1.11,1.11,0,0,1,.43-.78c.71-.45,2,0,2.49.26l.39.19,0-.43a10.18,10.18,0,0,0-.45-3,4.12,4.12,0,0,0-.53-1.16,2.53,2.53,0,0,1-.39-1,1.15,1.15,0,0,1,.25-1,1.61,1.61,0,0,1,1.23-.56c.91,0,1.24.43,1.84,1.23a13.23,13.23,0,0,1,1.15,1.92l.54,1.11V8a1.84,1.84,0,0,1,.45-1.17,1.49,1.49,0,0,1,1.12-.4,1.55,1.55,0,0,1,1.6,1.48l.28,3.39.28-2.52A1.5,1.5,0,0,1,19.1,7.39a1.58,1.58,0,0,1,1.67,1.4l.28,3.5.38-.79a1.42,1.42,0,0,1,1.48-1.13c1,0,1.32.42,1.46.73A4.88,4.88,0,0,1,24.59,13,28.4,28.4,0,0,1,23.33,20.63Z" fill="current" />
            </svg>
        </SvgIcon>
    );
}

export default CursorGrab;