import React from 'react';
import { ImageList, ImageListItem, IconButton, Button, Box } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ConstantsObservation from '../../utils/constantsObservation.js';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import '../../assets/css/identificationDialog.css';
import getTheme from "../../themes/index.js";

let theme = getTheme();

/**
 * Composant permettant de gérer l'affichage d'une liste
 * 
 * props:
 * - imagesURL : la liste d'url des images à afficher
 * - [onClickImage] : fonction appeler lors du click sur une image, prend en parametre l'index de l'image
 * - [onClickDeleteImage] : fonction permettant de supprimer l'image de la liste (fait apparaitre un bouton en haut à droite de l'image pour la supprimer)
 * - [onInputFileChange] : fonction appeler lors du click sur le bouton d'ajout d'image (fait apparaitre le bouton d'ajout)
 * - [imageSize="small"] : permet de définir la taille des images
 * - [oneRow] : affiche les images sur une ligne
 */
class DisplayImageList extends React.Component {
    constructor(props) {
        super(props);
        this.inputFileRef = React.createRef();
    }

    onClickAddImage = () => {
        this.inputFileRef.current.click();
    }

    render() {
        const { imagesURL, onClickImage, onClickDeleteImage, onInputFileChange, oneRow, imageSize } = this.props;
        return (
            <div>
                <ImageList cols={(oneRow)?imagesURL.lentgh:(window.innerWidth > 410)?3:2} rowHeight={(oneRow)?'auto':125} className={(oneRow)?"id-result-img-list":""}>
                    {imagesURL.map((image, index) => {
                        return(
                            <ImageListItem key={index}>
                                <Box
                                    sx={{
                                        position:"relative",
                                        width:"fit-content",
                                        height:"fit-content"
                                    }}
                                >
                                    <img
                                        src={image}
                                        className={(imageSize === "small")?"id-img-result":"id-img-selected"}
                                        alt={`${StringTranslate.pinDropFormImage} ${index}`}
                                        onClick={() => onClickImage(index)}
                                    />
                                    {(onClickDeleteImage !== undefined) && 
                                        <IconButton 
                                            sx={{
                                                position:"absolute",
                                                top:"0px",
                                                right:"0px",
                                                padding:"0px",
                                                margin:"0px",
                                                borderRadius:"5px",
                                                backgroundColor: theme.palette.common.white
                                            }}
                                            color="primary"
                                            onClick={() => onClickDeleteImage(index)}
                                        >
                                            <CancelIcon/>
                                        </IconButton>
                                    }
                                </Box>
                            </ImageListItem>
                        );
                    })}
                    {(onInputFileChange !== undefined) && 
                        <ImageListItem key={"addAPhoto"}>
                            <Button 
                                variant="outlined"
                                color="primary"
                                sx={{
                                    height: '100px',
                                    width: '100px',
                                    marginTop: '12px',
                                    marginLeft: '12px'
                                }}
                                onClick={this.onClickAddImage} 
                                disabled={imagesURL.length >= ConstantsObservation.NbMaxImages}
                            >
                                <AddAPhotoIcon />
                            </Button>
                            <input 
                                ref={this.inputFileRef}
                                accept=".jpeg,.jpg"
                                id="raised-button-file"
                                type="file"
                                className="input"
                                value=''
                                onChange={onInputFileChange}
                            />
                        </ImageListItem>
                    }
                </ImageList>
            </div>
        );
    }
}

export default DisplayImageList;