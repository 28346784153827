import ReactAI from 'react-appinsights';
import ConstantsHosters from './constantsByConfig.js';
import StoreFunctions from './storeFunctions.js';

const logInConsole = false;
const sendToAzure = true;

const user = {};

ReactAI.init({instrumentationKey:ConstantsHosters.appInsightsId});

const sendError = function(key,value){
    var appInsights = ReactAI.ai();
    if (!user.userEmail) user.userEmail = StoreFunctions.getUserEmail();

    let detailsStr = '';
    try
    {
        if (value) {
            detailsStr = JSON.stringify(value);
        }
    }
    catch(except) {
        if (logInConsole) {
            console.log(`error ${except}`);
        }
    }

    if (logInConsole) {
        console.log(`error ${key}`);
        console.log(value);
        console.log(detailsStr);
    }
    if (sendToAzure) {
        const obj = {};
        obj.detailsStr = detailsStr;
        obj.clientId = (user.userId) ? user.userId : "notLogged";
        obj.clientLogin = (user.userEmail) ? user.userEmail : "notLogged";
        const objToSend = Object.assign(obj, value);
        appInsights.trackTrace(key, objToSend);
    }
}

export default sendError;