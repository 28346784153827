import React from "react";
import { connect } from 'react-redux';

//css
import '../../src/index.css'
// i18n
import StringTranslate from '../assets/i18n/stringLanguage.jsx';
// composants

import {
    IconButton, Dialog,
    Toolbar, Typography, AppBar, Tooltip, Grid,
    Chip, Box
} from "@mui/material";

import {
    Menu, AccountCircle, Close, ContactMailOutlined
} from "@mui/icons-material";

import { styled } from '@mui/material/styles';

import RenderProfil from "./profil/renderProfil";

// redux + helper
import {
    ActionGoToMapAndUnselectParcel,
    ActionGoToMap,
    ActionGoToSettings,
    ActionGoToProfil,
    ActionBurgerMenuOpen,
    ActionBurgerMenuOpenDesktop,
    ActionFitMapToBounds,
    ActionShowProfilMenuDialog,
    ActionCloseImageDialog,
    ActionShowCalendarDialog,
    ProfilIndex
} from '../redux/actions/contextApp.js';
import { LanguageHelper } from '../utils/languageHelper.js';

// Les images:
import HomeExploitationIcon from '../assets/homeExploitationIcon.tsx';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoSpotifarm from '../assets/logoSpotifarm/LogoSpotifarmTitreEtFeuille';
//import LogoSpotifarmSansFeuille from "../assets/logoSpotifarm/LogoSpotifarmSansFeuille"; // version 'sans feuille' mais pas afficher par ce composant mais par le 'sidebar' (en cas d'écran mobile) !

// les outils
import lodashGet from 'lodash/get';
import NotificationHeadway from './notification/NotificationHeadway.jsx';
import DisplayPurchaseStoreResult from './stripe/displayPurchaseStoreResult.jsx';

/* theme */
import getTheme from "../themes/index.js";
import CustomCalendar from "./customCalendar";

let theme = getTheme();

/* customisation du style du composant - CSS in JS */
const CustomizedImageDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),
    },
    '& .MuiDialog-paper': {
        padding: 0
    }
}));

/**
 *  Composant global définissant l'entête de notre application web.
 */
class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            mobileMoreAnchorEl: null,

            /* snackbar qui correspond au bouton historique */
            snackbarHistoryDatas: null, // les infos du snackbar pour le bouton icone historique

            /* affiche le dialog popup pour lancer le didacticiel FirstParcel */
            displayTutorialFirstParcel: false,

            /* Pour savoir si le menu de gauche est fermé ou ouvert en desktop */
            isOpenMenuBurgerDesktop: props.isOpenMenuBurgerDesktop,
        };

        this.datePickerComponentRef = React.createRef(); // reference de l'objet DatePicker (calendrier) - permet d'utiliser la référence depuis le bouton calendar
        this.focusDatePicker = false; // controle permettant de connaitre l'état d'ouverture du calendrier

        /* ↓ BIND ↓ */
        this.handleProfilMenuOpen = this.handleProfilMenuOpen.bind(this);
        this.handleMobileProfilMenuOpen = this.handleMobileProfilMenuOpen.bind(this);
        this.handleMenuClose = this.handleMenuClose.bind(this);
        this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
        this.handleGoToSettingsViewClick = this.handleGoToSettingsViewClick.bind(this);
        this.handleMobileGoToSettingsViewClick = this.handleMobileGoToSettingsViewClick.bind(this);
        this.handleGoToProfilView = this.handleGoToProfilView.bind(this);
        this.handleMobileGoToProfilView = this.handleMobileGoToProfilView.bind(this);
        this.handleMobileOpenBurgerMenu = this.handleMobileOpenBurgerMenu.bind(this);
        this.handleDesktopOpenBurgerMenu = this.handleDesktopOpenBurgerMenu.bind(this);
        this.handleParcellaireClick = this.handleParcellaireClick.bind(this);
        this.handleContactClient = this.handleContactClient.bind(this);
        this.handleMobileParcellaireClick = this.handleMobileParcellaireClick.bind(this);
        this.removeSnackbar = this.removeSnackbar.bind(this);
        this.calendarCustomDateInput = undefined; 

    }

    /* fonction callback */
    handleProfilMenuOpen(event) {
        this.setState({ anchorEl: event.currentTarget });
    };

    /* fonction callback */
    handleMobileProfilMenuOpen(event) {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    /* fonction callback */
    handleMenuClose(event) {
        this.setState({
            anchorEl: null,
            mobileMoreAnchorEl: null,
        });
    };

    /* fonction callback */
    handleMobileMenuClose(event) {
        this.setState({ mobileMoreAnchorEl: null });
    };

    /* fonction callback */
    handleGoToSettingsViewClick(event) {
        this.props.goToSettings();
        this.handleMenuClose();
    }

    /* fonction callback */
    handleMobileGoToSettingsViewClick(event) {
        this.props.goToSettings();
        this.handleMobileMenuClose();
    }

    /* fonction callback */
    handleGoToProfilView(event) {
        if (window.dataLayer) // google tag manager
            window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'profil' });

        this.props.goToProfil();
        this.handleMenuClose();
    }

    /* fonction callback */
    handleMobileGoToProfilView(event) {
        if (window.dataLayer) // google tag manager
            window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'profil' });

        this.props.goToProfil();
        this.handleMobileMenuClose();
    }

    /* fonction callback */
    handleMobileOpenBurgerMenu(event) {
        this.props.openBurgerMenu();
    }

    /* Ouverture et fermeture du menu de gauche en mode desktop */
    handleDesktopOpenBurgerMenu(event) {
        this.props.openBurgerMenuDesktop();

        /* Au clic du menu burger, on resize l'écran (important pour la map) */
        window.dispatchEvent(new Event('resize'));
    }

    /* fonction callback */
    handleParcellaireClick(event) {
        if (this.props.goToMapAndUnselectParcel) {
            this.props.goToMapAndUnselectParcel();
            this.props.fitMapToBounds();
        } else if (this.props.goToMap) {
            this.props.goToMap();
        }
    }

    /**
     * Affiche la fenetre de contact du service client
     */
    handleContactClient() {
        const { showProfilMenuDialog } = this.props;
        showProfilMenuDialog(ProfilIndex.contacterLeServiceClient);
    }

    /* fonction callback */
    handleMobileParcellaireClick(event) {
        this.handleParcellaireClick(event);
        this.handleMobileMenuClose();
    }

    /* fonction permettant de retirer le snackbar du bouton icone historique */
    removeSnackbar() {
        this.setState({
            snackbarHistoryDatas: null
        });
    }

    handleOpenProfilMenu = () => {
        const { showProfilMenuDialog } = this.props;

        showProfilMenuDialog();
    }

    onCloseImageDialog = () => {
        this.props.closeImageDialog();
    }

    /**
     * Fonctions de rendu
     */

    // ↓ POP-UP - Affichage d'une image en gros plan ↓
    renderShowImage = () => {
        const { showImageDialogUri } = this.props;
        let img = new Image();
        img.src = showImageDialogUri;
        return (
            <CustomizedImageDialog
                open={(showImageDialogUri !== "")}
                onClose={this.onCloseImageDialog}
            >
                <IconButton
                    aria-label="close"
                    onClick={this.onCloseImageDialog}
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        color: (theme) => theme.palette.common.white,
                    }}
                >
                    <Close />
                </IconButton>
                <img src={showImageDialogUri} alt={StringTranslate.pinDropFormErrorShowImage} />
            </CustomizedImageDialog>
        );
    }

    /* fonction cycle de vie react.js */
    render() {
        const { anchorEl, isOpenMenuBurgerDesktop } = this.state;
        const { 
            nameExploitation, languageSetting, isshowProfilMenuDialog,
            showPurchaseOnStoreResult, subscriptionProcessStarting
        } = this.props;

        this.snackbarHistoryAnchorOrigin = { vertical: 'top', horizontal: 'left' }; // snackbar lors du clic sur le bouton/icone historique se place en haut/gauche

        const isMenuOpen = Boolean(anchorEl);

        return (
            <>
                {/* ↓↓ composant d'affichage d'un retour d'achat depuis les Stores (mais uniquement si par levée d'event ET non lors du processus d'achat) ↓↓  */}
                {(subscriptionProcessStarting !== true) && (showPurchaseOnStoreResult === true) && <DisplayPurchaseStoreResult />}

                {isshowProfilMenuDialog ? <RenderProfil /> : null}


                <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={0}
                    sx={{
                        bgcolor: theme.palette.background.default,
                        transition: isOpenMenuBurgerDesktop ? theme.transitions.create('width') : 'none',
                        height: '70px'
                    }}
                >
                    <Toolbar> {/* un override du padding left dans le themeComponent pour ce composant */}
                        <Grid container direction="row" spacing={0} sx={{ [theme.breakpoints.down('sm')]: {mt: 2} }}>

                            <Grid container item xs={7} sm={9} md={8} spacing={0}>
                                <Grid item
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    {/* ↓ LOGO ↓ */}
                                    <Grid item sx={{ [theme.breakpoints.down('sm')]: { display: "none" } }}>
                                        <Box className="marqueImgWrapper">
                                            <Box sx={{ display:"flex", alignItems:"center"}}>
                                                <LogoSpotifarm/>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    {/* ↓ MENU BURGER - MOBILE ↓ */}
                                    <Grid item sx={{ [theme.breakpoints.up('md')]: { display: 'none' } }}>
                                        <Tooltip title="Menu">
                                            <IconButton
                                                sx={{ 
                                                    [theme.breakpoints.only('xs')]: { ml: 1, mr: 0 },
                                                }}
                                                size="small"
                                                variant="header"
                                                color="inherit"
                                                onClick={this.handleMobileOpenBurgerMenu}
                                            >
                                                <Menu />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>

                                    {/* ↓ MENU BURGER - DESKTOP ↓ */}
                                    <Grid item sx={{ [theme.breakpoints.down('md')]: { display: 'none' } }} >
                                        <Tooltip title="Menu">
                                            <IconButton
                                                sx={{ [theme.breakpoints.only('xs')]: { ml: 1, mr: 0 } }}
                                                size="small"
                                                variant="header"
                                                color="inherit"
                                                onClick={this.handleDesktopOpenBurgerMenu}
                                            >
                                                <Menu />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>

                                    {/* ↓ NOM DE L'EXPLOITATION ↓ */}
                                    <Grid item>
                                        <Tooltip title={`${StringTranslate.tooltipcenter}`} placement="bottom">
                                            <IconButton
                                                sx={{
                                                    [theme.breakpoints.only('xs')]: { ml: 1, mr: 0 }
                                                }}
                                                size="small"
                                                variant="header"
                                                onClick={this.handleParcellaireClick}
                                                color="inherit"
                                            >
                                                <HomeExploitationIcon />
                                                <Typography
                                                    id="nomDeLExploitation"
                                                    color="inherit"
                                                    variant="h5"
                                                    sx={{
                                                        ml: 1, mr: 1.5,
                                                        [theme.breakpoints.down('md')]: { display: 'none' }
                                                    }}>{(nameExploitation && (nameExploitation !== '')) ? nameExploitation : '...'}</Typography>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>

                                    {/* ↓ CALENDRIER ↓ */}
                                    <Grid item>
                                        <CustomCalendar/>
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid container item xs={5} sm={3} md={4} spacing={0}>
                                <Grid item
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={0}
                                >
                                    {/* ↓ BOUTON - NOTIFICATIONS HEADWAY ↓ */}
                                    <Grid item>
                                        {(languageSetting === 'fr-FR' || languageSetting === 'fr-BE' || languageSetting === 'es-ES') &&
                                            <Tooltip
                                                title={StringTranslate.notifications}
                                                placement="bottom"
                                            >
                                                    <span>
                                                        <NotificationHeadway {...this.props} />
                                                    </span>
                                            </Tooltip>}
                                    </Grid>

                                    {/* ↓ BOUTON - CONTACT CLIENT ↓ */}
                                    <Grid item>
                                        <Tooltip
                                            title={StringTranslate.contactSupport}
                                            placement="bottom"
                                        >
                                            <IconButton
                                                size="small"
                                                variant="header"
                                                aria-owns={(isMenuOpen) ? 'material-appbar' : null}
                                                aria-haspopup="true"
                                                onClick={this.handleContactClient}
                                                color="inherit"
                                            >
                                                <ContactMailOutlined />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>

                                    {/* ↓ BOUTON - PARAMETRES ↓ */}
                                    <Grid item>
                                        <Tooltip
                                            title={`${StringTranslate.compte}`}
                                            placement="bottom"
                                            sx={{
                                                height: '42px',
                                                [theme.breakpoints.down('md')]: { display: 'none' }
                                            }}>
                                            <Chip
                                                variant="header"
                                                icon={<AccountCircle />}
                                                onClick={this.handleOpenProfilMenu}
                                                label={<SettingsOutlinedIcon />}
                                            />
                                        </Tooltip>

                                        {/* ↓ MENU - PROFIL - MOBILE ↓ */}
                                        <Tooltip title={`${StringTranslate.compte}`} placement="bottom" sx={{ [theme.breakpoints.up('md')]: { display: 'none' } }}>
                                            <IconButton
                                                size="small"
                                                variant="header"
                                                aria-owns={(isMenuOpen) ? 'material-appbar' : null}
                                                aria-haspopup="true"
                                                onClick={this.handleOpenProfilMenu}
                                                color="inherit"
                                            >
                                                <AccountCircle />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>

                    </Toolbar>
                </AppBar>

                {/* ↓ POP-UP - Affichage d'une image en gros plan ↓ */}
                {this.renderShowImage()}
            </>
        );
    }
}

//Définition des propriétés "décorant" le composant 'App':
const mapStateToProps = state => ({

    //Infos provenant du reducer 'settings':
    nameExploitation: lodashGet(state, 'settingsData.settings.nameExploitation', ''),
    languageSetting: lodashGet(state, 'settingsData.settings.language', LanguageHelper.defaultLanguage),

    //Infos provenant du reducer 'contextApp':
    showImageDialogUri: lodashGet(state, 'contextAppData.showImageDialogUri', ""),
    isshowProfilMenuDialog: lodashGet(state, 'contextAppData.showProfilMenu', false),

    //Infos provenant du reducer 'clientUserData':
    subscriptionProcessStarting: lodashGet(state, 'clientUserData.subscriptionProcess.planId', '') ? true : false,
    showPurchaseOnStoreResult: lodashGet(state, 'clientUserData.subscriptionProcess.showPurchaseOnStoreResult', false),
});

//Définition des méthodes "décorant" le composant 'App':
const mapDispatchToProps = dispatch => ({
    goToMapAndUnselectParcel: () => dispatch(ActionGoToMapAndUnselectParcel()),
    goToMap: () => dispatch(ActionGoToMap()),
    goToSettings: () => dispatch(ActionGoToSettings()),
    goToProfil: () => dispatch(ActionGoToProfil()),

    fitMapToBounds: () => dispatch(ActionFitMapToBounds()),

    openBurgerMenu: () => dispatch(ActionBurgerMenuOpen()),
    openBurgerMenuDesktop: () => dispatch(ActionBurgerMenuOpenDesktop()),

    showProfilMenuDialog: (index) => dispatch(ActionShowProfilMenuDialog(index)),

    closeImageDialog: () => dispatch(ActionCloseImageDialog()),

    openCalendar: () => dispatch(ActionShowCalendarDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);