import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import getTheme from '../../../themes/index';

let theme = getTheme();


function IconeObservationLevee(props: SvgIconProps) {
  return ( 
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" strokeWidth="1.8" stroke="none" fill="current">
        <g>
          <path d="M7,18.56c-.23-.35-.9-1.37-1-1.67a11.07,11.07,0,0,1-1.25-5.15A11.27,11.27,0,1,1,26,16.89c-.16.3-.83,1.32-1.07,1.69L16,31.17Z" fill={theme.palette.primary.main}/>
          <path d="M16,1A10.78,10.78,0,0,1,26.76,11.74a10.6,10.6,0,0,1-1.19,4.92c-.12.24-.68,1.1-1,1.61L16,30.3l-8.54-12c-.36-.54-.92-1.4-1-1.64a10.6,10.6,0,0,1-1.19-4.92A10.78,10.78,0,0,1,16,1m0-1A11.76,11.76,0,0,0,5.52,17.12c.2.39,1.09,1.73,1.09,1.73L16,32l9.38-13.18s.89-1.34,1.09-1.73A11.76,11.76,0,0,0,16,0Z" fill={theme.palette.primary.main}/>
        </g>
        <path d="M14.28,14.73a25.56,25.56,0,0,0-.78-4A8.54,8.54,0,0,0,12.78,9c-.3-.56-.72-.9-1.06-.8s-.75.83-1,1.43-.47,1.29-.69,1.95a20.57,20.57,0,0,1-.72,2,4.18,4.18,0,0,1-.52.92,1.13,1.13,0,0,1-.9.51,1.07,1.07,0,0,0,.81-.57,4.39,4.39,0,0,0,.41-.94c.21-.66.35-1.34.5-2s.29-1.37.5-2.06a6.28,6.28,0,0,1,.39-1,2.31,2.31,0,0,1,.85-1,1.27,1.27,0,0,1,.76-.12l.38.07.3.17a3,3,0,0,1,.9.87,10.41,10.41,0,0,1,1.05,2A27.08,27.08,0,0,1,16,14.46Z" fill={theme.palette.common.white}/>
        <path d="M14.84,14.57c-.08-1.57-.2-3.16-.4-4.72a21.65,21.65,0,0,0-.4-2.3,6.15,6.15,0,0,0-.34-1,1.46,1.46,0,0,0-.21-.34c-.07,0,.1,0,0,0a2.46,2.46,0,0,0-.43.89c-.11.37-.21.75-.29,1.13-.18.77-.32,1.56-.47,2.34s-.29,1.58-.47,2.36A11.72,11.72,0,0,1,11.51,14a4,4,0,0,1-.25.56c-.1.16-.24.36-.45.36.21,0,.33-.22.41-.39a3.69,3.69,0,0,0,.19-.56,11,11,0,0,0,.23-1.17c.11-.78.19-1.58.27-2.37s.15-1.59.26-2.39a12.26,12.26,0,0,1,.22-1.21,3,3,0,0,1,.53-1.28.78.78,0,0,1,.51-.26.81.81,0,0,1,.57.16,1.9,1.9,0,0,1,.5.6A7,7,0,0,1,15,7.27c.26.79.45,1.58.63,2.38.34,1.59.59,3.17.81,4.78Z" fill={theme.palette.common.white}/>
        <path d="M15.48,14.46a27.08,27.08,0,0,1,1.26-4.14,10.41,10.41,0,0,1,1.05-2,3,3,0,0,1,.9-.87l.3-.17.38-.07a1.25,1.25,0,0,1,.76.12,2.31,2.31,0,0,1,.85,1,6.28,6.28,0,0,1,.39,1c.21.69.35,1.38.5,2.06s.29,1.36.5,2a4.39,4.39,0,0,0,.41.94,1.07,1.07,0,0,0,.81.57,1.13,1.13,0,0,1-.9-.51,4.18,4.18,0,0,1-.52-.92,20.57,20.57,0,0,1-.72-2c-.22-.66-.43-1.32-.69-1.95s-.61-1.27-1-1.43S19,8.39,18.7,9A8.54,8.54,0,0,0,18,10.76a24.42,24.42,0,0,0-.77,4Z" fill={theme.palette.common.white}/>
        <path d="M15.08,14.47c.21-1.47.47-2.92.81-4.37a22,22,0,0,1,.63-2.16A5.33,5.33,0,0,1,17,6.86a1.62,1.62,0,0,1,.52-.53.8.8,0,0,1,1,.12,2.52,2.52,0,0,1,.55,1.15c.1.38.16.74.22,1.11.11.73.19,1.46.27,2.18s.17,1.45.28,2.17a9.52,9.52,0,0,0,.23,1.06,4.34,4.34,0,0,0,.2.5.56.56,0,0,0,.4.33.56.56,0,0,1-.44-.31,2.82,2.82,0,0,1-.25-.49,9.76,9.76,0,0,1-.32-1.05c-.18-.71-.33-1.43-.47-2.15s-.29-1.43-.46-2.13c-.09-.35-.18-.69-.3-1A2,2,0,0,0,18,7c-.08,0,0,0,0,0a1.12,1.12,0,0,0-.2.28,4.4,4.4,0,0,0-.34.93A17.39,17.39,0,0,0,17,10.32c-.21,1.42-.34,2.87-.42,4.29Z" fill={theme.palette.common.white}/>
        <path d="M14.75,14.38c.15-1.87.32-3.72.54-5.58.11-.93.23-1.85.39-2.78.08-.47.16-.93.28-1.4a4.86,4.86,0,0,1,.25-.73l.11-.2c.1-.09.1-.18.36-.26a.5.5,0,0,1,.49.18,1.09,1.09,0,0,1,.14.24,12.87,12.87,0,0,1,.38,2.86l.14,2.79L18,12.28q0,.69.12,1.38c0,.23.06.46.1.69s.07.49.28.6c-.22-.1-.25-.38-.32-.59s-.1-.45-.15-.68c-.08-.46-.14-.92-.2-1.38l-.31-2.77-.31-2.77a12.69,12.69,0,0,0-.5-2.62c0-.12-.13,0,.17,0l.09,0,0,.1a3.18,3.18,0,0,0-.16.61c-.08.43-.14.89-.19,1.34-.1.91-.16,1.83-.21,2.75-.1,1.85-.16,3.7-.19,5.55Z" fill={theme.palette.common.white}/>
        <polygon points="14.28 14.73 17.21 14.73 15.95 11.86 14.28 14.73" fill={theme.palette.common.white} fillRule="evenodd"/>
      </svg>
    </SvgIcon>
  );
}

export default IconeObservationLevee;