///////////////////////////////////////////////////////////////////////////
// Import des éléments utilisés par le composant <ControlsMapsModulation>
///////////////////////////////////////////////////////////////////////////
import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ImageSwiperModulation from './imageSwiperModulation';
import { connect } from 'react-redux';
import '../../../assets/css/swipeablePanel.css';
import lodashGet from 'lodash/get';


///////////////////////////////////////////////////////////////////////////
// Définition des constantes
///////////////////////////////////////////////////////////////////////////
export const LayersData = ['routiere', 'satellite', 'sentinel_satellite', 'sentinel_NDVI'];
export const Views = ['all','single'];

///////////////////////////////////////////////////////////////////////////
// Composant définissant les options d'affichage sur le rendu de la notre carte.
///////////////////////////////////////////////////////////////////////////
class ControlsMapsModulation extends React.Component {    

    ///////////////////////////////////////////////////////////////////////////
    // fonction de rendu visuel pour la fonctionnalité swiper
    ///////////////////////////////////////////////////////////////////////////
    getContentSwiper() {
        const { parcelIdSelected /*, isOpenMenuBurgerDesktop*/ } = this.props;

        return (parcelIdSelected > 0) ? (
            <Card className="leaflet-bottom leaflet-left leaflet-PosNone swiper-images-controlMaps">
                <CardContent className="widget-infos-content">
                    <ImageSwiperModulation {...this.props}/>
                </CardContent>
            </Card>
        
        ) : null;
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction de rendu visuel - layout général
    ///////////////////////////////////////////////////////////////////////////
    render() {
        return (
            /* ↓↓ Partie - Contenu ↓↓ */
            this.getContentSwiper()  
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    parcelIdSelected: lodashGet(state, 'contextAppData.parcelIdSelected', -1),

    parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
});

export default connect( mapStateToProps, null )(ControlsMapsModulation);