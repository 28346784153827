import React from 'react';
import { IconButton } from '@mui/material';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

/**
 * classe en lien avec le bouton + fonctionnel de la géolocalisation
 */
class ButtonGeolocation extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            isGeolocationOn: false, // la géolocalisation est elle activée ?
        }

        // ↓↓ BIND ↓↓
        this.handleClickGeolocationButton = this.handleClickGeolocationButton.bind(this);
        this.startGeolocation = this.startGeolocation.bind(this);
        this.stopGeolocation = this.stopGeolocation.bind(this);
        this.onLocationFound = this.onLocationFound.bind(this);
        this.onLocationError = this.onLocationError.bind(this);

        //↓↓ DONNEES - géolocalisation ↓↓
        this.geolocationLatLng = null; // position exacte GPS
    }

    /* cycle de vie react.js */
    componentWillUnmount() {
        this.stopGeolocation(); // ⚠️ - car géolocalisation non fonctionnelle si changement de page lorsque elle est en marche
    }

    /**
     * fonction callback lors du clique du bouton de géolocalisation 
     */
    handleClickGeolocationButton(event) {
        if (event) event.stopPropagation(); // permet d'éviter que l'événement clique ne soit remonté à la carte (évite de zoomer)

        if (!this.state.isGeolocationOn) 
            this.startGeolocation();
        else 
            this.stopGeolocation();
    }

    /**
     * fonction permettant d'activer la géolocalisation
     */
    startGeolocation() {

        // ↓ on cable les différents listeners ↓
        this.props.renderMapsCmp.map.on('locationfound', this.onLocationFound);
        this.props.renderMapsCmp.map.on('locationerror', this.onLocationError);

        this.props.renderMapsCmp.map.locate({
            watch: true, //watch => starts continous watching of location changes
            timeout: 60000,
            enableHighAccuracy: true
        }); 

        this.setState({isGeolocationOn:true});
    }

    /**
     * fonction permettant de stopper la géolocalisation
     */
    stopGeolocation() {
        this.props.renderMapsCmp.map.stopLocate(); // fin de la géolocalisation

        // ↓ on décable les différents listeners ↓
        this.props.renderMapsCmp.map.off('locationfound', this.onLocationFound);
        this.props.renderMapsCmp.map.off('locationerror', this.onLocationError);
        this.geolocationLatLng = null;

        this.setState({isGeolocationOn: false});
    }

    /**
     * fonction executée lorsqu'une position GPS a été trouvée
     */
    onLocationFound(e) {
        try {
            this.geolocationLatLng = e.latlng; 
            //↓↓ on zoome sur le point ↓↓
            this.props.renderMapsCmp.map.setView(this.geolocationLatLng, 15);
            this.stopGeolocation(); // on désactive la géolocalisation une fois la position trouvée
        } catch (error) {}
    }

    /**
     * fonction executée lors d'une erreur liée à la géolocalisation
     */
    onLocationError(error) {
        this.stopGeolocation(); // on stoppe la géolocalisation
    }

    /* fonction cycle de vie */
    render() {
        const { isGeolocationOn } = this.state;

        return (
            <IconButton 
                aria-label="directions"
                onClick={this.handleClickGeolocationButton}
            >
                <GpsFixedIcon color={(isGeolocationOn) ? "secondary" : "primary"} />
            </IconButton>
        )
    }
}

export default ButtonGeolocation;