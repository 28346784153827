import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import getTheme from '../../themes/index.js';

let theme = getTheme();

function FeuilleSpotifarmLogoCouleur(props: SvgIconProps) {
    return ( 
        <SvgIcon viewBox="0 0 500 500" {...props}>
            <svg height="500" viewBox="0 0 500 500" width="500" xmlns="http://www.w3.org/2000/svg">
                <path d="M 295,490 Q -40,520,160,5 L 210,190,215,405,260,370,295,490 Z " fill={theme.palette.secondary.main} strokeWidth="0.0"/>
                <path d="M 160,5 Q 580,345,295,490 L 305,280,255,330,210,190,160,5 Z " fill={theme.palette.secondary.dark} strokeWidth="0.0"/>
                <path d="M 210,190 L 210,190,215,405,260,370,295,490 L 305,280,255,330,210,190,210,190 Z" fill={theme.palette.common.white} strokeWidth="0.0"/>
            </svg>
        </SvgIcon>
    );
}

export default FeuilleSpotifarmLogoCouleur;