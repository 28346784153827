import React from "react";
import { Box, DialogContentText } from "@mui/material";
import stepsuccessvg from '../../../assets/images/didacticiel/step_succes.svg';
import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';

/**
 * Composant représentant l'étape de succès lors du didacticiel firstparcel
 */
function StepSuccess(props) {

    return (
        <>
            <DialogContentText>{StringTranslate.successsubtitle}</DialogContentText>

            <Box sx={{ marginTop: '20px', marginBottom: '20px' }} className='dfp-content-welcome'>
                <img src={stepsuccessvg} alt="success" className="dfp-img-welcome" />
            </Box>

            <DialogContentText>{StringTranslate.successcontent}</DialogContentText>

            <Box sx={{ marginTop: '10px' }}>
                <DialogContentText><span role="img" aria-label="img-subcontent1">🌱</span> {StringTranslate.successsubcontent1}</DialogContentText>
                <DialogContentText><span role="img" aria-label="img-subcontent2">🌾</span> {StringTranslate.successsubcontent2}</DialogContentText>
                <DialogContentText><span role="img" aria-label="img-subcontent3">🚨</span> {StringTranslate.successsubcontent3}</DialogContentText>
                <DialogContentText><span role="img" aria-label="img-subcontent4">💚</span> {StringTranslate.successsubcontent4}</DialogContentText>
                <DialogContentText><span role="img" aria-label="img-subcontent5">🌽</span> {StringTranslate.successsubcontent5}</DialogContentText>
            </Box>
        </>
    )
}

export default StepSuccess;