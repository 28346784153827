import { Component } from 'react';
import { Grid } from "@mui/material";
import FeuilleSpotifarmLogoCouleur from "./FeuilleSpotifarmLogoCouleur";
import '../../../src/index.css';
import getTheme from '../../themes/index';

let theme = getTheme();

class LogoSpotifarmTitreEtFeuille extends Component {
    state = {  } 
    render() { 
        return (
            <Grid container sx={{pt:"12px"}} justifyContent="center">
                <Grid item>
                    <FeuilleSpotifarmLogoCouleur fontSize='large'/>
                </Grid>
                <Grid item>
                    <div 
                        className="spotifont"
                        style={{
                            background: `linear-gradient(90deg, ${theme.palette.secondary.dark} 37%, ${theme.palette.secondary.main} 100%)`,
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent"
                        }}
                    >
                        Spotifarm
                    </div>
                </Grid>
            </Grid>
        );
    }
}
 
export default LogoSpotifarmTitreEtFeuille;