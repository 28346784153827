import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


function ScrollWheel(props: SvgIconProps) {
    return ( 
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <svg width="32" height="32" strokeWidth="1.8" stroke="none" fill="current">
              <path d="M16.1,26.47a5.74,5.74,0,0,1-5.73-5.74V10.87a5.74,5.74,0,1,1,11.47,0v9.86A5.75,5.75,0,0,1,16.1,26.47Zm0-19.92a4.33,4.33,0,0,0-4.32,4.32v9.86a4.32,4.32,0,1,0,8.64,0V10.87A4.32,4.32,0,0,0,16.1,6.55Z" fill="current" />
              <path d="M18.23,12.31H14a.71.71,0,1,1,0-1.42h4.25a.71.71,0,0,1,0,1.42Z" fill="current" />
              <path d="M18.23,15.14H14a.71.71,0,0,1,0-1.42h4.25a.71.71,0,0,1,0,1.42Z" fill="current" />
              <path d="M18.23,18H14a.71.71,0,0,1,0-1.42h4.25a.71.71,0,0,1,0,1.42Z" fill="current" />
              <path d="M18.23,20.8H14a.71.71,0,0,1-.71-.71.7.7,0,0,1,.71-.7h4.25a.7.7,0,0,1,.71.7A.71.71,0,0,1,18.23,20.8Z" fill="current" />
              <g>
                <path d="M5.85,11.79,3,16.68H5.15v3.23a.7.7,0,0,0,.7.71.71.71,0,0,0,.71-.71V16.68H8.68Z" fill="current" />
                <path d="M26.85,15V11.79a.71.71,0,1,0-1.41,0V15H23.32l2.82,4.88L29,15Z" fill="current" />
              </g>
            </svg>
        </SvgIcon>
    );
}

export default ScrollWheel;