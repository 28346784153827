import { ActionTypeParcels } from '../actions/parcels.js';
import { ParcelsHelper } from '../../utils/parcelsHelper.js';
import { ChangeType, ReducerType, ChangeAction } from '../../models/changeAction.js';
import { ConstantsLayers } from '../../utils/constantsOfLayers.js';
import { GeneratePathOfThumbnailParcel } from '../../components/thumbnail/ThumbnailParcelShape.jsx';

/* state initial */
const initialState = {
    loading: false, //permettra de mettre une waiting cursor dans le tableau et le sidebar...
    updating: false, //permettra de mettre une waiting cursor dans la carte...
    deleting: false, //pour la waiting cursor dans l'écran de rendu du tableau des parcelles'.

    updatingParcelName: false, // lors de l'enregistrement du nom d'une parcelle
    errorUpdateParcelName: null, // erreur relevée lors de l'enregistrement du nouveau nom d'une parcelle

    updatingParcel: false, // lors de l'enregistrement/modification des champs d'une parcelle
    errorUpdateParcel: null, // erreur relevée lors de l'enregistrement/modification des champs d'une parcelle

    isDrawingParcel: false, //permet de savoir si on est en train de dessiner une parcelle ou non
    savingDrawnParcel: false, //permettra de mettre une waiting cursor (ex: création parcelle dessinée)
    loadingImportedOrDrawnParcels: false, //permettra de mettre un waiting cursor lors d'un import de parcelles ou de chargement d'images satellite à la création ou au clic d'une parcelle 
    
    parcelDico: {}, // dico { parcelId: <Parcel>, ... } !!! liste complète des entités POCO reçues de la Web API !!! 
    parcelDicoCounter: 0, // nombre total de parcelle
    lastParcelDicoAction: null, // dernière opération effectuée sur le dico parcelDico ; Typée 'ChangeAction'
    lastParcelDicoActionForModulation: null, // dernière opération effectuée sur le dico parcelDico ; Typée 'ChangeAction'

    loadingParcelsArchivedForFertilizer: false, // Permettra de savoir si le chargement de parcelles archivées pour la fumure est en cours de traitements
    parcelsArchivedForFertilizerLoaded: false,
    parcelArchivedForFertilizerDico: {}, //Dico des parcelles qui ont été archivées par rapport à la fumure. Chaque parcelle archivée a une fumure archivée
    parcelArchivedForFertilizerDicoCounter: 0, //Nombre de parcelles archivées dans le dico

    parcelIdFilteredList: [], // liste des ids de parcelles filtrées 
    parcelIdFilteredListCounter: 0, // nombre de parcelles filtrées
    
    thumbnailParcelDico: {}, // dico { parcelId: <ThumbnailInfos>, ... } !!! liste complète des infos utiles pour la production des miniatures de contour de parcelles dont le 'path' du SVG !!! 
    thumbnailParcelArchivedForFertilizerDico: {},

    errorMessage: undefined, // message d'erreur 

    hasDeletedAllParcels: false,

    parcelTab: 0, // l'index à afficher dans le menu parcel (0=parcelles, 1=modélisation)
}

function parcelsManagerAction(state = initialState, action) {
    switch (action.type) {
        case ActionTypeParcels.LoadingParcels: {
            return Object.assign({}, state, {
                loading: true,
                parcelDico: {}, //Je suis tenter de laisser la liste telle qu'elle est ! Un processus de suppression devrait la vider avant. Mais par sécurité, je le fais.
                parcelDicoCounter: 0,
                thumbnailParcelDico: {}, // dico !!!
                loadingImportedOrDrawnParcels: action.loadingImportedOrDrawnParcels
            });
        }

        case ActionTypeParcels.UploadedParcels: {
            return Object.assign({}, state, {
                loadingImportedOrDrawnParcels: false
            });
        }
            
        case ActionTypeParcels.DeletingParcels: {
            return Object.assign({}, state, {
                deleting: true,
            });
        }

        case ActionTypeParcels.DrawingParcel: {
            return Object.assign({}, state, {
                isDrawingParcel: action.isDrawingParcel,
            });
        }
            
        /*case ActionTypeParcels.ParcelsLoaded: { //fait dans le cas 'LoadParcelAndAddContextDico'
            return Object.assign({}, state, {
                loading: false,
                parcelDico: action.parcelDico,
                parcelDicoCounter: ParcelsHelper.count(action.parcelDico),
                thumbnailParcelDico: ...
            });
        }*/
            
        case ActionTypeParcels.DeleteParcels: { 
            return Object.assign({}, state, {
                deleting: false,
                parcelDico: {}, 
                parcelDicoCounter: 0, 
                parcelIdFilteredList: [], 
                parcelIdFilteredListCounter: 0,
                thumbnailParcelDico: {}, // dico !!!
                lastParcelDicoAction: null, 
                errorMessage: undefined,
                hasDeletedAllParcels: true,
            });
        }

        case ActionTypeParcels.DeleteParcelIdList: { 

            // ↓ mise à jour du dico de parcelle et de la liste de parcelId filterée ↓
            action.parcelIdList.forEach( parcelId => {
                delete state.parcelDico[parcelId]; // on supprime l'entrée depuis le dico de parcelle
                state.parcelIdFilteredList = state.parcelIdFilteredList.filter( id => parcelId !== id ); // on met à jour la liste filtrée
                delete state.thumbnailParcelDico[parcelId]; // on supprime l'entrée depuis le dico de miniature !!!
            });

            return Object.assign({}, state, {
                ...state,
                parcelDico: {
                    ...state.parcelDico
                }, 
                parcelDicoCounter: state.parcelDicoCounter - action.parcelIdListCounter, 
                parcelIdFilteredList: [
                    ...state.parcelIdFilteredList
                ], 
                thumbnailParcelDico: {
                    ...state.thumbnailParcelDico
                }, 
                parcelIdFilteredListCounter: state.parcelIdFilteredList.length, 
                lastParcelDicoAction: null, 
                hasDeletedAllParcels: (state.parcelDicoCounter - action.parcelIdListCounter) <= 0
            });
        }

        case ActionTypeParcels.DeleteParcelArchivedIdList: { 

            //#region parcelle archivée dans dico
            let newParcelsArchivedForFertilizerDico = {};

            let parcelsArchivedForFertilizerDico = state.parcelArchivedForFertilizerDico;
            let parcelArchivedForFertilizerDicoCounter = state.parcelArchivedForFertilizerDicoCounter;

            if (parcelsArchivedForFertilizerDico && (parcelArchivedForFertilizerDicoCounter > 0) &&
                (action.parcelIdList !== undefined) && (action.parcelIdList.length > 0)) {
                const counterDeleteToGo = action.parcelIdList.length;
                for (const key in parcelsArchivedForFertilizerDico) {
                    const parcelArchived = parcelsArchivedForFertilizerDico[key];
                    if (parcelArchived) {
                        let isFound = false;

                        for (let indexI = 0; indexI < counterDeleteToGo; indexI++) {
                            let parcelId = action.parcelIdList[indexI];
                            if (parcelArchived.id === parcelId) {
                                isFound = true;
                                break; //sort de la boucle puisqu'on l'a trouvé !                        
                            } //else //passe à la comparaison suivante !
                        }
                        if (isFound === false) { //pas trouvé dans la liste de celle à supprimer => on la conserve !
                            newParcelsArchivedForFertilizerDico[parcelArchived.id] = parcelArchived;
                        }
                    }
                }
            } else {
                newParcelsArchivedForFertilizerDico = parcelsArchivedForFertilizerDico; //Ne change rien !
            }
            //#endregion

            //#region thumbnail archivé dans dico
            let newThumbnailParcelArchivedForFertilizerDico = {};

            let thumbnailParcelArchivedForFertilizerDico = state.thumbnailParcelArchivedForFertilizerDico;

            if (thumbnailParcelArchivedForFertilizerDico && (state.thumbnailParcelArchivedForFertilizerDico.length > 0) &&
                (action.parcelIdList !== undefined) && (action.parcelIdList.length > 0)) {
                    const counterDeleteToGo = action.parcelIdList.length;
                    for (const key in thumbnailParcelArchivedForFertilizerDico) {
                        const thumbnailParcelArchived = thumbnailParcelArchivedForFertilizerDico[key];
                        if (thumbnailParcelArchived) {
                            let isFound = false;

                            for (let indexI = 0; indexI < counterDeleteToGo; indexI++) {
                                let parcelId = action.parcelIdList[indexI];
                                if (thumbnailParcelArchived.id === parcelId) {
                                    isFound = true;
                                    break; //sort de la boucle puisqu'on l'a trouvé !                        
                                } //else //passe à la comparaison suivante !
                            }
                            if (isFound === false) { //pas trouvé dans la liste de celle à supprimer => on la conserve !
                                newThumbnailParcelArchivedForFertilizerDico[thumbnailParcelArchived.id] = thumbnailParcelArchived;
                            }
                        }
                    }
                } else {
                    newThumbnailParcelArchivedForFertilizerDico = thumbnailParcelArchivedForFertilizerDico; //Ne change rien !
            }
            //#endregion

            return Object.assign({}, state, {
                ...state,
                parcelArchivedForFertilizerDico: {
                    ...newParcelsArchivedForFertilizerDico
                },
                parcelArchivedForFertilizerDicoCounter: parcelArchivedForFertilizerDicoCounter - action.parcelIdListCounter,
                thumbnailParcelArchivedForFertilizerDico: {
                    ...newThumbnailParcelArchivedForFertilizerDico
                },
            });

        }

        case ActionTypeParcels.ErrorLoadParcels: {
            return Object.assign({}, state, {
                loading: false,
                loadingImportedOrDrawnParcels: false,
                errorMessage : action.errorMessage,
            });
        }

        case ActionTypeParcels.loadParcelsWithCurrentSatimage: {
            //Mise à jour de certaines infos de chaque parcelle ET production des infos utiles à leur miniature:
            let thumbnailParcelBuilded = {};
            Object.keys(action.parcelDico).forEach((parcelId) => {
                let imageData = (action.lastSatimageByParcelIdDico && action.lastSatimageByParcelIdDico[parcelId]) ? action.lastSatimageByParcelIdDico[parcelId] : null;
                action.parcelDico[parcelId] = {
                    ...action.parcelDico[parcelId],
                    currentLayerType: ConstantsLayers.NdviParcelLayerName, // on lui affecte un type par défaut
                    currentSatimageId: (imageData && imageData.id) ? imageData.id : -1, // on affecte la nouvelle image
                    currentSatimageDate: (imageData && imageData.date && imageData.date instanceof Date) ? 
                        imageData.date : (imageData && imageData.date && (!isNaN(Date.parse(imageData.date)))) ? new Date(imageData.date) : null, //rappelle la date (pour éviter à tous de la chercher)
                    firstSatimageDate: (imageData && imageData.date && imageData.date instanceof Date) ? 
                        imageData.date : (imageData && imageData.date && (!isNaN(Date.parse(imageData.date)))) ? new Date(imageData.date) : null,
                };
                thumbnailParcelBuilded[parcelId] = GeneratePathOfThumbnailParcel(action.parcelDico[parcelId], parcelId);
            });

            return Object.assign({}, state, {
                loading: false,
                parcelDico: action.parcelDico,
                parcelDicoCounter: ParcelsHelper.count(action.parcelDico),
                parcelIdFilteredList: action.parcelIdFilteredList,
                parcelIdFilteredListCounter: (action.parcelIdFilteredList && Array.isArray(action.parcelIdFilteredList)) ? action.parcelIdFilteredList.length : 0,
                thumbnailParcelDico: thumbnailParcelBuilded,
            });
        }

        case ActionTypeParcels.LoadingParcelsArchivedForFertilizer: {
            return Object.assign({}, state, {
                loadingParcelsArchivedForFertilizer: true,
                parcelsArchivedForFertilizerLoaded: false,
                thumbnailParcelArchivedForFertilizerDico: {}
            });
        }

        case ActionTypeParcels.ErrorLoadParcelsArchivedForFertilizer: {
            return Object.assign({}, state, {
                loadingParcelsArchivedForFertilizer: false,
                parcelsArchivedForFertilizerLoaded: false,
                errorMessage: action.errorMessage
            });
        }

        case ActionTypeParcels.LoadParcelsArchivedForFertilizer: {
            let thumbnailParcelBuilded = {};
            Object.keys(action.parcelArchivedForFertilizerDico).forEach((parcelId) => {
                thumbnailParcelBuilded[parcelId] = GeneratePathOfThumbnailParcel(action.parcelArchivedForFertilizerDico[parcelId], parcelId);
            });

            return Object.assign({}, state, {
                parcelArchivedForFertilizerDico: action.parcelArchivedForFertilizerDico,
                parcelArchivedForFertilizerDicoCounter: ParcelsHelper.count(action.parcelArchivedForFertilizerDico),
                loadingParcelsArchivedForFertilizer: false,
                parcelsArchivedForFertilizerLoaded: true,
                thumbnailParcelArchivedForFertilizerDico: thumbnailParcelBuilded,
                errorMessage: undefined
            });
        }

        case ActionTypeParcels.LoadParcels: {
            //Production des infos utiles à la miniature de chaque parcelle:
            let thumbnailParcelBuilded = {};
            Object.keys(action.parcelDico).forEach((parcelId) => {
                thumbnailParcelBuilded[parcelId] = GeneratePathOfThumbnailParcel(action.parcelDico[parcelId], parcelId);
            });

            return Object.assign({}, state, {
                loading: false,
                parcelDico: action.parcelDico,
                parcelDicoCounter: ParcelsHelper.count(action.parcelDico),
                parcelIdFilteredList: action.parcelIdFilteredList,
                parcelIdFilteredListCounter: (action.parcelIdFilteredList && Array.isArray(action.parcelIdFilteredList)) ? action.parcelIdFilteredList.length : 0,
                thumbnailParcelDico: thumbnailParcelBuilded,
            });
        }

        case ActionTypeParcels.SavingDrawnParcel: {
            return {
                ...state,
                savingDrawnParcel: true,
                loadingImportedOrDrawnParcels: true
            }
        }

        /* action d'ajout d'une nouvelle parcelle dessinée */
        case ActionTypeParcels.AddDrawnParcel: {
            return {
                ...state,
                savingDrawnParcel: false,
                parcelDico: {
                    ...state.parcelDico,
                    [action.parcelId]: action.parcel
                },
                parcelDicoCounter: state.parcelDicoCounter + 1,
                parcelIdFilteredList: [ //@@ voir si on applique le filtre ??
                    ...state.parcelIdFilteredList,
                    action.parcelId
                ],
                parcelIdFilteredListCounter: state.parcelIdFilteredListCounter + 1,
                thumbnailParcelDico: {
                    ...state.thumbnailParcelDico,
                    [action.parcelId]: GeneratePathOfThumbnailParcel(action.parcel, action.parcelId),
                },
                lastParcelDicoAction: new ChangeAction(ChangeType.ADD, ReducerType.PARCEL, ActionTypeParcels.AddDrawnParcel, { parcelId: action.parcelId, parcel: action.parcel}),
            };
        }

        case ActionTypeParcels.askTotalCurrentMonthHistoOfParcel: {
            return {
                ...state,
                parcelDico: {
                    ...state.parcelDico,
                    [action.parcelId]: {
                        ...state.parcelDico[action.parcelId],
                        alreadyAskAllImagesForThisMonth: action.alreadyAskAllImagesForThisMonth,
                    }
                },
            }
        }

        case ActionTypeParcels.SetSatimageIdToParcel: {
            return {
                ...state,
                parcelDico: {
                    ...state.parcelDico,
                    [action.parcelId]: {
                        ...state.parcelDico[action.parcelId],
                        currentLayerType: ConstantsLayers.NdviParcelLayerName, // on lui affecte un type par défaut
                        currentSatimageId: action.satimageId, // on affecte la nouvelle image
                        currentSatimageDate: (action.satimageDate instanceof Date) ? action.satimageDate : (!isNaN(Date.parse(action.satimageDate))) ? new Date(action.satimageDate) : null, //rappelle la date (pour éviter à tous de la chercher)
                        firstSatimageDate: (action.satimageDate instanceof Date) ? action.satimageDate : (!isNaN(Date.parse(action.satimageDate))) ? new Date(action.satimageDate) : null,
                    }
                },
                lastParcelDicoAction: new ChangeAction(ChangeType.ADD, ReducerType.PARCEL, ActionTypeParcels.SetSatimageIdToParcel, { parcelId: action.parcelId, satimageId: action.satimageId, layerType: ConstantsLayers.NdviParcelLayerName }),
            }
        }

        case ActionTypeParcels.ChangeSatimageIdToParcel: {
            const parcelData = state.parcelDico[action.parcelId];
            
            return {
                ...state,
                parcelDico: {
                    ...state.parcelDico,
                    [action.parcelId]: {
                        ...parcelData,
                        //RQ: Ne change pas le layer actuel !
                        currentSatimageId: action.satimageId, // on affecte la nouvelle image
                        currentSatimageDate: (action.satimageDate instanceof Date) ? action.satimageDate : (!isNaN(Date.parse(action.satimageDate))) ? new Date(action.satimageDate) : null, //rappelle la date (pour éviter à tous de la chercher)
                    }
                },
                lastParcelDicoAction: new ChangeAction(ChangeType.UPDATE, ReducerType.PARCEL, ActionTypeParcels.ChangeSatimageIdToParcel, { parcelId: action.parcelId, satimageId: action.satimageId, layerType: parcelData.currentLayerType }),
            };
        }

        case ActionTypeParcels.UpdateParcelIdFiltered: {
            return {
                ...state,
                parcelIdFilteredList: action.parcelIdFilteredList,
                parcelIdFilteredListCounter: (action.parcelIdFilteredList && Array.isArray(action.parcelIdFilteredList)) ? action.parcelIdFilteredList.length : 0,
            };
        }

        case ActionTypeParcels.SelectLayerOfParcel: {
            let newLayertypeValue = action.layertype;
            if ((newLayertypeValue !== ConstantsLayers.NdviParcelLayerName) && (newLayertypeValue !== ConstantsLayers.VisibleParcelLayerName)) {
                newLayertypeValue = ConstantsLayers.NdviParcelLayerName;
            };
    
            return {
                ...state,
                parcelDico: {
                    ...state.parcelDico,
                    [action.parcelId]: {
                        ...state.parcelDico[action.parcelId],
                        currentLayerType: newLayertypeValue,
                    }
                },
                lastParcelDicoAction: new ChangeAction(ChangeType.UPDATE, ReducerType.PARCEL, ActionTypeParcels.SelectLayerOfParcel, { parcelId: action.parcelId, layerType: newLayertypeValue }),
            };
        }

        case ActionTypeParcels.SelectLayerOfParcelForModulation: {
            let newLayertypeValue = action.layertypeForModulation;
            if ((newLayertypeValue !== ConstantsLayers.NdviParcelLayerName) && (newLayertypeValue !== ConstantsLayers.VisibleParcelLayerName)) {
                newLayertypeValue = ConstantsLayers.NdviParcelLayerName;
            };
    
            return {
                ...state,
                parcelDico: {
                    ...state.parcelDico,
                    [action.parcelId]: {
                        ...state.parcelDico[action.parcelId],
                        currentLayerTypeForModulation: newLayertypeValue,
                    }
                },
                lastParcelDicoActionForModulation: new ChangeAction(ChangeType.UPDATE, ReducerType.PARCEL, ActionTypeParcels.SelectLayerOfParcel, { parcelId: action.parcelId, layerType: newLayertypeValue }),
            };
        }

        case ActionTypeParcels.UpdatingParcelName: {
            return {
                ...state,
                updatingParcelName: true,
            }
        }

        case ActionTypeParcels.UpdateParcelName: {
            return {
                ...state,
                updatingParcelName: false,
                parcelDico: {
                    ...state.parcelDico,
                    [action.parcelId]: {
                        ...state.parcelDico[action.parcelId],
                        name: action.name
                    }
                },
                // lastParcelDicoAction: new ChangeAction(ChangeType.UPDATE, ReducerType.PARCEL, ActionTypeParcels.UpdatingParcelName, { parcelId: action.parcelId }),
            }
        }

        case ActionTypeParcels.UpdateParcelCulture: {
            return Object.assign({}, state, {
                ...state,
                parcelDico: {
                    ...state.parcelDico,
                    [action.parcelId]: {
                        ...state.parcelDico[action.parcelId],
                        details: {
                            culture: action.culture
                        }
                    }
                },
            });
        }

        case ActionTypeParcels.UpdateParcelLocalisation: {
            return Object.assign({}, state, {
                ...state,
                parcelDico: {
                    ...state.parcelDico,
                    [action.parcelId]: {
                        ...state.parcelDico[action.parcelId],
                        localisation: action.newLocalisation, 
                    }
                },
            });
        }

        case ActionTypeParcels.UpdateParcelCropYear: {
            return Object.assign({}, state, {
                ...state,
                parcelDico: {
                    ...state.parcelDico,
                    [action.parcelId]: {
                        ...state.parcelDico[action.parcelId],
                        details: {
                            ...state.parcelDico[action.parcelId].details,
                            campagne: action.cropYear
                        }
                    }
                },
            });
        }

        case ActionTypeParcels.UpdateParcelCrop: {
            return Object.assign({}, state, {
                ...state,
                parcelDico: {
                    ...state.parcelDico,
                    [action.parcelId]: {
                        ...state.parcelDico[action.parcelId],
                        details: {
                            ...state.parcelDico[action.parcelId].details,
                            culture: action.crop
                        }
                    }
                },
            });
        }

        case ActionTypeParcels.UpdateParcelVariety: {
            return Object.assign({}, state, {
                ...state,
                parcelDico: {
                    ...state.parcelDico,
                    [action.parcelId]: {
                        ...state.parcelDico[action.parcelId],
                        details: {
                            ...state.parcelDico[action.parcelId].details,
                            variete: action.variety
                        }
                    }
                },
            });
        }

        case ActionTypeParcels.UpdateParcelSowingDate: {
            return Object.assign({}, state, {
                ...state,
                parcelDico: {
                    ...state.parcelDico,
                    [action.parcelId]: {
                        ...state.parcelDico[action.parcelId],
                        details: {
                            ...state.parcelDico[action.parcelId].details,
                            dateSemi: action.sowingDate
                        }
                    }
                },
            });
        }

        case ActionTypeParcels.ErrorUpdateParcelName: {
            return {
                ...state,
                updatingParcelName: false,
                errorUpdateParcelName: action.error,
            }
        }

        case ActionTypeParcels.UpdateParcelDico: {
            return {
                ...state,
                parcelDico: action.dico,
            }
        }

        case ActionTypeParcels.UpdatingParcel: {
            return {
                ...state,
                updatingParcel: true,
            }
        }

        case ActionTypeParcels.UpdateParcel: {
            return {
                ...state,
                updatingParcel: false,
                parcelDico: {
                    ...state.parcelDico,
                    [action.parcelId]: action.parcel,
                },
               
            }
        }

        case ActionTypeParcels.UpdateParcelsAfterBiomassesDeleted: {
            
            action.biomassesDeleted.forEach(item => {
                if ((state.parcelDico[item.parcelId] !== undefined) && (state.parcelDico[item.parcelId] !== null)) {

                    if (item.isBeginningWinter === true) {
                        state.parcelDico[item.parcelId].imagSourceDateBeginningWinter = undefined;
                    }
                    else {
                        state.parcelDico[item.parcelId].imagSourceDateEndingWinter = undefined;
                    }
                }
            });

            return Object.assign({}, state, {
                parcelDico: state.parcelDico
            });
        }

        case ActionTypeParcels.UpdateParcelAfterBiomassAdded: {
            //TODO : On ne touche qu'au date ? (pas au provider, ni à l'ID)
            if (action.biomass.parameter.isBeginningWinter === true) {
                state.parcelDico[action.biomass.parameter.parcelId].imagSourceDateBeginningWinter = action.biomass.parameter.dateImagSource;
            }
            else {
                state.parcelDico[action.biomass.parameter.parcelId].imagSourceDateEndingWinter = action.biomass.parameter.dateImagSource;
            }

            return Object.assign({}, state, {
                parcelDico: state.parcelDico
            });
        }

        case ActionTypeParcels.ErrorUpdateParcel: {
            return {
                ...state,
                updatingParcel: false,
                errorUpdateParcel: action.error,
            }
        }

        case ActionTypeParcels.updateParcelHasModulation: {
            return {
                ...state, 
                parcelDico: {
                    ...state.parcelDico,
                    [action.parcelId]: {
                        ...state.parcelDico[action.parcelId],
                        hasModulation: action.hasModulation,
                    }
                }
            }
        }

        case ActionTypeParcels.setParcelTab: {
            return {
                ...state,
                parcelTab: action.indexTab,
            }
        }

        default:
            return state;
    }
}

export default parcelsManagerAction;
