import React from 'react';

import {
    Typography, Grid, Avatar, Tabs, Tab, Accordion, AccordionDetails,
    AccordionSummary
} from '@mui/material';

import CreateIcon from '@mui/icons-material/Create';
import HelpIcon from '@mui/icons-material/Help';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { StepEnumDidacticielFirstParcel } from '../../../redux/actions/didacticiel';
import CustomIFrame from '../../customIFrame.jsx';

import AbstractParcels from '../../../views/parcels/abstractParcels.jsx';
import { TabPanel, buildPropsForTab } from '../../tabPanel';

import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';
import { ConstantsTutorialHelps } from '../../../utils/constantsTutorialHelps';
import StepDessin from '../../../assets/images/didacticiel/step_dessin.tsx';

import "../../../assets/css/dfp-didacticielFirstParcel.css";

import getTheme from "../../../themes/index.js";

let theme = getTheme();


/**
 * Composant affichage de la partie Import lors du didacticiel firstparcel
 */
export function StepImport(props) {

    let constUrlLangage = ConstantsTutorialHelps.SubUrlLangageFrFr;

    constUrlLangage = StringTranslate.getLanguage() === 'es-ES' ?
        ConstantsTutorialHelps.SubUrlLangageEsEs :
        (StringTranslate.getLanguage() === 'en-GB' ?
            ConstantsTutorialHelps.SubUrlLangageEnGb : ConstantsTutorialHelps.SubUrlLangageFrFr);

    const [tab, setTabValue] = React.useState(0);
    const [expanded, setExpanded] = React.useState(false);
    const [helpExport, setHelpExport] = React.useState(0);
    const [titlePanelPasDeFichier, setTitlePanel1] = React.useState(StringTranslate.importhelptitlepasdefichier);

    /* Changement d'affichage d'aides */
    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        if (panel === 'panel1' && isExpanded) {
            setTitlePanel1(StringTranslate.importhelppasdefichiercontent);
        }
        else {
            setTitlePanel1(StringTranslate.importhelptitlepasdefichier);
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleHelp = () => {
        setTabValue(1);
    };

    /* Redirection vers l'étape dessin du didacticiel FirstParcel */
    const goToStepDessin = () => {
        props.goToStep(StepEnumDidacticielFirstParcel.DESSIN);
    }

    /* Redirection vers l'étape de succès du didacticiel FirstParcel */
    /* const goToStepSucces = () => {
        props.goToStep(StepEnumDidacticielFirstParcel.INDICE);
    } */

    const openHelpExport = (event, newValue) => {
        setHelpExport(newValue);
    }

    const getHeightExportMesparcelles = (height) => {
        if (height > 0 && height <= 568) {
            return '3000px';
        }
        else if (height > 568 && height <= 640) {
            return '2400px';
        }
        else if (height > 640 && height <= 653) {
            return '3800px';
        }
        else if (height > 653 && height <= 667) {
            return '2300px';
        }
        else if (height > 667 && height <= 736) {
            return '2000px';
        }
        if (height > 736 && height <= 812) {
            return '2200px';
        }
        if (height > 812 && height <= 823) {
            return '2000px';
        }
        if (height > 823 && height <= 882) {
            return '650px';
        }
        else return '1200px';
    }

    const getHeightExportGeofolia = (height) => {
        if (height > 0 && height <= 568) {
            return '800px';
        }
        else if (height > 568 && height <= 640) {
            return '730px';
        }
        else if (height > 640 && height <= 653) {
            return '920px';
        }
        else if (height > 653 && height <= 667) {
            return '770px';
        }
        else if (height > 667 && height <= 736) {
            return '770px';
        }
        if (height > 736 && height <= 812) {
            return '700px';
        }
        if (height > 812 && height <= 823) {
            return '700px';
        }
        else return '620px';
    }

    const getHeightExportTelepac = (height) => {
        if (window.innerHeight > 0 && window.innerHeight <= 568) {
            return '2020px';
        }
        else if (window.innerHeight > 568 && window.innerHeight <= 640) {
            return '1720px';
        }
        else if (window.innerHeight > 640 && window.innerHeight <= 653) {
            return '1520px';
        }
        else if (window.innerHeight > 653 && window.innerHeight <= 667) {
            return '1720px';
        }
        else if (window.innerHeight > 667 && window.innerHeight <= 736) {
            return '1520px';
        }
        if (window.innerHeight > 736 && window.innerHeight <= 812) {
            return '1720px';
        }
        if (window.innerHeight > 812 && window.innerHeight <= 823) {
            return '1520px';
        }
        else return '1020px';
    }

    const getHeightImportFile = (height) => {
        if (window.innerHeight > 0 && window.innerHeight <= 568) {
            return '1320px';
        }
        else if (window.innerHeight > 568 && window.innerHeight <= 640) {
            return '1220px';
        }
        else if (window.innerHeight > 640 && window.innerHeight <= 653) {
            return '1620px';
        }
        else if (window.innerHeight > 653 && window.innerHeight <= 667) {
            return '1120px';
        }
        else if (window.innerHeight > 667 && window.innerHeight <= 736) {
            return '1020px';
        }
        if (window.innerHeight > 736 && window.innerHeight <= 812) {
            return '1120px';
        }
        if (window.innerHeight > 812 && window.innerHeight <= 823) {
            return '1020px';
        }
        else return '620px';
    }

    return (
        <div id="import">

            <Tabs
                variant_pz="row"
                value={tab}
                variant="scrollable"
                onChange={handleTabChange}
                scrollButtons={true}
            >
                <Tab to="#" icon={<CreateIcon sx={{ fontSize: '1.3rem' }} />} 
                    label={`${StringTranslate.importmenusaisie}`}
                    {...buildPropsForTab('menuTutoFp', 0)}
                />
                <Tab to="#" icon={<HelpIcon sx={{ fontSize: '1.3rem' }} />}
                    label={`${StringTranslate.importmenuaide}`}
                    {...buildPropsForTab('menuTutoFp', 1)}
                />

            </Tabs>
            <TabPanel value={tab} index={0} prefixId='menuTutoFp'>

                {/* page Import de parcelles du stepImport */}
                <AbstractParcels isDidacticiel={true} {...props} />

                {/* Direction vers la page Aide du stepImport */}
                <Grid container spacing={2} sx={{ marginTop: '10px' }}>
                    <Grid item xs={2} sm={1} md={1}>
                        <Avatar
                            variant="rounded"
                            color="inherit"
                            sx={{ bgcolor: theme.palette.primary, ml: 'auto' }}
                            onClick={handleHelp}>
                            <HelpIcon color="white" />
                        </Avatar>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography variant="h4" sx={{ mb: 0 }}>
                            {`${StringTranslate.importmenuaide}`}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            {StringTranslate.aideimport}
                        </Typography>
                    </Grid>
                </Grid>

                {/* Direction vers l'étape de dessin */}
                <Grid container spacing={2} sx={{ marginTop: '10px' }} >
                    <Grid item xs={2} sm={1} md={1}>
                        <Avatar
                            variant="rounded"
                            color="inherit"
                            sx={{ bgcolor: theme.palette.primary, ml: 'auto' }}
                            onClick={goToStepDessin}>
                            <StepDessin sx={{ fontSize: '30px'}}/>
                        </Avatar>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography variant="h4" sx={{ mb: 0 }}>
                            {`${StringTranslate.choixdrawtitle}`}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            {StringTranslate.aideimportredirect}
                        </Typography>
                    </Grid>
                </Grid>

            </TabPanel>

            {/* Menu Aide du stepImport */}
            <TabPanel value={tab} index={1} prefixId='menuTutoFp' scroll="paper">

                {/* 1er accordéon */}
                <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')} sx={{ padding: "0px" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography>{titlePanelPasDeFichier}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <Tabs
                                value={helpExport}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={openHelpExport}
                            >
                                <Tab label="Mesp@rcelles" {...buildPropsForTab('smSourceTutoFp', 0)} />
                                {(props.languageSetting !== 'en-GB') && <Tab label="Geofolia" {...buildPropsForTab('smSourceTutoFp', 1)} />}
                                <Tab label="Telepac" {...buildPropsForTab('smSourceTutoFp', 2)} />

                            </Tabs>
                            <TabPanel value={helpExport} index={0} prefixId='smSourceTutoFp'>
                                <CustomIFrame url={ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangage + ConstantsTutorialHelps.SubUrlHelpExportMesparcelles} height={getHeightExportMesparcelles(window.innerHeight)} />
                            </TabPanel>
                            <TabPanel value={helpExport} index={1} prefixId='smSourceTutoFp'>
                                <CustomIFrame url={ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangage + ConstantsTutorialHelps.SubUrlHelpExportGeofolia} height={getHeightExportGeofolia(window.innerHeight)} />
                            </TabPanel>
                            <TabPanel value={helpExport} index={2} prefixId='smSourceTutoFp'>
                                <CustomIFrame url={ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangage + ConstantsTutorialHelps.SubUrlHelpExportTelepac} height={getHeightExportTelepac(window.innerHeight)} />
                            </TabPanel>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                {/* 2eme accordéon */}
                <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccordion('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <Typography>{StringTranslate.importhelptitleimport}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CustomIFrame url={ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangage + ConstantsTutorialHelps.SubUrlHelpImport} height={getHeightImportFile(window.innerHeight)} />
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                {/* 3eme accordéon */}
                <Accordion expanded={expanded === 'panel3'} onChange={handleChangeAccordion('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <Typography>{StringTranslate.importhelptitleautreprobleme}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CustomIFrame url={ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangage + ConstantsTutorialHelps.SubUrlHelpContactForm + "#"} height={getHeightImportFile(window.innerHeight)} />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </TabPanel>
        </div>
    );
}

export default StepImport;
