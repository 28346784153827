const MaxSize = 1000; // taille maximum en pixel (hauteur et largeur)
const NbMaxImages = 3; // nombre maximal d'images que peut contenir une observation
const LienPlantnet = "https://my.plantnet.org/"; //lien vers le site plantnet

const ConstantsObservation = {
    MaxSize: MaxSize,
    NbMaxImages: NbMaxImages,
    LienPlantnet: LienPlantnet,
}

export default ConstantsObservation;