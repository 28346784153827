import React from "react";
import { connect } from 'react-redux';

import MapInfo from '../../components/mapInfo';
import LoadingContent from '../../components/loadingContent';
import '../../assets/css/rm-render_maps.css';
import '../../assets/css/biomass.css';
import MapsBiomass from "./map/mapsBiomass";
import ControlsMapsBiomass from './map/controlsMapsBiomass';

import { Box } from "@mui/material";

/*
Composant global, affichant l'étape de choix de la date de clichet d'une parcelle pour lancer une modulation
Elle contient la carte et tous les composants s'affichant par-dessus.
*/
class MapBiomass extends React.Component {
    render() {
        const { globalLayerOnLoading } = this.props;

        return (
            <Box 
                sx={{
                    height:"100%",
                    width:"100%",
                    display:"flex",
                    flexDirection:"column"
                }}
            >

                {/* Partie haute - partie rendu cartographique */}
               <Box 
                    sx={{
                        position:"relative",
                        display:"flex",
                        flex:"1",
                        width:"100%",
                        height:"100%"
                    }} 
                > 

                    {/* ↓↓ composant d'affichage d'informations (loading, progress bar, ...) - position en rapport avec la carte (relative-absolute) ↓↓ */}
                    <MapInfo />

                    {/* ↓↓ composant carte ↓↓ */}
                    <MapsBiomass />

                 </Box>

                {/* ↓↓ Partie - CHARGEMENT EN COURS (pas de planSouscription ou bien pas encore d'informations client) - (contenu: SPINNER + fond grisé) ↓↓ */}
                {(globalLayerOnLoading === true) && <LoadingContent />}

                {/* ↓↓ Partie rétractable - zone en bas de la carte permettant l'affichage de fonctionnalités (swiper, infos, sélection fonds de carte) ↓↓ */}
                 <ControlsMapsBiomass />
            </Box>
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = function (state) {
    return {
        //Infos provenant du reducer 'contextApp':
        globalLayerOnLoading: (state && state.contextAppData) ? state.contextAppData.globalLayerOnLoading : false,
    };
}

export default connect(mapStateToProps, null)(MapBiomass);