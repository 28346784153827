import React from 'react';
import { Grid, Skeleton } from '@mui/material';


class AbonnementSkeleton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            spinningIcon: true, // affichage spinning icon
        }
    }

    render() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <Skeleton variant="rectangular" animation="wave" sx={{borderRadius:'8px', height:'400px'}}/>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Skeleton variant="rectangular" animation="wave" sx={{borderRadius:'8px', height:'400px'}}/>
                </Grid>
                {/* <Grid item xs={12} md={3}>
                    <Skeleton variant="rectangular" animation="wave" sx={{borderRadius:'8px', height:'400px'}}/>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Skeleton variant="rectangular" animation="wave" sx={{borderRadius:'8px', height:'400px'}}/>
                </Grid> */}
            </Grid>
        )
    }
}

export default AbonnementSkeleton;