import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from "react-router-dom";
import { ProductFruits } from 'react-product-fruits'; //A réactiver pour livrer l'outil de Onboarding en PROD!
import Sidebar from '../../components/sidebar.jsx';
import Header from '../../components/header.jsx';
import AbstractMaps from "../maps/abstractMaps";
import AbstractModulation from "../modulation/abstractModulation";
import AbstractParcels from "../parcels/abstractParcels.jsx";
import RenderObservations from '../observations/renderObservations.jsx';
import AbstractDidacticielFirstParcel from '../didacticiel/firstparcel/abstractfirstparcel';
import AbstractBiomass from '../biomass/abstractBiomass';
import AbstractFertilizer from '../fertilizer/abstractFertilizer';
import AbstractHarvest from '../harvest/abstractHarvest';
import { styled } from '@mui/material/styles';

import lodashGet from 'lodash/get';

import ConstantsContent from "../../utils/constantsContent";

import ConstantsHosters from '../../utils/constantsByConfig.js';

import getTheme from "../../themes/index.js";
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';


const theme = getTheme();

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({ //TODO : Ajout de commentaire ET à revoir avec SGa !
    ...theme.typography.mainContent,
    height: "fit-content",
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: `${ConstantsContent.MarginCard}px`,
            width: `calc(100% - 80px)`
        },
        [theme.breakpoints.down('md')]: {
            borderTopLeftRadius: "0px !important",
            borderTopRightRadius: "0px !important",
            marginLeft: '0px',
            width: `calc(100%)`,
        },
    }),
    ...(open && {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        [theme.breakpoints.up('md')]: {
            marginLeft: ConstantsContent.DrawerWidth,
            width: `calc(100% - 320px)`
        },
        [theme.breakpoints.down('md')]: {
            borderTopLeftRadius: "0px !important",
            borderTopRightRadius: "0px !important",
            width: `calc(100% - ${ConstantsContent.MarginCard}px)`,
            marginLeft: '0px'
        },
        [theme.breakpoints.down('sm')]: {
            borderTopLeftRadius: "0px !important",
            borderTopRightRadius: "0px !important",
            width: `calc(100% - ${ConstantsContent.MarginCard / 2}px)`,
            marginLeft: '0px'
        },
    }),
}));

/**
 * Premier élément visuel affiché après authentification de l'utilisateur
 */
class DashBoard extends React.Component {
    constructor(props) {
        super(props);

        //RAS sur le state local !

        this.productFruitsOptions = undefined;
        this.buildProductFruitsOptions(); //on va construire 'this.productFruitsOptions' qui doit correspondre à la définition de 'ProductFruitsComponentProps' !

        //Facon N°1(sur 3) de savoir si l'outil de onboarding est ok:
        window.addEventListener('productfruits_ready', function() { 
            console.log('Product Fruits is ready !');
        });
        //Facon N°2(sur 3) de savoir si l'outil de onboarding est ok:
        window.productFruitsReady = function() {
            console.log('Product Fruits est ok !');
        };
    }

    /* fonction cyclde vie react.js */
    componentDidUpdate(prevProps) {
        //RAS !!!
        
        //Facon N°3(sur 3) de savoir si l'outil de onboarding est ok:
        console.log(`PF is ready ? => ${(window.productFruitsIsReady === true) ? 'yes !' : 'no.'}`);
    }

    buildProductFruitsOptions() {
        if (ConstantsHosters.idOnboardingApp && (ConstantsHosters.idOnboardingApp !== '')) {
            const { language, clientId, masterEmailClient, clientLabelTypo, isClientGeofolia, totalCounterParcelManaged } = this.props;
            if ((!clientId) || (clientId <= 0)) {
                this.productFruitsOptions = undefined;
                return;
            }

            let completLanguage = StringTranslate.getLanguage();
            if (completLanguage && (completLanguage !== '') && (completLanguage.length >= 2)) {
                completLanguage = completLanguage.substring(0, 2);
            } else if (language && (language !== '') && (language.length >= 2)) {
                completLanguage = language.substring(0, 2);
            } else {
                completLanguage = 'fr';
            }
            
            this.productFruitsOptions = { //doit correspondre à la définition de 'ProductFruitsComponentProps' !
                workspaceCode: ConstantsHosters.idOnboardingApp, //code fournit par la plateforme, sous le compte de Promize !
                language: completLanguage, //'fr', 
                user: { //de type 'ProductFruitsUserObject' !
                    username: `${ConstantsHosters.onboardingPrefix}${clientId}`, // indispensable !!!!! on a décidé pour le moment d'y mettre l'ID du client Spotifarm !
                    email: (ConstantsHosters.onboardingWithEmail === true) ? masterEmailClient : undefined, 
                    //role?: string;
                    //signUpAt?: string;
                    //firstname?: string;
                    //lastname?: string;
                    props: { //de type 'UserCustomProps' !
                        //pour tester la récup de données pouvant être utile aux scénarios !
                        'typo': clientLabelTypo ? clientLabelTypo : '', 
                        'userPV': (isClientGeofolia !== false) ? 1 : 0, 
                        'counterParcelMng': (totalCounterParcelManaged > 0) ? totalCounterParcelManaged : 0,
                    },
                    //hmac?: {
                    //    hash: string;
                    //    expiration?: string | null | undefined;
                    //};
                    //group?: {
                    //    groupId?: string;
                    //    props?: UserGroupCustomProps;
                    //};
                },
                //dontDestroy?: boolean;
                //lifeCycle?: 'neverUnmount' | 'unmount';
                config: { //de type 'ProductFruitsInitOptions' !
                    disableLocationChangeDetection: false,
                    disableBannersAutoMargin: false,
                },
                debug: ConstantsHosters.iWantDevToolsEnabled, 
            };
        }
        //else //on n'initialise rien car on n'ajoutera pas le composant de 'ProductFruits' !
    }

    /* fonction cyclde vie react.js */
    render() {

        const { isOpenMenuBurgerDesktop } = this.props;

        this.buildProductFruitsOptions();

        return (
         
                <Switch>

                    {/* ↓ route - didacticiel première parcelle (accès restreint) ↓ */}
                    <Route path="/didacticiel/firstparcel">
                        <AbstractDidacticielFirstParcel {...this.props} />
                    </Route>

                    {/* ↓ route par défaut ↓ */}
                    <Route> 
                        <React.Fragment>

                            {/* header */}
                            <Header {...this.props} />

                            {/* sidebar */}
                            <Sidebar {...this.props} />

                            {/* Contenu central */}
                            <Main theme={theme} open={isOpenMenuBurgerDesktop}>
                           
                                <Switch>

                                    {/* ⇓⇓ permet de gérer le contenu de carte ⇓⇓ */}
                                    <Route path="/map">
                                        <AbstractMaps {...this.props}/>
                                    </Route>
                                    {/* ⇓⇓ permet de gérer le contenu de la modulation de parcelles ⇓⇓ */}
                                    <Route path="/modulation">
                                        <AbstractModulation {...this.props}/>
                                    </Route>
                                    {/* ⇓⇓ permet de gérer le contenu de la pesée de biomasse de parcelles ⇓⇓ */}
                                    <Route path="/biomass">
                                        <AbstractBiomass {...this.props} />
                                    </Route>
                                    {/* ⇓⇓ permet de gérer le contenu de la fumure de parcelles ⇓⇓ */}
                                    <Route path="/fertilizer">
                                        <AbstractFertilizer />
                                    </Route>
                                    {/* ⇓⇓ permet de gérer le contenu d'import ou de management des parcelles ⇓⇓ */}
                                    <Route path="/parcels">
                                        <AbstractParcels {...this.props} />
                                    </Route>
                                    {/* ⇓⇓ permet de gérer le contenu de la récolte de maïs ⇓⇓ */}
                                    <Route path="/harvest">
                                        <AbstractHarvest {...this.props} />
                                    </Route>
                                    {/* ⇓⇓ permet de gérer le contenu lié aux observations ⇓⇓ */}
                                    <Route path="/observations">
                                        <RenderObservations {...this.props} />
                                    </Route>
                                    
                                    {/* ⇓⇓ permet de gérer la redirection automatique vers la carte (A <Switch> renders the first child <Route> that matches. A <Route> with no path always matches) ⇓⇓ */}
                                    <Route> 
                                        <Redirect to={'/map'} />
                                    </Route>
                                </Switch>

                            </Main>

                            {/* Test de l'outil de onboarding */}
                            {(ConstantsHosters.idOnboardingApp && (ConstantsHosters.idOnboardingApp !== '')) && (
                                <ProductFruits {...this.productFruitsOptions}/>
                            )}

                        </React.Fragment>
                    </Route>   

                </Switch>
          
        )
    }
}

//Définition des propriétés "décorant" le composant 'App':
const mapStateToProps = state => ({
    language: lodashGet(state, 'settingsData.settings.language', undefined),

    masterEmailClient: lodashGet(state, 'clientUserData.clientDatas.masterEmail', ''),
    clientId: lodashGet(state, 'clientUserData.clientDatas.id', 0),
    clientLabelTypo: lodashGet(state, 'clientUserData.clientDatas.labelTypoClient', ''),
    isClientGeofolia: (lodashGet(state, 'clientUserData.clientDatas.domainIdFAH', 0) > 0),
    totalCounterParcelManaged: lodashGet(state, 'clientUserData.clientDatas.totalCounterParcelManaged', 0),

    parcelDicoCounter: lodashGet(state, 'satimageData.parcelDicoCounter', 0),
    
    pvLinkActivated: lodashGet(state, 'clientUserData.pvLinkActivated', false),
    clientIsNewer: lodashGet(state, 'clientUserData.clientIsNewer', false),
    clientNeverShowWellcome: lodashGet(state, 'clientUserData.clientNeverShowWellcome', false),
    discountCodeToDisplay: lodashGet(state, 'clientUserData.discountCodeToDisplay', undefined),

    showTutorialFirstParcelAfterDeletingSlots: lodashGet(state, 'contextApp.showTutorialFirstParcelAfterDeletingSlots', false),
    isOpenMenuBurgerDesktop: lodashGet(state, 'contextAppData.isOpenMenuBurgerDesktop', false),

});

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
    //RAS !!!
})

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);