import React from 'react';
import { connect } from 'react-redux';

/* Composants MUI */
import { Button, Typography, Link, Grid, IconButton } from '@mui/material';

/* Composants React */
import ResultItemComponent from './ResultItemComponent';
import DisplayImageList from '../../thumbnail/DisplayImageList';

/* Redux */
import { ActionShowImageDialog, ActionCloseIdentificationDialog } from '../../../redux/actions/contextApp';
import {
    ActionGoToStepIdentification, StepEnumIdentification,
    ActionDeleteIdentificationImagesToAPI
} from '../../../redux/actions/observations';

/* Traduction */
import StringTranslate from '../../../assets/i18n/stringLanguage';

/* Images, icones */
import PlantnetSvg from '../../../assets/images/observation/powered-by-plantnet-light.tsx';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

/* Utils */
import ConstantsObservation from '../../../utils/constantsObservation';

/* css */
import '../../../assets/css/identificationDialog.css';
class StepShowResult extends React.Component {

    /**
     * Fonction appelé lors du click d'une image
     */
    handleOpenDialogImage = (index) => {
        const { identificationImages, showImage } = this.props;
        let image = identificationImages[index];
        showImage(image.imageUrl);
    }

    /**
     * Fonction permettant de retourner à l'étape précédente
     */
    onClickCancel = () => {
        this.props.goToStepIdentification(StepEnumIdentification.CHOIX_IMAGE);
    }

    onClickClose = () => {
        const { closeIdentificationDialog, deleteIdentificationImages } = this.props;
        deleteIdentificationImages();
        closeIdentificationDialog();

        /* this.setState({ isDrawerSelectImageOpen: false }); */
    }

    render() {
        const { identificationResult, identificationImages, showImage } = this.props;

        return (
            <>
                {/* Titre identification des plantes : résultat */}
                <Typography variant="subtitle2">{StringTranslate.identificationResultSubtitle}</Typography>

                {/* Images utilisées pour l'identification */}
                <DisplayImageList
                    imagesURL={identificationImages.map((image) => { return image.imageUrl })}
                    onClickImage={this.handleOpenDialogImage}
                    oneRow
                />

                <Grid container spacing={1}>
                    {/* Résultats de l'identification */}
                    <Grid item>
                        {((identificationResult) && (identificationResult.results) && (identificationResult.results.length > 0)) ?
                            <Grid container spacing={1}>
                                {identificationResult.results.map((result, index) => {
                                    return (<Grid item xs={12} key={"result" + index}>
                                        <ResultItemComponent key={"result" + index} result={result} showImage={showImage} />
                                    </Grid>);
                                })}
                            </Grid>
                            :
                            <Typography variant="body2">{StringTranslate.identificationResultEmpty}</Typography>
                        }
                    </Grid>

                    {/* Bouton d'identification non trouvée  */}
                    <Grid item sx={{ mt: 2, mb: 2 }}>
                        <Grid container>
                            <Grid item xs={4}>
                                <IconButton
                                    color="primary"
                                    onClick={this.onClickClose}
                                ><ArrowBackIcon /></IconButton>
                            </Grid>
                            <Grid item xs={8}>
                                <Button variant="contained" color='secondary' size="small" onClick={this.onClickCancel}>
                                    {StringTranslate.identificationResultNotFound}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Powered By Pl@ntnet */}
                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={4}>
                            <PlantnetSvg />
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="caption">
                                {StringTranslate.identificationResultPoweredByPlantnet}
                                <Link href={ConstantsObservation.LienPlantnet} color="primary" rel="noopener noreferrer" target="_blank" variant="caption">
                                    {ConstantsObservation.LienPlantnet}
                                </Link>
                            </Typography>
                        </Grid>

                    </Grid>

                </Grid>
            </>
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant */
const mapStateToProps = function (state) {
    return {
        identificationImages: state.observationsData.identificationImages,
        identificationResult: state.observationsData.identificationResult,
    };
}

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
    goToStepIdentification: (step) => dispatch(ActionGoToStepIdentification(step)),
    showImage: (uri) => dispatch(ActionShowImageDialog(uri)),
    deleteIdentificationImages: () => dispatch(ActionDeleteIdentificationImagesToAPI()),
    closeIdentificationDialog: () => dispatch(ActionCloseIdentificationDialog()),
})

export default connect(mapStateToProps, mapDispatchToProps)(StepShowResult);