import React, { useCallback } from "react";
import { connect } from 'react-redux';

import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    Fade, Button, Grid, Box, Typography, Divider,
    useMediaQuery, Stack
} from "@mui/material";

import { ActionGoToStepForDidacticielFirstParcel, StepEnumDidacticielFirstParcel, 
    ActionSetDidacticielFirstParcelOpened } from '../../../redux/actions/didacticiel.js';
import { ActionGoToMap } from '../../../redux/actions/contextApp.js';
import '../../../assets/css/dfp-didacticielFirstParcel.css';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import '../../../assets/css/dfp-didacticielFirstParcel.css';
import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';
import UpdateIcon from '@mui/icons-material/Update';
import LogoSpotifarm from '../../../assets/logoSpotifarm/LogoSpotifarmTitreEtFeuille';
import "../../../index.css";
import "../../../assets/css/dfp-didacticielFirstParcel.css";
import { useTheme } from '@mui/material/styles';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade direction="up" ref={ref} {...props} timeout={{ enter: 1000, exit: 2000 }} />;
});

/**
 * Composant fenêtre de dialogue - étapes nécessitant la fenêtre de dialogue
 * smartphone - pleine page
 */
function Modal(props) {

    const { currentStep, counterCompletedTutorial, goToStep, goToMap, setIsOpened } = props;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.up('sm'));

    /* fonction permettant de revenir à l'étape de choix */
    const goToStepChoix = useCallback(() => goToStep(StepEnumDidacticielFirstParcel.CHOIX), [goToStep]);

    /* fonction permettant de fermer le didacticiel */
    const handleClose = useCallback(() => goToMap(), [goToMap]);

    /* fonction permettant de se positionner sur l'étape de succès */
    const actualizeApplication = () => window.location.reload();

    /* fonction renvoyant vers la carte */
    const onSuccess = useCallback(() => {
        setIsOpened(false); //ferme le tuto.
        goToMap();
    }, [goToMap, setIsOpened]);

    /* fonction qui fera commencer le tutoriel */
    const onStartTutorial = useCallback(() => goToStep(StepEnumDidacticielFirstParcel.CHOIX), [goToStep/* , props */]);

    /* fonction qui mène vers l'étape de succès */
    const onGoToSuccess = useCallback(() => goToStep(StepEnumDidacticielFirstParcel.SUCCES), [goToStep/* , props */]);

    /*let cultureValue = StringTranslate.getLanguage();
    if ((!cultureValue) || (cultureValue.length < 2)) {
        cultureValue = 'fr';
    }*/
    //const languageValue = cultureValue.substring(0, 2);

    const renderHeader = () => {
        let resultShowed = undefined;
        if ((currentStep === undefined) || (currentStep < StepEnumDidacticielFirstParcel.BIENVENUE)) return resultShowed;

        switch(currentStep) {
            case StepEnumDidacticielFirstParcel.BIENVENUE: {
                resultShowed = (
                    <Stack spacing={2} direction='column' justifyContent="center" alignItems="center">
                        <Typography variant="h3">
                            <span role="img" aria-label="img-welcome">👋🏼</span>
                            {StringTranslate.welcomespotifarmertitle}
                        </Typography>

                        <Stack spacing={2} direction='row' sx={{ display:"flex", alignItems:"center"}}>
                            <LogoSpotifarm/>
                        </Stack>

                        <Divider />
                    </Stack>
                );
                break;
            }
            
            case StepEnumDidacticielFirstParcel.CHOIX: {
                resultShowed = (
                    <Stack direction='column' justifyContent="center" alignItems="center">
                        {StringTranslate.choixtitle}
                    </Stack>
                );
                break;
            }
            
            case StepEnumDidacticielFirstParcel.IMPORT: {
                resultShowed = ( 
                    <Stack direction='column' justifyContent="center" alignItems="center">
                        {StringTranslate.choiximporttitle}
                    </Stack>
                );
                break;
            }
            
            case StepEnumDidacticielFirstParcel.GEOFOLIA: {
                resultShowed = ( 
                    <Stack direction='column' justifyContent="center" alignItems="center">
                        {StringTranslate.choixgeofoliatitle}
                    </Stack>
                );
                break;
            }
            
            case StepEnumDidacticielFirstParcel.INDICE: {
                resultShowed = (
                    <Stack direction='column' justifyContent="center" alignItems="center">
                        {StringTranslate.stepIndiceTitle}
                    </Stack>
                );
                break;
            }
            
            case StepEnumDidacticielFirstParcel.SUCCES: {
                resultShowed = ( 
                    <Stack direction='column' justifyContent="center" alignItems="center">
                        {StringTranslate.successtitle}
                    </Stack>
                );
                break;
            }

            default: {
                resultShowed = null;
                break;
            }
        }

        return resultShowed;
    };

    return (
        <Dialog
            aria-labelledby="dfp-simple-dialog-title"
            open={true}
            fullWidth={true}
            fullScreen={fullScreen ? false : true}
            maxWidth={((currentStep === StepEnumDidacticielFirstParcel.GEOFOLIA) || (currentStep === StepEnumDidacticielFirstParcel.IMPORT)) ? 'xl' : (currentStep === StepEnumDidacticielFirstParcel.CHOIX) ? 'md' : 'sm'}
            PaperProps={{ classes: { root: ((currentStep === StepEnumDidacticielFirstParcel.GEOFOLIA) || (currentStep === StepEnumDidacticielFirstParcel.IMPORT)) ? 'dfp-dialog-paper-height-XL' : null } }}
            TransitionComponent={Transition}
            sx={{
                '& root': { backgroundColor: "transparent" }
            }}
        >

            {/* ↓ Header ↓ */}
            <DialogTitle>                
                {renderHeader()}

                {((counterCompletedTutorial > 0) && (currentStep !== StepEnumDidacticielFirstParcel.SUCCES)) && (
                    <Button className="btn-close-dialog-tutorial">
                        <CloseIcon onClick={(currentStep === StepEnumDidacticielFirstParcel.INDICE) ? onGoToSuccess : handleClose} />
                    </Button>
                )}
            </DialogTitle>

            {/* ↓ Contenu ↓ */}
            <DialogContent dividers={((currentStep !== StepEnumDidacticielFirstParcel.BIENVENUE) && (currentStep !== StepEnumDidacticielFirstParcel.CHOIX))}>
                {props.children}
            </DialogContent>

            {/* ↓ Footer ↓ */}
            {((currentStep === StepEnumDidacticielFirstParcel.GEOFOLIA) || (currentStep === StepEnumDidacticielFirstParcel.IMPORT)) && (
                <DialogActions classes={{ root: "dfp-dialog-dialogActions" }} >
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Button variant="text" color="primary" startIcon={<KeyboardReturnIcon />} onClick={goToStepChoix}>
                                {StringTranslate.gobacktostepchoix}
                            </Button>
                        </Grid>

                        <Grid item alignContent="center" xs={4}>
                            {(currentStep === StepEnumDidacticielFirstParcel.GEOFOLIA) ?
                                <Button variant="contained" startIcon={<UpdateIcon />} onClick={actualizeApplication}>
                                    <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>{StringTranslate.actualize}</Box>
                                    <Box sx={{ display: { xs: 'block', sm: 'none', md: 'none' } }}>{StringTranslate.actualizeForSmallScreen}</Box>
                                </Button>
                                : 
                                null}
                        </Grid>
                    </Grid>
                </DialogActions>
            )}

            {(currentStep === StepEnumDidacticielFirstParcel.SUCCES) &&
                <DialogActions className="dfp-content-welcome">
                    <Button color="primary" variant="contained" onClick={onSuccess}>
                        {StringTranslate.successbtnvalidation}
                    </Button>

                </DialogActions>}

            {(currentStep === StepEnumDidacticielFirstParcel.BIENVENUE) &&
                <DialogActions className="dfp-content-welcome">
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onStartTutorial}>
                        {StringTranslate.starttutorial}
                    </Button>

                </DialogActions>}

            {(currentStep === StepEnumDidacticielFirstParcel.INDICE) &&
                <DialogActions className="dfp-content-welcome">
                    <Button color="primary" variant="contained" onClick={onGoToSuccess}>
                        {StringTranslate.stepIndiceButton}
                    </Button>
                </DialogActions>}
        </Dialog>
    )
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    currentStep: state.didacticielData.firstparcel.currentStep,
    counterCompletedTutorial: state.didacticielData.firstparcel.counterCompleted
});

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
    goToStep: (enumStep, status) => dispatch(ActionGoToStepForDidacticielFirstParcel(enumStep, status)),
    goToMap: () => dispatch(ActionGoToMap()),
    setIsOpened: (bool) => dispatch( ActionSetDidacticielFirstParcelOpened(bool) ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);