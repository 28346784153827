import React from "react";
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

/* Mui components */
import {
    Grid, Typography, FormControlLabel, Checkbox,
    Button, TextField, Box
} from '@mui/material';

/* React components */
import CustomDataGrid from "../../components/customDataGrid";
import { ThumbnailParcelShapeFromPathInfos } from '../thumbnail/ThumbnailParcelShape.jsx';

/* Redux */
import { TableType, UpdateNbRowsPerPageTable } from '../../redux/actions/settings.js';
import { ModulationStep, ActionGetLastModulationSettings, ActionGoToNextStepOfModulation,
    ActionClearNewModulationCounter, ActionInitializePrescriptions } from '../../redux/actions/modulations';

/* Dicos, helpers, translation */
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import dicoFunction from '../../datas/dicoDetails.js';
import DateHelper from '../../utils/dateHelper.js';
import { ParcelsHelper } from '../../utils/parcelsHelper.js';

/* Mui Icons */
import DoneIcon from '@mui/icons-material/Done';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import stringHelper from '../../utils/stringHelper.js';
import SearchIcon from '@mui/icons-material/Search';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import ConstantsTutorialHelps from '../../utils/constantsTutorialHelps.js';
import CustomIFrame from '../customIFrame';

/* css */
import '../../assets/css/renderParcels.css';

/* theme */
import getTheme from "../../themes/index.js";
import ModalVideos from "../modalVideos";
let theme = getTheme();


/**
 * Composant d'affichage des parcelles actuelles.
 */
class ChoixParcelsModulation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            parcelDatas: this.initialiseParcelDatas(props.parcelIdFilteredList), //parcelles

            //sauvegarde la langue en cours lors de la création du composant: (Car pas présente dans le store Redux)
            language: props.language,

            cultures: this.initialiseCultures(),

            datasNotModulatedOnly: false,

            parcelsSelected: (props.parcelIdSelected > 0) ? [this.props.parcelIdSelected] : [], //parcelles sélectionnées par les checkbox
            pageSize: props.rowsPerPageForTableParcels, //Nombre de lignes par page au départ
            searchFilter: '', //Zone de recherche du tableau 
            currentRowsOfTable: [], //Datas en fonction du texte tapé dans la zone de recherche
            modalIsOpen: false, // Valide l'apparition du modal
            videos: ConstantsTutorialHelps.UrlVideoModulation, // Lien url d'une vidéos qu'on passe en props dans le composant modalVideos
        };

        // liste des noms de colonnes affichées
        this.columns = this.initialiseParcelColumns(props.parcelIdFilteredList); //RQ: pas dans le 'state' car on en utilise des propriétés !
        // Ouvre ou non une modal
        this.handleOpenModalVideos = this.handleOpenModalVideos.bind(this);
        this.handleCloseModalVideos = this.handleCloseModalVideos.bind(this);
    }

    //renvoie les cultures appartenant aux parcelles existantes
    initialiseCultures() {
        const { parcelDico } = this.props;

        return ParcelsHelper.mergeCultures(parcelDico);
    }

    componentDidUpdate(prevProps, prevState) {
        //@@Réagir aux modifications sur les parcelles : Suppression d'une ou toutes les parcelles ; Modification d'une ou plusieurs données d'une parcelle !
        const { parcelDicoCounter, parcelIdFilteredListCounter, parcelIdFilteredList,
            updatingParcelName, updatingParcelInfos, modulationDicoCounter, modulationDico } = this.props;
        const { language, parcelDatas } = this.state;

        /* Lorsqu'il y a un ajout, suppression de parcelle, il faut actualiser la liste des 'datas': */
        /* Idem, si c'est le filtre des parcelles qui change la liste de celles filtrées ! */
        if ((prevProps.parcelDicoCounter !== parcelDicoCounter) || (prevProps.parcelIdFilteredListCounter !== parcelIdFilteredListCounter)) {
            const newDatas = this.initialiseParcelDatas(parcelIdFilteredList);

            if (prevProps.parcelDicoCounter !== parcelDicoCounter) {
                // liste des noms de colonnes affichées. 
                this.setState({
                    parcelDatas: newDatas
                },
                    () => { this.columns = this.initialiseParcelColumns(parcelIdFilteredList) }); //RQ: ca va que l'on actualise 'cultures' sans quoi le rendu n'aurai pas lieu et on n'utiliserai pas la nouvelle liste de noms de colonne !
            } else {
                this.setState({
                    parcelDatas: newDatas,
                });
            }
        }
        /* Lorsqu'il y a une modification des données d'une parcelle (RQ: n'a pas être réalisé qu'ici!), il faut actualiser la liste des 'datas': */
        /* RQ: c'est plus rapide de recharger toutes les parcelles que de rechercher quelle parcelle a été modifié et quelle(s) info(s) de cette parcelle! */
        else if (((prevProps.updatingParcelName === true) && (updatingParcelName === false)) ||
            ((prevProps.updatingParcelInfos === true) && (updatingParcelInfos === false))) { //RQ: lorsque l'on passe de 'true' à 'false' c'est que le traitement est finis (même si pourrai être KO)
            const newDatas = this.initialiseParcelDatas(parcelIdFilteredList);
            this.setState({ parcelDatas: newDatas, });
        }

        if ((prevProps.modulationDicoCounter !== modulationDicoCounter) || prevProps.modulationDico !== modulationDico) {
            //Mettre à jour les parcelles qui SONT modulées (rajout de la coche verte)
            //Premièrement, on met toutes les parcelles avec la modulation à false
            //Deuxièmement, les parcelles dont les ids qui sont dans modulationDico auront la coche verte.
            parcelDatas.forEach(parcel => {
                if (parcel) {
                    parcel.hasModulation = false;
                }
            });
            for (const key in modulationDico) {
                for (let i = 0; i < parcelDatas.length; i++) {
                    const modulParcelItem = modulationDico[key];
                    const parcelItem = parcelDatas[i];
                    if (modulParcelItem && parcelItem && modulParcelItem.parcelId === parcelItem.id) {
                        parcelItem.hasModulation = true;
                    }
                }
            }
            this.setState({ parcelDatas: parcelDatas, });
        }

        /* Lorsqu'il y a un changement de langue (même si on se doute que l'utilisateur ne changera pas de langue tous les 4 matins !), il faut actualiser certaines listes: */
        const currentLanguage = StringTranslate.getLanguage();
        if ((!language) || (language === '') || (language !== currentLanguage)) {
            // liste des noms de colonnes affichées. 
            this.columns = this.initialiseParcelColumns(parcelIdFilteredList); //RQ: ca va que l'on actualise 'cultures' sans quoi le rendu n'aurai pas lieu et on n'utiliserai pas la nouvelle liste de noms de colonne !
            const newCultures = this.initialiseCultures();
            this.setState({ cultures: newCultures, language: currentLanguage, });
        }

    }

    /**
     * Initialisation des colonnes de parcelles.
     * Il y a une traduction sur les listes fixes de cultures, exploitations et variétés
     */
    initialiseParcelColumns(parcelIdFilteredList) {
        const { parcelDico } = this.props;

        let newColumns = [];
        if (parcelIdFilteredList && parcelIdFilteredList[0]) {

            /* ↓ ajout colonne non visible - id de la parcelle ↓ */
            newColumns.push({
                headerName: "Id",
                field: "id",
                hideable: false,
            });

            /* ↓ ajout colonne visible - (booléen parcelle modulée) ↓ */
            newColumns.push({
                headerName: `${StringTranslate.parcelModulated}`,
                field: "hasModulation",
                width: 95,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.parcelModulated}</Typography>)
                },
                renderCell: params => (
                    <div onClick={() => this.onClickForModulation([params.row.id])}>
                        {(params.value === true) ?
                            <DoneIcon style={{ color: theme.palette.success.main }} />
                            : null}
                    </div>),
            });

            /* ↓ ajout colonne visible - (Miniature + nom de la parcelle) ↓ */
            newColumns.push({
                headerName: `${StringTranslate.nomcolumn}`,
                field: "nom",
                minWidth: 180,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.nomcolumn}</Typography>)
                },
                renderCell: params => (
                    <Box style={{ display: "flex", flexDirection: "row", alignItems: "center"  }} onClick={() => this.onClickForModulation([params.row.id])} >
                        {(params) ? (<ThumbnailParcelShapeFromPathInfos id={`listItemTbl_${params.row.parcel.id}`} {...params.row.thumbnailInfos} />) : (undefined)}
                        <Typography>{params.value}</Typography>
                    </Box>
                ),
                cellStyle: { paddingBottom: '7px' }, //<= Si on voulais personnalliser le rendu (mais je nesais pas comment faire pour le cas du survoil...)
            });

            const firstParcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelIdFilteredList[0]);
            if (firstParcel && firstParcel.details) {
                const dico = dicoFunction();
                for (let detail in firstParcel.details) {
                    dico.forEach(column => {
                        if (detail === column.name) {
                            if (column.name === "culture" || column.name === "culturePrecedente") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    minWidth: 150,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "exploitation") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    minWidth: 150,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "dateSemi") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    width: 110,
                                    type: "date",
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "campagne") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    width: 100,
                                    type: "date",
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "surface") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    width: 100,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "variete") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    minWidth: 150,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                        }
                    });
                }
            }
        }
        return newColumns;
    }

    /* fonction permettant d'extraire les données qui seront utilisées dans le visuel pour les parcelles */
    initialiseParcelDatas(parcelIdFilteredList) {
        const { parcelDico, thumbnailParcelDico } = this.props;
        let parcels = ParcelsHelper.getParcelsDicoFromParcelIdList(parcelDico, parcelIdFilteredList);
        let newDatas = [];

        for (var itemPropName in parcels) {
            let temp = [];
            const parcel = parcels[itemPropName];

            if (parcel) {
                const thumbnailParcelItem = ParcelsHelper.selectParcelFromDicoById(thumbnailParcelDico, parcel.id);
                temp.push(parcel.id); //pour l'update du nom de la parcelle             
                temp.push(parcel.name);

                if (parcel.details) {
                    for (let itemDetailName in parcel.details) {
                        if (itemDetailName.toLowerCase() !== 'campagne') {
                            const propValue = parcel.details[itemDetailName];
                            let value = (propValue) ? propValue : '';
                            if (itemDetailName.toLowerCase() === 'datesemi') {
                                if (propValue && (propValue instanceof (Date))) {
                                    value = DateHelper.formati18n(propValue, 'P');
                                }
                                else if (propValue && (DateHelper.getDateFromString(propValue) instanceof Date)) {
                                    value = DateHelper.formati18n(new Date(propValue), 'P');
                                }
                            }
                            else if (itemDetailName.toLowerCase() === 'surface') {
                                if(parcel.area && (parcel.area > 0)) {
                                    value = parcel.area;
                                }
                            }
                            temp.push(value);
                        }
                    }
                }

                newDatas.push({
                    id: parcel.id,
                    hasModulation: parcel.hasModulation,
                    parcel: parcel,
                    nom: parcel.name,
                    culture: parcel.details.length < 3 ? null : temp[2],
                    culturePrecedente: parcel.details.length < 4 ? null : temp[3],
                    dateSemi: parcel.details.length < 5 ? null : temp[4],
                    exploitation: parcel.details.length < 6 ? null : temp[5],
                    surface: parcel.details.length < 7 ? null : temp[6],
                    variete: parcel.details.length < 8 ? null : temp[7],
                    tableData: { checked: this.props.parcelIdSelected === parcel.id ? true : false },
                    thumbnailInfos: thumbnailParcelItem,
                });
            }
        }
        // on met le champ selectionné en première position de la liste de parcels pour qu'il soit le premier dans le tableau
        if (this.props.parcelIdSelected > 0 && parcelIdFilteredList.includes(this.props.parcelIdSelected)) {
            let parcelToChange = newDatas[parcelIdFilteredList.indexOf(this.props.parcelIdSelected)];
            newDatas.splice(newDatas.indexOf(parcelToChange), 1);
            newDatas.splice(0, 0, parcelToChange);
        }
        return newDatas;
    }

    /* Redirection vers l'étape MAP avoir sélectionné le(s) parcelle(s) pour commencer la modulation 
       puis cliqué sur le bouton "Commencer la modulation" */
    onClickForModulation(newParcelIdsSelected) {
        const { clearNewModulationCounter, rememberModulationSettings, getLastModulationSettings,
            clientId, initializePrescriptions, goToNextStepOfModulation } = this.props;

        // On ré-initialise le compteur de bonne génération complète:
        clearNewModulationCounter();

        // puis, on passe à la première étape de la génération d'une modulation (pour la première parcelle parmis celles sélectionnées):
        let readableMode = false;

        //Le client a demandé auparavant de mettre par défaut la dose moyenne, la teneur, les paramètres de la dernière modulation enregistrée
        if (rememberModulationSettings === true) {
            getLastModulationSettings(clientId);
        }

        //Initialiser les prescriptions à [] (allPrescriptions),
        initializePrescriptions();

        goToNextStepOfModulation(ModulationStep.MAP, newParcelIdsSelected, readableMode);
    }

    handleChangeDatasNotModulatedOnly(event) {
        this.setState({
            datasNotModulatedOnly: event.target.checked
        });
    }

    //Toolbar customisé du tableau comprenant :
    // - la zone de recherche
    // - le bouton d'accès à la modulation
    CustomToolbar(props) {
        return (
            <Grid container spacing={2} >
                {/* Zone de recherche */}
                <Grid item xs={12}>
                    <Grid container spacing={1} sx={{alignItems:"center"}}>
                        <Grid item xs={10} sm={8} md={4} lg={4}>
                            <TextField
                                size="small"
                                value={props.value}
                                onChange={props.onChange}
                                placeholder={StringTranslate.searchPlaceholder}
                                InputProps={{
                                    startAdornment: <SearchIcon fontSize="small" />,
                                    endAdornment: (
                                        <IconButton
                                            title="Clear"
                                            aria-label="Clear"
                                            size="small"
                                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                                            onClick={props.clearSearch}
                                        >
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={2} sm={4} md={8}>
                            <Box sx={{ display: { md: 'block', xs: 'none' }, '& button': { m: 1 } }} style={{ textAlign: 'end' }} >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => props.startModulation(props.parcelsSelected)}
                                    disabled={(props.parcelsSelected.length > 0) ? false : true}>
                                    {`${StringTranslate.startModulation}`}
                                </Button>
                            </Box>
                            <Box sx={{ display: { md: 'none', xs: 'block' }, '& button': { m: 1 } }} style={{ textAlign: 'end' }} >
                                <div>
                                    <IconButton 
                                        color="primary"
                                        sx={{
                                            bgcolor:theme.palette.primary.main,
                                            color:theme.palette.common.white
                                        }}
                                        size="large" 
                                        aria-label="start modulation(s)" 
                                        component="span"
                                        onClick={() => props.startModulation(props.parcelsSelected)}
                                        disabled={(props.parcelsSelected.length > 0) ? false : true}
                                    >
                                        <PlayArrowIcon />
                                    </IconButton>
                                </div>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={props.datasNotModulatedOnly}
                                        onChange={(event) => props.handleChangeDatasNotModulatedOnly(event)}
                                        name="datasNotModulatedOnly"
                                    />
                                }
                                label={`${StringTranslate.showOnlyParcelWithoutPrescription}`}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {/* Bouton d'aide */}
                <Grid item xs={6}>
                    <Button color="secondary" variant="text" size="small" onClick={props.handleOpenModalVideos}>
                        {StringTranslate.helpAsk}
                    </Button>
                </Grid>

                {/* Modal videos */}                
                <ModalVideos
                    modalIsOpen={props.modalIsOpen}
                    modalVideos={props.videos}
                    onClose={() => props.handleCloseModalVideos()}
                />
                
            </Grid>
        );
    }

    /* Fonction correspondant à la zone de recherche */
    requestSearch(searchValue, rowsOfTable) {
        this.setState({
            searchFilter: searchValue
        });

        try {
            const searchRegex = new RegExp(stringHelper.escapeRegExp(searchValue), 'i');
            const filteredRows = rowsOfTable.filter((row) => {
                return Object.keys(row).some((field) => {
                    let textValue = "";
                    if (field === 'nom' || field === 'culture') {
                        textValue = textValue + row[field];
                    }
                    return searchRegex.test(textValue.toString());
                });
            });

            this.setState({
                currentRowsOfTable: filteredRows
            });
        }
        catch(errRegex) { /* Peut arriver si on saisis un truc du genre 'EARL++' ! (il n'aime pas les '++') */ }
    }

    //Sélection des parcelles pour commencer la modulation
    setSelectionParcels = (newSelectionParcels) => {
        this.setState({
            parcelsSelected: newSelectionParcels
        });
    }

    /**
     * Permet d'ouvrir le modal, il met à jours le state local à true
     */
    handleOpenModalVideos = () => {
        this.setState({ modalIsOpen: true });
    }
    /**
     * Permet de fermer le modal, il met à jours le state local à false
     */
    handleCloseModalVideos = () => {
        this.setState({ modalIsOpen: false });
    }
    ///////////////////////////////////////////////////////////////////////////
    // fonction de rendu visuel - layout général
    ///////////////////////////////////////////////////////////////////////////
    render() {
        const { parcelDatas, datasNotModulatedOnly, pageSize, searchFilter, parcelsSelected, currentRowsOfTable, modalIsOpen, videos } = this.state; // etat du dialog popup
        const { authorizeModulation } = this.props;

        return (
            /* ↓↓ partie - zone de visualisation des parcelles ↓↓ */

            /* Si l'utilisateur a le droit d'accès à la fonctionnalité => ok ; sinon, message ! */
            (authorizeModulation === true) ? (
               
                /* partie tableau */
                <CustomDataGrid
                    tableName={TableType.parcels}
                    pageSize={pageSize}
                    updateNbRowsPerPageTable={this.props.updateNbRowsPerPageTableParcels}
                    onSelectionModelChange={(newSelectionParcels) => { //Sélection des parcelles par clic checkbox
                        this.setSelectionParcels(newSelectionParcels);
                    }}
                    keepNonExistentRowsSelected={searchFilter !== "" ? true : false}
                    selectionModel={parcelsSelected}
                    Toolbar={this.CustomToolbar}
                    toolbar={{
                        parcelsSelected: parcelsSelected,
                        datasNotModulatedOnly: datasNotModulatedOnly,
                        handleChangeDatasNotModulatedOnly: (event) => this.handleChangeDatasNotModulatedOnly(event),
                        startModulation: () => this.onClickForModulation(parcelsSelected),
                        handleOpenModalVideos: () => this.handleOpenModalVideos(),
                        handleCloseModalVideos: () => this.handleCloseModalVideos(),
                        parcelDatas: parcelDatas,
                        //Concerne la zone de recherche dans le tableau :
                        value: searchFilter,
                        modalIsOpen: modalIsOpen,
                        videos : videos,
                        onChange: (event) => this.requestSearch(event.target.value, parcelDatas),
                        clearSearch: () => this.requestSearch('', parcelDatas)
                    }}
                    rows={(searchFilter !== "") ?
                        (datasNotModulatedOnly ?
                            currentRowsOfTable.filter(data => ((!data) || (data.hasModulation === false))) : currentRowsOfTable) :
                        (datasNotModulatedOnly ?
                            parcelDatas.filter(data => ((!data) || (data.hasModulation === false))) : parcelDatas)}
                    columns={this.columns}
                    checkBoxActive={true}
                />)
                :
                (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <CustomIFrame className="iframes_profil" url={ConstantsTutorialHelps.UrlContactFormForModulation}/>
                    </Grid>
                </Grid>    
                )

        );
    }
}

const mapStateToProps = state => ({
    //Infos provenant du reducer 'modulation':
    modulationDico: lodashGet(state, 'modulationsData.modulationDico', {}),
    modulationDicoCounter: lodashGet(state, 'modulationsData.modulationDicoCounter', 0),
    allPrescriptions: lodashGet(state, 'modulationsData.allPrescriptions', {}), //sert surtout pour la multi saisie de parcelles à moduler, pour le téléchargement futur
    parcelIdsSelected: lodashGet(state, 'modulationsData.parcelIdsSelected', {}),
    readableMode: lodashGet(state, 'modulationsData.readableMode', true),

    //Infos provenant du reducer 'parcels':
    parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
    parcelDicoCounter: lodashGet(state, 'parcelsData.parcelDicoCounter', 0),
    parcelIdFilteredList: lodashGet(state, 'parcelsData.parcelIdFilteredList', {}),
    parcelIdFilteredListCounter: lodashGet(state, 'parcelsData.parcelIdFilteredListCounter', 0),
    thumbnailParcelDico: lodashGet(state, 'parcelsData.thumbnailParcelDico', {}),
    updatingParcelName: lodashGet(state, 'parcelsData.updatingParcelName', false),
    updatingParcelInfos: lodashGet(state, 'parcelsData.updatingParcel', false),

    //Infos provenant du reducer 'settings':
    rememberModulationSettings: lodashGet(state, 'settingsData.settings.rememberModulationSettings', true),
    rowsPerPageForTableParcels: lodashGet(state, 'settingsData.settings.rowsPerPageForTableParcels', 20),
    language: lodashGet(state, 'settingsData.settings.language', StringTranslate.getLanguage()),
    
    //Infos provenant du reducer 'client':
    authorizeModulation: lodashGet(state, 'clientUserData.clientDatas.authorizeModulation', false),
    clientDatas: lodashGet(state, 'clientUserData.clientDatas', -1),
   
    //Infos provenant du reducer 'contextApp':
    parcelIdSelected: lodashGet(state, 'contextAppData.parcelIdSelected', -1),
});

const mapDispatchToProps = dispatch => ({
    getLastModulationSettings: (clientId) => dispatch(ActionGetLastModulationSettings(clientId)),
    initializePrescriptions: () => dispatch(ActionInitializePrescriptions()),
    goToNextStepOfModulation: (nextStepOfModulation, parcelIdsSelected, readableMode, modulationSelectedItemValue = undefined) =>
        dispatch(ActionGoToNextStepOfModulation(nextStepOfModulation, parcelIdsSelected, readableMode, modulationSelectedItemValue)),
    clearNewModulationCounter: () => dispatch(ActionClearNewModulationCounter()),
    updateNbRowsPerPageTableParcels: (rowsPerPage) => dispatch(UpdateNbRowsPerPageTable(rowsPerPage, TableType.parcels)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChoixParcelsModulation);
