import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import getTheme from '../../../themes/index';

let theme = getTheme();


function IconeObservationGibier(props: SvgIconProps) {
  return ( 
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" strokeWidth="1.8" stroke="none" fill="current">
        <g>
          <path d="M7,18.56c-.24-.35-.9-1.37-1.06-1.67a11.27,11.27,0,1,1,21.29-5.15A11.06,11.06,0,0,1,26,16.89c-.15.3-.82,1.32-1.06,1.69L16,31.17Z" fill={theme.palette.primary.main}/>
          <path d="M16,1A10.78,10.78,0,0,1,26.76,11.74a10.59,10.59,0,0,1-1.18,4.92c-.12.24-.68,1.1-1,1.61L16,30.3l-8.55-12c-.35-.54-.91-1.4-1-1.64a10.6,10.6,0,0,1-1.19-4.92A10.78,10.78,0,0,1,16,1m0-1A11.76,11.76,0,0,0,5.53,17.12c.2.39,1.09,1.73,1.09,1.73L16,32l9.37-13.18s.9-1.34,1.1-1.73A11.78,11.78,0,0,0,16,0Z" fill={theme.palette.primary.main}/>
        </g>
        <path d="M24.49,10.58s-.26.2-.42-.14c0,0-.06-.11-.12-.2a.55.55,0,0,0-.19-.63s.06.38,0,.45l-.64-.38a2.94,2.94,0,0,1-.82-.77s-.07-.05,0-.25.11-.37-.45-1.18c0,0-.7-.73-.92-1a1.07,1.07,0,0,1,0,.32L18.66,6a2.17,2.17,0,0,0-1.81.12,28,28,0,0,1-2.82.8,8.43,8.43,0,0,1-1.51.29A3.22,3.22,0,0,0,9.68,8.64,2.22,2.22,0,0,0,9,10.24c0,.41-.21.74-.11,1.16a5.6,5.6,0,0,0,.3.81s.1-.65.14-1a1.43,1.43,0,0,0-.08-.67c-.06-.23.08-1.09.46-1.3a4.18,4.18,0,0,0-.05,1.78c.2.84.4,1.13,0,1.53a.83.83,0,0,0-.2.82L9.68,15l.19.06s0,.51.7.51c0,0,.23-.14.09-.27a2.41,2.41,0,0,1-.41-1.56,1.58,1.58,0,0,1,.23-.47c.15-.22.53-.3.63-.53.24,0,.19.09.22.31a1.51,1.51,0,0,0,.2.62,6.7,6.7,0,0,1,.9,1.4s.07.1.2,0a.47.47,0,0,0,.48.27c.36,0,.51,0,.44-.17s-.68-.59-.84-.88-.76-.63-.2-1.58l.22-.29a6,6,0,0,0,1,.56,2.26,2.26,0,0,0,1.67-.14c.17-.06.93-.43,1.06-.25a4,4,0,0,1,.52.92,3.48,3.48,0,0,1,.22,1.08,2.21,2.21,0,0,0,.21.81l.12,0a.47.47,0,0,0,.74.22s.27.06.14-.14a1.85,1.85,0,0,1-.48-1.3,2.11,2.11,0,0,1,.1-.9.14.14,0,0,1,.13.12c0,.13.2,1.08.22,1.39,0,0,.09.18.23.18s.13.34.74.37a.17.17,0,0,0,0-.27,1.42,1.42,0,0,1-.3-.6c-.07-.2-.27-.91-.1-1.2a2.53,2.53,0,0,0,.14-1,5.48,5.48,0,0,0,.71-.43,2.49,2.49,0,0,0,1.23.11,1.9,1.9,0,0,0,1.13-.16,1.06,1.06,0,0,0,.59-.13,1.53,1.53,0,0,0,.59,0,1.35,1.35,0,0,1,.53,0,1.51,1.51,0,0,0,.37-.09S24.89,10.66,24.49,10.58Z" fill= {theme.palette.common.white} fillRule="evenodd"/>
      </svg>
    </SvgIcon>
  );
}

export default IconeObservationGibier;