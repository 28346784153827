
// Valable pour toutes les configs:
// Nom des couches:
const RoadBaseLayerNameCst = 'BasicLayersInfo-plan';
const VisibleBaseLayerNameCst = 'BasicLayersInfo-satellite';

const VisibleGlobalLayerNameCst = 'WMTSLayersInfo-satellite';
const NdviGlobalLayerNameCst = 'WMTSLayersInfo-ndvi';

const VisibleParcelLayerNameCst = 'WMSLayersInfo-satellite';
const NdviParcelLayerNameCst = 'WMSLayersInfo-ndvi';

//const LayersArray = { RoadBaseLayerNameCst:0, VisibleGlobalLayerNameCst:1, NdviGlobalLayerNameCst:2, VisibleParcelLayerNameCst:3, NdviParcelLayerNameCst:4, };
//const LayersArrayEnumCst = Object.freeze(LayersArray);


export const ConstantsLayers = {
    RoadBaseLayerName: RoadBaseLayerNameCst, 
    VisibleBaseLayerName: VisibleBaseLayerNameCst, 

    VisibleGlobalLayerName: VisibleGlobalLayerNameCst,
    NdviGlobalLayerName: NdviGlobalLayerNameCst, 

    VisibleParcelLayerName: VisibleParcelLayerNameCst, //RQ: plus utilis�e mais on conserve la valeur d'�num�r�e
    NdviParcelLayerName: NdviParcelLayerNameCst, 
};

export const GetBaseLayerName = function(baseLayerOnBdD) {
    if ((!baseLayerOnBdD) || (baseLayerOnBdD !== 'routiere')) {
        return ConstantsLayers.VisibleBaseLayerName;
    } else {
        return ConstantsLayers.RoadBaseLayerName;
    }
}

export const GetBaseLayerIndex = function(baseLayerName) {
    if ((!baseLayerName) || (baseLayerName !== ConstantsLayers.VisibleBaseLayerName)) {
        return 'routiere';
    } else {
        return 'satellite';
    }
}

export const GetForewardLayerName = function(lastLayerOnBdD) {
    if ((!lastLayerOnBdD) || (lastLayerOnBdD === 3) || (lastLayerOnBdD === 4)) {
        return ConstantsLayers.NdviParcelLayerName;
    } else if (lastLayerOnBdD === 1) {
        return ConstantsLayers.VisibleGlobalLayerName;
    } else if (lastLayerOnBdD === 2) {
        return ConstantsLayers.NdviGlobalLayerName;
    }

    return ConstantsLayers.NdviParcelLayerName;
}

export const GetDefaultForewardLayerName = function() {
    return 4; // corresponds à 'ConstantsLayers.NdviParcelLayerName' !
}

export const GetLastLayerIndex = function(forewardLayerName) {
    if ((!forewardLayerName) || (forewardLayerName === ConstantsLayers.VisibleParcelLayerName) || (forewardLayerName === ConstantsLayers.NdviParcelLayerName)) {
        return 4; // corresponds à 'ConstantsLayers.NdviParcelLayerName' !
    } else if (forewardLayerName === ConstantsLayers.VisibleGlobalLayerName) {
        return 1;
    } else if (forewardLayerName === ConstantsLayers.NdviGlobalLayerName) {
        return 2;
    }

    return ConstantsLayers.NdviParcelLayerName;
}

export default ConstantsLayers;
