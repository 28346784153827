import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import lodashGet from 'lodash/get';
import lodashIsDate from 'lodash/isDate';

/* utils */
import { IsNativeHoster } from '../../utils/platformHelper';
import { ConstantsContent } from '../../utils/constantsContent.js';
import DateHelper from '../../utils/dateHelper.js';
import ConstantsFertilizer from '../../utils/constantsFertilizer.js';

/* MUI Components */
import MainCard from "../../components/subLayouts/mainCard.jsx";
import { Tab, Tabs, Box, Alert, AlertTitle, } from '@mui/material';
import { TabPanel, buildPropsForTab } from '../../components/tabPanel';

/* React Components */
import NitrogenStepsManagement from '../../components/fertilizer/nitrogenStepsManagement.jsx';
import FertilizersList from '../../components/fertilizer/fertilizersList.jsx';
import NitrogenMap from '../../components/fertilizer/nitrogenStepsManagement/nitrogenMap.jsx';
import FertilizerAuthorization from '../../components/fertilizer/fertilizerAuthorization.jsx';
import AbstractLastNitrogenInput from '../lastNitrogenInput/abstractLastNitrogenInput';
import DataGridSkeleton from '../../components/dataGridSkeleton';

/* MUI Icons */
import { Add } from '@mui/icons-material';
/* React Icons */
import FertilizationIcon from "../../assets/images/fertilization/fertilizationIcon.tsx";

/* Redux */
import { ActionInitCultures, TabAbstractFertilizer, ActionSelectFertilizerTab } from '../../redux/actions/fertilizer';
import { ActionSetCurrentPageName, PageName } from '../../redux/actions/contextApp';

/* Theme Berry */
import getTheme from "../../themes/index.js";
import StringTranslate from "../../assets/i18n/stringLanguage";
import LastNitrogenInputMap from "../../components/lastNitrogenInput/lastNitrogenInputMap";


let theme = getTheme();

/////////////////////////////////////////////////////////
// Composant général concernant la fumure de parcelles //
/////////////////////////////////////////////////////////

class AbstractFertilizer extends React.Component {
    constructor(props) {
        super(props);

        this.state = { };
        
        const nowDate = new Date();
        this.defaultOpenDate = new Date(nowDate.getFullYear(), 0, 15); //janvier
        this.defaultCloseDate = new Date(nowDate.getFullYear(), 6, 15); //juillet

        this.engineProcessIsClose = false; //potentiellement revu par 'initializeDatas' !
        this.explainOpenDate = StringTranslate.textOpenDateAlertFertilizersWithoutDate; //potentiellement revu par 'initializeDatas' !

        this.initializeDatas();
    }

    handleChangeTab = (event, newValue) => {
        //Signaler à REDUX dans quel onglet (tab) on se trouve ! 
        this.props.setValueTabForAbstractFertilizer((newValue < TabAbstractFertilizer.NITROGEN_MANAGEMENT) ? TabAbstractFertilizer.NITROGEN_MANAGEMENT : newValue);
    }
    
    initializeDatas() {
        const nowDate = new Date();
        const closingDateAzofert = lodashGet(this.props, 'closingDateAzofert', this.defaultCloseDate);
        const openingDateAzofert = lodashGet(this.props, 'openingDateAzofert', this.defaultOpenDate);

        this.engineProcessIsClose = (lodashIsDate(closingDateAzofert) && lodashIsDate(openingDateAzofert) &&
            (closingDateAzofert <= nowDate) && (openingDateAzofert >= nowDate) &&
            (closingDateAzofert < openingDateAzofert)) ? true : false;
        this.explainOpenDate = (lodashIsDate(openingDateAzofert)) ? 
            `${StringTranslate.textOpenDateAlertFertilizers} ${DateHelper.formati18n(openingDateAzofert, 'P')}` : 
            StringTranslate.textOpenDateAlertFertilizersWithoutDate;
    }

    componentDidMount() {
        const { initCulturesToStart, culturesForFertilizing, setCurrentPageName } = this.props;

        //Demande des cultures pour commencer la fumure
        if (initCulturesToStart && ((!culturesForFertilizing) || (culturesForFertilizing.length <= 0)) ) {
            initCulturesToStart();
        }

        //Signaler à Redux qu'on est dans la fonctionnalité Fumure
        setCurrentPageName(PageName.Fertilizer, false);
    }

    componentDidUpdate(prevProps, prevState) {
        const { askingAzofertDatesRange } = this.props;

        //si on vient de recevoir les dates d'ouverture des service de fumure:
        if (((!prevProps) || (prevProps.askingAzofertDatesRange === true)) && (askingAzofertDatesRange === false)) {
            this.initializeDatas();
        }
    }

    render() {

        const { isOpenMenuBurgerDesktop, showFertilizerMap, showLastNitrogenInputMap, authorizeFertilizer, valueTabForAbstractFertilizer, 
            askingAzofertDatesRange } = this.props;

        const nativeAppHoster = IsNativeHoster(); // Sert à savoir si on se trouve en mobile ou non pour la réduction des textes par exemple

        //textOpenDateAlertFertilizersWithoutDate

        return (

            (showFertilizerMap === true || showLastNitrogenInputMap === true) ?
                <Box
                    className="contentAbstractMap"
                    sx={{
                        [theme.breakpoints.up('md')]: {
                            left: isOpenMenuBurgerDesktop ? ConstantsContent.DrawerWidth : ConstantsContent.MarginCard,
                            width: isOpenMenuBurgerDesktop ? `calc(100% - ${ConstantsContent.DrawerWidth + ConstantsContent.MarginCard}px)` : `calc(100% - ${ConstantsContent.MarginCard * 2}px)`
                        }
                    }}>
                    {(showFertilizerMap === true) ? <NitrogenMap {...this.props} /> : <LastNitrogenInputMap />}
                </Box> :

                <MainCard>

                    <Tabs
                        value={valueTabForAbstractFertilizer}
                        variant="scrollable"
                        onChange={this.handleChangeTab}
                        variant_pz="row"
                    >
                        {/* menu des onglets: ('buildPropsForTab' génère les ids entre les items de menu et leur contenu respectif) */}
                        {/* ---> TabAbstractFertilizer.NITROGEN_MANAGEMENT*/}
                        <Tab 
                            value={TabAbstractFertilizer.NITROGEN_MANAGEMENT}
                            component={Link}
                            to="#"
                            icon={<Add />}
                            label={(nativeAppHoster === true) ? StringTranslate.newFeaturesAlert : StringTranslate.newsNitrogenBoard}
                            {...buildPropsForTab('menuFerti', TabAbstractFertilizer.NITROGEN_MANAGEMENT)} /> 
                        
                        {/* ---> TabAbstractFertilizer.FERTILIZERS_LIST*/}
                        <Tab 
                            value={TabAbstractFertilizer.FERTILIZERS_LIST}
                            component={Link}
                            to="#"
                            icon={
                                <FertilizationIcon 
                                    sx={{ 
                                        stroke: theme.palette.grey[600],
                                        '&:selected': { stroke: theme.palette.primary.main }
                                    }} 
                                />
                            }
                            label={StringTranslate.tipsNitrogen}
                            {...buildPropsForTab('menuFerti', TabAbstractFertilizer.FERTILIZERS_LIST)} />
                            
                        {/* menu des onglets associés au "dernier apport" */}
                        {/* ---> TabAbstractFertilizer.SELECT_FOR_LASTINPUT*/}
                        {/* Onglets masqués si l'utilisateur n'a pas les droits; pour ses anciens, il les retrouvera dans sa consultation des conseils déjà générés */}
                        {(authorizeFertilizer === true) && 
                            <Tab
                                value={TabAbstractFertilizer.SELECT_FOR_LASTINPUT}
                                component={Link}
                                to="#"
                                icon={<Add />}
                                //label={(nativeAppHoster === true) ? StringTranslate.selectForLastInputNMobile : StringTranslate.selectForLastInputN}
                                label={(nativeAppHoster === true) ? StringTranslate.selectForLastInputNMobile : StringTranslate.selectForLastInputN}
                                {...buildPropsForTab('menuFerti', TabAbstractFertilizer.SELECT_FOR_LASTINPUT)} /> }

                        {/* ---> TabAbstractFertilizer.LASTINPUT_LIST*/}
                        <Tab
                            value={TabAbstractFertilizer.LASTINPUT_LIST}
                            component={Link}
                            to="#"
                            icon={
                                <FertilizationIcon 
                                    sx={{ 
                                        stroke: theme.palette.grey[600],
                                        '&:selected': { stroke: theme.palette.primary.main }
                                    }}  
                                />
                            }
                            //label={StringTranslate.tipsLastInputN}
                            label={StringTranslate.tipsLastInputN}
                            {...buildPropsForTab('menuFerti', TabAbstractFertilizer.LASTINPUT_LIST)} />

                    </Tabs>

                    {/* contenu des onglets: */}
                    <TabPanel value={valueTabForAbstractFertilizer} index={TabAbstractFertilizer.NITROGEN_MANAGEMENT} prefixId='menuFerti'>
                        {(askingAzofertDatesRange === false) ? (
                            (authorizeFertilizer === true) ? (
                                (this.engineProcessIsClose === true) ? 
                                    <Alert severity="info">
                                        <AlertTitle>{StringTranslate.titleInfoAlertFertilizers}</AlertTitle>
                                        {StringTranslate.textUnavailableAlertFertilizers}
                                        <strong>— {this.explainOpenDate}.</strong>
                                        {StringTranslate.textGetPastAlertFertilizers}
                                    </Alert> : <NitrogenStepsManagement {...this.props} />
                                ) : (
                                    <FertilizerAuthorization />
                                )
                        ) : <DataGridSkeleton />}
                    </TabPanel>

                    <TabPanel value={valueTabForAbstractFertilizer} index={TabAbstractFertilizer.FERTILIZERS_LIST} prefixId='menuFerti'>
                        <FertilizersList />
                    </TabPanel>

                    {/* Onglet masqué si l'utilisateur n'a pas les droits; pour ses anciens, il les retrouvera dans sa consultation des dernier apports déjà générés */}
                    {(authorizeFertilizer === true) && (<>
                        <TabPanel value={valueTabForAbstractFertilizer} index={TabAbstractFertilizer.SELECT_FOR_LASTINPUT} prefixId='menuFerti'>
                            <AbstractLastNitrogenInput newLastNitrogenInput />
                        </TabPanel>
                    </>)}

                    <TabPanel value={valueTabForAbstractFertilizer} index={TabAbstractFertilizer.LASTINPUT_LIST} prefixId='menuFerti'>
                        <AbstractLastNitrogenInput />
                    </TabPanel>
                    
                </MainCard>

        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    //Données concernant le contextAppData :
    isOpenMenuBurgerDesktop: lodashGet(state, 'contextAppData.isOpenMenuBurgerDesktop', false),

    //Données concernant le fertilizerData :
    showFertilizerMap: lodashGet(state, 'fertilizerData.showFertilizerMap', false),
    valueTabForAbstractFertilizer: lodashGet(state, 'fertilizerData.valueTabForAbstractFertilizer', TabAbstractFertilizer.NITROGEN_MANAGEMENT), //onglet (tab) par défaut quand on arrive sur la page des fumures (AbstractFertilizer)
    culturesForFertilizing: lodashGet(state, 'fertilizerData.culturesForFertilizing', []),

    showLastNitrogenInputMap: lodashGet(state, 'lastNitrogenInputData.showLastNitrogenInputMap', false),

    //Données concernant le ClientDatas :
    authorizeFertilizer: lodashGet(state, 'clientUserData.clientDatas.authorizeFertilizer', true),

    closingDateAzofert: lodashGet(state, 'fertilizerData.closingDateAzofert', ConstantsFertilizer.DefaultClosingDateAzofert),
    openingDateAzofert: lodashGet(state, 'fertilizerData.openingDateAzofert', ConstantsFertilizer.DefaultOpeningDateAzofert),
    askingAzofertDatesRange: lodashGet(state, 'fertilizerData.askingAzofertDatesRange', false),
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    initCulturesToStart: () => dispatch(ActionInitCultures()),
    setValueTabForAbstractFertilizer: (tab) => dispatch( ActionSelectFertilizerTab(tab) ),
    setCurrentPageName: (pageName) => dispatch( ActionSetCurrentPageName(pageName, false) ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AbstractFertilizer);