import React, { useCallback } from "react";
import { Button } from "@mui/material";
import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';
import BorderColorIcon from '@mui/icons-material/BorderColor';

//import getTheme from "../../../themes/index.js";
//let theme = getTheme();

/**
 * Composant bouton permettant de lancer le fonctionnel de dessin de parcelle
 */
function StartDrawButton({ drawParcelButtonControlCmp }) {

    /* callback */
    const onClick = useCallback(() => {
        try {
            drawParcelButtonControlCmp.current.handleClickDrawParcelButton();
        } catch (error) { }
    }, [drawParcelButtonControlCmp])

    return (
        <Button 
            sx={{
                pointerEvents: 'all',
                width:'185px',
                height: '104px'
            }} variant="contained" color="primary" onClick={onClick}>
            <BorderColorIcon />
            {StringTranslate.dessindrawparcel}
        </Button>
    )
};

export default StartDrawButton;