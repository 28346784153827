import React from 'react';
import { Spinner } from 'spin.js';
import { Box } from '@mui/material';

/* données de paramètrage de l'icône de chargement */
var options = {
    lines: 13, // The number of lines to draw
    length: 38, // The length of each line
    width: 17, // The line thickness
    radius: 45, // The radius of the inner circle
    scale: 0.40, // Scales overall size of the spinner
    corners: 1, // Corner roundness (0..1)
    color: 'black', // CSS color or array of colors
    fadeColor: 'transparent', // CSS color or array of colors
    speed: 0.7, // Rounds per second
    rotate: 0, // The rotation offset
    animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
    direction: 1, // 1: clockwise, -1: counterclockwise
    zIndex: 2e9, // The z-index (defaults to 2000000000)
    className: 'spinner', // The CSS class to assign to the spinner
    top: '50%', // Top position relative to parent
    left: '50%', // Left position relative to parent
    shadow: '0 0 1px transparent', // Box-shadow for the lines
    position: 'absolute' // Element positioning
};

/**
 * Composant Spinner
 */
class ReactSpinner extends React.Component {
    componentDidMount() {
        this.spinner = new Spinner(options);
        if (this.container && this.spinner)
            this.spinner.spin(this.container);
    }

    render() {
        return (
            <span ref={(container) => (this.container = container)} />
        )
    }
}

/**
 * Page contenant le spinner - A mettre dans un contenu où le css contient 'position: relative'
 */
class LoadingContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            spinningIcon: true, // affichage spinning icon
        }
    }

    render() {
        const { spinningIcon } = this.state;
        const { stopEvent } = this.props; // props permettant de stopper la propagation des évenements on Click
        //let classeName = '' + ((stopEvent) ? ' loadingContent-stop-event' : '');

        return (
            <Box
                sx={{
                    position:"absolute",
                    top:"0",
                    left:"0",
                    right:"0",
                    backgroundColor:"transparent",
                    zIndex:"600",
                    width:"100%",
                    height:"100%",
                    ...( stopEvent && {
                        pointerEvents:"unset"
                    })
                }}
            >
                { (spinningIcon) && <ReactSpinner /> }
            </Box>
        )
    }
}

export default LoadingContent;