import React from "react";
import { connect } from 'react-redux';

import { get as lodashGet } from 'lodash';

import {
    CircularProgress,
    Typography, Button, Link, Box, Grid, TextField, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, IconButton, TableContainer, Stack, Alert, Card
} from "@mui/material";

import CustomDataGrid from "../../components/customDataGrid";

import {
    ErrorOutline, Clear, PictureAsPdf, DeleteForeverSharp, Search, InfoOutlined
} from "@mui/icons-material";

import AlertDialog from '../alertDialog';

import numberHelper from '../../utils/numberHelper.js';
import stringHelper from '../../utils/stringHelper.js';
import { BiomassesHelper } from '../../utils/biomassesHelper.js';
import DateHelper from '../../utils/dateHelper.js';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

/* Actions redux */
import { ProfilIndex, ActionShowProfilMenuDialog } from "../../redux/actions/contextApp.js";
import { TableType, UpdateNbRowsPerPageTable } from '../../redux/actions/settings.js';
import { ActionDeleteBiomasses, ActionGenerateFileOfResumeBiomass, ActionGenerateFileOfThisBiomass,
    ActionGoToShowThisBiomass, ActionClearBiomassValue } from '../../redux/actions/biomass';

/* css */
import '../../assets/css/renderParcels.css';
import '../../assets/css/biomass.css';

///Composant affichant la liste des pesées de colza complètes présentes en BdD:
class BiomassesList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openConfirmDeleteBiomassDialog: false,
            biomassesToDelete: [],
            openErrorWeightingBiomassDialog: false,

            openConfirmDownloadWeightingBiomassDialog: false, //ouverture du dialog de téléchargement des pesées de biomasse colza
            weightingBiomasesToDownload: [],
            showFirstStepForDownloadingWeightingBiomasses: false, //Si le client a des pesées de biomasse dont les types d'apport sont différents, on visualise l'étape 1

            idBiomassGenerating: -1,
            recapBiomassGenerating: false,

            biomassesSelected: [], //Biomasses sélectionnées par les checkbox
            searchFilter: '', //Zone de recherche du tableau 
            currentRowsOfTable: [] //Datas en fonction du texte tapé dans la zone de recherche
        };

        this.popupErrorDialog = {
            getTitle: () => { return (<ErrorOutline />); },
            description: StringTranslate.errorDownloadWeightingBiomass, //erreur par défaut, qui sera remplacer par la prop 'errorWeightingBiomass' !
            button: StringTranslate.close,
        }

        this.onGenerateBiomassFileClick = this.onGenerateBiomassFileClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps && (prevProps.generatingFile === true) && (this.props.generatingFile !== true)) { //En cas de fin de génération !
            this.setState({ idBiomassGenerating: -1, recapBiomassGenerating: false, });
        }
    }

    //Toolbar customisé du tableau comprenant :
    // - la zone de recherche,
    // - les boutons de suppression et de téléchargement
    // - le bouton d'aide
    CustomToolbar(props) {
        return (
            <Grid container spacing={2} >
                {/* Zone de recherche */}
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={8} sm={8} md={4} lg={4}>
                            <TextField
                                size="small"
                                value={props.value}
                                onChange={props.onChange}
                                placeholder={StringTranslate.toolbarsearch}
                                InputProps={{
                                    startAdornment: <Search fontSize="small" />,
                                    endAdornment: (
                                        <IconButton
                                            title="Clear"
                                            aria-label="Clear"
                                            size="small"
                                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                                            onClick={props.clearSearch}
                                        >
                                            <Clear fontSize="small" />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Grid>
                        {/* Boutons de suppression et de téléchargement */}
                        <Grid item xs={4} sm={4} md={8}>
                            {/* Boutons en écran PC (>= md) */}
                            <Box sx={{ display: { md: 'block', xs: 'none' }, '& button': { m: 1 } }} style={{ textAlign: 'end' }} >
                                <Button
                                    variant="text" color="error"
                                    disabled={(props.biomassesSelected.length <= 0) || ((props.generatingFile === true) && (props.recapBiomassGenerating === true))}
                                    onClick={(evt, data) => props.deleteBiomasses(evt, props.biomassesSelected)}
                                >
                                    {StringTranslate.deleteWeightingBiomasses}
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={( ((props.generatingFile === true) && (props.recapBiomassGenerating === true)) || (props.biomassDicoCounter <= 0) )}
                                    startIcon={((props.generatingFile === true) && (props.recapBiomassGenerating === true)) && <CircularProgress color="inherit" size={25} />}
                                    onClick={(evt, data) => props.generateResumePdfBiomassByIds(evt, data)}>
                                    {props.biomassesSelected.length <= 0 ? StringTranslate.generateResumePdfWeightingAllBiomasses : props.formatStringButton(StringTranslate.generateResumePdfWeightingBiomasses, StringTranslate.generateResumePdfOneWeightingBiomass, props.biomassesSelected)}
                                </Button>
                            </Box>
                            {/* Boutons en écran Mobile (< md) */}
                            <Stack 
                                sx={{ display: { md: 'none', xs: 'block' }, '& button': { m: 1 }, pt:1 }} 
                                style={{ textAlign: 'end' }} 
                                direction="row" spacing={1}
                            >
                                <IconButton
                                    disabled={(props.biomassesSelected.length <= 0) || ((props.generatingFile === true) && (props.recapBiomassGenerating === true))}
                                    color="error"
                                    size="large"
                                    aria-label="delete biomass(es)"
                                    component="span"
                                    onClick={(evt, data) => props.deleteBiomasses(evt, props.biomassesSelected)}
                                    sx={{p:0}}
                                >
                                    <DeleteForeverSharp />
                                </IconButton>

                                <IconButton
                                    color="primary"
                                    size="large"
                                    aria-label="download pdf(s)"
                                    component="span"
                                    disabled={((props.generatingFile === true) && (props.recapBiomassGenerating === true))}
                                    onClick={(evt, data) => props.generateResumePdfBiomassByIds(evt, data)}
                                    sx={{p:0}}
                                >
                                    {((props.generatingFile === true) && (props.recapBiomassGenerating === true)) ? <CircularProgress color="inherit" size={25} /> : <PictureAsPdf />}
                                </IconButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Bouton d'aide */}
                <Grid item xs={6}>
                    <Button color="secondary" variant="text" onClick={() => props.showProfilMenuDialog()}>
                        {StringTranslate.helpAsk}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    //Sélection des biomasses
    setSelectionBiomasses = (newSelectionBiomasses) => {
        this.setState({
            biomassesSelected: newSelectionBiomasses
        });
    }


    //Titre des colonnes de la table des biomasses
    getColumnsTable() {

        const columns = [
            {
                headerName: 'id',
                field: 'id',
                hideable: false
            },
            {
                headerName: StringTranslate.ColumnPclName,
                field: 'parcelName',
                minWidth: 150,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.ColumnPclName}</Typography>)
                },
                renderCell: (params) => {
                    return (<Typography className="select-biomass" onClick={() => this.onShowBiomassRowClick(params.row.id)}>{params.value}</Typography>)
                }
            },
            {
                headerName: StringTranslate.ColumnBioBeginningWinter + " / " + StringTranslate.ColumnBioEndingWinter,
                field: 'winterTime',
                minWidth: 110,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.ColumnBioBeginningWinter + " / " + StringTranslate.ColumnBioEndingWinter}</Typography>)
                },
                renderCell: (params) => {
                    return (<Typography className="select-biomass" onClick={() => this.onShowBiomassRowClick(params.row.id)}>{params.value}</Typography>)
                }
            },
            {
                headerName: StringTranslate.ColumnBioDateWinter,
                field: 'imagSourceDate',
                minWidth: 140,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.ColumnBioDateWinter}</Typography>)
                },
                renderCell: (params) => {
                    return (<Typography className="select-biomass" onClick={() => this.onShowBiomassRowClick(params.row.id)}>{params.value}</Typography>)
                }
            },
            {
                headerName: StringTranslate.ColumnBioWeighting,
                field: 'averageQuantity',
                minWidth: 120,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.ColumnBioWeighting}</Typography>)
                },
                renderCell: (params) => {
                    return (<Typography className="select-biomass" onClick={() => this.onShowBiomassRowClick(params.row.id)}>{params.value}</Typography>)
                }
            },
            {
                headerName: StringTranslate.pdf,
                field: 'pdf',
                minWidth: 80,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.pdf}</Typography>)
                },
                renderCell: (params) => {
                    return (
                        <>
                            { ((this.props.generatingFile === true) && (this.props.idBiomassGenerating === params.row.id)) ?
                                <CircularProgress size={20} color="primary" />
                                : 
                                <IconButton
                                    color="primary"
                                    onClick={(event) => this.onGenerateBiomassFileClick(event, params.row.id)}
                                >
                                    <PictureAsPdf />
                                </IconButton> }
                        </>
                    )
                }
            }
        ];

        return columns;
    }

    //Données des biomasses pour remplir le tableau
    getContentTable() {
        const { biomassDico, biomassDicoCounter, parcelDico } = this.props;

        let rowsOfTable = [];
        if (biomassDico && (biomassDicoCounter > 0)) {

            for (const key in biomassDico) {
                const biomassItem = biomassDico[key];

                // RQ: Si la parcelle n'existe pas ou plus, les biomasses en liaison à cette parcelle ne doivent
                // pas s'afficher!
                let parcelOfBiomass = parcelDico[biomassItem.parameter.parcelId];
                if ((parcelOfBiomass !== undefined) && (parcelOfBiomass !== null)) {
                    //RQ: Même si certaines données ne sont pas affichées, elles sont exploitées dans du fonctionnel 
                    // (Ex: sélection de weightingbiomass pour téléchargement -> Cf. 'onDownLoadWeightingBiomasses')
                    rowsOfTable.push(
                        {
                            id: biomassItem.id,
                            parcelId: biomassItem.parameter.parcelId,
                            clientId: biomassItem.parameter.clientId,
                            sizeImg: biomassItem.sizeImg,
                            imagSourceDateBeginningWinter: (biomassItem.parameter.isBeginningWinter === true) ? DateHelper.formati18n(new Date(biomassItem.parameter.dateImagSource), 'P') : null,
                            isBeginningWinter: biomassItem.parameter.isBeginningWinter,
                            imagSourceDateEndingWinter: (biomassItem.parameter.isBeginningWinter === false) ? DateHelper.formati18n(new Date(biomassItem.parameter.dateImagSource), 'P') : null,
                            imagSourceDate: DateHelper.formati18n(new Date(biomassItem.parameter.dateImagSource), 'P'),
                            winterTime: (biomassItem.parameter.isBeginningWinter === true) ? StringTranslate.ColumnBioBeginningWinter.substring(0, 1) : StringTranslate.ColumnBioEndingWinter.substring(0, 1),
                            averageQuantity: numberHelper.fixeDecimal(biomassItem.averageQuantity),
                            parcelName: biomassItem.parameter.parcelName,
                            creationDate: DateHelper.formati18n(new Date(biomassItem.creationDate), 'P'),
                            pdf: biomassItem.id,
                            zones: biomassItem.zones,
                        }
                    );
                }

            }
        }

        //Tri des lignes par l'id des parcelles
        return BiomassesHelper.sortByParcel(rowsOfTable);
    }

    /* Lance l'affichage sur le panneau du dessous (composant '') les détails de cette biomasse */
    onShowBiomassRowClick(biomassId) {
        const { biomassDico, clearBiomassValue, goToShowThisBiomass } = this.props;
        try {
            const biomassSelected = biomassDico[biomassId];
            clearBiomassValue(); //si une pesée de biomasse colza a déjà été sélectionnée auparavant, vider les valeurs de la biomasse :

            goToShowThisBiomass(biomassSelected); //Rq: Force le mode "consultation" !
        }
        catch (err) { }
    }

    onGenerateBiomassFileClick(event, biomassId) {
        if (event) { //pour ne pas sélectionner la ligne !
            event.preventDefault();
            event.stopPropagation(); // Really this time.
        }

        const { biomassDico, generateBiomassFile } = this.props;

        this.setState({ idBiomassGenerating: biomassId, });

        //recherche l'entité de biomass visée via son id, puis appel à la génération du pdf:
        try {
            const biomassSelected = biomassDico[biomassId];

            generateBiomassFile(biomassSelected);
        }
        catch (err) { }
    }

    /* ↓↓↓ Suppression des pesées de biomasse ↓↓↓ */
    /* Demande de suppression des pesées de biomasse sélectionnées en fonction des ids donnés en paramètre */
    onDeleteBiomassByIds(event, biomasses) {
        const { biomassDico } = this.props;

        let biomassesToDelete = [];
        biomasses.forEach(biomassId => {
            let biomassSelected = biomassDico[biomassId];
            if (biomassSelected) {
                biomassesToDelete.push({
                    id: biomassSelected.id,
                    parcelId: biomassSelected.parcelId,
                    isBeginningWinter: biomassSelected.isBeginningWinter //Pour supprimer la bonne pesée (sortie ou entrée d'hiver!)
                });
            }
        });

        this.setState({
            openConfirmDeleteBiomassDialog: true,
            biomassesToDelete: biomassesToDelete
        });
    }

    /* ↓↓↓ Génère le pdf récapitulatif de certaines pesées de biomasse ↓↓↓ */
    /* Soit de la totalité, si aucune sélection | Soit uniquement des pesées sélectionnées */
    onGenerateResumePdfBiomassByIds(event, biomasses) {
        const { generateResumeBiomassFile, biomassDico } = this.props;
        const { biomassesSelected } = this.state;

        this.setState({ recapBiomassGenerating: true, });

        // Récupération des parcelIds associées aux biomasses
        let parcelIdsOfBiomassSelected = [];
        biomassesSelected.forEach(biomassId => {
            parcelIdsOfBiomassSelected.push(biomassDico[biomassId].parameter?.parcelId)
        })
        // Si la liste est vide et on génèrera tout ce que l'on trouve en BdD !!!

        //on appelle à la génération du pdf de récap (potentiellement avec une liste vide !):
        generateResumeBiomassFile(parcelIdsOfBiomassSelected);
    }

    /* Désaffiche la boite de dialog pour la suppression des pesées de colza */
    handleCloseConfirmDialogForDeleting = () => {
        this.setState({
            openConfirmDeleteBiomassDialog: false,
            biomassesToDelete: []
        });
    }

    /* Fonction de suppression des pesées de colza */
    onConfirmDeleteBiomassByIds = () => {
        const { biomassesToDelete, currentRowsOfTable } = this.state;
        const { deleteBiomassByIds, biomassDico } = this.props;
   
        if (biomassesToDelete && biomassesToDelete.length > 0) {
            let biomasses = [];
            let biomassIds = [];
            
            biomassesToDelete.forEach(item => {
                if (item.id !== undefined) {
                    const biomass = biomassDico[item.id];
                    biomassIds.push(item.id);
                    biomasses.push({
                        id: biomass.id,
                        parcelId: biomass.parameter.parcelId,
                        isBeginningWinter: biomass.parameter.isBeginningWinter,
                    });
                }
            });

            if (biomasses && biomasses.length > 0) {
                deleteBiomassByIds(biomasses);
                
                //Si on sélectionne plusieurs biomasses, qu'ensuite, on fait une recherche,
                //il faut que si on supprime des biomasses qui sont dans la liste 'currentRowsOfTable' (liste filtrée après la recherche),
                //que ces biomasses là ne soient pas gardées dans la liste 'currentRowsOfTable' !
                //Attention au paramètre 'keepNonExistentRowsSelected'.
                let newcurrentRowsOfTable = currentRowsOfTable.filter(row => biomassIds.includes(row.id) === false);
                
                this.setState({
                    openConfirmDeleteBiomassDialog: false,
                    biomassesToDelete: [],
                    biomassesSelected: [],
                    currentRowsOfTable: newcurrentRowsOfTable,
                });
            }
        }
    }
    /* ↑↑↑ Suppression des pesée de colza ↑↑↑ */

    requestSearch = (searchValue, rowsOfTable) => {
        this.setState({
            searchFilter: searchValue
        });

        try {
            const searchRegex = new RegExp(stringHelper.escapeRegExp(searchValue), 'i');
            const filteredRows = rowsOfTable.filter((row) => {
                return Object.keys(row).some((field) => {
                    let textValue = "" + row[field];
                    return searchRegex.test(textValue.toString());
                });
            });

            this.setState({
                currentRowsOfTable: filteredRows
            });
        }
        catch(errRegex) { /* Peut arriver si on saisis un truc du genre 'EARL++' ! (il n'aime pas les '++') */ }
    }


    formatStringButton = (textValue, textForOnlyOneValue, datasSelection) => {
        if ((!datasSelection) || (!Array.isArray(datasSelection)) || (textValue.indexOf('{0}') < 0)) {
            if ((textValue) && (textValue !== '')) {
                return textValue;
            } else {
                return textForOnlyOneValue;
            }
        } else if ((textForOnlyOneValue) && (textForOnlyOneValue !== '') && (datasSelection.length === 1)) {
            return textForOnlyOneValue;
        } else {
            return StringTranslate.formatString(textValue, datasSelection.length);
        }
    }

    render() {
        const { biomassDicoCounter, loadWaiting, errorWeightingBiomass, showProfilMenuDialog, generatingFile, rowsPerPageForTableBiomasses } = this.props;
        const { openConfirmDeleteBiomassDialog, openErrorWeightingBiomassDialog, idBiomassGenerating, recapBiomassGenerating,
            biomassesSelected, searchFilter, currentRowsOfTable } = this.state;

        const rowsOfTable = this.getContentTable();

        return (
            <>
                {/* ↓↓ Partie visuel - affichage Popup pour la suppression des pesées de biomasse ↓↓ */}
                <Dialog open={openConfirmDeleteBiomassDialog}
                    onClose={this.handleCloseConfirmDialogForDeleting}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{StringTranslate.titleDeleteBiomasses}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{StringTranslate.confirmDeleteBiomassesSelected}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseConfirmDialogForDeleting} variant="text" color="error" autoFocus>
                            {StringTranslate.cancelDeleteAction}
                        </Button>
                        <Button onClick={this.onConfirmDeleteBiomassByIds} variant="contained" color="primary">
                            {StringTranslate.confirmDeleteAction}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* ↓↓ Partie visuel - affichage Pop d'une erreur ↓↓ */}
                {((this.downloadAsking === true) && errorWeightingBiomass && (errorWeightingBiomass !== '')) &&
                    (openErrorWeightingBiomassDialog === true) && (
                    <AlertDialog popup={this.popupErrorDialog} handleChangePopup={this.handleCloseErrorDialog} />
                )}

                <Grid container spacing={1} mb={1}>
                    <Grid item xs={12}>
                        <Card>
                            <Alert
                                severity="info"
                                icon={<InfoOutlined />}
                            >
                                <Typography>{StringTranslate.biomassesCertifTerresInovia}</Typography>
                            </Alert>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <Alert
                                severity="info"
                                icon={<InfoOutlined />}
                            >
                                {(loadWaiting === true) && (
                                    <CircularProgress fontSize='small' classes={{ root: 'mdlFiles-tableSummary-circularprogress' }} />
                                )}
                                <Typography>{StringTranslate.linkResultsWeightings} <Link href="http://www.regletteazotecolza.fr/#/etape1" target="_blank">{StringTranslate.clicHere}</Link>.</Typography>
                            </Alert>
                        </Card>
                    </Grid>
                </Grid>

                <TableContainer>
                    <Grid style={{ width: '100%' }} item xs={12}>
                        <CustomDataGrid
                            tableName={TableType.biomasses}
                            pageSize={rowsPerPageForTableBiomasses}
                            updateNbRowsPerPageTable={this.props.updateNbRowsPerPageTableBiomasses}
                            onSelectionModelChange={(newSelectionBiomasses) => { //Sélection des biomasses par clic checkbox
                                this.setSelectionBiomasses(newSelectionBiomasses);
                            }}
                            keepNonExistentRowsSelected={searchFilter !== "" ? true : false}
                            selectionModel={biomassesSelected}
                            Toolbar={this.CustomToolbar}
                            toolbar={{
                                showProfilMenuDialog: () => showProfilMenuDialog(ProfilIndex.aide_PeseeDeBiomasse),
                                deleteBiomasses: (evt, data) => this.onDeleteBiomassByIds(evt, data),
                                biomassesSelected: biomassesSelected,
                                generateResumePdfBiomassByIds: (evt, data) => this.onGenerateResumePdfBiomassByIds(evt, data), //Rq: présent mais ne sert pas puisque le bouton est tout le temps désactivé !
                                formatStringButton: (generalText, textForOneItem, data) => this.formatStringButton(generalText, textForOneItem, data),
                                generatingFile: generatingFile,
                                recapBiomassGenerating: recapBiomassGenerating,
                                idBiomassGenerating: idBiomassGenerating,
                                rowsOfTable: rowsOfTable,
                                biomassDicoCounter: biomassDicoCounter,
                                //Concerne la zone de recherche dans le tableau :
                                value: searchFilter,
                                onChange: (event) => this.requestSearch(event.target.value, rowsOfTable),
                                clearSearch: () => this.requestSearch('', rowsOfTable)
                            }}
                            loadWaiting={loadWaiting}
                            rows={(searchFilter !== "") ? currentRowsOfTable : rowsOfTable}
                            columns={this.getColumnsTable()}
                            checkBoxActive={true}
                        />
                    </Grid>
                </TableContainer>

            </>
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = function (state) {
    return {
        //Infos provenant du reducer 'biomasses':
        biomassDicoCounter: lodashGet(state, 'biomassData.biomassDicoCounter', 0),
        loadWaiting: lodashGet(state, 'biomassData.loading', false), //@@RQ: Il en faudrait une lors des appel aux différents 'Build...' !
        generatingFile: lodashGet(state, 'biomassData.generatingFile', false),
        biomassDico: lodashGet(state, 'biomassData.biomassDico', {}),

        //Infos provenant du reducer 'parcels':
        parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
        
        //Infos provenant du reducer 'settings':
        rowsPerPageForTableBiomasses: lodashGet(state, 'settingsData.settings.rowsPerPageForTableBiomasses', 20),
    };
}

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
    showProfilMenuDialog: (index) => dispatch(ActionShowProfilMenuDialog(index)),

    /* Tableau des biomasses */
    deleteBiomassByIds: (biomassIds) => dispatch(ActionDeleteBiomasses(biomassIds)),
    generateResumeBiomassFile: (parcelSelectedIds) => dispatch(ActionGenerateFileOfResumeBiomass(parcelSelectedIds)),
    generateBiomassFile: (biomassSelectedItem) => dispatch(ActionGenerateFileOfThisBiomass(biomassSelectedItem)),
    goToShowThisBiomass: (biomassSelectedItem) => dispatch(ActionGoToShowThisBiomass(biomassSelectedItem)),
    updateNbRowsPerPageTableBiomasses: (nbRowsPerPage) => dispatch(UpdateNbRowsPerPageTable(nbRowsPerPage, TableType.biomasses)),
    
    /* Biomasse */
    clearBiomassValue: () => dispatch(ActionClearBiomassValue()),
})

export default connect(mapStateToProps, mapDispatchToProps)(BiomassesList);