import React from 'react';

import {
    Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Card, CardContent
} from '@mui/material';

import '../assets/css/confirmDialog.css';


class AlertDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.popup ? true : false,
            //open: props.popup && props.popup !== {} ? true : false,
        };
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClickOpen() {
        this.setState({ open: true });
    };

    handleClose() {
        this.setState({ open: false });
        const { handleChangePopup } = this.props;
        handleChangePopup();
    };

    render() {
        const { open } = this.state;
        const { popup } = this.props;

        return (
            <Dialog
                open={open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableEscapeKeyDown={true}>
                
                {(popup.getTitle !== undefined) && 
                    <DialogTitle id="alert-dialog-title">{popup.getTitle()}</DialogTitle>}
                
                <DialogContent id="alert-dialog-content">

                    <Card>
                        <CardContent sx={{ pt: 0 }}>
                            <DialogContentText id="alert-dialog-description">
                                {popup.description}
                            </DialogContentText>
                            { (popup.getAdditionalDescription !== null) && 
                              (popup.getAdditionalDescription !== undefined) && 
                              (popup.getAdditionalDescription !== "") && 
                              <DialogContentText id="alert-dialog-description">
                                  {popup.getAdditionalDescription()}
                              </DialogContentText> 
                            }
                        </CardContent>
                    </Card>

                </DialogContent>

                <DialogActions>

                    <Button variant="text" onClick={this.handleClose} color="primary" autoFocus>
                        {popup.button}
                    </Button>

                </DialogActions>
            </Dialog>
        );
    }
}

export default AlertDialog;