import sha1 from 'js-sha1';


const CryptoHelper = {
    //converti une chaîne, en un tableau de byte
    FromHexString(hexValue) {
        //ex: return [0x10, 'f', 0xDF];        
        if ((!hexValue) || (hexValue.length <= 0)) return undefined;
        if ((hexValue.length % 2) !== 0) return [];

        const byteLength = hexValue.length / 2;
        const hexValueLower = hexValue.toLowerCase();
        let bytesResult = [];
        try {
            for (var i = 0; i < byteLength; i++)
            {
                const newbyte = parseInt(hexValueLower.substr(i * 2, 2), 16);
                bytesResult.push(newbyte);
            }
        }
        catch (e) {
            return undefined;
        }

        return bytesResult;
    },

    DecodeBytesToString(bytesValue, sha1Value) {
        let bytesResult = undefined;
        let strResult = undefined;
        if ((!bytesValue) || (!sha1Value) || (sha1Value.length <= 0)) return strResult;

        bytesResult = [];
        strResult = '';
        if (bytesValue.length <= 0) return strResult;

        const xOffset = bytesValue[0] & 0x0F;
        const lengthValue = bytesValue.length;

        try {
            if (xOffset === 0) {// copie de tout les octets, suaf le premier:
                    for (var i = 1; i < lengthValue; i++)
                    {
                        bytesResult.push(bytesValue[1]);
                    }
            } else {
                const maskLowBits = ((0x00FF << xOffset) & 0xFF);
                const maskMstBits = (0x00FF - maskLowBits);
                for (var ii = 1; ii < lengthValue; ii++) {
                    bytesResult.push(0);
                }

                for (var index = (lengthValue - 1); index > 0; index--) {
                    let itemByte = bytesValue[index]; // octet courant.
                    bytesResult[index - 1] += ((itemByte & maskLowBits) >> xOffset);

                    if ((index - 1) > 0) { // Il y a un octet encore avant...
                        itemByte = bytesValue[index - 1]; // octet précédent.
                        bytesResult[index - 1] += ((itemByte & maskMstBits) << (8 - xOffset));
                    } else { // L'octet courant est le premier...
                        // on doit alors compléter le premier byte "de valeur" afin d'injecter
                        itemByte = bytesValue[lengthValue - 1]; // dernier octet.
                        bytesResult[0] += ((itemByte & maskMstBits) << (8 - xOffset));
                    }
                }
            }
        }
        catch (Exception) {
            bytesResult = null;
        }

        if ((bytesResult) && (bytesResult.length === (lengthValue - 1))) //sans l'offset !
        {
            try {
                const bytesHash = sha1(bytesResult);
                const sha1ValueMaster = bytesHash.toString('hex');

                if ((!sha1ValueMaster) || (sha1ValueMaster !== sha1Value)) {
                    bytesResult = null;
                } else {
                    for (var iii = 0; iii < (lengthValue - 1); iii++) {
                        strResult += String.fromCharCode(bytesResult[iii]); //parseInt(bytesResult[i], 2));
                    }
                }
            }
            catch (Exception)
            {
                bytesResult = null;
            }
        }

        return strResult;
    },

    encodeStringToCredential(strValue) {
        //RQ: Retournera un objet contenant deux propriétés:
        let result = {
            pwdCrypted: '', //chaîne Hexa !
            shaOneOfPwd: '',
        }

        //@@CAn

        return result;
    }
}

export default CryptoHelper;
