import lodashGet from 'lodash/get';
import { settingsWebApiProvider } from '../../utils/webApiProvider.js';
import ConstantsLayers, { GetBaseLayerIndex, GetLastLayerIndex } from '../../utils/constantsOfLayers.js';
import { ActionSelectLayerOfParcel } from './parcels.js';
import { ActionAskUpdateAllSatimageForAllParcels, ActionAskReloadAllSatimageForAllParcels } from './satImage.js';
import { IsNativeHoster } from '../../utils/platformHelper';


//RQ Générale: Même si on va charger le paramétrage dans un seul appel à la Web API (lors de la recherche de l'email client), 
//  on aura sans nulle doute besoin d'appeler les actions définies ici ; 
//  -> les actions simples pour notifier le reducer associé (et donc la partie du store Redux stockant les infos du paramétrage client).
//  -> l'actions complète (processus d'intérrogation de la Web API) pour du fonctionnel tel que la mise à jour/modification du paramétrage client.

const LOADING_SETTINGS = 'LOADING_SETTINGS';
const SETTINGS_LOADED = 'SETTINGS_LOADED';
const ERROR_LOAD_SETTINGS = 'ERROR_LOAD_SETTINGS';

const UPDATING_SETTINGS = 'UPDATING_SETTINGS';
const SETTINGS_UPDATED = 'SETTINGS_UPDATED';
const SAVE_SETTINGS = 'SAVE_SETTINGS';
const RESETING_SETTINGS = 'RESETING_SETTINGS';
const SAVE_RESETED_SETTINGS = 'SAVE_RESETED_SETTINGS';

const SET_DEFAULT_LANG = 'SET_DEFAULT_LANG';
const SET_LANG = 'SET_LANG';

const SELECT_LAYER = 'SELECT_LAYER';

const SAVE_REMEMBER_MODULATION_PARAMETERS = 'SAVE_REMEMBER_MODULATION_PARAMETERS';

const UPDATE_NB_ROWS_PER_PAGE_TABLE_PARCELS = 'UPDATE_NB_ROWS_PER_PAGE_TABLE_PARCELS';
const UPDATE_NB_ROWS_PER_PAGE_TABLE_MODULATIONS = 'UPDATE_NB_ROWS_PER_PAGE_TABLE_MODULATIONS';
const UPDATE_NB_ROWS_PER_PAGE_TABLE_BIOMASSES = 'UPDATE_NB_ROWS_PER_PAGE_TABLE_BIOMASSES';
const UPDATE_NB_ROWS_PER_PAGE_TABLE_OBSERVATIONS = 'UPDATE_NB_ROWS_PER_PAGE_TABLE_OBSERVATIONS';
const UPDATE_NB_ROWS_PER_PAGE_TABLE_MODELISATIONS = 'UPDATE_NB_ROWS_PER_PAGE_TABLE_MODELISATIONS';
const UPDATE_NB_ROWS_PER_PAGE_TABLE_FERTILIZERS = 'UPDATE_NB_ROWS_PER_PAGE_TABLE_FERTILIZERS';
const UPDATE_NB_ROWS_PER_PAGE_TABLE_HARVESTS = 'UPDATE_NB_ROWS_PER_PAGE_TABLE_HARVESTS';

const DISPLAY_LANDSAT8_IMG = 'DISPLAY_LANDSAT8_IMG';

/* Enuméré des actions pour la gestion du paramétrage: */
export const ActionTypeSettings = {
    LoadingSettings: LOADING_SETTINGS,
    SettingsLoaded: SETTINGS_LOADED,
    ErrorLoadSettings: ERROR_LOAD_SETTINGS,
    UpdatingSettings: UPDATING_SETTINGS,
    ResetingSettings: RESETING_SETTINGS,
    SetDefaultLanguage: SET_DEFAULT_LANG,
    SelectLayer: SELECT_LAYER,
    SetLanguage: SET_LANG,
    saveSettings: SAVE_SETTINGS,
    settingsUpdated: SETTINGS_UPDATED,
    saveResetedSettings: SAVE_RESETED_SETTINGS,
    SaveRememberModulaitonParameters: SAVE_REMEMBER_MODULATION_PARAMETERS,
    UpdateNbRowsPerPageTableParcels: UPDATE_NB_ROWS_PER_PAGE_TABLE_PARCELS,
    UpdateNbRowsPerPageTableModulations: UPDATE_NB_ROWS_PER_PAGE_TABLE_MODULATIONS,
    UpdateNbRowsPerPageTableObservations: UPDATE_NB_ROWS_PER_PAGE_TABLE_OBSERVATIONS,
    UpdateNbRowsPerPageTableBiomasses: UPDATE_NB_ROWS_PER_PAGE_TABLE_BIOMASSES,
    UpdateNbRowsPerPageTableModelisations: UPDATE_NB_ROWS_PER_PAGE_TABLE_MODELISATIONS,
    UpdateNbRowsPerPageTableFertilizers: UPDATE_NB_ROWS_PER_PAGE_TABLE_FERTILIZERS,
    UpdateNbRowsPerPageTableHarvests: UPDATE_NB_ROWS_PER_PAGE_TABLE_HARVESTS,
    displayLandsatImgs: DISPLAY_LANDSAT8_IMG,
}
export const TableType = {
    parcels: "parcels",
    modulations: "modulations",
    observations: "observations",
    biomasses: "biomasses",
    modelisations: "modelisations",
    fertilizers: "fertilizers",
    harvests: "harvests",
    harvestVarieties: "harvestVarieties",
};

/* Modification du nombre de lignes à afficher par page dans le tableau des parcelles */
function UpdateNbRowsPerPageTableParcels(rowsPerPage) {
    return {
        type: UPDATE_NB_ROWS_PER_PAGE_TABLE_PARCELS,
        rowsPerPageForTableParcels: rowsPerPage
    };
}

/* Modification du nombre de lignes à afficher par page dans le tableau des modulations */
function UpdateNbRowsPerPageTableModulations(rowsPerPage) {
    return {
        type: UPDATE_NB_ROWS_PER_PAGE_TABLE_MODULATIONS,
        rowsPerPageForTableModulations: rowsPerPage
    };
}

/* Modification du nombre de lignes à afficher par page dans le tableau des observations */
function UpdateNbRowsPerPageTableObservations(rowsPerPage) {
    return {
        type: UPDATE_NB_ROWS_PER_PAGE_TABLE_OBSERVATIONS,
        rowsPerPageForTableObservations: rowsPerPage
    };
}

/* Modification du nombre de lignes à afficher par page dans le tableau des biomasses */
function UpdateNbRowsPerPageTableBiomasses(rowsPerPage) {
    return {
        type: UPDATE_NB_ROWS_PER_PAGE_TABLE_BIOMASSES,
        rowsPerPageForTableBiomasses: rowsPerPage
    };
}

/* Modification du nombre de lignes à afficher par page dans le tableau des biomasses */
function UpdateNbRowsPerPageTableModelisations(rowsPerPage) {
    return {
        type: UPDATE_NB_ROWS_PER_PAGE_TABLE_MODELISATIONS,
        rowsPerPageForTableModelisations: rowsPerPage
    };
}

/* Modification du nombre de lignes à afficher par page dans le tableau des fumures */
function UpdateNbRowsPerPageTableFertilizers(rowsPerPage) {
    return {
        type: UPDATE_NB_ROWS_PER_PAGE_TABLE_FERTILIZERS,
        rowsPerPageForTableFertilizers: rowsPerPage
    };
}

/* Modification du nombre de lignes à afficher par page dans le tableau des récoltes */
function UpdateNbRowsPerPageTableHarvests(rowsPerPage) {
    return {
        type: UPDATE_NB_ROWS_PER_PAGE_TABLE_HARVESTS,
        rowsPerPageForTableHarvests: rowsPerPage
    };
}

/* Actions: */
export function UpdateNbRowsPerPageTable(rowsPerPage, table) {
    return function (dispatch, getState) {
        // ↓ mise à jour de l'objet settings ↓
        dispatch(ActionUpdatingSettings());

        return settingsWebApiProvider.updateRowsPerPageTable(dispatch, getState, rowsPerPage, table)
            .then((response) => {
                if (table === TableType.parcels) {
                    dispatch(UpdateNbRowsPerPageTableParcels(rowsPerPage));
                }
                else if (table === TableType.modulations) {
                    dispatch(UpdateNbRowsPerPageTableModulations(rowsPerPage));
                }
                else if (table === TableType.biomasses) {
                    dispatch(UpdateNbRowsPerPageTableBiomasses(rowsPerPage));
                }
                else if (table === TableType.modelisations) {
                    dispatch(UpdateNbRowsPerPageTableModelisations(rowsPerPage))
                }
                else if (table === TableType.fertilizers) {
                    dispatch(UpdateNbRowsPerPageTableFertilizers(rowsPerPage));
                }
                else if (table === TableType.harvests) {
                    dispatch(UpdateNbRowsPerPageTableHarvests(rowsPerPage));
                }
                else {
                    dispatch(UpdateNbRowsPerPageTableObservations(rowsPerPage));
                }
            })
            .catch((error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorLoadSettings(error));
            });
    }
}

export function ActionSaveRememberModulationParameters(rememberValue) {
    return function (dispatch, getState) {
        // ↓ mise à jour de l'objet settings ↓
        dispatch(ActionUpdatingSettings());

        // ↓ appel API ↓
        return settingsWebApiProvider.updateRememberModulationSettings(dispatch, getState, rememberValue)
            .then((response) => { //La réponse est juste 'ok' ou 'pas ok'.
                // Signale que l'on a bien mis à jour le paramètrage:
                dispatch(ActionRememberModulationSettingsSaved(rememberValue));
            })
            .catch((error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorLoadSettings(error));
            });
    }
}

function ActionRememberModulationSettingsSaved(rememberValue) {
    return {
        type: SAVE_REMEMBER_MODULATION_PARAMETERS,
        rememberModulationSettings: rememberValue
    }
}

export function ActionLoadingSettings() {
    return {
        type: LOADING_SETTINGS,
    };
}

/*export*/ function ActionUpdatingSettings() {
    return {
        type: UPDATING_SETTINGS,
    };
}

export function ActionSettingsUpdated() {
    return {
        type: SETTINGS_UPDATED,
    };
}

export function ActionResetingSettings() {
    return {
        type: RESETING_SETTINGS,
    };
}

export function ActionSettingsLoaded(settingsValue) {
    return {
        type: SETTINGS_LOADED,
        settings: settingsValue,
    };
}

export function ActionErrorLoadSettings(errorValue) {
    return {
        type: ERROR_LOAD_SETTINGS,
        errorMessage: errorValue,
    };
}

export function ActionSaveSettings(settingsToSave) {
    return {
        type: SAVE_SETTINGS,
        settings: settingsToSave,
    }
}

export function ActionSaveResetedSettings(settingsToSave) {
    return {
        type: SAVE_RESETED_SETTINGS,
        settings: settingsToSave,
    }
}

/* fonction de sauvegarde d'un objet settings */
export function ActionSaveSettingsAsk(settingsToSave) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        let newSettings = settingsToSave;

        const currentStoreState = getState();
        const currentSettings = lodashGet(currentStoreState, `settingsData.settings`, undefined);

        // ↓ Obtient l'objet settings courant ↓
        if (!settingsToSave) {
            const currentBaseLayerName = lodashGet(currentStoreState, `settingsData.baseLayerSelected`, undefined);
            const currentForewardLayerName = lodashGet(currentStoreState, `settingsData.forewardLayerSelected`, undefined);
            if (currentSettings && currentBaseLayerName && currentForewardLayerName) {
                newSettings = {
                    ...currentSettings,
                    baseLayer: GetBaseLayerIndex(currentBaseLayerName),
                    lastLayer: GetLastLayerIndex(currentForewardLayerName),
                }
            }
        }

        if (newSettings) {
            // Si on (l'application web) est hébergée dans l'appli native, il faut avertir celle-ci d'un potentiel changement de langue:
            if ((IsNativeHoster() === true) && userAgentAuthent && userAgentAuthent.commProviderWithNativeApp &&//Si l'appli Web est lancée dans l'appli Native...
                ((!currentSettings) || (currentSettings.language !== newSettings.language))) { //si seulement la langue à changée !

                userAgentAuthent.commProviderWithNativeApp.notifyChangeLanguage();
            }

            // Flag permettant de lancer ou non la mise à jour la liste des images par parcelle:
            const mustUpdateSatimages = (currentSettings && (newSettings.parcelMaxcc > currentSettings.parcelMaxcc)) ? true : false; //anciennement 'haveToUpdateToAPI'
            const mustReloadSatimages = (currentSettings && (newSettings.parcelMaxcc < currentSettings.parcelMaxcc)) ? true : false; //anciennement 'haveToUpdateToAPI'

            // ↓ mise à jour de l'objet settings ↓
            dispatch(ActionUpdatingSettings());

            // ↓ appel API ↓
            return settingsWebApiProvider.updateSettings(dispatch, getState, newSettings)
                .then((response) => { //La réponse est juste 'ok' ou 'pas ok'.
                    // Signale que l'on a bien mis à jour le paramètrage:
                    dispatch(ActionSettingsLoaded(newSettings));

                    // Si l'on vient d'augmenter le taux d'enuagement autorisé, on demande la mise à jour des images désormais 'valides' 
                    // et actualisation de la liste d'images de chaque parcelle:
                    if (mustUpdateSatimages && (mustUpdateSatimages === true)) {
                        dispatch(ActionAskUpdateAllSatimageForAllParcels());
                    } else if (mustReloadSatimages && (mustReloadSatimages === true)) {
                        dispatch(ActionAskReloadAllSatimageForAllParcels());
                    }//else /RAS !
                })
                .catch((error) => {
                    //Avertit du mauvais retour:
                    dispatch(ActionErrorLoadSettings(error));
                });
        }
    }
}

/* fonction permettant de revenir au valeur par défaut suite à une suppression de parcellaire/parcelle */
export function ActionResetSettingsAsk() {
    return function (dispatch, getState) {
        // ↓ mise à jour de l'objet settings ↓
        dispatch(ActionResetingSettings());

        // ↓ création d'un nouvel objet settings (données par défaut) ↓
        const state = getState();
        const currentSettings = state.settingsData.settings;
        const newSettings = {
            ...currentSettings,
            nameExploitation: '',
            parcelMaxcc: 20,
        };

        // ↓ appel API ↓
        return settingsWebApiProvider.updateSettings(dispatch, getState, newSettings)
            .then((response) => { //La réponse est juste 'ok' ou 'pas ok'.
                // Signale que l'on a bien mis à jour le paramètrage:
                dispatch(ActionSaveResetedSettings(newSettings));
            })
            .catch((error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorLoadSettings(error));
            });
    }
}

// Choix de la langue:
export function ActionSetDefaultLanguage() {
    return {
        type: SET_DEFAULT_LANG,
    };
}

export function ActionSetLanguage(newCultureValue) {
    return {
        type: SET_LANG,
        newCulture: newCultureValue,
    };
}


///////////////////////////////////////////////////////////////// ↓↓ CHOIX DES COUCHES ↓↓
export function ActionSelectLayer(baseLayertypeValue, forewardLayertypeValue) {
    return function (dispatch, getState) {
        //Besoin d'actualiser le choix des couches si une parcelle est sélectionnée et corresponds à un type spécifique 'Parcelle':
        if (forewardLayertypeValue && (
            (forewardLayertypeValue === ConstantsLayers.VisibleParcelLayerName) || (forewardLayertypeValue === ConstantsLayers.NdviParcelLayerName))) {
            const currentStoreState = getState();
            // 1- récupération de la parcelle sélectionnée
            const currentParcelIdSelected = lodashGet(currentStoreState, 'contextAppData.parcelIdSelected', -1);
            if (currentParcelIdSelected > 0)
                dispatch(ActionSelectLayerOfParcel(forewardLayertypeValue, currentParcelIdSelected));
        }

        // Valeurs possibles pour 'baseLayertypeValue' : ConstantsLayers.VisibleBaseLayerName ou ConstantsLayers.RoadBaseLayerName !
        // Valeurs possibles pour 'forewardLayertypeValue' : NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
        return dispatch({
            type: SELECT_LAYER,
            baseLayertype: baseLayertypeValue,
            forewardLayertype: forewardLayertypeValue,
        });
    }
}
///////////////////////////////////////////////////////////////// ↑↑ CHOIX DES COUCHES ↑↑

///////////////////////////////////////////////////////////////// ↓↓ Demande d'affichage des images Landsat8 ↓↓
export function ActionDisplayLandsatImgs(display) {
    return async function (dispatch, getState) {
        // ↓ mise à jour de l'objet settings ↓
        dispatch(ActionUpdatingSettings());

        // ↓ appel API ↓
        await settingsWebApiProvider.updateDisplayLandsatSettings(dispatch, getState, display)
            .then((response) => { //La réponse est juste 'ok' ou 'pas ok'.
                // Signale que l'on a bien mis à jour le paramètrage:
                dispatch(ActionSetDisplayLandsatImgs(display));

                // Appel au rechargement de l'historique de chaque parcelle:
                dispatch(ActionAskReloadAllSatimageForAllParcels(1)); //Rq: pas 'ActionAskUpdateAllSatimageForAllParcels' car on n'a pas besoin de générer des images, juste les filtrer !
            })
            .catch((error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorLoadSettings(error));
            }); 
    }
}

/*export*/ function ActionSetDisplayLandsatImgs(displayValue) {
    return {
        type: DISPLAY_LANDSAT8_IMG,
        displayLandsatImgs: displayValue,
    };
}
///////////////////////////////////////////////////////////////// ↑↑ Demande d'affichage des images Landsat8 ↑↑