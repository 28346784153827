import React, { /*useCallback, useRef,*/ useEffect } from 'react';
import { IconButton } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';


// configuration Headway - @see https://docs.headwayapp.co/widget for more configuration options.
const HW_config = {
    selector: ".notification-headway-container", // CSS selector where to inject the badge
    account: "7Nwj8J",
    // callbacks: {
    //     onWidgetReady: function (widget) {
    //         console.log("headway -test - Widget is here!");
    //         console.log("headway -test - unseen entries count: " + widget.getUnseenCount());
    //     },
    //     onShowWidget: function () {
    //         console.log("headway -test - Someone opened the widget!");
    //     },
    //     onShowDetails: function (changelog) {
    //         console.log(`"headway -test - ${changelog.position}`); // position in the widget
    //         console.log(`"headway -test - ${changelog.id}`); // unique id
    //         console.log(`"headway -test - ${changelog.title}`); // title
    //         console.log(`"headway -test - ${changelog.category}`); // category, lowercased
    //     },
    //     onReadMore: function (changelog) {
    //         console.log(`"headway -test - ${changelog}`); // same changelog object as in onShowDetails callback
    //     },
    //     onHideWidget: function () {
    //         console.log("headway -test - Who turned off the light?");
    //     }
    // }
};

/**
 * Composant - notifications en lien avec une librairie tiers Headway https://docs.headwayapp.co/widget
 */
/*function NotificationHeadway(props) {
    const headwayRef = useRef();

    // Le onClick avec Headway ne fonctionne plus.

    // cycle de vie reactjs //
    useEffect(() => {
        if (window.Headway)
            window.Headway.init(HW_config);
    },[])

    return (
            <>
                <div className="notification-headway-container" ref={headwayRef}></div>
                <IconButton
                    sx={{ml: 0, zIndex:-1 }}
                    size="small"
                    variant="header"
                    color="inherit"
                >
                    <NotificationsIcon />
                </IconButton>
            </>
    )
}*/ //pour palier l'avertissement de la console sur l'emploi de 'useRef' dans un composant fonction.
const NotificationHeadway = React.forwardRef(
    (props, ref) => {
        // cycle de vie reactjs //
        useEffect(() => {
            if (window.Headway)
                window.Headway.init(HW_config);
        }, []);

        return (
            <>
                <div className="notification-headway-container" ref={ref}></div>
                <IconButton
                    sx={{ml: 0, zIndex:-1 }}
                    size="small"
                    variant="header"
                    color="inherit"
                >
                    <NotificationsIcon />
                </IconButton>
            </>
        );
    }
);

export default NotificationHeadway;
