import React, { Component } from "react";
import styles from './PinDropFormDialog.module.css';
import { Dialog } from '@mui/material';
import PinDropFormPopup from '../PinDropFormPopup.jsx';


///////////////////////////////////////////////////////////////////////////
// Composant appliqué par dessus le composant RenderMaps pour afficher un formulaire de création d'observation - utilisation smartphone
///////////////////////////////////////////////////////////////////////////
class PinDropFormDialog extends Component {

    /* cycle de vie react */
    render() {
        const { pinDropFormDialogDatas } = this.props;

        return (
            /* ↓↓ Boite de dialogue en lien avec la création d'une observation ↓↓ */
            <Dialog
                open={true}
                container={() => document.getElementById('pinDropFormDialog')} // target le container ayant la position relative
                style={{ position: 'absolute' }}
                BackdropProps={{ classes: { root: styles.backdrop } }}
                fullScreen={true}
                maxWidth={'sm'} // limitation de la largeur
            /* sx={{ m: 0, padding: 0 }} */
            >
                <PinDropFormPopup {...pinDropFormDialogDatas} />
            </Dialog>)
    }
}

export default PinDropFormDialog; // withMobileDialog => va permettre de rendre la boite de dialogue responsive