import React from 'react';

import { Button, Typography, Paper, InputBase, Divider, Box } from '@mui/material';

import '../../assets/css/planCard.css';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import { stripeWebApiProvider } from '../../utils/webApiProvider.js';
import getTheme from "../../themes/index.js";


let theme = getTheme();

class StripeCoupon extends React.Component {
    constructor(props) {
        super(props);

        // ↓ CAS: APPLICATION CODE PROMO PROVENANT D'UN PARAMETRE URL (dicountcode && planId) ↓
        let planidParameter = (props.URLParameters && props.URLParameters.planid) ? props.URLParameters.planid : null;
        let discountcodeParameter = (props.URLParameters && props.URLParameters.discountcode) ? props.URLParameters.discountcode : null;
        let haveToApplyDiscountcode = (((props.planDatas.id === planidParameter) && discountcodeParameter) || (!planidParameter && discountcodeParameter)) ? true : false;

        this.state = {
            onFocus: false, // le focus est-il sur le champ code promo ?
            discountCode: (haveToApplyDiscountcode) ? discountcodeParameter : null, // si code déjà renseigné => appel automatique à la web API
            error: null, // message d'erreur
        }

        // BIND de fonction
        this.onChangeFocus = this.onChangeFocus.bind(this);
        this.onChangeDiscountCode = this.onChangeDiscountCode.bind(this);
        this.validateDicountCode = this.validateDicountCode.bind(this);
        this.cancelDiscountCode = this.cancelDiscountCode.bind(this);
    }

    /* fonction cycle de vie react */
    componentDidMount() {
        const { discountCode } = this.state;
        if (discountCode && discountCode !== '')
            this.validateDicountCode();
    }

    /* callback si l'utilisateur est en cours de saisie d un code promo */
    onChangeFocus(event) {
        this.setState({onFocus:true, error:null});
    }

    /* fonction liée au changement de valeur dans le champ code promo */
    onChangeDiscountCode(event) {
        this.setState({discountCode: (event && event.target && event.target.value) ? event.target.value : ''});
    }

    /* fonction permettant de valider un code promo - appel web API */
    validateDicountCode() {
        // ↓ Mise en attente de l'utilisateur ↓
        this.setState({onSubmit: true});

        // ↓ envoie de la requête ↓
        const { discountCode } = this.state;
        const { planDatas } = this.props;
        stripeWebApiProvider.checkDiscountCode(discountCode, planDatas.id)
            .then((discountPlan) => {
                if (discountPlan && discountPlan.isAvailable) {

                    this.props.saveDiscountedPlan(discountPlan, planDatas.id); // enregistrement REDUX (insertion dans le plan associé => plan.DiscountedPlanDatas = discountPlan)
                    
                    if (window.dataLayer) // google tag manager
                        window.dataLayer.push({'event': 'suiviBouton', 'action': `code promo ${discountCode}`});

                    this.setState({onSubmit:false, error:null, onFocus:false, discountCode: null});
                }
                else {
                    if (window.dataLayer) // google tag manager
                        window.dataLayer.push({'event': 'suiviBouton', 'action': `code promo faux`});

                    this.setState({onSubmit:false, error: StringTranslate.promocodenotvalid, onFocus:false});
                    // StoreFunctions.clearDiscountCodes(); // si code non valide, on supprimer la donnée du localStorage
                }
            })
            .catch((error) => {
                this.setState({onSubmit:false, error: StringTranslate.promocodenotvalid, onFocus:false});
                // StoreFunctions.clearDiscountCodes();  // si code non valide, on supprimer la donnée du localStorage
            })
    }

    /* fonction permettant d'annuler le processus de code promo */
    cancelDiscountCode() {
        this.setState({discountCode: null,planDiscounted: null,error: null,isValid: false,onSubmit: false,onFocus: false});
    }

    /* fonction cylde de vie react.js */
    render() {
        const { discountCode, /*onFocus, onSubmit,*/ error } = this.state;

        return (
            <React.Fragment>
            {/* ↓↓ Partie - Formulaire (champ + bouton d'envoi) ↓↓ */}
                <React.Fragment>
                    {/* <div className='plan-card-discount-textfield'>
                        <TextField
                            id="champ-code-promo"
                            autoFocus
                            label="Code promo"
                            value={discountCode ? discountCode : ''} // @@ à utiliser dès que l'on aura un code promo a disposition au démarrage de l'application
                            error={(error) ? true : false}
                            onChange={this.onChangeDiscountCode}
                            variant="outlined"
                            InputLabelProps={{shrink: true}}
                            inputProps={{ style: { height: '20px', padding: '10px 15px'} }}
                            onFocus={this.onChangeFocus}
                            sx={{ "& root":{margin:"0px 5px"} }}
                        />
                        {(onFocus && !onSubmit) &&
                            <Button 
                                variant='outlined' 
                                color="primary" 
                                onClick={this.validateDicountCode}
                                disabled={((!discountCode || discountCode === '') || onSubmit) ? true : false}
                            >
                                {StringTranslate.promocodeverify}
                            </Button>
                        }
                        {(!onFocus) &&
                            <Button 
                                variant='outlined' 
                                color="secondary" 
                                onClick={this.cancelDiscountCode}
                                disabled={(!discountCode || discountCode === '') ? true : false}
                            >
                                {StringTranslate.promocodecancel}
                            </Button>
                        }
                        {(onSubmit) && <CircularProgress />}
                    </div> */}

                    <Paper
                        component="div"
                        sx={{
                            px: 0.25,
                            py: 0.5,
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid',
                            borderColor: theme.palette.grey[400]
                        }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1, fontWeight: 500 }}
                            fullWidth
                            placeholder={StringTranslate.promocodeedit}
                            inputProps={{ 'aria-label': 'search google maps' }}
                            id="code"
                            name="code"
                            value={discountCode ? discountCode : ''}
                            onChange={this.onChangeDiscountCode}
                            error={(error) ? true : false}
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <Button type="submit" color="primary" aria-label="directions" onClick={this.validateDicountCode}>
                            {StringTranslate.promocodeverify}
                        </Button>

                    </Paper>
                    
                    {/* ↓↓ Partie bon de réduction - message (erreur) ↓↓ */}
                    <Box id="plan-card-discount-message" sx={{ paddingLeft:"10px" }}>
                        {(error) && <Typography variant='caption' color='error'>{error}</Typography>}
                    </Box>

                </React.Fragment>
            </React.Fragment>
        )}
}

export default StripeCoupon;