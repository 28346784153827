import React from 'react';
import { connect } from 'react-redux';

/* Composants MUI */
import {
    Typography, Button, Card, CardContent, CardActions, Grid
} from '@mui/material';

/* Composants React */
import DisplayImageList from '../../thumbnail/DisplayImageList';

/* Traduction */
import StringTranslate from '../../../assets/i18n/stringLanguage';

/* Redux */
import { ActionChooseIdentificationResult } from '../../../redux/actions/observations';
import { ActionShowImageDialog } from '../../../redux/actions/contextApp';

/* css */
import '../../../assets/css/identificationDialog.css';
import getTheme from "../../../themes/index.js";

let theme = getTheme();

class ResultItemComponent extends React.Component {
    constructor(props) {
        super(props);
        const { result } = props;

        // Récupération des images
        let images = [];
        if (result && (result.images) && (result.images.length > 0)) {
            result.images.forEach((image) => {
                if ((image) && (image.url)) {
                    images.push({
                        imageUrl: image.url.o,
                        vignetteUrl: image.url.s,
                    });
                }
            });
        }

        this.images = [...images];
    }

    /* ↓ fonctions liées aux click des photo ↓ */
    handleOpenDialogImage = (index) => {
        if (index < 0) return;

        if ((!this.images) || (!Array.isArray(this.images)) || (index >= this.images.length)) return;

        let image = this.images[index];
        if (!image) return;

        const { showImage } = this.props;
        showImage(image.imageUrl);
    }

    /**
     * Fontion permettant de valider le résultat et de retourner à l'observation (ou la créer)
     */
    onClickChooseResult = () => {
        const { closeIdentificationDialog, createObservation, chooseIdentificationResult, result } = this.props;
        chooseIdentificationResult(result);
        closeIdentificationDialog();
        if (createObservation !== undefined)
            createObservation();
    }

    render() {
        const { result, } = this.props;
        return (
            /* Affichage des noms */
            <Card 
                sx={{
                    border: '1px solid',
                    borderColor: theme.palette.primary.light,
                    background: theme.palette.grey[50],
                    ':hover': {
                        boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)'
                    }
                }}>
                <CardContent>
                    {/* Affichage Photos */}
                    {(this.images.length > 0) &&
                        <DisplayImageList
                            imagesURL={this.images.map((image) => { return image.vignetteUrl })}
                            onClickImage={this.handleOpenDialogImage}
                            imageSize="small"
                            oneRow
                        />
                    }
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6">{result.species.commonNames[0]}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="text.secondary">{result.species.scientificName}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">{result.species.family.scientificName}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">{`${StringTranslate.formatString(StringTranslate.identificationResultComponentConfidence, Math.round(result.score * 100))}`}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button variant="contained" size="small" color="primary" onClick={this.onClickChooseResult}>
                        {StringTranslate.valider}
                    </Button>
                </CardActions>
            </Card >
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant */
const mapStateToProps = function (state) {
    return {
        createObservation: state.observationsData.createObservation,
        closeIdentificationDialog: state.observationsData.closeIdentificationDialog,
    };
}

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
    chooseIdentificationResult: (result) => dispatch(ActionChooseIdentificationResult(result)),
    showImage: (uri) => dispatch(ActionShowImageDialog(uri)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResultItemComponent);