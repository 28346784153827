export const harvestVarietyCharacteristicsHelper = {

    /**
     *  fonction permettant de calculer le nombre total de variétés de culture se trouvant dans le dico
     */
    count(harvestVarietyCharacteristicsDico) {
        if (!harvestVarietyCharacteristicsDico) return 0;
        return Object.entries(harvestVarietyCharacteristicsDico).length;
    },

    /* fonction permettant d'adapter l'item de variété de culture reçu - le concept de normalisation des données est ici utilisé */
    convertItem(harvestVarietyCharacteristicsItem) {
        if (!harvestVarietyCharacteristicsItem) {
            return {};
        }

        return harvestVarietyCharacteristicsItem;
    },

    /* fonction permettant de passer d'une liste à un dico - le concept de normalisation des données est ici utilisé */
    convertToDico(harvestVarietyCharacteristicsList) {
        if ((!harvestVarietyCharacteristicsList) || (!Array.isArray(harvestVarietyCharacteristicsList))) {
            return {};
        }

        let dicoHarvest = {};
        harvestVarietyCharacteristicsList.forEach(harvestVarietyCharacteristics => {
            dicoHarvest[harvestVarietyCharacteristics.id] = harvestVarietyCharacteristicsHelper.convertItem(harvestVarietyCharacteristics);
        });

        return dicoHarvest;
    },

}