/*
    Ensemble de constantes permettant la configuration d'un fournisseur d'authentification:
*/
import ConstantsHosters from './constantsByConfig.js';


//--------------------------------------------------------------
//------- Données fixe quelque soit la configuration : ---------
// Type de fournisseurs disponible pour l'authentification:
const ProviderTable = [ undefined, "ADAzure", "ADAzureNewer", "Facebook", "Twitter", "LinkedIn", "Gmail", "SpotiFarm", "SpotiFarmReset"];
const Providers = { "ADAzure":1, "ADAzureNewer":2, "Facebook":3, "Twitter":4, "LinkedIn":5, "Gmail":6, "SpotiFarm":7, "SpotiFarmReset":8 };
const ProviderEnum = Object.freeze(Providers);

// Constantes associées à une authent (fause) via notre création de compte:
const idTokenSpotiFarmCst = "09a28848-0e97-4cef-b950-cea037161155";
const accessTokenSpotiFarmCst = "6572EE16-5FE5-4331-BB6D-76A49C56E423";


//Sous-url:
const RedirectSubUriLoginCst = '/login'; 
const RedirectSubUriSettingsCst = '/settings'; 
const RedirectSubUriDefaultCst = '/LogedByADAzure'; 
const RedirectSubUriFacebookCst = '/LogedByFacebook'; 
const RedirectSubUriTwitterCst = '/LogedByTwitter'; 
const RedirectSubUriLinkedInCst = '/LogedByLinkedIn'; 
const RedirectSubUriGmailCst = '/LogedByGmail';
//--------------------------------------------------------------


    //essai de déclaration de todo (plug'in "Todo Tree"):
    // BUG - Aie !!!
    // TODO - Blabla !
    // FIXME - how to do this ?

//--------------------------------------------------------------
// const B2C_tenantCst = 'DevADSpotiFarm.onmicrosoft.com';
const B2C_tenantCst = process.env.REACT_APP_B2C_TENANT;

//RQ: en mode "non-prod", leurs définitionn n'a pas vraiment de sens... Resté comme c'étaient en prototypage
// const ScopeDefaultEltsCst = ['openid', 'profile', 'offline_access'];
const ScopeParcelsAccessFunctCst = process.env.REACT_APP_SCOPE_PARCEL_ACCESS_FUNCT;
const ScopeHistoryAccessFunctCst = process.env.REACT_APP_SCOPE_HISTORY_ACCESS_FUNCT;
const SignInPolicyDefaultCst = process.env.REACT_APP_SIGN_IN_POLICY_DEFAULT;
const SignInPolicyDefaultNewerCst = process.env.REACT_APP_SIGN_IN_POLICY_DEFAULT_NEWER;
// const SignInPolicyDefaultChangeCst = 'N/A'; 
const SignInPolicyDefaultResetPwdCst = process.env.REACT_APP_SIGN_IN_POLICY_DEFAULT_RESET_PWD;
const SignInPolicyFacebookCst = process.env.REACT_APP_SIGN_IN_POLICY_FACEBOOK;
const SignInPolicyTwitterCst = process.env.REACT_APP_SIGN_IN_POLICY_TWITTER;
const SignInPolicyLinkedInCst = process.env.REACT_APP_SIGN_IN_POLICY_LINKEDIN;
const SignInPolicyGmailCst = process.env.REACT_APP_SIGN_IN_POLICY_GMAIL;
const SignInPolicyROPCCst = process.env.REACT_APP_SIGN_IN_POLICY_ROPC;

const DefaultInstanceCst = process.env.REACT_APP_DEFAULT_INSTANCE;
//RQ: On n'utilise plus le compte de test! directement le compte de prod (depuis la personnalisation pour l'internationnal)
const UrlRootCompteResourcesCst = process.env.REACT_APP_URL_ROOT_COMPTE_RESSOURCE;
const UrlTradCompteResourcesCst = process.env.REACT_APP_URL_TRAD_COMPTE_RESSOURCE;

//↓ Constante Stripe ↓
const stripeApiKeyValue = process.env.REACT_APP_STRIPE_API_KEY_VALUE;

export const ConfigAuthent = {
    ////Configuration:
    // optional, will default to this
    instance: DefaultInstanceCst, 
    // your B2C tenant
    tenant: B2C_tenantCst, 
    // the the B2C application you want to authenticate with (that's just a random GUID - get yours from the portal)
    applicationId: ConstantsHosters.applicationId, // '235bc358-d7d4-4162-ba49-8c7d2ccf2470',
    //secret 
    secret: ConstantsHosters.secret, // 'F7o\\6G.[F6m0_C3V78|(EMGa',
    // where MSAL will store state - localStorage or sessionStorage
    cacheLocation: 'localStorage', // celui visé et aussi celui par défaut !
    otherCacheLocation: 'sessionStorage', // celui tenté d'utilisé si le premier n'existe pas !
    // the scopes you want included in the access token
    scopes: ['openid', 'profile', 'offline_access', ScopeParcelsAccessFunctCst, ScopeHistoryAccessFunctCst],
    // optional, the URI to redirect to after logout
    postLogoutRedirectUri: ConstantsHosters.urlRootWebAppReact,

    // !!! Eléments paramétrable !!!
    // the policy to use to sign in, can also be a sign up or sign in policy
    signInPolicy: SignInPolicyDefaultCst,
    // optional, the redirect URI - if not specified MSAL will pick up the location from window.href
    redirectUri: ConstantsHosters.urlRootWebAppReact + RedirectSubUriDefaultCst,

    ////Constances générales:
    ConstAndDefault: {
        DefaultInstance: DefaultInstanceCst,

        ScopeParcelsAccessFunct: ScopeParcelsAccessFunctCst,
        ScopeHistoryAccessFunct: ScopeHistoryAccessFunctCst,

        ProvidersName: ProviderTable,
        Providers: ProviderEnum,

        idTokenSpotiFarm: idTokenSpotiFarmCst,
        accessTokenSpotiFarm: accessTokenSpotiFarmCst,

        SignInPolicyDefaultNewer: SignInPolicyDefaultNewerCst,

        SignInPolicyDefault: SignInPolicyDefaultCst,
        SignInPolicyFacebook: SignInPolicyFacebookCst,
        SignInPolicyTwitter: SignInPolicyTwitterCst,
        SignInPolicyLinkedIn: SignInPolicyLinkedInCst,
        SignInPolicyGmail: SignInPolicyGmailCst,
        SignInPolicyROPC: SignInPolicyROPCCst,

        SignInPolicyDefaultResetPwd: SignInPolicyDefaultResetPwdCst,

        RedirectUriBase: ConstantsHosters.urlRootWebAppReact,

        RedirectSubUriLogin: RedirectSubUriLoginCst,
        RedirectSubUriSettings: RedirectSubUriSettingsCst,

        RedirectSubUriDefault: RedirectSubUriDefaultCst,
        RedirectSubUriFacebook: RedirectSubUriFacebookCst,
        RedirectSubUriTwitter: RedirectSubUriTwitterCst,
        RedirectSubUriLinkedIn: RedirectSubUriLinkedInCst,
        RedirectSubUriGmail: RedirectSubUriGmailCst,

        RedirectUriDefault: ConstantsHosters.urlRootWebAppReact + RedirectSubUriDefaultCst,
        RedirectUriFacebook: ConstantsHosters.urlRootWebAppReact + RedirectSubUriFacebookCst,
        RedirectUriTwitter: ConstantsHosters.urlRootWebAppReact + RedirectSubUriTwitterCst,
        RedirectUriLinkedIn: ConstantsHosters.urlRootWebAppReact + RedirectSubUriLinkedInCst,
        RedirectUriGmail: ConstantsHosters.urlRootWebAppReact + RedirectSubUriGmailCst,

        UrlRootCompteResources: UrlRootCompteResourcesCst,
        UrlTradCompteResources: UrlTradCompteResourcesCst,
    },
    //↓ Constante Stripe ↓
    stripeApiKey: stripeApiKeyValue,

};

export default ConfigAuthent;