import React, { Component } from "react";
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import { LinearProgress } from "@mui/material";

import StringTranslate from '../assets/i18n/stringLanguage.jsx';
import 'spin.js/spin.css';
import { ActionSetIsNewSubscription } from '../redux/actions/clientUser.js';
import CustomSnackbar, { SnackbarDataContent } from './customSnackbar.jsx';
import { Spinner } from 'spin.js';
import { ActionGoToParcels, ActionShowProfilMenuDialog, ProfilIndex, ActionShowSnackbarHelpNdvi } from '../redux/actions/contextApp.js';
import { ActionSetValueNewImageShowedOnMap, ActionShowSnackbarNewImages } from '../redux/actions/contextApp.js';
import { ActionUploadedParcels } from '../redux/actions/parcels.js';


/**
 * Composant appliqué par dessus (superposé) le composant RenderMaps pour afficher de l'information personnalisée 
 */
class MapInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            progressBar: false, // affichage progress bar

            // snackbars ↓↓
            snackbarDatas: null, // affichage snackbar,
            snackbarAnchorOrigin: { vertical: 'top', horizontal: 'center' },// snackbar plaçé en haut/milieu
            spinningIcon: false, // affichage spinning icon,
            newImportOrDrawing: false, //pour afficher la notif "nouvelles images diponibles"
        }

        this.removeSnackbar = this.removeSnackbar.bind(this);
    }

    /* cycle de vie react */
    componentDidMount() {
        // CAS: PAS DE PARCELLE
        if ((!this.props.isNewSubscription) && (this.props.parcelDicoCounter <= 0)) {
            this.setState({ 
                progressBar: false,
                snackbarDatas: null,
            });
        }

        // CAS: PAS DE PARCELLE + SUITE SOUSCRIPTION TERMINEE ET VALIDE
        if (this.props.isNewSubscription && (this.props.parcelDicoCounter <= 0)) {
            this.props.setIsNewSubscription(false);
            let snackbarDatas = { content: SnackbarDataContent.redirectImport, text:StringTranslate.redirectimport, time: 15000, onclick: this.props.goToParcels};
            this.setState({ snackbarDatas: snackbarDatas });
        }

        // CAS: PARCELLE + CRUD en cours sur les données de parcelles
        if (this.props.parcelsLoading || this.props.parcelsUpdating || this.props.deleting) {
            this.setState({ progressBar: true });
        }

        // CAS: PARCELLE + HISTORIQUE EN COURS -RECUPERATION DES IMAGES
        if (this.props.parcelsInProgressCounter > 0) {
            this.setState({
                progressBar: true,
                snackbarDatas: { 
                    content: SnackbarDataContent.info, 
                    text: `${StringTranslate.recupimg}`,
                },
            });
        }
    }

    /* fonction permettant de retirer le snackbar */
    removeSnackbar() {
        const { clientIsNewer, snackbarHelpNdviHasBeenShown, showProfilMenuDialog, setSnackbarNdviHasBeenShown } = this.props;
        //Si on remove la notif "Nouvelles images" et que c'est la premiere fois que le user créé/importe une parcelle
        if((this.state.snackbarDatas.content === SnackbarDataContent.newimage) && (clientIsNewer === true) && (snackbarHelpNdviHasBeenShown === false)){
            //on efface la notif
            this.setState({snackbarDatas: null}, () => {
                //on attend 0.5s puis on affiche la notif pour comprendre l'indice de végétation
                setTimeout(() => {
                    this.setState({
                        progressBar: false,
                        snackbarDatas: { 
                            content: SnackbarDataContent.help,
                            text: `${StringTranslate.clickToUnderstandImage}`,
                            onclick: () => {showProfilMenuDialog(ProfilIndex.aide_IndiceVegetation) },
                            // helpAsk - je laisse ce mot afin de retrouver les liens vers le menu d'aide plus facilement
                            time: 10000,
                        },
                    });
                    setSnackbarNdviHasBeenShown(true);
                }, 500);
            });
        } else {
            this.setState({snackbarDatas: null,});
        }
    }

    /* cycle de vie react.js */
    componentDidUpdate(prevProps, prevState){

        const { parcelsLoading, parcelsUpdating, parcelsDeleting, parcelsImportingOrDrawing, isNewSubscription,
            parcelDicoCounter, parcelsInProgressCounter, newImageShowedOnMap, snackbarNewImagesHasBeenShown,
            setIsNewSubscription, goToParcels, setValueNewImageShowedOnMap, setSnackbarHasBeenShown, uploadedParcels, textSnackbarCreateZoneOrMarker, parcelMaxcc} = this.props;

        // CAS: PAS ENCORE DE PARCELLE - RECUPERATION DES PARCELLES
        if (prevProps.parcelsLoading !== parcelsLoading) {
            this.setState({progressBar: (parcelsLoading) ? true : false });
        }
        
        if (prevProps.parcelsUpdating !== parcelsUpdating) {
            this.setState({progressBar:(parcelsUpdating) ? true : false});
        }
        if (prevProps.parcelsDeleting !== parcelsDeleting) {
            this.setState({progressBar:(parcelsDeleting) ? true : false});
        }

        // CAS: PAS DE PARCEL + SOUSCRIPTION TERMINEE ET VALIDE
        if (isNewSubscription && (parcelDicoCounter <= 0)) {
            setIsNewSubscription(false);
            let snackbarDatas = { content: SnackbarDataContent.redirectImport, text:StringTranslate.redirectimport, onclick: goToParcels };
            this.setState({ snackbarDatas: snackbarDatas, });
        }

        // CAS: PARCELLES - SUPPRESSION DU PARCELLAIRE
        if ((prevProps.parcelDicoCounter !== parcelDicoCounter) && (parcelDicoCounter <= 0)) {
            let snackbarDatas = { content: SnackbarDataContent.redirectImport, text:StringTranslate.redirectimport, time: 1500, onclick: goToParcels };
            this.setState({ 
                progressBar: false,
                snackbarDatas: snackbarDatas,
            });
        }
        
        // CAS: PARCELLES - HISTORIQUE EN COURS - RECUPERATION DES IMAGES
        /* Rq: Quand (parcelsInProgressCounter > 0) && (parcelsImportingOrDrawing === true) signifie qu'un import est en cours */
        /* parcelsImportingOrDrawing : variable redux à true signifie qu'un parcellaire est en cours d'importation ou qu'une parcelle est créée, que le swiper est fermé et récupère les images */
        if (prevProps.parcelsInProgressCounter !== parcelsInProgressCounter) {
            if(parcelsInProgressCounter > 0) {
                if (parcelsImportingOrDrawing === true) {
                    this.setState({
                        progressBar: true,
                        snackbarDatas:
                            { 
                                content: SnackbarDataContent.info, 
                                text: `${StringTranslate.recupimg}`, 
                            },
                        newImportOrDrawing: true,
                    });
                }
                //else on n'efface pas les informations de la notif en cours
            } else {
                this.setState({
                    progressBar: false,
                    snackbarDatas: null,
                });
            }
        }

        // CAS: VERIFICATION NOUVELLES IMAGES AFFICHEES SUR LA CARTE
        if ( ((prevProps.parcelsInProgressCounter !== parcelsInProgressCounter) && parcelsInProgressCounter <= 0) || // à la fin d'un chargmement d'historique
            (parcelsInProgressCounter <= 0 && (prevProps.newImageShowedOnMap !== newImageShowedOnMap)) // lors de l'affichage d'une nouvelle image affichée sur la carte
        ) {
            if ((newImageShowedOnMap === true) && // il y a actuellement une nouvelle image affichée sur la carte (il y a au moins un contour rose sur une des parcelles)
                ((this.state.newImportOrDrawing === true) || (snackbarNewImagesHasBeenShown === false))) //il y a un nouvelle import ou la notif n'a pas encore été montrée
            {  
                this.setState({ 
                    progressBar: false,
                    snackbarDatas: { content: SnackbarDataContent.newimage, text: StringTranslate.newimg, time: 10000 },
                    newImportOrDrawing: false,
                });
                setValueNewImageShowedOnMap(false); // on met à jour cette valeur (false) après lecture
                setSnackbarHasBeenShown(true); // le snackbar des nouvelles images a été montré 1 fois (au démarrage), donc on ne le montrera plus
            }
        }

        //Mise à zéro de la variable redux 'loadingImportedParcels' s'il n'y a plus de parcelles en cours d'import ou de mise à jour
        if ((prevProps.parcelsInProgressCounter !== parcelsInProgressCounter) && (parcelsInProgressCounter <= 0)) {
            uploadedParcels();
        }

        if (prevProps.textSnackbarCreateZoneOrMarker !== textSnackbarCreateZoneOrMarker) {
            if (textSnackbarCreateZoneOrMarker !== null) {
                this.setState({
                    snackbarDatas: { content: SnackbarDataContent.help , text: textSnackbarCreateZoneOrMarker },
                });
            } else {
                this.setState({
                    snackbarDatas: null,
                });
            }
        }
        // CAS : D'un changement de taux % de nuage
        if (prevProps.parcelMaxcc !== parcelMaxcc) {
            if (parcelsInProgressCounter > 0) {
                this.setState({
                    progressBar: true,
                    snackbarDatas:
                        { 
                            content: SnackbarDataContent.info, 
                            text: `${StringTranslate.recupimg}`, 
                        },
                    newImportOrDrawing: true,
                });
            } else {
                this.setState({
                    progressBar: false,
                    snackbarDatas: null,
                });
            } 
        }        
    }

    /* cycle de vie react.js */
    render() {
        const { progressBar, snackbarDatas, spinningIcon, snackbarAnchorOrigin } = this.state;

        return (
                <>
                    {/* ↓ composant représentant une barre de chargement ↓ */}
                    {(progressBar) && <LinearProgress sx={{ top: "-20px" /* le css de la carte est différents des autres pages. D'où le -20 */ }} /> }

                    {/* ↓ composant représentant un composant snackbar ↓ */}
                    {(snackbarDatas) && 
                        <CustomSnackbar 
                            snackbarDatas={snackbarDatas} 
                            anchorOrigin={snackbarAnchorOrigin} 
                            removeSnackbar={this.removeSnackbar} 
                        />
                    }

                    {/* ↓ composant représentant une icône de chargement - loading incone ↓ */}
                    {(spinningIcon) && <ReactSpinner />}

                </>
        )
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant MapInfo */
const mapStateToProps = function(state) {
    return {
        parcelsLoading: lodashGet(state, 'parcelsData.loading', false),
        parcelsUpdating: lodashGet(state, 'parcelsData.updating', false),
        parcelsDeleting: lodashGet(state, 'parcelsData.deleting', false),
        parcelsImportingOrDrawing: lodashGet(state, 'parcelsData.loadingImportedOrDrawnParcels'),
        parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
        parcelDicoCounter: lodashGet(state, 'parcelsData.parcelDicoCounter', 0),
        parcelIdFilteredList: lodashGet(state, 'parcelsData.parcelIdFilteredList', {}),
        parcelIdFilteredListCounter: lodashGet(state, 'parcelsData.parcelIdFilteredListCounter', 0),
        lastAction: lodashGet(state, 'parcelsData.lastParcelDicoAction'),
        parcelMaxcc: lodashGet(state, 'settingsData.settings.parcelMaxcc', 20),
        parcelsInProgressCounter: lodashGet(state, 'satimageData.parcelsInProgressCounter', 0),
        satimageByParcelDico: lodashGet(state, 'satimageData.satimagesByParcelDico', {}),
        snackbarNewImagesHasBeenShown: lodashGet(state, 'contextAppData.snackbarNewImagesHasBeenShown', false),
        snackbarHelpNdviHasBeenShown: lodashGet(state, 'contextAppData.snackbarHelpNdviHasBeenShown', false),
        textSnackbarCreateZoneOrMarker: lodashGet(state, 'observationsData.textSnackbarCreateZoneOrMarker', null),
        isNewSubscription: lodashGet(state, 'clientUserData.isNewSubscription', false),
        clientIsNewer: lodashGet(state, 'clientUserData.clientIsNewer', false),
        hasParcelSelected: lodashGet(state, 'contextAppData.parcelIdSelected', false),
        newImageShowedOnMap: lodashGet(state, 'contextAppData.newImageShowedOnMap', false),
    };
}

/* fonction permettant de fournir les fonctions (actions) au composant AbstractProfil */
const mapDispatchToProps = dispatch => ({
    setIsNewSubscription: (bool) => dispatch( ActionSetIsNewSubscription(bool) ),
    goToParcels: () => dispatch( ActionGoToParcels() ),
    setValueNewImageShowedOnMap: (bool) => dispatch( ActionSetValueNewImageShowedOnMap(bool) ),
    setSnackbarHasBeenShown: (snackbarHasBeenShown) => dispatch( ActionShowSnackbarNewImages(snackbarHasBeenShown) ),
    uploadedParcels: () => dispatch (ActionUploadedParcels() ),
    showProfilMenuDialog: (index) => dispatch( ActionShowProfilMenuDialog(index) ),
    setSnackbarNdviHasBeenShown: (snackbarNdviHasBeenShown) => dispatch( ActionShowSnackbarHelpNdvi(snackbarNdviHasBeenShown) ),
})

export default connect(mapStateToProps, mapDispatchToProps)(MapInfo);

/* données de paramètrage de l'icône de chargement */
var options = {
    lines: 13, // The number of lines to draw
    length: 38, // The length of each line
    width: 17, // The line thickness
    radius: 45, // The radius of the inner circle
    scale: 0.40, // Scales overall size of the spinner
    corners: 1, // Corner roundness (0..1)
    color: 'black', // CSS color or array of colors
    fadeColor: 'transparent', // CSS color or array of colors
    speed: 0.7, // Rounds per second
    rotate: 0, // The rotation offset
    animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
    direction: 1, // 1: clockwise, -1: counterclockwise
    zIndex: 2e9, // The z-index (defaults to 2000000000)
    className: 'spinner', // The CSS class to assign to the spinner
    top: '50%', // Top position relative to parent
    left: '50%', // Left position relative to parent
    shadow: '0 0 1px transparent', // Box-shadow for the lines
    position: 'absolute' // Element positioning
};

///////////////////////////////////////////////////////////////////////////
// Composant qui permet d'utiliser le spinner 
///////////////////////////////////////////////////////////////////////////
class ReactSpinner extends Component {

    componentDidMount() {
        this.spinner = new Spinner(options);
        if (this.container && this.spinner)
            this.spinner.spin(this.container);
    }

    render() {
        return (
            <span ref={(container) => (this.container = container)} />
        )
    }
}