import React from 'react';
import { connect } from 'react-redux';
import { Box, Fab, Grid, IconButton, Stack, Tooltip, Typography, Button, } from '@mui/material';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import ConstantsLayers from '../../utils/constantsOfLayers.js';
import { ActionAskToChangeLayer, ActionGlobalLayerLoaded } from '../../redux/actions/contextApp.js';
import CloudIcon from '@mui/icons-material/Cloud';
import CloudOffIcon from '@mui/icons-material/CloudOff';

//import ImageDeactivateButtonControl from './ImageDeactivateButtonControl.jsx'; //Evolution à venir pour permettre la désactivation d'une image en particulier !

import SubCard from '../subLayouts/subCard';
import getTheme from '../../themes/index.js';

import lodashGet from 'lodash/get';
import { SatImageSourceProvider } from '../../utils/constantsProvidersSatellite.js';


let theme = getTheme();
/**
 * composant bouton de sélection de layers
 */
function LayerCloudSelectionButtonControl(props) {
    const { providerSrcImageSelectedOfParcel } = props;
    let isModelisationImage = (providerSrcImageSelectedOfParcel===SatImageSourceProvider.Modelisation);

    const [openTooltipForModelizedImage, setOpenTooltipForModelizedImage] = React.useState(false);
    const [openTooltipIconForModelizedImage, setOpenTooltipIconForModelizedImage] = React.useState(false);
    const [openTooltipMobile, setOpenTooltipMobile] = React.useState(false);

    const selectLayerAndSaveChoice = (layer, newSettingsToSave) => {
        // On prévient qu'il faut changer de fond de carte: 
        if (props.askToChangeLayerAndSaveSettings) {

            // Il apparait que quand on change rapidement de layer en ayant le spinner de chrgement qui tourne encore,
            // celui-ci ne disparait pas car toujours en cours.
            // Forcer l'arrêt du spinner de chargement du layer si apparant quand on change de layer 
            if (props.globalLayerLoaded()) {
                props.globalLayerLoaded();
            }

            // Valeurs possibles pour 'forewardLayerSelected' : NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
            props.askToChangeLayerAndSaveSettings(undefined, layer, newSettingsToSave);
        }
    }

    /**
     * fonction callback lors du click du bouton du choix de couche 
     * (par dessus celle de fond) qui renvoie à la couche NDVI-Parcel
     */
    const onSelectNdviLayer = () => {
        let newSettingsToSave = {
            ...props.settings,
            forewardLayerSelected: ConstantsLayers.NdviParcelLayerName,
            lastLayer: 4 //WMSLayersInfo-ndvi
        };

        selectLayerAndSaveChoice(ConstantsLayers.NdviParcelLayerName, newSettingsToSave);
    }

    /**
     * fonction callback lors du click du bouton du choix de couche des nuages 
     * (par dessus celle de fond)
     */
    const onSelectCloudLayer = () => {
        // Si l'image est modélisée, on ne change pas de layer
        if (isModelisationImage) return;

        let newSettingsToSave = {
            ...props.settings,
            forewardLayerSelected: ConstantsLayers.VisibleGlobalLayerName,
            lastLayer: 1, //WMTSLayersInfo-satellite
        };

        selectLayerAndSaveChoice(ConstantsLayers.VisibleGlobalLayerName, newSettingsToSave);
    }

    

    return (
        <>
            {/* ↓ bouton - sélection de layer (2 choix : WMSLayersInfo-ndvi, WMTSLayersInfo-satellite) ↓ */}
            {(props.parcelIdSelected > 0) &&

                <Tooltip title={<Typography color="inherit">{StringTranslate.tooltipcouche}</Typography>}>
                    {(props.forewardLayerSelected !== ConstantsLayers.VisibleGlobalLayerName) ?
                        <>
                            <Box sx={{
                                display: { xs: 'none', sm: 'none', md: 'block' }
                            }}>
                                <SubCard sx={{ zIndex: "1100" }}>
                                    <Grid container alignItems="center" spacing={0} justifyContent="space-between">
                                        <Grid item>
                                            <Stack spacing={1}>
                                                <Tooltip 
                                                    open={isModelisationImage && openTooltipForModelizedImage} 
                                                    title={StringTranslate.noVerifCloudForModelisation}
                                                    onOpen={() => {setOpenTooltipForModelizedImage(true);}}
                                                    onClose={() => {setOpenTooltipForModelizedImage(false);}}
                                                >
                                                    <span>
                                                        <Button 
                                                            variant="outlined" 
                                                            onClick={onSelectCloudLayer}
                                                            disabled={isModelisationImage}
                                                        >
                                                            {StringTranslate.verifClouds}
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            </Stack>
                                        </Grid>
                                        <Grid item>
                                            <Tooltip 
                                                open={isModelisationImage && openTooltipIconForModelizedImage} 
                                                title={StringTranslate.noVerifCloudForModelisation}
                                                onOpen={() => {setOpenTooltipIconForModelizedImage(true);}}
                                                onClose={() => {setOpenTooltipIconForModelizedImage(false);}}
                                            >
                                                <span>
                                                    <IconButton
                                                        sx={{
                                                            bgcolor: theme.palette.primary.light,
                                                            color: theme.palette.primary.main,
                                                            '& .MuiSvgIcon-root': { fontSize: '1.5rem' }
                                                        }}
                                                        size="large"
                                                        onClick={onSelectCloudLayer}
                                                        disabled={isModelisationImage}
                                                    >
                                                        <CloudIcon />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </SubCard>
                            </Box>

                            <Box sx={{
                                display: { xs: 'block', sm: 'block', md: 'none' }
                            }}>
                                {/* Tooltip Mobile déclenché par un LongPress */}
                                <Tooltip 
                                    open={openTooltipMobile}
                                    title={
                                        <Typography color="inherit">{(isModelisationImage)?StringTranslate.noVerifCloudForModelisation:StringTranslate.verifClouds}</Typography>
                                    } 
                                    placement="bottom-start"
                                    onClose={()=>setOpenTooltipMobile(false)}
                                    enterDelay={500}
                                >
                                    <span
                                        onTouchStart={()=>setOpenTooltipMobile(true)}
                                    >
                                        {/* Ici lorsqu'il s'agit d'une image modélisée, on ne désactive pas le bouton
                                         => Car s'il est désactivé, on ne peut pas voir le Tooltip (malgré le span)
                                         => Donc si l'image est modélisée, on lui donne l'aspect désactivé mais on le désactive pas pour autant */}
                                        <Fab
                                            className='leaflet-control'
                                            size="small"
                                            onClick={onSelectCloudLayer}
                                            sx={(isModelisationImage)?{
                                                backgroundColor: theme.palette.action.disabledBackground,
                                                color: theme.palette.primary.main,
                                                opacity: theme.palette.action.disabledOpacity,
                                                ":focus": {
                                                    backgroundColor: theme.palette.action.disabledBackground,
                                                }
                                            }:{
                                                backgroundColor: theme.palette.background.paper,
                                                color: theme.palette.primary.main
                                            }}
                                        >
                                            <CloudIcon />
                                        </Fab>
                                    </span>
                                </Tooltip>
                            </Box>
                        </>
                        :
                        <>
                            <Box sx={{
                                display: { xs: 'none', sm: 'none', md: 'block' }
                            }}>
                                <SubCard sx={{ zIndex: "1100" }}>
                                    <Box sx={{
                                        display: { xs: 'none', sm: 'none', md: 'block' }
                                    }}>
                                        <Grid container alignItems="center" spacing={0} justifyContent="space-between">
                                            <Grid item>
                                                <Stack spacing={1}>
                                                    <Button variant="text" onClick={onSelectNdviLayer}>
                                                        {StringTranslate.goBackToVegetationIndex}
                                                    </Button>
                                                </Stack>
                                            </Grid>
                                            <Grid item>
                                                {/*Bouton pour afficher l'indice de végétation*/}
                                                <IconButton
                                                    sx={{
                                                        bgcolor: theme.palette.primary.light,
                                                        color: theme.palette.primary.main,
                                                        '& .MuiSvgIcon-root': { fontSize: '1.5rem' }
                                                    }}
                                                    size="large"
                                                    onClick={onSelectNdviLayer}>
                                                    <CloudOffIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>

                                        {/* Evolution à venir pour permettre la désactivation d'une image en particulier !
                                        { (props.satimageIdSelectedOfParcel > 0) && <Grid container alignItems="right" spacing={0} justifyContent="space-between">
                                            <Grid item spacing={1}>
                                                <ImageDeactivateButtonControl />
                                            </Grid>
                                        </Grid> }
                                        */}
                                    </Box>
                                </SubCard>
                            </Box>


                            <Box sx={{
                                display: { xs: 'block', sm: 'block', md: 'none' }
                            }}>
                                <Tooltip title={<Typography color="inherit">{StringTranslate.goBackToVegetationIndex}</Typography>} placement="left">
                                    <Fab
                                        className='leaflet-control'
                                        size="small"
                                        onClick={onSelectNdviLayer}
                                        sx={{
                                            backgroundColor: theme.palette.background.paper,
                                            color: theme.palette.primary.main
                                        }}>
                                        <CloudOffIcon />
                                    </Fab>
                                </Tooltip>
                            </Box>
                        </>
                    }
                </Tooltip>

            }
        </>


    )
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant */
const mapStateToProps = function (state) {
    return {
        parcelIdSelected: lodashGet(state, 'contextAppData.parcelIdSelected', -1),
        providerSrcImageSelectedOfParcel: lodashGet(state, 'contextAppData.providerSrcImageSelectedOfParcel', 0),
        satimageIdSelectedOfParcel: lodashGet(state, 'contextAppData.satimageIdSelectedOfParcel', -1),

        settings: lodashGet(state, 'settingsData.settings', {}), // seul moyen trouvé pour rafraichir le visuel (traduction) du fait que maintenant on rentre dans l'application sans attendre les informations du client
        forewardLayerSelected: lodashGet(state, 'settingsData.forewardLayerSelected', ConstantsLayers.NdviParcelLayerName),
    };
}

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
    askToChangeLayerAndSaveSettings: (baseLayertypeValue, forewardLayertypeValue, newSettingsValue) => dispatch(ActionAskToChangeLayer(baseLayertypeValue, forewardLayertypeValue, newSettingsValue)),
    globalLayerLoaded: () => dispatch(ActionGlobalLayerLoaded()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LayerCloudSelectionButtonControl);