import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ActionSetCurrentPageName, PageName } from '../../redux/actions/contextApp.js';

import BiomassesList from "../../components/biomasse/biomassesList";
import ChoixParcelsBiomass from "../../components/biomasse/choixParcelsBiomass";
import WeightingBiomass from "../../components/biomasse/weightingBiomass";
import MapBiomass from "../../components/biomasse/mapBiomass";
import MainCard from "../../components/subLayouts/mainCard.jsx";

import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

import { ActionGetAllWeightingBiomasses, BiomassStep } from '../../redux/actions/biomass';

import { Add, Balance } from '@mui/icons-material';

import '../../assets/css/rm-render_maps.css';

import { Tab, Tabs, Box } from "@mui/material";

import { TabPanel, buildPropsForTab } from '../../components/tabPanel';

import { ConstantsContent } from '../../utils/constantsContent.js';

import getTheme from "../../themes/index.js";
import DataGridSkeleton from "../../components/dataGridSkeleton.jsx";
let theme = getTheme();

///////////////////////////////////////////////////////////////////////////
// Composant général concernant notre carte (gestion et affichage).
///////////////////////////////////////////////////////////////////////////
class AbstractBiomass extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            valueTab: 0
        };
    }

    handleChangeTab = (event, newValue) => {
        this.setState({
            valueTab: newValue
        });
    }

    componentDidMount() {
        const { setCurrentPageName, getAllWeightingBiomasses, clientId } = this.props;

        setCurrentPageName(PageName.BiomassColza, false);

        if (getAllWeightingBiomasses)
            getAllWeightingBiomasses(clientId);
    }

    getContentOfStepsForBuildBiomass(biomassStep) {

        const { isOpenMenuBurgerDesktop } = this.props;

        //Quand le popup des félicitations est affiché, l'utilisateur est redirigé vers le choix des des parcelles pour la pesée de colza 
        //La liste des pesées est affichée que quand on ferme le popup. Donc forcément, on se retrouve au niveau
        //du choix des parcelles pour la pesée. 

        if (window) {
            window.scrollTo(0, 0); //Permet de se positionner sur la liste des pesées enregistrées.
        }

        if (biomassStep === BiomassStep.WEIGHTING) {
            return (
                <WeightingBiomass />
            );
        }
        else if (biomassStep === BiomassStep.MAP) {
            return (
                <Box
                    className="contentAbstractMap"
                    sx={{
                        [theme.breakpoints.up('md')]: { 
                            left: isOpenMenuBurgerDesktop ? ConstantsContent.DrawerWidth : ConstantsContent.MarginCard,
                            width: isOpenMenuBurgerDesktop ? `calc(100% - ${ConstantsContent.DrawerWidth + ConstantsContent.MarginCard}px)` : `calc(100% - ${ConstantsContent.MarginCard * 2}px)`
                        }
                    }}>
                    <MapBiomass />
                </Box>)
                ;
        } else /*if (biomassStep === BiomassStep.CHOIX_PARCELS)*/ { //par défaut !
            return <>
                <ChoixParcelsBiomass />
            </>;
        }
    }

    /* fonction cycle de vie react */
    render() {
        const { biomassStep, loadWaiting } = this.props;
        const { valueTab } = this.state;

        return (
            (biomassStep === BiomassStep.CHOIX_PARCELS) ?
          
                    <MainCard>
                    
                        <Tabs
                            value={valueTab}
                            variant="scrollable"
                            onChange={this.handleChangeTab}
                            variant_pz="row"
                        >
                            {/* menu des onglets: ('buildPropsForTab' génère les ids entre les items de menu et leur contenu respectif) */}
                            <Tab
                                component={Link}
                                to="#"
                                icon={<Add />}
                                label={StringTranslate.listparcelsBiomass}
                                {...buildPropsForTab('menuBiomass', 0)} />
                            <Tab
                                component={Link}
                                to="#"
                                icon={<Balance />}
                                label={StringTranslate.weightingBiomassesExistsTitle}
                                {...buildPropsForTab('menuBiomass', 1)} />
                        </Tabs>

                    {/* contenu des onglets: */}
                    <TabPanel value={valueTab} index={0} prefixId='menuBiomass'>
                            <ChoixParcelsBiomass {...this.props} />
                        </TabPanel>
                    <TabPanel value={valueTab} index={1} prefixId='menuBiomass'>
                        {(loadWaiting === true) ? (
                            <DataGridSkeleton/>
                        ) : (
                                <BiomassesList />
                            )}
                    </TabPanel>
                    
                    </MainCard>
                       :
                /* rendu des étapes lors de la génération de pesée de biomasse (dont celle de cartographique) */
                this.getContentOfStepsForBuildBiomass(biomassStep)
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = function (state) {
    return {
        //Infos provenant du reducer 'biomasses':
        biomassStep: (state && state.biomassData) ? state.biomassData.biomassStep : BiomassStep.CHOIX_PARCELS,
        readableMode: state.biomassData.readableMode,
        loadWaiting: (state && state.biomassData) ? state.biomassData.loading : false, //@@RQ: Il en faudrait une lors des appel aux différents 'Build...' !
        hasAskedForEndingBiomass: (state && state.biomassData) ? state.biomassData.hasAskedForEndingBiomass : false,

        //Infos provenant du reducer 'settings':
        settings: state.settingsData.settings,
        rowsPerPageForTableBiomasses: (state && state.settingsData && state.settingsData.settings && (state.settingsData.settings.rowsPerPageForTableBiomasses !== undefined && state.settingsData.settings.rowsPerPageForTableBiomasses !== null)) ? state.settingsData.settings.rowsPerPageForTableBiomasses : 16,

        //Infos provenant du reducer 'clientUser':
        clientId: (state && state.clientUserData && state.clientUserData.clientDatas) ? state.clientUserData.clientDatas.id : -1,
        clientDatas: state.clientUserData.clientDatas,

        //Infos provenant du reducer 'contextApp':
        isOpenMenuBurgerDesktop: (state && state.contextAppData) ? state.contextAppData.isOpenMenuBurgerDesktop : false,
    };
}

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({

    //nécessaire pour 'DisplayIncitationPremium':
    getAllWeightingBiomasses: (clientId) => dispatch(ActionGetAllWeightingBiomasses(clientId)),

    //nécessaire pour renseigner le nom de la page en cours
    setCurrentPageName: (pageName, showTutorialFirstParcel) => dispatch(ActionSetCurrentPageName(pageName, showTutorialFirstParcel)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AbstractBiomass);