import React, { Component } from "react";

import {
    Dialog, Button, Typography, Grid,
    DialogContent, DialogTitle, DialogContentText,
    DialogActions, Avatar, IconButton, Stack
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';

import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import LogoSpotifarm from '../../assets/logoSpotifarm/LogoSpotifarmTitreEtFeuille';
import { Help } from '@mui/icons-material';
import { IsNativeHoster } from '../../utils/platformHelper';

import incitation_premiumsvg from '../../assets/images/didacticiel/incitation_premium.svg';

import getTheme from "../../themes/index.js";
import { ProfilIndex } from "../../redux/actions/contextApp.js";


let theme = getTheme();

const CLOSE_WINDOW = "CLOSE_WINDOW";
const GO_TO_PROFIL = "GO_TO_PROFIL";

////////////////////////////////////////////////////////////////////////////////////////
// Composant appliqué par dessus (position:absolute) le composant RenderMaps 
//  pour afficher l'incitation au passage en mode Premium avant la fin du mode Explorer
////////////////////////////////////////////////////////////////////////////////////////
class DisplayIncitationExplorerBeforeEnd extends Component {
    /* constructeur */
    constructor(props) {
        super(props);

        this.state = {
            open: true,
        }
    }

    /* fonction permettant de fermer le fenêtre de dialogue */
    closeDialog(userChoice = CLOSE_WINDOW) {
        this.setState({ open: false });

        /* ↓ demande la fermeture de la popup d'incitation en précisant l'action utilisateur */
        if (this.props.closeInviteToPremium) {
            this.props.closeInviteToPremium(userChoice);
        }
    }

    handleCloseDialog() {
        this.closeDialog();
    }

    handleGotoProfil() {
        if (window.dataLayer) {// google tag manager
            window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'incitationProfilPage' });
        }

        /* ↓ demande le changementde page, au profit de l'écran des abonnements */
        if (this.props.showProfilMenuDialog) {
            this.props.showProfilMenuDialog(ProfilIndex.abonnements);
        }

        this.closeDialog(GO_TO_PROFIL);
    }

    /* cycle de vie react */
    render() {
        const { fullScreen } = this.props;
        const { open } = this.state;

        let cultureValue = StringTranslate.getLanguage();
        if ((!cultureValue) || (cultureValue.length < 5)) {
            cultureValue = 'fr-FR';
        }
        //const languageValue = cultureValue.substring(0, 2);

        const nativeAppHoster = IsNativeHoster();

        return (open) ?
            (
                <>
                    {/* ↓↓ Boite de dialogue en lien avec l'import de parcelle GEOFOLIA FAH ↓↓ */}
                    <Dialog open={open}
                        fullScreen={fullScreen} // en mode fullscreen si on est en mobile @@ mettre en place
                        maxWidth={'xs'} // limitation de la largeur
                    >
                        <DialogTitle sx={{ display:"flex", alignItems:"center"}}>
                            {/* ↓↓ Partie informant le client d'une incitation à la sauvegarde, suite au refus d'une fonctionnalité ↓↓ */}
                            <LogoSpotifarm/>
                            {/* Si l'appli Web est lancée dans l'appli mobile: */}
                            {(nativeAppHoster === true) ?
                                <IconButton sx={{
                                    position: 'absolute',
                                    right: 10,
                                    top: 10,
                                    color: (theme) => theme.palette.grey[500]
                                }}>
                                    <CloseIcon onClick={this.handleCloseDialog.bind(this)} />
                                </IconButton> : null}

                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h4">{StringTranslate.incitationendoftrialtitle} <span role="img" aria-label="emojifortitle">💚</span></Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <img src={incitation_premiumsvg} alt="" className="dfp-img-incitation" />
                                </Grid>
                                <Grid item xs={8}>
                                    {/* Si l'appli Web est lancée dans un navigateur (pas dans l'appli mobile): */}
                                    {(nativeAppHoster !== true) ?
                                        <Grid container rowSpacing={2}>
                                            <Grid item>
                                                <DialogContentText>{StringTranslate.incitationendoftrialcontent1}</DialogContentText>
                                            </Grid>
                                            <Grid item>
                                                <DialogContentText>{StringTranslate.incitationendoftrialcontent2}</DialogContentText>
                                            </Grid>
                                            <Grid item>
                                                <DialogContentText>{StringTranslate.incitationendoftrialcontent3}</DialogContentText>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container rowSpacing={2}>
                                            <Grid item>
                                                <DialogContentText>{StringTranslate.incitationendoftrialcontent2ForMobile}</DialogContentText>
                                            </Grid>
                                            <Grid item>
                                                <DialogContentText>{StringTranslate.incitationendoftrialcontent3}</DialogContentText>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container spacing={2} sx={{ mt: 1 }}>
                                        <Grid item xs={2}>
                                            <Avatar
                                                variant="rounded"
                                                color="inherit"
                                                component="a"
                                                sx={{ bgcolor: theme.palette.primary, color: theme.palette.common.white, ml: 'auto' }}
                                                href="mailto:contact@spotifarm.fr">
                                                <Help />
                                            </Avatar>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant="body2" sx={{ mb: 2 }}>
                                                {StringTranslate.incitationtrialfooter}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            {/* Si l'appli Web est lancée dans un navigateur (pas dans l'appli mobile): */}
                            {(nativeAppHoster !== true) ?
                                <Grid container>
                                    <Grid item xs={12} >
                                        <Stack direction="row" justifyContent="right"
                                            alignItems="right">
                                            <Button sx={{ mr: 1 }}
                                                variant="text"
                                                color="primary"
                                                onClick={this.handleCloseDialog.bind(this)}
                                            >
                                                {StringTranslate.incitationendoftrialnotnow}
                                            </Button>
                                            <Button
                                                variant="contained" color="primary"
                                                onClick={this.handleGotoProfil.bind(this)}
                                            >
                                                {StringTranslate.incitationendoftrialok}
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                :
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={this.handleCloseDialog.bind(this)}>
                                    {StringTranslate.close}
                                </Button>
                            }
                        </DialogActions>
                    </Dialog></>) : (null);
    }
}

export default DisplayIncitationExplorerBeforeEnd; 