import { ActionTypeSettings } from '../actions/settings.js';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import { LanguageHelper } from '../../utils/languageHelper.js';
import ConstantsLayers, { GetBaseLayerName, GetBaseLayerIndex, GetForewardLayerName, GetLastLayerIndex } from '../../utils/constantsOfLayers.js';


// objet inital représentant le state initial
const initialState = {
    loading: false,
    updating: false,
    reseting: false,
    settings: { //infos tel qu'elles sont enregistrées en BdD !
        clientId: 0,
        nameExploitation: '',
        parcelMaxcc: 20,
        lastLayer: 4, // corresponds à 'ConstantsLayers.NdviParcelLayerName'
        baseLayer: 'satellite', // corresponds à 'ConstantsLayers.VisibleBaseLayerName'
        language: LanguageHelper.getLanguage(), // permet de récupérer la langue en cours
        codeCountry: '',
        showNdviCharts: true,
        imageModulationSizeQuality: 128, // image modulation
        rememberModulationSettings: true, //Se rappeler des anciens paramètres de modulation pour les prochaines modulations
        buildIsoXmlV4: true,
        
        rowsPerPageForTableParcels: 20, //nombre de lignes dans le tableau des parcelles par page
        rowsPerPageForTableModulations: 20, //nombre de lignes dans le tableau des modulations par page
        rowsPerPageForTableObservations: 20, //nombre de lignes dans le tableau des observations par page
        rowsPerPageForTableBiomasses: 16, //nombre de lignes dans le tableau des biomasses par page
        rowsPerPageForTableModelisations: 10, //nombre de lignes dans le tableau des modélisations par page
        rowsPerPageForTableFertilizers: 20, //nombre de lignes dans le tableau des fumures par page
        rowsPerPageForTableHarvests: 20, //nombre de lignes dans le tableau des récoltes de maïs par page

        displayLandsatImgs: true, //pour savoir si les images landsat8 sont affichées ou non
    },            
    //Choix des couches: 
    baseLayerSelected: ConstantsLayers.VisibleBaseLayerName, // VisibleBaseLayerName ou RoadBaseLayerName !
    forewardLayerSelected: ConstantsLayers.NdviParcelLayerName, // NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
    errorMessage: null,
};

function settingsUserReducer(state = initialState, action) {
    
    switch (action.type) {
        case ActionTypeSettings.UpdateNbRowsPerPageTableParcels: {
            return Object.assign({}, state, {
                settings: {
                    ...state.settings,
                    rowsPerPageForTableParcels: action.rowsPerPageForTableParcels,
                }
            });
        }

        case ActionTypeSettings.UpdateNbRowsPerPageTableModulations: {
            return Object.assign({}, state, {
                settings: {
                    ...state.settings,
                    rowsPerPageForTableModulations: action.rowsPerPageForTableModulations,
                }
            });
        }

        case ActionTypeSettings.UpdateNbRowsPerPageTableBiomasses: {
            return Object.assign({}, state, {
                settings: {
                    ...state.settings,
                    rowsPerPageForTableBiomasses: action.rowsPerPageForTableBiomasses,
                }
            });
        }

        case ActionTypeSettings.UpdateNbRowsPerPageTableFertilizers: {
            return Object.assign({}, state, {
                settings: {
                    ...state.settings,
                    rowsPerPageForTableFertilizers: action.rowsPerPageForTableFertilizers,
                }
            });
        }

        case ActionTypeSettings.UpdateNbRowsPerPageTableObservations: {
            return Object.assign({}, state, {
                settings: {
                    ...state.settings,
                    rowsPerPageForTableObservations: action.rowsPerPageForTableObservations,
                }
            });
        }

        case ActionTypeSettings.UpdateNbRowsPerPageTableModelisations: {
            return Object.assign({}, state, {
                settings: {
                    ...state.settings,
                    rowsPerPageForTableModelisations: action.rowsPerPageForTableModelisations,
                }
            });
        }

        case ActionTypeSettings.UpdateNbRowsPerPageTableHarvests: {
            return Object.assign({}, state, {
                settings: {
                    ...state.settings,
                    rowsPerPageForTableHarvests: action.rowsPerPageForTableHarvests,
                }
            });
        }

        case ActionTypeSettings.SaveRememberModulaitonParameters: {
            return Object.assign({}, state, {
                settings: {
                    ...state.settings,
                    rememberModulationSettings: action.rememberModulationSettings,
                }
            });
        }

        case ActionTypeSettings.LoadingSettings: {
            return Object.assign({}, state, {
                loading: true,
            });
        }

        case ActionTypeSettings.UpdatingSettings: {
            return Object.assign({}, state, {
                updating: true,
            });
        }
            
        case ActionTypeSettings.SettingsLoaded: {
            // ↓ on met à jour la langue ↓
            if (action.settings && action.settings.language) {
                LanguageHelper.updateLanguage(action.settings.language);
            }

            if (action.settings) {
                return Object.assign({}, state, {
                    loading: false,
                    updating: false,
                    settings: action.settings,
                    baseLayerSelected: (action.settings) ? GetBaseLayerName(action.settings.baseLayer) : ConstantsLayers.VisibleBaseLayerName, // VisibleBaseLayerName ou RoadBaseLayerName !
                    forewardLayerSelected: (action.settings) ? GetForewardLayerName(action.settings.lastLayer) : ConstantsLayers.NdviParcelLayerName, // NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
                    rememberModulationSettings: action.settings.rememberModulationSettings
                });
            } else { //Ne devrait pas arriver !
                return Object.assign({}, state, {
                    loading: false,
                    updating: false,
                    errorMessage : StringTranslate.noLoadSettingsDatas,
                });
            }
        }
            
        case ActionTypeSettings.ErrorLoadSettings: {
            return Object.assign({}, state, {
                loading: false,
                updating: false,
                errorMessage : action.errorMessage,
            });
        }

        case ActionTypeSettings.saveSettings: {
            return Object.assign({}, state, {
                updating: false,
                settings: action.settings,
                baseLayerSelected: (action.settings) ? GetBaseLayerName(action.settings.baseLayer) : ConstantsLayers.VisibleBaseLayerName, // VisibleBaseLayerName ou RoadBaseLayerName !
                forewardLayerSelected: (action.settings) ? GetForewardLayerName(action.settings.lastLayer) : ConstantsLayers.NdviParcelLayerName, // NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
            });
        }

        case ActionTypeSettings.ResetingSettings: {
            return Object.assign({}, state, {
                reseting: true,
            });
        }

        case ActionTypeSettings.saveResetedSettings: {
            return Object.assign({}, state, {
                reseting: false,
                settings: action.settings,
                baseLayerSelected: (action.settings) ? GetBaseLayerName(action.settings.baseLayer) : ConstantsLayers.VisibleBaseLayerName, // VisibleBaseLayerName ou RoadBaseLayerName !
                forewardLayerSelected: (action.settings) ? GetForewardLayerName(action.settings.lastLayer) : ConstantsLayers.NdviParcelLayerName, // NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
            });
        }

        //Choix de la langue:
        case ActionTypeSettings.SetDefaultLanguage: {
            return Object.assign({}, state, {
                language: LanguageHelper.defaultLanguage,
            });
        }
            
        case ActionTypeSettings.SetLanguage: {
            state.settings = Object.assign({}, state.settings, {
                language: action.newCulture,
            });
            return state;
        }
        
        //Choix des couches:        
        case ActionTypeSettings.SelectLayer: {
            //baseLayerSelected peut valoir: ConstantsLayers.VisibleBaseLayerName, // VisibleBaseLayerName ou RoadBaseLayerName !
            //forewardLayerSelected peut valoir: NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
            let newBaseLayersSelected = action.baseLayertype;
            if ((!newBaseLayersSelected) || (
                (newBaseLayersSelected !== ConstantsLayers.VisibleBaseLayerName) && (newBaseLayersSelected !== ConstantsLayers.RoadBaseLayerName)) ) {
                newBaseLayersSelected = ConstantsLayers.VisibleBaseLayerName;
            };
            let newForewardLayersSelected = action.forewardLayertype;
            if ((!newForewardLayersSelected) || (
                (newForewardLayersSelected === ConstantsLayers.VisibleBaseLayerName) || (newForewardLayersSelected === ConstantsLayers.RoadBaseLayerName)) ) {
                newForewardLayersSelected = ConstantsLayers.NdviParcelLayerName;
            };
    
            return Object.assign({}, state, {
                settings: {
                    ...state.settings,
                    baseLayer: GetBaseLayerIndex(newBaseLayersSelected),
                    lastLayer: GetLastLayerIndex(newForewardLayersSelected),
                },
                baseLayerSelected: newBaseLayersSelected, 
                forewardLayerSelected: newForewardLayersSelected, 
            });
        }

        case ActionTypeSettings.displayLandsatImgs: {
            return Object.assign({}, state, {
                settings: {
                    ...state.settings,
                    displayLandsatImgs: action.displayLandsatImgs
                },
            });
        }

        default:
            return state;
    }
}

export default settingsUserReducer;
