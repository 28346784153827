import { get as lodashGet, isNil as lodashIsNil, filter as lodashFilter, includes as lodashIncludes, find as lodashFind } from 'lodash';
import dateHelper from '../utils/dateHelper';
//import lodashIsEmpty from 'lodash/isEmpty';
//import lodashIsDate from 'lodash/isDate';
import ConstantsFertilizer from '../utils/constantsFertilizer.js';
import { FertilizerEngineEnum } from '../redux/actions/fertilizer';
/* Traduction */
import StringTranslate from '../assets/i18n/stringLanguage';


const fertilizerHelper = {

    relicTypeEnum: {
        unknown: 0,
        analyse: 1,
        valeurRegionale: 2,
        valeurEstimee: 3,
    },

    /* fonction permettant de lister les fumures par parcel id */
    sortByParcel(fertilizersList) {
        if (fertilizersList !== undefined && fertilizersList !== null) {
            fertilizersList = fertilizersList.sort((a, b) => (a.parcelId >= b.parcelId) ? 1 : -1);
        }
        return fertilizersList;
    },

    /* Fonction permettant de savoir si la culture de la parcelle correspond à la culture sélectionnée pour la fumure */
    hasFertilizerCropSelected(parcelCrop, fertilizerCrop) {
        let found = false;

        // On se s'occupe que de culture d'hivers pour le moment
        if (parcelCrop.toLowerCase().includes("printemps") || parcelCrop.toLowerCase().includes("primavera") || parcelCrop.toLowerCase().includes("spring")) {
            return found;
        }

        switch(fertilizerCrop) {
            case 1: //blé
                if ((parcelCrop.toLowerCase().includes("ble") || parcelCrop.toLowerCase().includes("wheat") || parcelCrop.toLowerCase().includes("bth") || parcelCrop.toLowerCase().includes("blé") || parcelCrop.toLowerCase().includes("trigo"))
                && !(parcelCrop.toLowerCase().includes("bdh") || parcelCrop.toLowerCase().includes("dur") || parcelCrop.toLowerCase().includes("durum") || parcelCrop.toLowerCase().includes("duro"))) {
                    found = true;
                }
                break;
            case 2: //orge
                if ((parcelCrop.toLowerCase().includes("orge") || parcelCrop.toLowerCase().includes("barley") || parcelCrop.toLowerCase().includes("orh") || parcelCrop.toLowerCase().includes("orge 6 rangs d'hiver") || parcelCrop.toLowerCase().includes("escourgeon") || parcelCrop.toLowerCase().includes("cebada"))) {
                        found = true;
                }
                break;
            case 3: //colza
                if ((parcelCrop.toLowerCase().includes("colza") || parcelCrop.toLowerCase().includes("czh") || parcelCrop.toLowerCase().includes("czp") || parcelCrop.toLowerCase().includes("rapeseed"))) {
                    found = true;
                }
                break;
            case 4: //blé dur
                if ((parcelCrop.toLowerCase().includes("ble") || parcelCrop.toLowerCase().includes("wheat") || parcelCrop.toLowerCase().includes("blé") || parcelCrop.toLowerCase().includes("trigo")
                    || parcelCrop.toLowerCase().includes("bdh")  || parcelCrop.toLowerCase().includes("durum"))
                    && !(parcelCrop.toLowerCase().includes("bth") || parcelCrop.toLowerCase().includes("tendre") || parcelCrop.toLowerCase().includes("soft") || parcelCrop.toLowerCase().includes("blando"))) {
                    found = true;
                }
                break;
            default:
                found = false;
                break;
        }
        return found;
    },

    /**
     * fonction d'initialisation de la culture par défaut de l'écran de saisis commune
     * @param {*} culture objet culture, car on utilise culture.id
     * @returns la date de récolte par défaut sous le format new Date
     */
    getDefaultHarvestDateByCulture(culture) {
        // Attention: le mois vont de 0 à 11 sachant que 0 est Janvier et 11 est Décembre
        // l'année et le jour sont normaux
        if (culture.id.toString() === "1") { //Blé
            let today = new Date();
            if (today < new Date(today.getFullYear(), 6, 15)) {
                return new Date(today.getFullYear(), 6, 15);        // 15 Juillet
            }
            else {
                return new Date(today.getFullYear() + 1, 6, 15);
            }
        }
        else if (culture.id.toString() === "2") { //Escourgeon
            let today = new Date();
            if (today < new Date(today.getFullYear(), 6, 5)) {
                return new Date(today.getFullYear(), 6, 5);        // 5 Juillet
            }
            else {
                return new Date(today.getFullYear() + 1, 6, 5);
            }
        }
        else if (culture.id.toString() === "4") { //Blé dur
            let today = new Date();
            if (today < new Date(today.getFullYear(), 6, 15)) {
                return new Date(today.getFullYear(), 6, 15);        // 15 Juillet
            }
            else {
                return new Date(today.getFullYear() + 1, 6, 15);
            }
        }
        else  { //Colza
            let today = new Date();
            if (today < new Date(today.getFullYear(), 6, 15)) {
                return new Date(today.getFullYear(), 6, 15);        // 15 Juillet
            }
            else {
                return new Date(today.getFullYear() + 1, 6, 15);
            }
        }
    },

    /**
     * création de la date par défaut au format new Date
     * @param {*} culture l'objet comportant la culture car on utilise culture.id
     * @returns la date par défaut de semis
     */
    getDefaultSowingDateByCulture(culture) {
        // Attention: le mois vont de 0 à 11 sachant que 0 est Janvier et 11 est Décembre
        // l'année et le jour sont normaux
        const harvestYear = fertilizerHelper.getDefaultHarvestDateByCulture(culture).getFullYear();
        if ((culture.id.toString() === "1") || (culture.id.toString() === "2")) { //Blé || Escourgeon
            return new Date(harvestYear - 1, 9, 15);    // 15 Oct
        }
        else if (culture.id.toString() === "4") { // Blé dur
            return new Date(harvestYear - 1, 10, 1);    // 1 Nov
        }
        else  { //Colza
            return new Date(harvestYear - 1, 8, 5);    // 5 Sept
        }
    },

    /**
     * date par défaut: 15 Juillet (année de campagne - 1)
     * @param {*} anneeCampagne l'année de campagne sur laquelle on va se baser pour rendre la date de récolte précédente
     * @returns un format Date si l'année de campagne existe, sinon null
     */
    getDefaultPreviousHarvestDateByCulture(anneeCampagne) {
        // Attention: le mois vont de 0 à 11 sachant que 0 est Janvier et 11 est Décembre
        // l'année et le jour sont normaux

        if (anneeCampagne !== null) {
            return new Date(anneeCampagne - 1, 6, 15);
        } else {
            return null;
        }
    },

    /**
     * date par défaut: 15 Sept. (année de campagne - 1)
     * @param {*} anneeCampagne l'année de campagne sur laquelle on va se baser pour rendre la date de récolte précédente
     * @param {*} hasApportOrga bool true si il y a un apport organique, false sinon
     * @returns un format Date si l'année de campagne existe, sinon null
     */
    getDefaultOrganicInputDateByCulture(anneeCampagne, hasApportOrga) {
        // Attention: le mois vont de 0 à 11 sachant que 0 est Janvier et 11 est Décembre
        // l'année et le jour sont normaux

        if ((anneeCampagne !== null) && (hasApportOrga)) {
            return new Date(anneeCampagne - 1, 8, 15);
        } else {
            return null;
        }
    },
     
    //Demande si les infos sont identiques
    compareCommonInfos(oldDatas, newDatas) {
        if ( (oldDatas === undefined) ||
            (dateHelper.isSameDateDDMMYYYY(new Date(oldDatas.sowingDate), new Date(newDatas.sowingDate)) === false) ||
            (dateHelper.isSameDateDDMMYYYY(new Date(oldDatas.harvestDate), new Date(newDatas.harvestDate)) === false) ||
            (lodashGet(oldDatas, 'productionTarget.id', undefined) !== lodashGet(newDatas, 'productionTarget.id', undefined)) ||
            (lodashGet(oldDatas, 'performanceGoal', undefined) !== lodashGet(newDatas, 'performanceGoal', undefined)) ||
            (lodashGet(oldDatas, 'intermediateCultureFrequency.id', undefined) !== lodashGet(newDatas, 'intermediateCultureFrequency.id', undefined)) ||
            (lodashGet(oldDatas, 'tailingsManagement.id', undefined) !== lodashGet(newDatas, 'tailingsManagement.id', undefined)) ||
            (lodashGet(oldDatas, 'fertilizerType.id', undefined) !== lodashGet(newDatas, 'fertilizerType.id', undefined)) ||
            (lodashGet(oldDatas, 'ploughing', undefined) !== lodashGet(newDatas, 'ploughing', undefined)) ||
            (lodashGet(oldDatas, 'ploughingDepth', undefined) !== lodashGet(newDatas, 'ploughingDepth', undefined)) ||
            (lodashGet(oldDatas, 'organicInputFrequency.id', undefined) !== lodashGet(newDatas, 'organicInputFrequency.id', undefined)) ||
            (lodashGet(oldDatas, 'interCropType.id', undefined) !== lodashGet(newDatas, 'interCropType.id', undefined)) ||
            (lodashGet(oldDatas, 'content', undefined) !== lodashGet(newDatas, 'content', undefined)) ||
            (lodashGet(oldDatas, 'doseReserved', undefined) !== lodashGet(newDatas, 'doseReserved', undefined)) ||
            (lodashGet(oldDatas, 'doseProvided', undefined) !== lodashGet(newDatas, 'doseProvided', undefined)) ) {
            return -1;
        }
        else {
            return 0;
        }
    },

    //retourne l'objet élément dans une liste
    findCommonListElement(listToCompare, id, defaultValue) {
        const elementFound = listToCompare.find(el => { return (el.id === id) });
        return ((elementFound === undefined) ? defaultValue : elementFound);
    },

    //retourne l'objet élément dans une liste
    findCommonListElementByLabel(listToCompare, label, defaultValue) {
        const elementFound = listToCompare.find(el => { return (el.label === label) });
        return ((elementFound === undefined) ? defaultValue : elementFound);
    },
    
    //fonction permettant de savoir si une donnée est vide, nulle
    dataIsNull(commonInfosElement) {
        if ((commonInfosElement === "-1") || 
            (commonInfosElement === "") || 
            (commonInfosElement === undefined) || 
            (commonInfosElement === null) ) {
                return true;
        }
        else {
            return false;
        }
    },

    //Fonction qui retourne la bonne fumure en fonction de la parcelle id et de la culture sélectionnée pour la fumure
    getFertilizerByParcelIdAndCropType(fertilizerDico, parcelId, cropType) { //'cropType' est en fait la valeur de énuméré !
        let fertilizer = undefined;
        for (const key in fertilizerDico) {
            let fertilizerFound = fertilizerDico[parseInt(key)];
            if (fertilizerFound && (fertilizerFound.idParcel === parcelId)) {
                fertilizer = fertilizerFound;
                break;
            }
        }
        //Si les infos ne correspondent pas à la culture choisie, on ne récupère pas les données spécifiques en base !
        if (fertilizer !== undefined) {
            if (parseInt(cropType) !== parseInt(fertilizer.enumOfCrop)) {
                fertilizer = undefined;
            }
        }

        return fertilizer;
    },

    //Fonction qui retourne la bonne fumure en fonction de la parcelle id
    getFertilizerByParcelId(fertilizerDico, parcelId) {
        let fertilizer = undefined;
        for (const key in fertilizerDico) {
            let fertilizerFound = fertilizerDico[parseInt(key)];
            if (fertilizerFound.idParcel === parcelId) {
                fertilizer = fertilizerFound;
                break;
            }
        }
        
        return fertilizer;
    },

    /**
     * 
     * @param {*} listOfSpecificInfos 
     * @param {*} isNO3 
     * @returns the list of 3 items either no3 or nh4 values
     */
    getRelicsData(listOfSpecificInfos, isNO3) {
        // fonction de récupération des infos des reliquats
        const relicSamples = lodashGet(listOfSpecificInfos, 'relicSamples', null);
        let theResult = ['', '', ''];

        if (relicSamples !== null) {
            if (isNO3) {
                theResult[0] = lodashGet(relicSamples[0], 'nO3', '');
                theResult[1] = lodashGet(relicSamples[1], 'nO3', '');
                theResult[2] = lodashGet(relicSamples[2], 'nO3', '');
                
            } else {
                theResult[0] = lodashGet(relicSamples[0], 'nH4', '');
                theResult[1] = lodashGet(relicSamples[1], 'nH4', '');
                theResult[2] = lodashGet(relicSamples[2], 'nH4', '');
            }
        }
        return theResult;
    },
    
    /**
     * @param {*} relicSamples 
     * @returns le nombre d'horizons à afficher
     */
    isHorizon3Exist(relicSamples) {
        let nbHorizonsToShow = 1;

        if ((relicSamples !== null) && 
        (relicSamples !== undefined) && 
        ((relicSamples[1].nO3 >= 0) || (relicSamples[1].nH4 >= 0))) {
            nbHorizonsToShow = 2;
        }

        if ((relicSamples !== null) && 
        (relicSamples !== undefined) && 
        ((relicSamples[2].nO3 > 0) || (relicSamples[2].nH4 > 0))) {
            nbHorizonsToShow = 3;
        }
        return nbHorizonsToShow;
    },

    /**
     * Fonction permettant de récupérer la bonne liste de variété
     * @param {*} ListVariete La liste de variété classique
     * @param {*} ListVarieteProt La liste de variété de protéine / améliorant
     * @param {*} culture L'énuméré de culture de la parcelle
     * @param {*} objProdBle L'objectif de production de la parcelle
     * @param {*} moteur Le moteur de fumure de la parcelle
     * @param {*} isImprovingCrop Si la culture est améliorante
     * @returns La liste correspondante au paramétrage de la parcelle
     */
    getRelevantVarietyList(ListVariete, ListVarieteProt, culture, objProdBle, moteur, isImprovingCrop){
        const cropType = lodashGet(culture, 'cropType', undefined);
        const objProdBleCode = lodashGet(objProdBle, 'code', undefined);
        // Si on est en blé d'hiver
        if ((cropType === 1) && (
            // Avec Azofert en objectif protéine
            ((moteur === FertilizerEngineEnum.AZOFERT) && (objProdBleCode === "2"))
            // Ou avec Fertiweb en culture améliorante
            || ((moteur === FertilizerEngineEnum.FERTIWEB) && (isImprovingCrop === true))))
        {
            return ListVarieteProt;
        } else {
            return ListVariete;
        };
    },

    /**
     * 
     * @param {*} list 
     * @param {*} specificInfo 
     * @param {*} propertyOfSpecificInfo 
     * @param {*} commonInfo 
     * @param {*} defaultValue 
     * @returns the object that was saved either in the specific infos, or common infos or returns the default value
     */
    getItemFromList(list, specificInfo, propertyOfSpecificInfo, commonInfo, defaultValue) {
        
        let theFinalObject = defaultValue;

        //Pas besoin de checker list.length avec 0 car 0 est déjà faux 
        if (list && list.length) {
            const specificInfoObject = lodashGet(specificInfo, propertyOfSpecificInfo, defaultValue);
            const specificInfoObjectId = lodashGet(specificInfoObject, "id", defaultValue);
            const commonInfoObjectId = (commonInfo > 0) ? commonInfo : defaultValue;
            if (specificInfoObjectId !== defaultValue) {
                theFinalObject = this.findCommonListElement(list, specificInfoObjectId, defaultValue);
            } else if (commonInfoObjectId !== defaultValue) {
                theFinalObject = this.findCommonListElement(list, commonInfoObjectId.toString(), defaultValue);
            }
        }

        return theFinalObject;
    },

    /**
     * 
     * @param {*} list 
     * @param {*} specificInfo 
     * @param {*} propertyOfSpecificInfo 
     * @param {*} defaultValue 
     * @returns la date qui correspond à la culture précédente
     */
    getPreviousSowingDateFromPreviousCrop(list, specificInfo, propertyOfSpecificInfo, defaultValue) {

        let theFinalObject = defaultValue;

        //Par défaut on privilégie la date de semi N-1 archivée.
        if ((theFinalObject !== null) || (theFinalObject !== undefined)) {
            return theFinalObject
        }
        //S'il n'y a pas de date de semi archivée :
        else {
            if (list && list.length) {
                const specificInfoObject = lodashGet(specificInfo, propertyOfSpecificInfo, defaultValue);
                const specificInfoObjectId = lodashGet(specificInfoObject, "id", defaultValue);

                if ((specificInfoObjectId !== defaultValue) && (specificInfoObjectId !== null)) {
                    theFinalObject = this.findCommonListElement(list, specificInfoObjectId, defaultValue);
                    return (theFinalObject !== null) ? theFinalObject.theFinalObject : theFinalObject;
                } 
            }
        }
        
        return theFinalObject;
    },

    /**
     * 
     * @param {*} specificInfo Si il n'y a pas de specificInfos, alors mettre undefined
     * @param {*} propertyOfSpecificInfo Si il n'y a pas de specificInfos, alors mettre ""
     * @param {*} variableSavedFromCommonInfos la valeur de commonInfos
     * @param {*} defaultValue la valeur à retourner dans le cas ou rien n'a été sauvegardé dans les variables passées avant
     * @param {*} isDate true si une date afin de retourner la réponse sous un format de date si il y a une valeur qui n'est pas la defaultValue
     * @returns the value that was saved either in the specific infos, or common infos or returns the default value
     */
    getValue(specificInfo, propertyOfSpecificInfo, variableSavedFromCommonInfos, defaultValue, isDate) {
        let theFinalVariable = defaultValue;
        const VariableFromSpecificInfoProperty = lodashGet(specificInfo, propertyOfSpecificInfo, defaultValue);
        const VariableFromCommonInfo = variableSavedFromCommonInfos;

        let chooseSpecificInfo = false;    
        if (specificInfo !== undefined) {
            if ((VariableFromSpecificInfoProperty !== null) && (VariableFromSpecificInfoProperty !== undefined) && (VariableFromSpecificInfoProperty !== defaultValue)) {
                chooseSpecificInfo = true;
            } else {
                chooseSpecificInfo = false;
            }
        } else {
            chooseSpecificInfo = false;
        };

        if (chooseSpecificInfo) {
            theFinalVariable = VariableFromSpecificInfoProperty;
        } else {
            if ((VariableFromCommonInfo !== null) && (VariableFromCommonInfo !== undefined) && (VariableFromCommonInfo !== defaultValue)) {
                theFinalVariable = VariableFromCommonInfo;
            }
        }

        // transformer theFinalVariable en format Date si isDate=true et que la valeur de theFinalVariable n'est pas defaultValue
        if (isDate) {
            if (theFinalVariable !== defaultValue) {
                theFinalVariable = new Date(theFinalVariable);
            }
        }
        return theFinalVariable;
    },

    /**
     * 
     * @param {*} specificInfo Si il n'y a pas de specificInfos, alors mettre undefined
     * @param {*} propertyOfSpecificInfo Si il n'y a pas de specificInfos, alors mettre ""
     * @param {*} variableSavedFromCommonInfos la valeur de commonInfos
     * @param {*} valueFromParcelInfo la valeur de la parcelle
     * @returns the value that was saved either in the specific infos, or common infos or parcelInfo
     */
    getSowingDateValue(specificInfo, propertyOfSpecificInfo, variableSavedFromCommonInfos, valueFromParcelInfo) {
        let theFinalVariable = null;
        const VariableFromSpecificInfoProperty = lodashGet(specificInfo, propertyOfSpecificInfo, null);
        const VariableFromCommonInfo = variableSavedFromCommonInfos;

        if (!lodashIsNil(VariableFromSpecificInfoProperty)) {
            theFinalVariable = VariableFromSpecificInfoProperty;
        }
        else if (!lodashIsNil(valueFromParcelInfo)) {
            theFinalVariable = valueFromParcelInfo;
        }
        else if (!lodashIsNil(VariableFromCommonInfo)) {
            theFinalVariable = VariableFromCommonInfo;
        }

        
        if (!lodashIsNil(theFinalVariable)) {
            theFinalVariable = new Date(theFinalVariable);
        }
        return theFinalVariable;
    },

    /**
     * Translate the name from the state (from dialogSpecificInfosEntry) to match the properties of the errorManagerDico
     * @param {*} value the name of the variable to be translated
     * @returns string of the name of the propery
     */
    getErrorManagerDicoPropertyName(value) {
        let translation = undefined;

        switch (value) {
            case 'objProdBle':
                translation = "productionTarget";
                break;
            case 'dateDeSemi':
                translation = "sowingDate";
                break;
            case 'dateDeRecolte':
                translation = "harvestDate";
                break;
            case 'typeDeSol':
                translation = "soilKindType";
                break;
            case 'objRendement':
                translation = "performanceGoal";
                break;
            case 'dateDeRecoltePrec':
                translation = "previousHarvestDate";
                break;
            case 'culturePrec':
                translation = "previousCrop";
                break;
            case 'rendementPrec':
                translation = "previousPerformance";
                break;
            case 'dateEnfouissementPrec':
                translation = "previousPloughingDate";
                break;
            case 'qteApportOrga':
                translation = "organicInputDate";
                break;
            case 'dateApportAO':
                translation = "organicInputQuantity";
                break;
            case 'reliquatMeasuresNo3':
            case 'reliquatMeasuredNh4':
            case 'SNO3NH4':
                translation = "relic";
                break;
            case 'reliquatDate':
                translation = "relicDate";
                break;
            case 'typeFertilisationMajoritaire':
                translation = "fertilizerType";
                break;
            case 'dateDeSemisPrec':
                translation = "previousSowingDate";
                break;
            default:
                translation = undefined;
                break;
        }

        return translation;
    },

    /**
     * Fonction de controle de la présence des données minimales requises pour le passage à l'étape suivante de la fumure
     * @param {*} fertiCropType type (énuméré 'AvailableCropEnum' côté C#) de culture de fumure.
     * @param {*} specificInfos dictionnaire que l'on veut sauvegarder en base 
     * @param {*} fertilizerEngine Azofert ou fertiweb (les contôles sont différnts d'un moteur de fumure à un autre)
     * @returns La nouvelle version du dictionnaire d'erreur 
     */
    checkMinimumValuesSaved(fertiCropType, specificInfos, fertilizerEngineEnum) {
        // Crop n'est pas testé car il n'y a pas de champ pour la culture dans le dialog

        let isOk = true;
        let errorManagerDico = { isOk: isOk, };

        const warningLabel = StringTranslate.buildFertiNeedThisData;

        // errorManagerDico[titre] = 'phrase'
        const sowingDate = lodashGet(specificInfos, "sowingDate", null); //date de semis - même contrôle pour les 2 moteurs de fumure
        const harvestDate = lodashGet(specificInfos, "harvestDate", null); //date de récolte - même contrôle pour les 2 moteurs de fumure
        const relicDate = lodashGet(specificInfos, "relicDate", null); //date de reliquat
        const previousHarvestDate = lodashGet(specificInfos, "previousHarvestDate", null); //date de récolte précédente - même contrôle pour les 2 moteurs de fumure
        const prevTailingsMngt = lodashGet(specificInfos, "previousTailingsManagement", null);
        const relicType = lodashGet(specificInfos, "relicType", this.relicTypeEnum.unknown); //relicType - même contrôle pour les 2 moteurs de fumure. Pour Fertiweb : la date n'y est pas quand on choisit valeur régionale

        //#region objectif de production - contrôle uniquement SI culture Blé pour les 2 moteurs de fumure Azofert et Fertiweb
        if ((fertiCropType === 1) && (lodashGet(specificInfos, "productionTarget", null) === null)) { //objectif de production
            errorManagerDico["productionTarget"] = warningLabel;
            isOk = false;
        } 
        //#endregion objectif de production

        //#region dateDeSemis - même contrôle pour les 2 moteurs de fumure
        if (sowingDate === null) {
            errorManagerDico["sowingDate"] = warningLabel;
            isOk = false;
        }
        //#endregion dateDeSemis

        //#region date de récolte - même contrôle pour les 2 moteurs de fumure
        if (harvestDate === null) {//dateDeRecolte
            errorManagerDico["harvestDate"] = warningLabel;
            isOk = false;
        }
        //#endregion date de récolte

        //#region date de reliquat - même contrôle pour les 2 moteurs de fumure. Pour Fertiweb : la date n'y est pas quand on choisit valeur régionale
        if ((relicDate === null) && (fertilizerEngineEnum === FertilizerEngineEnum.AZOFERT)) { //reliquatDate
            errorManagerDico["relicDate"] = warningLabel;
            isOk = false;
        }
        else if ((relicDate === null) && (relicType === this.relicTypeEnum.analyse) && (fertilizerEngineEnum === FertilizerEngineEnum.FERTIWEB)) { //reliquatDate
            errorManagerDico["relicDate"] = warningLabel;
            isOk = false;
        }
        //#endregion date de reliquat

        //#region date de récolte précédente - même contrôle pour les 2 moteurs de fumure
        if (previousHarvestDate === null) {//dateDeRecoltePrec
            errorManagerDico["previousHarvestDate"] = warningLabel;
            isOk = false;
        }
        //#endregion date de récolte précédente

        //#region type de sol - même contrôle pour les 2 moteurs de fumure
        if (lodashGet(specificInfos, "soilKindType", null) === null) {//typeDeSol
            errorManagerDico["soilKindType"] = warningLabel;
            isOk = false;
        }
        //#endregion type de sol
        
        //#region objectif de rendement - même contrôle pour les 2 moteurs de fumure
        if (lodashGet(specificInfos, "performanceGoal", "") === "") {//objRendement
            errorManagerDico["performanceGoal"] = warningLabel;
            isOk = false;
        } 
        //#endregion objectif de rendement

        //#region culture précédente - même contrôle pour les 2 moteurs de fumure
        if (lodashGet(specificInfos, "previousCrop", null) === null) {//culture précédente
            errorManagerDico["previousCrop"] = warningLabel;
            isOk = false;
        } 
        //#endregion culture précédente

        //#region date de semis N-1 - contrôle uniquement pour le moteur de fumure Fertiweb
        if (((lodashGet(specificInfos, "previousSowingDate", null) === null) || !(lodashGet(specificInfos, "previousSowingDate", null) instanceof Date)) && (fertilizerEngineEnum === FertilizerEngineEnum.FERTIWEB)) {
            errorManagerDico["previousSowingDate"] = warningLabel;
            isOk = false;
        }
        //#endregion culture précédente

        //#region rendement N-1 - même contrôle pour les 2 moteurs de fumure
        if (lodashGet(specificInfos, "previousPerformance", "") === "") {//rendementPrec
            errorManagerDico["previousPerformance"] = warningLabel;
            isOk = false;
        } 
        //#endregion rendement précédent

        //#endregion gestion des résidus du précédent - pour Fertiweb : la 'saisie' de la gestion est rendu obligatoire !
        if ((fertilizerEngineEnum === FertilizerEngineEnum.FERTIWEB) && ((prevTailingsMngt === null) || (prevTailingsMngt === ""))) {//résidusPrec
            errorManagerDico["previousTailingsManagement"] = warningLabel;
            isOk = false;
        } 
        //#region date des résidus du précédent - 

        //#region type de fertilisation majoritaire - contrôle uniquement pour Azofert (n'existe pas chez Fertiweb)
        if ((lodashGet(specificInfos, "fertilizerType", null) === null) && (fertilizerEngineEnum === FertilizerEngineEnum.AZOFERT))  {//typeFertilisationMajoritaire
            errorManagerDico["fertilizerType"] = warningLabel;
            isOk = false;
        } 
        //#endregion type de fertilisation majoritaire

        //#region teneur en azote - même contrôle pour les 2 moteurs de fumure
        if (lodashGet(specificInfos, "content", "") === "") {//teneurEnAzotezer
            errorManagerDico["content"] = warningLabel;
            isOk = false;
        }
        //#endregion teneur en azote

        //#region relicType - même contrôle pour les 2 moteurs de fumure
        const sNO3NH4 = lodashGet(specificInfos, "sNO3NH4", undefined);
        const relicSamples = lodashGet(specificInfos, "relicSamples", undefined); 
        
        if (fertilizerEngineEnum === FertilizerEngineEnum.AZOFERT) {
            if ((relicType === this.relicTypeEnum.analyse) && (relicSamples !== undefined)) {
                if (relicSamples[0] !== undefined) {
                    let nO3_0 = lodashGet(relicSamples[0], "nO3", undefined);
                    if (nO3_0 === undefined) {
                        errorManagerDico["relic0nO3"] = warningLabel;
                        isOk = false;
                    }
    
                    let nH4_0 = lodashGet(relicSamples[0], "nH4", undefined);
                    if (nH4_0 === undefined) {
                        errorManagerDico["relic0nH4"] = warningLabel;
                        isOk = false;
                    }
                }
                
                if (relicSamples[1] !== undefined) {
                    let nO3_1 = lodashGet(relicSamples[1], "nO3", undefined);
                    if (nO3_1 === undefined) {
                        errorManagerDico["relic1nO3"] = warningLabel;
                        isOk = false;
                    }
    
                    let nH4_1 = lodashGet(relicSamples[1], "nH4", undefined);
                    if (nH4_1 === undefined) {
                        errorManagerDico["relic1nH4"] = warningLabel;
                        isOk = false;
                    }
                }
                
                if (relicSamples[2] !== undefined) {
                    let nO3_2 = lodashGet(relicSamples[2], "nO3", undefined);
                    let nH4_2 = lodashGet(relicSamples[2], "nH4", undefined);
                    if ((nO3_2 === undefined) && (nH4_2 !== undefined)) {
                        errorManagerDico["relic2nO3"] = warningLabel;
                        isOk = false;
                    }
    
                    
                    if ((nH4_2 === undefined) && (nO3_2 !== undefined)) {
                        errorManagerDico["relic2nH4"] = warningLabel;
                        isOk = false;
                    }
                }
            }

            else if (((relicType === this.relicTypeEnum.valeurRegionale) && ((sNO3NH4 === undefined) || !(sNO3NH4>0))) ||
            ((relicType === this.relicTypeEnum.analyse) && (relicType !== this.relicTypeEnum.valeurRegionale))
            ) {
                errorManagerDico["relic"] = warningLabel;
                isOk = false;
            }
        }
        else if (fertilizerEngineEnum === FertilizerEngineEnum.FERTIWEB) {
            if ((relicType === this.relicTypeEnum.analyse) && (relicSamples !== undefined)) {
                if (relicSamples[0] !== undefined) {
                    let nO3_0 = lodashGet(relicSamples[0], "nO3", undefined);
                    if (nO3_0 === undefined) {
                        errorManagerDico["relic0nO3"] = warningLabel;
                        isOk = false;
                    }
    
                    let nH4_0 = lodashGet(relicSamples[0], "nH4", undefined);
                    if (nH4_0 === undefined) {
                        errorManagerDico["relic0nH4"] = warningLabel;
                        isOk = false;
                    }
                }
                
                if (relicSamples[1] !== undefined) {
                    let nO3_1 = lodashGet(relicSamples[1], "nO3", undefined);
                    if (nO3_1 === undefined) {
                        errorManagerDico["relic1nO3"] = warningLabel;
                        isOk = false;
                    }
    
                    let nH4_1 = lodashGet(relicSamples[1], "nH4", undefined);
                    if (nH4_1 === undefined) {
                        errorManagerDico["relic1nH4"] = warningLabel;
                        isOk = false;
                    }
                }
                
                if (relicSamples[2] !== undefined) {
                    let nO3_2 = lodashGet(relicSamples[2], "nO3", undefined);
                    let nH4_2 = lodashGet(relicSamples[2], "nH4", undefined);
                    if ((nO3_2 === undefined) && (nH4_2 !== undefined)) {
                        errorManagerDico["relic2nO3"] = warningLabel;
                        isOk = false;
                    }
    
                    
                    if ((nH4_2 === undefined) && (nO3_2 !== undefined)) {
                        errorManagerDico["relic2nH4"] = warningLabel;
                        isOk = false;
                    }
                }
            }

            else if (((relicType === this.relicTypeEnum.valeurRegionale) && ((sNO3NH4 === undefined) || !(sNO3NH4>0)))) {
                errorManagerDico["relic"] = warningLabel;
                isOk = false;
            }
        }
        //#endregion relicType

        const hasOrganicInput = lodashGet(specificInfos, "hasOrganicInput", false);
        const organicInputDate = lodashGet(specificInfos, "organicInputDate", null);
        const organicInputQuantity = lodashGet(specificInfos, "organicInputQuantity", -1);
        const organicInput = lodashGet(specificInfos, "organicInput", null);
        
        const OAWarning = StringTranslate.buildFertiNeedThisWhenAddOrganicInput;

        //#region date d'apport organique
        if ((hasOrganicInput === true) && (organicInputDate === null)) {
            errorManagerDico["organicInputDate"] = OAWarning;
            isOk = false;
        } 
        //#endregion date d'apport organique
        
        //#region organicInputQuantity
        if ((hasOrganicInput === true) && ((organicInputQuantity <= -1) || (organicInputQuantity === null) || (organicInputQuantity === undefined) || (organicInputQuantity === ""))) {
            errorManagerDico["organicInputQuantity"] = OAWarning;
            isOk = false;
        } 
        //#endregion organicInputQuantity
        
        //#region hasInputOrganic
        if ((hasOrganicInput === true) && (organicInput === null)) {
            errorManagerDico["organicInput"] = OAWarning;
            isOk = false;
        } 
        //#endregion hasInputOrganic
         
        errorManagerDico["isOk"] = isOk;
        return errorManagerDico;
    },

    /**
     * Fonction de controle de la cohérence des informations que l'on veux sauvegarder en base
     * @param {*} specificInfos dictionnaire que l'on veut sauvegarder en base 
     * @param {*} errorManagerDico dictionnaire d'erreur
     * @returns Le dictionnaire d'erreur à jour
     */
    checkConsistencyOfValues(specificInfos, errorManagerDico, fertilizerEngineEnum) { //RQ : La traduction n'est pas encore géré ici (prio faible)

        let futurErrorManager = errorManagerDico;

        // Etape 0 : réucpération des données et y a t'il déjà des messages d'erreurs
        // récupération des dates
        const sowingDate = lodashGet(specificInfos, "sowingDate", null);
        const harvestDate = lodashGet(specificInfos, "harvestDate", null);
        const previousHarvestDate = lodashGet(specificInfos, "previousHarvestDate", null);
        const previousPloughingDate = lodashGet(specificInfos, "previousPloughingDate", null);
        const previousSowingDate = lodashGet(specificInfos, "previousSowingDate", null);
        const relicDate = lodashGet(specificInfos, "relicDate", null);
        const hasOrganicInput = lodashGet(specificInfos, "hasOrganicInput", false);
        const organicInputDate = lodashGet(specificInfos, "organicInputDate", null);
        // ce champ possède t'il déjà une alerte ?
        let sowingDateWitoutWarning = lodashGet(errorManagerDico, "sowingDate", undefined) === undefined;
        let harvestDateWitoutWarning = lodashGet(errorManagerDico, "harvestDate", undefined) === undefined;
        let previousHarvestDateWitoutWarning = lodashGet(errorManagerDico, "previousHarvestDate", undefined) === undefined;
        let previousSowingDateWitoutWarning = lodashGet(errorManagerDico, "previousSowingDate", undefined) === undefined;
        let previousPloughingDateWitoutWarning = 
            (lodashGet(errorManagerDico, "previousPloughingDate", undefined) === undefined) && 
            (previousPloughingDate !== null);
        let relicDateWitoutWarning = lodashGet(errorManagerDico, "relicDate", undefined) === undefined;
        let orgaInputDateWitoutWarning = lodashGet(errorManagerDico, "organicInputDate", undefined) === undefined;
        const relicType = lodashGet(specificInfos, "relicType", this.relicTypeEnum.unknown)

        // Etape 1 : récupérer l'année de campagne et la comparrer au autres dates
        try{
            if (sowingDate !== null) {
                // initialisation du message d'erreur
                let errorMessageStep1 = "l'année semble érronée par rapport à celle de la date de semis";
                let errorRelicDateDayMonthStep1 = "la date de reliquat doit être comprise entre le 01/01 et le 29/02";

                // récupération de l'année de campagne (on se base sur l'année de la date de semis)        
                let anneeDeCampagneTheorique = sowingDate.getFullYear() + 1;

                // on compare la date de récolte (qui doit avoir la même année que celle de la campagne)
                if (harvestDateWitoutWarning && (harvestDate.getFullYear() !== anneeDeCampagneTheorique)) {
                    futurErrorManager["harvestDate"] = errorMessageStep1;
                    errorManagerDico["isOk"] = false;
                    harvestDateWitoutWarning = false; // màj de la présence de warning
                }

               //On vérifie que nous sommes dans le cas où le moteur est soit Azofert, soit Fertiweb mais en analyse car il n'y a que dans ce cas qu'une date de reliquat est renseignée
               if (((relicType === this.relicTypeEnum.analyse) && (fertilizerEngineEnum === FertilizerEngineEnum.FERTIWEB)) || (fertilizerEngineEnum === FertilizerEngineEnum.AZOFERT)) {

                //Dans le cas où la date est invalide (par ex : 29/02/2023 alors qu'il n'y a que 28 jours)
                if (relicDateWitoutWarning && isNaN(relicDate) && isNaN(relicDate.getTime())) {
                    futurErrorManager["relicDate"] = errorRelicDateDayMonthStep1;
                    errorManagerDico["isOk"] = false;
                    relicDateWitoutWarning = false; // màj de la présence de warning
                }

                // on compare la date du reliquat (qui doit avoir la même année que celle de la campagne)
                if (relicDateWitoutWarning && relicDate.getFullYear() !== anneeDeCampagneTheorique) {
                    futurErrorManager["relicDate"] = errorMessageStep1;
                    errorManagerDico["isOk"] = false;
                    relicDateWitoutWarning = false; // màj de la présence de warning
                }

                // on compare la date du reliquat (qui doit être comprise entre le 1er janvier et le 28 ou 29 février)
                if (relicDateWitoutWarning && !((relicDate.getMonth() === 0 ) || ((relicDate.getMonth() === 1)))) {
                    futurErrorManager["relicDate"] = errorRelicDateDayMonthStep1;
                    errorManagerDico["isOk"] = false;
                    relicDateWitoutWarning = false; // màj de la présence de warning
                }

            }
            }
        } catch (error) {}


        // Etape 2 : comparaison 2 par 2 des dates pour vérifier qu'elle sont dans l'ordre, si non alors les 2 récupèrent le message d'erreur
        // harvestDate > sowingDate > previousPloughingDate > previousHarvestDate
        try {
            // harvestDate > sowingDate
            if (harvestDateWitoutWarning && sowingDateWitoutWarning && !(harvestDate > sowingDate)) {
                futurErrorManager["harvestDate"] = "La date de récolte doit être après la date de semis";
                futurErrorManager["sowingDate"] = "La date de semis doit être avant la date de récolte";
                errorManagerDico["isOk"] = false;
                sowingDateWitoutWarning = false; // màj de la présence de warning
                harvestDateWitoutWarning = false; // màj de la présence de warning
            }
            
            // sowingDate > previousPloughingDate
            if (sowingDateWitoutWarning && previousPloughingDateWitoutWarning && !(sowingDate > previousPloughingDate)) {
                futurErrorManager["sowingDate"] = "La date de semis doit être après la date d'enfouissement précédent";
                futurErrorManager["previousPloughingDate"] = "La date d'enfouissement précédent doit être avant la date de semis";
                errorManagerDico["isOk"] = false;
                sowingDateWitoutWarning = false; // màj de la présence de warning
                previousPloughingDateWitoutWarning = false; // màj de la présence de warning
            }
            
            // previousPloughingDate > previousHarvestDate
            if (previousPloughingDateWitoutWarning && previousHarvestDateWitoutWarning && !(previousPloughingDate > previousHarvestDate)) {
                futurErrorManager["previousPloughingDate"] = "La date d'enfouissement précédent doit être après la date de récolte précédente";
                futurErrorManager["previousHarvestDate"] = "La date de récolte précédente doit être avant la date d'enfouissement précédent";
                errorManagerDico["isOk"] = false;
                previousPloughingDateWitoutWarning = false; // màj de la présence de warning
                previousHarvestDateWitoutWarning = false; // màj de la présence de warning
            }
                
            // sowingDate > previousHarvestDate
            if (sowingDateWitoutWarning && previousHarvestDateWitoutWarning && !(sowingDate > previousHarvestDate)) {
                futurErrorManager["sowingDate"] = "La date de semis doit être après la date de récolte précédente";
                futurErrorManager["previousHarvestDate"] = "La date de récolte précédente doit être avant la date de semis";
                errorManagerDico["isOk"] = false;
                sowingDateWitoutWarning = false; // màj de la présence de warning
                previousHarvestDateWitoutWarning = false; // màj de la présence de warning
            }
                
            // organicInputDate > previousHarvestDate
            if (orgaInputDateWitoutWarning && previousHarvestDateWitoutWarning && 
                (hasOrganicInput === true) && (organicInputDate !== null) && (!(organicInputDate > previousHarvestDate))) {
                futurErrorManager["organicInputDate"] = "La date du premier apport organique doit être après la date de récolte précédente";
                futurErrorManager["previousHarvestDate"] = "La date de récolte précédente doit être avant la date du premier apport organique";
                errorManagerDico["isOk"] = false;
                orgaInputDateWitoutWarning = false; // màj de la présence de warning
                previousHarvestDateWitoutWarning = false; // màj de la présence de warning
            }

            // previousSowingDate > sowingDate
            if (previousSowingDateWitoutWarning && (previousSowingDate !== null) && (previousSowingDate !== undefined) && (sowingDate < previousSowingDate)) {
                futurErrorManager["previousSowingDate"] = "La date de semis précédente doit être inférieure à la date de semis actuelle";
                errorManagerDico["isOk"] = false;
                previousSowingDateWitoutWarning = false; // màj de la présence de warning
            }

            //vérification des dates < Date d'aujourd'hui :
            const nowDate = new Date();
            if (sowingDateWitoutWarning && (sowingDate > nowDate)) {
                futurErrorManager["sowingDate"] = "La date de semis ne doit pas être au delà du jour actuel";
                errorManagerDico["isOk"] = false;
            }
            if (previousHarvestDateWitoutWarning && (previousHarvestDate > nowDate)) { 
                futurErrorManager["previousHarvestDate"] = "La date de récolte précédente ne doit pas être au delà du jour actuel";
                errorManagerDico["isOk"] = false;
                previousHarvestDateWitoutWarning = false; // màj de la présence de warning
            }
            if (previousSowingDateWitoutWarning && (previousSowingDate >= nowDate)) { 
                futurErrorManager["previousSowingDate"] = "La date de semis précédente ne doit pas être au delà du jour actuel";
                errorManagerDico["isOk"] = false;
                previousSowingDateWitoutWarning = false; // màj de la présence de warning
            }
            if (previousPloughingDateWitoutWarning && (previousPloughingDate > nowDate)) { 
                futurErrorManager["previousPloughingDate"] = "La date d'enfouissement précédent ne doit pas être au delà du jour actuel";
                errorManagerDico["isOk"] = false;
            }
            if (relicDateWitoutWarning && (relicDate > nowDate)) { 
                futurErrorManager["relicDate"] = "La date du reliquat ne doit pas être au delà du jour actuel";
                errorManagerDico["isOk"] = false;
            }
            if (orgaInputDateWitoutWarning && (organicInputDate > nowDate)) { 
                futurErrorManager["organicInputDate"] = "La date du premier apport organique ne doit pas être au delà du jour actuel";
                errorManagerDico["isOk"] = false;
            }

            if (previousHarvestDate !== null) {
                if (previousHarvestDateWitoutWarning  && previousSowingDate && (previousHarvestDate <= previousSowingDate)) { 
                    futurErrorManager["previousHarvestDate"] = "La date de récolte précédente doit être après la date de semis précédente";
                    errorManagerDico["isOk"] = false;
                    previousHarvestDateWitoutWarning = false; // màj de la présence de warning
                }

                if (previousHarvestDateWitoutWarning && harvestDate && (previousHarvestDate >= harvestDate)) { 
                    futurErrorManager["previousHarvestDate"] = "La date de récolte précédente doit être avant la date courante de récolte";
                    errorManagerDico["isOk"] = false;
                    previousHarvestDateWitoutWarning = false; // màj de la présence de warning
                }
            }

            if (previousSowingDate !== null) {
                if (previousSowingDateWitoutWarning && previousHarvestDate && (previousSowingDate >= previousHarvestDate)) { 
                    futurErrorManager["previousSowingDate"] = "La date de semis précédente doit être avant la date de récolte précédente"; 
                    errorManagerDico["isOk"] = false;
                }
            }
            
        } catch (error) {}

        return futurErrorManager;
    },

    /**
     *  fonction permettant de calculer le nombre total de fumures se trouvant dans le dico
     */
    count(specificInfosDico) {
        if (!specificInfosDico) return 0;
        return Object.entries(specificInfosDico).length;
    },

    /* fonction permettant d'adapter l'item de données de fumure reçu - le concept de normalisation des données est ici utilisé */
    convertItem(fertilizerItem) {
        if (!fertilizerItem) {
            return {};
        }

        //transforme la date (caîne en entité 'Date'):
        fertilizerItem.harvestDate = (fertilizerItem.harvestDate && fertilizerItem.harvestDate!== null && (!isNaN(Date.parse(fertilizerItem.harvestDate)))) ?
            new Date(fertilizerItem.harvestDate) : fertilizerHelper.getDefaultHarvestDateByCulture(fertilizerItem.crop);
        fertilizerItem.sowingDate = (fertilizerItem.sowingDate && fertilizerItem.sowingDate !== null && (!isNaN(Date.parse(fertilizerItem.sowingDate)))) ?
            new Date(fertilizerItem.sowingDate) : fertilizerHelper.getDefaultSowingDateByCulture(fertilizerItem.crop);
        fertilizerItem.previousHarvestDate = (fertilizerItem.previousHarvestDate && (!isNaN(Date.parse(fertilizerItem.previousHarvestDate)))) ?
            new Date(fertilizerItem.previousHarvestDate) : undefined;
        fertilizerItem.previousPloughingDate = (fertilizerItem.previousPloughingDate && (!isNaN(Date.parse(fertilizerItem.previousPloughingDate)))) ?
            new Date(fertilizerItem.previousPloughingDate) : undefined
        fertilizerItem.organicInputDate = (fertilizerItem.organicInputDate && (!isNaN(Date.parse(fertilizerItem.organicInputDate)))) ?
            new Date(fertilizerItem.organicInputDate) : undefined;
        fertilizerItem.relicDate = (fertilizerItem.relicDate && (!isNaN(Date.parse(fertilizerItem.relicDate)))) ?
            new Date(fertilizerItem.relicDate) : undefined;
        fertilizerItem.beginningWinterDate = (fertilizerItem.beginningWinterDate && (!isNaN(Date.parse(fertilizerItem.beginningWinterDate)))) ?
            new Date(fertilizerItem.beginningWinterDate) : undefined;
        fertilizerItem.endingWinterDate = (fertilizerItem.endingWinterDate && (!isNaN(Date.parse(fertilizerItem.endingWinterDate)))) ?
            new Date(fertilizerItem.endingWinterDate) : undefined;
     
        //s'il contient aussi les données du précédent conseil:
        fertilizerItem.creationDate = (fertilizerItem.creationDate && (!isNaN(Date.parse(fertilizerItem.creationDate)))) ?
            new Date(fertilizerItem.creationDate) : undefined;

        return fertilizerItem;
    },

    /* fonction permettant de passer d'une liste à un dico - le concept de normalisation des données est ici utilisé */
    convertToDico(fertilizersList) {
        if ((!fertilizersList) || (!Array.isArray(fertilizersList))) {
            return {};
        }

        let dicoFertilizers = {};
        fertilizersList.forEach(fertilizerItem => {
            dicoFertilizers[fertilizerItem.id] = fertilizerHelper.convertItem(fertilizerItem);
        });

        return dicoFertilizers;
    },

    /* Bloquer le type de culture intermédiaire si pas de fréquence de culture intermédiaire */
    enableIntermediateCropType(commonInfos) {
        if ((commonInfos) &&
            (commonInfos.intermediateCultureFrequency) &&
            (commonInfos.intermediateCultureFrequency.id) &&
            (commonInfos.intermediateCultureFrequency.id !== "")) {
                return true;
        }
        else {
            return false;
        }
    },

    //Méthode permettant d'obtenir l'année de la campagne en cours en fonction de la date de fermeture et la date d'ouverture de Azofert
	getCurrentCropYear(closingDateAzofert, openingDateAzofert) {

		const dateNow = new Date();
				
        if ((closingDateAzofert !== undefined) && (closingDateAzofert !== null) && 
            (openingDateAzofert !== undefined) && (openingDateAzofert !== null)) {
            const yearOfClosing = closingDateAzofert.getFullYear();
            if (dateNow < closingDateAzofert) {
                return yearOfClosing;
            }
            else if (dateNow >= Date.parse(openingDateAzofert)) {
                return (yearOfClosing + 1);
            }
            else {
                return yearOfClosing; //Par défaut pendant la période de fermeture
            }
        } 
        else {
            const closingDate = ConstantsFertilizer.DefaultClosingDateAzofert;
            const yearOfDefaultClosing = closingDate.getFullYear()
            if (dateNow < closingDate) {
                return yearOfDefaultClosing;
            }
            else if (dateNow >= ConstantsFertilizer.DefaultOpeningDateAzofert) {
                return (yearOfDefaultClosing + 1);
            }
            else {
                return yearOfDefaultClosing; //Par défaut pendant la période de fermeture
            }
        }
	},

    //Méthode pour vérifier si un conseil azoté possède un dernier apport.
    hasLastNitrogenInput(parcelIds, parcels, fertilizerDico){
        if ((parcelIds != null) && (fertilizerDico != null) && (parcels != null)){
            let parcelNamesWithLastNInput = [];
            // Récupère les conseil azoté avec dernier apport des parcelles spécifiés
            let fertiWithLastInput = lodashFilter(fertilizerDico, f => {
                return f && f.hasLastNitrogenInput && lodashIncludes(parcelIds, f.idParcel);
            });

            // Pour chaque conseil azoté avec dernier apport, on récupère le nom de la parcelle
            for (const ferti of fertiWithLastInput) {
                let parcel = lodashFind(parcels, { 'id': ferti.idParcel });
                if (parcel){
                    const getParcelName = parcel.parcelName || parcel.nom;
                    parcelNamesWithLastNInput.push(getParcelName);
                }
            }
    
            if (parcelNamesWithLastNInput.length > 0) {
                return { matchFound: true, parcelName: parcelNamesWithLastNInput };
            } else {
                return { matchFound: false, parcelName: [] };
            }
        } else { 
            return { matchFound: false, parcelName: [] };
        }
    }
}

export default fertilizerHelper;