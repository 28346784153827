
/*
    Constantes déinissant les erreurs possibles
    RQ: Pourquoi commencer à 1001 ? et pourquoi pas !!
*/
const codeErrorAuthentFailed = 1001; 
const codeErrorNoDatasAuthent = 1002; 
const codeErrorBadAuthentResultDatas = 1003;
//D'autres à venir... MAIS surtout : des codes sont reçus de la Web API et directement transmis à l'appli Native !

/* Enuméré des cas d'erreur spécifiques identifiés: */
export const AuthentErrorCode = {
    DefaultFailed: codeErrorAuthentFailed,
    NoLoginPwd: codeErrorNoDatasAuthent,
    NoReturnToken: codeErrorBadAuthentResultDatas,
};

export default AuthentErrorCode;
