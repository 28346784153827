import React from "react";
import { connect } from 'react-redux';
import {
    Typography, Button,
    TextField, Grid, InputLabel, Stack, Alert
} from "@mui/material";
import StringTranslate from "../../assets/i18n/stringLanguage";
import { ActionSaveUpdatedClientBilling } from "../../redux/actions/clientUser";

import countryDatas from '../../datas/country.json';

import '../../assets/css/profil.css'
import getTheme from "../../themes/index.js";
import lodashGet from 'lodash/get';
let theme = getTheme();


export const validateCommonValue = function (value) {
    if (!value) return false;

    return (value === '') ? false : true;
}

export const validatePhoneValue = function (value) {
    if (!value) return false;

    return ((value === '') || (value.length < 9)) ? false : true;
}

export const validatePostalCodeFromRegex = function (value, countryRegex) {
    if (!value) return false;
    if (value === '') return false;
    if (!countryRegex) return false;

    let result = (countryRegex && countryRegex.regex) ? value.match(countryRegex.regex) : [];

    return (result && (result.length > 0) && (result[0] === value)) ? true : false;
}

export const validatePostalCode = function (value, codeCountry) {
    if (!value) return false;
    if (value === '') return false;
    if ((!codeCountry) || (codeCountry === '')) return false;

    let countryRegex = undefined;
    try {
        countryRegex = (codeCountry && countryDatas[codeCountry]) ? countryDatas[codeCountry].rules : countryDatas['FR'].rules;
    }
    catch (errCountry) {
        countryRegex = undefined;
    }
    if (!countryRegex) return false;

    let result = (countryRegex && countryRegex.regex) ? value.match(countryRegex.regex) : [];

    return (result && (result.length > 0) && (result[0] === value)) ? true : false;
}

export const validateDatasForInvoice = function ({ firstName, lastName, phone, label, postalCode, cityName, country, codeCountry }) {
    let stateToUpdate = {};
    let countryRegex = undefined;
    try {
        countryRegex = (codeCountry && countryDatas[codeCountry]) ? countryDatas[codeCountry].rules : countryDatas['FR'].rules;
    }
    catch (errCountry) {
        countryRegex = undefined;
    }

    if (validateCommonValue(firstName) !== true)
        stateToUpdate.errorMessage = `${StringTranslate.renseignerprenom}`;
    else if (validateCommonValue(lastName) !== true)
        stateToUpdate.errorMessage = `${StringTranslate.renseignernom}`;
    else if (validatePhoneValue(phone) !== true)
        stateToUpdate.errorMessage = `${StringTranslate.renseignertelephone}`;
    else if (validateCommonValue(label) !== true)
        stateToUpdate.errorMessage = `${StringTranslate.renseigneradresse}`;
    else if (validatePostalCodeFromRegex(postalCode, countryRegex) !== true)
        stateToUpdate.errorMessage = `${StringTranslate.renseignercode}`;
    else if (validateCommonValue(cityName) !== true)
        stateToUpdate.errorMessage = `${StringTranslate.renseignerville}`;
    else if (validateCommonValue(country) !== true)
        stateToUpdate.errorMessage = `${StringTranslate.renseignerpays}`;
    //else //ok !

    return stateToUpdate;
}


class UserInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            masterEmail: props.masterEmail,
            nom: (props.naming && props.naming.lastName) ? props.naming.lastName : '',
            prenom: (props.naming && props.naming.firstName) ? props.naming.firstName : '',
            societe: (props.naming && props.naming.compagnyName) ? props.naming.compagnyName : '',
            phone: (props.phone) ? props.phone : '',

            onEdit_Naming: false, // permet de mettre tout le formulaire en édition 

            requiredDatasNeed: false,
        }

        this.hasSubscribePlan = (props.clientDatas && props.clientDatas.planSubscriptions && Array.isArray(props.clientDatas.planSubscriptions) &&
            (props.clientDatas.planSubscriptions.length > 0)) ? true : false;

        this.onClickCancelNaming = this.onClickCancelNaming.bind(this);
        this.onClickSaveNaming = this.onClickSaveNaming.bind(this);
    }

    onClickCancelNaming() {
        const { naming, phone } = this.props;

        this.setState({
            nom: (naming && naming.lastName) ? naming.lastName : '',
            prenom: (naming && naming.firstName) ? naming.firstName : '',
            societe: (naming && naming.compagnyName) ? naming.compagnyName : '',
            phone: (phone) ? phone : '',
            onEdit_Naming: false,
            requiredDatasNeed: false,
        });
    }

    onClickSaveNaming() {
        // ↓↓ création nouvel objet ↓↓
        const { clientDatas } = this.props;
        const { societe, prenom, nom, phone } = this.state;

        //Rq société n'est pas obligatoire !
        if ((this.hasSubscribePlan !== true) || (
            (validateCommonValue(prenom) === true) && (validateCommonValue(nom) === true) && (validatePhoneValue(phone) === true))) { //on peut enregistrer !
            let newClientDatas = {
                ...clientDatas,
                naming: {
                    compagnyName: societe,
                    firstName: prenom,
                    lastName: nom,
                },
                phone: phone,
            };

            // ↓↓ enregistrement BDD + redux ↓↓ 
            this.props.saveUpdatedClientBilling(newClientDatas);

            this.setState({
                onEdit_Naming: false,
                requiredDatasNeed: false,
            });
        } else {
            //affiche le message demandant la saisie du(des) champs requis:
            this.setState({ requiredDatasNeed: true, });
            //programme la disparition du message:
            setTimeout(() => {
                this.setState({ requiredDatasNeed: false, });
            }, 5000);
        }
    }

    handleChange = param => (event) => {
        if (param === 'societe') {
            this.setState({
                societe: event.target.value,
                onEdit_Naming: true,
                requiredDatasNeed: false,
            });
        } else if (param === 'prenom') {
            this.setState({
                prenom: event.target.value,
                onEdit_Naming: true,
                requiredDatasNeed: false,
            });
        } else if (param === 'nom') {
            this.setState({
                nom: event.target.value,
                onEdit_Naming: true,
                requiredDatasNeed: false,
            });
        } else if (param === 'phone') {
            this.setState({
                phone: event.target.value,
                onEdit_Naming: true,
                requiredDatasNeed: false,
            });
        }
    }

    render() {
        const { masterEmail, societe, nom, prenom, phone, onEdit_Naming, requiredDatasNeed } = this.state;

        const fNameCtrlResult = validateCommonValue(prenom);
        const lNameCtrlResult = validateCommonValue(nom);
        const phoneCtrlResult = validatePhoneValue(phone);

        return (
            <Grid container spacing={2} alignItems="center">
                {/* ↓↓ Identifiant Email ↓↓ */}
                <Grid item xs={12}>
                    <Stack spacing={0.25} alignItems="flex-start" sx={{ mb: 3 }}>
                        <Typography variant="caption">{`${StringTranslate.email}`}</Typography>
                        <Typography variant="subtitle1">{(masterEmail) ? masterEmail : '---'}</Typography>
                    </Stack>
                </Grid>

                {/* ↓↓ Champ nom de la société ↓↓ */}
                <Grid item xs={12}>
                    <InputLabel>{StringTranslate.societe}</InputLabel>
                    <TextField
                        fullWidth variant="outlined" margin="normal"
                        placeholder={`${StringTranslate.societe}`}
                        value={societe}
                        onChange={this.handleChange('societe')}
                        inputProps={{ maxLength: 100 }}
                    />
                </Grid>

                {/* ↓↓ Champ Nom-Prénom-Téléphone ↓↓ */}
                <Grid item xs={12}>
                    <InputLabel>{StringTranslate.prenom}</InputLabel>
                    <TextField
                        fullWidth variant="outlined" margin="normal"
                        placeholder={`${StringTranslate.prenom}`}
                        value={prenom}
                        onChange={this.handleChange('prenom')}
                        inputProps={{ maxLength: 40 }}
                        error={(fNameCtrlResult !== true) && onEdit_Naming && this.hasSubscribePlan}
                        helperText={((fNameCtrlResult !== true) && this.hasSubscribePlan) ? `${StringTranslate.mandatoryField}` : ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel>{StringTranslate.nom}</InputLabel>
                    <TextField
                        fullWidth variant="outlined" margin="normal"
                        placeholder={`${StringTranslate.nom}`}
                        value={nom}
                        onChange={this.handleChange('nom')}
                        inputProps={{ maxLength: 40 }}
                        error={(lNameCtrlResult !== true) && onEdit_Naming && this.hasSubscribePlan}
                        helperText={((lNameCtrlResult !== true) && this.hasSubscribePlan) ? `${StringTranslate.mandatoryField}` : ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel>{StringTranslate.telephone}</InputLabel>
                    <TextField
                        fullWidth margin="normal" variant="outlined"
                        placeholder={`${StringTranslate.telephone}`}
                        value={phone}
                        onChange={this.handleChange('phone')}
                        inputProps={{ maxLength: 12 }}
                        error={(phoneCtrlResult !== true) && onEdit_Naming && this.hasSubscribePlan}
                        helperText={((phoneCtrlResult !== true) && this.hasSubscribePlan) ? `${StringTranslate.mandatoryField}` : ""}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Stack direction="column">
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <Button
                                type="submit"
                                variant="text"
                                color="error"
                                onClick={this.onClickCancelNaming}
                                disabled={!onEdit_Naming}
                            >
                                {`${StringTranslate.annuler}`}
                            </Button>

                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={this.onClickSaveNaming}
                                disabled={(!onEdit_Naming) || (requiredDatasNeed && this.hasSubscribePlan)}
                            >
                                {`${StringTranslate.enregistrer}`}
                            </Button>
                        </Stack>
                        {(requiredDatasNeed && this.hasSubscribePlan) && (<Alert severity="error" sx={{ color: theme.palette.error.main }}>
                            {StringTranslate.SomeDataRequired}
                        </Alert>)}
                    </Stack>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    masterEmail: state.clientUserData.clientDatas.masterEmail,
    naming: state.clientUserData.clientDatas.naming,
    phone: state.clientUserData.clientDatas.phone,
    clientDatas: state.clientUserData.clientDatas,
    settings: lodashGet(state, 'settingsData.settings', {}), // seul moyen trouvé pour rafraichir le visuel (traduction) du fait que maintenant on rentre dans l'application sans attendre les informations du client
})

const mapDispatchToProps = dispatch => ({
    saveUpdatedClientBilling: (clientDatas) => dispatch(ActionSaveUpdatedClientBilling(clientDatas)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);