import React, { Component } from "react";
import sendError from '../utils/errorService.js';
import { Button, Typography, Box } from "@mui/material";
import StringTranslate from '../assets/i18n/stringLanguage.jsx';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        
        // You can also log the error to an error reporting service
        sendError('ErrorBoundary', { cmpDidCatchError: error, cmpDidCatchInfo: info });
        
        // va recharger l'application de manière automatique - on recharge au bout de 8 secondes d'inaction
        setTimeout( () => {
            if (window.location) window.location.reload();
        }, 8000);
    }

    relaunch = (event) => { if (window.location) window.location.reload(); }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
            <Box 
                sx={{
                    height:"100%",
                    width:"100%",
                    textAlign:"center",
                    position:"relative"
                }}
            >
                <Box 
                    sx={{
                        width:"100%",
                        position:"absolute",
                        top:"45%",
                        textAlign:"center"
                    }}
                >
                    <Typography>{StringTranslate.erroroups} &#9785; {StringTranslate.errorsuite}</Typography>
                    <Typography>{StringTranslate.error}</Typography>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        sx={{ width:"280px" }}
                        onClick={this.relaunch}
                    >
                        {`${StringTranslate.relancerapp}`}
                    </Button>
                </Box>
            </Box>)
        }
        return this.props.children;
    }
}

export default ErrorBoundary;