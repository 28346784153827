import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get as lodashGet, filter as lodashFilter, sortBy as lodashSortBy } from 'lodash';

import { Grid, TableContainer, Typography, Box, TextField, InputAdornment, IconButton, Button } from "@mui/material";
import CustomDataGrid from '../customDataGrid';
import { ThumbnailParcelShapeFromPathInfos } from '../thumbnail/ThumbnailParcelShape';
import ProcessStatusChip from '../customComponents/ProcessStatusChip';
import { Clear, Search, Delete } from '@mui/icons-material';

import { ParcelsHelper } from '../../utils/parcelsHelper';
import DateHelper from '../../utils/dateHelper';

import { TableType, UpdateNbRowsPerPageTable } from '../../redux/actions/settings';
import StringTranslate from '../../assets/i18n/stringLanguage';
import stringHelper from '../../utils/stringHelper';
import { ProcessStatus } from '../../redux/actions/modelisation';
import { ActionDeleteModelisation } from '../../redux/actions/modelisation';

///////////////////////////////////////////////////////////////////////////
// Composant permettant d'afficher les parcelles en fonction de leur modèle
///////////////////////////////////////////////////////////////////////////
class Modelisations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableGridDatas: this.initialiseTableGridDatas(),
            rowsIdFiltered: [], //id des datas filtrer => permet de filtrer sur la liste de datas

            searchFilter: '', // texte de la zone de recherche
            parcelsSelected: [],
        };
        
        this.columns = this.initialiseColumns();
    }

    componentDidUpdate(prevProps) {
        const { modelisationDico } = this.props;

        if (prevProps.modelisationDico !== modelisationDico) {
            this.setState({
                tableGridDatas: this.initialiseTableGridDatas(),
            });
        }
    }
    
    /**
     * Initialisation des colonnes.
     */
    initialiseColumns() {

        let newColumns = [];

        /* ↓ ajout colonne visible - (Miniature + nom de la parcelle) ↓ */
        newColumns.push({
            headerName: `${StringTranslate.nomcolumn}`,
            field: "name",
            minWidth: 180,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.nomcolumn}</Typography>)
            },
            renderCell: params => {
                return (
                    <Box style={{ display: "flex", flexDirection: "row", alignItems: "center"  }} >
                        {(params) ? (<ThumbnailParcelShapeFromPathInfos id={`listItemTbl_${params.row.thumbnailInfos.parcelId}`} {...params.row.thumbnailInfos} />) : (undefined)}
                        <Typography>{params.value}</Typography>
                    </Box>
                )
            }
        });
        //Colonne status modélisation
        newColumns.push({
            headerName: "modelisationStatus",
            field: "modelisationStatus",
            width: 180,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.modelisationStatusTitle}</Typography>)
            },
            renderCell: (params) => {
                return (<ProcessStatusChip processStatus={params.value} />);
            },
        });

        //Colonne nombre images modélisées
        newColumns.push({
            headerName: "nbImageModelize",
            field: "nbImageModelize",
            width: 180,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.modelisationNbImageModelised}</Typography>)
            },
        });

        // Colonne date Semis
        newColumns.push({
            headerName: "dateSemis",
            field: "dateSemis",
            minWidth: 180,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.libelecolumnsemis}</Typography>)
            },
        });

        // Colonne culture
        newColumns.push({
            headerName: "culture",
            field: "culture",
            minWidth: 180,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.libelecolumnculture}</Typography>)
            }
        });

        // Colonne exploitation
        newColumns.push({
            headerName: "exploitation",
            field: "exploitation",
            minWidth: 180,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.libelecolumnexploitation}</Typography>)
            }
        });
        
        // Colonne Surface
        newColumns.push({
            headerName: "surface",
            field: "surface",
            width: 180,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.libelecolumnsurface}</Typography>)
            },
        });


        return newColumns;
    }
    
    /**
     * Méthode permettant de récupérer les données à afficher dans chaque ligne
     */
    initialiseTableGridDatas() {
        const { parcelDico, modelisationDico, thumbnailParcelDico } = this.props;
        let newDatas = [];

        // On itere sur les modélisations et pas sur les parcelles pour éviter des itérations inutiles
        for (let itemPropName in modelisationDico) {
            const modelisation = modelisationDico[itemPropName];
            if (!modelisation) continue;
            if (modelisation.modelisationStatus === ProcessStatus.Unknown) continue;

            const parcel = parcelDico[modelisation.parcelId];
            if (!parcel) continue;   

            let sowingDate = null;
            if (modelisation.sowingDate) {
                if (modelisation.sowingDate instanceof (Date)) {
                    sowingDate = DateHelper.formati18n(modelisation.sowingDate, 'P');
                }
                else if (DateHelper.getDateFromString(modelisation.sowingDate) instanceof Date) {
                    sowingDate = DateHelper.formati18n(new Date(modelisation.sowingDate), 'P');
                }
            }

            newDatas.push({
                id: parcel.id,
                parcel: parcel,
                name: parcel.name,
                dateSemis: sowingDate,
                culture: lodashGet(parcel, "details.culture", null),
                exploitation: lodashGet(parcel, "details.exploitation", null),
                surface: lodashGet(parcel, "details.surface", null),
                modelisationStatus: modelisation.modelisationStatus,
                nbImageModelize: modelisation.nbImageModelize,
                thumbnailInfos: ParcelsHelper.selectParcelFromDicoById(thumbnailParcelDico, parcel.id),
            });
        }

        // On trie les lignes par l'id de la parcelle (comme dans les autres tableaux)
        let sortedDatas = lodashSortBy(newDatas, d => d.id);

        return sortedDatas;
    }

    /* Fonction correspondant à la zone de recherche (recherche une correspondance dans toutes les colonnes) */
    requestSearch(searchValue, rowsOfTable) {
        this.setState({
            searchFilter: searchValue
        });

        try {
            const searchRegex = new RegExp(stringHelper.escapeRegExp(searchValue), 'i');
            const filteredRows = rowsOfTable.filter((row) => {
                return Object.keys(row).some((field) => {
                    let textValue = "" + row[field];
                    return searchRegex.test(textValue.toString());
                });
            }).map((row) => row.id);

            this.setState({
                rowsIdFiltered: filteredRows
            });
        }
        catch(errRegex) { /* Peut arriver si on saisis un truc du genre 'EARL++' ! (il n'aime pas les '++') */ }
    }
    clearSearch = (tableGridDatas) => this.requestSearch('', tableGridDatas)

    /**
     * Méthode permettant de mettre à jour la liste des parcelles sélectionnées dans le tableaux
     * @param {number[]} newSelectionParcels liste des ids de parcelles sélectionner
     */
    setSelectionParcels(newSelectionParcels) {
        this.setState({
            parcelsSelected: newSelectionParcels
        });
    }

    /**
     * Méthode permettant de supprimer les parcelles sélectionnées du tableau
     */
    handleRowsDelete() {
        const { parcelsSelected, tableGridDatas } = this.state;
        const { deleteModelisation, } = this.props;

        let newTableGridDatas = lodashFilter(tableGridDatas, (d) => {
            return !parcelsSelected.includes(d.id);
        });

        deleteModelisation(parcelsSelected);

        this.setState({
            tableGridDatas: newTableGridDatas,
            parcelsSelected: [],
        });
    }

    CustomToolbar(props) {
        const { searchFilter, clearSearch, requestSearch, parcelsSelected, removeSelectedParcels } = props;
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        {/* Zone de recherche */}
                        <Grid item xs={10} sm={8} md={8} lg={8}>
                            <TextField
                                value={searchFilter}
                                onChange={requestSearch}
                                placeholder={StringTranslate.toolbarsearch}
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search fontSize="small" />
                                        </InputAdornment>),
                                    endAdornment: (
                                        <IconButton
                                            title="Clear"
                                            aria-label="Clear"
                                            size="small"
                                            style={{ visibility: searchFilter ? 'visible' : 'hidden' }}
                                            onClick={clearSearch}
                                        >
                                            <Clear fontSize="small" />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Grid>

                        {/* Bouton de suppression */}
                        <Grid item sx={{ alignItems:'center', p: 1 }} xs={2} sm={4} md={4}>
                            <Box sx={{ display: { md: 'block', xs: 'none' } }} style={{ textAlign: 'end', padding:1 }} >
                                <Button
                                    variant="text"
                                    color="error"
                                    onClick={() => removeSelectedParcels()}
                                    disabled={parcelsSelected.length > 0 ? false : true}
                                >
                                    {StringTranslate.modelisationDeleteSelected}
                                </Button>
                            </Box>

                            <Box sx={{ display: { md: 'none', xs: 'block' } }} style={{ textAlign: 'end' }} >
                                <div>
                                    <IconButton color="error" size="large" aria-label="delete field(s)" component="span"
                                        onClick={() => removeSelectedParcels()}
                                        disabled={parcelsSelected.length > 0 ? false : true}>
                                        <Delete />
                                    </IconButton>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    render() {
        const { updateNbRowsPerPageTableModelisations, rowsPerPageForTableModelisations } = this.props;
        const { tableGridDatas, searchFilter, rowsIdFiltered, parcelsSelected } = this.state;

        let rows = tableGridDatas;
        if (searchFilter !== "") {
            rows = rows.filter((row) => rowsIdFiltered.includes(row.id));
        }

        return (
            <>
                <Grid container>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Grid style={{ width: '100%' }} item xs={12}>
                                {/* partie tableau */}
                                <CustomDataGrid
                                    disableSelectionOnClick={true}
                                    tableName={TableType.modelisations}
                                    Toolbar={this.CustomToolbar}
                                    toolbar={{
                                        parcelsSelected,
                                        removeSelectedParcels: () => this.handleRowsDelete(),

                                        searchFilter, 
                                        requestSearch: (event) => this.requestSearch(event.target.value, tableGridDatas),
                                        clearSearch: () => this.clearSearch(tableGridDatas),
                                    }}
                                    pageSize={rowsPerPageForTableModelisations}
                                    updateNbRowsPerPageTable={updateNbRowsPerPageTableModelisations}
                                    rows={rows}
                                    columns={this.columns}

                                    checkBoxActive={true}
                                    selectionModel={parcelsSelected}
                                    onSelectionModelChange={(newSelectionParcels) => { //Sélection des parcelles par clic checkbox
                                        this.setSelectionParcels(newSelectionParcels);
                                    }}
                                />
                            </Grid>
                        </TableContainer>
                    </Grid>
                </Grid>
            </>
        );
    }
}

const mapStateToProps = state => ({
    //Infos provenant du reducer 'parcels':
    parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
    thumbnailParcelDico: lodashGet(state, 'parcelsData.thumbnailParcelDico', {}),

    //Infos provenant du reducer 'settings':
    rowsPerPageForTableModelisations: lodashGet(state, 'settingsData.settings.rowsPerPageForTableModelisations', 20),
    language: lodashGet(state, 'settingsData.settings.language', StringTranslate.getLanguage()),

    //Infos provenant du reducer 'modelisation':
    modelisationDico: lodashGet(state, 'modelisationData.modelisationDico', {}),
});

const mapDispatchToProps = dispatch => ({
    updateNbRowsPerPageTableModelisations: (rowsPerPage) => dispatch(UpdateNbRowsPerPageTable(rowsPerPage, TableType.modelisations)),

    deleteModelisation: (parcelIds) => dispatch(ActionDeleteModelisation(parcelIds)),
});

export default connect(mapStateToProps,mapDispatchToProps)(Modelisations);