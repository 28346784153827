import React from 'react';
import { connect } from "react-redux";
import '../../../assets/css/identificationDialog.css';
import StringTranslate from '../../../assets/i18n/stringLanguage';
import { Typography, Grid } from "@mui/material";
import StepChooseImages from "./StepChooseImages";
import StepShowResult from "./StepShowResult";
import { StepEnumIdentification, ActionDeleteIdentificationImagesToAPI } from "../../../redux/actions/observations";


/**
 * Composant permettant de gérer l'identification
 */
class AbstractIdentification extends React.Component {
    /**
     * Fonction permettant de refermer la page d'identification
     */
    onClickClose = () => {
        const { closeIdentificationDialog, deleteIdentificationImages } = this.props;
        deleteIdentificationImages();
        closeIdentificationDialog();
    }

    /* cycle de vie react */
    render() {
        const { stepIdentification } = this.props;
        return (
            <Grid container spacing={1}>

                {/* Header de l'identification */}
                <Grid item xs={12}>
                    <Typography variant="subtitle1">{StringTranslate.identificationTitle}</Typography>
                </Grid>

                {/* Contenu de l'identification : étape choix d'images ou étape résultat */}
                <Grid item xs={12}>
                    {((stepIdentification === StepEnumIdentification.CHOIX_IMAGE) &&
                        <StepChooseImages />)}

                    {((stepIdentification === StepEnumIdentification.RESULTAT) &&
                        <StepShowResult />)}
                </Grid>

            </Grid>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        stepIdentification: state.observationsData.stepIdentification,
        closeIdentificationDialog: state.observationsData.closeIdentificationDialog,
    };
}

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
    deleteIdentificationImages: () => dispatch(ActionDeleteIdentificationImagesToAPI()),
})

/**
 * Composant permettant de gérer l'identification
 */
export default connect(mapStateToProps, mapDispatchToProps)(AbstractIdentification);