import { fetch } from 'whatwg-fetch';
import StringTranslate from '../assets/i18n/stringLanguage.jsx';


//const parse = JSON.parse;

//const abortableFetch = ('signal' in new Request('')) ? window.fetch : fetch;

/**
 * Parses the JSON returned by a network request
 * @param  {object} response A response from a network request
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
    //return ((response) && (response.json) && (response.value)) ? response.json() : undefined;
    let valueReturned = undefined;

    if (!response) { //'valueReturned' indéfini...
        return valueReturned;
    }
    else if (response.status && response.status === 204) { //'valueReturned' indéfini...
        return valueReturned;
    }
    else if (response.statusText && response.statusText === 'No Content') { //'valueReturned' indéfini...
        return valueReturned;
    }
    //else // on devrai avoir reçu de la donnée !

    //On vérifie si on n'a pas reçu un flux de fichier et ses caractèristiques:
    let hasContentDispositionValue = false;
    let dispoFileName = undefined;
    let dispoId = undefined;
    let dispoFileSize = undefined;
    try {
        //RQ Importante : Ce n'est pas parce que la fenêtre de debogage du navigateur nous montre sa présence, qu'il est lisible par 'fetch' !
        //Cf. https://github.com/matthew-andrews/isomorphic-fetch/issues/67 et  : https://medium.com/@nerdyman/prompt-a-file-download-with-the-content-disposition-header-using-fetch-and-filesaver-8683faf565d0
        const contentDispositionValue = response.headers.get('content-disposition'); 
        if (contentDispositionValue) {
            const dispoItemValues = contentDispositionValue.split(';');
            if (dispoItemValues) {
                dispoFileName = dispoItemValues.find(itemValue => itemValue.includes('filename='))
                    .replace('filename=', '')
                    .replace('"', '') //celui de départ !
                    .replace('"', '') //celui d'arrivée !
                    .trim();
                    
                dispoId = dispoItemValues.find(itemValue => itemValue.includes('name=') && (!itemValue.includes('filename=')))
                    .replace('name=', '')
                    .replace('"', '') //celui de départ !
                    .replace('"', '') //celui d'arrivée !
                    .trim();
                    
                //Rq: revient à la même chose que 'response.headers.get('content-length');'
                dispoFileSize = dispoItemValues.find(itemValue => itemValue.includes('size='))
                    .replace('size=', '')
                    .trim();
                
                if ((dispoFileSize === undefined) || (dispoFileSize <= 0)) {
                    try {
                        const strFileSize = response.headers.get('content-length');
                        if (strFileSize && (strFileSize !== '')) 
                            dispoFileSize = parseInt(strFileSize, 10);
                        else 
                            dispoFileSize = undefined; 
                    } 
                    catch (errFileSz) { 
                        dispoFileSize = undefined; 
                    }
                }

                //La mise à dispo de la taille du flux n'est pas une obligation !
                hasContentDispositionValue = (dispoFileName && dispoId && //(dispoFileSize !== undefined) && 
                    (dispoFileName !== '') && (dispoId !== '') /*&& (dispoFileSize > 0)*/ );
            }
            //else 'hasContentDispositionValue' reste à 'false' !
        }
        //else 'hasContentDispositionValue' reste à 'false' !
    }
    catch (error0) {
        //
    }

    try {
        if (hasContentDispositionValue === true) {
            valueReturned = {
                data : response,
                fileName : dispoFileName,
                id : dispoId,
                size : dispoFileSize
            };
        } else {
            valueReturned = ((response) && (response.json)) ? response.json() : response;        
        }
    }
    catch (error) {
        valueReturned = (response.value) ? response.value : response;
    }

    return valueReturned;
}

/**
 * Checks if a network request came back fine, and throws an error if not
 * @param  {object} response   A response from a network request
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    return parseJSON(response).then(responseFormatted => {
        const error = new Error(response.statusText);
        error.response = response;
        error.response.payload = responseFormatted;
        error.message = responseFormatted;

        throw error;
    });
}

/**
 * Format query params
 * @param params
 * @returns {string}
 */
function formatQueryParams(params) {
    return Object.keys(params)
        .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`) // (ancienne version) => .map(k => `${encodeURIComponent(k)}=${params[k]}`)
        .join('&');
}

const RequestCalls = {
    /**
     * Requests a URL, returning a promise
     * @param  {string} url       The URL we want to request
     * @param  {object} [options] The options we want to pass to "fetch"
     * @return {object}           The response data
     */
    request: (url, options = {}, stringify = true) => {
        // Set headers
        if (stringify) {
            options.headers = Object.assign(
                {
                    'Content-Type': 'application/json',
                },
                options.headers,
                {},
            );
        }

        let currentLanguage = 'fr-FR';
        if (StringTranslate) {
            const valLanguage = StringTranslate.getLanguage();
            currentLanguage = valLanguage;
        }

        if (options.params) {
            options.params = Object.assign(
                {
                    lang: options.params.lang ? options.params.lang : currentLanguage,
                },
                {},
                options.params,
            );
        }
        else {
            options.params = {
                lang: currentLanguage,
            };
        }
        const params = formatQueryParams(options.params);
        url = `${url}?${params}`;
    
        // Stringify body object
        if (options.body && stringify) {
            options.body = JSON.stringify(options.body);
        }
    
        //return abortableFetch(url, options) 
        return fetch(url, options)
            .then(checkStatus)
            .then(parseJSON); 
    },

    /**
     * Requests a URL with authentification's data include, returning a promise
     * @param  {string} url       The URL we want to request
     * @param  {object} [options] The options we want to pass to "fetch"
     * @return {object}           The response data
     */
    requestWithAuthent: (url, options = {}, stringify = true) => { 
        //Désormais, on ne fait que contrôler la présence du jeton et de l'ID client...  
        if ((!options.headers) || (!options.headers.withCredentials) || (!options.headers.credentials) || (!options.headers.Authorization)) {
            return new Promise((resolve, reject) => reject('NO_HEADERS_DEFINE')); //retourne une promesse qui va retourner l'échec !
        } else {
            if (stringify) { //@@si aucun appel à '' n'est fait sans avoir '' à false, on pourra simplifier en virant l'assignation et le faire partout....
                options.headers = Object.assign(
                    {
                        'Content-Type': 'application/json', 
                    },
                    {},
                    options.headers,
                );
            }
            //else // laisse comme tel qu'il a été fournis !
        }

        let currentLanguage = 'fr-FR';
        if (StringTranslate) {
            const valLanguage = StringTranslate.getLanguage();
            currentLanguage = valLanguage;
        }

        if (options.params) {
            options.params = Object.assign(
                {
                    lang: options.params.lang ? options.params.lang : currentLanguage,
                },
                {},
                options.params,
            );
        }
        else {
            options.params = {
                lang: currentLanguage,
            };
        }

        const params = formatQueryParams(options.params);
        url = `${url}?${params}`;

        // Stringify body object
        if (options.body) {
            if (stringify) {
                options.body = JSON.stringify(options.body);
            }
            //else //Laisse comme cela a été fourni !
        }

        //return abortableFetch(url, options) 
        return fetch(url, options)
            .then(checkStatus)
            .then(parseJSON);
    },
};

export default RequestCalls;
