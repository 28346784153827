import StoreFunctions from './storeFunctions.js';


/* classe permettant de procéder à des fonctions générales disponibles l'application */
const URLHelper = {

    /* fonction permettant de sauvegarder les paramètres URL */
    saveURLParameters: function() {   
        let params = (new URL(document.location)).searchParams;
        let obj = {};
        for (let pair of params.entries()) {
            obj[pair[0]] = pair[1];
        }
        if (obj && Object.keys(obj).length > 0) // si on a des paramètres URL alors
            StoreFunctions.saveURLParameters(obj); // enregistrement dans le localStorage
    },

    /* fonction en lien avec la recherche d'un paramètre d'URL */
    getURLParameter(param = null, oneUse = false) { // le 2nd paramètre permet de supprimer après la lecture
        //↓ récupération des paramètres ↓
        let params = StoreFunctions.getURLParameters();
        if (!params || !params[param]) return null;

        // recherche de la valeur
        let value = params[param];
        //alert(`param '${param}'='${value}`);

		//↓ sauvegarde - on supprime l ancienne valeur ↓
		if (value && oneUse) {
			delete params[param]; 
			StoreFunctions.saveURLParameters(params); // enregistrement dans le localStorage
		}

		return value;
    },

    /* fonction permettant de traiter les différents paramètres d'URL spotifarm  après authentification (récupération accessToken) */
    getURLParameters: function() {
        let params = StoreFunctions.getURLParameters(); // récupération des paramètres URL après authentification
        StoreFunctions.clearURLParameters(); // suppression des paramètres URL du sessionStorage après lecture
        return params;
    },

    CanonicalizeUri: function (url) {
        if (url) {
            url = url.toLowerCase();
        }
        if (url && !this.endsWith(url, "/")) {
            url += "/";
        }
        return url;
    },

    endsWith: function(url, suffix) {
        if (!url || !suffix) {
            return false;
        }
        return url.indexOf(suffix, url.length - suffix.length) !== -1;
    }
}

export default URLHelper;