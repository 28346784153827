///////////////////////////////////////////////////////////////////////////
// Import des éléments utilisés par le composant <ImportParcels>
///////////////////////////////////////////////////////////////////////////
import React from "react";
import { connect } from 'react-redux';

import { ActionSaveSettingsAsk, UpdateNbRowsPerPageTable, TableType } from '../../redux/actions/settings.js';
import { ActionImportParcelsAsk, ActionDeleteParcelsAsk, ActionDeleteParcelIdListToAPI, ActionSaveParcel, ActionSetParcelTab } from '../../redux/actions/parcels.js';
import { ActionGoToMap, ActionGoToMapAndSelectParcel, ActionGoToProfil, ActionShowProfilMenuDialog } from '../../redux/actions/contextApp.js';
import { ActionGoToStepForDidacticielFirstParcel, StepEnumDidacticielFirstParcel } from '../../redux/actions/didacticiel.js';
import { ActionParcelListImportWhenDidacticielFirstParcel } from '../../redux/actions/parcels.js';

import {
    RadioGroup, FormControlLabel, Radio, Typography,
    Button, CardActions, Paper, Divider,
    Table, TableHead, TableRow, TableBody, TableCell,
    TextField, Checkbox, CircularProgress,
    Box, Grid, Stack, InputLabel,
    TableContainer
} from "@mui/material";

import { CloudUpload } from "@mui/icons-material";

import shp from 'shpjs'; //module permettant d'importer un fichier .zip ou .shp
import converter from '../../utils/converter.js'; //permet de transformer les données pour les adapter aux entités de l'API web et inversement
import geojsonHelper from '../../utils/geojsonHelper.js';
import JSZip from 'jszip'; //permet de dézipper le contenu d'un fichier zip
import { Redirect } from "react-router-dom";
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import format from 'date-fns/format';
import dicoFunction from '../../datas/dicoDetails.js';
import b64 from 'base64-js';
import sendError from '../../utils/errorService.js';
import { parcelWebApiProvider } from '../../utils/webApiProvider.js';
import '../../assets/css/importParcels.css';
import HelpsForImportParcels from './helpsForImportParcels';
import { ParcelOriginEnum } from '../../redux/actions/parcels';
import { getListCountryCode } from '../../datas/countryCode';
import externalApplicationName, { getListExternalApplicationName } from '../../datas/externalApplicationName';
import projectionByAppAndCountry from '../../datas/projectionByAppAndCountry';
import detailsConverter from "../../utils/detailsConverter.js";
import extensionFile, { typeFile } from "../../datas/extensionFile.js";
import ConstantsTutorialHelps from "../../utils/constantsTutorialHelps.js";
import ConfigAuthent from "../../utils/configAuthent.js";

import MainCard from "../../components/subLayouts/mainCard.jsx";
import SubCard from "../../components/subLayouts/subCard.jsx";


///////////////////////////////////////////////////////////////////////////
// Composant gérant l'import de nouvelles parcelles.
///////////////////////////////////////////////////////////////////////////
class ImportParcels extends React.Component {
    ///////////////////////////////////////////////////////////////////////////
    // constructeur
    ///////////////////////////////////////////////////////////////////////////
    constructor(props) {
        super(props);
        this.default = this.getDefaultParams(props);
        this.state = this.default;

        this.files = undefined; // récupération du fichier =

        //↓↓ on récupère le nombre de parcelle max que l'utilisateur peut importer ↓↓
        this.notLimitedParcelImportable = (props && props.maxParcelTypoClient && props.maxParcelTypoClient <= 0); // si (typo = 2 => maxParcelTypoClient = -1)
        this.maxParcelImported = (props && props.maxParcelTypoClient && props.maxParcelTypoClient > 0) ? props.maxParcelTypoClient : 3;

        // BIND
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancelImport = this.handleCancelImport.bind(this);
    }

    /* fonction permettant de d'obtenir les paramètres */
    getDefaultParams(props) {
        let thisProps = props;
        if (!thisProps) {
            thisProps = this.props;
        }

        return {
            activeStep: 0,
            stepValid: [0, 0],
            geojson: undefined,
            provenance: undefined, //contien une des valeurs de externalApplicationName qu'apres la sélection des fichiers
            name: (thisProps && thisProps.nameExploitation) ? thisProps.nameExploitation : `${StringTranslate.exploitation}`,
            countryCode: (thisProps && thisProps.codeCountry) ? thisProps.codeCountry : 'FR-fr', //@@On doit avoir une constante pour cela....
            selected: [], // permet de récupérer les index des parcelles sélectionnées
            loadingFiles: false,
            messageError: null,
            onSubmit: false,
            isDidacticiel: false,

            //strings contenant la valeur choisis par l'utilisateur via les radioButtons
            radioProvenance: null, //peux contenir une des valeurs de externalApplicationName
            radioType: null, //peux contenir une des valeurs de typeFile

            openHelpFile: false
        };
    }

    onOpenHelpFile = () => {
        this.setState({
            openHelpFile: true
        });
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant d'obtenir le contenu visuel en rapport avec l'étape en question
    ///////////////////////////////////////////////////////////////////////////
    getStepContent(step) {
        const { openHelpFile, selected, name, geojson, radioProvenance, radioType } = this.state;

        let datas = null;
        let columns = null;

        if (geojson !== undefined) {
            datas = this.initialiseDataTable(geojson.features);
            columns = this.initialiseColumnTable(geojson.features);
        }

        const srcGifHelpImportFiles = ConfigAuthent.ConstAndDefault.UrlRootCompteResources + ConstantsTutorialHelps.SubUrlHelpImportFiles;

        switch (step) {
            case 0:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Stack>
                                <InputLabel>{`${StringTranslate.importparcelles}`}</InputLabel>
                                <TextField
                                    id="farmName"
                                    name="farmName"
                                    value={name}
                                    onChange={this.handleName}
                                    fullWidth
                                    placeholder="Nom du parcellaire"
                                    autoFocus
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <SubCard title={`${StringTranslate.provfichier}`}>
                                {/* card content */}

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <Typography gutterBottom variant="subtitle1">
                                            {`${StringTranslate.importFichiersProvenance}`}
                                        </Typography>
                                        <RadioGroup
                                            name="provenance"
                                            value={radioProvenance}
                                            onChange={this.handleRadioProvenanceChange}
                                        >
                                            <FormControlLabel value={externalApplicationName.telepac} control={<Radio color="primary" />} label={`${StringTranslate.telepac}`} />
                                            <FormControlLabel value={externalApplicationName.geofolia} control={<Radio color="primary" />} label={`${StringTranslate.geofolia}`} />
                                            <FormControlLabel value={externalApplicationName.mesparcelles} control={<Radio color="primary" />} label={`${StringTranslate.mesparcelles}`} />
                                            <FormControlLabel value={externalApplicationName.autre} control={<Radio color="primary" />} label={`${StringTranslate.autre}`} />
                                        </RadioGroup>
                                    </Grid>

                                    <Grid item xs={12} md={4} disabled={radioProvenance}>
                                        <Typography gutterBottom variant="subtitle1">
                                            {`${StringTranslate.importFichiersType}`}
                                        </Typography>
                                        <RadioGroup
                                            name="type"
                                            value={radioType}
                                            onChange={this.handleRadioTypeChange}
                                        >
                                            <FormControlLabel value={typeFile.zip} control={<Radio color="primary" />} label={`${StringTranslate.importFichiersTypeZip}`}
                                                disabled={!radioProvenance} />
                                            <FormControlLabel value={typeFile.fichiers} control={<Radio color="primary" />} label={`${StringTranslate.importFichiersTypeIndividuels}`}
                                                disabled={!radioProvenance} />
                                        </RadioGroup>
                                        <Button variant="text" color="secondary" disabled={(radioType !== typeFile.fichiers)} onClick={this.onOpenHelpFile}>
                                            {`${StringTranslate.aide}`}
                                        </Button>

                                    </Grid>

                                    {openHelpFile && <Grid item xs={12} md={4}>
                                        <Typography gutterBottom variant="subtitle1">
                                            {`${StringTranslate.aide}`}
                                        </Typography>
                                        <Box className="auto-horizontal-scroll">
                                            <img
                                                src={srcGifHelpImportFiles}
                                                alt={`${StringTranslate.importParcellesGif}`}
                                                loading={`${StringTranslate.chargeimage}`}
                                                className='fade'
                                            />
                                        </Box>
                                    </Grid>}

                                </Grid>

                            </SubCard>

                        </Grid>
                    </Grid>
                );
            case 1:
                return (
                    <>
                        {/* partie - affichage du tableau */}
                        {/* partie - Affichage du nombre de parcelle maximum à importer */}
                        {(this.notLimitedParcelImportable) ? (
                            <Typography variant="body2" gutterBottom>{`${StringTranslate.vosparcelles}`} ({selected.length})</Typography>
                        ) : (
                            <Typography variant="body2" gutterBottom>{`${StringTranslate.selectionnerparcelles}`} {selected.length}/{this.maxParcelImported}</Typography>
                        )}
                        <TableContainer component={Paper} classes={{ root: 'import-paper-root' }}>
                            {((geojson !== undefined) && (geojson.features !== undefined) && (
                                <Table classes={{ root: 'table-root' }} size="small">
                                    <TableHead>
                                        <TableRow key={"keys"}>
                                            <TableCell></TableCell>
                                            {columns.map((value, index) => (
                                                <TableCell key={"key_" + index} classes={{ root: "table-cell-head-root" }}>{value}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {datas.map((parcel, index) => {
                                            const isSelected = this.isSelected(index);
                                            return (
                                                <TableRow
                                                    key={"feature_" + index}
                                                    hover
                                                    onClick={event => this.handleSelectClick(event, index)}
                                                    role="checkbox"
                                                    aria-checked={isSelected}
                                                    tabIndex={-1}
                                                    selected={isSelected}
                                                >
                                                    <TableCell padding="checkbox"><Checkbox checked={isSelected} color="primary" /></TableCell>
                                                    {parcel.map((property, indexParcel) => (
                                                        <TableCell classes={{ root: "table-cell-root" }} key={"value_" + indexParcel}>{property}</TableCell>
                                                    ))}

                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            ))}
                        </TableContainer>
                    </>
                );
            default: // 'Etape inconnue. Retournez sur l\'onglet \'carte\'';
                return (
                    <Redirect to="/maps" from="/Parcels" />
                );
        }
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de modifier la provenance du fichier
    ///////////////////////////////////////////////////////////////////////////
    handleRadioProvenanceChange = (event) => {
        if (window.innerWidth <= 614) {
            window.scrollTo({ top: 300, behavior: "smooth" })
        }
        this.setState({ radioProvenance: event.target.value })
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de modifier le type de fichier
    ///////////////////////////////////////////////////////////////////////////
    handleRadioTypeChange = (event) => {
        if (window.innerWidth <= 614) {
            window.scrollTo({ top: 600, behavior: "smooth" })
        }
        this.setState({ radioType: event.target.value })
    }

    /**
     * fonction permettant de créer un tableau contenant les informations des parcelles importer
     */
    initialiseDataTable = (featuresList) => {
        const { provenance } = this.state;

        let newDatas = [];
        let compteur = 0;
        featuresList.forEach(feature => {
            let temp = [];
            detailsConverter.convertPropertiesToDetailsFromParcel(feature, provenance);
            feature.pz_isDetailConverted = true;

            if (feature.properties) {
                temp.push(detailsConverter.getParcelName(feature.properties, provenance, compteur++));
                for (let detail in feature.properties.pz_details) { //La liste a été définis par 'convertPropertiesToDetails'
                    if (feature.properties.pz_details[detail] instanceof (Date)) {
                        temp.push(format(feature.properties.pz_details[detail], 'dd/MM/yyyy'));
                    } else {
                        temp.push(feature.properties.pz_details[detail]);
                    }
                }
            }

            newDatas.push(temp);
        });

        return newDatas;
    }

    /**
     * fonction permettant de créer un tableau contenant les noms des colonnes du tableau à afficher 
     * Colonne afficher : Nom de la parcelle, toutes les propriétés de DetailsParcel
     */
    initialiseColumnTable = (featuresList) => {
        let newCol = [];
        newCol.push(`${StringTranslate.nomparcelle}`);
        if ((!featuresList) || (featuresList.length <= 0)) return newCol;

        if (featuresList[0].properties) {
            const details = featuresList[0].properties.pz_details;
            const dico = dicoFunction();
            for (let detail in details) {
                dico.forEach(column => {
                    if (detail === column.name) {
                        newCol.push(column.libeleColumn);
                    }
                });
            }
        }

        return newCol;
    }


    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de savoir si la parcelle est une parcelle sélectionnée
    ///////////////////////////////////////////////////////////////////////////
    isSelected = id => this.state.selected.indexOf(id) !== -1;

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de sélectionner la parcelle à importer suivant un event click sur la ligne du tableau
    ///////////////////////////////////////////////////////////////////////////
    handleSelectClick = (event, id) => {
        if (this.notLimitedParcelImportable) { // sélection automatique (impossible de désélectionner)
            return;
        }

        const { selected } = this.state;
        const isMaxReached = (selected && (selected.length === this.maxParcelImported || selected.length > this.maxParcelImported)) ? true : false;

        const selectedIndex = selected.indexOf(id); // permet de savoir si la parcelle fait partie des éléments sélectionnés
        let newSelected = [];

        if (selectedIndex === -1) { // ne s'y trouve pas - on l'ajoute
            if (!isMaxReached) newSelected = newSelected.concat(selected, id);
            else newSelected = selected;
        } else if (selectedIndex === 0) { // c'est le 1er
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) { // c'est le dernier
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) { // il est entre le 1er et le dernier
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    };

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de renseigner le nom de l'exploitation
    ///////////////////////////////////////////////////////////////////////////
    handleName = (event) => {
        this.setState({
            name: event.target.value,
        });
    };

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de faire apparaitre la sélection de fichier
    ///////////////////////////////////////////////////////////////////////////
    handleChange = () => {
        const { radioProvenance } = this.state;
        this.setState({
            provenance: radioProvenance,
        });
    };

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de passer à l'étape suivante
    ///////////////////////////////////////////////////////////////////////////
    handleNext = () => {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep + 1,
        });
    };

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de passer à l'étape précèdente   
    /////////////////////////////////////////////////////////////////////////// 
    handleBack = () => {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep - 1,
        });
    };

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de VALIDER LES ETAPES pour passer à l'étape suivante
    ///////////////////////////////////////////////////////////////////////////
    handleValidation() {
        const { activeStep, stepValid, geojson } = this.state;

        if ((activeStep === 0) && /*(stepValid[activeStep] === 0) &&*/ (geojson !== undefined)) {
            let selected = [];
            if (this.notLimitedParcelImportable) {
                for (let indexSelection = 0; indexSelection < geojson.features.length; indexSelection++) {
                    selected.push(indexSelection);
                }
            }

            stepValid[activeStep] = 1;
            this.setState({
                stepValid: stepValid,
                selected: selected
            }, () => {
                this.saveParcellaireNameToServer();

                this.handleNext();
            });
        }
        else if ((activeStep === 1) /*&& (stepValid[activeStep] === 0)*/) {
            stepValid[activeStep] = 1;
            this.setState({ stepValid: stepValid }, () => {
                this.handleImport();
            });
        }
        else {
            this.handleNext();
        }
    }

    //Méthode retournant un faux contenu du store Redux:
    myGetState = () => {
        const { token, clientId } = this.props; // Provenant de la décoration de Redux

        return {
            connectionData: {
                accessToken: token,
            },
            clientUserData: {
                clientDatas: {
                    id: clientId,
                },
            },
        };
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de demander la redirection vers la carte (suite importation ok)
    ///////////////////////////////////////////////////////////////////////////
    handleImport() {
        const { goToMap, goToSuccessStep, isDidacticiel, counterCompletedTutorial } = this.props;

        if ((isDidacticiel) && (counterCompletedTutorial <= 0))
            goToSuccessStep(); // si on est dans le didacticiel - on redirige vers l' étape de succès
        else
            goToMap(); // si non, on redirige vers la carte
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de rechercher et récupérer les fichiers contenus dans le zip, nécessaires pour l'import parcellaire
    ///////////////////////////////////////////////////////////////////////////
    searchImportFilesInZip(readerResult) {
        let promiseImportFiles = (resolve, reject) => {
            let promiseDatas = undefined;
            if (!readerResult) {
                resolve(promiseDatas); //renvoie une bonne fin mais sans les données !
                return;
            }

            //lecture du zip:
            var new_zip = new JSZip();
            new_zip.loadAsync(readerResult)
                .then(function(zip) {
                    var files = zip.file(/.+/); // regex => on ne récupère que les fichiers avec extension
                    promiseDatas = [];
                    files.forEach(function (a) {
                        if ((a) && (a.name)) {
                            const extFileFund = a.name.slice(-3).toLowerCase();
                            if ((extFileFund === extensionFile.shp) || (extFileFund === extensionFile.dbf) || (extFileFund === extensionFile.shx)) {
                                //data[extensionFile.shp] = a.asNodeBuffer();
                                promiseDatas.push(
                                    //stocke la promesse de chaque lecture de fichier contenu dans le zip...
                                    //a.async("arraybuffer").then((abValue) => { //ok, mais le 'shp.parseShp(' ne semble pas y arriver (est-ce le format retourné ?)
                                    //a.async("nodebuffer").then((bufferValue) => {//j'ignore pourquoi dans notre projet, cela ressort avec le msg : ''
                                    a.async("base64").then((abValue) => {
                                        //console.log(`abValue (of ${extFileFund}):`, abValue);
                                        const bufferValue = b64.toByteArray(abValue);
                                        //console.log(`bufferValue (of ${extFileFund}):`, bufferValue);
                                        
                                        return { 
                                            result: true, 
                                            data: bufferValue,
                                            extFile: extFileFund,
                                        };
                                    }).catch((err) => {
                                        //console.log(`err (of ${extFileFund}):`, err);

                                        return { result: false, };
                                    })
                                );
                            } else if ((extFileFund === extensionFile.prj) || (extFileFund === extensionFile.cpg)) {
                                //data[extensionFile.shp] = a.asNodeBuffer();
                                promiseDatas.push(
                                    //stocke la promesse de chaque lecture de fichier contenu dans le zip...
                                    //a.async("arraybuffer").then((abValue) => { //ok, mais le 'shp.parseShp(' ne semble pas y arriver (est-ce le format retourné ?)
                                    //a.async("nodebuffer").then((bufferValue) => {//j'ignore pourquoi dans notre projet, cela ressort avec le msg : ''
                                    a.async("string").then((strValue) => {
                                        //console.log(`strValue (of ${extFileFund}):`, strValue);
                                        
                                        return { 
                                            result: true, 
                                            data: strValue,
                                            extFile: extFileFund,
                                        };
                                    }).catch((err) => {
                                        //console.log(`err (of ${extFileFund}):`, err);

                                        return { result: false, };
                                    })
                                );
                            }
                            //else // on ne lit pas le fichier car non-reconnu !
                        }
                        //else //on passe au fichier suivant car celui-ci ne semble pas correct !
                    }, this);

                    //et retourne les promesses de lecture des fichiers qui nous intéresse :
                    resolve(promiseDatas);
                    return;
                })
                .catch((err) => {
                    reject(err);
                    return;
                });
        };

        return new Promise(promiseImportFiles);
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de vérifier la présence du fichier de projection et d'en fournir un si besoin puis de créer le geoJson
    ///////////////////////////////////////////////////////////////////////////
    checkPrjFileAndCreateGeoJson(data, arrayExtensions) {
        const { countryCode, provenance } = this.state;
        const hasPrjFile = arrayExtensions.includes(extensionFile.prj);

        //la projection n'a pas été fournis, on va donc en définir une suivant la source du parcellaire !
        //!!! 28/10/2021 On ne prend pas en compte le prj de mesparcelles, il est incorrect!!!
        if ((!hasPrjFile) || (provenance === externalApplicationName.mesparcelles)) {
            if (getListExternalApplicationName().includes(provenance)) {
                if (getListCountryCode().includes(countryCode)) {
                    data[extensionFile.prj] = projectionByAppAndCountry[provenance][countryCode];
                } else {
                    data[extensionFile.prj] = projectionByAppAndCountry[provenance].default;
                }
                arrayExtensions.push(extensionFile.prj);
            }
        }

        //-2-A-2 ⇓⇓ extraction les données geoJson ⇓⇓
        let arr = [];
        // ⇓⇓ on vérifie si fichier .prj présent, le plugin shpjs applique une transformation dans le systeme géodésique 4326  ⇓⇓
        if (arrayExtensions.includes(extensionFile.prj)) //Y a t il un fichier définissant la projection?
            arr.push(shp.parseShp(data[extensionFile.shp], data[extensionFile.prj]));
        else
            arr.push(shp.parseShp(data[extensionFile.shp])); //cas possible si la source du parcellaire n'a pas été reconnu...

        // ⇓⇓ on vérifie si le fichier facultatif .dbf existe  ⇓⇓
        if (arrayExtensions.includes(extensionFile.dbf)) {
            if (arrayExtensions.includes(extensionFile.cpg)) {//Y a t il un fichier définissant l'encodage?
                arr.push(shp.parseDbf(data[extensionFile.dbf], data[extensionFile.cpg]));
            }
            else {
                if ((provenance === externalApplicationName.geofolia) || (provenance === externalApplicationName.mesparcelles)) {
                    arr.push(shp.parseDbf(data[extensionFile.dbf], 'windows-1252')); //windows-1252 pour garder les accents bien sur !!!
                } else {
                    arr.push(shp.parseDbf(data[extensionFile.dbf]));
                }
            }
        }

        //-2-A-3 ⇓⇓ On va créer les données sous le format GeoJson ⇓⇓
        let geojson = converter.arrayShapefileToGeojson(arr);

        //-2-A-6 ⇓⇓ On change l'état permettant le changement d'étape  ⇓⇓
        this.setState({ messageError: null, loadingFiles: false, geojson: geojson }, () => {
            this.handleValidation();
        });

    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de récupérer le fichier .shp/.zip et le stocker dans l'état du composant pour pouvoir travailler appeler par l'élément html input
    ///////////////////////////////////////////////////////////////////////////
    onInputFileChange = (e) => {
        const { provenance } = this.state;

        //-1- ⇓⇓ récupération du fichier ou des fichiers ⇓⇓ 
        let fichiers = e.target.files;
        //-1-B ⇓⇓ on lance l'icône de chargement ⇓⇓
        this.setState({ messageError: null, loadingFiles: true });

        //-2- ⇓⇓ Analyse des différents fichiers - Si 1 seul fichier ⇓⇓
        if (fichiers.length === 1) {
            let fichier = e.target.files[0]; //on prend l'unique
            let extension = fichier.name.slice((fichier.name.lastIndexOf(".") - 1 >>> 0) + 2); //permet de récupérer l'extension

            //-2-A ⇓⇓ lecture du fichier zip (autorisé pour geofolia/telpack/autre) et transformation des informations au format geojson ⇓⇓
            if (extension === typeFile.zip) {

                let reader = new FileReader(); // l'utilisation du FileReader est nécessaire => https://developer.mozilla.org/fr/docs/Web/API/FileReader
                reader.onload = () => { // lors du chargement => déclenché par readAsArrayBuffer()
                    //-2-A-1 ⇓⇓ On extrait les données des différents fichiers - extrait du module shpjs (unzip) ⇓⇓
                    // Utilisation du module version 2.. à cause des problématiques d'asynchronisme (voir fonction asNodeBuffer() asText())
                    var data = {};
                    let arrayExtensions = [];
                    this.searchImportFilesInZip(reader.result)
                        .then((resultImportFiles) => {
                            if (!resultImportFiles) {
                                this.setState({ messageError: `${StringTranslate.mezip}`, loadingFiles: false });
                            } else {
                                //lance les réalisations de chaque promesse, et attend le retour de toutes...
                                Promise.all(resultImportFiles).then((values) => {
                                    if ((!values) || (!Array.isArray(values)) || (values.length < 1)) {
                                        this.setState({ messageError: `${StringTranslate.mezip}`, loadingFiles: false });
                                    } else {
                                        //on vérifie les lectures valides...
                                        values.forEach(function (itemRead) {
                                            if (itemRead && (itemRead.result === true) && (itemRead.data) && (itemRead.extFile)) {
                                                data[itemRead.extFile] = itemRead.data;
                                                arrayExtensions.push(itemRead.extFile);
                                            }
                                            //else //ko ou incomplet => on ignore !
                                        });

                                        //Si on a le fichier de projection dans le zip, on demande à la Web API s'il faut le corriger:
                                        //TODO : A l'idéal, il faudrait faire l'appelle à une action (qui fait les deux appels et permet de patienter et de récupérer le zip final à exploiter.)
                                        if (!arrayExtensions.includes(extensionFile.shp)) { // Vérification fichier importés - 1 fichier essentiel !!!
                                            this.setState({ messageError: `${StringTranslate.mezip}`, loadingFiles: false });
                                        }
                                        else {
                                            //!!! 28/10/2021 On ne prend pas en compte le prj de mesparcelles, il est incorrect!!!
                                            if ((arrayExtensions.includes(extensionFile.prj)) && (provenance !== externalApplicationName.mesparcelles)) {
                                                //Je fais appelle à la Web API pour qu'il me retourne le zip corrigé (si la projection le nécessite):
                                                let thisProj = data[extensionFile.prj];
                                                parcelWebApiProvider.checkProjection(null, this.myGetState.bind(this), thisProj)
                                                    .then((response) => { // on reçoit un string => Soit vide: signifiant que le contrôle est OK ; Sinon, c'est la définition de la projectoion corrigée
                                                        //Si il y a du contenu retourné (dans cette partie valide, c'est que la projection a été corrigé !)
                                                        if (response) {
                                                            thisProj = response;

                                                            //Appelle la Web API pour corriger le zip:
                                                            let zipForCorrection = { datasCorrected: Array.from(new Uint8Array(reader.result)), isCorrected: false };
                                                            parcelWebApiProvider.correctShapeZip(null, this.myGetState.bind(this), zipForCorrection)
                                                                .then((response) => { // on reçoit une nouvelle entité POCO 'ZipParcels' contenant le flux modifié (si la projection contenue le nécessitait)
                                                                    let datasZip = reader.result;
                                                                    if (response && response.isCorrected && response.datasCorrected) { // => Dans le but de lire ceci plutôt que 'reader.result'
                                                                        datasZip = b64.toByteArray(response.datasCorrected);
                                                                    }
                                                                    //else //le zip n'a pas eu besoin d'être corrigé!

                                                                    //-2-A-1 ⇓⇓ On extrait les données des différents fichiers - extrait du module shpjs (unzip) ⇓⇓
                                                                    // Utilisation du module version 2.. à cause des problématiques d'asynchronisme (voir fonction asNodeBuffer() asText())
                                                                    var dataCtrlPrj = {};
                                                                    let arrayExtensionsCtrlPrj = [];
                                                                    this.searchImportFilesInZip(datasZip)
                                                                        .then((resultImportFilesCtrlPrj) => {
                                                                            if (!resultImportFilesCtrlPrj) {
                                                                                this.setState({ messageError: `${StringTranslate.mezip}`, loadingFiles: false });
                                                                            } else {
                                                                                //lance les réalisations de chaque promesse, et attend le retour de toutes...
                                                                                Promise.all(resultImportFilesCtrlPrj).then((valuesCtrlPrj) => {
                                                                                    if ((!valuesCtrlPrj) || (!Array.isArray(valuesCtrlPrj)) || (valuesCtrlPrj.length < 1)) {
                                                                                        this.setState({ messageError: `${StringTranslate.mezip}`, loadingFiles: false });
                                                                                    } else {
                                                                                        //on vérifie les lectures valides...
                                                                                        valuesCtrlPrj.forEach(function (itemReadCtrlPrj) {
                                                                                            if (itemReadCtrlPrj && (itemReadCtrlPrj.result === true) && 
                                                                                                (itemReadCtrlPrj.data) && (itemReadCtrlPrj.extFile)) {
                                                                                                    dataCtrlPrj[itemReadCtrlPrj.extFile] = itemReadCtrlPrj.data;
                                                                                                arrayExtensionsCtrlPrj.push(itemReadCtrlPrj.extFile);
                                                                                            }
                                                                                            //else //ko ou incomplet => on ignore !
                                                                                        });

                                                                                        //-2-A-2-Scenario ⇓⇓ provenance 'autre' mais pas de fichier .prj renseigné ⇓⇓
                                                                                        if (!arrayExtensionsCtrlPrj.includes(extensionFile.shp)) {
                                                                                            this.setState({ messageError: `${StringTranslate.mezip}`, loadingFiles: false });
                                                                                        } else {
                                                                                            this.checkPrjFileAndCreateGeoJson(dataCtrlPrj, arrayExtensionsCtrlPrj);
                                                                                        }
                                                                                    }
                                                                                });
                                                                            }
                                                                        })
                                                                        .catch((err2bis) => {
                                                                            // Traçage d'une action particulière:
                                                                            sendError('handleParcel - searchImportFilesInZip (bis)', {
                                                                                "err": err2bis,
                                                                            });
                                                
                                                                            this.setState({ messageError: `${StringTranslate.mefichier}`, loadingFiles: false });
                                                                        });
                                                                })
                                                                .catch((err) => {
                                                                    // Traçage d'une action particulière:
                                                                    sendError('handleParcel - ControlZip', {
                                                                        "err": err,
                                                                    });

                                                                    this.setState({ messageError: `${StringTranslate.mefichier}`, loadingFiles: false });
                                                                });
                                                        } else {//si le Proj n'a pas besoin de correction, on prend le zip tel que:
                                                            if (!arrayExtensions.includes(extensionFile.shp)) { // Vérification fichier importés - 1 fichier essentiel !!!
                                                                this.setState({ messageError: `${StringTranslate.mezip}`, loadingFiles: false });
                                                            } else {
                                                                this.checkPrjFileAndCreateGeoJson(data, arrayExtensions);
                                                            }
                                                        }
                                                    })
                                                    .catch((err) => {
                                                        // Traçage d'une action particulière:
                                                        sendError('handleParcel - ControlProj', {
                                                            "err": err,
                                                        });

                                                        this.setState({ messageError: `${StringTranslate.mefichier}`, loadingFiles: false });
                                                    });
                                            } else { //si le zip n'a pas de fichier Proj, on premet le zip tel que (on ne pourra jamais rien corriger, s'il y avait besoin)
                                                this.checkPrjFileAndCreateGeoJson(data, arrayExtensions);
                                            }
                                        }
                                    }
                                });
                            }
                        })
                        .catch((err2) => {
                            // Traçage d'une action particulière:
                            sendError('handleParcel - searchImportFilesInZip', {
                                "err": err2,
                            });

                            this.setState({ messageError: `${StringTranslate.mefichier}`, loadingFiles: false });
                        });
                };

                try {
                    reader.readAsArrayBuffer(fichier); // Cette méthode démarre la lecture du contenu
                }
                catch (errCatchedZip) {
                    sendError('onInputFileChange', { "errCatchedZip": errCatchedZip });
                }
            }
            //-2-B ⇓⇓ lecture du fichier shp (autorisé pour Geofolia et telepac) et transformation des informations au format geojson ⇓⇓
            else if (extension === extensionFile.shp) {

                let reader = new FileReader(); // l'utilisation du FileReader est nécessaire => https://developer.mozilla.org/fr/docs/Web/API/FileReader
                reader.onload = () => { // lors du chargement => déclenché par readAsArrayBuffer()

                    // -1- ⇓⇓ utilisation du plugin shpsjs pour récupérer un array ⇓⇓
                    var data = { [extensionFile.shp]: reader.result };
                    let arrayExtensions = [extensionFile.shp];
                    this.checkPrjFileAndCreateGeoJson(data, arrayExtensions);
                };

                try {
                    reader.readAsArrayBuffer(fichier); // Cette méthode démarre la lecture du contenu
                }
                catch (errCatchedShp) {
                    sendError('onInputFileChange', { "errCatchedShp": errCatchedShp });
                }
            }
            // 2-C Aucune extension n'a été trouvée => @@ gérer message d'erreur
            else {
                if (provenance === externalApplicationName.geofolia)
                    this.setState({ loadingFiles: false, messageError: `${StringTranslate.me4}` });
                else
                    this.setState({ loadingFiles: false, messageError: `${StringTranslate.me2Min}` });
            }
        }
        //-3- ⇓⇓ Analyse des différents fichiers - Si on a plusieurs fichiers - activation de l'attribut Multiple dans l'élément HTML <input> ⇓⇓
        else if (fichiers.length > 1) {

            var datas = {};// permet de récupérer les extensions et les données de fichier associées
            let arrayExtensions = [];
            Promise.all( //permet d'attendre la fin du traitement pour passer à la fonction then() - reader.onloadend étant ASYNCHRONE et sachant que l'on a plusieurs fichiers !!!
                [].map.call(fichiers, function (fichier) { // permet de retourner un Array[promise1,promise2,...]
                    return new Promise(function (resolve, reject) {
                        const extensionItem = fichier.name.slice((fichier.name.lastIndexOf(".") - 1 >>> 0) + 2); //permet de récupérer l'extension);

                        var reader = new FileReader();
                        reader.onloadend = () => {
                            datas[extensionItem] = reader.result;
                            arrayExtensions.push(extensionItem);
                            resolve('success');
                        };
                        reader.onerror = (error) => { reject('fail') };

                        try {
                            //Lecture des fichiers correspondant aux extensions qu'on manipule (les autres nous sont inutiles)
                            if ((extensionItem === extensionFile.shp) || (extensionItem === extensionFile.dbf)) {
                                reader.readAsArrayBuffer(fichier);
                            } else {
                                if ((extensionItem === extensionFile.prj) || (extensionItem === extensionFile.shx) || (extensionItem === extensionFile.cpg)) {
                                    reader.readAsText(fichier);
                                }
                            }
                        }
                        catch (errCatched) {
                            sendError('onInputFileChange', { "errCatched": errCatched });

                            reject('fail');
                        }
                    })
                })
            )
            .then((results) => {
                if (!results.includes('fail')) {
                    if (datas[extensionFile.shp]) {
                        //!!! 28/10/2021 On ne prend pas en compte le prj de mesparcelles, il est incorrect!!!
                        if (datas[extensionFile.prj] && (provenance !== externalApplicationName.mesparcelles)) {//si l'utilisateur a fournis le fichier de projection, autant l'exploiter !
                            //Il faut vérifier si la projection fournie nécessite une modification !
                            // Attention: dans cette appel onne fournis pas un zip mais les deux fichiers (shp et prj) !
                            let filesForCorrection = {
                                datasCorrected: Array.from(new Uint8Array(datas[extensionFile.shp])), //flux du fichier SHP fournie (et qui sera potentiellement modifié)
                                prjDefine: datas[extensionFile.prj], //c'est une chaîne de caractères ! Même remarque...
                                isCorrected: false,
                            };
                            parcelWebApiProvider.correctShapeShp(null, this.myGetState.bind(this), filesForCorrection)
                                .then((response) => { // on reçoit une nouvelle entité POCO 'ZipParcels' contenant le flux modifié (si la projection contenue le nécessitait)
                                    if (response && response.isCorrected && response.datasCorrected) { // => Dans le but de lire ceci plutôt que 'reader.result'
                                        datas[extensionFile.shp] = b64.toByteArray(response.datasCorrected); //on actualise en reprenant le shp modifié
                                        datas[extensionFile.prj] = response.prjDefine; //en principe, elle est fournie et doit être du 4326 !
                                    }
                                    //else //le shp n'a pas eu besoin d'être corrigé!
                                    this.checkPrjFileAndCreateGeoJson(datas, arrayExtensions);
                                })
                                .catch((errShp) => {
                                    // Traçage d'une action particulière:
                                    sendError('handleParcel - ControlShp', {
                                        "err": errShp,
                                    });

                                    this.setState({ messageError: `${StringTranslate.mefichier}`, loadingFiles: false });
                                });
                        }
                        else {//sans projection de fournie, on tente via la projection par défaut suivant la langue et la source d'import:
                            this.checkPrjFileAndCreateGeoJson(datas, arrayExtensions);
                        }
                    }
                    else {
                        this.setState({ loadingFiles: false, messageError: `${StringTranslate.me2Min}` });
                    }
                } else {
                    this.setState({ loadingFiles: false });
                }
            })
            .catch((err) => {
                // Traçage d'une action particulière: 
                sendError('handleImportFilesInput', { "err": err });

                this.setState({ loadingFiles: false });
            });
        }
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction liée par le BOUTON 'Enregistrer mon parcellaire' => récupération des données GeoJson et enregistrement en BDD
    ///////////////////////////////////////////////////////////////////////////
    handleSubmit() {

        const { geojson, selected, provenance, name } = this.state;

        //-0- ⇓⇓ étape de vérification ⇓⇓
        if ((!geojson) || (!provenance) || (!name)) return;
        if ((!this.notLimitedParcelImportable) && ((!selected) || (selected.length === 0))) return;

        this.setState({ onSubmit: true }, this.saveGeojsonToServer());

    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction liée par le BOUTON 'Enregistrer mon parcellaire' => récupération des données GeoJson et enregistrement en BDD
    ///////////////////////////////////////////////////////////////////////////
    saveGeojsonToServer() {
        const { clientId, isDidacticiel } = this.props; // Provenant de la décoration de Redux
        const { geojson, selected, provenance, name } = this.state;

        //-1- ⇓⇓ étape de vérification ⇓⇓
        if ((!geojson) || (!provenance) || (!name)) return;

        /* si on est en dehors du didacticiel FirstParcel */
        if ((!this.notLimitedParcelImportable) &&
            ((!selected) || (selected.length === 0))) return;

        //-2- ⇓⇓ on convertit des données geojson vers des objets de type 'parcel' ⇓⇓
        let parcels = []
        try {
            parcels = converter.geojsonToDatabaseParcels(geojson, ParcelOriginEnum.Imported, provenance, name); //On convertit l'objet lu du fichier SHP ou zip ('geojson') en une liste d'objet POCO parcelles
        }
        catch(errConvertParcels) {
            // Traçage d'une action particulière:
            sendError('ImportParcels: saveGeojsonToServer - geojsonToDatabaseParcels', {
                "errConvertParcels": errConvertParcels,
                "clientId": clientId,
                "provenance": provenance,
                "name": name,
                //"feature1": ((geojson) && (geojson.features) && (geojson.features.length > 0)) ? JSON.stringify(geojson.features[0]) : 'N/A',
            });
        }

        var unselectedParcels = null;
        if (!parcels) {
            this.setState({ onSubmit: false });
            return;
        }

        //-3- ⇓⇓ Pour l'ensemble des parcelles, on lui fournie l'IdClient  ⇓⇓
        //(on précise l'origine de la parcelle qui ici est une parcelle importée)
        parcels.forEach((parcel) => {
            parcel.clientId = clientId;
            parcel.enumOrigin = ParcelOriginEnum.Imported;
        });

        //-4- ⇓⇓ Etape de filtre des parcelles (sélectionnées et non-sélectionnées) suivant les différents profils : GRATUIT ou FREEMIUM  ⇓⇓
        if (!this.notLimitedParcelImportable) {
            var results = geojsonHelper.splitParcelsArrayByIndex(parcels, selected);
            parcels = results[0];
            unselectedParcels = results[1];
        }

        //-5- ↓ Import suivant cas d'utilisation ↓
        if (isDidacticiel === true) // on est dans le didacticiel au moment de l'import
            this.props.saveParcelListImportWhenDidacticielFirstParcel(parcels, unselectedParcels);
        else
            this.props.importParcels(unselectedParcels, parcels);

        //-6- ↓ On met à jour le visuel et valide l'étape ↓
        this.setState({ onSubmit: false }, this.handleValidation);
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant d'enregistrer le nom de l'exploitation (dans settings) côté serveur - déclenché aprés un succès total/partiel de l'enregistrement des parcelles
    ///////////////////////////////////////////////////////////////////////////
    saveParcellaireNameToServer() {
        const { settings } = this.props;
        const { name } = this.state;

        // Lance l'action de mise à jour du paramétrage:
        if (this.props.updateSettings) {
            const settingsToUpdate = Object.assign({}, settings, {
                nameExploitation: name,
            });
            this.props.updateSettings(settingsToUpdate);
        }
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant d'annuler l'import - retour au formulaire de départ - effacement de données
    ///////////////////////////////////////////////////////////////////////////
    handleCancelImport() {
        this.default = this.getDefaultParams(undefined);// forcera les propriétés par défaut, MAIS en prenant en compte le settings actuel (équivalente à la BdD)
        this.setState(this.default);
    }

    /**
     * fonction cycle de vie react.js
     */
    componentDidUpdate(prevProps) {

        // ↓ CAS: CHANGEMENT du nom du parcellaire suite à suppression du parcellaire ↓ 
        if (prevProps.nameExploitation !== this.props.nameExploitation) {
            this.setState({ name: (this.props.nameExploitation && this.props.nameExploitation !== '') ? this.props.nameExploitation : `${StringTranslate.exploitation}` });
        }
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction de rendu visuel - layout général
    ///////////////////////////////////////////////////////////////////////////
    render() {
        const { activeStep, radioType, provenance, onSubmit, messageError, loadingFiles } = this.state; //états en cours     
        const { isDidacticiel, goToMap, showProfilMenuDialog } = this.props;

        return (
            <MainCard title={(!isDidacticiel) ? `${StringTranslate.importStep2}` : false}>
                {/* partie contenu en rapport avec l'étape en question*/}
                {this.getStepContent(activeStep)}

                <CardActions>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {(messageError) && <Typography variant="body2" color="error">{messageError}</Typography>}
                        </Grid>
                        {(activeStep !== 1) && <Grid item xs={12}>
                            <label htmlFor="raised-button-file">
                                <Button
                                    sx={{
                                        textTransform: "none",
                                    }}
                                    component="span"
                                    variant="contained"
                                    color="primary"
                                    startIcon={(loadingFiles) ? <CircularProgress color="inherit" size={20} /> : <CloudUpload />}
                                    onClick={this.handleChange}
                                    disabled={!radioType || loadingFiles}
                                >
                                    {`${StringTranslate.parcellesImportBtnOnSidebar}`}
                                </Button>
                            </label>
                            {(provenance !== undefined) &&
                                (
                                    <input
                                        accept={(radioType === typeFile.zip) ? "application/zip" : ".shp,.dbf,.prj,.shx,.cpg"}
                                        id="raised-button-file"
                                        name="raised-button-file"
                                        type="file"
                                        className="input"
                                        value=''
                                        multiple
                                        onChange={(event) => this.onInputFileChange(event)} //l'action est lancé lors de la sélection des fichiers
                                    />
                                )}
                        </Grid>}

                        {(activeStep === 1) &&
                            <Grid item xs={12}>
                                {/* partie - boutons de contrôle (ANNULATION, ENREGISTREMENT des parcelles sélectionnées) */}
                                <Button sx={{ mr: 1 }} variant="text" component="span" color="error" onClick={this.handleCancelImport} disabled={onSubmit}>
                                    {`${StringTranslate.annulerimport}`}
                                </Button>
                                <Button variant="contained" component="span" color="primary" onClick={this.handleSubmit} disabled={onSubmit}>
                                    {`${StringTranslate.enregistrerparcelles}`}
                                </Button>

                                {(onSubmit) && <CircularProgress size={20} />}
                            </Grid>}
                        <Grid item xs={12}>
                            {/* on affiche le component HelpsForImportParcels quand on n'est pas dans le didacticiel First Parcel */}
                            {(isDidacticiel !== true) && (<HelpsForImportParcels goToMap={goToMap} showProfilMenuDialog={showProfilMenuDialog}></HelpsForImportParcels>)}
                        </Grid>
                    </Grid>

                </CardActions>
            </MainCard>
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    //Infos provenant du reducer 'parcels':
    parcelDico: state.parcelsData.parcelDico,
    parcelDicoCounter: state.parcelsData.parcelDicoCounter,
    parcelIdFilteredList: state.parcelsData.parcelIdFilteredList,
    parcelIdFilteredListCounter: state.parcelsData.parcelIdFilteredListCounter,
    thumbnailParcelDico: (state && state.parcelsData) ? state.parcelsData.thumbnailParcelDico : {},
    updatingParcelName: (state && state.parcelsData) ? state.parcelsData.updatingParcelName : false,
    updatingParcelInfos: (state && state.parcelsData) ? state.parcelsData.updatingParcel : false,
   
    loading: state.parcelsData.loading,
    deleting: state.parcelsData.deleting,
    loadingImportedParcels: state.parcelsData.loadingImportedParcels,

    parcelTab: (state && state.parcelsData) ? state.parcelsData.parcelTab : 0,
    
    //Infos provenant du reducer 'context App':
    farmNameList : state.contextAppData.farmNameList,
    cropNameList : state.contextAppData.cropNameList,

    //Infos provenant du reducer 'settings':
    settings: state.settingsData.settings,
    nameExploitation: (state && state.settingsData && state.settingsData.settings) ? state.settingsData.settings.nameExploitation : '',
    codeCountry: (state && state.settingsData && state.settingsData.settings) ? state.settingsData.settings.codeCountry : '',
    rowsPerPageForTableParcels: (state && state.settingsData && state.settingsData.settings && state.settingsData.settings.rowsPerPageForTableParcels !== undefined) ? state.settingsData.settings.rowsPerPageForTableParcels : 20,
    
    //Infos provenant du reducer 'connection':
    token: (state && state.connectionData) ? state.connectionData.accessToken : undefined,

    //Infos provenant du reducer 'clientUser':
    clientId: (state && state.clientUserData && state.clientUserData.clientDatas) ? state.clientUserData.clientDatas.id : -1,
    enumTypoClient: (state && state.clientUserData && state.clientUserData.clientDatas) ? state.clientUserData.clientDatas.enumTypoClient : -1,
    maxParcelTypoClient: (state && state.clientUserData && state.clientUserData.clientDatas) ? state.clientUserData.clientDatas.maxParcelTypoClient : 3,
    authorizeHistoric: (state && state.clientUserData && state.clientUserData.clientDatas) ? state.clientUserData.clientDatas.authorizeHistoric : true,
    pvLinkActivated: (state && state.clientUserData && state.clientUserData.clientDatas) ? state.clientUserData.clientDatas.pvLinkActivated : false,

    //Infos provenant du reducer 'observation':
    observationIdListByParcelIdDico: state.observationsData.observationIdListByParcelIdDico,

    //Infos provenant du reducer 'didacticiel':
    isDidacticiel: (state.didacticielData && state.didacticielData.firstparcel) ? state.didacticielData.firstparcel.isOpened : false, 
    counterCompletedTutorial: (state.didacticielData && state.didacticielData.firstparcel) ? state.didacticielData.firstparcel.counterCompleted : 0,
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    updateSettings: (newSettingsValue) => dispatch(ActionSaveSettingsAsk(newSettingsValue)),
    updateNbRowsPerPageTableParcels: (rowsPerPage) => dispatch(UpdateNbRowsPerPageTable(rowsPerPage, TableType.parcels)),

    deleteParcels: () => dispatch(ActionDeleteParcelsAsk()),
    deleteParcelIdList: (parcelIdListToDelete) => dispatch( ActionDeleteParcelIdListToAPI( parcelIdListToDelete ) ),
    importParcels: (parcelListDisabledValue, parcelListEnabledValue) => dispatch(ActionImportParcelsAsk(parcelListDisabledValue, parcelListEnabledValue)),

    updateParcel: (parcelId, parcel) => dispatch(ActionSaveParcel(parcelId, parcel)),

    setParcelTab: (indexTab) => dispatch( ActionSetParcelTab(indexTab) ),

    goToMap: () => dispatch(ActionGoToMap()),
    goToMapAndSelectParcel: (parcelId) => dispatch(ActionGoToMapAndSelectParcel(parcelId)),
    subscribe: () => dispatch(ActionGoToProfil()),

    goToSuccessStep: () => dispatch(ActionGoToStepForDidacticielFirstParcel(StepEnumDidacticielFirstParcel.INDICE)),
    saveParcelListImportWhenDidacticielFirstParcel: (selectedParcelListToSave, unselectedParcelListToSave) => dispatch( ActionParcelListImportWhenDidacticielFirstParcel(selectedParcelListToSave, unselectedParcelListToSave) ),

    showProfilMenuDialog: (index) => dispatch( ActionShowProfilMenuDialog(index) ),
    
});

export default connect( mapStateToProps, mapDispatchToProps )(ImportParcels);
