import React from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import country from '../../../datas/country.json';
import { Box } from '@mui/material';

/**
 * Composant carte utilisé pour le didacticiel firstparcel
 */
class Map extends React.Component {

    /**
     * fonction cycle de vie
     */
    componentDidMount() {
        const { codeCountry } = this.props;

        // ↓ récupération du code pays pour zoomer sur le pays en question lors de la vue de la carte ↓
        try {
            this.latlng = country[codeCountry].geo.latlng;
            this.zoom = country[codeCountry].geo.zoom;
        } catch (error) {
            this.latlng = country['FR'].geo.latlng;
            this.zoom = country['FR'].geo.zoom;
        }

        // ↓ CREATION - carte munie de son paramètrage ↓
        this.map = L.map( 'backgroundmap',
            {
                crs: L.CRS.EPSG3857, //définition de la projection
                center: this.latlng,
                zoom: this.zoom,
                minZoom: 6, // en dessous de 7 => les layers Sentinel ne sont pas disponibles
                maxZoom: 18, // au dessus le rendu est flou
                zoomControl: false, // on n'affiche pas les boutons de zoom natifs
                attributionControl: false,
            }
        );

        //↓↓ CREATION ET INSERTION des différents layers de fond de carte ↓↓
        this.streetBaseMaps = L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {});
        this.satelliteBaseMaps = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', { attribution: 'Esri-visible' });
        this.placesBaseMaps = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}', {});
        this.map.addLayer(this.satelliteBaseMaps);
        this.map.addLayer(this.placesBaseMaps);
    }

    /**
     * fonction cycle de vie 
     */
    render() {
        return  <Box 
                    sx={{
                        position:"relative",
                        display:"flex",
                        flex:"1",
                        width:"100%",
                        height:"100%"
                    }} 
                    id='backgroundmap'
                />
    }
}

export default Map;