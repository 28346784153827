import StringTranslate from '../assets/i18n/stringLanguage.jsx';
import AuthentErrorCode from './specificErrorAuthent.js';
import sendError from '../utils/errorService.js';
import { CreateNewGuid } from '../utils/dateHelper';


/*
    Constantes déinissant les différents appels entre les deux mondes (Web et Natif)
*/
// Appel émis par l'appli native vers l'appli Web:
/*
const mt1OnNativeAppEvent = 'Event_StartingOn_NativeApp'; 
const mt2ReturnDatasAuthent = 'Return_LoginPwd';
const mt3WakeUpEvent = 'Event_WakeUp';
const mt4UpdateDatasAuthent = 'Ask_Update_LoginPwd';
const mt5ReturnAuthentTokens = 'Return_Tokens';
const mt6AddTraceInsight = 'Add_Trace_Insight';
const mt7ReturnPurchaseResult = 'Return_Purchase_Result';
const mt8StopPlanStore = 'Stop_PlanStore';
const mt9ControlPlanStore = 'Control_PlanStore';
*///Là, juste pour mémo!
// Appel émis par l'appli Web vers l'appli native:
const mt11GetDatasAuthent = 'Ask_LoginPwd';
const mt12AuthenticatedEvent = 'Event_Authenticated';
const mt13UpdateAppEvent = 'Event_Update_NativeApp';
const mt14DisconnectEvent = 'Event_Disconnect';
const mt15ChangePwdEvent = 'Event_Change_Pwd';
const mt16AuthentFailedEvent = 'Event_AuthentFailed’';
const mt17GetRefreshWebApp = 'Ask_Refresh_WebApp';
const mt18ClearWakeUpDatas = 'Ask_Clear_WebAppDatas';
const mt19ForceNewDatasAuthent = 'Ask_New_LoginPwd';
const mt20ChangeLanguage = 'Event_Update_Language';
const mt21ReinitPwdEvent = 'Event_Reinit_Pwd';
const mt22CloseAppEvent = 'Event_CloseApp';
const mt23ClientPZfundEvent = 'Event_ClientPzFund';
const mt24ShowThisUrlEvent = 'Event_ShowThisUrl';
const mt25AskPurchaseByStore = 'Ask_Purchase_ByStore';
const mt26ClientUpgraded = 'Event_Client_Upgraded';
const mt27RemoveClientandDatas = 'Event_Remove_ClientDatas';


/*
    Objet permettant de faire le lien avec l'application native.
*/
export class CommProviderWithNativeApp {
    constructor(userAgentAuthent, callBackActionsFct) {
        this.userAgentAuthent = userAgentAuthent;
        this.callBackActionsFunction = callBackActionsFct;

        //this.counterOfCall = 0; //RQ: on enverra sous cette forme: '00000';
        this.lastMessageGuidCall = 0;
        this.onMessageFromNativeApp = this.onMessageFromNativeApp.bind(this);  

        // ↓ Mise en place Listener - message provenant de l'application native ↓
        /*document.addEventListener('message', (event) => {
            if ((!event) || (!event.data)) return;

            try {
                let message = JSON.parse(event.data);
                this.onMessageFromNativeApp(message);
            } catch (error) {}
        });*/ //ne semble pas fonctionner avec le simulateur d'hébergement par l'appli native !
        window.addEventListener('message', (event) => {
            if ((!event) || (!event.data)) return;

            try {
                if ((typeof(event.data) === 'string') && (event.data !== '')) {
                    let message = JSON.parse(event.data);
                    if (this.lastMessageGuidCall !== message.guidCall) {
                        this.lastMessageGuidCall = message.guidCall;
                        this.onMessageFromNativeApp(message);
                    } else { //Cas d'un message déjà traité !
                        //this.sendAlertToNativeApp(`event listener of 'message' : this.lastMessageGuidCall === message.guidCall !`);
                        //console.log(`event listener of 'message' : this.lastMessageGuidCall === message.guidCall !`);
                    }
                } /*else { //Evénement qui n'est pas lié à la comm entre les deux applis (native et web)
                    this.sendAlertToNativeApp(`event listener of 'message' : event.data is void!`);
                }*/
            } catch (error) { // Peut arriver si on traite un événement non lié à la comm entre les deux applis (native et web)
                //this.sendAlertToNativeApp(`event listener of 'message' : catch exception... ${error} (event.data="${event.data}")`);
                //console.log(`event listener of 'message' : catch exception... ${error} (event.data="${event.data}")`);
            }
        });

        //juste pour test:
        document.addEventListener('message', (event) => {
            if ((!event) || (!event.data)) return;
            /*//Pour du test / débogage:
            let message = 'event is undefined........';
            let msgDataOk = false;
            if (event) {
                if (event.data) {
                    message = `event.data - ${event.data}`;
                    msgDataOk = true;
                } else {
                    message = `event - ${JSON.stringify(event)}`;
                }
            }                

            //this.sendAlertToNativeApp(`DOCUMENT event listener of 'message' received this: "${message}"`);
            console.log(`DOCUMENT event listener of 'message' received this: "${message}"`);
            */

            //if (msgDataOk === true) {
                try {
                    if ((typeof(event.data) === 'string') && (event.data !== '')) {
                        let message = JSON.parse(event.data);
                        if (this.lastMessageGuidCall !== message.guidCall) {
                            this.lastMessageGuidCall = message.guidCall;
                            this.onMessageFromNativeApp(message);
                        } else { //Cas d'un message déjà traité !
                            //this.sendAlertToNativeApp(`DOCUMENT event listener of 'message' : this.lastMessageGuidCall === message.guidCall !`);
                            //console.log(`DOCUMENT event listener of 'message' : this.lastMessageGuidCall === message.guidCall !`);
                        }
                    } /*else { //Evénement qui n'est pas lié à la comm entre les deux applis (native et web)
                        this.sendAlertToNativeApp(`DOCUMENT event listener of 'message' : event.data is void!`);
                    }*/
                } catch (error) { // Peut arriver si on traite un événement non lié à la comm entre les deux applis (native et web)
                    //this.sendAlertToNativeApp(`DOCUMENT event listener of 'message' : catch exception... ${error} (event.data="${event.data}")`);
                    //console.log(`DOCUMENT event listener of 'message' : catch exception... ${error} (event.data="${event.data}")`);
                }
            //}
        });
    }

    getNextGuidOfCall() {
        return CreateNewGuid();
    }

    /* Méthode qui ne doit pas être appelé par l'extérieur ! */
    onMessageFromNativeApp(messageValue) {
        //Pour test / débogague:
        //this.sendAlertToNativeApp(`onMessageFromNativeApp(${messageValue})`);
        //console.log(`onMessageFromNativeApp(${messageValue})`);
        
        if ((!messageValue) || (!messageValue.type) || (messageValue.type <= 0)) return;

        switch (messageValue.type) {
            case 1: { //mt1OnNativeAppEvent : l'application native signale qu'elle lance l'appli web (dans sa webview)
                //on demande alors l'initialisation du processus d'authent (en liaison avec l'appli native et non grâce à MSal):
                this.userAgentAuthent.initAuthentProcess(messageValue.datas);
                break;
            }

            case 2: { //mt2ReturnDatasAuthent : l'application native retourne les données d'authentification:
                this.userAgentAuthent.startAuthentProcess(messageValue.datas);
                break;
            }

            case 3: { //mt3WakeUpEvent : l'application native signale qu'elle revient au premier plan du mobile:
                this.userAgentAuthent.checkWebApplication(messageValue.datas);
                break;
            }

            case 4: { //mt4UpdateDatasAuthent : l'application native signale qu'elle a modifié les données d'authentification:
                messageValue.datas.pwdChanged = true; //définit une variable pour indiquer qu'il faut actualiser le mot de passe utilisateur avant de poursuivre
                this.userAgentAuthent.startAuthentProcess(messageValue.datas);
                break;
            }

            case 5: { //mt5ReturnAuthentTokens : l'application native signale qu'elle a authentifié l'utilisateur du mobile (et nous fournis le jeton d'accès):
                this.userAgentAuthent.useAuthentTokensProcess(messageValue.datas);
                break;
            }

            case 6: { //mt6AddTraceInsight: l'application native demande à ce qu'une trace dans Azure Insight soit inscrite 
                //RQ: Pas trouvé de module fonctionnel/compatible avec le type de projet React Native !
                sendError('NativeApp - onMessageFromNativeApp', messageValue.datas);
                break;
            }

            case 7: { //mt7ReturnPurchaseResult: l'application native nous donne un retour sur l'achat qui a été déclenché 
                if (this.callBackActionsFunction) {
                    this.callBackActionsFunction(messageValue);
                }
                break;
            }

            case 8: { //mt8StopPlanStore: l'application native nous signale l'arrêt d'un plan du store (ou l'erreur d'achat depuis le store)
                if (this.callBackActionsFunction) {
                    this.callBackActionsFunction(messageValue);
                }
                break;
            }

            case 9: { //mt9ControlPlanStore: l'application native nous signale une ou plusieurs souscription en cours ; Afin de vérifier la cohérence de typologie...
                if (this.callBackActionsFunction) {
                    this.callBackActionsFunction(messageValue);
                }
                break;
            }

            default: break;
        }
    }

    /* Méthode qui ne doit pas être appelé par l'extérieur ! */
    sendMessageToNativeApp(messageValue) {
        if ((!messageValue) || (!messageValue.type) || (messageValue.type <= 0) ||
            (!window.ReactNativeWebView)) {
            //throw new Error(`${StringTranslate.commNativeWithoutWebView}`); //On déclenche une erreur car on n'aurai pas dû pouvoir initialiser cette classe !        
            //Trace Azure:
            sendError('NativeApp - sendMessageToNativeApp', { "alert": `${StringTranslate.commNativeWithoutWebView}`, });
        } else {
            const msgStr = JSON.stringify(messageValue);
            window.ReactNativeWebView.postMessage(msgStr);
        }
    }

    //méthode pour le déboguage: Pour l'envoie d'une trace dans la console de l'appli native !
    sendAlertToNativeApp(alertValue) {
        const messageToSend = {
            type: 100,
            label: alertValue,
            guidCall: this.getNextGuidOfCall(),
            //datas: undefined,
        }

        //Trace Azure:
        sendError('NativeApp - sendAlertToNativeApp', { "alert": alertValue, });

        this.sendMessageToNativeApp(messageToSend);
    }

    ///// Méthodes émises par le natif ou pour faire appel à ce dernier. /////
    //Demande au natif de fournir les données nécessaire à l'authentification
    askDatasAuthent() { 
        const messageToSend = {
            type: 11,
            label: mt11GetDatasAuthent,
            guidCall: this.getNextGuidOfCall(),
            //datas: undefined,
        }

        this.sendMessageToNativeApp(messageToSend);
    }

    //Notifie l'appli native que l'authentification a réussi. 
    notifyClientAuthenticated(dateExpireValue) {
        let dateStr = dateExpireValue;
        try {
            if (dateExpireValue instanceof Date) {
                dateStr = dateExpireValue.toDateString();
            } else if (typeof(dateExpireValue) !== 'string') {
                dateStr = dateExpireValue.toString();
            } //else //'dateStr' vaut déjà 'dateExpireValue' qui est déjà une chaîne !
        }
        catch (err) {
            dateStr = dateExpireValue;
        }

        const messageToSend = {
            type: 12,
            label: mt12AuthenticatedEvent,
            guidCall: this.getNextGuidOfCall(),
            datas: {
                dateExpire: dateStr,
            },
        }

        this.sendMessageToNativeApp(messageToSend);
    }

    //Notifie l'appli native que son utilisateur doit mettre à jour l'application
    notifyNeedUpdateNativeApp(minVersionValue) { 
        let datasTosend = undefined;
        if (minVersionValue) {
            datasTosend = { 
                ...minVersionValue,
                displayVersion: (minVersionValue.mincompNativRel) ? 
                    `${minVersionValue.mincompNativMaj}.${minVersionValue.mincompNativMin}.${minVersionValue.mincompNativRel}` : 
                    `${minVersionValue.mincompNativMaj}.${minVersionValue.mincompNativMin}.x`
            };
        }
        const messageToSend = {
            type: 13,
            label: mt13UpdateAppEvent,
            guidCall: this.getNextGuidOfCall(),
            datas: datasTosend,
        }

        this.sendMessageToNativeApp(messageToSend);
    }

    //Notifie l'appli native que l'utilisateur cherche à se déconnecter
    // (depuis l'application native, cela corresponds plus à une demande de déconnexion pour se connecter à un autre compte)
    notifyDisconnection() {
        const messageToSend = {
            type: 14,
            label: mt14DisconnectEvent,
            guidCall: this.getNextGuidOfCall(),
            //datas: undefined,
        }

        this.sendMessageToNativeApp(messageToSend);
    }

    //Demande au natif de présenter la saisie de changement des données nécessaire à l'authentification (= réinitialisation du mot de passe)
    askChangeDatasAuthent() {
        const messageToSend = {
            type: 15,
            label: mt15ChangePwdEvent,
            guidCall: this.getNextGuidOfCall(),
            //datas: undefined,
        }

        this.sendMessageToNativeApp(messageToSend);
    }

    //Notifie l'appli native que l'authentification a échoué. 
    // (Potentiellement, par ce que le mot de passe a changé. Il est nécessaire de redemander les infos d'authentification auprès de l'utilisateur
    notifyAuthentFailed(errorMessageValue, errorCodeValue = AuthentErrorCode.DefaultFailed) {
        const messageToSend = {
            type: 16,
            label: mt16AuthentFailedEvent,
            guidCall: this.getNextGuidOfCall(),
            datas: {
                code: errorCodeValue,
                message: errorMessageValue,
            },
        }

        //Trace Azure:
        sendError('NativeApp - errorMessageValue', messageToSend.datas);

        this.sendMessageToNativeApp(messageToSend);
    }
    notifyAuthentVoidData(errorMessageValue) {
        const messageToSend = {
            type: 16,
            label: mt16AuthentFailedEvent,
            guidCall: this.getNextGuidOfCall(),
            datas: {
                code: AuthentErrorCode.NoLoginPwd,
                message: errorMessageValue,
            },
        }

        //Trace Azure:
        sendError('NativeApp - notifyAuthentVoidData', messageToSend.datas);

        this.sendMessageToNativeApp(messageToSend);
    }

    //Notifie l'appli native que l'appli web doit être rechargée. 
    askRefreshWebView() {
        const messageToSend = {
            type: 17,
            label: mt17GetRefreshWebApp,
            guidCall: this.getNextGuidOfCall(),
            //datas: undefined,
        }

        this.sendMessageToNativeApp(messageToSend);
    }

    //Notifie l'appli native que l'appli web vient de recharger les données (suite à une sortie de mise en veille). 
    askClearWakeUpDatas() {
        const messageToSend = {
            type: 18,
            label: mt18ClearWakeUpDatas,
            guidCall: this.getNextGuidOfCall(),
            //datas: undefined,
        }

        this.sendMessageToNativeApp(messageToSend);
    }

    //Demande au natif de présenter la saisie du login et mot de passe (pour en changer / corriger, si l'authent a été invalidé)
    askForceNewDatasAuthent(reasonMessageValue) { 
        const messageToSend = {
            type: 19,
            label: mt19ForceNewDatasAuthent,
            guidCall: this.getNextGuidOfCall(),
            datas: {
                message: reasonMessageValue,
            },
        }

        this.sendMessageToNativeApp(messageToSend);
    }

    //Notifie au natif que le client vient de changer de langue d'affichage (ou suite à la récup de son param&trage stocké en BdD)
    notifyChangeLanguage() { 
        const messageToSend = {
            type: 20,
            label: mt20ChangeLanguage,
            guidCall: this.getNextGuidOfCall(),
            datas: {
                language: StringTranslate.getLanguage(),
            },
        }

        this.sendMessageToNativeApp(messageToSend);
    }

    //Notifie au natif que le client souhaite qu'on lui ré-initialise son mot de passe 
    // (le natif se chargera alors de lancer la page web dans le navigateur du mobile)
    notifyReinitDatasAuthent(authorityResetValue) { 
        const messageToSend = {
            type: 21,
            label: mt21ReinitPwdEvent,
            guidCall: this.getNextGuidOfCall(),
            datas: {
                authority: authorityResetValue,
            },
        }

        this.sendMessageToNativeApp(messageToSend);
    }

    //Notifie l'appli native que l'utilisateur cherche à fermer l'application
    // (depuis l'application native, cela corresponds plus à une demande d'arrêt de l'application)
    notifyCloseApp() {
        const messageToSend = {
            type: 22,
            label: mt22CloseAppEvent,
            guidCall: this.getNextGuidOfCall(),
            //datas: undefined,
        }

        this.sendMessageToNativeApp(messageToSend);
    }

    //Notifie l'appli native que l'utilisateur a été retrouvé en tant que client Spotifarm en BdD (voir créé)
    // (depuis l'application native, cela sert à relier ce compte avec le mobile inscrit auprès de Firebase et OneSignal pour les notifications PUSH)
    notifySpotifarmClientInfos(emailValue, clientIdValue) {
        const messageToSend = {
            type: 23,
            label: mt23ClientPZfundEvent,
            guidCall: this.getNextGuidOfCall(),
            datas: {
                email: emailValue,
                clientId: clientIdValue,
            },
        }

        this.sendMessageToNativeApp(messageToSend);
    }

    //Notifie l'appli native que l'on souhaite lancer cette URL
    askShowUrl(urlToShowValue) {
        const messageToSend = {
            type: 24,
            label: mt24ShowThisUrlEvent,
            guidCall: this.getNextGuidOfCall(),
            datas: urlToShowValue,
        }

        this.sendMessageToNativeApp(messageToSend);
    }

    //Demande l'achat depuis le Store en correspondance avec l'OS du mobile
    askPurchaseStorePlan(storePlanIdByOsValue) {
        const messageToSend = {
            type: 25,
            label: mt25AskPurchaseByStore,
            guidCall: this.getNextGuidOfCall(),
            datas: storePlanIdByOsValue, //contient le SKU de Apple et celui de Android !
        }

        this.sendMessageToNativeApp(messageToSend);
    }

    //Confirme auprès du mobile (et donc du Store associé) que le client a bien eu les droits octroyés (suite à l'achat réussie).
    notifyClientUpgradedWithPurchase(purchaseDatasValue) {
        const messageToSend = {
            type: 26,
            label: mt26ClientUpgraded,
            guidCall: this.getNextGuidOfCall(),
            datas: purchaseDatasValue,
        }

        this.sendMessageToNativeApp(messageToSend);
    }

    //Appel le mobile après avoir tout 'virer' côté BdD / Compte de stockage.
    notifyClientToRemove() { 
        const messageToSend = {
            type: 27,
            label: mt27RemoveClientandDatas,
            guidCall: this.getNextGuidOfCall(),
            datas: undefined,
        }

        this.sendMessageToNativeApp(messageToSend);
    }
}

export default CommProviderWithNativeApp;
