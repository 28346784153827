import React, { useState, useCallback, useRef } from 'react';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import ButtonGeolocation from './ButtonGeolocation.jsx';
import debounce from 'lodash/debounce';

import { 
    Paper, InputBase, Divider, IconButton, 
    Button, List, ListItemButton, ListItemIcon, 
    ListItemText, Box, Alert
        } from '@mui/material';
        
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';
import Close from '@mui/icons-material/Close';
import MapIcon from '@mui/icons-material/Map';

import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';
import '../../../assets/css/dfp-didacticielFirstParcel.css';

/**
 * Composant permettant de rechercher une localité - rendu: barre de recherche
 */
function PlaceSearchButton(props) {

    const provider = new OpenStreetMapProvider({ params: { country: '', countrycodes: [''] } });

    /* ↓ fonction liée à la recherche d'endroits via l'api OpenStreetMapProvider ↓ */
    const findLocation = (query) => {
        if (query && (query !== '')) {
            provider.search({ query: query })
                .then((resultList) => setResultList(resultList))
                .catch((error) => setResultList([]))
        }
        else {
            setResultList([])
        }
    }

    /* ↓ fonctionnel autour de la valeur du champ de recherche ↓*/
    const [query, setQuery] = useState('');
    const [textfieldHasBeenTouched, setTexfieldHasBeenTouched] = useState(false); // variable permettant de savoir que l'utilisateur a au minimun utilisé la zone de saisie
    const debounceFindLocation = useRef(debounce(query => findLocation(query), 500)); // fonction de rebond
    const handleChangeQuery = useCallback((event) => {
        if (textfieldHasBeenTouched === false)
            setTexfieldHasBeenTouched(true); // l'utilisateur a utilisé la zone de saisie au moins 1 fois
        setQuery(event.target.value);
        debounceFindLocation.current(event.target.value);
    }, [textfieldHasBeenTouched]);

    /* ↓ fonctionnel autour des résultats de recherche  ↓*/
    const [resultList, setResultList] = useState([]);
    const onClickResult = useCallback(result => event => {
        try {
            setResultList([]);
            setQuery('');
            props.renderMapsCmp.map.fitBounds(result.bounds);
            if (props.setSubStepDessin && props.subStepDessinEnum) {
                props.setSubStepDessin(props.subStepDessinEnum.DESSIN);
            }
        } catch (error) { }
    }, [props]);

    /* fonctionnel autour de la zone d'annulation de recherche */
    const onClickCancel = useCallback((event) => {
        event.preventDefault();
        if (props.setSubStepDessin && props.subStepDessinEnum) {
            props.setSubStepDessin(props.subStepDessinEnum.DESSIN);
        }
    }, [props]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: {xs:270, sm:400, md:400},
                marginTop: '10px',
                pointerEvents: 'all',
                marginRight: {xs:'42px', sm:'0'},
                
            }}
        >

            {/* ↓ Champ de recherche ↓ */}
            <Paper sx={{ display:'flex' }}>
           
                <InputBase
                    sx={{marginLeft: 2, flex: 1}}
                    placeholder={StringTranslate.searchcity}
                    inputProps={{ 'aria-label': 'Recherche de votre ville' }}
                    value={query}
                    onChange={handleChangeQuery}
                    autoFocus={(props.autoFocus) ? true : false}
                />
                <IconButton type="submit" aria-label="search">
                    <SearchIcon />
                </IconButton>
                {(props.showIconButtonCancel) && (
                    <Button 
                        aria-label="close" 
                        onClick={onClickCancel} 
                        color="error"
                        sx={{
                            borderTopLeftRadius:"0px",
                            borderBottomLeftRadius:"0px",
                        }}
                    >
                        <Close />
                    </Button>
                )}
                {(!props.hideGeolocationButton) && (
                    <React.Fragment>
                        <Divider 
                            sx={{height: '28px', margin:'4px'}}
                            orientation="vertical" />
                        <ButtonGeolocation {...props} />
                    </React.Fragment>
                )}
            </Paper>

            {/* ↓ Résultat ↓ */}
            {(resultList && (resultList.length > 0)) && (
                <Paper 
                    sx={{
                        marginTop:'2px',
                        maxHeight:'200px',
                        overflowY:"scroll"
                    }}
                >
                    <List dense={true}>
                        {resultList.map((result, index) => (
                            <ListItemButton onClick={onClickResult(result)} dense={true} key={index}>
                                <ListItemIcon>
                                    <LocationOnIcon />
                                </ListItemIcon>
                                <ListItemText primary={result.label} />
                            </ListItemButton>
                        ))}

                    </List>
                </Paper>
            )}

            {/* ↓ Zone d'annulation de recherche - n'apparait actuellement que dans la sous étape de recherche de localité ↓ */}
            {(props.showTextForCancel && textfieldHasBeenTouched) && (
                <Alert severity="info"
                    action={
                        <Button variant="outlined" color="inherit" size="small" onClick={onClickCancel}>
                            <MapIcon />
                        </Button>
                    }>
                    {StringTranslate.dessinzoominsidemap}
                </Alert>
            )}
        </Box>
    )
}

export default PlaceSearchButton;