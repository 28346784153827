import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import getTheme from '../../../themes/index';

let theme = getTheme();


function IconeObservationCarence(props: SvgIconProps) {
    return ( 
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" strokeWidth="1.8" stroke="none" fill="current">
              <g>
                <path d="M7,18.56c-.24-.35-.91-1.37-1.06-1.67a11.27,11.27,0,1,1,21.29-5.15A11.18,11.18,0,0,1,26,16.89c-.15.3-.82,1.32-1.07,1.69L16,31.17Z" fill={theme.palette.primary.main}/>
                <path d="M16,1a10.78,10.78,0,0,1,9.59,15.68c-.13.24-.68,1.1-1,1.61L16,30.3l-8.54-12c-.36-.54-.91-1.4-1-1.64A10.78,10.78,0,0,1,16,1m0-1A11.78,11.78,0,0,0,5.53,17.12c.21.39,1.1,1.73,1.1,1.73L16,32l9.38-13.18s.89-1.34,1.1-1.73A11.78,11.78,0,0,0,16,0Z" fill={theme.palette.primary.main}/>
              </g>
              <g>
                <path d="M14.56,19.2V7.78c0-3.88,5.12-4.05,5.12,0V10" fill="none" stroke={theme.palette.common.white} strokeMiterlimit="10" strokeWidth="0.8957952000000005px"/>
                <circle cx="19.68" cy="11.64" r="1.61" fill="none" stroke={theme.palette.common.white} strokeMiterlimit="10" strokeWidth="0.8957952000000005px"/>
                <ellipse cx="19.68" cy="15.11" rx="0.66" ry="1.86"  fill={theme.palette.common.white}/>
                <ellipse cx="18.28" cy="14.2" rx="0.66" ry="1.86"  fill={theme.palette.common.white}/>
                <ellipse cx="17.54" cy="12.67" rx="1.86" ry="0.66" transform="translate(-0.78 24.19) rotate(-68.02)"  fill={theme.palette.common.white}/>
                <ellipse cx="21.91" cy="12.67" rx="0.66" ry="1.86" transform="translate(-3.15 9.12) rotate(-21.98)"  fill={theme.palette.common.white}/>
                <ellipse cx="21.17" cy="14.28" rx="0.66" ry="1.86"  fill={theme.palette.common.white}/>
                <path d="M14.48,14.57S11.59,13.91,11,12a25.49,25.49,0,0,1-.74-3s2.4.08,3.3,1.82A9,9,0,0,1,14.48,14.57Z" fill="none" stroke={theme.palette.common.white} strokeMiterlimit="10" strokeWidth="0.8957952000000005px"/>
              </g>
            </svg>
        </SvgIcon>
    );
}

export default IconeObservationCarence;