import React, { Component } from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

/* MUI Components */
import { Box, Stepper, Step, StepLabel } from '@mui/material';

/* React Components */
import StartModelisation from './startModelisation.jsx';
import ModelisationCropChoice from './modelisationCropChoice';
import ModelisationParcelChoice from './modelisationParcelChoice.jsx';
import ModelisationSpecificInfosManagement from './modelisationSpecificInfosManagement.jsx';
import ModelisationResultPage from './modelisationResultPage.jsx';

import StringTranslate from '../../assets/i18n/stringLanguage';

/* Redux */
import { ModelisationSteps } from '../../redux/actions/modelisation';

/* theme */
import getTheme from '../../themes/index.js';


const theme = getTheme();

class ModelisationStepsManagement extends Component {

    /**
     * Méthode permettant de récupérer les libéllés des différentes étapes
     */
    getSteps = () => [
        `${StringTranslate.modelisationStep0Culture}`,
        `${StringTranslate.modelisationStep1Parcelle}`, 
        `${StringTranslate.modelisationStep2SaisieTableauRecapitulatif}`, 
        `${StringTranslate.modelisationStep3Résultat}`
    ]

    /*  Step 0 : Choix de la culture de modéliasation
        Step 1 : Choix des parcelles
        Step 2 : Saisie 
        Step 3 : Visualisation du l'init */

    getStep = () => {
        const { modelisationStep } = this.props;

        switch (modelisationStep) {

            case ModelisationSteps.CROP_CHOICE: return 0;
            case ModelisationSteps.PARCELS_CHOICE: return 1;
            case ModelisationSteps.MANAGEMENT_MODELISATION: return 2;
            case ModelisationSteps.RESULT: return 3;
            default: return 0;

        }

    };

    render() {
        const { modelisationStep } = this.props;

        return (
            <Box sx={{ width: '100%' }}>
                {
                    ((modelisationStep !== ModelisationSteps.WELCOME_START_MODELISATION) ) && 
                    <Stepper 
                        activeStep={this.getStep()} 
                        alternativeLabel={window.innerWidth < 456}
                        sx={{
                            [theme.breakpoints.up('md')]:{
                                width:"60%", ml:"20%"
                            }
                        }}
                        >
                        {this.getSteps().map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
    
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                }
    
                <Box sx={{mt: 3}}>
                    {(modelisationStep === ModelisationSteps.WELCOME_START_MODELISATION) && <StartModelisation/>}
                    {(modelisationStep === ModelisationSteps.CROP_CHOICE) && <ModelisationCropChoice/>}
                    {(modelisationStep === ModelisationSteps.PARCELS_CHOICE) && <ModelisationParcelChoice/>}
                    {(modelisationStep === ModelisationSteps.MANAGEMENT_MODELISATION) && <ModelisationSpecificInfosManagement/>}
                    {(modelisationStep === ModelisationSteps.RESULT) && <ModelisationResultPage/>}
                </Box>
    
            </Box>
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    modelisationStep: lodashGet(state, 'modelisationData.modelisationStep', ModelisationSteps.WELCOME_START_MODELISATION),

    language: lodashGet(state, 'settingsData.settings.language', StringTranslate.getLanguage()),
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(ModelisationStepsManagement);
