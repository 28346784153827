import StringTranslate from '../assets/i18n/stringLanguage.jsx';

/*
Définit les options de filtre sur la liste des parcelles:
*/

//Comparateur de surface de parcelle:
export const AreaComparatorAllCode = `All`;
export const AreaComparatorLowerCode = `LOWER`;
export const AreaComparatorUpperCode = `UPPER`;
export const AreaComparators = [{ code: AreaComparatorAllCode }, { code: AreaComparatorLowerCode }, { code: AreaComparatorUpperCode }]; 

export const generateLabelAreaComparator = () => {
    return [StringTranslate.labelAreaCompareAll, StringTranslate.labelAreaCompareLower, StringTranslate.labelAreaCompareUpper];
}
