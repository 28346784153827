import Parcel from '../models/parcel.js';
import Leaflet from 'leaflet';
import detailsConverter from './detailsConverter.js';
import DetailsParcel from '../models/detailsParcel.js';
import { Observation } from '../models/observation.js';
import StringTranslate from '../assets/i18n/stringLanguage.jsx';
import { ParcelOriginEnum } from '../redux/actions/parcels';

// finchier permettant d'exporter les fonctionnalités de conversion
const converter = {
    /**
     * données geojson vers données BDD 
     * @param  {JSON} geojson [description]
     */
    geojsonToDatabaseParcels(geojson, originEnum = null, origin = null, farmName = StringTranslate.exploitation) {
        let parcels = [];
        if ((!geojson) || (!geojson.features)) return parcels;

        Array.prototype.forEach.call(geojson.features, (parcelle, index) => { //lecture chaque parcelle dans Geojson

            if (parcelle && (parcelle.type === 'Feature') && parcelle.geometry && 
                ((parcelle.geometry.type === 'Polygon') || (parcelle.geometry.type === 'MultiPolygon')) && 
                (parcelle.geometry.coordinates) ) {

                // 1- ⇓⇓  création chaine BBox pour utilisation dans une URL pour accéder à l API sentinel hub ⇓⇓ 
                // ⇓⇓  Identification des nouvelles valeurs BBox dans la projection 3857 de lEfalet ⇓⇓ 
                var imageBounds = [];
                // 2- ⇓⇓ réation paramêtre de requête HTTP - GEOMETRY pour utilisation dans une URL pour accéder à l API sentinel hub ⇓⇓ 
                var coordinatesJson = "";
                var geometryURLstring = "POLYGON((";
                if ((parcelle.geometry.type === 'Polygon') && parcelle.geometry.coordinates[0]) {
                    coordinatesJson = JSON.stringify(parcelle.geometry.coordinates);

                    //⚠️- On utilise la projection/ conversion des coordonnées appliquées sur la carte
                    let bounds = Leaflet.polygon(parcelle.geometry.coordinates[0]).getBounds();
                    imageBounds = [
                        [(bounds.getSouthWest()).lng, (bounds.getSouthWest()).lat],
                        [(bounds.getNorthEast()).lng, (bounds.getNorthEast()).lat],
                    ];
                    
                    parcelle.geometry.coordinates[0].forEach((coordinate, index) => {
                        if (index !== 0) {
                            geometryURLstring += ",";
                        }
                        geometryURLstring += coordinate[1] + " " + coordinate[0];
                    });
                } else if (parcelle.geometry.coordinates[0] && parcelle.geometry.coordinates[0][0]) { // => 'MultiPolygon'
                    coordinatesJson = JSON.stringify(parcelle.geometry.coordinates[0]);

                    //⚠️- On utilise la projection/ conversion des coordonnées appliquées sur la carte
                    let bounds = Leaflet.polygon(parcelle.geometry.coordinates[0][0]).getBounds();
                    imageBounds = [
                        [(bounds.getSouthWest()).lng, (bounds.getSouthWest()).lat],
                        [(bounds.getNorthEast()).lng, (bounds.getNorthEast()).lat],
                    ];

                    parcelle.geometry.coordinates[0][0].forEach((coordinate, index) => {
                        if (index !== 0) {
                            geometryURLstring += ",";
                        }
                        geometryURLstring += coordinate[1] + " " + coordinate[0];
                    });
                }

                if (imageBounds && (imageBounds.length > 0)) {
                    var bboxURLstring = imageBounds.join(',');// utilisé comme paramêtre de requête HTTP - BBOX 
                    geometryURLstring += "))";

                    // 3- ⇓⇓ création entité ⇓⇓
                    var parcel = new Parcel({
                        id: 0,
                        clientId: 0,
                        farm: farmName,
                        name: detailsConverter.getParcelName(parcelle.properties, origin, index),
                        geometryType: 'Polygon', //parcelle.geometry.type,
                        coordinates: coordinatesJson,
                        properties: JSON.stringify(parcelle.properties),
                        details: parcelle.properties.pz_isDetailConverted ? parcelle.properties.pz_details : detailsConverter.convertPropertiesToDetails(parcelle.properties, origin),
                        isDetailConverted: true,
                        origin: (originEnum && (originEnum === ParcelOriginEnum.Drawn)) ? 'dessiné' : origin,  //`${StringTranslate.drawn}` //car pas à traduire....
                        enumOrigin: originEnum,
                        epsg: 4326,
                        xmin: imageBounds[0][1],
                        ymin: imageBounds[0][0],
                        xmax: imageBounds[1][1],
                        ymax: imageBounds[1][0],
                        bBoxStr4326: bboxURLstring,
                        polygonStr4326: geometryURLstring,
                        dateImport: (new Date()).toISOString(),
                        isActive: true
                    });

                    parcels.push(parcel);
                }
                //else //comment faire remonter l'erreur ?!
            }
        });
        return parcels;
    },

    /**
     * dFonction permettant de convertir un objet de type geojson vers un objet parcel utilisable par le serveur
     */
    geojsonLayerToDatabaseParcel(geojson, originEnum = null, origin = null, farmName = StringTranslate.exploitation) {
        if (!geojson) return;

        if (((geojson.type === 'Feature') || (geojson.type === 'feature')) && 
            ((geojson.geometry.type === 'Polygon') || (geojson.geometry.type === 'MultiPolygon')) && 
            (geojson.geometry.coordinates && geojson.geometry.coordinates[0]) ) {

            // 1- ⇓⇓  création chaine BBox pour utilisation dans une URL pour accéder à l API sentinel hub ⇓⇓ 
            // ⇓⇓  Identification des nouvelles valeurs BBox dans la projection 3857 de lEfalet ⇓⇓ 
            var imageBounds = [];
            // 2- ⇓⇓ réation paramêtre de requête HTTP - GEOMETRY pour utilisation dans une URL pour accéder à l API sentinel hub ⇓⇓ 
            var coordinatesJson = "";
            var geometryURLstring = "POLYGON((";
            if ((geojson.geometry.type === 'Polygon') && (geojson.geometry.coordinates[0])) {
                coordinatesJson = JSON.stringify(geojson.geometry.coordinates);
                
                //⚠️- On utilise la projection/ conversion des coordonnées appliquées sur la carte
                let bounds = Leaflet.polygon(geojson.geometry.coordinates[0]).getBounds();
                imageBounds = [
                    [(bounds.getSouthWest()).lng, (bounds.getSouthWest()).lat],
                    [(bounds.getNorthEast()).lng, (bounds.getNorthEast()).lat],
                ];

                geojson.geometry.coordinates[0].forEach((coordinate, index) => {
                    if (index !== 0) {
                        geometryURLstring += ",";
                    }
                    geometryURLstring += coordinate[1] + " " + coordinate[0];
                });
            } else if (geojson.geometry.coordinates[0] && geojson.geometry.coordinates[0][0]) { // => 'MultiPolygon'
                coordinatesJson = JSON.stringify(geojson.geometry.coordinates[0]);
                
                //⚠️- On utilise la projection/ conversion des coordonnées appliquées sur la carte
                let bounds = Leaflet.polygon(geojson.geometry.coordinates[0][0]).getBounds();
                imageBounds = [
                    [(bounds.getSouthWest()).lng, (bounds.getSouthWest()).lat],
                    [(bounds.getNorthEast()).lng, (bounds.getNorthEast()).lat],
                ];

                geojson.geometry.coordinates[0][0].forEach((coordinate, index) => {
                    if (index !== 0) {
                        geometryURLstring += ",";
                    }
                    geometryURLstring += coordinate[1] + " " + coordinate[0];
                });
            }

            if (imageBounds && (imageBounds.length > 0)) {
                var bboxURLstring = imageBounds.join(',');// utilisé comme paramêtre de requête HTTP - BBOX 
                geometryURLstring += "))";

                // 3- ⇓⇓ création entité ⇓⇓
                var parcel = new Parcel({
                    id: 0,
                    clientId: 0,
                    farm: farmName,
                    name: detailsConverter.getParcelName(geojson.properties, origin),
                    geometryType: 'Polygon', //geojson.geometry.type,
                    coordinates: coordinatesJson,
                    properties: JSON.stringify(geojson.properties),
                    details: geojson.properties.pz_isDetailConverted ? geojson.properties.pz_details : detailsConverter.convertPropertiesToDetails(geojson.properties, origin),
                    isDetailConverted: true,
                    origin: (originEnum && (originEnum === ParcelOriginEnum.Drawn)) ? 'dessiné' : origin, //`${StringTranslate.drawn}` //car pas à traduire....
                    enumOrigin: originEnum,
                    epsg: 4326,
                    xmin: imageBounds[0][1],
                    ymin: imageBounds[0][0],
                    xmax: imageBounds[1][1],
                    ymax: imageBounds[1][0],
                    bBoxStr4326: bboxURLstring,
                    polygonStr4326: geometryURLstring,
                    dateImport: (new Date()).toISOString(),
                    isActive: true
                });

                try { // calcul de la surface dans le cas du dessin d'une parcelle
                    parcel.details.surface = geojson.properties.pz_details.surface;
                } catch (error) {}

                return parcel;
            }
        }

        return null;
    },

    /**
     * données BDD Liste de Parcel vers données geojson (featureCollection)
     * @param  {JSON} datas [description]
     * L'idée va être de basculer d'objets parcels vers un format json
     */
    databaseParcelsTogeojson(parcels = null) {
        let featureCollection = {};

        featureCollection.type = "FeatureCollection";
        featureCollection.filename = "Custom";
        featureCollection.features = [];

        if (parcels) {//} && parcels.length > 0) {
            parcels.forEach(parcel => {
                featureCollection.features.push(this.databaseParcelTogeojson(parcel));
            });
        }

        return featureCollection;
    },

    /**
     * données objet Parcel vers données geojson (feature)
     * @param  {JSON} datas [description]
     * L'idée va être de basculer d'objets parcels vers un format json
     */
    databaseParcelTogeojson(parcel) {
        let feature = {};

        feature.type = 'Feature';
        feature.geometry = {};
        feature.geometry.bbox = [parcel.xmin, parcel.ymin, parcel.xmax, parcel.ymax];
        feature.geometry.type = parcel.geometryType;
        feature.geometry.coordinates = JSON.parse(parcel.coordinates);
        feature.properties = JSON.parse(parcel.properties);

        feature.properties.pz_id = parcel.id;
        feature.properties.pz_clientID = parcel.clientId;
        feature.properties.pz_farm = parcel.farm;
        feature.properties.pz_name = parcel.name;
        feature.properties.pz_dateImport = parcel.dateImport;
        feature.properties.pz_origin = parcel.origin;
        feature.properties.pz_enum_origin = parcel.enumOrigin;
        feature.properties.pz_epsg = parcel.epsg;
        feature.properties.pz_isActive = parcel.isActive;

        if (parcel.details) {
            feature.properties.pz_details = new DetailsParcel({
                campagne: parcel.details.campagne,
                culture: parcel.details.culture,
                culturePrecedente: parcel.details.culturePrecedente,
                dateSemi: parcel.details.dateSemi ? new Date(parcel.details.dateSemi) : null,
                exploitation: parcel.details.exploitation,
                surface: (parcel.area >= 0) ? parcel.area : parcel.details.surface,
                variete: parcel.details.variete,
            });
        } else if (parcel.area >= 0) {
            feature.properties.pz_details = new DetailsParcel({
                campagne: '',
                culture: '',
                culturePrecedente: '',
                dateSemi: null,
                exploitation: '',
                surface: parcel.area,
                variete: '',
            });
        }

        feature.properties.pz_isDetailConverted = parcel.isDetailConverted;

        return feature;
    },

    /**
     * données array issu du plugin shpjs vers données geojson 
     * Tout ce qui arrive ici doit être en 4326
     */
    arrayShapefileToGeojson(arr) {
        let out = {};

        out.type = 'FeatureCollection';
        out.features = [];
        out.filename = 'Custom';
        let i = 0;
        while (i < arr[0].length) {
            out.features.push({
                'type': 'Feature',
                'geometry': arr[0][i],
                'properties': ((arr[1] && arr[1][i]) ? arr[1][i] : {}) // 2 cas => fichier zip ou shp (pas de propriétés!)
            });
            i++;
        }

        return out;
    },

    /**
     * Fonction qui permet de formater une liste d'observation suite à un appel serveur en dico <observationId,observation> (utilisé dans redux)
     */
    observationListToDico(observationList) {
        if (!observationList || !Array.isArray(observationList))
            return {};

        let observationDico = observationList.reduce((objToBuild, obj) => {
            try {
                let observationToAdd = new Observation(obj);
                objToBuild[observationToAdd.id] = observationToAdd;
                return objToBuild;
            } catch (error) { };
            return objToBuild;
        }, {});

        return observationDico;
    },

    /**
     * Fonction qui permet de formater une liste de type d'observation en dico <observationTypeId,obj> (utilisé dans redux)
     */
    observationTypeListToDico(observationTypeList) {
        if (!Array.isArray(observationTypeList))
            return {};

        let dico = {};
        observationTypeList.forEach(observationType => {
            dico[observationType.id] = observationType;
        });

        return dico;
    }

};

export default converter;
