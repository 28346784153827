// Fichiers dans lesquels il faut remplacer la couleur à la main:
// C:\_Promize.src\Projects\SentinelV2\SpotiFarm-TdP-Correctifs\Sources\sentinelv2-app\src\index.css
//          ===> en hex et rgb
// C:\_Promize.src\Projects\SentinelV2\SpotiFarm-TdP-Correctifs\Sources\sentinelv2-app\public\index.html
//          ===> en rgb et rgba
// C:\_Promize.src\Projects\SentinelV2\SpotiFarm-TdP-Correctifs\Sources\sentinelv2-app\src\assets\css\observationArea.css
//          ===> en @@

// (optionnel, suivant la demande ou non du Mkt:
// 1/ Mettre à jour des fichiers HTML dans $/Isagri_Promize/Projects/SentinelV2/SpotiFarm-TdP-Correctifs/Utils/CompteStockageAzure/trad (fr/en/es)
//          ===> en changeant le nom de l'image Png utilisé en tant qu'icône de page : Icon-Spotifarm-XXXXX.png
//          ===> en changeant le nom de l'image Png utilisé en tant que titre Spotifarm (actuellement une image Png) : Fond-Login-Promize-XXXXX.png
//          RQ : s'il y a changement de nom(s), il faut ajouter le(s) nouveau(x) fichier(s) dans le répertoire qui convient ($/.../Utils/CompteStockageAzure/_$root ou $/.../Utils/CompteStockageAzure/trad si dépends de la langue)
//          A VOIR DEMAIN : Modifier les pages HTML pour définir le logo ainsi que le titre 'Spotifarm' en utilisant la même technique que dans l'appli Web ReactJs !
//          ATTENTION : Il faut aussi republier sous Azure (DEMO et PROD) les nouveaux fichiers ! Faire ceux de PROD que lors de la livraison officielle !
// 2/ Exploiter le fichier Icon-Spotifarm-XXXXX.png et le site @@ pour créer l'icône (format .ico).
//          ===> enregistrer le fichier dans TFS et le mettre à jour sous Azure également.
//          ===> dupliquer l'icône et l'enregistrer dans TFS ici : $/Isagri_Promize/Projects/SentinelV2/SpotiFarm-TdP-Correctifs/Sources/sentinelv2-app/public
//          RQ : Soit le fichier est à renommer pour 'faviconThMkt.ico' ; Soit il faut modifier le fichier manifest.json sous la clef 'icons' \ 'src'.
// 3/ Pour les Applications Mobile, le fichier Icon-Spotifarm-XXXXX.png est à référencer dans 
//          ===> dans le fichier app.json sous $/Isagri_Promize/Projects/SentinelV2/SpotiFarm-TdP-Correctifs/Sources/Sentinelv2_NativeApp, 
//          sous $/Isagri_Promize/Projects/SentinelV2/SpotiFarm-TdP-Correctifs/Sources/Sentinelv2_NativeApp/android/app/src/main/res/raw 
//          et sous $/Isagri_Promize/Projects/SentinelV2/SpotiFarm-TdP-Correctifs/Sources/Sentinelv2_NativeApp/ios/assets (!!! Idem pour l'application mobile DEMO !!!)
//          ===> dans le fichier authentPage.js sous $/Isagri_Promize/Projects/SentinelV2/SpotiFarm-TdP-Correctifs/Sources/Sentinelv2_NativeApp/src/components/pages (!!! Idem pour l'application mobile DEMO !!!)
//          ===> exploiter le fichier Icon-Spotifarm-XXXXX.png pour le mettre sous $/Isagri_Promize/Projects/SentinelV2/SpotiFarm-TdP-Correctifs/Sources/Sentinelv2_NativeApp/src/assets/images 
//          en le nommant satellite_spotifarm_couleur.png et aux dimensions 260 * 260 ! (!!! Idem pour l'application mobile DEMO !!!)


// Outils d'aide pour choisir 1/ une palette et 2/ les versions light et dark pour les couleurs primaire et secondaire:
// https://material.io/inline-tools/color/
// https://material.io/resources/color/#!/?view.left=1&view.right=0&primary.color=197620&secondary.color=2674dc


import { createTheme } from '@mui/material/styles';

import marketThemeJson from './theme.json';
import themeComponent from './themeComponent.js';
import themeTypography from './themeTypography.js';

const TheFontFamily = '"Roboto",sans-serif';

export const getTheme = () => {
    const marketTheme = createTheme(marketThemeJson);

    const themeComplet = createTheme ({
        breakpoints: marketTheme.breakpoints,
        direction: "ltr",
        palette: marketTheme.palette,
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '0px 8px 0px 0px',
                '@media (min-width: 600px)': {
                    minHeight: '48px'
                }
            }
        },
        components: themeComponent(marketTheme),
        typography: themeTypography(marketTheme, TheFontFamily),
        customShadows: marketTheme.customShadows,
        transitions: marketTheme.transitions,
        zIndex: marketTheme.zIndex,
    })
    
    return themeComplet;
}

export default getTheme;