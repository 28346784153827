import React from "react";

import { connect } from 'react-redux';
import {
    ActionShowProfilMenuDialog,
    ActionCloseProfilMenuDialog,
    ProfilIndex,
    TypoClientEnum,
} from "../../redux/actions/contextApp";
import { ActionChangePassword, ActionDisconnect, ActionRemoveClientAndDatas } from "../../redux/actions/connection"
import { ActionSaveSettingsAsk } from "../../redux/actions/settings"

import {
    List, IconButton, Divider, Dialog, Drawer, DialogContent, DialogContentText,
    DialogTitle, Collapse, Box, Tab, Tabs, Typography, Stack,
    Button, DialogActions, ListItemText, ListItemIcon, Paper, ListItemButton
} from "@mui/material";

import {
    ExpandLess, ExpandMore, SettingsOutlined,
    HelpOutline, PlayCircleOutline,
    ShareOutlined, WhatsApp,
    FavoriteBorderOutlined, InfoOutlined, FactCheckOutlined,
    ContactMailOutlined, ChevronLeft, Close,
    Balance, SyncAlt, PowerSettingsNew, LockReset, 
    PhoneIphone, PhoneAndroid, DesktopWindows,
    PersonRemove
} from '@mui/icons-material';
import CornIcon from '../../assets/images/harvest/cornIcon.tsx';
import ModelisationIcon from "../../assets/images/modelisation/ModelisationIcon.tsx";

import HomeExploitationIcon from '../../assets/homeExploitationIcon.tsx';

import EuroReportFactureIcon from '../../assets/euro-report-facture.tsx';
import LogoSpotifarm from '../../assets/logoSpotifarm/LogoSpotifarmTitreEtFeuille';
import IconeModulation from '../../assets/images/modulation/iconeModulation.tsx';
import SeedingIcon from '../../assets/seeding-logo.tsx';
import { IsNativeHoster } from '../../utils/platformHelper';

import StringTranslate from "../../assets/i18n/stringLanguage";
import lodashGet from 'lodash/get';

import MonExploit from "./monExploit";
import Parametres from "./parametres";
import Abonnements from "./abonnements";
import AboutUs from "./aboutUs";
import Factures from "./factures";

import '../../assets/css/profil.css';
import '../../index.css';
import getTheme from "../../themes";
import MainCard from "../subLayouts/mainCard";

/* React Icons */
import LogoSpotifarmSansFeuille from "../../assets/logoSpotifarm/LogoSpotifarmSansFeuille";
import FertilizationIcon from "../../assets/images/fertilization/fertilizationIcon.tsx";
import CustomIFrame from '../customIFrame.jsx';

let theme = getTheme();

// contenu du mailTo pour le partage en mode desktop
// const mailtoShare = "mailto:?subject=Je te conseille Spotifarm !&body=Voici un lien pour que tu test l'appli:%0D%0Ahttps://www.app.spotifarm.fr/%0D%0AOu vient voir notre site internet:%0D%0Ahttps://www.spotifarm.fr/"
const mailtoShare = "mailto:?subject=Je te conseille Spotifarm !&body=Bonjour,%0D Je te transmets ce lien https://www.app.spotifarm.fr/ pour que tu puisses tester la nouvelle application Spotifarm 🛰%0D Spotifarm te permet de surveiller tes parcelles grâce aux images satellites et tu peux également faire des cartes de modulations.%0D Si tu veux en savoir plus c’est par ici https://www.spotifarm.fr/ !%0D Cordialement%0D ";


//TODO : A revoir car on utilise pas ici le 'TabPanel'. Pourquoi ? comment cela fonctionne alors ?
function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

class renderProfil extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openProfilMenu: false,
            // TODO Comment mieux gérer ce cas?
            setOpenHelpQuestions: ((props.selectedIndexProfilMenu >= ProfilIndex.aide_IndiceVegetation) && (props.selectedIndexProfilMenu <= ProfilIndex.aide_PeseeDeBiomasse)),
            language: props.language,
            profilMenuSidebarStateMobile: true,
            openConfirmationLogOutDialog: false,

            /* pour l'affichage d'une confirmation de suppression de compte (et données associées) */
            displayConfirmRemoveAccount: false,

            /* pour l'affichage d'une confirmation de changement du mot de passe (compte local uniquement) */
            openConfirmationChangePwdDialog: false,
        }

        //Détecte si l'appli web est chargée dans l'appli mobile:
        this.isNativeAppHoster = IsNativeHoster();

        this.handleOpenProfilMenu = this.handleOpenProfilMenu.bind(this);
        this.handleCloseProfilMenu = this.handleCloseProfilMenu.bind(this);

        this.handleOpenConfirmationLogOutDialog = this.handleOpenConfirmationLogOutDialog.bind(this);
        this.handleCloseConfirmationLogOutDialog = this.handleCloseConfirmationLogOutDialog.bind(this);
        this.handleChangeLogout = this.handleChangeLogout.bind(this);

        this.handleMobileRemoveClientAndDatas = this.handleMobileRemoveClientAndDatas.bind(this);
        this.handleCancelRemoveAccount = this.handleCancelRemoveAccount.bind(this);
        this.handleValidRemoveAccount = this.handleValidRemoveAccount.bind(this);

        this.handleOpenConfirmationChangePwdDialog = this.handleOpenConfirmationChangePwdDialog.bind(this);
        this.handleCloseConfirmationChangePwdDialog = this.handleCloseConfirmationChangePwdDialog.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);

        this.fullScreen = (window && window.innerWidth && (window.innerWidth < theme.breakpoints.values.lg)) ? true : false;
    }   

    toggleProfilSidebarMobile = (stateDrawer) => {
        this.setState({ profilMenuSidebarStateMobile: stateDrawer })
    }

    chooseimgForMobile() {
        /*let cultureValue = StringTranslate.getLanguage();
        if ((!cultureValue) || (cultureValue.length < 2)) {
            cultureValue = 'fr';
        }
        const languageValue = cultureValue.substring(0, 2);*/ //plus nécessaire pour le choix du logo !

        return (
            <div>
                <LogoSpotifarmSansFeuille/>
            </div>
        )
    }

    /**
     * Logo spotifarm pour Dialog d'aide
     */
    chooseimg() {
        /*let cultureValue = StringTranslate.getLanguage();
        if ((!cultureValue) || (cultureValue.length < 2)) {
            cultureValue = 'fr';
        }
        const languageValue = cultureValue.substring(0, 2);*/ //plus nécessaire pour le choix du logo !

        return (
            <Box sx={{ display:"flex", alignItems:"center"}}>
                <LogoSpotifarm/>
            </Box>
        )
    }

    handleOpenProfilMenu(event) {
        this.setState({ openProfilMenu: true });
    };

    handleCloseProfilMenu(event) {
        const { closeProfilMenuDialog } = this.props;
        closeProfilMenuDialog();
    };

    handleOpenConfirmationLogOutDialog(event) {
        this.setState({ openConfirmationLogOutDialog: true });
    }

    handleCloseConfirmationLogOutDialog(event) {
        this.setState({ openConfirmationLogOutDialog: false });
    }

    handleMobileRemoveClientAndDatas(event) {
        //on lance un message de confirmation avant !
        this.setState({ displayConfirmRemoveAccount: true, });
    }

    handleCancelRemoveAccount(event) {
        //ferme la dialog de confirmation:
        this.setState({ displayConfirmRemoveAccount: false, });
    }

    handleValidRemoveAccount(event) {
        //ferme la dialog de confirmation:
        this.setState({ displayConfirmRemoveAccount: false, });

        //et lance la procèdure...
        this.props.removeClientAndDatas();
    }

    handleOpenConfirmationChangePwdDialog(event) {
        this.setState({ openConfirmationChangePwdDialog: true });
    }

    handleCloseConfirmationChangePwdDialog(event) {
        this.setState({ openConfirmationChangePwdDialog: false });
    }

    handleChangePassword(event) {
        //ferme la dialog de confirmation:
        this.setState({ openConfirmationChangePwdDialog: false, });

        //et lance la procèdure...
        this.props.changePassword();
    }

    handleListItemClick = (event, index) => {
        const { showProfilMenuDialog } = this.props;

        this.toggleProfilSidebarMobile(false);

        showProfilMenuDialog(index);
    };

    handleHelpClick = (event, index) => {
        const { setOpenHelpQuestions } = this.state;

        this.setState({ setOpenHelpQuestions: !setOpenHelpQuestions });

    };

    handleTabChangeTelechargement = (event, newValue) => {
        const { showProfilMenuDialog } = this.props;
        let newIndex;
        switch (newValue) {
            case 0:
                newIndex = ProfilIndex.aide_ModulationTéléchargementModulation;
                break;
            case 1:
                newIndex = ProfilIndex.aide_ModulationTéléchargementModulationAndroid;
                break;
            case 2:
                newIndex = ProfilIndex.aide_ModulationTéléchargementModulationIOS;
                break;
            default:
                newIndex = ProfilIndex.aide_ModulationTéléchargementModulation;
        }
        showProfilMenuDialog(newIndex);
    };

    handleTabChangeImportParcelles = (event, newValue) => {
        const { showProfilMenuDialog } = this.props;
        let newIndex;
        switch (newValue) {
            case 0:
                newIndex = ProfilIndex.aide_RecuperationParcelles;
                break;
            case 1:
                newIndex = ProfilIndex.aide_RecuperationParcellesGeofolia;
                break;
            case 2:
                newIndex = ProfilIndex.aide_RecuperationParcellesTelepac;
                break;
            default:
                newIndex = ProfilIndex.aide_RecuperationParcelles;
        }
        showProfilMenuDialog(newIndex);
    };

    /**
     * function permettant de retrouver le titre de la page grâce à son index
     * @param {number} selectedIndexProfilMenu
     * @returns {String} title
     */
    getTitleFromIndexPage = (selectedIndexProfilMenu) => {
        switch (selectedIndexProfilMenu) {
            case ProfilIndex.monExploitation:   // mon exploitation
                return StringTranslate.exploitation;

            case ProfilIndex.parametres:   // paramètres
                return StringTranslate.parametres;

            case ProfilIndex.abonnements:   // abonnements
                return StringTranslate.abonner;

            case ProfilIndex.mesFacturations:   // les factures
                return StringTranslate.mesfactures;

            case ProfilIndex.aide_IndiceVegetation:   // aide
            case ProfilIndex.aide_RecuperationParcelles:   // aide
            case ProfilIndex.aide_RecuperationParcellesGeofolia:   // aide
            case ProfilIndex.aide_RecuperationParcellesTelepac:   // aide
            case ProfilIndex.aide_ImporterParcelles:   // aide
            case ProfilIndex.aide_ModulationTéléchargementModulation:   // aide
            case ProfilIndex.aide_ModulationTéléchargementModulationAndroid:   // aide
            case ProfilIndex.aide_ModulationTéléchargementModulationIOS:   // aide
            case ProfilIndex.aide_ModulationFormatFichier:   // aide
            case ProfilIndex.aide_ModulationResolutionCarte:   // aide
            case ProfilIndex.aide_PeseeDeBiomasse:   // aide
            case ProfilIndex.aide_Fumure:   // aide
            case ProfilIndex.aide_LastNitrogenInput:   // aide
            case ProfilIndex.aide_Modelisation: // aide
            case ProfilIndex.aide_HarvestCorn: // aide
                return StringTranslate.aide;

            case ProfilIndex.tutosVideo:   // tutos vidéos
                return StringTranslate.tutoVideos;

            case ProfilIndex.contacterLeServiceClient:   // contacter le service client
                return StringTranslate.contactSupport;

            case ProfilIndex.partagerLAppli:   // partager l'appli
                return StringTranslate.shareApp;

            case ProfilIndex.about:   // à propos
                return StringTranslate.aboutUs;

            case ProfilIndex.disconnect:   // se déconnecter
                return StringTranslate.disconnect;

            default:
                return StringTranslate.exploitation;
        }
    }

    showIFrames = (urlToDisplay) => {
        return (

            <Paper
                variant="background"
                sx={{
                    height: { xs: "calc(100vh - 88px)", sm: "calc(100vh - 88px)", md: "calc(100vh - 156px)" },
                    [theme.breakpoints.down('md')]:
                    {
                        border: 'none',
                        borderRadius: 0,
                        m: 0
                    }
                }}
            >
                <MainCard sx={{ height: { xs: "calc(100vh - 108px)", sm: "calc(100vh - 108px)", md: "calc(100vh - 206px)" } }}>
                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'none', md: 'block' }
                        }}
                    >
                        <CustomIFrame className="iframes_profil" url={urlToDisplay}/>
                    </Box>
                    <Box
                        sx={{
                            display: { sm: 'block', md: 'none' },
                        }}
                    >
                        <CustomIFrame className="iframes_profil_mobile" url={urlToDisplay}/>
                    </Box>
                </MainCard>
            </Paper>
        )
    }

    //Pages d'aide de téléchargement de modulation
    renderDownLoadModulationHelp = () => {
        const { selectedIndexProfilMenu, profilPageUrl } = this.props;
        let tab = selectedIndexProfilMenu - ProfilIndex.aide_ModulationTéléchargementModulation;
        return (
            <Paper
                variant="background"
                sx={{
                    height: "fit-content",
                    minHeight: 'calc(100vh - 80px)',
                    [theme.breakpoints.down('md')]:
                    {
                        border: 'none',
                        borderRadius: 0,
                        m: 0
                    }
                }}
            >
                <MainCard sx={{ height: { xs: "calc(100vh - 108px)", sm: "calc(100vh - 108px)", md: "calc(100vh - 206px)" } }} id="card for tabs and shit">
                    <Tabs
                        value={tab}
                        variant="scrollable"
                        onChange={this.handleTabChangeTelechargement}
                        id="scrollable tabs import aides"
                    >
                        <Tab
                            label={StringTranslate.helpForDownloadModulationOnDesktop}
                            icon={<DesktopWindows sx={{ fontSize: '1.3rem' }} />}
                            {...a11yProps(0)} />
                        <Tab
                            label="Android"
                            icon={<PhoneAndroid sx={{ fontSize: '1.3rem' }} />}
                            {...a11yProps(1)} />
                        <Tab
                            label="Apple"
                            icon={<PhoneIphone sx={{ fontSize: '1.3rem' }} />}
                            {...a11yProps(1)} />
                    </Tabs>

                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'none', md: 'block' }
                        }}
                    >
                        <CustomIFrame className="iframes_profil_smaller_help" url={profilPageUrl}/>
                    </Box>
                    <Box
                        sx={{
                            display: { sm: 'block', md: 'none' },
                        }}
                    >
                        <CustomIFrame className="iframes_profil_smaller_help_mobile" url={profilPageUrl}/>
                    </Box>
                </MainCard>
            </Paper>
        );
    }

    //Pages d'aide de récupération de parcellaire
    renderRecuperationParcellesHelp = () => {
        const { selectedIndexProfilMenu, profilPageUrl } = this.props;
        let tab = selectedIndexProfilMenu - ProfilIndex.aide_RecuperationParcelles;
        return (
            <Paper
                variant="background"
                sx={{
                    height: { xs: "calc(100vh - 88px)", sm: "calc(100vh - 88px)", md: "calc(100vh - 156px)" },
                    [theme.breakpoints.down('md')]:
                    {
                        border: 'none',
                        borderRadius: 0,
                        m: 0
                    }
                }}
            >
                <MainCard sx={{ height: { xs: "calc(100vh - 108px)", sm: "calc(100vh - 108px)", md: "calc(100vh - 206px)" } }}>
                    <Tabs
                        value={tab}
                        variant="scrollable"
                        onChange={this.handleTabChangeImportParcelles}
                        id="scrollable tabs import aides"
                    >
                        <Tab
                            label="Mesp@rcelles"
                            icon={<DesktopWindows sx={{ fontSize: '1.3rem' }} />}
                            {...a11yProps(0)} />
                        <Tab
                            label="Geofolia"
                            icon={<DesktopWindows sx={{ fontSize: '1.3rem' }} />}
                            {...a11yProps(0)} />
                        <Tab
                            label="Telepac"
                            icon={<DesktopWindows sx={{ fontSize: '1.3rem' }} />}
                            {...a11yProps(0)} />
                    </Tabs>

                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'none', md: 'block' }
                        }}
                    >
                        <CustomIFrame className="iframes_profil_smaller_help" url={profilPageUrl}/>
                    </Box>
                    <Box
                        sx={{
                            display: { sm: 'block', md: 'none' },
                        }}
                    >
                        <CustomIFrame className="iframes_profil_smaller_help_mobile" url={profilPageUrl}/>
                    </Box>
                </MainCard>
            </Paper>
        );
    }

    /* fonction callback */
    handleChangeLogout(event) {
        this.props.saveSettings();
        this.props.disconnect();
        this.handleCloseProfilMenu();
    }

    /**
     * création de la sidebar du menu de profil
     */
    renderProfilMenuDrawer = () => {
        const { setOpenHelpQuestions } = this.state;
        const { selectedIndexProfilMenu, language, tokenFromSocialAccount } = this.props;
        return (
            <>
                {/* ↓ ZONE - LOGO + bouton cloture burger pour mobile ↓ */}
                <Box
                    sx={{
                        display: { sm: 'block', md: 'none' },
                    }}
                >
                    <List classes={{ root: 'list-root' }}>
                        <div className="logoAndButtonForMobile">
                            <Box sx={{ pl: 3 }} className="marqueImgWrapper" >
                                {this.chooseimgForMobile()}
                            </Box>
                            <Box>
                                <IconButton
                                    onClick={this.handleCloseProfilMenu}
                                    sx={{
                                        position: 'absolute',
                                        right: 20,
                                        top: 18,
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            </Box>
                        </div>
                    </List>
                </Box>


                {/* ↓ ZONE - LOGO  pour desktop ↓ */}
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}><List>{this.chooseimg()}</List></Box>

                {/* ↓ ZONE - Boutons ↓ */}
                <List sx={{ overflowY: "scroll" }}>

                    <Divider />

                    <ListItemButton
                        selected={selectedIndexProfilMenu === ProfilIndex.monExploitation}
                        onClick={(event) => this.handleListItemClick(event, ProfilIndex.monExploitation)}
                        sx={{
                            display: "flex",
                            '& hover': {
                                display: "none"
                            }
                        }}

                    >
                        <ListItemIcon>
                            <HomeExploitationIcon />
                        </ListItemIcon>
                        <ListItemText primary={StringTranslate.exploitation} />
                    </ListItemButton>


                    <ListItemButton
                        selected={selectedIndexProfilMenu === ProfilIndex.parametres}
                        onClick={(event) => this.handleListItemClick(event, ProfilIndex.parametres)}>
                        <ListItemIcon>
                            <SettingsOutlined sx={{ paddingRight: 1, fontSize: 30 }} />
                        </ListItemIcon>
                        <ListItemText primary={StringTranslate.parametres} />
                    </ListItemButton>

                    {/* Pas que Desktop */}
                    {/* <Box
                        sx={{ 
                            display:{xs:'none', sm: 'none', md:'block'} 
                        }}
                    > */}
                    <ListItemButton
                        selected={selectedIndexProfilMenu === ProfilIndex.abonnements}
                        onClick={(event) => this.handleListItemClick(event, ProfilIndex.abonnements)}>

                        <ListItemIcon>
                            <FactCheckOutlined sx={{ paddingRight: 1, fontSize: 30 }} />
                        </ListItemIcon>
                        <ListItemText primary={StringTranslate.abonner} />
                    </ListItemButton>
                    <ListItemButton
                        id="theFushiaColor"
                        variant="dashed"
                        selected={selectedIndexProfilMenu === ProfilIndex.mesFacturations}
                        onClick={(event) => this.handleListItemClick(event, ProfilIndex.mesFacturations)}
                        sx={{
                            "& .MuiListItemIcon-root": { "stroke": theme.palette.grey[700] },
                            "&.Mui-selected": {
                                "& .MuiListItemIcon-root": { "stroke": theme.palette.secondary.dark }
                            },
                            "&:hover": {
                                "& .MuiListItemIcon-root": { "stroke": theme.palette.secondary.dark }
                            }
                        }}
                    >


                        <ListItemIcon
                            id="list item icon element"
                        >
                            <EuroReportFactureIcon
                                sx={{ fontSize: "40px" }}
                                id="icon de la facture"
                            />
                        </ListItemIcon>
                        <ListItemText primary={StringTranslate.mesfactures} />
                    </ListItemButton>

                    {/* </Box> */}
                    <Divider />

                    <ListItemButton
                        selected={selectedIndexProfilMenu === ProfilIndex.aide}
                        onClick={(event) => this.handleHelpClick(event, ProfilIndex.aide)}>

                        <ListItemIcon>
                            <HelpOutline sx={{ paddingRight: 1, fontSize: 30 }} />
                            {setOpenHelpQuestions ?
                                <ExpandLess
                                    sx={{
                                        position: 'absolute',
                                        right: 20,
                                        top: 12,
                                    }} />
                                :
                                <ExpandMore
                                    sx={{
                                        position: 'absolute',
                                        right: 20,
                                        top: 12,
                                    }} />
                            }
                        </ListItemIcon>
                        <ListItemText primary={StringTranslate.aide} />
                    </ListItemButton>

                    <Collapse in={setOpenHelpQuestions} unmountOnExit>
                        <List sx={{ paddingLeft: 3 }}>
                            <ListItemButton
                                sx={{
                                    "& .MuiListItemIcon-root": { "stroke": theme.palette.grey[700] },
                                    "&.Mui-selected": {
                                        "& .MuiListItemIcon-root": { "stroke": theme.palette.secondary.dark }
                                    },
                                    "&:hover": {
                                        "& .MuiListItemIcon-root": { "stroke": theme.palette.secondary.dark }
                                    }
                                }}
                                selected={selectedIndexProfilMenu === ProfilIndex.aide_IndiceVegetation}
                                onClick={(event) => this.handleListItemClick(event, ProfilIndex.aide_IndiceVegetation)}>

                                <ListItemIcon>
                                    <SeedingIcon />
                                </ListItemIcon>
                                <ListItemText primary={StringTranslate.indicevege} />
                            </ListItemButton>
                            <ListItemButton
                                selected={selectedIndexProfilMenu === ProfilIndex.aide_RecuperationParcelles}
                                onClick={(event) => this.handleListItemClick(event, ProfilIndex.aide_RecuperationParcelles)}>

                                <ListItemIcon>
                                    <HelpOutline sx={{ paddingRight: 1, fontSize: 30 }} />
                                </ListItemIcon>
                                <ListItemText primary={StringTranslate.aideimportParcelles} />
                            </ListItemButton>
                            <ListItemButton
                                selected={selectedIndexProfilMenu === ProfilIndex.aide_ImporterParcelles}
                                onClick={(event) => this.handleListItemClick(event, ProfilIndex.aide_ImporterParcelles)}>

                                <ListItemIcon>
                                    <HelpOutline sx={{ paddingRight: 1, fontSize: 30 }} />
                                </ListItemIcon>
                                <ListItemText primary={StringTranslate.aideimport} />
                            </ListItemButton>

                            {/* Les trois pages ci-dessous n'ont aucun contenu en anglais et espagnol */}
                            {language === "fr-FR" || language === "fr-BE" ?
                                <>
                                    <ListItemButton
                                        selected={selectedIndexProfilMenu === ProfilIndex.aide_ModulationTéléchargementModulation}
                                        onClick={(event) => this.handleListItemClick(event, ProfilIndex.aide_ModulationTéléchargementModulation)}>

                                        <ListItemIcon>
                                            <IconeModulation />
                                        </ListItemIcon>
                                        <ListItemText primary={StringTranslate.helpForDownloadModulation} />
                                    </ListItemButton>
                                    <ListItemButton
                                        selected={selectedIndexProfilMenu === ProfilIndex.aide_ModulationFormatFichier}
                                        onClick={(event) => this.handleListItemClick(event, ProfilIndex.aide_ModulationFormatFichier)}>

                                        <ListItemIcon>
                                            <IconeModulation />
                                        </ListItemIcon>
                                        <ListItemText primary={StringTranslate.helpForFilesModulation} />
                                    </ListItemButton>
                                    <ListItemButton
                                        selected={selectedIndexProfilMenu === ProfilIndex.aide_ModulationResolutionCarte}
                                        onClick={(event) => this.handleListItemClick(event, ProfilIndex.aide_ModulationResolutionCarte)}>

                                        <ListItemIcon>
                                            <IconeModulation />
                                        </ListItemIcon>
                                        <ListItemText primary={StringTranslate.helpForResolutionModulation} />
                                    </ListItemButton>
                                </>
                                :
                                <></>
                            }

                            {/* La page de biomasse n'existe pas en anglais ni en espagnol */}
                            {language === "fr-FR" || language === "fr-BE" ?
                                <>
                                    <ListItemButton
                                        selected={selectedIndexProfilMenu === ProfilIndex.aide_PeseeDeBiomasse}
                                        onClick={(event) => this.handleListItemClick(event, ProfilIndex.aide_PeseeDeBiomasse)}>

                                        <ListItemIcon>
                                            <Balance sx={{ paddingRight: 1, fontSize: 30 }} />
                                        </ListItemIcon>
                                        <ListItemText primary={StringTranslate.aidePeseeColza} />
                                    </ListItemButton>
                                    <ListItemButton
                                        selected={selectedIndexProfilMenu === ProfilIndex.aide_Fumure}
                                        onClick={(event) => this.handleListItemClick(event, ProfilIndex.aide_Fumure)}
                                        sx={{
                                            "& .MuiListItemIcon-root": { "stroke": theme.palette.grey[700] },
                                            "&.Mui-selected": {
                                                "& .MuiListItemIcon-root": { "stroke": theme.palette.secondary.dark }
                                            },
                                            "&:hover": {
                                                "& .MuiListItemIcon-root": { "stroke": theme.palette.secondary.dark }
                                            }}}>
                                        <ListItemIcon>
                                            <FertilizationIcon sx={{ fontSize: 36 }} />
                                        </ListItemIcon>
                                        <ListItemText primary={StringTranslate.fertilizer} />
                                    </ListItemButton>
                                    <ListItemButton
                                        selected={selectedIndexProfilMenu === ProfilIndex.aide_LastNitrogenInput}
                                        onClick={(event) => this.handleListItemClick(event, ProfilIndex.aide_LastNitrogenInput)}
                                        sx={{
                                            "& .MuiListItemIcon-root": { "stroke": theme.palette.grey[700] },
                                            "&.Mui-selected": {
                                                "& .MuiListItemIcon-root": { "stroke": theme.palette.secondary.dark }
                                            },
                                            "&:hover": {
                                                "& .MuiListItemIcon-root": { "stroke": theme.palette.secondary.dark }
                                            }}}>
                                        <ListItemIcon>
                                            <FertilizationIcon sx={{ fontSize: 36 }} />
                                        </ListItemIcon>
                                        <ListItemText primary={StringTranslate.lastNitrogenInput} />
                                    </ListItemButton>
                                    <ListItemButton
                                        selected={selectedIndexProfilMenu === ProfilIndex.aide_Modelisation}
                                        onClick={(event) => this.handleListItemClick(event, ProfilIndex.aide_Modelisation)}>

                                        <ListItemIcon>
                                            <ModelisationIcon sx={{ paddingRight: 1, fontSize: 30 }} />
                                        </ListItemIcon>
                                        <ListItemText primary={StringTranslate.modelisationTitle} />
                                    </ListItemButton>
                                </>
                                :
                                <></>
                            }
                            <ListItemButton
                                selected={selectedIndexProfilMenu === ProfilIndex.aide_HarvestCorn}
                                onClick={(event) => this.handleListItemClick(event, ProfilIndex.aide_HarvestCorn)}>
                                <ListItemIcon>
                                    <CornIcon sx={{ paddingRight: 1, fontSize: 30 }} />
                                </ListItemIcon>
                                <ListItemText primary={StringTranslate.helpHarvestTitle} />
                            </ListItemButton>
                        </List>
                    </Collapse>

                    {language === "fr-FR" || language === "fr-BE" ?
                        <ListItemButton
                            selected={selectedIndexProfilMenu === ProfilIndex.tutosVideo}
                            onClick={(event) => this.handleListItemClick(event, ProfilIndex.tutosVideo)}>
                            <ListItemIcon>
                                <PlayCircleOutline sx={{ paddingRight: 1, fontSize: 30 }} />
                            </ListItemIcon>
                            <ListItemText primary={StringTranslate.tutoVideos} />
                        </ListItemButton>
                        : <div />}
                    <ListItemButton
                        selected={selectedIndexProfilMenu === ProfilIndex.contacterLeServiceClient}
                        onClick={(event) => this.handleListItemClick(event, ProfilIndex.contacterLeServiceClient)}>


                        <ListItemIcon>
                            <ContactMailOutlined sx={{ paddingRight: 1, fontSize: 30 }} />
                        </ListItemIcon>
                        <ListItemText primary={StringTranslate.contactSupport} />
                    </ListItemButton>

                    <Divider />

                    {/* Desktop */}
                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'none', md: 'block' }
                        }}
                    >
                        <ListItemButton
                            component="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={mailtoShare}
                            selected={selectedIndexProfilMenu === ProfilIndex.partagerLAppli}
                        >
                            <ListItemIcon>
                                <ShareOutlined sx={{ paddingRight: 1, fontSize: 30 }} />
                            </ListItemIcon>
                            <ListItemText primary={StringTranslate.shareApp} />
                        </ListItemButton>
                    </Box>

                    {/* Mobile   --> React Native ?*/}
                    <Box
                        sx={{
                            display: "none",
                        }}
                    >
                        <ListItemButton
                            selected={selectedIndexProfilMenu === ProfilIndex.partagerLAppli}
                            onClick={(event) => this.handleListItemClick(event, ProfilIndex.partagerLAppli)}>

                            <ListItemIcon>
                                <ShareOutlined sx={{ paddingRight: 1, fontSize: 30 }} />
                            </ListItemIcon>
                            <ListItemText primary={StringTranslate.shareApp} />
                        </ListItemButton>
                    </Box>

                    {((language === "fr-FR") || (language === "fr-BE")) && (
                        <ListItemButton
                            component="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://chat.whatsapp.com/GirabB7ssJfFuQuJ1TeOsg"
                        >
                            <ListItemIcon>
                                <WhatsApp sx={{ paddingRight: 1, fontSize: 30 }} />
                            </ListItemIcon>
                            <ListItemText primary={StringTranslate.joinWhatsappGroup} />
                        </ListItemButton>
                    )}

                    {/* Desktop */}
                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'none', md: 'block' }
                        }}
                        id="en-desktop"
                    >
                        <ListItemButton
                            component="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.google.com/search?q=spotifarm&hl=en&source=hp&ei=WJc5YrnxM-OUlwTSw7ugCw&iflsig=AHkkrS4AAAAAYjmlaHKMs9dxPA9FWIsBugJ4JEkBKm3G&gs_ssp=eJzj4tVP1zc0zEmLNzIrMc8wYLRSNagwMU81NzA3SDJLNTE1tzBPszKoME4ySzNONDVLBpImycZGXpzFBfklmWmJRbkARtISxg&oq=spotifarm&gs_lcp=Cgdnd3Mtd2l6EAEYADILCC4QgAQQxwEQrwEyBAgAEB4yBggAEAUQHjoLCC4QgAQQxwEQ0QM6BQgAEIAEOgsILhCABBDHARCjAjoFCC4QgAQ6CAguEIAEENQCOgoILhDHARDRAxAKOgQIABAKOgQILhAKULQGWNwbYNMyaAFwAHgAgAFMiAHLBJIBATmYAQCgAQGwAQA&sclient=gws-wiz#lrd=0x47e7070b6e45787f:0x3b6f3a56cf3a4c32,1,,,">
                            <ListItemIcon>
                                <FavoriteBorderOutlined sx={{ paddingRight: 1, fontSize: 30 }} />
                            </ListItemIcon>
                            <ListItemText primary={StringTranslate.rateUs} />
                        </ListItemButton>
                    </Box>

                    {/* Mobile */}
                    <Box
                        sx={{
                            display: "none",
                        }}
                        id="en-mobile"
                    >               {/* Item à modifier en mobile, pour le moment c'est le fonctionnel du desktop */}
                        <ListItemButton
                            component="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.google.com/search?q=spotifarm&hl=en&source=hp&ei=WJc5YrnxM-OUlwTSw7ugCw&iflsig=AHkkrS4AAAAAYjmlaHKMs9dxPA9FWIsBugJ4JEkBKm3G&gs_ssp=eJzj4tVP1zc0zEmLNzIrMc8wYLRSNagwMU81NzA3SDJLNTE1tzBPszKoME4ySzNONDVLBpImycZGXpzFBfklmWmJRbkARtISxg&oq=spotifarm&gs_lcp=Cgdnd3Mtd2l6EAEYADILCC4QgAQQxwEQrwEyBAgAEB4yBggAEAUQHjoLCC4QgAQQxwEQ0QM6BQgAEIAEOgsILhCABBDHARCjAjoFCC4QgAQ6CAguEIAEENQCOgoILhDHARDRAxAKOgQIABAKOgQILhAKULQGWNwbYNMyaAFwAHgAgAFMiAHLBJIBATmYAQCgAQGwAQA&sclient=gws-wiz#lrd=0x47e7070b6e45787f:0x3b6f3a56cf3a4c32,1,,,"
                        >
                            <ListItemIcon>
                                <FavoriteBorderOutlined sx={{ paddingRight: 1, fontSize: 30 }} />
                            </ListItemIcon>
                            <ListItemText primary={StringTranslate.rateUs} />
                        </ListItemButton>
                    </Box>

                    <Divider />

                    <ListItemButton
                        selected={selectedIndexProfilMenu === ProfilIndex.about}
                        onClick={(event) => this.handleListItemClick(event, ProfilIndex.about)}
                        sx={{
                            display: "flex",
                            '& hover': {
                                display: "none"
                            }
                        }}
                    >
                        <ListItemIcon>
                            <InfoOutlined sx={{ paddingRight: 1, fontSize: 30 }} />
                        </ListItemIcon>
                        <ListItemText primary={StringTranslate.aboutUs} />
                    </ListItemButton>

                    <Divider />

                    {/* ↓ BOUTON POUR DEMANDER LA SUPPRESSION DE TOUTES LES DONNEES CLIENT (DONT LA SUPPRESSION DE SON COMPTE) ↓ */}
                    {(this.isNativeAppHoster === true) && (<ListItemButton onClick={this.handleMobileRemoveClientAndDatas}>
                        <ListItemIcon>
                            <PersonRemove sx={{ paddingRight: 1, fontSize: 30 }} />
                        </ListItemIcon>
                        <ListItemText primary={StringTranslate.removeClientFromNativeApp} />
                    </ListItemButton>)}

                    {/* ↓ BOUTON POUR FERMER L'APPLICATION NATIVE ↓ */}
                    {/* Mis en commentaire le temps de trouver comment améliorer le visuel à la remise en route de l'appli...
                    {(this.isNativeAppHoster === true) && (<ListItemButton onClick={this.handleMobileCloseNativeApp}>
                        <ListItemIcon>
                            <PowerSettingsNew sx={{paddingRight: 1, fontSize: 30}} />
                        </ListItemIcon>
                        <ListItemText primary={StringTranslate.closeNativeApp} />
                    </ListItemButton>)} */}

                    {/* ↓ Bouton pour demander un changement de mot de passe ↓ */}
                    {(this.isNativeAppHoster === true) && (tokenFromSocialAccount === false) && (
                        <ListItemButton onClick={this.handleOpenConfirmationChangePwdDialog}>
                            <ListItemIcon>
                                <LockReset sx={{ paddingRight: 1, fontSize: 30 }} />
                            </ListItemIcon>
                            <ListItemText primary={StringTranslate.changePassword} />
                        </ListItemButton>
                    )}

                    {/* ↓ Bouton pour se déconnecter ↓ */}
                    <ListItemButton onClick={this.handleOpenConfirmationLogOutDialog}>
                        <ListItemIcon>
                            {(this.isNativeAppHoster === true) ?
                                (<SyncAlt sx={{ paddingRight: 1, fontSize: 30 }} />)
                                :
                                (<PowerSettingsNew sx={{ paddingRight: 1, fontSize: 30 }} />)}
                        </ListItemIcon>
                        <ListItemText primary={StringTranslate.disconnect} />
                    </ListItemButton>

                </List>
            </>
        );
    }

    renderCurrentProfilPage = () => {
        const {
            selectedIndexProfilMenu, profilPageUrl
        } = this.props;

        // Pour le "calc(100vh - 88px)" le 88px vient de la taille du header (48px) + les bordures hautes et basses du paper (2x20px)

        switch (selectedIndexProfilMenu) {
            case ProfilIndex.monExploitation:   // mon exploitation
                return (
                    <Paper
                        variant="background"
                        sx={{
                            height: { xs: "calc(100vh - 88px)", sm: "calc(100vh - 88px)", md: "calc(100vh - 93px)", lg: "calc(100vh - 156px)" },
                            overflowY: "auto",
                            [theme.breakpoints.down('md')]:
                            {
                                border: 'none',
                                borderRadius: 0,
                                m: 0
                            }
                        }}
                        id="ici une variante qui marche"
                    >
                        {/* <PerfectScrollbar style={{ height: 'fit-co', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}> */}
                        <MonExploit />
                        {/* </PerfectScrollbar> */}
                    </Paper>
                )
            case ProfilIndex.parametres:   // paramètres
                return (
                    <Paper
                        variant="background"
                        sx={{
                            height: { xs: "calc(100vh - 88px)", sm: "calc(100vh - 88px)", md: "calc(100vh - 156px)" },
                            overflow: { xs: "auto", sm: "auto", md: "hidden" },
                            [theme.breakpoints.down('md')]:
                            {
                                border: 'none',
                                borderRadius: 0,
                                m: 0
                            }
                        }}
                    >
                        <Parametres />
                    </Paper>
                )
            case ProfilIndex.abonnements:   // abonnements
                return (
                    <Paper
                        variant="background"
                        sx={{
                            height: { xs: "calc(100vh - 88px)", sm: "calc(100vh - 88px)", md: "calc(100vh - 156px)" },
                            overflow: "auto",
                            [theme.breakpoints.down('md')]:
                            {
                                border: 'none',
                                borderRadius: 0,
                                m: 0
                            }
                        }}
                    >
                        <Abonnements />
                    </Paper>
                )
            case ProfilIndex.mesFacturations:   // les factures
                return (
                    <Paper
                        variant="background"
                        sx={{
                            height: { xs: "calc(100vh - 88px)", sm: "calc(100vh - 88px)", md: "calc(100vh - 156px)" },
                            overflow: "auto",
                            [theme.breakpoints.down('md')]:
                            {
                                border: 'none',
                                borderRadius: 0,
                                m: 0
                            }
                        }}
                    >
                        <Factures />
                    </Paper>
                )
            case ProfilIndex.aide_IndiceVegetation:   // aide
            case ProfilIndex.aide_ImporterParcelles:   // aide
            case ProfilIndex.aide_ModulationFormatFichier:   // aide
            case ProfilIndex.aide_ModulationResolutionCarte:   // aide
            case ProfilIndex.aide_PeseeDeBiomasse:   // aide
            case ProfilIndex.aide_Fumure:   //aide
            case ProfilIndex.aide_LastNitrogenInput:   //aide
            case ProfilIndex.aide_Modelisation:   //aide
            case ProfilIndex.tutosVideo:   // tutos vidéos
            case ProfilIndex.contacterLeServiceClient:   // contacter le service client
            case ProfilIndex.aide_HarvestCorn: //aide récolte
                return this.showIFrames(profilPageUrl);

            case ProfilIndex.aide_RecuperationParcelles:   // aide
            case ProfilIndex.aide_RecuperationParcellesGeofolia:   // aide
            case ProfilIndex.aide_RecuperationParcellesTelepac:   // aide
                return this.renderRecuperationParcellesHelp();


            case ProfilIndex.aide_ModulationTéléchargementModulation:   // aide
            case ProfilIndex.aide_ModulationTéléchargementModulationAndroid:   // aide
            case ProfilIndex.aide_ModulationTéléchargementModulationIOS:   // aide
                return this.renderDownLoadModulationHelp();


            case ProfilIndex.partagerLAppli:   // partager l'appli
                return (
                    <>
                        {/* Redirection vers un site externe, donc pas besoin de contenu */}
                    </>
                )
            case ProfilIndex.whatsappCommu:   // rejoindre le groupe whatsapp
                return (
                    <>
                        {/* Redirection vers un site externe, donc pas besoin de contenu */}
                    </>
                )
            case ProfilIndex.rateApp:   // noter l'application
                return (
                    <Paper
                        variant="background"
                        sx={{
                            height: "90vh",
                            overflow: "hidden",
                            [theme.breakpoints.down('md')]:
                            {
                                border: 'none',
                                borderRadius: 0,
                                m: 0
                            }
                        }} />
                )
            case ProfilIndex.about:   // à propos
                return (
                    <Paper
                        variant="background"
                        sx={{
                            height: { xs: "calc(100vh - 88px)", sm: "calc(100vh - 88px)", md: "calc(100vh - 156px)" },
                            overflow: "auto",
                            [theme.breakpoints.down('md')]:
                            {
                                border: 'none',
                                borderRadius: 0,
                                m: 0
                            }
                        }}
                    >
                        <AboutUs />
                    </Paper>
                )
            case ProfilIndex.disconnect:   // à propos
                return (
                    <>

                    </>
                )
            default:
                return <div />
        }
    }

    /* Affichage de la confirmation avant suppression du compte et des données associées */
    renderConfirmationClearAllDatasDialog = () => {
        const { displayConfirmRemoveAccount } = this.state;
        
        return (
            <Dialog
                open={displayConfirmRemoveAccount}
                onClose={this.handleCancelRemoveAccount}
            >
                <DialogTitle>{StringTranslate.confirmDeleteAccount}</DialogTitle>

                <DialogActions>
                    <Button onClick={this.handleCancelRemoveAccount} variant="text" color="error">
                        {StringTranslate.cancelRemoveAccount}
                    </Button>
                    <Button onClick={this.handleValidRemoveAccount} variant="outlined" color='primary' autofocus>
                        {StringTranslate.validRemoveAccount}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderConfirmationLogOutDialog = () => {
        const { openConfirmationLogOutDialog } = this.state;

        return (
            <Dialog
                open={openConfirmationLogOutDialog}
                onClose={this.handleCloseConfirmationLogOutDialog}
            >
                <DialogTitle>{StringTranslate.disconnect}</DialogTitle>

                <DialogContent>
                    <DialogContentText>{StringTranslate.confirmDisconnection}</DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.handleCloseConfirmationLogOutDialog} variant="text" color="error">
                        {StringTranslate.annuler}
                    </Button>
                    <Button onClick={this.handleChangeLogout} variant="outlined" color='primary' autofocus>
                        {StringTranslate.disconnect}
                    </Button>
                </DialogActions>

            </Dialog>
        )
    }

    renderConfirmationChangePwdDialog = () => {
        const { openConfirmationChangePwdDialog } = this.state;

        return (
            <Dialog
                open={openConfirmationChangePwdDialog}
                onClose={this.handleCloseConfirmationChangePwdDialog}
            >
                <DialogTitle>{StringTranslate.changePassword}</DialogTitle>

                <DialogContent>
                    <DialogContentText>{StringTranslate.confirmChangePassword}</DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.handleCloseConfirmationChangePwdDialog} variant="text" color="error">
                        {StringTranslate.annuler}
                    </Button>
                    <Button onClick={this.handleChangePassword} variant="outlined" color='primary' autofocus>
                        {StringTranslate.confirmChangePwd}
                    </Button>
                </DialogActions>

            </Dialog>
        )
    }

    renderProfilMenu = () => {

        const {
            isshowProfilMenuDialog,
            selectedIndexProfilMenu
        } = this.props;
        const { profilMenuSidebarStateMobile,
        } = this.state

        const drawerWidth = '272px';    // Pour desktop uniquement

        const profilTitle = this.getTitleFromIndexPage(selectedIndexProfilMenu);

        return (
            <Dialog
                open={isshowProfilMenuDialog}
                onClose={this.handleCloseProfilMenu}
                fullWidth={true}
                maxWidth={false}
                fullScreen={this.fullScreen}
                className="radius12"
                id="dialog for profil menu"
            >

                {/* drawer pour mobile */}
                <Box
                    sx={{
                        display: { sm: 'block', md: 'none' }
                    }}
                >
                    <Drawer
                        id="profilMenuSidebarMobile"
                        variant="persistent"
                        open={profilMenuSidebarStateMobile}
                        onClose={(event) => this.setState({ profilMenuSidebarStateMobile: false })}
                        anchor="left"
                        sx={{
                            "& .MuiDrawer-paper": {
                                width: "100vw",
                                position: "fixed",
                                height: "100%"
                            }
                        }}
                    >
                        {this.renderProfilMenuDrawer()}
                    </Drawer>
                </Box>

                {/* drawer pour desktop */}
                <Box
                    id='box_of_profil_menu'
                    sx={{
                        display: { xs: 'none', sm: 'none', md: 'block' },
                    }}
                >
                    <Drawer
                        sx={{
                            '& .MuiDrawer-paper': {
                                maxHeight: '100%',
                                width: '240px',
                                paddingRight: "16px",
                                paddingLeft: "16px",
                                overflowY: "hidden",
                                position: "absolute"
                            }
                        }}
                        variant="permanent"
                        open={false}
                        anchor="left"
                    >
                        {this.renderProfilMenuDrawer()}
                    </Drawer>
                </Box>

                {/* contenu de la page */}
                <Box
                    sx={{
                        height: { xs: "none", sm: "none", md: window.screen.height },
                        marginLeft: { sm: 'none', md: drawerWidth },
                        overflow: "hidden",
                    }}
                >
                    <DialogTitle style={{ padding: "0px" }}>
                        {/* Header */}
                        {/* <Toolbar> */}
                        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ px: 2 }}>
                            <Typography variant="h3">{`${profilTitle}`}</Typography>
                            <IconButton
                                // Desktop
                                sx={{
                                    color: (theme) => theme.palette.grey[500],
                                    display: { xs: 'none', sm: 'none', md: 'block' }, // En Desktop on met une croix et on ferme le menu
                                }}
                                onClick={this.handleCloseProfilMenu}>
                                <Close />
                            </IconButton>
                            <IconButton
                                // Mobile
                                color="inherit"
                                onClick={(event) => this.setState({ profilMenuSidebarStateMobile: true })}
                                aria-label="close"
                                sx={{
                                    color: (theme) => theme.palette.grey[500],
                                    display: { sm: 'block', md: 'none' } // En Mobile on met un chevron et on ouvre le menu
                                }}
                            >
                                <ChevronLeft />
                            </IconButton>
                        </Stack>
                        {/* </Toolbar> */}
                    </DialogTitle>
                    <DialogContent style={{ padding: "0px" }}>
                        {/* SWITCH ICI */}
                        {this.renderCurrentProfilPage()}
                    </DialogContent>
                </Box>
            </Dialog>
        );
    }

    render() {
        return (

            <Box>
                {this.renderProfilMenu()}

                {this.renderConfirmationClearAllDatasDialog()}
                {this.renderConfirmationLogOutDialog()}
                {this.renderConfirmationChangePwdDialog()}
            </Box>
        );
    }


}

const mapStateToProps = state => ({
    isshowProfilMenuDialog: lodashGet(state, 'contextAppData.showProfilMenu', false),
    selectedIndexProfilMenu: lodashGet(state, 'contextAppData.profilIndex', ProfilIndex.monExploitation),
    profilPageUrl: lodashGet(state, 'contextAppData.profilPageUrl', undefined),
    language: state.settingsData.settings.language,
    hasSubscription: ((state.clientUserData.clientDatas.enumTypoClient !== TypoClientEnum.Freemium) && (state.clientUserData.clientDatas.enumTypoClient !== TypoClientEnum.Explorer )),
    clientDatas: state.clientUserData.clientDatas,
    tokenFromSocialAccount: state.connectionData.tokenFromSocialAccount,
})

const mapDispatchToProps = dispatch => ({
    showProfilMenuDialog: (index) => dispatch(ActionShowProfilMenuDialog(index)),
    closeProfilMenuDialog: () => dispatch(ActionCloseProfilMenuDialog()),
    disconnect: (closeNativeApp) => dispatch(ActionDisconnect(closeNativeApp)),
    //@@Remettre méthode de réinit du PwD.
    saveSettings: () => dispatch(ActionSaveSettingsAsk()),
    removeClientAndDatas: () => dispatch(ActionRemoveClientAndDatas()),
    changePassword: () => dispatch(ActionChangePassword()),
})

export default connect(mapStateToProps, mapDispatchToProps)(renderProfil);