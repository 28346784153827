import PropTypes from 'prop-types';

/**
 * Objet IdentificationImage
 */
export class IdentificationImage {
    constructor(props) {
        this.clientId = props.clientId || -1;
        this.imageUrl = props.imageUrl || null;
        this.imageData = props.imageData || [];
        this.organ = props.organ || EnumIdentificationOrgan.AUTO;
        this.isSave = props.isSave || false;
    }
}

IdentificationImage.propTypes = {
    clientId: PropTypes.number,
    imageUrl: PropTypes.string,
    imageData: PropTypes.arrayOf(PropTypes.number),
    organ: PropTypes.number,
    isSave: PropTypes.bool,
};

export const EnumIdentificationOrgan = {
    AUTO: 0,
    LEAF: 1,
    FLOWER: 2,
    FRUIT: 3,
    BARK: 4,
    HABIT: 5, //utiliser AUTO de préférence
    OTHER: 6, //utiliser AUTO de préférence
};