import React from "react";
import { connect } from 'react-redux';
import { 
    Button, Select, MenuItem, InputLabel, 
    TextField, Grid, Stack
        } from "@mui/material";
import StringTranslate from "../../assets/i18n/stringLanguage";
import { ActionSaveSettingsAsk } from '../../redux/actions/settings.js';

import FlagFR from '../../assets/images/flag/FR.svg';
import FlagBE from '../../assets/images/flag/BE.svg';
import FlagGB from '../../assets/images/flag/GB.svg';
import FlagES from '../../assets/images/flag/ES.svg';
import { LanguageHelper } from "../../utils/languageHelper";

import '../../assets/css/profil.css'

class FarmInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameExploitation: props.nameExploitation,
            language: props.language,
            onEdit_Settings: true,

        }

        this.saveSettings = this.saveSettings.bind(this);
    }

    onClickCancelFarmName = () => {
        this.setState({
            editingFarmName: false,
            nameExploitation: this.props.nameExploitation,
            onEdit_Settings: true,
        });
    }

    saveSettings() {
        LanguageHelper.updateLanguage(this.state.language);
        let settingsToSave = {
            ...this.props.settings,
            nameExploitation: this.state.nameExploitation,
            language: this.state.language,
            }
            
        // ↓ sauvegarde en BDD ↓
        this.props.saveSettings(settingsToSave)
            .then(() => this.setState({
                onEdit_Settings: true,
            }))
            .catch((error) => {
                //console.log(error);
            });
    }

    handleChange = param => (event) => {

        if (param === 'nameExploitation') {

            this.setState({
                nameExploitation: event.target.value,
                onEdit_Settings: false,
            });

        } else if (param === 'language') {

            this.setState({
                language: event.target.value,
                onEdit_Settings: false,
            });
        }
    }

    renderCard = () => {
        const { nameExploitation, language, onEdit_Settings } = this.state;
        return (
            <Grid container spacing={2} alignItems="center">

                {/* 2 => Nom de l'exploitation et pouvoir le modifier */}
                <Grid item xs={12} >
                    <InputLabel>{StringTranslate.nomexploit}</InputLabel>
                    <TextField
                        placeholder={`${StringTranslate.nomexploit}`}
                        value={nameExploitation}
                        onChange={this.handleChange('nameExploitation')}
                        margin="normal"
                        inputProps={{ maxLength: 40 }}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>

                {/* ↓ partie SELECTION de la langue ↓ */}
                <Grid item xs={12} >
                    <InputLabel >{StringTranslate.language}</InputLabel>
                    <Select 
                        value={language} 
                        onChange={this.handleChange('language')} 
                        fullWidth
                        sx={{mt:2, mb:1}}
                    >
                        <MenuItem value='fr-FR'> <div><img src={FlagFR} width="18" alt="Fr" />{` - ${StringTranslate.itemMenuLangueFR}`}</div> </MenuItem>
                        <MenuItem value='fr-BE'> <div><img src={FlagBE} width="18" alt="Be" />{` - ${StringTranslate.itemMenuLangueBE}`}</div> </MenuItem>
                        <MenuItem value='en-GB'> <div><img src={FlagGB} width="18" alt="Gb" />{` - ${StringTranslate.itemMenuLangueGB}`}</div> </MenuItem>
                        <MenuItem value='es-ES'> <div><img src={FlagES} width="18" alt="Es" />{` - ${StringTranslate.itemMenuLangueES}`}</div> </MenuItem>
                    </Select>
                </Grid>

                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                        <Button
                            type="submit"
                            variant="text"
                            color="error"
                            onClick={this.onClickCancelFarmName}
                            disabled={onEdit_Settings}
                        >
                            {`${StringTranslate.annuler}`}
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={this.saveSettings}
                            disabled={onEdit_Settings}
                        >
                            {`${StringTranslate.enregistrer}`}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        );
    }

    render() {
        return (

            <>
                {this.renderCard()}
            </>
        );
    }
}

const mapStateToProps = state => ({
    nameExploitation: state.settingsData.settings.nameExploitation || '',
    language: state.settingsData.settings.language,
    settings: state.settingsData.settings,
})

const mapDispatchToProps = dispatch => ({
    saveSettings: (settingsToSave) => dispatch( ActionSaveSettingsAsk(settingsToSave) ),
})

export default connect(mapStateToProps, mapDispatchToProps)(FarmInfo);