import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';

// constant
/*const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};*/

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = React.forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            secondary,
            shadow,
            sx = {},
            title,
            ...others
        },
        ref
    ) => {
        const theme = useTheme();

        return (
            <Card
                id="cardMainCard"
                ref={ref}
                {...others}
                sx={{
                    p: { xs: 1, md: 3}, 
                    border: border ? '1px solid' : 'none',
                    borderColor: theme.palette.primary[200] + 75,
                    ':hover': {
                        boxShadow: boxShadow
                            ? shadow || ('0 2px 14px 0 rgb(32 40 45 / 8%)')
                            : 'inherit'
                    },
                    ...sx
                }}
            >
            {/* p: { xs: 0.5, md: 3} => padding de 0.5*8px si petit écran ; 3*8px dès les moyens écrans(et plus grand) */}

                {/* card header and action */}
                {title && 
                    <CardHeader 
                        id="cardHeaderMainCard"
                        sx={{
                            pt:{sm: 2, md:2 ,lg: 0}
                        }}
                        title={
                            <Typography variant="h4" id="cardHeaderMainCard">
                                {title}
                            </Typography>} 
                        action={secondary}
                    />}

                {/* content & header divider */}
                {title && <Divider sx={{mb:2}} />}

                {/* card content */}
                {content && (
                    <CardContent 
                        id="cardContentMainCard"
                        className={contentClass} 
                        sx={{
                            p: 0,
                            ...contentSX}}
                    >
                        {/* p: { xs: 0, md: 3} => padding de 0 si petit écran ; 3*8px dès les moyens écrans(et plus grand) */}
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default MainCard;
