import React from 'react';
import { createRoot } from 'react-dom/client'; //import React from "react"; import ReactDOM from "react-dom";
import { connect, Provider } from 'react-redux';
import L from 'leaflet';
import { Button, ButtonGroup, SpeedDialAction, ThemeProvider, Typography } from '@mui/material';
import '../../assets/css/pdc-pinDropControl.css';
import PinDropFormPopup from './PinDropFormPopup.jsx';
import { ParcelsHelper } from '../../utils/parcelsHelper.js';
import { actionSaveObservationToAPI, ActionResetLastObservationIdSaved, ActionShowSnackbarCreateZoneOrMarker, ActionGoToIdentification } from '../../redux/actions/observations.js';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import leafletfunction from '../../assets/i18n/leaflet-draw-i18n.js';
import {
    ActionDisableDrawMarkerPindrop,
    ActionDisableDrawAreaPindrop,
    ActionOpenPinDropFormDialog,
    ActionClosePinDropFormDialog,
    ActionOpenIdentificationDialog,
    ActionCloseIdentificationDialog,
} from '../../redux/actions/contextApp.js';
import { ObservationsHelper } from '../../utils/observationsHelper.js';
import storeManager from '../../redux/store/configureStore';
import { ObservationImage } from '../../models/observationImage';
import { IdentificationImage } from '../../models/identificationImage';

import IconIdentification from '../../assets/images/observation/IconIdentification.tsx';
import fileHelper from '../../utils/fileHelper';
import getTheme from "../../themes/index.js";

import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import UndoIcon from '@mui/icons-material/Undo';
import PlaceIcon from '@mui/icons-material/Place';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PinDropAreaIcon from '../../assets/pinDropArea.tsx';

let theme = getTheme();

// ↓ ajout d'icônes valides ↓
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

/**
 * Controles (boutons) affichées sur la carte + fonctionnalité CARNET DE PLAINE
 */
class PinDropControl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            buttonGroupOpened: true, // en lien avec l'affichage des boutons (marker, polygone)
            drawMarkerEnabled: false, // l'utilisateur a cliqué sur le bouton de création de marker
            drawPolygonEnabled: false, // l'utilisateur a cliqué sur le bouton de création de surface d'observation (polygone)
            image: undefined, // image prise avant la création de l'observation
        }

        this.handleOpenButtonGroup = this.handleOpenButtonGroup.bind(this);
        this.onStartDrawGeometry = this.onStartDrawGeometry.bind(this);
        this.onStartDrawMarker = this.onStartDrawMarker.bind(this);
        this.onStartDrawPolygon = this.onStartDrawPolygon.bind(this);
        this.onStopDrawGeometry = this.onStopDrawGeometry.bind(this);
        this.onStopDrawMarker = this.onStopDrawMarker.bind(this);
        this.onStopDrawPolygon = this.onStopDrawPolygon.bind(this);
        this.deleteLastPointDrawPolygon = this.deleteLastPointDrawPolygon.bind(this);
        this.onJustDrawNewPoint = this.onJustDrawNewPoint.bind(this);
        this.completeShapeDrawPolygon = this.completeShapeDrawPolygon.bind(this);
        this.onCreateDrawGeometry = this.onCreateDrawGeometry.bind(this);
        this.savePinDropGeometry = this.savePinDropGeometry.bind(this);
        this.deletePinDropGeometry = this.deletePinDropGeometry.bind(this);

        this.counterVertex = 0;
        this.inputFileRef = React.createRef();
        this.isInputFileOpenForIdentification = false;
    }

    /* fonction cycle de vie react.js */
    componentDidMount() {
        //↓ i18n = internationalisation ↓
        if (!L.drawLocal) L.drawLocal = leafletfunction();

        // ↓ devons-nous activer automatiquement une des fonctionnalités au démarrage ? ↓
        if (this.props.enableDrawMarkerPindrop === true) {
            this.onStartDrawMarker();
            this.props.disableDrawMarkerPindrop(); // on remet la valeur à faux 
        }
        if (this.props.enableDrawAreaPindrop === true) {
            this.onStartDrawPolygon();
            this.props.disableDrawAreaPindrop(); // on remet la valeur à faux 
        }
    }

    /* fonction permettant de contrôler l'ouverture/fermeture du groupe de bouton */
    handleOpenButtonGroup(event) {
        this.setState({ buttonGroupOpened: !this.state.buttonGroupOpened })
    }

    /* fonction commune à la création de point/zone d'observation */
    onStartDrawGeometry(event) {
        this.props.renderMapsCmp.PINDROPSTART = true;

        // prévient le parent ('SpeedDial') qu'il ne doit pas se fermer: 
        if (this.props.onSpeedDialCanClose) { 
            this.props.onSpeedDialCanClose(false);
        }
    }

    /* fonction commune lors de la fin de création de point/zone d'observation */
    onStopDrawGeometry(event) {
        this.props.renderMapsCmp.PINDROPSTART = false;
    }

    /* fonction permettant de lancer la création de point d'observation */
    onStartDrawMarker(event) {
        if (this.state.drawMarkerEnabled) return; // évite le multi click (la fonctinnalité est déjà activée)
        if (this.props.renderMapsCmp.DRAWINGPARCEL === true) return; // on est déjà sur un dessin de parcelle @@ l'utiliser dans redux

        if (this.state.drawPolygonEnabled)
            this.onStopDrawPolygon();

        this.onStartDrawGeometry();

        if (window.innerWidth <= 600)
            this.props.showSnackbarCreateZoneOrMarker(StringTranslate.placeMarkerOnMap);

        this.drawerMarker = new L.Draw.Marker(this.props.renderMapsCmp.map, { icon: ObservationsHelper.getDefaultObservationTypeIcon() });
        this.drawerMarker.enable();

        this.props.renderMapsCmp.map.on(L.Draw.Event.DRAWSTOP, this.onStopDrawMarker);
        this.props.renderMapsCmp.map.on(L.Draw.Event.CREATED, this.onCreateDrawGeometry);

        this.setState({
            drawMarkerEnabled: true,
            drawPolygonEnabled: false,
        });
    }

    /* fonction permettant de stopper la création de point d'observation */
    onStopDrawMarker(event) {
        if (this.drawerMarker)
            this.drawerMarker.disable();

        this.onStopDrawGeometry();
        this.props.showSnackbarCreateZoneOrMarker(null);

        this.drawerMarker = null;
        this.props.renderMapsCmp.map.off(L.Draw.Event.DRAWSTOP, this.onStopDrawMarker);
        this.props.renderMapsCmp.map.off(L.Draw.Event.CREATED, this.onCreateDrawGeometry);

        this.setState({ drawMarkerEnabled: false, image: undefined });

        // prévient le parent ('SpeedDial') qu'il peut se fermer: 
        if (this.props.onSpeedDialCanClose) { 
            this.props.onSpeedDialCanClose(true);
        }
    }

    /* fonction permettant de lancer la création de zone d'observation */
    onStartDrawPolygon(event) {
        if (this.state.drawPolygonEnabled) return; // évite le multi click (la fonctionnalité est déjà activée)
        if (this.props.renderMapsCmp.DRAWINGPARCEL === true) return; // on est déjà sur un dessin de parcelle @@ l'utiliser dans redux

        if (this.state.drawMarkerEnabled)
            this.onStopDrawMarker();

        this.onStartDrawGeometry();

        // Affiche une popup d'aide pour mobile pour placer le premier point
        if (window.innerWidth <= 600)
            this.props.showSnackbarCreateZoneOrMarker(StringTranslate.placeFirstPointForPolygonOnMap);

        // ↓ MODIFICATION FONCTIONNEL SUR MOBILE (touch event) - https://github.com/Leaflet/Leaflet.draw/issues/935#issuecomment-577151194 ↓
        L.Draw.Polygon.prototype._onTouch = L.Util.falseFn;
        if (L.Browser.touch && (this.props.renderMapsCmp.map.tap && this.props.renderMapsCmp.map.tap.disable))
            this.props.renderMapsCmp.map.tap.disable();

        // ↓ création des options + fonctionnel provenant de leaflet draw ↓
        this.drawerOptions = {
            allowIntersection: false, // Restricts shapes to simple polygons
            drawError: {
                color: theme.palette.contour.selection, // Color the shape will turn when intersects
                message: `${StringTranslate.noauth}`  // Message that will show when intersect
            },
            icon: new L.DivIcon({ // taille des points
                iconSize: new L.Point(15, 15),
                className: 'leaflet-div-icon pdc-divIco'
            }),
        };
        this.drawerPolygon = new L.Draw.Polygon(this.props.renderMapsCmp.map, this.drawerOptions);
        this.drawerPolygon.enable();
        this.counterVertex = 0;


        this.props.renderMapsCmp.map.on(L.Draw.Event.DRAWVERTEX, this.onJustDrawNewPoint);
        this.props.renderMapsCmp.map.on(L.Draw.Event.DRAWSTOP, this.onStopDrawPolygon);
        this.props.renderMapsCmp.map.on(L.Draw.Event.CREATED, this.onCreateDrawGeometry);

        this.setState({
            drawMarkerEnabled: false,
            drawPolygonEnabled: true,
        });
    }

    /* fonction permettant de supprimer le dernier point lors du dessin d'une zone d'observation */
    completeShapeDrawPolygon(event) {
        if (this.drawerPolygon) {
            if (this.counterVertex > 2) { //minimum un triangle!
                this.drawerPolygon.completeShape();
            } else {
                this.onStopDrawPolygon(event);
            }
        }

        // prévient le parent ('SpeedDial') qu'il peut se fermer: 
        if (this.props.onSpeedDialCanClose) { 
            this.props.onSpeedDialCanClose(true);
        }
    }

    /* fonction permettant de supprimer le dernier point lors du dessin d'une zone d'observation */
    deleteLastPointDrawPolygon(event) {
        if (this.drawerPolygon && (this.counterVertex > 0)) {
            this.drawerPolygon.deleteLastVertex();
            this.counterVertex -= 1;
        }

        // on n'a pas à prévenir le parent ('SpeedDial') qu'il pourrait se fermer, car on reste en cours de dessin !
    }

    /**
     * fonction callback lors de la création d'une parcelle dessinée (lors du dessin terminé)
     */
    onJustDrawNewPoint(vertex) {
        if (vertex) {
            this.counterVertex += 1;

            /* Permet de supprimer la popup d'aide suite à la création du premier point */
            const layerIds = Object.keys(vertex.layers._layers);
            if (layerIds.length === 1) {
                this.props.showSnackbarCreateZoneOrMarker(null);
            }
        }
    }

    /* fonction permettant de stopper la création de zone d'observation */
    onStopDrawPolygon(event) {
        if (this.drawerPolygon)
            this.drawerPolygon.disable();

        this.onStopDrawGeometry();

        this.props.showSnackbarCreateZoneOrMarker(null);

        this.drawerPolygon = null;
        this.props.renderMapsCmp.map.off(L.Draw.Event.DRAWSTOP, this.onStopDrawPolygon);
        this.props.renderMapsCmp.map.off(L.Draw.Event.CREATED, this.onCreateDrawGeometry);
        this.props.renderMapsCmp.map.off(L.Draw.Event.DRAWVERTEX, this.closeSnackbarCreateZone);

        this.setState({ drawPolygonEnabled: false });

        // prévient le parent ('SpeedDial' qu'il peut se fermer): 
        if (this.props.onSpeedDialCanClose) { 
            this.props.onSpeedDialCanClose(true);
        }
    }

    /* fonction lancée juste après la création d'un élément d'observation (point, polygone, ...) */
    onCreateDrawGeometry({ layerType, layer }) {
        this.props.showSnackbarCreateZoneOrMarker(null);
        if (!layer) return;

        this.pindropLayer = layer;
        let pinDropLayerArea = 0;
        let linkedParcelList = [{ parcelId: 0, parcelName: `${StringTranslate.noparcelname}`, area: 0 }];

        // ↓ ZONE D'OBSERVATION => POLYGONE ↓
        if (layerType === "polygon") {

            // ↓ calcul de la surface ↓
            pinDropLayerArea = ParcelsHelper.getAreaFromFeature(this.pindropLayer.toGeoJSON());

            // ↓ Association => la zone déssinée se trouve t elle sur une ou plusieurs parcelles ? ↓
            let parcelPolygonLayerArray = this.props.renderMapsCmp.parcelPolygonLayerGroup.getLayers();
            for (let index = 0; index < parcelPolygonLayerArray.length; index++) {
                let linkedParcel = ParcelsHelper.getDatasFrom2PolygonsIntersection(this.pindropLayer, parcelPolygonLayerArray[index]);
                if (linkedParcel) linkedParcelList.push(linkedParcel); // linkedParcel => { ...parcelInfos }
            }
            linkedParcelList.sort((a, b) => (b.area - a.area)); // tri => l'aire la plus grande en premier => en lien avec la présentation

            // ↓ customisation du layer ↓
            this.pindropLayer.setStyle({ className: ObservationsHelper.getObservationAreaClassFromObservationTypeId(1) });
        }

        // ↓ POINT D'OBSERVATION => MARKER ↓
        else if (layerType === "marker") {

            // ↓ Association => le point déssinée= se trouve t il sur une parcelles ? ↓
            let parcelPolygonLayerArray = this.props.renderMapsCmp.parcelPolygonLayerGroup.getLayers();
            for (let index = 0; index < parcelPolygonLayerArray.length; index++) {
                let linkedParcel = ParcelsHelper.pointsWithinPolygon(this.pindropLayer, parcelPolygonLayerArray[index]);
                if (linkedParcel) linkedParcelList.unshift(linkedParcel); // linkedParcel => { ...parcelInfos }
            }
        }
        else {/*RAS*/ }

        // ↓ ajout du nouveau layer sur la carte - provisoire ↓
        this.props.renderMapsCmp.map.addLayer(this.pindropLayer);

        // ↓ Affichage du formulaire de création d'observation - Responsive pris en charge (popup/dialogue)↓
        if (window.innerWidth > 600) {

            // ↓ configuration et affichage d'une popup ↓
            let popup = L.DomUtil.create('div', 'pdp-container');
            const root = createRoot(popup); // createRoot(container!) if you use TypeScript
            if (root) {
                root.render(
                    <ThemeProvider theme={theme}>
                        <Provider store={storeManager.store}> {/*obligatoire sinon pas de connexion au store*/}
                            <PinDropFormPopup
                                layerType={layerType}
                                pindropLayer={this.pindropLayer}
                                pinDropLayerArea={pinDropLayerArea}
                                linkedParcelList={linkedParcelList}
                                savePinDropGeometry={this.savePinDropGeometry}
                                deletePinDropGeometry={this.deletePinDropGeometry}
                            />
                        </Provider>
                    </ThemeProvider>);
            }
            layer.bindPopup(popup);
            layer.on('popupclose', this.deletePinDropGeometry);
            layer.on('popupopen', (e) => {
                var px = this.props.renderMapsCmp.map.project(e.target._popup._latlng); // recupere la localisation du marker en pixel
                const size = this.props.renderMapsCmp.map.getSize(); //recupere la taille du rendu de la map
                px.y -= (size.y / 2) - 50; // fait en sorte que la pointe du markeur se situe à 50px du bas du rendu de la map
                this.props.renderMapsCmp.map.panTo(this.props.renderMapsCmp.map.unproject(px), { animate: true }); // deplace la map vers la localisation souhaiter
            });
            layer.openPopup();
        }
        else {
            // ↓ ouverture d'une fenêtre de dialogue en fullscreen ↓
            this.props.openPinDropFormDialog({
                layerType: layerType,
                pindropLayer: this.pindropLayer,
                pinDropLayerArea: pinDropLayerArea,
                linkedParcelList: linkedParcelList,
                savePinDropGeometry: this.savePinDropGeometry,
                deletePinDropGeometry: this.deletePinDropGeometry,
                image: this.state.image,
            })
        }
    }

    /* fonction permettant de sauvegarder le nouveau layer et de l'afficher sur la carte (précisément dans le groupe de layer associé) */
    savePinDropGeometry(observationToSave) {

        return this.props.saveObservation(observationToSave)
            .then((isSuccess) => {
                if ((isSuccess) && (this.pindropLayer && this.props.renderMapsCmp.map.hasLayer(this.pindropLayer))) {

                    // retire le layer provisoire de la map (il sera ajouter à l'update du renderMaps)
                    this.pindropLayer.off('popupclose', this.deletePinDropGeometry);
                    this.pindropLayer.closePopup();
                    this.pindropLayer.unbindPopup();
                    this.props.renderMapsCmp.map.removeLayer(this.pindropLayer);
                    if (this.pindropLayer.bringToFront) this.pindropLayer.bringToFront(); //@@ Il y a un intérêt de faire cela ? (car on vient de le retirer de la map!)

                    this.pindropLayer = null;

                    // ↓ smartphone - fermeture de la fenêtre de dialogue ↓
                    if (this.props.pinDropFormDialogOpened)
                        this.props.closePinDropFormDialog();

                    return true;
                } else {
                    return false;
                }

            })
            .catch((error) => {
                //this.deletePinDropGeometry(); //@@ à changer par un message d'erreur
                return false;
            })
    }

    /* fonction permettant de supprimer le nouveau layer précèdemment créé */
    deletePinDropGeometry() {
        if (this.pindropLayer && this.props.renderMapsCmp.map.hasLayer(this.pindropLayer)) {
            this.props.renderMapsCmp.map.removeLayer(this.pindropLayer);
            this.pindropLayer = null;
            this.props.resetLastObservationIdSaved();

            // ↓ smartphone - fermeture de la fenêtre de dialogue ↓
            if (this.props.pinDropFormDialogOpened)
                this.props.closePinDropFormDialog();
        }
    }

    /* fonction permettant de créer une observation sur la localisation du client */
    createObservationOnLocation = () => {
        if (this.state.drawMarkerEnabled) return; // évite le multi click (la fonctinnalité est déjà activée)
        if (this.props.renderMapsCmp.DRAWINGPARCEL === true) return; // on est déjà sur un dessin de parcelle @@ l'utiliser dans redux

        if (this.state.drawPolygonEnabled)
            this.onStopDrawPolygon();

        // création du point d'observation avec les coordonnées gps de l'utilisateur
        this.onCreateDrawGeometry({
            layerType: "marker",
            layer: L.marker(this.props.geolocationCircleLayers.getLayers()[1].getLatLng()),
        });
    }

    handleClickObservationImage = () => {
        this.isInputFileOpenForIdentification = false;
        this.inputFileRef.current.click();

        // prévient le parent ('SpeedDial') qu'il peut se fermer, malgrè que l'on va seuelemnt ouvrir la dialoge associé
        // car l'écran dispose de ses propres boutons de validation / annulation ! 
        if (this.props.onSpeedDialCanClose) { 
            this.props.onSpeedDialCanClose(true);
        }
    }

    handleClickIdentification = () => {
        this.isInputFileOpenForIdentification = true;
        this.inputFileRef.current.click();

        // prévient le parent ('SpeedDial') qu'il peut se fermer, malgrè que l'on va seuelemnt ouvrir la dialoge associé
        // car l'écran dispose de ses propres boutons de validation / annulation ! 
        if (this.props.onSpeedDialCanClose) { 
            this.props.onSpeedDialCanClose(true);
        }
    }

    onInputFileChange = (event) => {
        const { clientId, openIdentificationDialog, goToIdentification, closeIdentificationDialog } = this.props;
        let imgFile = event.target.files[0]; // récupération du flux de l'image

        fileHelper.getDataFromJpegImage(imgFile)
            .then((informationImage) => {
                if (this.isInputFileOpenForIdentification) {
                    let images = [
                        new IdentificationImage({
                            clientId: clientId,
                            imageData: [...informationImage.imageData],
                            imageUrl: informationImage.imageUri,
                            organ: 0,
                        }),
                    ];
                    goToIdentification(images, this.createObservation, closeIdentificationDialog);
                    openIdentificationDialog();
                } else {
                    // création de l'objet image
                    this.setState({
                        image: new ObservationImage({
                            observationId: -1,
                            clientId: clientId || -1,
                            date: Date.now(),
                            width: informationImage.width,
                            height: informationImage.height,
                            imageData: [...informationImage.imageData],
                            imageUrl: informationImage.imageUri,
                            vignetteUrl: informationImage.imageUri,
                        }),
                    }, () => {
                        this.createObservation();
                    });
                }
            })
            .catch();
    }

    createObservation = () => {
        // après mise à jour du state
        if (this.props.geolocationCircleLayers !== undefined) {
            this.createObservationOnLocation();
        } else {
            // lance la création d'un point d'observation
            this.onStartDrawMarker();
        }
    }

    /* fonction cycle de vie */
    componentDidUpdate(prevProps) {
        //↓↓ CAS - CHANGEMENT DE LANGUE - du fait que maintenant on rentre dans l'application sans attendre les informations du settings... ↓↓
        if (prevProps.settings !== this.props.settings) {
            L.drawLocal = leafletfunction();
        }
    }

    /* fonction cycle de vie */
    render() {

        const actions = [
            {
                icon: <PlaceIcon style={{ fontSize: 24, color: theme.palette.primary.main }} />,
                name: `${StringTranslate.createpindropmarker}`,
                onClick: this.onStartDrawMarker
            },
            {
                icon: <PinDropAreaIcon style={{ fontSize: 24, color: theme.palette.primary.main }} />,
                name: `${StringTranslate.createpindroppolygon}`,
                onClick: this.onStartDrawPolygon
            }
        ];

        return (
            <React.Fragment>
                {/* ↓ bouton mobile - créer une observation avec une image ↓ */}
                {(window.innerWidth < 600) &&
                    <div>
                        <label htmlFor="raised-button-file">
                            <SpeedDialAction
                                key={StringTranslate.createpindropimage}
                                icon={<AddAPhotoIcon style={{ color: theme.palette.primary.main }}  />}
                                tooltipTitle={StringTranslate.createpindropimage}
                                tooltipPlacement="bottom"
                                onClick={this.handleClickObservationImage}
                                sx={{
                                    ...((this.props.class === true) && {
                                        opacity: "100",
                                        transform: "none",
                                        m:0,
                                        ml:1
                                    })
                                }}
                            />
                            <SpeedDialAction
                                key={"Faire une identification"}
                                icon={<IconIdentification sx={{m:"0 !important"}} stroke={theme.palette.primary.main} />}
                                tooltipTitle={StringTranslate.createpindropimage}
                                tooltipPlacement="bottom"
                                onClick={this.handleClickIdentification}
                                sx={{
                                    color:theme.palette.primary.dark,
                                    ...((this.props.class === true) && {
                                        opacity: "100",
                                        transform: "none",
                                        m:0,
                                        ml:1
                                    })
                                }}
                            />
                        </label>
                        <input
                            ref={this.inputFileRef}
                            accept=".jpeg,.jpg"
                            id="raised-button-file"
                            type="file"
                            className="input"
                            value=''
                            onChange={this.onInputFileChange}
                        />
                    </div>
                }

                {/* ↓ boutons - fonctionnalité création d'un point/d'une aire d'observation ↓ */}
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={<Typography color="inherit">{action.name}</Typography>}
                        tooltipPlacement="bottom"
                        onClick={action.onClick}
                        sx={{
                            ...((this.props.class === true) && {
                                opacity: "100",
                                transform: "none",
                                m:0,
                                ml:1
                            })
                        }}
                    />
                ))}

                {(this.state.drawMarkerEnabled) &&
                    <ButtonGroup
                        variant="contained"
                        color="primary"
                        variant_pz="btns-grp-drawAndobs">
                        <Button onClick={this.onStopDrawMarker}>
                            {StringTranslate.annuler}
                        </Button>
                    </ButtonGroup>}

                {(this.state.drawPolygonEnabled) &&
                    <ButtonGroup 
                        variant="contained" 
                        variant_pz="btns-grp-drawAndobs">
                        {/* Boutons à afficher pour le desktop */}
                        <Button onClick={this.onStopDrawPolygon}
                            sx={{ 
                                [theme.breakpoints.down('md')]: { display: 'none' }
                             }}>
                            {StringTranslate.annuler}
                        </Button>
                        <Button onClick={this.deleteLastPointDrawPolygon}
                            sx={{
                                [theme.breakpoints.down('md')]: { display: 'none' }
                            }}>
                            {StringTranslate.supppoint}
                        </Button>
                        <Button onClick={this.completeShapeDrawPolygon} 
                            sx={{
                                [theme.breakpoints.down('md')]: { display: 'none' }
                            }}>
                            {StringTranslate.terminer}
                        </Button>

                        {/* Boutons à afficher pour le mobile */}
                        <Button onClick={this.onStopDrawPolygon}
                            sx={{ 
                                [theme.breakpoints.up('md')]: { display: 'none' }
                            }}>
                            <CancelIcon />    
                        </Button> 
                        <Button onClick={this.deleteLastPointDrawPolygon}
                            sx={{
                                [theme.breakpoints.up('md')]: { display: 'none' }
                            }}>
                            <UndoIcon />
                        </Button>
                        <Button 
                            onClick={this.completeShapeDrawPolygon}
                            sx={{
                                [theme.breakpoints.up('md')]: { display: 'none' }
                            }}>
                            <DoneIcon />
                        </Button>
                    </ButtonGroup>}

            </React.Fragment>
        )
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant */
const mapStateToProps = function (state) {
    return {
        clientId: (state.clientUserData && state.clientUserData.clientDatas) ? state.clientUserData.clientDatas.id : -1,
        settings: state.settingsData.settings, // seul moyen trouvé pour rafraichir le visuel (traduction) du fait que maintenant on rentre dans l'application sans attendre les informations du client
        enableDrawMarkerPindrop: state.contextAppData.enableDrawMarkerPindrop,
        enableDrawAreaPindrop: state.contextAppData.enableDrawAreaPindrop,
        pinDropFormDialogOpened: state.contextAppData.pinDropFormDialogOpened, // état de la fenêtre de dialogue utilisée dans la création d'observation
        geolocationCircleLayers: state.contextAppData.geolocationCircleLayers,
        observationTypeDico: state.observationsData.observationTypeDico, // récupération de la liste de type d'observation
    };
}

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
    saveObservation: (observation) => dispatch(actionSaveObservationToAPI(observation)),
    disableDrawMarkerPindrop: () => dispatch(ActionDisableDrawMarkerPindrop()),
    disableDrawAreaPindrop: () => dispatch(ActionDisableDrawAreaPindrop()),
    openPinDropFormDialog: (obj) => dispatch(ActionOpenPinDropFormDialog(obj)),
    closePinDropFormDialog: () => dispatch(ActionClosePinDropFormDialog()),
    resetLastObservationIdSaved: () => dispatch(ActionResetLastObservationIdSaved()),
    showSnackbarCreateZoneOrMarker: (text) => dispatch(ActionShowSnackbarCreateZoneOrMarker(text)),
    openIdentificationDialog: () => dispatch(ActionOpenIdentificationDialog()),
    goToIdentification: (images, createObservation, closeIdentificationDialog) => dispatch(ActionGoToIdentification(images, createObservation, closeIdentificationDialog)),
    closeIdentificationDialog: () => dispatch(ActionCloseIdentificationDialog()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PinDropControl);