///////////////////////////////////////////////////////////////////////////
// Import des éléments utilisés par le composant <AbstractParcels>
///////////////////////////////////////////////////////////////////////////
import PropTypes from 'prop-types';
import detailsParcel from './detailsParcel';
import { ConstantsLayers } from '../utils/constantsOfLayers.js';
import { ModelisationModelEnum } from '../redux/actions/parcels';


///////////////////////////////////////////////////////////////////////////
// classe POCO représenteant les infos sur une parcelle.
///////////////////////////////////////////////////////////////////////////
class Parcel extends Object {
    ///////////////////////////////////////////////////////////////////////////
    // constructeur
    /////////////////////////////////////////////////////////////////////////// 
    constructor(props) {
        super(props);

        if (props !== undefined) {
            this.id = props.id || 0;
            this.clientId = props.clientId || 0;
            this.farm = props.farm || '';
            this.name = props.name || '';
            this.geometryType = props.geometryType || '';
            this.coordinates = props.coordinates || '';
            this.properties = props.properties || '';
            this.details = props.details || null;
            this.isDetailConverted = props.isDetailConverted || false;
            this.origin = props.origin || '';
            this.enumOrigin = props.enumOrigin || null;
            this.epsg = props.epsg || 0;
            this.xmin = props.xmin || 0;
            this.ymin = props.ymin || 0;
            this.xmax = props.xmax || 0;
            this.ymax = props.ymax || 0;
            this.bBoxStr4326 = props.bBoxStr4326 || '';
            this.polygonStr4326 = props.polygonStr4326 || '';
            this.dateImport = props.dateImport || Date.now();
            this.isActive = props.isActive || true;
            this.modelisationModel = props.modelisationModel || ModelisationModelEnum.Unknown;

            //Propriétés ajoutées  côté ReactJs: 
            this.currentSatimageId = props.currentSatimageId || -1; // propriété de contexte
            this.currentSatimageDate = props.currentSatimageDate || undefined; // propriété de contexte
            this.currentLayerType = props.currentLayerType || ConstantsLayers.NdviParcelLayerName; // propriété de contexte
            this.firstSatimageDate = props.firstSatimageDate || undefined; // propriété de contexte
        }
        else {
            this.id = 0;
            this.clientId = 0;
            this.farm = '';
            this.name = '';
            this.geometryType = '';
            this.coordinates = '';
            this.properties = '';
            this.details = null;
            this.isDetailConverted = false;
            this.origin = '';
            this.enumOrigin = null;
            this.epsg = 0;
            this.xmin = 0;
            this.ymin = 0;
            this.xmax = 0;
            this.ymax = 0;
            this.bBoxStr4326 = '';
            this.polygonStr4326 = '';
            this.dateImport = Date.now();
            this.isActive = true;
            this.modelisationModel = ModelisationModelEnum.Unknown;

            //Propriétés ajoutées  côté ReactJs: 
            this.currentSatimageId = -1; // propriété de contexte
            this.currentSatimageDate = undefined; // propriété de contexte
            this.currentLayerType = ConstantsLayers.NdviParcelLayerName;
            this.firstSatimageDate = undefined; // propriété de contexte
        }
    };
};

export default Parcel;

Parcel.defaultProps = {};
Parcel.propTypes = {
    id: PropTypes.number,
    clientId: PropTypes.number,
    farm: PropTypes.string,
    name: PropTypes.string,
    geometryType: PropTypes.string,
    coordinates: PropTypes.string,
    properties: PropTypes.string,
    details: PropTypes.instanceOf(detailsParcel),
    isDetailConverted: PropTypes.bool,
    origin: PropTypes.string,
    enumOrigin: PropTypes.number,
    epsg: PropTypes.number,
    xmin: PropTypes.number,
    ymin: PropTypes.number,
    xmax: PropTypes.number,
    ymax: PropTypes.number,
    bBoxStr4326: PropTypes.string,
    polygonStr4326: PropTypes.string,
    dateImport: PropTypes.instanceOf(Date),
    modelisationModel: PropTypes.oneOf([ModelisationModelEnum.Unknown, ModelisationModelEnum.Ble, ModelisationModelEnum.Colza]),

    //Propriétés ajoutées  côté ReactJs: 
    isActive: PropTypes.bool,
    currentSatimageId: PropTypes.number,
    currentSatimageDate: PropTypes.instanceOf(Date),
    currentLayerType: PropTypes.oneOf([ConstantsLayers.NdviParcelLayerName, ConstantsLayers.VisibleParcelLayerName]), 
};
