import { ActionTypeFertilizer, FertilizerStep, TabAbstractFertilizer, FertilizerEngineEnum } from '../actions/fertilizer';
import ConstantsLayers, { GetBaseLayerIndex, GetLastLayerIndex } from '../../utils/constantsOfLayers.js';
import fertilizerHelper from '../../utils/fertilizerHelper.js';
import lodashIsNil from 'lodash/isNil';

/* state initial */
const initialState = {

    loading: false, //permettra de mettre une waiting cursor dans le tableau et le sidebar...
    readableMode: false, //permet de visualiser le paramétrage d'une pesée de la fumure sans rien pouvoir en changer (mode consultation).
    fertilizerStep: FertilizerStep.CULTURE_AND_PARCELS_CHOICE,
    deleting: false, //Suppression des données de fumure en cours

    specificInfosAzofertManagementActivation: false, //Activation du dialog azofert pour afficher les données azofert
    specificInfosFertiwebManagementActivation: false, //Activation du dialog fertiweb pour afficher les données fertiweb
    infoCurrentSelectedParcel: undefined,

    errorMessage: undefined,
    building: false, //permettra de mettre une waiting cursor,

    cropYearsSelectedForFilterFertilizer: [], //Années de campagne sélectionnées dans le filtre à l'onglet 2

    /* Dico fertilizer pour les fumures en cours (actuelles) */
    fertilizerDico: {}, //dico dont la clef est l'ID de parcelle associée - valeur l'entité 'Fertilizerdata'. => Uniquement les pesée de la fumure complète en BdD.
    fertilizerDicoCounter: 0,
    fertilizerDicoIsLoaded: false, //permet de savoir si le dico a déjà été chargé
    fertilizerDicoIsLoading: false, //permet de savoir si le dico des données spécifiques est en cours de chargement

    askingAzofertDatesRange: true,

    parcelIdsSelected: [], //parcelles id sélectionnées pour commencer la fumure
    parcelIdSelected: undefined,
    culturesForFertilizing: [], //cultures pour la fumure
    cultureSelected: undefined, //culture choisie pour commencer la fumure

    downloading: false, //permettra de mettre une waiting cursor,

    openCongratulationsDialog: false,

    generatingFile: false, //permettra de mettre un waiting cursor lors de la création/téléchargement des fichiers de FUMURE.

    fertilizerEngine: undefined, 
    askingFertilizerEngine: false,

    fertilizerListsDico: {}, //une liste pour chaque moteur de fumure. (objectif de production, gestion des résidues, habitude de fréquence des cultures intermédiaire, FertilizersType, OrganicInputFrequencies, OrganicInputTypes, InterCropTypes, TurningOverGrasslandFrequencies, GrasslandDuration)
    fertilizerCommonLists: undefined, //(objectif de production, gestion des résidues, habitude de fréquence des cultures intermédiaire, FertilizersType, OrganicInputFrequencies, InterCropTypes, TurningOverGrasslandFrequencies, GrasslandDuration) //listes sportifarm
    fertilizerListsAsked: false, //Signale que les listes pour générer un conseil azoté ont été chargées

    specificInfosLists: undefined, //listes chargées pour le popup de saisie spécifique
    fertilizerSpecificList_TypeDeSolDico: { }, //chaque moteur de fumure a sa liste de type de sol
    fertilizerSpecificList_VarieteDico: { }, // chaque moteur de fumure a sa liste de variéié
    fertilizerSpecificList_VarieteProtDico: { },// chaque moteur de fumure a sa liste de variéié de protéine
    fertilizerSpecificList_TypeDeSol_awaitingAnAnswer: false,
    fertilizerSpecificList_Variete_awaitingAnAnswer:false,

    commonInfosManagementSaving: false, //sauvegarde en cours des données communes sur l'étape 2
    askingCommonInfosAndListsByCulture: false, 

    specificInfosManagementSaving: false, //sauvegarde en cours des données spécifiques sur l'étape 3
    specificInfos: undefined, //données spécifiques qu'on utilise après la sauvegarde des données spécifiques par exemple

    commonInfosByCulture: undefined,
    fertilizerSelected: undefined,
    fertilizerShowed: undefined, //Fumure sélectionnée à partir de l'onglet "Mes conseils azotés" dans le tableau
    /* commonInfosByCulture: {
        id: 0,
        idClient: 0,
        idCulture: 0,
        harvestDate: undefined,
        sowingDate: undefined,
        productionTarget: undefined,
        performanceGoal: undefined,
        intermediateCultureFrequency: undefined,
        tailingsManagement: undefined
    }, */

    settings: { //settings for map Fertilizer
        lastLayerForFertilizer: 4, //correspond à 'ConstantsLayers.NdviParcelLayerName'
        baseLayerForFertilizer: 'satellite',
        showNdviCharts: true,
        isPanelOpened: true, //RQ : Pour le moment, on n'autorise pas son 'masquage' !
    },

    //Choix des couches: 
    baseLayerSelectedForFertilizer: ConstantsLayers.VisibleBaseLayerName, // VisibleBaseLayerName ou RoadBaseLayerName !
    forewardLayerSelectedForFertilizer: ConstantsLayers.NdviParcelLayerName, // NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
    
    showFertilizerMap: false,
    askBeginningWinterFirst: true, //Demande de la date de début d'hiver en premier

    updatingCropInParcel: false, //Mise à jour du nom de la culture dans les données de la parcelle

    valueTabForAbstractFertilizer: 0, //tab par défaut quand on arrive sur la page des fumures (AbstractFertilizer)

    downloadingPrescriptions: false, //téléchargement des prescriptions zip en cours

    /* Plage de dates de clôture de Azofert */
    closingDateAzofert: undefined,
    openingDateAzofert: undefined,

    askingSpecificInfosArchived: false, //pour la demande de chargement de la fumure de la campagne de l'année passée
    archivedFertilizer: undefined, //Conseil validé archivé de l'année passée.
    askingDatasToShowSpecificInfosManagement: false,
    fertilizersArchivedDataDico: {}, //Dico des données archivées des parcelles qui ont déjà été demandées

    loadedVariety: null, // varieté enventuellement recuperée
    varietyPending: false // chargement de la varieté en cours
};

function fertilizerManagerAction(state = initialState, action) {
    switch (action.type) {

        case ActionTypeFertilizer.ERROR_ASK_FERTILIZER_DATAS: {
            return Object.assign({}, state, {
                errorMessage: action.error,
                specificInfosManagementSaving: false,
            });
        }

        case ActionTypeFertilizer.ERROR_ASK_FERTILIZER_ENGINE: {
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
                askingFertilizerEngine: false,
                fertilizerListsAsked: false,
                askingSpecificInfosArchived: false,
                askingDatasToShowSpecificInfosManagement: false,
                fertilizerSpecificList_TypeDeSol_awaitingAnAnswer: false
            });
        }

        case ActionTypeFertilizer.ASKING_FERTILIZER_ENGINE: {
            return Object.assign({}, state, {
                askingFertilizerEngine: true,
                specificInfosAzofertManagementActivation: false,
                specificInfosFertiwebManagementActivation: false,
            });
        }

        case ActionTypeFertilizer.EndFertilizerUpdate: {
            return Object.assign({}, state, {
                errorMessage: undefined,
                specificInfosManagementSaving: false,
            });
        }
        
        case ActionTypeFertilizer.GO_TO_STEP_OF_FERTILIZER: {
            return Object.assign({}, state, {
                loading: false,
                errorMessage: undefined,
                fertilizerStep: action.fertilizerStep,
                askBeginningWinterFirst: action.askBeginningWinterFirst,
                showFertilizerMap: (action.fertilizerStep === FertilizerStep.MANAGEMENT_NITROGEN) ? false : (action.fertilizerStep === FertilizerStep.RESULT) ? false : state.showFertilizerMap,
                building: 
                    ( (action.fertilizerStep === FertilizerStep.MANAGEMENT_NITROGEN) &&
                    (action.fertilizerStep === FertilizerStep.CULTURE_AND_PARCELS_CHOICE) ) ? 
                        false : 
                        state.building,
                readableMode: action.readableMode,
            });
        }

        case ActionTypeFertilizer.SET_STEP_DATE_WINTER: {
            return Object.assign({}, state, {
                askBeginningWinterFirst: action.askBeginningWinterFirst,
            });
        }

        case ActionTypeFertilizer.SHOW_SPEC_INFOS_MANAGEMENT: {
            return {
                ...state,
                specificInfosAzofertManagementActivation: (state.fertilizerEngine === FertilizerEngineEnum.AZOFERT),
                specificInfosFertiwebManagementActivation: (state.fertilizerEngine === FertilizerEngineEnum.FERTIWEB),
                infoCurrentSelectedParcel: action.infoCurrentSelectedParcel,
                askingDatasToShowSpecificInfosManagement: false,
            }
        }

        case ActionTypeFertilizer.closeSpecificInfosManagement: {
            return {
                ...state,
                specificInfosAzofertManagementActivation: false,
                specificInfosFertiwebManagementActivation: false,
                infoCurrentSelectedParcel: undefined,
                fertilizerEngine: undefined,
            }
        }

        case ActionTypeFertilizer.SELECT_PARCELS_FOR_FERTILIZING: {
            if (action.parcelIdsSelected) {
                
                return Object.assign({}, state, {
                    errorMessage: undefined,
                    parcelIdsSelected: action.parcelIdsSelected,
                    cultureSelected: action.cultureSelected,
                });
            }

            return state;
        }

        case ActionTypeFertilizer.SELECT_CULTURE: {
            return Object.assign({}, state, {
                cultureSelected: action.crop
            });
        }

        case ActionTypeFertilizer.GET_CULTURES: {
            return Object.assign({}, state, {
                culturesForFertilizing: action.culturesForFertilizing,
            });
        }

        case ActionTypeFertilizer.GET_COMMON_INFOS_BY_CULURE: {
            return Object.assign({}, state, {
                commonInfosManagementSaving: false,
                commonInfosByCulture: action.commonInfosByCulture
            });
        }

        case ActionTypeFertilizer.ASK_SPECIFIC_INFOS_TYPEDESOL: {
            return Object.assign({}, state, {
                fertilizerSpecificList_TypeDeSol_awaitingAnAnswer: true,
            })
        }

        case ActionTypeFertilizer.ASK_SPECIFIC_INFOS_VARIETE: {
            return Object.assign({}, state, {
                fertilizerSpecificList_Variete_awaitingAnAnswer: true,
            })
        }

        case ActionTypeFertilizer.GET_SPECIFIC_INFOS_TYPEDESOL: {
            return Object.assign({}, state, {
                fertilizerSpecificList_TypeDeSolDico:{
                    ...state.fertilizerSpecificList_TypeDeSolDico,
                    [action.fertilizerEngine]: action.fertilizerSpecificList_TypeDeSol
                },
                fertilizerSpecificList_TypeDeSol_awaitingAnAnswer: false,
            })
        }

        case ActionTypeFertilizer.GET_SPECIFIC_INFOS_VARIETE: {
            return Object.assign({}, state, {
                fertilizerSpecificList_VarieteDico: {
                    ...state.fertilizerSpecificList_VarieteDico,
                    [action.fertilizerEngine]: action.fertilizerSpecificList_Variete
                },
                fertilizerSpecificList_Variete_awaitingAnAnswer: false,
            })
        }
        
        case ActionTypeFertilizer.GET_SPECIFIC_INFOS_VARIETE_PROT: {
            return Object.assign({}, state, {
                fertilizerSpecificList_VarieteProtDico: {
                    ...state.fertilizerSpecificList_VarieteProtDico,
                    [action.fertilizerEngine]: action.fertilizerSpecificList_VarieteProt
                },
                fertilizerSpecificList_Variete_awaitingAnAnswer: false,
            })
        }

        case ActionTypeFertilizer.ERROR_LOAD_CULTURES: {
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
            });
        }

        case ActionTypeFertilizer.CLEAR_FERTILIZER_VALUE: {
            return Object.assign({}, state, {
                generatingFile: false,
                downloading: false,
                building: false,
                loading: false,
                errorMessage: undefined,
                openCongratulationsDialog: false,
                //parcelIdsSelected: [],
                parcelIdSelected: undefined,
                commonInfosManagementSaving: false,
                commonInfosByCulture: undefined,
                showFertilizerMap: false,
                //fertilizerSelected: undefined, //Pas nécessaire 
            });
        }

        case ActionTypeFertilizer.GENERATING_FERTILIZER_FILE: {
            return Object.assign({}, state, {
                errorMessage: undefined,
                generatingFile: true,
            });
        }

        case ActionTypeFertilizer.FERTILIZER_FILE_GENERATED: {
            return Object.assign({}, state, {
                generatingFile: false,
            });
        }

        case ActionTypeFertilizer.CLEAN_ERROR: {
            return Object.assign({}, state, {
                loading: false, 
                downloading: false, 
                building: false, 
                errorMessage: undefined,
                readableMode: false,
                downloadingPrescriptions: false,
                //fertilizerDicoIsLoaded: false,
                fertilizerDicoIsLoading: false,
            });
        }

        case ActionTypeFertilizer.SAVING_COMMON_INFOS: {
            return Object.assign({}, state, {
                errorMessage: undefined,
                commonInfosManagementSaving: action.commonInfosManagementSaving,
            });
        }

        case ActionTypeFertilizer.SAVING_SPECIFIC_INFOS: {
            return Object.assign({}, state, {
                errorMessage: undefined,
                specificInfosManagementSaving: action.specificInfosManagementSaving,
            });
        }

        case ActionTypeFertilizer.COMMON_INFOS_SAVED: {
            return Object.assign({}, state, {
                commonInfosManagementSaving: action.commonInfosManagementSaving,
                commonInfosByCulture: action.commonInfosByCulture
            });
        }

        case ActionTypeFertilizer.SPECIFIC_INFOS_SAVED: {
            //S'il y a une donnée à enregistrer :
            if ((action.specificInfos !== null) && (action.specificInfos !== undefined)) {
                let specificInfosFound = false;
                
                //Si le dico possède déjà des fumures :
                if (state.fertilizerDico && (state.fertilizerDicoCounter > 0)) {
    
                    for (const key in state.fertilizerDico) {
                        if (state.fertilizerDico[key].idParcel === action.specificInfos.idParcel) {
                            specificInfosFound = true;
                            state.fertilizerDico[key] = action.specificInfos;
                        }
                    }
                }
                
                //Si la fumure est inexistante dans le dico :
                if (specificInfosFound === false) {
                    state.fertilizerDico[action.specificInfos.id] = action.specificInfos;
                }
    
                return Object.assign({}, state, {
                    specificInfosManagementSaving: action.specificInfosManagementSaving,
                    specificInfos: action.specificInfos,
                    fertilizerDico: state.fertilizerDico,
                    fertilizerDicoCounter: fertilizerHelper.count(state.fertilizerDico)
                });
            }

            return state;
        }

        case ActionTypeFertilizer.INIT_FERTILIZER_LISTS: {
            return Object.assign({}, state, {
                askingCommonInfosAndListsByCulture: false,
                fertilizerListsDico: {
                    ...state.fertilizerListsDico,
                    [action.fertilizerEngine]: action.fertilizerLists
                }
            });
        }

        case ActionTypeFertilizer.INIT_FERTILIZER_COMMON_LISTS: {
            return Object.assign({}, state, {
                askingCommonInfosAndListsByCulture: false,
                fertilizerCommonLists: action.fertilizerCommonLists,
            });
        }

        case ActionTypeFertilizer.INIT_FERTILIZER_DATAS: {
            return Object.assign({}, state, {
                generatingFile: false,
                downloading: false,
                building: false,
                loading: false,
                errorMessage: undefined,
                openCongratulationsDialog: false,
                parcelIdsSelected: [],
                parcelIdSelected: undefined,
                commonInfosManagementSaving: false,
                commonInfosByCulture: undefined,
                showFertilizerMap: false,
                cultureSelected: undefined,
                valueTabForAbstractFertilizer: 0
            });
        }

        case ActionTypeFertilizer.COMMON_INFOS_AND_LISTS_BY_CULTURE_ASKED: {
            return Object.assign({}, state, {
                askingCommonInfosAndListsByCulture: false
            });
        }

        case ActionTypeFertilizer.ASKING_COMMON_INFOS_AND_LISTS_BY_CULTURE: {
            return Object.assign({}, state, {
                errorMessage: undefined,
                askingCommonInfosAndListsByCulture: true
            });
        }
        
        case ActionTypeFertilizer.SELECT_BEG_END_WINTER_DATES_BY_PARCELID: {
            return Object.assign({}, state, {
                parcelIdSelected: action.parcelIdSelected,
                showFertilizerMap: true,
                // Si on retourne en arrière depuis les résultats, alors on affiche la date de sortie hiver sinon l'entrée
                askBeginningWinterFirst: !action.isGoBack, 
            });
        }

        case ActionTypeFertilizer.SELECT_LAYER_FOR_FERTILIZER: {
            //baseLayerSelected peut valoir: ConstantsLayers.VisibleBaseLayerName, // VisibleBaseLayerName ou RoadBaseLayerName !
            //forewardLayerSelected peut valoir: NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
            let newBaseLayersSelected = action.baseLayertypeForFertilizer;
            if ((!newBaseLayersSelected) || (
                (newBaseLayersSelected !== ConstantsLayers.VisibleBaseLayerName) && (newBaseLayersSelected !== ConstantsLayers.RoadBaseLayerName))) {
                newBaseLayersSelected = ConstantsLayers.VisibleBaseLayerName;
            };
            let newForewardLayersSelected = action.forewardLayertypeForFertilizer;
            if ((!newForewardLayersSelected) || (
                (newForewardLayersSelected === ConstantsLayers.VisibleBaseLayerName) || (newForewardLayersSelected === ConstantsLayers.RoadBaseLayerName))) {
                newForewardLayersSelected = ConstantsLayers.NdviParcelLayerName;
            };

            return Object.assign({}, state, {
                errorMessage: undefined,
                settings: {
                    ...state.settings,
                    baseLayerForFertilizer: GetBaseLayerIndex(newBaseLayersSelected),
                    lastLayerForFertilizer: GetLastLayerIndex(newForewardLayersSelected),
                },
                baseLayerSelectedForFertilizer: newBaseLayersSelected,
                forewardLayerSelectedForFertilizer: newForewardLayersSelected,
            });
        }

        case ActionTypeFertilizer.SET_FERTILIZER_DICO: {
            return {
                ...state,
                fertilizerDico: action.dico,
            };
        }

        case ActionTypeFertilizer.UPDATE_SPECIFIC_INFO: {
            return {
                ...state,
                fertilizerDico: {
                    ...state.fertilizerDico,
                    [action.idParcel]: action.specificInfo,
                },
            };
        }

        case ActionTypeFertilizer.DELETING_FERTILIZER: {
            return Object.assign({}, state, {
                deleting: true,
                errorMessage: undefined,
                readableMode: false,
            });
        }

        case ActionTypeFertilizer.DELETING_FERTILIZERS: {
            return Object.assign({}, state, {
                deleting: true,
                errorMessage: undefined,
                readableMode: false,
            });
        }

        case ActionTypeFertilizer.DELETE_FERTILIZERS: {
            let newFertilizerDico = {};

            let fertilizers = state.fertilizerDico;
            let fertilizerDicoCounter = state.fertilizerDicoCounter;

            if (fertilizers && (fertilizerDicoCounter > 0) &&
                (action.parcelIdsDeleted !== undefined) && (action.parcelIdsDeleted.length > 0)) {
                const counterDeleteToGo = action.parcelIdsDeleted.length;
                for (const key in fertilizers) {
                    const fertilizerItem = fertilizers[key];
                    if (fertilizerItem) {
                        let isFound = false;

                        for (let indexI = 0; indexI < counterDeleteToGo; indexI++) {
                            let parcelId = action.parcelIdsDeleted[indexI];
                            if (fertilizerItem.idParcel === parcelId) {
                                isFound = true;
                                break; //sort de la boucle puisqu'on l'a trouvé !                        
                            } //else //passe à la comparaison suivante !
                        }
                        if (isFound === false) { //pas trouvé dans la liste de celle à supprimer => on la conserve !
                            newFertilizerDico[fertilizerItem.id] = fertilizerItem;
                        }
                    }
                }
            } else {
                newFertilizerDico = fertilizers; //Ne change rien !
            }

            return Object.assign({}, state, {
                deleting: false,
                errorMessage: undefined,
                fertilizerDico: newFertilizerDico,
                fertilizerDicoCounter: fertilizerHelper.count(newFertilizerDico),
            });
        }

        case ActionTypeFertilizer.ERROR_LOAD_FERTILIZERS: {
            return Object.assign({}, state, {
                loading: false,
                errorMessage: action.errorMessage,
                //readableMode: false, //on n'a pas à changer le mode en cas d'erreur ! //TODO: A vérifier sur autres cas et autres reducers !
                downloadingPrescriptions: false,
                //fertilizerDicoIsLoaded: false,
                fertilizerDicoIsLoading: false,
            });
        }

        case ActionTypeFertilizer.LOADING_FERTILIZERS: {
            return Object.assign({}, state, {
                loading: true,
                errorMessage: undefined,
                fertilizerDicoIsLoading: true,
            });
        }

        case ActionTypeFertilizer.LOAD_FERTILIZERS: {
            return Object.assign({}, state, {
                loading: false,
                errorMessage: undefined,
                fertilizerDico: action.fertilizerDico,
                fertilizerDicoCounter: fertilizerHelper.count(action.fertilizerDico),
                fertilizerDicoIsLoaded: true,
                fertilizerDicoIsLoading: false,
            });
        }

        case ActionTypeFertilizer.SELECT_FERTILIZER_ENGINE: {

            return Object.assign({}, state, {
                errorMessage: undefined,
                fertilizerEngine: action.fertilizerEngine,
                askingFertilizerEngine: false,
            });
        }

        case ActionTypeFertilizer.SELECT_FERTILIZER: {
            return Object.assign({}, state, {
                loading: false,
                errorMessage: undefined,
                readableMode: (action.readableMode !== undefined) ? action.readableMode : true,
                fertilizerSelected: action.fertilizerSelected,
                building: false,
            });
        }
        
        case ActionTypeFertilizer.SHOW_FERTILIZER: {
            return Object.assign({}, state, {
                loading: false,
                errorMessage: undefined,
                readableMode: (action.readableMode !== undefined) ? action.readableMode : true,
                fertilizerShowed: action.fertilizerShowed,
                building: false,
            });
        }

        case ActionTypeFertilizer.START_BUILD_FERTILIZER: {
            return Object.assign({}, state, {
                building: true,
                errorMessage: undefined,
            });
        }

        case ActionTypeFertilizer.END_BUILD_FERTILIZER: {
            return Object.assign({}, state, {
                building: false,
            });
        }

        case ActionTypeFertilizer.ERROR_BUILD_FERTILIZER: {
            return Object.assign({}, state, {
                building: false,
                errorMessage: action.errorMessage,
                readableMode: false,
            });
        }

        case ActionTypeFertilizer.UP_NEW_FERTILIZER: {
            
            state.fertilizerDico[action.newFertilizer.id] = action.newFertilizer;
            return Object.assign({}, state, {
                //incrémente 
                fertilizerDicoCounter: fertilizerHelper.count(state.fertilizerDico),
                fertilizerDico: state.fertilizerDico,
                //fertilizerDicoIsLoaded: false,
                building: false,
            });
        }

        case ActionTypeFertilizer.UPDATING_CROP_IN_PARCEL: {
            return Object.assign({}, state, {
                errorMessage: undefined,
                updatingCropInParcel: true
            });
        }

        case ActionTypeFertilizer.CROP_IN_PARCEL_UPDATED: {
            return Object.assign({}, state, {
                updatingCropInParcel: false,
            });
        }

        case ActionTypeFertilizer.ERROR_UPDATE_CROP_IN_PARCEL: {
            return Object.assign({}, state, {
                updatingCropInParcel: false,
                errorMessage: action.errorMessage,
            });
        }

        case ActionTypeFertilizer.UPDATING_DOSE_SAVED_AND_ZONES: {
            return Object.assign({}, state, {
                building: true,
                errorMessage: undefined,
            });
        }

        case ActionTypeFertilizer.DOSE_SAVED_AND_ZONES_UPDATED: {
            return Object.assign({}, state, {
                building: false,
            });
        }

        case ActionTypeFertilizer.ERROR_UPDATE_DOSE_SAVED_AND_ZONES: {
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
                building: false,
            });
        }

        case ActionTypeFertilizer.UPDATE_DOSE_SAVED_AND_ZONES: {
            state.fertilizerDico[action.fertilizerSelected.id] = action.fertilizerSelected;

            return Object.assign({}, state, {
                fertilizerSelected: action.fertilizerSelected,
                fertilizerDico: state.fertilizerDico,
                building: false,
            });
        }

        case ActionTypeFertilizer.SET_VALUE_TAB_FOR_ABSTRACT_FERTILIZER: {
            return Object.assign({}, state, {
                valueTabForAbstractFertilizer: action.valueTabForAbstractFertilizer,
                readableMode: (action.valueTabForAbstractFertilizer === TabAbstractFertilizer.FERTILIZERS_LIST) ? true : false,
            });
        }

        case ActionTypeFertilizer.DOWNLOADING_FERTILIZER_FOR_PRESCRIPTION: {
            return Object.assign({}, state, {
                errorMessage: undefined,
                downloadingPrescriptions: true
            });
        }

        case ActionTypeFertilizer.DOWNLOADED_FERTILIZER_FOR_PRESCRIPTION: {
            return Object.assign({}, state, {
                downloadingPrescriptions: false
            });
        }

        case ActionTypeFertilizer.INIT_AZOFERT_DATES_RANGE: {
            return Object.assign({}, state, {
                closingDateAzofert: action.closingDateAzofert,
                openingDateAzofert: action.openingDateAzofert,
                askingAzofertDatesRange: false,
            });
        }

        case ActionTypeFertilizer.ASKING_AZOFERT_DATES_RANGE: {
            return Object.assign({}, state, {
                askingAzofertDatesRange: action.askingAzofertDatesRange,
            });
        }

        case ActionTypeFertilizer.SET_CROPYEARSSELECTED_FOR_VALIDATED_FERTILIZER: {
            return Object.assign({}, state, {
                cropYearsSelectedForFilterFertilizer: action.cropYearsSelectedFilter,
            });
        }

        case ActionTypeFertilizer.ASKING_SPECIFICINFOS_ARCHIVED: {
            return Object.assign({}, state, {
                askingSpecificInfosArchived: true,
                askingDatasToShowSpecificInfosManagement: true,
            });
        }

        case ActionTypeFertilizer.SPECIFICINFOS_ARCHIVED_ASKED: {
            return Object.assign({}, state, {
                askingSpecificInfosArchived: false,
            });
        }

        case ActionTypeFertilizer.SELECT_SPECIFICINFOS_ARCHIVED: {
            return Object.assign({}, state, {
                archivedFertilizer: action.archivedFertilizer,
                askingDatasToShowSpecificInfosManagement: false,
            });
        }

        case ActionTypeFertilizer.ERROR_ASK_FERTILIZER_ARCHIVED_DATAS: {
            return Object.assign({}, state, {
                askingSpecificInfosArchived: false,
                askingDatasToShowSpecificInfosManagement: false,
            })
        }

        case ActionTypeFertilizer.ADD_FERTILIZER_ARCHIVEDDATA: {
            return Object.assign({}, state, {
                fertilizersArchivedDataDico: {
                    ...state.fertilizersArchivedDataDico,
                    [action.archivedDataToAdd.parcelId]: action.archivedDataToAdd
                }
            })
        }
      
        case ActionTypeFertilizer.FERTILIZER_SPECIFIC_LISTS_ASKED: {
            return Object.assign({}, state, {
                fertilizerListsAsked: true,
            })
        }

        case ActionTypeFertilizer.FERTILIZER_SPECIFIC_LISTS_ASKING: {
            return Object.assign({}, state, {
                fertilizerListsAsked: false,
            })
        }

        case ActionTypeFertilizer.ASKING_PARCEL_VARIETY_PENDING: {
            return Object.assign({}, state, {
                varietyPending: true,
                loadedVariety: null,
            })
        }

        case ActionTypeFertilizer.SET_CURRENT_PARCEL_VARIETY: {
            if (state.fertilizerDico && (state.fertilizerDicoCounter > 0)) {

                for (const key in state.fertilizerDico) {
                    if (state.fertilizerDico[key].idParcel === action.parcelId) {
                        state.fertilizerDico[key].variety = action.variety;
                    }
                }
            }

            return Object.assign({}, state, {
                fertilizerDico: state.fertilizerDico,
                varietyPending: false,
                loadedVariety: action.variety === null ? {} : action.variety,
            })
        }

        case ActionTypeFertilizer.SET_PARCEL_VARIETY_PENDING: {
            return Object.assign({}, state, {
                varietyPending: false,
            })
        }

        case ActionTypeFertilizer.UPDATE_HAS_LAST_NITROGEN_INPUT: {
            if (!lodashIsNil(state.fertilizerDico[action.fertiId]))
                state.fertilizerDico[action.fertiId].hasLastNitrogenInput = action.hasLastNitrogenInput;
                return Object.assign({}, state, {
                    fertilizerDico: state.fertilizerDico,
                });
        }

        default:
            return state;
    }
}

export default fertilizerManagerAction;