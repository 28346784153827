import { ParcelsHelper } from '../../utils/parcelsHelper.js';
import { get as lodashGet, isNil as lodashIsNil, isEmpty as lodashIsEmpty, omitBy as lodashOmitBy, 
has as lodashHas, maxBy as lodashMaxBy /*, filter as lodashFilter, forEach as lodashForEach*/ } from 'lodash';
import { ActionSetFirstSatimageIdToParcel, ActionChangeSatimageIdToParcel } from '../actions/parcels.js';
import { historyWebApiProvider } from '../../utils/webApiProvider.js';
import { SatimageHelper } from '../../utils/satimageHelper.js';
import { addMonths /*, add as dateFnsAdd*/ } from 'date-fns';
import { SatimageState } from "../../models/stateYearMonthOfSatimages.js";
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import dateHelper from '../../utils/dateHelper';
import { ReasonInviteToPremium, ActionForceInviteToPremium, ActionSelectDateImg, ActionSelectDateImgParcel, ActionGoToMapAndSelectParcel } from '../actions/contextApp.js';
import { ActionAskTotalCurrentMonthHistoOfParcel } from '../actions/parcels.js';
import sendError from '../../utils/errorService.js';
import { SatImageSourceProvider } from '../../utils/constantsProvidersSatellite';
import ModelisationHelper from '../../utils/modelisationHelper';


//RQ Générale: Même remarque que pour la gestion des parcelles...
//  on aura sans nulle doute besoin d'appeler les actions définies ici ; 
//  -> les actions simples pour notifier le reducer associé (et donc la partie du store Redux stockant les infos des dates d'image).
//  -> l'actions complète (processus d'intérrogation de la Web API) pour du fonctionnel tel que la navigation dans le calendrier ou le carrousselle.

/* actions */
const ADD_ALL_LAST_SATIMAGE_BYPARCELID = 'ADD_ALL_LAST_SATIMAGE_BYPARCELID';

const LOADING_SATIMAGES_ALLPARCELS = 'LOADING_SATIMAGES_ALLPARCELS';
const SATIMAGES_LOADED_PARCEL = 'SATIMAGES_LOADED_PARCEL';
const ERROR_LOAD_SATIMAGES_PARCEL = 'ERROR_LOAD_SATIMAGES_PARCEL';

const UPDATING_ALLSATIMAGES_ALLPARCELS = 'UPDATING_ALLSATIMAGES_ALLPARCELS';
const ALLSATIMAGES_UPDATED_PARCEL = 'ALLSATIMAGES_UPDATED_PARCEL';
const ASKING_REALOAD_ALL_SATIMAGE_FOR_ALL_PARCELS = 'ASKING_REALOAD_ALL_SATIMAGE_FOR_ALL_PARCELS';
const REALOAD_ALL_SATIMAGE_FOR_ALL_PARCELS_ASKED = 'REALOAD_ALL_SATIMAGE_FOR_ALL_PARCELS_ASKED';

const LOADING_SATIMAGES_PARCEL = 'LOADING_SATIMAGES_PARCEL';

const ADD_SATIMAGES_WITH_HIGHERMAXCC_BYPARCELID_DICO = 'ADD_SATIMAGES_WITH_HIGHERMAXCC_BYPARCELID_DICO';

const LOADING_SATIMAGE = 'LOADING_SATIMAGE';
const ERROR_LOAD_SATIMAGE = 'ERROR_LOAD_SATIMAGE';
const ADD_SATIMAGE = 'ADD_SATIMAGE';

const DELETE_SATIMAGE_AFTER_PARCELS_DELETED = 'DELETE_SATIMAGE_AFTER_PARCELS_DELETED';
const DELETE_SATIMAGE_BY_PARCELID_LIST = 'DELETE_SATIMAGE_BY_PARCELID_LIST';

const LOADING_ANALYS = 'LOADING_ANALYS';
const ERROR_LOAD_ANALYS = 'ERROR_LOAD_ANALYS';
const ADD_ANALYS = 'ADD_ANALYS';

const ADD_THE_LAST_SELECTED_SATIMAGES_TO_SET_ON_MAP = 'ADD_THE_LAST_SELECTED_SATIMAGES_TO_SET_ON_MAP';
const SET_ON_MAP_THE_LAST_SELECTED_SATIMAGES = 'SET_ON_MAP_THE_LAST_SELECTED_SATIMAGES';

const SET_SAT_IMAGE_DICO_BY_PARCEL = 'SET_SAT_IMAGE_DICO_BY_PARCEL';
const SET_SAT_IMAGE_WITH_HIGHER_MAXCC_DICO = 'SET_SAT_IMAGE_WITH_HIGHER_MAXCC_DICO';

const SET_MONTH_MISSING = 'SET_MONTH_MISSING';

const UPDATE_SATIMAGE_DEACTIVATION_REASON = 'UPDATE_SATIMAGE_DEACTIVATION_REASON';
const UPDATING_SATIMAGE_DEACTIVATION_REASON = 'UPDATING_SATIMAGE_DEACTIVATION_REASON';
const ERROR_UPDATE_SATIMAGE_DEACTIVATION_REASON = 'ERROR_UPDATE_SATIMAGE_DEACTIVATION_REASON';

/* Enuméré des actions pour la gestion des parcelles: */
export const ActionTypeSatimage = {
    addAllLastSatimageByparcelid: ADD_ALL_LAST_SATIMAGE_BYPARCELID,

    LoadingSatimagesAllParcels: LOADING_SATIMAGES_ALLPARCELS,
    SatimagesLoadedParcel: SATIMAGES_LOADED_PARCEL,
    errorLoadSatimageParcel: ERROR_LOAD_SATIMAGES_PARCEL,

    UpdatingAllSatimagesAllParcels: UPDATING_ALLSATIMAGES_ALLPARCELS,
    AllSatimagesUpdatedParcel: ALLSATIMAGES_UPDATED_PARCEL,
    AskingReloadAllSatimageForAllParcels: ASKING_REALOAD_ALL_SATIMAGE_FOR_ALL_PARCELS,
    ReloadAllSatimageForAllParcelsAsked: REALOAD_ALL_SATIMAGE_FOR_ALL_PARCELS_ASKED,

    LoadingSatimagesOfParcel: LOADING_SATIMAGES_PARCEL,

    addSatimagesWithHighermaxccByparcelidDico: ADD_SATIMAGES_WITH_HIGHERMAXCC_BYPARCELID_DICO,

    loadingSatimage: LOADING_SATIMAGE,
    errorLoadSatimage: ERROR_LOAD_SATIMAGE,
    addSatimage: ADD_SATIMAGE,

    DeleteAllSatimages: DELETE_SATIMAGE_AFTER_PARCELS_DELETED,
    DeleteSatimageByParcelidList: DELETE_SATIMAGE_BY_PARCELID_LIST,
    
    loadingSatimageAnalys: LOADING_ANALYS,
    errorLoadSatimageAnalys: ERROR_LOAD_ANALYS,
    addSatimageAnalys: ADD_ANALYS,

    addTheLastSelectedSatImagesToSetOnMap: ADD_THE_LAST_SELECTED_SATIMAGES_TO_SET_ON_MAP,
    setOnMapTheLastSelectedSatImages: SET_ON_MAP_THE_LAST_SELECTED_SATIMAGES,

    setSatImageDicoByParcel: SET_SAT_IMAGE_DICO_BY_PARCEL,
    setSatImageWithHigherMaxccDico: SET_SAT_IMAGE_WITH_HIGHER_MAXCC_DICO,

    setMonthMissing: SET_MONTH_MISSING,

    updateSatImageDeactivationReason: UPDATE_SATIMAGE_DEACTIVATION_REASON,
    updatingSatImageDeactivationReason: UPDATING_SATIMAGE_DEACTIVATION_REASON,
    errorUpdateSatImageDeactivationReason: ERROR_UPDATE_SATIMAGE_DEACTIVATION_REASON,
}

export const _MONTH_LIMIT_ = 4;//Limite de remontée dans les mois précédents (lors de la recherche d'un premier hsitorique de parcelle(s))

export const MIN_YEARDATE_SENTINEL = 2017;
export const MIN_MONTHDATE_SENTINEL = 4;
export const MIN_DATE_SENTINEL = new Date('2017-04-01');//Date minimale pour obtenir des images de Sentinel !

/* Actions creator */
function ActionLoadingSatimagesOfParcel(parcelIdValue, yearValue, monthValue) {
    return {
        type: LOADING_SATIMAGES_PARCEL,
        parcelIdLinked: parcelIdValue,
        year: yearValue,
        month: monthValue,
    };
}

export function ActionLoadingSatimagesForAllParcels(allParcelIdsValue = []) { //forcement pour le mois en cours !
    return {
        type: LOADING_SATIMAGES_ALLPARCELS,
        allParcelIds: allParcelIdsValue,
    };
}

export function ActionSatimagesLoadedOfParcel(parcelIdValue, satimageDicoValue, yearValue = undefined, monthValue = undefined, reload = false) { //soit 'month' et 'year' ne sont pas définis = mois en cours ; soit ils sont définis tous les deux.
    return {
        type: SATIMAGES_LOADED_PARCEL,
        parcelIdLinked: parcelIdValue,
        satimageDico: satimageDicoValue,
        year: yearValue,
        month: monthValue,
        reload: reload
    };
}

export function ActionAddSatimagesWithHighermaxccByparcelidDico(parcelIdValue, satimageWithHigherMaxccDico, yearValue = undefined, monthValue = undefined) { //soit 'month' et 'year' ne sont pas définis = mois en cours ; soit ils sont définis tous les deux.
    return {
        type: ADD_SATIMAGES_WITH_HIGHERMAXCC_BYPARCELID_DICO,
        parcelIdLinked: parcelIdValue,
        satimageWithHigherMaxccDico: satimageWithHigherMaxccDico,
        year: yearValue,
        month: monthValue
    };
}

export function ActionErrorLoadSatimagesOfParcel(parcelIdValue, errorValue, yearValue = undefined, monthValue = undefined) { //soit 'month' et 'year' ne sont pas définis = mois en cours ; soit ils sont définis tous les deux.
    return {
        type: ERROR_LOAD_SATIMAGES_PARCEL,
        parcelIdLinked: parcelIdValue,
        errorMessage: errorValue,
        year: yearValue,
        month: monthValue,
    };
}

/*export*/ function ActionUpdatingAllSatimagesForAllParcels(allParcelIdsValue = []) { //forcement pour le mois en cours !
    return {
        type: UPDATING_ALLSATIMAGES_ALLPARCELS,
        parcelIdList: allParcelIdsValue,
    };
}

export function ActionAllSatimagesUpdatedOfParcel(parcelIdValue, satimageDicoValue, maxccValue) {
    return {
        type: ALLSATIMAGES_UPDATED_PARCEL,
        parcelIdLinked: parcelIdValue,
        satimageDico: satimageDicoValue,
        maxcc: maxccValue,
    };
}

/* fonction permettant de réinitaliser les infos, suite à la suppression des parcelles */
export function ActionDeleteSatimagesAfterParcelsDeleted() {
    return {
        type: DELETE_SATIMAGE_AFTER_PARCELS_DELETED,
    };
}

/* Action permettant de supprimer des données satImages en lien avec une liste de parcelId */
export function ActionDeleteSatimageByParcelIdList(parcelIdList = []) {
    return {
        type: DELETE_SATIMAGE_BY_PARCELID_LIST,
        parcelIdList: parcelIdList,
    };
}

export function ActionLoadingSatimage(parcelIdValue) {
    return {
        type: LOADING_SATIMAGE,
        parcelIdLinked: parcelIdValue,
    };
}

export function ActionErrorLoadSatimage(parcelIdValue, imageIdValue, errorValue) {
    return {
        type: ERROR_LOAD_SATIMAGE,
        parcelIdLinked: parcelIdValue,
        imageId: imageIdValue,
        errorMessage: errorValue,
    };
}

export function ActionAddSatimage(parcelIdValue, imageIdValue, satimageValue) {
    return {
        type: ADD_SATIMAGE,
        parcelIdLinked: parcelIdValue,
        imageId: imageIdValue,
        satimage: satimageValue,
    };
}

export function ActionLoadingSatimageAnalys(parcelIdValue, imageIdValue) {
    return {
        type: LOADING_ANALYS,
        parcelId: parcelIdValue,
        imageId: imageIdValue,
    };
}

export function ActionErrorLoadSatimageAnalys(parcelIdValue, imageIdValue, errorValue) {
    return {
        type: ERROR_LOAD_ANALYS,
        parcelId: parcelIdValue,
        imageId: imageIdValue,
        errorMessage: errorValue,
    };
}

export function ActionAddSatimageAnalys(parcelIdValue, imageIdValue, analysValue) {
    return {
        type: ADD_ANALYS,
        parcelId: parcelIdValue,
        imageId: imageIdValue,
        analys: analysValue,
    };
}

/**
 * fonction permettant de mettre à jour le reducer SatImages suivant les informations reçues lors du première appel à la webapi (récupération de toutes les informations)
 */
export function ActionAddLastSatImageByParcelIdDico(lastSatImageByParcelIdDico = {}) {
    return {
        type: ADD_ALL_LAST_SATIMAGE_BYPARCELID,
        lastSatImageByParcelIdDico: lastSatImageByParcelIdDico,
    }
}

/**
 * Fonction permttant de mettre à jour les dico d'image de chaque parcel défini dans le dico passé en paramètre
 * @param {Dictionnaire<ParcelId, Dictionnaire<ImageId, Image>>} satImageDicoByParcel 
 */
export function ActionSetSatImageDicoByParcel(satImageDicoByParcel) {
    return {
        type: SET_SAT_IMAGE_DICO_BY_PARCEL,
        satImageDicoByParcel: satImageDicoByParcel,
    };
}

export function ActionSetSatImageWithHigherMaxccDico(parcelId, newSatImageWithHigherMaxccDico) {
    return {
        type: SET_SAT_IMAGE_WITH_HIGHER_MAXCC_DICO,
        parcelId: parcelId,
        satImageWithHigherMaxccDico: newSatImageWithHigherMaxccDico,
    }
}

/**
 * Met a jour la proprieté missingMonth d'une parcelle
 * @param {Number} parcelId id de la parcelle 
 * @param {Boolean} value booléen de manquement de mois
 */
export function ActionSetMissingMonth(parcelId, value) {
    return {
        type: SET_MONTH_MISSING,
        parcelId: parcelId,
        missingMonth: value,
    }
}

/* 
 * fonction permettant de lancer le premier historique pour toutes les parcelles - 
 * ⚠️ - le flux d'image est automatiquement fourni pour le satImage le plus récent d'une liste
 * *  nbImagesToShowFirst : le nombre d'images récentes à afficher au démarrage de l'appli
 */
export function ActionAskFirstHistoForAllParcel(nbImagesToShowFirst, clientDatas = undefined) {
    return function (dispatch, getState) {
        const currentStoreState = getState();

        // 0- Contrôle que le client a le droit de générer des historiques:
        let isAuthorizeHistoric = (clientDatas) ? (clientDatas.authorizeHistoric === true) : false;
        if ((!clientDatas) && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
            isAuthorizeHistoric = (currentStoreState.clientUserData.clientDatas.authorizeHistoric === true);
        }

        if (isAuthorizeHistoric === true) { //si le client peut générer des historique...
            // 1- récupération des parcelles et de leur id 
            const parcels = lodashGet(currentStoreState, 'parcelsData.parcelDico', {});
            const allParcelIds = ParcelsHelper.selectAllParcelIds(parcels); // (si {} => [])
            if (allParcelIds.length <= 0) return;

            // 2- Informe que l'on va faire la demande du premier historique de toutes les parcelles
            dispatch(ActionLoadingSatimagesForAllParcels(allParcelIds));

            /* nbImagesToShowFirst = 0 : génération de toutes les images du mois en cours
             * nbImagesToShowFirst > 0 : génération d'un nombre fixe d'images les plus récentes du mois en cours */
            if (nbImagesToShowFirst < 0 || nbImagesToShowFirst === undefined) {
                nbImagesToShowFirst = 0;
            }

            // 3- Pour chaque parcelle, on va récupérer une liste d'entité SatImage - la plus récente aura déjà une image
            allParcelIds.forEach((parcelId) => {
                historyWebApiProvider.generateHistory(dispatch, getState, parcelId, nbImagesToShowFirst)
                    .then((response) => {
                        const firstSatimageList = (response && response.imageDataList) ? response.imageDataList : {}; // liste des satImage valides
                        const firstSatImageWithHigherMaxccDico = (response && response.satImageWithHigherMaxccDico) ? response.satImageWithHigherMaxccDico : {}; // dico de satImage dont la couverture nuageuse est supérieure au MaxCC du client

                        // ↓ 0- si la liste reçue est vide, on va demander l'histo sur le mois précédent ↓
                        // (appel fait plus bas, mais pour éviter de changer l'état 'en progression' vers 'noDate' puis à nouveau 'en progression' sur la parcelle, 
                        // on signale la demande du mois précédent avant de signaler le retour du mois en cours)
                        const datePrevMonth = addMonths(new Date(), -1);
                        const yearPrevDate = datePrevMonth.getUTCFullYear();
                        const monthPrevDate = datePrevMonth.getUTCMonth() + 1;
                        if (Object.keys(firstSatimageList).length <= 0) {
                            dispatch(ActionLoadingSatimagesOfParcel(parcelId, yearPrevDate, monthPrevDate));
                        }

                        // ↓ 1- envoi de la liste de satImageDico au reducer et mets à jour la liste des états ↓
                        dispatch(ActionSatimagesLoadedOfParcel(parcelId, firstSatimageList));

                        // ↓ 2- envoi du dico de satImageAboveMaxCCDico (entités satImage ayant un taux d'ennuagement supérieur au maxcc du client) au reducer ↓
                        dispatch(ActionAddSatimagesWithHighermaxccByparcelidDico(parcelId, firstSatImageWithHigherMaxccDico));

                        //↓↓ On a au moins un objet satImage ? (le + récent) ↓
                        if (response.mostRecentSatImage)
                            dispatch( ActionSetFirstSatimageIdToParcel(parcelId, response.mostRecentSatImage.id, response.mostRecentSatImage.date) ); // affectation de l'entité Satimage à l'entité parcel associée
                        else {
                            //↓↓ si pas de donnée, demande la recherche de dates d'images sur le mois précédent ↓
                            dispatch( ActionAskOldHistoForSelectedParcel(parcelId, yearPrevDate, monthPrevDate, nbImagesToShowFirst, 1) );
                        }  
                    })
                    .catch((error) => {
                        //Trace Azure:
                        sendError('ActionAskFirstHistoForAllParcel - getFirstHistory', error);

                        //Avertit du mauvais retour:
                        dispatch(ActionErrorLoadSatimagesOfParcel(parcelId, error));
                    });
            });
        } //else //client pas autorisé à générer des historiques !
    };
}

/* 
 * fonction permettant de lancer le premier historique pour une parcelle ciblée - 
 * ⚠️ - le flux d'image est automatiquement fourni pour le satImage le plus récent d'une liste 
 * ⚠️ - on demande sur le mois précèdent si aucune image n'est sur le mois demandé - on remonte jusqu à 4 MOIS 
 *  nbImagesToShowFirst : le nombre d'images récentes à afficher au démarrage de l'appli
 */
export function ActionAskFirstHistoForSelectedParcel(nbImagesToShowFirst, parcelId, clientDatas = undefined) {
    return function (dispatch, getState) {
        if ((!parcelId) || (parcelId <= 0)) return;
        const currentStoreState = getState();

        // 1- Contrôle que le client a le droit de générer des historiques:
        let isAuthorizeHistoric = (clientDatas) ? (clientDatas.authorizeHistoric === true) : false;
        if ((!clientDatas) && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) {
            isAuthorizeHistoric = (currentStoreState.clientUserData.clientDatas.authorizeHistoric === true);
        }

        if (isAuthorizeHistoric === true) { //si le client peut générer des historique...
            // 2- Informe que l'on va faire la demande du premier historique
            dispatch( ActionLoadingSatimagesOfParcel(parcelId) );

            dispatch( ActionAskTotalCurrentMonthHistoOfParcel(parcelId) );

            /* nbImagesToShowFirst = 0 : on génère toutes les images du mois en cours
             * nbImagesToShowFirst > 0 : on génère uniquement un nombre fixe d'images du mois en cours */
            if (nbImagesToShowFirst <= 0 || nbImagesToShowFirst === undefined) {
                nbImagesToShowFirst = 0;
            }

            //clickedParcel à true : permet de mettre à jour la date à laquelle le client à cliqué sur une parcelle
            let clickedParcel = true;
            // 3- Pour chaque parcelle, on va récupérer une liste d'entité SatImage - la plus récente aura déjà une image
            historyWebApiProvider.generateHistory(dispatch, getState, parcelId, nbImagesToShowFirst, clickedParcel)
                .then(async (response) => {

                    const firstSatimageList = (response && response.imageDataList) ? response.imageDataList : {}; // liste des satImage valides
                    const firstSatImageWithHigherMaxccDico = (response && response.satImageWithHigherMaxccDico) ? response.satImageWithHigherMaxccDico : {}; // Dico des satImage dont la couverture nuageuse est supérieure au MaxCC du client

                    // ↓ 0- si la liste reçue est vide, on va demander l'histo sur le mois précédent ↓
                    // (appel fait plus bas, mais pour éviter de changer l'état 'en progression' vers 'noDate' puis à nouveau 'en progression' sur la parcelle, 
                    // on signale la demande du mois précédent avant de signaler le retour du mois en cours)
                    const datePrevMonth = addMonths(new Date(), -1);
                    const yearPrevDate = datePrevMonth.getUTCFullYear();
                    const monthPrevDate = datePrevMonth.getUTCMonth() + 1;
                    if (Object.keys(firstSatimageList).length <= 0) {
                        dispatch(ActionLoadingSatimagesOfParcel(parcelId, yearPrevDate, monthPrevDate));
                    }

                    // ↓ 1- envoi de la liste de satImageDico au reducer et mets à jour la liste des états ↓
                    dispatch(ActionSatimagesLoadedOfParcel(parcelId, firstSatimageList));

                    // ↓ 2- envoi du dico de satImageAboveMaxCCDico (entités satImage ayant un taux d'ennuagement supérieur au maxcc du client) au reducer ↓
                    dispatch(ActionAddSatimagesWithHighermaxccByparcelidDico(parcelId, firstSatImageWithHigherMaxccDico));

                    //↓↓ On a au moins un objet satImage ? (le + récent) ↓
                    if (response.mostRecentSatImage) {
                        dispatch( ActionSetFirstSatimageIdToParcel(parcelId, response.mostRecentSatImage.id, response.mostRecentSatImage.date) ); // affectation de l'entité Satimage à l'entité parcel associée
                        // On reselectionne volontairement la parcelle pour forcer la première image selectionnée, car au dessin d'une parcelle le context ne peux etre mis a jour car la parcelle ne connait pas l'id de l'image
                        dispatch(ActionGoToMapAndSelectParcel(parcelId));
                    }
                    else {
                        //↓↓ si pas de donnée, demande la recherche de dates d'images sur le mois précédent ↓
                        dispatch( ActionAskOldHistoForSelectedParcel(parcelId, yearPrevDate, monthPrevDate, nbImagesToShowFirst, 1) );
                    }  
                })
                .catch((error) => {
                    //Trace Azure:
                    sendError('ActionAskFirstHistoForSelectedParcel - generateHistory', error);
                    
                    //Avertit du mauvais retour:
                    dispatch(ActionErrorLoadSatimagesOfParcel(parcelId, error));
                });
        } //else //client pas autorisé à générer des historiques !
    };
}

/**
 * Fonction qui permet de restauré d'eventuel mois manquants d'une parcelle
 */
export function ActionFillAllGaps() {
    return function (dispatch, getState) {
        const currentStoreState = getState();
        const satImages = lodashGet(currentStoreState, `satimageData.satimagesByParcelDico`);

        if (satImages) {
            // pour chaque parcelles, on cherche à savoir si missingMonth est à true
            Object.values(satImages).forEach(e => {
                if (e.missingMonth) {
                    const allMissingMonths = SatimageHelper.getMissingMonth(e);

                    // si on a trouvé des mois manquant, on les ajoutes via l'API
                    if (allMissingMonths.length > 0) {
                        allMissingMonths.forEach((month) => {
                            dispatch(ActionAskOldHistoForSelectedParcel(e.parcelId, month.year, month.month));
                        })
                    }

                    // Une fois tout les appelles effectués, on remet missingMonth a false pour cette parcelle
                    dispatch(ActionSetMissingMonth(e.parcelId, false));
                }
            })
        }
    }
}

/**
 * Action qui selectionne une image valide dans un interval de pesée de colza
 * @param {Object} satImages
 * @param {Object} range
 */
export function ActionSelectImageInRange(satImages, range) {
    return function (dispatch, getState) {
        
        const currentStoreState = getState();
        const selectedSatimageDate = lodashGet(currentStoreState, 'contextAppData.satimageDateSelectedOfParcel');

        // si l'image deja selectionné, se trouve directement dans l'interval, on ne va pas essayer d'en trouver une autre
        if (SatimageHelper.checkDateInterval(selectedSatimageDate, range) === 'IN_RANGE') return;

        const images = Object.values(satImages);

        // on cherche la premiere image dans l'interval elargi
        const firstImageInExtraRange = images.find((element) => (
            SatimageHelper.checkDateInterval(element.date.toString(), range) === 'IN_EXTRA_RANGE'
        ));

        // on cherche la premiere image dans l'interval
        const firstImageInRange = images.find((element) => (
            SatimageHelper.checkDateInterval(element.date.toString(), range) === 'IN_RANGE'
        ));

        
        if (firstImageInRange !== undefined) // Si on a une image valide dans l'interval:
        {
            dispatch(ActionSelectDateImg(firstImageInRange.id, firstImageInRange.date, firstImageInRange.parcelId));
        }
        else if (firstImageInExtraRange !== undefined) // Si on a aucune image valide, on essaie d'en avoir dans l'interval elargi
        {
            dispatch(ActionSelectDateImg(firstImageInExtraRange.id, firstImageInExtraRange.date, firstImageInExtraRange.parcelId));
        } else {
            //SI on est sur la sélection d'une date pour l'entrée d'hiver : on ne selectionne rien car on a aucune image valide
            if (range.isBeginningWinter !== false) return;
            else { //pour la sortie d'hiver : on choisit la dernière disponible !
                //TODO : A faire finaliser par Anddy !
                return;
            }
        }
    }
}

export function ActionAskOldHistoForSelectedParcel(parcelIdValue, yearValue, monthValue, nbImagesToShowFirst = undefined, countCallOfPrevMonth = undefined, reload = false, biomass = undefined, range = undefined) {
    return function (dispatch, getState) {
        if ((!parcelIdValue) || (parcelIdValue <= 0)) return;
        const currentStoreState = getState();

        // 0- Contrôle que le client a le droit de générer des historiques:
        let isAuthorizeHistoric = (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) ? 
            (currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas.authorizeHistoric === true) : false;

        if (isAuthorizeHistoric === true) { //si le client peut générer des historique...
            // 0bis-Vérification que l'on demande pas sur une date antérieur aux images disponibles de Sentinel:
            if (dateHelper.Compare(new Date(yearValue, monthValue, 1), MIN_DATE_SENTINEL) <= 0) {
                const stateForthisYearMonth = lodashGet(currentStoreState, `satimageData.satimagesByParcelDico[${parcelIdValue}].stateByYearMonthDico[${MIN_YEARDATE_SENTINEL}_${MIN_MONTHDATE_SENTINEL}]`, undefined);
                
                if (!stateForthisYearMonth) {//Avertit du mauvais retour:
                    dispatch(ActionErrorLoadSatimagesOfParcel(parcelIdValue, StringTranslate.noImageBefore, MIN_YEARDATE_SENTINEL, MIN_MONTHDATE_SENTINEL));
                }

                return;
            }

            // 1- vérifie si on retrouve les infos sur l'année/mois de cette parcelle 
            const stateForthisYearMonth = lodashGet(currentStoreState, `satimageData.satimagesByParcelDico[${parcelIdValue}].stateByYearMonthDico[${yearValue}_${monthValue}]`, undefined);

            if ((!stateForthisYearMonth) || (stateForthisYearMonth.stateAsk === SatimageState.stateNotAsk) || //cas d'un mois non-encore demandé !
                (countCallOfPrevMonth && (stateForthisYearMonth.stateAsk === SatimageState.stateAskOnProgress))) { //cas d'un mois précédent, dont on vient de nous demander (mais pour lequel on a déjà prévenu de sa progression)
                // 2- Informe que l'on va faire la demande du premier historique
                dispatch(ActionLoadingSatimagesOfParcel(parcelIdValue, yearValue, monthValue));
                
                /* nbImagesToShowFirst = 0 : génération de toutes les images du mois en cours 
                 * nbImagesToShowFirst > 0 : génération d'un nombre fixe d'images les plus récentes du mois en cours */
                if (nbImagesToShowFirst < 0 || nbImagesToShowFirst === undefined) {
                    nbImagesToShowFirst = 0;
                }
                // 3- Pour chaque parcelle, on va récupérer une liste d'entité SatImage - la plus récente aura déjà une image
                historyWebApiProvider.generateOldHistory(dispatch, getState, parcelIdValue, yearValue, monthValue, nbImagesToShowFirst)
                    .then((response) => {
                        const oldSatimageList = (response && response.imageDataList) ? response.imageDataList : {}; // liste des satImage valides
                        const oldSatImageWithHigherMaxccDico = (response && response.satImageWithHigherMaxccDico) ? response.satImageWithHigherMaxccDico : {}; // Dico des satImage dont la couverture nuageuse est supérieure au MaxCC du client

                        // ↓ 0- si la liste reçue est vide, on va demander l'histo sur le mois précédent ↓
                        // (appel fait plus bas, mais pour éviter de changer l'état 'en progression' vers 'noDate' puis à nouveau 'en progression' sur la parcelle, 
                        // on signale la demande du mois précédent avant de signaler le retour du mois en cours)
                        const datePrevMonth = addMonths(new Date(yearValue, monthValue, 1), -1);
                        const yearPrevDate = datePrevMonth.getUTCFullYear();
                        const monthPrevDate = datePrevMonth.getUTCMonth() + 1;
                        if ((Object.keys(oldSatimageList).length <= 0) && (countCallOfPrevMonth < _MONTH_LIMIT_)) { //uniquement si on a pas atteint les 4 mois !
                            dispatch(ActionLoadingSatimagesOfParcel(parcelIdValue, yearPrevDate, monthPrevDate));
                        }

                        // ↓ 1- envoi de la liste de satImageDico au reducer et mets à jour la liste des états ↓
                        dispatch(ActionSatimagesLoadedOfParcel(parcelIdValue, oldSatimageList, yearValue, monthValue));

                        // ↓ 2- envoi du dico de satImageAboveMaxCCDico (entités satImage ayant un taux d'ennuagement supérieur au maxcc du client) au reducer ↓
                        dispatch(ActionAddSatimagesWithHighermaxccByparcelidDico(parcelIdValue, oldSatImageWithHigherMaxccDico, yearValue, monthValue));

                        if (countCallOfPrevMonth) { //si 'countCallOfPrevMonth' est défini, c'est que l'on est dans le chargement des premières images...
                            // ->il faut demander l'affichage de la première reçue; A défaut, demander la recherche de celle-coi dans le mois précédent !
                            //↓↓ On a au moins un objet satImage (et un flux image)↓
                            if (Object.keys(oldSatimageList).length > 0) {
                                // ↓ 2- récupération première satImage et affectation à l'entité parcel associée ↓
                                if (response.mostRecentSatImage)
                                    dispatch( ActionSetFirstSatimageIdToParcel(parcelIdValue, response.mostRecentSatImage.id, response.mostRecentSatImage.date) ); // affectation de l'entité Satimage à l'entité parcel associée

                            } else if (countCallOfPrevMonth < _MONTH_LIMIT_) {
                                // ↓ 2(bis)- demande la recherche de dates d'images sur le mois précédent ↓
                                const newCountCallOfPrevMonth = countCallOfPrevMonth + 1;
                                dispatch(ActionAskOldHistoForSelectedParcel(parcelIdValue, yearPrevDate, monthPrevDate, nbImagesToShowFirst, newCountCallOfPrevMonth));
                            } //else //on a déja remonté 4 mois en arrière pour trouver une image... on s'arrête là pour ne pas faire trop attendre le client !
                        }//else //on a les dates d'images mais ce n'est pas pour cela que l'on doit forcer l'affichage de la plus récente d'entre elles...

                        if ((biomass !== undefined) && (range !== undefined)) {
                            const satImages = lodashGet(currentStoreState, `satimageData.satimagesByParcelDico[${parcelIdValue}].satimageDico`);
                            dispatch(ActionSelectImageInRange(satImages, range));
                        }
                    })
                    .catch((error) => {
                        //Trace Azure:
                        sendError('ActionAskOldHistoForSelectedParcel - generateOldHistory', error);
                        
                        //Avertit du mauvais retour:
                        dispatch(ActionErrorLoadSatimagesOfParcel(parcelIdValue, error, yearValue, monthValue));
                    });
            }

            // CAS - DEJA EXISTANT MAIS EN ERREUR - on relance la génération si demandé (reload)
            if ((stateForthisYearMonth) && (stateForthisYearMonth.stateAsk === SatimageState.stateOnError) && (reload)) {

                // - mise à jour du state - historique en cours de progression
                dispatch(ActionLoadingSatimagesOfParcel(parcelIdValue, yearValue, monthValue));

                // - APPEL API - demande historique - récupération liste d'entité SatImage - la plus récente aura déjà une image
                historyWebApiProvider.generateOldHistory(dispatch, getState, parcelIdValue, yearValue, monthValue)
                    .then((response) => {

                        const satimageDico = (response && response.imageDataList) ? response.imageDataList : {}; // liste des satImage valides
                        // const satimageAboveMaxCCDico = (response && response.imageDataAboveMaxCCList) ? response.imageDataAboveMaxCCList : {}; // liste des satImage dont la couverture nuageuse est supérieure au MaxCC du client

                        // - mise à jour du state
                        dispatch(ActionSatimagesLoadedOfParcel(parcelIdValue, satimageDico, yearValue, monthValue, reload));
                    })
                    .catch((error) => {
                        // - mise à jour du reducer - ⚠️ - A ce jour, on ne relance qu'une seule fois la génération d'historique si celui-ci est en erreur (si toujours en erreur - on le passe à aucune image pour le mois)
                        dispatch(ActionSatimagesLoadedOfParcel(parcelIdValue, {}, yearValue, monthValue));
                    });
            }
        } //else //client pas autorisé à générer des historiques !
    };
}

/* 
 * fonction permettant de mettre à jour l'historique de chaque parcelle suite à, par exemple, au changement de taux d'enuagement (taux à la parcelle en hausse)
 * ⚠️ - le flux d'image est automatiquement fourni pour le satImage le plus récent d'une liste 
 * ⚠️ - on demande sur le mois précèdent si aucune image n'est sur le mois demandé - on remonte jusqu à 4 MOIS 
 */
export function ActionAskUpdateAllSatimageForAllParcels() {
    return function (dispatch, getState) {
        const currentStoreState = getState();

        // 0- Contrôle que le client a le droit de générer des historiques:
        let isAuthorizeHistoric = (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) ? 
            (currentStoreState.clientUserData.clientDatas.authorizeHistoric === true) : false;

        if (isAuthorizeHistoric === true) { //si le client peut générer des historique...
            // 1- récupération des parcelles et de leur id 
            const parcels = lodashGet(currentStoreState, 'parcelsData.parcelDico', {});
            const allParcelIds = ParcelsHelper.selectAllParcelIds(parcels); // (si {} => [])

            // - récupération du nouveau maxcc
            const newMaxcc = lodashGet(currentStoreState, 'settingsData.settings.parcelMaxcc', -1);

            // 2- Informe que l'on va faire la demande du premier historique de toutes les parcelles
            if (allParcelIds.length > 0)
                dispatch(ActionUpdatingAllSatimagesForAllParcels(allParcelIds));

            // 3- Pour chaque parcelle, on va récupérer une liste d'entité SatImage - la plus récente aura déjà une image
            // RQ: en cas d'erreur, on indiquera celle-ci mais on ne videra pas les dates d'images pour poursuivre l'utilisation de l'appli.
            allParcelIds.forEach((parcelId) => {
                historyWebApiProvider.updateHistory(dispatch, getState, parcelId)
                    .then((newSatimageList = {}) => { // Reçoit les images de tous les année/mois déjà parcourru !

                        // ↓ 1- envoi de la liste de satImageDico au reducer et mets à jour la liste des états ↓
                        dispatch(ActionAllSatimagesUpdatedOfParcel(parcelId, newSatimageList, newMaxcc));

                        //↓↓ On a au moins un objet satImage (et un flux image)↓
                        if (newSatimageList && Object.keys(newSatimageList).length > 0) {
                            // ↓ 2- récupération première satImage et affectation à l'entité parcel associée ↓
                            if (newSatimageList.mostRecentSatImage)
                                dispatch( ActionSetFirstSatimageIdToParcel(parcelId, newSatimageList.mostRecentSatImage.id, newSatimageList.mostRecentSatImage.date) ); // affectation de l'entité Satimage à l'entité parcel associée
                        } else { //Cas noramlement pas possible...
                            // car on a dû récupérer au moins une image lors du chargement du premier historique (on était remonté dans les mois jusqu'à l'obtension d'une image).                        
                            dispatch(ActionErrorLoadSatimagesOfParcel(parcelId, StringTranslate.imageNonObtenue));
                        }
                    })
                    .catch((error) => {
                        //Trace Azure:
                        sendError('ActionAskUpdateAllSatimageForAllParcels - updateHistory', error);
                        
                        //Avertit du mauvais retour:
                        dispatch(ActionErrorLoadSatimagesOfParcel(parcelId, error));
                    });
            });
        } //else //client pas autorisé à générer des historiques !
    };
}

export function ActionAskingReloadAllSatimageForAllParcels() {
    return {
        type: ASKING_REALOAD_ALL_SATIMAGE_FOR_ALL_PARCELS,
    }
}

export function ActionReloadAllSatimageForAllParcelsAsked() {
    return {
        type: REALOAD_ALL_SATIMAGE_FOR_ALL_PARCELS_ASKED,
    }
}

/* 
 * fonction permettant de forcer la réobtention de l'historique de chaque parcelle suite à, par exemple, au changement de taux d'enuagement (taux à la parcelle à la baisse)
 * ⚠️ - le flux d'image est automatiquement fourni pour le satImage le plus récent d'une liste 
 * ⚠️ - on demande sur le mois précèdent si aucune image n'est sur le mois demandé - on remonte jusqu à 4 MOIS 
 */
export function ActionAskReloadAllSatimageForAllParcels(nbImages = 0, thisParcelsOnly = undefined) {
    
    return function (dispatch, getState) {

        //Signale qu'on demande la recharge des images sat
        dispatch(ActionAskingReloadAllSatimageForAllParcels());

        const currentStoreState = getState();

        // 0- Contrôle que le client a le droit de générer des historiques:
        let isAuthorizeHistoric = (currentStoreState && currentStoreState.clientUserData && currentStoreState.clientUserData.clientDatas) ? 
            (currentStoreState.clientUserData.clientDatas.authorizeHistoric === true) : false;

        if (isAuthorizeHistoric === true) { //si le client peut générer des historique...
            // 1- récupération des parcelles et de leur id 
            const parcels = lodashGet(currentStoreState, 'parcelsData.parcelDico', {});
            const selectionOfParcelIds = (thisParcelsOnly && (Array.isArray(thisParcelsOnly)) && (thisParcelsOnly.length > 0)) ? 
                thisParcelsOnly : ParcelsHelper.selectAllParcelIds(parcels); // (si {} => [])

            // - récupération du nouveau maxcc
            const newMaxcc = lodashGet(currentStoreState, 'settingsData.settings.parcelMaxcc', -1);

            // 2- Informe que l'on va faire la demande du premier historique de toutes les parcelles
            if (selectionOfParcelIds.length > 0) {
                dispatch(ActionUpdatingAllSatimagesForAllParcels(selectionOfParcelIds));
                //Pour celà, suppression des images
                dispatch(ActionDeleteSatimageByParcelIdList(selectionOfParcelIds));   
            }

            // 3- Pour chaque parcelle, on va récupérer une liste d'entité SatImage - la plus récente aura déjà une image
            // RQ: en cas d'erreur, on indiquera celle-ci mais on ne videra pas les dates d'images pour poursuivre l'utilisation de l'appli.
            selectionOfParcelIds.forEach((parcelId) => {

                historyWebApiProvider.generateHistory(dispatch, getState, parcelId, nbImages)
                    .then((response) => {
                        
                        const newSatimageList = (response && response.imageDataList) ? response.imageDataList : {}; // liste des satImagevalide
                        const newSatImageWithHigherMaxccDico = (response && response.satImageWithHigherMaxccDico) ? response.satImageWithHigherMaxccDico : {}; // Dico des satImage dont la couverture nuageuse est supérieure au MaxCC du client

                        // ↓ 0- si la liste reçue est vide, on va demander l'histo sur le mois précédent ↓
                        // (appel fait plus bas, mais pour éviter de changer l'état 'en progression' vers 'noDate' puis à nouveau 'en progression' sur la parcelle, 
                        // on signale la demande du mois précédent avant de signaler le retour du mois en cours)
                        const datePrevMonth = addMonths(new Date(), -1);
                        const yearPrevDate = datePrevMonth.getUTCFullYear();
                        const monthPrevDate = datePrevMonth.getUTCMonth() + 1;
                        if (newSatimageList && Object.keys(newSatimageList).length <= 0) {
                            dispatch(ActionLoadingSatimagesOfParcel(parcelId, yearPrevDate, monthPrevDate));
                        }

                        // ↓ 1- envoi de la liste de satImageDico au reducer et mets à jour la liste des états ↓
                        dispatch(ActionAllSatimagesUpdatedOfParcel(parcelId, newSatimageList, newMaxcc));

                        // ↓ 2- envoi du dico de satImageAboveMaxCCDico (entités satImage ayant un taux d'ennuagement supérieur au maxcc du client) au reducer ↓
                        dispatch(ActionAddSatimagesWithHighermaxccByparcelidDico(parcelId, newSatImageWithHigherMaxccDico));

                        //↓↓ On a au moins un objet satImage ? (le + récent) ↓
                        if (response.mostRecentSatImage) {
                            dispatch(ActionSetFirstSatimageIdToParcel(parcelId, response.mostRecentSatImage.id, response.mostRecentSatImage.date)); // affectation de l'entité Satimage à l'entité parcel associée
                        }
                        else //↓↓ si pas de donnée, demande la recherche de dates d'images sur le mois précédent ↓
                            dispatch(ActionAskOldHistoForSelectedParcel(parcelId, yearPrevDate, monthPrevDate, 1));

                        //Informe que la demande de rechargement des images sat est terminé
                        
                    })
                    .catch((error) => {
                        //Trace Azure:
                        sendError('ActionAskReloadAllSatimageForAllParcels - generateHistory', error);

                        //Avertit du mauvais retour:
                        dispatch(ActionErrorLoadSatimagesOfParcel(parcelId, error));
                    });
                });


            dispatch(ActionReloadAllSatimageForAllParcelsAsked());
                
        } //else //client pas autorisé à générer des historiques !
    };
}

/* 
 * fonction permettant de demander l'analyse associée à une image, via l'ID de celle-ci 
 */
export function ActionAskAnalysFromImageId(parcelIdValue, imageIdValue) {
    return function (dispatch, getState) {
        if ((!parcelIdValue) || (parcelIdValue <= 0) || (!imageIdValue) || (imageIdValue <= 0)) return;
        const currentStoreState = getState();

        // 1- vérifie si on retrouve l'analyse' de cette image 
        const satImageSelected = lodashGet(currentStoreState, `satimageData.satimagesByParcelDico[${parcelIdValue}].satimageDico[${imageIdValue}]`, undefined);
        if (satImageSelected && (!satImageSelected.dataAnalyse)) {
            // 2- Informe que l'on va faire la demande l'analyse
            dispatch(ActionLoadingSatimageAnalys(parcelIdValue, imageIdValue));

            // 3- Pour l'image indiquée, on va demander l'analyse
            historyWebApiProvider.getAnalyseOfImageFromServer(dispatch, getState, imageIdValue)
                .then((dataAnalyse) => {
                    // ↓ 1- envoi de la réponse : l'analyse de cette image ↓
                    dispatch(ActionAddSatimageAnalys(parcelIdValue, imageIdValue, dataAnalyse));

                    // ↓ 2- émet l'info via la callback, en fournissant l'analyse de cette image ↓
                    if (satImageSelected.callBackGetAnalys) {
                        satImageSelected.dataAnalyse = dataAnalyse;
                        satImageSelected.callBackGetAnalys(dataAnalyse);
                    }
                })
                .catch((error) => {
                    //Trace Azure:
                    sendError('ActionAskAnalysFromImageId - getAnalyseOfImageFromServer', error);
                    
                    //Avertit du mauvais retour:
                    dispatch(ActionErrorLoadSatimageAnalys(parcelIdValue, imageIdValue, error));
                });
        } else if (satImageSelected && satImageSelected.dataAnalyse) { //on n'a déjà l'info...
            // ↓ 1- envoi TOUT DE SUITE la réponse via la callback, en fournissant l'analyse de cette image ↓
            if (satImageSelected.callBackGetAnalys) {
                satImageSelected.callBackGetAnalys(satImageSelected.dataAnalyse);
            }
        }
        //else //On ne peut rien y faire...
    };
}

export function ActionAskDatasOfImage(parcelIdValue, satimageIdValue) {
    return function (dispatch, getState) {
        if ((!parcelIdValue) || (!satimageIdValue)) return;

        const currentStoreState = getState();
        if ((parcelIdValue > 0) && (satimageIdValue > 0)) {
            // 1- récupération de la Satimage visée, associée à cette parcelle :
            const satimageFund = lodashGet(currentStoreState, `satimageData.satimagesByParcelDico[${parcelIdValue}].satimageDico[${satimageIdValue}]`, undefined);

            // 2- demande des flux de la Satimage si pas déjà présentes :
            if (satimageFund && //satimageFund.id && (satimageFund.id > 0) && 
                ((!satimageFund.data) || (!satimageFund.dataLight))) {
                historyWebApiProvider.getImageFromServer(dispatch, getState, satimageFund.id)
                    .then((satimageWithStream) => {
                        if (satimageWithStream && satimageWithStream.data && satimageWithStream.dataLight) {
                            //sauvegarde les données dans l'entité existante:
                            //satimageDicoOfParcel[satimageFund.id] = satimageWithStream; //pas comme cela, pour bien utiliser le mécanisme de Redux !
                            // ↓ 1- envoi de la réponse : les flux de cette image ↓
                            dispatch(ActionAddSatimage(parcelIdValue, satimageIdValue, satimageWithStream));

                            // ↓ 2- émet l'info via la callback, en fournissant l'analyse de cette image ↓
                            if (satimageFund.callBackGetDatas) {
                                satimageFund.callBackGetDatas(satimageWithStream);
                            }
                        } else {
                            //Avertit du mauvais retour:
                            dispatch(ActionErrorLoadSatimage(parcelIdValue, satimageIdValue, StringTranslate.imageNonObtenue)); // `L'image au '${dateInstance.toLocaleDateString('fr-FR')}' n'est pas téléchargeable`));
                        }
                    })
                    .catch((error) => {
                        //Trace Azure:
                        sendError('ActionAskDatasOfImage - getImageFromServer', error);
                        
                        //Avertit du mauvais retour:
                        dispatch(ActionErrorLoadSatimage(parcelIdValue, satimageIdValue, error));
                    });
            } //else //on a déjà les flux (Ndvi, Visible et miniature). Rien d'autre à faire!
        }

        return;
    }
}

/*
* ⚠️ - fonction pour les utilisateurs dont la restriction est blocage de l'historique
* fonction permettant de récupérer l'historique en fonction du mois et de l'année pour toutes les parcelles - Seules les données en BDD sont récupérées
* ⚠️ - le flux d'image est automatiquement fourni pour le satImage le plus récent d'une liste 
*/
export function ActionGetHistoForAllParcelsByMonthYear(monthAsked, yearAsked) {

    return function (dispatch, getState) {
        const currentStoreState = getState();

        // 1- récupération des parcelles et de leur id 
        const parcels = lodashGet(currentStoreState, 'parcelsData.parcelDico', {});
        const allParcelIds = ParcelsHelper.selectAllParcelIds(parcels); // (si {} => [])
        if (allParcelIds.length <= 0) return;

        // 2- Informe que l'on va faire la demande du premier historique de toutes les parcelles
        dispatch(ActionLoadingSatimagesForAllParcels(allParcelIds));

        // 3- Pour chaque parcelle, on va récupérer une liste d'entité SatImage - la plus récente aura déjà une image
        allParcelIds.forEach((parcelId) => {

            historyWebApiProvider.getHistoryForSpecificMonthYearByParcelId(dispatch, getState, monthAsked, yearAsked, parcelId)
                .then((response) => {

                    const firstSatimageList = (response && response.imageDataList) ? response.imageDataList : {}; // liste des satImage valides
                    const firstSatImageWithHigherMaxccDico = (response && response.satImageWithHigherMaxccDico) ? response.satImageWithHigherMaxccDico : {}; // dico de satImage dont la couverture nuageuse est supérieure au MaxCC du client

                    // ↓ 1- envoi de la liste de satImageDico au reducer et mets à jour la liste des états ↓
                    dispatch(ActionSatimagesLoadedOfParcel(parcelId, firstSatimageList));

                    // ↓ 2- envoi du dico de satImageAboveMaxCCDico (entités satImage ayant un taux d'ennuagement supérieur au maxcc du client) au reducer ↓
                    dispatch(ActionAddSatimagesWithHighermaxccByparcelidDico(parcelId, firstSatImageWithHigherMaxccDico));

                    //↓↓ On a au moins un objet satImage ? (le + récent) ↓
                    if (response.mostRecentSatImage)
                        dispatch( ActionSetFirstSatimageIdToParcel(parcelId, response.mostRecentSatImage.id, response.mostRecentSatImage.date) ); // affectation de l'entité Satimage à l'entité parcel associée
                })
                .catch((error) => {
                    //Trace Azure:
                    sendError('ActionGetHistoForAllParcelsByMonthYear - getHistoryForSpecificMonthYearByParcelId', error);
                    
                    //Avertit du mauvais retour:
                    dispatch(ActionErrorLoadSatimagesOfParcel(parcelId, error));
                })
        })
    }
}

/*
* ⚠️ - fonction pour les utilisateurs dont la restriction est blocage de l'historique
* fonction permettant de récupérer l'historique en fonction du mois et de l'année pour une parcelle - Seules les données en BDD sont récupérées
* ⚠️ - le flux d'image est automatiquement fourni pour le satImage le plus récent d'une liste 
*/
export function ActionGetHistoForParcelByMonthYear(parcelIdValue, monthAsked, yearAsked) {
    
    return function (dispatch, getState) {
        // 1 - Vérifier qu'une parcelle est bien sélectionnée
        if (!parcelIdValue) return;

        // 2- Informe que l'on va faire la demande de l'historique de la parcelle
        dispatch(ActionLoadingSatimagesOfParcel(parcelIdValue));
        
        // 3- On va récupérer une liste d'entité SatImage - la plus récente aura déjà une image
        historyWebApiProvider.getHistoryForSpecificMonthYearByParcelId(dispatch, getState, monthAsked, yearAsked, parcelIdValue)
            .then((response) => {
                const firstSatimageList = (response && response.imageDataList) ? response.imageDataList : {}; // liste des satImage valides
                const firstSatImageWithHigherMaxccDico = (response && response.satImageWithHigherMaxccDico) ? response.satImageWithHigherMaxccDico : {}; // dico de satImage dont la couverture nuageuse est supérieure au MaxCC du client

                // ↓ 1- envoi de la liste de satImageDico au reducer et mets à jour la liste des états ↓
                dispatch(ActionSatimagesLoadedOfParcel(parcelIdValue, firstSatimageList));

                // ↓ 2- envoi du dico de satImageAboveMaxCCDico (entités satImage ayant un taux d'ennuagement supérieur au maxcc du client) au reducer ↓
                dispatch(ActionAddSatimagesWithHighermaxccByparcelidDico(parcelIdValue, firstSatImageWithHigherMaxccDico));

                //↓↓ On a au moins un objet satImage (et un flux image)↓
                if (Object.keys(firstSatimageList).length > 0) {
                    // ↓ 2- récupération première satImage et affectation à l'entité parcel associée ↓
                    if (response.mostRecentSatImage)
                        dispatch( ActionSetFirstSatimageIdToParcel(parcelIdValue, response.mostRecentSatImage.id, response.mostRecentSatImage.date) ); // affectation de l'entité Satimage à l'entité parcel associée
                }
                else if (Object.keys(firstSatImageWithHigherMaxccDico).length <= 0) {
                    //on affiche l'incitation au mode premium quand la liste des images valides et la liste des images non valides sont vides 
                    // car cela signifie que la génération n'a pas eu lieu (OU cas très rare: qu'aucun raster ne soit valide).
                    dispatch( ActionForceInviteToPremium(ReasonInviteToPremium.ComebackFreemium) );
                }
            })
            .catch((error) => {
                //Trace Azure:
                sendError('ActionGetHistoForParcelByMonthYear - getHistoryForSpecificMonthYearByParcelId', error);
                
                //Avertit du mauvais retour:
                dispatch(ActionErrorLoadSatimagesOfParcel(parcelIdValue, error));
            })

    }
}

/**
 * fonction permettant de mettre à jour le reducer SatImages suivant les informations reçues lors du première appel à la webapi (récupération de toutes les informations)
 */
export function ActionAddSatImageByParcelIdDico_old(satImageByParcelIdDico) {
    return function (dispatch, getState) {
        if (!satImageByParcelIdDico) return;
        
        const parcels = lodashGet( getState(), 'parcelsData.parcelDico', {});
        const allParcelIds = ParcelsHelper.selectAllParcelIds(parcels); // (si {} => [])
        if (allParcelIds.length <= 0) return;

        // 2- Informe que l'on va faire la demande du premier historique de toutes les parcelles
        dispatch( ActionLoadingSatimagesForAllParcels(allParcelIds) );

        // 3- Pour chaque parcelle, on va récupérer une liste d'entité SatImage - la plus récente aura déjà une image
        allParcelIds.forEach((parcelId) => {
            
                const satImageDatas = satImageByParcelIdDico[parcelId];
                if (satImageDatas) {
                    
                    const mostRecentSatImage = (satImageDatas.imageDataList[0]) ? satImageDatas.imageDataList[0] : null; // l'objet satImage le + récent (liste triée par l API)
                    const imageDataList = SatimageHelper.convertToDico(satImageDatas.imageDataList); // liste des parcelles => transformation dico (imageId - satImage)
                    const satImageWithHigherMaxccDico = SatimageHelper.convertToDico(satImageDatas.satImageWithHigherMaxccList); // liste d'entités satImage ayant un taux d'ennuagement supérieure au maxcc du client => transformation dico (imageId - satImage)
        
                    // ↓ 1- envoi de la liste de satImageDico au reducer et mets à jour la liste des états ↓
                    dispatch( ActionSatimagesLoadedOfParcel(parcelId, imageDataList) );
        
                    // ↓ 2- envoi du dico de satImageAboveMaxCCDico (entités satImage ayant un taux d'ennuagement supérieur au maxcc du client) au reducer ↓
                    dispatch( ActionAddSatimagesWithHighermaxccByparcelidDico(parcelId, satImageWithHigherMaxccDico) );

                    //↓↓ On a au moins un objet satImage ? (le + récent) ↓
                    if (mostRecentSatImage)
                        dispatch( ActionSetFirstSatimageIdToParcel(parcelId, mostRecentSatImage.id, mostRecentSatImage.date) ); // affectation de l'entité Satimage à l'entité parcel associée
                }  
        })
    }
}

/**
 * Action permettant d'ajouter les dates d'images des parcelles (pendant la sélection de la dates d'image en conseil azoté, biomasse ou modulation)
 * A refournir aux parcelles quand on retourne sur la carte.
 */
export function ActionAddTheLastSelectedSatImagesToSetOnMap(parcelIdValue, satImageIdValue, satImageDateValue) {
    return {
        type: ADD_THE_LAST_SELECTED_SATIMAGES_TO_SET_ON_MAP,
        parcelId: parcelIdValue,
        satImageId: satImageIdValue,
        satImageDate: satImageDateValue
    };
}

/**
 * Action permettant de se repositionner sur les bonnes dates d'images pour les parcelles en paramètre
 * quand on retourne sur la carte (si un conseil azoté, une modulation ou une biomasse a été fait).
 */
export function ActionSetOnMapTheLastSelectedSatImages() { 
    return function (dispatch, getState) {
        let theLastSelectedSatImageToSetOnMapDico = lodashGet( getState(), 'satimageData.theLastSelectedSatImageToSetOnMapDico', {});

        Object.keys(theLastSelectedSatImageToSetOnMapDico).forEach((parcelId) => {
            parcelId = parseInt(parcelId);
            dispatch(ActionSelectDateImg(theLastSelectedSatImageToSetOnMapDico[parcelId].satImageId, theLastSelectedSatImageToSetOnMapDico[parcelId].satImageDate, parcelId));
        });

        return dispatch({
            type: SET_ON_MAP_THE_LAST_SELECTED_SATIMAGES
        });
    }
}

/**
 * Action permettant de supprimer des images modélisées sur la période de la modélisation allant être générée
 * période = date de semis saisie par l'utilisateur à la date de récolte par défaut
 * @param {Modelisation[]} modelisations modelisations pour lesquelles il faut supprimer des images modélisées 
 */
export function ActionRemoveModelisationImage(modelisations) {
    return function (dispatch, getState) {

        // Si on n'a pas de modélisation, on ne fait rien
        if (lodashIsNil(modelisations) || lodashIsEmpty(modelisations)) {
            return;
        }

        // Dico<ParcelId, Dico<ImageId, Image>>
        // dico permettant de mettre à jour les satImageDico de chaque parcelle défini dans ce dico
        let satImageDicoByParcel = {}; 

        const currentStoreState = getState();
        const satImageByParcelDico = lodashGet(currentStoreState, 'satimageData.satimagesByParcelDico', {});
        const culturesForModelisation = lodashGet(currentStoreState, 'modelisationData.culturesForModelisation', []);

        // On ne veut pas itérer sur une valeur "undefined" ou "null" donc on filtre la liste avant 
        // C'est mieux de filtrer avant le forEach que de skip une itération de ce dernier
        modelisations.filter((m) => !lodashIsNil(m)).forEach((modelisation) => {

            // Récupération des images de la parcelle lié à la modélisation
            const satImageDico = lodashGet(satImageByParcelDico, `[${modelisation.parcelId}].satimageDico`, {});
            let newSatImageDico = Object.assign({}, satImageDico);
            
            // Récupération des date de semis et de récolte
            const cultureForModelisation = culturesForModelisation.find(c => c.cropType === modelisation.cropModel);
            let sowingDate = modelisation.sowingDate;
            let harvestDate = ModelisationHelper.getDefaultHarvestDate(cultureForModelisation, modelisation.campagne);

            // On retire les images corrrespondant à nos critères
            newSatImageDico = lodashOmitBy(newSatImageDico, (i) => {

                // L'image n'est pas une image modélisée
                if (i.sourceProvider !== SatImageSourceProvider.Modelisation) return false;

                let date = new Date(i.date);
                
                // L'image date d'avant la date de semis 
                if (date < sowingDate) return false;

                // L'image date après la date de récolte
                if (date > harvestDate) return false

                // Donc l'image est une image modélisée entre la date de semis et la date de récolte
                return true;
            });

            // On ajoute le dico modifié (avec les éventuelles images en moins) au dico global
            satImageDicoByParcel[modelisation.parcelId] = newSatImageDico;

            // On supprime des images donc si l'une d'elle était celle sélectionnée, il faut changer pour la plus récente
            const imageIdSelected = lodashGet(currentStoreState, `parcelsData.parcelDico[${modelisation.parcelId}].currentSatimageId`, 0);
            if (!lodashHas(newSatImageDico, `${imageIdSelected}`)) {

                // On récupère l'image avec la date la plus récente
                let lastImage = lodashMaxBy(Object.values(newSatImageDico), (i) => new Date(i.date).getTime());
                if (!lodashIsNil(lastImage)){

                    let imageDate = new Date(lastImage.date);
                    // On update l'image sélectionnée avec la plus récente
                    dispatch(ActionChangeSatimageIdToParcel(modelisation.parcelId, lastImage.id, imageDate))
                
                    // On regarde si la parcelle est sélectionnée, si oui, on update le contextData
                    const parcelId = lodashGet(currentStoreState, `contextAppData.parcelIdSelected`, 0);
                    if (parcelId === modelisation.parcelId) {
                        const parcelName = lodashGet(currentStoreState, `parcelsData.parcelDico[${modelisation.parcelId}].name`, "");

                        dispatch(ActionSelectDateImgParcel(modelisation.parcelId, parcelName, lastImage.id, imageDate, lastImage.sourceProvider));
                    }
                }
            }
        });

        // On sauvegarde dans rédux
        dispatch(ActionSetSatImageDicoByParcel(satImageDicoByParcel));
    }
}

/**
 * Action permettant de mettre à jour la raison de désactivation de l'image
 * @param {number} parcelId 
 * @param {number} satImageId
 * @param {number} reasonDeactivate 
 */
export function ActionUpdateImageDeactivationReason(parcelId, satImageId, reasonDeactivate) {
    return function (dispatch, getState) {

        //Signale qu'on modifie la raison de désactivation de l'image :
        dispatch(ActionUpdatingSatImageDeactivationReason());

        // Appel API
        historyWebApiProvider.updateSatImageDeactivationReason(dispatch, getState, parcelId, satImageId, reasonDeactivate) 
            .then((reason) => {
                dispatch(ActionUpdateSatImageDeactivationReason(parcelId, satImageId, reasonDeactivate));
            })
            .catch((error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorUpdateSatImageDeactivationReason(error));
            });
    }
}

export function ActionUpdateSatImageDeactivationReason(parcelIdValue, satImageIdValue, reasonDeactivateValue) {
    return {
        type: UPDATE_SATIMAGE_DEACTIVATION_REASON,
        parcelId: parcelIdValue,
        satImageId: satImageIdValue,
        reasonDeactivate: reasonDeactivateValue
    };
}

export function ActionUpdatingSatImageDeactivationReason() {
    return {
        type: UPDATING_SATIMAGE_DEACTIVATION_REASON
    };
}

export function ActionErrorUpdateSatImageDeactivationReason(errorValue) {
    return {
        type: ERROR_UPDATE_SATIMAGE_DEACTIVATION_REASON,
        errorMessage: errorValue,
    };
}