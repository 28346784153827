import detailsParcel from "../models/detailsParcel.js";
import dicoFunction, { geofoliaNumColumnParcelName, telepacLotNameColumn, telepacParcelNameColumn } from '../datas/dicoDetails.js';
import parse from 'date-fns/parse';
import { ParcelsHelper } from '../utils/parcelsHelper.js';
import numberHelper from '../utils/numberHelper.js'; //numberHelper.fixeDecimal(number); //garde que 2 décimales ! import numberHelper from '../../utils/numberHelper.js';
import StringTranslate from "../assets/i18n/stringLanguage.jsx";

/**
 * @module detailsConverter
 * @description Convertit les propriétés d'une parcelle en détails
 */
const detailsConverter = {

    /**
     * Fonction permettant de convertir les propriétés d'une parcelle en détails et de les ajouter à la parcelle
     * @param {Object} parcel 
     * @param {String} origin
     */
    convertPropertiesToDetailsFromParcel(parcel, origin) {
        var details = this.convertPropertiesToDetails((parcel) ? parcel.properties : undefined, origin);
        
        // calcul automatique de la surface (si manquante !):
        if ((!details.surface) || (details.surface <= 0.0)) {
            details.surface = ParcelsHelper.getAreaFromParcel(parcel);
        }

        if (details.surface && (details.surface > 0.0)) {
            if (details.surface instanceof String) {
                let areaValue = parseFloat(details.surface, 10);
                details.surface = numberHelper.fixeDecimal(areaValue);
            } else if (details.surface instanceof Number) {
                details.surface = numberHelper.fixeDecimal(details.surface);
            } else {
                let areaValueStr = `${details.surface}`;
                details.surface = numberHelper.fixeDecimal(areaValueStr); //fait aussi la convertion string to number...
            }
        }

        parcel.properties.pz_details = details;
        parcel.properties.pz_isDetailConverted = true;
    },

    /**
     * Fonction permettant de convertir les propriétés d'une parcelle en détails
     * @param {Object} properties 
     * @param {String} origin 
     * @returns {Object} Les details de la parcelles
     */
    convertPropertiesToDetails(properties, origin) {
        var details = new detailsParcel();
        const dico = dicoFunction();

        if (!properties) {
            return details;
        }

        // Ici on vérifie si l'origine n'est ni geofolia ni telepac ni agrimap (vérifier si l'origine EST l'un des 3 est aussi envisageable).
        // Pourquoi fais comme ça ? => en cas de faute de frappe dans le code, on aura toujours un retour de details complété (ou partiellement complété) de fait
        if ((origin !== "geofolia") && (origin !== "telepac") && (origin !== "agrimap") && (origin !== "mesparcelles")) {
            dico.forEach(column => {
                const listName = Object.values(column.listName); // On ne récupère que les "value" de l'objet [key, value] listName car on a pas besoin des origins (on les connais pas)
                
                for (let name in listName) {
                    if (properties[listName[name]] !== undefined) {
                        // on verifie si le format et le suffixe existe dans le dico pour savoir si notre colonne est de type Date ou non
                        if ((column.dateFormat !== undefined) && (column.dateSuffixe !== undefined)) {

                            // Parfois, la date que l'on reçoit est déjà de type Date et non une chaine de caractère. 
                            // Dans ce cas, on affecte directement sinon on "parse" la chaine en Date
                            if (properties[listName[name]] instanceof (Date)) {
                                details[column.name] = properties[listName[name]];
                            } else {

                                let formats = Object.values(column.dateFormat); // Récupération seulement des "value" de format de date comme plus haut
                                let suffixes = Object.values(column.dateSuffixe); // Récupération seulement des "value" de suffixe de date comme plus haut

                                // On parcours tous les formats de date. la variable index va ici servir pour récupérer le format mais aussi le suffixe
                                // car format et suffixe marche par pair
                                for (let index in formats) {
                                    let newDate = parse(properties[listName[name]] + suffixes[index], formats[index], new Date());

                                    if (!newDate.toString().includes("Invalid")) {
                                        details[column.name] = newDate;
                                        break;
                                    }
                                }
                            }
                        } else {
                            details[column.name] = properties[name];
                            //↓↓ Override de la fonctionnalité initiale - calcul automatique de la surface ↓↓
                            if (column.name === 'surface' && properties.pz_details && properties.pz_details.surface && properties.pz_details.surface > 0) // Override de la fonctionnalité initiale - calcul automatique de la surface
                                details[column.name] = properties.pz_details.surface;
                            if (column.name === 'culture' && properties.pz_details && properties.pz_detailsculture)
                                details[column.name] = properties.pz_details.culture;
                        }
                        break;
                    }
                }
            });
        } else {

            // Ici, on reproduit le même principe que plus haut à l'exception qu'on à pas besoin de boucler 
            // sur la liste de nom ni les formats/suffixes car on connais l'origine
            dico.forEach(column => {
                //Pour Geofolia, utilisation de l'index sinon, utilisation du nom de la propriété
                const propertieValue = (origin === 'geofolia') ? properties[Object.keys(properties)[column.numColumnGeofolia]] : properties[column.listName[origin]];
                if (propertieValue !== undefined) {
                    if ( (column.dateFormat !== undefined) && (column.dateSuffixe !== undefined) ) {

                        if (propertieValue instanceof (Date)) {
                            details[column.name] = propertieValue;
                        } else {
                            let newDate = parse(propertieValue + column.dateSuffixe[origin], column.dateFormat[origin], new Date());

                            if (!newDate.toString().includes("Invalid")) {
                                details[column.name] = newDate;
                            }
                        }
                    } else {
                        details[column.name] = propertieValue;
                        //↓↓ Override de la fonctionnalité initiale - calcul automatique de la surface ↓↓
                        if (origin !== "telepac") {
                            if (column.name === 'surface' && properties.pz_details && properties.pz_details.surface && properties.pz_details.surface > 0)
                                details[column.name] = properties.pz_details.surface;
                        }
                    }
                }
            });
        }
        return details;
    },

    /**
     * Fonction permettant de récupérer le nom de la parcelle qu'on importe
     * @param {Object} properties 
     * @param {String} origin 
     * @param {Number} [undefined] numéro de la parcelle
     * @returns {String} Differents nom selon telepac ou geofolia
     */
    getParcelName(properties, origin, numParcel = undefined) {
        let parcelName = numParcel === undefined ? "autre" : `${StringTranslate.parcelDefaultNameFormat}${numParcel}`;

        //Si la parcelle provient de Geofolia on récupère son nom via l'index de la colonne
        if(origin === "geofolia") {
            const propertieParcelName = properties[Object.keys(properties)[geofoliaNumColumnParcelName]];
            if ( (propertieParcelName !== undefined) && (propertieParcelName !== "") )
            {
                parcelName = propertieParcelName;
            }
        } else if (origin === "telepac") {
            parcelName = `${StringTranslate.telepacClusterAttribute} ${properties[telepacLotNameColumn]} - ${StringTranslate.telepacParcelAttribute} ${properties[telepacParcelNameColumn]}`;
        }

        return parcelName;
    },
}

export default detailsConverter;