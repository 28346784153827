import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ProcessStatus } from '../../redux/actions/modelisation';
import getTheme from "../../themes";
import { Chip, CircularProgress, } from '@mui/material';
import StringTranslate from '../../assets/i18n/stringLanguage';

const theme = getTheme();

const ProcessStatusHelper = {
    [ProcessStatus.Waiting]: {
        color: theme.palette.warning.dark,
        bgcolor: theme.palette.warning.light + 60,
        label: StringTranslate.processStatusWaiting,
    },
    [ProcessStatus.InProgress]: {
        color: theme.palette.info.dark,
        bgcolor: theme.palette.info.light + 60,
        label: StringTranslate.modelisationStatusInitialisation,// StringTranslate.processStatusInProgress,
        icon: <CircularProgress variant='indeterminate' size={10} />,
    },
    [ProcessStatus.Success]: {
        color: theme.palette.success.light,
        bgcolor: theme.palette.success.dark,
        label: StringTranslate.modelisationStatusHarvested, // StringTranslate.processStatusSuccess
        // Actuellement ce composant n'est utilisé que dans la modélisation et l'on souhaite avoir modelisationStatusHarvested plutot que processStatusSuccess
        // Si besoin dans le futur on pourra créer une variante en fonction du type de process
    },
    [ProcessStatus.Error]: {
        color: theme.palette.error.dark,
        bgcolor: theme.palette.error.light + 60,
        label: StringTranslate.processStatusError,
    },
    [ProcessStatus.Ready]: {
        color: theme.palette.success.dark,
        bgcolor: theme.palette.success.light + 60,
        label: StringTranslate.processStatusReady,
    },
    [ProcessStatus.Deferred]: {
        color: theme.palette.orange.dark,
        bgcolor: theme.palette.orange.light + 60,
        label: StringTranslate.processStatusDiferred,
    },
}

class ProcessStatusChip extends Component {
    render() {
        const { processStatus } = this.props;
        if ((!processStatus) || (processStatus === ProcessStatus.Unknown)) {
            return;
        }

        return (
            <Chip 
                icon={ProcessStatusHelper[processStatus].icon}
                size="small" 
                label={ProcessStatusHelper[processStatus].label}
                sx={{
                    color: ProcessStatusHelper[processStatus].color,
                    bgcolor: ProcessStatusHelper[processStatus].bgcolor,
                    '& .MuiChip-icon': {
                        color: ProcessStatusHelper[processStatus].color
                    }
                }} 
            />
        );
    }
}

ProcessStatusChip.propTypes = {
    processStatus: PropTypes.oneOf(Object.values(ProcessStatus)).isRequired,
};

export default ProcessStatusChip;