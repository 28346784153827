import React, { Component } from "react";

import { Dialog, Button, Typography,
    Grid, DialogContent, DialogTitle,
    DialogActions, DialogContentText, IconButton } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import { IsNativeHoster } from '../../utils/platformHelper';

import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import LogoSpotifarm from '../../assets/logoSpotifarm/LogoSpotifarmTitreEtFeuille';
import incitation_premium_lostvg from '../../assets/images/didacticiel/incitation_premium_lost.svg';


const CLOSE_WINDOW = "CLOSE_WINDOW";

///////////////////////////////////////////////////////////////////////////////////
// Composant appliqué par dessus (position:absolute) le composant RenderMaps 
//  pour afficher l'incitation Freemium avec un Shp
///////////////////////////////////////////////////////////////////////////////////
class DisplayIncitationFreemiumWithShp extends Component {
    /* constructeur */
    constructor(props) {
        super(props);

        this.state = {
            open: true,
        }
    }

    /* fonction permettant de fermer le fenêtre de dialogue */
    closeDialog(userChoice = CLOSE_WINDOW) {
        this.setState({ open: false });

        /* ↓ demande la fermeture de la popup d'incitation en précisant l'action utilisateur */
        if (this.props.closeInviteToPremium) {
            this.props.closeInviteToPremium(userChoice);
        }
    }

    handleCloseDialog() {
        this.closeDialog();
    }

    /* cycle de vie react */
    render() {
        const { fullScreen } = this.props;
        const { open } = this.state;

        let cultureValue = StringTranslate.getLanguage();
        if ((!cultureValue) || (cultureValue.length < 5)) {
            cultureValue = 'fr-FR';
        }
        //const languageValue = cultureValue.substring(0, 2);

        const nativeAppHoster = IsNativeHoster();
        
        return (open) ?
            (<>
                {/* ↓↓ Boite de dialogue en lien avec l'incitation freemium ayant déjà un shp ↓↓ */}
                <Dialog open={open}
                    fullScreen={fullScreen} // en mode fullscreen si on est en mobile @@ mettre en place
                    maxWidth={'xs'} // limitation de la largeur
                >
                    <DialogTitle sx={{ display:"flex", alignItems:"center"}}>
                        <LogoSpotifarm/>
                        
                        {/* Si l'appli Web est lancée dans l'appli mobile: */}
                        {(nativeAppHoster === true) ?
                            <IconButton sx={{
                                position: 'absolute',
                                right: 10,
                                top: 10,
                                color: (theme) => theme.palette.grey[500]
                            }}>
                                <CloseIcon onClick={this.handleCloseDialog.bind(this)} />
                            </IconButton> : null}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4">{StringTranslate.incitationfreemiumwithshptitle}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <img src={incitation_premium_lostvg} alt="" className="dfp-img-incitation" />
                            </Grid>
                            <Grid item xs={8}>
                                <Grid container rowSpacing={2}>
                                    <Grid item>
                                        <DialogContentText>{StringTranslate.incitationfreemiumwithshpcontent1}</DialogContentText>
                                    </Grid>
                                    <Grid item>
                                        <DialogContentText>{StringTranslate.incitationfreemiumwithshpcontent2}</DialogContentText>
                                    </Grid>
                                    <Grid item sx={{ mt: 1 }} >
                                        <Typography variant="caption">{StringTranslate.incitationfreemiumwithshpsignature}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid
                            container
                            direction="row"
                            justifyContent="right"
                            alignItems="right">
                            <Grid item>
                                <Button color="primary" variant="contained"
                                    onClick={this.handleCloseDialog.bind(this)}>
                                    {StringTranslate.incitationendoftrialok}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog></>) : null
    }
}

export default DisplayIncitationFreemiumWithShp; 