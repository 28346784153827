import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import getTheme from '../../../themes/index';

let theme = getTheme();


function IconeObservationRavageur(props: SvgIconProps) {
  return ( 
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" strokeWidth="1.8" stroke="none" fill="current">
        <g>
          <path d="M7,18.56c-.23-.35-.9-1.37-1.06-1.67a11.27,11.27,0,1,1,21.3-5.15A11.18,11.18,0,0,1,26,16.89c-.16.3-.83,1.32-1.07,1.69L16,31.17Z" fill={theme.palette.primary.main}/>
          <path d="M16,1A10.78,10.78,0,0,1,26.77,11.74a10.6,10.6,0,0,1-1.19,4.92c-.12.24-.68,1.1-1,1.61L16,30.3l-8.54-12c-.36-.54-.92-1.4-1-1.64a10.6,10.6,0,0,1-1.19-4.92A10.78,10.78,0,0,1,16,1m0-1A11.76,11.76,0,0,0,5.53,17.12c.2.39,1.09,1.73,1.09,1.73L16,32l9.38-13.18s.89-1.34,1.09-1.73A11.76,11.76,0,0,0,16,0Z" fill={theme.palette.primary.main}/>
        </g>
        <g>
          <path d="M16.15,7.22c2,0,3,2.89,3,5.6s-1.77,3.25-3,3.25h.09c-1.22,0-3-.54-3-3.25s.95-5.6,3-5.6Z" fill={theme.palette.common.white}/>
          <polyline points="12.17 6.48 12.4 9.41 13.52 8.17 14.75 8.84" fill="none" stroke={theme.palette.common.white} stroke-linecap="round" stroke-linejoin="round" strokeWidth="0.5898240000000002px"/>
          <polyline points="10.25 13.22 11.75 10.74 14.05 11.61" fill="none" stroke={theme.palette.common.white} stroke-linecap="round" stroke-linejoin="round" strokeWidth="0.5898240000000002px"/>
          <polyline points="12.92 16.09 11.73 13.96 14.23 13.1" fill="none" stroke={theme.palette.common.white} stroke-linecap="round" stroke-linejoin="round" strokeWidth="0.5898240000000002px"/>
          <path d="M13.3,4.25A2.74,2.74,0,0,0,14.83,5.7" fill="none" stroke={theme.palette.common.white} stroke-linecap="round" strokeMiterlimit="10" strokeWidth="0.5898240000000002px"/>
          <polyline points="20.22 6.48 19.99 9.41 18.87 8.17 17.64 8.84" fill="none" stroke={theme.palette.common.white} stroke-linecap="round" stroke-linejoin="round" strokeWidth="0.5898240000000002px"/>
          <polyline points="22.14 13.22 20.64 10.74 18.34 11.61" fill="none" stroke={theme.palette.common.white} stroke-linecap="round" stroke-linejoin="round" strokeWidth="0.5898240000000002px"/>
          <polyline points="19.47 16.09 20.66 13.96 18.16 13.1" fill="none" stroke={theme.palette.common.white} stroke-linecap="round" stroke-linejoin="round" strokeWidth="0.5898240000000002px"/>
          <path d="M19.09,4.25A2.74,2.74,0,0,1,17.56,5.7" fill="none" stroke={theme.palette.common.white} stroke-linecap="round" strokeMiterlimit="10" strokeWidth="0.5898240000000002px"/>
          <path d="M16.15,6.69h.09a2.54,2.54,0,0,1,1.58.61c.25-.25,0-.57,0-.91a1.47,1.47,0,0,0-1.63-1.54A1.47,1.47,0,0,0,14.6,6.39c0,.33-.22.63,0,.88a2.57,2.57,0,0,1,1.55-.58Z" fill={theme.palette.common.white}/>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default IconeObservationRavageur;