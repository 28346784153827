import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import getTheme from '../../../themes/index';

let theme = getTheme();


function IconeCreationZoneObservation(props: SvgIconProps) {
    return ( 
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" strokeWidth="1.8" stroke="none" fill="current">
              <path d="M16,2.14a5.38,5.38,0,0,0-5.38,5.38c0,3,5.38,9.73,5.38,9.73s5.37-6.76,5.37-9.73A5.37,5.37,0,0,0,16,2.14ZM16,10a2.45,2.45,0,1,1,2.45-2.45A2.45,2.45,0,0,1,16,10Z" fill={theme.palette.secondary.main}/>
              <path d="M22.64,9a12.53,12.53,0,0,1-.75,1.91,2.71,2.71,0,0,1,2.34,2.68V24.67a2.71,2.71,0,0,1-2.71,2.7h-11a2.7,2.7,0,0,1-2.7-2.7V13.62a2.71,2.71,0,0,1,2.33-2.68A12.44,12.44,0,0,1,9.36,9a4.74,4.74,0,0,0-3.61,4.59V24.67a4.73,4.73,0,0,0,4.72,4.72h11a4.72,4.72,0,0,0,4.72-4.72V13.62A4.74,4.74,0,0,0,22.64,9Z" fill={theme.palette.secondary.main}/>
            </svg>
        </SvgIcon>
    );
}

export default IconeCreationZoneObservation;