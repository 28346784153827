import lodashIsNil from 'lodash/isNil';
import numberHelper from './numberHelper';

export const SupplyType = {
    Solid: 'Solid',
    Liquid: 'Liquid'
}

const lastNitrogenInputHelper = {

    /*convertToDico(LastNitrogenInputsList) {
        if ((!LastNitrogenInputsList) || (!Array.isArray(LastNitrogenInputsList))) {
            return {};
        }

        let dicoLastNitrogenInputs = {};
        dicoLastNitrogenInputs.forEach(lastNitrogenInputItem => {
            dicoLastNitrogenInputs[lastNitrogenInputItem.id] = lastNitrogenInputItem;
        });

        return dicoLastNitrogenInputs;
    },*/ //pas nécessaire car on doit recevoir directement un dico de la part de la Web API !

    /*sortByParcel(LastNitrogenInputsList) {
        if (LastNitrogenInputsList !== undefined && LastNitrogenInputsList !== null) {
            LastNitrogenInputsList = LastNitrogenInputsList.sort((a, b) => (a.parcelId >= b.parcelId) ? 1 : -1);
        }
        return LastNitrogenInputsList;
    },*/ //quel est l'intérêt ???

    count(specificInfosDico) {
        if (!specificInfosDico) return 0;
        return Object.entries(specificInfosDico).length;
    },

    /**
     * Transformation de la donnée pour quelle soit exploitable par le front
     * @param {*} lastNitrogenInput 
     */
    getFromWebApiEntity(lastNitrogenInput) {
        if (lastNitrogenInput) { 
            const contentAvailable = (numberHelper.testDataValid(lastNitrogenInput.content) &&
                (lastNitrogenInput.content > 0) && (lastNitrogenInput.content <= 100)) ? true : false;

            if (lastNitrogenInput.dateImagSource) { //convertit en date !
                lastNitrogenInput.dateImagSource =  (lastNitrogenInput.dateImagSource instanceof Date) ? lastNitrogenInput.dateImagSource : new Date(lastNitrogenInput.dateImagSource);
            }
            if (lastNitrogenInput.zones) {
                let quantityToExpand = 0;
                const parcelAreaFund = (numberHelper.testDataValid(lastNitrogenInput.parcelArea)) ? lastNitrogenInput.parcelArea : 0
                lastNitrogenInput.zones.forEach(zoneItem => {
                    //calcul de la doze d'azote par Ha via la dose d'intrant et la teneur
                    zoneItem.actualDoseUnit = (contentAvailable) ? (zoneItem.actualDose * lastNitrogenInput.content / 100) : zoneItem.actualDose;
                    zoneItem.withContent = (contentAvailable);
                    zoneItem.area = (numberHelper.testDataValid(zoneItem.ratio)) ? (zoneItem.ratio * parcelAreaFund) : 0;
                    quantityToExpand += (zoneItem.area * (
                        (numberHelper.testDataValid(zoneItem.actualDose) && (zoneItem.actualDose > 0)) ? zoneItem.actualDose : 0));
                });
                lastNitrogenInput.quantityToExpand = quantityToExpand;
                lastNitrogenInput.quantityToExpandUnit = lastNitrogenInput.nitrogenPrescription * parcelAreaFund;
            }
            if (lastNitrogenInput.ratios) {
                lastNitrogenInput.ratios.forEach(ratioItem => {
                    ratioItem.withContent = false;
                });
            }
            
            lastNitrogenInput.supplyType = (lastNitrogenInput.supplyType === true) ? SupplyType.Liquid : SupplyType.Solid;
        }
    },

    /**
     * Transformation de la donnée pour quelle soit compréhensible par le back
     * @param {*} lastNitrogenInput 
     */
    transformToWebApiEntity(lastNitrogenInput) {
        // On reçoit de la part de l'appli ReactJs une chaîne. Mais exploit un boolean dans la Web API !
        if (!lodashIsNil(lastNitrogenInput)) { //@@A changer pour exploiter partout un boolean !
            if (lastNitrogenInput.supplyType === SupplyType.Liquid) {
                lastNitrogenInput.supplyType = true;
            } else {
                lastNitrogenInput.supplyType = false; // === SupplyType.Solid !
            }
        }
    }
};

export default lastNitrogenInputHelper;