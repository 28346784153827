import { observationWebApiProvider } from '../../utils/webApiProvider.js';
import sendError from '../../utils/errorService.js';
import { Observation } from '../../models/observation.js';
import { ObservationImage } from '../../models/observationImage.js';
import { IdentificationImage } from '../../models/identificationImage.js';


export const LOADING_OBSERVATIONS = 'LOADING_OBSERVATIONS';
export const ADD_OBSERVATION = 'ADD_OBSERVATION';
export const ADD_OBSERVATION_IMAGE = 'ADD_OBSERVATION_IMAGE';
export const SAVING_OBSERVATION = 'SAVING_OBSERVATION';
export const DELETING_OBSERVATION = 'DELETING_OBSERVATION';
export const ADD_OBSERVATION_DICO = 'ADD_OBSERVATION_DICO';
export const SHOW_ALL_OBSERVATION = 'SHOW_ALL_OBSERVATION';
export const RESET_OBSERVATION_DATAS = 'RESET_OBSERVATION_DATAS';
export const DELETE_OBSERVATION_ID_LIST = 'DELETE_OBSERVATION_ID_LIST';
export const DELETE_OBSERVATION_BY_PARCEL_ID_LIST = 'DELETE_OBSERVATION_BY_PARCEL_ID_LIST';
export const DELETE_ALL_LINKED_OBSERVATION = 'DELETE_ALL_LINKED_OBSERVATION';
export const ADD_OBSERVATION_TYPE_DICO = 'ADD_OBSERVATION_TYPE_DICO';
export const RESET_LAST_OBSERVATION_ID_SAVED = 'RESET_LAST_OBSERVATION_ID_SAVED';
export const SET_MSG_ERROR_DURING_SAVE = 'SET_MSG_ERROR_DURING_SAVE';
export const OBSERVATION_SAVED = 'OBSERVATION_SAVED';
export const SHOW_SNACKBAR_CREATE_OBSERVATION = 'SHOW_SNACKBAR_CREATE_OBSERVATION';
export const GO_TO_STEP_IDENTIFICATION = 'GO_TO_STEP_IDENTIFICATION';
export const SET_IDENTIFICATION_RESULT = 'SET_IDENTIFICATION_RESULT';
export const SET_IDENTIFICATION_IMAGE_AT_INDEX = 'SET_IDENTIFICATION_IMAGE_AT_INDEX';
export const ERROR_DETECTED_DURING_IDENTIFICATION = 'ERROR_DETECTED_DURING_IDENTIFICATION';
export const GO_TO_IDENTIFICATION = 'GO_TO_IDENTIFICATION';
export const START_TO_SEARCH_IDENTIFICATION = 'START_TO_SEARCH_IDENTIFICATION';
export const RESET_IDENTIFICATION_DATA = 'RESET_IDENTIFICATION_DATA';
export const CHOOSE_IDENTIFICATION_RESULT = 'CHOOSE_IDENTIFICATION_RESULT';
export const REMOVE_IDENTIFICATION_IMAGE = 'REMOVE_IDENTIFICATION_IMAGE';
export const SET_OBSERVATION_IMAGES_FOR_IDENTIFICATION = 'SET_OBSERVATION_IMAGES_FOR_IDENTIFICATION';

export const msgErrorDuringSaveValues = {
    image: 'image',
    observation: 'observation',
}

export const StepEnumIdentification = {
    CHOIX_IMAGE: 'CHOIX_IMAGE',
    RESULTAT: 'RESULTAT',
}

/* énuméré */
export const ActionTypeObservations = {
    LOADING_OBSERVATIONS: LOADING_OBSERVATIONS,
    ADD_OBSERVATION: ADD_OBSERVATION,
    ADD_OBSERVATION_IMAGE: ADD_OBSERVATION_IMAGE,
    SAVING_OBSERVATION: SAVING_OBSERVATION,
    ADD_OBSERVATION_DICO: ADD_OBSERVATION_DICO,
    SHOW_ALL_OBSERVATION: SHOW_ALL_OBSERVATION,
    RESET_OBSERVATION_DATAS: RESET_OBSERVATION_DATAS,
    DELETING_OBSERVATION: DELETING_OBSERVATION,
    DELETE_OBSERVATION_ID_LIST: DELETE_OBSERVATION_ID_LIST,
    DELETE_OBSERVATION_BY_PARCEL_ID_LIST: DELETE_OBSERVATION_BY_PARCEL_ID_LIST,
    DELETE_ALL_LINKED_OBSERVATION: DELETE_ALL_LINKED_OBSERVATION,
    ADD_OBSERVATION_TYPE_DICO: ADD_OBSERVATION_TYPE_DICO,
    SET_MSG_ERROR_DURING_SAVE: SET_MSG_ERROR_DURING_SAVE,
    RESET_LAST_OBSERVATION_ID_SAVED: RESET_LAST_OBSERVATION_ID_SAVED,
    OBSERVATION_SAVED: OBSERVATION_SAVED,
    SHOW_SNACKBAR_CREATE_OBSERVATION: SHOW_SNACKBAR_CREATE_OBSERVATION,
    GO_TO_STEP_IDENTIFICATION: GO_TO_STEP_IDENTIFICATION,
    SET_IDENTIFICATION_RESULT: SET_IDENTIFICATION_RESULT,
    SET_IDENTIFICATION_IMAGE_AT_INDEX: SET_IDENTIFICATION_IMAGE_AT_INDEX,
    ERROR_DETECTED_DURING_IDENTIFICATION: ERROR_DETECTED_DURING_IDENTIFICATION,
    GO_TO_IDENTIFICATION: GO_TO_IDENTIFICATION,
    START_TO_SEARCH_IDENTIFICATION: START_TO_SEARCH_IDENTIFICATION,
    RESET_IDENTIFICATION_DATA: RESET_IDENTIFICATION_DATA,
    CHOOSE_IDENTIFICATION_RESULT: CHOOSE_IDENTIFICATION_RESULT,
    REMOVE_IDENTIFICATION_IMAGE: REMOVE_IDENTIFICATION_IMAGE,
    SET_OBSERVATION_IMAGES_FOR_IDENTIFICATION: SET_OBSERVATION_IMAGES_FOR_IDENTIFICATION,
}

/* Actions creator */
export const ActionLoadingObservations = () => ({
    type: LOADING_OBSERVATIONS,
})

/* Actions creator */
export const ActionSavingObservation = () => ({
    type: SAVING_OBSERVATION,
})

/* Actions creator */
export const ActionDeletingObservation = () => ({
    type: DELETING_OBSERVATION,
})

/* Actions creator */
export const ActionAddObservation = (observation) => ({
    type: ADD_OBSERVATION,
    observationToAdd: observation,
    linkedParcelId:( observation.parcelId > 0) ? observation.parcelId : -1,
})

export const ActionAddObservationImage = (observationImage, index) => ({
    type: ADD_OBSERVATION_IMAGE,
    observationImage: observationImage,
    imageIndex: index,
})

/* Actions creator */
export const ActionAddObservationDico = (observationDico = {}) => ({
    type: ADD_OBSERVATION_DICO,
    observationDicoToAdd: observationDico,
    observationDicoToAddCounter: Object.keys(observationDico).length,
})

/* Actions creator */
export const ActionShowAllObservation = (bool) => ({
    type: SHOW_ALL_OBSERVATION,
    bool: bool,
})

/* Actions creator */
export const ActionResetObservationDatas = () => ({
    type: RESET_OBSERVATION_DATAS,
})

/* Actions creator */
export const ActionDeleteObservationIdList = (observationIdListToDelete = []) => ({
    type: DELETE_OBSERVATION_ID_LIST,
    observationIdList: observationIdListToDelete,
    observationIdListCounter: observationIdListToDelete.length,
})

/* Actions creator */
export const ActionDeleteObservationByParcelIdList = (parcelIdList = []) => ({
    type: DELETE_OBSERVATION_BY_PARCEL_ID_LIST,
    parcelIdList: parcelIdList,
})

/* Actions creator */
export const ActionDeleteAllLinkedObservationDatas = () => ({
    type: DELETE_ALL_LINKED_OBSERVATION,
})

/* Actions creator */
export const ActionAddObservationTypeDico = (observationTypeDico = {}) => ({
    type: ADD_OBSERVATION_TYPE_DICO,
    observationTypeDicoToAdd: observationTypeDico,
})

export const ActionResetLastObservationIdSaved = () => ({
    type: RESET_LAST_OBSERVATION_ID_SAVED,
})

export const ActionSetMsgErrorDuringSave = (msgError) => ({
    type: SET_MSG_ERROR_DURING_SAVE,
    msgError: msgError,
})

export const ActionObservationSaved = () => ({
    type: OBSERVATION_SAVED,
})

export const ActionShowSnackbarCreateZoneOrMarker = (text = null) => ({
    type: SHOW_SNACKBAR_CREATE_OBSERVATION,
    text: text,
})

export const ActionGoToStepIdentification = (step = StepEnumIdentification.CHOIX_IMAGE) => ({
    type: GO_TO_STEP_IDENTIFICATION,
    step: step,
})

export const ActionSetIdentificationResult = (result = null) => ({
    type: SET_IDENTIFICATION_RESULT,
    result: result,
})

export const ActionSetIdentificationImageAtIndex = (image = {}, index) => ({
    type: SET_IDENTIFICATION_IMAGE_AT_INDEX,
    image: image,
    index: index,
})

export const ActionErrorDetectedDuringIdentification = () => ({
    type: ERROR_DETECTED_DURING_IDENTIFICATION,
})

export const ActionGoToIdentification = (images = [], createObservation = undefined, closeIdentificationDialog = undefined) => ({
    type: GO_TO_IDENTIFICATION,
    images: images,
    createObservation: createObservation,
    closeIdentificationDialog: closeIdentificationDialog,
})

export const ActionStartToSearchIdentification = (images, imagesToSelect) => ({
    type: START_TO_SEARCH_IDENTIFICATION,
    images: images,
    imagesToSelect: imagesToSelect,
})

export const ActionResetIdentificationData = () => ({
    type: RESET_IDENTIFICATION_DATA,
})

export const ActionChooseIdentificationResult = (result) => ({
    type: CHOOSE_IDENTIFICATION_RESULT,
    result: result,
})

export const ActionRemoveIdentificationImage = (url) => ({
    type: REMOVE_IDENTIFICATION_IMAGE,
    url: url,
})

export const ActionSetObservationImagesForIdentification = (observationImages, closeIdentificationDialog) => ({
    type: SET_OBSERVATION_IMAGES_FOR_IDENTIFICATION,
    observationImages: observationImages,
    closeIdentificationDialog: closeIdentificationDialog,
})

/**
 * fonction permettant de sauvegarder une observation en BDD 
 */
export const actionSaveObservationToAPI = (newObservationToSave) => (dispatch, getState) => {
    
    dispatch( ActionSavingObservation() );

    //création d'un objet sans images pour un appel api plus leger
    let observationWithoutImages = new Observation({
        ...newObservationToSave,
        images: []
    });
    return observationWebApiProvider.saveObservation(observationWithoutImages)
            .then( async (observationSaved) => { 
                // ajoute l'observation créer dans redux
                dispatch( ActionAddObservation(observationSaved));

                observationWithoutImages.images = [
                    ...newObservationToSave.images,
                ];
                observationWithoutImages.id = observationSaved.id;
                
                let promises = [];
                observationWithoutImages.images.forEach((image, index) => {
                    let promise = null;
                    // Création d'une promesse pour chaque appel api pour les ajouts d'images
                    if (image.id === undefined) {
                        //Pour les images d'identification
                        promise = new Promise((resolve, reject) => {
                            let imageForRequest = new IdentificationImage({
                                ...image,
                            });
                            observationWebApiProvider.addObservationImageFromIdentificationImage(imageForRequest, observationSaved.id)
                                .then( (imageSaved) => {
                                    //sauvegarde Redux
                                    let imageSavedWithoutDatas = {
                                        ...imageSaved,
                                        imageData: null,
                                        vignetteData: null,
                                    };
                                    dispatch( ActionAddObservationImage(imageSavedWithoutDatas, index));
                                    dispatch( ActionRemoveIdentificationImage(image.imageUrl) );
                                    resolve();
                                })
                                .catch( (error) => {
                                    // envoie du type d'erreur à redux pour avertir l'utilisateur
                                    dispatch( ActionSetMsgErrorDuringSave(msgErrorDuringSaveValues.image) );
                                    //Trace Azure:
                                    sendError('actionSaveObservationToAPI - addObservationImageFromIdentificationImage', error);
                                    reject();
                                });
                        });
                    } else {
                        // Pour les images d'observations
                        promise = new Promise((resolve, reject) => {
                            let imageWithoutUrl = new ObservationImage({
                                ...image,
                                observationId: observationSaved.id,
                                imageURL: "",
                                vignetteUrl: "",
                            });
                            observationWebApiProvider.addObservationImage(imageWithoutUrl)
                                .then( (imageSaved) => {
                                    //sauvegarde Redux
                                    let imageSavedWithoutDatas = {
                                        ...imageSaved,
                                        imageData: null,
                                        vignetteData: null,
                                    };
                                    dispatch( ActionAddObservationImage(imageSavedWithoutDatas, index));
                                    resolve();
                                })
                                .catch( (error) => {
                                    // envoie du type d'erreur à redux pour avertir l'utilisateur
                                    dispatch( ActionSetMsgErrorDuringSave(msgErrorDuringSaveValues.image) );
                                    //Trace Azure:
                                    sendError('actionSaveObservationToAPI - addObservationImage', error);
                                    reject();
                                });
                        });
                    }
                    promises.push(promise);
                });
                
                // Attends que les Promesses soient terminées (peut import si resolve ou reject) et obtient une liste des résultats
                return Promise.allSettled(promises)
                    .then((results) => {
                        // Retourne si la sauvegarde c'est bien dérouler ou non
                        let isSuccess = true;
                        results.forEach((result) => {
                            if (result.status === 'rejected') {
                                isSuccess = false;
                            }
                        });
                        if (isSuccess) {
                            dispatch( ActionResetLastObservationIdSaved() );
                            dispatch( ActionResetIdentificationData() );
                        }
                        dispatch( ActionObservationSaved() );
                        return isSuccess;
                    });
            })
            .catch( (error) => {
                dispatch( ActionSetMsgErrorDuringSave(msgErrorDuringSaveValues.observation) );
                //Trace Azure:
                sendError('actionSaveObservationToAPI - saveObservation', error);
            });
}

/**
 * fonction permettant de mettre à jour une observation en BDD
 */
export const ActionUpdateObservationToAPI = (observationToUpdate) => (dispatch, getState) => {
    
    dispatch( ActionSavingObservation() );
    // Récupère uniquement les images avec id pour supprimer les anciennes images ne faisant pas parti de cette liste
    let oldImages = [];
    let oldImagesWithoutData = [];
    observationToUpdate.images.forEach((image)=>{
        if (image.id > 0) {
            oldImagesWithoutData.push(new ObservationImage({
                ...image,
                imageData: [],
                imageUrl: null,
                vignetteUrl: null,
            }));
            oldImages.push(new ObservationImage({
                ...image,
            }));
        }
    });
    let observationWithoutImageData = new Observation({
        ...observationToUpdate,
        images: [
            ...oldImagesWithoutData,
        ],
    });

    return observationWebApiProvider.updateObservation(observationWithoutImageData)
            .then( async (observationSaved) => { 
                // Maj rédux
                observationSaved.images = [...oldImages];
                dispatch( ActionAddObservation(observationSaved));

                let promises = [];
                observationToUpdate.images.forEach((image, index)=>{
                    if ( image.id <= 0 ) {
                        // Création d'une promesse pour chaque appel api pour les ajouts d'images
                        let promise = new Promise((resolve, reject) => {
                            // retire les url pour éviter un appel api trop volumineux
                            let imageWithoutUrl = new ObservationImage({
                                ...image,
                                observationId: observationSaved.id,
                                imageURL: "",
                                vignetteUrl: "",
                            });
                            observationWebApiProvider.addObservationImage(imageWithoutUrl)
                                .then( (imageSaved) => {
                                    //sauvegarde Redux
                                    let imageSavedWithoutDatas = {
                                        ...imageSaved,
                                        imageData: null,
                                        vignetteData: null,
                                    };
                                    dispatch( ActionAddObservationImage(imageSavedWithoutDatas, index));
                                    resolve(imageSaved);
                                })
                                .catch( (error) => {
                                    // envoie du type d'erreur à redux pour avertir l'utilisateur
                                    dispatch( ActionSetMsgErrorDuringSave(msgErrorDuringSaveValues.image) );
                                    //Trace Azure:
                                    sendError('actionSaveObservationToAPI - addObservationImage', error);
                                    reject();
                                });
                        });
                        promises.push(promise);
                    }
                    //Création de promesse pour la création d'image d'observation à partir d'une image d'identification
                    if (image.id === undefined) {
                        let promise = new Promise((resolve, reject) => {
                            let imageForRequest = new IdentificationImage({
                                ...image,
                            });
                            observationWebApiProvider.addObservationImageFromIdentificationImage(imageForRequest, observationSaved.id)
                                .then( (imageSaved) => {
                                    //sauvegarde Redux
                                    let imageSavedWithoutDatas = {
                                        ...imageSaved,
                                        imageData: null,
                                        vignetteData: null,
                                    };
                                    dispatch( ActionAddObservationImage(imageSavedWithoutDatas, index));
                                    dispatch( ActionRemoveIdentificationImage(image.imageUrl) );
                                    resolve();
                                })
                                .catch( (error) => {
                                    // envoie du type d'erreur à redux pour avertir l'utilisateur
                                    dispatch( ActionSetMsgErrorDuringSave(msgErrorDuringSaveValues.image) );
                                    //Trace Azure:
                                    sendError('actionSaveObservationToAPI - addObservationImageFromIdentificationImage', error);
                                    reject();
                                });
                        });
                        promises.push(promise);
                    }
                });

                // Attends que les Promesses soient terminées (peut import si resolve ou reject) et obtient une liste des résultats
                return Promise.allSettled(promises)
                    .then((results) => {
                        // Retourne si la sauvegarde c'est bien dérouler ou non
                        let isSuccess = true;
                        results.forEach((result) => {
                            if (result.status === 'rejected') {
                                isSuccess = false;
                            } else {
                                // Ajoute les images créées à la liste
                                observationSaved.images.push(result.value);
                            }
                        });
                        let returnResult = {
                            isSuccess: isSuccess,
                            observation: observationSaved,
                        };
                        return returnResult;
                    });
            })
            .catch( (error) => {
                dispatch( ActionSetMsgErrorDuringSave(msgErrorDuringSaveValues.observation) );
                //Trace Azure:
                sendError('actionUpdateObservationToAPI - updateObservation', error);
                let returnResult = {
                    isSuccess: false,
                };
                return returnResult;
            });
}

/**
 * fonction permettant de supprimer une liste d'ID d'observation en BDD (⚠️- la suppression est une simple désactivation en BDD) puis suppression redux 
 */
export const ActionDeleteObservationIdListToAPI = (newObservationIdListToDelete) => (dispatch, getState) => {
    
    dispatch( ActionDeletingObservation() );

    return observationWebApiProvider.deleteObservationList(newObservationIdListToDelete)
            .then( (observationIdListDisabled = []) => { 
                    let observationIdListToDelete = observationIdListDisabled;
                    dispatch( ActionDeleteObservationIdList(observationIdListToDelete) );
                    return observationIdListToDelete;
            })
            .catch( (error) => {
                //Trace Azure:
                sendError('ActionDeleteObservationIdListToAPI - deleteObservationList', error);
            });
}

export const ActionSearchPlant = (identificationImages, imagesToSelect) => (dispatch, getState) => {
    let imagesSaved = [];
    let promises = [];    

    dispatch( ActionStartToSearchIdentification(
            identificationImages.map((image)=>{
                let returnImage = {...image};
                //Si pas enregistrer, retire l'url (gain de perf redux)
                if (returnImage.id === -1) {
                    returnImage.imageUrl= "";
                }
                return returnImage;
            }), 
            imagesToSelect.map((image)=>{
                let returnImage = {...image};
                //Si pas enregistrer, retire l'url (gain de perf redux)
                if (returnImage.id === -1) {
                    returnImage.imageUrl= "";
                }
                return returnImage;
            }),
    ) );

    identificationImages.forEach( (image, index) => {
        if (image.isSave || (image.id > 0)) {
            imagesSaved.push(image);
        } else {
            //retire l'url pour diminuer la taille de la requête API
            let imageToAdd = new IdentificationImage({
                ...image,
                imageUrl: null,
            });
            // Création d'une promesse pour chaque appel api pour les ajouts d'images
            let promise = new Promise((resolve, reject) => {
                observationWebApiProvider.addIdentificationImage(imageToAdd)
                    .then((response) => {
                        resolve({
                            image: response,
                            index: index
                        });
                    })
                    .catch( (error) => {
                        //Trace Azure:
                        sendError('ActionSearchPlant - addIdentificationImage', error);
                        reject();
                    });
            });
            promises.push(promise);
        }
    });

    //Ajoute toutes les images puis recherche la plante avec les images sauvegardées
    Promise.allSettled(promises)
            .then((results) => {
                let errorDetected = false;
                results.forEach((result) => {
                    if (result.status === 'rejected') {
                        errorDetected = true;
                    } else {
                        let imageSaved = {
                            ...result.value.image,
                            imageData: null,
                            isSave: true
                        };
                        // Ajoute les images sauvegardées sur Azure à la liste
                        dispatch( ActionSetIdentificationImageAtIndex(imageSaved, result.value.index) );
                        imagesSaved.push(result.value.image);
                    }
                });

                if (errorDetected) {
                    dispatch( ActionErrorDetectedDuringIdentification() );
                } else {
                    //Recherche la plante avec les images saugardées sur Azure
                    observationWebApiProvider.searchPlant(imagesSaved)
                        .then( (searchResult) => {
                            dispatch( ActionSetIdentificationResult(searchResult) );
                        })
                        .catch( (error) => {
                            dispatch( ActionErrorDetectedDuringIdentification() );
                            //Trace Azure:
                            sendError('ActionSearchPlant - searchPlant', error);
                        });;
                }
            });
}

/**
 * fonction permettant de supprimer les images d'identification temporaire sur Azure
 */
export const ActionDeleteIdentificationImagesToAPI = () => (dispatch, getState) => {
    return observationWebApiProvider.deleteIdentificationImage()
            .then(()=>{
                dispatch( ActionResetIdentificationData() );
            })
            .catch( (error) => {
                //Trace Azure:
                sendError('ActionDeleteIdentificationImagesToAPI - deleteIdentificationImage', error);
            });
}