/*
    Ensemble de constantes permettant la configuration d'accès aux APIs de SpotiFarm:
*/
import ConstantsHosters from './constantsByConfig.js';


// Les constantes des différents contrôleurs !
const SubUrlWebApiClientsCst = 'Clients/';
const SubUrlWebApiSettingsCst = 'Settings/';
const SubUrlWebApiParcelsCst = 'Parcels/';
const SubUrlWebApiHistoryCst = 'History/';
const SubUrlWebApiAccountsCst = 'Accounts/';
const SubUrlWebApiBillingsCst = 'Billings/';
const SubUrlWebApiPvInjectionCst = 'PvInjection/';
const SubUrlWebApiObservationCst = 'Observations/';
const SubUrlWebApiModulationCst = 'Modulations/';
const SubUrlWebApiWeightingBiomassCst = 'Biomasses/';
const SubUrlWebApiTutorialFirstParcelCst = 'FirstParcel/';
const SubUrlWebApiFertilizerCst = 'Fertilizers/';
const SubUrlWebApiModelisationCst = 'Modelisation/';
const SubUrlWebApiHarvestCst = 'Harvests/';
const SubUrlWebApiVersioningCst = 'Utilities/Versioning';
const SubUrlWebApiLastNitrogenInputCst = 'LastNitrogenInput/';

//const UrlSentinelWmsCst = "https://services.sentinel-hub.com/ogc/wms/" + ConstantsHosters.sentinelApiWmsKey; //valable avant la distinction des instances selon la couche et le satellite visé (Sentinel ou Landsat).
const BaseUrlSentinelWmsCst = "https://services.sentinel-hub.com/ogc/wms/";
const BaseUrlLandsatWmsCst = "https://services-uswest2.sentinel-hub.com/ogc/wms/";

//
export const ConfigApi = {
    ////Constances générales:
    ConstAndDefault: {
        BaseUrlWebApi: ConstantsHosters.urlBaseWebApis,

        UrlWebApiWeightingBiomasses: ConstantsHosters.urlBaseWebApis + SubUrlWebApiWeightingBiomassCst,
        UrlWebApiModulations: ConstantsHosters.urlBaseWebApis + SubUrlWebApiModulationCst,
        UrlWebApiClients: ConstantsHosters.urlBaseWebApis + SubUrlWebApiClientsCst,
        UrlWebApiSettings: ConstantsHosters.urlBaseWebApis + SubUrlWebApiSettingsCst,
        UrlWebApiParcels: ConstantsHosters.urlBaseWebApis + SubUrlWebApiParcelsCst,
        UrlWebApiHistory: ConstantsHosters.urlBaseWebApis + SubUrlWebApiHistoryCst,
        UrlWebApiAccounts: ConstantsHosters.urlBaseWebApis + SubUrlWebApiAccountsCst,
        UrlWebApiBillings: ConstantsHosters.urlBaseWebApis + SubUrlWebApiBillingsCst,
        UrlWebApiPvInjection: ConstantsHosters.urlBaseWebApis + SubUrlWebApiPvInjectionCst,
        UrlWebApiObservation: ConstantsHosters.urlBaseWebApis + SubUrlWebApiObservationCst,
        UrlWebApiTutorialFP: ConstantsHosters.urlBaseWebApis + SubUrlWebApiTutorialFirstParcelCst,
        UrlWebApiVersioning: ConstantsHosters.urlBaseWebApis + SubUrlWebApiVersioningCst,
        UrlWebApiFertilizers: ConstantsHosters.urlBaseWebApis + SubUrlWebApiFertilizerCst,
        UrlWebApiModelisation: ConstantsHosters.urlBaseWebApis + SubUrlWebApiModelisationCst,
        UrlWebApiHarvests: ConstantsHosters.urlBaseWebApis + SubUrlWebApiHarvestCst,
        UrlWebApiLastNitrogenInput: ConstantsHosters.urlBaseWebApis + SubUrlWebApiLastNitrogenInputCst,

        //UrlSentinelWms: UrlSentinelWmsCst,
        // Désormais on distingue entre instance pour Sentinel et LandSat, mais aussi si couche Visible ou NDVI !!!!
        UrlSentinelVisibleWms: BaseUrlSentinelWmsCst + ConstantsHosters.sentinelVisibleApiWmsKey,
        UrlSentinelNdviWms: BaseUrlSentinelWmsCst + ConstantsHosters.sentinelNdviApiWmsKey,
        UrlLandsatVisibleWms: BaseUrlLandsatWmsCst + ConstantsHosters.landsatVisibleApiWmsKey,
        UrlLandsatNdviWms: BaseUrlLandsatWmsCst + ConstantsHosters.landsatNdviApiWmsKey,
    },
};

export default ConfigApi;