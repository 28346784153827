import React from "react";
import { connect } from 'react-redux';
import { get as lodashGet } from 'lodash';

import SelectParcelForLastNitrogenInput from "../../components/lastNitrogenInput/selectParcelForLastNitrogenInput";
import DataGridSkeleton from "../../components/dataGridSkeleton.jsx";
import LastNitrogenInputStepsManagement from "../../components/lastNitrogenInput/lastNitrogenInputStepsManagement";

import { Box } from "@mui/material";

import { ActionGetAllLastNitrogenInputs } from "../../redux/actions/lastNitrogenInput";


///////////////////////////////////////////////////////////////////////////
// Composant général concernant les derniers apports azotés (gestion et affichage).
///////////////////////////////////////////////////////////////////////////
class AbstractLastNitrogenInput extends React.Component {
    componentDidMount() {
        const { getAllLastNitrogenInputs, lastNitrogenInputDicoIsLoaded, } = this.props;		
		//La liste des derniers apports sont chargés à cet endroit

		//Récupère les derniers apports, si pas déjà obtenu:
		if (lastNitrogenInputDicoIsLoaded === false) {
			getAllLastNitrogenInputs();
		}
    }

    /* fonction cycle de vie react */
    render() {
        const { newLastNitrogenInput, loading } = this.props;
        
        return(
            <>
                {
                    // Affiche un écran de chargement en attendant de récupérer les informations des derniers apports du client
                    (loading)?
                        <Box sx={{ width: '100%' }} >
                            <DataGridSkeleton/>
                        </Box>
                    :
                    // Affiche la séléction des parcelles pour lesquelles on peut lancer un dernier apport
                    (newLastNitrogenInput)?
                        <SelectParcelForLastNitrogenInput/>
                    :
                    // Affiche le tableau des derniers apports existants
                        <LastNitrogenInputStepsManagement />//Steps ici
                }
            </>
        )
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    //Infos provenant du reducer 'lastNitrogenInput' :
	lastNitrogenInputDicoIsLoaded: lodashGet(state, 'lastNitrogenInputData.lastNitrogenInputDicoIsLoaded', false),
	loading: lodashGet(state, 'lastNitrogenInputData.loading', false),
});

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
	getAllLastNitrogenInputs: () => dispatch( ActionGetAllLastNitrogenInputs() ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AbstractLastNitrogenInput);