import * as React from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

/* Utils, Helpers */
import { ParcelsHelper } from '../../../utils/parcelsHelper.js';
import format from 'date-fns/format';
import numberHelper from '../../../utils/numberHelper.js';

/* Translate */
import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';

/* Redux */
import { FertilizerStep, ActionUpdateFertilizerDoses, ActionCleanErrorFertilizer, ActionSelectWinterDatesByParcelId,
	ActionBuildFertilizerFile, ActionGoToStepOfFertilizer } from "../../../redux/actions/fertilizer";

/* Mui COmponents */
import { Box, Grid, Typography, ButtonGroup, Button, styled, TextField,
	TableContainer, Table, TableHead, TableBody, TableCell, TableRow, TableFooter, Paper,
	Tooltip, Card, CardContent, Divider, FormControl, OutlinedInput, InputAdornment, //FormHelperText,
	Stack, LinearProgress, CircularProgress, Alert } from '@mui/material';

/* Icons */
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Help, Add, Remove, NavigateNext, InfoOutlined } from '@mui/icons-material';
import IconPdf from '@mui/icons-material/PictureAsPdf';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import FmdBadOutlinedIcon from '@mui/icons-material/FmdBadOutlined';

/* React Components */
import SubCard from "../../subLayouts/subCard.jsx";
import AlertDialog from '../../alertDialog';
import LinkToContactUs from '../../linkToContactUs';

/* Theme Berry */
import getTheme from "../../../themes/index.js";
let theme = getTheme();

/* les constantes pour les unités de l'azote */
const AzoteUnit = "U";
const AzoteUnitBySurface = `${AzoteUnit}/Ha`;
const AzoteUnitSolidType = "Kg";
const AzoteUnitSolidBySurface = `${AzoteUnitSolidType}/Ha`;
const AzoteUnitLiquidType = "L";
const AzoteUnitLiquidBySurface = `${AzoteUnitLiquidType}/Ha`;

/* Obtient la couleur de la zone de modulation */
const getColor = function (color) {
	let Color = require('color');
	return Color(color).hex();
}

/* Customisation des boutons + et - du nombre de zones */
const CustomTextField = styled(TextField)({

	'& label.Mui-focused': {
		color: 'transparent',
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: 'transparent',
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: 'transparent',
		},
		'&:hover fieldset': {
			borderColor: 'transparent',
		},
		'&.Mui-focused fieldset': {
			borderColor: 'transparent',
		},
	},
});


const defaultMinZoneNbr = 2;
const defaultMaxZoneNbr = 6;

class NitrogenResult extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			parcelAreaFund: (props && props.fertilizerSelected) ? lodashGet(props, props.fertilizerSelected.isArchived ? `parcelArchivedForFertilizerDico[${props.fertilizerSelected.idParcel}].area` : `parcelDico[${props.fertilizerSelected.idParcel}].area`, 0) : 0,
			doseSaved: lodashGet(props, 'fertilizerSelected.doseSaved', undefined),

			openDialogOfErrors: (props.errorMessage !== '' && props.errorMessage !== undefined) ? true : false,

		};

		this.popupErrorDialog = {
			getTitle: () => { 
				return (
					<Stack direction="row" spacing={1} alignItems="flex-end">
						<ErrorOutlineIcon />
						<Typography>{StringTranslate.errorsuite}</Typography>
					</Stack>
				); 
			},
			description: props.errorMessage,
			getAdditionalDescription: () => { return (<Typography variant="subtitle1">{StringTranslate.errorDialogText2}<LinkToContactUs displayText={StringTranslate.contactUs}/>.</Typography>); },
			button: StringTranslate.close,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const { errorMessage } = this.props;

		if (((!prevProps) || (!prevProps.errorMessage) || (prevProps.errorMessage === '')) &&
			(errorMessage && (errorMessage !== ''))) {
			this.popupErrorDialog.description = errorMessage;
			this.setState({
				openDialogOfErrors: true,
			});
		}
	}

	/* Ferme le dialog des messages d'erreur */
	closeDialogOfErrors = () => {
		this.setState({
			openDialogOfErrors: false,
		});

		if (this.props.cleanErrorFertilizer) {
			this.props.cleanErrorFertilizer();
		}
	}

	/* Fonction permettant de retourner à l'étape du pilotage des parcelles */
	goBackToSpecificInfosManagement = () => {
		this.props.goToStep(FertilizerStep.MANAGEMENT_NITROGEN);
	}

	/* Fonction permettant de retourner au niveau de la carte pour
	le choix des dates d'entrée et de sortie d'hiver */
	goBackToMap = () => {
		const { goToStep, selectBeginningAndEndOfWinterDatesByParcelId, fertilizerSelected } = this.props;

		//La carte se trouve à l'étape MANAGEMENT_NITROGEN
		goToStep(FertilizerStep.MANAGEMENT_NITROGEN);
		selectBeginningAndEndOfWinterDatesByParcelId((fertilizerSelected) ? fertilizerSelected.idParcel : -1, true);
	}

	/* Modification de la dose de réserve */
	onChangeDoseSaved = (event) => {
		let newDoseSaved = event.target.value;

		if (newDoseSaved < 0) {
			newDoseSaved = newDoseSaved * (-1);
		}
		this.setState({ doseSaved: newDoseSaved });
	}

	/* fonction de mise à jour de la dose de réserve */
	applyChangeDoseSaved = () => {
		const { doseSaved } = this.state;
		const { updateFertilizerDoses, fertilizerSelected, zonesCounterBuild } = this.props; //RQ: le nbr de zones ne changera pas !

		let fertilizerParameter = {
			id: fertilizerSelected.id,
			idClient: fertilizerSelected.idClient,
			idParcel: fertilizerSelected.idParcel,
			newParameters: {
				zonesCounter: zonesCounterBuild, //ne change pas le nombre de zones !
				doseSaved: doseSaved
			}
		};

		updateFertilizerDoses(fertilizerParameter);
	}

	/* fonction d'ajout ou de suppression de zones lors des clics sur les boutons +, - */
	applyAddOrRemoveZones = (isForIncrZone) => {
		const { doseSaved } = this.state; //RQ : on prendra en compte un éventuel changement de dose de réserve !
		const { updateFertilizerDoses, fertilizerSelected, zonesCounterBuild } = this.props;

		let fertilizerParameter = {
			id: fertilizerSelected.id,
			idClient: fertilizerSelected.idClient,
			idParcel: fertilizerSelected.idParcel,
			newParameters: {
				zonesCounter: (isForIncrZone === true) ? (zonesCounterBuild + 1) : (zonesCounterBuild - 1),
				doseSaved: doseSaved
			}
		};

		updateFertilizerDoses(fertilizerParameter);
	}

	//Génération du PDF :
	onDownLoadPDF = () => {
		const { downloadFertilizerFile, fertilizerSelected } = this.props;
		if (!fertilizerSelected) return;
		try {
			downloadFertilizerFile(fertilizerSelected.id);
		}
		catch (err) { }
	}

	render() {
		const { 
			parcelAreaFund, doseSaved, openDialogOfErrors
		} = this.state;
		const { 
			parcelDico, cultureSelected, building, readableMode, fertilizerSelected, zonesCounterBuild, parcelArchivedForFertilizerDico,
			downloadingPrescription,
		} = this.props;

		const labelCrop = (readableMode === true) ? lodashGet(fertilizerSelected, 'crop.label', '') : lodashGet(cultureSelected, 'name', '');

		let averageToExpand = 0;
		const zones = lodashGet(fertilizerSelected, 'zones', []);
		if (zones && (parcelAreaFund > 0)) {
			zones.forEach(zoneItem => {
				averageToExpand += (zoneItem.ratio * parcelAreaFund * (
					(numberHelper.testDataValid(zoneItem.actualDose) && (zoneItem.actualDose > 0)) ? zoneItem.actualDose : 0));
			});

			averageToExpand = numberHelper.fixeDecimal(averageToExpand / parcelAreaFund);
		} else {
			averageToExpand = lodashGet(fertilizerSelected, 'nitrogenPrescription', 0);
		}
		//définit la valeur permettant de convertir les doses de U/Ha à Kg/Ha ou L/Ha :
		const rateUnitToKgL = 100 / lodashGet(fertilizerSelected, 'content', 100);
		const supplyTypeValue = lodashGet(fertilizerSelected, 'supplyType', true);
		const unitOfPartToExpand = (supplyTypeValue === true) ? AzoteUnitLiquidBySurface : AzoteUnitSolidBySurface;
		const unitOfTotalToExpand = (supplyTypeValue === true) ? AzoteUnitLiquidType : AzoteUnitSolidType;
		const maxZonesCount = lodashGet(fertilizerSelected, 'maxZonesCount', 0); //peut être 'undefined' si on n'a pas encore détecté de limite !
		const maxZoneCounter = (maxZonesCount > 0) ? maxZonesCount : defaultMaxZoneNbr;
		return (
			<>
				{/* Dialog des erreurs */}
				{(openDialogOfErrors === true) &&
					<AlertDialog popup={this.popupErrorDialog} handleChangePopup={this.closeDialogOfErrors} />
				}

				<Box sx={{ width: '100%' }}>
					<Typography fontWeight="bold" m={2}>{StringTranslate.libelecolumnculture} : {labelCrop}</Typography>
					<Divider sx={{ mb: 4 }} />

					{(building === true) && <LinearProgress sx={{ mb: 1 }} />}

					<Grid container spacing={2} disabled={building}>
						<Grid item xs={12} md={6} lg={4}>

							{/* Map modulation */}
							<SubCard title={<Typography sx={{ fontWeight: "bold" }}>{StringTranslate.subTitleDefinitionZones}</Typography>}>
								<Grid container rowSpacing={2}>
									<Grid item xs={12}>

										{ /* on affiche les dates */}
										{fertilizerSelected && (<>
											<Grid container>
												<Grid item sx={{ width: '150px' }}>
												<Typography variant="subtitle1" sx={{ color: theme.palette.text.primary, pl: 1 }}>{StringTranslate.winterOutingShot}</Typography>
												</Grid>
												<Grid item>
												<Typography variant="subtitle1" sx={{ color: theme.palette.primary.dark }}>{(fertilizerSelected.beginningWinterDate === undefined) ? "---" : `${format(fertilizerSelected.beginningWinterDate, StringTranslate.formatDate)}`}</Typography>
												</Grid>
											</Grid>
											<Grid container>
												<Grid item sx={{ width: '150px' }}>
												<Typography variant="subtitle1" sx={{ color: theme.palette.text.primary, pl: 1 }}>{StringTranslate.winterEntranceShot}</Typography>
												</Grid>
												<Grid item>
												<Typography variant="subtitle1" sx={{ color: theme.palette.primary.dark }}>{(fertilizerSelected.endingWinterDate === undefined) ? "---" : `${format(fertilizerSelected.endingWinterDate, StringTranslate.formatDate)}`}</Typography>
												</Grid>
											</Grid>
										</>)}

										{ /* on affiche le nom de la parcelle */}
										<Typography variant="subtitle1" sx={{ pt: 2 }} fontWeight="bold">
											{fertilizerSelected && (
												ParcelsHelper.getParcelNamefromIdParcel(fertilizerSelected.isArchived ? parcelArchivedForFertilizerDico : parcelDico, fertilizerSelected.idParcel)
											)}
										</Typography>
									</Grid>
									{((maxZonesCount > 0 && maxZonesCount < 6) && (zonesCounterBuild === maxZonesCount)) &&
                                        <Grid item xs={12}>
                                            <Card>
                                                <Alert
                                                    severity="warning"
                                                    icon={<InfoOutlined />}
                                                    sx={{backgroundColor: theme.palette.warning.light}}
                                                >
                                                    <Typography>{StringTranslate.badNumberOfAreas}</Typography>
                                                </Alert>
                                            </Card>
                                        </Grid>
                                    }
									<Grid item xs={12}>
										<Grid item container spacing={1} xs={12} sx={{ alignItems: "center" }}>
											<Grid item xs={4}>
												<Typography variant="body2">{`${StringTranslate.nbZones}`}</Typography>
											</Grid>
											<Grid item xs={4}>
											{(readableMode === true) ? <Typography variant="body2">{zonesCounterBuild}</Typography> :
												(zonesCounterBuild > 0) ? (
													<ButtonGroup size="large" variant="text" color="inherit" sx={{ border: '1px solid', borderColor: 'grey.400' }} disabled={(building === true)}>
														<Button
															key="three"
															onClick={() => this.applyAddOrRemoveZones(false)}
															disabled={((zonesCounterBuild === defaultMinZoneNbr) || (readableMode === true))}
															sx={{ border: 'none !important', minWidth: '0px !important' }}
														>
															<Remove color={((zonesCounterBuild === defaultMinZoneNbr) || (readableMode === true)) ? theme.palette.grey[200] : "primary" } fontSize="inherit" />
														</Button>
														<CustomTextField key="two"
															id="modulation_nb_zones"
															type="number"
															InputProps={{
																inputProps: {
																	max: { maxZoneCounter }, min: { defaultMinZoneNbr },
																	style: { textAlign: "center" },
																}
															}}
															value={zonesCounterBuild}
															name="zonesCounter"
														/>
														<Button
															key="one"
															disabled={((zonesCounterBuild === maxZoneCounter) || (readableMode === true))}
															onClick={() => this.applyAddOrRemoveZones(true)}
															sx={{ minWidth: '0px !important' }}>
															<Add color={((zonesCounterBuild === maxZoneCounter) || (readableMode === true)) ? theme.palette.grey[200] : "primary" } fontSize="inherit" />
														</Button>
													</ButtonGroup>) : ("---")
											}
											</Grid>
										</Grid>
									</Grid>

									{((fertilizerSelected !== undefined) && (fertilizerSelected.resultWinterImagResult !== null)) && (
										<Grid item xs={12}>
											{(fertilizerSelected.resultWinterImagResult.dataImg !== null) ? (
												<img id='imgData' src={`data:image/jpeg;base64,${fertilizerSelected.resultWinterImagResult.dataImg}`} 
													alt="zones-modulation" className="image-zones-biomass" />
											) : (
												<img id='imgUrl' src={fertilizerSelected.resultWinterImagResult.azureImagURL} alt="zones-modulation" className="image-zones-biomass" />
											)}
										</Grid>
									)}
								</Grid>
							</SubCard>
						</Grid>

						{/* Zones de modulation */}
						<Grid item xs={12} md={6} lg={4}>
							<SubCard title={<Typography sx={{ fontWeight: "bold" }}>{StringTranslate.subTitleDoses}</Typography>}>
								<Grid container rowSpacing={2}>

									{ /* liquide ou solide */}
									<Grid item xs={12}>
										<Typography variant="h5" sx={{ color: theme.palette.text.primary, pl: 1 }}>{StringTranslate.supplyType} : {fertilizerSelected.supplyType === true ? "Liquide" : "Solide"}</Typography>
									</Grid>

									{ /* Tableau des doses */}
									<Grid item xs={12}>
										<TableContainer component={Paper}>
											<Table sx={{ maxWidth: "650px" }} aria-label="caption table" size="small">
												<TableHead>
													<TableRow>
														{/* cette première colonne est prévu pour la couleur de cette zone */}
														<TableCell></TableCell>
														<TableCell>{`${StringTranslate.area} (Ha)`}</TableCell>
														<TableCell>{<><div>{StringTranslate.doseNitrogen}</div> ({AzoteUnitBySurface})</>}</TableCell>
														<TableCell>{<><div>{StringTranslate.doseInputs}</div> ({unitOfPartToExpand})</>}</TableCell>
													</TableRow>
												</TableHead>

												{(zones !== undefined) &&
													<TableBody>
														{zones.map((row, index) => (
															<TableRow key={row.id} className="table-row-modulation">
																<TableCell style={{ background: getColor(row.color), width: '5%' }} />
																<TableCell className="cell-ratio">
																	<Typography>{numberHelper.fixeDecimal(row.ratio * parcelAreaFund)}</Typography>
																</TableCell>
																{/* dose en U/Ha */}
																<TableCell style={{ width: '30%' }}>
																	<Typography>{numberHelper.fixeDecimal(row.actualDose)}</Typography>
																</TableCell>
																{/* dose en Kg/Ha ou L/Ha */}
																<TableCell style={{ width: '30%' }}>
																	<Typography>{numberHelper.fixeDecimal(row.actualDose * rateUnitToKgL)}</Typography>
																</TableCell>
															</TableRow>
														))}
													</TableBody>
												}

												<TableFooter>
													{ /* Aide pour les zones de modulation */}
													{(fertilizerSelected.cloudyDetected === true) ?
														<TableRow>
															<TableCell colSpan={4}>
															<Tooltip
																title={`${StringTranslate.helpCloudZones}`}
																placement="bottom">
																<Help sx={{ verticalAlign: "middle", pl: 1, color: theme.palette.grey[700] }} />
															</Tooltip>
															</TableCell>
														</TableRow> : null
													}
													<TableRow>
														{/* Pour afficher la moyenne des doses pondérée des surfaces à épandre: */}
														{(averageToExpand > 0) && (<>
															<TableCell colSpan={2}>
																<Typography fontWeight="bold">{StringTranslate.quantityToFertiExpandShort}</Typography>
															</TableCell>
															<TableCell>
																<Typography fontWeight="bold">{numberHelper.fixeDecimal(averageToExpand)} {AzoteUnitBySurface}</Typography>
															</TableCell>
															<TableCell>
																<Typography fontWeight="bold">{numberHelper.fixeDecimal(averageToExpand * rateUnitToKgL)} {unitOfTotalToExpand}</Typography>
															</TableCell>
														</>)}
													</TableRow>
													<TableRow>
														{/* Pour afficher la quantité total à épandre: */}
														{(averageToExpand > 0) && (<>
															{/* 'colSpan' vaut 3 (et pas 2) pour que le nombre se retrouve sous la dernière colonne */}
															<TableCell colSpan={3}>
																<Typography fontWeight="bold">{StringTranslate.totalDose}</Typography>
															</TableCell>
															<TableCell>
																<Typography fontWeight="bold">{numberHelper.fixeDecimal(averageToExpand * rateUnitToKgL * parcelAreaFund)} {unitOfTotalToExpand}</Typography>
															</TableCell>
														</>)}
													</TableRow>
												</TableFooter>
											</Table>
										</TableContainer>
									</Grid>
								</Grid>
							</SubCard>
						</Grid>

						<Grid item xs={12} md={12} lg={4}>
							<Grid container rowSpacing={2}>

								{/* Dose du conseil du moteur (Azote total) */}
								<Grid item xs={12}>
									<Card
										sx={{
											background: theme.palette.info.main,
											position: 'relative',
											color: theme.palette.common.white,
											'&:hover svg': {
												opacity: '1',
												transform: 'scale(1.1)'
											}
										}}
									>
										<CardContent>
											<Typography
												variant="body2"
												sx={{
													position: 'absolute',
													right: 15,
													top: 25,
													color: theme.palette.common.white,
													'&> svg': {
														width: 40,
														height: 40,
														opacity: '0.4',
														transition: 'all .3s ease-in-out'
													}
												}}
											>
												<TaskAltIcon />
											</Typography>
											<Grid container spacing={0}>
												<Grid item xs={12}>
													<Typography variant="h5" color="inherit">{StringTranslate.recommandedTotalDose}</Typography>
												</Grid>
												<Grid item xs={12}>
													<Typography variant="h1" color="inherit">{fertilizerSelected.nitrogenTotal} {AzoteUnitBySurface}</Typography>
												</Grid>
											</Grid>
										</CardContent>
									</Card>
								</Grid>

								{/* Dose totale à épandre lorsque tout est ok */}
								{(fertilizerSelected) && (fertilizerSelected.nitrogenPrescription > 0) && (
									<Grid item xs={12}>
										<Card
											sx={{
												background: theme.palette.secondary.main,
												position: 'relative',
												color: theme.palette.common.white,
												'&:hover svg': {
													opacity: '1',
													transform: 'scale(1.1)'
												}
											}}
										>
											<CardContent>
												<Typography
													variant="body2"
													sx={{
														position: 'absolute',
														right: 15,
														top: 25,
														color: theme.palette.common.white,
														'&> svg': {
															width: 40,
															height: 40,
															opacity: '0.4',
															transition: 'all .3s ease-in-out'
														}
													}}
												>
													<TaskAltIcon />
												</Typography>
												<Grid container spacing={0}>
													<Grid item xs={12}>
														<Typography variant="h5" color="inherit">{StringTranslate.quantityToFertiExpand}</Typography>
													</Grid>
													<Grid item xs={12}>
														<Typography variant="h1" color="inherit">{fertilizerSelected.nitrogenPrescription} {AzoteUnitBySurface}</Typography>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>
								)}

								{/* Dose totale à épandre lorsque qu'il n'y a plus d'azote épandable == '(Ntotal - NEpandu - NReserve) < 0' !*/}
								{((!fertilizerSelected) || (fertilizerSelected.nitrogenPrescription <= 0)) && (
									<Grid item xs={12}>
										<Card
											sx={{
												background: theme.palette.error.main,
												position: 'relative',
												color: theme.palette.common.white,
												'&:hover svg': {
													opacity: '1',
													transform: 'scale(1.1)'
												}
											}}
										>
											<CardContent>
												<Typography
													variant="body2"
													sx={{
														position: 'absolute',
														right: 15,
														top: 10,
														color: theme.palette.common.white,
														'&> svg': {
															width: 40,
															height: 40,
															opacity: '0.4',
															transition: 'all .3s ease-in-out'
														}
													}}
												>
													<FmdBadOutlinedIcon />
												</Typography>
												<Grid container spacing={0}>
													<Grid item xs={12}>
														<Typography variant="h1" color="inherit">{StringTranslate.badQuantityToFertiExpand}</Typography>
													</Grid>
													<Grid item xs={12}>
														<Typography variant="h5" color="inherit">{StringTranslate.badQuantityToFertiExpandDetails}</Typography>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>
								)}

								{/* Dose déjà épandue et dose de réserve */}
								<Grid item xs={12}>
									{(readableMode === false) ? (
										<SubCard
											sx={{
												background: theme.palette.background.default,
												position: 'relative',
												border: '1px solid',
												borderColor: theme.palette.info.main,
												'&:hover svg': {
													opacity: '1',
													transform: 'scale(1.1)'
												}
											}}
										>
											<Typography
												variant="body2"
												sx={{
													position: 'absolute',
													right: 15,
													top: 25,
													color: theme.palette.info.main,
													'&> svg': {
														width: 40,
														height: 40,
														opacity: '0.4',
														transition: 'all .3s ease-in-out'
													}
												}}
											>
												<EmojiObjectsIcon />
											</Typography>
											<Grid container spacing={0} direction="row">
												<Grid item xs={12}>
													<Typography variant="h5" sx={{ color: theme.palette.text.primary, pl: 1 }}>{StringTranslate.doseAlreadyProvided}</Typography>
												</Grid>
												<Grid item xs={12}>
													<Typography variant="h3" sx={{ color: theme.palette.text.primary, pl: 1 }}>
														{((fertilizerSelected.doseExtended < 0) || (fertilizerSelected.doseExtended === null)) ? 
															0 : fertilizerSelected.doseExtended} {AzoteUnitBySurface}
													</Typography>
												</Grid>
												<Grid item sx={{ pt: 2, pr: 1 }}>
													<Grid container>
														<Grid item xs={12}>
															<Typography variant="h5" sx={{ color: theme.palette.text.primary, pl: 1 }}>{StringTranslate.reserveDose}</Typography>
														</Grid>
													</Grid>
													<Grid item>
														<Stack direction="row" spacing={1}>
															<FormControl sx={{ maxWidth: '20ch' }} variant="outlined">
																<OutlinedInput
																	id="outlined-adornment-dose-saved"
																	endAdornment={<InputAdornment position="end">{AzoteUnitBySurface}</InputAdornment>}
																	type="number"
																	size="small"
																	value={doseSaved}
																	onChange={(event) => this.onChangeDoseSaved(event)}
																	disabled={building === true}
																/>
															</FormControl>
															<Button variant="contained" sx={{ height: '39px' }} disabled={(building === true)} onClick={() => this.applyChangeDoseSaved()}>{StringTranslate.calculateAgain}</Button>
														</Stack>
													</Grid>
												</Grid>
											</Grid>
										</SubCard>
									) : (
										<Card
											sx={{
												background: theme.palette.background.default,
												position: 'relative',
												border: '1px solid',
												borderColor: theme.palette.info.main,
												'&:hover svg': {
													opacity: '1',
													transform: 'scale(1.1)'
												}
											}}
										>
											<CardContent>
												<Typography
													variant="body2"
													sx={{
														position: 'absolute',
														right: 15,
														top: 25,
														color: theme.palette.info.main,
														'&> svg': {
															width: 40,
															height: 40,
															opacity: '0.4',
															transition: 'all .3s ease-in-out'
														}
													}}
												>
													<EmojiObjectsIcon />
												</Typography>
												<Grid container spacing={0.5}>
													<Grid item xs={12}>
														<Typography variant="h5" color="inherit">{StringTranslate.doseAlreadyProvided}</Typography>
													</Grid>
													<Grid item xs={12}>
														<Typography variant="h3" color="inherit">
															{((fertilizerSelected.doseExtended < 0) || (fertilizerSelected.doseExtended === null)) ? 
																0 : fertilizerSelected.doseExtended} {AzoteUnitBySurface}
														</Typography>
													</Grid>
													<Grid item xs={12}>
														{/* vide ! */}
														<Typography variant="h3" color="inherit"> </Typography>
													</Grid>
													<Grid item xs={12}>
														<Typography variant="h5" color="inherit">{StringTranslate.reserveDose}</Typography>
													</Grid>
													<Grid item xs={12}>
														<Typography variant="h3" color="inherit">
															{((fertilizerSelected.doseSaved < 0) || (fertilizerSelected.doseSaved === null)) ? 
																0 : fertilizerSelected.doseSaved} {AzoteUnitBySurface}
														</Typography>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									)}
								</Grid>

								<Grid item xs={12}
									sx={{ display: (lodashGet(fertilizerSelected, 'enumOfCrop', 1) === 3) ? 'block' : 'none' }}
								>
									<Card
										sx={{
											background: theme.palette.background.default,
											position: 'relative',
											border: '1px solid',
											borderColor: theme.palette.info.main,
											'&:hover svg': {
												opacity: '1',
												transform: 'scale(1.1)'
											}
										}}
									>
										<CardContent>
											<Alert
												severity='info'
												icon={<InfoOutlined fontSize="inherit" />}
											>
												<Typography>
													{StringTranslate.associatedFrostCulture}
												</Typography>
											</Alert>
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					{/* Bouton de retour vers l'étape 3 */}
					<Grid container marginTop={3} disabled={building}>
						<Grid item xs={6} sm={4}>
							{(readableMode === false) &&
								<Button variant="text" onClick={this.goBackToMap} disabled={(building === true)}>
									{StringTranslate.goBack}
								</Button>}
						</Grid>
						<Grid item xs={12} sm={4}>
							<Stack direction="row" sx={{ 
                                [theme.breakpoints.only('xs')]: { justifyContent: "flex-end" },
                                [theme.breakpoints.up('md')]: { justifyContent: "center" },
                             }}>
								<Button 
									startIcon={downloadingPrescription ? <CircularProgress color="inherit" size={20} /> : <IconPdf />} 
									variant="contained" 
									color="secondary"
									onClick={this.onDownLoadPDF}
									disabled={downloadingPrescription}>
									{StringTranslate.downloadPPF}
								</Button>
							</Stack>
						</Grid>
						<Grid item xs={6} sm={4} sx={{ [theme.breakpoints.only('xs')]: { marginTop: 1 } }}>
							{(readableMode === false) && (
								<Stack direction="row" justifyContent="flex-end">
									<Button 
										startIcon={<NavigateNext />}
										variant="contained"
										color="primary"
										onClick={this.goBackToSpecificInfosManagement}>
										{StringTranslate.registerAndContinue}
									</Button>
								</Stack>
							)}
						</Grid>
					</Grid>
				</Box>
			</>
		);
	}
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = function (state) {
	return {
		//Infos provenant du reducer 'parcel':
		parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
		parcelArchivedForFertilizerDico: lodashGet(state, 'parcelsData.parcelArchivedForFertilizerDico', {}),

		//Infos provenant du reducer 'fertilizer':		
    	cultureSelected: lodashGet(state, 'fertilizerData.cultureSelected', undefined),
		fertilizerSelected: (lodashGet(state, 'fertilizerData.readableMode', false) === true) ? 
			lodashGet(state, 'fertilizerData.fertilizerShowed', undefined) : lodashGet(state, 'fertilizerData.fertilizerSelected', undefined),
		zonesCounterBuild: (lodashGet(state, 'fertilizerData.readableMode', false) === true) ? //pas affiché mais utile pour détecter des modifications de ce qui a été re-généré !
			lodashGet(state, 'fertilizerData.fertilizerShowed.zonesCounterBuild', 2) : lodashGet(state, 'fertilizerData.fertilizerSelected.zonesCounterBuild', 2), 
		
		building: lodashGet(state, 'fertilizerData.building', false),
		errorMessage: lodashGet(state, 'fertilizerData.errorMessage', undefined),
		readableMode: lodashGet(state, 'fertilizerData.readableMode', false),
		downloadingPrescription: lodashGet(state, 'fertilizerData.downloadingPrescriptions', false),
	};	
}

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    goToStep: (step) => dispatch(ActionGoToStepOfFertilizer(step)),
	
	updateFertilizerDoses: (fertilizerParameter) => dispatch( ActionUpdateFertilizerDoses(fertilizerParameter) ),
	cleanErrorFertilizer: () => dispatch( ActionCleanErrorFertilizer() ),
	selectBeginningAndEndOfWinterDatesByParcelId: (parcelIdSelected, isGoBack) => dispatch(ActionSelectWinterDatesByParcelId(parcelIdSelected, isGoBack)),
	downloadFertilizerFile: (fertilizerId) => dispatch(ActionBuildFertilizerFile(fertilizerId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NitrogenResult);