import lodashGet from 'lodash/get';
import format from 'date-fns/format';
import ConstantsLayers from './constantsOfLayers.js';
import dateHelper from '../utils/dateHelper';
import StringTranslate from '../assets/i18n/stringLanguage.jsx';


/**
 * Helper pour la manipulation du reducer présent ici
 */
export const SatimageHelper = {
    /* fonction permettant de passer d'une liste à un dico - le concept de normalisation des données est ici utilisé */
    convertToDico(imageDataList) {
        if (!imageDataList || !Array.isArray(imageDataList)) return {};

        let dico = {};
        imageDataList.forEach(imageData => {
            if (imageData) { //pas sencé être indéfini, mais on contrôle tout de même...
                //On lui ajoute des propriétés pour faciliter son exploitation:
                /*let date = new Date(imageData.date);      
                imageData.day = (date.getDate());          
                imageData.month = (date.getUTCMonth() + 1);
                imageData.year = (date.getUTCFullYear());
                imageData.dateStr = `${imageData.day}/${imageData.month}/${imageData.year}`;*/
                SatimageHelper.buildDateString(imageData);

                const imageIdStr = `${imageData.id}`;
                dico[imageIdStr] = imageData;
            }
            //else //ne sera pas exploiter dans l'appli (et évite les plantes) !
        });

        return dico;
    },

    buildDateString(satimageValue) {
        if (!satimageValue) return false;

        try {
            const date = new Date(satimageValue.date);

            satimageValue.day = (date.getDate());
            satimageValue.month = (date.getUTCMonth() + 1);
            satimageValue.year = (date.getUTCFullYear());

            satimageValue.dateStr = format(date, StringTranslate.formatDate);
        }
        catch (err) {
            return false;
        }

        return true;
    },

    /* Méthodes autres */
    /**
     *  fonction permettant de récupérer l'objet (date est la + récente, imageId ) depuis une liste contenant des entités dateImg
     */
    getMostRecentSatimageFromList(satimageDicoValue) { // 'dateImgDico' === des couples de type '<DateTime, int>', c'est donc un dico !
        if (!satimageDicoValue) return;

        let mostRecentSatimage = undefined;
        try {
            for (var itemPropName in satimageDicoValue) {
                if (itemPropName) {
                    const satimage = satimageDicoValue[itemPropName];
                    if ((!mostRecentSatimage) && satimage && satimage.data && satimage.dataLight) {
                        mostRecentSatimage = satimage;
                    }
                }
            }
        } catch (e) {
            //RAS
        }

        return mostRecentSatimage;
    },

    /* Méthodes autres */
    /**
     *  fonction permettant de récupérer l'objet (date est la + récente, imageId ) depuis une liste contenant des entités dateImg
     */
    searchMostRecentSatimageFromList(satimageDicoValue) { // 'dateImgDico' === des couples de type '<DateTime, int>', c'est donc un dico !
        if (!satimageDicoValue) return undefined;

        // On recherche en parcourant la liste car la liste a probablement été chargé mois par mois...
        let mostRecentSatimage = undefined;
        try {
            let currentMostDate = undefined;
            for (var itemPropName in satimageDicoValue) {
                if (itemPropName) {
                    const satimage = satimageDicoValue[itemPropName];
                    if (satimage && satimage.data && satimage.dataLight) {
                        let dateImg = undefined;
                        if (!(dateImg instanceof Date)) {
                            dateImg = new Date(satimage.date);
                        } else {
                            dateImg = satimage.date;
                        }

                        if (!mostRecentSatimage) {
                            currentMostDate = dateImg;
                            mostRecentSatimage = satimage;
                        } else if (dateHelper.Compare(dateImg, currentMostDate) === 1) {
                            currentMostDate = dateImg;
                            mostRecentSatimage = satimage;
                        }
                        //else 'dateImg' < 'mostRecentSatimage'
                    }
                }
            }
        } catch (e) {
            //RAS
        }

        return mostRecentSatimage;
    },

    /* Méthodes autres */
    /**
     *  fonction permettant de récupérer l'objet (date est la + récente, imageId ) depuis une liste contenant des entités dateImg
     */
    getSatimageFromListByDate(satimageDicoValue, date) { // 'dateImgDico' === des couples de type '<DateTime, int>', c'est donc un dico !
        if ((!satimageDicoValue) || (!date)) return undefined;

        let dateInstance = undefined;
        if (!(date instanceof Date)) {
            dateInstance = new Date(date);
        } else {
            dateInstance = date;
        }

        let satimageForThisDate = undefined;
        try {
            for (var itemPropName in satimageDicoValue) {
                if (itemPropName) {
                    const satimage = satimageDicoValue[itemPropName];
                    if ((!satimageForThisDate) && satimage &&
                        //satimage.data && satimage.dataLight && //ne teste pas la présence des flux car potentiellement, on ne les a pas encore obtenus !
                        satimage.date && (dateHelper.isSameDateDDMMYYYY(new Date(satimage.date), dateInstance))) {
                        satimageForThisDate = satimage;
                    }
                }
            }
        } catch (e) {
            //RAS
        }

        return satimageForThisDate;
    },

    /**
     *  fonction permettant de récupérer le flux d'image spécifique
     */
    getImageData(satimageByParcelDico, parcelId, satimageId, layerType) {
        if (!satimageByParcelDico || (parcelId <= 0) || (satimageId <= 0) || !layerType) return null;

        if (layerType === ConstantsLayers.NdviParcelLayerName)
            return lodashGet(satimageByParcelDico, `[${parcelId}].satimageDico[${satimageId}].data`, null);
        /*else if (layerType === ConstantsLayers.VisibleParcelLayerName)
            return lodashGet( satimageByParcelDico, `[${parcelId}].satimageDico[${satimageId}].dataVisible`, null);*/
        else {
            return null;
        };
    },

    /**
     *  fonction permettant de récupérer le flux d'image spécifique
     */
    getImageUrl(satimageByParcelDico, parcelId, satimageId, layerType) {
        if (!satimageByParcelDico || (parcelId <= 0) || (satimageId <= 0) || !layerType) return null;

        if (layerType === ConstantsLayers.NdviParcelLayerName)
            return lodashGet(satimageByParcelDico, `[${parcelId}].satimageDico[${satimageId}].azureNdviURL`, null);

        return null;
    },

    /**
     *  fonction permettant de savoir si l'entité satImage est caractérisée comme nouvelle image (par sa propriété isNew)
     */
    isNewSatimage(satimageByParcelDico, parcelId, satimageId) {
        if (!satimageByParcelDico || (parcelId <= 0) || (satimageId <= 0)) return false;

        return lodashGet(satimageByParcelDico, `[${parcelId}].satimageDico[${satimageId}].isNew`, false);
    },

    /**
     *  fonction permettant de mettre à jour la propriété isNew
     */
    setIsNewSatimage(parcelDico, satimageByParcelDico, parcelId, bool) {
        if (!parcelDico || !satimageByParcelDico || (parcelId <= 0)) return;

        const parcel = parcelDico[parcelId];
        const currentSatimageId = (parcel) ? parcel.currentSatimageId : null;
        const satImage = lodashGet(satimageByParcelDico, `[${parcelId}].satimageDico[${currentSatimageId}]`, null);
        if (satImage)
            satImage.isNew = bool;
    },

    /**
     * fonction permettant de savoir si les images du mois correspondant a la date, ont deja été chargé
     * @param {Date} date 
     * @param {int} parcelId 
     * @returns {boolean | "FUTURE_DATE"} true si les images ont été chargé, false si non, 'FUTURE_DATE' si le mois en question est dans le futur
     */
    isImagesAsked(date, parcelId, currentStoreState) {
        const currentDate = new Date();

        if (date > currentDate) {
            return 'FUTURE_DATE'
        }

        const stateAsk = lodashGet(currentStoreState, `satimageData.satimagesByParcelDico[${parcelId}].stateByYearMonthDico[${date.getFullYear()}_${date.getMonth() + 1}].stateAsk`);
        return stateAsk === "DATEIMG_ASKED_OK";
    },

    /**
     * Fonction permettant de trouver les mois manquant a partir d'un Dico
     * @param {*} satimagesByParcelDico 
     * @returns {Array<{year: number, month: number}>} tableau contenant les mois manquant 
     */
    getMissingMonth(satimagesByParcelDico) {
        const stateByYearMonth = satimagesByParcelDico.stateByYearMonthDico;
        if (stateByYearMonth === undefined) return;

        let missingMonths = [];

        // on trie les dates
        const tmp = Object.values(stateByYearMonth).sort((a, b) => {
            // Comparaison par année
            const yearComparison = a.year - b.year;

            // Si les années sont égales, compare par mois
            if (yearComparison === 0) {
                return a.month - b.month;
            }

            return yearComparison;
        }).reverse();

        // si il y a un trou, on ajoute les mois manquants dans le tableau missingMonths
        tmp.forEach((value, index, array) => {
            if (index < array.length - 1) {
                const year = value.year;
                const month = value.month;
                const nextYear = array[index + 1].year;
                const nextMonth = array[index + 1].month;

                const isSameYear = year === nextYear;
                const monthDiff = month - nextMonth;

                if (monthDiff >= 2 && isSameYear) {
                    for (let i = month - 1; i > nextMonth; i--) {
                        missingMonths.push({ year: year, month: i })
                    }
                } else if (!isSameYear && month === 1 && nextMonth !== 12) {
                    for (let i = 12; i > nextMonth; i--) {
                        missingMonths.push({ year: nextYear, month: i })
                    }
                }
            }
        });

        return missingMonths;
    },

    /**
     * Fonction permettant de trouver les mois actuellement en chargement
     * @param {Number} parcelId id de la parcelle
     * @param {*} satimageByParcelDico 
     * @returns {Array<{year: number, month: number}>} tableau des mois en chargement
     */
    getMonthInProgress(parcelId, satimageByParcelDico) {
        if ((parcelId === undefined) || (satimageByParcelDico === undefined)) return undefined;

        const stateByYearMonth = satimageByParcelDico.stateByYearMonthDico;

        let monthsInProgress = [];

        for (const key in stateByYearMonth) {
            const state = stateByYearMonth[key]
            if (state.stateAsk === 'DATEIMG_IN_PROGRESS') { //TODO: définir les constantes à un seul endroit !
                monthsInProgress.push({ year: state.year, month: state.month })
            }
        }

        return monthsInProgress;
    },

    /**
     * Fonction permettant de savoir ou se trouve une date dans un interval
     * @param {String} dateStr la date en chaine de caractère
     * @param {Object} range l'interval
     * @returns {"OUT_OF_RANGE" | "IN_EXTRA_RANGE" | "IN_RANGE"} sa position dans l'interval
     */
    checkDateInterval(dateStr, range) {
        const date = new Date(dateStr);

        // on s'interesse qu'a la date du jour, pour ne pas fausser les resultats on doit mettre a 0 le reste :
        date.setHours(0, 0, 0, 0);

        if (date) {
            if (date >= range.beginDate && date <= range.endDate) {
                return "IN_RANGE";
            } else if (
                (date >= range.beginExtraDate && date < range.beginDate) ||
                (date <= range.endExtraDate && date > range.endDate)
            ) {
                return "IN_EXTRA_RANGE";
            }
        }
        return "OUT_OF_RANGE";
    }
}
