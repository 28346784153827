import PropTypes from 'prop-types';
import isValid from 'date-fns/isValid';

/**
 * Objet observationImage
 */
export class ObservationImage {
    constructor(props) {
        this.id = props.id || -1;
        this.clientId = props.clientId || -1;
        this.observationId = props.observationId || -1;
        this.date = (isValid(new Date(props.date))) ? new Date(props.date) : new Date();
        this.width = props.width || 0;
        this.height = props.height || 0;
        this.imageUrl = props.imageUrl || null;
        this.imageData = props.imageData || [];
        this.vignetteUrl = props.vignetteUrl || null;
        this.vignetteData = props.vignetteData || [];
    }
}

ObservationImage.propTypes = {
    clientId: PropTypes.number,
    observationId: PropTypes.number,
    date: PropTypes.objectOf(Date),
    width: PropTypes.number,
    height: PropTypes.number,
    imageUrl: PropTypes.string,
    imageData: PropTypes.arrayOf(PropTypes.number),
    vignetteUrl: PropTypes.string,
    vignetteData: PropTypes.arrayOf(PropTypes.number),
};