import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

/* MUI Components */
import {
    Table, TableHead, TableRow, TableCell, TableBody
} from "@mui/material";

import dateHelper from '../../utils/dateHelper';

/* theme Berry */
import getTheme from "../../themes/index.js";
let theme = getTheme();

const headerRowSx = { backgroundColor: 'transparent !important', border: 0 }

function backgroundByDegreeOfCertainty(degree, position = undefined) {

    let color = "transparent";

    if (position !== undefined) {
        switch(position) {
            case 0: 
                if (degree <= 3) {
                    color = theme.palette.success.main;
                }
                else {
                    color = theme.palette.error.main;
                }
                break;
            case 1:
            case 2:
            case 3:
                if (degree <= 3 && degree >= position) {
                    color = theme.palette.success.main;
                }
                else if (degree >= 4) {
                    color = theme.palette.error.main;
                }
                else {
                    color = theme.palette.grey[400];
                }
                break;
            case 4:
            case 5:
                color = theme.palette.grey[400];
                break;
            default:
                color = theme.palette.grey[400];
                break;
        }
    }

    return {
        p: 0, 
        width: 14, 
        border: 1, 
        borderColor: theme.palette.background.paper,
        backgroundColor: color,
        color: theme.palette.background.paper,
    };
}

/* Affichage du degré de certitude avec sa date de récolte approximative.
   ... Pourrait changer de style ou de fonctionnement ... */
function DegreeOfCertainty(props) {
    const estimatedDateMinus5 = dateHelper.addDaysToDate(props.estimatedDate, -5);
    const estimatedDatePlus5 = dateHelper.addDaysToDate(props.estimatedDate, 5);
    return (
       <Table sx={{ width: 234, maxHeight: 37, marginBottom: 1 }} aria-label="table degree of certainty">
            <TableHead>
                <TableRow sx={headerRowSx}>
                    <TableCell sx={backgroundByDegreeOfCertainty(props.degreeOfCertainty)} colSpan={3} align="left">{(new Date(estimatedDateMinus5)).toLocaleDateString(StringTranslate.getLanguage(), { month: 'short' })}</TableCell>
                    <TableCell sx={backgroundByDegreeOfCertainty(props.degreeOfCertainty)} colSpan={5} align="center">{(new Date(props.estimatedDate)).toLocaleDateString(StringTranslate.getLanguage(), { month: 'short' })}</TableCell>
                    <TableCell sx={backgroundByDegreeOfCertainty(props.degreeOfCertainty)} colSpan={3} align="right">{(new Date(estimatedDatePlus5)).toLocaleDateString(StringTranslate.getLanguage(), { month: 'short' })}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell sx={backgroundByDegreeOfCertainty(props.degreeOfCertainty, 5)} align="center">{ estimatedDateMinus5.getDate() }</TableCell>
                    <TableCell sx={backgroundByDegreeOfCertainty(props.degreeOfCertainty, 4)} />
                    <TableCell sx={backgroundByDegreeOfCertainty(props.degreeOfCertainty, 3)} />
                    <TableCell sx={backgroundByDegreeOfCertainty(props.degreeOfCertainty, 2)} />
                    <TableCell sx={backgroundByDegreeOfCertainty(props.degreeOfCertainty, 1)} />
                    <TableCell sx={backgroundByDegreeOfCertainty(props.degreeOfCertainty, 0)} align="center">{ props.estimatedDate.getDate() }</TableCell>
                    <TableCell sx={backgroundByDegreeOfCertainty(props.degreeOfCertainty, 1)} />
                    <TableCell sx={backgroundByDegreeOfCertainty(props.degreeOfCertainty, 2)} />
                    <TableCell sx={backgroundByDegreeOfCertainty(props.degreeOfCertainty, 3)} />
                    <TableCell sx={backgroundByDegreeOfCertainty(props.degreeOfCertainty, 4)} />
                    <TableCell sx={backgroundByDegreeOfCertainty(props.degreeOfCertainty, 5)} align="center">{ estimatedDatePlus5.getDate() }</TableCell>
                </TableRow>
            </TableBody>
        </Table> 
    );
}

export default DegreeOfCertainty;
