import { Component } from "react";
import { get as lodashGet, } from 'lodash';
import { connect } from 'react-redux';
import { ModelisationSteps, ActionGoToStepOfModelisation } from "../../redux/actions/modelisation";
import { Grid, Typography, Divider, Button, TableContainer, Box } from "@mui/material";
import { ActionGoToMap } from "../../redux/actions/contextApp";
import StringTranslate from '../../assets/i18n/stringLanguage';
import { ParcelsHelper } from "../../utils/parcelsHelper";
import { ThumbnailParcelShapeFromPathInfos } from "../thumbnail/ThumbnailParcelShape";
import { UpdateNbRowsPerPageTable, TableType } from "../../redux/actions/settings";
import CustomDataGrid from "../customDataGrid";
import ProcessStatusChip from '../customComponents/ProcessStatusChip';
import DateHelper from "../../utils/dateHelper";

class ModelisationResultPage extends Component {
    constructor(props) {
        super(props);
        this.columns = this.initialiseColumns();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { language, parcelIdFilteredList } = this.props;

        // Si la langue de l'appli à changer, on met à jour les colonnes
        if (language !== nextProps.language) {
            this.columns = this.initialiseColumns(parcelIdFilteredList);
        }

        return true;
    }

    /**
     * Initialisation des colonnes.
     */
    initialiseColumns() {

        let newColumns = [];

        /* ↓ ajout colonne visible - (Miniature + nom de la parcelle) ↓ */
        newColumns.push({
            headerName: `${StringTranslate.nomcolumn}`,
            field: "name",
            minWidth: 180,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.nomcolumn}</Typography>)
            },
            renderCell: params => {
                return (
                    <Box style={{ display: "flex", flexDirection: "row", alignItems: "center"  }} >
                        {(params) ? (<ThumbnailParcelShapeFromPathInfos id={`listItemTbl_${params.row.thumbnailInfos.parcelId}`} {...params.row.thumbnailInfos} />) : (undefined)}
                        <Typography>{params.value}</Typography>
                    </Box>
                )
            }
        });
        
        //Colonne status modélisation
        newColumns.push({
            headerName: "modelisationStatus",
            field: "modelisationStatus",
            width: 180,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.modelisationStatusTitle}</Typography>)
            },
            renderCell: (params) => {
                return (<ProcessStatusChip processStatus={params.value} />);
            },
        });

        // Colonne date Semis
        newColumns.push({
            headerName: "dateSemis",
            field: "dateSemis",
            minWidth: 180,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.libelecolumnsemis}</Typography>)
            },
        });

        // Colonne culture
        newColumns.push({
            headerName: "culture",
            field: "culture",
            minWidth: 180,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.libelecolumnculture}</Typography>)
            }
        });

        // Colonne exploitation
        newColumns.push({
            headerName: "exploitation",
            field: "exploitation",
            minWidth: 180,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.libelecolumnexploitation}</Typography>)
            }
        });
        
        // Colonne Surface
        newColumns.push({
            headerName: "surface",
            field: "surface",
            width: 180,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.libelecolumnsurface}</Typography>)
            },
        });
        return newColumns;
    }

    /**
     * Méthode permettant de récupérer les données à afficher dans chaque ligne
     */
    initialiseTableGridDatas() {
        const {parcelDico, modelisationDico, thumbnailParcelDico, parcelIdsSelected } = this.props;
        let newDatas = [];

        for (let itemPropName in modelisationDico) {
            const modelisation = modelisationDico[itemPropName];
            if (!modelisation) continue;
            if (!parcelIdsSelected.includes(modelisation.parcelId)) continue;

            const parcel = parcelDico[modelisation.parcelId];
            if (!parcel) continue;   

            let sowingDate = null;

            if (modelisation.sowingDate) {
                if (modelisation.sowingDate instanceof (Date)) {
                    sowingDate = DateHelper.formati18n(modelisation.sowingDate, 'P');
                }
                else if (DateHelper.getDateFromString(modelisation.sowingDate) instanceof Date) {
                    sowingDate = DateHelper.formati18n(new Date(modelisation.sowingDate), 'P');
                }
            }

            newDatas.push({
                id: parcel.id,
                parcel: parcel,
                name: parcel.name,
                dateSemis: sowingDate,
                culture: lodashGet(parcel, "details.culture", null),
                exploitation: lodashGet(parcel, "details.exploitation", null),
                surface: lodashGet(parcel, "details.surface", null),
                modelisationStatus: modelisation.modelisationStatus,
                thumbnailInfos: ParcelsHelper.selectParcelFromDicoById(thumbnailParcelDico, parcel.id),
            });

        }
        return newDatas;
    }

    //Toolbar customisé du tableau comprenant :
    // - le bouton pour recommencer le scénario d'ajout de modélisation
    CustomToolbar = (props) => {

        return (
            <Grid container spacing={2} sx={{ mt: 1 }}>
                {/* zone du bouton pour lancer de nouvelle modélisation */}
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Grid container spacing={1}>
                        {/* Zone du bouton pour lancer de nouvelle modélisation */}
                        <Grid item xs={12} sm={12} md={12}>
                            <Box style={{ textAlign: 'end' }} >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.props.nextStep()}    //() => this.onStartManagement(parcelsSelected)
                                >
                                    {StringTranslate.modelisationStartNewModelisation}
                                </Button>
                            </Box>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
    
    render() { 
        const { cultureSelected, rowsPerPageForTableModelisations, updateNbRowsPerPageTableModelisations } = this.props;
        return ( 
            <>

                <Typography fontWeight="bold" m={2}>{StringTranslate.cropName} {cultureSelected}</Typography>

                <Divider mb={3} />

                <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Grid style={{ width: '100%' }} item xs={12}>
                                {/* partie tableau */}
                                <CustomDataGrid
                                    disableSelectionOnClick={true}
                                    tableName={TableType.modelisations}
                                    pageSize={rowsPerPageForTableModelisations}
                                    Toolbar={this.CustomToolbar}
                                    updateNbRowsPerPageTable={updateNbRowsPerPageTableModelisations}
                                    rows={this.initialiseTableGridDatas()}
                                    columns={this.columns}
                                />
                            </Grid>
                        </TableContainer>
                    </Grid>
                </Grid>

            </>
         );
    }
}

const mapStateToProps = state => ({
    //Infos provenant du reducer 'parcels':
    parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
    thumbnailParcelDico: lodashGet(state, 'parcelsData.thumbnailParcelDico', {}),

    //Infos provenant du reducer 'settings':
    rowsPerPageForTableModelisations: lodashGet(state, 'settingsData.settings.rowsPerPageForTableModelisations', 20),
    language: lodashGet(state, 'settingsData.settings.language', StringTranslate.getLanguage()),

    //Infos provenant du reducer 'modelisation':
    cultureSelected: lodashGet(state, 'modelisationData.cultureSelected.name', ""),
    modelisationDico: lodashGet(state, 'modelisationData.modelisationDico', {}),
    parcelIdsSelected: lodashGet(state, 'modelisationData.parcelIdsSelected', []),
    modelisationProcessing: lodashGet(state, 'modelisationData.modelisationProcessing', []),
});

const mapDispatchToProps = dispatch => ({
    updateNbRowsPerPageTableModelisations: (rowsPerPage) => dispatch(UpdateNbRowsPerPageTable(rowsPerPage, TableType.modelisations)),
    nextStep: () => dispatch(ActionGoToStepOfModelisation(ModelisationSteps.CROP_CHOICE)),
    goToMap: () => dispatch(ActionGoToMap()),
});

export default connect (mapStateToProps, mapDispatchToProps)(ModelisationResultPage);