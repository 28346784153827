import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


function FertilizationIcon(props: SvgIconProps) {
    return ( 
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <svg width="32" height="32" strokeWidth="1.8" viewBox="0 0 32 32" stroke="current" fill="none"  preserveAspectRatio="xMidYMid meet">
                <line stroke="current" x1="26" y1="9" x2="22" y2="9"/>
                <line stroke="current" x1="22" y1="7" x2="25" y2="4"/>
                <line stroke="current" x1="20" y1="3" x2="20" y2="7"/>
                <polyline stroke="current" fill="none" points="10,23 10,14 14,23 14,14"/>
                <polyline stroke="current" fill="none" points="4,12 20,12 20,25 4,25 4,12 8,12 8,8"/>
                <path stroke="current" fill="none" d="  M 8 8 A 4 4 7 0 1 16 7"/>
            </svg>
        </SvgIcon>
    );
}

export default FertilizationIcon;