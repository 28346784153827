import React, { Component } from "react";
import { BarChart, Bar, Cell, YAxis, LabelList } from 'recharts';
import { Box } from "@mui/material";
import getTheme from "../../themes/index.js";

let theme = getTheme();

///////////////////////////////////////////////////////////////////////////
// Composant permettant d'afficher un histogramme d'une image NDVI 
///////////////////////////////////////////////////////////////////////////
class NdviChart extends Component {
    getCustomCells(allPercentages) {
        if (allPercentages && Array.isArray(allPercentages)) {
            return allPercentages.map((entry, index) => (<Cell key={index} fill={entry.color} />));
        }

        return null;
    }

    /* cycle de vie react */
    render() {
        const { datas, showChart, area } = this.props;
        const customCellsRender = this.getCustomCells(datas);

        // ajout de l'unité (hectare) pour la surface
        const areaWithUnit = area + "Ha";

        return (
            <div className='dnc-display-chart'>
               
                {/* ↓ partie affichage histogramme ↓ */}
                {(showChart && datas && customCellsRender) && (
                    <Box sx={{ height:"150px", fontFamily: '"Roboto",sans-serif' }}>
                        <BarChart
                            width={250}
                            height={150}
                            data={datas}
                            margin={{ top: 20, right: 0, left: 0, bottom: 0 }} // position du diagramme par rapport à son container
                            barCategoryGap={0} // espace entre les colonnes                            
                        >
                            <YAxis width={40} domain={[0,100]} tick={false} label={{ value: areaWithUnit, position: 'top', fontSize: 10, fill: theme.palette.grey[500]}} stroke={theme.palette.grey[500]} />
                            <Bar 
                                dataKey="percentage" // la valeur à lire dans le set de données
                                radius={[0, 0, 0, 0]} // arrondi sur chaque bar
                            >
                                <LabelList dataKey="area" position="top" fontSize={11} fill={theme.palette.grey[500]}/>
                                {customCellsRender}
                            </Bar>
                        </BarChart>
                    </Box>
                )}

            </div>
        );
    }
}

export default NdviChart;