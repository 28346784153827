import { Component } from 'react';
import { Box } from "@mui/material";
import '../../../src/index.css';
import getTheme from '../../themes/index';


let theme = getTheme();

class LogoSpotifarmSansFeuille extends Component {
    state = {  } 
    render() { 
        return (
        <Box sx={{pt:"12px"}}>
            <div 
                className="spotifont"
                style={{
                    background: `linear-gradient(90deg, ${theme.palette.secondary.dark} 37%, ${theme.palette.secondary.main} 100%)`,
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent"
                }}
            >
                Spotifarm
            </div>
        </Box>
        );
    }
}
 
export default LogoSpotifarmSansFeuille;