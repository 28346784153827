import { UserAgentApplication } from "msal";
import { InternalAgentApplication } from '../native/internalAgentApplication.js';
import ConfigAuthent from './configAuthent.js';
import URLHelper from "./urlHelper.js";
import lodashGet from 'lodash/get';
import StoreFunctions from './storeFunctions.js';


/* éléments liés à l'authentification */
export const authoritySigninSignup = `${ConfigAuthent.instance}${ConfigAuthent.tenant}/${ConfigAuthent.signInPolicy}`;
export const authorityOnlySignup = `${ConfigAuthent.instance}${ConfigAuthent.tenant}/${ConfigAuthent.ConstAndDefault.SignInPolicyDefaultNewer}`;
export const authorityResetPwd = `${ConfigAuthent.instance}${ConfigAuthent.tenant}/${ConfigAuthent.ConstAndDefault.SignInPolicyDefaultResetPwd}`;

/* fonction permettant de créer un objet msal */
export const createProviderAuthent = (isOnNativeApp) => {
    const optionsForAuthent = {
        auth: {
            clientId: ConfigAuthent.applicationId,
            authority: authoritySigninSignup,
            validateAuthority: false,
            postLogoutRedirectUri: ConfigAuthent.postLogoutRedirectUri,
            navigateToLoginRequestUrl: false,
            redirectUri: ConfigAuthent.redirectUri,
        },
        cache: {
            cacheLocation: ConfigAuthent.cacheLocation,
            storeAuthStateInCookie: isIE(),
        },
        // system: {
        //     navigateFrameWait: 0,
        //     logger: {
        //         error: console.error,
        //         errorPii: console.error,
        //         info: console.log,
        //         infoPii: console.log,
        //         verbose: console.log,
        //         verbosePii: console.log,
        //         warning: console.warn,
        //         warningPii: console.warn
        //     }
        // }
    };

    /* l'application web se trouve-elle dans l'application native ? ou pas! */
    //a partir du moment où cette prorpiété est définie, c'est que l'appli Web est chargée dans la Webview de l'appli native !
    if (isOnNativeApp === true) {
        //Instanciation de l'objet faisant le même travail que MSal mais qui se base sur les donées de l'appli native et sur la Web API
        // pour gérer l'authentification.
        const internalAuth = new InternalAgentApplication(optionsForAuthent);
        return internalAuth;
    } else { //sinon, on utilise l'objet de MSal !
        //pour éviter le soucis de non-présence du storage souhaité:
        // cf. "The value for the cacheLocation is not supported. Given location: localStorage at t [as constructor]"
        try {
            //on vérifie si il existe :
            let storageSupported = ((typeof window[ConfigAuthent.cacheLocation /* === optionsForAuthent.cache.cacheLocation*/] !== "undefined") && 
                (window[ConfigAuthent.cacheLocation] != null)) ? true : false;
            if (storageSupported !== true) {
                //si ne semble pas ok, on vérifie si l'autre l'est:
                storageSupported = ((typeof window[ConfigAuthent.otherCacheLocation] !== "undefined") && (window[ConfigAuthent.otherCacheLocation] != null)) ? true : false;
                if (storageSupported === true) {
                    //si ok, on change pour celui-ci.
                    optionsForAuthent.cache.cacheLocation = ConfigAuthent.otherCacheLocation;
                }
            }
        }
        catch (err) { 
            //RAS !
        }

        const msalApp = new UserAgentApplication(optionsForAuthent);
        return msalApp;
    }
}

/* extraction de l'email à partir d'un compte authentifié (via MSal Web) */
export const extractEmailFrom = function(account) {
    if (!account) return undefined;

    return lodashGet(account, 'idTokenClaims.emails[0]', undefined); // on récupère la valeur sinon on retourne undefined
}

/* fonction permettant de savoir si les données du localStorage est en lien avec le bon utilisateur récemment authentifié */
export const saveUserEmailInLocalStorage = function(account) {
    if (!account) return;

    const currentEmail = StoreFunctions.getUserEmail();
    const emailToUpdate = extractEmailFrom(account); // on récupère la valeur sinon on aura undefined

    if (emailToUpdate !== currentEmail) { // si pas le même email ou email actuel inconnu alors
        StoreFunctions.clearAllUserDatas(); // suprression des données
        StoreFunctions.saveUserEmail(emailToUpdate); // enregistrement nouvel email
    }
}

/* fonction permettant de lister la liste des messgaes d'erreur nécessitant une interaction */
export const requiresInteraction = errorMessage => {
    if (!errorMessage || !errorMessage.length) {
        return false;
    }

    return (
        errorMessage.indexOf("consent_required") > -1 ||
        errorMessage.indexOf("interaction_required") > -1 ||
        errorMessage.indexOf("login_required") > -1
    );
};

/* fonction permettant de mettre à jour l'autorité au sens msal.js (la même autorité (le même tfp) doit être utilisée entre l'idToken et l'accessToken) */
/* Pourquoi ? l'autorité appliquée peut être différente entre l'obtention de l'idToken et celui de l'accessToken => elle doit être identique */
export const updateAuthorityAfterIdToken = function(userAgentAuthent) { // 'userAgentAuthent' = 'msalApp'
    if (!userAgentAuthent) return;

    let account = userAgentAuthent ? userAgentAuthent.getAccount() : null; // a-t-on un compte client ? 
    if (account) {
        try {
            if (account.idTokenClaims.tfp !== userAgentAuthent.authority) { // si on a un compte 
                userAgentAuthent.authority = `${ConfigAuthent.instance}${ConfigAuthent.tenant}/${account.idTokenClaims.tfp}`;
                userAgentAuthent.setAuthorityCache(`${ConfigAuthent.instance}${ConfigAuthent.tenant}/${account.idTokenClaims.tfp}`);
            }
        } catch (error) {}
    }
}

/* fonction permettant de mettre à jour l'autorité au sens msal.js (la même autorité (le même tfp) */
export const updateAuthority = function(userAgentAuthent, type) { // 'userAgentAuthent' = 'msalApp'
    if ((!userAgentAuthent) || (!type)) return;

    if (type === 'resetPwd') {
        userAgentAuthent.authority = authorityResetPwd;
        userAgentAuthent.setAuthorityCache(authorityResetPwd);
    } else if (type === 'signup') {
        userAgentAuthent.authority = authorityOnlySignup;
        userAgentAuthent.setAuthorityCache(authorityOnlySignup);
    }
}

/* fonction permettant de déterminer si, en l'état actuel, l'autorité définie vise la ré-initialisation du mot de passe */
export const isAuthorityForResetPwd = function(userAgentAuthent) { // 'userAgentAuthent' = 'msalApp' on internal...
    if (!userAgentAuthent) return false;

    if (userAgentAuthent.authority === authorityResetPwd) {
        return true;
    } else {
        return false;
    }
}

/* fonction permettant de déterminer si, en l'état actuel, l'autorité définie vise l'inscription de compte */
export const isAuthorityForSignup = function(userAgentAuthent) { // 'userAgentAuthent' = 'msalApp' on internal...
    if (!userAgentAuthent) return false;

    if (userAgentAuthent.authority === authorityOnlySignup) {
        return true;
    } else {
        return false;
    }
}

/* fonction permettant de récupérer l'email de l'utilisateur depuis son compte (au sens msal.js) ou depuis le localStorage */
export const getLoginHint = function(userAgentAuthent) { // 'userAgentAuthent' = 'msalApp'
    if (!userAgentAuthent) return '';

    let account = userAgentAuthent ? userAgentAuthent.getAccount() : null; // a-t-on un compte client ? 
    let loginHint = '';
    if (account) { // si on a compte 
        try {
            const email = extractEmailFrom(account);
            const idp = lodashGet(account, 'idTokenClaims.idp', undefined); 
            if ((idp !== 'facebook.com') && (idp !== 'google.com') && (idp !== 'apple.signin.com')) 
                loginHint = email;
            
        } catch (error) {}
    }

    return loginHint;
}


/* fonction permettant de récupérer l'email de l'utilisateur depuis son compte (au sens msal.js) */
export const getDomainHint = function(userAgentAuthent) { // 'userAgentAuthent' = 'msalApp'
    if (!userAgentAuthent) return '';

    let account = userAgentAuthent ? userAgentAuthent.getAccount() : null; // a-t-on un compte client ? 
    let domainHint = '';
    if (account) {
        try {
            let str = account.idTokenClaims.idp;
            if (str === 'facebook.com' || str === 'google.com' || str === 'apple.signin.com') 
                domainHint = str;
        } catch (error) {}
    }

    return domainHint;
}

/* fonction permettant d'obtenir les données de requête pour obtention du token (scopes, autorité, loginHint, ... ) */
/* paramètres supplémentaires : consulter https://github.com/AzureAD/microsoft-authentication-library-for-js/ ou https://docs.microsoft.com/en-us/azure/active-directory-b2c/ */
export const getTokenRequest = function(userAgentAuthent) { // 'userAgentAuthent' = 'msalApp'
    let tokenRequest = { scopes: ConfigAuthent.scopes }; 

    let authority = provideAuthority(userAgentAuthent);
    tokenRequest['authority'] = authority;
    
    let loginHint = getLoginHint(userAgentAuthent); // récupération de l'email
    if (loginHint && loginHint !== '')
        tokenRequest['loginHint'] = loginHint;
    
    const extraQueryParameters = {}; // https://docs.microsoft.com/bs-latn-ba/azure/active-directory/develop/msal-js-sso
    
    let domainHint = getDomainHint(userAgentAuthent); // récupération du domaine (facebook ou google)
    if (domainHint && domainHint !== '')
        extraQueryParameters['domain_hint'] = domainHint;  

    tokenRequest['extraQueryParameters'] = extraQueryParameters;

    return tokenRequest;
}

/* fonction permettant de savoir si le compte est lié à un réseau social (connexion via un réseau social) */
export const isSocialAccount = function(account) {
    if (!account) return false;
    try {
        let str = account.idTokenClaims.idp;
        return (str === 'facebook.com' || str === 'google.com' || str === 'apple.signin.com') ? true : false;
    } catch (error) {}
    return false;
}

/* méthode permettant de savoir si on se trouve sur IE */
export const isIE = function() {
    let ua = '';
    try {
        ua = lodashGet(window, 'navigator.userAgent', '');
    }
    catch(errGetNav) {} //cas de navigateur qui bloque l'accès si certains paramètres du navigateur client sont actifs (Ex: blocage des cookies).

    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;

    return msie || msie11;
};

/* méthode permettant d'obtenir une autorité lors de la création d'un objet msal (va lire les paramètres URL providerType) */
export const provideAuthority = function(userAgentAuthent) {
    const providerType = URLHelper.getURLParameter('providertype', true); // recherche du paramètre URL (providerType)
    switch( providerType ) {
        case ConfigAuthent.ConstAndDefault.Providers.ADAzure.toString():
        {
            return authoritySigninSignup;
        }
        case ConfigAuthent.ConstAndDefault.Providers.ADAzureNewer.toString():
        {
            return authorityOnlySignup;
        }
        default:
        {
            return userAgentAuthent.authority;
        }
    }
}