//import { SatimageDatasParcel } from '../models/satimageDatasParcel.js';


/**
 * Helper pour la manipulation du reducer présent ici
 */
export const ReducerSatimageHelper = {
    /**
     *  fonction permettant de ...
     */
    selectParcelFromId: function(satimagesByParcelDico, parcelId) {
        if ((!satimagesByParcelDico) || 
            (!parcelId) || (!Number.isInteger(parcelId)) || (parcelId <= 0)) {
            return undefined;
        }

        //Vérifie si la propriété du même nom que l'identifiant de la parcelle existe:
        const parcelIdStr = parcelId.toString();
        try {
            if ("undefined" !== typeof(satimagesByParcelDico[parcelIdStr])) { // elle existe !
                return satimagesByParcelDico[parcelIdStr];
            } 
            //else { // elle n'existe pas, on va retourner 'undefined' !
        } catch(e) {
            //RAS
        }

        return undefined;
    },

    /**
     *  fonction permettant de ...
     */
    addParcel: function(satimagesByParcelDico, newParcelData) {
        if ((!satimagesByParcelDico) || (!newParcelData) || 
            (!newParcelData.parcelId) || (!Number.isInteger(newParcelData.parcelId)) || (newParcelData.parcelId <= 0)) {
            return false;
        }

        //Affecte la propriété du même nom que l'identifiant de la parcelle (création de cette propriété si inexistante):
        const parcelIdStr = newParcelData.parcelId.toString();
        try {
            //Gestion de doublon:
            if ("undefined" !== typeof(satimagesByParcelDico[parcelIdStr])) { // elle existe !
                return false;
            } else {
                satimagesByParcelDico[parcelIdStr] = newParcelData; 
            }

            return true;
        } catch(e) {
            //RAS
        }

        return false;
    },

    /**
     *  fonction permettant de ...
     */
    countParcelOnState: function(satimagesByParcelDico, parcelStateTosearch) {
        if ((!satimagesByParcelDico) || (!parcelStateTosearch)) {
            return 0;
        }

        if (!satimagesByParcelDico) {
            satimagesByParcelDico = {};
            return 0;
        }

        let counterSearch = 0;
        try {
            for (var itemPropName in satimagesByParcelDico) {
                if (itemPropName) {
                    const itemSatimageDatasParcel = satimagesByParcelDico[itemPropName];
                    if (itemSatimageDatasParcel) {
                        //Vérifie si l'entité POCO associée à la liste des date/image a le status recherché:
                        if (itemSatimageDatasParcel.stateAsk === parcelStateTosearch) {
                            counterSearch += 1;
                        }
                    }
                    //else //la valeur de la propriété n'est pas du type attendue.
                }
                //else //pas une propriété dont le nom identifie un identifiant de parcelle.
            }
        } catch(e) {
            //RAS
        }

        return counterSearch;
    },

    /**
     *  fonction permettant de récupérer la liste des entités satImage sont valides => dont le taux d'ennuagement est inférieur ou égal au maxCC paramétré par l'utilisateur - Conversion Dico => List
     */
    getSatImageWithLowerOrEqualMaxccListFromParcelId : function(satimagesByParcelDico, parcelId) {
        if ((satimagesByParcelDico) && (parcelId > 0) && (satimagesByParcelDico[parcelId]) && (satimagesByParcelDico[parcelId].satimageDico))
            return Object.values(satimagesByParcelDico[parcelId].satimageDico);
        else
            return [];
    },

    /**
     *  fonction permettant de récupérer la liste des entités satImage dont le taux d'ennuagement est supérieur au maxCC paramétré par l'utilisateur - Conversion Dico => List
     */
    getSatImageWithHigherMaxccListFromParcelId : function(satimagesByParcelDico, parcelId) {
        if ((satimagesByParcelDico) && (parcelId > 0) && (satimagesByParcelDico[parcelId]) && (satimagesByParcelDico[parcelId].satImageWithHigherMaxccDico))
            return Object.values(satimagesByParcelDico[parcelId].satImageWithHigherMaxccDico);
        else
            return [];
    },
}
