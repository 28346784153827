import stringLanguage_frFR from './stringLanguage_fr-FR.js';
import stringLanguage_esES from './stringLanguage_es-ES.js';
import stringLanguage_enGB from './stringLanguage_en-GB.js';
//import stringLanguage_enUS from './stringLanguage_en-US.js';
import stringLanguage_frBE from './stringLanguage_fr-BE.js';
import LocalizedStrings from 'react-localization';

export const StringTranslate = new LocalizedStrings({
    "fr-FR" : stringLanguage_frFR,
    "en-GB" : stringLanguage_enGB,
    //"en-US" : stringLanguage_enUS,
    "es-ES" : stringLanguage_esES,
    "fr-BE" : stringLanguage_frBE,
});

//traduction qui n'est pas à fournir à l'équipe d'internationnalisation car données qui ne faut surtout pas modifier !
// Il s'agit des noms de colonnes visées pour l'import de SHP, suivant le pays
export const FixedStringTranslate = new LocalizedStrings({
    "fr-FR" : {
        "listNameCampagne": {
            "geofolia": "CAMPAGNE",
            "telepac": "CAMPAGNE",
            "mesparcelles": "Année de r"
        },
        "listNameCulture": {
            "geofolia": "CP_CULTU",
            "telepac": "TYPE",
            "mesparcelles": "Culture"
        },
        "listNameVariete": {
            "geofolia": "VARIETE",
            "telepac": "CODE_VAR",
            "mesparcelles": "Code varié"
        },
        "listNameSemi": {
            "geofolia": "DATE_SEMIS",
            "telepac": "",
            "mesparcelles": "Date sem~1"
        },
        "dateSuffixeSemi": {
            "geofolia": "",
            "telepac": "",
            "mesparcelles": " +00"
        },
        "dateFormatSemi": {
            "geofolia": "yyyy-MM-dd'T'HH:mm:ss.SSSX",
            "telepac": "",
            "mesparcelles": "dd/MM/yyyy X"
        },
        "listNameCulturePrecedente": {
            "geofolia": "CULT_PREC",
            "telepac": "",
            "mesparcelles": ""
        },
        "listNameSurface": {
            "geofolia": "SURFACE",
            "telepac": "SURF",
            "mesparcelles": "Surface (h"
        },
        "listNameExploitation": {
            "geofolia": "RAIS_SOCIA",
            "telepac": "PACAGE",
            "mesparcelles": "Exploitati"
        }
    },

    "en-GB" : {
        "listNameCampagne": {
            "geofolia": "CROPYEAR",
            "telepac": "",
            "mesparcelles": "Year of"
        },
        "listNameCulture": {
            "geofolia": "CROP_ZC",
            "telepac": "CROP",
            "mesparcelles": "Crop "
        },
        "listNameVariete": {
            "geofolia": "VARIETY",
            "telepac": "CODE_VAR",
            "mesparcelles": "Code varie"
        },
        "listNameSemi": {
            "geofolia": "SEED_DATE",
            "telepac": "",
            "mesparcelles": "Seed date"
        },
        "dateSuffixeSemi": {
            "geofolia": "",
            "telepac": "",
            "mesparcelles": " +00"
        },
        "dateFormatSemi": {
            "geofolia": "yyyy-MM-dd'T'HH:mm:ss.SSSX",
            "telepac": "",
            "mesparcelles": "dd/MM/yyyy X"
        },
        "listNameCulturePrecedente": {
            "geofolia": "PREV_CROP",
            "telepac": "",
            "mesparcelles": ""
        },
        "listNameSurface": {
            "geofolia": "AREA",
            "telepac": "AREA_TOT",
            "mesparcelles": "Area "
        },
        "listNameExploitation": {
            "geofolia": "BUSS_NAME",
            "telepac": "Buss",
            "mesparcelles": "Business "
        }
    },

    /*"en-US" : {
    },*/

    "es-ES" : {
        "listNameCampagne": {
            "geofolia": "CAMPAÑA",
            "telepac": "",
            "mesparcelles": "año", 
        },
        "listNameCulture": {
            "geofolia": "CP_CULT",
            "telepac": "TYPE",
            "mesparcelles": "Cultura"
        },
        "listNameVariete": {
            "geofolia": "VARIEDAD",
            "telepac": "CODE_VAR",
            "mesparcelles": "Variedad"
        },
        "listNameSemi": {
            "geofolia": "FEC_SIEMB",
            "telepac": "",
            "mesparcelles": "Fecha siemb"
        },
        "dateSuffixeSemi": {
            "geofolia": "",
            "telepac": "",
            "mesparcelles": " +00"
        },
        "dateFormatSemi": {
            "geofolia": "yyyy-MM-dd'T'HH:mm:ss.SSSX",
            "telepac": "",
            "mesparcelles": "dd/MM/yyyy X"
        },
        "listNameCulturePrecedente": {
            "geofolia": "CULT_ANT",
            "telepac": "",
            "mesparcelles": "Ant cult"
        },
        "listNameSurface": {
            "geofolia": "SURFACE",
            "telepac": "SURF",
            "mesparcelles": "Surface (h"
        },
        "listNameExploitation": {
            "geofolia": "RAZ_SOCIAL",
            "telepac": "CAMPAGNE",
            "mesparcelles": "Exploitati"
        }
    },

    "fr-BE" : {
        "listNameCampagne": {
            "geofolia": "CAMPAGNE",
            "telepac": "",
            "mesparcelles": "Année de r"
        },
        "listNameCulture": {
            "geofolia": "CP_CULTU",
            "telepac": "TYPE",
            "mesparcelles": "Culture"
        },
        "listNameVariete": {
            "geofolia": "VARIETE",
            "telepac": "CODE_VAR",
            "mesparcelles": "Code varié"
        },
        "listNameSemi": {
            "geofolia": "DATE_SEMIS",
            "telepac": "",
            "mesparcelles": "Date sem~1"
        },
        "dateSuffixeSemi": {
            "geofolia": "",
            "telepac": "",
            "mesparcelles": " +00"
        },
        "dateFormatSemi": {
            "geofolia": "yyyy-MM-dd'T'HH:mm:ss.SSSX",
            "telepac": "",
            "mesparcelles": "dd/MM/yyyy X"
        },
        "listNameCulturePrecedente": {
            "geofolia": "CULT_PREC",
            "telepac": "",
            "mesparcelles": ""
        },
        "listNameSurface": {
            "geofolia": "SURFACE",
            "telepac": "SURF",
            "mesparcelles": "Surface (h"
        },
        "listNameExploitation": {
            "geofolia": "RAIS_SOCIA",
            "telepac": "CAMPAGNE",
            "mesparcelles": "Exploitati"
        }
    },
});

export default StringTranslate;
