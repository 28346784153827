import React from "react";
import { connect } from 'react-redux';

/* MUI components */
import {
    Step, Stepper, StepLabel, Grid, List, ListItemText
} from "@mui/material";
import StringTranslate from "../../assets/i18n/stringLanguage";

/* React components */
import AbonnementFormule from "./abonnementFormule";
import AbonnementAdresseFact from "./abonnementAdresseFact";
import AbonnementPayment from "./abonnementPayment";
import AbonnementSkeleton from "./abonnementSkeleton";
import MainCard from "../subLayouts/mainCard";
import { SuccessSubscription, FailSubscription } from '../stripe/stripeSuccessFailView';
import { validateDatasForInvoice } from './userInfo';
import ADVPlanCard from "../profil/aDVplanCard";
import ADVLink from "../profil/aDVLink";

/* Redux */
import {
    ActionGetPlansSubscriptions, ActionErrorSubscriptionProcess,
    ActionResetSubscriptionProcess, ActionGetClientDatasAfterSubscriptionAsk,
    ActionSetIsNewSubscription, ActionGetClientBilling, ActionSetIsFormValid
} from '../../redux/actions/clientUser.js';
import { ActionGoToMap } from "../../redux/actions/contextApp";

/* css */
import '../../assets/css/profil.css';

/* Utils */
import { StripeProvider } from 'react-stripe-elements';
import ConfigAuthent from '../../utils/configAuthent';

/* Custom plans pour clients adv */
const planDatasMap = {
    id: 1,
    title: 'Tour de Plaine 🛰️',
    description: 'Abonnement à SPOTIFARM - Niveau TOUR DE PLAINE',
    subDescription:
        <List dense={true}>
            <ListItemText primary="✔️ Application web et mobile iOS/Android" />
            <ListItemText primary="✔️ Parcelles illimitées" />
            <ListItemText primary="✔️ Import Telepac" />
            <ListItemText primary="✔️ Import Geofolia/Mesp@rcelles" />
            <ListItemText primary="✔️ Indice de végétation" />
            <ListItemText primary="✔️ Historique illimité" />
            <ListItemText primary="✔️ Géolocalisation" />
            <ListItemText primary="✔️ Saisie des observations" />
            <ListItemText primary="✔️ Découpe de zones" />
            <ListItemText primary="❌ Cartes de modulation" />
            <ListItemText primary="❌ Gestion des doses d'intrants" />
            <ListItemText primary="❌ Stratégies de modulation" />
            <ListItemText primary="❌ Export vers les consoles" />
            <ListItemText primary="✔️ Filtres par nom, taille, culture …" />
            <ListItemText primary="✔️ Support tchat et email" />
        </List>,
};

const planDatasModulation = {
    id: 2,
    title: 'Modulation 🚜',
    description: 'Abonnement à SPOTIFARM - Niveau MODULATION',
    subDescription:
        <List dense={true}>
            <ListItemText primary="✔️ Application web et mobile iOS/Android" />
            <ListItemText primary="✔️ Parcelles illimitées" />
            <ListItemText primary="✔️ Import Telepac" />
            <ListItemText primary="✔️ Import Geofolia/Mesp@rcelles" />
            <ListItemText primary="✔️ Indice de végétation" />
            <ListItemText primary="✔️ Historique illimité" />
            <ListItemText primary="✔️ Géolocalisation" />
            <ListItemText primary="✔️ Saisie des observations" />
            <ListItemText primary="✔️ Découpe de zones" />
            <ListItemText primary="✔️ Cartes de modulation" />
            <ListItemText primary="✔️ Gestion des doses d'intrants" />
            <ListItemText primary="✔️ Stratégies de modulation" />
            <ListItemText primary="✔️ Export vers les consoles" />
            <ListItemText primary="✔️ Filtres par nom, taille, culture …" />
            <ListItemText primary="✔️ Support tchat et email" />
        </List>
};

const planDatasOption = {
    id: 3,
    title: 'Option Fumure 🛡️',
    subDescription:
        <List dense={true}>
            <ListItemText
                primary="✔️ Votre dose d'azote ajustée par satellite sur :"
                secondary="le blé tendre, l'orge et le colza" />
        </List>
};

class Abonnements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            etapeCourante: 0,
            etapeAchevees: {},
            nbEtapes: 4,
            stripe: null,
            completeAddress: props.completeAddress,
        }

        this.handleChange = this.handleChange.bind(this);
        this.goToStep = this.goToStep.bind(this);
        this.getEtapes = this.getEtapes.bind(this);
    }

    getEtapes() {
        let etapes = [
            `${StringTranslate.selectionabo}`,
            `${StringTranslate.infofac}`,
            `${StringTranslate.infobanc}`
        ];
        return etapes;
    }

    handleChange = param => (event) => {
        const {
            etapeCourante
        } = this.state;

        if (param === 'next') {
            if (window.dataLayer && this.state.activeStep > 0) // google tag manager => seulement sur les étapes 2 et 3 
                window.dataLayer.push({ 'event': 'suiviBouton', 'action': `validation étape ${this.state.etapeCourante + 1}` });
            if (window.fbq && this.state.activeStep === 1) // facebook pixel => seulement sur le passage de l'étape 2 à 3 (infos profil remplies)
                window.fbq('track', 'AddPaymentInfo', { value: 0.0, currency: 'EUR' });

            this.setState({
                etapeCourante: etapeCourante + 1,
            })
        } else if (param === 'back') {
            if (window.dataLayer) // google tag manager
                window.dataLayer.push({ 'event': 'suiviBouton', 'action': `retour étape ${this.state.etapeCourante}` });
            this.setState({
                etapeCourante: etapeCourante - 1,
            })
        }

    }

    /* fonction cycle de vie react.js */
    componentDidMount() {
        /* Enregistrement dans Redux du nom de la page actuelle */

        if (window.Stripe) {
            this.setState({ stripe: window.Stripe(ConfigAuthent.stripeApiKey) });
        } else {
            document.querySelector('#stripe-js').addEventListener('load', () => {
                // Create Stripe instance once Stripe.js loads
                this.setState({ stripe: window.Stripe(ConfigAuthent.stripeApiKey) });
            });
        }

        // ↓ Récupération des plans munis de leur souscription (si cela n'a pas déjà été demandé) ↓
        if (!this.props.planSubscriptionDico || (Object.values(this.props.planSubscriptionDico).length <= 0))
            this.props.getPlansSubscriptions();
    }

    /* fonction permettant d'aller directement à une étape voulue */
    goToStep = stepIndexAsk => (event) => {
        const { nbEtapes, etapeCourante } = this.state;
        const { subscriptionProcess } = this.props;

        // Premier contrôle : on autorise le changement que si l'étape demandée est dans la liste (l'interval connu)...
        if ((stepIndexAsk < nbEtapes) && (stepIndexAsk >= 0)) {
            //this.setState({ etapeCourante : stepIndexAsk }); //il y a d'autres contrôles à faire !
            switch (stepIndexAsk) {
                case 0: { // 1ère étape: CHOIX DU PLAN
                    if (etapeCourante !== 0)
                        this.setState({ etapeCourante: 0 });

                    return;
                }

                case 1: { // 2eme étape: FORMULAIRE D'INFORMATIONS DE FACTURATION CLIENT
                    if (etapeCourante <= 0) { //on ne permet pas de passer de l'étape 'choix du plan' à celle de la 'saisie des infos client' (car seul le bouton sur un plan doit le permettre)
                        /* //@@A voir avec CDu si on remet (ou pas) en place ce message !
                        // ↓ on affiche juste un petit message invitant l'utilisateur à faire son choix parmis les plans actifs ! ↓
                        if (showSelectPlanMessage !== true) {
                            this.setState({ showSelectPlanMessage: true });
                            setTimeout(() => (this) ? this.setState({ showSelectPlanMessage: false }) : null, 5000) // on supprime le message au bout de 5s
                        }
                        */
                    }
                    else if (etapeCourante > 1) {
                        this.setState({ etapeCourante: 1 });
                    }

                    return;
                }

                case 2: { // 3eme étape: CARTE BANCAIRE POUR PAIEMENT
                    //Si le choix du plan est fait (en principe: oui car on a bloqué le passage à l'étape N°2 par le choix du plan)
                    //Et si les informations de facturation étaient déjà renseignées, alors on autorise l'affichage de cette étape.
                    // => d'ailleurs, il faudrait ne faire que l'affichage de la CB actuelle (si renseignée), sinon la saisie de la première CB.    
                    if (etapeCourante <= 0) { //on ne permet pas de passer de l'étape 'choix du plan' à celle de la 'saisie de la CB' (car seul le bouton sur un plan doit le permettre)
                        /* //@@A voir avec CDu si on remet (ou pas) en place ce message !
                        // ↓ on affiche juste un petit message invitant l'utilisateur à faire son choix parmis les plans actifs ! ↓
                        if (showSelectPlanMessage !== true) {
                            this.setState({ showSelectPlanMessage: true });
                            setTimeout(() => this.setState({ showSelectPlanMessage: false }), 5000) // on supprime le message au bout de 5s
                        }
                        */
                    }
                    else if (etapeCourante === 1) {
                        //on PEUT autoriser à passer à la troisième étape sans clic sur le bouton de validation, 
                        // SSI tous les champs sont remplis et valides.... Pour le vérifier, on lance une vérification.
                        if (subscriptionProcess.isFormValid !== true) {
                            const { naming, address, phone, codeCountry } = this.props;
                            const stateToUpdate = validateDatasForInvoice({
                                firstName: (naming && naming.firstName) ? naming.firstName : '',
                                lastName: (naming && naming.lastName) ? naming.lastName : '',
                                phone: phone,
                                label: (address && address.label) ? address.label : '',
                                postalCode: (address && address.postalCode) ? address.postalCode : '',
                                cityName: (address && address.cityName) ? address.cityName : '',
                                country: (address && address.country) ? address.country : 'France',
                                codeCountry: codeCountry,
                            });

                            if (!stateToUpdate.errorMessage) { //on autorise à passer à la troisième étape si les champs de facturation sont ok !
                                //subscriptionProcess.isFormValid = true; //il faut plutôt actualiser Redux !
                                this.props.setIsFormValid(true);

                                this.setState({ etapeCourante: 2 });
                            }
                        } else { //on autorise à passer à la troisième étape si les champs de facturation étaient déjà ok !
                            this.setState({ etapeCourante: 2 });
                        }
                    }

                    return; //on ne change rien sinon !
                }

                default:
                    return; //si cas non-géré, on ne change rien !
            }
        } else if (etapeCourante !== 0) { //on autorise à retourner sur la première étape...
            this.setState({ etapeCourante: stepIndexAsk });
        }
    }

    renderCurrentSubscriptionStep = () => {
        const { etapeCourante } = this.state;

        switch (etapeCourante) {
            case 0:   // choix de la formule d'abonnement
                return (
                    <>
                        <AbonnementFormule
                            handleNextStep={this.handleChange('next')}
                        />
                    </>
                )
            case 1:   // confirmation de l'adresse de facturation
                return (
                    <>
                        <AbonnementAdresseFact
                            handleNextStep={this.handleChange('next')}
                            handlePreviousStep={this.handleChange('back')}
                        />

                    </>
                )
            case 2:   // confirmation des infos de payment et validation
                return (
                    <>
                        <AbonnementPayment
                            handleNextStep={this.handleChange('next')}
                            handlePreviousStep={this.handleChange('back')}
                        />
                    </>
                )
            case 3: // 4eme étape: affichage écran de succès ou d'erreur
                if (this.props.subscriptionProcess && this.props.subscriptionProcess.error)
                    return <MainCard><FailSubscription {...this.props} handleBackToFirstStep={this.goToStep(0)} /></MainCard>;
                else
                    return <MainCard><SuccessSubscription {...this.props} /></MainCard>;
            default:
                return (
                    <>

                    </>
                )
        }

    }

    renderAbonnementCard = () => {
        const { etapeCourante } = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stepper activeStep={etapeCourante} alternativeLabel sx={{ mb: 4 }}>
                        {this.getEtapes().map((label, index) => (
                            <Step key={label} onClick={this.goToStep(index)} completed={index < etapeCourante}>
                                <StepLabel sx={{ cursor: "pointer" }}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>

                <Grid item xs={12}>
                    {/* {(etapeCourante === 2) ? 
                        <Button variant="outlined" color="primary" onClick={this.handleChange('back')}>{`${StringTranslate.revenir}`}</Button> 
                    : 
                        <></>
                    } */}

                    {/* ↓↓ Partie - CHARGEMENT EN COURS (pas de planSouscription ou bien pas encore d'informations client) - (contenu: SPINNER + fond grisé) ↓↓ */}
                    {(!(this.props.clientId > 0) || (this.props.gettingPlansSubscriptions && (!this.props.isNewSubscription) && (etapeCourante === 0))) && (
                        <AbonnementSkeleton />
                    )}
                    {this.renderCurrentSubscriptionStep()}
                </Grid>


            </Grid>
        );
    }

    renderAbonnementCardAdv = () => {
        const { enumTypoClient } = this.props;

        return (<>
        {/* Plans ADV à afficher en fonction de la typo client */}
            <Grid container spacing={3}>
                <ADVPlanCard planDatas={planDatasMap} subscribedResume={(enumTypoClient === 2) || (enumTypoClient === 5)}></ADVPlanCard>
                <ADVPlanCard planDatas={planDatasModulation} subscribedResume={(enumTypoClient === 4) || (enumTypoClient === 6)}></ADVPlanCard>
                <ADVPlanCard planDatas={planDatasOption} subscribedResume={(enumTypoClient === 5) || (enumTypoClient === 6)}></ADVPlanCard>
                <Grid item xs={12}>
                    <ADVLink />
                </Grid>
            </Grid>

        
        </>
        );
    }

    render() {
        /* Si le client est ADV, on affiche ce qu'il a droit en fonction de sa typo client, sinon,
        on affiche les abonnements venant de stripe */
        if (this.props.isAdvClient !== true) {
            return (
                <StripeProvider stripe={this.state.stripe}>
                    {this.renderAbonnementCard()}
                </StripeProvider>
            );
        }
        else {
            return (this.renderAbonnementCardAdv());
        }
    }
}

const mapStateToProps = state => ({
    enumTypoClient: (state && state.clientUserData && state.clientUserData.clientDatas) ? state.clientUserData.clientDatas.enumTypoClient : -1,

    // Pour StripeCreditCardView.jsx
    creditCard: state.clientUserData.clientDatas.creditCard,
    subscriptionProcess: state.clientUserData.subscriptionProcess,  // aussi pour stripeSuccessFailView.jsx
    clientId: state.clientUserData.clientDatas.id,
    isAdvClient: state.clientUserData.clientDatas.isADV,
    language: state.settingsData.settings.language,
    naming: state.clientUserData.clientDatas.naming,
    phone: state.clientUserData.clientDatas.phone,
    address: state.clientUserData.clientDatas.address,
    clientDatas: state.clientUserData.clientDatas,
    codeCountry: state.settingsData.settings.codeCountry,
    isNewSubscription: state.clientUserData.isNewSubscription,
    gettingPlansSubscriptions: state.clientUserData.gettingPlansSubscriptions,
    completeAddress: state.clientUserData.clientDatas.address,
    waitLoadingClient: (state && state.clientUserData) ? state.clientUserData.isWaitingToSearchClient : false,
    planSubscriptionDico: (state && state.clientUserData) ? state.clientUserData.planSubscriptionDico : {}
})

const mapDispatchToProps = dispatch => ({
    // Pour StripeCreditCardView.jsx
    addErrorToSubscriptionProcess: (error) => dispatch(ActionErrorSubscriptionProcess(error)),

    // Pour stripeSuccessFailView.jsx
    getClientDatasAfterSubscription: () => dispatch(ActionGetClientDatasAfterSubscriptionAsk()),
    resetSubscriptionProcess: () => dispatch(ActionResetSubscriptionProcess()),
    getPlansSubscriptions: () => dispatch(ActionGetPlansSubscriptions()),
    setIsNewSubscription: (bool) => dispatch(ActionSetIsNewSubscription(bool)),
    goToMap: () => dispatch(ActionGoToMap()),
    getClientBilling: (limitInvoices) => dispatch(ActionGetClientBilling(limitInvoices)),

    setIsFormValid: (bool) => dispatch(ActionSetIsFormValid(bool)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Abonnements);
