import React, { useCallback } from "react";

import { Typography, Box, Card, CardContent, CardMedia, CardActions, Grid, Button, DialogContentText } from "@mui/material";

import StepImport from '../../../assets/images/didacticiel/step_import.tsx';
import StepDessin from '../../../assets/images/didacticiel/step_dessin.tsx';
import IconeGeofolia from '../../../assets/images/IconeGeofolia.tsx';
import { StepEnumDidacticielFirstParcel } from '../../../redux/actions/didacticiel.js';
import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';
import getTheme from "../../../themes/index.js";

let theme = getTheme();

// Il est utilisé 3 fois, donc pertinent de factoriser
const themeCard = {
    background: theme.palette.grey[50],
    border: '1px solid',
    borderColor: theme.palette.grey[100],
    height: '278px',
    maxWidth: 245,
    ':hover': {
        border: '1px solid',
        borderColor: theme.palette.primary,
    },
}

/**
 * Composant représentant l'étape de Choix - didacticiel première parcelle
 */
function StepChoix(props) {

    const onclickdessin = useCallback(() => props.goToStep(StepEnumDidacticielFirstParcel.DESSIN), [props]);
    const onclickimport = useCallback(() => props.goToStep(StepEnumDidacticielFirstParcel.IMPORT), [props]);
    const onclickgeofolia = useCallback(() => props.goToStep(StepEnumDidacticielFirstParcel.GEOFOLIA), [props]);

    return (
      <>
        <DialogContentText>{StringTranslate.choixsubtitle}</DialogContentText>
              
        <Box sx={{ textAlign: '-webKit-center', marginTop: '15px' }}>

            <Grid
                container
                spacing={2}
                columns={{ xs: 3, sm: 8, md: 12 }}
                justifyContent="center"
                alignItems="center">
                <Grid item xs={2} sm={3} md={4}>
                    <Card sx={themeCard}>
                        <CardMedia>
                            <StepDessin sx={{ marginTop: '30px', marginBottom: '10px', fontSize: '70px'}}/>
                        </CardMedia>
                        <CardContent variant_pz="cardct-tuto-chx">
                            <Typography variant="subtitle2">
                                {StringTranslate.choixdrawcontent}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <Button variant="contained" onClick={onclickdessin}>{StringTranslate.choixdrawtitle}</Button>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={2} sm={3} md={4}>
                    <Card sx={themeCard}>
                        <CardMedia>
                            <StepImport sx={{ marginTop: '30px', marginBottom: '10px', fontSize: '70px'}}/>
                        </CardMedia>
                        <CardContent variant_pz="cardct-tuto-chx">
                            <Typography variant="subtitle2">
                                {StringTranslate.choiximportcontent}
                            </Typography>
                        </CardContent>
                        <CardActions sx={{ bottom: 1 }}>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <Button variant="contained" onClick={onclickimport}>{StringTranslate.choiximporttitle}</Button>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>

                {(props.languageSetting !== 'en-GB') && (
                    <Grid item xs={2} sm={3} md={4}>
                            <Card sx={themeCard}>
                                <CardMedia>
                                    <IconeGeofolia sx={{ marginTop: '30px', marginBottom: '10px', fontSize: '70px'}}/>
                                </CardMedia>
                                <CardContent variant_pz="cardct-tuto-chx">
                                    <Typography variant="subtitle2">
                                        {StringTranslate.choixgeofoliacontent}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Grid container justifyContent="center">
                                        <Grid item>
                                            <Button variant="contained" onClick={onclickgeofolia}>{StringTranslate.choixgeofoliatitle}</Button>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Card>
                    </Grid>
                )}
            </Grid>

        </Box>
        </>
    )
}

export default StepChoix;