import React from "react";
import { connect } from 'react-redux';
import { get as lodashGet, isNil as lodashIsNil, isEmpty as lodashIsEmpty } from 'lodash';

import { ActionSetCurrentPageName, PageName } from '../../redux/actions/contextApp.js';
import { ActionGetAllModelisations, ActionGetModelisations, ActionInitCultures } from '../../redux/actions/modelisation';

import StartModelisation from "../../components/modelisation/startModelisation";
import ModelisationStepsManagement from "../../components/modelisation/modelisationStepsManagement";
import Modelisations from "../../components/modelisation/modelisations.jsx";
import DataGridSkeleton from "../../components/dataGridSkeleton.jsx";

import { Box } from "@mui/material";


///////////////////////////////////////////////////////////////////////////
// Composant général concernant la modélisation (gestion et affichage).
///////////////////////////////////////////////////////////////////////////
class AbstractModelisation extends React.Component {
    componentDidMount() {
        const { 
            setCurrentPageName, 
            listOfCultures, initCultures, getAllModelisations,
            alreadyAskLoadingModelisations, modelisationProcessing, getModelisationsInitializing
        } = this.props;

        setCurrentPageName(PageName.Modelisation, false);

        //Obtention des cultures de modélisations
        if (listOfCultures.length <= 0) {
            initCultures();
        }

        //Obtention des modélisations
        if (alreadyAskLoadingModelisations === false) {
            getAllModelisations();
        }

        if ((!lodashIsEmpty(modelisationProcessing)) && lodashIsNil(this.intervalId)) {
            this.intervalId = setInterval(() => getModelisationsInitializing(), 5000);
        }
    }

    componentDidUpdate(prevProps) {
        const { modelisationProcessing, getModelisationsInitializing } = this.props;

        // S'il n'y a plus un process d'une modélisation lié à une parcelle sélectionnée qui est en attente ou en cours
        // alors on arrete de demander            
        if (prevProps.modelisationProcessing !== modelisationProcessing) {
            if (lodashIsEmpty(modelisationProcessing)) {
                if (!lodashIsNil(this.intervalId)) {
                    clearInterval(this.intervalId);
                    this.intervalId = null;
                }
            }
            else {
                if (lodashIsNil(this.intervalId)) {
                    this.intervalId = setInterval(() => getModelisationsInitializing(), 5000);
                }
            }
        }
    }

    componentWillUnmount() {
        // Arrete la récupération des modélisations à la mort du composant
        if (!lodashIsNil(this.intervalId)) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }

    /* fonction cycle de vie react */
    render() {
        const { authorizeModelisation, newModelisation, loadingModelisations } = this.props;
        
        return(
            <>
                {
                    // Affiche l'incitation si le client ne dispose pas des droits nécessaire
                    (!authorizeModelisation)?
                        <StartModelisation incitation />
                    :
                    // Affiche un écran de chargement en attendant de récupérer les informations de modélsiation du client
                    (loadingModelisations)?
                    <Box sx={{ width: '100%' }} >
                        <DataGridSkeleton/>
                    </Box>
                    :
                    // Affiche les différentes étapes de création de modélisation
                    (newModelisation)?
                        <ModelisationStepsManagement/>
                    :
                    // Affiche le tableau des modélisations existantes
                        <Modelisations />
                }
            </>
        )
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    //Infos provenant du reducer 'modelisations' :
    alreadyAskLoadingModelisations: lodashGet(state, 'modelisationData.alreadyAskLoadingModelisations', false),
    loadingModelisations: lodashGet(state, 'modelisationData.loadingModelisations', false),
    listOfCultures: lodashGet(state, 'modelisationData.culturesForModelisation', []),
    modelisationProcessing: lodashGet(state, 'modelisationData.modelisationProcessing', []),

    //Infos provenant du reducer 'clientUser':
    authorizeModelisation: lodashGet(state, 'clientUserData.clientDatas.authorizeModelisation', false),
});

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
    //nécessaire pour récupérer toutes les modélisations de parcelles venant de la base de données
    getAllModelisations: () => dispatch(ActionGetAllModelisations()),
    // Récupère les modélisations en cours d'init
    getModelisationsInitializing: () => dispatch(ActionGetModelisations()),

    //nécessaire pour renseigner le nom de la page en cours
    setCurrentPageName: (pageName, showTutorialFirstParcel) => dispatch(ActionSetCurrentPageName(pageName, showTutorialFirstParcel)),
    initCultures: () => dispatch(ActionInitCultures()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AbstractModelisation);