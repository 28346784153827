import React from 'react';
import { connect } from 'react-redux';
import lodash from 'lodash';

/* DatePicker */
import frLocale from "date-fns/locale/fr";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-GB";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CustomDatePicker  from '../customDatePicker.jsx';

/* Traduction */
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

/* Models, utils */
import { Observation } from '../../models/observation.js';
import { ObservationsHelper } from '../../utils/observationsHelper.js';
import { ObservationImage } from '../../models/observationImage.js';
import ConstantsObservation from '../../utils/constantsObservation.js';
import fileHelper from '../../utils/fileHelper.js';

/* Icones */
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

/* Composants MUI */
import { Button, Typography, TextField, MenuItem, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText,
    DialogActions, Grid, CircularProgress, Divider } from '@mui/material';

/* Redux */
import { ActionDeleteObservationIdListToAPI, ActionUpdateObservationToAPI, ActionResetLastObservationIdSaved, msgErrorDuringSaveValues,
    ActionResetIdentificationData, ActionSetObservationImagesForIdentification } from '../../redux/actions/observations.js';
import { ActionClosePinDropFormDialog, ActionShowImageDialog } from '../../redux/actions/contextApp.js';

import AbstractIdentification from './identification/AbstractIdentification.jsx';
import DisplayImageList from '../thumbnail/DisplayImageList';

/* css */
import '../../assets/css/pdp-pinDropPopup.css';
import { Stack } from '@mui/system';


/**
 * Composant représentant la popup de validation (suppression/sauvegarde) juste après avoir créer un point/zone d'observation
 */
class PinDropFormPopup extends React.Component {
    constructor(props) {
        super(props);

        this.linkedParcelList = (Array.isArray(props.linkedParcelList)) ? props.linkedParcelList : [{ parcelId: 0, parcelName: StringTranslate.noparcelname, area: 0 }];
        this.observationTypeList = ObservationsHelper.getObservationTypeListWithi18n(props.observationTypeDico); // récupération d'une liste avec TRADUCTION INCLUSE

        let parcelId = this.linkedParcelList[0].parcelId; // id de la parcelle sélectionnée
        let parcelName = this.linkedParcelList[0].parcelName;
        let selectedDate = new Date(); // date de création de l'observation
        let selectedType = (this.observationTypeList[0] ? this.observationTypeList[0] : null); // type d'observation
        let comment = ''; // commentaire

        this.images = [];
        this.imagesURL = [];

        this.observation = undefined;

        // Récupération des données de l'observation si existantes
        if (props.observationId !== undefined) {
            this.observation = { ...props.observationDico[props.observationId] };
            parcelId = (this.observation.parcelId !== -1) ? this.observation.parcelId : 0;
            parcelName = this.linkedParcelList.find((linkedParcel) => linkedParcel.parcelId === parcelId).parcelName;
            selectedDate = this.observation.observationDate;
            selectedType = this.observationTypeList.find((type) => type.id === this.observation.observationType);
            comment = (this.observation.description != null) ? this.observation.description : '';

            this.images = [...this.observation.images];
            this.imagesURL = this.images.map((image) => {
                return image.vignetteUrl;
            });
        }
        // Récupération de l'image si prise avant l'affichage du formulaire (mobile uniquement)
        if (props.image !== undefined) {
            this.images = [props.image,];
            this.imagesURL = [props.image.vignetteUrl,];
        }

        if (props.identificationResultChoosen !== null) {
            comment = `${StringTranslate.pinDropFormIdentificationComment} ${props.identificationResultChoosen.species.commonNames[0]}`;
            props.identificationImages.forEach((image) => {
                this.images.push({ ...image });
                this.imagesURL.push(image.imageUrl);
            });
            selectedType = this.observationTypeList.find((type) => type.id === 2); //Type "adventices"
        }

        this.state = {
            parcelId: parcelId,
            parcelName: parcelName,
            selectedDate: selectedDate,
            selectedType: selectedType,
            comment: comment,

            isDatePickerOpen: false,

            saving: false,
            errorMsg: null,

            openDialogCancel: false,
            openDialogDelete: false,
            images: [...this.images],
            imagesURL: [...this.imagesURL],

            identificationDialogOpened: false,
        };

        //↓ i18n - calendrier ↓
        let language = StringTranslate.getLanguage();
        this.staticDateAdapter = null;
        if (language === 'fr-FR')
            this.staticDateAdapter = new AdapterDateFns({ locale: frLocale });
        else if (language === 'fr-BE')
            this.staticDateAdapter = new AdapterDateFns({ locale: frLocale });
        else if (language === 'es-ES')
            this.staticDateAdapter = new AdapterDateFns({ locale: esLocale });
        else if (language === 'en-GB' || language === 'en-US')
            this.staticDateAdapter = new AdapterDateFns({ locale: enLocale });
        else
            this.staticDateAdapter = new AdapterDateFns({ locale: frLocale });

        this.inputFileRef = React.createRef();

    }

    componentDidUpdate(prevProps, prevState) {

        const { lastObservationIdSaved, msgErrorDuringSave, observationDico, identificationResultChoosen, identificationImages, resetIdentificationData } = this.props;
        let lastObsIdSaved = lastObservationIdSaved;

        // Récupération des informations de l'observation après sauvegarde
        if ((prevProps.lastObservationIdSaved !== lastObsIdSaved) && (lastObsIdSaved !== undefined)) {
            let ids = this.state.images.filter((image) => image.id !== undefined).map((image) => { return image.id });
            this.images = [
                ...this.state.images,
                ...observationDico[lastObsIdSaved].images.filter((image) => !ids.includes(image.id)),
            ];
            this.imagesURL = this.images.map((image) => {
                return (image.vignetteUrl !== undefined) ? image.vignetteUrl : image.imageUrl; //Si pas de vignette on affiche l'image de base 
            });
            this.observation = {
                ...observationDico[lastObsIdSaved],
                images: [
                    ...this.images,
                ],
            };
            this.setState({
                images: [...this.images],
                imagesURL: [...this.imagesURL],
            })
        }

        // Récupération du message d'erreur si problème lié à la sauvegarde
        if (prevProps.msgErrorDuringSave !== msgErrorDuringSave) {
            if (msgErrorDuringSave === msgErrorDuringSaveValues.observation) {
                this.setState({ errorMsg: StringTranslate.pindDropFormErrorSaveObservation, saving: false });
            }
            if (msgErrorDuringSave === msgErrorDuringSaveValues.image) {
                this.setState({ errorMsg: StringTranslate.pindDropFormErrorSaveImage, });
            }
        }

        // Fin des appels Api (débloque le formulaire si erreur)
        if ((prevProps.saving !== this.props.saving) && (this.props.saving === false) && (this.props.saving !== this.state.saving)) {
            this.setState({ saving: false });
        }

        if ((prevProps.identificationResultChoosen !== identificationResultChoosen) && (identificationResultChoosen !== null)) {
            this.images = [
                ...this.images,
                ...identificationImages.filter((image) => image.id === undefined),
            ];
            this.imagesURL = this.images.map((image) => {
                return image.vignetteUrl || image.imageUrl;
            });

            this.setState({
                comment: `${StringTranslate.pinDropFormIdentificationComment} ${identificationResultChoosen.species.commonNames[0]} \n ${this.state.comment}`,
                images: [...this.images],
                imagesURL: [...this.imagesURL],
            }, () => {
                resetIdentificationData();
            });
        }
    }

    /* ↓ fonctions liées à l'affection d'une parcelle ↓ */
    onSelectParcelNameFromMenu = (event) => {
        event.stopPropagation();
        let parcelLinked = this.linkedParcelList.find((linkedParcel) => linkedParcel.parcelName === event.target.value);
        this.setState({
            parcelName: event.target.value,
            parcelId: (parcelLinked) ? parcelLinked.parcelId : -1,
        });
    }
    onCloseParcelNameFromMenu = (event) => {
        event.stopPropagation();
    }

    /* fonction permettant de prendre en compte le click sur une date dans le calendrier */
    handlePickDate = (date) => {
        this.setState({
            selectedDate: date,
            isDatePickerOpen: false,
        });
    }

    /* ↓ fonctions liées à l'affection d'un type d'observation ↓ */
    onSelectTypeFromMenu = (event) => {
        const { layerType, pindropLayer } = this.props;
        const { observation } = this;

        event.stopPropagation();

        let oldSelectedType = this.state.selectedType;
        let newSelectedType = event.target.value;
        this.setState({
            selectedType: newSelectedType,
        });

        // ↓ mise à jour du visuel lié au type d'observation ↓
        if (newSelectedType && observation === undefined) {
            if (layerType === "marker") {
                let customLeafletIcon = ObservationsHelper.getLeafletIconFromObservationTypeId(newSelectedType.id);
                pindropLayer.setIcon(customLeafletIcon);
            }
            else if (layerType === "polygon") {
                let oldClasseToRemove = ObservationsHelper.getObservationAreaClassFromObservationTypeId(oldSelectedType ? oldSelectedType.id : 0);
                let newClasseToAdd = ObservationsHelper.getObservationAreaClassFromObservationTypeId(newSelectedType.id);
                pindropLayer._path.classList.remove(oldClasseToRemove); // ⚠️ - seul moyen trouvé pour mettre à jour le className une fois que le layer est affiché...
                pindropLayer._path.classList.add(newClasseToAdd); // ⚠️ - seul moyen trouvé pour mettre à jour le className une fois que le layer est affiché...
            }
            else { /* RAS */ }
        }
    }

    onCloseTypeFromMenu = (event) => {
        event.stopPropagation();
    }

    /* ↓ fonctions liées à l'ajout de photo ↓ */
    handleClickTriggerInputFile = () => {
        if (this.state.images.length < ConstantsObservation.NbMaxImages) {
            this.inputFileRef.current.click(); //trigger l'input
        }
    }

    onInputFileChange = (event) => {
        const { images, imagesURL, selectedDate } = this.state;
        const { clientId, } = this.props;
        let imgFile = event.target.files[0]; // récupération du flux de l'image

        this.setState({
            saving: true,
        }, () => {
            fileHelper.getDataFromJpegImage(imgFile)
                .then((informationImage) => {
                    // création de l'objet image
                    this.setState({
                        images: [
                            ...images,
                            new ObservationImage({
                                observationId: (this.observation !== undefined) ? this.observation.id : -1,
                                clientId: clientId || -1,
                                date: selectedDate,
                                width: informationImage.width,
                                height: informationImage.height,
                                imageData: [...informationImage.imageData],
                                imageUrl: informationImage.imageUri,
                                vignetteUrl: informationImage.imageUri,
                            })
                        ],
                        imagesURL: [...imagesURL, informationImage.imageUri],
                        saving: false,
                    });
                })
                .catch(() => {
                    this.setState({
                        saving: false,
                    });
                });
        });
    }

    onClickDeleteImage = (index) => {
        const { imagesURL, images } = this.state;
        let newImagesURL = imagesURL;
        let newImages = images;
        newImagesURL.splice(index, 1);
        newImages.splice(index, 1);
        this.setState({
            images: [...newImages],
            imagesURL: [...newImagesURL],
        });
    }

    /* ↓ fonctions liées à l'afficahge de photo ↓ */
    handleOpenDialogImage = (index) => {
        if (index < 0) return;

        const { images } = this.state;
        if ((!images) || (!Array.isArray(images)) || (index >= images.length)) return;

        let image = this.state.images[index];
        if (!image) return;

        this.props.showImageDialog(image.imageUrl);
    }

    handleClickIdentification = (event) => {
        const { setObservationImagesForIdentification } = this.props;

        event.stopPropagation();
        event.preventDefault();
        
        let imageForIdentification = [...this.state.images];
        setObservationImagesForIdentification(
            imageForIdentification.map((image) => {
                let returnImage = { ...image };
                //Si pas enregistrer, retire les urls des photos (gain de perf redux)
                if (returnImage.id === -1) {
                    returnImage.imageUrl = "";
                    returnImage.vignetteUrl = "";
                }
                return returnImage;
            }),
            this.closeDialogIdentification
        );
        this.setState({ identificationDialogOpened: true });
    }

    closeDialogIdentification = () => {
        this.setState({ identificationDialogOpened: false });
    }

    // fonction permettant de refermer la popup
    handleClose = () => {
        const { deletePinDropGeometry, identificationResultChoosen, resetIdentificationData } = this.props;
        const { parcelId, selectedType, comment, images } = this.state;
        if (this.observation === undefined) {
            deletePinDropGeometry();
            if (identificationResultChoosen !== null)
                resetIdentificationData();
        } else {
            let observationToUpdate = new Observation({
                ...this.observation,
                parcelId: (parcelId > 0) ? parcelId : -1,
                observationType: selectedType.id,
                description: comment,
                images: images,
            });
            let observation = new Observation({
                ...this.observation,
            });
            if (!lodash.isEqual(observation, observationToUpdate)) {
                this.setState({ openDialogCancel: true });
            } else {
                this.handleAbortModificationAndClose();
            }
        }
    }

    handleCloseDialogCancel = () => {
        this.setState({ openDialogCancel: false });
    }

    handleAbortModificationAndClose = () => {
        const { resetLastObservationIdSaved, pindropLayer, pinDropFormDialogOpened, closePinDropFormDialog, identificationResultChoosen,
            resetIdentificationData } = this.props;
        resetLastObservationIdSaved();
        pindropLayer.fire("click");

        if (identificationResultChoosen !== null)
            resetIdentificationData();
        // ↓ smartphone - fermeture de la fenêtre de dialogue ↓
        if (pinDropFormDialogOpened)
            closePinDropFormDialog();
    }

    /* fonctions liées à la suppression de l'observation */
    handleShowDialogDelete = (bool) => {
        this.setState({ openDialogDelete: bool, });
    }

    handleDelete = () => {
        const { deleteObservationIdList, pindropLayer, deleteLayerFromMap, deletePinDropGeometry, resetLastObservationIdSaved } = this.props;
        this.setState({ saving: true, openDialogDelete: false });
        //supprime en bdd et redux
        deleteObservationIdList([this.observation.id])
            .then(() => {
                resetLastObservationIdSaved();
                this.handleShowDialogDelete(false);
                //supprime le pin
                if (deleteLayerFromMap !== undefined) {
                    pindropLayer.fire("click");
                    deleteLayerFromMap(pindropLayer);
                } else {
                    // cas où il y a eu une erreur lors d'un ajout d'image et que la popup n'a pas été réouverte
                    deletePinDropGeometry();
                }

            });
    }

    /* fonction permettant de créer un objet observation et de lancer le processus de sauvegarde */
    handleClickSave = () => {
        const { parcelId, selectedDate, selectedType, comment, images } = this.state;
        const { pindropLayer, clientId, pinDropLayerArea, savePinDropGeometry } = this.props;
        this.setState({ saving: true, });

        let feature4326 = (pindropLayer) ? pindropLayer.toGeoJSON() : null;
        let geometryType = (feature4326) ? feature4326.geometry.type : '';
        let observation = new Observation({
            clientId: (clientId) ? clientId : -1,
            parcelId: (parcelId > 0) ? parcelId : -1,
            observationDate: selectedDate,
            observationType: selectedType ? selectedType.id : null,
            description: comment,
            geometryType: geometryType,
            featureStr4326: JSON.stringify(feature4326),
            superficieHa: (pinDropLayerArea > 0) ? pinDropLayerArea : 0,
            images: images,
        });

        savePinDropGeometry(observation).then((isSuccess) => {
            this.setState({ saving: false, });
        });
    }

    handleCreateOrUpdate = () => {
        const { pindropLayer, updateObservation, createLayerOnMap, deleteLayerFromMap, deletePinDropGeometry, observationId } = this.props;
        const { images, parcelId, selectedType, comment } = this.state;
        const { observation } = this;

        if (this.observation === undefined) {
            //Create
            this.handleClickSave();
        } else {
            //Update
            let observationToUpdate = new Observation({
                ...observation,
                parcelId: (parcelId > 0) ? parcelId : -1,
                observationType: selectedType.id,
                description: comment,
                images: images,
            });
            // si l'observation est modifiée on la sauvegarde
            if (!lodash.isEqual(observation, observationToUpdate)) {
                this.setState({ saving: true });
                updateObservation(observationToUpdate).then((returnResult) => {
                    this.setState({ saving: false });
                    if (returnResult.isSuccess) {
                        if (observationId !== undefined) {
                            //update map (supprimer puis rajouter une pin pour mettre a jour le visuel du type d'observation)
                            pindropLayer.fire("click"); // trigger l'event click du layer
                            deleteLayerFromMap(pindropLayer);
                            createLayerOnMap(returnResult.observation);
                        } else {
                            // cas où il y a eu une erreur lors d'un ajout d'image et que la popup n'a pas été réouverte
                            deletePinDropGeometry();
                        }
                    }
                });
            } else { // sinon on referme la popup
                this.handleClose();
            }
        }
    }

    render() {
        const { layerType, pinDropLayerArea } = this.props;
        const { parcelName, selectedDate, selectedType, comment,
            imagesURL, saving, openDialogDelete, errorMsg, openDialogCancel, identificationDialogOpened, isDatePickerOpen } = this.state;

        return (
            <>
                <DialogTitle>

                    <Grid container spacing={0}>
                        <Grid item xs={8} sx={{alignSelf: "center"}} >
                            {(layerType === 'marker') && 
                                <Typography variant='h4'>
                                    {StringTranslate.pinDropFormTitlePoint}
                                </Typography>
                            }
                            {(layerType === 'polygon') && 
                                <Typography variant='h4'>
                                    {StringTranslate.pinDropFormTitleZone}
                                </Typography>
                            }
                        </Grid>
                        <Grid item xs={4} align="right">
                            <IconButton
                                aria-label="close"
                                onClick={this.handleClose}
                                sx={{
                                    color: (theme) => theme.palette.grey[500]
                                }}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                </DialogTitle>

                <Divider />

                <DialogContent disabled={saving} sx={{height: '284px'}}>
                    {(!identificationDialogOpened) ?
                        <>
                           
                            <Grid container spacing={2}>

                                {/* ↓ champ surface ↓ */}
                                {(layerType === 'polygon') && (pinDropLayerArea > 0) && <Grid item xs={12}>
                                    <Grid container spacing={1} sx={{ mt: 1 }}>
                                        <Grid item xs={6}>
                                            {StringTranslate.columnsurperficie}
                                        </Grid>
                                        <Grid item xs={6} align="right">
                                            {pinDropLayerArea}
                                        </Grid>
                                    </Grid>

                                </Grid>}

                                {/* ↓ champ parcelle ciblée  ↓ */}
                                <Grid item xs={12} sx={{ mt: 1 }}>

                                    <TextField
                                        fullWidth
                                        disabled={(Array.isArray(this.linkedParcelList) && this.linkedParcelList.length > 1) ? false : true}
                                        select
                                        label={StringTranslate.columnparcelname}
                                        value={parcelName}
                                        onChange={this.onSelectParcelNameFromMenu}
                                        size="small"
                                    >
                                        {this.linkedParcelList.map((linkedParcel, i) => (<MenuItem key={i} value={linkedParcel.parcelName}>{linkedParcel.parcelName}</MenuItem>))}
                                    </TextField>

                                </Grid>

                                {/* ↓ champ date d'observation  ↓ */}
                                <Grid item xs={6} sx={{ mt: 1 }}>
                                    <CustomDatePicker
                                        label={StringTranslate.columnobservationdate}
                                        disabled={this.observation !== undefined}
                                        value={(selectedDate !== null) ? new Date(selectedDate) : null}
                                        placeholder={StringTranslate.formatDatePlaceHolder}
                                        slotPropsDisabled={saving}
                                        onChange={date => this.handlePickDate(date)}
                                        open={isDatePickerOpen}
                                        onOpen={() => this.setState({ isDatePickerOpen: true })}
                                        onClose={() => this.setState({ isDatePickerOpen: false })}
                                        size={'small'}
                                    />

                                </Grid>

                                {/* ↓ champ type d'observation  ↓ */}
                                <Grid item xs={12} sx={{ mt: 1 }}>
                                    <TextField
                                        fullWidth
                                        select
                                        label={StringTranslate.columnobservationtype}
                                        value={selectedType || ''}
                                        onChange={this.onSelectTypeFromMenu}
                                        disabled={saving}
                                        size="small"
                                    >
                                        {(Array.isArray(this.observationTypeList)) && this.observationTypeList.map((type) => <MenuItem key={type.id} value={type}>{type.i18n}</MenuItem>)}
                                    </TextField>
                                </Grid>

                                {/* ↓ champ remarques ↓ */}
                                <Grid item xs={12} sx={{ mt: 1 }}>
                                    <TextField
                                        fullWidth
                                        label={StringTranslate.columnremarques}
                                        multiline
                                        rows="2"
                                        value={comment}
                                        disabled={saving}
                                        onChange={(event) => this.setState({ comment: event.target.value })}
                                        size="small"
                                    />
                                </Grid>

                                {/* ↓ affichage photos ↓ */}
                                <Grid item xs={12} sx={{ mt: 0.5 }}>

                                    <Grid container spacing={1} direction="row">

                                        <Grid item disabled={saving}>
                                            <DisplayImageList
                                                imagesURL={imagesURL}
                                                onClickImage={this.handleOpenDialogImage}
                                                onClickDeleteImage={this.onClickDeleteImage}
                                                onInputFileChange={this.onInputFileChange}
                                            />
                                        </Grid>
                                    </Grid>

                                </Grid>

                                {/* ↓ bouton Identification ↓ */}
                                {(selectedType.id === 2) && <Grid item xs={12}>

                                    <Button
                                        variant='contained'
                                        color='primary'
                                        size="small"
                                        startIcon={<SearchIcon />}
                                        disabled={saving}
                                        onClick={(event) => this.handleClickIdentification(event)}
                                    >
                                        {StringTranslate.identificationTitle}
                                    </Button>

                                </Grid>}

                                {/* ↓ Messages d'erreur ↓ */}
                                {(errorMsg !== null) && <Grid item xs={12} sx={{ mt: 2 }}>
                                    <Typography variant="subtitle2" color="error">
                                        {errorMsg}
                                    </Typography>
                                </Grid>}

                            </Grid>
                        </>
                        :
                        <AbstractIdentification />}
                </DialogContent>

                <Divider />

                <DialogActions 
                    sx={{
                        display:"none",
                        ...( (this.observation !== undefined) && {
                            display:"block"
                        })
                    }}
                >
                    <Stack
                        justifyContent="space-between"
                        direction="row"
                    >
                        <Button
                            color="error"
                            onClick={() => this.handleShowDialogDelete(true)}
                            variant="text"
                            disabled={saving}
                        >
                            {StringTranslate.deleteObservation}
                        </Button>
                        <div>                        
                            <Button
                                color="error"
                                onClick={this.handleClose}
                                variant="text"
                                disabled={saving}>
                                {StringTranslate.annulerimport}
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={(saving) && <CircularProgress color="inherit" size={24} />}
                                disabled={saving}
                                onClick={this.handleCreateOrUpdate}
                                sx={{
                                    ml:1
                                }}
                            >
                                {StringTranslate.enregistrer}
                            </Button>
                        </div>
                    </Stack>
                </DialogActions>
                

                <DialogActions
                    sx={{
                        display:"block",
                        ...( (this.observation !== undefined) && {
                            display:"none"
                        })
                    }}
                >
                    <Stack
                        justifyContent="space-between"
                        direction="row"
                    >
                        <Button
                            color="error"
                            onClick={this.handleClose}
                            variant="text"
                            disabled={saving}>
                            {StringTranslate.annulerimport}
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={(saving) && <CircularProgress color="inherit" size={24} />}
                            disabled={saving}
                            onClick={this.handleCreateOrUpdate}
                            sx={{
                                ml:1
                            }}
                        >
                            {StringTranslate.enregistrer}
                        </Button>
                    </Stack>
                </DialogActions>

                <Dialog open={openDialogDelete} onClose={() => this.handleShowDialogDelete(false)} aria-labelledby="form-dialog-titleDel-observ">
                    <DialogTitle id="form-dialog-titleDel-observ">{StringTranslate.deleteObservation}</DialogTitle>

                    <DialogContent>

                        <DialogContentText>
                            {StringTranslate.deleteObservationConfirmation}
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>

                        <Button
                            onClick={() => this.handleShowDialogDelete(false)}
                            variant="text"
                            color="error" sx={{mr: 1}}>
                            {StringTranslate.cancelDeleteAction}
                        </Button>
                        <Button onClick={this.handleDelete} variant="contained" color="primary">
                            {StringTranslate.confirmDeleteAction}
                        </Button>

                    </DialogActions>
                </Dialog>

                <Dialog open={openDialogCancel} onClose={this.handleCloseDialogCancel} aria-labelledby="form-dialog-titleCancel-observ">
                    <DialogTitle id="form-dialog-titleCancel-observ">{StringTranslate.cancelObservation}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {StringTranslate.cancelObservationConfirmation}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={this.handleCloseDialogCancel} variant="text" color="error" sx={{mr: 1}}>
                            {StringTranslate.cancelDeleteAction}
                        </Button>
                        <Button onClick={this.handleAbortModificationAndClose} variant="contained" color="primary">
                            {StringTranslate.confirmDeleteAction}
                        </Button>

                    </DialogActions>
                </Dialog>
            </>

        )
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant décorée */
const mapStateToProps = state => ({
    clientId: state.clientUserData.clientDatas.id,

    pinDropFormDialogOpened: state.contextAppData.pinDropFormDialogOpened, // état de la fenêtre de dialogue utilisée dans la création d'observation
    observationDico: state.observationsData.observationDico,
    observationTypeDico: state.observationsData.observationTypeDico,
    lastObservationIdSaved: state.observationsData.lastObservationIdSaved,
    msgErrorDuringSave: state.observationsData.msgErrorDuringSave,
    saving: state.observationsData.saving,

    identificationImages: state.observationsData.identificationImages,
    identificationResultChoosen: state.observationsData.identificationResultChoosen,
});

/* fonction permettant de fournir les fonctions (actions) au composant décorée */
const mapDispatchToProps = dispatch => ({
    deleteObservationIdList: (observationIdListToDelete) => dispatch(ActionDeleteObservationIdListToAPI(observationIdListToDelete)),
    updateObservation: (observationToUpdate) => dispatch(ActionUpdateObservationToAPI(observationToUpdate)),
    resetLastObservationIdSaved: () => dispatch(ActionResetLastObservationIdSaved()),
    closePinDropFormDialog: () => dispatch(ActionClosePinDropFormDialog()),
    resetIdentificationData: () => dispatch(ActionResetIdentificationData()),
    setObservationImagesForIdentification: (observationImages, closeDialogIdentification) => dispatch(ActionSetObservationImagesForIdentification(observationImages, closeDialogIdentification)),
    showImageDialog: (uri) => dispatch(ActionShowImageDialog(uri)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PinDropFormPopup);