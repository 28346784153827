import React from 'react';
import Iframe from 'react-iframe';
import { connect } from 'react-redux';


class CustomIFrame extends React.Component {
    /*constructor(props){
        super(props);
    }*/ //Pas nécessaire car vide !

    render() {
        const { className, url, height, id } = this.props;
        if((height !== null || height !== undefined) && (className === null || className === undefined)){
            return (
                <Iframe 
                    url={url}
                    frameBorder="0"
                    width="100%"
                    height={height}
                    allowFullScreen
                    position="relative" />
              );
        }
        else if((id !== null || id !== undefined) && (height !== null || height !== undefined) && (className === null || className === undefined)){
            return(
                <Iframe
                id={id}
                url={url}
                frameBorder="0"
                width="100%"
                height={height}
                allowFullScreen
                position="relative"
            />
            );
        }
        else
        {
            return (
                <Iframe 
                    url={url}
                    frameBorder="0"
                    width="100%"
                    className= {className}
                    allowFullScreen
                    position="relative" />
              );
        }

    }
}



const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CustomIFrame);