import * as React from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

/* MUI Components */
import { Box, Stepper, Step, StepLabel } from '@mui/material';

/* React Components */
import SpecificInfosManagement from './harvestStepsManagement/specificInfosManagement.jsx';
import ParcelsChoice from './harvestStepsManagement/parcelsChoice.jsx';

/* Redux */
import { HarvestStep, ActionGoToStepOfHarvest } from '../../redux/actions/harvest';

/* Traduction */
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

/* theme */
import getTheme from "../../themes/index.js";

let theme = getTheme();

function HarvestStepsManagement(props) {
    /* Les différentes étapes */
    const steps = [StringTranslate.parcellesListBtnOnSidebar, StringTranslate.harvest];
    /* Step 0 : Parcelles
       Step 1 : Management */
    const getStep = () => {
        switch (props.harvestStep) {

            case HarvestStep.CHOIX_PARCELS: return 0;
            case HarvestStep.MANAGEMENT: return 1;
            default: return 0;
        }
    };
    
    return (
            <Box sx={{ width: '100%' }}>
        
                <Stepper
                    sx={{ 
                        [theme.breakpoints.up('md')]: { width:'60%', marginLeft: '20%'},
                        [theme.breakpoints.down('md')]: { width:'100%'},
                 }}
                    activeStep={getStep()} 
                    alternativeLabel={window.innerWidth < 456}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>

            <Box sx={{mt: 3}}>
                {/* TODO ne pas passer {...props} => on ne sait pas ce qu'on passe au composant et on lui passe surtout des props que l'on utilise pas */}
                {(props.harvestStep === HarvestStep.CHOIX_PARCELS) && <ParcelsChoice />}
                {(props.harvestStep === HarvestStep.MANAGEMENT) && <SpecificInfosManagement />}
            </Box>

        </Box>
    );
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    //Infos provenant du reducer 'harvest':
    harvestStep: lodashGet(state, 'harvestData.harvestStep', HarvestStep.CHOIX_PARCELS),

    //Infos provenant du reducer 'clientUserData':
    clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1),

    //Infos provenant du reducer 'settingsData':
    language: lodashGet(state, 'settingsData.settings.language', StringTranslate.getLanguage()),
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    goToStep: (step) => dispatch(ActionGoToStepOfHarvest(step)),

})

export default connect(mapStateToProps, mapDispatchToProps)(HarvestStepsManagement);
