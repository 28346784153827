// /!\ attention les abréviations 
const themeComponent = (theme) => {
    return {
        MuiTableRow: {  // Mui-datatable
            styleOverrides: { 
                root: {
                    backgroundColor: 'white !important'
                }
            }
        },
        MuiSpeedDial: {
            styleOverrides: {
                fab: {
                    backgroundColor: theme.palette.common.white,
                    '&:hover': {
                      backgroundColor:theme.palette.primary.light
                    }
                }
            }
        },
        MuiSpeedDialIcon: {
            styleOverrides: {
                root:{
                    color: theme.palette.primary.main
                }
            }
        },
        MuiSpeedDialAction: {
            styleOverrides: {
                fab: {
                    backgroundColor: theme.palette.common.white,
                    '&:hover': {
                      backgroundColor:theme.palette.primary.light
                    }
                }
            }
        },
        MuiSwitch: {
            variant: [
                {
                    props: { variant: 'choose', color: 'test'},
                    style: {
                        "&.Mui-checked": {
                          "& .MuiSwitch-thumb": {},
                          "& .MuiSwitch-track": { opacity: 0.5 }
                        },
                        "& .MuiSwitch-thumb": {},
                        "& .MuiSwitch-track": { opacity: 0.5 },
                        "& .MuiTouchRipple-root:hover": {}
                    }
                }                
            ]
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    '&:focus': {
                        backgroundColor: 'transparent'
                    }
                }
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                root: { 
                    backgroundColor: "transparent",
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none'
                    },
                    '& .MuiDataGrid-cell:focus-within': {
                        outline: 'none'
                    },
                    '& .MuiDataGrid-columnHeader:focus': {
                        outline: 'none'
                    },
                    '& .MuiDataGrid-columnHeader:focus-within': {
                        outline: 'none'
                    }
                },
                main: { backgroundColor: "transparent" },
                virtualScroller: { overflowY: "scroll" },
            }
        },
        MuiCheckBox: {
            styleOverrides: { root: {} }
        },
        MuiTreeItem: {
            styleOverrides: {
                label: {
                    marginBottom: 14,
                    marginTop: 14
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    borderBottom: "1px solid",
                    borderColor: theme.palette.grey[200]
                }
            },
            variants: [
                {
                    props: { variant_pz: 'row' },
                    style: { 
                        marginBottom: 24, // mb: 3 (3 => 24px)
                        '& a': {
                            minHeight: 'auto',
                            minWidth: '80px', //10
                            padding: '12px', // 1.5
                            marginRight: '17.6px', // 2.2
                            color: theme.palette.grey[600],
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        },
                        '& a.Mui-selected': {
                            color: theme.palette.primary.main
                        },
                        '& a > svg': {
                            marginBottom: '0px !important',
                            marginRight: '8.8px' // 1.1
                        }
                    }
                }
            ]
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    marginBottom: "4px",
                    color: theme.palette.text.primary,
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderRadius: "8px",
                    '&.Mui-selected': {
                        color: theme.palette.secondary.dark,
                        backgroundColor: theme.palette.secondary.light,
                        '&:hover': { backgroundColor: theme.palette.secondary.light },
                        '& .MuiListItemIcon-root': { color: theme.palette.secondary.dark }
                    },
                    '&:hover': {
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        '& .MuiListItemIcon-root': { color: theme.palette.secondary.dark }
                    }
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    padding: "10px 16px 10px 24px",
                    borderRadius: "8px",
                    marginBottom: "4px",
                    color: theme.palette.text.primary,
                    '&.Mui-selected': {
                        color: theme.palette.secondary.dark,
                        backgroundColor: theme.palette.secondary.light,
                        '&:hover': { backgroundColor: theme.palette.secondary.light },
                        '& .MuiListItemIcon-root': { color: theme.palette.secondary.dark }
                    },
                    '&:hover': {
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        '& .MuiListItemIcon-root': { color: theme.palette.secondary.dark }
                    }
                }
            },
            variants: [
                {
                    props: { variant_pz: 'legende_calendrier' },
                    style: { 
                        padding: "2px 5px 2px 10px",
                        borderRadius: "8px",
                        marginBottom: "5px",
                        color: theme.palette.text.primary,
                        '&.Mui-selected': {
                            color: theme.palette.secondary.dark,
                            '& .MuiListItemIcon-root': { color: theme.palette.secondary.dark }
                        },
                    }
                }
            ]
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.palette.grey[700],
                    minWidth: "36px"
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: theme.palette.common.white,
                    borderRadius: "8px",
                    '& .MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.grey[400] },
                    '&:hover $notchedOutline': { borderColor: theme.palette.grey[700] },
                    '&.MuiInputBase-multiline': { padding: 1 },
                    '&.Mui-disabled': {
                        background: theme.palette.grey[50]
                    },
                },
                input: {
                    fontWeight: 500,
                    background: theme.palette.common.white,
                    padding: "15.5px 14px",
                    borderRadius: "8px",
                    '&.MuiInputBase-inputSizeSmall': {
                        padding: "10px 14px",
                        '&.MuiInputBase-inputAdornedStart': { paddingLeft: 0 }
                    },
                    '&.Mui-disabled': {
                        background: theme.palette.grey[50]
                    },
                },
                inputAdornedStart: { paddingLeft: 4 },
                notchedOutline: { borderRadius: "8px" }
            }
        },
        MuiButtonGroup: {
            variants: [
                {
                    props: { variant_pz: 'btns-grp-drawAndobs' },
                    style: { 
                        background: 'transparent',
                        boxShadow: 'none',
                        height: '30px',
                        position: "absolute",
                        marginTop: "57px",
                        marginLeft: "-239px",
                        pointerEvents: "visible",
                        '& root': {
                            pointerEvents: "visible"
                        },
                        '& > button.MuiButtonBase-root': {
                            marginRight:"3px"
                        }
                    }
                }
            ]
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    borderRadius: "4px"
                }
            },
            variants: [
                {
                    props: { color: "success", variant: "contained" },
                    style: { color: theme.palette.common.white }
                },
                {
                    props: { variant: "dashed" },
                    style: { border: "1px dashed", borderColor: theme.palette.secondary.main }
                },
                {
                    props: { variant_pz: "icon" },
                    style: { minWidth: 0, padding: "8px" }
                }
            ]
        },
        MuiIconButton: {
            variants: [
                {
                    props: { variant: "header" },
                    style: {
                        backgroundColor: theme.palette.secondary.light,
                        borderRadius:"9px",
                        marginLeft : "16px",
                        '&:hover': 
                        {
                            backgroundColor: theme.palette.secondary.dark,
                            color: theme.palette.secondary.light
                        }
                    }
                }
            ]
        },
        MuiChip: {
            styleOverrides: {
                root: {"&.MuiChip-deletable .MuiChip-deleteIcon": { color: "inherit" }}
            },
            variants: [
                {
                    props: { variant: "header" },
                    style: {
                        backgroundColor: theme.palette.secondary.light,
                        borderRadius:"9px",
                        marginLeft : "16px",
                        '&:hover': 
                        {
                            backgroundColor: theme.palette.secondary.dark,
                            color: theme.palette.secondary.light,
                            '& .MuiChip-icon': {color: theme.palette.secondary.light},
                            '& .MuiChip-label': {color: theme.palette.secondary.light},
                        },
                        '& .MuiChip-icon':
                        {
                            color: theme.palette.secondary.main,
                        },
                        '& .MuiChip-label':
                        {
                            color: theme.palette.secondary.main,
                        }
                    }
                }
            ]
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.secondary.dark,
                    boxShadow: "none"
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    paddingLeft: "13px !important",
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.dark,
                    padding: "16px"
                },
                title: { fontSize: "1.125rem" }
            }
        },
        MuiPaper: {
            defaultProps: {elevation: 0},
            styleOverrides: {
                root: {backgroundImage: "none"},
                rounded: {borderRadius: "8px"}
            },
            variants: [
                {
                    props: { variant: "background" },
                    style: {
                        borderRadius: "13px 13px 0px 0px",
                        margin: " 5px 15px 0px 0px",
                        backgroundColor: theme.palette.primary.light,
                        padding: "20px",
                        width: "-webkit-fill-available",
                        height: "-webkit-fill-available"
                    }
                }
            ]
        },
        MuiSlider: {
            styleOverrides: {
            root: { '&.Mui-disabled': { color: theme.palette.grey[300] } },
            mark: {
                backgroundColor: theme.palette.background.default,
                width: "4px"
            },
            valueLabel: { color: theme.palette.background.content }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.grey[700],
                    boxShadow: "none",
                    border: "none"
                }
            }
        },
        MuiTimelineContent: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.dark,
                    fontSize: "16px"
                }
            }
        },
        MuiTimelineDot: {
            styleOverrides: {
                root: {
                    boxShadow: "none"
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.palette.text.dark,
                    '&::placeholder': {
                        color: theme.palette.text.secondary,
                        fontSize: "0.875rem"
                    }
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiAutocomplete-tag': {
                        background: theme.palette.secondary.light,
                        borderRadius: 4,
                        color: theme.palette.text.dark,
                        '.MuiChip-deleteIcon': { 
                            color: theme.palette.grey.A100
                        }
                    }
                },
                popper: {
                    borderRadius: "8px",
                    boxShadow: "0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)"
                }
            }
        },
        MuiFab:{
            styleOverrides: {
                root: {                        
                    '&:hover': {
                        backgroundColor: theme.palette.primary.light,
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderColor: theme.palette.grey[200],
                    '&.MuiTableCell-head': {
                        fontSize: "0.875rem",
                        color: theme.palette.text.dark,
                        fontWeight: 500
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
            tooltip: {
                color: theme.palette.common.white,
                background: theme.palette.grey[700]
            }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.palette.grey[200],
                    opacity: 1,
                    marginBottom: "4px"
                }
            }
        },
        MuiCard: {
            variants: [
                {
                    props: { variant_pz: 'card-md-bio' },
                    style: { 
                        width: "470px",
                        bottom: "122px",
                        left: "7px",
                        position: "none",
                        pointerEvents: "auto"
                    }
                }
            ]
        },
        MuiCardContent: {
            styleOverrides: {
                root: { alignItems: "center" },
                outlined: { border: '1px dashed'}
            },
            variants: [
                {
                    props: { variant_pz: 'cardct-tuto-chx' },
                    style: { 
                        //padding: { xs: 1, md: 2}, // => padding de 1*8px si petit écran ; 2*8px dès les moyens écrans(et plus grand)
                        //padding: { xs: '8px', md: '16px' }, // => padding de 1*8px si petit écran ; 2*8px dès les moyens écrans(et plus grand)
                        padding: '8px',
                        '& .MuiTypography-root': {
                            minHeight: '60px',
                            maxHeight: '90px',
                        }
                    }
                }
            ]
        },
        MuiCardActions: {
            styleOverrides: { root: {padding: '24px'}}
        },
        MuiAlert: {
            styleOverrides: {
                root: {alignItems: 'center'},
                outlined: {border: '1px dashed'}
            }
        },
        MuiDialog: {
            styleOverrides: {paper: { padding: "12px 0 12px 0" } }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: { fontSize: "1.25rem" }
            }
        },
        "-webkit-scrollbar": {
            styleOverrides: {width: "4px"}
        }
    }
}

export default themeComponent;