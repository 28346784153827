/**************************************************************************************/
/* Configuration complète selon qui est hébergué où:                                  */
/* REMARQUE IMPORTANTE : on aura les valeurs affecté dans 'process.env.'              */
/*  en fonction de la commande lancée dans le terminal, visant un des fichiers .env   */
/**************************************************************************************/
const simulateLoadIntoNativeAppCst = false; // true; // -> //a commenter si pas en test de l'authent Native !!!

const IWantDevToolsEnabled = process.env.REACT_APP_DEV_TOOL_ENABLE === 'true' ? true : false;

const ApplicationIdCst = process.env.REACT_APP_APPLICATION_ID;
const SecretCst = process.env.REACT_APP_SECRET;

const RedirectUriBaseCst = process.env.REACT_APP_REDIRECT_URI_BASE;
const BaseUrlWebApiCst = process.env.REACT_APP_BASE_URL_WEB_API;
const BaseInvoicerUrlWebApiCst = process.env.REACT_APP_BASE_INVOICE_URL_WEB_API;

const AppInsightsIdCst = process.env.REACT_APP_APPLICATION_INSIGHT_ID;

const SentinelVisibleApiWmsKeyCst = process.env.REACT_APP_SENTINEL_VISIBLE_API_WMS_KEY;
const SentinelNdviApiWmsKeyCst = process.env.REACT_APP_SENTINEL_NDVI_API_WMS_KEY;
const LandsatVisibleApiWmsKeyCst = process.env.REACT_APP_LANDSAT_VISIBLE_API_WMS_KEY;
const LandsatNdviApiWmsKeyCst = process.env.REACT_APP_LANDSAT_NDVI_API_WMS_KEY;

const LogoTerresInovia = process.env.REACT_APP_LOGO_TERRES_INOVIA;

const appMode = process.env.REACT_APP_ENV;

const idOnboardingAppCst = process.env.REACT_APP_ONBOARDING_APP;
const withEmailCst = process.env.REACT_APP_ONBOARDING_WITH_EMAIL === 'true' ? true : false;
const onboardingPrefixCst = process.env.REACT_APP_ONBOARDING_PREFIX; //Soit l'ID pour la DEMO / PROD ; Soit pour la version PROTO (pour test ISAGRI) !

export const ConstantsHosters = {
    iWantDevToolsEnabled: IWantDevToolsEnabled,
    applicationId: ApplicationIdCst, 
    secret: SecretCst, 
    urlRootWebAppReact: RedirectUriBaseCst, 
    urlBaseWebApis: BaseUrlWebApiCst,  
    urlBaseInvoicerWebApis: BaseInvoicerUrlWebApiCst, //même si pas encore utilisé car pas de fonctionnel associé, je trouve important de la définir pour la différencier de l'URL de la Web API 'classique' !

    appInsightsId: AppInsightsIdCst,

    //sentinelApiWmsKey: SentinelApiWmsKeyCst,
    sentinelVisibleApiWmsKey: SentinelVisibleApiWmsKeyCst,
    sentinelNdviApiWmsKey: SentinelNdviApiWmsKeyCst,
    landsatVisibleApiWmsKey: LandsatVisibleApiWmsKeyCst,
    landsatNdviApiWmsKey: LandsatNdviApiWmsKeyCst,

    simulateLoadIntoNativeApp: simulateLoadIntoNativeAppCst,

    LogoTerresInovia: LogoTerresInovia,

    appMode: appMode,

    //pour l'outil de onboarding:
    idOnboardingApp: idOnboardingAppCst,
    onboardingWithEmail: withEmailCst,
    onboardingPrefix: onboardingPrefixCst,
};

export default ConstantsHosters;
