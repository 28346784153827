import lodashGet from 'lodash/get';

import { harvestWebApiProvider } from '../../utils/webApiProvider';
import { ActionUpdateParcelCrop, ActionUpdateParcelVariety, ActionUpdateParcelSowingDate } from '../actions/parcels';

export const GO_TO_STEP_OF_HARVEST = 'GO_TO_STEP_OF_HARVEST';
export const GO_TO_TAB_OF_HARVEST = 'GO_TO_TAB_OF_HARVEST';
export const GET_ALL_HARVESTS_BY_CLIENTID = 'GET_ALL_HARVESTS_BY_CLIENTID';
export const LOADING_HARVESTS = 'LOADING_HARVESTS';
export const HARVESTS_LOADED = 'HARVESTS_LOADED';
export const ERROR_LOAD_HARVESTS = 'ERROR_LOAD_HARVESTS';
export const SELECT_ID_PARCELS_FOR_MANAGEMENT = 'SELECT_ID_PARCELS_FOR_MANAGEMENT';
export const HARVESTS_DATES_ASKED_AT_THE_BEGINNING = 'HARVESTS_DATES_ASKED_AT_THE_BEGINNING';

export const GET_ALL_VARIETIES_CHARACTERISTICS_BY_CLIENTID = 'GET_ALL_VARIETIES_CHARACTERISTICS_BY_CLIENTID';
export const LOADING_VARIETIES_CHARACTERISTICS = 'LOADING_VARIETIES_CHARACTERISTICS';
export const VARIETIES_CHARACTERISTICS_LOADED = 'VARIETIES_CHARACTERISTICS_LOADED';
export const ERROR_LOAD_VARIETIES_CHARACTERISTICS = 'ERROR_LOAD_VARIETIES_CHARACTERISTICS';

export const DELETING_HARVESTS = 'DELETING_HARVESTS';
export const DELETE_HARVESTS = 'DELETE_HARVESTS';
export const DELETING_VARIETIES = 'DELETING_VARIETIES';
export const DELETE_VARIETIES = 'DELETE_VARIETIES';
export const ERROR_DELETE_VARIETIES = 'ERROR_DELETE_VARIETIES';

export const GENERATING_HARVEST_DATES = 'GENERATING_HARVEST_DATES';
export const ERROR_GENERATE_RESULTS = 'ERROR_GENERATE_RESULTS';
export const INIT_HARVEST_DATES = 'INIT_HARVEST_DATES';
export const UPDATE_HARVEST_DATAS = 'UPDATE_HARVEST_DATAS';
export const ERROR_UPDATE_HARVEST_DICO = 'ERROR_UPDATE_HARVEST_DICO';
export const UPDATING_HARVEST_DATAS = 'UPDATING_HARVEST_DATAS';

export const SET_VARIETY_CHARACTERISTICS_BY_PARCEL = 'SET_VARIETY_CHARACTERISTICS_BY_PARCEL';
export const CLEAR_VARIETY_BY_PARCEL_ID = 'CLEAR_VARIETY_BY_PARCEL_ID';

export const UPDATING_VARIETY = 'UPDATING_VARIETY';
export const VARIETY_UPDATED = 'VARIETY_UPDATED';
export const OPEN_POPUP_VARIETY_FORM = 'OPEN_POPUP_VARIETY_FORM';

export const ADDING_VARIETY = 'ADDING_VARIETY';
export const VARIETY_ADDED = 'VARIETY_ADDED';
export const ERROR_ADDING_VARIETY = 'ERROR_ADDING_VARIETY';

export const CLEAR_HARVEST_MESSAGE_ERROR = 'CLEAR_HARVEST_MESSAGE_ERROR';

export const GET_PARCEL_ID_FOCUSED = 'GET_PARCEL_ID_FOCUSED';
export const RESET_PARCEL_ID_AND_VARIETY_SAVED = 'RESET_PARCEL_ID_AND_VARIETY_SAVED';

export const LOADING_CORN_DRY_SPECIFICATIONS = 'LOADING_CORN_DRY_SPECIFICATIONS';
export const CORN_DRY_SPECIFICATIONS_LOADED = 'CORN_DRY_SPECIFICATIONS_LOADED';
export const ERROR_LOAD_CORN_DRY_SPECIFICATIONS = 'ERROR_LOAD_CORN_DRY_SPECIFICATIONS';

export const ActionTypeHarvest = {
    GO_TO_STEP_OF_HARVEST: GO_TO_STEP_OF_HARVEST,
    GO_TO_TAB_OF_HARVEST: GO_TO_TAB_OF_HARVEST,
    GET_ALL_HARVESTS_BY_CLIENTID: GET_ALL_HARVESTS_BY_CLIENTID,
    LOADING_HARVESTS: LOADING_HARVESTS,
    HARVESTS_LOADED: HARVESTS_LOADED,
    HARVESTS_DATES_ASKED_AT_THE_BEGINNING: HARVESTS_DATES_ASKED_AT_THE_BEGINNING,
    ERROR_LOAD_HARVESTS: ERROR_LOAD_HARVESTS,
    SELECT_ID_PARCELS_FOR_MANAGEMENT: SELECT_ID_PARCELS_FOR_MANAGEMENT,
    GET_ALL_VARIETIES_CHARACTERISTICS_BY_CLIENTID: GET_ALL_VARIETIES_CHARACTERISTICS_BY_CLIENTID,
    LOADING_VARIETIES_CHARACTERISTICS: LOADING_VARIETIES_CHARACTERISTICS,
    VARIETIES_CHARACTERISTICS_LOADED: VARIETIES_CHARACTERISTICS_LOADED,
    ERROR_LOAD_VARIETIES_CHARACTERISTICS: ERROR_LOAD_VARIETIES_CHARACTERISTICS,
    GENERATING_HARVEST_DATES: GENERATING_HARVEST_DATES,
    ERROR_GENERATE_RESULTS: ERROR_GENERATE_RESULTS,
    INIT_HARVEST_DATES: INIT_HARVEST_DATES,
    UPDATE_HARVEST_DATAS: UPDATE_HARVEST_DATAS, 
    ERROR_UPDATE_HARVEST_DICO: ERROR_UPDATE_HARVEST_DICO,
    UPDATING_HARVEST_DATAS: UPDATING_HARVEST_DATAS,
    DELETING_HARVESTS: DELETING_HARVESTS,
    DELETING_VARIETIES: DELETING_VARIETIES,
    DELETE_HARVESTS: DELETE_HARVESTS,
    DELETE_VARIETIES: DELETE_VARIETIES,
    ERROR_DELETE_VARIETIES: ERROR_DELETE_VARIETIES,
    SET_VARIETY_CHARACTERISTICS_BY_PARCEL: SET_VARIETY_CHARACTERISTICS_BY_PARCEL,
    CLEAR_VARIETY_BY_PARCEL_ID: CLEAR_VARIETY_BY_PARCEL_ID,
    UPDATING_VARIETY: UPDATING_VARIETY,
    VARIETY_UPDATED: VARIETY_UPDATED,
    OPEN_POPUP_VARIETY_FORM: OPEN_POPUP_VARIETY_FORM,
    ADDING_VARIETY: ADDING_VARIETY,
    VARIETY_ADDED: VARIETY_ADDED,
    ERROR_ADDING_VARIETY: ERROR_ADDING_VARIETY,
    CLEAR_HARVEST_MESSAGE_ERROR: CLEAR_HARVEST_MESSAGE_ERROR,
    GET_PARCEL_ID_FOCUSED: GET_PARCEL_ID_FOCUSED,
    RESET_PARCEL_ID_AND_VARIETY_SAVED: RESET_PARCEL_ID_AND_VARIETY_SAVED,
    LOADING_CORN_DRY_SPECIFICATIONS: LOADING_CORN_DRY_SPECIFICATIONS,
    CORN_DRY_SPECIFICATIONS_LOADED: CORN_DRY_SPECIFICATIONS_LOADED,
    ERROR_LOAD_CORN_DRY_SPECIFICATIONS: ERROR_LOAD_CORN_DRY_SPECIFICATIONS

}

/* Les différentes étapes de la récolte */
export const HarvestStep = {
    CHOIX_PARCELS: 'CHOIX_PARCELS',
    MANAGEMENT: 'MANAGEMENT',
}

/* Le type de maïs */
export const CornType = {
    None: 0,
    Ensilage: 1,
    Grain: 2,
}

/* Les différentes cultures pour la récolte */
export const Crop = {
    None: 0,
    Corn: 1
}

/* Les différentes tabs de la récolte */
export const HarvestTab = {
    Management: 0,
    Summary: 1,
    VarietiesSummary: 2
}

//#region GoToStep
export function ActionGoToStepOfHarvest(nextStepValue) {
    return function (dispatch, getState, { history, userAgentAuthent }) {        
        if (nextStepValue === HarvestStep.MANAGEMENT) {
            const currentStoreState = getState();
            const parcelIdsSelected = lodashGet(currentStoreState, 'harvestData.parcelIdsSelected', []);
            const harvestDico = lodashGet(currentStoreState, 'harvestData.harvestDico', {});
            const parcelVarietiesMapping = lodashGet(currentStoreState, 'harvestData.parcelVarietiesMapping', {});

            let parcelIds = [];

            parcelIdsSelected.forEach(id => {
                const harvest = lodashGet(harvestDico, `${id}`, undefined);
                const varietyAlreadyAsked = lodashGet(parcelVarietiesMapping, `${id}`, undefined);

                // Si pas de gestion de récolte existante sur cette parcelle ET que l’on n’a pas encore lancé de recherche d’une similarité, on va la lancer pour cette parcelle.
                if ((harvest === undefined) && (varietyAlreadyAsked === undefined)) {
                    parcelIds.push(id);
                }
            })

            if (parcelIds.length > 0) {
                dispatch(ActionFetchVarietiesCharacteristics(parcelIds));
            }
        }

        dispatch({
            type: GO_TO_STEP_OF_HARVEST,
            harvestStep: nextStepValue,
        });
    };
}
//#endregion GoToStep

//#region GoToTab
export function ActionGoToTab(nextTabValue) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        
        dispatch({
            type: GO_TO_TAB_OF_HARVEST,
            harvestTab: nextTabValue,
        });
    };
}
//#endregion GoToTab

export function ActionUpdateAllHarvestDatesAtTheBeginning(clientId, harvests) {
    return function (dispatch, getState) {
        const currentStoreState = getState();
        const harvestsDatesUpdatedAtBeginning = lodashGet(currentStoreState, 'harvestData.harvestsDatesUpdatedAtBeginning', false);
        if (harvestsDatesUpdatedAtBeginning === false) {
            // Génération des dates estimées :
            if (harvests) {
                let datasToManage = [];
                const currentDate = new Date(Date.now()).setHours(0, 0, 0, 0);
                Object.values(harvests).forEach(harvest => {
                    let harvestGenrationDate = lodashGet(harvest, 'generationDate', null);

                    if ((harvest.generationDate !== undefined) && (harvestGenrationDate !== null)) {
                        let generationDate = new Date(harvestGenrationDate).setHours(0, 0, 0, 0);
                        if (generationDate < currentDate) {
                            datasToManage.push(harvest);
                        }
                    }
                });

                if (datasToManage.length > 0) {
                    dispatch(ActionGenerateResults(clientId, datasToManage, true));
                }
            }

            //Signaler que la demande de génération de dates de récolte a été demandée au démarrage de la récolte.
            dispatch(ActionHarvestsDateAskedAtTheBeginning());
        }
    }
}

/*export*/ function ActionHarvestsDateAskedAtTheBeginning() {
    return {
        type: HARVESTS_DATES_ASKED_AT_THE_BEGINNING,
    };
}

//#region GetAllHarvests
export function ActionGetAllHarvests(clientId, cropEnum = Crop.Corn, nextStepValue = null) {
    return function (dispatch, getState) {
        const currentStoreState = getState();
        const harvestsAlreadyLoaded = lodashGet(currentStoreState, 'harvestData.harvestsAlreadyLoaded', false);

        if (harvestsAlreadyLoaded === false) {
            // 1- informe que l'on va faire la demande d'obtention des récoltes du client:
            dispatch(ActionLoadingHarvests());

            // 2- récupération des récoltes
            harvestWebApiProvider.loadHarvests(dispatch, getState, clientId, cropEnum)
            .then((harvests) => {
                // Fournis les données des récoltes du client:
                dispatch(ActionInitHarvestDico(harvests));

                // 3- On se dirige à la prochaine étape
                if ((nextStepValue !== null) && (nextStepValue === HarvestStep.MANAGEMENT)) {
                    dispatch(ActionGoToStepOfHarvest(nextStepValue));
                }
            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorLoadHarvests(error));
            });
        }
        else {
            //Les récoltes ont déjà été chargées !
            // On se dirige à la prochaine étape
            if ((nextStepValue !== null) && (nextStepValue === HarvestStep.MANAGEMENT)) {
                dispatch(ActionGoToStepOfHarvest(nextStepValue));
            }
        }
        
    }
}

/*export*/ const ActionLoadingHarvests = () => ({
    //return {
        type: LOADING_HARVESTS,
        //};
    });

/*export*/ function ActionInitHarvestDico(harvestDicoValue) {
    return {
        type: HARVESTS_LOADED,
        harvestDico: harvestDicoValue
    };
}

export function ActionErrorLoadHarvests(errorValue) {
    return {
        type: ERROR_LOAD_HARVESTS,
        errorMessage: errorValue,
    };
}
//#endregion GetAllHarvests

//#region GetAllVarietiesCharacteristics
export function ActionGetAllVarietiesCharacteristics(clientId, cropEnum = Crop.Corn) {
    return function (dispatch, getState) {
        const currentStoreState = getState();
        const varietiesCharacteristicsAlreadyLoaded = lodashGet(currentStoreState, 'harvestData.varietiesCharacteristicsAlreadyLoaded', false);

        if (varietiesCharacteristicsAlreadyLoaded === false) {
            // 1- informe que l'on va faire la demande d'obtention des variétés de culture du client:
            dispatch(ActionLoadingVarietiesCharacteristics());

            // 2- récupération des variétés de culture
            harvestWebApiProvider.loadVarietiesCharacteristics(dispatch, getState, clientId, cropEnum)
            .then((varietiesCharacteristics) => {
                //Fournis les données de variétés de culture du client:
                dispatch(ActionInitVarietiesCharacteristicsDico(varietiesCharacteristics));

            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorLoadVarietiesCharacteristics(error));
            });
        }
        else {
            //Les variétés de culture ont déjà été chargées !
        }
        
    }
}

/*export*/ const ActionLoadingVarietiesCharacteristics = () => ({
    //return {
        type: LOADING_VARIETIES_CHARACTERISTICS,
        //};
    });

/*export*/ function ActionInitVarietiesCharacteristicsDico(harvestVarietyCharacteristicsDicoValue) {
    return {
        type: VARIETIES_CHARACTERISTICS_LOADED,
        harvestVarietyCharacteristicsDico: harvestVarietyCharacteristicsDicoValue
    };
}

export function ActionErrorLoadVarietiesCharacteristics(errorValue) {
    return {
        type: ERROR_LOAD_VARIETIES_CHARACTERISTICS,
        errorMessage: errorValue,
    };
}
//#endregion GetAllVarietiesCharacteristics

//#region Management

export function ActionSelectIdsParcelsToStartManagement(parcelIdsSelectedValue) {
    return {
        type: SELECT_ID_PARCELS_FOR_MANAGEMENT,
        parcelIdsSelected: parcelIdsSelectedValue
    };
}

export function ActionGenerateResults(clientId, datasToManage) {
    return function (dispatch, getState) {

        const currentState = getState();
        let generatingForParcelIds = lodashGet(currentState, "harvestData.generatingForParcelIds", []);
        generatingForParcelIds = generatingForParcelIds.concat(datasToManage.map(item => item.parcelId));

        dispatch(ActionGeneratingHarvestDates(generatingForParcelIds));

        let parcelIdsManaged = [];
        harvestWebApiProvider.generateResult(dispatch, getState, clientId, datasToManage)
            .then((harvests) => {
                dispatch(ActionInitHarvestDates(harvests));
                
                const harvestTab = lodashGet(currentState, "harvestData.harvestTab", HarvestTab.Management);
                if (harvestTab === HarvestTab.Management) {
                    //Pour chaque récolte qui a eu une date estimée, on modifie visuellement la culture de la parcelle,
                    //la variété de la parcelle et la date de semis de la parcelle.
                    Object.values(harvests).forEach((harvest) => {
                        let parcelId = harvest.parcelId;

                        parcelIdsManaged.push(parcelId);

                        //Mise à jour dela culture de la parcelle :
                        let crop = harvest.harvestCrop.label + (harvest.isGrain === true ? " grain" : " ensilage");
                        dispatch(ActionUpdateParcelCrop(crop, parcelId));

                        //Mise à jour de la variété de la parcelle :
                        dispatch(ActionUpdateParcelVariety(harvest.varietyLabel, parcelId));

                        //Mise à jour de la date de semis de la parcelle :
                        dispatch(ActionUpdateParcelSowingDate(harvest.sowingDate, parcelId));
                    });
                }
                
                const parcelIdsSelected = lodashGet(currentState, "harvestData.parcelIdsSelected", []);

                //Redirection vers l'onglet 2 (récapitulatif).
                //L'étape 2 de l'onglet 1 est terminée, alors on est redirigé automatiquement à l'onglet 2 (récapitulatif).
                //Si on décide ensuite de retourner à l'onglet 1, il faudrait se repositionner à l'étape 1 et pas l'étape 2.
                if (parcelIdsSelected.length <= parcelIdsManaged.length) {
                    //Signaler que si on retourne vers l'onglet 1, on se positionne à l'étape 1 :
                    dispatch(ActionGoToStepOfHarvest(HarvestStep.CHOIX_PARCELS));
                    
                    //Comme les dates estimées ont été générées pour toutes les parcelles, 
                    //on se positionne sur l'onglet 2 (récapitulatif) :
                    dispatch(ActionGoToTab(HarvestTab.Summary));
                }

                if (parcelIdsSelected.length > 0) {
                    let newParcelIdsSelected = parcelIdsSelected.filter(x => !parcelIdsManaged.includes(x));
                    //Signaler qu'il y a un changement de parcelles sélectionnées :
                    dispatch(ActionSelectIdsParcelsToStartManagement(newParcelIdsSelected));
                }
            },
                (error) => {
                    //Avertit du mauvais retour:
                    dispatch(ActionErrorGenerateResults(error));
                });
    }
}

export function ActionGeneratingHarvestDates(datasToManage) {
    return {
        type: GENERATING_HARVEST_DATES,
        generatingForParcelIds: datasToManage,
    };
}

export function ActionErrorGenerateResults(errorValue) {
    return {
        type: ERROR_GENERATE_RESULTS,
        errorMessage: errorValue
    };
}

export function ActionInitHarvestDates(harvestsValue) {
    return {
        type: INIT_HARVEST_DATES,
        harvests: harvestsValue
    };
}

//#endregion Management

//#region Save Harvest

/*export*/ function ActionUpdatingHarvestDico() {
    return {
        type: UPDATING_HARVEST_DATAS,
    };
}

/*export*/ function ActionUpdateHarvestDico(harvestsValue) {
    return {
        type: UPDATE_HARVEST_DATAS,
        harvests: harvestsValue
    };
}

export function ActionClearHarvestMessageError() {
    return {
        type: CLEAR_HARVEST_MESSAGE_ERROR,
    };
}

/*export*/ function ActionErrorUpdateHarvestDico(msgError) {
    return {
        type: ERROR_UPDATE_HARVEST_DICO,
        errorMessage: msgError,
    }
}

/**
 * Action permettant de sauvegarder les données de récoltes
 * @param {Harvest[]} harvests 
 * @returns 
 */
export function ActionSaveHarvests(harvests) {
    return function (dispatch, getState) {
        dispatch(ActionUpdatingHarvestDico());

        harvestWebApiProvider.saveHarvests(dispatch, getState, harvests)
            .then((harvestsDico) => {
                dispatch(ActionUpdateHarvestDico(harvestsDico));

                //Redirection vers l'étape 1 :
                dispatch(ActionGoToStepOfHarvest(HarvestStep.CHOIX_PARCELS));
            }, 
            (error) => {
                dispatch(ActionErrorUpdateHarvestDico(error));
            });
    }
}

//#endregion Save Harvest

//#region Suppression des récoltes

//Avertit le début de la suppresion de plusieurs récoltes
/*export*/ const ActionDeletingHarvests = () => ({
    type: DELETING_HARVESTS,
})

//Avertit la fin de la suppression des récoltes
/*export*/ function ActionHarvestsDeleted(parcelsIdsToDelete) {
    return {
        type: DELETE_HARVESTS,
        parcelIdsDeleted: parcelsIdsToDelete,
    };
}

export function ActionDeleteHarvests(harvestsIdsToDelete){
    return function (dispatch, getState) {
        dispatch(ActionDeletingHarvests());

        return harvestWebApiProvider.deleteHervests(dispatch, getState, harvestsIdsToDelete)
        .then((response) => {
            dispatch(ActionHarvestsDeleted(harvestsIdsToDelete));
        },
        (error) => {
            dispatch(ActionHarvestsDeleted(undefined));
        });
    }
}
//#endregion

//#region Mes variétés

//#region Suppression des variétés
//Avertit le début de la suppresion de plusieurs variétés
/*export*/ const ActionDeletingVarieties = () => ({
    type: DELETING_VARIETIES,
})

//Avertit la fin de la suppression des variétés
/*export*/ function ActionVarietiesDeleted(varietyIdsToDelete) {
    return {
        type: DELETE_VARIETIES,
        varietyIdsToDelete: varietyIdsToDelete,
    };
}

/*export*/ function ActionErrorDeleteVarieties(errorMessage) {
    return {
        type: ERROR_DELETE_VARIETIES,
        errorMessage: errorMessage,
    };
}

//Suppression des variétés
export function ActionDeleteVarieties(varietyIdsToDelete) {
    return function (dispatch, getState) {
        dispatch(ActionDeletingVarieties());

        return harvestWebApiProvider.deleteVarieties(dispatch, getState, varietyIdsToDelete)
        .then((response) => {
            dispatch(ActionVarietiesDeleted(varietyIdsToDelete));
        },
        (error) => {
            dispatch(ActionVarietiesDeleted(undefined));
            dispatch(ActionErrorDeleteVarieties(error));
        });
    }
}
//#endregion Suppression des variétés



//#region LoadVarieties

/**
 * Va associer une variété à une parcelle dans l'objet parcelVarietiesMapping
 * @param {Number} parcelId 
 * @param {Number} varietyId 
 * @returns 
 */
export function ActionSetVarietyCharacteristicsByParcel(parcelId, varietyResponse = null) {
    return {
        type: SET_VARIETY_CHARACTERISTICS_BY_PARCEL,
        parcelId: parcelId,
        varietyId: (varietyResponse !== null) ? varietyResponse.id : 0, // 0 correspondant a la parcelle par defaut
    };
}

/**
 * Va aller demander a l'api les caracteristiques des varietes pour chaque parcelle
 * @param {Number[]} parcelIds Tableau des ids des parcelles dont on veut les caracteristiques des varietes
 * @returns 
 */
export function ActionFetchVarietiesCharacteristics(parcelIds) {
    return function (dispatch, getState) {
        const currentStoreState = getState();
        const clientId = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
        const crop = lodashGet(currentStoreState, 'harvestData.currentCrop', Crop.Corn);

        if (parcelIds.length > 0) {
            parcelIds.forEach(parcelId => {
                harvestWebApiProvider.loadVariety(dispatch, getState, clientId, parcelId, crop)
                .then((response) => {
                    dispatch(ActionSetVarietyCharacteristicsByParcel(parcelId, response));
                },
                (error) => {
                    // Tampis, on peut pas simplifier la saisie client
                    // pour autant, on l'avertie pas de l'erreur 
                    // => on a réalisé l'action de notre propre chef et suite à une demande client, donc on l'averti pas d'erreur (on passe ça sous silence)
                    dispatch(ActionSetVarietyCharacteristicsByParcel(parcelId));
                })
                .catch((error) => {
                    // Tampis, on peut pas simplifier la saisie client
                    // pour autant, on l'avertie pas de l'erreur 
                    // => on a réalisé l'action de notre propre chef et suite à une demande client, donc on l'averti pas d'erreur (on passe ça sous silence)
                    dispatch(ActionSetVarietyCharacteristicsByParcel(parcelId));
                });
            })
        }
    }
}

//#endregion


//#region Mise à jour d'une variété

//Avertit du début de la mise à jour de la variété
/*export*/ const ActionUpdatingVariety = () => ({
    type: UPDATING_VARIETY
})

//Avertit la fin de la mise à jour de la variété
/*export*/ function ActionVarietyUpdated(variety) {
    return {
        type: VARIETY_UPDATED,
        variety: variety,
    };
}

export function ActionUpdateVariety(variety) {
    return function (dispatch, getState) {
        dispatch(ActionUpdatingVariety());

        return harvestWebApiProvider.updateVariety(dispatch, getState, variety)
            .then((response) => {
                dispatch(ActionVarietyUpdated(variety));
            },
            (error) => {
                dispatch(ActionVarietyUpdated(undefined));
            });
    }
}

//#endregion Mise à jour d'une variété

//#region Ajout d'une variété
/*export*/ const ActionAddingVariety = () => ({
    type: ADDING_VARIETY
})

export function ActionVarietyAdded(newVariety) {
    return {
        type: VARIETY_ADDED,
        newVariety: newVariety,
    };
}

export function ActionAddVariety(newVariety) {
    return function (dispatch, getState){
        dispatch(ActionAddingVariety());

        return harvestWebApiProvider.addVariety(dispatch, getState, newVariety)
        .then((response) =>{
            dispatch(ActionVarietyAdded(response));
        },
        (error) => {
            dispatch(ActionErrorAddingVariety(error));
        });
    }
}

export function ActionErrorAddingVariety(errorMessage){
    return {
        type: ERROR_ADDING_VARIETY,
        errorMessage: errorMessage,
    }
}
//#endregion

//#region Actions liés à la sélection de la variété ajoutée lors de la sélection de celle-ci
export function ActionGetParcelIdFocused(parcelId){
    return {
        type: GET_PARCEL_ID_FOCUSED,
        parcelId: parcelId
    }
}

export function ActionResetParcelAndVarietyIdsSaved(){
    return{
        type: RESET_PARCEL_ID_AND_VARIETY_SAVED
    }
}
//#endregion
export function ActionOpenPopupVarietyForm(open) {
    return {
        type: OPEN_POPUP_VARIETY_FORM,
        openPopupVarietyForm: open
    }
}
//#endregion Mes variétés

//#endregion Mise à jour d'une variété

//#region Obtension des spécificités du Maïs Grain (pour personnaliser l'objectif de récolte)
/*export*/ const ActionAddingTargetDryingInfos = () => ({
    type: LOADING_CORN_DRY_SPECIFICATIONS
})

export function ActionTargetDryingInfosAdded(targetDryingInfosListValue) {
    return {
        type: CORN_DRY_SPECIFICATIONS_LOADED,
        targetDryingInfosList: targetDryingInfosListValue,
    };
}

export function ActionLoadTargetDryingInfos() {
    return function (dispatch, getState){
        dispatch(ActionAddingTargetDryingInfos());

        return harvestWebApiProvider.loadHarvestTargetInfos(dispatch, getState)
        .then((response) =>{
            dispatch(ActionTargetDryingInfosAdded(response));
        },
        (error) => {
            dispatch(ActionErrorTargetDryingInfos(error));
        });
    }
}

export function ActionErrorTargetDryingInfos(errorMessage){
    return {
        type: ERROR_LOAD_CORN_DRY_SPECIFICATIONS,
        errorMessage: errorMessage,
    }
}
//#endregion
