import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';

import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import CardHeader from '@mui/material/CardHeader';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Grid, CardContent, Divider, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { ParcelsHelper } from '../../../utils/parcelsHelper.js';
import numberHelper from '../../../utils/numberHelper.js';

import DateHelper from '../../../utils/dateHelper.js';

import {BiomassStep, ActionGoToShowThisBiomass, ActionClearBiomassValue, ActionGenerateFileOfThisBiomass, ActionGetAllWeightingBiomasses, ActionGoToNextStepOfBiomass, ActionClearNewBiomassCounter } from '../../../redux/actions/biomass';

import {ActionGoToBiomass} from '../../../redux/actions/contextApp.js';

import lodashGet from 'lodash/get';

/* Theme Berry */
import { styled } from '@mui/material/styles';
import getTheme from "../../../themes/index.js";
import SubCard from "../../subLayouts/subCard";
const theme = getTheme();

const IconWrapper = styled('div')({
    transform: 'rotate(25deg)',
    '&> svg': {
        color: theme.palette.common.white,
        [theme.breakpoints.only('xs')]: {
            marginTop: '15%'
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: '17%'
        },
        width: '79px',
        height: '79px', 
        opacity: '0.35',
        position: "absolute",
        marginTop: '56px',
        marginLeft: '-16px'
    }
});

//////////////////////////////////////////////////////////////////////////////////////////
// fonction de rendu visuel pour la fonctionnalité widget d'une pesée de colza de parcelle 
//////////////////////////////////////////////////////////////////////////////////////////
function WidgetBiomass(props) {
    const { clientId, getAllBiomasses } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    //Au clic d'une parcelle, on charge TOUTES les pesées de colza du client
    useEffect(() => {
        if (getAllBiomasses && (clientId > 0)) {
            getAllBiomasses(clientId);
        }
    }, [clientId, getAllBiomasses]);

    //Au clic du menu de droite
    const handleClick = (event) => {
        event.persist();
        setAnchorEl(event.currentTarget);
    };

    //Fermeture du menu de droite
    const handleClose = () => {
        setAnchorEl(null);
    };

    //Redirection -> affichage biomasse
    const handleShowBiomass = (biomass) => {
        setAnchorEl(null);
        const { showBiomass, clearBiomassValue } = props;

        //si une pesée de colza a déjà été sélectionnée auparavant, vider les valeurs :
        if (clearBiomassValue) { 
            clearBiomassValue();
        }

        if (showBiomass) { 
            showBiomass(biomass);
        }
    };

    //Génération générale de pdf pour les pesées de colza (E/S)
    const generatePdf = (biomasses) => {
        setAnchorEl(null);
        const { generateBiomassFile } = props;

        if (generateBiomassFile) { 
            generateBiomassFile(biomasses[0]);
        }
    };

    const handleSelectBiomassPageClick = (event, data) => {
        const {clearNewBiomassCounter, goToBiomass, goToNextStepOfBiomass } = props;
        clearNewBiomassCounter();
        if (window.dataLayer) // google tag manager
            window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'biomassPage' });

        let readableMode = false;
        let newParcelIdsSelected = [data];
        
        if (goToBiomass) {
            goToNextStepOfBiomass(BiomassStep.MAP, newParcelIdsSelected, readableMode);
            goToBiomass(true,newParcelIdsSelected, false );
        }
    };

    const getContent = () => {
        const { parcelDico, parcelIdSelected, biomassDico } = props;

        if (parcelIdSelected <= 0) return;

        let parcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelIdSelected);
        if (!parcel) return;

        let biomasses = undefined;

        //Récupérer toutes les pesées de colza correspondant à la parcelle
        if (biomassDico !== undefined) {
            for (const key in biomassDico) {
                const biomItem = biomassDico[key];
                if (biomItem && biomItem.parameter && (biomItem.parameter.parcelId === parcelIdSelected)) {
                    if (biomasses === undefined) {
                        biomasses = [];
                    }
                    biomasses.push(biomItem);
                }
            }
        }

        //Affichage de(s) pesée(s) de colza correspondant à la parcelle sélectionnée
        if (biomasses !== undefined) {
            return (
                <>
                    <CardHeader
                        action={
                            <IconButton aria-label="settings" onClick={(event) => handleClick(event)}>
                                <MoreHorizIcon className="widget-color" />
                            </IconButton>
                        }
                        title={StringTranslate.mybiomasses}
                    />
                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                        keepMounted
                    >
                        <MenuItem key={2} onClick={() => generatePdf(biomasses)}>
                            <FileDownloadIcon color="action" /><Typography variant="subtitle2">{StringTranslate.downloadWeightingPdf}</Typography>
                        </MenuItem>
                    </Menu>

                    < Divider />

                    <CardContent>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <TableContainer className="widget-table-container">
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow key="rowHead" className="widget-color">
                                                {/* ↓ cellules concernant le nom de la parcelle ↓ */}
                                                <TableCell><Typography fontWeight='bold'>{StringTranslate.ColumnBioBeginningWinter + " / " + StringTranslate.ColumnBioEndingWinter}</Typography></TableCell>
                                                <TableCell><Typography fontWeight='bold'>{StringTranslate.ColumnBioDateWinter}</Typography></TableCell>
                                                <TableCell><Typography fontWeight='bold'>{StringTranslate.ColumnBioWeighting}</Typography></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {biomasses.map(biomass => (
                                                <TableRow key={`row[${biomass.id}]`} onClick={() => handleShowBiomass(biomass)} className="widget-color" hover>
                                                    <TableCell>{(biomass.parameter.isBeginningWinter === true) ? StringTranslate.ColumnBioBeginningWinter.substring(0, 1) : StringTranslate.ColumnBioEndingWinter.substring(0, 1)}</TableCell>
                                                    <TableCell>{DateHelper.formati18n(new Date(biomass.parameter.dateImagSource), 'P')}</TableCell>
                                                    <TableCell>{numberHelper.fixeDecimal(biomass.averageQuantity)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </CardContent>
                </>
            );
        }
        //Chargement de(s) pesée(s) de colza en cours avant affichage
        else if (biomassDico === undefined) {
            return (
                <CardContent>
                    <Grid container spacing={0}>
                        <Grid item xs={10}>
                            <Typography variant="body1" gutterBottom style={{ textAlign: "center" }}>{StringTranslate.loadingBiomassRecords}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <CircularProgress size={20} />
                        </Grid>
                    </Grid>
                </CardContent>
            );
        }
        //Aucune pesée de colza à afficher
        else {
            return (
                <CardContent sx={{ backgroundColor: theme.palette.grey[300] }}>
                    <IconWrapper><InfoOutlinedIcon /></IconWrapper>
                    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>

                        <Grid item sm={12}>
                            <Button
                                aria-label="show-infos"
                                color="primary"
                                variant="contained"
                                onClick={(event) => {handleSelectBiomassPageClick(event, parcelIdSelected)}}
                            >
                                {StringTranslate.newBiomassWidget}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            );
        }
       
    };

    return (
        
        <SubCard sx={{ zIndex: "1100" }} title={false} content={false}>
            {getContent()}
        </SubCard>
    );
};

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = (state) => {
    return {
        parcelIdSelected: lodashGet(state, 'contextAppData.parcelIdSelected', -1),
        parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
        biomassDicoLoading: lodashGet(state, 'biomassData.loading', false),
        biomassDico: lodashGet(state, 'biomassData.biomassDico', {}),
        biomassDicoCounter: lodashGet(state, 'biomassData.biomassDicoCounter', 0),
        clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1),
        settings: lodashGet(state, 'settingsData.settings', {}), // seul moyen trouvé pour rafraichir le visuel (traduction) du fait que maintenant on rentre dans l'application sans attendre les informations du client       
    };
}

const mapDispatchToProps = dispatch => ({
    showBiomass: (biomassSelectedItem) => dispatch( ActionGoToShowThisBiomass(biomassSelectedItem, true) ),
    clearBiomassValue: () => dispatch( ActionClearBiomassValue() ),
    generateBiomassFile: (biomass) => dispatch( ActionGenerateFileOfThisBiomass(biomass) ),
    getAllBiomasses: (clientId) => dispatch( ActionGetAllWeightingBiomasses(clientId) ),
    goToNextStepOfBiomass: (nextStepOfBiomass, parcelIdsSelected, readableMode, biomassSelectedItemValue = undefined) =>
        dispatch(ActionGoToNextStepOfBiomass(nextStepOfBiomass, parcelIdsSelected, readableMode, biomassSelectedItemValue)),
    clearNewBiomassCounter: () => dispatch(ActionClearNewBiomassCounter()),
    goToBiomass: () => dispatch(ActionGoToBiomass(true)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WidgetBiomass);