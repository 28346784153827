import React from "react";
import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';
import { ConstantsTutorialHelps } from '../../../utils/constantsTutorialHelps';
import CustomIFrame from '../../customIFrame.jsx';

/**
 * Composant affichage de la partie Geofolia - didacticiel firstparcel
 */
function StepGeofolia(props) {

    let constUrlLangage = ConstantsTutorialHelps.SubUrlLangageFrFr;

    constUrlLangage = StringTranslate.getLanguage() === 'es-ES' ?
    ConstantsTutorialHelps.SubUrlLangageEsEs :
            (StringTranslate.getLanguage() === 'en-GB' ? 
            ConstantsTutorialHelps.SubUrlLangageEnGb : ConstantsTutorialHelps.SubUrlLangageFrFr);

    const urlGeofolia = ConstantsTutorialHelps.UrlSpotifarmBase + constUrlLangage + ConstantsTutorialHelps.SubUrlHelpExportGeofolia;

    /* callback onLoad iframe */
    // const onLoad = useCallback(() => {
    //     // var iframe = document.getElementById('iframe-step-geofolia');
    //     // on peut ensuite accéder aux éléments à l'intérieur de l'iframe
    // }, []);

    return (
        <div className="dfp-geofolia-step">
            <CustomIFrame id="iframe-step-geofolia" height={window.innerHeight > 640 && window.innerHeight <= 653 ? "670px" : "600px"} url={urlGeofolia} />
        </div>
    );
}

export default StepGeofolia;