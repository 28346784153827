///////////////////////////////////////////////////////////////////////////
// Import des éléments utilisés par le composant <ControlsMapsBiomass>
///////////////////////////////////////////////////////////////////////////
import React from "react";
import { connect } from 'react-redux';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ImageSwiperBiomass from './imageSwiperBiomass';
import '../../../assets/css/swipeablePanel.css';


///////////////////////////////////////////////////////////////////////////
// Définition des constantes
///////////////////////////////////////////////////////////////////////////
export const LayersData = ['routiere', 'satellite', 'sentinel_satellite', 'sentinel_NDVI'];
export const Views = ['all','single'];


///////////////////////////////////////////////////////////////////////////
// Composant définissant les options d'affichage sur le rendu de la notre carte.
///////////////////////////////////////////////////////////////////////////
class ControlsMapsBiomass extends React.Component {    

    ///////////////////////////////////////////////////////////////////////////
    // fonction de rendu visuel pour la fonctionnalité swiper
    ///////////////////////////////////////////////////////////////////////////
    getContentSwiper() {
        const { parcelIdSelected } = this.props;

        return (parcelIdSelected > 0) ? (
            <Card 
                className="leaflet-bottom leaflet-left leaflet-PosNone swiper-images-controlMaps">
                <CardContent className="widget-infos-content">
                    <ImageSwiperBiomass />
                </CardContent>
            </Card>
        ) : null;
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction de rendu visuel - layout général
    ///////////////////////////////////////////////////////////////////////////
    render() {
        return (
            /* ↓↓ Partie - Contenu ↓↓ */ 
            this.getContentSwiper()                      
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = function (state) {
    return {
        //Infos provenant du reducer 'contextApp':
        globalLayerOnLoading: (state && state.contextAppData) ? state.contextAppData.globalLayerOnLoading : false,
        parcelIdSelected: (state && state.contextAppData) ? state.contextAppData.parcelIdSelected : -1,
    };
}

export default connect(mapStateToProps, null)(ControlsMapsBiomass);