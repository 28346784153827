import {
    ActionTypeDidacticiel, StepEnumDidacticielModelisation
} from '../actions/didacticiel.js';

const initialState = {

    // didacticiel première parcelle
    firstparcel: {
        isOpened: false, // donnée permettant de savoir si le didacticiel est en cours de visualisation (n'importe quelle étape)
        currentStep: undefined, // donnée permettant de savoir sur quelle étape l'utilisateur se trouve sur le didacticiel 
        counterCompleted: 0, // donnée permettant de savoir combien de fois l'utilisateur a utilisé et est allé au bout du didacticiel
    },

    // didacticiel modelisation
    modelisation: {
        currentStep: StepEnumDidacticielModelisation.START, // donnée permettant de savoir sur quelle étape l'utilisateur se trouve sur le didacticiel 
        counterCompleted: 0, // donnée permettant de savoir combien de fois l'utilisateur a utilisé et est allé au bout du didacticiel
    },
}

function didacticielManagerAction(state = initialState, action) {

    switch (action.type) {

        case ActionTypeDidacticiel.SET_DIDACTICIEL_FIRSTPARCEL_ISOPENED:
            return {
                ...state,
                firstparcel: {
                    ...state.firstparcel,
                    isOpened: action.isOpened
                }
            }

        case ActionTypeDidacticiel.GO_TO_STEP_FOR_DIDACTICIEL_FIRSTPARCEL:
            return {
                ...state,
                firstparcel: {
                    ...state.firstparcel,
                    currentStep: action.stepToGo,
                    isOpened: true
                }
            }

        case ActionTypeDidacticiel.ADD_COUNTER_COMPLETED_DIDACTICIEL_FIRSTPARCEL:
            return {
                ...state,
                firstparcel: {
                    ...state.firstparcel,
                    counterCompleted: state.firstparcel.counterCompleted + 1,
                }
            }

        case ActionTypeDidacticiel.SET_DIDACTICIEL_FIRSTPARCEL_DATAS:
            return {
                ...state,
                firstparcel: {
                    ...state.firstparcel,
                    currentStep: action.currentStep,
                    counterCompleted: action.counterCompleted,
                }
            }

        
        case ActionTypeDidacticiel.SET_DIDACTICIEL_MODELISATION_DATAS:
            return {
                ...state,
                modelisation: {
                    ...state.modelisation,
                    currentStep: action.currentStep,
                    counterCompleted: action.counterCompleted,
                }
            }

        case ActionTypeDidacticiel.GO_TO_STEP_FOR_DIDACTICIEL_MODELISATION:
            return {
                ...state,
                modelisation: {
                    ...state.modelisation,
                    currentStep: action.stepToGo,
                }
            }

        case ActionTypeDidacticiel.ADD_COUNTER_COMPLETED_DIDACTICIEL_MODELISATION:
            return {
                ...state,
                modelisation: {
                    ...state.modelisation,
                    counterCompleted: state.modelisation.counterCompleted + 1,
                }
            }

        default:
            return state;
    }
}

export default didacticielManagerAction;