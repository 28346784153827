import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import getTheme from '../../../themes/index';

let theme = getTheme();


function IconeObservationMaladie(props: SvgIconProps) {
  return ( 
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" strokeWidth="1.8" stroke="none" fill="current">
        <g>
          <path d="M7,18.56c-.23-.35-.9-1.37-1-1.67a11.06,11.06,0,0,1-1.24-5.15A11.27,11.27,0,1,1,26,16.89c-.16.3-.82,1.32-1.07,1.69L16,31.17Z" fill={theme.palette.primary.main}/>
          <path d="M16,1A10.78,10.78,0,0,1,26.76,11.74a10.6,10.6,0,0,1-1.19,4.92c-.12.24-.68,1.1-1,1.61L16,30.3l-8.54-12c-.36-.54-.92-1.4-1-1.64A10.78,10.78,0,0,1,16,1m0-1A11.78,11.78,0,0,0,5.52,17.12c.21.39,1.1,1.73,1.1,1.73L16,32l9.38-13.18s.89-1.34,1.09-1.73A11.76,11.76,0,0,0,16,0Z" fill={theme.palette.primary.main}/>
        </g>
        <path d="M22.05,10.41l-1.66-.48-.32-.08A2.72,2.72,0,0,0,20,9.34l.33-.26c.33-.27.33-.28-.1-.24l-.37,0a3.35,3.35,0,0,0-.21-.47c0-.06.1-.15.2-.33l.83-1.52c.2-.38.19-.38-.19-.18L19,7.22l-.18.1A3.1,3.1,0,0,0,18.34,7l.14-.76c.08-.43.07-.43-.23-.12l-.51.55a3.78,3.78,0,0,0-.9-.28l-.05-.2c-.12-.43-.36-1.25-.49-1.67s-.13-.41-.26,0l-.47,1.67-.05.2a3.49,3.49,0,0,0-.84.23l-.37-.42c-.29-.32-.3-.32-.23.11l.08.56a4.49,4.49,0,0,0-.52.37l-.25-.16-1.52-.83c-.38-.2-.39-.19-.18.19l.83,1.5a1.51,1.51,0,0,0,.23.35,3.07,3.07,0,0,0-.17.31l-.33,0c-.43,0-.44,0-.11.24l.28.22a3.65,3.65,0,0,0-.19.87L12,9.93l-1.66.48c-.41.13-.41.14,0,.26l1.66.48.36.08a3.68,3.68,0,0,0,.15.47l-.63.82c-.17.23-.17.24.1.14l.86-.31a2.52,2.52,0,0,0,.24.33l-.06.11c-.22.38-.63,1.14-.83,1.52s-.2.38.18.18l1.51-.84.16-.09a3.82,3.82,0,0,0,1.56.58c.13.45.34,1.19.46,1.57s.14.41.26,0,.32-1.13.45-1.57a3.81,3.81,0,0,0,1.57-.6l.15.09,1.51.84c.38.2.39.2.18-.19s-.61-1.13-.83-1.51l-.06-.11.25-.36.86.32c.28.09.28.09.11-.14l-.65-.84a3.24,3.24,0,0,0,.13-.41,1.25,1.25,0,0,0,.42-.08l1.66-.48C22.47,10.55,22.47,10.54,22.05,10.41Zm-8.23.85a.39.39,0,0,1,0-.77.39.39,0,1,1,0,.77Zm1-2.4a.58.58,0,1,1,.58-.58A.58.58,0,0,1,14.83,8.86Zm.73,1.24a.78.78,0,1,1,.78.78A.78.78,0,0,1,15.56,10.1ZM16.92,13a.58.58,0,1,1,.58-.58A.58.58,0,0,1,16.92,13Zm1.2-4.14a.39.39,0,1,1,.39-.39A.4.4,0,0,1,18.12,8.86Z" fill={theme.palette.common.white} fillRule="evenodd"/>
      </svg>
    </SvgIcon>
  );
}

export default IconeObservationMaladie;