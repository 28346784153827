import format from 'date-fns/format';

function shortenedDateFormat(dateValue, language) {

    let dateFormated = undefined;
    
    // fonction pour déterminer la longueur de l'abréviation du mois
    let month = dateValue.getMonth() + 1;

    dateFormated = format(dateValue, "dd MMMM", { locale: language } /* StringTranslate.formatDate */);

    let monthLength = 0;

    switch(language.code) {
        case 'fr':
            {
                switch (month) {
                    case 1:
                    case 2:
                    case 3:
                    case 6:
                    case 8:
                    case 9:
                        monthLength = 4;
                        break;
                    case 7:
                        monthLength = 5;
                        break;
                    default: 
                        monthLength = 3;
                        break;
                }
                break;
            }
        case 'en-US': //TODO: Est-ce normal de tester ce format complet et non le en-GB ?
            {
                switch (month) {
                    case 6:
                    case 7:
                    case 9:
                        monthLength = 4;
                        break;
                    default: 
                        monthLength = 3;
                        break;
                } 
                break;
            }
        case 'es':
            {
                switch (month) {
                    case 1:
                        monthLength = 5;
                        break;
                    case 5:
                        monthLength = 4;
                        break;
                    case 8:
                        monthLength = 6;
                        break;
                    case 9:
                        monthLength = 4;
                        break;
                    default: 
                        monthLength = 3;
                        break;
                }
                break;
            }
        default: 
            monthLength = 3;
            break;
    }

    let lengthDateFormated = 3 + monthLength; // le 3 est pour la date et on y ajoute la longueur du mois
    dateFormated = dateFormated.substring(0, lengthDateFormated);

    return dateFormated;
    
}

export default shortenedDateFormat;