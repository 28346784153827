// fichier permettant de faire de la manipulation en rapprt avec des chaines de caractères
const stringHelper = {

    escapeRegExp(value) {
        return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

}

export default stringHelper;
