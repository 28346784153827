import React from 'react';

import {
    Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle
} from '@mui/material';

import '../assets/css/confirmDialog.css';


function ConfirmDialog({ openConfirmDialog, handleConfirmClose, deleteParcels, subscribe, displaySubscribeBtn, title, contentText, buttonSupprimer, buttonSAbonner, buttonAnnuler }) {

    return (
        <Dialog
            open={openConfirmDialog}
            onClose={handleConfirmClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">

            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {contentText}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="text"
                    onClick={handleConfirmClose}
                    color="error"
                    autoFocus>
                    {buttonAnnuler}
                </Button>

                <Button variant="contained" onClick={deleteParcels} color="primary">
                    {buttonSupprimer}
                </Button>

                { displaySubscribeBtn === true ?
                    <Button
                        variant="contained"
                        onClick={subscribe}
                        color="secondary"
                        autoFocus>
                        {buttonSAbonner}
                    </Button>
                    : null }
            </DialogActions>
            
        </Dialog>
    );

}

export default ConfirmDialog;