//code pays pour les imports de parcellaire
const countryCode = {
    FR: "FR",
    BE: "BE",
    ES: "ES",
};

export default countryCode;

export function getListCountryCode() {
    let list = [];
    for (const key in countryCode) {
        list.push(countryCode[key]);  
    }
    return list;
};