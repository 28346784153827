import React from "react";
import { connect } from 'react-redux';

import lodashGet from 'lodash/get';

import ImageSwiper from "./map/imageSwiper";
import MapInfo from '../mapInfo';
import LoadingContent from '../loadingContent';
import Maps from "./map/maps";
import LinkToContactUs from "../linkToContactUs";
import AlertDialog from "../alertDialog";

import { Box, Typography } from "@mui/material";

import { ErrorOutline } from "@mui/icons-material";

import { ActionClearLastNitrogenInputError } from "../../redux/actions/lastNitrogenInput";

import StringTranslate from "../../assets/i18n/stringLanguage";

import '../../assets/css/rm-render_maps.css';

/**
 * Composant global, affichant l'étape de choix de la date de clichet d'une parcelle pour lancer une modulation
 * Elle contient la carte et tous les composants s'affichant par-dessus.
 */
class LastNitrogenInputMap extends React.Component {
    constructor(props){
        super (props);

		const errorMessage = lodashGet(props, 'errorMessage', undefined);

        this.state = {
			openDialogOfErrors: (errorMessage !== undefined),
        };

		// Définition du popup contenant le message d'erreur à diffuser au client:
		this.popupErrorDialog = {
			getTitle: () => { return (<ErrorOutline />); },
			description: errorMessage, //Rq : sera mis à jour en fonction de la prop 'errorMessage' !
			getAdditionalDescription: () => {
				return (
					<Typography variant="subtitle1">
						{StringTranslate.errorDialogText2} <LinkToContactUs displayText={StringTranslate.contactUs} />. 
					</Typography>
				);
			},
			button: StringTranslate.close,
		};
		this.closeDialogOfErrors = this.closeDialogOfErrors.bind(this);
    };
    
    componentDidUpdate(prevProps) {
		const { errorMessage, } = this.props;
	 
		//gestion de cas d'erreur:
		if (((!prevProps) || (!prevProps.errorMessage) || (prevProps.errorMessage === '') || (prevProps.errorMessage === undefined)) &&
		(errorMessage && (errorMessage !== '') && (errorMessage !== undefined))) {
			this.popupErrorDialog.description = errorMessage;

			this.setState({
				openDialogOfErrors: true,
			});
		}
	}

    /* Ferme le dialog des messages d'erreur */
	closeDialogOfErrors = () => {
		const { clearLastNitrogenInputError } = this.props;

		this.setState({
			openDialogOfErrors: false,
		});

		clearLastNitrogenInputError();
	}

    render() {
        const { globalLayerOnLoading } = this.props;
        const { openDialogOfErrors } = this.state;

        return (
            /* contentMapModulation */
            <Box 
                sx={{
                    height:"100%",
                    width:"100%",
                    display:"flex",
                    flexDirection:"column"
                }}
            >

                {/* Partie haute - partie rendu cartographique */}
               <Box 
                    sx={{
                        position:"relative",
                        display:"flex",
                        flex:"1",
                        width:"100%",
                        height:"100%"
                    }} 
                > 

                    {/* ↓↓ composant d'affichage d'informations (loading, progress bar, ...) - position en rapport avec la carte (relative-absolute) ↓↓ */}
                    <MapInfo />

                    {/* ↓↓ composant carte ↓↓ */}
                    <Maps />

                </Box>

                {/* ↓↓ Partie - CHARGEMENT EN COURS (pas de planSouscription ou bien pas encore d'informations client) - (contenu: SPINNER + fond grisé) ↓↓ */}
                {(globalLayerOnLoading === true) && <LoadingContent />}

                {/* ↓↓ Partie rétractable - zone en bas de la carte permettant l'affichage de fonctionnalités (swiper, infos, sélection fonds de carte) ↓↓ */}
                <ImageSwiper />

                {/* ↓↓ Partie visuel - affichage Pop d'une erreur ↓↓ */}
                {(openDialogOfErrors === true) && (
                    <AlertDialog popup={this.popupErrorDialog} handleChangePopup={this.closeDialogOfErrors} />
                )}
            </Box>
        );
    }
}

const mapStateToProps = state => ({
    //Infos provenant du reducer 'contextApp':
    globalLayerOnLoading: lodashGet(state, 'contextAppData.globalLayerOnLoading', false),

    //Infos provenant du reducer 'lastNitrogenInputData':
	errorMessage: lodashGet(state, 'lastNitrogenInputData.errorMessage', undefined),
});
const mapDispatchToProps = dispatch => ({

	clearLastNitrogenInputError: () => dispatch(ActionClearLastNitrogenInputError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LastNitrogenInputMap);