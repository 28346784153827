import React from 'react';

import { Button, Typography, Grid, Box, Stack } from '@mui/material';
import MainCard from '../subLayouts/mainCard';
import lodashIsEmpty from 'lodash/isEmpty'; //pour savoir si un objet a une valeur différente de undefined, null, ou [] (pour une liste)

import LogoSpotifarm from '../../assets/logoSpotifarm/FeuilleSpotifarmLogoCouleur';

import '../../assets/css/planCard.css';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import StripeCoupon from './stripeCoupon.jsx';
import lodashGet from 'lodash/get';
import { IsNativeHoster } from '../../utils/platformHelper';

import getTheme from "../../themes/index.js";


let theme = getTheme();

/* fonction permettant de renvoyer un contenu en relation avec le prix/currency/interval d'un plan */ 
function makePeriodPriceText(planDatas) {
    if ((!planDatas) || (!planDatas.interval)) return '';

    let formatStr = '';
    const perOne = ((!planDatas.intervalCount) || (planDatas.intervalCount === 0) || (planDatas.intervalCount < 2));
    if ((planDatas.interval === "IntervalLabel_Day") || (planDatas.interval === "day") || (planDatas.interval === "jour") || 
        (planDatas.interval === "Day") || (planDatas.interval === "Jour"))
    {
        if (perOne) {
            formatStr =  `${StringTranslate.jour}`;
        }
        else { 
            formatStr = `${StringTranslate.jourS}`;
        }
    }
    else if ((planDatas.interval === "IntervalLabel_Week") || (planDatas.interval === "week") || (planDatas.interval === "semaine") || 
        (planDatas.interval === "Week") || (planDatas.interval === "Semaine"))
    {
        if (perOne) {
            formatStr =  `${StringTranslate.semaine}`;
        }
        else { 
            formatStr = `${StringTranslate.semaineS}`;
        }
    }
    else if ((planDatas.interval === "IntervalLabel_Month") || (planDatas.interval === "month") || (planDatas.interval === "mois") || 
        (planDatas.interval === "Month") || (planDatas.interval === "Mois"))
    {
        if (perOne) {
            formatStr =  `${StringTranslate.mois}`;
        }
        else { 
            formatStr = `${StringTranslate.moisS}`;
        }
    }
    else if ((planDatas.interval === "IntervalLabel_Year") || (planDatas.interval === "year") || (planDatas.interval === "annee") || (planDatas.interval === "année") || 
        (planDatas.interval === "Year") || (planDatas.interval === "Annee") || (planDatas.interval === "Année"))
    {
        if (perOne) {
            formatStr =  `${StringTranslate.annee}`;
        }
        else { 
            formatStr = `${StringTranslate.anneeS}`;
        }
    }
    
    if (perOne || (!formatStr) || (formatStr === '')) {
        return formatStr;
    }
    else { 
        return formatStr.replace('X', planDatas.intervalCount.toString());
    }
}

/* fonction permettant de renvoyer un contenu en relation avec la description d'un plan */ 
function getContentDescribePlan(describeValue) {
    if (!describeValue) {
        return (<></>);
    } else {
        const splitText = describeValue.split("\n");
        if (!splitText) {
            return (
                <Typography component="li" variant="body2" align="left" key="0">{describeValue}</Typography>
            );
        } else {
            return (
                splitText.map((value, index) => 
                    ((value === '+') || (value === '-')) ? 
                        <Typography variant="body2" align="left" key={index}>{value}</Typography> : 
                        <Typography variant="body2" align="left" key={index}>{value}</Typography>)
            );
        }
    }
}

/**
 * Composant d'affichage global d'un plan (⚠️ - suivant la donnée planDatas.souscription => on renvoie un composant BasicSubscribedPlanCard || BasicPlanCard)
 */
class PlanCard extends React.Component {    
    constructor(props) {
        super(props);

        this.state = {
            discountCode: (props.discountCode) ? props.discountCode : null, // si code déjà renseigné => appel automatique à la web API
        }

        this.onSelect = this.onSelect.bind(this);
        this.findSizeFromLength = this.findSizeFromLength.bind(this);
    }

    /* fonction permettant de sélectionner un plan */
    onSelect() {
        if (window.dataLayer) // google tag manager
            window.dataLayer.push({'event': 'suiviBouton', 'action': (this.props.planDatas && this.props.planDatas.name) ? this.props.planDatas.name : null});

        //'planDatas' prop introduite par le 'map' dans 'stripePlanView.jsx', et vaut "planSubscriptionDico[${this.props.planDatas.id}]"
        let discountPlanId = lodashGet(this.props, `planDatas.discountPlanDatas.idDiscount`, null); // a-t-on un coupon de réduction valide ?
        this.props.selectPlan(this.props.planDatas.id, discountPlanId); // REDUX: sélection du plan dans subscriptionProcess (donnée représentant la soucription en cours)
        this.props.handleNextStep(); // passage à l'étape suivante
    }

    findSizeFromLength = (length) => {
        let gridMdSize = 3;
        switch (length) {
            case "1":
                gridMdSize=12;
                break;
            case "2":
                gridMdSize=6;
                break;
            case "3":
                gridMdSize=4;
                break;

            default: //'gridMdSize' reste à 3 !
                break;
        }

        return gridMdSize;
    }

    render() {
        const { planDatas, length } = this.props;

        //Cas Stripe où cas de lecture des infos client de facturation 'ClientBilling' en disposant d'un achat sur les Stores Mobile
        const subscribedByStripe = (planDatas && planDatas.subscription && planDatas.subscription.id) ? true : false;
        //Cas d'un achat sur les Stores Mobile, pour la présentation des plans pour lesquels le client peut lancer une souscription (sauf sur celui acheté)
        const purchaseOnStore = (planDatas && (planDatas.storePlanBuying === true)) ? true : false;
        const subscribedResume = (subscribedByStripe === true) || (purchaseOnStore === true);

        let gridMdSize = this.findSizeFromLength(length);
        
        return(
            <Grid item xs={12} md={6} lg={gridMdSize}>
                <MainCard
                    boxShadow
                    sx={{
                        pt: 1.75,
                        border: (subscribedResume === true) ? '2px solid' : '1px solid',
                        borderColor: (subscribedResume === true) ? theme.palette.secondary.main : 'rgba(133, 145, 161, 0.46)',
                        bgcolor: (subscribedResume === true) ? theme.palette.secondary.light : null
                    }}
                >
                    <Grid container textAlign="center" spacing={2}>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '50%',
                                    width: 80,
                                    height: 80,
                                    background: theme.palette.primary.light,
                                    color: theme.palette.primary.main,
                                    '& > svg': {
                                        width: 35,
                                        height: 35
                                    }
                                }}
                            >
                                <LogoSpotifarm/>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                sx={{
                                    fontSize: '1.5625rem',
                                    fontWeight: 500,
                                    position: 'relative',
                                    mb: 1.875,
                                    '&:after': {
                                        content: '""',
                                        position: 'absolute',
                                        bottom: -15,
                                        left: 'calc(50% - 25px)',
                                        width: 50,
                                        height: 4,
                                        background: theme.palette.primary.main,
                                        borderRadius: '3px'
                                    }
                                }}
                            >
                                {(purchaseOnStore) ? `${planDatas.name} (${StringTranslate.buyByStore})`: planDatas.name}
                            </Typography>
                        </Grid>
                        {!lodashIsEmpty(planDatas.description) && planDatas.description !== "" &&
                            <Grid item xs={12}>
                                <Typography variant="body2">{planDatas.description}</Typography>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            {/* ↓↓ Partie - DESCRIPTION D'UN PLAN ↓↓ */}
                            {(planDatas.metadata) && Object.entries(planDatas.metadata).map(function ([key, value]) {
                                    return (key === 'description') ? getContentDescribePlan(value) : null;
                                })}
                        </Grid>
                        {(!planDatas.discountPlanDatas) ?
                            <Grid item xs={12}>
                                <Typography
                                    component="div"
                                    variant="body2"
                                    sx={{
                                        fontSize: '2.1875rem',
                                        fontWeight: 700,
                                        '& > span': {
                                            fontSize: '1.25rem',
                                            fontWeight: 500
                                        }
                                    }}
                                >
                                    <sup>{(planDatas.currency === 'eur') ? '€' : '$'}</sup>
                                    {planDatas.amount}
                                    <span>{makePeriodPriceText(planDatas)}</span>
                                </Typography>
                            </Grid>
                        :                         
                            <>
                                <Grid item xs={12}>
                                    <Stack direction="row" justifyContent="center">
                                        <Typography
                                            component="div"
                                            variant="body2"
                                            sx={{
                                                fontSize: '2.1875rem',
                                                fontWeight: 700,
                                                '& > span': {
                                                    fontSize: '1.25rem',
                                                    fontWeight: 500
                                                }
                                            }}
                                        >                                            
                                        <sup>{(planDatas.currency === 'eur') ? '€' : '$'}</sup>
                                        </Typography>
                                        <Typography
                                            component="div"
                                            variant="body2"
                                            color="secondary"
                                            sx={{
                                                fontSize: '2.1875rem',
                                                fontWeight: 700,
                                                textDecoration: "line-through",
                                                marginRight:1,
                                                '& > span': {
                                                    fontSize: '1.25rem',
                                                    fontWeight: 500
                                                }
                                            }}
                                        >
                                            {planDatas.amount}
                                        </Typography>
                                        <Typography
                                            component="div"
                                            variant="body2"
                                            sx={{
                                                fontSize: '2.1875rem',
                                                fontWeight: 700,
                                                '& > span': {
                                                    fontSize: '1.25rem',
                                                    fontWeight: 500
                                                }
                                            }}
                                        >
                                            {planDatas.discountPlanDatas.amountDiscount}
                                            <span>{makePeriodPriceText(planDatas)}</span>
                                        </Typography>
                                    </Stack>
                                </Grid>
                                {(planDatas.discountPlanDatas.isForever) ?
                                    <></>
                                :
                                    <Grid item xs={12}> puis {(planDatas.currency === 'eur') ? '€' : '$'} {planDatas.amount} {makePeriodPriceText(planDatas)} </Grid>
                                }
                            </>
                        }
                        {!planDatas.discountPlanDatas ? <></> :                         
                            <Grid item xs={12}>
                                <Typography variant="body2">{planDatas.discountPlanDatas.displayDiscount}</Typography>
                            </Grid>
                        }
                        
                        {/* ↓↓ Partie - CODE PROMOTIONNEL : MAIS interdit si l'appli Web est lancée via l'appli mobile OU si ce plan est celui déjà souscrit ↓↓ */}
                        {
                            ((IsNativeHoster() === true) || (subscribedResume === true)) ? <></> : 
                            <Grid item xs={12}>
                                <StripeCoupon {...this.props} />
                            </Grid>
                        }
                        <Grid item xs={12} textAlign="center">
                            <Button 
                                variant={(subscribedResume === true) ? "text" : "outlined"}
                                disabled={subscribedResume}
                                sx={{
                                    "&:disabled":{
                                        color:theme.palette.secondary.main
                                    }
                                }}
                                onClick={this.onSelect}
                            >
                                {(subscribedResume === true) ? StringTranslate.suscribetext : StringTranslate.selectbutton }
                            </Button>
                        </Grid>
                    </Grid>
                </MainCard>
            </Grid>
        )
    };
}

export default PlanCard;
