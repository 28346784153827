import PropTypes from 'prop-types';


/* énuméré */
const ChangeType = {
    ADD:'ADD',
    UPDATE:'UPDATE',
    DELETE:'DELETE', 
}

/* énuméré rprésentant le type sur lequel est effectué l'opération */
const ReducerType = {
    PARCEL:'PARCEL',
    SATIMAGE:'SATIMAGE', 
}

/* classe représentant l'opération effectuée sur la liste (dico) parcelContextDico */
class ChangeAction {
    constructor(type, reducer, action, params) {
        this.type = type; // type d'action (add, delete, delete,..)
        this.reducer = reducer; // quel reducer impacté ? (parcel,satimage,...)
        this.action = action; // l'action associée
        this.params = params; // paramètres supplémentaires
    }
};

export { ChangeType, ReducerType, ChangeAction };

ChangeAction.defaultProps = {};
ChangeAction.propTypes = {
    type: PropTypes.string,
    reducer: PropTypes.string,
    action: PropTypes.string,
    params: PropTypes.object,
};