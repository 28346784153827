import React, { useState, useEffect, useMemo  } from "react";
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';

/* Helpers */
import fertilizerHelper from '../../../utils/fertilizerHelper';
import dateHelper from '../../../utils/dateHelper';

/* Redux */
import { FertilizerStep, ActionGetAllFertilizers, ActionCleanErrorFertilizer, 
  ActionSaveCommonInfosManagement, ActionGoToStepOfFertilizer, ActionClearFertilizerValue
 } from "../../../redux/actions/fertilizer";

/* MUI Components */
import { Card, Alert, Button, Typography, TextField, InputLabel, MenuItem, FormControl, Select,
  Grid, FormHelperText, InputAdornment, CircularProgress, Stack, ButtonGroup, Divider } from '@mui/material';

/* React Components */
import SubCard from "../../../components/subLayouts/subCard.jsx";
import AlertDialog from '../../../components/alertDialog';
import LinkToContactUs from '../../linkToContactUs';

/* Traduction */
import StringTranslate from '../../../assets/i18n/stringLanguage';

/* DatePicker */
import CustomDatePicker  from '../../customDatePicker.jsx';

/* Icons */
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

/* Theme Berry */
import getTheme from "../../../themes/index.js";



const theme = getTheme();

/////////////////////////////////////////////////////////
// Composant pour la saisie groupée                    //
/////////////////////////////////////////////////////////
function CommonInfosManagement(props) { //TODO : Modifier le composant pour que les données du state local est une sémantique plus précise sur le contenu de chaque props (Ex: 'intermediateCropFrequency' contient l'id de l'item choisi)

	const commonInfos = props.commonInfosByCulture; //Infos communes à toutes les parcelles en fonction de la culture

	const productionsTargets = lodashGet(props, 'fertilizerCommonLists.productionTargets', []); //liste des objectifs de production
	const tailingManagements = lodashGet(props, 'fertilizerCommonLists.tailingManagments', []); //liste de gestion des résidus
	const interCropFrequencies = lodashGet(props, 'fertilizerCommonLists.interCropFrequency', []); //liste de fréquence de culture intermédiaire
	const fertilizerTypes = lodashGet(props, 'fertilizerCommonLists.fertilizerTypes', []); //liste des différents types d'engrais
	const organicInputFrequencies = lodashGet(props, 'fertilizerCommonLists.organicInputFrequencies', []); //liste des différentes habitudes de fréquence d'apport organique 
	const interCropTypes = lodashGet(props, 'fertilizerCommonLists.interCropTypes', []); //liste des différentes cultures intermédiaires
	
	const defaultValue = "-1";
	const emptyTextChoice = StringTranslate.writeLater; 

	/* date de récolte */
	const [harvestDate, setHarvestDate] = useState(fertilizerHelper.getValue(undefined, "", lodashGet(commonInfos, 'harvestDate', null, true), fertilizerHelper.getDefaultHarvestDateByCulture(props.cultureSelected)));

	/* date de semis */
	const [sowingDate, setSowingDate] = useState(fertilizerHelper.getValue(undefined, "", lodashGet(commonInfos, 'sowingDate', null, true), fertilizerHelper.getDefaultSowingDateByCulture(props.cultureSelected)));

	//date qui perd le focus:
	const [blurDataName, setBlurDataName] = useState(undefined);

	/* objectif de production */
	let [productionTarget, setProductionTarget] = useState(lodashGet(commonInfos, 'productionTarget.id', ""));

	/* Gestion des résidus */
	const [tailingsManagement, setTailingsManagement] = useState(lodashGet(commonInfos, 'tailingsManagement.id', ""));

	/* fréquence de culture intermédiaire */
	const [intermediateCropFrequency, setIntermediateCultureFrequency] = useState(lodashGet(commonInfos, 'intermediateCultureFrequency.id', "")); //-1 si aucune donnée saisie

	/* objectif de rendement */
	const [performanceGoal, setPerformanceGoal] = useState(lodashGet(commonInfos, 'performanceGoal', (props.cultureSelected.cropType <= 2) ? 80 : 30));

	/* Type de fertilisation majoritaire */
	const [fertilizerType, setFertilizerType] = useState(lodashGet(commonInfos, 'fertilizerType.id', ""));

	/* Teneur en engrais */
	const [content, setContent] = useState(lodashGet(commonInfos, 'content', ""));

	/* Labour */
	const [ploughing, setPloughing] = useState(lodashGet(commonInfos, 'ploughing', false));

	/* Profondeur de labour */
	const [ploughingDepth, setPloughingDepth] = useState((lodashGet(commonInfos, 'ploughingDepth', "") === null) ? "" : lodashGet(commonInfos, 'ploughingDepth', ""));

	/* Fréquence en apport organique */
	const [organicInputFrequency, setOrganicInputFrequency] = useState(lodashGet(commonInfos, 'organicInputFrequency.id', ""));

	/* Type de culture intermédiaire */
	const [interCropType, setInterCropType] = useState(lodashGet(commonInfos, 'interCropType.id', ""));

	/* Dose N de réserve */
	const [doseReserved, setDoseReserved] = useState((lodashGet(commonInfos, 'doseReserved', "") === null) ? "" : lodashGet(commonInfos, 'doseReserved', ""));

	/* Dose N déjà apportée */
	const [doseProvided, setDoseProvided] = useState((lodashGet(commonInfos, 'doseProvided', "") === null) ? "" : lodashGet(commonInfos, 'doseProvided', ""));

	/* Bloquer le type de culture intermédiaire si pas de fréquence de culture intermédiaire */
	const [hasInterCropType, setHasInterCropType] = useState(fertilizerHelper.enableIntermediateCropType(commonInfos));

	const [openDialogOfErrors, setOpenDialogOfErrors] = useState(((props.errorMessage !== '') && (props.errorMessage !== null) && (props.errorMessage !== undefined)) ? true : false);
	
	const popupErrorDialog = useMemo(() => {
		return {
			getTitle: () => { 
				return (
					<Stack direction="row" spacing={1} alignItems="flex-end">
						<ErrorOutlineIcon />
						<Typography>Une erreur est survenue</Typography>
					</Stack>
				); 
			},
			description: props.errorMessage,
			getAdditionalDescription: () => { 
				return (
					<Typography variant="subtitle1">
						Si le problème persiste, <LinkToContactUs displayText={StringTranslate.contactUs}/>.
					</Typography>
				); 
			},
			button: StringTranslate.close,
		};
	}, [props.errorMessage]);

  /* ↓↓ Fonctions pour modifier les infos communes ↓↓ */
  /* Fonction pour modifier la date derécolte */
  const handleChangeHarvestDate = (newValue) => {
    setHarvestDate(newValue);
  };

  const handleBlurDatePicker = (valueType) => {
    if (!valueType) return;

    //En utilisant 'dateHelper.getDateValid(curentDateValue, true)' pour actualiser/forcer une valeur correcte, on peut (potetiellement) récupérer l'ancienne valeur du state local 
    // (valeur avant que 'onchange' est eu le temps d'actualisé ce state OU ce dernier soit à jour).
    //Du coup, on avertit juste de quelle saisie de date vient de perdre le focus. Et la détection de changement de valeur et ce 'onblurDataName' fera le contrôle.
    switch (valueType) {
        case 'dateDeSemi': 
        case 'dateDeRecolte': 
          setBlurDataName(valueType);
          break;

        default: //RAS !
            break;
    }
}

  /* Fonction pour modifier la date de semis */
  const handleChangeSowingDate = (newValue) => {
    setSowingDate(newValue);
  };

  /* Fonction pour modifier le rests des saisies */
  const handleChange = (param, event) => {

    let value = event.target.value;

    switch (param) {

      case 'performanceGoal':
        if (
          (value.toString().includes('.') && value.toString().split('.')[1].length > 2) ||
          (value.toString().includes(',') && value.toString().split(',')[1].length > 2)
          ) {
          //on ne fait rien. 
        }
        else {
          setPerformanceGoal(value.replace("-", ""));
        }
        break;
      case 'content':
        if (
          (value.toString().includes('.') && value.toString().split('.')[1].length > 2) ||
          (value.toString().includes(',') && value.toString().split(',')[1].length > 2)
          ) {
          //on ne fait rien. 
        }
        else {
          setContent(value.replace("-", ""));
        }
        break;
      case 'doseReserved':
        if (
          (value.toString().includes('.') && value.toString().split('.')[1].length > 2) ||
          (value.toString().includes(',') && value.toString().split(',')[1].length > 2)
          ) {
          //on ne fait rien. 
        }
        else {
          setDoseReserved(value.replace("-", ""));
        }
        break;
      case 'doseProvided':
        if (
          (value.toString().includes('.') && value.toString().split('.')[1].length > 2) ||
          (value.toString().includes(',') && value.toString().split(',')[1].length > 2)
          ) {
          //on ne fait rien. 
        }
        else {
          setDoseProvided(value.replace("-", ""));
        }
        break;
      case 'ploughingDepth':
        if (
          (value.toString().includes('.') && value.toString().split('.')[1].length > 2) ||
          (value.toString().includes(',') && value.toString().split(',')[1].length > 2)
          ) {
          //on ne fait rien. 
        }
        else {
          setPloughingDepth(value.replace("-", ""));
        }
        break;

      case 'productionTarget':
        setProductionTarget(value);
        break;

      case 'tailingsManagement':
        setTailingsManagement(value);
        break;

      case 'fertilizerType':
        setFertilizerType(value);
        break;

      case 'intermediateCropFrequency':
        if ((value !== undefined) && (value !== defaultValue) && (value !== "1") && (value !== "0") && (value !== null)) {
          setHasInterCropType(true);
        }
        else {
          setHasInterCropType(false); //on désactive interCropType
          setIntermediateCultureFrequency(""); //on supprime la valeur de intermediaiteCultureFrequency
          setInterCropType(""); //on supprime la valeur du type de la culture intermédiaire
        }
        setIntermediateCultureFrequency(value);
        break;

      case 'ploughing':
        setPloughing(!ploughing);
        if (ploughing === true) { //Si pas de labour, on remet à zéro la profondeur de labour
          setPloughingDepth("");
        }
        break;

      case 'organicInputFrequency':
        setOrganicInputFrequency(value);
        break;

      case 'interCropType':
        setInterCropType(value);
        break;

      default:
        break;

    }
  }
  /* ↑↑ Fonctions pour modifier les infos communes ↑↑ */

  /* ↓↓ Boutons de redirection vers l'étape précédente ou l'étape suivante ↓↓ */
  /* Les infos obligatoires pour passer à l'étape suivante : harvestDate, sowingDate, performanceGoal */
  const validateCommonInfosManagement = () => {

    const { saveCommonInfos, fertilizerDicoIsLoaded, getAllFertilizers, goToStep, clientId } = props;
    
    let newCommonInfosManagement = {
      idClient: clientId,
      enumOfCrop: props.cultureSelected.cropType,
      harvestDate: (!harvestDate) || (isNaN(harvestDate)) ? null : new Date(harvestDate),
      sowingDate: (!sowingDate) || (isNaN(sowingDate)) ? null : new Date(sowingDate),
      productionTarget: (productionTarget === defaultValue) || (productionTarget === "") ? null : fertilizerHelper.findCommonListElement(productionsTargets, productionTarget),
      performanceGoal: performanceGoal,
      intermediateCultureFrequency: (intermediateCropFrequency === defaultValue) || (intermediateCropFrequency === "") ? null : fertilizerHelper.findCommonListElement(interCropFrequencies, intermediateCropFrequency), //-1 pour aucune donnée saisie, on considèrera qu'il n'y aura aucune fréquance.
      tailingsManagement: (tailingsManagement === defaultValue) || (tailingsManagement === "") ? null : fertilizerHelper.findCommonListElement(tailingManagements, tailingsManagement),
      fertilizerType: (fertilizerType === defaultValue) || (fertilizerType === "") ? null : fertilizerHelper.findCommonListElement(fertilizerTypes, fertilizerType),
      content: (content === undefined) || (content === null) || (content === "") ? null : Number(content),
      ploughing: ploughing,
      ploughingDepth: (ploughingDepth !== "") && (ploughingDepth !== null) ? Math.round(ploughingDepth) : null, //on arrondit car on attend un entier en BDD
      organicInputFrequency: (organicInputFrequency === defaultValue) || (organicInputFrequency === "") ? null : fertilizerHelper.findCommonListElement(organicInputFrequencies, organicInputFrequency),
      interCropType: (interCropType === defaultValue) || (interCropType === "") ? null : fertilizerHelper.findCommonListElement(interCropTypes, interCropType),
      doseReserved: (doseReserved === undefined) || (doseReserved === null) || (doseReserved === "") ? null : Number(doseReserved),
      doseProvided: (doseProvided === undefined) || (doseProvided === null) || (doseProvided === "") ? null : Number(doseProvided),
    };

    // on enregistre en base les infos si elles sont différentes
    if (fertilizerHelper.compareCommonInfos(commonInfos, newCommonInfosManagement) < 0) {
      saveCommonInfos(newCommonInfosManagement);
    }
      
    //Chargement des données spécifiques si ce n'est pas déjà fait
    if (fertilizerDicoIsLoaded === false) {
      getAllFertilizers(clientId); // On passe à l'étape suivante dans cette fonction
    }
    else {
      // on passe a l'étape suivante
      goToStep(FertilizerStep.MANAGEMENT_NITROGEN);
    }
  };

	/* Fonction pour retourner à l'étape 1 de choix de culture et de parcelles */
	const goBackToCultureAndParcelsChoice = () => {
		const { clearFertilizerValue, goToStep } = props;

		//Réinitialisation des paramètres :
		clearFertilizerValue();

		//Redirection vers létape du choix de la culture et de la sélection de parcelles
		goToStep(FertilizerStep.CULTURE_AND_PARCELS_CHOICE);
	};
	/* ↑↑ Boutons de redirection vers l'étape précédente ou l'étape suivante ↑↑ */

	// Similar to componentDidMount and componentDidUpdate:
	useEffect(() => {
		if (props.errorMessage  && (props.errorMessage !== '') && (props.errorMessage !== null) && (props.errorMessage !== undefined)) {
			//'popupErrorDialog.description' sera affecté de 'props.errorMessage' dans 'useMemo' !

			setOpenDialogOfErrors(true);
		}

    if (blurDataName && (blurDataName !== '')) {
        try {
            if (blurDataName === 'dateDeSemi') {
              const curentDateValue = sowingDate;
              const futureValue = dateHelper.getDateValid(curentDateValue, true);
      
              setBlurDataName(''); //pour éviter de rerentrer ici !
              if (curentDateValue !== futureValue) {
                setSowingDate(futureValue);
              }
              //else //ok, on ne change rien !
            } else if (blurDataName === 'dateDeRecolte') {
              const curentDateValue = harvestDate;
              const futureValue = dateHelper.getDateValid(curentDateValue, true);
      
              setBlurDataName(''); //pour éviter de rerentrer ici !
              if (curentDateValue !== futureValue) {
                setHarvestDate(futureValue);
              }
              //else //ok, on ne change rien !
            } else {
              //Ras! 
              
              setBlurDataName('');
            }
        }
        catch(errDate) {
            //console.log('blurDataName: ', errDate);
            setBlurDataName('');
        }
    }
	}, [props.errorMessage, sowingDate, harvestDate, blurDataName]);

  	/* Ferme le dialog des messages d'erreur */
	const closeDialogOfErrors = () => {
		setOpenDialogOfErrors(false);

		if (props.cleanErrorFertilizer) {
			props.cleanErrorFertilizer();
		}
	};

  return (
    <>
      {/* Dialog des erreurs */}
      {(openDialogOfErrors === true) &&
        <AlertDialog 
          popup={popupErrorDialog} 
          handleChangePopup={closeDialogOfErrors} />
      }

      <Grid container marginY={2} >
        <Grid item xs={12}>
          <Card>
            <Alert
              severity="info"
              icon={<InfoOutlined />}
            >
              <Typography>{StringTranslate.commonInfosManagementInfos}</Typography>
            </Alert>
          </Card>
        </Grid>
      </Grid>

      <Typography fontWeight="bold" m={2}>{StringTranslate.cropName} {props.cultureSelected.name}</Typography>

      <Divider mb={3} />

      { (props.fertilizerCommonLists !== undefined) &&  (openDialogOfErrors === false) &&
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ mt: '8px !important' }}>

          <Grid item xs={4} sm={4} md={4}>
            <SubCard title={<Typography sx={{ fontWeight: "bold" }}>{StringTranslate.agronomicInformation}</Typography>}>

              <Grid container spacing={{ xs: 2, md: 3 }}>
                {/* Date de semis (mobile et desktop) */}
                <Grid item xs={12} sx={{ display: 'block' }}>
                  <CustomDatePicker
                    label="Date de semis"
                    value={(sowingDate !== null) ? new Date(sowingDate) : null}
                    onChange={handleChangeSowingDate}
                    inputProps={{ placeholder: StringTranslate.formatDatePlaceHolder, helpertext: '' }}
                    placeholder="Date de semis"
                    slotPropsDisabled={props.fertilizerDicoIsLoading}
                    onBlurValue={ () => handleBlurDatePicker('dateDeSemi')}
                  />
                </Grid>

                {/* Date de récolte (mobile et desktop) */}
                <Grid item xs={12} sx={{ display: 'block' }}>
                  <CustomDatePicker
                      label="Date de récolte"
                      value={(harvestDate !== null) ? new Date(harvestDate) : null}
                      onChange={handleChangeHarvestDate}
                      placeholder="Date de récolte"
                      slotPropsDisabled={props.fertilizerDicoIsLoading}
                      onBlurValue={() => handleBlurDatePicker('dateDeRecolte')}
                  />
                </Grid>

                {/* Objectif de production */}
                {(props.cultureSelected.cropType === 1) && (<Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="production-target">{StringTranslate.productionTarget}</InputLabel>
                    <Select
                      labelId="production-target"
                      id="select-production-target"
                      value={productionTarget}
                      label="Production target"
                      onChange={(event) => handleChange("productionTarget", event)}
                      disabled={(props.fertilizerDicoIsLoading)}>
                      <MenuItem id={0} value={defaultValue} sx={{ fontStyle: 'italic' }}>{emptyTextChoice}</MenuItem>
                      {(productionsTargets.length === 0) ? <MenuItem><CircularProgress color="inherit" size={20} /></MenuItem> :
                        productionsTargets.map(productionTarget =>
                          <MenuItem key={productionTarget.id} value={productionTarget.id}>
                            {productionTarget.label}
                          </MenuItem>
                        )}
                    </Select>
                  </FormControl>
                </Grid>)}

                {/* Objectif de rendement */}
                <Grid item xs={12}>
                  <TextField
                    label="Objectif de rendement"
                    id="input-yield-target"
                    sx={{ width: '25ch' }}
                    type="number"
                    onWheel={event => event.target.blur()}
                    onChange={(event) => handleChange("performanceGoal", event)}
                    value={performanceGoal}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">q/ha</InputAdornment>,
                    }}
                    disabled={(props.fertilizerDicoIsLoading)}
                  />
                </Grid>

                {/* Gestion des résidus */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="tailings-management">{StringTranslate.residueManagement}</InputLabel>
                    <Select
                      labelId="tailings-management"
                      id="select-tailings-management"
                      value={tailingsManagement}
                      label="Tailings management"
                      onChange={(event) => handleChange("tailingsManagement", event)}
                      disabled={(props.fertilizerDicoIsLoading)}>
                      <MenuItem value={defaultValue} sx={{ fontStyle: 'italic' }}>{emptyTextChoice}</MenuItem>
                      {((tailingManagements < 0) || (tailingManagements.length === 0)) ?
                        <MenuItem>
                          <CircularProgress color="inherit" size={20} />
                        </MenuItem> :
                        tailingManagements.map(tailingManagement =>
                          <MenuItem key={tailingManagement.id} value={tailingManagement.id}>
                            {tailingManagement.label}
                          </MenuItem>
                        )}
                    </Select>
                    <FormHelperText>{StringTranslate.practicesFromLast10years}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

            </SubCard>
          </Grid>

          <Grid item xs={4} sm={4} md={4}>

            <SubCard title={<Typography sx={{ fontWeight: "bold" }}>{StringTranslate.fertilization}</Typography>}>

              <Grid container spacing={{ xs: 2, md: 3 }}>
                {/* Type d'engrais à épandre */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      select
                      label="Type d'engrais à épandre"
                      id="select_main_type_fertilization"
                      value={fertilizerType}
                      onChange={(event) => handleChange("fertilizerType", event)}
                      disabled={(props.fertilizerDicoIsLoading)}
                    >
                      <MenuItem value={defaultValue} sx={{ fontStyle: 'italic' }}>{emptyTextChoice}</MenuItem>
                      {fertilizerTypes.map((fertilizerType) => (
                        <MenuItem key={fertilizerType.id} value={fertilizerType.id}>
                          {fertilizerType.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>

                {/* Teneur en azote de l'engrais */}
                <Grid item xs={12}>
                  <FormControl sx={{ 
                    [theme.breakpoints.up('md')]: { width: '28ch' },
                    [theme.breakpoints.down('md')]: { width: '100%' } }}>
                    <TextField
                      label="Teneur en azote de l'engrais"
                      id="input-content"
                      value={content}
                      onChange={(event) => handleChange("content", event)}
                      type="number"
                      onWheel={event => event.target.blur()}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                      disabled={(props.fertilizerDicoIsLoading)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </SubCard>

            <SubCard title={<Typography sx={{ fontWeight: "bold" }}>{StringTranslate.organicContribution}</Typography>} sx={{ mt: 2 }}>

              <Grid container spacing={{ xs: 2, md: 3 }}>

                {/* Fréquence apport organique */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      select
                      label="Fréquence d'apport organique"
                      id="input-organic-frequancy"
                      value={organicInputFrequency}
                      onChange={(event) => handleChange('organicInputFrequency', event)}
                      disabled={(props.fertilizerDicoIsLoading)}
                    >
                      <MenuItem value={defaultValue} sx={{ fontStyle: 'italic' }}>{emptyTextChoice}</MenuItem>
                      {organicInputFrequencies.map((organicInputFrequency) => (
                        <MenuItem key={organicInputFrequency.id} value={organicInputFrequency.id}>
                          {organicInputFrequency.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>

                </Grid>
              </Grid>
            </SubCard>
          </Grid>

          <Grid item xs={4} sm={4} md={4}>
            <SubCard title={<Typography sx={{ fontWeight: "bold" }}>{StringTranslate.cultivationHabitsPlot}</Typography>}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                {/* Labour */}
                <Grid item xs={12}>
                  <Stack direction="row" alignContent="center">
                    <Typography sx={{ alignSelf: 'center', pr: 2 }}>{StringTranslate.plowing}</Typography>
                    <ButtonGroup disableElevation variant='contained'>
                      <Button
                        size='medium'
                        onClick={(event) => handleChange("ploughing", event)}
                        sx={{
                          bgcolor: theme.palette.primary.main,
                          color: theme.palette.common.white,
                          opacity: "0.4",
                          '&:disabled': {
                            bgcolor: theme.palette.primary.main,
                            color: theme.palette.common.white,
                            opacity: "1",
                          }
                        }}
                        disabled={(ploughing) || (props.fertilizerDicoIsLoading)}
                      >
                        {StringTranslate.yes}
                      </Button>
                      <Button
                        size='medium'
                        onClick={(event) => handleChange('ploughing', event)}
                        sx={{
                          bgcolor: theme.palette.primary.main,
                          color: theme.palette.common.white,
                          opacity: "0.4",
                          '&:disabled': {
                            bgcolor: theme.palette.primary.main,
                            color: theme.palette.common.white,
                            opacity: "1",
                          }
                        }}
                        disabled={!ploughing || (props.fertilizerDicoIsLoading)}
                      >
                        {StringTranslate.no}
                      </Button>

                    </ButtonGroup>
                  </Stack>
                </Grid>

                {/* Profondeur de labour */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      label="Profondeur de labour"
                      id="input_ploughing_depth"
                      type="number"
                      onWheel={event => event.target.blur()}
                      sx={{ width: '25ch' }}
                      value={ploughingDepth}
                      onChange={(event) => handleChange('ploughingDepth', event)}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                        min: 0
                      }}
                      disabled={!ploughing || (props.fertilizerDicoIsLoading)}
                    />
                  </FormControl>
                </Grid>

                {/* Fréquence de culture intermédiaire */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="intermediate-crop-frequency">{StringTranslate.intermediatecropFrequency}</InputLabel>
                    <Select
                      labelId="intermediate-crop-frequency"
                      id="select-intermediate-crop-frequency"
                      value={intermediateCropFrequency}
                      label="Intermediate crop frequency"
                      onChange={(event) => handleChange("intermediateCropFrequency", event)}
                      disabled={(props.fertilizerDicoIsLoading)}>
                      <MenuItem value={defaultValue} sx={{ fontStyle: 'italic' }}>{emptyTextChoice}</MenuItem>
                      {((interCropFrequencies < 0) || (interCropFrequencies.length === 0)) ?
                        <MenuItem>
                          <CircularProgress color="inherit" size={20} />
                        </MenuItem> :
                        interCropFrequencies.map(interCropFrequency =>
                          <MenuItem key={interCropFrequency.id} value={interCropFrequency.id}>
                            {interCropFrequency.label}
                          </MenuItem>
                        )}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Type de culture intermédiaire */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      select
                      label="Type de culture intermédiaire"
                      id="select-intermediaite-culture-type"
                      value={interCropType}
                      onChange={(event) => handleChange('interCropType', event)}
                      disabled={(props.fertilizerDicoIsLoading === true) ? true : ((hasInterCropType === true) ? false : true)}
                    >
                      <MenuItem value={defaultValue} sx={{ fontStyle: 'italic' }}>{emptyTextChoice}</MenuItem>
                      {interCropTypes.map((interCropType) => (
                        <MenuItem key={interCropType.id} value={interCropType.id}>
                          {interCropType.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </SubCard>

            <SubCard title={<Typography sx={{ fontWeight: "bold" }}>{StringTranslate.dosesProvided}</Typography>} sx={{ mt: 2 }}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                {/* Dose déjà apportée */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      label="Dose déjà apportée"
                      id="input_dose_provided"
                      type="number"
                      onWheel={event => event.target.blur()}
                      sx={{ width: '25ch' }}
                      value={doseProvided}
                      onChange={(event) => handleChange('doseProvided', event)}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">U/ha</InputAdornment>,
                        min: 0
                      }}
                      disabled={props.fertilizerDicoIsLoading}
                    />
                  </FormControl>
                </Grid>
                {/* Dose réservée */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      label="Dose réservée"
                      id="input_dose_reserved"
                      type="number"
                      onWheel={event => event.target.blur()}
                      sx={{ width: '25ch' }}
                      value={doseReserved}
                      onChange={(event) => handleChange('doseReserved', event)}
                      InputProps={{
                        endAdornment: 
                          <InputAdornment position="end">U/ha</InputAdornment>, 
                        min: 0
                      }}
                      disabled={props.fertilizerDicoIsLoading}
                    />
                    <FormHelperText id="info-reserve-text">
                      {StringTranslate.doseLastInstake}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </SubCard>
          </Grid>
        </Grid>}

      { (props.fertilizerCommonLists === undefined) &&  (openDialogOfErrors === false) &&
        <CircularProgress color="inherit" size={25} sx={{ m: 2 }} />}

      {/* Bouton de retour vers l'étape de choix de la culture et de la sélection de parcelles */}
      {/* Bouton de validation des infos communes et redirection vers l'étape des infos uniques */}
      <Grid container xs={12} marginTop={3} item>
        <Grid item xs={6}>
          <Button variant="text" onClick={goBackToCultureAndParcelsChoice}>
            {StringTranslate.goBack}
          </Button>
        </Grid>
        { (props.fertilizerCommonLists !== undefined) &&  (openDialogOfErrors === false) &&
          <Grid item xs={6} justifyContent="flex-end" align="right">
            <Button type="submit" variant="contained" 
              onClick={validateCommonInfosManagement}
              disabled={props.fertilizerDicoIsLoading}
              startIcon={(props.fertilizerDicoIsLoading === true) && <CircularProgress color="inherit" size={20} />}>
              {StringTranslate.validate}
            </Button>
          </Grid>}
      </Grid>

    </>
  );
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
  cultureSelected: lodashGet(state, 'fertilizerData.cultureSelected', undefined),
  commonInfosByCulture: lodashGet(state, 'fertilizerData.commonInfosByCulture', undefined),
  fertilizerCommonLists: lodashGet(state, 'fertilizerData.fertilizerCommonLists', undefined),
	fertilizerDicoIsLoading: lodashGet(state, 'fertilizerData.fertilizerDicoIsLoading', false),
	fertilizerDicoIsLoaded: lodashGet(state, 'fertilizerData.fertilizerDicoIsLoaded', false),
  errorMessage: lodashGet(state, 'fertilizerData.errorMessage', undefined),
  
  clientId: lodashGet(state, 'clientUserData.clientDatas.id', 0),
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
  goToStep: (step) => dispatch(ActionGoToStepOfFertilizer(step)),
  
	getAllFertilizers: (clientId) => dispatch( ActionGetAllFertilizers(clientId) ),
	cleanErrorFertilizer: () => dispatch( ActionCleanErrorFertilizer() ),
  
  saveCommonInfos: (newCommonInfosManagement) => dispatch(ActionSaveCommonInfosManagement(newCommonInfosManagement)),
  clearFertilizerValue: () => dispatch(ActionClearFertilizerValue())
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonInfosManagement);