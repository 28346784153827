const UrlSpotifarmBase = "https://www.spotifarm.";

const SubUrlLangageFrFr = "fr/";
const SubUrlLangageEsEs = "es/";
const SubUrlLangageEn = "en/";
const SubUrlLangageForModulationEsEs = "es/es-es/";
const SubUrlLangageEnGb = "fr/en-gb/";
const SubUrlLanguageEndFr = "-fr";
const SubUrlLanguageEndEs =  "-es";
const SubUrlLanguageEndEn =  "-en";

const SubUrlHubspot = 'hs-fs/hubfs/';

const SubUrlHelpMenu = "top-secret-help-menu";

const SubUrlHelpExport = "top-secret-aide-a-l-export";
const SubUrlHelpExportTelepac = "top-secret-aide-a-l-export-telepac";
const SubUrlHelpExportGeofolia = "top-secret-aide-a-l-export-geofolia";
const SubUrlHelpExportMesparcelles = "top-secret-aide-a-l-export-mesparcelles";

const SubUrlHelpImport = "top-secret-aide-a-l-import";

const SubUrlHelpOtherProblems = "top-secret-autres-problemes";

const SubUrlHelpImagesSpotifarm = "top-secret-comprendre-les-images-spotifarm";

const SubUrlHelpModulationTelechargementAndroid = "aide-telechargement-android";
const SubUrlHelpModulationTelechargementApple = "aide-telechargement-apple";
const SubUrlHelpModulationTelechargementDesktop = "aide-telechargement-ordinateur";
const SubUrlHelpModulationFichiersEtConsoles = "aide-pour-format-fichier-et-gestion-par-les-consoles";

const SubUrlHelpResolutionMapModulation = "top-secret-resolution-cartes-modulation";

const SubUrlHelpPeseeDeColza = "top-secret-pesee-de-colza";

const SubUrlHelpFumure = "top-secret-conseil-azote-fumure";

const SubUrlHelpLastInputN = "dernier-apport";

const SubUrlHelpModelisation = "images-modélisées";

const SubUrlHelpContactForm = "top-secret-formulaire-de-contact";

const SubUrlHelpIndice = "Onboarding_Understand_Ndvi.gif";

const SubUrlHelpImportFiles = "Help_MultiFile_Selection.gif";

const SubUrlHelpHarvestCorn = "date-de-recolte-mais";

const SubUrlTutoVideo = "top-secret-tutos-video";

const UrlContactFormForSuscription = "https://share.hsforms.com/1bC4incYrRtW5PlZ9tmv3EQ3auly";
const UrlContactFormForModulation = "https://share-eu1.hsforms.com/1XftQAfC_S6-gq40NTviSfw3auly";
const UrlContactFormForFertilizer = "https://share-eu1.hsforms.com/1BKwElpePRC-AkilNEDHZ4w3auly";
const UrlContactFormForCornHarvest = "https://share-eu1.hsforms.com/1p6lQJRLITsyI2pFp83f5ng3auly";
const UrlVideoModulation = "https://www.youtube.com/embed/5DA1SsADS64?si=hKnTMTQoaJSF3ciQ";

export const ConstantsTutorialHelps = {
    UrlSpotifarmBase: UrlSpotifarmBase,
    SubUrlLangageFrFr: SubUrlLangageFrFr,
    SubUrlLangageEsEs: SubUrlLangageEsEs,
    SubUrlLangageEn: SubUrlLangageEn,
    SubUrlLangageEnGb: SubUrlLangageEnGb,
    SubUrlHelpMenu: SubUrlHelpMenu,
    SubUrlHelpExport: SubUrlHelpExport,
    SubUrlHelpExportTelepac: SubUrlHelpExportTelepac,
    SubUrlHelpExportGeofolia: SubUrlHelpExportGeofolia,
    SubUrlHelpExportMesparcelles: SubUrlHelpExportMesparcelles,
    SubUrlHelpImport: SubUrlHelpImport,
    SubUrlHelpOtherProblems: SubUrlHelpOtherProblems,
    SubUrlHelpImagesSpotifarm: SubUrlHelpImagesSpotifarm,
    SubUrlLangageForModulationEsEs: SubUrlLangageForModulationEsEs,
    SubUrlHelpModulationTelechargementAndroid: SubUrlHelpModulationTelechargementAndroid,
    SubUrlHelpModulationTelechargementApple: SubUrlHelpModulationTelechargementApple,
    SubUrlHelpModulationTelechargementDesktop: SubUrlHelpModulationTelechargementDesktop,
    SubUrlHelpModulationFichiersEtConsoles: SubUrlHelpModulationFichiersEtConsoles,
    SubUrlLanguageEndFr: SubUrlLanguageEndFr,
    SubUrlLanguageEndEs: SubUrlLanguageEndEs,
    SubUrlLanguageEndEn:SubUrlLanguageEndEn,
    SubUrlHelpResolutionMapModulation: SubUrlHelpResolutionMapModulation,
    SubUrlHelpPeseeDeColza: SubUrlHelpPeseeDeColza,
    SubUrlHelpFumure: SubUrlHelpFumure,
    SubUrlHelpLastInputN: SubUrlHelpLastInputN,
    SubUrlHelpModelisation: SubUrlHelpModelisation,
    SubUrlHelpContactForm: SubUrlHelpContactForm,
    SubUrlHubspot: SubUrlHubspot,
    SubUrlHelpIndice: SubUrlHelpIndice,
    SubUrlHelpImportFiles: SubUrlHelpImportFiles,
    SubUrlTutoVideo: SubUrlTutoVideo,
    UrlContactFormForSuscription: UrlContactFormForSuscription,
    UrlContactFormForModulation: UrlContactFormForModulation,
    UrlContactFormForFertilizer: UrlContactFormForFertilizer,
    UrlContactFormForCornHarvest: UrlContactFormForCornHarvest,
    UrlVideoModulation: UrlVideoModulation,
    SubUrlHelpHarvestCorn: SubUrlHelpHarvestCorn,
};

export default ConstantsTutorialHelps;