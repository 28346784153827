import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


function DrawParcelIcon(props: SvgIconProps) {
    return ( 
        <SvgIcon viewBox="0 0 30 30" {...props}>
            <svg height="30" width="30">
                <polygon points="5,5 25,9 26,23 8,26" strokeWidth="2" stroke="current" fillOpacity="0.0" />
                <circle cx="5" cy="5" r="3" strokeWidth="2" stroke="current" fill="white" />
                <circle cx="25" cy="9" r="3" strokeWidth="2" stroke="current" fill="white" />
                <circle cx="26" cy="23" r="3" strokeWidth="2" stroke="current" fill="white" />
                <circle cx="8" cy="26" r="3" strokeWidth="2" stroke="current" fill="white" />
            </svg>
        </SvgIcon>
    );
}

export default DrawParcelIcon;