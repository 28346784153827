import React from "react";
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';

/* Redux */
import { ActionSaveSettingsAsk, UpdateNbRowsPerPageTable, TableType } from '../../redux/actions/settings.js';
import { ActionImportParcelsAsk, ActionDeleteParcelsAsk, ActionDeleteParcelIdListToAPI, ActionSaveParcel, ActionSetParcelTab } from '../../redux/actions/parcels.js';
import { ActionGoToMap, ActionGoToMapAndSelectParcel, ActionGoToProfil, ActionShowProfilMenuDialog } from '../../redux/actions/contextApp.js';
import { ActionGoToStepForDidacticielFirstParcel, StepEnumDidacticielFirstParcel } from '../../redux/actions/didacticiel.js';
import { ActionParcelListImportWhenDidacticielFirstParcel } from '../../redux/actions/parcels.js';

/* Composants MUI */
import { createFilterOptions } from '@mui/material/Autocomplete';
import {
    InputAdornment, Button, CircularProgress, Grid, Typography, Autocomplete,
    Box, Dialog, DialogActions, DialogContent, DialogTitle, TableContainer,
    FormControl, TextField, DialogContentText, Tabs, Tab, Link, Stack,
} from '@mui/material';

import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

/* Dialog */
import ConfirmDialog from '../../components/confirmDialog';

/* DatePicker */
import CustomDatePicker  from '../../components/customDatePicker.jsx'

import { ThumbnailParcelShapeFromPathInfos } from '../../components/thumbnail/ThumbnailParcelShape.jsx';
import CustomDataGrid from "../../components/customDataGrid";
import MainCard from "../../components/subLayouts/mainCard.jsx";
import AbstractModelisation from "../modelisation/abstractModelisation.jsx";
import { TabPanel, buildPropsForTab } from "../../components/tabPanel.jsx";

/* Icones */
import { ViewList } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import ModelisationIcon from "../../assets/images/modelisation/ModelisationIcon.tsx";

/* Helpers et Dico */
import stringHelper from '../../utils/stringHelper.js';
import DateHelper from '../../utils/dateHelper.js';
import { ParcelsHelper } from '../../utils/parcelsHelper.js';
import dicoFunction from '../../datas/dicoDetails.js';

/* css */
import '../../assets/css/renderParcels.css';
import '../../assets/css/pdp-pinDropPopup.css';

/* Concerne les options pour les cultures, cultures précédentes, exploitation */
const filter = createFilterOptions();

/**
 * Composant d'affichage des parcelles actuelles.
 */
class RenderParcels extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openConfirmDialog: false, //ouvre le popup dialog pour confirmer la suppression de toutes les parcelles
            openDeleteParcels: false, //ouvre le popup dialog pour confirmer la suppression d'une ou plusieurs parcelles
            openUpdateParcel: false, //ouvre le popup dialog pour modifier une parcelle
            parcelsToDeleteWithModulation: false, //au moins une parcelle pour la suppression est liée à une modulation
            isDeleting: false, //suppression des parcelles en cours...

            datas: this.initialiseDatas(props.parcelIdFilteredList), //parcelles
            cultures: this.initialiseCultures(), //initialisation des cultures : cultures fixes + cultures existantes dans les parcelles
            varieties: this.initialiseVarieties(), // initialisation des variétés : bouton "ajouter une variété" + variétés existantes dans les parcelles
            farms: this.initialiseFarms(), // initialisation des exploitations : bouton "ajouter une exploitation" + exploitations existantes dans les parcelles 

            //sauvegarde la langue en cours lors de la création du composant: (Car pas présente dans le store Redux)
            language: StringTranslate.getLanguage(),

            rowsPerPageForTableParcels: props.rowsPerPageForTableParcels, //nombre de lignes par page dans le tableau enregistré

            parcelsSelected: [], //parcelles sélectionnées par les checkbox
            pageSize: props.rowsPerPageForTableParcels, //Nombre de lignes par page au départ
            searchFilter: '', //Zone de recherche du tableau 
            currentRowsOfTable: [], //Datas en fonction du texte tapé dans la zone de recherche

            //Valeur affectée aux différentes données d'une parcelle en cours de modification:
            id: 0,
            name: null,
            campagne: null,
            culture: null,
            culturePrecedente: null,
            dateSemi: null,
            exploitation: null,
            surface: null,
            variete: null,
            thumbnailInfos: null,
        };

        // liste des noms de colonnes affichées
        this.columns = this.initialiseColumns(props.parcelIdFilteredList); //RQ: pas dans le 'state' car on en utilise des propriétés !
        this.handleUpdateParcel = this.handleUpdateParcel.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        //@@Réagir aux modifications sur les parcelles : Suppression d'une ou toutes les parcelles ; Modification d'une ou plusieurs données d'une parcelle !
        const { parcelDicoCounter, parcelIdFilteredListCounter, parcelIdFilteredList,
            updatingParcelName, updatingParcelInfos } = this.props;
        const { language } = this.state;

        /* Lorsqu'il y a un ajout, suppression de parcelle, il faut actualiser la liste des 'datas': */
        /* Idem, si c'est le filtre des parcelles qui change la liste de celles filtrées ! */
        if ((prevProps.parcelDicoCounter !== parcelDicoCounter) || (prevProps.parcelIdFilteredListCounter !== parcelIdFilteredListCounter)) {
            if (prevProps.parcelDicoCounter > parcelDicoCounter) { /* on est dans la suppresion */
                this.setState({
                    openDeleteParcels: false,
                    isDeleting: false
                });
            }

            const newDatas = this.initialiseDatas(parcelIdFilteredList);

            if (prevProps.parcelDicoCounter !== parcelDicoCounter) {
                //RQ: C'est par ce que la liste des cultures comprend une liste fixe de cultures, qui sont naturellement traduites!
                const newCultures = this.initialiseCultures(); //initialisation des cultures : cultures fixes + cultures existantes dans les parcelles
                // liste des noms de colonnes affichées. 
                this.setState({
                    datas: newDatas,
                    cultures: newCultures,
                },
                    () => { this.columns = this.initialiseColumns(parcelIdFilteredList) }); //RQ: ca va que l'on actualise 'cultures' sans quoi le rendu n'aurai pas lieu et on n'utiliserai pas la nouvelle liste de noms de colonne !
            } else {
                this.setState({
                    datas: newDatas,
                });
            }
        }
        /* Lorsqu'il y a une modification des données d'une parcelle (RQ: n'a pas être réalisé qu'ici!), il faut actualiser la liste des 'datas': */
        /* RQ: c'est plus rapide de recharger toutes les parcelles que de rechercher quelle parcelle a été modifié et quelle(s) info(s) de cette parcelle! */
        else if (((prevProps.updatingParcelName === true) && (updatingParcelName === false)) ||
            ((prevProps.updatingParcelInfos === true) && (updatingParcelInfos === false))) { //RQ: lorsque l'on passe de 'true' à 'false' c'est que le traitement est finis (même si pourrai être KO)
        
            const newCultureList = this.initialiseCultures();
            const newVarietiesList = this.initialiseVarieties();
            const newFarmList = this.initialiseFarms();
            
            const newDatas = this.initialiseDatas(parcelIdFilteredList);
            this.setState({
                datas: newDatas,
                openUpdateParcel: false,
                cultures: newCultureList,
                varieties: newVarietiesList,
                farms: newFarmList,
            });
        }

        /* Lorsqu'il y a un changement de langue (même si on se doute que l'utilisateur ne changera pas de langue tous les 4 matins !), il faut actualiser certaines listes: */
        const currentLanguage = StringTranslate.getLanguage();
        if ((!language) || (language === '') || (language !== currentLanguage)) {
            //RQ: C'est par ce que la liste des cultures comprend une liste fixe de cultures, qui sont naturellement traduites!
            const newCultures = this.initialiseCultures(); //initialisation des cultures : cultures fixes + cultures existantes dans les parcelles
            // liste des noms de colonnes affichées. 
            this.columns = this.initialiseColumns(parcelIdFilteredList); //RQ: ca va que l'on actualise 'cultures' sans quoi le rendu n'aurai pas lieu et on n'utiliserai pas la nouvelle liste de noms de colonne !

            this.setState({ cultures: newCultures, language: currentLanguage, });
        }
    }

    /* demande la sélection de la parcelle, et la redirection vers la vue 'Carte'. */
    onParcelRowClick = (parcelId) => {
        if (parcelId > 0)
            this.props.goToMapAndSelectParcel(parcelId);
    }

    //renvoie les exploitations appartenant aux parcelles existantes
    initialiseFarms() {
        const { parcelDico } = this.props;

        let farms = [];

        //Ajout des nom d'exploitations trouvés dans la définition des parcelles:
        ParcelsHelper.searchFarms(parcelDico)
            .forEach(farm => {
                if (!farms.find(f => f.title === farm))
                    farms.push({ title: farm });
            });

        return Array.from(new Set(farms));
    }

    // rencoie les variétés appartenant aux parcelles existantes
    initialiseVarieties() {
        const { parcelDico } = this.props;
        let varieties = [];

        //Ajout des variétés trouvées dans la définition des parcelles:
        ParcelsHelper.searchVarieties(parcelDico)
            .forEach(variety => {
                if (!varieties.find(v => v.title === variety)) {
                    varieties.push({ title: variety });
                }
            });

        return varieties.sort((a, b) => a.title > b.title ? 1 : -1);
    }

    //renvoie les cultures appartenant aux parcelles existantes
    initialiseCultures() {
        const { parcelDico } = this.props;

        return ParcelsHelper.mergeCultures(parcelDico);
    }

    /**
     * Function permettant de changer l'onglet à afficher
     */
    handleChangeTab = (event, newValue) => {
        const { setParcelTab } = this.props;

        setParcelTab(newValue);
    }

    /* Ouverture du popup dialog de confirmation de suppression de toutes les parcelle */
    handleClickOpenConfirmDialog = (event) => {
        this.setState({
            openConfirmDialog: true
        });
    }

    /* fermeture de la popup de confirmation de la suppression de toutes les parcelles */
    handleConfirmClose = () => {
        this.setState({
            openConfirmDialog: false
        });
    }

    /* Edition d'une parcelle sélectionnée dans le tableau */
    handleEditClick = (parcel, thumbnailInfos) => {
        this.setState({
            id: parcel.id,
            openUpdateParcel: true,

            name: parcel.name,
            campagne: parcel.details.campagne,
            culture: parcel.details.culture,
            culturePrecedente: parcel.details.culturePrecedente,
            dateSemi: parcel.details.dateSemi,
            exploitation: parcel.details.exploitation,
            surface: parcel.details.surface,
            variete: parcel.details.variete,
            details: parcel.details,
            thumbnailInfos: thumbnailInfos,
        });

    }

    /* Fermeture de la popup de l'édition d'une parcelle */
    handleCloseUpdateParcel = () => {
        this.setState({
            openUpdateParcel: false,
        });
    }

    /**
     * Validation de l'édition d'une parcelle sélectionné
     * @param {number} parcelId id de la parcelle
     */
    handleUpdateParcel = (parcelId) => {
        const {
            name, campagne, culture, culturePrecedente,
            dateSemi, exploitation, variete
        } = this.state;

        const { parcelDico } = this.props;

        if (parcelDico) {
            const parcelToUpdate = lodashGet(parcelDico, `[${parcelId}]`, undefined);

            //Le nom de la parcelle est obligatoire pour mettre à jour les données de la parcelle.
            if (parcelToUpdate && (name !== "")) {
                parcelToUpdate.details.campagne = campagne;
                parcelToUpdate.details.culture = culture;
                parcelToUpdate.details.culturePrecedente = culturePrecedente;
                parcelToUpdate.details.dateSemi = dateSemi;
                parcelToUpdate.details.exploitation = exploitation;
                parcelToUpdate.details.variete = variete;
                parcelToUpdate.name = name;

                //Appel à l'action Redux d'enregistrement des modifications sur la parcelle:
                this.props.updateParcel(parcelId, parcelToUpdate);
            }
        }
    }

    /**
     * Initialisation des colonnes de parcelles.
     * Il y a une traduction sur les listes fixes de cultures, exploitations et variétés
     */
    initialiseColumns(parcelIdFilteredList) {
        const { parcelDico } = this.props;

        let newColumns = [];
        if (parcelIdFilteredList && parcelIdFilteredList[0]) {

            /* ↓ ajout colonne non visible - id de la parcelle ↓ */
            /*newColumns.push({ 
                headerName: "Id", 
                field: "idParcel", 
                editable: true,
                hideable: true,
            }); */

            newColumns.push({
                field: 'actions',
                editable: false,
                hideable: false,
                headerName: '',
                width: 60,
                renderCell: params => {
                    return (
                        <IconButton onClick={() => this.handleEditClick(params.value, params.row.thumbnailInfos)}>
                            <EditIcon color="inherit" />
                        </IconButton>
                    )
                }
            });

            /* ↓ ajout colonne visible - (Miniature + nom de la parcelle) ↓ */
            newColumns.push({
                headerName: `${StringTranslate.nomcolumn}`,
                field: "nom",
                minWidth: 180,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.nomcolumn}</Typography>)
                },
                renderCell: params => {
                    return (
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "center"  }} onClick={() => this.onParcelRowClick(params.row.thumbnailInfos.parcelId)} >
                            {(params) ? (<ThumbnailParcelShapeFromPathInfos id={`listItemTbl_${params.row.thumbnailInfos.parcelId}`} {...params.row.thumbnailInfos} />) : (undefined)}
                            <Typography>{params.value}</Typography>
                        </Box>
                    )
                }
            });

            const firstParcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelIdFilteredList[0]);
            if (firstParcel && firstParcel.details) {
                const dico = dicoFunction();
                for (let detail in firstParcel.details) {
                    dico.forEach(column => {
                        if (detail === column.name) {
                            if (column.name === "variete") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    minWidth: 150,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "exploitation") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    minWidth: 150,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "dateSemi") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    width: 110,
                                    type: "date",
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "campagne") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    width: 100,
                                    type: "date",
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "culture" || column.name === "culturePrecedente") {
                                newColumns.push({
                                    headerName: (column.name === "culture" ?
                                        `${StringTranslate.libelecolumnculture}` :
                                        `${StringTranslate.libelecolumncultureprece}`),
                                    field: column.name,
                                    minWidth: 150,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "surface") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    width: 100,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                        }
                    });
                }
            }
        }
        return newColumns;
    }

    /* fonction pour fermer le dialog de suppresion de parcelles */
    handleCloseDeleteParcels = () => {
        this.setState({
            openDeleteParcels: false,
        });
    }

    /* fonction permettant d'extraire les données qui seront utilisées dans le visuel */
    initialiseDatas(parcelIdFilteredList) {
        const { parcelDico, thumbnailParcelDico } = this.props;
        let parcels = ParcelsHelper.getParcelsDicoFromParcelIdList(parcelDico, parcelIdFilteredList);
        let newDatas = [];

        for (var itemPropName in parcels) {
            let temp = [];
            const parcel = parcels[itemPropName];

            if (parcel) {
                const thumbnailParcelItem = ParcelsHelper.selectParcelFromDicoById(thumbnailParcelDico, parcel.id);

                temp.push(parcel.id);
                temp.push(parcel.name);
                if (parcel.details) {
                    for (let itemDetailName in parcel.details) {
                        const propValue = parcel.details[itemDetailName];
                        let value = (propValue) ? propValue : '';
                        if (itemDetailName.toLowerCase() === 'datesemi') {
                            if (propValue && (propValue instanceof (Date))) {
                                value = DateHelper.formati18n(propValue, 'P');
                            }
                            else if (propValue && (DateHelper.getDateFromString(propValue) instanceof Date)) {
                                value = DateHelper.formati18n(new Date(propValue), 'P');
                            }
                        }
                        else if (itemDetailName.toLowerCase() === 'surface') {
                            if(parcel.area && (parcel.area > 0)) {
                                value = parcel.area;
                            }
                        }
                        temp.push(value);
                    }
                }
                newDatas.push({
                    id: parcel.id,
                    actions: parcel,
                    parcel: parcel,
                    nom: parcel.name,
                    campagne: temp[2],
                    culture: parcel.details.length < 4 ? null : temp[3],
                    culturePrecedente: parcel.details.length < 5 ? null : temp[4],
                    dateSemi: parcel.details.length < 6 ? null : temp[5],
                    exploitation: parcel.details.length < 7 ? null : temp[6],
                    surface: parcel.details.length < 8 ? null : temp[7],
                    variete: parcel.details.length < 9 ? null : temp[8],
                    thumbnailInfos: thumbnailParcelItem,
                });

            }
        }
        return newDatas;
    }

    /* fonction du dialog de suppresion de parcelles pour confirmer la suppresion */
    handleConfirmDeleteParcels = () => {
        const { parcelsSelected, currentRowsOfTable } = this.state;

        //Demande la suppression en BdD (et maJ du store Redux):
        this.props.deleteParcelIdList(parcelsSelected);

        //Si on sélectionne plusieurs parcelles, qu'ensuite, on fait une recherche,
        //il faut que si on supprime des parcelles qui sont dans la liste 'currentRowsOfTable' (liste filtrée après la recherche),
        //que ces parcelles là ne soient pas gardées dans la liste 'currentRowsOfTable' !
        //Attention au paramètre 'keepNonExistentRowsSelected'.
        let newcurrentRowsOfTable = currentRowsOfTable.filter(row => parcelsSelected.includes(row.id) === false);

        //Mise à jour des données exploitées par ce composant:
        this.setState({
            isDeleting: true,
            parcelsSelected: [],
            currentRowsOfTable: newcurrentRowsOfTable,
            /* openDeleteParcels: false, */
        });
    }

    /* Fonction de suppression de parcelles */
    handleRowsDelete() {
        const { parcelDico } = this.props;

        let parcelsWithAtLeastAModulation = false;
        this.state.parcelsSelected.forEach(id => {
            const parcelItem = parcelDico[id];
            if (parcelItem && (parcelItem.hasModulation === true)) {
                parcelsWithAtLeastAModulation = true;
            }
        })

        this.setState({
            openDeleteParcels: true,
            parcelsToDeleteWithModulation: parcelsWithAtLeastAModulation
        });
    }

    /* Fonction correspondant à la zone de recherche uniqueent sur la culture */
    requestSearch(searchValue, rowsOfTable) {
        this.setState({
            searchFilter: searchValue
        });

        try {
            const searchRegex = new RegExp(stringHelper.escapeRegExp(searchValue), 'i');
            const filteredRows = rowsOfTable.filter((row) => {
                return Object.keys(row).some((field) => {
                    let textValue = "" + row[field];
                    return searchRegex.test(textValue.toString());
                });
            });

            this.setState({
                currentRowsOfTable: filteredRows
            });
        }
        catch(errRegex) { /* Peut arriver si on saisis un truc du genre 'EARL++' ! (il n'aime pas les '++') */ }
    }

    //Sélection des parcelles pour commencer la pesée de colza
    setSelectionParcels = (newSelectionParcels) => {
        this.setState({
            parcelsSelected: newSelectionParcels
        });
    }

    //Toolbar customisé du tableau comprenant :
    // - la zone de recherche
    // - le bouton de suppression des parcelles sélectionnées
    CustomToolbar(props) {
        return (
            <Grid container spacing={2} >
                {/* Zone de recherche */}
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={10} sm={8} md={4} lg={4}>
                            <TextField
                                value={props.value}
                                onChange={props.onChange}
                                placeholder={StringTranslate.toolbarsearch}
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>),
                                    endAdornment: (
                                        <IconButton
                                            title="Clear"
                                            aria-label="Clear"
                                            size="small"
                                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                                            onClick={props.clearSearch}
                                        >
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={2} sm={4} md={8}>
                            <Box sx={{ display: { md: 'block', xs: 'none' } }} style={{ textAlign: 'end' }} >
                                <Button
                                    variant="text"
                                    color="error"
                                    onClick={() => props.deleteSelectedParcels()}
                                    disabled={props.parcelsSelected.length > 0 ? false : true}>
                                    {(props.deleting) && <CircularProgress />} {`${StringTranslate.deleteParcels}`}
                                </Button>
                            </Box>

                            <Box sx={{ display: { md: 'none', xs: 'block' } }} style={{ textAlign: 'end' }} >
                                <div>
                                    <IconButton color="error" size="large" aria-label="delete field(s)" component="span"
                                        onClick={() => props.deleteSelectedParcels()}
                                        disabled={props.parcelsSelected.length > 0 ? false : true}>
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    /* Mise à jour des infos de la parcelle */
    handleChangeParcel = (event) => {
        if (event && event.target) { 
            this.setState({
                [event.target.name]: event.target.value
            });
        }
    };

    handleChangeParcelCampagne = (newCampagne) => {
        //console.log(newCampagne);
        if (!newCampagne) return;

        this.setState({
            campagne: new Date(newCampagne).getFullYear()
        });
    }

    handleChangeParceldateSemi = (newDateSemi) => {
        this.setState({
            dateSemi: newDateSemi
        });
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction de rendu visuel - layout général
    ///////////////////////////////////////////////////////////////////////////
    render() {

        //const { deleting } = this.props; // provenant de l'injection Redux depuis 'AbstractParcels'. A utiliser pour le multi parcellaire
        const { deleteParcels, enumTypoClient, subscribe, parcelTab } = this.props; // fonctions mapDispatchToProps

        const { openConfirmDialog, datas, openDeleteParcels,
            parcelsToDeleteWithModulation, searchFilter, currentRowsOfTable, pageSize, parcelsSelected, openUpdateParcel,
            isDeleting
        } = this.state; // etat du dialog popup

        return (
            <>
                { /* ↓↓ partie - Dialog pour confirmer la suppression de toutes les parcelles - ↓↓ */}
                {openConfirmDialog ?
                    <ConfirmDialog
                        openConfirmDialog={openConfirmDialog}
                        handleConfirmClose={this.handleConfirmClose}
                        deleteParcels={() => deleteParcels()}
                        subscribe={() => subscribe()}
                        displaySubscribeBtn={(enumTypoClient === 1) ? true : false}
                        title={`${StringTranslate.suppParcel}`}
                        contentText={(enumTypoClient === 1) ? `${StringTranslate.looseActualImages} ${StringTranslate.confirmSuppParcels}` : `${StringTranslate.confirmSuppParcels}`}
                        buttonSupprimer={`${StringTranslate.suppParcel}`}
                        buttonSAbonner={`${StringTranslate.abonner}`}
                        buttonAnnuler={`${StringTranslate.annuler}`}>
                    </ConfirmDialog> : null}

                { /* ↓↓ partie - Dialog pour supprimer une ou plusieurs parcelles sélectionnées - ↓↓ */}
                {openDeleteParcels ?
                    <Dialog open={openDeleteParcels} onClose={this.handleCloseDeleteParcels} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title-parcels">{StringTranslate.deleteParcels}</DialogTitle>
                        <DialogContent>
                            <DialogContent>
                                <DialogContentText sx={{pb:2}}>                                    
                                    <Typography variant="body1">{StringTranslate.askForDeletingParcels}</Typography>
                                </DialogContentText>
                                {(parcelsToDeleteWithModulation === true) ?
                                    <DialogContentText alignItems="center">
                                        <Grid container>
                                            <Grid item xs={1}><ReportProblemRoundedIcon color="error" /></Grid>
                                            <Grid item xs={11}><Typography variant="body1">{StringTranslate.alertDeleteModulation}</Typography></Grid>
                                        </Grid>
                                    </DialogContentText>
                                : 
                                    null
                                }
                            </DialogContent>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDeleteParcels} variant="text" color="error">
                                {StringTranslate.cancelDeleteAction}
                            </Button>
                            <Button
                                onClick={this.handleConfirmDeleteParcels}
                                variant="contained"
                                color="primary"
                                startIcon={isDeleting && <CircularProgress color="inherit" size={20} />}
                                disabled={isDeleting}>
                                {StringTranslate.confirmDeleteAction}
                            </Button>
                        </DialogActions>
                    </Dialog> : null}

                {openUpdateParcel ?
                    <Dialog
                        /* fullScreen={fullScreen} */
                        sx={{ '& .MuiDialog-paper': { maxHeight: '80%' } }}
                        maxWidth="xs"
                        open={openUpdateParcel}
                        onClose={this.handleCloseUpdateParcel}>
                        <DialogTitle>
                            <Typography>
                                {(this.state.thumbnailInfos) ? (<ThumbnailParcelShapeFromPathInfos id={`listItemTbl_${this.state.thumbnailInfos.parcelId}`} {...this.state.thumbnailInfos} />) : (undefined)}
                                {StringTranslate.updateCrop} {this.state.name}
                            </Typography>
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled={this.props.updatingParcelInfos}
                                            required
                                            error={this.state.name === "" ? true : false}
                                            label={StringTranslate.ColumnPclName}
                                            id="standard-size-small"
                                            name="name"
                                            value={this.state.name}
                                            onChange={this.handleChangeParcel}
                                            helperText={this.state.name === "" ? StringTranslate.champobligatoire : null}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl>
                                        <CustomDatePicker
                                            name="campagne"
                                            disabled={this.props.updatingParcelInfos}
                                            label={StringTranslate.libelecolumncampagne}
                                            value={new Date(new Date().setFullYear(this.state.campagne))}
                                            onChange={(newValue) => this.handleChangeParcelCampagne(newValue)}
                                            placeholder={StringTranslate.formatDatePlaceHolder}
                                            views={['year']}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Autocomplete key="culture"
                                            disabled={this.props.updatingParcelInfos}
                                            name={StringTranslate.libelecolumnculture}
                                            value={this.state.culture}
                                            onChange={
                                                (event, newValue) => {
                                                    if (newValue && newValue.inputValue) {
                                                        this.setState({ culture: newValue.inputValue });
                                                    } else if (newValue && newValue.title) {
                                                        this.setState({ culture: newValue.title });
                                                    } else if (newValue === null) {
                                                        this.setState({ culture: '' });
                                                    }
                                                }
                                            }
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);

                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => inputValue === option.title);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        inputValue,
                                                        title: `${StringTranslate.ajouter3} "${inputValue}"`,
                                                    });
                                                }

                                                return filtered;
                                            }}
                                            options={this.state.cultures}
                                            getOptionLabel={(option) => {
                                                // Ajouter "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                // Value selected with enter, right from the input
                                                if (typeof option.title === 'string') {
                                                    return option.title;
                                                }
                                                // Regular option
                                                return option;
                                            }}
                                            renderOption={(props, option) =>
                                                <li {...props}>
                                                    {(option.title.includes(StringTranslate.ajouter3)) ?
                                                        <Button color="primary" variant="contained">{option.title}</Button> :
                                                        option.title}
                                                </li>}
                                            /* sx={{ width: 300 }} */
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField {...params} label={StringTranslate.libelecolumnculture} />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Autocomplete key="culturepre"
                                            disabled={this.props.updatingParcelInfos}
                                            value={this.state.culturePrecedente}
                                            name={StringTranslate.libelecolumncultureprece}
                                            onChange={
                                                (event, newValue) => {
                                                    if (newValue && newValue.inputValue) {
                                                        this.setState({ culturePrecedente: newValue.inputValue });
                                                    } else if (newValue && newValue.title) {
                                                        this.setState({ culturePrecedente: newValue.title });
                                                    } else if (newValue === null) {
                                                        this.setState({ culturePrecedente: '' });
                                                    }
                                                }
                                            }
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);
                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => inputValue === option.title);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        inputValue,
                                                        title: `${StringTranslate.ajouter3} "${inputValue}"`,
                                                    });
                                                }
                                                return filtered;
                                            }}
                                            options={this.state.cultures}
                                            getOptionLabel={(option) => {
                                                // Ajouter "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                // Value selected with enter, right from the input
                                                if (typeof option.title === 'string') {
                                                    return option.title;
                                                }
                                                // Regular option
                                                return option;
                                            }}
                                            renderOption={(props, option) =>
                                                <li {...props}>
                                                    {(option.title.includes(StringTranslate.ajouter3)) ?
                                                        <Button color="primary" variant="contained">{option.title}</Button> :
                                                        option.title}
                                                </li>}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField {...params} label={StringTranslate.libelecolumncultureprece} />
                                            )}
                                        />

                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl>
                                        <CustomDatePicker
                                            name="dateSemi"
                                            disabled={this.props.updatingParcelInfos}
                                            label={StringTranslate.libelecolumnsemis}
                                            value={(this.state.dateSemi !== null) ? new Date(this.state.dateSemi) : null}
                                            onChange={(newValue) => this.handleChangeParceldateSemi(newValue)}
                                            placeholder={StringTranslate.formatDatePlaceHolder}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disabled={this.props.updatingParcelInfos}
                                            name="exploitation"
                                            value={this.state.exploitation}
                                            onChange={
                                                (event, newValue) => {
                                                    if (!newValue) {
                                                        this.setState({ exploitation: '' });
                                                    }
                                                    else if (newValue && newValue.inputValue) {
                                                        this.setState({ exploitation: newValue.inputValue });
                                                    } else if (newValue && newValue.title) {
                                                        this.setState({ exploitation: newValue.title });
                                                    }
                                                }
                                            }
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);

                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => inputValue === option.title);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        inputValue,
                                                        title: `${StringTranslate.ajouter3} "${inputValue}"`,
                                                    });
                                                }

                                                return filtered;
                                            }}
                                            options={this.state.farms}
                                            getOptionLabel={(option) => {
                                                // Ajouter "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                // Value selected with enter, right from the input
                                                if (typeof option.title === 'string') {
                                                    return option.title;
                                                }
                                                // Regular option
                                                return option;
                                            }}
                                            renderOption={(props, option) =>
                                                <li {...props}>
                                                    {(option.title.includes(StringTranslate.ajouter3)) ?
                                                        <Button color="primary" variant="contained">{option.title}</Button> :
                                                        option.title}
                                                </li>}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField {...params} label={StringTranslate.libelecolumnexploitation} />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disabled={this.props.updatingParcelInfos}
                                            name="variete"
                                            value={this.state.variete}
                                            onChange={
                                                (event, newValue) => {
                                                    if (newValue && newValue.inputValue) {
                                                        this.setState({ variete: newValue.inputValue });
                                                    } else if (newValue && newValue.title) {
                                                        this.setState({ variete: newValue.title });
                                                    } else if (newValue === null) {
                                                        this.setState({ variete: '' });
                                                    }
                                                }
                                            }
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);

                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => inputValue === option.title);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        inputValue,
                                                        title: `${StringTranslate.ajouter3} "${inputValue}"`,
                                                    });
                                                }

                                                return filtered;
                                            }}
                                            options={this.state.varieties}
                                            getOptionLabel={(option) => {
                                                // Ajouter "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                // Value selected with enter, right from the input
                                                if (typeof option.title === 'string') {
                                                    return option.title;
                                                }
                                                // Regular option
                                                return option;
                                            }}
                                            renderOption={(props, option) =>
                                                <li {...props}>
                                                    {(option.title.includes(StringTranslate.ajouter3)) ?
                                                        <Button variant="contained">{option.title}</Button> :
                                                        option.title}
                                                </li>}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField {...params} label={StringTranslate.libelecolumnvariete} />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>

                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="text" color="error" onClick={this.handleCloseUpdateParcel}>{StringTranslate.cancelDeleteAction}</Button>
                            <Button
                                color="primary"
                                disabled={this.props.updatingParcelInfos}
                                autoFocus
                                startIcon={this.props.updatingParcelInfos && <CircularProgress color="inherit" size={25} />}
                                variant="contained"
                                onClick={() => this.handleUpdateParcel(this.state.thumbnailInfos.parcelId)}>
                                {this.props.updatingParcelInfos ?
                                    StringTranslate.downloadModulationInProgress :
                                    StringTranslate.enregistrer}
                            </Button>
                        </DialogActions>
                    </Dialog> : null}

                {/* ↓↓ partie - zone de visualisation des parcelles ↓↓ */}
                <MainCard /*title={`${StringTranslate.listparcels}`}*/ >
                    <Tabs
                        variant_pz="row"
                        value={parcelTab}
                        variant="scrollable"
                        onChange={this.handleChangeTab}
                    >
                        <Tab
                            component={Link}
                            to="#"
                            icon={<ViewList sx={{ fontSize: '1.3rem' }} />}
                            label={StringTranslate.parcellesListBtnOnSidebar}
                            {...buildPropsForTab('menuParcel', 0)} />
                        <Tab
                            component={Link}
                            to="#"
                            icon={<ModelisationIcon sx={{ fontSize: '1.3rem' }} />}
                            label={
                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <Typography>{StringTranslate.newModelisation}</Typography>
                                </Stack>
                            }
                            {...buildPropsForTab('menuParcel', 1)} />
                        
                        <Tab
                            component={Link}
                            to="#"
                            icon={<ModelisationIcon sx={{ fontSize: '1.3rem' }} />}
                            label={
                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <Typography>{StringTranslate.modelisationTitle}</Typography>
                                </Stack>
                            }
                            {...buildPropsForTab('menuParcel', 2)} />
                    </Tabs>
                    <TabPanel value={parcelTab} index={0} prefixId='menuParcel'>
                        <TableContainer>
                            <Grid style={{ width: '100%' }} item xs={12}>
                                {/* partie tableau */}
                                <CustomDataGrid
                                    disableSelectionOnClick={true}
                                    tableName={TableType.parcels}
                                    pageSize={pageSize}
                                    updateNbRowsPerPageTable={this.props.updateNbRowsPerPageTableParcels}
                                    onSelectionModelChange={(newSelectionParcels) => { //Sélection des parcelles par clic checkbox
                                        this.setSelectionParcels(newSelectionParcels);
                                    }}
                                    selectionModel={parcelsSelected}
                                    keepNonExistentRowsSelected={searchFilter !== "" ? true : false}
                                    Toolbar={this.CustomToolbar}
                                    toolbar={{
                                        parcelsSelected: parcelsSelected,
                                        deleteSelectedParcels: () => this.handleRowsDelete(),
                                        //importCrops: () => this.handleClickOpenConfirmDialog(),
                                        parcelDatas: datas,
                                        deleting: this.props.deleting,
                                        //Concerne la zone de recherche dans le tableau :
                                        value: searchFilter,
                                        onChange: (event) => this.requestSearch((event && event.target) ? event.target.value : '', datas),
                                        clearSearch: () => this.requestSearch('', datas)
                                    }}
                                    rows={(searchFilter !== "") ? currentRowsOfTable : datas}
                                    columns={this.columns}
                                    checkBoxActive={true}
                                />
                            </Grid>
                        </TableContainer>
                    </TabPanel>
                    <TabPanel value={parcelTab} index={1} prefixId='menuParcel'>
                        <AbstractModelisation newModelisation />
                    </TabPanel>
                    <TabPanel value={parcelTab} index={2} prefixId='menuParcel'>
                        <AbstractModelisation />
                    </TabPanel>
                </MainCard>

                { }
                {/* Le code de l'importation d'un nouveau parcellaire est en commentaire pour l'instant
                et sera utilisé plus tard pour le mode multi parcellaire */}
                {/* <div>
                    <Card className="card-margin-top">
                        <CardContent classes={{ root: "render-parcels-card-content" }}>
                            <Button 
                                variant="contained" 
                                component="span" 
                                color="primary" 
                                className="render-parcels-delete-button"
                                disabled={deleting} onClick={() => this.handleClickOpenConfirmDialog()}
                            >
                                {`${StringTranslate.suppParcel}`}
                            </Button>
                            {(deleting) && <CircularProgress />}
                        </CardContent>
                    </Card>
                </div> */}
            </>
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    //Infos provenant du reducer 'parcels':
    parcelDico: state.parcelsData.parcelDico,
    parcelDicoCounter: state.parcelsData.parcelDicoCounter,
    parcelIdFilteredList: state.parcelsData.parcelIdFilteredList,
    parcelIdFilteredListCounter: state.parcelsData.parcelIdFilteredListCounter,
    thumbnailParcelDico: (state && state.parcelsData) ? state.parcelsData.thumbnailParcelDico : {},
    updatingParcelName: (state && state.parcelsData) ? state.parcelsData.updatingParcelName : false,
    updatingParcelInfos: (state && state.parcelsData) ? state.parcelsData.updatingParcel : false,
   
    loading: state.parcelsData.loading,
    deleting: state.parcelsData.deleting,
    loadingImportedParcels: state.parcelsData.loadingImportedParcels,

    parcelTab: (state && state.parcelsData) ? state.parcelsData.parcelTab : 0,
    
    //Infos provenant du reducer 'context App':
    farmNameList : state.contextAppData.farmNameList,
    cropNameList : state.contextAppData.cropNameList,

    //Infos provenant du reducer 'settings':
    settings: state.settingsData.settings,
    nameExploitation: (state && state.settingsData && state.settingsData.settings) ? state.settingsData.settings.nameExploitation : '',
    codeCountry: (state && state.settingsData && state.settingsData.settings) ? state.settingsData.settings.codeCountry : '',
    rowsPerPageForTableParcels: (state && state.settingsData && state.settingsData.settings && state.settingsData.settings.rowsPerPageForTableParcels !== undefined) ? state.settingsData.settings.rowsPerPageForTableParcels : 20,
    
    //Infos provenant du reducer 'connection':
    token: (state && state.connectionData) ? state.connectionData.accessToken : undefined,

    //Infos provenant du reducer 'clientUser':
    clientId: (state && state.clientUserData && state.clientUserData.clientDatas) ? state.clientUserData.clientDatas.id : -1,
    enumTypoClient: (state && state.clientUserData && state.clientUserData.clientDatas) ? state.clientUserData.clientDatas.enumTypoClient : -1,
    maxParcelTypoClient: (state && state.clientUserData && state.clientUserData.clientDatas) ? state.clientUserData.clientDatas.maxParcelTypoClient : 3,
    authorizeHistoric: (state && state.clientUserData && state.clientUserData.clientDatas) ? state.clientUserData.clientDatas.authorizeHistoric : true,
    pvLinkActivated: (state && state.clientUserData && state.clientUserData.clientDatas) ? state.clientUserData.clientDatas.pvLinkActivated : false,

    //Infos provenant du reducer 'observation':
    observationIdListByParcelIdDico: state.observationsData.observationIdListByParcelIdDico,

    //Infos provenant du reducer 'didacticiel':
    isDidacticiel: (state.didacticielData && state.didacticielData.firstparcel) ? state.didacticielData.firstparcel.isOpened : false, 
    counterCompletedTutorial: (state.didacticielData && state.didacticielData.firstparcel) ? state.didacticielData.firstparcel.counterCompleted : 0,
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    updateSettings: (newSettingsValue) => dispatch(ActionSaveSettingsAsk(newSettingsValue)),
    updateNbRowsPerPageTableParcels: (rowsPerPage) => dispatch(UpdateNbRowsPerPageTable(rowsPerPage, TableType.parcels)),

    deleteParcels: () => dispatch(ActionDeleteParcelsAsk()),
    deleteParcelIdList: (parcelIdListToDelete) => dispatch( ActionDeleteParcelIdListToAPI( parcelIdListToDelete ) ),
    importParcels: (parcelListDisabledValue, parcelListEnabledValue) => dispatch(ActionImportParcelsAsk(parcelListDisabledValue, parcelListEnabledValue)),

    updateParcel: (parcelId, parcel) => dispatch(ActionSaveParcel(parcelId, parcel)),

    setParcelTab: (indexTab) => dispatch( ActionSetParcelTab(indexTab) ),

    goToMap: () => dispatch(ActionGoToMap()),
    goToMapAndSelectParcel: (parcelId) => dispatch(ActionGoToMapAndSelectParcel(parcelId)),
    subscribe: () => dispatch(ActionGoToProfil()),

    goToSuccessStep: () => dispatch(ActionGoToStepForDidacticielFirstParcel(StepEnumDidacticielFirstParcel.INDICE)),
    saveParcelListImportWhenDidacticielFirstParcel: (selectedParcelListToSave, unselectedParcelListToSave) => dispatch( ActionParcelListImportWhenDidacticielFirstParcel(selectedParcelListToSave, unselectedParcelListToSave) ),

    showProfilMenuDialog: (index) => dispatch( ActionShowProfilMenuDialog(index) ),
    
});

export default connect( mapStateToProps, mapDispatchToProps )(RenderParcels);
