import dateHelper from './dateHelper.js';
import { StateYearMonthOfSatimages } from '../models/stateYearMonthOfSatimages.js';
import { SatimageState } from "../models/stateYearMonthOfSatimages.js";
//import { SatimageDatasParcel } from '../models/satimageDatasParcel.js';


/**
 * Helper pour la manipulation des objets 'dateImgDatasParcel'
 */
export const SatimageDatasParcelHelper = {
    /**
     *  fonction permettant de ...
     */
    selectSatimageStateOfYearMonthFromId: function(parcelData, year, month) {
        if ((!parcelData) || (!year) || (!month) || (!Number.isInteger(year)) || (!Number.isInteger(month)) || (!dateHelper.isValid(year, month))) {
            return undefined;
        }

        if (!parcelData.stateByYearMonthDico) {
            parcelData.stateByYearMonthDico = {};
        }

        //Vérifie si la propriété du même nom que l'identifiant du mois/année existe:
        const yearMonthStr = `${year.toString()}_${month.toString()}`;
        try {
            if ("undefined" !== typeof(parcelData.stateByYearMonthDico[yearMonthStr])) { // elle existe !
                return parcelData.stateByYearMonthDico[yearMonthStr]; // on retourne sa valeur...
            } 
            //else { // elle n'existe pas, on retournera 'undefined'
        } catch(e) {
            //RAS
        }

        return undefined;
    },

    /**
     *  fonction permettant de ...
     */
    addSatimageStateOfYearMonth: function(parcelData, newSatimageStateOfYearMonth) {
        if ((!parcelData) || (!newSatimageStateOfYearMonth) ||  
            (!newSatimageStateOfYearMonth.year) || (!newSatimageStateOfYearMonth.month) || 
            (!Number.isInteger(newSatimageStateOfYearMonth.year)) || (!Number.isInteger(newSatimageStateOfYearMonth.month)) || 
            (!dateHelper.isValid(newSatimageStateOfYearMonth.year, newSatimageStateOfYearMonth.month))) {
            return false;
        }

        if (!parcelData.stateByYearMonthDico) {
            parcelData.stateByYearMonthDico = {};
        }

        //Affecte la propriété du même nom que l'identifiant du mois/année (création si elle n'existait pas déjà):
        const yearMonthStr = `${newSatimageStateOfYearMonth.year.toString()}_${newSatimageStateOfYearMonth.month.toString()}`;
        try {
            parcelData.stateByYearMonthDico[yearMonthStr] = newSatimageStateOfYearMonth; //@@Faudra, sans doute, gérer l'ajout des items si la liste existe déjà !
            parcelData.yearMonthStateDicoCounter += 1;
            
            return true;
        } catch(e) {
            //RAS
        }

        return false;
    },

    /**
     *  fonction permettant de ...
     */
    addSatimages: function(parcelData, newSatimageDico) { //pas encore utilisée !
        if ((!parcelData) || (!newSatimageDico)) { 
            return 0;
        }
        
        if (!parcelData.satimageDico) {
            parcelData.satimageDico = {};
        }

        //Pour chaque date, on vérifie si on a déjà la date ou pas:
        let counterAdded = 0;
        try {         
            for (var itemPropName in newSatimageDico) {
                if (itemPropName) {
                    const itemSatimage = newSatimageDico[itemPropName];
                    if (itemSatimage && (Number.isInteger(itemSatimage.id)) && (itemSatimage.id > 0)) {
                        try 
                        {
                            const imageIdStr = `${itemSatimage.id}`;
                            if ("undefined" !== typeof(parcelData.satimageDico[imageIdStr])) { // elle existe (il y a déjà une entité image de renseigné !)
                                //on ne fait rien !!! Mais peut-être qu'il faudrait actualiser les flux de cette image... dans quel cas cela pourrai arriver ?
                            } else { // The property not exists => on ajoute l'image ! 
                                parcelData.satimageDico[imageIdStr] = itemSatimage;
                                parcelData.satimageDicoCounter += 1;

                                //Recherche de l'entité POCO associée au status des date/image pour le mois/année visé:
                                let dateImgDatasStateForThisMonthYear = SatimageDatasParcelHelper.selectSatimageStateOfYearMonthFromId(parcelData, itemSatimage.year, itemSatimage.month);
                                if (!dateImgDatasStateForThisMonthYear) { //si l'entité n'existe pas encore:
                                    dateImgDatasStateForThisMonthYear = new StateYearMonthOfSatimages({ year: itemSatimage.year, month: itemSatimage.month, parcelId: parcelData.parcelId });
                                    dateImgDatasStateForThisMonthYear.stateAsk = SatimageState.stateNotAsk;
                                    SatimageDatasParcelHelper.addSatimageStateOfYearMonth(parcelData, dateImgDatasStateForThisMonthYear);
                                }
                                
                                dateImgDatasStateForThisMonthYear.satimageCounter += 1;
                                //surtout, on ne change pas ici le statut de cette 'année/mois', ni de cette entité (typé 'SatimageDatasParcel') liée à la parcelle !
                                
                                counterAdded += 1;
                            }
                        } catch(e) {
                            //RAS
                        }
                    }
                }
            }
        } catch(eGlobal) {
            //RAS
        }

        return counterAdded;
    },

    /**
     *  fonction permettant de ...
     */
    countSatimageStateOfYearMonthOnState: function(parcelData, satimageStateTosearch) {
        if ((!parcelData) || (!satimageStateTosearch)) {
            return 0;
        }

        if (!parcelData.stateByYearMonthDico) {
            parcelData.stateByYearMonthDico = {};
        }

        let counterSearch = 0;
        try {
            for (var itemPropName in parcelData.stateByYearMonthDico) {
                if (itemPropName && (itemPropName.indexOf('_') > 0)) {
                    const itemDateImgsDatasState = parcelData.stateByYearMonthDico[itemPropName];
                    if (itemDateImgsDatasState) {
                        //Vérifie si l'entité POCO associée à la liste des date/image a le status recherché:
                        if (itemDateImgsDatasState.stateAsk === satimageStateTosearch) {
                            counterSearch += 1;
                        }
                    }
                    //else //la valeur de la propriété n'est pas du type attendue.
                }
                //else //pas une propriété dont le nom identifie une année/mois.
            }
        } catch(e) {
            //RAS
        }

        return counterSearch;
    },

    /**
     *  fonction permettant de ...
     */
    selectSatimgeFromDate: function(parcelData, date) { //'date' est un entier !
        if ((!parcelData) || (!date) || (!Number.isInteger(date))) {
            return undefined;
        }
        
        if (!parcelData.satimageDico) {
            parcelData.satimageDico = {};
            return undefined;
        }

        // vérifie si la propriété du même nom que l'identifiant de la date existe:
        //@@extraction du : jour, mois,année
        try {
            //@@recherche dans le dico
        } catch(e) {
            //RAS
        }

        return undefined;
    },

    /**
     *  fonction permettant de récupérer le premier élément du dico des 'Satimage'
     */
    getFirstSatimageFromDico(parcelData) { 
        if ((!parcelData) || 
            (!parcelData.satimageDico) || !(parcelData.satimageDico instanceof Object) || (Object.keys(parcelData.satimageDico).length <= 0)) {
            return undefined;
        }

        const firstSatimage = Object.keys(parcelData.satimageDico)[0];
        
        return firstSatimage;
    },

    selectImageFromId(parcelData, imageId) {
        if ((!parcelData) || (!parcelData.satimageDico) || !(parcelData.satimageDico instanceof Object) || 
            (!imageId) || (!Number.isInteger(imageId)) || (imageId <= 0)) {
            return undefined;
        }

        //Vérifie si la propriété du même nom que l'identifiant de la parcelle existe:
        const imageIdStr = imageId.toString();
        try {
            if ("undefined" !== typeof(parcelData.satimageDico[imageIdStr])) { // elle existe !
                return parcelData.satimageDico[imageIdStr];
            } 
            //else { // elle n'existe pas, on va retourner 'undefined' !
        } catch(e) {
            //RAS
        }

        return undefined;
    },

    addSatimageToParcel(parcelData, newSatimage) {
        if ((!parcelData) || (!newSatimage) || (!Number.isInteger(newSatimage.id)) || (newSatimage.id > 0)) { 
            return 0;
        }
        
        if (!parcelData.satimageDico) {
            parcelData.satimageDico = {};
        }

        let counterAdded = 0;
        try 
        {
            const imageIdStr = `${newSatimage.id}`;
            if ("undefined" !== typeof(parcelData.satimageDico[imageIdStr])) { // elle existe (il y a déjà une entité image de renseigné !)
                //on ne fait rien !!! Mais peut-être qu'il faudrait actualiser les flux de cette image... dans quel cas cela pourrai arriver ?
            } else { // The property not exists => on ajoute l'image ! 
                parcelData.satimageDico[imageIdStr] = newSatimage;
                parcelData.satimageDicoCounter += 1;

                //Recherche de l'entité POCO associée au status des date/image pour le mois/année visé:
                let dateImgDatasStateForThisMonthYear = SatimageDatasParcelHelper.selectSatimageStateOfYearMonthFromId(parcelData, newSatimage.year, newSatimage.month);
                if (!dateImgDatasStateForThisMonthYear) { //si l'entité n'existe pas encore:
                    dateImgDatasStateForThisMonthYear = new StateYearMonthOfSatimages({ year: newSatimage.year, month: newSatimage.month, parcelId: parcelData.parcelId });
                    SatimageDatasParcelHelper.addSatimageStateOfYearMonth(parcelData, dateImgDatasStateForThisMonthYear);
                }

                counterAdded += 1;
            }
        } catch(e) {
            //RAS
        }

        return counterAdded;
    },

    /**
     *  fonction permettant de ...
     */
    selectAllDate: function(parcelData) {
        if (!parcelData) {
            return [];
        }

        if (!parcelData.satimageDico) {
            parcelData.satimageDico = {};
        }

        let dateList = [];
        try {
            for (var itemPropName in parcelData.satimageDico) {
                if (itemPropName) {
                    const itemSatimage = parcelData.satimageDico[itemPropName];
                    if (itemSatimage && itemSatimage.date && itemSatimage.hasdata) {
                        const thisDate = new Date(itemSatimage.date);
                        let dateToMidnight = dateHelper.setDateToMidnight(thisDate);
                        if (dateToMidnight) {
                            dateList.push(dateToMidnight);
                         } else {
                             dateList.push(thisDate);
                         }
                    }
                    //else //la valeur de la propriété n'est pas du type attendue.
                }
                //else //pas une propriété dont le nom identifie une année/mois.
            }
        } catch(e) {
            //RAS
        }

        return dateList;
    },

    /**
     *  fonction permettant de créer une liste de date dont le taux d'ennuagement est supérieur au taux d'ennuagement client (maxcc) à partir d'une fraction du state satImage (satImagesByParcelDico[parcelId])
     */
    selectAllDateWithHigherMaxcc: function(satImageDatasByParcelId) {

        if (satImageDatasByParcelId && satImageDatasByParcelId.satImageWithHigherMaxccDico) {
            let dateList = [];
            Object.values(satImageDatasByParcelId.satImageWithHigherMaxccDico).forEach( satImage => {
                let date = dateHelper.getDateFromString(satImage.date, true);
                if (date) dateList.push(date);
            });
            return dateList;
        }
        else
            return [];
    },
}
