import PropTypes from 'prop-types';
import DateHelper from '../utils/dateHelper';

class detailsParcel extends Object {

    constructor(props) {
        super(props);

        if (props !== undefined) {
            this.campagne = props.campagne || new Date().getUTCFullYear();
            this.culture = props.culture || "";
            this.culturePrecedente = props.culturePrecedente || "";
            this.dateSemi = props.dateSemi ? DateHelper.getDateFromString(props.dateSemi) : null;
            this.exploitation = props.exploitation || "";
            this.surface = props.surface || 0;
            this.variete = props.variete || "";
        } else {
            this.campagne = new Date().getUTCFullYear();
            this.culture = "";
            this.culturePrecedente = "";
            this.dateSemi = null;
            this.exploitation = "";
            this.surface = 0;
            this.variete = "";
        }
    }
}

export default detailsParcel;

detailsParcel.defaultProps = {};
detailsParcel.propTypes = {
    campagne: PropTypes.number,
    culture: PropTypes.string,
    culturePrecedente: PropTypes.string,
    dateSemi: PropTypes.instanceOf(Date),
    exploitation: PropTypes.string,
    surface: PropTypes.number,
    variete: PropTypes.string,
}