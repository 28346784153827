import { 
        Autocomplete, Button, Dialog, DialogActions, DialogContent, 
        DialogTitle, FormControl, Grid, TextField, Typography, CircularProgress
    } from '@mui/material';
import CustomDatePicker  from '../customDatePicker.jsx';
import React from 'react';
import StringTranslate from '../../assets/i18n/stringLanguage';
import { ParcelsHelper } from '../../utils/parcelsHelper';
import lodashGet from 'lodash/get';
import { ThumbnailParcelShapeFromPathInfos } from '../thumbnail/ThumbnailParcelShape';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { ActionCloseParcelEdit } from '../../redux/actions/contextApp';
import { ActionSaveParcel } from '../../redux/actions/parcels';
import { connect } from 'react-redux';

const filter = createFilterOptions();

/**
 * Définit le Dialog qui permet la modification des infos d'une parcelle.
 * 
 * Utilisation :
 *  - appeler => \<UpdateParcelInfo/\>
 *  - Action à lier au onClick() => ActionOpenParcelEdit(parcel, thumbnailInfos)
 * 
 * Infos :
 *  - l'ouverture et la fermeture est gérée par Redux avec la variable 'contextAppData.openDialogParcelEdit' qui provient de ContextApp
 */
class UpdateParcelInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            //Valeur affectée aux différentes données d'une parcelle en cours de modification:
            id: props.parcelEdit_Infos.id,
            name: props.parcelEdit_Infos.name,
            campagne: props.parcelEdit_Infos.details.campagne,
            culture: props.parcelEdit_Infos.details.culture,
            culturePrecedente: props.parcelEdit_Infos.details.culturePrecedente,
            dateSemi: props.parcelEdit_Infos.details.dateSemi,
            exploitation: props.parcelEdit_Infos.details.exploitation,
            surface: props.parcelEdit_Infos.surface,
            variete: props.parcelEdit_Infos.details.variete,
            details: props.parcelEdit_Infos.details,
            thumbnailInfos: props.parcelEdit_ThumbnailsInfos,

            openUpdateParcel: props.openDialogParcelEdit,
            
            cultures: this.initialiseCultures(), //initialisation des cultures : cultures fixes + cultures existantes dans les parcelles
            varieties: this.initialiseVarieties(), // initialisation des variétés : bouton "ajouter une variété" + variétés existantes dans les parcelles
            farms: this.initialiseFarms(), // initialisation des exploitations : bouton "ajouter une exploitation" + exploitations existantes dans les parcelles 
        }
    }

    componentDidUpdate(prevProps) {
        const { parcelIdFilteredList, updatingParcelName, updatingParcelInfos } = this.props;

        if (((prevProps.updatingParcelName === true) && (updatingParcelName === false)) ||
            ((prevProps.updatingParcelInfos === true) && (updatingParcelInfos === false))) {
                const newCultureList = this.initialiseCultures();
                const newVarietiesList = this.initialiseVarieties();
                const newFarmList = this.initialiseFarms();
                const newDatas = this.initialiseDatas(parcelIdFilteredList);


                this.setState({
                    datas: newDatas,
                    openUpdateParcel: false,
                    cultures: newCultureList,
                    varieties: newVarietiesList,
                    farms: newFarmList,
                });
            }
    }

    //renvoie les exploitations appartenant aux parcelles existantes
    initialiseFarms() {
        const { parcelDico } = this.props;

        let farms = [];

        //Ajout des nom d'exploitations trouvés dans la définition des parcelles:
        ParcelsHelper.searchFarms(parcelDico)
            .forEach(farm => {
                if (!farms.find(f => f.title === farm))
                    farms.push({ title: farm });
            });

        return Array.from(new Set(farms));
    }
    // renvoie les variétés appartenant aux parcelles existantes
    initialiseVarieties() {
        const { parcelDico } = this.props;
        let varieties = [];

        //Ajout des variétés trouvées dans la définition des parcelles:
        ParcelsHelper.searchVarieties(parcelDico)
            .forEach(variety => {
                if (!varieties.find(v => v.title === variety)) {
                    varieties.push({ title: variety });
                }
            });

        return varieties.sort((a, b) => a.title > b.title ? 1 : -1);
    }
    //renvoie les cultures appartenant aux parcelles existantes
    initialiseCultures() {
        const { parcelDico } = this.props;

        return ParcelsHelper.mergeCultures(parcelDico);
    }

    /* Edition d'une parcelle sélectionnée dans le tableau */
    handleEditClick = (parcel, thumbnailInfos) => {
        this.setState({
            id: parcel.id,
            openUpdateParcel: true,

            name: parcel.name,
            campagne: parcel.details.campagne,
            culture: parcel.details.culture,
            culturePrecedente: parcel.details.culturePrecedente,
            dateSemi: parcel.details.dateSemi,
            exploitation: parcel.details.exploitation,
            surface: parcel.details.surface,
            variete: parcel.details.variete,
            details: parcel.details,
            thumbnailInfos: thumbnailInfos,
        });
    }

    /* Fermeture de la popup de l'édition d'une parcelle */
    handleCloseUpdateParcel = () => {
        this.props.closeParcelUpdate();
    }

    /* Validation de l'édition d'une parcelle sélectionnée */
    handleUpdateParcel = () => {
        const {
            name, campagne, culture,
            culturePrecedente, dateSemi, exploitation,
            variete, id /* id de la parcelle sélectionnée pour modification de données */
        } = this.state;

        const { parcelDico, updateParcel } = this.props;

        if (parcelDico) {
            const parcelToUpdate = lodashGet(parcelDico, `[${id}]`, undefined);

            //Le nom de la parcelle est obligatoire pour mettre à jour les données de la parcelle.
            if (name !== "") {

                parcelToUpdate.details.campagne = campagne;
                parcelToUpdate.details.culture = culture;
                parcelToUpdate.details.culturePrecedente = culturePrecedente;
                parcelToUpdate.details.dateSemi = dateSemi;
                parcelToUpdate.details.exploitation = exploitation;
                parcelToUpdate.details.variete = variete;
                parcelToUpdate.name = name;

                //Appel à l'action Redux d'enregistrement des modifications sur la parcelle:
                updateParcel(id, parcelToUpdate);
            }
        }
    }

    /* Mise à jour des infos de la parcelle */
    handleChangeParcel = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleChangeParcelCampagne = (newCampagne) => {
        this.setState({
            campagne: new Date(newCampagne).getFullYear()
        });
    }

    handleChangeParceldateSemi = (newDateSemi) => {
        this.setState({
            dateSemi: newDateSemi
        });
    }

    render() { 
        const {
            name, campagne, culture, culturePrecedente, dateSemi, exploitation, 
            variete, thumbnailInfos, openUpdateParcel, cultures, farms, varieties
        } = this.state;
        const {
            updatingParcelInfos
        } = this.props;
        return (             
            <Dialog
                sx={{ '& .MuiDialog-paper': { maxHeight: '80%' } }}
                maxWidth="xs"
                open={openUpdateParcel}
                onClose={this.handleCloseUpdateParcel}>
                <DialogTitle>
                    <Typography>
                        {(thumbnailInfos) ? (<ThumbnailParcelShapeFromPathInfos id="parcelShape" {...thumbnailInfos} />) : (undefined)}
                        {StringTranslate.updateCrop} {name}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    disabled={updatingParcelInfos}
                                    required
                                    error={(name === "") ? true : false}
                                    label={StringTranslate.ColumnPclName}
                                    id="standard-size-small"
                                    name="name"
                                    value={name}
                                    onChange={this.handleChangeParcel}
                                    helperText={(name === "") ? StringTranslate.champobligatoire : null}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl>
                                <CustomDatePicker
                                    onChange={(newValue) => this.handleChangeParcelCampagne(newValue)}
                                    name="campagne"
                                    views={['year']}
                                    label={StringTranslate.libelecolumncampagne}
                                    disabled={updatingParcelInfos}
                                    value={new Date(new Date().setFullYear(campagne))}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Autocomplete key="culture"
                                    disabled={updatingParcelInfos}
                                    name={StringTranslate.libelecolumnculture}
                                    value={culture}
                                    onChange={
                                        (event, newValue) => {
                                            if (newValue && newValue.inputValue) {
                                                this.setState({ culture: newValue.inputValue });
                                            } else if (newValue && newValue.title) {
                                                this.setState({ culture: newValue.title });
                                            } else if (newValue === null) {
                                                this.setState({ culture: '' });
                                            }
                                        }
                                    }
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option.title);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                inputValue,
                                                title: `${StringTranslate.ajouter3} "${inputValue}"`,
                                            });
                                        }

                                        return filtered;
                                    }}
                                    options={cultures}
                                    getOptionLabel={(option) => {
                                        // Ajouter "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Value selected with enter, right from the input
                                        if (typeof option.title === 'string') {
                                            return option.title;
                                        }
                                        // Regular option
                                        return option;
                                    }}
                                    renderOption={(props, option) =>
                                        <li {...props} key={option.title}>
                                            {(option.title.includes(StringTranslate.ajouter3)) ?
                                                <Button color="primary" variant="contained">{option.title}</Button> :
                                                option.title}
                                        </li>}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} label={StringTranslate.libelecolumnculture} />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Autocomplete key="culturepre"
                                    disabled={updatingParcelInfos}
                                    value={culturePrecedente}
                                    name={StringTranslate.libelecolumncultureprece}
                                    onChange={
                                        (event, newValue) => {
                                            if (newValue && newValue.inputValue) {
                                                this.setState({ culturePrecedente: newValue.inputValue });
                                            } else if (newValue && newValue.title) {
                                                this.setState({ culturePrecedente: newValue.title });
                                            } else if (newValue === null) {
                                                this.setState({ culturePrecedente: '' });
                                            }
                                        }
                                    }
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option.title);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                inputValue,
                                                title: `${StringTranslate.ajouter3} "${inputValue}"`,
                                            });
                                        }
                                        return filtered;
                                    }}
                                    options={cultures}
                                    getOptionLabel={(option) => {
                                        // Ajouter "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Value selected with enter, right from the input
                                        if (typeof option.title === 'string') {
                                            return option.title;
                                        }
                                        // Regular option
                                        return option;
                                    }}
                                    renderOption={(props, option) =>
                                        <li {...props}>
                                            {(option.title.includes(StringTranslate.ajouter3)) ?
                                                <Button color="primary" variant="contained">{option.title}</Button> :
                                                option.title}
                                        </li>}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} label={StringTranslate.libelecolumncultureprece} />
                                    )}
                                />

                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl>
                                <CustomDatePicker
                                    label={StringTranslate.libelecolumnsemis}
                                    name="dateSemi"
                                    disabled={updatingParcelInfos}
                                    value={(dateSemi !== null) ? new Date(dateSemi) : null}
                                    onChange={(newValue) => this.handleChangeParceldateSemi(newValue)}
                                    placeholder= {StringTranslate.formatDatePlaceHolder}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    disabled={updatingParcelInfos}
                                    name="exploitation"
                                    value={exploitation}
                                    onChange={
                                        (event, newValue) => {
                                            if (!newValue) {
                                                this.setState({ exploitation: '' });
                                            }
                                            else if (newValue && newValue.inputValue) {
                                                this.setState({ exploitation: newValue.inputValue });
                                            } else if (newValue && newValue.title) {
                                                this.setState({ exploitation: newValue.title });
                                            }
                                        }
                                    }
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option.title);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                inputValue,
                                                title: `${StringTranslate.ajouter3} "${inputValue}"`,
                                            });
                                        }

                                        return filtered;
                                    }}
                                    options={farms}
                                    getOptionLabel={(option) => {
                                        // Ajouter "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Value selected with enter, right from the input
                                        if (typeof option.title === 'string') {
                                            return option.title;
                                        }
                                        // Regular option
                                        return option;
                                    }}
                                    renderOption={(props, option) =>
                                        <li {...props}>
                                            {(option.title.includes(StringTranslate.ajouter3)) ?
                                                <Button color="primary" variant="contained">{option.title}</Button> :
                                                option.title}
                                        </li>}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} label={StringTranslate.libelecolumnexploitation} />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    disabled={updatingParcelInfos}
                                    name="variete"
                                    value={variete}
                                    onChange={
                                        (event, newValue) => {
                                            if (newValue && newValue.inputValue) {
                                                this.setState({ variete: newValue.inputValue });
                                            } else if (newValue && newValue.title) {
                                                this.setState({ variete: newValue.title });
                                            } else if (newValue === null) {
                                                this.setState({ variete: '' });
                                            }
                                        }
                                    }
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option.title);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                inputValue,
                                                title: `${StringTranslate.ajouter3} "${inputValue}"`,
                                            });
                                        }

                                        return filtered;
                                    }}
                                    options={varieties}
                                    getOptionLabel={(option) => {
                                        // Ajouter "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Value selected with enter, right from the input
                                        if (typeof option.title === 'string') {
                                            return option.title;
                                        }
                                        // Regular option
                                        return option;
                                    }}
                                    renderOption={(props, option) =>
                                        <li {...props}>
                                            {(option.title.includes(StringTranslate.ajouter3)) ?
                                                <Button variant="contained">{option.title}</Button> :
                                                option.title}
                                        </li>}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} label={StringTranslate.libelecolumnvariete} />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" color="error" onClick={this.handleCloseUpdateParcel}>{StringTranslate.cancelDeleteAction}</Button>
                    <Button
                        color="primary"
                        disabled={updatingParcelInfos}
                        autoFocus
                        startIcon={updatingParcelInfos ? <CircularProgress color="inherit" size={25} /> : null}
                        variant="contained"
                        onClick={this.handleUpdateParcel}>
                        {updatingParcelInfos ?
                            StringTranslate.downloadModulationInProgress :
                            StringTranslate.enregistrer}
                    </Button>
                </DialogActions>
            </Dialog>
         );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
    updatingParcelName: lodashGet(state, 'parcelsData.updatingParcelName', false),
    updatingParcelInfos: lodashGet(state, 'parcelsData.updatingParcel', false),

    openDialogParcelEdit: lodashGet(state, 'contextAppData.openDialogParcelEdit', false),
    parcelEdit_Infos: lodashGet(state, 'contextAppData.parcelEdit_Infos', []),    
    parcelEdit_ThumbnailsInfos: lodashGet(state, 'contextAppData.parcelEdit_ThumbnailsInfos', []),    
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    updateParcel: (parcelId, parcel) => dispatch(ActionSaveParcel(parcelId, parcel)),

    closeParcelUpdate: () => dispatch(ActionCloseParcelEdit())
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateParcelInfo);