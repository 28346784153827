import React from 'react';
import { Stack, CircularProgress, Button, Typography } from '@mui/material';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
//import { IsNativeHoster } from '../../utils/platformHelper';

import getTheme from "../../themes/index.js";
let theme = getTheme();

/**
 * Composant permettant de souscrire à un abonnement (reécupération du token de carte et création d'un customer)
 * l'objet this.props.subsciptionProcess est utilisé comme objet contenant les informations de souscription en cours de progression
 */
class WaitBuyByStore extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showGoBack: false,
        };

        this.waitResultBuy = false;

        this.handleBuy = this.handleBuy.bind(this);
        this.handleBack = this.handleBack.bind(this);
        //RQ: ce n'est pas ce composant qui fait l'appel à l'achat, mais 'stripeSubscribeProcess' (pour gagner en temps d'attente).
        // RQ bis: De même, c'est le message reçu de l'application Native signalant le résultat de l'achat qui fera passer à l'étape suivante (chat OK ou erreur pendant l'achat)
    }

    componentDidMount() {
        ///RAS !!!
    }

    componentDidUpdate(prevProps /*prevState*/) {
        const { showPurchaseOnStoreResult } = this.props;

        // si on a un retour de l'achat In-App (bon ou pas), on passe à l'étape d'après:
        if ( ((!prevProps) || (prevProps.showPurchaseOnStoreResult !== true)) && (showPurchaseOnStoreResult === true)) {
            if (this.props.handleNextStep) {
                this.props.handleNextStep();
            }
            //else //aie...
        }
        //else //RAS !!!
    }

    handleBuy() {
        // On déclenche l'achat du Store mobile en apelant l'application native:
        if ((this.waitResultBuy !== true) && this.props.callBuyOnNativeApp) {
            this.waitResultBuy = true;
        
            //Appel au natif:
            this.props.callBuyOnNativeApp(); //le plan Stripe permettra de retrouver/identifier le plan du store qui convient !
            
            // Au bout d'un temps donné, si on a pas reçu le message que l'achat par le Store a abouti (ou échoué), 
            // on propose un bout de retour en arrière:
            setTimeout(() => {
                this.setState({ showGoBack: true, });
            }, 20000); //20s
        }
        //else //Aie !!!
    }

    handleBack() {
        this.waitResultBuy = false;
        
        // ↓ on revient à l'étape précèdente ↓ 
        if (this.props.handlePreviousStep) {
            this.props.handlePreviousStep();
        }
    }

    render() {
        const { showGoBack } = this.state;
        const { showPurchaseOnStoreResult, subscriptionProcess } = this.props;

        const errorMessage = (subscriptionProcess) ? subscriptionProcess.error : undefined;

        return (
            <Stack spacing={2} direction="column" alignContent="center" alignItems="center">
                {(this.waitResultBuy !== true) ? (<>
                    <Typography variant='body1' component="span">{StringTranslate.explainBuyByStore}</Typography>
                    <Button variant='contained' color="primary" onClick={this.handleBuy}>{StringTranslate.purchaseStore}</Button>
                </>) : (<>
                    {(showPurchaseOnStoreResult === true) && (errorMessage && (errorMessage !== '')) ? (
                        <Typography variant="body2" component="span" style={{ color: theme.palette.contour.selection, }}>
                            {errorMessage}
                        </Typography>
                    ) : (
                        <CircularProgress color='primary' variant='indeterminate' />
                    )}
                    
                    {(showGoBack === true) ? (<>
                        <Typography variant='body1' component="span">
                            {StringTranslate.buyByStoreRequireTime}
                        </Typography>
                        <Button variant='outlined' color="primary" onClick={this.handleBack}>{StringTranslate.goBackPurchaseStoreWaiting}</Button>
                    </>) : (
                        <Typography variant='body1' component="span">
                            {StringTranslate.pleaseWaitBuyByStore}
                        </Typography>
                    )}
                </>)}
            </Stack>
        );
    }
}

export default WaitBuyByStore;
