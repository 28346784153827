import React from "react";
import { connect } from 'react-redux';
import { Button, TextField, 
    Grid, InputLabel, Stack, Alert, Autocomplete } from "@mui/material";
import StringTranslate from "../../assets/i18n/stringLanguage";
import { ActionSaveUpdatedClientBilling } from "../../redux/actions/clientUser";
import { ActionSaveSettingsAsk } from '../../redux/actions/settings.js';

import countryLookupDatas from '../../datas/countryPickerList.json';
import { validateCommonValue, validatePostalCode } from './userInfo';
import '../../assets/css/profil.css';
import { geoSearch } from '../../utils/api_search_geo.js';
import sendError from '../../utils/errorService.js';

import getTheme from "../../themes/index.js";

let theme = getTheme();


class AdresseFacturationInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            completeAddress: props.completeAddress,
            country: (props.completeAddress && props.completeAddress.country) ? props.completeAddress.country : '',
            codeCountry: props.codeCountry,
            postalCode: (props.completeAddress && props.completeAddress.postalCode) ? props.completeAddress.postalCode : '',
            city: (props.completeAddress && props.completeAddress.cityName) ? props.completeAddress.cityName : '',
            adressePostale: (props.completeAddress && props.completeAddress.label) ? props.completeAddress.label : '',

            onEdit_Adresse: false,

            diabledVilleTextField: false,
            cities: [],

            inputCountryValue: (props.completeAddress && props.completeAddress.country) ? props.completeAddress.country : '',
            inputCityValue: (props.completeAddress && props.completeAddress.cityName) ? props.completeAddress.cityName : '',

            requiredDatasNeed: false, 

            lastChange: undefined,
        };

        this.hasSubscribePlan = (props.clientDatas && props.clientDatas.planSubscriptions && Array.isArray(props.clientDatas.planSubscriptions) && 
            (props.clientDatas.planSubscriptions.length > 0)) ? true : false;
        this.countryList = (props && props.language) ? countryLookupDatas[props.language] : countryLookupDatas['FR'];

        this.onClickCancelAdresseFacturation = this.onClickCancelAdresseFacturation.bind(this);
        this.onClickSaveAdresseFacturation = this.onClickSaveAdresseFacturation.bind(this);
    }

    componentDidMount() {
        const { postalCode, city, codeCountry } = this.state;

        this.searchCitiesFromPostalCode(postalCode);

        //Si on n'a pas de code postal mais une ville, on recherche des villes pouvant correspondre à la saisie (d'après l'API externe):
        if ((validatePostalCode(postalCode, codeCountry) !== true) && (validateCommonValue(city) === true)) {
            this.searchCitiesFromCityName(city);
        }

        //@@vérifie le pays fournis par rapport à la liste affichée (qui dépends de la langue courante) ; Et si pas là , on ferai quoi ???
    }

    componentDidUpdate(prevProps, prevState) {
        const { language } = this.props;

        if ((!prevProps) || (!prevProps.language) || (prevProps.language !== language)) { //si la langue courante change,
            // on actualise la liste des pays dispo !
            this.countryList = (language) ? countryLookupDatas[language] : countryLookupDatas['FR'];
        }
    }

    onClickCancelAdresseFacturation = () => {
        const { completeAddress, codeCountry } = this.props;

        const newPostalCode = (completeAddress && completeAddress.postalCode) ? completeAddress.postalCode : '';
        let postalCodeOk = validatePostalCode(newPostalCode, codeCountry);
        let newDisabledVilleTextField = !postalCodeOk;
        if ((codeCountry === "FR") || (codeCountry === "ES")) {
            if (newPostalCode.length !== 5) {
                newDisabledVilleTextField = true;

                postalCodeOk = false;
            } 
            //else les variables sont déjà définit !
        } 
        //else les variables sont déjà définit !

        this.setState({
            country: (completeAddress && completeAddress.country) ? completeAddress.country : '',
            inputCountryValue: (completeAddress && completeAddress.country) ? completeAddress.country : '', 
            postalCode: newPostalCode, 
            city: (completeAddress && completeAddress.cityName) ? completeAddress.cityName : '', 
            inputCityValue: (completeAddress && completeAddress.cityName) ? completeAddress.cityName : '', 
            adressePostale: (completeAddress && completeAddress.label) ? completeAddress.label : '', 
            onEdit_Adresse: false,
            diabledVilleTextField: newDisabledVilleTextField, 
            requiredDatasNeed: false, 
            lastChange: undefined,
        });
    }

    onClickSaveAdresseFacturation = () => {
        // ↓↓ création nouvel objet ↓↓
        const { clientDatas } = this.props;
        const { adressePostale, postalCode, city, country, codeCountry } = this.state;

        if ((this.hasSubscribePlan !== true) || (
            (validateCommonValue(adressePostale) === true) && (validatePostalCode(postalCode, codeCountry) === true) &&
            (validateCommonValue(city) === true) && (validateCommonValue(country) === true) )) { //on peut enregistrer !
            let newClientDatas = {
                ...clientDatas,
                address: {
                    label: adressePostale,
                    postalCode: postalCode,
                    cityName: city,
                    country: country,
                }
            };
            
            this.saveCodeCountryToSettings();

            // ↓↓ enregistrement BDD + redux ↓↓ 
            this.props.saveUpdatedClientBilling(newClientDatas);
                /*.then((result) => {
                    console.log('saveUpdatedClientBilling - result: ', result);
                })
                .catch( (err) => {
                    console.log('saveUpdatedClientBilling (err:)', err);
                });*/ //pas nécessaire !
            
            this.setState({ 
                onEdit_Adresse: false, 
                requiredDatasNeed: false, 
                lastChange: undefined, 
            });        
        } else {
            //affiche le message demandant la saisie du(des) champs requis:
            this.setState({ requiredDatasNeed: true, });
            //programme la disparition du message:
            setTimeout(() => {
                this.setState({ requiredDatasNeed: false, });
            }, 5000);
        }
    }

    /* fonction permettant de requêter une API pour récupérer le nom des villes */
    searchCitiesFromPostalCode(postalCode) {
        if (!postalCode) return;

        const { codeCountry } = this.state;

        geoSearch.getCitiesFromPostalCode(codeCountry, postalCode)
            .then((result) => {                
                if (result) {
                    if (result.length === 1) {
                        this.setState({ cities: result, city: result[0], }); // force la seule ville avec ce code postal !
                    } else if (result.length > 1) {
                        this.setState({ cities: result, }); //touche pas à city qui a peut être été alimenté !
                    } else {
                        this.setState({ cities: (result) ? result : [], }); //touche pas à city qui a peut être été alimenté !
                    }
                } else {
                    this.setState({ cities: [], }); //touche pas à city qui a peut être été alimenté !
                }
            })
            .catch( (err) => {
                sendError('adresseFacturationInfo - searchCitiesFromPostalCode', { 
                    "error": err, 
                    "postalCode": postalCode, 
                    "codeCountry": codeCountry, 
                });
            });
    }

    /* fonction permettant de requêter une API pour récupérer le nom des villes suivant une recherche sur le champ ville */
    searchCitiesFromCityName(city) {
        if ((!city) || (city === '')) return;

        const { codeCountry } = this.state;
        
        geoSearch.getCitiesFromCitySearch(city, codeCountry)
            .then((result) => {
                if (result && result.length === 1) {
                    this.setState({ cities: result, city: result[0] });
                }
            })
            .catch( (err) => {
                sendError('adresseFacturationInfo - searchCitiesFromCityName', { 
                    "error": err, 
                    "citySearch": city, 
                    "codeCountry": codeCountry, 
                });
            });
    }

    saveCodeCountryToSettings() {
        const  { codeCountry, completeAddress, settings } = this.props;
        const oldCountry = (completeAddress && completeAddress.country) ? completeAddress.country : '';

        //enregistre en BdD les changement UNIQUEMENT s'il y en a eu ... sur le pays !
        if ((oldCountry !== this.state.country) || (codeCountry !== this.state.codeCountry)) {
            let settingsToSave = {
                ...settings,
                codeCountry: this.state.codeCountry,
            };
                
            // ↓ sauvegarde en BDD ↓
            this.props.saveSettings(settingsToSave);
        }
        //else //pas d'enregistrement !
    }

    handleChangeCountry = (event, newValue) => {
        const { codeCountry } = this.state;

        //si on arrive ici, c'est que le choix parmis celles autorisées est correct !
        //on recherche le code pays associé avant d'actualiser les données:
        let newCodeCountry = codeCountry;
        if (this.countryList) {
            try {
                const countriesFund = this.countryList.filter((countryItem) => (countryItem.Name === newValue)? true : false);
                if (countriesFund && (countriesFund.length > 0)) {
                    const valueObj = JSON.parse(countriesFund[0].Value);
                    if (valueObj && (valueObj.codeCountry)) {
                        newCodeCountry = valueObj.codeCountry;
                    }
                }
            }
            catch (errCode) {
                //ras. tampis !
            }
        }
        //else // ne change rien !

        this.setState({ 
            country: newValue,
            lastChange: 'country',
            onEdit_Adresse: true,
            requiredDatasNeed: false, 
            codeCountry: newCodeCountry, 
        });
    }

    setCountryValue = (event, newInputValue) => {
        this.setState({ inputCountryValue: newInputValue, lastChange: 'countryInput', });

        //Rq: Pour le moment on n'en fait rien... mais on pourrai s'en servir pour auto-sélectionner le seul item correspondant à la saisie en cours...
        // => idéalement en récupérant la liste filtré par le composant 'Autocomplete'
    }

    handleChange = (param) => (event) => {
        if (event !== null) {
            if (param === 'adresse') {
                this.setState({
                    adressePostale: event.target.value,
                    lastChange: 'adresse',
                    onEdit_Adresse: true,
                    requiredDatasNeed: false, 
                });
            } /*else if (param === 'country') { //Cf 'handleChangeCountry' !
                if (!this.Countries) return;

                let newCodeCountry = countryLookupDatas[this.browserLanguage].filter((option) => (option.Name === event._targetInst.key)? true : false).map((option) => option.Value.slice(-4, -2))[0];

                this.setState({
                    country: event._targetInst.key,
                    onEdit_Adresse: true,
                    onSafeGuardAdresseFacturation : this.updateGardeFou(param, event._targetInst.key),
                    codeCountry: newCodeCountry,
                });

            }*/ else if (param === 'PostalCode') {
                const { codeCountry } = this.state;

                const newValue = event.target.value;
                let postalCodeOk = validatePostalCode(newValue, codeCountry);
                let newDisabledVilleTextField = !postalCodeOk;
                if ((codeCountry === "FR") || (codeCountry === "ES")) {
                    if (newValue.length !== 5) {
                        newDisabledVilleTextField = true;

                        postalCodeOk = false;
                    } 
                    //else les variables sont déjà définit !
                } 
                //else les variables sont déjà définit !

                this.setState({
                    postalCode: event.target.value,
                    lastChange: 'postalCode',
                    onEdit_Adresse: true,
                    requiredDatasNeed: false, 
                    diabledVilleTextField: newDisabledVilleTextField,
                });

                if (postalCodeOk === true) {
                    this.searchCitiesFromPostalCode(newValue);
                } else if ((!newValue) || (newValue === '')) {
                    this.setState({ cities: [], });
                }
            } /*else if (param === 'city') { //Cf 'handleChangeCity' !
                let theNewCityName = event.target.value;
                if (theNewCityName === 0 ) {
                    theNewCityName = event.target.textContent;
                }

                this.setState({
                    city: theNewCityName,
                    onEdit_Adresse: true,
                });
            }*/
        }
    }

    handleChangeCity = (event, newValue) => {
        let theNewCityName = newValue;
        if (validateCommonValue(theNewCityName) !== true) {
            theNewCityName = event.target.value;
        }
        if (theNewCityName === 0 ) {
            theNewCityName = event.target.textContent;
        }

        this.setState({
            city: theNewCityName,
            lastChange: 'city', 
            onEdit_Adresse: true,
            requiredDatasNeed: false, 
        });
    }

    setCityValue = (event, newInputValue) => {
        this.setState({ inputCityValue: newInputValue, onEdit_Adresse: true, lastChange: 'cityInput', });

        //Rq: Pour le moment on n'en fait rien... mais on pourrai s'en servir pour auto-sélectionner le seul item correspondant à la saisie en cours...
        // => idéalement en récupérant la liste filtré par le composant 'Autocomplete'
        // ET AUSSI rechercher des villes pouvant correspondre à la saisie (d'après l'API externe).
    }

    validateDatas(dataName, newValue) {    
        const { adressePostale, city, country, codeCountry, postalCode } = this.state;

        //Rq : pas de contrôle sur le nom de la société !
        if (!dataName) {
            return ((validateCommonValue(adressePostale) === true) && (validatePostalCode(postalCode, codeCountry) === true) &&
                (validateCommonValue(city) === true) && (validateCommonValue(country) === true));
        } else {
            // sinon, la nouvelle valeur du champ modifié, n'a pas encore été actualisé sur le state local, donc on exploite la nouvelle donnée fournie:
            if (dataName === 'label') {
                return ((validateCommonValue(newValue) === true) && (validatePostalCode(postalCode, codeCountry) === true) &&
                    (validateCommonValue(city) === true) && (validateCommonValue(country) === true));
            } else if (dataName === 'postalCode') {
                return ((validateCommonValue(adressePostale) === true) && (validatePostalCode(newValue, codeCountry) === true) &&
                    (validateCommonValue(city) === true) && (validateCommonValue(country) === true));
            } else if (dataName === 'city') {
                return ((validateCommonValue(adressePostale) === true) && (validatePostalCode(postalCode, codeCountry) === true) &&
                    (validateCommonValue(newValue) === true) && (validateCommonValue(country) === true));
            } else if (dataName === 'country') {
                return ((validateCommonValue(adressePostale) === true) && (validatePostalCode(postalCode, codeCountry) === true) &&
                    (validateCommonValue(city) === true) && (validateCommonValue(newValue) === true));
            }

            return true;
        }
    }

    handleBlurCity = (event) => {
        const { city, inputCityValue, lastChange } = this.state;

        const ctrlCity = validateCommonValue(city);
        const ctrlCityInput = validateCommonValue(inputCityValue);

        //si la sélection n'a pas été fait mais que la saisie est ok, alors on valide !
        if ( ((ctrlCity !== true) || (lastChange === 'cityInput')) && (ctrlCityInput === true)) {
            //TODO : A l'idéal, il faudrait différentier le cas où aucune ville n'est référencée => pour considérer la saisie ok !
            // avec le cas où on a une liste => on valide que si la saisie corresponds à l'une des villes.
            const name = 'city';
            this.setState({ 
                city: inputCityValue, 
                requiredDatasNeed: !this.validateDatas(name, inputCityValue), 
            });
        } 
        // Sinon, si la saisie vient de vider le choix de la ville, on invalide.
        else if ((lastChange === 'cityInput') && (inputCityValue === '')) {
            this.setState({ 
                city: inputCityValue, 
                requiredDatasNeed: true, //!this.validateDatas(name, inputCityValue),  //pas besoin de relancer, on le sait déjà que ce n'est pas ok !
            });
        }
    }

    render() {
        const { adressePostale, city, inputCityValue, cities, country, inputCountryValue, codeCountry, postalCode, 
            onEdit_Adresse, diabledVilleTextField, requiredDatasNeed } = this.state;
        
        const labelCtrlResult = validateCommonValue(adressePostale);
        const postalCodeCtrlResult = validatePostalCode(postalCode, codeCountry);
        //const cityCtrlResult = validateCommonValue(city);

        return(
            <Grid container spacing={2} alignItems="center">
                {/* ↓↓ Champ Adresse ↓↓ */}
                <Grid item xs={12}>
                    <InputLabel>{StringTranslate.adresse}</InputLabel>
                    <TextField
                        placeholder={`${StringTranslate.adresse}`}
                        value={adressePostale}
                        onChange={this.handleChange('adresse')}
                        margin="normal"
                        inputProps={{ maxLength: 100 }}
                        variant="outlined"
                        error={(labelCtrlResult !== true) && onEdit_Adresse && this.hasSubscribePlan}
                        helpertext={((labelCtrlResult !== true) && this.hasSubscribePlan) ? `${StringTranslate.mandatoryField}` : ''}
                        fullWidth
                    />
                </Grid>

                {/* ↓↓ Champ Pays ↓↓ */}
                <Grid item xs={12}>
                    <InputLabel>{StringTranslate.pays}</InputLabel>
                    {/*<Autocomplete ... error={(validateCommonValue(country) !== true) && onEdit_Adresse && this.hasSubscribePlan} ... /> non !*/}
                    <Autocomplete
                        fullWidth disableClearable
                        sx={{mt:2, mb:1}}
                        id="autocomplete_Country_ID"
                        options={this.countryList.map((countryItem) => countryItem.Name)}
                        value={country}
                        renderInput= {(params) => <TextField variant="outlined" {...params} label=""/>}
                        onChange={(event, newValue) => { this.handleChangeCountry(event, newValue); }}
                        inputValue={inputCountryValue}
                        onInputChange={(event, newInputValue) => { this.setCountryValue(event, newInputValue); }}
                        helpertext={((labelCtrlResult !== true) && this.hasSubscribePlan) ? `${StringTranslate.mandatoryField}` : ''}
                    />
                </Grid>

                {/* ↓↓ Champ Code postal ↓↓ */}
                <Grid item xs={12}>
                    <InputLabel>{StringTranslate.codepostal}</InputLabel>
                    <TextField
                        placeholder={`${StringTranslate.codepostal}`}
                        value={postalCode}
                        onChange={this.handleChange('PostalCode')}
                        margin="normal"
                        inputProps={{ maxLength: 100 }}
                        variant="outlined"
                        fullWidth
                        error={((postalCodeCtrlResult !== true) || diabledVilleTextField) && onEdit_Adresse && this.hasSubscribePlan}
                        helpertext={((postalCodeCtrlResult !== true) && this.hasSubscribePlan) ? `${StringTranslate.mandatoryField}` : (diabledVilleTextField ? "code postal non valide" : "")}
                    />
                </Grid>

                {/* ↓↓ Champ Ville ↓↓ */}
                <Grid item xs={12} >
                    <InputLabel>{StringTranslate.ville}</InputLabel>
                    {/*<Autocomplete ... error={(cityCtrlResult !== true) && onEdit_Adresse && this.hasSubscribePlan} ... /> non !*/}
                    <Autocomplete
                        sx={{mt:2, mb:1}}
                        freeSolo fullWidth
                        disableClearable
                        id="autocomplete_City_ID"
                        value={city}
                        renderInput={(params) => <TextField {...params} variant="outlined" label=""/>}
                        onChange={(event, newValue) => { this.handleChangeCity(event, newValue); }} 
                        inputValue={inputCityValue}
                        onInputChange={(event, newInputValue) => { this.setCityValue(event, newInputValue); }}
                        onBlur={(event) => { this.handleBlurCity(event); }}
                        options={cities}
                        helpertext={((labelCtrlResult !== true) && this.hasSubscribePlan) ? `${StringTranslate.mandatoryField}` : ''}
                        noOptionsText={StringTranslate.AnyCityFromCurrentPostalcode}
                    />
                </Grid>
                
                {/* ↓↓ Boutons d'action ↓↓ */}
                <Grid item xs={12}>
                    <Stack direction="column">
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <Button 
                                variant="text" 
                                color="error"
                                type="submit"
                                onClick={this.onClickCancelAdresseFacturation} 
                                disabled={!onEdit_Adresse}
                            >
                                {StringTranslate.annuler}
                            </Button>
                            
                            <Button 
                                variant="contained" 
                                color="primary"
                                type="submit"
                                onClick={this.onClickSaveAdresseFacturation} 
                                disabled={(!onEdit_Adresse) || (requiredDatasNeed && this.hasSubscribePlan)}
                            >
                                {StringTranslate.enregistrer}
                            </Button>
                        </Stack>
                        {(requiredDatasNeed && this.hasSubscribePlan) && (<Alert severity="error" sx={{ color: theme.palette.error.main }}>
                            {StringTranslate.SomeDataRequired}
                        </Alert>)}
                    </Stack>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    completeAddress: state.clientUserData.clientDatas.address,
    clientDatas: state.clientUserData.clientDatas,
    codeCountry: state.settingsData.settings.codeCountry,
    settings: state.settingsData.settings,
})

const mapDispatchToProps = dispatch => ({
    saveUpdatedClientBilling: (clientDatas) => dispatch(ActionSaveUpdatedClientBilling(clientDatas)),
    saveSettings: (settingsToSave) => dispatch( ActionSaveSettingsAsk(settingsToSave) ),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdresseFacturationInfo);
