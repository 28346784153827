import React from 'react';
import substepgeolocationsvg from '../../../assets/images/didacticiel/substep_geolocation.svg';
import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';

import { Dialog, DialogContent, Button, Fade, DialogContentText, DialogActions } from '@mui/material';

/* transition */
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade direction="up" ref={ref} {...props} timeout={{ enter: 1000, exit: 2000 }} style={{ transitionDelay: '500ms' }} />;
});

/**
 * Composant représentant l'UI de géolocalisation automatique dans l'étape dessin
 */
class SubStepGeolocation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isGeolocationOn: false, // la géolocalisation est elle activée ?
            timeout: false, // donnée représentant le temps limite de recherche de géolocalisation
        }

        this.map = (props.renderMapsCmp && props.renderMapsCmp.map) ? props.renderMapsCmp.map : null;
        
        this.startGeolocation = this.startGeolocation.bind(this);
        this.stopGeolocation = this.stopGeolocation.bind(this);
        this.onLocationFound = this.onLocationFound.bind(this);
        this.onLocationError = this.onLocationError.bind(this);
    }

    /**
     * fonction cycle de vie
     */
    componentDidMount() {
        if (this.map)
            this.startGeolocation(); // ⚠️ - lancement automatique de la géolocalisation
        this.timeout = setTimeout(() => this.setState({ timeout: true }), 2000);
    }

    /**
     * fonction cycle de vie
     */
    componentWillUnmount() {
        if (this.map)
            this.stopGeolocation(); // ⚠️ - important car géolocalisation non fonctionnelle si changement de page lorsque elle est en marche
        clearTimeout(this.timeout);
    }

    /**
     * fonction permettant d'activer la géolocalisation
     */
    startGeolocation() {

        // ↓ on cable les différents listeners ↓
        this.map.on('locationfound', this.onLocationFound);
        this.map.on('locationerror', this.onLocationError);

        this.map.locate({
            watch: true, //watch => starts continous watching of location changes
            timeout: 60000,
            enableHighAccuracy: true
        });

        this.setState({ isGeolocationOn: true });
    }

    /**
     * fonction permettant de stopper la géolocalisation
     */
    stopGeolocation() {
        this.map.stopLocate(); // fin de la géolocalisation

        // ↓ on décable les différents listeners ↓
        this.map.off('locationfound', this.onLocationFound);
        this.map.off('locationerror', this.onLocationError);
        this.geolocationLatLng = null;

        this.setState({isGeolocationOn: false});
    }
    
    /**
     * fonction executée lorsqu'une position GPS a été trouvée
     */
    onLocationFound(e) {
        try {
            //↓↓ on zoome sur le point ↓↓
            this.map.setView(e.latlng, 15);

            //↓↓ on désactive la géolocalisation une fois la position trouvée ↓↓
            this.stopGeolocation();

            // ↓↓ on peut aller directement à l'UI de dessin de parcelle - test: ajout 1s pour une meilleure expérience utilisateur ↓↓
            // setTimeout(() => this.props.setSubStepDessin(this.props.subStepDessinEnum.DESSIN), 1000);
            this.props.setSubStepDessin(this.props.subStepDessinEnum.DESSIN)

        } catch (error) {}
    }

    /**
     * fonction executée lors d'une erreur liée à la géolocalisation
     */
    onLocationError(error) {
        this.stopGeolocation(); // on désactive la géolocalisation une fois la position trouvée

        // ↓↓ on peut aller directement à l'UI de recherche de localité - test: ajout 1s pour une meilleure expérience utilisateur ↓↓
        // setTimeout(() => this.props.setSubStepDessin(this.props.subStepDessinEnum.PLACESEARCH), 1000);
        this.props.setSubStepDessin(this.props.subStepDessinEnum.PLACESEARCH)
    }

    /**
     * fonction cycle de vie
     */
    render() {
        const { timeout } = this.state;

        return (
            <Dialog
                open={true}
                TransitionComponent={Transition}>
                <DialogContent>
                    <img src={substepgeolocationsvg} alt="geolocalisation" width="190" />
                    <DialogContentText>{StringTranslate.dessinwaitgeolocalisation}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {(timeout) && (<Button onClick={this.onLocationError} variant="contained" fullWidth>{StringTranslate.commune}</Button>)}
                </DialogActions>
            </Dialog>
        )
    }
    
}

export default SubStepGeolocation;