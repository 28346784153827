import React from 'react';
import { connect } from 'react-redux';
import App from "../../app.js";
import { 
    ActionSetAccount, ActionSetIdToken, //ActionSetAccessToken, 
    ActionSetAccessTokenAndSearchClient, ActionUpdateNewAccountSocialProvider,
    StatesConnection 
} from '../actions/connection';
import { updateAuthority, getTokenRequest, isSocialAccount, saveUserEmailInLocalStorage, extractEmailFrom } from '../../utils/authentUtils.js';
import StoreFunctions from '../../utils/storeFunctions.js';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
//import { TimeUtilsHelper } from '../../utils/timeUtilsHelper.js';
//import sendError from '../utils/errorService.js';


/* Composant d’ordre supérieur - décorateur du composant App - https://fr.reactjs.org/docs/higher-order-components.html */ 
//function withAuthentMSal(AppComponent) {

    class HOComponent extends React.Component {
        constructor(props) {
            super(props);

            this.authRedirectCallBack = this.authRedirectCallBack.bind(this);
        }

        /* méthode de cycle de vie */
        componentDidMount() {
            const msalApp = this.props.userAgentAuthent; // l'initialisation de l'objet msal se fait dans le fichier index.js

        	// enregistrement callback => peut être appelée et peut lancer des actions dans la foulée
        	msalApp.handleRedirectCallback( this.authRedirectCallBack );

        	// si la fonction de callback n'est pas appelée alors on redirige vers l'écran de connexion (récupération IdToken) 
        	if ( !this.authRedirectCallBackExecuted ) {
                let tokenRequest = getTokenRequest(msalApp); // obtention des données de requête (scopes, autorité, loginHint, ...
                msalApp.loginRedirect(tokenRequest); // pour récupération idToken (authentification - sign in)
            }
        }

        componentDidUpdate(prevProps, prevState) {
            const { stateConnexion, emailOfClient, 
                needUpdateToken, clientIsNewer /*, actionSetIdToken, actionSetAccessToken*/ } = this.props;
            const msalApp = this.props.userAgentAuthent; // l'initialisation de l'objet msal se fait dans le fichier index.js

            // Cas de l'authentification enfin validée:
            if ((prevProps.stateConnexion !== stateConnexion) && (stateConnexion === StatesConnection.connected) &&
                emailOfClient && (emailOfClient !== '')) {
                if (window.dataLayer) {
                    window.dataLayer.push({ 'userId': emailOfClient });
                }
            }

            /*const { expiration } = this.props;

            if ((prevProps.expiration !== expiration) && (expiration)) {
                const expirationAsNumber = Number(expiration);
                const offset = (this.config && this.config.system && this.config.system.tokenRenewalOffsetSeconds) ? this.config.system.tokenRenewalOffsetSeconds : 300;
                if (expirationAsNumber > 0) {
                    setTimeout(() => {
                        // on approche la fin de validité du jeton...
                        let tokenRequest = getTokenRequest(this.props.msalApp); // obtention des données de requête (scopes, autorité, loginHint, ...
                        msalApp.loginRedirect(tokenRequest); // devrait appeller 'acquireTokenSilent(tokenRequest)' pour récupération idToken à jour !!!
                    }, (expirationAsNumber - TimeUtilsHelper.dateNowNumber() - offset) * 1000);
                }
            }*/ //cette solution me gène car exploite un 'timeout' qui peut/doit sans doute consommer du temps machine et donc ralentir.

            // Cas de la détection de la nécessité d'actualiser le jeton d'authent (normalement, pas plus d'une fois par jour):            
            if ((prevProps.needUpdateToken !== needUpdateToken) && (needUpdateToken === true)) {
                // on approche la fin de validité du jeton...
                const tokenRequest = getTokenRequest(msalApp); // obtention des données de requête (scopes, autorité, loginHint, ...
                msalApp.loginRedirect(tokenRequest); //fonctionne. On provoque le redémarrage de l'appli front actualiser toutes les données clients !
                /*msalApp.acquireTokenSilent(tokenRequest) //juste pour test car on préfère actualiser toutes les données clients !
                    .then((response) => {
                        if (response.accessToken) {
                            //console.log(`authRedirectCallBack - call 'actionSetAccessTokenAndSearchClient'`);
                            
                            // transmis à redux
                            actionSetIdToken(response.idToken, response.expiresOn);
                            actionSetAccessToken(response.accessToken);
                        }
                        else { // cas où l'on a utilisé 2 emails différents (1 pour l'idToken lors d'une connexion précèdente) (1 pour l'accessToken lors d'une nouvelle connexion)
                            const tokenRequest2 = getTokenRequest(msalApp); // obtention des données de requête (scopes, autorité, loginHint, ...)
                            msalApp.loginRedirect(tokenRequest2); // si pas de compte alors on redirige vers la page b2cLogin pour récupératon d'un token
                        }
                    })
                    .catch((error) => {
                        const tokenRequest3 = getTokenRequest(msalApp); // obtention des données de requête (scopes, autorité, loginHint, ...)
                        msalApp.loginRedirect(tokenRequest3); // si pas de compte alors on redirige vers la page b2cLogin pour récupératon d'un token
                    });*/
            }

            // Cas: TRACKING - détection d'authentification d'un nouveau client
            if ((prevProps.clientIsNewer !== clientIsNewer) && (clientIsNewer === true)) {
                if (window.dataLayer) // google tag manager
                    window.dataLayer.push({'event': 'nouveauClient', 'action': 'premiereConnexion'});
                if (window.fbq) // facebook pixel
                    window.fbq('track', 'CompleteRegistration', { 
                        value: 0.0,
                        currency: 'EUR',
                        content_name: emailOfClient,
                        status: 'complete'
                });
            }
        }

        /* méthode de callback utilisée par msal.js lors d'une redirection - msal.js */
        authRedirectCallBack = (error, response) => {
            /*const jsonParams = (response !== undefined) ? JSON.stringify(response) : 
                (error !== undefined) ? JSON.stringify(error) : 'Tout null!';
            console.log(`authRedirectCallBack - start: jsonParams=${jsonParams}`);*/
            //alert(`authRedirectCallBack - start: response=${JSON.stringify(response)}`);

            this.authRedirectCallBackExecuted = true; // Flag utilisé dans la fonction authRedirectCallBack

            // ↓↓ dans le cas d'une réponse POSITIVE ↓↓
            const { actionSetAccount, actionSetIdToken, actionSetAccessTokenAndSearchClient } = this.props;
            const msalApp = this.props.userAgentAuthent; // l'initialisation de l'objet msal se fait dans le fichier index.js
            
            if ( response ) {
            
                const account = msalApp.getAccount(); // a-t-on un compte client ? 
                if (account) { // on a un compte - l'utilisateur est authentifié à ce stade
                    //console.log(`authRedirectCallBack - account=${JSON.stringify(account)}`);

                    actionSetAccount(account); // transmis à redux
                    saveUserEmailInLocalStorage(account); // enregistrement email (si email+datas actuels différents du nouvel email => suppression)

                    if (response.tokenType === 'id_token') { // - SOIT réponse de type id_token
                            actionSetIdToken(response.idToken, response.expiresOn); // transmis à redux
                            let tokenRequest = getTokenRequest(msalApp); // obtention des données de requête (scopes, autorité, loginHint, ...)

                            //console.log(`authRedirectCallBack - call 'msalApp.acquireTokenRedirect'`);

                            msalApp.acquireTokenRedirect(tokenRequest);
                    }
                    
                    else if (response.tokenType === 'access_token') { // - SOIT réponse de type access_token
                            if (response.accessToken) {
                                //console.log(`authRedirectCallBack - call 'actionSetAccessTokenAndSearchClient'`);

                                actionSetAccessTokenAndSearchClient(response); // transmis à redux
                            }
                            else { // cas où l'on a utilisé 2 emails différents (1 pour l'idToken lors d'une connexion précèdente) (1 pour l'accessToken lors d'une nouvelle connexion)
                                StoreFunctions.clearAllUserDatas(); // suppression données ancien email client 
                                let tokenRequest = getTokenRequest(msalApp); // obtention des données de requête (scopes, autorité, loginHint, ...)
                                msalApp.loginRedirect(tokenRequest); // si pas de compte alors on redirige vers la page b2cLogin pour récupératon d'un token
                            }
                    }

                } else { // pas de compte
                        let tokenRequest = getTokenRequest(msalApp); // obtention des données de requête (scopes, autorité, loginHint, ...)
                        msalApp.loginRedirect(tokenRequest); // si pas de compte alors on redirige vers la page b2cLogin pour récupératon d'un token
                }
            }
            
            
            // ↓↓ dans le cas d'une réponse NEGATIVE (annulation, ...) ↓↓
            if ( error ) {
                // sendError('WithAuthent-authRedirectCallBack-error', error);
                const account = msalApp.getAccount(); // a-t-on au minimum un compte client ? 

                if (error.errorCode === "access_denied" && error.errorMessage.indexOf("AADB2C90118") > -1 ) { // oubli du mot de passe`ou réinitialisation du mot de passe
                    
                        if (account) { // réinitialisation du mot de passe
                            let socialAccount = isSocialAccount(account); // facebook ou gmail ?
                            if (!socialAccount) { // si ce n'est pas un réseau social mais bien un compte de type azure alors on peut demander la réinitialisation du mot de passe
                                updateAuthority(msalApp,'resetPwd');
                                let tokenRequest = getTokenRequest(msalApp); // obtention des données de requête (scopes, autorité, loginHint, ...)
                                msalApp.loginRedirect(tokenRequest); // relance de l'application - récupération idToken (authentification - sign in)
                            }
                            else {
                                alert(`${StringTranslate.alertrs}`); // on ne peut pas changer le mot de passe sur une connexion de type réseau social 
                            }
                        }
                        else { // clic sur 'oubli du mot de passe' lors de la connexion
                            updateAuthority(msalApp,'resetPwd');
                            let tokenRequest = getTokenRequest(msalApp); // obtention des données de requête (scopes, autorité, loginHint, ...)
                            msalApp.logout(); // annulation du processus de login initialement (sinon on obtient une erreur)
                            msalApp.loginRedirect(tokenRequest); // relance de l'application - récupération idToken (authentification - sign in)
                        }
                }
                else if (error.errorCode === "access_denied" && error.code === "AADB2C90091" ) { // annulation
                    
                        let tokenRequest = getTokenRequest(msalApp); // obtention des données de requête (scopes, autorité, loginHint, ...)
                        msalApp.loginRedirect(tokenRequest); // relance de l'application - récupération idToken (authentification - sign in)
                }
                else if (error.errorCode === "access_denied" && error.code === "AADB2C90002" ) { // fichier blob non trouvé (en lien avec la traduction)
                        
                        StoreFunctions.SaveUserLanguageToLocalStorage(); // ← langage par défaut sauvegardée (fr-FR)
                        let tokenRequest = getTokenRequest(msalApp); // obtention des données de requête (scopes, autorité, loginHint, ...)
                        msalApp.loginRedirect(tokenRequest); // relance de l'application - récupération idToken (authentification - sign in)
                }
                else { // erreur quelconque 
                        StoreFunctions.clearAllUserDatas(); // suppression données ancien email client 
                        let tokenRequest = getTokenRequest(msalApp); // obtention des données de requête (scopes, autorité, loginHint, ...)
                        msalApp.logout(); // on supprime les données d'authentcôté msal
                        msalApp.loginRedirect(tokenRequest); // relance de l'application - récupération idToken (authentification - sign in)
                }
            }
        }

        render() {
            //return <AppComponent {...this.props} />;
            return <App {...this.props} />;
        }
    };

    /* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
    const mapStateToProps = state => ({
        idToken: state.connectionData.idToken,
        accessToken: state.connectionData.accessToken,
        stateConnexion: state.connectionData.stateConnexion,
        account: state.connectionData.account,
        //Pour être avertis de la nécessité d'actualiser le jeton d'authent:
        //expiration: (state.connectionData.expiration) ? state.connectionData.expiration: 1, 
        needUpdateToken: (state.connectionData.expiration) ? state.connectionData.needUpdateToken : undefined, 

        clientIsNewer: (state.clientUserData.clientIsNewer) ? state.clientUserData.clientIsNewer : false, 
                
        //TODO: A remplacer par 'state.connectionData.emailOfAccount' lorsqu'il sera en place (appel à set-account-email)
        emailOfClient: (state.connectionData) ? extractEmailFrom(state.connectionData.account) : '', 
        
        showSocialAccountSignupForm: state.contextAppData.showSocialAccountSignupForm,
    })

    /* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
    const mapDispatchToProps = dispatch => ({
        actionSetAccount: account => dispatch(ActionSetAccount(account)),
        actionSetIdToken: (idToken, expiresOn) => dispatch(ActionSetIdToken(idToken, expiresOn)),
        //actionSetAccessToken: account => dispatch(ActionSetAccessToken(account)),
        actionSetAccessTokenAndSearchClient: authent => dispatch(ActionSetAccessTokenAndSearchClient(authent)),
        actionUpdateNewAccountSocialProvider: (phone, countryStr) => dispatch(ActionUpdateNewAccountSocialProvider(phone, countryStr)),
    })

    /*return connect( mapStateToProps, mapDispatchToProps )(HOComponent);
}

export default withAuthentMSal;*/
export default connect( mapStateToProps, mapDispatchToProps )(HOComponent);