import StringTranslate from '../assets/i18n/stringLanguage.jsx';

/**
 * Objet javascript permettant d'obtenir des informations du navigateur => type , langage, ...
 */
const language_helper = {

    /* liste des langues supportées par l'application */
    languageList: ['fr-FR','fr-BE','en-GB','es-ES'],
    alpha2LanguageList: ['FR','EN','ES'],
    defaultLanguage: 'fr-FR',
    defaultAlpha2Language: 'fr',
    
    /* fonction initialisation de la langue */
    onInitLanguage: function() {
        let browserLanguage = language_helper.getBrowserLanguage();
        StringTranslate.setLanguage(browserLanguage);
    },

    /* fonction permettant de mettre à jour la langue de l'application (via le StringTranslate) */
    updateLanguage: function(languageToUpdate = language_helper.defaultLanguage) {
        if ( language_helper.languageList.includes(languageToUpdate) ) {
            StringTranslate.setLanguage(languageToUpdate);
        }
    },

    /*  fonction permettant de récupérer la langue du navigateur - ⚠ - on peut retourner une donnée transformée (une langue prise en charge par l'application */ 
    getBrowserLanguage: function() {
        try {
            let browserLanguage = navigator.language || navigator.userLanguage || navigator.browserLanguage; 
            if ( language_helper.languageList.includes(browserLanguage) ) { // la donnée fait elle partie de la liste de langue gérée par l'application ?
                return browserLanguage;
            }
            else { // si non, on en crée une à partir des informations 
                let alpha2 = browserLanguage.split(/[-_]/)[0]; // ex: es-ES => es, es-Cl => es, es => es
                let lang = language_helper.languageList.find(str => str.indexOf(alpha2) >= 0);
                return (lang) ? lang : language_helper.defaultLanguage;
            }
        } catch (error) {}

        return language_helper.defaultLanguage; 
    },

    /* fonction permettant de récupérer la langue actuellement paramétrée */
    getLanguage: function() {
        return StringTranslate.getLanguage();
    },

    /* fonction permettant de récupérer la langue actuellement paramétrée - seulement les 2 premières lettre */
    getAlpha2Language: function(culture = '') {
        let position = culture.indexOf("-");
        let alpha2 = (position >= 0) ? culture.slice(0, position) : null;
        return (alpha2) ? alpha2.toUpperCase() : null;
    },

    /* fonction permettant de récupérer la langue du navigateur dans sa forme la plus simple*/
    getAlpha2BrowserLanguage: function(culture = '') {
        return language_helper.getAlpha2Language(language_helper.getBrowserLanguage()); // par défaut FR !
    },
}

export { language_helper as LanguageHelper };