import { updateAuthority, getTokenRequest } from '../../utils/authentUtils.js';
import { ActionSearchOrCreateClientAsk, ActionErrorSearchClient } from './clientUser.js';
import { ActionSetURLParameters, ActionGoToPage } from './contextApp.js';
import URLHelper from '../../utils/urlHelper.js';
import { API, clientWebApiProvider } from '../../utils/webApiProvider.js';
import { IsNativeHoster } from '../../utils/platformHelper';


/* déclaration des actions de connexion */ 
const AUTHENT_SET_ACCOUNT = 'AUTHENT_SET_ACCOUNT';
const AUTHENT_SET_ACCOUNT_EMAIL = 'AUTHENT_SET_ACCOUNT_EMAIL';
const AUTHENT_SET_IDTOKEN = 'AUTHENT_SET_IDTOKEN';
const AUTHENT_SET_ACCESSTOKEN = 'AUTHENT_SET_ACCESSTOKEN';
const AUTHENT_UPDATE_IDTOKEN = 'AUTHENT_UPDATE_IDTOKEN';
const AUTHENT_LOGOUT = 'AUTHENT_LOGOUT';
const AUTHENT_LOGOUT_AND_SIGNUP = 'AUTHENT_LOGOUT_AND_SIGNUP';
const AUTHENT_LOGOUT_AND_CLOSEAPP = 'AUTHENT_LOGOUT_AND_CLOSEAPP';
const AUTHENT_RESET_PWD = 'AUTHENT_RESET_PWD';
const AUTHENT_ERROR = 'AUTHENT_ERROR';
const AUTHENT_NEED_NEW_TOKEN = "AUTHENT_NEED_NEW_TOKEN";
const AUTHENT_SET_STATE_CONNEXION = "AUTHENT_SET_STATE_CONNEXION";
const UPDATE_NEW_ACCOUNT_SOCIAL_PROVIDER = "UPDATE_NEW_ACCOUNT_SOCIAL_PROVIDER";
const UPDATE_TOKENS_FROM_NATIVE_SOCIALPROVIDER = 'UPDATE_TOKENS_FROM_NATIVE_SOCIALPROVIDER';

/* déclaration des états de connexion */ 
const AUTHENT_CONNECTING = 'AUTHENT_CONNECTING';
const AUTHENT_CONNECTED = 'AUTHENT_CONNECTED';
const AUTHENT_DISCONNECTING = 'AUTHENT_DISCONNECTING';
const AUTHENT_RESETING_PWD = 'AUTHENT_RESETING_PWD';

/* déclaration énuméré des états de connexion */
export const StatesConnection = {
    connecting: AUTHENT_CONNECTING,
    connected: AUTHENT_CONNECTED,
    disconnecting: AUTHENT_DISCONNECTING,
    resetingPwd: AUTHENT_RESETING_PWD,
}

/* Enuméré des actions de connexion: */
export const MsalProcess = {
    set_account: AUTHENT_SET_ACCOUNT,
    set_accountAndEmail: AUTHENT_SET_ACCOUNT_EMAIL,
    set_idtoken: AUTHENT_SET_IDTOKEN,
    set_accesstoken: AUTHENT_SET_ACCESSTOKEN,
    update_idtoken: AUTHENT_UPDATE_IDTOKEN,
    logout: AUTHENT_LOGOUT,
    logoutAndSignup: AUTHENT_LOGOUT_AND_SIGNUP,
    closeNativeApp: AUTHENT_LOGOUT_AND_CLOSEAPP,
    resetPwd: AUTHENT_RESET_PWD,
    error: AUTHENT_ERROR,
    needNewToken: AUTHENT_NEED_NEW_TOKEN,
    setStateConnexion: AUTHENT_SET_STATE_CONNEXION,
    updateNewAccountSocialProvider: UPDATE_NEW_ACCOUNT_SOCIAL_PROVIDER,
    updateTokensFromNativeSocialProvider: UPDATE_TOKENS_FROM_NATIVE_SOCIALPROVIDER
}

/* Actions creator */
export const ActionSetAccount = (account) => ({
    type: AUTHENT_SET_ACCOUNT,
    account: account
})
export const ActionSetAccountAndEmail = (account, email) => ({ //TODO : A appeler par toutes les gestionnaires d'authentification !
    type: AUTHENT_SET_ACCOUNT_EMAIL,
    account: account,
    emailUser: email,
})

export const ActionSetIdToken = (idToken, expiresOn) => ({
    type: AUTHENT_SET_IDTOKEN,
    idToken: idToken,
    expiresOn: expiresOn
})

export const ActionSetAccessToken = (accessToken) => ({ //pas exportée pour obliger à lancer la recherche du client
    type: AUTHENT_SET_ACCESSTOKEN,
    accessToken: accessToken
})

export const ActionUpdateIdToken = (idTokenValue, expiresOnValue) => ({
    type: AUTHENT_UPDATE_IDTOKEN,
    idToken: idTokenValue,
    expiresOn: expiresOnValue,
})

export function ActionNeedRenewToken() {
    return {
        type: AUTHENT_NEED_NEW_TOKEN,
    };
}

export function ActionSetStateConnexionToConnected() {
    return {
        type: AUTHENT_SET_STATE_CONNEXION,
        stateConnexion: StatesConnection.connected
    };
}

export function ActionUpdateTokensFromNativeSocialProvider(accessToken, idToken, expiresOn) {
    return {
        type: UPDATE_TOKENS_FROM_NATIVE_SOCIALPROVIDER,
        idToken: idToken,
        expiresOn: expiresOn,
        accessToken: accessToken
    }
}

/**
 * Action permettant de mettre à jour les données d'authentification suite à la récupération d'un accessToken et récupérer(ou créer) les données d'un client 
 */
export const ActionSetAccessTokenAndSearchClient = ({ accessToken, idToken, expiresOn }) => (dispatch) => {
    //console.log(`ActionSetAccessTokenAndSearchClient - start: accessToken=${accessToken}, idToken=${idToken}`);

    dispatch(ActionSetIdToken(idToken, expiresOn));
    dispatch(ActionSetAccessToken(accessToken));

    API.token = accessToken; // accessToken transmis à l'objet API

    // ↓↓ une fois athentifié, on vient lire les paramètres URL récupérés après le processus d'authentification Afin de les stocker dans le Store Redux (ContextApp) ↓↓
    let URLParameters = URLHelper.getURLParameters();
    dispatch( ActionSetURLParameters(URLParameters) );
    if ( URLParameters && URLParameters['goToPage'] ) {
        dispatch( ActionGoToPage(URLParameters['goToPage']) );
    }

    //console.log(`authRedirectCallBack - call 'ActionSearchOrCreateClientAsk'`);
    dispatch( ActionSearchOrCreateClientAsk() ); // récupération client (obtention ou création) et de ses informations
}

/**
 * Action permettant de mettre à jour les données d'authentification (application native - via réseau social)
 */
export const ActionUpdateTokens = ({ accessToken, idToken, expiresOn }) => (dispatch) => {
    dispatch( ActionUpdateTokensFromNativeSocialProvider(accessToken, idToken, expiresOn) )
    API.token = accessToken; // accessToken transmis à l'objet API
}

export function ActionDisconnect(closeNativeAppValue = false) {
    return function(dispatch, getState, { history, userAgentAuthent }) {
        if (closeNativeAppValue === true) {
            if (userAgentAuthent) {
                userAgentAuthent.closeApp = true;
                try {
                    userAgentAuthent.logout();
                }
                finally {
                    userAgentAuthent.closeApp = false;
                }
            }

            dispatch({ 
                type: AUTHENT_LOGOUT_AND_CLOSEAPP,
            }); // modification du store et affichage du visuel associé
        } else {
            dispatch({ 
                type: AUTHENT_LOGOUT,
            }); // modification du store et affichage du visuel associé

            if (userAgentAuthent) {
                userAgentAuthent.logout();
            }
        }
    };
}

export const ActionDisconnectAndSignup = () => (dispatch, getState, { history, userAgentAuthent }) => {
    dispatch({ type: AUTHENT_LOGOUT_AND_SIGNUP }); // modification du store et affichage du visuel associé
    if (userAgentAuthent) {
        userAgentAuthent.logout();

        updateAuthority(userAgentAuthent, 'signup');
        let tokenRequest = getTokenRequest(userAgentAuthent); // obtention des données de requête (scopes, autorité, loginHint, ...)
        userAgentAuthent.loginRedirect(tokenRequest); // relance de l'application - récupération idToken (authentification - sign up)
    }
}

export const ActionResetPwd = () => (dispatch, getState, { history, userAgentAuthent }) => {
    dispatch({ type: AUTHENT_RESETING_PWD }); // modification du store et affichage du visuel associé

    updateAuthority(userAgentAuthent, 'resetPwd');
    let tokenRequest = getTokenRequest(userAgentAuthent); // obtention des données de requête (scopes, autorité, loginHint, ...)
    if (userAgentAuthent) userAgentAuthent.loginRedirect(tokenRequest); // relance de l'application - récupération idToken (authentification - sign in)
}

export const ActionUpdateNewAccountSocialProvider = (phone, countryStr) => (dispatch) => {
    dispatch({ 
        type: UPDATE_NEW_ACCOUNT_SOCIAL_PROVIDER,
        phone: phone,
        countryStr: countryStr,
    });

    dispatch( ActionSearchOrCreateClientAsk() ); // nouvelle demande de création de compte avec les informations de pays et téléphone renseignées
};

export function ActionRemoveClientAndDatas() {
    return function(dispatch, getState, { history, userAgentAuthent }) {
        // ↓ appel API : on demande la la Web API de supprimer les données... ↓
        const promizeDelClientDatas = (resolveFct, rejectFct) => {
            return clientWebApiProvider.removeClientAndDatas(dispatch, getState)
                .then((response) => { //on ne reçoit rien ...
                    /// RAS !
                    resolveFct(true);
                    return true;
                })
                .catch((error) => {
                    dispatch( ActionErrorSearchClient(error) );

                    //on retourne tout de même un 'ok' !
                    resolveFct(false);
                    return true;
                });
        };

        return new Promise(promizeDelClientDatas).then((resp) => {
            //puis, sans attendre, on prévient l'appli native !
            if ((IsNativeHoster() === true) && userAgentAuthent && userAgentAuthent.commProviderWithNativeApp) { 
                userAgentAuthent.commProviderWithNativeApp.notifyClientToRemove();
            }

            //ET on se déconnecte !
            if (userAgentAuthent) {
                userAgentAuthent.notifyNativeApp = false;
                try {
                    userAgentAuthent.logout();
                }
                finally {
                    userAgentAuthent.notifyNativeApp = true;
                }
            }

            dispatch({ 
                type: AUTHENT_LOGOUT_AND_CLOSEAPP,
            }); // modification du store et affichage du visuel associé
        });
    };
}

export function ActionChangePassword() {
    return function(dispatch, getState, { history, userAgentAuthent }) {
        //puis, sans attendre, on prévient l'appli native !
        if ((IsNativeHoster() === true) && userAgentAuthent && userAgentAuthent.commProviderWithNativeApp) { 
            userAgentAuthent.commProviderWithNativeApp.askChangeDatasAuthent();
        }
        
        //puis, sans attendre, on fait comme si on se déconnectait:
        dispatch({ 
            type: AUTHENT_LOGOUT,
        }); // modification du store et affichage du visuel associé
    };
}
