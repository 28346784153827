import React from "react";
import { connect } from 'react-redux';

import lodashIsEmpty from 'lodash/isEmpty';
import { CardContent, TableContainer, Grid, Typography, IconButton, Divider } from "@mui/material";
import MainCard from "../../components/subLayouts/mainCard.jsx";
import ADVLink from "../../components/profil/aDVLink.jsx";
import { ActionLoadInvoiceFile } from "../../redux/actions/clientUser";
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

import CustomDataGrid from "../../components/customDataGrid";
import { CircularProgress, Chip } from '@mui/material';
import IconPdf from '@mui/icons-material/PictureAsPdf';

import '../../assets/css/profil.css';

import getTheme from "../../themes/index.js";
let theme = getTheme();


class Factures extends React.Component {

    constructor(props) {
        super(props);

        const clientDatas = props.clientDatas;

        this.state = {
            isADVClient: clientDatas ? clientDatas.isADV : false,
            invoices: (clientDatas && clientDatas.lastSCharges && Array.isArray(clientDatas.lastSCharges)) ? clientDatas.lastSCharges : [], // liste des factures
            page: 0, // page en cours (pagination)
            rowsPerPage: 5, //
            numberInvoiceDownloading: -1, //pour différentier la facture qui est en cours de téléchargement... et pas les autres !
        };

        this.updateNbRowsPerPageTableFactures = this.updateNbRowsPerPageTableFactures.bind(this);
    }

    updateNbRowsPerPageTableFactures(rowsPerPage) {
        //RAS !
    }

    onLoadInvoiceFileClick(invoiceSelected) {
        if (!invoiceSelected) return;

        this.setState({ numberInvoiceDownloading: invoiceSelected.number, });

        const { loadInvoiceFile } = this.props;
        try {
            loadInvoiceFile(invoiceSelected);
        }
        catch (err) { }
    }

    createColumns() {
        const columns =
            [
                {
                    field: 'id',
                    headerName: 'id',
                    hideable: false,
                },
                {
                    field: 'facture',
                    headerName: `${StringTranslate.facture}`,
                    minWidth: 200,
                    hideable: false,
                    renderHeader: (params) => {
                        return (<Typography fontWeight='bold' >{StringTranslate.facture}</Typography>)
                    },
                    renderCell: (params) => {
                        return (
                            <Typography variant="h6">{params.value}</Typography>
                        )
                    }
                },
                {
                    field: 'descriptionfac',
                    headerName: `${StringTranslate.descriptionfac}`,
                    hideable: false,
                    minWidth: 350,
                    renderHeader: (params) => {
                        return (<Typography fontWeight='bold' >{StringTranslate.descriptionfac}</Typography>)
                    },
                },
                {
                    field: 'prix',
                    headerName:`${StringTranslate.prix}`,
                    hideable: false,
                    minWidth: 100,
                    renderHeader: (params) => {
                        return (<Typography fontWeight='bold' >{StringTranslate.prix}</Typography>)
                    },
                },
                {
                    field: 'status',
                    headerName: `${StringTranslate.statut}`,
                    hideable: false,
                    minWidth: 150,
                    renderHeader: (params) => {
                        return (<Typography fontWeight='bold' >{StringTranslate.statut}</Typography>)
                    },
                    renderCell: (params) => {
                        return (
                            <Chip
                                size="small"
                                label={params.value ? `${StringTranslate.facturePayee}` : `${StringTranslate.factureEnImpayee}`}
                                color={params.value ? 'success' : 'error'}
                                sx={{ borderRadius: '4px', textTransform: 'capitalize', color: theme.palette.common.white }}
                            />

                        )
                    }
                },
                {
                    field: 'pdf',
                    headerName: `${StringTranslate.pdf}`,
                    hideable: false,
                    minWidth: 60,
                    renderHeader: (params) => {
                        return (<Typography fontWeight='bold' >{StringTranslate.pdf}</Typography>)
                    },
                    renderCell: (params) => {
                        return (
                        <>
                            {((this.props.downloadingFile === true) && (this.state.numberInvoiceDownloading === params.value.number)) ?
                                <CircularProgress size={20} color="primary" />
                                    :
                                    <IconButton
                                    color="primary"
                                    onClick={() => this.onLoadInvoiceFileClick(params.value)}
                                    >
                                        <IconPdf />
                                    </IconButton>}
                        </>
                        )
                    }
                },
            ]
        return columns;
    }

    createRows() {
        let rows = [];
        this.state.invoices.map((invoice) => {
            rows.push({
                id: invoice.reference,
                facture: invoice.reference,
                descriptionfac: invoice.description,
                prix: ((invoice.totalPaid) ? invoice.totalPaid : '') + ' ' + ((invoice.currency) ? invoice.currency : ''),
                status: invoice.isPaid,
                pdf: invoice
            })
            return null;
        })
        return rows;
    }

    render() {
        return (
                <MainCard content={false}>
                    {/* Que l'on soit depuis l'appli Web via PC ou mobile, OU via l'appli Mobile, il faut pouvoir présenter les différents achats... */}

                    {/* Factures via achat Stripe */}
                    <CardContent
                        sx={{ display: 'block', }}
                    id="invoices-from-stripe"
                >
                    {(lodashIsEmpty(this.state.invoices) === false) && (this.state.isADVClient === false) &&
                        <>
                            <TableContainer>
                                <Grid style={{ width: '100%' }} item xs={12}>
                                    <CustomDataGrid
                                        tableName="invoices"
                                        pageSize={10}
                                        updateNbRowsPerPageTable={this.updateNbRowsPerPageTableFactures}
                                        rows={this.createRows()}
                                        columns={this.createColumns()}
                                        checkBoxActive={false}
                                    />
                                </Grid>
                            </TableContainer>
                            <Divider sx={{ mt: 1, mb: 2.5 }} />
                        </>}

                    <ADVLink />

                </CardContent>

                {/* En cas d'achat via l'Achat In-App */}
            </MainCard>
        );
    }
}

const mapStateToProps = state => ({
    clientDatas: state.clientUserData.clientDatas,
    downloadingFile: (state && state.clientUserData) ? state.clientUserData.downloadingFile : false,
})

const mapDispatchToProps = dispatch => ({
    loadInvoiceFile: (invoiceSelectedItem) => dispatch(ActionLoadInvoiceFile(invoiceSelectedItem)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Factures);