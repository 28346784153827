import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import isValid from 'date-fns/isValid';
import { fr, enGB, es } from 'date-fns/locale';
import sendError from './errorService.js';
import StringTranslate from '../assets/i18n/stringLanguage.jsx';
import { random } from 'lodash';

export function CreateNewGuid() {
    //this.counterOfCall++;

    try {
        //RQ: on peut laisser en permanence le 'locale Français car c'est une donnéesjuste pour de l'interne !
        //return new Intl.NumberFormat('fr-FR', { minimumIntegerDigits: 5, maximumFractionDigits: 0, }).format(this.counterOfCall); 

        //Pour être indépendant du rechargement d'appli (et donc du retour à zéro sur le compteur ''), on se sert de la date/heure actuelle:
        return `${(new Date()).valueOf()}`;
    } catch (error) {
        // console.log(`getNextGuidOfCall - error = '${error}'.`);
    }

    return random(1, 100000, false).toString();
}

// fichier permettant de faire de la manipulation et des opérations en rapprt avec la date
const dateHelper = {

    getDateToFrString(dateValue) {
        if (!dateValue) return '';

        let thisDateStr = '';
        try {
            thisDateStr = format(dateValue, 'dd/MM/yyyy'); // info - https://blog.date-fns.org/post/unicode-tokens-in-date-fns-v2-sreatyki91jg
        }
        catch (e) {
            if (sendError) {
                sendError('dateHelper-getDateToFrString', { "e": e, "dateValue": dateValue, });
            }
        }

        return thisDateStr;
    },

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de savoir si 2 objets Date sont les mêmes suivant le format DDMMYYYY
    ///////////////////////////////////////////////////////////////////////////
    isSameDateDDMMYYYY(date1, date2) {
        if ((!date1) || (!date2)) return false;

        try {
            let firstDate = date1;
            if (!(date1 instanceof Date)) {
                firstDate = new Date(date1);
            }
            let nextDate = date2;
            if (!(date2 instanceof Date)) {
                nextDate = new Date(date2);
            }

            firstDate = format(firstDate, 'dd/MM/yyyy'); // info - https://blog.date-fns.org/post/unicode-tokens-in-date-fns-v2-sreatyki91jg
            nextDate = format(nextDate, 'dd/MM/yyyy');
            if (firstDate === nextDate) return true;
        }
        catch (e) {
            if (sendError) {
                sendError('dateHelper-isSameDateDDMMYYYY', { "e": e, "date1": date1, "date2": date2, });
            }
        }

        return false;
    },

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de mettre à date à minuit 0h,00min,00s
    ///////////////////////////////////////////////////////////////////////////
    setDateToMidnight(date) {
        if (!(date instanceof Date)) return null;
        try {
            return new Date(format(date, 'yyyy-MM-dd'));
        }
        catch (e) {
            if (sendError) {
                sendError('dateHelper-setDateToMidnight', { "e": e, "date": date, });
            }
        }
        return null;
    },

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de savoir si la date (mois année) est valide
    ///////////////////////////////////////////////////////////////////////////
    isValid(year = null, month = null) {
        if (!month || !year) return false;
        return isValid(new Date(year, month, 1))
    },

    ///////////////////////////////////////////////////////////////////////////
    // fonction permettant de savoir si la date est valide
    ///////////////////////////////////////////////////////////////////////////
    isValidDate(date) {
        return isValid(date);
    },

    Compare(date1, date2) {
        if ((!date1) && (!date2)) return 0; //égalité !
        if (date1 && (!date2)) return 1;
        if ((!date1) && date2) return -1;
        //else les deux dates sont ok!

        let firstDate = undefined;
        if (!(date1 instanceof Date)) {
            firstDate = new Date(date1);
        } else {
            firstDate = date1;
        }
        let nextDate = undefined;
        if (!(date2 instanceof Date)) {
            nextDate = new Date(date2);
        } else {
            nextDate = date2;
        }

        //@@Serait-il plus judicieux d'utiliser 'isAfter' de 'date-fns' ?
        const yearDate1 = firstDate.getUTCFullYear();
        const yearDate2 = nextDate.getUTCFullYear();
        if (yearDate1 > yearDate2) return 1;
        else if (yearDate1 < yearDate2) return -1;
        else {//même année !
            const monthDate1 = firstDate.getUTCMonth();
            const monthDate2 = nextDate.getUTCMonth();
            if (monthDate1 > monthDate2) return 1;
            else if (monthDate1 < monthDate2) return -1;
            else {//même mois !
                const dayDate1 = firstDate.getUTCDate();
                const dayDate2 = nextDate.getUTCDate();
                if (dayDate1 > dayDate2) return 1;
                else if (dayDate1 < dayDate2) return -1;
                else return 0; //égalité !
            }
        }
    },

    /* fonction permettant de renvoyer une date sous forme de chaine de caractère respectant le formatage et l'i18n */
    formati18n(date, formatStr) {
        if (isValid(date)) {
            let language = StringTranslate.getLanguage();
            if (language === 'fr-FR')
                return format(date, formatStr, { locale: fr });
            else if (language === 'fr-BE')
                return format(date, formatStr, { locale: fr });
            else if (language === 'es-ES')
                return format(date, formatStr, { locale: es });
            else if (language === 'en-GB')
                return format(date, formatStr, { locale: enGB });
            else
                return format(date, formatStr);
        }
        return '';
    },

    /* fonction permettant de retourner un objet date depuis une chaine de caractère */
    getDateFromString(str, toStartOfDay = false) {
        return ((typeof str === 'string') && isValid(new Date(str))) ? ((toStartOfDay) ? startOfDay(new Date(str)) : new Date(str)) : null;
    },

    /* fonction pour obtenir la date sans les heures, minutes et secondes.
       Possibilité d'ajouter/enlever le nombre de mois 
       et se positionner au premier jour du mois (true: 1er jour du mois, false: le jour courant) */
    getDateWithoutHours(date, monthsAdded, firstDayOfTheMonth) {
        return new Date(date.getFullYear() + '/' + (date.getMonth() + 1 + monthsAdded) + '/' + ((firstDayOfTheMonth === true) ? 1 : date.getDate()));
    },

    /* fonction permettant de retourner un objet date depuis une variable (chaine de caractère ou déjà une date) */
    getDateValid(valueSource, controlYear = false) {
        if (!valueSource) return null;

        const defaultDate = new Date(null); // == 01/01/1970
        let dateResult = null;
        if ((typeof valueSource === 'string') && (!isNaN(Date.parse(valueSource)))) { 
            dateResult = new Date(valueSource);
        }
        else if (typeof valueSource === typeof defaultDate) { //type 'Date' !
            dateResult = valueSource;
        }
        else {
            dateResult = new Date(valueSource);
        }

        if ((dateResult !== undefined) && (dateResult !== null)) {
            if ((isValid(dateResult)) && (controlYear === true)) { //Alors on vérifie l'année sur 4 chiffres
                let yearValue = dateResult.getFullYear();
                if ((yearValue >= 10) && (yearValue < 100)) { //année sur deux chiffres !
                    dateResult = new Date(yearValue + 2000, dateResult.getMonth(), dateResult.getDate());
                }
                else if ((yearValue >= 100) && (yearValue < 1000) && //année sur trois chiffres !
                    ((yearValue * 10) > defaultDate.getFullYear())) { //et si on ajoute un 'zéro' à droite, on aurai une année > à 1970 !
                    dateResult = new Date(yearValue * 10, dateResult.getMonth(), dateResult.getDate());
                }
                else if (yearValue > 1970) { //'> 1000 ne sert à rien car le minimum est à 1970 !
                    //RAS ! normalement, même > 2000 !
                }
                else { //on force l'année en cours
                    yearValue = (new Date()).getFullYear(); // == l'année de la date du jour !
                    dateResult = new Date(yearValue, dateResult.getMonth(), dateResult.getDate());
                }
            }
            //else //on ne fait rien !
        }
        else 
            dateResult = null;

        return dateResult;
    },

    /**
     * Fonction permettant de trouver la dates la plus proche 
     * @param {Date} date Date pour la quelle on doit trouver la date la plus proche
     * @param {Date[]} dates Dates parmis lesquelles on doit trouver la date la plus proche
     * @returns {Date} closestDate date la plus proche 
     */
    getClosestDate(date, dates) {
        let diffTime = dates.map(d => Math.abs(date.getTime() - d.getTime()));
        let indexOfClosest = diffTime.indexOf(Math.min(...diffTime));

        return dates[indexOfClosest];
    },

    /**
     * Fonction permettant de rajouter une nombre de jou à une date
     * @param {Date} date Date à laquelle on doit rajouter un nombre de jours
     * @param {integer} nbDays Nombre de jours à rajouter ou soustraire à ola date
     *  */
    addDaysToDate(date, nbDays) {
        let numberOfMlSeconds = date.getTime();
        let addMlSeconds = nbDays * 24 * 60 * 60 * 1000;
        let newDateObj = new Date(numberOfMlSeconds + addMlSeconds);
        return newDateObj;
    },

    /**
     * Fonction permettant d'avoir une date sans time zone (ISO 8601)
     * /!\ Utilisé pour envoyer de javascript à C# ou de C# à javascript !
     * @param {*} date 
     * @returns date sans timezone
     */
    dateTimeZoneOffset(date) {
        if (date instanceof Date && !isNaN(date)) {
            return (new Date(date.setHours(-date.getTimezoneOffset()/60)));
        }
        else {
            return date;
        }
    }
}

export default dateHelper;