import { get as lodashGet, isNil as lodashIsNil, unset as lodashUnset, findKey as lodashFindKey, isEmpty as lodashIsEmpty, 
    pick as lodashPick, filter as lodashFilter, } from 'lodash';

import { modelisationWebApiProvider } from '../../utils/webApiProvider';
import { ActionUpdateParcelDico } from './parcels';
import { ActionRemoveModelisationImage, ActionAskReloadAllSatimageForAllParcels } from './satImage';
//import ModelisationHelper from '../../utils/modelisationHelper';


const GO_TO_STEP_OF_MODELISATION = 'GO_TO_STEP_OF_MODELISATION';
const SAVE_CHOSEN_CROP = 'SAVE_CHOSEN_CROP';
const SAVE_CHOSEN_PARCELS = 'SAVE_CHOSEN_PARCELS';
const GET_CULTURES_MODELISATION = 'GET_CULTURES_MODELISATION';
const LOADING_MODELISATIONS = 'LOADING_MODELISATIONS';
const LOADED_MODELISATIONS = 'LOADED_MODELISATIONS';
const ERROR_LOAD_CULTURES_MODELISATION = 'ERROR_LOAD_CULTURES_MODELISATION';
const ERROR_LOAD_MODELISATIONS = 'ERROR_LOAD_MODELISATIONS';
const ERROR_INIT_MODELISATIONS = 'ERROR_INIT_MODELISATIONS';
const SUCCESS_INIT_MODELISATIONS = 'SUCCESS_INIT_MODELISATIONS';
const SET_MODELISATION_PROCESSING = 'SET_MODELISATION_PROCESSING';

export const ActionTypeModelisation = {
    GO_TO_STEP_OF_MODELISATION: GO_TO_STEP_OF_MODELISATION,
    SAVE_CHOSEN_CROP: SAVE_CHOSEN_CROP,
    SAVE_CHOSEN_PARCELS: SAVE_CHOSEN_PARCELS,
    GET_CULTURES_MODELISATION : GET_CULTURES_MODELISATION,
    LOADING_MODELISATIONS : LOADING_MODELISATIONS,
    LOADED_MODELISATIONS : LOADED_MODELISATIONS,
    ERROR_LOAD_CULTURES_MODELISATION : ERROR_LOAD_CULTURES_MODELISATION,
    ERROR_LOAD_MODELISATIONS : ERROR_LOAD_MODELISATIONS,
    ERROR_INIT_MODELISATIONS : ERROR_INIT_MODELISATIONS,
    SUCCESS_INIT_MODELISATIONS : SUCCESS_INIT_MODELISATIONS,
    SET_MODELISATION_PROCESSING: SET_MODELISATION_PROCESSING
};

export const ModelisationSteps = {
    WELCOME_START_MODELISATION: 'WELCOME_START_MODELISATION',
    CROP_CHOICE: 'CROP_CHOICE',
    PARCELS_CHOICE: 'PARCELS_CHOICE',
    MANAGEMENT_MODELISATION: 'MANAGEMENT_MODELISATION',
    RESULT: 'RESULT',
};

export const ProcessStatus = {
    Unknown: 0,
    Waiting: 1,
    InProgress: 2,
    Success: 3,
    Error: 4,
    Ready: 5,
    Deferred: 6,
};

export const ActionGoToStepOfModelisation = (nextStepValue) => ({
    type: GO_TO_STEP_OF_MODELISATION,
    modelisationStep: nextStepValue,
});

export const ActionSaveChosenCrop = (chosenCrop) => ({
    type: SAVE_CHOSEN_CROP,
    chosenCrop: chosenCrop,
});

export const ActionSaveChosenParcels = (listOfParcels) => ({
    type: SAVE_CHOSEN_PARCELS,
    parcelIdsSelected: listOfParcels,
});

/*export*/ const ActionLoadingModelisations = () => ({
    type: LOADING_MODELISATIONS,
});

/*export*/ const ActionInitModelisationDico = (modelisationDicoValue) => ({
    type: LOADED_MODELISATIONS,
    modelisationDico: modelisationDicoValue
});

/*export*/ const ActionGetCulturesModelisation = (cultures) => ({
    type: GET_CULTURES_MODELISATION,
    culturesForModelisation: cultures
});

/*export*/ const ActionSuccessInitModelisation = (modelisationDicoValue) => ({
    type: SUCCESS_INIT_MODELISATIONS,
    modelisationsInitialized: modelisationDicoValue
});

/*export*/ const ActionErrorInitModelisation = (errorValue) => ({
    type: ERROR_INIT_MODELISATIONS,
    errorMessage: errorValue,
});

/*export*/ const ActionErrorLoadCultures = (errorValue) => ({
    type: ERROR_LOAD_CULTURES_MODELISATION,
    errorMessage: errorValue,
});

/*export*/ const ActionErrorLoadModelisations = (errorValue) => ({
    type: ERROR_LOAD_MODELISATIONS,
    errorMessage: errorValue,
});

const ActionSetModelisationProcessing = (modelisationProcessing) => ({
    type: SET_MODELISATION_PROCESSING,
    modelisationProcessing: modelisationProcessing,
});

/**
 * Action permettant de sauvegarder la culture sélectionnée et de passée à l'étape de sélection de parcelle
 * @param {*} chosenCrop Culture de modélisation sélectionnée pour le processus d'initialisation
 */
export function ActionSelectChosenCrop(chosenCrop) {
    return function (dispatch, getState) {
        dispatch(ActionSaveChosenCrop(chosenCrop));
        dispatch(ActionSaveChosenParcels([]));
        dispatch(ActionGoToStepOfModelisation(ModelisationSteps.PARCELS_CHOICE));
    }
}

export function ActionSelectParcelForModelisation(listOfParcels, listModelisationParcelsInfo) {
    return function (dispatch, getState) {

        // 1- Vérification des parcels pour lesquelles on a déjà fait une demande d'histo
        const currentState = getState();
        const previousParcelIdsSelected = lodashGet(currentState, "modelisationData.parcelIdsSelected", []);
        
        const modelisationInfosForAskToGenerate = lodashFilter(listModelisationParcelsInfo, (m) => (!previousParcelIdsSelected.includes(m.parcelId)));

        // 2- demande de génération d'historique pour la modélisation (génération de la date semis)
        if (!lodashIsEmpty(modelisationInfosForAskToGenerate)) {
            modelisationWebApiProvider.askToGenerateForModelisation(dispatch, getState, modelisationInfosForAskToGenerate);
        }

        // 3- changement de page pour que le client aille sur la page de saisie:
        dispatch(ActionGoToStepOfModelisation(ModelisationSteps.MANAGEMENT_MODELISATION));
        dispatch(ActionSaveChosenParcels(listOfParcels));

    }
}

/* Obtention des cultures pour commencer la modélisation */
export function ActionInitCultures() {
    return function (dispatch, getState, { userAgentAuthent }) {
        modelisationWebApiProvider.loadCultures(dispatch, getState) //demande la liste des cultures pour commencer la fumure !
            .then((respDatas) => {
                dispatch(ActionGetCulturesModelisation(respDatas));
                    
            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorLoadCultures(error));
            });
    }
}

/**
 * Récupération des modélisations étant en cours d'initialisation
 */
export function ActionGetModelisations() {
    return function (dispatch, getState) {

        const currentState = getState();
        const modelisationProcessing = lodashGet(currentState, 'modelisationData.modelisationProcessing', []);

        if (!lodashIsEmpty(modelisationProcessing)) {

            const modelisationDico = lodashGet(currentState, 'modelisationData.modelisationDico', {});
            const modelisationsToSearch = lodashPick(modelisationDico, modelisationProcessing);

            // Récupération des parcelIds lié au modélisation
            let parcelIds = Object.values(modelisationsToSearch).map(m => m.parcelId);

            // Récupération des modelisations
            modelisationWebApiProvider.loadModelisations(dispatch, getState, parcelIds)
                .then((modelisations) => {
                    
                    // On met à jour le dico des modélisations
                    let newModelisationDico = Object.assign({}, modelisationDico, modelisations);
                    dispatch(ActionInitModelisationDico(newModelisationDico));

                    // Récupère les modélisations ayant fini l'init
                    let modelisationInitialized = lodashFilter(modelisations, (m) => (m.modelisationStatus !== ProcessStatus.InProgress));

                    if (!lodashIsEmpty(modelisationInitialized)) {
                        // On retire ces modélisations à celles étant en cours de process
                        let modelisationIds = modelisationInitialized.map(m => m.id.toString());
                        let newModelisationProcessing = modelisationProcessing.filter((mid) => !modelisationIds.includes(mid));
                        dispatch(ActionSetModelisationProcessing(newModelisationProcessing));

                        //const culturesForModelisation = lodashGet(currentState, 'modelisationData.culturesForModelisation', {});

                        let parcelListToUpdate = [];
                        // Pour chaque modélisation on va récupérer re-demander le charger de son historique.
                        // On ne veut pas itérer sur une valeur "undefined" ou "null" donc on filtre la liste avant 
                        // C'est mieux de filtrer avant le forEach que de skip une itération de ce dernier
                        modelisationInitialized.filter((m) => !lodashIsNil(m)).forEach((m) => {
                            /*let crop = culturesForModelisation.find(c => c.cropType === m.cropModel)
                            let harvestDate = ModelisationHelper.getDefaultHarvestDate(crop, m.campagne);*/
                            parcelListToUpdate.push(m.parcelId);
                        });
                        // Appel au rechargement de l'historique de chaque parcelle:
                        dispatch(ActionAskReloadAllSatimageForAllParcels(1, parcelListToUpdate)); //Rq: pas 'ActionAskUpdateAllSatimageFor...' car on n'a pas besoin de générer des images, juste les filtrer !
                    }

                    
                },
                (error) => {
                    //Avertit du mauvais retour:
                    dispatch(ActionErrorLoadModelisations(error));
                });
        }
    };
}

/* Obtentions de toutes les modélisations en fonction du clientId */
export function ActionGetAllModelisations(changeStep = true) {
    return function (dispatch, getState) {
        // 1- informe que l'on va faire la demande d'obtension des modélisations du client:
        dispatch(ActionLoadingModelisations());

        // 2- récupération des modelisations
        modelisationWebApiProvider.loadModelisations(dispatch, getState)
            .then((modelisations) => {
                // Si le client a déjà des modélsiations alors on ne lui affiche pas l'écran de présentation
                // mais directement celui du choix de la culture et des parcelles
                if (changeStep && (Object.keys(modelisations).length > 0)) {
                    dispatch(ActionGoToStepOfModelisation(ModelisationSteps.CROP_CHOICE));
                }

                //Fournit les données des modelisations du client:
                dispatch(ActionInitModelisationDico(modelisations));

                // Récupère les modélisations ayant fini l'init
                let modelisationInitializing = lodashFilter(modelisations, (m) => (m.modelisationStatus === ProcessStatus.InProgress));

                if (!lodashIsEmpty(modelisationInitializing)) {
                    let modelisationIds = modelisationInitializing.map(m => m.id.toString());
                    dispatch(ActionSetModelisationProcessing(modelisationIds));
                }
            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorLoadModelisations(error));
            });
    };
}

/**
 * Fonction permettant de lancer la modélisation pour les parcelles voulues
 * @param {*} nextStepValue 
 * @param {*} listOfModelisations 
 */
export function ActionInitModelisation(nextStepValue, listOfModelisations) {
    return function (dispatch, getState) {
        // 1- changement de page pour que le client aille sur la page de résultats:
        dispatch(ActionGoToStepOfModelisation(nextStepValue));

        // 2- récupération de la campagne en fonction de la date de semis
        const currentStoreState = getState();
        const culturesForModelisation = lodashGet(currentStoreState, 'modelisationData.culturesForModelisation', []);
        const clientId = lodashGet(currentStoreState,'clientUserData.userDatas.idClient', 0);

        // 3- initialisation des modelisations
        modelisationWebApiProvider.initModelisations(dispatch, getState, clientId, listOfModelisations)
            .then((modelisations) => {
                
                // Maj Redux des parcels
                // On prefère recreer un nouveau dico en se basant sur le premier pour cela
                // Cela permet une seule maj Redux pour toutes les parcelles => gain de perf de l'appli
                const parcelDico = lodashGet(currentStoreState, 'parcelsData.parcelDico', {});
                let newParcelDico = Object.assign({}, parcelDico);

                // Pour chaque parcelle, on vient mettre à jour ses données de date de semis et de culture
                for (let index in listOfModelisations) {
                    let modelisation = listOfModelisations[index];
                    newParcelDico[modelisation.parcelId] = {
                        ...newParcelDico[modelisation.parcelId],
                        details: {
                            ...newParcelDico[modelisation.parcelId].details,
                            culture: culturesForModelisation.find(c => c.cropType === modelisation.cropModel).name ?? "",
                            dateSemi: modelisation.sowingDate,
                            campagne: modelisation.campagne,
                        }
                    }
                }
                dispatch(ActionUpdateParcelDico(newParcelDico));

                // Retire les images modélisées d'ancienne modélisation ayant une date entre la date de semis et la date de récolte de la nouvelle modélisation
                dispatch(ActionRemoveModelisationImage(listOfModelisations));
                
                //Fournit les données des modelisations du client:
                dispatch(ActionSuccessInitModelisation(modelisations));
            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorInitModelisation(error));
            });
        
    };
}

/**
 * Fonction permettant de supprimer une modélisation (ne supprime pas les images modélisées)
 * @param {number[]} parcelIds ids des parcelles pour lesquelles il faut supprimer la modélisation
 */
export function ActionDeleteModelisation(parcelIds) {
    return function (dispatch, getState) {
        modelisationWebApiProvider.deleteModelisation(dispatch, getState, parcelIds)
            .then(() => {
                //Maj redux des modélisations
                const currentStoreState = getState();
                const modelisationDico = lodashGet(currentStoreState, 'modelisationData.modelisationDico', {});
                let newModelisationDico = Object.assign({}, modelisationDico);

                parcelIds.forEach((parcelId) => {
                    // récupère la clef du dico
                    let modelisationId = lodashFindKey(newModelisationDico, m => m.parcelId === parcelId);
                    // supprime la clef du dico
                    lodashUnset(newModelisationDico, modelisationId.toString());
                });

                dispatch(ActionInitModelisationDico(newModelisationDico));
            },
            (error) => {
                // TODO gérer les cas d'erreurs
            });
    }
}