import { ActionLoadingSettings, ActionSettingsLoaded, ActionErrorLoadSettings } from './settings.js';
import { ActionLoadingParcels, ActionParcelsLoadedAndFilter, ActionErrorLoadParcels, ActionAddParcelsWithCurrentSatImage } from './parcels.js';
import { ActionAskFirstHistoForAllParcel, ActionGetHistoForAllParcelsByMonthYear, ActionAddLastSatImageByParcelIdDico } from './satImage.js';
import { ActionAddObservationDico, ActionAddObservationTypeDico } from './observations.js';
import { ActionForceInviteToPremium, ActionUpdateFilterAndApply  } from './contextApp.js';
import { ActionInitDidacticielFirstParcel } from './didacticiel.js';
import { clientWebApiProvider, stripeWebApiProvider, storeNativeAppWebApiProvider } from '../../utils/webApiProvider.js';
import { API } from '../../utils/webApiProvider.js'; 
import lodashGet from 'lodash/get';
import { ActionSetStateConnexionToConnected } from './connection.js';
import { IsNativeHoster, IsIOS, GetOSName } from '../../utils/platformHelper';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import sendError from '../../utils/errorService.js';
import { GetDefaultForewardLayerName } from '../../utils/constantsOfLayers.js';


/* déclaration des actions de connexion */ 
const AUTHENT_SEARCHING_CLIENT = 'AUTHENT_SEARCHING_CLIENT';
const AUTHENT_CLIENT_FUND = 'AUTHENT_CLIENT_FUND';
const SEARCH_CLIENT_ERROR = 'SEARCH_CLIENT_ERROR';
const ADD_CLIENT_BILLING = 'ADD_CLIENT_BILLING';
const UPDATING_CLIENT_BILLING = 'UPDATING_CLIENT_BILLING';
const UPDATE_CLIENT_BILLING = 'UPDATE_CLIENT_BILLING';
const ERROR_UPDATE_CLIENT_BILLING = 'ERROR_UPDATE_CLIENT_BILLING';
const GETTING_CLIENT_BILLING = 'GETTING_CLIENT_BILLING';
const ERROR_GET_CLIENT_BILLING = 'ERROR_GET_CLIENT_BILLING';

const GETTING_PLANS_SUBSCRIPTIONS = 'GETTING_PLAN_SUBSCRIPTIONS';
const ADD_PLANS_SUBSCRIPTIONS = 'ADD_PLANS_SUBSCRIPTIONS';
const ERROR_GET_PLANS_SUBSCRIPTIONS = 'ERROR_GET_PLANS_SUBSCRIPTIONS';
const ADD_DISCOUNTED_PLAN = 'ADD_DISCOUNTED_PLAN';

const SELECT_PLAN = 'SELECT_PLAN';
const SET_IS_FORM_VALID = 'SET_IS_FORM_VALID';
const SET_IS_NEW_SUBSCRIPTION = 'SET_IS_NEW_SUBSCRIPTION';
const SUBSCRIPTION_PROCESS_ASKING = 'SUBSCRIPTION_PROCESS_ASKING';
const END_SUBSCRIPTION_PROCESS = 'END_SUBSCRIPTION_PROCESS';
const ERROR_SUBSCRIPTION_PROCESS = 'ERROR_SUBSCRIPTION_PROCESS';
const RESET_SUBSCRIPTION_PROCESS = 'RESET_SUBSCRIPTION_PROCESS';

const UPDATE_TYPO_CLIENT = 'UPDATE_TYPO_CLIENT';

const DOWNLOADING_INVOICE_FILE = 'DOWNLOADING_INVOICE_FILE';
const INVOICE_FILE_DOWNLOADED = 'INVOICE_FILE_DOWNLOADED';

const UPDATE_PLANSTRIPE_WITH_STOREPLANIDS = 'UPDATE_PLANSTRIPE_WITH_STOREPLANIDS';
const SHOW_PURCHASE_STORE_RESULT = 'SHOW_PURCHASE_STORE_RESULT';
const CLOSE_PURCHASE_STORE_RESULT = 'CLOSE_PURCHASE_STORE_RESULT';

/* Enuméré des actions de gestion du client et utilisateur connecté: */
export const ActionTypeClientUser = {
    SearchingClient: AUTHENT_SEARCHING_CLIENT,
    ClientFund: AUTHENT_CLIENT_FUND,
    ErrorSearchClient: SEARCH_CLIENT_ERROR,
    AddClientBilling: ADD_CLIENT_BILLING,
    updatingClientBilling: UPDATING_CLIENT_BILLING,
    updateClientBilling: UPDATE_CLIENT_BILLING,
    errorUpdateClientBilling: ERROR_UPDATE_CLIENT_BILLING,
    gettingClientBilling:GETTING_CLIENT_BILLING,
    errorGetClientBilling:ERROR_GET_CLIENT_BILLING,
    gettingPlansSubscriptions: GETTING_PLANS_SUBSCRIPTIONS,
    addPlansSubscriptions: ADD_PLANS_SUBSCRIPTIONS,
    errorGetPlansSubscriptions: ERROR_GET_PLANS_SUBSCRIPTIONS,
    addDiscountedPlan: ADD_DISCOUNTED_PLAN,
    selectPlan: SELECT_PLAN,
    setIsFormValid: SET_IS_FORM_VALID,
    setIsNewSubscription: SET_IS_NEW_SUBSCRIPTION,
    askSubscriptionProcess: SUBSCRIPTION_PROCESS_ASKING,
    endSubscriptionProcess: END_SUBSCRIPTION_PROCESS,
    errorSubscriptionProcess: ERROR_SUBSCRIPTION_PROCESS,
    resetSubscriptionProcess: RESET_SUBSCRIPTION_PROCESS,
    updateTypoClient: UPDATE_TYPO_CLIENT,
    downloadingInvoice: DOWNLOADING_INVOICE_FILE,
    invoiceDownloaded: INVOICE_FILE_DOWNLOADED,
    updatePlanWithStoreIds: UPDATE_PLANSTRIPE_WITH_STOREPLANIDS,
    showPurchaseStoreResult: SHOW_PURCHASE_STORE_RESULT,
    closePurchaseStoreResult: CLOSE_PURCHASE_STORE_RESULT,
}

/* Actions creator */
export function ActionSearchingClient() {
    return {
        type: AUTHENT_SEARCHING_CLIENT,
    };
}

/*export*/ function ActionClientFund(ClientDatasValue) { //Pas exporter pour forcer l'enchaînement avec la demande de génération d'historique. //Reçoit l'entité C# 'AllDataClient'.
    return {
        type: AUTHENT_CLIENT_FUND,
        clientDatas: ClientDatasValue,
    };
}

export function ActionErrorSearchClient(errorMessage) {
    return {
        type: SEARCH_CLIENT_ERROR,
        error: errorMessage,
    };
}

export function ActionAddClientBilling(ClientBillingValue) {
    return {
        type: ADD_CLIENT_BILLING,
        clientBilling: ClientBillingValue,
    };
}

export function ActionUpdatingClientBilling() {
    return {
        type: UPDATING_CLIENT_BILLING,
    };
}

export function ActionUpdateClientBilling(clientBilling) {
    return {
        type: UPDATE_CLIENT_BILLING,
        clientBilling: clientBilling,
    };
}

export function ActionErrorUpdateClientBilling(errorMessage) {
    return {
        type: ERROR_UPDATE_CLIENT_BILLING,
        error: errorMessage,
    };
}

export function ActionGettingPlansSubscriptions() {
    return {
        type: GETTING_PLANS_SUBSCRIPTIONS,
    };
}

export function ActionAddPlansSubscriptions(plansSubscriptions) {
    return {
        type: ADD_PLANS_SUBSCRIPTIONS,
        planSubscriptionDico: plansSubscriptions,
    };
}

export function ActionErrorGetPlansSubscriptions(error) {
    return {
        type: ERROR_GET_PLANS_SUBSCRIPTIONS,
        error: error
    }
}

export function ActionAddDiscountedPlan(discountPlan, planId) {
    return {
        type: ADD_DISCOUNTED_PLAN,
        discountPlan: discountPlan,
        planId: planId,
    }
}

export function ActionSelectPlan(planId, discountPlanId) {
    return {
        type: SELECT_PLAN,
        planId: planId,
        discountPlanId: discountPlanId
    }
}

export function ActionSetIsFormValid(validValue) {
    return {
        type: SET_IS_FORM_VALID,
        isFormValid: validValue,
    }
}

export function ActionErrorSubscriptionProcess(messageErrorValue) {
    return {
        type: ERROR_SUBSCRIPTION_PROCESS,
        error: messageErrorValue
    }
}

export function ActionResetSubscriptionProcess() {
    return {
        type: RESET_SUBSCRIPTION_PROCESS,
    }
}

export function ActionReInitSubscriptionProcess() {
    return function(dispatch, getState , { history, userAgentAuthent }) {
        const thisPromise = function (resolve, reject) {
            //effacte les infos du précédent processus de souscription/achat:
            dispatch({
                type: RESET_SUBSCRIPTION_PROCESS,
            });

            resolve();
        };

        return new Promise(thisPromise);
    };
}

export function ActionSetIsNewSubscription(bool) {
    return {
        type: SET_IS_NEW_SUBSCRIPTION,
        bool: bool
    }
}

export function ActionGettingClientBilling() {
    return {
        type: GETTING_CLIENT_BILLING,
    }
}

export function ActionErrorGetClientBilling(error) {
    return {
        type: ERROR_GET_CLIENT_BILLING,
        error: error,
    }
}

export function ActionUpdateTypoClient(typoClientObj) {
    return {
        type: UPDATE_TYPO_CLIENT,
        typoClientToUpdate: typoClientObj
    };
}


function ActionDetectAndSaveGodfather(clientFund) {
    return function(dispatch, getState /*, { history, userAgentAuthent }*/) {
        const currentStoreState = getState();
        const parrainEmail = lodashGet(currentStoreState, 'contextAppData.URLParameters.godfather', undefined);

        if (parrainEmail && (parrainEmail !== '') && clientFund && (clientFund.id > 0) && (clientFund.isNewer === true)) {
            // ↓ appel API ↓
            clientWebApiProvider.updateClientGodfather(dispatch, getState, clientFund.id, parrainEmail)
                .then((response) => {
                    // Signale que le parrain a bien été retrouvé:
                    //dispatch( Action@@@(response.user.id) ); //pas besoin à ce jour, car zéro fonctionnel associé !
                })
                .catch((error) => dispatch( ActionErrorSearchClient(error) ))
        }
    }
}

/**
 * Fonction permettant d'obtenir les données clients juste après l'authentification
 */
export function ActionSearchOrCreateClientAsk() {
    return function(dispatch, getState , { history, userAgentAuthent }) {
        //console.log(`ActionSetAccessTokenAndSearchClient - start: ...`);

        // Informe que l'on va faire la demande de recherche(ou création) des données client:
        dispatch( ActionSearchingClient() );
        //Avertit aussi le gestionnaire du paramétrage, du parcellaire:
        dispatch( ActionLoadingSettings() );
        dispatch( ActionLoadingParcels() );

        clientWebApiProvider.searchOrCreateClientDatasConnected(dispatch, getState)
            .then((response) => {

                //Fournis les données sur le client , l'utilisateur et la typoClient:
                dispatch( ActionClientFund(response) );

                // fournis l'Id client à l'API
                API.clientId = (response && response.client && (response.client.id > 0)) ? response.client.id : -1;

                // Si on (l'application web) est hébergée dans l'appli native, il faut l'informer l'application native (si l'appli web y est lancée):
                if ((IsNativeHoster() === true) && userAgentAuthent && userAgentAuthent.commProviderWithNativeApp && //Si l'appli Web est lancée dans l'appli Native...I
                    response && response.client && (response.client.masterEmail)) { //les infos utiles sont connues et valides !
                    userAgentAuthent.commProviderWithNativeApp.notifySpotifarmClientInfos(response.client.masterEmail, API.clientId);
                    //puis l'info de la langue courante:
                    userAgentAuthent.commProviderWithNativeApp.notifyChangeLanguage(response.settings.language); //considère la langue comme étant correcte !
                }
                
                //Ajouter le déclenchement de l'incitation si définis par la Web API....
                //RQ : utilisera 'response.clientDatas.user.forceIncitation' et 'response.clientDatas.user.specificTypeIncitation' pour déterniner s'il y en a une ou pas !
                dispatch( ActionForceInviteToPremium(null, response.client)); 

                // ajout de l'objet clientBilling ⚠️ - fusion avec les informations clients
                dispatch( ActionAddClientBilling(response.clientBilling) );

                //Signale que l'on a reçu aussi le paramètrage:
                if (response.settings) response.settings.lastLayer = GetDefaultForewardLayerName(); // pour forcer 'forewardLayerSelected' !
                dispatch( ActionSettingsLoaded(response.settings) );

                // ajout des données satImage de type <parcelId, satImageDatas>
                if (response.lastSatimageByParcelIdDico && (Object.keys(response.lastSatimageByParcelIdDico).length > 0))
                    dispatch( ActionAddLastSatImageByParcelIdDico(response.lastSatimageByParcelIdDico) );

                //Signale que l'on a reçu aussi le parcellaire:
                if (response.lastSatimageByParcelIdDico && (Object.keys(response.lastSatimageByParcelIdDico).length > 0))
                    dispatch( ActionAddParcelsWithCurrentSatImage(response.parcels, response.lastSatimageByParcelIdDico) );
                else 
                    dispatch( ActionParcelsLoadedAndFilter(response.parcels) );

                //On vérifie si on avait un parrain... Et dans ce cas, on l'enregistre en BdD:
                dispatch( ActionDetectAndSaveGodfather(response.client) );

                // ajout d'une liste d'observation - ⚠️ placement important (placé après ActionParcelsLoadedAndFilter - layer inséré après les layers de parcelle renderMaps)
                dispatch( ActionAddObservationDico(response.observations) );

                // ajout d'une liste de type d'observation - ces données vont être stoquées directement dans le reducer observatin
                dispatch( ActionAddObservationTypeDico(response.observationTypes) );

                // didacticiel - suivant les informations reçues, on va procéder à un lancement (ou non) automatique du didacticiel firstparcel
                dispatch( ActionInitDidacticielFirstParcel(response) );

                // passage de l'application en mode connecté 
                dispatch( ActionSetStateConnexionToConnected() );

                // Applique le filtre des parcelles sur la carte
                if (response.settings && response.settings.mapParcelFilter) {
                    let filter = JSON.parse(response.settings.mapParcelFilter);
                    dispatch(ActionUpdateFilterAndApply(filter));
                }

                //On lance la demande de mise à jour l'historique de chaque parcelle - si l'utilisateur a les droits:
                if (response.client) {
                    if (response.client.authorizeHistoric === true)
                        dispatch( ActionAskFirstHistoForAllParcel(1, response.client) ); //En principe les infos du client (ID), de son parcellaire sont affectés dans le store lors du traitement de cette appel...
                    else {
                        const currentDate = new Date();
                        dispatch(ActionGetHistoForAllParcelsByMonthYear(currentDate.getMonth(), currentDate.getFullYear()) ); //récupération uniquement des données stockées en BDD en fonction du mois et de l'année (aucun appel sentinel-hub pour de nouvelles images) pour toutes les parcelles 
                    }
                }
            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch( ActionErrorSearchClient(error) );
                //Avertit aussi le gestionnaire du paramétrage, du parcellaire:
                dispatch( ActionErrorLoadSettings(error) );
                dispatch( ActionErrorLoadParcels(error) );
            });
    }
}


///////////////////////////////////////////////////////////////// ↓↓ Fonctionnels liés aux achats (de Stripe et des Stores) ↓↓

/* fonction permettant de mettre à jour les informations de clientBilling */
export function ActionSaveUpdatedClientBilling(clientbillingToSave) {
    return function(dispatch, getState) {
        
        // ↓ mise en attente de l'utilisateur ↓
        dispatch( ActionUpdatingClientBilling() );

        //juste pour les traces occasionnel !
        const currentStoreState = getState();
        const idClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);

        // ↓ appel API ↓
        return clientWebApiProvider.updateClientDatas(dispatch, getState, clientbillingToSave)
            .then((response) => {
                // Signale que l'on a bien mis à jour les informations clientBilling:
                dispatch( ActionUpdateClientBilling(clientbillingToSave) );
            })
            .catch((error) => {
                dispatch( ActionErrorUpdateClientBilling(StringTranslate.errorUpdateClientBilling) );
                
                //Log Azure :
                sendError('ActionSaveUpdatedClientBilling - updateClientDatas', {
                    "erreur": "L'appel à l'enregistrement des infos de facturation client (CB) a échoué.",
                    error: error,
                    "idOfClient": idClient,
                    "clientInfos": clientbillingToSave,
                });
            })
    }
}

/* fonction permettant d'obtenir la liste des plans et des souscriptions d'un client */
export function ActionGetPlansSubscriptions() {
    return function(dispatch, getState) {
         // ↓ mise en attente de l'utilisateur ↓
        dispatch( ActionGettingPlansSubscriptions() );

        //juste pour les traces occasionnel !
        const currentStoreState = getState();
        const idClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);

        // ↓ appel API ↓
        return stripeWebApiProvider.getPlansSubscribs(dispatch, getState)
            .then((planSubscriptionDico) => dispatch( ActionAddPlansSubscriptions(planSubscriptionDico) ) ) 
            .catch((error) => {
                dispatch( ActionErrorGetPlansSubscriptions(StringTranslate.errorGetClientBilling) );

                //Log Azure :
                sendError('ActionGetPlansSubscriptions - getPlansSubscribs', {
                    "erreur": "L'appel de la lecture des plans du Store a échoué.",
                    error: error,
                    "idOfClient": idClient,
                });
            });
    };
}

/* export */ function ActionUpdateSelectedPlanWithStoreIds(storePlanIdsObject) { 
    return {
        type: UPDATE_PLANSTRIPE_WITH_STOREPLANIDS,
        storePlanIdByOs: storePlanIdsObject
    };
}

/* export */ function ActionShowPurchaseStoreResult(isOkValue = false) { 
    return {
        type: SHOW_PURCHASE_STORE_RESULT,
        isOk: isOkValue,
    };
}
export function ActionClosePurchaseStoreResult() { 
    return {
        type: CLOSE_PURCHASE_STORE_RESULT,
    };
}

/* fonction permettant d'appeler l'application native afin de déclencher l'achat In-App */
export function ActionBuyOnNativeAppProcess() {
    return function(dispatch, getState, { history, userAgentAuthent }) {
        // Si on arrive ici, c'est que l'application web est hébergée dans l'appli native, on envoie un message à l'application native 
        //  pour qu'elle déclenche l'achat via le Store correspondant à l'OS du mobile ET au plan du Store référencé dans le plan Stripe:
        const currentStoreState = getState();
        const idClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);

        const subscriptionSelected = lodashGet(currentStoreState, 'clientUserData.subscriptionProcess', undefined);
        if ((IsNativeHoster() === true) && userAgentAuthent && userAgentAuthent.commProviderWithNativeApp && //Si l'appli Web est lancée dans l'appli Native...I
            subscriptionSelected && (subscriptionSelected.planId !== undefined) && (subscriptionSelected.planId !== '')) { //les infos utiles sont connues et valides !
            //recherche des données du plan Stripe dont la metadonnées contenant le SKU du Store:
            const planStripeItem = lodashGet(currentStoreState, `clientUserData.planSubscriptionDico[${subscriptionSelected.planId}]`, undefined);
            if (planStripeItem && planStripeItem.metadata && planStripeItem.metadata['skuIOS'] && planStripeItem.metadata['skuAndroid']) { 
                const storePlanIdByOs = { appleId: planStripeItem.metadata['skuIOS'], googleId: planStripeItem.metadata['skuAndroid'] };
                dispatch( ActionUpdateSelectedPlanWithStoreIds(storePlanIdByOs) );
                
                userAgentAuthent.commProviderWithNativeApp.askPurchaseStorePlan(storePlanIdByOs);
            } else {
                //Avertit du mauvais retour:
                dispatch( ActionShowErrorOfPurchaseStore(StringTranslate.errorNoPurchaseWithoutIdStorePlan) );
                //Log Azure :
                sendError('ActionBuyOnNativeAppProcess - sku', {
                    "erreur": "L'obtension du SKU du plan Store de l'OS mobile visé n'a pas été trouvé.",
                    "idOfClient": idClient,
                    "planStripeItem": planStripeItem, 
                });
            }
        } else {
            //Avertit du mauvais retour:
            dispatch( ActionShowErrorOfPurchaseStore(StringTranslate.errorNoPurchaseWithoutSelection) );
            //Log Azure :
            sendError('ActionBuyOnNativeAppProcess - ctrls', {
                "erreur": "L'appel à l'achat In-App ne peut se faire que depuis l'appli mobile.",
                "idOfClient": idClient,
            });
        }
    };
} 

/* fonction permettant de réagir suite à un achat sur les Stores (notifié par l'application native) */
export function ActionUpdatePurchasedStorePlan(purchaseDatasValue, platformOSValue = undefined) { 
    return function(dispatch, getState, { history, userAgentAuthent }) {
        // Si on arrive ici, c'est que l'application web est hébergée dans l'appli native, on a reçu un message de l'application native 
        //  pour nous indiquer qu'un achat via le Store (correspondant à l'OS du mobile) a réussi.
        // => On demande à la Web API de dévérouiller les accès associés au plan 'souscrit':
        let planIdStore = (purchaseDatasValue) ? purchaseDatasValue.productId : undefined; 
        //Rq : il ne faut pas se baser sur 'getState().clientUserData.subscriptionProcess.planId' et 'getState().clientUserData.subscriptionProcess.storePlanIds'
        // car il se peut que l'on fasse appel à cette méthode, ici présente, bien plus tard après le clic d'achat sur le plan affiché dans la page des aboenement Spotifarm !
        // MAIS ca ne coûte rien de tenter de le lire s'il est lâ !
        const currentStoreState = getState();
        const isIosMobile = (IsIOS() || (platformOSValue && (platformOSValue.toLowerCase() === 'ios'))) ? true : false;
        if ((!planIdStore) || (planIdStore === '')) {
            //Ne devrait pas arriver MAIS...
            //Dans ce cas, on peut rechercher le plan Stripe associé en recherchant dans la meta de chaque plan connu...
            try {
                const subscriptionStorePlanIds = lodashGet(currentStoreState, 'clientUserData.subscriptionProcess.storePlanIdByOs', undefined);
                planIdStore = (subscriptionStorePlanIds) ? (
                    (isIosMobile === true) ? subscriptionStorePlanIds.appleId : subscriptionStorePlanIds.googleId ) : planIdStore;
            }
            catch(err) {
                //Tampis !!! on n'ira pas plus loin !
            }
        }

        const idClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
        if (planIdStore && (planIdStore !== '') /*&& planIdStripe*/) { //le plan stripe pourra être retrouvé côté Web API !
            const datasPurchase = {
                storeName: (isIosMobile === true) ? 'Apple Store' : 'Google Play',
                osName: (isIosMobile === IsIOS()) ? GetOSName() : ((isIosMobile) ? 'IOS': 'ANDROID'),
                productId: planIdStore, 
                transactionId: (purchaseDatasValue) ? purchaseDatasValue.transactionId : undefined, 
                transactionDate: (purchaseDatasValue) ? purchaseDatasValue.transactionDate : Date.now(), 
                transactionReceipt: (purchaseDatasValue) ? purchaseDatasValue.transactionReceipt : undefined, 
                purchaseToken: (purchaseDatasValue) ? purchaseDatasValue.purchaseToken : undefined, 
                idClient: idClient, 
            };
            
            return storeNativeAppWebApiProvider.upgradeClientAfterPurchase(dispatch, getState, datasPurchase)
                .then( (result) => { // result => object typé 'PurchaseStoreDatas' !
                    if (result) {
                        //on ne fait rien de ce 'result'!

                        //on prévient l'appli mobile que cela a bien été pris en compte (l'achat auprès du Store):
                        if (userAgentAuthent && userAgentAuthent.commProviderWithNativeApp) {
                            userAgentAuthent.commProviderWithNativeApp.notifyClientUpgradedWithPurchase(purchaseDatasValue);
                        }
                        
                        //on affiche le bon résultat de l'achat et de l'activation des droits:
                        return dispatch( ActionShowPurchaseStoreResult(true) ); //résultat positif !
                        /*return dispatch( ActionGetClientDatasAfterSubscriptionAsk() ) 
                            .then(() => {
                                dispatch( ActionGoToMap() );

                                return true;
                            })
                            .catch( (err) => {
                                // on signale l'échec d'activation des droits associés à l'achat:
                                dispatch( ActionShowErrorOfPurchaseStore(`${StringTranslate.errorUpdateClientAfterPurchasedFromNativeAppMessage}`) );
                                    
                                return false;
                            });*/ //et c'est cet écran qui fermera la dialogue qui vient d'être ouverte !
                    } else {
                        return false;
                    }
                })
                .catch( (err) => {
                    //Avertit du mauvais retour:
                    dispatch( ActionShowErrorOfPurchaseStore(StringTranslate.errorUpgradeClientRulesAfterPurchase) );
                    //Log Azure :
                    sendError('ActionUpdatePurchasedStorePlan - upgradeClientAfterPurchase', {
                        "erreur": "L'appel à la mise à jour d'une typo client suite à un achat a retourné une erreur.",
                        err: err,
                        "idOfClient": idClient,
                        "planIdStore": planIdStore,
                    });

                    return false;
                });
        } else { //pas normal que l'on n'arrive pas à retrouver l'info (identifiant du plan acheté) !
            //Avertit du mauvais retour:
            dispatch( ActionShowErrorOfPurchaseStore(StringTranslate.errorPurchasePlanNotDetected) );
            //Log Azure :
            sendError('ActionUpdatePurchasedStorePlan - planIdStore', {
                "erreur": "L'appel à la mise à jour d'une typo client suite à un achat ne peut se faire sans l'ID du plan en lien.",
                "idOfClient": idClient,
                "planIdStore": planIdStore,
            });

            /*return ( new Promise((resolveFct, rejectFct) => {
                //on renvoie une erreur !
                rejectFct("Le plan souscrit n'a pas pû être identifié ! L'octroit de droits ne peut avoir lieu.");
            }) );*/
            return false;
        }
    };
}

/* fonction permettant de réagir suite à l'arrêt (ou l'échec) d'une souscription depuis les stores (notifié par l'application native) */
export function ActionStopStorePlan(purchaseDatasValue, platformOSValue = undefined) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        // Si on arrive ici, c'est que l'application web est hébergée dans l'appli native, on a reçu un message de l'application native 
        //  pour nous indiquer qu'une souscription de Store (correspondant à l'OS du mobile) a été annulé, arrêté.
        // => On demande à la Web API de REvérouiller les accès qui avaient été accordé:
        let planIdStore = (purchaseDatasValue) ? purchaseDatasValue.productId : undefined;
        const isIosMobile = (IsIOS() || (platformOSValue && (platformOSValue.toLowerCase() === 'ios'))) ? true : false;
        const currentStoreState = getState();
        const idClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
        //if (planIdStore && (planIdStore !== '')) { //on s'en moque ! Alors on considérera qu'il n'a aucun abo depuis un Store !
        const datasPurchase = {
            storeName: (isIosMobile === true) ? 'Apple Store' : 'Google Play',
            osName: (isIosMobile === IsIOS()) ? GetOSName() : ((isIosMobile) ? 'IOS' : 'ANDROID'),
            productId: planIdStore,
            idClient: idClient,
        };

        return storeNativeAppWebApiProvider.downgradeClient(dispatch, getState, datasPurchase)
            .then((result) => { 
                //RAS !!!
                return false;
            })
            .catch((err) => {
                //Log Azure :
                sendError('ActionStopStorePlan - downgradeClient', {
                    "erreur": "L'appel à la mise à jour d'une typo client suite à l'arrêt d'une souscription (depuis un Store) a retourné une erreur.",
                    err: err,
                    "idOfClient": idClient,
                    "planIdStore": planIdStore,
                });

                return false;
            });
        /*} else { //pas normal que l'on n'arrive pas à retrouver l'info (identifiant du plan acheté) !
            //Log Azure :
            sendError('ActionUpdatePurchasedStorePlan - planIdStore', {
                "erreur": "L'appel à la mise à jour d'une typo client suite à un achat ne peut se faire sans l'ID du plan en lien.",
                "idOfClient": idClient,
                "planIdStore": planIdStore,
            });
            
            return false;
        }*/
    };
}

/* fonction permettant de vérifier l'adéquation entre typo client Spotifarm et la(les) souscription(s) du store (notifié par l'application native) */
export function ActionControlPurchasesStorePlan(purchaseDatasList, platformOSValue = undefined) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        // Si on arrive ici, c'est que l'application web est hébergée dans l'appli native, on a reçu un message de l'application native 
        //  pour nous demander de vérifier les souscriptions de Store (correspondant à l'OS du mobile) détecté depuis le mobile.
        // => On demande à la Web API de vérifier la typologie accordée par rapport aux souscriptions fournies:
        const isIosMobile = (IsIOS() || (platformOSValue && (platformOSValue.toLowerCase() === 'ios'))) ? true : false;
        const currentStoreState = getState();
        const idClient = lodashGet(currentStoreState, 'clientUserData.clientDatas.id', -1);
        //on extrait les Ids de produits encore actifs:
        const productIdList = [];
        if (purchaseDatasList && (Array.isArray(purchaseDatasList)) && (purchaseDatasList.length > 0)) {
            purchaseDatasList.forEach((value, index) => {
                //référence uniquement les produits en ok !
                if ((value) && (value.productId) && (value.productId !== '') && (
                    (isIosMobile === true) || //sous IOS, on ne reçoit que ce qui est encore 'en cours' !
                    ((value.purchaseStateAndroid !== undefined) && (value.purchaseStateAndroid === 1)) )) { //PurchaseStateAndroid.PURCHASED = 1, 
                        productIdList.push(value.productId);
                }
                //else //ne le comptabilise pas !
            });
        }
        
        //if (purchaseDatasList.length > 0) { //on évite de considérer qu'il n'a aucun abo depuis un Store ! (tampis pour ceux qui en aurait réellement plus)
        //if (productIdList.length > 0) {
            const datasPurchases = {
                storeName: (isIosMobile === true) ? 'Apple Store' : 'Google Play',
                osName: (isIosMobile === IsIOS()) ? GetOSName() : ((isIosMobile) ? 'IOS' : 'ANDROID'),
                productIds: productIdList,
                idClient: idClient,
            };

            return storeNativeAppWebApiProvider.controlClient(dispatch, getState, datasPurchases)
                .then((result) => { 
                    //RAS !!!
                    return false;
                })
                .catch((err) => {
                    //Log Azure :
                    sendError('ActionControlPurchasesStorePlan - controlClient', {
                        "erreur": "L'appel au contrôle de la typo client vis-à-vis de ses souscriptions (depuis un Store) a retourné une erreur.",
                        err: err,
                        "idOfClient": idClient,
                        "planIdsStore": productIdList,
                    });

                    return false;
                });
        /*} else { //pas normal que l'on n'arrive pas à recevoir les infos !
            //Log Azure :
            sendError('ActionUpdatePurchasedStorePlan - planIdStore', {
                "erreur": "L'appel à la mise à jour d'une typo client suite à un achat ne peut se faire sans l'ID du plan en lien.",
                "idOfClient": idClient,
                "planIdStore": planIdStore,
            });
            
            return false;
        }*/
    };
}

/* fonction permettant de réagir à une erreur reçue des Stores, vis-à-vis d'un achat (notifié par l'application native) */
export function ActionShowErrorOfPurchaseStore(messageErrorValue) {
    return function(dispatch, getState, { history, userAgentAuthent }) {
        const thisPromise = function (resolve, reject) {
            //Avertit du mauvais retour:
            dispatch( ActionErrorSubscriptionProcess(messageErrorValue) )

            resolve();
        };

        return (new Promise(thisPromise))
            .then(() => {
                //déclenche l'affichage de la popup de résultat (après que le message d'erreur soit enregistré sur Redux...):
                dispatch( ActionShowPurchaseStoreResult() );

                return true;
            })
            .catch( (err) => {
                return false;
            });
    };
}

/* fonction permettant d'obtenir les données d'un client qui vient de souscrire */
export function ActionGetClientDatasAfterSubscriptionAsk() {
    return function(dispatch, getState) {

        dispatch( ActionSearchingClient() );
        dispatch( ActionLoadingParcels() );
        
        //sendError('clientUser - ActionGetClientDatasAfterSubscriptionAsk', { info: 'calling...' });

        return clientWebApiProvider.searchOrCreateClientDatasConnected(dispatch, getState)
            .then((response) => {
                //sendError('clientUser - ActionGetClientDatasAfterSubscriptionAsk', { info: 'OK !' });

                //Fournis les données sur le client et l'utilisateur:
                dispatch ( ActionClientFund(response) );

                // ajout de l'objet clientBilling ⚠️ - fusion avec les informations clients
                dispatch( ActionAddClientBilling(response.clientBilling) );

                //sendError('clientUser - ActionGetClientDatasAfterSubscriptionAsk', { info: 'step2 !' });

                //@@RQs : pas besoin d'actualiser les settings car inchangé par la souscription  !
                // Idem pour le tutoriel "First Parcel" et pour le parainage.
                //@@PAR CONTRE, il faudrait relancer l'obtension des observation qui a pû être actualisé...

                //Signale que l'on a reçu aussi le parcellaire:
                dispatch( ActionParcelsLoadedAndFilter(response.parcels) );
                
                //sendError('clientUser - ActionGetClientDatasAfterSubscriptionAsk', { info: 'step3 !' });

                //On lance la demande de mise à jour l'historique de chaque parcelle:
                dispatch( ActionAskFirstHistoForAllParcel(1, response.client) ); //En principe les infos du client (ID), de son parcellaire sont affectés dans le store lors du traitement de cette appel...
                //RQ: Pas besoin ici, de vérifier si le client a le droit de générer ses histos de parcelles ! Il vient d'acheter, on suppose que c'est compris dans l'abonnement !!!

                //sendError('clientUser - ActionGetClientDatasAfterSubscriptionAsk', { info: 'step4 !' });

                //Puis, on remets tout à zéro concernant le parcours d'achat :
                //if (idPlanDiscounted) StoreFunctions.clearDiscountCodes();
                dispatch( ActionResetSubscriptionProcess() ); // on supprime les données du scénario de souscription
                dispatch( ActionGetPlansSubscriptions() ); // on remet à jour la liste des plans en lien avec la nouvelle souscription
                dispatch( ActionSetIsNewSubscription(true) ); // on indique que l'on est sur une nouvelle souscription (impact sur la visuel)
                
                return true;
            })
            .catch((error) => {
                //Avertit du mauvais retour:
                dispatch( ActionErrorSearchClient(error) );
                dispatch( ActionErrorLoadParcels(error) );
                
                return false;
            });
    };
}

/* fonction permettant d'obtenir les données de facturation d'un client  */
export function ActionGetClientBilling(limitInvoices) {
    
    return function(dispatch, getState) {

        dispatch( ActionSearchingClient() );

        return stripeWebApiProvider.getStripeCustomerInformations(dispatch, getState, limitInvoices)
            .then((clientBilling) => {
                // ajout de l'objet clientBilling (⚠️ - fusion avec les informations clients)
                dispatch( ActionAddClientBilling(clientBilling) );
            })
            .catch((error) => {
                //Avertit du mauvais retour:
                dispatch( ActionErrorGetClientBilling(error) );
            });
    }
}

export function ActionCreateStripeCustomer(tokenCardId) {    
    return function(dispatch, getState) {
        return stripeWebApiProvider.createCustomer(dispatch, getState, tokenCardId) 
            .then( (result) => { // if (result = true) alors on peut demander la souscription du customer à un plan
                //console.log('[createCustomer - result]', result);
                if (result) {
                    return true;
                } else {
                    dispatch( ActionErrorSubscriptionProcess(StringTranslate.errorStripeCreateCustomer) );

                    return false;
                }
            })
            .catch( (err) => { // si on est en présence d'une erreur, il y a de fortes chances que le customer n'a pas été créé en BDD
                dispatch( ActionErrorSubscriptionProcess(StringTranslate.errorStripeCreateCustomer) );
                
                return false;
            });
    };
}

/*export*/ function ActionAskingSubscribeCstm() { 
    return {
        type: SUBSCRIPTION_PROCESS_ASKING,
    };
}

/*export*/ function ActionEndAskSubscribeCstm() { 
    return {
        type: END_SUBSCRIPTION_PROCESS,
    };
}

export function ActionSubscribeStripePlan(planId, idPlanDiscounted) {    
    return function(dispatch, getState) {
        //active la boucle d'attente:
        dispatch( ActionAskingSubscribeCstm() );

        return stripeWebApiProvider.subscribeCustomer(dispatch, getState, planId, idPlanDiscounted) 
            .then( (result) => { // result => souscription (success || failed) => dans tous les cas, l'abonnement est accepté
                //arrête la boucle d'attente:
                dispatch( ActionEndAskSubscribeCstm() );

                return true;
            })
            .catch( (err) => {
                dispatch( ActionErrorSubscriptionProcess(StringTranslate.errorStripeSubscribePlan) );

                return false;
            });
    };
}

///////////////////////////////////////////////////////////////// ↑↑ Fonctionnels liés aux achats (de Stripe et des Stores) ↑↑

/*export*/ const ActionDownloadingInvoiceFile = () => ({
    type: DOWNLOADING_INVOICE_FILE,
})

/*export*/ const ActionInvoiceFileDownloaded = () => ({
    type: INVOICE_FILE_DOWNLOADED,
})

/* fonction permettant d'obtenir la facture stripe d'un client  */
export function ActionLoadInvoiceFile(invoiceSelectedItem) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        if (invoiceSelectedItem) {
            //Affiche l'attente:
            dispatch( ActionDownloadingInvoiceFile() );
    
            //recherche et obtient le pdf de cette facture:
            //stripeWebApiProvider.LoadInvoiceFile(dispatch, getState, invoiceSelectedItem, false, true) //demande le stockage du fichier Pdf !
            stripeWebApiProvider.LoadInvoiceFile(dispatch, getState, invoiceSelectedItem, false, false) //demande le téléchargement du fichier Pdf !
                .then((respDatas) => {
                    //Supprime l'attente:
                    dispatch( ActionInvoiceFileDownloaded() );
                    
                    //A CE JOUR : on reçoit : 
                    // - Rien, si on est sur l'appli Web;
                    // - l'URL du fichier stocké sur le compte Azure, si on est sur l'appli mobile.
                    //Dans ce second cas, il faut transmettre à l'appli native cette url pour lancer son affichage !
                    if ((IsNativeHoster() === true) && (respDatas !== '') && userAgentAuthent && userAgentAuthent.commProviderWithNativeApp) {
                        userAgentAuthent && userAgentAuthent.commProviderWithNativeApp.askShowUrl(respDatas); //'respDatas' est une URL !
                    }
                },
                (error) => {
                    //Supprime l'attente:
                    dispatch( ActionInvoiceFileDownloaded() );
                    
                    //Avertit du mauvais retour:
                    dispatch(ActionErrorGetClientBilling(error));
                });
        } //else //ne fait rien !
    }
}