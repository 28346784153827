import numberHelper from './numberHelper.js';

/* ces constantes sont inutilisables pour l'instant */
// const Dose_Limit_Min = -30;
// const Dose_Limit_Max = 30;

/**
 * 30% de dispersion par défaut
 */
export const DEFAULT_MODULATION_DISPERSION = 30;

export const ModulationsHelper = {

    /**
     *  fonction permettant de calculer le nombre total de modulations se trouvant dans le dico
     */
    count(modulationDico) {
        if (!modulationDico) return 0;
        return Object.entries(modulationDico).length;
    },

    /* fonction permettant d'adapter l'item de modulation reçu - le concept de normalisation des données est ici utilisé */
    convertItem(modulationItem) {
        if (!modulationItem) {
            return {};
        }

        //transforme la date (caîne en entité 'Date'):
        modulationItem.creationDate = (modulationItem.creationDate && (!isNaN(Date.parse(modulationItem.creationDate)))) ?
            new Date(modulationItem.creationDate) : new Date();
            modulationItem.dateImagSource = (modulationItem.dateImagSource && (!isNaN(Date.parse(modulationItem.dateImagSource)))) ?
            new Date(modulationItem.dateImagSource) : new Date();
            
        //On reçoit de la part de la Web API un boolean. Mais exploit une chaîne dans l'appli ReactJs !
        if (modulationItem.supplyType === true) { //@@A changer pour exploiter partout un boolean !
            modulationItem.supplyType = 'Liquid';
        } else {
            modulationItem.supplyType = 'Solid';
        }

        /* //C'est au(x) visuels de décider comment présenter les données ! 
        //Pour les calculs, il vaut mieux se baser sur les valeurs 'complètes' !
        //limite les décimales:
        if (modulationItem.parcelArea > 0) {
            modulationItem.parcelArea = modulationItem.parcelArea.toFixed(2);
        }
        if (modulationItem.doseMin > 0) {
            modulationItem.doseMin = modulationItem.doseMin.toFixed(2);
        }
        if (modulationItem.doseMax > 0) {
            modulationItem.doseMax = modulationItem.doseMdoseMaxin.toFixed(2);
        }
        if (modulationItem.doseMedium > 0) {
            modulationItem.doseMedium = modulationItem.doseMedium.toFixed(2);
        }
        //pour la dose de chaque zone aussi:
        if (modulationItem.zones) {
            modulationItem.zones.forEach(zoneItem => {
                if (zoneItem.initialDose > 0) {
                    zoneItem.initialDose = zoneItem.initialDose.toFixed(2);
                }
                if (zoneItem.actualDose > 0) {
                    zoneItem.actualDose = zoneItem.actualDose.toFixed(2);
                }
            });
        }*/

        return modulationItem;
    },

    /* fonction permettant de passer d'une liste à un dico - le concept de normalisation des données est ici utilisé */
    convertToDico(modulationlList) {
        if ((!modulationlList) || (!Array.isArray(modulationlList))) {
            return {};
        }

        let dicoModulation = {};
        modulationlList.forEach(modulationItem => {
            dicoModulation[modulationItem.id] = ModulationsHelper.convertItem(modulationItem);
        });

        return dicoModulation;
    },

    /* fonction permettant d'adapter l'item de modulation reçu - le concept de normalisation des données est ici utilisé */
    convertSettingsItem(modulationSettingsItem) {
        if (!modulationSettingsItem) {
            return {};
        }

        //On reçoit de la part de la Web API un boolean. Mais exploit une chaîne dans l'appli ReactJs !
        if (modulationSettingsItem.supplyType === true) { //@@A changer pour exploiter partout un boolean !
            modulationSettingsItem.supplyType = 'Liquid';
        } else {
            modulationSettingsItem.supplyType = 'Solid';
        }

        return modulationSettingsItem;
    },

    /**
     * Calcul Manuel de doses : retourne la dose moyenne en fonction des doses saisies manuelement par zone.
     * 
     * @param {Array} manualDoses Le tableau des doses saisies manuellement par l'utilsateur.
     * @param {Array} ratios Le tableau du % de surface / zones en ratio.
     * @param {number} content La teneur en % de l'enrais utilisé.
     * @return {number} La dose moyenne calculée
     */
    get_Dose_Moyenne(manualDoses, ratios, contentValue) {     
        let content = contentValue;
        if ((contentValue <= 0) || (contentValue === undefined) || (contentValue === '')) {
            content = 100;
        }

        const reducer = (accumulator, currentValue) => accumulator + currentValue;
        return numberHelper.fixeDecimal(this.getDoseP(manualDoses, ratios).reduce(reducer) * (content /100));
    },

    /**
     * Calcul automatique des doses / zones en fonction de la dose moyenne
     * @note Il existe l'quivalent de cette fonction dans la webAPI
     * @param {number} nbZones Le nombres de zones définis par l'utilisateur.
     * @param {Array} ratios Le tableau du % de surface / zones en ratio.
     * @param {number} doseMediumValue La dose moyenne paramétrée par l'utilisateur.
     * @param {number} contentValue La teneur en % de l'enrais utilisé.
     * @param {boolean} optimisation La stratégie d'optimisation ou compensation 
     * @return {Array} Un tableau avec toutes les doses / zones.
     */
    get_Doses_Auto(nbZones, ratios, doseMediumValue, contentValue, optimisation, repartition) {
        let content = contentValue;
        if ((contentValue <= 0) || (contentValue === undefined) || (contentValue === '')) {
            content = 100;
        }

        const Dose_Limit_Min = -(doseMediumValue * repartition);//TODO : Et si 'repartition' est null ? cas dosage manuel !
        const Dose_Limit_Max = (doseMediumValue * repartition);

        let doseMedium = doseMediumValue / (content / 100);
        let dose_min = doseMedium + (Dose_Limit_Min / (content / 100));
        
        if (dose_min < 0) { 
            dose_min = 0 
        };
        let dose_max = doseMedium;
        if (content !== undefined) {
            dose_max = doseMedium + (Dose_Limit_Max / (content / 100));
        }
        let inc;
        let doses = [];
        //Dans le cas où on accepte une seule zone (dernier apport)
        if (nbZones === 1){
            inc = dose_max;
        } else {
            inc = (dose_max - dose_min) / (nbZones - 1);
            for (let i = 0; i < nbZones; i++) {
                doses.push(dose_min + (i * inc));
            }
        }

        if (optimisation === false) {
            doses = doses.reverse();
        }

        if (ratios.length > doses.length) {
            doses.push(doseMedium);
        }
        var Spread_doses = this.get_Spread_Doses(doses, ratios, doseMedium);

        return Spread_doses;
    },

    get_Spread_Doses(doses, ratios, DoseConsigne) {

        let Dose_Moyenne = this.getDoseP(doses, ratios).reduce((accumulateur, valeurCourante) => accumulateur + valeurCourante);
        let x = (DoseConsigne - Dose_Moyenne);

        //Vérif des cas de doses hors limites
        while ((x > 0.01) || (x < -0.01)) {
            for (var i = 0; i < doses.length; i++) {
                if ((doses[i] + x) < 0){
                    doses[i] = 0;
                } else {
                    doses[i] = doses[i] + x;
                }
            }

            Dose_Moyenne = this.getDoseP(doses, ratios).reduce((accumulateur, valeurCourante) => accumulateur + valeurCourante);
            x = (DoseConsigne - Dose_Moyenne);
        }
        
        let Spread_Doses = [];
        for (let i = 0; i < doses.length; i++) {
            Spread_Doses.push((doses[i] > 0) ? numberHelper.fixeDecimal(doses[i]) : 0);
        }

        return Spread_Doses;
    },

    getDoseP(doses, ratios) {
        let doseP = [];
        for (let i = 0; i < doses.length; i++) {
            doseP.push(doses[i] * ratios[i])         
        }
        return doseP;
    },
    

    /* On supprime la notion de teneur */
    clearValuesIfContentDefined(modulationSelected) {
        if (!modulationSelected) return undefined;
        
        let newModulationSelected = modulationSelected;
        if ((newModulationSelected.content > 0) && (newModulationSelected.content < 100)) {
            newModulationSelected = {...modulationSelected};
            
            newModulationSelected.content = '';

            if (newModulationSelected.zones !== undefined) {
                newModulationSelected.zones.forEach(zoneItem => {
                    zoneItem.actualDose = parseFloat((zoneItem.actualDose * newModulationSelected.content) / 100);
                });
            }
        } else {
            newModulationSelected.content = '';
        }
        
        return newModulationSelected;
    }
}