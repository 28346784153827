import React from 'react';

// material-ui
import { Box } from '@mui/material';

const ImagSwiperSlide = React.forwardRef(
    ( { content }, ref ) => {
        return (
            <Box
                sx={{
                    height: "88px",
                    width: "88px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    marginTop: '27%'
                }}
            >
                {content}
            </Box>
        );
    }
);

export default ImagSwiperSlide;
