import React, { createRef } from "react";
import { connect } from 'react-redux';
import StringTranslate from '../assets/i18n/stringLanguage.jsx';

/* DatePicker */
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import { frFR, esES, enUS } from '@mui/x-date-pickers/locales';

/* Thème Berry */
import getTheme from '../themes';
let theme = getTheme();
const themeColorForNotEmptyField = theme.palette.primary[100];

// Disatnce Minimal que le doigt doit parcourir pour considerer que le client a swiper
const minSwipeDistance = 50;


class CustomDatePicker extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            //sauvegarde la langue en cours lors de la création du composant: (Car pas présente dans le store Redux)
            language: StringTranslate.getLanguage(),

            // Vue sur laquelle le calendrier est ouvert (openTo est la props du Datepicker permettant de définir cela)
            actualView: /* props.openTo ?? */'day',

            touchPositionStart: null, // Position de départ sur l'axe horizontal lorsque le client touche son écran
            touchPositionEnd: null, // Position de fin sur l'axe horizontal  lorsque le client touche son écran
        };
        this.localMap = ((StringTranslate.getLanguage() === 'fr-FR' || StringTranslate.getLanguage() === 'fr-BE') ? frLocale : (StringTranslate.getLanguage() === "en-GB" ? enLocale : esLocale)); //Concerne la traduction des datePicker
        this.localeText = ((StringTranslate.getLanguage() === 'fr-FR' || StringTranslate.getLanguage() === 'fr-BE') ? frFR.components.MuiLocalizationProvider.defaultProps.localeText : (StringTranslate.getLanguage() === "en-GB" ? enUS.components.MuiLocalizationProvider.defaultProps.localeText : esES.components.MuiLocalizationProvider.defaultProps.localeText)); //Concerne la traduction des composants de datePicker 
    
        // reférence des flèches pour le changement de mois du calendrier
        this.refNextButton = createRef();
        this.refPreviousButton = createRef();
    }

    /**
     * Function trigger quand le client touche son écran (en mode mobile)
     * @param {Event} e 
     */
    onTouchStart = (e) => {
        this.setState({
            // On met à jour la position de départ
            touchPositionStart: e.targetTouches[0].clientX,
            // on réinitialise la position de fin
            touchPositionEnd: null,
        });
    }
    
    /**
     * Function trigger quand le client déplace son doigt sur son écran (en mode mobile)
     * @param {*} e 
     * @returns 
     */
    onTouchMove = (e) => this.setState({ touchPositionEnd: e.targetTouches[0].clientX})
    
    /**
     * Function trigger quand le client relève son doigt de son écran (en mode mobile)
     * @param {*} e 
     * @returns 
     */
    onTouchEnd = () => {
        const { touchPositionStart, touchPositionEnd, actualView } = this.state;

        // Si on a pas la position de départ ou de fin
        // ou que l'on est pas sur la vue des jours
        if (!touchPositionStart || !touchPositionEnd || (actualView !== 'day')) return;

        // On récupère la distance parcouru
        const distance = touchPositionStart - touchPositionEnd;

        // Puis si elle est suffisante pour être considérer comme du swip
        const isLeftSwipe = (distance > minSwipeDistance);
        const isRightSwipe = (distance < -minSwipeDistance);
        if (isLeftSwipe) {
            // Si le client à déplacer son doigt vers la gauche, 
            //on passe au mois suivant (on trigger le bouton next)
            this.refNextButton.current?.click();
        }
        else if (isRightSwipe) {
            // Si le client à déplacer son doigt vers la droite, 
            //on passe au mois précédent (on trigger le bouton previous)
            this.refPreviousButton.current?.click();
        }
    }

    /* Définition du thème pour les textfields */
    getThemeTextField = (param) => {
        if ((param !== "") && (param !== null) && (param !== undefined)) {
            return ({
                '& .MuiOutlinedInput-input': { backgroundColor: themeColorForNotEmptyField },
                '& .MuiOutlinedInput-root': { backgroundColor: themeColorForNotEmptyField }
            });
        }
    }

    adjustTimeZone = (date) => {
        let newDateValue = ((date !== undefined) && (date !== null) && (date !== "")) ? new Date(date) : null;
        if (newDateValue instanceof Date) {
            const offsetTime = newDateValue.getTimezoneOffset(); //exprimé en minutes !
            if (offsetTime !== 0) {
                newDateValue = newDateValue.setHours(newDateValue.getHours() - (offsetTime / 60));
                newDateValue = new Date(newDateValue);
            }
        }
        return newDateValue;
    }

    /**
     * Modifie la valeur de la date avant de réalisé la modification voulue du composant parents
     * @param {*} newValue 
     */
    customOnChange = (newValue, context) => {
        const { onChange } = this.props;
        // Si la props n'est pas fournie, on ne va pas plus loin
        if (!onChange) return;

        // On vient trunker la valeur de la date pour ne pas tenir compte des timezones
        let newDate = this.adjustTimeZone(newValue);

        // TOUJOURS FAIRE APPEL au 'onChange' du composant parent
        onChange(newDate, context);
    }

    /**
     * Modifie la valeur de la date avant de réalisé la modification voulue du composant parents
     * @param {*} newValue 
     */
    customOnAccept = (newValue) => {
        const { onAccept } = this.props;
        // Si la props n'est pas fournie, on ne va pas plus loin
        if (!onAccept) return;

        // On vient trunker la valeur de la date pour ne pas tenir compte des timezones
        let newDate = this.adjustTimeZone(newValue);

        // TOUJOURS FAIRE APPEL au 'onAccept' du composant parent
        onAccept(newDate);
    }

    render() {
        const {minDate, maxDate, value, disabled, 
            name, label, helperText, placeholder, slotPropsDisabled, 
            dialogOption,  getThemeTextFieldValue,
            error,onBlur, actions, clearable, open, dateFormat, required,
            disableFuture, shouldRespectLeadingZeros, onOpen, onClose,
            views, size
        } = this.props;

        if(dialogOption === 1) //Sert a selectionner le datePicker générale ou le datepicker concernant la fumure (1 = fumure / autre = général)
        {
            return(
                <LocalizationProvider adapterLocale={this.localMap} dateAdapter={AdapterDateFns} localeText={this.localeText}>
                    <DatePicker
                        label={label} 
                        value={value}
                        onChange={this.customOnChange}
                        onAccept={this.customOnAccept}
                        views={(views !== undefined) ? views : ['year', 'month', 'day']} //valeur par defaut  
                        onViewChange={(view) => this.setState({actualView: view})} // Récupération de la vue afficher (pour la gestion du swipe)
                        inputProps={{ placeholder: StringTranslate.formatDatePlaceHolder }}
                        slotProps={{
                            textField: {
                                placeholder: placeholder, 
                                error: error, 
                                helperText: helperText,
                                sx: { ...this.getThemeTextField(getThemeTextFieldValue) },
                                onBlur: onBlur,
                            },

                            // Props nécessaire pour la gestion du swipe
                            dialog: {
                                onTouchStart: this.onTouchStart,
                                onTouchMove: this.onTouchMove,
                                onTouchEnd: this.onTouchEnd,
                            },
                            nextIconButton: {
                                ref: this.refNextButton,
                            },
                            previousIconButton: {
                                ref: this.refPreviousButton,
                            }
                        }}
                        disabled={disabled} 
                    />
                </LocalizationProvider>
            );
        }
        else
        {
            return (
                <LocalizationProvider adapterLocale={this.localMap} dateAdapter={AdapterDateFns} localeText={this.localeText}>
                        <DatePicker
                            name={name}
                            label={label}
                            inputProps={{ placeholder: StringTranslate.formatDatePlaceHolder }}
                            onChange={this.customOnChange}
                            onAccept={this.customOnAccept}
                            onOpen={onOpen}
                            onClose={onClose}
                            open={open}
                            closeOnSelect={true} // valeur par default true (pour desktop) , false pour mobile
                            orientation="portrait" //valeur concernant la version mobile - valeur par default -> portrait
                            views={(views !== undefined) ? views : ['year', 'month', 'day']} //valeur par defaut  
                            onViewChange={(view) => this.setState({actualView: view})} // Récupération de la vue afficher (pour la gestion du swipe)
                            slotProps={{
                                textField: {
                                    size: (size !== undefined) ? size : 'medium', //valeur par defaut -> medium
                                    fullWidth: true, //par default -> false
                                    helperText: helperText,
                                    placeholder: placeholder,
                                    disabled: slotPropsDisabled, //par default -> false
                                    clearable: clearable,
                                    required: required,
                                },
                                popper: {
                                    placement: "top", //version desktop , par default valeur = bottom
                                },
                                actionBar: {
                                    actions: actions
                                },

                                // Props nécessaire pour la gestion du swipe
                                dialog: {
                                    onTouchStart: this.onTouchStart,
                                    onTouchMove: this.onTouchMove,
                                    onTouchEnd: this.onTouchEnd,
                                },
                                nextIconButton: {
                                    ref: this.refNextButton,
                                },
                                previousIconButton: {
                                    ref: this.refPreviousButton,
                                }
                            }}
                            minDate={minDate}
                            maxDate={maxDate}
                            value={this.adjustTimeZone(value)} //par defaut -> aucune date affichée 
                            disabled={disabled} //par default -> false
                            dateFormat={dateFormat}
                            disableFuture={disableFuture}
                            shouldRespectLeadingZeros={shouldRespectLeadingZeros}
                        />
                </LocalizationProvider>  
            );
        }
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomDatePicker);