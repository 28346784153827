import React from "react";
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';

/* Composants MUI */
import {
    Grid, Typography, Box, TableContainer, Alert, AlertTitle, TextField,
    InputAdornment, IconButton, CircularProgress, Button, Dialog,
    DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl
} from '@mui/material';
import DataGridSkeleton from '../dataGridSkeleton.jsx';

/* Composants React */
import { ThumbnailParcelShapeFromPathInfos } from '../thumbnail/ThumbnailParcelShape.jsx';
import CustomDataGrid from "../customDataGrid";
import LinkToContactUs from '../linkToContactUs';
import DegreeOfCertainty from "./degreeOfCertainty.jsx";

import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

/* DatePicker */
import CustomDatePicker  from '../customDatePicker.jsx';

/* Icones */
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import AutorenewIcon from '@mui/icons-material/Autorenew';

/* Helpers et Dico */
import { ParcelsHelper } from '../../utils/parcelsHelper';
import DateHelper from '../../utils/dateHelper';
import stringHelper from '../../utils/stringHelper.js';

/* Redux */
import { TableType, UpdateNbRowsPerPageTable } from '../../redux/actions/settings';
import { ActionDeleteHarvests, ActionGenerateResults, ActionUpdateAllHarvestDatesAtTheBeginning } from '../../redux/actions/harvest';
import { ProfilIndex, ActionShowProfilMenuDialog } from "../../redux/actions/contextApp";

/* css */
import '../../assets/css/renderParcels.css';
import '../../assets/css/pdp-pinDropPopup.css';

/* theme Berry */
import getTheme from "../../themes/index.js";

let theme = getTheme();

const thresholdDegreeCertitude = 5;

class HarvestList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchFilter: '', //Zone de recherche du tableau
            currentRowsOfTable: [], //Datas en fonction du texte tapé dans la zone de recherche

            datas: this.initialiseDatas(true), //parcelles. True pour dire qu'il faut trier le tableau à l'arrivée
            harvestsSelected: [], //Correspond aux parcelIds des récoltes sélectionnées

            //sauvegarde la langue en cours lors de la création du composant: (Car pas présente dans le store Redux)
            language: props.language,
            openConfirmDeleteHarvestsDialog: false, //Permet d'afficher ou désafficher la dialog de suppression.

            activateSortByEstimatedDateBtn: false, //Permet d'activer ou de désactiver le bouton de tri en fonction de la date estimée

            harvestsToEstimate: [], //Liste des parcelles dont il faut relancer le calcul
        };
        // liste des noms de colonnes affichées
        this.columns = this.initialiseColumns(props.generatingForParcelIds); //RQ: pas dans le 'state' car on en utilise des propriétés !
    }

    componentDidMount() {
        const { harvestDico, updateAllHarvestDatesAtTheBeginning, harvestsDatesUpdatedAtBeginning,
            clientId, authorizeCornHarvest } = this.props;

        //Demande de génération de dates de récolte au démarrage (une seule fois)
        if ((harvestsDatesUpdatedAtBeginning === false) && (Object.keys(harvestDico).length > 0) && (authorizeCornHarvest === true)) {
            updateAllHarvestDatesAtTheBeginning(clientId, harvestDico);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        //@@Réagir aux modifications sur les parcelles : Modification d'une ou plusieurs données d'une parcelle !
        const { harvestDico, generatingForParcelIds, updateAllHarvestDatesAtTheBeginning,
            harvestsDatesUpdatedAtBeginning, clientId, authorizeCornHarvest } = this.props;
        const { language } = this.state;

        //Demande de génération de dates de récolte au démarrage (une seule fois)
        if ((harvestsDatesUpdatedAtBeginning === false) && (Object.keys(harvestDico).length > 0) && (authorizeCornHarvest === true)) {
            updateAllHarvestDatesAtTheBeginning(clientId, harvestDico);
        }

        if ((harvestDico !== prevProps.harvestDico) || (prevProps.generatingForParcelIds !== generatingForParcelIds)) {
            const newDatas = this.initialiseDatas(true);
            this.columns = this.initialiseColumns(generatingForParcelIds);
            this.setState({
                datas: newDatas,
            });
        }

        /* Lorsqu'il y a un changement de langue (même si on se doute que l'utilisateur ne changera pas de langue tous les 4 matins !), il faut actualiser certaines listes: */
        const currentLanguage = StringTranslate.getLanguage();
        if ((!language) || (language === '') || (language !== currentLanguage)) {

            // liste des noms de colonnes affichées. 
            this.columns = this.initialiseColumns(generatingForParcelIds);
            this.setState({ language: currentLanguage, });
        }

    }

    //Toolbar customisé du tableau comprenant :
    // - la zone de recherche
    // - Bouton de tri
    // - Bouton de suppression de récolte dans le tableau
    // - Bouton d'aide
    CustomToolbar = (props) => {
        const { loading, deleting, showProfilMenuDialog } = this.props;
        const { activateSortByEstimatedDateBtn, harvestsSelected, harvestsToEstimate } = this.state;

        return (
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ ml: 1 }}>
                    <Grid container spacing={2}>
                        {/* Zone de recherche */}
                        <Grid item xs={8} sm={8} md={4} lg={4}>
                            {/* Zone de recherche */}
                            <TextField
                                value={props.value}
                                onChange={props.onChange}
                                placeholder={StringTranslate.toolbarsearch}
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>),
                                    endAdornment: (
                                        <IconButton
                                            title="Clear"
                                            aria-label="Clear"
                                            size="small"
                                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                                            onClick={props.clearSearch}
                                        >
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Grid>

                        {/* Bouton de suppression */}
                        <Grid item xs={4} sm={4} md={8}>
                            <Box sx={{ display: { md: 'none', xs: 'block' }, '& button': { m: 1 }, pt: 1 }}
                                style={{ textAlign: 'end' }} >
                                <IconButton color="error" size="large" aria-label="delete harvest(s)" component="span"
                                    onClick={(evt, data) => props.deleteHarvests(data)}
                                    disabled={((loading === true) || (deleting === true) || (harvestsSelected.length <= 0)) ? true : false}
                                    sx={{ p: 0 }}
                                >
                                    {((loading === true) || (deleting === true)) ? <CircularProgress color="inherit" size={20} /> : <DeleteForeverSharpIcon />}
                                </IconButton>
                            </Box>

                            <Box sx={{ display: { md: 'block', xs: 'none' }, '& button': { m: 1 } }} style={{ textAlign: 'end' }} >
                                <Button
                                    variant="text"
                                    color="error"
                                    onClick={(evt, data) => props.deleteHarvests(data)}
                                    startIcon={((loading === true) || (deleting === true)) ? <CircularProgress color="inherit" size={20} /> : null}
                                    disabled={((loading === true) || (deleting === true) || (harvestsSelected.length <= 0)) ? true : false}
                                >
                                    {StringTranslate.deleteHarvests}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ ml: 1 }}>
                    <Button
                        variant="contained"
                        startIcon={<AutorenewIcon />}
                        onClick={() => props.onClickSortDatasByEstimatedDate()}
                        disabled={(activateSortByEstimatedDateBtn === false) || (harvestsToEstimate.length <= 0)}>{StringTranslate.sortBtn}</Button>
                </Grid>
                {/* Bouton d'aide */}
                <Grid item xs={12}>
                    <Button color="secondary" variant="text" size="small" onClick={() => showProfilMenuDialog(ProfilIndex.aide_HarvestCorn)}>
                        {StringTranslate.helpAsk}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    setSelectionHarvests = (newSelectionharvests) => {
        this.setState({
            harvestsSelected: newSelectionharvests,
        });

    }

    /* Fonction correspondant à la zone de recherche */
    requestSearch(searchValue, rowsOfTable) {
        this.setState({
            searchFilter: searchValue
        });

        try {
            const searchRegex = new RegExp(stringHelper.escapeRegExp(searchValue), 'i');
            const filteredRows = rowsOfTable.filter((row) => {
                return Object.keys(row).some((field) => {
                    let textValue = "" + row[field];
                    return searchRegex.test(textValue.toString());
                });
            });

            this.setState({
                currentRowsOfTable: filteredRows
            });
        }
        catch (errRegex) { /* Peut arriver si on saisis un truc du genre 'EARL++' ! (il n'aime pas les '++') */ }
    }

    /**
     * Initialisation des colonnes de parcelles.
     * Il y a une traduction sur les listes fixes de cultures, exploitations et variétés
     */
    initialiseColumns(generatingForParcelIds = undefined) {
        const { authorizeCornHarvest } = this.props;

        const currentYear = new Date().getFullYear();
        const fisrtDayOCurrentYear = new Date(currentYear, 0, 1);

        let newColumns = [];
        /* ↓ ajout colonne visible - (Miniature + nom de la parcelle) ↓ */
        newColumns.push({
            headerName: `${StringTranslate.nomcolumn}`,
            field: "nom",
            minWidth: 180,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold'>{StringTranslate.nomcolumn}</Typography>)
            },
            renderCell: params => {
                if (params.row.thumbnailInfos !== undefined) {
                    return (
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                            {(params) ? (<ThumbnailParcelShapeFromPathInfos id={`listItemTbl_${params.row.thumbnailInfos.parcelId}`} {...params.row.thumbnailInfos} />) : (undefined)}
                            <Typography>{params.value}</Typography>
                        </Box>
                    )
                }
            }
        });

        newColumns.push({
            headerName: `Type`,
            field: "cornType",
            minWidth: 135,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.cornType}</Typography>)
            },
            renderCell: params => {
                return (<Typography>{params.value}</Typography>)
            }
        });

        newColumns.push({
            headerName: `Variete`,
            field: "varietyLabel",
            minWidth: 110,
            hideable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.harvestVariety}</Typography>)
            },
            renderCell: params => {
                return (<Typography>{params.value}</Typography>)
            }
        });

        newColumns.push({
            headerName: `Pourcentage`,
            field: "dryOrHumidityThreshold",
            minWidth: 120,
            hideable: false,
            filterable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.percentage}</Typography>)
            },
            renderCell: params => {
                return (<Typography>{params.value}% {(params.row.cornType === "Ensilage") ? " MS" : " H"}</Typography>)
            }
        });

        newColumns.push({
            headerName: `Date estimée`,
            field: "degreeOfCertainty",
            minWidth: 270,
            hideable: false,
            filterable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.harvestEstimatedDate}</Typography>)
            },
            renderCell: params => {
                return ((generatingForParcelIds !== undefined) && (generatingForParcelIds.length > 0) && (generatingForParcelIds.includes(params.row.parcelId))) ?
                    <CircularProgress color="inherit" size={20} /> :
                    ((params.value > thresholdDegreeCertitude) ?
                        <Typography>{StringTranslate.hightDegreeOfCertitude}</Typography> :
                        <DegreeOfCertainty
                            estimatedDate={params.row.estimatedDate}
                            degreeOfCertainty={params.value} ></DegreeOfCertainty>)
            }
        });

        newColumns.push({
            headerName: `Date de semis`,
            field: "sowingDate",
            minWidth: 135,
            hideable: false,
            filterable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.harvestSowingdate}</Typography>)
            },
            renderCell: params => {
                return (<Typography>{(params.value !== null) ? new Date(params.value).toLocaleDateString() : ''}</Typography>)
            }
        });

        newColumns.push({
            headerName: `Date de floraison`,
            field: "floweringDate",
            minWidth: 210,
            hideable: false,
            filterable: false,
            renderHeader: (params) => {
                return (<Typography fontWeight='bold' >{StringTranslate.harvestFloweringDate}</Typography>)
            },
            renderCell: params => {
                return (
                    <Box
                        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                        sx={{ width: "100%" }}
                    >
                        <FormControl>
                            {<CustomDatePicker
                                onChange={(newValue, context) => {
                                    if (context.validationError == null) {
                                        this.handleChangeFloweringDate(params.row.parcel.id, newValue)
                                    }
                                }}
                                onAccept={(newValue) => {
                                    this.handleChangeFloweringDate(params.row.parcel.id, newValue)
                                }}
                                label={StringTranslate.libeleColumn}
                                placeholder={StringTranslate.formatDatePlaceHolder}
                                minDate={(params.row.sowingDate !== null) ? params.row.sowingDate : fisrtDayOCurrentYear}
                                maxDate={new Date()}
                                value={(params.value !== null) ? new Date(params.value) : null}
                                disabled={((generatingForParcelIds !== undefined) && 
                                    (generatingForParcelIds.length > 0) && 
                                    (generatingForParcelIds.includes(params.row.parcelId))) ? 
                                        true : ((authorizeCornHarvest === true) ? false : true)}
                                actions={['clear']}
                                clearable={true}
                                disableFuture={true}
                                shouldRespectLeadingZeros={true}
                                slotPropsDisabled= {((generatingForParcelIds !== undefined) && (generatingForParcelIds.length > 0) && (generatingForParcelIds.includes(params.row.parcelId))) ? true : false}
                                size={'small'}
                            />}                                
                        </FormControl>
                    </Box>
                )
            }
        });

        return newColumns;
    }

    /* fonction permettant d'extraire les données qui seront utilisées dans le visuel */
    initialiseDatas(askToSortData = false) {
        const { parcelDico, thumbnailParcelDico, harvestDico } = this.props;

        let harvests = Object.entries(harvestDico);

        let newDatas = [];

        for (const [/*key*/, value] of harvests) {
            if (value.estimatedDate !== null) {
                let parcelId = value.parcelId;
                const parcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelId);

                if (parcel) {
                    const thumbnailParcelItem = ParcelsHelper.selectParcelFromDicoById(thumbnailParcelDico, parcelId);

                    let isGrain = lodashGet(value, "isGrain", undefined);

                    newDatas.push({
                        id: parcel.id,
                        idHarvest: value.id,
                        parcel: parcel,
                        nom: lodashGet(parcel, "name", ""),
                        campagne: lodashGet(parcel, "details.campagne", ""),
                        sowingDate: lodashGet(value, "sowingDate", lodashGet(parcel, "details.dateSemi", null)),
                        cornType: (isGrain !== undefined) ? ((isGrain === true) ? "Grain" : "Ensilage") : undefined,
                        dryOrHumidityThreshold: lodashGet(value, "dryOrHumidityThreshold", undefined),
                        floweringDate: lodashGet(value, "floweringDate", null),
                        estimatedDate: lodashGet(value, "estimatedDate", undefined),
                        degreeOfCertainty: lodashGet(value, "degreeOfCertainty", undefined),
                        generationDate: lodashGet(value, "generationDate", undefined),
                        thumbnailInfos: thumbnailParcelItem,
                        varietyLabel: lodashGet(value, "varietyLabel", ""),
                        parcelId: lodashGet(value, "parcelId", undefined),
                        clientId: lodashGet(value, "clientId", undefined),
                        isGrain: lodashGet(value, "isGrain", undefined),
                        harvestVarietyId: lodashGet(value, "harvestVarietyId", undefined),
                        harvestVarietyInfoId: lodashGet(value, "harvestVarietyInfoId", undefined),
                        harvestCrop: lodashGet(value, "harvestCrop", undefined),
                    });
                }
            }
        }

        if ((askToSortData === true) && (newDatas.length > 1)) {
            newDatas = this.sortHarvestsByEstimatedDate(newDatas);
        }

        return newDatas;
    }

    sortHarvestsByEstimatedDate = (datas) => {
        return [...datas].sort((a, b) => a.estimatedDate > b.estimatedDate ? 1 : -1);
    }

    /* Suite au clic sur le bouton de tri */
    // 2 actions sont possibles :
    // - Si des dates de floraisons ont été modifiées, on génère les nouvelles dates estimées
    // - Sinon, on trie le tableau
    onClickSortDatasByEstimatedDate = () => {
        const { harvestsToEstimate } = this.state;
        const { generateResults, clientId } = this.props;

        let newDatas = this.sortHarvestsByEstimatedDate(this.state.datas);

        if (harvestsToEstimate.length > 0) {
            //On génère les nouvelles estimations :
            generateResults(clientId, harvestsToEstimate);
        }
        else {
            //On trie le tableau :
            this.setState({
                datas: newDatas,
            });
        }

        //Tri des récoltes après clic sur le bouton de tri :
        this.setState({
            activateSortByEstimatedDateBtn: false,
            harvestsToEstimate: []
        });
    }

    //Désaffichage de la dialog de suppression
    handleCloseConfirmDialog = () => {
        this.setState({
            openConfirmDeleteHarvestsDialog: false
        });
    }

    /**
     * Méthode permettant de mettre à jour la date de floraison d'une parcelle de maïs
     * @param {number} parcelId id de la récolte pour laquelle il faut changer la date de floraison d'une parcelle de maïs
     * @param {Date} newFloweringDate nouvelle date de floraison
     */
    handleChangeFloweringDate(parcelId, newFloweringDate) {
        const { harvestsToEstimate } = this.state;
        const { harvestDico } = this.props;

        let newHarvestToEstimate = harvestsToEstimate;

        let newDateIsEqualToData = true;
        const newDatas = this.state.datas.map(data => {

            if (data.parcel.id === parcelId) {
                data.floweringDate = ((newFloweringDate !== undefined) && (newFloweringDate !== null) && (newFloweringDate !== "")) ? new Date(newFloweringDate) : null;
            }

            /* Permet de savoir si une ou plusieurs dates sont différentes de ce qu'il y avait au départ
            Si oui, on active le bouton pour obtenir de nouvelles dates estimées
            Si non, le bouton reste désactivé */
            let floweringDateFromData = lodashGet(harvestDico, `[${data.parcel.id}].floweringDate`, null);
            let comparisonDatesFromDataAndCurrent = DateHelper.Compare(floweringDateFromData, data.floweringDate);

            //Dans le cas où il y a plusieurs items avec le même idHarvest dans la liste
            //des données à estimer, on supprime puis on rajoute le dernier en date si différent
            //Sinon on ne garde que ceux à vraiment estimer !
            newHarvestToEstimate = newHarvestToEstimate.filter(function (item) {
                return item.idHarvest !== data.idHarvest
            });

            if (comparisonDatesFromDataAndCurrent !== 0) {
                newDateIsEqualToData = false;
                newHarvestToEstimate.push(data);
            }

            if (data.parcel.id === parcelId) {
                return {
                    ...data,
                    floweringDate: data.floweringDate
                }
            }

            return data;
        });

        this.setState({
            datas: newDatas,
            harvestsToEstimate:  [...new Set(newHarvestToEstimate)],
            activateSortByEstimatedDateBtn: !newDateIsEqualToData,
        });
    }

    /* Fonction de suppression des récoltes */
    onConfirmDeleteHarvests = () => {
        const { deleteHarvests } = this.props;
        const { harvestsSelected } = this.state;

        //Suppression des récoltes
        deleteHarvests(harvestsSelected);

        this.setState({
            openConfirmDeleteHarvestsDialog: false,
            harvestsSelected: []
        });
    }

    deleteHarvests = (datas) => {
        this.setState({
            openConfirmDeleteHarvestsDialog: true,
        });
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction de rendu visuel - layout général
    ///////////////////////////////////////////////////////////////////////////
    render() {

        const {
            errorHarvest, updateNbRowsPerPageTableHarvests, loading,
            deleting, rowsPerPageForTableHarvests
        } = this.props; // fonctions mapDispatchToProps

        const {
            datas, openConfirmDeleteHarvestsDialog, currentRowsOfTable,
            searchFilter
        } = this.state; // etat du dialog popup

        return (
            <>
                {/* ↓↓ Partie visuel - affichage Popup pour la suppression des Récoltes ↓↓ */}
                <Dialog open={openConfirmDeleteHarvestsDialog}
                    onClose={this.handleCloseConfirmDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{StringTranslate.askDeleteHarvestTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{StringTranslate.askDeleteHarvests}</DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.handleCloseConfirmDialog} variant="text" color="error" autoFocus>
                            {StringTranslate.cancelDeleteAction}
                        </Button>
                        <Button onClick={this.onConfirmDeleteHarvests} variant="contained" color="primary">
                            {StringTranslate.confirmDeleteAction}
                        </Button>
                    </DialogActions>
                </Dialog>

                {(loading === true) ? <DataGridSkeleton /> :
                    /* ↓↓ partie - zone de visualisation des parcelles ↓↓ */
                    ((errorHarvest === undefined) || (errorHarvest === null) || (errorHarvest === '')) ?
                        <TableContainer>
                            <Grid style={{ width: '100%' }} item xs={12}>
                                {/* partie tableau */}
                                <CustomDataGrid
                                    disableSelectionOnClick={true}
                                    tableName={TableType.harvests}
                                    pageSize={rowsPerPageForTableHarvests}
                                    keepNonExistentRowsSelected={searchFilter !== "" ? true : false}
                                    updateNbRowsPerPageTable={updateNbRowsPerPageTableHarvests}      // ==> pas utilsé dans customDataGrid
                                    onSelectionModelChange={(newSelectionHarvests) => {
                                        this.setSelectionHarvests(newSelectionHarvests);
                                    }}
                                    Toolbar={this.CustomToolbar}
                                    toolbar={{
                                        deleteHarvests: (event, data) => this.deleteHarvests(data),
                                        onClickSortDatasByEstimatedDate: () => this.onClickSortDatasByEstimatedDate(),
                                        value: searchFilter,
                                        onChange: (event) => this.requestSearch(event.target.value, datas),
                                        clearSearch: () => this.requestSearch('', datas),
                                    }}
                                    loading={(deleting === true)}
                                    rows={(searchFilter !== "") ? currentRowsOfTable : datas}
                                    columns={this.columns}
                                    checkBoxActive={true}
                                />
                            </Grid>
                        </TableContainer> :
                        <Alert
                            severity="error"
                            icon={<InfoOutlined />}
                            sx={{ color: theme.palette.error.main }}
                        >
                            <AlertTitle>{StringTranslate.erroroups} &#9785; {StringTranslate.errorsuite}</AlertTitle>
                            {errorHarvest}
                            {StringTranslate.errorDialogText2} <LinkToContactUs displayText={StringTranslate.contactUs} />.
                        </Alert>
                }

            </>
        );
    }

}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    //Infos provenant du reducer 'parcelDatas':
    parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
    thumbnailParcelDico: lodashGet(state, 'parcelsData.thumbnailParcelDico', {}),

    //Infos provenant du reducer 'harvest':
    errorHarvest: lodashGet(state, 'harvestData.errorMessage', undefined),
    harvestDico: lodashGet(state, 'harvestData.harvestDico', {}),
    loading: lodashGet(state, 'harvestData.loadingHarvests', false),
    deleting: lodashGet(state, 'harvestData.deleting', false),
    generatingForParcelIds: lodashGet(state, 'harvestData.generatingForParcelIds', []),
    harvestsDatesUpdatedAtBeginning: lodashGet(state, 'harvestData.harvestsDatesUpdatedAtBeginning', false),

    //Infos provenant du reducer 'settings':
    rowsPerPageForTableHarvests: lodashGet(state, 'settingsData.settings.rowsPerPageForTableHarvests', 20),
    language: lodashGet(state, 'settingsData.settings.language', StringTranslate.getLanguage()),

    //Infos provenant du reducer 'clientUser':
    clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1),
    authorizeCornHarvest: lodashGet(state, 'clientUserData.clientDatas.authorizeCornHarvest', true),
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    deleteHarvests: (harvestsIdsToDelete) => dispatch(ActionDeleteHarvests(harvestsIdsToDelete)),
    showProfilMenuDialog: (index) => dispatch(ActionShowProfilMenuDialog(index)),
    updateNbRowsPerPageTableHarvests: (rowsPerPage) => dispatch(UpdateNbRowsPerPageTable(rowsPerPage, TableType.harvests)),
    generateResults: (clientId, datasToManage) => dispatch(ActionGenerateResults(clientId, datasToManage)),
    updateAllHarvestDatesAtTheBeginning: (clientId, harvests) => dispatch(ActionUpdateAllHarvestDatesAtTheBeginning(clientId, harvests)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HarvestList);