import React, { Component } from "react";
import { connect } from 'react-redux';

import lodashGet from 'lodash/get';

import {
    Typography, Menu, MenuItem, Divider,
    Grid, FormGroup, FormControlLabel, Checkbox, Tooltip
} from "@mui/material";

import LayerButton from './layerButton.jsx';
import ConstantsLayers from '../utils/constantsOfLayers.js';
import StringTranslate from '../assets/i18n/stringLanguage.jsx';
import routeLayerImg from '../assets/images/route-layer-90px.png';
import vueAerienneLayerImg from '../assets/images/vue-aerienne-layer-90px.png';
import sentinelSatelliteLayerImg from '../assets/images/sentinel-satellite-layer-90px.png';
import sentinelNDVILayerImg from '../assets/images/sentinel-ndvi-layer-90px.png';
import sentinelSatelliteParcelImg from '../assets/images/sentinel-satellite-parcel-90px.png';
import { ActionSaveSettingsAsk, ActionSelectLayer } from '../redux/actions/settings.js';
import { ActionLayersMenuClose, ActionAskToChangeLayer, ActionSetAnchorLayersPanel, ActionGlobalLayerLoaded } from '../redux/actions/contextApp.js';
import { ActionShowAllObservation } from '../redux/actions/observations.js';
import { SatImageSourceProvider } from "../utils/constantsProvidersSatellite.js";

// Valeurs possibles pour 'baseLayertypeValue' : ConstantsLayers.VisibleBaseLayerName ou ConstantsLayers.RoadBaseLayerName !
// Valeurs possibles pour 'forewardLayerSelected' : NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !

/* CONSTANTES */
const BASIC_LAYERS_INFO = { //Fond de carte !
    description: '',
    layers: [
        { details: `${StringTranslate.carterout}`, img: routeLayerImg, name: ConstantsLayers.RoadBaseLayerName, type: 'routiere' }, // = 'BasicLayersInfo-plan'},
        { details: `${StringTranslate.visustand}`, img: vueAerienneLayerImg, name: ConstantsLayers.VisibleBaseLayerName, type: 'satellite' }, // = 'BasicLayersInfo-satellite'}
    ]
};
const WMTS_LAYERS_INFO = { // Vues globales
    description: '',
    layers: [
        { details: `${StringTranslate.photovis}`, img: sentinelSatelliteLayerImg, name: ConstantsLayers.VisibleGlobalLayerName, type: 1 }, // = 'WMTSLayersInfo-satellite'},
        { details: `${StringTranslate.anavege}`, img: sentinelNDVILayerImg, name: ConstantsLayers.NdviGlobalLayerName, type: 2 }, // = 'WMTSLayersInfo-ndvi'}
    ]
};
const WMS_LAYERS_INFO = { // Vues à la parcelle
    description: '',
    layers: [
        { details: `${StringTranslate.photovis}`, img: sentinelSatelliteParcelImg, name: ConstantsLayers.VisibleParcelLayerName, type: 3 }, // = 'WMSLayersInfo-satellite'}, // <= Plus utilisé !
        { details: `${StringTranslate.anavege}`, img: vueAerienneLayerImg, name: ConstantsLayers.NdviParcelLayerName, type: 4 }, // = 'WMSLayersInfo-ndvi'}
    ]
};

////////////////////////////////////////////////////////////////////////////////////
// Composant global définissant la partie sélection des layers dans le menude droite
////////////////////////////////////////////////////////////////////////////////////
class LayerSelectionMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openTooltipAllParcel: false,
            openTooltipCloud: false,
        };
    }

    /**
     * fonction callback lors du click du bouton d'un choix de couche de fond dont son nom est passé en paramètre
     */
    onSelectBaseLayer = (name) => (event) => {
        const { selectLayer } = this.props;

        // ↓↓ suivant le nom du layer passé en paramètre ↓↓
        let newBaseLayer = undefined;

        switch (name) {
            case BASIC_LAYERS_INFO.layers[0].name: { // Fond de plan - plan
                newBaseLayer = name;
                break;
            }

            case BASIC_LAYERS_INFO.layers[1].name: { // Fond de plan - satellite
                newBaseLayer = name;
                break;
            }

            default:
                break;
        }
        // On prévient qu'il faut changer de fond de carte: 
        if (selectLayer) {
            // Valeurs possibles pour 'baseLayertypeValue' : ConstantsLayers.VisibleBaseLayerName ou ConstantsLayers.RoadBaseLayerName !
            selectLayer(newBaseLayer, undefined);
        }

    }

    /**
     * fonction callback lors du click du bouton d'un choix de couche générale (par dessus celle de fond) dont son nom est passé en paramètre
     */
    onSelectGeneralLayer(name) {

        const { askToChangeLayerAndSaveSettings, setAnchorLayersPanel, anchorLayersPanelTarget, globalLayerLoaded } = this.props;

        // ↓↓ suivant le nom du layer passé en paramètre ↓↓
        let newLastLayer = undefined;
        let newSettingsToSave = undefined;
        switch (name) {
            case WMTS_LAYERS_INFO.layers[0].name: { // Visible global
                newLastLayer = name;
                newSettingsToSave = {
                    ...this.props.settings,
                    lastLayer: WMTS_LAYERS_INFO.layers[0].type,
                };
                //on ne sauvegarde pas ici, mais plus tard (Sur fermeture du panneau: en mobile, c'est de suite ; sinon, lorsque l'on rabaisse ce panneau)     
                break;
            }

            case WMTS_LAYERS_INFO.layers[1].name: { // NDVI global
                newLastLayer = name;
                newSettingsToSave = {
                    ...this.props.settings,
                    lastLayer: WMTS_LAYERS_INFO.layers[1].type,
                };
                //on ne sauvegarde pas ici, mais plus tard (Sur fermeture du panneau: en mobile, c'est de suite ; sinon, lorsque l'on rabaisse ce panneau)               
                break;
            }

            case WMS_LAYERS_INFO.layers[1].name: { // NDVI à la parcelle
                newLastLayer = name;
                newSettingsToSave = {
                    ...this.props.settings,
                    lastLayer: WMS_LAYERS_INFO.layers[1].type,
                };
                //on ne sauvegarde pas ici, mais plus tard (Sur fermeture du panneau: en mobile, c'est de suite ; sinon, lorsque l'on rabaisse ce panneau)                 

                // Il apparait que quand on change rapidement de layer en ayant le spinner de chrgement qui tourne encore,
                // celui-ci ne disparait pas car toujours en cours.
                // Forcer l'arrêt du spinner de chargement du layer si apparant quand on change de layer 
                if (globalLayerLoaded()) {
                    globalLayerLoaded();
                }
                break;
            }

            default:
                break;
        }

        // On prévient qu'il faut changer de fond de carte: 
        if (askToChangeLayerAndSaveSettings) {
            // Valeurs possibles pour 'forewardLayerSelected' : NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
            askToChangeLayerAndSaveSettings(undefined, newLastLayer, newSettingsToSave);
        }

        if (setAnchorLayersPanel)
            setAnchorLayersPanel(anchorLayersPanelTarget);

    }

    handleClose = () => {
        const { closeLayersMenu, setAnchorLayersPanel } = this.props;

        closeLayersMenu();

        if (setAnchorLayersPanel)
            setAnchorLayersPanel(null);
    };

    /* fonction cycle de vie react */
    render() {
        const { forewardLayerIndex, menuLayerSelectionOpened, anchorLayersPanelTarget, observationDicoCounter,
            observationShowAll, showAllObservation, providerSrcImageSelectedOfParcel } = this.props;
        const { openTooltipAllParcel, openTooltipCloud } = this.state;

        let isModelisationImage = (providerSrcImageSelectedOfParcel===SatImageSourceProvider.Modelisation);

        return (
            <Menu style={{ marginTop: '40px', marginLeft: '-48px' }}
                anchorEl={anchorLayersPanelTarget}
                open={menuLayerSelectionOpened}
                onClose={this.handleClose}
                onClick={this.handleClose}
                PaperProps={{
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,

                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                <Typography variant="h5" style={{ textAlign: 'center' }}>{`${StringTranslate.indvege}`}</Typography>
                <MenuItem>
                    <Grid container spacing={1}>
                        <Grid item sm={6}>
                            <LayerButton
                                src={WMS_LAYERS_INFO.layers[1].img}
                                buttonState={forewardLayerIndex === WMS_LAYERS_INFO.layers[1].type ? 'select' : 'unselect'}
                                name={WMS_LAYERS_INFO.layers[1].name}
                                onSelectLayer={() => this.onSelectGeneralLayer(WMS_LAYERS_INFO.layers[1].name)}
                            />
                            <div><Typography variant="caption">{`${StringTranslate.vueparcelle}`}</Typography></div>
                        </Grid>
                        <Grid item sm={6}>
                            <Tooltip
                                open={isModelisationImage && openTooltipAllParcel} 
                                title={StringTranslate.noNdviOnAllMapForModelisation}
                                onOpen={() => {this.setState({openTooltipAllParcel: true});}}
                                onClose={() => {this.setState({openTooltipAllParcel: false});}}
                            >
                                <span>
                                    <LayerButton
                                        src={WMTS_LAYERS_INFO.layers[1].img}
                                        buttonState={forewardLayerIndex === WMTS_LAYERS_INFO.layers[1].type ? 'select' : 'unselect'}
                                        name={WMTS_LAYERS_INFO.layers[1].name}
                                        onSelectLayer={() => this.onSelectGeneralLayer(WMTS_LAYERS_INFO.layers[1].name)}
                                        disabled={isModelisationImage}
                                    />
                                    <div><Typography variant="caption">{`${StringTranslate.vueglobale}`}</Typography></div>
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </MenuItem>

                <Divider />

                <Typography variant="h5" style={{ textAlign: 'center' }}>{`${StringTranslate.DetectClouds}`}</Typography>
                <MenuItem>
                    <Grid container
                        justifyContent="center"
                        direction="column"
                        alignItems="center">
                        <Grid item xs={12}>
                            <Tooltip
                                open={isModelisationImage && openTooltipCloud} 
                                title={StringTranslate.noVerifCloudForModelisation}
                                onOpen={() => {this.setState({openTooltipCloud: true});}}
                                onClose={() => {this.setState({openTooltipCloud: false});}}
                            >
                                <span>
                                    <LayerButton
                                        src={WMTS_LAYERS_INFO.layers[0].img}
                                        buttonState={forewardLayerIndex === WMTS_LAYERS_INFO.layers[0].type ? 'select' : 'unselect'}
                                        name={WMTS_LAYERS_INFO.layers[0].name}
                                        onSelectLayer={() => this.onSelectGeneralLayer(WMTS_LAYERS_INFO.layers[0].name)}
                                        disabled={isModelisationImage}
                                    />
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>

                </MenuItem>

                {(observationDicoCounter > 0) && (
                    <>
                        <Divider />

                        <FormGroup style={{ marginLeft: '10px' }}>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={observationShowAll}
                                    color="secondary"
                                    onClick={(event) => { event.stopPropagation(); showAllObservation(!observationShowAll); }}
                                />
                            } label={<Typography variant="subtitle2">{`${StringTranslate.displayObservations}`}</Typography>}
                            />
                        </FormGroup>
                    </>
                )}


            </Menu>
        )
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = function (state) {
    return {
        //Infos provenant du reducer 'clientUser':
        enumTypoClient: lodashGet(state, 'clientUserData.clientDatas.enumTypoClient', 1),

        //Infos provenant du reducer 'contexte':
        showInviteToPremium: lodashGet(state, 'contextAppData.showInviteToPremium', false),
        menuLayerSelectionOpened: lodashGet(state, 'contextAppData.menuLayerSelectionOpened', false),
        anchorLayersPanelTarget: lodashGet(state, 'contextAppData.anchorLayersPanelTarget', null),

        //Infos provenant du reducer 'settings':
        settings: lodashGet(state, 'settingsData.settings', undefined),
        baseLayerIndex: lodashGet(state, 'settingsData.settings.baseLayer', 'satellite'),
        baseLayerSelected: lodashGet(state, 'settingsData.baseLayerSelected', ConstantsLayers.VisibleBaseLayerName),
        forewardLayerIndex: lodashGet(state, 'settingsData.settings.lastLayer', 4),
        forewardLayerSelected: lodashGet(state, 'settingsData.forewardLayerSelected', ConstantsLayers.NdviParcelLayerName),
        providerSrcImageSelectedOfParcel: lodashGet(state, 'contextAppData.providerSrcImageSelectedOfParcel', 0),

        //Infos provenant du reducer 'observations':
        observationShowAll: lodashGet(state, 'observationsData.showAll', undefined),
        observationDicoCounter: lodashGet(state, 'observationsData.observationDicoCounter', undefined),
    };
}

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
    selectLayer: (baseLayertypeValue, forewardLayertypeValue) => dispatch(ActionSelectLayer(baseLayertypeValue, forewardLayertypeValue)),
    askToChangeLayerAndSaveSettings: (baseLayertypeValue, forewardLayertypeValue, newSettingsValue) => dispatch(ActionAskToChangeLayer(baseLayertypeValue, forewardLayertypeValue, newSettingsValue)),
    saveSettings: (settingsToSave) => dispatch(ActionSaveSettingsAsk(settingsToSave)),
    closeLayersMenu: () => dispatch(ActionLayersMenuClose()),
    setAnchorLayersPanel: (target) => dispatch(ActionSetAnchorLayersPanel(target)),
    globalLayerLoaded: () => dispatch(ActionGlobalLayerLoaded()),
    showAllObservation: (bool) => dispatch(ActionShowAllObservation(bool)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LayerSelectionMenu);
