const themeTypography = (theme, TheFontFamily) => {
    return {
        fontFamily: TheFontFamily,
        h6:{
            fontWeight: 500,
            fontSize: "0.75rem", 
            lineHeight: 1.6
        },
        h5:{
            fontWeight: 500,
            fontSize: "0.875rem", 
            lineHeight: 1.334
        },
        h4:{
            fontWeight: 600,
            fontSize: "1rem", 
            lineHeight: 1.235
        },
        h3:{
            fontWeight: 600,
            fontSize: "1.25rem", 
            lineHeight: 1.167
        },
        h2:{
            fontWeight: 700,
            fontSize: "1.5rem", 
            lineHeight: 1.2
        },
        h1:{
            fontWeight: 700,
            fontSize: "2.125rem", 
            lineHeight: 1.167
        },
        subtitle1:{
            fontWeight: 500,
            fontSize: "0.875rem", 
            lineHeight: 1.75
        },
        subtitle2:{
            fontWeight: 400,
            fontSize: "0.75rem", 
            lineHeight: 1.57,
            color: theme.palette.grey[500]
        },
        caption:{
            fontWeight: 400,
            fontSize: "0.75rem", 
            lineHeight: 1.66,
            color: theme.palette.grey[500]
        },
        body1:{
            fontWeight: 400,
            fontSize: "0.875rem", 
            lineHeight: 1.334
        },
        body2:{
            fontWeight: 400,
            fontSize: "0.875rem", 
            lineHeight: "1.5em",
            color: theme.palette.grey[700],
            letterSpacing: "0em"
        },
        button:{
            fontWeight: 500,
            fontSize: "0.875rem", 
            lineHeight: 1.75,
            textTransform: "none"
        },
        customInput: {
            marginTop: 1,
            marginBottom: 1,
            '& > label': {
                top: 23,
                left: 0,
                color: theme.palette.grey[500],
                '&[data-shrink="false"]': {
                    top: 5
                }
            },
            '& > div > input': {
                padding: '30.5px 14px 11.5px !important'
            },
            '& legend': {
                display: 'none'
            },
            '& fieldset': {
                top: 0
            }
        },
        mainContent: {
            backgroundColor: theme.palette.primary.light,
            width: '100%',
            flexGrow: 1,
            marginTop: '70px',            
            borderRadius: `8px`,
            
            "@media (max-width: 600px)": 
                { 
                    "padding": "5px", 
                    marginRight: '0px', 
                    minHeight: 'calc(100vh - 80px)', // 80 vient de la margin top , et du padding de 5
                },
            "@media (min-width: 601px) and (max-width: 900px)": 
                { 
                    "padding": "10px", 
                    marginRight: '10px', 
                    minHeight: 'calc(100vh - 90px)', // 90 vient de la margin top , et du padding de 10
                },
            "@media (min-width: 901px)": 
                { 
                    "padding": "20px", 
                    marginRight: '20px', 
                    minHeight: 'calc(100vh - 110px)', // 110 vient de la margin top , et du padding de 20
                },
        },
        menuCaption: {
            fontSize: '0.875rem',
            fontWeight: 500,
            color: theme.palette.grey[600],
            padding: '6px',
            textTransform: 'capitalize',
            marginTop: '10px'
        },
        subMenuCaption: {
            fontSize: '0.6875rem',
            fontWeight: 500,
            color: theme.palette.text.secondary,
            textTransform: 'capitalize'
        },
        commonAvatar: {
            cursor: 'pointer',
            borderRadius: '8px'
        },
        smallAvatar: {
            width: '22px',
            height: '22px',
            fontSize: '1rem'
        },
        mediumAvatar: {
            width: '34px',
            height: '34px',
            fontSize: '1.2rem'
        },
        largeAvatar: {
            width: '44px',
            height: '44px',
            fontSize: '1.5rem'
        },
        htmlFontSize: 16,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        overline: {
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 2.66,
            textTransform: "uppercase"
        }
    }
};

export default themeTypography;