import React from "react";

import { Box, DialogContentText } from "@mui/material";

import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';
import ConstantsTutorialHelps from "../../../utils/constantsTutorialHelps.js";
import ConfigAuthent from "../../../utils/configAuthent.js";

/**
 * Composant représentant l'étape de succès lors du didacticiel firstparcel
 */
function StepIndice(props) {

    let cultureValue = StringTranslate.getLanguage();

    let constUrlLangage = ConstantsTutorialHelps.SubUrlLangageFrFr;

    if (cultureValue && (cultureValue === 'es-ES')) {
        constUrlLangage = ConstantsTutorialHelps.SubUrlLangageEsEs;
    }
    else if (cultureValue && (cultureValue === 'en-GB')) {
        constUrlLangage = ConstantsTutorialHelps.SubUrlLangageEn;
    }

    const gifAideIndice = ConfigAuthent.ConstAndDefault.UrlTradCompteResources + constUrlLangage + ConstantsTutorialHelps.SubUrlHelpIndice;

    return (
        <><DialogContentText>{StringTranslate.stepIndiceSubtitle}</DialogContentText>
            <Box sx={{ marginTop: '20px' }} className='dfp-content-welcome'>
                <img src={gifAideIndice} alt="indice" className="dfp-img-indice" />
            </Box>
        </>
    );
}

export default StepIndice;