import React from "react";

import StringTranslate from '../assets/i18n/stringLanguage.jsx';
import { Box, TableContainer, Paper  } from '@mui/material';
import { DataGrid, gridClasses, frFR, esES, enUS } from '@mui/x-data-grid';

import { styled } from '@mui/material/styles';

import getTheme from "../themes/index.js";
let theme = getTheme();

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.grey[400],
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.grey[100],
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.grey[200],
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.common.white,
    },
    '& .ant-empty-img-5': {
        fillOpacity: '0.8',
        fill: theme.palette.grey[100],
    },
}));
  

/* Concerne les tableaux de biomasses et de modulations */
function CustomDataGrid(props) {

    const [pageSize, setPageSize] = React.useState(props.pageSize); // Pour mise à jour du nombre de lignes par page dans le tableau dans la table Settings */

    /* A afficher quand il n'y a aucune données en base de données à afficher */
    const CustomNoRowsOverlay = () => {
        return (
            <StyledGridOverlay sx={{ m: 1 }}>
                <svg
                    width="100"
                    height="80"
                    viewBox="0 0 184 152"
                    aria-hidden
                    focusable="false"
                >
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(24 31.67)">
                            <ellipse
                                className="ant-empty-img-5"
                                cx="67.797"
                                cy="106.89"
                                rx="67.797"
                                ry="12.668"
                            />
                            <path
                                className="ant-empty-img-1"
                                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                            />
                            <path
                                className="ant-empty-img-2"
                                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                            />
                            <path
                                className="ant-empty-img-3"
                                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                            />
                        </g>
                        <path
                            className="ant-empty-img-3"
                            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                        />
                        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                        </g>
                    </g>
                </svg>
                <Box sx={{ m: 2 }}>{StringTranslate.noRows}</Box>
            </StyledGridOverlay>
        );
    }

    /* A afficher quand les données sont en train de se télécharger */
    const CustomNoDownloadingOverlay = () => {
        return (
            <StyledGridOverlay sx={{ m: 1 }}>
                <svg
                    width="100"
                    height="80"
                    viewBox="0 0 184 152"
                    aria-hidden
                    focusable="false"
                >
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(24 31.67)">
                            <ellipse
                                className="ant-empty-img-5"
                                cx="67.797"
                                cy="106.89"
                                rx="67.797"
                                ry="12.668"
                            />
                            <path
                                className="ant-empty-img-1"
                                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                            />
                            <path
                                className="ant-empty-img-2"
                                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                            />
                            <path
                                className="ant-empty-img-3"
                                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                            />
                        </g>
                        <path
                            className="ant-empty-img-3"
                            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                        />
                        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                        </g>
                    </g>
                </svg>
                <Box sx={{ m: 2 }}>En cours de chargement ...</Box>
            </StyledGridOverlay>
        );
    }

    //Traduction des éléments du tableau de la liste des biomasses (tri, nombre de lignes par page, etc)
    const setLocalizationTextDataGrid = (language) => {
        if (language === 'fr-FR' || language === 'fr-BE')
            return frFR.components.MuiDataGrid.defaultProps.localeText;
        else if (language === 'es-ES')
            return esES.components.MuiDataGrid.defaultProps.localeText;
        else
            return enUS.components.MuiDataGrid.defaultProps.localeText;
    };

    /* Mise à jour du nombre de lignes par page dans le tableau des pesées de biomasse dans la table Settings */
    const updatePageSize = (rowsPerPage) => {
        setPageSize(rowsPerPage);

        if (props.updateNbRowsPerPageTable) {
            props.updateNbRowsPerPageTable(rowsPerPage);
        }
    }

    // En fonction du tableau (biomasse ou modulation), le nombre de lignes par page :
    const getRowsPerPageOptions = () => {
        return [5, 10, 20];
    }

    return (
        <TableContainer component={Paper}>
            <DataGrid
                sx={{
                    '& .MuiDataGrid-cell': {
                        borderColor: theme.palette.grey[200]
                    },
                    '& .disabled': {
                        color: theme.palette.grey[400],
                    },
                    border: 0,
                    [`& .${gridClasses.cell}`]: {
                        py: 0.5,
                      },
                    "& .MuiDataGrid-columnHeaderTitleContainer": {
                        overflow: "visible",
                        lineHeight: "1.43rem",
                        whiteSpace: "normal",
                    }
                }}
                localeText={setLocalizationTextDataGrid(StringTranslate.getLanguage())} //pour la traduction du tableau
                editMode={props.editMode}
                showColumnRightBorder={true}
                experimentalFeatures={{ newEditingApi: true }}
                disableColumnMenu={false} //Dans chaque colonne, on cache le menu
                autoHeight={true} //Taille du tableau en hauteur : automatique
                pageSize={pageSize} //Nombre de lignes par page de tableau
                onPageSizeChange={(newPageSize) => updatePageSize(newPageSize)}
                rowsPerPageOptions={getRowsPerPageOptions()}
                pagination
                onSelectionModelChange={props.onSelectionModelChange}
                selectionModel={props.selectionModel}
                components={{
                    Toolbar: props.Toolbar,
                    NoRowsOverlay: (props.loadWaiting === true) ? CustomNoDownloadingOverlay : CustomNoRowsOverlay,
                }}
                componentsProps={{
                    toolbar: props.toolbar, //TODO : Penser à renommer cette props pour mieux la différencier de 'Toolbar' (celle avec Majuscule).
                }}
                rows={props.rows}
                rowHeight={props.rowHeight ?? 52}
                columns={props.columns}
                columnVisibilityModel={{
                    // Hide columns status and traderName, the other columns will remain visible
                    id: false,
                }}
                isRowSelectable={props.isRowSelectable}
                getRowClassName={props.getRowClassName} //Style overrided des lignes du tableau
                checkboxSelection={props.checkBoxActive} //Ajout des checkbox pour sélection
                disableSelectionOnClick={props.disableSelectionOnClick}
                loading={props.loading}
                disableColumnSelector
                keepNonExistentRowsSelected = {props.keepNonExistentRowsSelected}
                onCellClick={props.onCellClick}
                disableColumnFilter={(props.disableColumnFilter === undefined) || (props.disableColumnFilter === null) ? false : props.disableColumnFilter}
                getRowHeight={() => 'auto'}
                
            />
        </TableContainer>
    );
}

export default CustomDataGrid;