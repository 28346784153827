import StringTranslate from '../assets/i18n/stringLanguage.jsx';
import IconeObservationAutre from '../assets/images/observation/IconeObservationAutre.tsx';
import IconeObservationAdventice from '../assets/images/observation/IconeObservationAdventice.tsx';
import IconeObservationCarence from '../assets/images/observation/IconeObservationCarence.tsx'; //@@ récupérer cette icône auprès de Samuel Maillard
import IconeObservationGibier from '../assets/images/observation/IconeObservationGibier.tsx';
import IconeObservationMaladie from '../assets/images/observation/IconeObservationMaladie.tsx';
import IconeObservationLevee from '../assets/images/observation/IconeObservationLevee.tsx';
import IconeObservationRavageur from '../assets/images/observation/IconeObservationRavageur.tsx';
import { renderToString } from 'react-dom/server'

import L from 'leaflet';

const ObservationTypeIconList = [
    IconeObservationAutre,
    IconeObservationAdventice,
    IconeObservationCarence,
    IconeObservationGibier,
    IconeObservationMaladie,
    IconeObservationLevee,
    IconeObservationRavageur,
];

/**
 * Helper pour la manipulation des objets 'Observation' et 'ObservationType' provenant de la WebApi
 */
export const ObservationsHelper = {

    /**
     *  fonction permettant de récupérer une entité observation
     */
    selectObservationFromDicoById(observationDico, observationId) {
        if ((!observationDico) || (!observationId) || (!Number.isInteger(observationId)) || (observationId <= 0)) {
            return undefined;
        }

        let observationFund = undefined;
        try {
            const observationIdStr = `${observationId}`;
            observationFund = observationDico[observationIdStr];
        } catch (e) {
            //RAS, juste pour pouvoir s'arrêter, sauf si réelle erreur.
        }

        return observationFund;
    },

    /**
     *  fonction permettant d'obtenir à partir d'un dico une liste d'entité observationType munie de variables traduites et d'un tri 
     */
    getObservationTypeListWithi18n(observationTypeDico) {
        if (!observationTypeDico)
            return [];

        let observationTypeList = Object.values(observationTypeDico);

        observationTypeList.forEach( observationType => {
            let key = `observation_type_${observationType.id}`;
            observationType.i18n = StringTranslate[key] ? StringTranslate[key] : '';
        });

        return observationTypeList;
    },

    /**
     *  fonction permettant de renvoyer le nom i18n suivant l'objet observationType
     */
    geti18nFromObservationTypeId(observationTypeId) {
        if (observationTypeId <= 0) return '';

        let key = `observation_type_${observationTypeId}`;
        return StringTranslate[key] ? StringTranslate[key] : '';
    },

    /**
     *  fonction permettant de renvoyer l'icône suivant le type observation
     */
    getLeafletIconFromObservationTypeId(observationTypeId) {
        if (observationTypeId <= 0) return null;

        let svg = ObservationTypeIconList[(observationTypeId - 1)]; // pourquoi -1 ? décalage - la liste commence par 0

        let icon = renderToString(<IconeObservationAutre fontSize="large" />);

        switch (svg) {
            case IconeObservationAutre:
                icon = renderToString(<IconeObservationAutre fontSize="large" />);
                break;
            case IconeObservationAdventice:
                icon = renderToString(<IconeObservationAdventice fontSize="large" />);
                break;
            case IconeObservationCarence:
                icon = renderToString(<IconeObservationCarence fontSize="large" />);
                break;
            case IconeObservationGibier:
                icon = renderToString(<IconeObservationGibier fontSize="large" />);
                break;
            case IconeObservationMaladie:
                icon = renderToString(<IconeObservationMaladie fontSize="large" />);
                break;
            case IconeObservationLevee:
                icon = renderToString(<IconeObservationLevee fontSize="large" />);
                break;
            case IconeObservationRavageur:
                icon = renderToString(<IconeObservationRavageur fontSize="large" />);
                break;
            default: 
                icon = renderToString(<IconeObservationAutre fontSize="large" />);
                break;
        }
    
        return L.divIcon({
            className: "leaflet-data-marker",
            html: icon,
            // html: L.Util.template(`<${svg.name} />`),            
            // html: L.Util.template(`<img src=${svg} width='40' alt="" />`),
            iconSize: [40, 40],
            iconAnchor:  [20, 40],
            popupAnchor: [0, -40]
        });
    },

    /**
     *  fonction permettant de renvoyer l'icône de type observation 
     */
    getDefaultObservationTypeIcon() {
        
        let svg = ObservationTypeIconList[0];

        let icon = renderToString(<IconeObservationAutre fontSize="large" />);

        switch (svg) {
            case IconeObservationAutre:
                icon = renderToString(<IconeObservationAutre fontSize="large" />);
                break;
            case IconeObservationAdventice:
                icon = renderToString(<IconeObservationAdventice fontSize="large" />);
                break;
            case IconeObservationCarence:
                icon = renderToString(<IconeObservationCarence fontSize="large" />);
                break;
            case IconeObservationGibier:
                icon = renderToString(<IconeObservationGibier fontSize="large" />);
                break;
            case IconeObservationMaladie:
                icon = renderToString(<IconeObservationMaladie fontSize="large" />);
                break;
            case IconeObservationLevee:
                icon = renderToString(<IconeObservationLevee fontSize="large" />);
                break;
            case IconeObservationRavageur:
                icon = renderToString(<IconeObservationRavageur fontSize="large" />);
                break;
            default: 
                icon = renderToString(<IconeObservationAutre fontSize="large" />);
                break;
        }
    
        return L.divIcon({
            className: "leaflet-data-marker",
            html: icon,
            iconSize: [40, 40],
            iconAnchor:  [20, 40],
            popupAnchor: [0, -40]
        });
    },

    /**
     *  fonction permettant de renvoyer la classe css à appliquer sur le layer représentant une zone d'observation
     *  observationTypeId => l'ID du type d'observation 
     */
    getObservationAreaClassFromObservationTypeId(observationTypeId = 0) {
        return (observationTypeId <= 0) ? 'observationAreaType_1' : `observationAreaType_${observationTypeId}`;
    },
};