import { ActionTypeHarvest, HarvestStep, HarvestTab, Crop, CornType } from '../actions/harvest';
import { HarvestHelper } from '../../utils/harvestHelper';
import { harvestVarietyCharacteristicsHelper } from '../../utils/harvestVarietyCharacteristicsHelper';
import lodashGet from 'lodash/get';
import lodashFind from 'lodash/find';


/* state initial */
const initialState = {
    errorMessage: undefined, //Message d'erreur dans l'appli pour la récolte
    harvestStep: HarvestStep.CHOIX_PARCELS, //Etape de démarrage : choix des parcelles pour commencer à remplir les données de récolte
    harvestTab: HarvestTab.Management, //Tab de démarrage

    parcelIdsSelected: {}, //Ids de parcelles sélectionnées à la première étape (Choix des parcelles)

    loadingHarvests: false, //Permet de savoir si on charge les récoltes
    harvestsAlreadyLoaded: false, //Permet de savoir si les récoltes en Bdd ont déjà été chargées

    loadingVarietiesCharacteristics: false, //Permet de savoir si on charge les variétés de culture
    varietiesCharacteristicsAlreadyLoaded: false, //Permet de savoir si les variétés de culture en Bdd ont déjà été chargées

    harvestDico: {}, //Dico des récoltes du client
    harvestDicoCounter: 0, //Nombre de récoltes du client

    harvestVarietyCharacteristicsDico: {}, //Dico des variétés de culture du client
    harvestVarietyCharacteristicsDicoCounter: 0, //Nombre de variétés de culture du client
    parcelVarietiesMapping: {}, // Dico des id de varietés par id de parcelle

    generatingHarvestDates: false, //Permet de savoir si on est en cours de demande de dates de récolte
    generatingForParcelIds: [],
    harvestsDatesUpdatedAtBeginning: false, //Chargement des dates de récolte au démarrage de l'appli
    savingDatasBeforeGoBackToStep1: false, //Permet de savoir si les données de récoltes sont en cours d'enregistrement au clic sur le bouton retour à l'étape 2

    currentCrop: Crop.Corn, //Culture utilisée pour la récolte
    deleting: false,

    deletingVarieties: false, //Annonce la suppression en cours des variétés
    
    specificInfosParcelIdsDone: [], //les ids parcel qui sont dans le tableau des données spécifiques qui ont une date de générée quand on clique sur le bouton de génération

    updatingVariety: false, //Informe qu'une variété est en cours de mise à jour
    addingVariety: false, //Informe qu'une variété est en cours d'ajout
    openPopupVarietyForm: false,

    //Props nécessaires à la sélection de la variété créée par le client pour la parcelle à laquelle il souhaite ajouter la variété 
    parcelIdFocused: -1, //Id de la parcelle qui est en train d'être modifié / à laquelle on ajoute une variété
    lastAddedVariety: [], //Dernière variété créée par le client

    //props pour gérer les différents objectifs de récolte dans le cas du Maïs grain:
    cornMinDryingTarget: undefined, //si reste à cette valeur, on ne personnalisera pas l'estimation !
    cornMaxDryingTarget: undefined,
    cornDryingList: [],
    cornDryingCounter: 0,
};

function harvestManagerAction(state = initialState, action) {
    switch (action.type) {

        case ActionTypeHarvest.GO_TO_STEP_OF_HARVEST: {
            return Object.assign({}, state, {
                errorMessage: undefined,
                harvestStep: action.harvestStep,
                specificInfosParcelIdsDone: action.harvestStep === HarvestStep.CHOIX_PARCELS ? [] : state.specificInfosParcelIdsDone
            });
        }

        case ActionTypeHarvest.GO_TO_TAB_OF_HARVEST: {
            return Object.assign({}, state, {
                errorMessage: undefined,
                harvestTab: action.harvestTab,
            });
        }

        case ActionTypeHarvest.LOADING_HARVESTS: {
            return Object.assign({}, state, {
                loadingHarvests: true,
                harvestsAlreadyLoaded: false,
            });
        }

        case ActionTypeHarvest.LOADING_VARIETIES_CHARACTERISTICS: {
            return Object.assign({}, state, {
                loadingVarietiesCharacteristics: true,
                varietiesCharacteristicsAlreadyLoaded: false,
            });
        }

        case ActionTypeHarvest.HARVESTS_LOADED: {
            return Object.assign({}, state, {
                loadingHarvests: false,
                harvestsAlreadyLoaded: true,
                harvestDico: action.harvestDico,
                harvestDicoCounter: HarvestHelper.count(action.harvestDico),
            });
        }

        case ActionTypeHarvest.VARIETIES_CHARACTERISTICS_LOADED: {
            return Object.assign({}, state, {
                loadingVarietiesCharacteristics: false,
                varietiesCharacteristicsAlreadyLoaded: true,
                harvestVarietyCharacteristicsDico: action.harvestVarietyCharacteristicsDico,
                harvestVarietyCharacteristicsDicoCounter: harvestVarietyCharacteristicsHelper.count(action.harvestVarietyCharacteristicsDico),
            });
        }

        case ActionTypeHarvest.ERROR_LOAD_HARVESTS: {
            return Object.assign({}, state, {
                loadingHarvests: false,
                errorMessage: action.errorMessage,
            });
        }

        case ActionTypeHarvest.ERROR_LOAD_VARIETIES_CHARACTERISTICS: {
            return Object.assign({}, state, {
                loadingVarietiesCharacteristics: false,
                errorMessage: action.errorMessage,
            });
        }

        case ActionTypeHarvest.SELECT_ID_PARCELS_FOR_MANAGEMENT: {
            return Object.assign({}, state, {
                parcelIdsSelected: action.parcelIdsSelected
            });
        }

        case ActionTypeHarvest.HARVESTS_DATES_ASKED_AT_THE_BEGINNING: {
            return Object.assign({}, state, {
                harvestsDatesUpdatedAtBeginning: true,
            });
        }

        case ActionTypeHarvest.GENERATING_HARVEST_DATES: {
            return Object.assign({}, state, {
                generatingHarvestDates: true,
                generatingForParcelIds: action.generatingForParcelIds,
            });
        }

        case ActionTypeHarvest.ERROR_GENERATE_RESULTS: {
            return Object.assign({}, state, {
                generatingHarvestDates: false,
                errorMessage: action.errorMessage,
                generatingForParcelIds: [],
                savingDatasBeforeGoBackToStep1: false,
            });
        }

        case ActionTypeHarvest.INIT_HARVEST_DATES: {

            let parcelIdsDone = [];
            let generatingForParcelIds = state.generatingForParcelIds;

            Object.values(action.harvests).forEach( (harvest) => {
                let harvestFound = Object.values(state.harvestDico).find(harvestFromDico => harvestFromDico.parcelId === harvest.parcelId );
                if (harvestFound !== undefined) {
                    state.harvestDico[harvestFound.parcelId] = harvest;
                    parcelIdsDone.push(harvestFound.parcelId);

                    generatingForParcelIds = generatingForParcelIds.filter(function(item) {
                        return item !== harvestFound.parcelId
                    });
                }
                else {
                    //On rajoute un nouvel Harvest dans le dico :
                    state.harvestDico[harvest.parcelId] = harvest;
                    parcelIdsDone.push(harvest.parcelId);
                    
                    generatingForParcelIds = generatingForParcelIds.filter(function(item) {
                        return item !== harvest.parcelId
                    });
                }
            });

            return Object.assign({}, state, {
                generatingHarvestDates: false,
                //On rajoute les nouvelles données au dico de harvests
                harvestDico: {
                    ...state.harvestDico
                },
                harvestDicoCounter: HarvestHelper.count(state.harvestDico),
                specificInfosParcelIdsDone: parcelIdsDone,
                generatingForParcelIds: [...new Set(generatingForParcelIds)],
            });
        }

        case ActionTypeHarvest.UPDATING_HARVEST_DATAS: {
            return Object.assign({}, state, {
                savingDatasBeforeGoBackToStep1: true,
            });
        }

        case ActionTypeHarvest.UPDATE_HARVEST_DATAS: {

            let newDico = {
                ...state.harvestDico,
                ...action.harvests,
            }

            return Object.assign({}, state, {
                //On rajoute les nouvelles données au dico de harvests
                harvestDico: newDico,
                harvestDicoCounter: HarvestHelper.count(newDico),
                savingDatasBeforeGoBackToStep1: false,
            });
        }

        case ActionTypeHarvest.ERROR_UPDATE_HARVEST_DICO: {
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
                savingDatasBeforeGoBackToStep1: false,
            });
        }

        case ActionTypeHarvest.SET_VARIETY_CHARACTERISTICS_BY_PARCEL: {
            return Object.assign({}, state, {
                parcelVarietiesMapping: {
                    ...state.parcelVarietiesMapping,
                    [action.parcelId]: action.varietyId,
                }
            });
        }

        case ActionTypeHarvest.DELETING_HARVESTS: {
            return Object.assign({}, state, {
                deleting: true,
                errorMessage: undefined,
                readableMode: false,
            });
        }

        case ActionTypeHarvest.DELETE_HARVESTS: {
            // ↓ mise à jour du dico de parcelle et de la liste de harvestId filterée ↓
            let harvestsDeleted = 0;
            action.parcelIdsDeleted.forEach( parcelId => {
                delete state.harvestDico[parcelId]; // on supprime l'entrée depuis le harvestDico
                harvestsDeleted = harvestsDeleted + 1;
            });

            return Object.assign({}, state, {
                deleting: false,
                errorMessage: undefined,
                harvestDico: {
                    ...state.harvestDico
                }, 
                harvestDicoCounter: state.harvestDicoCounter - harvestsDeleted, 
            });
        }

        case ActionTypeHarvest.DELETING_VARIETIES: {
            return Object.assign({}, state, {
                deletingVarieties: true,
                errorMessage: undefined,
                readableMode: false,
            });
        }

        case ActionTypeHarvest.DELETE_VARIETIES: {
            // ↓ mise à jour du dico de variétés ↓
            let varietiesDeleted = 0;
            if ((action.varietyIdsToDelete !== undefined) && (action.varietyIdsToDelete !== null)) {
                action.varietyIdsToDelete.forEach( varietyId => {
                    delete state.harvestVarietyCharacteristicsDico[varietyId]; // on supprime l'entrée depuis le varietyDico
                    varietiesDeleted = varietiesDeleted + 1;
                });
            }
           
            return Object.assign({}, state, {
                deletingVarieties: false,
                errorMessage: undefined,
                harvestVarietyCharacteristicsDico: {
                    ...state.harvestVarietyCharacteristicsDico
                }, 
                harvestVarietyCharacteristicsDicoCounter: state.harvestVarietyCharacteristicsDicoCounter - varietiesDeleted, 
            });
        }

        case ActionTypeHarvest.ERROR_DELETE_VARIETIES: {
            return Object.assign({}, state, {
                deletingVarieties: false,
                errorMessage: action.errorMessage,
            });
        }

        case ActionTypeHarvest.UPDATING_VARIETY: {
            return Object.assign({}, state, {
                updatingVariety: true,
            });
        }

        case ActionTypeHarvest.VARIETY_UPDATED: {
            if (action.variety === undefined) {
                return Object.assign({}, state, {
                    updatingVariety: false,
                    openPopupVarietyForm: false
                });
            }
            else {
                return Object.assign({}, state, {
                    updatingVariety: false,
                    harvestVarietyCharacteristicsDico: {
                        ...state.harvestVarietyCharacteristicsDico,
                        [action.variety.id]: action.variety
                    }, 
                    openPopupVarietyForm: false
                });
            }
            
        }

        case ActionTypeHarvest.OPEN_POPUP_VARIETY_FORM: {
            return Object.assign({}, state, {
                openPopupVarietyForm: action.openPopupVarietyForm
            });
        }

        case ActionTypeHarvest.ADDING_VARIETY: {
            return Object.assign({}, state, {
                addingVariety: true,
            });
        }

        case ActionTypeHarvest.VARIETY_ADDED: {
            return Object.assign({}, state, {
                addingVariety: false,
                harvestVarietyCharacteristicsDico: {
                    ...state.harvestVarietyCharacteristicsDico,
                    [action.newVariety.id]: action.newVariety
                },
                harvestVarietyCharacteristicsDicoCounter: state.harvestVarietyCharacteristicsDicoCounter + 1,
                lastAddedVariety: action.newVariety,
                openPopupVarietyForm: false
            });
    
        }

        case ActionTypeHarvest.ERROR_ADDING_VARIETY:{
            return {
                ...state,
                addingVariety: false,
                errorMessage: action.errorMessage,
            }
        }

        case ActionTypeHarvest.CLEAR_HARVEST_MESSAGE_ERROR: {
            return Object.assign({}, state, {
                errorMessage: undefined,
            });
        }

        //Ajout des actions de récupération d'id de la parcelle et de reset des ids de parcelle et de variété
        //Action qui récupère l'id de la parcelle où on souhaite sélectionner la variété
        case ActionTypeHarvest.GET_PARCEL_ID_FOCUSED:{
            return Object.assign({}, state, {
                parcelIdFocused: action.parcelId,
            });        
        }
        //Action qui reset l'id de la parcelle et la dernière variété ajoutée 
        case ActionTypeHarvest.RESET_PARCEL_ID_AND_VARIETY_SAVED:{
            return Object.assign({}, state, {
                parcelIdFocused: -1,
                lastAddedVariety: [],
            });
        }

        //Gestion des définitions d'oobej:
        case ActionTypeHarvest.CORN_DRY_SPECIFICATIONS_LOADED: {
            let targetInfos = (action.targetDryingInfosList !== undefined) ? action.targetDryingInfosList : [];
            
            //Définir la liste des % disponible (pour le maïs grain uniquement) !
            let minTargetRate = undefined; //si reste à cette indéfinition, on utilisera uniquement le % fournis par le semencier !
            let maxTargetRate = undefined;
            let cornDryingRateList = [];
            if ((Array.isArray(targetInfos)) && (targetInfos.length > 0)) {
                const targetInfosOfCornDry = lodashFind(targetInfos, (item) => { return (item.enumCrop === Crop.Corn) && (item.harvestType === CornType.Grain); }); //filtre le maïs grain !

                minTargetRate = lodashGet(targetInfosOfCornDry, 'minRate', undefined);
                maxTargetRate = lodashGet(targetInfosOfCornDry, 'maxRate', undefined);
                if ((minTargetRate !== undefined) && (maxTargetRate !== undefined)) {
                    for (let index = minTargetRate; index <= maxTargetRate; index++) {
                        cornDryingRateList.push(index);
                    };
                }
            }

            return Object.assign({}, state, {
                cornMinDryingTarget: minTargetRate,
                cornMaxDryingTarget: maxTargetRate,
                cornDryingList: cornDryingRateList,
                cornDryingCounter: cornDryingRateList.length,
            });
        }

        default:
            return state;
    }
}

export default harvestManagerAction;