import React from "react";
import { connect } from "react-redux";
import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';
import '../../../assets/css/identificationDialog.css';
import {
    Button, Typography, Grid, IconButton, Alert, Stack, CircularProgress
} from "@mui/material";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ConstantsObservation from "../../../utils/constantsObservation";
import { IdentificationImage, EnumIdentificationOrgan } from "../../../models/identificationImage";
import { ActionDeleteIdentificationImagesToAPI, ActionSearchPlant } from "../../../redux/actions/observations.js";
import { ActionShowImageDialog } from "../../../redux/actions/contextApp.js";
import DisplayImageList from "../../thumbnail/DisplayImageList.jsx";
import fileHelper from "../../../utils/fileHelper.js";

class StepChooseImages extends React.Component {
    constructor(props) {
        super(props);

        this.imagesToSelect = [];

        //identification depuis une observation
        if (props.observationImages !== undefined) {
            //premiere recherche
            if (props.identificationImages === []) {
                //Récupère toutes les images de l'observation
                this.imagesToSelect = [
                    ...props.observationImages.map((image) => {
                        let returnImage = { ...image };
                        //Si l'image n'est pas enregistrer sur azure, on recré son uri à partir de sa data
                        if (returnImage.id === -1) {
                            returnImage.imageUrl = fileHelper.getUriFromDataForJpegImage(image.imageData); //création de l'uri de l'image
                        }
                        return returnImage
                    }),
                ];
            }
            //la recherche ne convient pas 
            else {
                //récupération des images pas encore utilisé pour la recherche
                this.imagesToSelect = [
                    ...props.imagesToSelect.map((image) => {
                        let returnImage = { ...image };
                        //Si l'image n'est pas enregistrer sur azure, on recré son uri à partir de sa data
                        if (returnImage.id === -1) {
                            returnImage.imageUrl = fileHelper.getUriFromDataForJpegImage(image.imageData); //création de l'uri de l'image
                        }
                        return returnImage
                    }),
                ];
            }
        }

        this.state = {
            // Images
            imagesForIdentification: [...props.identificationImages],
            imagesURL: [...props.identificationImages.map((image) => { return image.imageUrl })],

            organIndex: EnumIdentificationOrgan.AUTO,

            saving: false,

            // On affiche la pop-up de sélection d'image si identification vient d'une observation et aucune image sélectionnée
            isDrawerSelectImageOpen: (props.identificationImages.length === 0),
        };

        this.inputFileRef = React.createRef();

        this.handleClickTriggerInputFile.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { searching, identificationImages } = this.props;

        //récupération des images pendant le chargement
        if ((prevProps.searching !== searching) && (searching)) {
            let images = [...identificationImages.map((image) => {
                let returnImage = { ...image };
                //Si l'image n'est pas enregistrer sur azure, on recré son uri à partir de sa data
                if (returnImage.imageData !== null && returnImage.imageData.lenght) {
                    returnImage.imageUrl = fileHelper.getUriFromDataForJpegImage(image.imageData);
                }
                return returnImage;
            })];
            this.setState({
                imagesForIdentification: images,
                imagesURL: [
                    ...images.map((image) => {
                        return image.imageUrl;
                    })
                ],
            });
        }
    }

    /* ↓ fonctions liées à l'afficahge de photo ↓ */
    /**
     * Fonction gérant le click d'une photo dans le Composant DisplayImageList
     */
    handleOpenDialogImage = (index) => {
        if (index < 0) return;

        const { imagesForIdentification } = this.state;
        if ((!imagesForIdentification) || (!Array.isArray(imagesForIdentification)) || (index >= imagesForIdentification.length)) return;

        let image = imagesForIdentification[index];
        if (!image) return;

        this.props.showImage(image.imageUrl);
    }

    /**
     * Fonction gérant la suppression d'une photo dans le Composant DisplayImageList
     */
    onClickDeleteImage = (index) => {
        if (index < 0) return;

        const { imagesURL, imagesForIdentification } = this.state;
        if ((!imagesURL) || (!Array.isArray(imagesURL)) || (index >= imagesURL.length)) return;
        if ((!imagesForIdentification) || (!Array.isArray(imagesForIdentification)) || (index >= imagesForIdentification.length)) return;

        let newImagesURL = imagesURL;
        let newImages = imagesForIdentification;

        newImagesURL.splice(index, 1);
        let imageDeleted = newImages.splice(index, 1)[0];
        this.setState({
            imagesForIdentification: [...newImages],
            imagesURL: [...newImagesURL],
        });
        if (imageDeleted && (imageDeleted.id !== undefined))
            this.imagesToSelect.push(imageDeleted);
    }

    /**
     * Fonction permettant de récupérer l'index de l'organ
     */
    onClickOrganButton = (organIndex) => {
        const { imagesToSelect, observationImages } = this.props;
        if ((imagesToSelect !== undefined) && (observationImages !== undefined)) {
            //Affiche la sélection d'image
            this.setState({ organIndex: organIndex, isDrawerSelectImageOpen: true });
        } else {
            //Trigger l'inputFile
            this.handleClickTriggerInputFile(organIndex);
        }
    }

    /* ↓ fonctions liées à l'ajout de photo ↓ */
    /**
     * Fonction permettant de trigger l'inputFile
     */
    handleClickTriggerInputFile = (organIndex = -1) => {
        if (organIndex === -1) {
            if (this.state.imagesForIdentification.length < ConstantsObservation.NbMaxImages) {
                this.inputFileRef.current.click(); //trigger l'input
            }
        } else {
            this.setState({ organIndex: organIndex }, () => {
                if (this.state.imagesForIdentification.length < ConstantsObservation.NbMaxImages) {
                    this.inputFileRef.current.click(); //trigger l'input
                }
            });
        }
    }

    /**
     * Fonction permettant d'ajouter une photo suite à la validation de l'inputFile
     */
    onInputFileChange = (event) => {
        const { imagesForIdentification, imagesURL, organIndex } = this.state;
        const { clientId, } = this.props;
        let imgFile = event.target.files[0]; // récupération du flux de l'image

        //Affiche le chargement pendant le process
        this.setState({
            saving: true,
            isDrawerSelectImageOpen: false,
        }, () => {
            //Récupère les informations de l'image
            fileHelper.getDataFromJpegImage(imgFile)
                .then((informationImage) => {
                    // création de l'objet image
                    this.setState({
                        imagesForIdentification: [
                            ...imagesForIdentification,
                            new IdentificationImage({
                                clientId: clientId,
                                imageData: [...informationImage.imageData],
                                imageUrl: informationImage.imageUri,
                                organ: organIndex,
                            })
                        ],
                        imagesURL: [...imagesURL, informationImage.imageUri],
                        saving: false,
                    });
                })
                .catch(() => {
                    this.setState({
                        saving: false,
                    });
                });
        });
    }

    /**
     * Fonction permettant de mettre à jour les listes d'images suite à la sélection d'une image
     */
    selectObservationImage = (index) => {
        const { imagesForIdentification, imagesURL, organIndex } = this.state;
        let imageSelected = this.imagesToSelect[index];
        this.setState({
            imagesForIdentification: [
                ...imagesForIdentification,
                {
                    ...imageSelected,
                    organ: organIndex,
                }
            ],
            imagesURL: [...imagesURL, imageSelected.imageUrl],
            isDrawerSelectImageOpen: false
        });
        this.imagesToSelect.splice(index, 1);
    }

    /**
     * Fonction permettant de lancer la recherche
     */
    onClickSearchIdentification = () => {
        const { imagesForIdentification } = this.state;
        const { searchPlant } = this.props;
        searchPlant(
            [...imagesForIdentification],
            [...this.imagesToSelect],
        );
    }

    /**
     * fonction appeler pour fermer la fenetre d'identification
     */
    onClickClose = () => {
        const { closeIdentificationDialog, deleteIdentificationImages } = this.props;
        deleteIdentificationImages();
        closeIdentificationDialog();

        this.setState({ isDrawerSelectImageOpen: false });
    }

    render() {
        const { searching, errorDetected, } = this.props;
        const { imagesURL, saving, isDrawerSelectImageOpen } = this.state;
        return (
            (!isDrawerSelectImageOpen) ?
                // Affichage des photos sélectionnées + boutons pour en choisir d'autres
                <>
                    <Typography variant="subtitle2">{StringTranslate.identificationChooseImageSubtitle}</Typography>

                    {/* ↓ affichage photos ↓ */}
                    <DisplayImageList
                        disabled={(searching || saving)}
                        imagesURL={imagesURL}
                        onClickImage={this.handleOpenDialogImage}
                        onClickDeleteImage={this.onClickDeleteImage}
                    />

                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {/* ↓ Ajout photos ↓ */}
                            <Typography variant="subtitle2">{StringTranslate.identificationChooseImageTips}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} columns={4}>
                                <Grid item xs={2} align="right">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disabled={imagesURL.length >= ConstantsObservation.NbMaxImages || (searching || saving)}
                                        onClick={() => { this.onClickOrganButton(EnumIdentificationOrgan.LEAF) }}
                                        sx={{
                                            width: "80px",
                                            height: "80px"
                                        }}>
                                        <span><AddAPhotoIcon /> {StringTranslate.identificationChooseImageOrganLeaf}</span>
                                    </Button>
                                </Grid>

                                <Grid item xs={2}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disabled={imagesURL.length >= ConstantsObservation.NbMaxImages || (searching || saving)}
                                        onClick={() => { this.onClickOrganButton(EnumIdentificationOrgan.FLOWER) }}
                                        sx={{
                                            width: "80px",
                                            height: "80px"
                                        }}>
                                        <span><AddAPhotoIcon /> {StringTranslate.identificationChooseImageOrganFlower}</span>
                                    </Button>

                                </Grid>
                                <Grid item xs={2} align="right">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disabled={imagesURL.length >= ConstantsObservation.NbMaxImages || (searching || saving)}
                                        onClick={() => { this.onClickOrganButton(EnumIdentificationOrgan.FRUIT) }}
                                        sx={{
                                            width: "80px",
                                            height: "80px"
                                        }}>
                                        <span><AddAPhotoIcon /> {StringTranslate.identificationChooseImageOrganFruit}</span>
                                    </Button>

                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disabled={imagesURL.length >= ConstantsObservation.NbMaxImages || (searching || saving)}
                                        onClick={() => { this.onClickOrganButton(EnumIdentificationOrgan.BARK) }}
                                        sx={{
                                            width: "80px",
                                            height: "80px"
                                        }}>
                                        <span><AddAPhotoIcon /> {StringTranslate.identificationChooseImageOrganBark}</span>
                                    </Button>

                                </Grid>
                            </Grid>

                            <input
                                ref={this.inputFileRef}
                                accept=".jpeg,.jpg"
                                id="raised-button-file"
                                type="file"
                                className="input"
                                value=''
                                onChange={this.onInputFileChange}
                                disabled={imagesURL.length >= ConstantsObservation.NbMaxImages}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {/* Messages d'erreur */}
                            {(errorDetected) && <Alert severity="error">
                                {StringTranslate.identificationChooseImageMsgError}
                            </Alert>}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <IconButton
                                        color="primary"
                                        onClick={this.onClickClose}
                                        disabled={(searching || saving)}><ArrowBackIcon /></IconButton>
                                </Grid>
                                <Grid item xs={4}>
                                    <Stack justifyContent="center" alignItems="center">
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            startIcon={(searching || saving) && <CircularProgress color="inherit" size={20} />}
                                            disabled={(imagesURL.length <= 0) || (searching || saving)}
                                            onClick={this.onClickSearchIdentification}>
                                            {StringTranslate.identificationChooseImageSearch}
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
                :
                //Affiche la sélection de photo
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{StringTranslate.identificationSelectImage}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayImageList
                            imagesURL={this.imagesToSelect.map((image) => { return image.imageUrl })}
                            onClickImage={this.selectObservationImage}
                            onInputFileChange={(imagesURL.length + this.imagesToSelect.length < ConstantsObservation.NbMaxImages) ?
                                this.onInputFileChange
                                : undefined
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <IconButton
                            color="primary"
                            onClick={this.onClickClose}
                            disabled={(searching || saving)}>
                            <ArrowBackIcon />
                        </IconButton>
                    </Grid>
                </Grid>
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant */
const mapStateToProps = function (state) {
    return {
        clientId: (state.clientUserData && state.clientUserData.clientDatas) ? state.clientUserData.clientDatas.id : -1,
        observationDico: state.observationsData.observationDico,
        identificationImages: state.observationsData.identificationImages,
        searching: state.observationsData.searching,
        errorDetected: state.observationsData.errorDetectedDuringIdentification,
        imagesToSelect: state.observationsData.imagesToSelect,
        observationImages: state.observationsData.observationImages,
        closeIdentificationDialog: state.observationsData.closeIdentificationDialog,
    };
}

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
    searchPlant: (identificationImages, imagesToSelect) => dispatch(ActionSearchPlant(identificationImages, imagesToSelect)),
    deleteIdentificationImages: () => dispatch(ActionDeleteIdentificationImagesToAPI()),
    showImage: (uri) => dispatch(ActionShowImageDialog(uri)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StepChooseImages);