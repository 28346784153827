import React from 'react';
import { Modal, Paper, Stack, IconButton, Typography } from '@mui/material';
import SlideRangeMarker from '../SlideRangeMarker';
import { BiomassesHelper } from '../../../utils/biomassesHelper';
import { Close as CloseIcon } from '@mui/icons-material';
import StringTranslate from '../../../assets/i18n/stringLanguage';

/**
 * Modal qui affiche les informations sur les plages de biomasses
 * @param {boolean} open - Booléen pour ouvrir ou fermer la modal
 * @param {Object} parcel - parcelle
 * @param {boolean} isBeginningWinter - Booléen pour savoir si c'est le début de l'hiver 
 * @param {() => void} onClose - Fonction appellé au clic sur la croi, ou le clic ailleur que sur la modal
 * @returns {React.Component} - La modal
 */
export default class ModalBiomassRange extends React.Component {
  render() {
    const { open, parcel, isBeginningWinter, onClose } = this.props;

    return (
      <Modal
        open={open}
        onClose={onClose}
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper>
          <Stack p={1}>
            <Stack direction={'row-reverse'} justifyContent={''} alignItems={'center'}>
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack direction={'column'}>
              <Stack spacing={1} direction={'row'} alignItems={'baseline'}>
                <SlideRangeMarker label={'IN_RANGE'} />
                <Stack direction={'column'}>
                  <Typography variant="subtitle2">{BiomassesHelper.getRangeDatesText(parcel, isBeginningWinter)}</Typography>                                                
                  <Typography variant="subtitle2">{StringTranslate.biomassRangeText}</Typography>
                </Stack>
              </Stack>
              <Stack spacing={1} direction={'row'} alignItems={'baseline'}>
                <SlideRangeMarker label={'IN_EXTRA_RANGE'} />
                <Stack direction={'column'}>
                  <Typography variant="subtitle2">{BiomassesHelper.getExtraRangeDatesText(parcel, isBeginningWinter)}</Typography>
                  <Typography variant="subtitle2">{StringTranslate.biomassExtraRangeText}</Typography>
                </Stack>
              </Stack>
              <Stack spacing={1} direction={'row'} alignItems={'baseline'}>
                <SlideRangeMarker />
                <Typography variant="subtitle2">{StringTranslate.biomassOutOfRangeText}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </Modal>
    )
  }
}