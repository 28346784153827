import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


function StepDessin(props: SvgIconProps) {
    return ( 
        <SvgIcon viewBox="0 0 908 642" {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="907.88" height="641.74" strokeWidth="1.8" stroke="none" fill="current">
                <polygon points="531.57 550.42 358.41 550.42 358.41 611.3 377.65 611.3 377.65 641.74 512.33 641.74 512.33 611.3 531.57 611.3 531.57 550.42" fill="url(#6dbd394d-3147-4710-ae5b-040c9889b819)"/>
                <path d="M596.87,251.71V460.23c20.73,6.22,29.81,12.58,34.91,26.13a65.58,65.58,0,0,1,2.18,38c-3.27,14-9.28,21.47-25.65,25.81a62.53,62.53,0,0,1-28.41,1c-22.62-4.59-34.51-20-34.51-45.56,0-24.46,8.4-37.44,31.5-45.16V421.29l-1-170.59L406.09,503.22s81.27,67.93,111,199.05H655.23s41.59-152.22,111-189.69Z" transform="translate(-146.06 -129.13)" fill="url(#25611293-a7d7-4bef-9ea8-eef2963e34c7)"/>
                <path d="M599.93,261.88v193.5a55,55,0,1,1-27.52,2.37V261.88l-156,238.55s82.57,73.4,110.1,201.85H645.81s45.87-156,110.1-192.67Z" transform="translate(-146.06 -129.13)" fill="#eceff1"/>
                <rect x="59.35" y="31.83" width="366.99" height="18.35" fill="#eceff1"/>
                <rect x="472.22" y="31.83" width="366.99" height="18.35" fill="#eceff1"/>
                <rect x="380.47" y="591.49" width="128.45" height="45.87" fill="#00a42b"/>
                <rect x="413.91" y="1.14" width="69.93" height="69.93" fill="url(#f7aa0426-c3ad-4d3b-8596-50aa38a87e77)"/>
                <rect x="417.17" y="4.3" width="64.22" height="64.22" fill="#00a42b"/>
                <circle cx="41" cy="41" r="41" fill="url(#170327e1-c007-4bc3-aecd-c22d9d8756ba)"/>
                <circle cx="866.87" cy="41" r="41" fill="url(#35baff6c-edbd-4d6b-969d-bf3825558dc6)"/>
                <circle cx="41" cy="41" r="36.7" fill="#00a42b"/>
                <circle cx="866.74" cy="41" r="36.7" fill="#00a42b"/>
                <rect x="362.12" y="554.79" width="165.15" height="55.05" fill="#00a42b"/>
                <rect x="362.12" y="554.79" width="165.15" height="55.05" opacity="0.2"/>
            </svg>
        </SvgIcon>
    );
}

export default StepDessin;