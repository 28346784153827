import lodashGet from 'lodash/get';
import lodashSome from 'lodash/some';
import lastNitrogenInputHelper from "../../utils/lastNitrogenInputHelper";
import { ActionTypeLastNitrogenInput, LastNitrogenInputStep } from "../actions/lastNitrogenInput";
import ConstantsLayers from '../../utils/constantsOfLayers';


const initialState = {

    loading: false, //permettra de mettre une waiting cursor dans le tableau et le sidebar...
    deleting: false, //Suppression des données de fumure en cours

    errorMessage: undefined,
    building: false, //permettra de mettre une waiting cursor,
    buildingSampleAndDose: false, //permettra de mettre une waiting cursor,
    savingSampleAndDose: false, // permettra de mettre une waiting cursor,

    /* Dico fertilizer pour les troisièmes apports en cours (actuelles) */
    lastNitrogenInputDico: {}, //dico dont la clef est l'ID de parcelle associée - valeur l'entité 'lastNitrogenInputdata'. => Uniquement les parcelles avec un conseil azoté complet.
    lastNitrogenInputDicoCounter: 0,
    lastNitrogenInputDicoIsLoaded: false, //permet de savoir si le dico a déjà été chargé
    lastNitrogenInputDicoIsLoading: false, //permet de savoir si le dico des données spécifiques est en cours de chargement
    lastNitrogenInputStep: LastNitrogenInputStep.CHOIX_LAST_NITROGEN_INPUTS,
    showLastNitrogenInputMap: false,

    askingAzofertDatesRange: true,

    parcelIdsSelected: [], //parcelles id sélectionnées pour commencer le troisième apport
    parcelIdSelected: undefined,
    culturesForFertilizing: [], //cultures pour le troisième apport
    cultureSelected: undefined, //culture choisie pour commencer le troisième apport
    lastNitrogenInputSelected: -1, // clef du dico de l'entité sélectionnée

    downloading: false, //permettra de mettre une waiting cursor lors du téléchargement d'une nouvelle prescription,

    openCongratulationsDialog: false,

    generatingFile: false, //permettra de mettre un waiting cursor lors de la création/téléchargement des fichiers de FUMURE.

    downloadingPrescriptions: false, //téléchargement des prescriptions zip en cours

    //Choix des couches: 
    baseLayerSelectedForLastNitrogenInput: ConstantsLayers.VisibleBaseLayerName, // VisibleBaseLayerName ou RoadBaseLayerName !
    forewardLayerSelectedForLastNitrogenInput: ConstantsLayers.NdviParcelLayerName, // NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !

    lastInputNParameter: {}, //Données de modulation d'un conseil dernier apport
};

function lastNitrogenInputManagerAction(state = initialState, action) {
    switch (action.type) {
        case ActionTypeLastNitrogenInput.SELECT_LAST_NITROGEN_INPUT: {
            return {
                ...state,
                lastNitrogenInputSelected: action.lastNitrogenInputSelected,
            }
        }

        case ActionTypeLastNitrogenInput.LOAD_LAST_NITROGEN_INPUTS: {
            return {
                ...state,
                loading: false,
                errorMessage: undefined,
                lastNitrogenInputDico: action.lastNitrogenInputDico,
                lastNitrogenInputDicoCounter: lastNitrogenInputHelper.count(action.lastNitrogenInputDico),
                lastNitrogenInputDicoIsLoaded: true,
                lastNitrogenInputDicoIsLoading: false,
            };
        }

        case ActionTypeLastNitrogenInput.ERROR_LOAD_LAST_NITROGEN_INPUTS: {
            return {
                ...state,
                loading: false,
                errorMessage: action.errorMessage,
                //readableMode: false, //on n'a pas à changer le mode en cas d'erreur ! //TODO: A vérifier sur autres cas et autres reducers !
                downloadingPrescriptions: false,
                //fertilizerDicoIsLoaded: false,
                lastNitrogenInputDicoIsLoading: false,
            };
        }

        case ActionTypeLastNitrogenInput.LOADING_LAST_NITROGEN_INPUTS: {
            return {
                ...state,
                loading: true,
                errorMessage: undefined,
                lastNitrogenInputDicoIsLoading: true,
            };
        }

        case ActionTypeLastNitrogenInput.BUILDING_LAST_NITROGEN_INPUTS: {
            //construit un nouvel ensemble, où certains items sont flaggés comme étant en cours de calcul !
            let newLastInputNDico = { ...state.lastNitrogenInputDico };
            if (action.lastInputNStartingList) {
                action.lastInputNStartingList.forEach(itemLastInputN => {
                    const currentItem = lodashGet(newLastInputNDico, `[${itemLastInputN.parcelId}]`, undefined);
                    if (currentItem) {
                        currentItem.askBuild = true;
                        newLastInputNDico[itemLastInputN.parcelId] = currentItem;
                    }
                });
            }
            
            return {
                ...state,
                building: true,
                errorMessage: undefined,
                lastNitrogenInputDico: newLastInputNDico,
            };
        }

        case ActionTypeLastNitrogenInput.BUILD_LAST_NITROGEN_INPUT: {
            //construit un nouvel ensemble, où l'item fournit contient le nouveau résultat.
            let newLastInputNDico = { ...state.lastNitrogenInputDico };
            if (action.newLastNitrogenInput) {
                action.newLastNitrogenInput.askBuild = false;

                const currentItem = lodashGet(newLastInputNDico, `[${action.newLastNitrogenInput.parcelId}]`, undefined);
                if (currentItem) {
                    newLastInputNDico[action.newLastNitrogenInput.parcelId] = action.newLastNitrogenInput;
                }
            }
            //est-ce que c'était le seul qui n'avait pas encore reçu de réponce ?
            const isStillSomeToBuild = lodashSome(newLastInputNDico, { askBuild: true, });

            return {
                ...state,
                building: (isStillSomeToBuild === true),
                errorMessage: undefined,
                lastNitrogenInputDico: newLastInputNDico,
            };
        }

        case ActionTypeLastNitrogenInput.ERROR_BUILD_LAST_NITROGEN_INPUT: {
            let newLastInputNDico = { ...state.lastNitrogenInputDico };
            if (action.lastNitrogenInputParcelId > 0) {
                const currentItem = lodashGet(newLastInputNDico, `[${action.lastNitrogenInputParcelId}]`, undefined);
                if (currentItem) {
                    newLastInputNDico[action.lastNitrogenInputParcelId].askBuild = false;
                }
            }
            //est-ce que c'était le seul qui n'avait pas encore reçu de réponce ?
            const isStillSomeToBuild = lodashSome(newLastInputNDico, { askBuild: true, });

            return {
                ...state,
                building: (isStillSomeToBuild === true),
                errorMessage: action.errorMessage,
                lastNitrogenInputDico: newLastInputNDico,
            };
        }

        case ActionTypeLastNitrogenInput.DOWNLOADING_LAST_NITROGEN_INPUTS_PRESCRIPTIONS: {
            return {
                ...state,
                downloading: true,
                errorMessage: undefined,
            };
        }

        case ActionTypeLastNitrogenInput.DOWNLOADED_LAST_NITROGEN_INPUTS_PRESCRIPTIONS: {
            return {
                ...state,
                downloading: false,
                errorMessage: undefined,
            };
        }

        case ActionTypeLastNitrogenInput.ERROR_DOWNLOAD_LAST_NITROGEN_INPUTS_PRESCRIPTIONS: {
            return {
                ...state,
                downloading: false,
                errorMessage: action.errorMessage,
            };
        }

        case ActionTypeLastNitrogenInput.DELETING_LAST_NITROGEN_INPUT: {

            return {
                ...state,
                deleting: true,
                errorMessage: undefined,
            };
        }

        case ActionTypeLastNitrogenInput.DELETE_LAST_NITROGEN_INPUT: {

            return {
                ...state,
                deleting: false,
                errorMessage: undefined,
                lastNitrogenInputDico: action.lastNitrogenInputDico,
                lastNitrogenInputDicoCounter: lastNitrogenInputHelper.count(action.lastNitrogenInputDico),
            };
        }

        case ActionTypeLastNitrogenInput.ERROR_DELETE_LAST_NITROGEN_INPUT: {

            return {
                ...state,
                deleting: false,
                errorMessage: action.errorMessage,
            }
        }

        case ActionTypeLastNitrogenInput.CLEAR_LAST_NITROGEN_INPUT_ERROR: {

            return {
                ...state,
                errorMessage: undefined,
            }
        }

        case ActionTypeLastNitrogenInput.GO_TO_STEP_OF_LAST_NITROGEN_INPUT: {
            return {
                ...state,
                loading: false,
                lastNitrogenInputStep: action.lastNitrogenInputStep,
                showLastNitrogenInputMap: ((action.lastNitrogenInputStep === LastNitrogenInputStep.CHOIX_LAST_NITROGEN_INPUTS) || (action.lastNitrogenInputStep === LastNitrogenInputStep.RESULT)) ? false : (action.lastNitrogenInputStep === LastNitrogenInputStep.MAP) ? true : state.showLastNitrogenInputMap,
                building: (action.lastNitrogenInputStep === LastNitrogenInputStep.CHOIX_LAST_NITROGEN_INPUTS) ? false : state.building,
                lastNitrogenInputDatas: action.lastNitrogenInputDatas
            };
        }

        case ActionTypeLastNitrogenInput.BUILDING_LAST_NITROGEN_SAMPLE_AND_DOSES: {
            return{
                ...state,
                buildingSampleAndDose: true,
                lastInputNParameter: action.lastInputNParameter,
            }
        }

        case ActionTypeLastNitrogenInput.BUILD_LAST_NITROGEN_SAMPLE_AND_DOSES:{
            //construit un nouvel ensemble, où l'item fournit contient le nouveau résultat.
            let newLastInputNDico = { ...state.lastNitrogenInputDico };
            if (action.newLastNitrogenInput) {
                newLastInputNDico[action.newLastNitrogenInput.parcelId] = {
                    ...action.newLastNitrogenInput,
                    askBuild: false,
                };
            }

            return {
                ...state,
                buildingSampleAndDose: false,
                errorMessage: undefined,
                lastNitrogenInputDico: newLastInputNDico,
                lastNitrogenInputSelected: action.newLastNitrogenInput.parcelId,
            };
        }

        case ActionTypeLastNitrogenInput.ERROR_BUILD_LAST_NITROGEN_SAMPLE_AND_DOSES: {

            return {
                ...state,
                buildingSampleAndDose: false,
                errorMessage: action.errorMessage,
            }
        }

        case ActionTypeLastNitrogenInput.SAVING_LAST_NITROGEN_SAMPLE_AND_DOSES: {
            return{
                ...state,
                savingSampleAndDose: true,
            }
        }

        case ActionTypeLastNitrogenInput.SAVED_LAST_NITROGEN_SAMPLE_AND_DOSES:{
            //construit un nouvel ensemble, où l'item fournit contient le nouveau résultat.
            let newLastInputNDico = { ...state.lastNitrogenInputDico };
            if (action.newLastNitrogenInput) {
                newLastInputNDico[action.newLastNitrogenInput.parcelId] = {
                    ...action.newLastNitrogenInput,
                    askBuild: false,
                };
            }

            return {
                ...state,
                savingSampleAndDose: false,
                errorMessage: undefined,
                lastNitrogenInputDico: newLastInputNDico,
                lastNitrogenInputSelected: -1,
            };
        }

        case ActionTypeLastNitrogenInput.ERROR_SAVED_LAST_NITROGEN_SAMPLE_AND_DOSES: {

            return {
                ...state,
                savingSampleAndDose: false,
                errorMessage: action.errorMessage,
            }
        }

        case ActionTypeLastNitrogenInput.SELECT_LAYER_FOR_LAST_NITROGEN_INPUT: {
            return {
                ...state,
                baseLayerSelectedForLastNitrogenInput: action.baseLayertypeForLastNitrogenInput,
                forewardLayerSelectedForLastNitrogenInput:action.forewardLayertypeForLastNitrogenInput,
            }
        }

        case ActionTypeLastNitrogenInput.CREATE_LAST_NITROGEN_INPUT_PARAMETER: {
            return {
                ...state,
                lastInputNParameter: action.lastInputNParameter,
            }
        }
        
        default:
            return state;
    }
}

export default lastNitrogenInputManagerAction;
