import React from "react";
import { connect } from 'react-redux';

import { get as lodashGet } from 'lodash';

/* Composants mui */
import {
    CardContent, CardActions, Grid, TableContainer, Typography, TextField, Button, Box, Alert, Card
} from '@mui/material';

/* Composants react */
import { ThumbnailParcelShapeFromPathInfos } from '../thumbnail/ThumbnailParcelShape.jsx';
import CustomDataGrid from "../../components/customDataGrid";

/* Icones */
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

/* Dicos, helpers */
import dicoFunction from '../../datas/dicoDetails.js';
import DateHelper from '../../utils/dateHelper.js';
import stringHelper from '../../utils/stringHelper.js';
import { ParcelsHelper } from '../../utils/parcelsHelper.js';

/* Traduction */
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';

/* Actions redux */
import { BiomassStep, ActionClearNewBiomassCounter, ActionGoToNextStepOfBiomass } from '../../redux/actions/biomass';
import { TableType, UpdateNbRowsPerPageTable } from '../../redux/actions/settings.js';
import { ProfilIndex, ActionShowProfilMenuDialog } from "../../redux/actions/contextApp.js";

/* css */
import '../../assets/css/renderParcels.css';

/* theme */
import getTheme from "../../themes/index.js";
import { InfoOutlined } from "@mui/icons-material";
let theme = getTheme();

/**
 * Composant d'affichage des parcelles actuelles.
 */
class ChoixParcelsBiomass extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            parcelDatas: this.initialiseParcelDatas(props.parcelIdFilteredList), //parcelles

            //sauvegarde la langue en cours lors de la création du composant: (Car pas présente dans le store Redux)
            language: StringTranslate.getLanguage(),

            cultures: this.initialiseCultures(),

            parcelsSelected: (props.parcelIdSelected > 0) ? [props.parcelIdSelected] : [], //parcelles sélectionnées par les checkbox
            searchFilter: '', //Zone de recherche du tableau 
            currentRowsOfTable: [] //Datas en fonction du texte tapé dans la zone de recherche
        };

        // liste des noms de colonnes affichées
        this.columns = this.initialiseParcelColumns(props.parcelIdFilteredList); //RQ: pas dans le 'state' car on en utilise des propriétés !
    }

    //renvoie les cultures appartenant aux parcelles existantes
    initialiseCultures() {
        const { parcelDico } = this.props;

        return ParcelsHelper.mergeCultures(parcelDico);
    }

    componentDidUpdate(prevProps, prevState) {
        //@@Réagir aux modifications sur les parcelles : Suppression d'une ou toutes les parcelles ; Modification d'une ou plusieurs données d'une parcelle !
        const { parcelDicoCounter, parcelIdFilteredListCounter, parcelIdFilteredList,
            updatingParcelName, updatingParcelInfos, biomassDico, biomassDicoCounter } = this.props;
        const { language, parcelDatas } = this.state;

        /* Lorsqu'il y a un ajout, suppression de parcelle, il faut actualiser la liste des 'datas': */
        /* Idem, si c'est le filtre des parcelles qui change la liste de celles filtrées ! */
        if ((prevProps.parcelDicoCounter !== parcelDicoCounter) || (prevProps.parcelIdFilteredListCounter !== parcelIdFilteredListCounter)) {
            const newDatas = this.initialiseParcelDatas(parcelIdFilteredList);

            if (prevProps.parcelDicoCounter !== parcelDicoCounter) {
                // liste des noms de colonnes affichées. 
                this.setState({
                    parcelDatas: newDatas
                },
                    () => { this.columns = this.initialiseParcelColumns(parcelIdFilteredList) }); //RQ: ca va que l'on actualise 'cultures' sans quoi le rendu n'aurai pas lieu et on n'utiliserai pas la nouvelle liste de noms de colonne !
            } else {
                this.setState({
                    parcelDatas: newDatas,
                });
            }
        }
        /* Lorsqu'il y a une modification des données d'une parcelle (RQ: n'a pas être réalisé qu'ici!), il faut actualiser la liste des 'datas': */
        /* RQ: c'est plus rapide de recharger toutes les parcelles que de rechercher quelle parcelle a été modifié et quelle(s) info(s) de cette parcelle! */
        else if (((prevProps.updatingParcelName === true) && (updatingParcelName === false)) ||
            ((prevProps.updatingParcelInfos === true) && (updatingParcelInfos === false))) { //RQ: lorsque l'on passe de 'true' à 'false' c'est que le traitement est finis (même si pourrai être KO)
            const newDatas = this.initialiseParcelDatas(parcelIdFilteredList);
            this.setState({ parcelDatas: newDatas, });
        }
        else if ((prevProps.biomassDicoCounter !== biomassDicoCounter) || (prevProps.biomassDico !== biomassDico)) {
            //Mettre à jour les parcelles qui ont une entrée d'hiver et/ou une sortie d'hiver (rajout de la coche verte)
            if (biomassDico) {
                let newParcelDatas = [];
                for (let i = 0; i < parcelDatas.length; i++) {
                    let foundBiomassParcelBeginningWinter = false;
                    let foundBiomassParcelEndingWinter = false;
                    for (const key in biomassDico) {
                        if (biomassDico[key].parameter.parcelId === parcelDatas[i].id) {

                            if ((biomassDico[key].parameter.dateImagSource !== undefined) && (biomassDico[key].parameter.dateImagSource !== null)) {

                                if (biomassDico[key].parameter.isBeginningWinter === true) {
                                    foundBiomassParcelBeginningWinter = true;
                                    parcelDatas[i].parcel.imagSourceDateBeginningWinter = biomassDico[key].parameter.dateImagSource;
                                }
                                else {
                                    foundBiomassParcelEndingWinter = true
                                    parcelDatas[i].parcel.imagSourceDateEndingWinter = biomassDico[key].parameter.dateImagSource;
                                }
                            }
                        }
                    }
                    if (foundBiomassParcelBeginningWinter === false) {
                        parcelDatas[i].parcel.imagSourceDateBeginningWinter = undefined;
                    }
                    if (foundBiomassParcelEndingWinter === false) {
                        parcelDatas[i].parcel.imagSourceDateEndingWinter = undefined;
                    }

                    newParcelDatas.push(parcelDatas[i]);
                }

                this.setState({ parcelDatas: newParcelDatas, });
            } else {
                this.setState({ parcelDatas: [], });
            }
        }

        /* Lorsqu'il y a un changement de langue (même si on se doute que l'utilisateur ne changera pas de langue tous les 4 matins !), il faut actualiser certaines listes: */
        const currentLanguage = StringTranslate.getLanguage();
        if ((!language) || (language === '') || (language !== currentLanguage)) {
            // liste des noms de colonnes affichées. 
            this.columns = this.initialiseParcelColumns(parcelIdFilteredList); //RQ: ca va que l'on actualise 'cultures' sans quoi le rendu n'aurai pas lieu et on n'utiliserai pas la nouvelle liste de noms de colonne !
            const newCultures = this.initialiseCultures();
            this.setState({ cultures: newCultures, language: currentLanguage, });
        }
    }

    /**
     * Initialisation des colonnes de parcelles.
     * Il y a une traduction sur les listes fixes de cultures, exploitations et variétés
     */
    initialiseParcelColumns(parcelIdFilteredList) {

        const { parcelDico } = this.props;
        let newColumns = [];
        if (parcelIdFilteredList && parcelIdFilteredList[0]) {

            /* ↓ ajout colonne non visible - id de la parcelle ↓ */
            newColumns.push({
                headerName: "Id",
                field: "id",
                hideable: false,
            });

            /* ↓ ajout colonne visible - (Miniature + nom de la parcelle) ↓ */
            newColumns.push({
                headerName: `${StringTranslate.nomcolumn}`,
                field: "nom",
                minWidth: 180,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.nomcolumn}</Typography>)
                },
                renderCell: params => (
                    <Box style={{ display: "flex", flexDirection: "row", alignItems: "center"  }} onClick={() => this.onClickForBiomass([params.row.id])} >
                        {(params) ? (<ThumbnailParcelShapeFromPathInfos id={`listItemTbl_${params.row.parcel.id}`} {...params.row.thumbnailInfos} />) : (undefined)}
                        <Typography>{params.value}</Typography>
                    </Box>
                ),
            });

            /* ↓ ajout colonne visible - (booléen parcelle ayant une entrée d'hiver, puis sortie d'hiver) ↓ */
            newColumns.push({
                headerName: `${StringTranslate.parcelHasBeginningWinter}`,
                field: "hasBeginningWinter",
                minWidth: 110,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.parcelHasBeginningWinter}</Typography>)
                },
                renderCell: params => (
                    <div onClick={() => this.onClickForBiomass([params.row.id])}>
                        {(params.value === true) ?
                            <DoneIcon style={{ color: theme.palette.success.main }} />
                            : null}
                    </div>),
            });
            newColumns.push({
                headerName: `${StringTranslate.parcelHasEndingWinter}`,
                field: "hasEndingWinter",
                minWidth: 110,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.parcelHasEndingWinter}</Typography>)
                },
                renderCell: params => (
                    <div onClick={() => this.onClickForBiomass([params.row.id])}>
                        {(params.value === true) ?
                            <DoneIcon style={{ color: theme.palette.success.main }} />
                            : null}
                    </div>),
            });


            /* ↓ ajout colonnes visibles - (détails décrivant chaque parcelle) ↓ */
            const firstParcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelIdFilteredList[0]);
            if (firstParcel && firstParcel.details) {
                const dico = dicoFunction();
                for (let detail in firstParcel.details) {
                    dico.forEach(column => {
                        if (detail === column.name) {
                            if (column.name === "culture" || column.name === "culturePrecedente") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    minWidth: 150,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "exploitation") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    minWidth: 150,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "dateSemi") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    width: 110,
                                    type: "date",
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "campagne") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    width: 100,
                                    type: "date",
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "surface") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    width: 100,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else if (column.name === "variete") {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    minWidth: 150,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                            else {
                                newColumns.push({
                                    headerName: column.libeleColumn,
                                    field: column.name,
                                    minWidth: 120,
                                    hideable: false,
                                    renderHeader: (params) => {
                                        return (<Typography fontWeight='bold' >{column.libeleColumn}</Typography>)
                                    },
                                });
                            }
                        }
                    });
                }
            }
        }
        return newColumns;
    }

    /* fonction permettant d'extraire les données qui seront utilisées dans le visuel pour les parcelles */
    initialiseParcelDatas(parcelIdFilteredList) {
        const { parcelDico, thumbnailParcelDico, parcelIdSelected } = this.props;
        let parcels = ParcelsHelper.getParcelsDicoFromParcelIdList(parcelDico, parcelIdFilteredList);
        let newDatas = [];

        for (var itemPropName in parcels) {
            let temp = [];
            const parcel = parcels[itemPropName];

            if (parcel) {
                const thumbnailParcelItem = ParcelsHelper.selectParcelFromDicoById(thumbnailParcelDico, parcel.id);
                temp.push(parcel.id); //pour l'update du nom de la parcelle             
                temp.push(parcel.name);

                if (parcel.details) {
                    for (let itemDetailName in parcel.details) {
                        if (itemDetailName.toLowerCase() !== 'campagne') {
                            const propValue = parcel.details[itemDetailName];
                            let value = (propValue) ? propValue : '';
                            if (itemDetailName.toLowerCase() === 'datesemi') {
                                if (propValue && (propValue instanceof (Date))) {
                                    value = DateHelper.formati18n(propValue, 'P');
                                }
                                else if (propValue && (DateHelper.getDateFromString(propValue) instanceof Date)) {
                                    value = DateHelper.formati18n(new Date(propValue), 'P');
                                }
                            }
                            else if (itemDetailName.toLowerCase() === 'surface') {
                                if(parcel.area && (parcel.area > 0)) {
                                    value = parcel.area;
                                }
                            }
                            temp.push(value);
                        }
                    }
                }

                newDatas.push({
                    id: parcel.id,
                    parcel: parcel,
                    nom: parcel.name,
                    hasBeginningWinter: (parcel.imagSourceDateBeginningWinter !== undefined) && (parcel.imagSourceDateBeginningWinter !== null),
                    hasEndingWinter: (parcel.imagSourceDateEndingWinter !== undefined) && (parcel.imagSourceDateEndingWinter !== null),
                    culture: temp.length < 3 ? null : temp[2],
                    culturePrecedente: temp.length < 4 ? null : temp[3],
                    dateSemi: temp.length < 5 ? null : temp[4],
                    exploitation: temp.length < 6 ? null : temp[5],
                    surface: temp.length < 7 ? null : temp[6],
                    variete: temp.length < 8 ? null : temp[7],
                    tableData: { checked: parcelIdSelected === parcel.id ? true : false },
                    thumbnailInfos: thumbnailParcelItem,
                });
            }
        }
        let parcelToChange = [];

        if (parcelIdSelected > 0 && parcelIdFilteredList.includes(parcelIdSelected)) {
            parcelToChange = newDatas[parcelIdFilteredList.indexOf(parcelIdSelected)];
        }
        newDatas = ParcelsHelper.sortByCulture(newDatas, "Colza");
        // on met le champ selectionné en première position de la liste de parcels pour qu'il soit le premier dans le tableau

        if (parcelIdSelected > 0 && parcelIdFilteredList.includes(parcelIdSelected)) {
            newDatas.splice(newDatas.indexOf(parcelToChange), 1);
            newDatas.splice(0, 0, parcelToChange);
        }

        return newDatas;
    }

    /* Redirection vers l'étape MAP avoir sélectionné le(s) parcelle(s) pour commencer la pesée de biomasse colza 
       puis cliqué sur le bouton "Commencer la pesée de colza" */
    onClickForBiomass(data) {
        const { clearNewBiomassCounter, goToNextStepOfBiomass } = this.props;

        // On ré-initialise le compteur de bonne génération complète:
        clearNewBiomassCounter();

        // puis, on passe à la première étape de la génération d'une pesée de colza (pour la première parcelle parmis celles sélectionnées):
        let readableMode = false;
        let newParcelIdsSelected = [];

        data.forEach(element => {
            newParcelIdsSelected.push(element);
        });

        // Etape de la carte pour le choix de la date de l'image
        goToNextStepOfBiomass(BiomassStep.MAP, newParcelIdsSelected, readableMode);
    }

    handleGotoProfil() {
        const { showProfilMenuDialog } = this.props;

        if (window.dataLayer) {// google tag manager
            window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'incitationProfilPage' });
        }

        /* ↓ demande le changementde page, au profit de l'écran des abonnements */
        if (showProfilMenuDialog) {
            showProfilMenuDialog(ProfilIndex.abonnements);
        }
    }

    //Toolbar customisé du tableau comprenant :
    // - la zone de recherche
    // - le bouton d'accès à la pesée de colza
    CustomToolbar(props) {
        return (
            <Grid container spacing={2} >
                {/* Zone de recherche */}
                <Grid item xs={12}>
                    <Grid container spacing={1} sx={{alignItems:"center"}}>
                        <Grid item xs={10} sm={8} md={4} lg={4}>
                            <TextField
                                variant="outlined"
                                size="small"
                                value={props.value}
                                onChange={props.onChange}
                                placeholder={StringTranslate.searchPlaceholder}
                                InputProps={{
                                    startAdornment: <SearchIcon fontSize="small" />,
                                    endAdornment: (
                                        <IconButton
                                            title="Clear"
                                            aria-label="Clear"
                                            size="small"
                                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                                            onClick={props.clearSearch}
                                        >
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Grid>

                        <Grid item xs={2} sm={4} md={8}>
                            <Box sx={{ display: { md: 'block', xs: 'none' }, '& button': { m: 1 } }} style={{ textAlign: 'end' }} >
                                <Button
                                    /*  className="btn-modul-start" */
                                    variant={props.parcelsSelected.length > 0 ? "contained" : "outlined"}
                                    color="primary"
                                    onClick={() => props.startBiomass(props.parcelsSelected)}
                                    disabled={props.parcelsSelected.length > 0 ? false : true}>
                                    {`${StringTranslate.startBiomass}`}
                                </Button>
                            </Box>
                            <Box sx={{ display: { md: 'none', xs: 'block' }, '& button': { m: 1 } }} style={{ textAlign: 'end' }} >
                                <div>
                                    <IconButton 
                                        color="primary" 
                                        sx={{
                                            bgcolor:theme.palette.primary.main,
                                            color:theme.palette.common.white
                                        }}
                                        size="large" 
                                        aria-label="start biomass(es)" 
                                        component="span"
                                        onClick={() => props.startBiomass(props.parcelsSelected)}
                                        disabled={props.parcelsSelected.length > 0 ? false : true}>
                                        <PlayArrowIcon />
                                    </IconButton>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Bouton d'aide */}
                <Grid item xs={6}>
                    <Button color="secondary" variant="text" onClick={() => props.showProfilMenuDialog()}>
                        {StringTranslate.helpAsk}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    /* Fonction correspondant à la zone de recherche uniquement sur la culture */
    requestSearch(searchValue, rowsOfTable) {
        this.setState({
            searchFilter: searchValue
        });

        try {
            const searchRegex = new RegExp(stringHelper.escapeRegExp(searchValue), 'i');
            const filteredRows = rowsOfTable.filter((row) => {
                return Object.keys(row).some((field) => {
                    let textValue = "";
                    if (field !== "culturePrecedente") {
                        textValue = textValue + row[field];
                    }

                    return searchRegex.test(textValue.toString());
                });
            });

            this.setState({
                currentRowsOfTable: filteredRows
            });
        }
        catch(errRegex) { /* Peut arriver si on saisis un truc du genre 'EARL++' ! (il n'aime pas les '++') */ }
    }

    //Sélection des parcelles pour commencer la pesée de colza
    setSelectionParcels = (newSelectionParcels) => {
        this.setState({
            parcelsSelected: newSelectionParcels
        });
    }

    ///////////////////////////////////////////////////////////////////////////
    // fonction de rendu visuel - layout général
    ///////////////////////////////////////////////////////////////////////////
    render() {
        const { parcelDatas, currentRowsOfTable, searchFilter, parcelsSelected } = this.state; // etat du dialog popup
        const { authorizeBiomass, showProfilMenuDialog, rowsPerPageForTableBiomasses, updateNbRowsPerPageTableBiomasses } = this.props;

        return (
            /* Si l'utilisateur a le droit d'accès à la fonctionnalité => ok ; sinon, message ! */
            (authorizeBiomass === true) ?
                (<TableContainer>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Card>
                                <Alert
                                    severity="info"
                                    icon={<InfoOutlined />}
                                >
                                    <Typography>{StringTranslate.biomassesCertifTerresInovia}</Typography>
                                </Alert>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <Alert
                                    severity="info"
                                    icon={<InfoOutlined />}
                                >
                                    <Typography>{StringTranslate.biomassesHowToSelectDates}</Typography>
                                </Alert>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid style={{ width: '100%' }} item xs={12}>

                        <Grid item xs={12}>
                            <CustomDataGrid
                                tableName={TableType.parcels}
                                pageSize={rowsPerPageForTableBiomasses}
                                updateNbRowsPerPageTable={updateNbRowsPerPageTableBiomasses}
                                onSelectionModelChange={(newSelectionParcels) => { //Sélection des parcelles par clic checkbox
                                    this.setSelectionParcels(newSelectionParcels);
                                }}
                                keepNonExistentRowsSelected={searchFilter !== "" ? true : false}
                                selectionModel={parcelsSelected}
                                Toolbar={this.CustomToolbar}
                                toolbar={{
                                    showProfilMenuDialog: () => showProfilMenuDialog(ProfilIndex.aide_PeseeDeBiomasse),
                                    parcelsSelected: parcelsSelected,
                                    startBiomass: () => this.onClickForBiomass(parcelsSelected),
                                    formatStringButton: (generalText, textForOneItem, data) => this.formatStringButton(generalText, textForOneItem, data),
                                    parcelDatas: parcelDatas,
                                    //Concerne la zone de recherche dans le tableau :
                                    value: searchFilter,
                                    onChange: (event) => this.requestSearch(event.target.value, parcelDatas),
                                    clearSearch: () => this.requestSearch('', parcelDatas)
                                }}
                                rows={(searchFilter !== "") ? currentRowsOfTable : parcelDatas}
                                columns={this.columns}
                                checkBoxActive={true}
                            />
                        </Grid>

                    </Grid>
                </TableContainer>) :
                (<>
                    <CardContent>
                        {/* partie message */}
                        <Typography variant="subtitle1">{StringTranslate.invitationPremium}</Typography>
                        <Typography variant="subtitle2">{StringTranslate.invitationPremiumPlusSolutionBiomass}</Typography>
                    </CardContent>
                    <CardActions>
                        <Button
                            color="primary" variant="contained"
                            onClick={this.handleGotoProfil.bind(this)}>
                            {StringTranslate.incitationendoftrialok}
                        </Button>
                    </CardActions>
                </>)
        )
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = function (state) {
    return {
        //Infos provenant du reducer 'contextApp':
        parcelIdSelected: lodashGet(state, 'contextAppData.parcelIdSelected', -1),

        //Infos provenant du reducer 'biomasses':
        biomassDicoCounter: lodashGet(state, 'biomassData.biomassDicoCounter', 0),
        biomassDico: lodashGet(state, 'biomassData.biomassDico', {}),

        //Infos provenant du reducer 'parcels':
        parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
        updatingParcelInfos: lodashGet(state, 'parcelsData.updatingParcel', false),
        updatingParcelName: lodashGet(state, 'parcelsData.updatingParcelName', false),
        parcelIdFilteredList: lodashGet(state, 'parcelsData.parcelIdFilteredList', []),
        parcelIdFilteredListCounter: lodashGet(state, 'parcelsData.parcelIdFilteredListCounter', 0),
        parcelDicoCounter: lodashGet(state, 'parcelsData.parcelDicoCounter', 0),
        thumbnailParcelDico: lodashGet(state, 'parcelsData.thumbnailParcelDico', {}),
        
        //Infos provenant du reducer 'settings':
        rowsPerPageForTableBiomasses: lodashGet(state, 'settingsData.settings.rowsPerPageForTableBiomasses', 20),
        
        //Infos provenant du reducer 'clientUser':
        authorizeBiomass: lodashGet(state, 'clientUserData.clientDatas.authorizeBiomass', true),
    };
}

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
    showProfilMenuDialog: (index) => dispatch(ActionShowProfilMenuDialog(index)),
    goToNextStepOfBiomass: (nextStepOfBiomass, parcelIdsSelected, readableMode, biomassSelectedItemValue = undefined) =>
        dispatch(ActionGoToNextStepOfBiomass(nextStepOfBiomass, parcelIdsSelected, readableMode, biomassSelectedItemValue)),
    clearNewBiomassCounter: () => dispatch(ActionClearNewBiomassCounter()),
    updateNbRowsPerPageTableBiomasses: (rowsPerPage) => dispatch(UpdateNbRowsPerPageTable(rowsPerPage, TableType.biomasses)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChoixParcelsBiomass);
