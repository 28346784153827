/**
 * Helper pour la manipulation des données de tutoriel
 */
export const DidacticielFirstParcelHelper = {
    
    /**
     * fonction permettant de passer d'une liste (issu d'un appel à la webAPI) à un dico - le concept de normalisation des données est ici utilisé
     */
    convertToDico(tutorialList) {
        if (!tutorialList || !Array.isArray(tutorialList)) return {};

        let dico = {};
        tutorialList.forEach(tutorialData => {
            if (tutorialData) {
                dico[tutorialData.title] = tutorialData;
            }
        });

        return dico;
    },

    /**
     * Fonction permettant suivant des données en entrée d'appliquer les RÈGLES DE GESTION lié à l'affichage du didacticiel
     */
    canShowTutorial(datas) {
        if (!datas) return false;

        // ↓ CAS AFFICHAGE DU DIDACTICIEL ↓
        /* si c'est un nouvel utilisateur */
        if (datas.clientIsNewer === true)
            return true;

        /* si l'utilisateur n'a pas de parcelle et qu'il n'a jamais commencé ou réalisé/terminé le didacticiel */
        if ((datas.parcelDicoCounter <= 0) && ((!datas.currentDidacticielFirstParcel) || (datas.currentDidacticielFirstParcel.counterCompletedTutorial <= 0)))
            return true;

        /* si l'utilisateur est un utilisateur geofolia et qu'il n'a pas de parcelle et qu'il n'a jamais commencé le didacticiel - est ce nécessaire ? */
        // if ((datas.domainIdPv > 0) && (datas.parcelDicoCounter <= 0) && (!datas.currentDidacticielFirstParcel))
        //     return true;

        // ↓ DEFAUT - NON-AFFICHAGE DU DIDACTICIEL ↓
        return false;
    },
}
