import lodashGet from 'lodash/get';
import { ActionSelectParcel, ActionSelectDateImg } from '../actions/contextApp';
import { ActionSelectLayerOfParcelForFertilizer, 
    ActionUpdateParcelCulture, ActionUpdateLocalisation, ActionUpdateParcelCropYear, ActionUpdateParcelCrop,
    ActionLoadParcelArchivedForFertilizer, ActionDeleteParcelArchivedIdList } from '../actions/parcels';
import { ActionAddTheLastSelectedSatImagesToSetOnMap } from '../actions/satImage.js';
import { ActionGetAllLastNitrogenInputs } from '../actions/lastNitrogenInput';

import { fertilizerWebApiProvider, parcelWebApiProvider } from '../../utils/webApiProvider.js';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';// et utilisé comme ceci: ${StringTranslate.xxx}
import { IsNativeHoster } from '../../utils/platformHelper';
import fertilizerHelper from '../../utils/fertilizerHelper.js';
import ConstantsLayers from '../../utils/constantsOfLayers';
import lodashIsEmpty from 'lodash/isEmpty';


export const ERROR_ASK_FERTILIZER_DATAS = 'ERROR_ASK_FERTILIZER_DATAS';
export const END_FERTILIZER_DATAS_UPDATE = 'END_FERTILIZER_DATAS_UPDATE';
export const GO_TO_STEP_OF_FERTILIZER = 'GO_TO_STEP_OF_FERTILIZER';
export const SELECT_PARCELS_FOR_FERTILIZING = 'SELECT_PARCELS_FOR_FERTILIZING';
export const SHOW_FERTILIZER = 'SHOW_FERTILIZER';
export const GET_CULTURES = 'GET_CULTURES';
export const ERROR_LOAD_CULTURES = 'ERROR_LOAD_CULTURES';
export const CLEAN_ERROR = 'CLEAN_ERROR';
export const CLEAR_FERTILIZER_VALUE = 'CLEAR_FERTILIZER_VALUE';
export const GENERATING_FERTILIZER_FILE = 'GENERATING_FERTILIZER_FILE';
export const FERTILIZER_FILE_GENERATED = 'FERTILIZER_FILE_GENERATED';
export const ASKING_COMMON_INFOS_AND_LISTS_BY_CULTURE = 'ASKING_COMMON_INFOS_AND_LISTS_BY_CULTURE';
export const COMMON_INFOS_AND_LISTS_BY_CULTURE_ASKED = 'COMMON_INFOS_AND_LISTS_BY_CULTURE_ASKED';
export const GET_COMMON_INFOS_BY_CULURE = 'GET_COMMON_INFOS_BY_CULURE';
export const SAVING_COMMON_INFOS = 'SAVING_COMMON_INFOS';
export const COMMON_INFOS_SAVED = 'COMMON_INFOS_SAVED';
export const FERTILIZER_SPECIFIC_LISTS_ASKED = 'FERTILIZER_SPECIFIC_LISTS_ASKED';
export const FERTILIZER_SPECIFIC_LISTS_ASKING = 'FERTILIZER_SPECIFIC_LISTS_ASKING';
export const GET_SPECIFIC_INFOS_TYPEDESOL = 'GET_SPECIFIC_INFOS_TYPEDESOL';
export const GET_SPECIFIC_INFOS_VARIETE = 'GET_SPECIFIC_INFOS_VARIETE';
export const GET_SPECIFIC_INFOS_VARIETE_PROT = 'GET_SPECIFIC_INFOS_VARIETE_PROT';
export const ASK_SPECIFIC_INFOS_TYPEDESOL = 'ASK_SPECIFIC_INFOS_TYPEDESOL';
export const ASK_SPECIFIC_INFOS_VARIETE = 'ASK_SPECIFIC_INFOS_VARIETE';
export const SELECT_LAYER_FOR_FERTILIZER = 'SELECT_LAYER_FOR_FERTILIZER';
export const SELECT_BEG_END_WINTER_DATES_BY_PARCELID = 'SELECT_BEG_END_WINTER_DATES_BY_PARCELID';
export const SET_STEP_DATE_WINTER = 'SET_STEP_DATE_WINTER';
export const INIT_FERTILIZER_LISTS = 'INIT_FERTILIZER_LISTS';
export const INIT_FERTILIZER_COMMON_LISTS  = 'INIT_FERTILIZER_COMMON_LISTS ';
export const INIT_SPECIFIC_FERTILIZER_LISTS = 'INIT_SPECIFIC_FERTILIZER_LISTS';
export const SET_FERTILIZER_DICO = 'SET_FERTILIZER_DICO';
export const UPDATE_SPECIFIC_INFO = 'UPDATE_SPECIFIC_INFO';
export const INIT_FERTILIZER_DATAS = 'INIT_FERTILIZER_DATAS';
export const SELECT_CULTURE = 'SELECT_CULTURE';

export const SHOW_SPEC_INFOS_MANAGEMENT = 'SHOW_SPEC_INFOS_MANAGEMENT';
export const CLOSE_SPEC_INFOS_MANAGEMENT = 'CLOSE_SPEC_INFOS_MANAGEMENT';

export const DELETING_FERTILIZER = 'DELETING_FERTILIZER';
export const DELETING_FERTILIZERS = 'DELETING_FERTILIZERS';
export const DELETE_FERTILIZER = 'DELETE_FERTILIZER';
export const DELETE_FERTILIZERS = 'DELETE_FERTILIZERS';

export const LOADING_FERTILIZERS = 'LOADING_FERTILIZERS';
export const LOAD_FERTILIZERS = 'LOAD_FERTILIZERS';
export const ERROR_LOAD_FERTILIZERS = 'ERROR_LOAD_FERTILIZERS';

export const UP_NEW_FERTILIZER = 'UP_NEW_FERTILIZER';
export const SELECT_FERTILIZER = 'SELECT_FERTILIZER';
export const ERROR_BUILD_FERTILIZER = 'ERROR_BUILD_FERTILIZER';
export const START_BUILD_FERTILIZER = 'START_BUILD_FERTILIZER';
export const END_BUILD_FERTILIZER = 'END_BUILD_FERTILIZER';

export const SAVING_SPECIFIC_INFOS = 'SAVING_SPECIFIC_INFOS';
export const SPECIFIC_INFOS_SAVED = 'SPECIFIC_INFOS_SAVED';

export const REGISTER_WINTER_DATE = 'REGISTER_WINTER_DATE';

export const UPDATING_CROP_IN_PARCEL = 'UPDATING_CROP_IN_PARCEL';
export const CROP_IN_PARCEL_UPDATED = 'CROP_IN_PARCEL_UPDATED';
export const ERROR_UPDATE_CROP_IN_PARCEL = 'ERROR_UPDATE_CROP_IN_PARCEL';

export const UPDATING_DOSE_SAVED_AND_ZONES = 'UPDATING_DOSE_SAVED_AND_ZONES';
export const DOSE_SAVED_AND_ZONES_UPDATED = 'DOSE_SAVED_AND_ZONES_UPDATED';
export const ERROR_UPDATE_DOSE_SAVED_AND_ZONES = 'ERROR_UPDATE_DOSE_SAVED_AND_ZONES';
export const UPDATE_DOSE_SAVED_AND_ZONES = 'UPDATE_DOSE_SAVED_AND_ZONES';

export const SET_VALUE_TAB_FOR_ABSTRACT_FERTILIZER = 'SET_VALUE_TAB_FOR_ABSTRACT_FERTILIZER';

export const DOWNLOADING_FERTILIZER_FOR_PRESCRIPTION = 'DOWNLOADING_FERTILIZER_FOR_PRESCRIPTION';
export const DOWNLOADED_FERTILIZER_FOR_PRESCRIPTION = 'DOWNLOADED_FERTILIZER_FOR_PRESCRIPTION';

export const ASKING_AZOFERT_DATES_RANGE = 'ASKING_AZOFERT_DATES_RANGE';
export const INIT_AZOFERT_DATES_RANGE = 'INIT_AZOFERT_DATES_RANGE';

export const SET_CROPYEARSSELECTED_FOR_VALIDATED_FERTILIZER = 'SET_CROPYEARSSELECTED_FOR_VALIDATED_FERTILIZER';

export const ASKING_SPECIFICINFOS_ARCHIVED = 'ASKING_SPECIFICINFOS_ARCHIVED';
export const SPECIFICINFOS_ARCHIVED_ASKED = 'SPECIFICINFOS_ARCHIVED_ASKED';
export const SELECT_SPECIFICINFOS_ARCHIVED = 'SELECT_SPECIFICINFOS_ARCHIVED';
export const ERROR_ASK_FERTILIZER_ARCHIVED_DATAS = 'ERROR_ASK_FERTILIZER_ARCHIVED_DATAS';
export const ADD_FERTILIZER_ARCHIVEDDATA = 'ADD_FERTILIZER_ARCHIVEDDATA';

export const SELECT_FERTILIZER_ENGINE = 'SELECT_FERTILIZER_ENGINE';
export const ERROR_ASK_FERTILIZER_ENGINE = 'ERROR_ASK_FERTILIZER_ENGINE';
export const ASKING_FERTILIZER_ENGINE = 'ASKING_FERTILIZER_ENGINE';

export const ASKING_PARCEL_VARIETY_PENDING = 'ASKING_PARCEL_VARIETY';
export const SET_CURRENT_PARCEL_VARIETY = 'SET_CURRENT_PARCEL_VARIETY';
export const SET_PARCEL_VARIETY_PENDING = 'SET_PARCEL_VARIETY_PENDING';

export const UPDATE_HAS_LAST_NITROGEN_INPUT = 'UPDATE_HAS_LAST_NITROGEN_INPUT';

export const ActionTypeFertilizer = {
    ERROR_ASK_FERTILIZER_DATAS: ERROR_ASK_FERTILIZER_DATAS,
    EndFertilizerUpdate: END_FERTILIZER_DATAS_UPDATE,
    GO_TO_STEP_OF_FERTILIZER: GO_TO_STEP_OF_FERTILIZER,
    SHOW_SPEC_INFOS_MANAGEMENT: SHOW_SPEC_INFOS_MANAGEMENT,
    SHOW_FERTILIZER: SHOW_FERTILIZER,
    closeSpecificInfosManagement: CLOSE_SPEC_INFOS_MANAGEMENT,
    SELECT_PARCELS_FOR_FERTILIZING: SELECT_PARCELS_FOR_FERTILIZING,
    GET_CULTURES: GET_CULTURES,
    ERROR_LOAD_CULTURES: ERROR_LOAD_CULTURES,
    CLEAN_ERROR: CLEAN_ERROR,
    CLEAR_FERTILIZER_VALUE: CLEAR_FERTILIZER_VALUE,
    GENERATING_FERTILIZER_FILE: GENERATING_FERTILIZER_FILE,
    FERTILIZER_FILE_GENERATED: FERTILIZER_FILE_GENERATED,
    ASKING_COMMON_INFOS_AND_LISTS_BY_CULTURE: ASKING_COMMON_INFOS_AND_LISTS_BY_CULTURE,
    COMMON_INFOS_AND_LISTS_BY_CULTURE_ASKED: COMMON_INFOS_AND_LISTS_BY_CULTURE_ASKED,
    GET_COMMON_INFOS_BY_CULURE: GET_COMMON_INFOS_BY_CULURE,
    SAVING_COMMON_INFOS: SAVING_COMMON_INFOS,
    COMMON_INFOS_SAVED: COMMON_INFOS_SAVED,
    FERTILIZER_SPECIFIC_LISTS_ASKING: FERTILIZER_SPECIFIC_LISTS_ASKING,
    FERTILIZER_SPECIFIC_LISTS_ASKED: FERTILIZER_SPECIFIC_LISTS_ASKED,
    GET_SPECIFIC_INFOS_TYPEDESOL: GET_SPECIFIC_INFOS_TYPEDESOL,
    ASK_SPECIFIC_INFOS_VARIETE: ASK_SPECIFIC_INFOS_VARIETE,
    ASK_SPECIFIC_INFOS_TYPEDESOL: ASK_SPECIFIC_INFOS_TYPEDESOL,
    GET_SPECIFIC_INFOS_VARIETE: GET_SPECIFIC_INFOS_VARIETE,
    GET_SPECIFIC_INFOS_VARIETE_PROT: GET_SPECIFIC_INFOS_VARIETE_PROT,
    SELECT_LAYER_FOR_FERTILIZER: SELECT_LAYER_FOR_FERTILIZER,
    SELECT_BEG_END_WINTER_DATES_BY_PARCELID: SELECT_BEG_END_WINTER_DATES_BY_PARCELID,
    SET_STEP_DATE_WINTER: SET_STEP_DATE_WINTER,
    INIT_FERTILIZER_LISTS: INIT_FERTILIZER_LISTS,
    INIT_FERTILIZER_COMMON_LISTS: INIT_FERTILIZER_COMMON_LISTS,
    INIT_SPECIFIC_FERTILIZER_LISTS: INIT_SPECIFIC_FERTILIZER_LISTS,
    SET_FERTILIZER_DICO: SET_FERTILIZER_DICO,
    UPDATE_SPECIFIC_INFO: UPDATE_SPECIFIC_INFO,
    INIT_FERTILIZER_DATAS: INIT_FERTILIZER_DATAS,
    DELETING_FERTILIZER: DELETING_FERTILIZER,
    DELETING_FERTILIZERS: DELETING_FERTILIZERS,
    DELETE_FERTILIZER: DELETE_FERTILIZER,
    DELETE_FERTILIZERS: DELETE_FERTILIZERS,
    LOADING_FERTILIZERS: LOADING_FERTILIZERS,
    LOAD_FERTILIZERS: LOAD_FERTILIZERS,
    ERROR_LOAD_FERTILIZERS: ERROR_LOAD_FERTILIZERS,
    UP_NEW_FERTILIZER: UP_NEW_FERTILIZER,
    SELECT_FERTILIZER: SELECT_FERTILIZER,
    START_BUILD_FERTILIZER: START_BUILD_FERTILIZER,
    END_BUILD_FERTILIZER: END_BUILD_FERTILIZER,
    SAVING_SPECIFIC_INFOS: SAVING_SPECIFIC_INFOS,
    SPECIFIC_INFOS_SAVED: SPECIFIC_INFOS_SAVED,
    REGISTER_WINTER_DATE: REGISTER_WINTER_DATE,
    UPDATING_CROP_IN_PARCEL: UPDATING_CROP_IN_PARCEL,
    CROP_IN_PARCEL_UPDATED: CROP_IN_PARCEL_UPDATED,
    ERROR_UPDATE_CROP_IN_PARCEL: ERROR_UPDATE_CROP_IN_PARCEL,
    SELECT_CULTURE: SELECT_CULTURE,
    ERROR_BUILD_FERTILIZER: ERROR_BUILD_FERTILIZER,
    UPDATING_DOSE_SAVED_AND_ZONES: UPDATING_DOSE_SAVED_AND_ZONES,
    DOSE_SAVED_AND_ZONES_UPDATED: DOSE_SAVED_AND_ZONES_UPDATED,
    ERROR_UPDATE_DOSE_SAVED_AND_ZONES: ERROR_UPDATE_DOSE_SAVED_AND_ZONES,
    UPDATE_DOSE_SAVED_AND_ZONES: UPDATE_DOSE_SAVED_AND_ZONES,
    SET_VALUE_TAB_FOR_ABSTRACT_FERTILIZER: SET_VALUE_TAB_FOR_ABSTRACT_FERTILIZER,
    DOWNLOADING_FERTILIZER_FOR_PRESCRIPTION: DOWNLOADING_FERTILIZER_FOR_PRESCRIPTION,
    DOWNLOADED_FERTILIZER_FOR_PRESCRIPTION: DOWNLOADED_FERTILIZER_FOR_PRESCRIPTION,
    INIT_AZOFERT_DATES_RANGE: INIT_AZOFERT_DATES_RANGE,
    ASKING_AZOFERT_DATES_RANGE: ASKING_AZOFERT_DATES_RANGE,
    SET_CROPYEARSSELECTED_FOR_VALIDATED_FERTILIZER: SET_CROPYEARSSELECTED_FOR_VALIDATED_FERTILIZER,
    ASKING_SPECIFICINFOS_ARCHIVED: ASKING_SPECIFICINFOS_ARCHIVED,
    SPECIFICINFOS_ARCHIVED_ASKED: SPECIFICINFOS_ARCHIVED_ASKED,
    SELECT_SPECIFICINFOS_ARCHIVED: SELECT_SPECIFICINFOS_ARCHIVED,
    ERROR_ASK_FERTILIZER_ARCHIVED_DATAS: ERROR_ASK_FERTILIZER_ARCHIVED_DATAS,
    ADD_FERTILIZER_ARCHIVEDDATA: ADD_FERTILIZER_ARCHIVEDDATA,
    ERROR_ASK_FERTILIZER_ENGINE: ERROR_ASK_FERTILIZER_ENGINE,
    SELECT_FERTILIZER_ENGINE: SELECT_FERTILIZER_ENGINE,
    ASKING_FERTILIZER_ENGINE: ASKING_FERTILIZER_ENGINE,

    SET_CURRENT_PARCEL_VARIETY: SET_CURRENT_PARCEL_VARIETY,
    ASKING_PARCEL_VARIETY_PENDING: ASKING_PARCEL_VARIETY_PENDING,
    SET_PARCEL_VARIETY_PENDING: SET_PARCEL_VARIETY_PENDING,

    UPDATE_HAS_LAST_NITROGEN_INPUT: UPDATE_HAS_LAST_NITROGEN_INPUT,
};

/* Constantes associées aux culture Spotifarm (de fumure): */ //TODO : Vraiment pas top ! Idéalement, il faudrait pouvoir s'affranchir de toutes les données BdDs !
const CodePZCultureBle = "1";
const CodePZCultureOrge = "2";
//const CodePZCultureColza = "3"; //non exploiter pour le moment !
const CodePZCultureBleDur = "4";

/* Constantes associées aux objectifs de production Azofert: */
const CodeObjectifRendementDefault = "1";
const CodeObjectifRendementProtein = "2";

/* Les différentes étapes de la fumure */
export const FertilizerStep = {
    CULTURE_AND_PARCELS_CHOICE: 'CULTURE_AND_PARCELS_CHOICE',
    COMMON_INFOS_MANAGEMENT: 'COMMON_INFOS_MANAGEMENT',
    MANAGEMENT_NITROGEN: 'MANAGEMENT_NITROGEN',
    RESULT: 'RESULT',
};

/* Les 2 tabs de abstract fertilizer */
export const TabAbstractFertilizer = {
    NITROGEN_MANAGEMENT: 0,
    FERTILIZERS_LIST: 1,
    SELECT_FOR_LASTINPUT: 2, 
    LASTINPUT_LIST: 3,
}

/* Constantes moteur de fumure */
export const FertilizerEngineEnum = {
    UNKNOWN: "Unknown",
    AZOFERT: "Azofert",
    FERTIWEB: "Fertiweb",
    GEOFOLIA: "Geofolia",
}

export const ActionErrorAskFertilizerDatas = (errorValue) => {
    return {
        type: ERROR_ASK_FERTILIZER_DATAS,
        error: errorValue
    }
}

export const ActionEndFertilizerUpdate = () => {
    return {
        type: END_FERTILIZER_DATAS_UPDATE,
    }
}

export const ActionCleanErrorFertilizer = () => ({
    type: CLEAN_ERROR,
})

/* Initialisation (remise à zéro des données concernant la fumure */
export function ActionInitFertilizerDatas() {
    return {
        type: INIT_FERTILIZER_DATAS,
    }
}

/* Obtention des cultures pour commencer la fumure */
export function ActionInitCultures() {

    return function (dispatch, getState, { userAgentAuthent }) {
        fertilizerWebApiProvider.loadCultures(dispatch, getState) //demande la liste des cultures pour commencer la fumure !
            .then((respDatas) => {
                dispatch({
                    type: GET_CULTURES,
                    culturesForFertilizing: respDatas
                });
                    
            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorLoadCultures(error));
            });
    }
}

/* Obtention de la plage de dates de clôture de Azofert */
export function ActionInitAzofertDatesRange() {

    return function (dispatch, getState, { history, userAgentAuthent }) {

        dispatch( ActionAskingAzofertDatesRange() );

        fertilizerWebApiProvider.loadAzofertDatesRange(dispatch, getState)
            .then((response) => { //ok ! sinon, on aurai eu une erreur....
                //RAS !
                if ((response !== undefined) && (response !== null)) {
                    dispatch({
                        type: INIT_AZOFERT_DATES_RANGE,
                        closingDateAzofert: new Date(response.closingDate),
                        openingDateAzofert: new Date(response.openingDate),
                    });
                }
                else {
                    //Avertit du mauvais retour:
                    dispatch(ActionErrorAskFertilizerDatas(StringTranslate.badLoadFertilizerDatas));
                }
            })
            .catch((error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorAskFertilizerDatas(error));
            });
    }
}

/*export*/ function ActionAskingAzofertDatesRange() {
    return {
        type: ASKING_AZOFERT_DATES_RANGE,
        askingAzofertDatesRange: true,
    };
}

export function ActionGoToStepOfFertilizer(nextStepValue, readableModeValue) {

    return function (dispatch, getState, { history, userAgentAuthent }) {

        const currentStoreState = getState();

        const currentFertilizerStep = lodashGet(currentStoreState, 'fertilizerData.fertilizerStep', undefined);

        //tant que l'on est pas sur un retour arrière depuis l'écran de résultat (menant donc à la carto), on commencera par le choix de la date d'entrée d'hiver !
        const showStartWinderDate = ((currentFertilizerStep === FertilizerStep.RESULT) && (nextStepValue === FertilizerStep.MANAGEMENT_NITROGEN)) ? false : true; 

        //Signale le changement d'étape (avec le mode lecture seule activable sur l'étape de 'carto'):
        dispatch({
            type: GO_TO_STEP_OF_FERTILIZER,
            fertilizerStep: nextStepValue,
            askBeginningWinterFirst: showStartWinderDate,
            readableMode: 
                ( (nextStepValue === FertilizerStep.MANAGEMENT_NITROGEN) && 
                (nextStepValue === FertilizerStep.COMMON_INFOS_MANAGEMENT) && 
                (nextStepValue === FertilizerStep.CULTURE_AND_PARCELS_CHOICE) ) ? 
                    false : 
                    ((readableModeValue !== undefined) ? readableModeValue : true),
        });

        //si on revient à la carto (choix des dates d'hiver) : on présélectionne l'image de sortie d'hiver exploitée pour le conseil !
        if (showStartWinderDate === false) {
            const parcelIdSelected = lodashGet(currentStoreState, 'fertilizerData.parcelIdSelected', 
                lodashGet(currentStoreState, 'fertilizerData.fertilizerSelected.parcelId', -1));
            const satImageEndingWinterId = lodashGet(currentStoreState, 'fertilizerData.fertilizerSelected.endingWinterImagId', -1);
            //on pré-sélectionne la date de sortie d'hiver:
            dispatch( ActionSelectDateImg(satImageEndingWinterId, undefined, parcelIdSelected) );
        }
        //autre cas : on arrive sur la carto mais depuis l'écran de gestion des fumures : on présélectionne l'image d'entrée d'hiver, s'il y en avait une de renseignée...
        // mais ce cas n'est pas géré ici mais dans l'action 'ActionSelectWinterDatesByParcelId' !
    };
}

export const ActionShowSpecificInfosManagement = (paramCurrentParcel) => {
    return {
        type: SHOW_SPEC_INFOS_MANAGEMENT,
        infoCurrentSelectedParcel: paramCurrentParcel,
    };
}

export const ActionAskingFertilierEngine = () => {
    return {
        type: ASKING_FERTILIZER_ENGINE,
    };
}

/*export*/ const ActionFertilizerSpecificListsAsked = () => ({
    type: FERTILIZER_SPECIFIC_LISTS_ASKED,
})

/*export*/ const ActionFertilizerSpecificListsAsking = () => ({
    type: FERTILIZER_SPECIFIC_LISTS_ASKING,
})

/* Obtention des listes specifiques */
export function ActionGetFerilizerSpecificListsByEngine(engine, idParcel) {
    return function (dispatch, getState, {history, userAgentAuthent}) {
        
        const currentStoreState = getState();
        
        let fertilizerEngine = FertilizerEngineEnum.AZOFERT;
        if (engine === 2) {
            fertilizerEngine = FertilizerEngineEnum.FERTIWEB;
        }
        
        // Récupération des différentes listes (autres que le type de sol et les variétés)
        const fertilizerLists = lodashGet(currentStoreState, `fertilizerData.fertilizerListsDico[${fertilizerEngine}]`, undefined);
        if ((fertilizerLists === undefined) || (fertilizerLists === null)) {
            dispatch( ActionGetFertilizerLists(fertilizerEngine));
        }
        
        // Récupération des autres listes (type de sol et variété) en fonction de la culture et de l'idParcel
        const culture = lodashGet(currentStoreState, 'fertilizerData.cultureSelected.cropType', undefined);
        dispatch(ActionLoadSpecificInfosListsRequiringParcelId(idParcel, culture.toString(), fertilizerEngine));
        
    }
}

export const ActionAddFertilizerArchivedData = (archivedData) => {
    return {
        type: ADD_FERTILIZER_ARCHIVEDDATA,
        archivedDataToAdd: archivedData
    };
}

/* Obtention des données archivées en fonction du moteur de fumure */
export function ActionGetArchivedData(idParcel, fertilizerEngine) {
    return function (dispatch, getState, {history, userAgentAuthent}) {

        const currentStoreState = getState();

        // Récupération des données archivées s'il y en a
        dispatch(ActionAskingSpecificInfosArchived()); 

        //Si les données ont déjà été demandées, ne pas les redemandeer !
        const fertilizersArchivedDataDico = lodashGet(currentStoreState, 'fertilizerData.fertilizersArchivedDataDico', {});
        if ((fertilizersArchivedDataDico[idParcel] !== undefined) && (fertilizersArchivedDataDico[idParcel] !== null)) {
            if ((fertilizersArchivedDataDico[idParcel].engine === fertilizerEngine)) {
                dispatch(ActionSelectSpecificInfosArchived(fertilizersArchivedDataDico[idParcel]));
            }
            dispatch(ActionSpecificInfosArchivedAsked()); 
        }
        else {
            const closingDateAzofert = lodashGet(currentStoreState, 'fertilizerData.closingDateAzofert', undefined);
            const openingDateAzofert = lodashGet(currentStoreState, 'fertilizerData.openingDateAzofert', undefined);

            let currentCropYear = fertilizerHelper.getCurrentCropYear(closingDateAzofert, openingDateAzofert); 

            //Pour test : 
            //currentCropYear = currentCropYear + 1; 

            fertilizerWebApiProvider.LoadArchivedData(dispatch, getState, idParcel, currentCropYear -1)
            .then((archivedData) => { 
                
                //Si le moteur de fumure de la fumure archivée est identique au moteur
                if (archivedData.engine === fertilizerEngine) {
                    dispatch(ActionSelectSpecificInfosArchived(archivedData));
                }

                archivedData.parcelId = idParcel;
                // Ajout des données archivées dans le dico
                dispatch(ActionAddFertilizerArchivedData(archivedData));

                dispatch(ActionSpecificInfosArchivedAsked()); 
            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch( ActionErrorAskFertilizerArchivedDatas(error) );
            });
        }
    }
}

/* Avant d'afficher le popup en fonction du moteur de fumure :
   1 - Récupéer le moteur de fumure en fonction de l'idParcel
   2 - Obtenir les données archivées
   3 - Obtenir les listes de fumures
   4 - Afficher les données dans le popup */
export function ActionShowSpecificInfosManagementPopup(paramCurrentParcel) {
    return function (dispatch, getState, {history, userAgentAuthent}) {

        if (paramCurrentParcel.idParcel > 0) {
            //Demande de moteur de fumure en cours
            dispatch(ActionAskingFertilierEngine());

            const currentStoreState = getState();
            const fertilizerDico = lodashGet(currentStoreState, 'fertilizerData.fertilizerDico', {});

            //Conseil azoté correpondant à l'idParcel
            const fertilizer = fertilizerHelper.getFertilizerByParcelId(fertilizerDico, paramCurrentParcel.idParcel);

            let errorMessage = undefined;
            //Si la fumure a déjà son moteur de fumure de renseigné, ne pas redemander.
            //Obtention du moteur de fumure par le code postal de la parcelle :
            if ((fertilizer === undefined) || ((fertilizer !== undefined) && (fertilizer.engine < 0))) {
                fertilizerWebApiProvider.loadFertilizerEngine(dispatch, getState, paramCurrentParcel.idParcel)
                .then((engine) => { 
                    //Sert à savoir quel popup afficher (celui de Azofert ou celui de Fertiweb)
                    dispatch(ActionSelectFertilizerEngine(engine)); 

                    //Obtention des données archivées en fonction du moteur de fumure
                    dispatch(ActionGetArchivedData(paramCurrentParcel.idParcel, engine));

                    // Demande des listes de fumures en fonction du moteur de fumure
                    dispatch(ActionGetFerilizerSpecificListsByEngine(engine, paramCurrentParcel.idParcel));
                })
                .catch((error) => {
                    errorMessage = error;
                    //Avertit du mauvais retour:
                    dispatch( ActionErrorAskFertilizerEngine(error) );
                })
                .finally(() => {
                    if (errorMessage === undefined) {
                        // Affichage des données
                        dispatch(ActionShowSpecificInfosManagement(paramCurrentParcel));
                    }
                })
            }
            else {
                Promise.resolve( 
                    //Sert à savoir quel popup afficher (celui de Azofert ou celui de Fertiweb)
                    dispatch(ActionSelectFertilizerEngine(fertilizer.engine)),

                    //Obtention des données archivées en fonction du moteur de fumure
                    dispatch(ActionGetArchivedData(paramCurrentParcel.idParcel, fertilizer.engine)),

                    // Demande des listes de fumures en fonction du moteur de fumure
                    dispatch(ActionGetFerilizerSpecificListsByEngine(fertilizer.engine, paramCurrentParcel.idParcel))
                )
                .then(() =>  
                    {
                        // Affichage des données
                        dispatch(ActionShowSpecificInfosManagement(paramCurrentParcel)) 
                    });
            }
        }
    }
}

export const ActionSelectFertilizerEngine = (engineValue) => ({
    type: SELECT_FERTILIZER_ENGINE,
    fertilizerEngine: (engineValue === 1) ? 
        FertilizerEngineEnum.AZOFERT : 
        ((engineValue === 2) ? 
            FertilizerEngineEnum.FERTIWEB : 
            ((engineValue === 3) ? FertilizerEngineEnum.GEOFOLIA : FertilizerEngineEnum.UNKNOWN)),
});


/*export*/ function ActionErrorAskFertilizerEngine(errorValue) {
    return {
        type: ERROR_ASK_FERTILIZER_ENGINE,
        errorMessage: errorValue,
    };
}

export function ActionCloseSpecificInfosManagementPopup() {
    return {
        type: CLOSE_SPEC_INFOS_MANAGEMENT,
    };
}

export const ActionSelectParcelsForFertilizing = (parcelIdsSelectedForFertilizingValue, cultureSelectedValue) => ({
    type: SELECT_PARCELS_FOR_FERTILIZING,
    parcelIdsSelected: parcelIdsSelectedForFertilizingValue,
    cultureSelected: cultureSelectedValue
});

export function ActionClearFertilizerValue() {
    return {
        type: CLEAR_FERTILIZER_VALUE
    }
};

/*export*/ function ActionErrorLoadCultures(errorValue) {
    return {
        type: ERROR_LOAD_CULTURES,
        errorMessage: errorValue,
    };
}

/*export*/ const ActionGeneratingFertilizerFile = () => ({
    type: GENERATING_FERTILIZER_FILE,
})

/*export*/ const ActionFertilizerFileGenerated = () => ({
    type: FERTILIZER_FILE_GENERATED,
})

export function ActionGenerateFileOfThisFertilizer(fertilizer) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        if (fertilizer.idParcel > 0) {
            //Affiche l'attente:
            dispatch( ActionGeneratingFertilizerFile() );

            //Génère le pdf de cette fumure:
            fertilizerWebApiProvider.GenerateFertilizerPdf(dispatch, getState, fertilizer, false, false) //demande le téléchargement du fichier Pdf !
                .then((respDatas) => {
                    //Supprime l'attente:
                    dispatch( ActionFertilizerFileGenerated() );
                    
                    //A CE JOUR : on reçoit : 
                    // - Rien, si on est sur l'appli Web;
                    // - l'URL du fichier stocké sur le compte Azure, si on est sur l'appli mobile.
                    //Dans ce second cas, il faut transmettre à l'appli native cette url pour lancer son affichage !
                    if ((IsNativeHoster() === true) && (respDatas !== '') && userAgentAuthent && userAgentAuthent.commProviderWithNativeApp) {
                        userAgentAuthent.commProviderWithNativeApp.askShowUrl(respDatas); //'respDatas' est une URL !
                    }
                },
                (error) => {
                    //Supprime l'attente:
                    dispatch( ActionFertilizerFileGenerated() );
                    
                    //Avertit du mauvais retour:
                    dispatch(ActionErrorLoadFertilizers(error));
                });
        } //else //ne fait rien !
    }
}

/*export*/ const ActionSavingCommonInfos = () => ({
    type: SAVING_COMMON_INFOS,
    commonInfosManagementSaving: true
})

/*export*/ const ActionSavingSpecificInfos = () => ({
    type: SAVING_SPECIFIC_INFOS,
    specificInfosManagementSaving: true
})

/*export*/ const ActionCommonInfosSaved = (commonInfos = null) => ({
    type: COMMON_INFOS_SAVED,
    commonInfosManagementSaving: false,
    commonInfosByCulture: commonInfos
})

/*export*/ const ActionSpecificInfosSaved = (specificInfosValue = null) => ({
    type: SPECIFIC_INFOS_SAVED,
    specificInfosManagementSaving: false,
    specificInfos: specificInfosValue
})

/*export*/ const ActionAskingCommonInfosAndListsByCulture = () => ({
    type: ASKING_COMMON_INFOS_AND_LISTS_BY_CULTURE,
})

export function ActionRegisterWinterDate(fertilizerParameter) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        if (fertilizerParameter !== null) {
            //Affiche l'attente:
            dispatch( ActionSavingSpecificInfos() );

            fertilizerWebApiProvider.saveOrUpdateSpecificInfosWinterDate(dispatch, getState, fertilizerParameter)
                .then((respDatas) => {
                    //Supprime l'attente:
                    dispatch( ActionSpecificInfosSaved(fertilizerParameter) );
                },
                (error) => {
                    //Supprime l'attente:
                    dispatch( ActionSpecificInfosSaved() );
                });
        }
    }
}

/*export*/ const ActionCommonInfosAndListsByCultureAsked = () => ({
    type: COMMON_INFOS_AND_LISTS_BY_CULTURE_ASKED,
})

const ActionFillCommonInfosByCulture = (commonInfosByCultureValue) => ({
    type: GET_COMMON_INFOS_BY_CULURE,
    commonInfosByCulture: commonInfosByCultureValue,
})

export function ActionGetCommonInfosAndListsByCulture(culture) {
    return function (dispatch, getState, { history, userAgentAuthent }) {

        const currentStoreState = getState();

        if (culture.cropType > 0) {
            //Affiche l'attente:
            dispatch( ActionAskingCommonInfosAndListsByCulture() );
            fertilizerWebApiProvider.loadCommonInfosManagementByCulture(dispatch, getState, culture.cropType) //demande les infos communes en fonction de la culture 
                .then((respDatas) => {
                    //Supprime l'attente
                    dispatch( ActionCommonInfosAndListsByCultureAsked() );

                    //Rempli les données communes
                    dispatch( ActionFillCommonInfosByCulture(((respDatas !== null) && (respDatas !== undefined) && (respDatas.id > 0)) ? respDatas : undefined) );

                    const fertilizerCommonLists = lodashGet(currentStoreState, 'fertilizerData.fertilizerCommonLists', undefined);

                    //Si les listes sont déjà renseignées, plus besoin de demander les listes 
                    if (fertilizerCommonLists === undefined) {
                        //Demande des listes pour la fumure
                        dispatch( ActionGetFertilizerCommonLists() );
                    }

                    //Redirection vers l'étape 2
                    dispatch( ActionGoToStepOfFertilizer(FertilizerStep.COMMON_INFOS_MANAGEMENT) );
                    
                },
                (error) => {
                    //Supprime l'attente:
                    dispatch( ActionCommonInfosAndListsByCultureAsked() );
                    
                    const fertilizerCommonLists = lodashGet(currentStoreState, 'fertilizerData.fertilizerCommonLists', undefined);
                    
                    //Avertit du mauvais retour:
                    dispatch(ActionErrorAskFertilizerDatas(error));

                    //Si les listes sont déjà renseignées, plus besoin de demander les listes 
                    if (fertilizerCommonLists === undefined) {
                        //Demande des listes pour la fumure
                        dispatch( ActionGetFertilizerCommonLists() );
                    }

                    dispatch( ActionGoToStepOfFertilizer(FertilizerStep.COMMON_INFOS_MANAGEMENT) );
                });
        } //else //ne fait rien !
    }
}

const ActionFillSpecificInfos_TypeDeSol = (specificInfosValue) => ({
    type: GET_SPECIFIC_INFOS_TYPEDESOL,
    fertilizerSpecificList_TypeDeSol: specificInfosValue,
})

const ActionFillSpecificInfos_Variete = (fertilizerEngineValue, specificInfosValue) => ({
    type: GET_SPECIFIC_INFOS_VARIETE,
    fertilizerEngine: fertilizerEngineValue,
    fertilizerSpecificList_Variete: specificInfosValue,
})

const ActionFillSpecificInfos_VarieteProt = (fertilizerEngineValue, specificInfosValue) => ({
    type: GET_SPECIFIC_INFOS_VARIETE_PROT,
    fertilizerEngine: fertilizerEngineValue,
    fertilizerSpecificList_VarieteProt: specificInfosValue,
})

const ActionAskSpecificInfos_TypeDeSol = () => ({
    type: ASK_SPECIFIC_INFOS_TYPEDESOL,
})

const ActionAskSpecificInfos_Variete = () => ({
    type: ASK_SPECIFIC_INFOS_VARIETE,
})

/**
 * Action pour modifier la varieté de la parcelle selectionnée
 * @param {Number} parcelId id de la parcelle
 * @param {Object} varietyValue variete CommonListElement
 * @returns 
 */
const ActionSetCurrentParcelVariety = (parcelId, varietyValue) => ({
    type: SET_CURRENT_PARCEL_VARIETY,
    parcelId: parcelId,
    variety: varietyValue,
});

/**
 * Action pour gérer le state de chargement de varieté
 * @param {boolean} value 
 * @returns 
 */
const ActionSetVarietyPending = (value) => ({
    type: SET_PARCEL_VARIETY_PENDING,
    varietyPending: value,
});

/**
 * Action qui indique que l'on est en train de chargé potentiellement la varieté de la parcelle
 */
const ActionAskParcelVarietyPending = () => ({
    type: ASKING_PARCEL_VARIETY_PENDING,
});

export function ActionGetSpecificListSoilKind(parcelId) {
    return function (dispatch, getState, {history, userAgentAuthent}) {
        const currentStoreState = getState();
        
        dispatch(ActionAskSpecificInfos_TypeDeSol());

        // Demande liste type de sol
        fertilizerWebApiProvider.loadSoilKind(dispatch, getState, parcelId) //demande la liste des cultures précédentes
        .then((respDatas) => {
            dispatch( ActionFillSpecificInfos_TypeDeSol(((respDatas !== null) && (respDatas !== undefined) && (respDatas.length > 0)) ? respDatas : undefined) );

            const fertilizerSpecificList_TypeDeSol = lodashGet(currentStoreState, 'fertilizerData.fertilizerSpecificList_TypeDeSol', undefined);

            //Si les listes sont déjà renseignées, plus besoin de demander les listes 
            if (fertilizerSpecificList_TypeDeSol === undefined) {
                //Demande des listes pour la fumure
                dispatch( ActionGetTypeDeSolList(parcelId) );
            }
        },
        (error) => {
            const fertilizerSpecificList_TypeDeSol = lodashGet(currentStoreState, 'fertilizerData.fertilizerSpecificList_TypeDeSol', undefined);

            //Si les listes sont déjà renseignées, plus besoin de demander les listes 
            if (fertilizerSpecificList_TypeDeSol === undefined) {
                //Demande des listes pour la fumure
                dispatch( ActionGetTypeDeSolList(parcelId) );
            }
            
            //Avertit du mauvais retour:
            dispatch(ActionErrorAskFertilizerDatas(error));
        });

    }
}

export function ActionGetSpecificListVarieties(fertilizerEngine, parcelId, culture, isImprovingCrop) { //TODO : Ici, on charge sans vérifier si existe déjà ! => vérifier que l'appelant le fait !
    return function (dispatch, getState, {history, userAgentAuthent}) {
        dispatch(ActionAskSpecificInfos_Variete());

        // Si blé améliorant alors l'objectif de production est protéine
        // Dans le cas de Azofert, on se basera sur l'objectif de production
        // Dans le cas de Fertiweb, on se basera sur la culture améliorante
        let objectifRendement = isImprovingCrop ? CodeObjectifRendementProtein : CodeObjectifRendementDefault;

        // Demande liste des variétés
        fertilizerWebApiProvider.loadVarieties(dispatch, getState, fertilizerEngine, parcelId, culture, isImprovingCrop, objectifRendement) //demande la liste des cultures précédentes
            .then((respDatas) => {
                //Si on a un retour positif, on affecte la liste qui convient (à ce jour, on différencie la liste suivant les deux types "d'objectif de production") !
                const newVarietiesList = ((respDatas !== null) && (respDatas !== undefined) && (respDatas.length > 0)) ? respDatas : undefined;
                if (isImprovingCrop === true) {
                    dispatch( ActionFillSpecificInfos_VarieteProt(fertilizerEngine, newVarietiesList) );
                } else {
                    dispatch( ActionFillSpecificInfos_Variete(fertilizerEngine, newVarietiesList) );
                }
            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorAskFertilizerDatas(error));
            });
    }
}

/**
 * Action pour charger la varietée la plus proche de la parcelle
 * @param {Object} fertilizerEngine moteur de fumure
 * @param {Number} parcelId id de la parcelle
 * @param {Object} culture culture de la parcelle
 * @param {Boolean} isImprovingCrop si la culture est améliorante
 */
export function ActionGetVarietyForParcel(fertilizerEngine, parcelId, culture, isImprovingCrop) {    
    return function(dispatch, getState, {history, userAgentAuthent}) {
        // Si blé améliorant alors l'objectif de production est protéine
        // Dans le cas de Azofert, on se basera sur l'objectif de production
        // Dans le cas de Fertiweb, on se basera sur la culture améliorante
        let objectifRendement = isImprovingCrop ? CodeObjectifRendementProtein : CodeObjectifRendementDefault;

        dispatch(ActionAskParcelVarietyPending());

        fertilizerWebApiProvider.loadVarietyForParcel(dispatch, getState, fertilizerEngine, parcelId, culture, isImprovingCrop, objectifRendement)
            .then((respDatas) => {
                dispatch( ActionSetCurrentParcelVariety(parcelId , respDatas));
            })
            .catch((error) => {
                dispatch(ActionErrorAskFertilizerDatas(error));
            })
            .finally(() => {
                dispatch(ActionSetVarietyPending(false));
            });
    }
}

export function ActionSaveCommonInfosManagement(commonInfos) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        //Affiche l'attente:
        dispatch( ActionSavingCommonInfos() );

        //Génère le pdf de la ou les fumures de cette parcelle:
        fertilizerWebApiProvider.saveOrUpdateCommonInfosManagement(dispatch, getState, commonInfos) //demande les infos communes en fonction de la culture 
            .then((respDatas) => {
                //Supprime l'attente:
                dispatch( ActionCommonInfosSaved(commonInfos) );
            },
            (error) => {
                //Supprime l'attente:
                dispatch( ActionCommonInfosSaved() );
                
                //Avertit du mauvais retour:
                dispatch(ActionErrorAskFertilizerDatas(error));
            }
        );
    }
}

export function ActionSaveSpecificInfosManagement(specificInfos, noErrors, needValidation) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
            //Affiche l'attente:
            dispatch( ActionSavingSpecificInfos() );

            fertilizerWebApiProvider.saveOrUpdateSpecificInfos(dispatch, getState, specificInfos) 
                .then((respDatas) => {
                    //Supprime l'attente:
                    dispatch(ActionSpecificInfosSaved(respDatas));

                    if (noErrors && needValidation) {
                        //On ferme la popup:
                        dispatch(ActionCloseSpecificInfosManagementPopup());
                    } else if (!needValidation) {
                        //On ferme la popup:
                        dispatch(ActionCloseSpecificInfosManagementPopup());
                    }
                },
                (error) => {
                    //Supprime l'attente:
                    dispatch( ActionSpecificInfosSaved() );

                    // On ferme la popup:
                    // mise en commentaire car on doit montrer les erreurs sur nos differents champs
                    // dispatch(ActionCloseSpecificInfosManagementPopup());

                    //Avertit du mauvais retour:
                    dispatch(ActionErrorAskFertilizerDatas(error));
                });
    }
}

export const InitFertilizerLists = (fertilizerEngineValue, lists) => ({
    type: INIT_FERTILIZER_LISTS,
    fertilizerLists: lists,
    fertilizerEngine: fertilizerEngineValue
})

export const InitFertilizerCommonLists = (lists) => ({
    type: INIT_FERTILIZER_COMMON_LISTS,
    fertilizerCommonLists: lists,
})

export function ActionGetFertilizerLists(fertilizerEngine) {
    return function (dispatch, getState, { history, userAgentAuthent }) {

        //Signale que le téléchargement de listes spécificques (autres que les variétés et les types de sol) commence
        dispatch(ActionFertilizerSpecificListsAsking());

        const currentStoreState = getState();
        
        const closingDateAzofert = lodashGet(currentStoreState, 'fertilizerData.closingDateAzofert', undefined);
        const openingDateAzofert = lodashGet(currentStoreState, 'fertilizerData.openingDateAzofert', undefined);

        let currentCropYear = fertilizerHelper.getCurrentCropYear(closingDateAzofert, openingDateAzofert); 

        fertilizerWebApiProvider.loadFertilizerLists(dispatch, getState, fertilizerEngine, currentCropYear) //demande les listes spécifiques
            .then((respDatas) => {
                dispatch( InitFertilizerLists(fertilizerEngine, respDatas) );

                //Signale que le chargement de listes est terminé
                dispatch(ActionFertilizerSpecificListsAsked());
            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorAskFertilizerDatas(error));

                //Signale que le chargement de listes est terminé
                dispatch(ActionFertilizerSpecificListsAsked());
            });   
    }
}

export function ActionGetFertilizerCommonLists() {
    return function (dispatch, getState, { history, userAgentAuthent }) {

        fertilizerWebApiProvider.loadFertilizerCommonLists(dispatch, getState) //demande les listes communes
            .then((respDatas) => {
                dispatch( InitFertilizerCommonLists(respDatas) );
            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch( ActionErrorAskFertilizerDatas(error) );
            });    
    }
}

export const InitTypeDeSolList = (fertilizerEngineValue, lists) => ({
    type: GET_SPECIFIC_INFOS_TYPEDESOL,
    fertilizerEngine: fertilizerEngineValue,
    fertilizerSpecificList_TypeDeSol: lists,
})

export function ActionGetTypeDeSolList(fertilizerEngine, parcelId) {
    return function (dispatch, getState, { history, userAgentAuthent }) {

        fertilizerWebApiProvider.loadSoilKind(dispatch, getState, parcelId) //demande la liste des type de sols
            .then((respDatas) => {
                dispatch( InitTypeDeSolList(fertilizerEngine, respDatas) );
            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorAskFertilizerDatas(error));     // TODO: probablement mauvais call d'erreur
            });    
    }
}

export function ActionLoadSpecificInfosListsRequiringParcelId(parcelId, culture, fertilizerEngine = FertilizerEngineEnum.AZOFERT) {
    return function (dispatch, getState) {
        //TODO Ne faire qu'un seul Appel API qui récupère les listes
        //On demande le chargement de la ou les liste de variétés en fonction de la culture:

        // TODO: deux cas de figure : si pas de dico, on charge, si un dico, on va voir si la varieté est la, si non on charge
        const currentStoreState = getState();
        const fertilizerDico = lodashGet(currentStoreState, 'fertilizerData.fertilizerDico', {});

        // si on a un dico avec la parcelle et que celle-ci n'est pas renseigné, on va essayer de preselect la varieté
        if (fertilizerDico) {
            let exist = false;
            let index = null;

            // on va parcourir le dico et voir si elle existe, si oui on va avisé
            for (const key in fertilizerDico) {
                if (fertilizerDico[key].idParcel === parcelId) {
                    exist = true;
                    index = key;
                    break;
                }
            }

            if (exist) {
                // la parcelle existe mais elle n'a pas encore de varieté enregistrer on peut donc la charger
                if (fertilizerDico[index].variety === null || fertilizerDico[index].variety.code === null) {
                    dispatch( ActionGetVarietyForParcel(fertilizerEngine, parcelId, culture, false));
                }
            } else {
                dispatch( ActionGetVarietyForParcel(fertilizerEngine, parcelId, culture, false));
            }
        }
        
        if (culture === CodePZCultureBle) { //Pour le blé (il y en a deux à ce jour, car dépends de l'objectif de production) !
            dispatch( ActionGetSpecificListVarieties(fertilizerEngine, parcelId, culture, false) );
            dispatch( ActionGetSpecificListVarieties(fertilizerEngine, parcelId, culture, true) );
        } else if ((culture === CodePZCultureOrge) || (culture === CodePZCultureBleDur)) { //Une seule pour l'orge et le blé dur !
            dispatch( ActionGetSpecificListVarieties(fertilizerEngine, parcelId, culture, false) );
        }
        //else if (culture === CodePZCultureColza) {...} //aucune pour le colza !

        //On demande le chargement de la liste des types de sol:
        dispatch(ActionGetTypeDeSolList(fertilizerEngine, parcelId));
    }
}

///////////////////////////////////////////////////////////////// ↓↓ CHOIX DES COUCHES ↓↓
export function ActionSelectLayerForFertilizer(baseLayertypeValue, forewardLayertypeValue) {
    return function (dispatch, getState) {
        //Besoin d'actualiser le choix des couches si une parcelle est sélectionnée et corresponds à un type spécifique 'Parcelle':
        if (forewardLayertypeValue && (
            (forewardLayertypeValue === ConstantsLayers.VisibleParcelLayerName) || (forewardLayertypeValue === ConstantsLayers.NdviParcelLayerName))) {
            const currentStoreState = getState();
            // 1- récupération de la parcelle sélectionnée
            const currentParcelIdSelected = lodashGet(currentStoreState, 'contextAppData.parcelIdSelected', -1);
            if (currentParcelIdSelected > 0)
                dispatch(ActionSelectLayerOfParcelForFertilizer(forewardLayertypeValue, currentParcelIdSelected));
        }

        // Valeurs possibles pour 'baseLayertypeValue' : ConstantsLayers.VisibleBaseLayerName ou ConstantsLayers.RoadBaseLayerName !
        // Valeurs possibles pour 'forewardLayertypeValue' : NdviGlobalLayerName ou NdviParcelLayerName ou VisibleGlobalLayerName ou VisibleParcelLayerName !
        return dispatch({
            type: SELECT_LAYER_FOR_FERTILIZER,
            baseLayertypeForFertilizer: baseLayertypeValue,
            forewardLayertypeForFertilizer: forewardLayertypeValue,
        });
    }
}
///////////////////////////////////////////////////////////////// ↑↑ CHOIX DES COUCHES ↑↑

export function ActionSelectWinterDatesByParcelId(parcelId, isGoBack = false) {
    return function (dispatch, getState) {
        
        dispatch(ActionSelectParcel(parcelId));

        dispatch({
            type: SELECT_BEG_END_WINTER_DATES_BY_PARCELID,
            parcelIdSelected: parcelId,
            isGoBack: isGoBack,
        });

        const currentStoreState = getState();
        const parcelIdSelected = lodashGet(currentStoreState, 'fertilizerData.parcelIdSelected', 
            lodashGet(currentStoreState, 'fertilizerData.fertilizerSelected.parcelId', -1));

        //Mémoriser les dates des images courantes des parcelles pour pouvoir les remettre à la fin de la fumure, biomasse ou modulation
        const satimageId = lodashGet(currentStoreState, `parcelsData.parcelDico[${parcelIdSelected}].currentSatimageId`, null);
        const satImageDate = lodashGet(currentStoreState, `parcelsData.parcelDico[${parcelIdSelected}].currentSatimageDate`, null);
        
        if ((satimageId !== null) && (satImageDate !== null)) {
            dispatch( ActionAddTheLastSelectedSatImagesToSetOnMap(parcelIdSelected, satimageId, satImageDate) );
        }

        //on nous demande de passer sur la carto depuis l'écran de gestion des fumures : on présélectionne l'image d'entrée d'hiver, s'il y en avait une de renseignée...
        if (isGoBack === false) {
            const satImageStartingWinterId = lodashGet(currentStoreState, 'fertilizerData.fertilizerSelected.beginningWinterImagId', -1);
            //on pré-sélectionne la date de sortie d'hiver:
            dispatch( ActionSelectDateImg(satImageStartingWinterId, undefined, parcelIdSelected) );
        } else  { //sinon, en retour arrière, on préférera celle de sortie d'hiver !
            const satImageEndingWinterId = lodashGet(currentStoreState, 'fertilizerData.fertilizerSelected.endingWinterImagId', -1);
            //on pré-sélectionne la date de sortie d'hiver:
            dispatch( ActionSelectDateImg(satImageEndingWinterId, undefined, parcelIdSelected) );
        }
    }
    
}

//Signaler qu'on se trouve en date de début d'hiver ou date de sortie d'hiver
//quand on se retrouve dans l'étape de la carte pour le choix des dates
export const ActionSetStepDateWinter = (isBeginningWinter) =>({
    type: SET_STEP_DATE_WINTER,
    askBeginningWinterFirst: isBeginningWinter
});

/**
 * Action permettant d'affecter la valeur du dictionnaire contenant les informations spécific de fumure
 * @param {Dico<idParcel,Object>} fertilizerDico 
 */
export const ActionSetSpecificsInfosDico = (fertilizerDico) => ({
    type: SET_FERTILIZER_DICO,
    dico: fertilizerDico,
});

/**
 * Action permettant de mettre à jour les information spécifique d'une parcelle
 * @param {number} idParcel 
 * @param {Object} specificInfo 
 */
export const ActionUpdateSpecificInfo = (idParcel, specificInfo) => ({
    type: UPDATE_SPECIFIC_INFO,
    idParcel: idParcel,
    specificInfo: specificInfo,
})

///////////////////////////////////////////////////////////////// ↓↓ SUPPRESSION ↓↓ 
//Avertit le début de la suppresion d'une fumure
/*export*/ const ActionDeletingFertilizer = () => ({
    type: DELETING_FERTILIZER,
})

//Avertit le début de la suppresion de plusieurs fumures
/*export*/ const ActionDeletingFertilizers = () => ({
    type: DELETING_FERTILIZERS,
})

//Avertit la fin de la suppression d'une fumure
/*export*/ const ActionFertilizerDeleted = (parcelIdToDeleteValue) => ({
    type: DELETE_FERTILIZER,
    parcelIdDeleted: parcelIdToDeleteValue
})

//Avertit la fin de la suppression des fumures
/*export*/ const ActionFertilizersDeleted = (parcelIdsToDeleteValue) => ({
    type: DELETE_FERTILIZERS,
    parcelIdsDeleted: parcelIdsToDeleteValue,
})

//Action qui permet la suppression d'une fumure
export function ActionDeleteFertilizer(parcelIdToDelete) {
    return function (dispatch, getState) {
        dispatch(ActionDeletingFertilizer());

        fertilizerWebApiProvider.deleteFertilizer(dispatch, getState, parcelIdToDelete)
            .then((response) => {
                dispatch(ActionFertilizerDeleted(parcelIdToDelete));
                dispatch(ActionGetAllLastNitrogenInputs());
            },
                (error) => {
                    //Avertit du mauvais retour: non !
                    dispatch(ActionFertilizerDeleted(undefined));
                });
    }
}

//Action qui permet la suppression de plusieurs fumures
export function ActionDeleteFertilizers(fertilizersToDelete) {
    return function (dispatch, getState) {
        dispatch(ActionDeletingFertilizers());

        let parcelsIdsToDelete = [];
        let parcelArchivedIdsToDelete = [];
        fertilizersToDelete.forEach(item => {
            parcelsIdsToDelete.push(item.idParcel);
            if (item.isArchived === true) {
                parcelArchivedIdsToDelete.push(item.idParcel);
            }
            //fertilizersIdsToDelete.push(item.idFertilizer);
        });

        return fertilizerWebApiProvider.deleteFertilizers(dispatch, getState, parcelsIdsToDelete)
            .then((response) => {
                dispatch(ActionFertilizersDeleted(parcelsIdsToDelete));
                // Répercuter les changements de suppression au niveau des parcelles archivées uniquement
                if (parcelArchivedIdsToDelete.length > 0) {
                    dispatch(ActionDeleteParcelArchivedIdList(parcelArchivedIdsToDelete));
                }
                dispatch(ActionGetAllLastNitrogenInputs());
                //return fertilizersToDelete;
            },
            (error) => {
                //Avertit du mauvais retour: non !
                dispatch(ActionFertilizersDeleted(undefined));
            });
    }
}
///////////////////////////////////////////////////////////////// ↑↑ SUPPRESSION ↑↑

///////////////////////////////////////////////////////////////// ↓↓ LOAD FERTILIZERS ↓↓ 
/*export*/ const ActionLoadingFertilizers = () => ({
    //return {
        type: LOADING_FERTILIZERS,
        //};
    });

/*export*/ function ActionInitFertilizerDico(fertilizerDicoValue) {
    return {
        type: LOAD_FERTILIZERS,
        fertilizerDico: fertilizerDicoValue
    };
}

export function ActionErrorLoadFertilizers(errorValue) {
    return {
        type: ERROR_LOAD_FERTILIZERS,
        errorMessage: errorValue,
    };
}

//Actions qui permettent d'obtenir toutes les fumures (actuelles ou antérieures) enregistrées en base
export function ActionGetAllFertilizers(clientId) {
    return function (dispatch, getState) {
        // 1- informe que l'on va faire la demande d'obtension des données de fumure du client:
        dispatch(ActionLoadingFertilizers());
        // 2- récupération des données de fumure
        fertilizerWebApiProvider.loadFertilizers(dispatch, getState, clientId)
            .then((fertilizers) => {

                //Fournit les parcelles archivées
                dispatch(ActionLoadParcelArchivedForFertilizer());

                const currentStoreState = getState();
                const errorMessage = lodashGet(currentStoreState, 'parcelsData.errorMessage', undefined); //TODO : Je ne comprends pas ce code ! pourquoi tester ceci ???
                
                if (lodashIsEmpty(errorMessage)) {
                    //Fournis les données de fumure du client:
                    dispatch(ActionInitFertilizerDico(fertilizers));

                    //Si on est dans l'étape commune
                    const fertilizerStep = lodashGet(currentStoreState, 'fertilizerData.fertilizerStep', FertilizerStep.CULTURE_AND_PARCELS_CHOICE);
                    if (fertilizerStep === FertilizerStep.COMMON_INFOS_MANAGEMENT) {
                        dispatch(ActionGoToStepOfFertilizer(FertilizerStep.MANAGEMENT_NITROGEN));
                    }
                }
                else {
                    //Avertit du mauvais retour:
                    dispatch(ActionErrorLoadFertilizers(errorMessage));
                }
            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorLoadFertilizers(error));
            });    
    }
}

///////////////////////////////////////////////////////////////// ↑↑ LOAD FERTILIZERS ↑↑
//Action pour mettre à jour la liste des années de campagne sélectionnées par le client dans
//le deuxième onglet dans la liste déroulante/
//Car il faut garder en mémoire le choix du client pendant la session de l'appli. 
export function ActionSetCropYearsSelectedDForValidatedFertilizer(cropYearsSelected) {
    return {
        type: SET_CROPYEARSSELECTED_FOR_VALIDATED_FERTILIZER,
        cropYearsSelectedFilter: cropYearsSelected,
    };
}

export function ActionGoToShowThisFertilizer(fertilizerSelectedItem/* , isAskedFromMap = false */) {
    return function (dispatch, getState) {
        if ((fertilizerSelectedItem !== null) && (fertilizerSelectedItem !== undefined)) {
            const readableMode = true;

            //dispatch(ActionSelectParcelsForBiomass([fertilizerSelectedItem.idParcel]));

            dispatch(ActionNewFertilizerBuilding());

            //dispatch(ActionSelectParcel(fertilizerSelectedItem.idParcel));

            /* if (isAskedFromMap === true) {
                dispatch(ActionGoToBiomassFromMap());
            }
            else { */
            dispatch(ActionShowFertilizer(fertilizerSelectedItem, readableMode));
            /* } */
            
            //Si on n'a pas l'image, on l'obtient:
            //if (!fertilizerSelectedItem.resultWinterImagResult) {
                //fertilizerWebApiProvider.loadFertilizer(dispatch, getState, fertilizerSelectedItem.idClient, fertilizerSelectedItem.id)
                    //.then((newFertilizer) => {
                        //Affiche l'entité fumure actualisée:                    
                        //dispatch(ActionSelectFertilizer(newFertilizer, readableMode));
                    //},
                    //(error) => {
                        //Avertit du mauvais retour:
                        //dispatch(ActionErrorBuildFertilizer(error));

                        //Affiche l'entité fumure (sans son image):
                        //dispatch(ActionSelectFertilizer(fertilizerSelectedItem, readableMode));
                    //});
            //} else {
                //dispatch(ActionSelectFertilizer(fertilizerSelectedItem, readableMode));

            //}
            dispatch(ActionNewFertilizerBuilt());
            
        } //else //ne fait rien !
    }
}

export function ActionSelectCultureFromFertilizer(fertilzer) {
    return {
        type: SELECT_CULTURE,
        crop: fertilzer.crop
    }
};

export function ActionSelectFertilizer(fertilizerSelectedValue, readableModeValue = false) {
    return {
        type: SELECT_FERTILIZER,
        fertilizerSelected: fertilizerSelectedValue,
        readableMode: readableModeValue,
    }
};

export function ActionShowFertilizer(fertilizerSelectedValue) {
    return {
        type: SHOW_FERTILIZER,
        fertilizerShowed: fertilizerSelectedValue,
        readableMode: true,
    }
};

/*export*/ function ActionUpNewFertilizerCounter(fertilizerValue) {
    return {
        type: UP_NEW_FERTILIZER,
        newFertilizer: fertilizerValue
    };
}

/*export*/ function ActionErrorBuildFertilizer(errorValue) {
    return {
        type: ERROR_BUILD_FERTILIZER,
        errorMessage: errorValue,
    };
}

/*export*/ function ActionNewFertilizerBuilt() {
    return {
        type: END_BUILD_FERTILIZER,
    };
}

/*export*/ function ActionNewFertilizerBuilding() {
    return {
        type: START_BUILD_FERTILIZER,
    };
}


export function ActionBuildNitrogenSampleAndDoses(fertilizerParameter) {
    return function (dispatch, getState) {
        const currentStoreState = getState();
        const canBuildFromTypo = lodashGet(currentStoreState, 'clientUserData.clientDatas.authorizeFertilizer', false);

        if (canBuildFromTypo === true) { //Droit accordé !
            //Lance l'attente de génération:
            dispatch(ActionNewFertilizerBuilding());

            fertilizerWebApiProvider.buildNitrogenSampleAndDoses(dispatch, getState, fertilizerParameter.idClient, fertilizerParameter)
                .then((fertilizer) => {
                    //Si le retour est positif mais qu'il indique une 'invalidité' du nombre de zones, on avertit l'utilisateur:
                    if ((!fertilizer) || (fertilizer.isComplet === false)) {
                        //Avertit du mauvais retour:
                        dispatch(ActionErrorBuildFertilizer(
                            StringTranslate.formatString(StringTranslate.unvalidZonesFromBuildFerti, fertilizer.zonesCounterBuild)
                        ));
                    } else { //on est ok, on affiche le résultat !
                        // Assigne les nouvelles données générées:
                        dispatch(ActionSelectFertilizer(fertilizer));

                        //Mise à jour de l'année de la campagne de la parcelle (seulement si l'année de campagne est différente de null)
                        //Un conseil généré en dehors de la fermeture de Azofert ne doit pas avoir d'année de campagne à null.
                        //Un conseil généré pendant la période de fermeture de Azofert, a une année de campagne à null, dans ce cas, on ne 
                        //modifie pas l'année de campagne de la parcelle.
                        if ((fertilizer.cropYear !== undefined) && (fertilizer.cropYear !== null)) {
                            dispatch(ActionUpdateParcelCropYear(fertilizer.cropYear, fertilizer.idParcel));
                        }

                        //Mise à jour de la culture de la parcelle
                        const cropName = lodashGet(currentStoreState, 'fertilizerData.cultureSelected.name', undefined);
                        if (cropName !== undefined) {
                            dispatch(ActionUpdateParcelCrop(cropName, fertilizer.idParcel));
                        }
                        
                        //Si on était sur l'étape de saisie des specificInfos, on passe sur l'étape de resultat:
                        const currentStep = lodashGet(currentStoreState, 'fertilizerData.fertilizerStep', FertilizerStep.MANAGEMENT_NITROGEN);
                        if (currentStep === FertilizerStep.MANAGEMENT_NITROGEN) {
                            dispatch(ActionGoToStepOfFertilizer(FertilizerStep.RESULT, false));
                            dispatch(ActionUpNewFertilizerCounter(fertilizer));
                            //dispatch(ActionUpdateParcelAfterFertilizerAdded(fertilizer));
                        } //else //on est déjà dessus en principe !

                        dispatch(ActionNewFertilizerBuilt());
                        dispatch(ActionGetAllLastNitrogenInputs());
                    }
                },
                (error) => {
                    // Assigne quand même les données (notamment les dates d'entrée et de sortie d'hiver):
                    dispatch(ActionUpdateSpecificInfo(fertilizerParameter.idParcel, fertilizerParameter));

                    //Avertit du mauvais retour:
                    dispatch(ActionErrorBuildFertilizer(error));
                    //dispatch(ActionNewFertilizerBuilt());
                }
            );

        }
        //else //Client pas autorisé à générer !
    }
}

/*export*/ function UpdatingCropNameInParcel() {
    return {
        type: UPDATING_CROP_IN_PARCEL,
    };
}

/*export*/ function CropNameInParcelUpdated() {
    return {
        type: CROP_IN_PARCEL_UPDATED,
    };
}

export function ActionErrorUpdateCropNameInParcel(errorValue) {
    return {
        type: ERROR_UPDATE_CROP_IN_PARCEL,
        errorMessage: errorValue,
    };
}

/* Non utilisée pour le moment */
/* Permet de modifier la culture de la parcelle identique à la culture pour la fumure */
export function ActionUpdateCropParcel(parcelId, cultureSelected) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        dispatch(UpdatingCropNameInParcel());

        fertilizerWebApiProvider.updateCropNameInParcel(dispatch, getState, parcelId, cultureSelected)
            .then((result) => {
                dispatch(ActionUpdateParcelCulture(cultureSelected.name, parcelId));

                dispatch(CropNameInParcelUpdated());
            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorUpdateCropNameInParcel(error));
            });
    }
    
}

///////////////////////////////////////////////////////////////// ↓↓ ETAPE RESULTS : Mise à jour ↓↓
/*export*/ function UpdatingDoseSavedAndZones() {
    return {
        type: UPDATING_DOSE_SAVED_AND_ZONES,
    };
}

/*export*/ function DoseSavedAndZonesUpdated() {
    return {
        type: DOSE_SAVED_AND_ZONES_UPDATED,
    };
}

/*export*/ function ActionErrorUpdateDoseSavedAndZones(errorValue) {
    return {
        type: ERROR_UPDATE_DOSE_SAVED_AND_ZONES,
        errorMessage: errorValue,
    };
}

/*export*/ function ActionUpdateDoseSavedAndZones(fertilizerValue) {
    return {
        type: UPDATE_DOSE_SAVED_AND_ZONES,
        fertilizerSelected: fertilizerValue
    };
}

/* Permet de mettre à jour les zones */
export function ActionUpdateFertilizerDoses(fertilizerParameter) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        dispatch(UpdatingDoseSavedAndZones());

        fertilizerWebApiProvider.updateFertilizerDoses(dispatch, getState, fertilizerParameter)
            .then((newFertilizer) => {
                dispatch(ActionUpdateDoseSavedAndZones(newFertilizer));

                dispatch(DoseSavedAndZonesUpdated());
            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch(ActionErrorUpdateDoseSavedAndZones(error));
            });
    }
    
}
///////////////////////////////////////////////////////////////// ↑↑ ETAPE RESULTS : Mise à jour ↑↑

///////////////////////////////////////////////////////////////// ↓↓ ABSTRACT FERTILIZER - TABS ↓↓ 
export function ActionSelectFertilizerTab(valueTab) {
    return {
        type: SET_VALUE_TAB_FOR_ABSTRACT_FERTILIZER,
        valueTabForAbstractFertilizer: valueTab
    };
}
///////////////////////////////////////////////////////////////// ↑↑ ABSTRACT FERTILIZER - TABS ↑↑


/*export*/ function ActionFertilizerPrescriptionBuilding() {
    return {
        type: DOWNLOADING_FERTILIZER_FOR_PRESCRIPTION,
    };
}

/*export*/ function ActionFertilizerDownLoaded() {
    return {
        type: DOWNLOADED_FERTILIZER_FOR_PRESCRIPTION,
    };
}

export function ActionBuildListPrescriptions(fertilizerParameterListValue, exportFormat) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        if (!fertilizerParameterListValue) return;

        //Début de téléchargement:
        dispatch(ActionFertilizerPrescriptionBuilding());

        fertilizerWebApiProvider.buildListPrescriptions(dispatch, getState, fertilizerParameterListValue, exportFormat)
            .then((respDatas) => { 
                //Fin des téléchargements:
                dispatch(ActionFertilizerDownLoaded());
                
                //A CE JOUR : on reçoit : 
                // - Rien, si on est sur l'appli Web;
                // - l'URL du fichier stocké sur le compte Azure, si on est sur l'appli mobile.
                //Dans ce second cas, il faut transmettre à l'appli native cette url pour lancer son affichage !
                if ((IsNativeHoster() === true) && (respDatas !== '') && userAgentAuthent && userAgentAuthent.commProviderWithNativeApp) {
                    userAgentAuthent && userAgentAuthent.commProviderWithNativeApp.askShowUrl(respDatas); //'respDatas' est une URL !
                }
            })
            .catch((error) => {
                //Avertit du mauvais retour + arrêt de téléchargement:
                dispatch(ActionErrorLoadFertilizers(StringTranslate.badCompletBuildModulation));
            });
    };
}

export function ActionBuildFertilizerFile(fertilizerIdValue) {
    return function (dispatch, getState, { history, userAgentAuthent }) {
        if (!fertilizerIdValue) return;

        //Début de téléchargement:
        dispatch(ActionFertilizerPrescriptionBuilding());

        fertilizerWebApiProvider.buildFertilizerFile(dispatch, getState, fertilizerIdValue)
            .then((respDatas) => { 
                //Fin des téléchargements:
                dispatch(ActionFertilizerDownLoaded());
                
                //A CE JOUR : on reçoit : 
                // - Rien, si on est sur l'appli Web;
                // - l'URL du fichier stocké sur le compte Azure, si on est sur l'appli mobile.
                //Dans ce second cas, il faut transmettre à l'appli native cette url pour lancer son affichage !
                if ((IsNativeHoster() === true) && (respDatas !== '') && userAgentAuthent && userAgentAuthent.commProviderWithNativeApp) {
                    userAgentAuthent && userAgentAuthent.commProviderWithNativeApp.askShowUrl(respDatas); //'respDatas' est une URL !
                }
            })
            .catch((error) => {
                //Avertit du mauvais retour + arrêt de téléchargement:
                dispatch(ActionErrorLoadFertilizers(StringTranslate.badBuildPpfFile));
            });
    };
}

export function ActionUpdateLocalisationAndReload(parcelId, newLocalisation) { 
    return function (dispatch, getState, {history, userAgentAuthent}) {
        const currentStoreState = getState();

        dispatch(ActionSavingSpecificInfos()); //réutilise le même flag d'enregistrement en cours !

        // Demande la mise à jour de cette localité pour la parcelle visée
        parcelWebApiProvider.updateParcelLocalisation(dispatch, getState, parcelId, newLocalisation) 
            .then((respDatas) => { //si ok, on reçoit le code INSEE !
                //Si on a un retour positif, on affecte la parcelle associée: 
                dispatch( ActionUpdateLocalisation(parcelId, newLocalisation) );

                //Et on redemande les listes dépendants de la localisation (on cherche pas à savoir si nécessaire ou pas): 
                const culture = lodashGet(currentStoreState, 'fertilizerData.cultureSelected.cropType', undefined);
                if (culture !== null) {
                    dispatch( ActionLoadSpecificInfosListsRequiringParcelId(parcelId, culture.toString()) );
                }

                dispatch( ActionEndFertilizerUpdate() );
            },
            (error) => {
                //Avertit du mauvais retour:
                dispatch( ActionErrorAskFertilizerDatas(StringTranslate.updateLocalisationErrorMessage) );
            });
    }
}

////////////////////////////////////////////////////////////////////////////// Récupération des données de fumure passées
export const ActionAskingSpecificInfosArchived = () => ({
    type: ASKING_SPECIFICINFOS_ARCHIVED,
})

export const ActionSpecificInfosArchivedAsked = () => ({
    type: SPECIFICINFOS_ARCHIVED_ASKED,
})

export const ActionSelectSpecificInfosArchived = (archivedFertilizerValue) => ({
    type: SELECT_SPECIFICINFOS_ARCHIVED,
    archivedFertilizer: archivedFertilizerValue
})

export const ActionErrorAskFertilizerArchivedDatas = (error) => ({
    type: ERROR_ASK_FERTILIZER_ARCHIVED_DATAS,
})

// #region Update hasLastNitrogenInput d'une entité de conseil azoté
export const ActionUpdateHasLastNitrogenInput = (fertiId, hasLastNitrogenInput) => ({
    type: UPDATE_HAS_LAST_NITROGEN_INPUT,
    fertiId: fertiId,
    hasLastNitrogenInput: hasLastNitrogenInput,
});

// #endregion
