import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import '../../../assets/css/dfp-didacticielFirstParcel.css';
import Map from '../../../components/didacticiel/firstparcel/map.jsx';
import Modal from '../../../components/didacticiel/firstparcel/modal.jsx';
import StepBienvenue from '../../../components/didacticiel/firstparcel/stepBienvenue.jsx';
import StepChoix from '../../../components/didacticiel/firstparcel/stepChoix.jsx';
import StepDessin from '../../../components/didacticiel/firstparcel/stepDessin.jsx';
import StepImport from '../../../components/didacticiel/firstparcel/stepImport.jsx';
import StepGeofolia from '../../../components/didacticiel/firstparcel/stepGeofolia.jsx';
import StepSucces from '../../../components/didacticiel/firstparcel/stepSucces.jsx';
import StepIndice from "../../../components/didacticiel/firstparcel/StepIndice";
import { ActionGoToStepForDidacticielFirstParcel, StepEnumDidacticielFirstParcel, ActionSetDidacticielFirstParcelOpened } from '../../../redux/actions/didacticiel.js';
import { ActionGoToMap, ActionSetCurrentPageName /*, PageName*/ } from '../../../redux/actions/contextApp.js';
import { LanguageHelper } from '../../../utils/languageHelper.js';
import { Box } from "@mui/material";


/**
 * Composant permettant d'afficher un didacticiel pour l'ajout d'une première parcelle au démarrage de l'application
 * ⚠️ : redirection si canShow === false
 */
function AbstractDidacticielFirstParcel(props) {

    const { currentStep, goToStep, setIsOpened, goToMap, setCurrentPageName, languageSetting } = props;

    /* interdire le retour en arrière quand on est dans le didacticiel */
    const onBackButtonEvent = (e) => {
        e.preventDefault();
        window.history.pushState(null, null, window.location.pathname);
    }
  
    /* référence au composant carte pour utilisation dans le composant StepDessin */
    const [renderMapsCmp, setRenderMapsCmp] = useState(null);

    /* hooks react.js permettant de mettre à jour l'information que le didacticiel est actuellement affiché */
    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        //setIsOpened(true); // onMount
        //setCurrentPageName(PageName.TutorialFirstParcel);
        
        return () => setIsOpened(false); // onUnMount
    }, [setIsOpened, setCurrentPageName]);

    return (
        <Box
            sx={{
                width:"100%",
                height:"100%",
                overflow:"hidden"
            }}
        >

            <Map ref={ref => setRenderMapsCmp(ref)} />

            {((currentStep === StepEnumDidacticielFirstParcel.DESSIN) && (renderMapsCmp)) && <StepDessin goToStep={goToStep} goToMap={goToMap} renderMapsCmp={renderMapsCmp}/>}

            {(
                (currentStep === StepEnumDidacticielFirstParcel.BIENVENUE) ||
                (currentStep === StepEnumDidacticielFirstParcel.CHOIX) ||
                (currentStep === StepEnumDidacticielFirstParcel.IMPORT) ||
                (currentStep === StepEnumDidacticielFirstParcel.GEOFOLIA) ||
                (currentStep === StepEnumDidacticielFirstParcel.INDICE) ||
                (currentStep === StepEnumDidacticielFirstParcel.SUCCES)
            ) && (
                <Modal currentStep={currentStep}>
                
                    {(currentStep === StepEnumDidacticielFirstParcel.BIENVENUE) && <StepBienvenue goToStep={goToStep} />}
                    {(currentStep === StepEnumDidacticielFirstParcel.CHOIX) && <StepChoix goToStep={goToStep} languageSetting={languageSetting}/>}
                    {(currentStep === StepEnumDidacticielFirstParcel.IMPORT) && <StepImport goToStep={goToStep} languageSetting={languageSetting}/>}
                    {(currentStep === StepEnumDidacticielFirstParcel.GEOFOLIA) && <StepGeofolia goToStep={goToStep} />}
                    {(currentStep === StepEnumDidacticielFirstParcel.INDICE) && <StepIndice goToStep={goToStep} />}
                    {(currentStep === StepEnumDidacticielFirstParcel.SUCCES) && <StepSucces goToMap={goToMap}/>}

                </Modal>
            )}
            
        </Box>
    )
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    currentStep: state.didacticielData.firstparcel.currentStep,
    languageSetting: (state.settingsData.settings) ? state.settingsData.settings.language : LanguageHelper.defaultLanguage,
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    setIsOpened: (bool) => dispatch( ActionSetDidacticielFirstParcelOpened(bool) ),
    goToStep: (stepToGo, statusToSet) => dispatch( ActionGoToStepForDidacticielFirstParcel(stepToGo, statusToSet) ),
    goToMap: () => dispatch( ActionGoToMap() ),
    setCurrentPageName: (pageName) => dispatch( ActionSetCurrentPageName(pageName) )
})

export default connect( mapStateToProps, mapDispatchToProps )(AbstractDidacticielFirstParcel);
