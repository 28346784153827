const StoreKeyUserEmailsCst = 'spotifarm.user_email';
const StoreKeyLanguageCst = 'spotifarm.user_language';
const StoreKeyURLParametersCst = 'spotifarm.url_parameters';
// const StoreKeyPagetoGoCst = 'pageToGo';
// const StoreKeyDiscountCodesCst = 'spotifarm.discount_codes';
const StoreKeyNativeAuthorityCst = 'spotifarm.native_authority';

// const parse = JSON.parse;
// const stringify = JSON.stringify;

const StoreFunctions = {

	/* obtention de l'email client */
	getUserEmail() {
		return (localStorage) ? localStorage.getItem(StoreKeyUserEmailsCst) : undefined;
	},

	/* enregistrement de l'email client */
	saveUserEmail(userEmail = null) { 
		if (!userEmail) return;
		if (localStorage)
			localStorage.setItem(StoreKeyUserEmailsCst, userEmail);
	},

	/* suppression de l'email client */
	clearUserEmail() {
		if (localStorage)
			localStorage.removeItem(StoreKeyUserEmailsCst);
	},

	/* enregistrement des paramètres d'URL - (ex: lien depuis le site promotionnel vers l'application) */
	saveURLParameters(obj) { 
		sessionStorage.setItem( StoreKeyURLParametersCst, JSON.stringify(obj) );
	},

	/* obtention des paramètres d'URL */
	getURLParameters() {
		let datas = null;
		try {
			datas = JSON.parse( sessionStorage.getItem(StoreKeyURLParametersCst) );
		} catch (error) {}
		return datas;
	},

	/* suppression des paramètres d'URL */
	clearURLParameters() {
		sessionStorage.removeItem(StoreKeyURLParametersCst);
	},

	/* fonction en lien avec l'enregistrement de la langue choisie par l'utilisateur - fonction qui peut être appelée au tout début du chargement de l'application */
	getUserLanguageToLocalStorage() {
		let datas = null;
		if (localStorage) {
			try {
				datas = JSON.parse(localStorage.getItem(StoreKeyLanguageCst));
			} catch (error) {}
		}
		return datas;
	},

	/* fonction en lien avec la langue choisie par l'utilisateur */
	SaveUserLanguageToLocalStorage(str = 'fr-FR') {

		let position = (str) ? str.indexOf("-") : null;
		let TwoLettersLanguage = (str) ? ((position && position !== -1) ? str.slice(0, position) : str) : 'fr';

		//↓ mise en forme des données pour qu'elles soient utilisables par Azure (AD B2C) et l'application React ↓
		let json = { app: str, azureADB2C: TwoLettersLanguage };
		try {
			json = JSON.stringify({ app: str, azureADB2C: TwoLettersLanguage });
		} catch (error) {
			json = JSON.stringify({ app: 'fr-FR', azureADB2C: 'fr' });
		}

		// ↓ sauvegarde des données dans le local storage ↓
		if (localStorage)
			localStorage.setItem(StoreKeyLanguageCst, json);
	},

	/* fonction en lien avec la langue choisie par l'utilisateur */
	ClearUserLanguageFromLocalStorage() {
		if (localStorage) {
			localStorage.removeItem(StoreKeyLanguageCst);
		}
	},

	/* fonction suppression des données en lien avec un utilisateur */
	clearAllUserDatas() {
		StoreFunctions.clearUserEmail();
		StoreFunctions.ClearUserLanguageFromLocalStorage();
		// StoreFunctions.clearDiscountCodes();
	},

	// SaveGoToPage(dataPageToGo = undefined) {
	// 	//On efface:
	// 	if ((!dataPageToGo) || (!dataPageToGo.pageAsking)) {
	// 		this.ClearPageAsking();
	// 	}
	// 	else if (localStorage) {
	// 		const objDataToGo = Object.assign({}, dataPageToGo);

	// 		localStorage.setItem(StoreKeyPagetoGoCst, stringify(objDataToGo));
	// 	}
	// },

	// LoadGoToPage() {
	// 	if (localStorage) {
	// 		return parse(localStorage.getItem(StoreKeyPagetoGoCst));
	// 	}

	// 	return undefined;
	// },

	/* fonction en lien avec l'obtention d'un paramètre d'URL reçu au lancement de l'application */
	// getURLParameter(param) {
	// 	if (!param) return null;
	// 	let URLparams = StoreFunctions.getURLParameters();
	// 	if (!URLparams) return null;

	// 	let value = null;
	// 	const objParam = (URLparams) ? lodashFind(URLparams, param) : null; // recherche du paramètre URL (providerType)
	// 	if (objParam) {
	// 		try {
	// 			return objParam[param];
	// 		} catch (error) {}
	// 	}
	// 	return value;
	// },

	/* fonction en lien avec l'obtention des paramètres d'URL */
	// getDiscountCodes() {
	// 	let datas = null;
	// 	if (localStorage) {
	// 		try {
	// 			datas = JSON.parse(localStorage.getItem(StoreKeyDiscountCodesCst));
	// 		} catch (error) {}
	// 	}
	// 	return datas;
	// },

	/* fonction en lien avec l'enregistrement des paramètres d'URL - exemple lien depuis le site promotionnel vers l'application */
	// saveDiscountCodes(discountCode, planId = null) { 
	// 	if (!discountCode || discountCode === '') return;

	// 	// ↓ sauvegarde des données dans le local storage ↓
	// 	let obj = (planId) ? {'discountCode':discountCode,'planId':planId} : {'discountCode':discountCode};
	// 	if (localStorage)
	// 		localStorage.setItem(StoreKeyDiscountCodesCst, JSON.stringify(obj));
	// },

	/* fonction en lien avec la suppression des paramètres d'URL */
	// clearDiscountCodes() {
	// 	if (localStorage) {
	// 		localStorage.removeItem(StoreKeyDiscountCodesCst);
	// 	}
	// },

	/* enregistrement des paramètres d'URL - (ex: lien depuis le site promotionnel vers l'application) */
	saveNativeAuthority(obj) { 
		sessionStorage.setItem( StoreKeyNativeAuthorityCst, JSON.stringify(obj) );
	},
};

export default StoreFunctions;