const BadgeSentinel = "S"; //Représente les images issues de Sentinel
const BadgeLandSat = "L"; //Représente les images issues de LandSat
const BadgeModelisation = "M"; //Représsente les images issues de la modélisation

const SatImageSourceProviderUnknown = 0;
const SatImageSourceProviderSentinelL2 = 1;
const SatImageSourceProviderLandSat8 = 2;
const SatImageSourceProviderModelisation = 3;


export const SatImageSourceProvider = {
    //origine de l'image satellite inconnue
    Unkown: 0,
    //image satellite est issue du système Sentinel L2A.
    SentinelL2: 1,
    //image satellite est issue du système LandSat 8.
    LandSat8: 2,
    //image issues de la modélisation
    Modelisation: 3,
};

export const ConstantsProvidersSatellite = {
    BadgeSentinel: BadgeSentinel,
    BadgeLandSat8: BadgeLandSat,
    BadgeModelisation: BadgeModelisation,

    SatImageSource: {
        Unkown: SatImageSourceProviderUnknown,
        SentinelL2: SatImageSourceProviderSentinelL2,
        LandSat8: SatImageSourceProviderLandSat8,
        Modelisation: SatImageSourceProviderModelisation,
    }
}

export default ConstantsProvidersSatellite;