import React from 'react';
import { connect } from 'react-redux';

import LayerCloudSelectionButtonControl from "./LayerCloudSelectionButtonControl.jsx";
import DisplayNdviCharts from '../histogramme/displayNdviCharts.jsx';
import ImageSwiper from '../imageSwiper';
import WidgetParcel from './widgetPanel/widgetParcel.jsx';
import WidgetModulation from './widgetPanel/widgetModulation.jsx';
import WidgetBiomass from './widgetPanel/widgetBiomass.jsx';
import lodashGet from 'lodash/get';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import '../../assets/css/swipeablePanel.css';
import { Collapse, Button, Grid, Card, CardContent, Box } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ParcelsHelper } from '../../utils/parcelsHelper.js';

//import getTheme from "../../themes/index.js";

function SwipeablePanelForDesktop(props) {

	const [expanded, setExpanded] = React.useState(true);
	const { parcelIdSelected, parcelDico } = props;

	/* Ouverture /fermeture du panneau floattant des informations sur la parcelle */
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	/* Réalise une comparaison entre la culture de la parcelle et la culture envoyer afin d'autoriser ou non l'affichage d'un widget */
	const AddOrNoWidget = () => {
		let cultureIsColza = ParcelsHelper.checkCultureIsValid(parcelDico[parcelIdSelected].details.culture, "Colza");
		if (cultureIsColza === true) {
			return (
				<Grid item xs={12}>
					{/* card pesée de colza */}
					<WidgetBiomass {...props} className='leaflet-control' />
				</Grid>
			);
		}
	}
	
	return (
		(parcelIdSelected > 0) && (
			<div className="container-infos-image">
				<div className="swiper-parcel-infos">
					<Collapse in={!expanded} orientation="horizontal" timeout="auto">
						<Button
							aria-label="show-infos"
							color="primary"
							variant="contained"
							className="fabShow-infos-image leaflet-control"
							onClick={handleExpandClick}
						>
							<ArrowRightIcon viewBox="4 3 18 18" />
						</Button>
					</Collapse>

					<Collapse in={expanded} orientation="horizontal" timeout="auto">
						{/* Bouton flèche pour réduire le panneau */}
						<Button
							aria-label="show-infos"
							color="primary"
							variant="contained"
							className="fabShow-infos-image-open leaflet-control"
							onClick={handleExpandClick}
							sx={{
								zIndex: "1100"
							}}
						>
							<ArrowLeftIcon viewBox="4 3 18 18" />
						</Button>

						<Box
							className="widgets-desktop leaflet-control"
							sx={{
								zIndex: "1100",
								width: 466,
							}}
						>
							<PerfectScrollbar component="div">
							<Grid container spacing={1}>
								<Grid item xs={12}>
									{/* card Button clouds */}
									<LayerCloudSelectionButtonControl className='leaflet-control' renderMapsCmp={props.renderMapsCmp} />
									</Grid>
									<Grid item xs={12}>
								
									{/* card ndvi chart */}
									<DisplayNdviCharts className='leaflet-control' />
								</Grid>
								<Grid item xs={12}>
									{/* card Modulation */}
									<WidgetModulation {...props} className='leaflet-control' />
								</Grid>
								{AddOrNoWidget()}
								<Grid item xs={12}>
									{/* card informations parcelle */}
									<WidgetParcel className='leaflet-control' />
								</Grid>
							</Grid>
							</PerfectScrollbar>
						</Box>
					</Collapse>

					<Card sx={{ zIndex: "1100", ml: "0px !important" }} className='leaflet-control'>
						<CardContent>
							<Grid container spacing={0}>
								<Grid item xs={12}>
									<ImageSwiper />
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</div>
			</div>
		)
	);
}
const mapStateToProps = (state) => {
    return {
        parcelIdSelected: lodashGet(state, 'contextAppData.parcelIdSelected', -1),
        parcelDico: lodashGet(state, 'parcelsData.parcelDico', {}),
        clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1),
        settings: lodashGet(state, 'settingsData.settings', {}), // seul moyen trouvé pour rafraichir le visuel (traduction) du fait que maintenant on rentre dans l'application sans attendre les informations du client       
    };
}

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(SwipeablePanelForDesktop);
