import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-credit-cards';


const acceptedCards = ['visa','mastercard']

/* ↓↓ élément représentant l'image dynamique de la carte de crédit ↓↓ */
const CardShowed = ({ number, name, expiry, cvc, focused, scrambledNumber, placeholders, locale }) => (
    <Card
        number={number}
        name={name}
        expiry={expiry}
        cvc={cvc}
        focused={focused}
        locale={locale}
        placeholders={placeholders}
        acceptedCards={acceptedCards}
        preview={scrambledNumber}
    />
)

CardShowed.propTypes = {
    number: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    expiry: PropTypes.string.isRequired,
    cvc: PropTypes.string.isRequired,
    focused: PropTypes.string.isRequired,
    scrambledNumber: PropTypes.bool.isRequired,
    placeholders: PropTypes.object.isRequired,
    locale: PropTypes.object.isRequired,
}


export default CardShowed;
