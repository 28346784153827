import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


function IconeModulation(props: SvgIconProps) {
    return ( 
        <SvgIcon viewBox="0 0 512 512" {...props}>
            <svg width="512px" height="512px" viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
                <path d="M478 4510 c-168 -29 -336 -149 -410 -294 -44 -86 -60 -150 -65 -263
                -6 -110 -5 -112 20 -137 33 -33 71 -33 102 -1 22 21 25 33 25 102 0 43 5 98
                11 122 36 158 170 287 333 321 34 7 317 9 828 8 l778 -3 -96 -120 c-112 -141
                -158 -190 -239 -255 -185 -148 -378 -213 -669 -226 -214 -9 -293 -39 -393
                -147 -45 -49 -71 -95 -104 -183 -25 -66 -85 -136 -144 -167 -34 -18 -61 -22
                -172 -25 l-133 -4 0 151 c0 148 -1 153 -25 176 -13 14 -36 25 -50 25 -14 0
                -37 -11 -50 -25 l-25 -24 0 -1120 c0 -1209 0 -1198 53 -1331 14 -33 40 -86 58
                -118 44 -73 188 -217 263 -261 80 -48 200 -88 298 -101 58 -8 647 -10 1943 -8
                1780 3 1863 4 1925 22 270 77 473 274 551 536 21 70 23 97 27 415 3 336 3 341
                -18 368 -29 37 -80 38 -109 1 -19 -25 -21 -40 -21 -196 l0 -169 -387 3 -388 3
                -65 31 c-133 63 -193 156 -215 333 -17 133 -44 198 -116 270 -29 28 -77 63
                -108 78 -50 25 -67 28 -161 28 -93 0 -112 -3 -162 -27 -134 -62 -211 -177
                -228 -336 -6 -50 -14 -134 -20 -184 -17 -159 -83 -258 -209 -316 -51 -23 -72
                -26 -231 -32 -141 -6 -186 -11 -233 -28 -157 -56 -275 -183 -319 -341 -13 -47
                -18 -100 -18 -188 l0 -123 -190 0 c-159 0 -190 2 -190 14 0 8 -14 200 -30 426
                -21 280 -36 426 -46 458 -46 133 -153 215 -304 232 -89 10 -111 19 -157 63
                -27 25 -41 58 -79 174 -73 226 -171 352 -344 442 -114 60 -174 71 -412 78
                l-208 6 0 223 0 222 143 4 c139 3 143 4 217 39 119 57 198 147 240 275 26 78
                83 145 153 178 45 21 72 25 202 31 245 11 394 46 566 136 177 92 292 199 504
                467 l120 152 584 0 584 0 -6 -57 c-15 -168 -135 -316 -301 -373 -47 -16 -85
                -20 -185 -20 -171 -1 -248 -12 -326 -48 -311 -146 -309 -592 4 -745 l66 -32
                590 -6 c459 -5 608 -10 670 -22 364 -68 657 -233 906 -512 l89 -99 0 -114 c0
                -62 4 -121 10 -131 5 -10 22 -24 39 -30 24 -10 34 -10 59 3 16 8 32 23 36 31
                3 9 6 416 6 904 0 851 -1 891 -20 965 -50 195 -181 336 -380 408 l-65 23
                -2065 1 c-1136 1 -2086 -2 -2112 -6z m3524 -193 c25 -180 127 -368 264 -489
                140 -125 244 -170 551 -239 l153 -34 0 -435 0 -434 -67 65 c-288 280 -632 439
                -1032 479 -57 5 -329 10 -605 10 -482 0 -503 1 -546 21 -55 25 -105 71 -133
                124 -31 57 -30 173 0 231 30 57 79 103 133 127 37 17 78 21 235 27 104 4 208
                13 230 20 144 43 294 169 359 300 42 86 66 167 66 226 0 22 3 44 7 48 4 3 90
                5 192 4 l186 -3 7 -48z m641 38 c162 -42 281 -161 316 -316 10 -40 16 -329 7
                -329 -25 0 -362 86 -405 104 -186 75 -346 258 -396 451 -8 32 -15 70 -15 82
                l0 23 218 0 c161 0 233 -4 275 -15z m-4021 -1895 c177 -54 291 -183 353 -400
                44 -153 84 -219 163 -268 59 -37 98 -49 190 -61 91 -11 141 -42 171 -104 18
                -38 25 -94 51 -446 17 -222 30 -409 30 -417 0 -12 -40 -14 -240 -14 l-240 0
                -139 277 c-150 299 -199 372 -309 458 -120 95 -280 159 -429 172 l-73 6 0 408
                0 409 204 0 c175 0 213 -3 268 -20z m3003 -299 c48 -22 109 -86 124 -132 6
                -19 15 -74 20 -124 15 -141 60 -236 158 -332 62 -61 170 -116 257 -132 36 -7
                208 -11 424 -11 l365 0 -6 -92 c-3 -51 -14 -118 -24 -148 l-18 -55 -600 -5
                c-450 -5 -610 -9 -640 -19 -133 -43 -257 -155 -315 -284 l-35 -77 -538 0 -539
                0 4 138 c3 134 4 138 37 200 41 75 94 125 170 160 51 24 71 27 231 33 142 5
                186 11 234 28 114 41 205 116 269 224 43 71 63 155 77 322 6 72 16 147 22 168
                15 50 68 110 120 136 55 27 147 28 203 2z m-3266 -686 c125 -42 231 -117 306
                -217 38 -51 265 -482 265 -504 0 -13 -274 4 -330 21 -186 57 -334 191 -404
                367 -36 90 -46 152 -46 271 l0 100 68 -6 c37 -4 101 -18 141 -32z m4416 -553
                c-63 -60 -163 -118 -255 -148 -49 -16 -106 -18 -537 -22 -268 -2 -483 1 -483
                5 0 5 13 29 28 54 32 49 86 100 135 125 79 42 93 43 642 43 l530 0 -60 -57z"/>
                <path d="M1634 3270 c-62 -13 -189 -70 -241 -110 -65 -49 -127 -127 -154 -197
                -20 -49 -24 -76 -24 -173 1 -107 3 -119 31 -180 33 -69 224 -311 311 -391 140
                -131 326 -240 518 -304 133 -44 231 -49 335 -16 280 90 401 431 240 681 -51
                80 -113 127 -255 195 -150 71 -174 94 -250 229 -28 49 -74 113 -103 142 -107
                106 -260 153 -408 124z m213 -169 c66 -29 118 -85 185 -201 78 -134 136 -186
                290 -258 149 -71 178 -95 218 -176 101 -204 -44 -439 -268 -437 -186 0 -507
                174 -673 363 -112 128 -192 233 -215 283 -36 76 -34 173 6 253 17 33 48 75 68
                93 37 33 156 93 202 102 47 10 136 -1 187 -22z"/>
                </g>
            </svg>
        </SvgIcon> 
    );
}

export default IconeModulation;
