import React from 'react';

import {
    IconButton, Snackbar, Slide, Grid,
    ButtonBase, CircularProgress, Box
} from '@mui/material';
import {
    Close, CropSquare, ExitToApp, Help, Info
} from '@mui/icons-material'

import getTheme from "../themes/index.js";


export const SnackbarDataContent = {
    info: 1,
    redirectImport: 2,
    newimage: 3,
    help: 4,
};

let theme = getTheme();

/**
 * Composant snackbar customisé
 */
function CustomSnackbar({ snackbarDatas, anchorOrigin, removeSnackbar }) {
    return (
        <Snackbar
            open={Boolean(snackbarDatas)}
            TransitionComponent={Slide}
            transitionDuration={{ enter: 500, exit: 500 }}
            autoHideDuration={(snackbarDatas.time > 0) ? snackbarDatas.time : null}
            onClose={removeSnackbar}
            ClickAwayListenerProps={{ mouseEvent: false }}
            sx={{
                '& .MuiSnackbarContent-message': {
                    width: "80%"
                }
            }}
            message={
                <ButtonBase onClick={(snackbarDatas.onclick) ? snackbarDatas.onclick : null}>
                    <Grid container>
                        <Grid item xs={2}>
                            {(snackbarDatas.content === SnackbarDataContent.info) &&
                                <Box sx={{ position: "relative", display: "inline-flex" }}>
                                    <CircularProgress sx={{
                                        color: theme.palette.primary.light
                                    }} />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: "absolute",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <Info />
                                    </Box>
                                </Box>
                            }
                            {(snackbarDatas.content === SnackbarDataContent.redirectImport) && <ExitToApp />}
                            {(snackbarDatas.content === SnackbarDataContent.newimage) &&
                                <CropSquare sx={{ color: theme.palette.contour.new }} />
                            }
                            {(snackbarDatas.content === SnackbarDataContent.help) && <Help />}
                        </Grid>
                        <Grid item xs={10}>
                            {snackbarDatas.text}
                        </Grid>
                    </Grid>
                </ButtonBase>
            }
            action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={removeSnackbar}>
                    <Close />
                </IconButton>
            }
            style={{ position: 'absolute', pointerEvents: 'auto', top: "72px" }} // ⚠️ - important
            anchorOrigin={anchorOrigin}
        />
    )
}

export default CustomSnackbar;