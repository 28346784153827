import React from "react";
import { Typography, Card, CardContent, Grid } from "@mui/material";
import CursorGrab from '../../../assets/images/didacticiel/cursor_grab.tsx';
import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';
import getTheme from "../../../themes/index.js";

let theme = getTheme();

/**
 * Carte descriptive utilisation carte 
 */
function CardExplainMoveMap(props) {

    return (
        <Card 
            sx={{ 
                background: theme.palette.grey[50],
                border: '1px solid',
                borderColor: theme.palette.grey[100],
                display: 'flex',
                height: '100px'
            }}
        >
            <CardContent sx={{ display: 'flex', p:2 }}>
                <Grid container spacing={1}>
                    <Grid item xs={4} sx={{ display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <CursorGrab sx={{ fontSize:"40px" }} />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="subtitle2">{StringTranslate.dessinmovemap}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default CardExplainMoveMap;