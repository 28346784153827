import React from "react";
import { Box } from "@mui/material";
import getTheme from "../../themes";

const theme = getTheme(); /* Permet d'obtenir le thme de berry */

/**
 * Composant pastille colorée
 */
class SlideRangeMarker extends React.Component {
  render() {
    const { label } = this.props;

    return (
      <Box
        sx={{
          width: 10,
          height: 10,
          background: () => {
            switch (label) {
              case 'IN_RANGE':
                return theme.palette.success.main;
              case 'IN_EXTRA_RANGE':
                return theme.palette.ndviColors.moyenne;
              default: // Out of range...
                return theme.palette.ndviColors.faibleMoyenne;
            }
          },
          borderRadius: '50%',
        }}
      />
    )
  }
}

export default SlideRangeMarker