import React, { useCallback } from "react";
import { Button } from "@mui/material";
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { StepEnumDidacticielFirstParcel } from '../../../redux/actions/didacticiel.js';
import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';

/**
 * Composant affiché sur la carte permettant de revenir sur l'étape de choix
 */
function BackToMenuButton(props) {

    const onclick = useCallback(() => props.goToStep(StepEnumDidacticielFirstParcel.CHOIX), [props])

    return (
        <Button
            sx={{
                pointerEvents: 'all',
                width:'185px',
                height: '104px'
            }} variant="contained" color="primary" onClick={onclick}>
            <KeyboardReturnIcon />
            {StringTranslate.gobacktostepchoix}
        </Button>
    )
}

export default BackToMenuButton;