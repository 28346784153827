import React from "react";
import lodashGet from 'lodash/get';
import lodashSort from 'lodash/sortBy';
import lodashFind from 'lodash/find';
import lodashSome from 'lodash/some';
import { connect } from 'react-redux';

import { createFilterOptions } from '@mui/material/Autocomplete';

/* Composants MUI */
import {
    Grid, TableContainer, Button, TextField,
    InputAdornment, Box, Typography, IconButton, FormControl, MenuItem,
    Select, CircularProgress, Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions,
    DialogContentText,
    Alert,
    Card,
} from '@mui/material';

/* Composants React */
import AlertDialog from "../../alertDialog";
import LinkToContactUs from '../../linkToContactUs';
import CustomDataGrid from '../../customDataGrid';
import { ThumbnailParcelShapeFromPathInfos } from '../../thumbnail/ThumbnailParcelShape.jsx';
import VarietyForm from '../varietyForm.jsx';
import CustomDatePicker from '../../customDatePicker.jsx';

import StringTranslate from '../../../assets/i18n/stringLanguage.jsx';

/* Icones */
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import WarningIcon from "@mui/icons-material/WarningAmberRounded";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

/* Helpers et Dico */
import { ParcelsHelper } from '../../../utils/parcelsHelper';
import { HarvestHelper } from '../../../utils/harvestHelper';
import dicoFunction from '../../../datas/dicoDetails';
import stringHelper from '../../../utils/stringHelper';
import DateHelper from '../../../utils/dateHelper';

/* Redux */
import { HarvestStep, ActionGoToStepOfHarvest, ActionGenerateResults, ActionSelectIdsParcelsToStartManagement, Crop, CornType, ActionSaveHarvests, 
    ActionClearHarvestMessageError, ActionOpenPopupVarietyForm, ActionGetParcelIdFocused, ActionResetParcelAndVarietyIdsSaved } from '../../../redux/actions/harvest';
import { TableType, /* UpdateNbRowsPerPageTable */ } from '../../../redux/actions/settings';
import { ProfilIndex, ActionShowProfilMenuDialog } from "../../../redux/actions/contextApp";

/* css */
import '../../../assets/css/renderParcels.css';
import '../../../assets/css/pdp-pinDropPopup.css';

/* theme Berry */
import getTheme from "../../../themes/index.js";


let theme = getTheme();
const filter = createFilterOptions();

const defaultHarvest = { id: 0, label: `${StringTranslate.selectItem}`, isGrain: true, isSilage: true, defaultCornDryingTarget: 0, };

/**
 * Composant d'affichage des parcelles actuelles.
 */
class SpecificInfosManagement extends React.Component {

    constructor(props) {
        super(props);

        
        const errorHarvest = lodashGet(props, 'errorHarvest', undefined);

        this.state = {
            searchFilter: '', //Zone de recherche du tableau
            currentRowsOfTable: [], //Datas en fonction du texte tapé dans la zone de recherche

            datas: this.initialiseDatas(props.parcelIdsSelected), //parcelles sélectionnées à l'étape 1

            //sauvegarde la langue en cours lors de la création du composant: (Car pas présente dans le store Redux)
            language: StringTranslate.getLanguage(),

            missingDatasToStartManagement: false, //Précise à l'utilisateur les données nécessaire pour commencer le calcul

            openRegisteringDialog: false, //Signale que la dialog d'enregistrement est ouverte ou fermée 
            datasToSave: [], // données à sauvegarder avant retour à l'étape 1

            generateResultsDoneCorrectly: false, //Permet d'afficher le message disant que la génération des dates de récolte s'est bien passée.

            loadingVarietiesCharacteristics: [],

            loadingVarieties: this.initializeLoadingVarieties(), // Permet de savoir si les variétés sont en cours de chargement
            
            openDialogOfErrors: (errorHarvest !== undefined),
            variety: [] //Pour la création de variété
        };

        this.columns = this.initialiseColumns();//props.parcelIdsSelected); //RQ: pas dans le 'state' car on en utilise des propriétés !
        this.CustomToolbar = this.CustomToolbar.bind(this);

        // Définition du popup contenant le message d'erreur à diffuser au client:
		this.popupErrorDialog = {
			getTitle: () => { return (<ErrorOutline />); },
			description: errorHarvest, //Rq : sera mis à jour en fonction de la prop 'errorHarvest' !
			getAdditionalDescription: () => {
				return (
					<Typography variant="subtitle1">
						{StringTranslate.errorDialogText2} <LinkToContactUs displayText={StringTranslate.contactUs} />. 
					</Typography>
				);
			},
			button: StringTranslate.close,
		};
    }

    componentDidUpdate(prevProps, prevState) {
        const { language, datas } = this.state;
        const { harvestVarietyCharacteristicsDicoCounter, parcelIdsSelected, specificInfosParcelIdsDone, selectIdsParcels, 
            parcelVarietiesMapping, errorHarvest, addingVariety, parcelIdFocused, lastAddedVariety, resetParcelAndVarietyIds } = this.props;

        //gestion de cas d'erreur:
        if (((!prevProps) || (!prevProps.errorHarvest) || (prevProps.errorHarvest === '') || (prevProps.errorHarvest === undefined)) &&
        (errorHarvest && (errorHarvest !== '') && (errorHarvest !== undefined))) {
            this.popupErrorDialog.description = errorHarvest;

            this.setState({
                openDialogOfErrors: true,
            });
        }

        /* Lorsqu'il y a un changement de langue (même si on se doute que l'utilisateur ne changera pas de langue tous les 4 matins !), il faut actualiser certaines listes: */
        const currentLanguage = StringTranslate.getLanguage();
        if ((!language) || (language === '') || (language !== currentLanguage) ||
            (prevProps.harvestVarietyCharacteristicsDicoCounter !== harvestVarietyCharacteristicsDicoCounter) ||
            (prevProps.specificInfosParcelIdsDone !== specificInfosParcelIdsDone) || ((prevProps.addingVariety === true) && (addingVariety === false))) {
            
            //on met à jour les parcelIdsSeleted. On enlève les specificInfosParcelIdsDone dans la liste des parcelIdsSelected :
            let newParcelIdsSelected = [];
            let newDatas = datas;

            if (prevProps.specificInfosParcelIdsDone !== specificInfosParcelIdsDone) {
                specificInfosParcelIdsDone.forEach(parcelId => {
                    newParcelIdsSelected = parcelIdsSelected.filter(parcelIdSelected => 
                        parcelIdSelected !== parcelId
                    );

                    newDatas = newDatas.filter(data =>
                        data.parcelId !== parcelId
                    );
                });
                selectIdsParcels(newParcelIdsSelected);

                // Réactualisation du tableau de données :
                if (specificInfosParcelIdsDone.length > 0) {
                    this.setState({
                        datas: newDatas
                    });
                }
            }
            
            this.columns = this.initialiseColumns();//parcelIdsSelected); //RQ: ca va que l'on actualise 'cultures' sans quoi le rendu n'aurai pas lieu et on n'utiliserai pas la nouvelle liste de noms de colonne !

            this.setState({ language: currentLanguage, });
        }

        if (prevProps.parcelVarietiesMapping !== parcelVarietiesMapping) {
        
            Object.entries(parcelVarietiesMapping).forEach(([key, value]) => {
                const parcelId = Number(key);
                const varietyId = Number(value);

                // Si la variété est sur notre ecran alors on la met a jour
                if (parcelIdsSelected.includes(parcelId)) {
                    this.updateCharacteristicsByVarietyId(parcelId, varietyId);
                }
            });

            this.setState({
                loadingVarieties: this.initializeLoadingVarieties()
            });
        }

        //Contrôle si le client ajoute une variété sur la deuxième étape du 1er onglet
        // Si le compteur du dico à changer et qu'on a un identifiant de parcelle supérieur à 0
        if((prevProps.harvestVarietyCharacteristicsDicoCounter !== harvestVarietyCharacteristicsDicoCounter) && (parcelIdFocused > 0)){

            //On va récupérer les infos de la ligne où on souhaite ajouter une variété
            const parcelFocused = datas.find(data => data.id === parcelIdFocused);

            //Vérification que la variété possède au moins un objectif de récolte qui correspond au type de récolte souhaité pour la récolte
            const hasOnlyDifferentHarvestType = lastAddedVariety.varietyInfos.every(variety => variety.harvestType !== parcelFocused.cornType);     

            //Si ce n'est pas le cas on change le type de récolte de la parcelle pour qu'elle correspoande
            if (hasOnlyDifferentHarvestType) {
                parcelFocused.cornType = (parcelFocused.cornType === 1) ? 2 : 1;
            }

            //On sélectionne la variété dernièrement ajoutée à la parcelle dircetement
            this.handleChangeharvestVarietyCharacteristics(parcelIdFocused, parcelFocused.cornType, lastAddedVariety)

            //On reset les props liées à la sélection de la variété
            resetParcelAndVarietyIds();
        }
    }

    goBackToParcelsChoice = () => {
        const { datas } = this.state;
        const { generatingHarvestDates, goToNextStep, parcelIdsSelected, 
            harvestVarietyCharacteristicsDico, currentCrop, harvestDico } = this.props;
        
        if (generatingHarvestDates === true) {
            //On n'enregistre pas les données quand une génération de dates est en cours.
            //On retourne directement à l'étape 1.
        }
        else {
            //On enregistre les données quand aucune génération n'est en cours.
            if (parcelIdsSelected.length > 0) {
                let datasToSave = [];
                datas.forEach(data => {//pour éviter l'avertissement suivant : "Function declared in a loop contains unsafe references to variable(s) 'sowingDate'" !
                    let element = {
                        id: data.id,
                        clientId: data.clientId,
                        parcelId: data.parcelId,
                        isGrain: (data.cornType === CornType.None) ?
                            undefined :
                            (data.cornType === CornType.Ensilage ?
                                false : true),
                        dryOrHumidityThreshold: data.percentage,
                        //TotalDegreeDaysUse: ..., //Rq : pas utile à transmettre car définit côté API ! 
                        harvestVarietyId: (data.harvestVarietyId <= defaultHarvest.id) ? defaultHarvest.id : data.harvestVarietyId,
                        harvestVarietyInfoId: (data.harvestVarietyInfoId <= 0) ? 0 : data.harvestVarietyInfoId,
                        varietyLabel: (data.harvestVarietyId <= defaultHarvest.id) ? null : harvestVarietyCharacteristicsDico[data.harvestVarietyId].label,
                        harvestCrop: {
                            enumCrop: currentCrop,
                        },
                        sowingDate: (!isNaN(new Date(data.sowingDate)) && (data.sowingDate !== null) && (data.sowingDate !== undefined)) ? new Date(data.sowingDate) : null,
                        floweringDate: (!isNaN(new Date(data.floweringDate)) && (data.floweringDate !== null) && (data.floweringDate !== undefined)) ? new Date(data.floweringDate) : null,
                        estimatedDate: null,
                        degreeOfCertainty: null,
                        generationDate: null,
                    };

                    let harvestFound = lodashGet(harvestDico, `[${data.parcelId}]`, undefined);
                    let isSameData = false;
                    if (harvestFound) {
                        isSameData = HarvestHelper.isSameHarvestData(element, harvestFound);
                    }
                    if (isSameData === false) {
                        datasToSave.push(element);
                    }
                })

                if (datasToSave.length > 0) {
                    //Ouverture de la dialog d'enregistrement ou pas des données
                    this.setState({
                        openRegisteringDialog: true,
                        datasToSave: datasToSave,
                    });
                }
                else {
                    //Redirection vers létape 1 : 
                    if (goToNextStep) {
                        goToNextStep(HarvestStep.CHOIX_PARCELS);
                    }
                }
            }
        }
    }

    /**
     * Fonction permettant de mettre a jour une ligne avec une varieté reçu de la webApi
     * @param {Number} parcelId 
     * @param {Number} varietyId 
     */
    updateCharacteristicsByVarietyId = (parcelId, varietyId) => {
        const { parcelDico, harvestVarietyCharacteristicsDico } = this.props;

        const newVariety = lodashGet(harvestVarietyCharacteristicsDico, varietyId, defaultHarvest);

        // on a resultat
        if (newVariety !== defaultHarvest) {
            const parcel = ParcelsHelper.selectParcelFromDicoById(parcelDico, parcelId);

            let parcelCrop = parcel.details.culture;

            // Si on a quelque chose de sauvegardé en base
            let cornType = HarvestHelper.getCornTypeByVariety(newVariety, parcelCrop);
            
            this.handleChangeCornType(parcelId, cornType, () => {
                if (cornType !== CornType.None) {
                    this.handleChangeharvestVarietyCharacteristics(parcelId, cornType, newVariety);
                }
            });  
        }
    }

    /**
     * Méthode permettant de mettre à jour la date de semi d'une parcelle
     * @param {number} parcelId id de la parcelle pour laquelle il faut changer la date de semi
     * @param {Date} newSowingDate nouvelle date de semi
     */
    handleChangeSowingDate(parcelId, newSowingDate) {

        const newDatas = this.state.datas.map(data => {
            if (data.parcel.id === parcelId) {
                return {
                    ...data,
                    sowingDate: ((newSowingDate !== undefined) && (newSowingDate !== null) && (newSowingDate !== "")) ? new Date(newSowingDate) : null
                };
            }
            return data;
        });

        this.setState({
            datas: newDatas,
            missingDatasToStartManagement: false
        });
    }

    /**
     * Méthode permettant de mettre à jour la date de floraison d'une parcelle de maïs
     * @param {number} parcelId id de la parcelle pour laquelle il faut changer la date de floraison d'une parcelle de maïs
     * @param {Date} newFloweringDate nouvelle date de floraison
     */
    handleChangeFloweringDate(parcelId, newFloweringDate) {
        const newDatas = this.state.datas.map(data => {
            if (data.parcel.id === parcelId) {
                return { 
                    ...data, 
                    floweringDate: ((newFloweringDate !== undefined) && (newFloweringDate !== null) && (newFloweringDate !== "")) ? new Date(newFloweringDate) : null
                };
            }
            return data;
        });

        this.setState({
            datas: newDatas,
            missingDatasToStartManagement: false
        });
    }

    /**
     * Méthode permettant de mettre à jour le type de culture d'une parcelle de maïs
     * @param {number} parcelId id de la parcelle pour laquelle il faut changer le type de culture d'une parcelle de maïs
     * @param {Object} newType nouveau type de culture
     * @param {() => void} callback fonction lancé après la mise a jour de cornType
     */
    handleChangeCornType(parcelId, newType, callback) {
        const { getAllVarietiesCharacteristics, clientId, varietiesCharacteristicsAlreadyLoaded } = this.props;

        //Si les variétés ne sont pas encore chargées, on les charge quand le client décide de choisir un type de maïs
        if (varietiesCharacteristicsAlreadyLoaded === false) {
            getAllVarietiesCharacteristics(clientId);
        }

        //Toutes les fois que l'on change le type de maïs, on déselectionnne la variété.
        const newDatas = this.state.datas.map(data => {
            if (data.parcel.id === parcelId) {
                return {
                    ...data,
                    cornType: newType,
                    harvestVarietyId: defaultHarvest.id,
                    harvestVarietyInfoId: defaultHarvest.id,
                    percentage: 0,
                };
            }
            return data;
        });

        this.setState({
            datas: newDatas,
            missingDatasToStartManagement: false
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    /**
     * Méthode permettant de mettre à jour la variété de culture d'une parcelle de maïs
     * @param {number} parcelId id de la parcelle pour laquelle il faut changer la variété de culture d'une parcelle de maïs
     * @param {cornType} cornType type de maïs
     * @param {Object} newVariety nouvelle variété
     */
    handleChangeharvestVarietyCharacteristics(parcelId, cornType, newVariety) {
        
        let varietyId = lodashGet(newVariety, "id", defaultHarvest.id);
        // Si il s'agit de la variété à ajouter ("Ajouter 'MaVariété'")
        if (varietyId === -1) {
            const { getParcelIdFocused, openPopupVarietyForm } = this.props;

            let varietyLabel = lodashGet(newVariety, "inputValue", "");
            this.setState({
                variety:{
                    varietyId: defaultHarvest.id,
                    label: varietyLabel,
                },
                action: "add"
            })
    
            //On envoie l'id de la parcelle à laquelle on souhaite ajouter une variété pour qu'elle puisse être seléctionné au retour 
            getParcelIdFocused(parcelId);
            openPopupVarietyForm(true);

            return;
        }

        const newDatas = this.state.datas.map(data => {
            if (data.parcel.id === parcelId) {
                
                let percentageToShow = 0;
                let varietyInfoId = 0;
                
                let varietyInfos = lodashGet(newVariety, "varietyInfos", []);
                let varietyInfosForThisCornType = varietyInfos.filter(vi => vi.harvestType === cornType);
                if (varietyInfosForThisCornType.length === 1) {
                    let varietyInfo = lodashGet(varietyInfosForThisCornType, '[0]', {});
                    percentageToShow = lodashGet(varietyInfo, 'percentageHarvestTarget', 0);
                    varietyInfoId = lodashGet(varietyInfo, 'id', defaultHarvest.id);
                }
                // Sinon
                // Soit on en a pas => pas normal mais on peut rien faire
                // Soit on en a plus => on ne peut pas faire le choix à la place du client, donc on le laisse choisir
                
                return {
                    ...data,
                    harvestVarietyId: varietyId,
                    harvestVarietyInfoId: varietyInfoId,
                    percentage: percentageToShow,
                };
            }
            return {...data};
        });

        this.setState({
            datas: newDatas,
            missingDatasToStartManagement: false
        });
    }

    handleChangeharvestVarietyCharacteristicsPercentage(parcelId, event) {
        const { harvestVarietyCharacteristicsDico } = this.props; 

        const newValue = event.target.value;

        const newDatas = this.state.datas.map(data => {
            if (data.parcel.id === parcelId) {
                const variety = harvestVarietyCharacteristicsDico[data.harvestVarietyId];

                //Rq : Si on est dans le cas du maïs grain, alors on n'est pas sencé passer ici !
                const varietyInfo = lodashFind(variety.varietyInfos, vi => vi.id === newValue);

                return {
                    ...data,
                    harvestVarietyInfoId: newValue,
                    percentage: (newValue > 0) ? varietyInfo.percentageHarvestTarget : 0,
                };
            }
            return data;
        });

        this.setState({
            datas: newDatas,
            missingDatasToStartManagement: false
        });
    }

    handleChangeCornDryingRate(parcelId, event) { //Cas uniquement pour du Maïs grain !
        //on gère alors une valeur qui ne corresponds pas à une info de variété, mais à un objectif réel de récolte !
        const { harvestVarietyCharacteristicsDico } = this.props; 

        const newValue = event.target.value;

        const newDatas = this.state.datas.map(data => {
            if (data.parcel.id === parcelId) {
                const variety = harvestVarietyCharacteristicsDico[data.harvestVarietyId];

                //Rq : dans le cas du maïs grain, on sélectionne la première caractèristique type grain ! 
                // (la Web API fera la mise au point avant de lancer l'estimation, en prenant en compte l'objectif de récolte réel)
                const varietyInfo = lodashFind(variety.varietyInfos, vi => (vi.harvestType === CornType.Grain));

                return {
                    ...data,
                    harvestVarietyInfoId: lodashGet(varietyInfo, 'id', defaultHarvest.id),
                    percentage: (newValue > 0) ? newValue : defaultHarvest.defaultCornDryingTarget,
                };
            }
            return data;
        });

        this.setState({
            datas: newDatas,
            missingDatasToStartManagement: false
        });
    }

    /* Fonction correspondant à la zone de recherche */
    requestSearch(searchValue, rowsOfTable) {
        const { harvestVarietyCharacteristicsDico } = this.props;

        this.setState({
            searchFilter: searchValue
        });

        try {
            const searchRegex = new RegExp(stringHelper.escapeRegExp(searchValue), 'i');
            const filteredRows = rowsOfTable.filter((row) => {
                return Object.keys(row).some((field) => {
                    let textValue = "";
                    if (field === "harvestVarietyId") {
                        let varietyFound = Object.values(harvestVarietyCharacteristicsDico).find(variety => variety.id === row[field]);
                        if (varietyFound !== undefined) {
                            textValue = "" + varietyFound.label;
                        }
                        else {
                            textValue = "" + row[field];
                        }
                    }
                    else if (field === "cornType") {
                        textValue = "" + ((row[field] === CornType.Ensilage) ? "Ensilage" : ((row[field] === CornType.Grain) ? "Grain" : CornType.None));
                    }
                    else {
                        textValue = "" + row[field];
                    }
                    
                    return searchRegex.test(textValue.toString());
                });
            });

            this.setState({
                currentRowsOfTable: filteredRows
            });
        }
        catch (errRegex) { /* Peut arriver si on saisis un truc du genre 'EARL++' ! (il n'aime pas les '++') */ }
    }

    //Toolbar customisé du tableau comprenant :
    // - la zone de recherche
    // - Texte pour le chargement des données
    CustomToolbar = (props) => {
        const { showProfilMenuDialog, loadingVarietiesCharacteristics, generatingHarvestDates, parcelIdsSelected } = this.props;
        const { missingDatasToStartManagement, generateResultsDoneCorrectly } = this.state;

        return (
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Grid container spacing={1}>
                        {/* Zone de recherche */}
                        <Grid item xs={8} sm={8} md={4} lg={4}>
                            {/* Zone de recherche */}
                            <TextField
                                value={props.value}
                                onChange={props.onChange}
                                placeholder={StringTranslate.toolbarSearchNameCornTypeAndVariety}
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>),
                                    endAdornment: (
                                        <IconButton
                                            title="Clear"
                                            aria-label="Clear"
                                            size="small"
                                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                                            onClick={props.clearSearch}
                                        >
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={8}>
                            <Box style={{ textAlign: 'end' }} >
                                <Button
                                    variant="contained"
                                    onClick={() => props.onStartManagement()}
                                    startIcon={(generatingHarvestDates === true) ? <CircularProgress color="inherit" size={20} /> : null}
                                    disabled={loadingVarietiesCharacteristics || (generatingHarvestDates === true) || (parcelIdsSelected.length <= 0)}
                                >
                                    {StringTranslate.accessToEstimationDates}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Bouton d'aide */}
                <Grid item xs={12}>
                    <Button color="secondary" variant="text" size="small" onClick={() => showProfilMenuDialog(ProfilIndex.aide_HarvestCorn)}>
                        {StringTranslate.helpAsk}
                    </Button>
                </Grid>
                {/* Texte pour le chargement des données */}
                {(loadingVarietiesCharacteristics === true) &&
                    <Grid item xs={12}>
                        <Typography variant="body2" color="secondary" gutterBottom><CircularProgress color="inherit" size={20} /> <i>{StringTranslate.loadingCropVarieties}</i></Typography>
                    </Grid>
                }
                {/* Données non remplies pour démarrer le calcul */}
                {(missingDatasToStartManagement === true) &&
                    <Grid item xs={12}>
                        <Card>
                            <Alert
                                severity="warning"
                                icon={<WarningIcon />}
                                sx={{backgroundColor: theme.palette.warning.light}}
                            >
                                <Typography>{StringTranslate.errorHarvestGenerate1}</Typography>
                                <Typography>{StringTranslate.errorHarvestGenerate2}</Typography>
                            </Alert>
                        </Card>
                    </Grid>
                }

                {/* Données non remplies pour démarrer le calcul */}
                {(generateResultsDoneCorrectly === true) && (generatingHarvestDates === false) &&
                    <Grid item xs={12}>
                        <Card>
                            <Alert
                                severity="info"
                                icon={<InfoOutlined />}
                            >
                                <Typography>{StringTranslate.datesGenerated}</Typography>
                            </Alert>
                        </Card>
                    </Grid>
                }
            </Grid>
        );
    }

    /**
     * Initialisation des colonnes de récoltes.
     */
    initialiseColumns() {//parcelIdFilteredList) {
        const { /*parcelDico,*/ harvestVarietyCharacteristicsDico, harvestVarietyCharacteristicsDicoCounter,
        currentCrop, cornMinDryingTarget, cornMaxDryingTarget, cornDryingList, cornDryingCounter } = this.props;

        let harvestVarietyCharacteristicsSilageDico = {};
        let harvestVarietyCharacteristicsGrainDico = {};

        if (harvestVarietyCharacteristicsDicoCounter > 0) {

            for (const [/*key*/, value] of Object.entries(harvestVarietyCharacteristicsDico)) {
                if (lodashSome(value.varietyInfos, ['harvestType', CornType.Ensilage]))
                    harvestVarietyCharacteristicsSilageDico[value.id] = value;

                if (lodashSome(value.varietyInfos, ['harvestType', CornType.Grain]))
                    harvestVarietyCharacteristicsGrainDico[value.id] = value;
            }

            //Tri des dicos de variété en fonction du label
            harvestVarietyCharacteristicsGrainDico = lodashSort(harvestVarietyCharacteristicsGrainDico, ['label'], ['desc']);
            harvestVarietyCharacteristicsSilageDico = lodashSort(harvestVarietyCharacteristicsSilageDico, ['label'], ['desc']);
            // harvestVarietyCharacteristicsGrainDico[0] = defaultHarvest;
            // harvestVarietyCharacteristicsSilageDico[0] = defaultHarvest;
            //harvestVarietyCharacteristicsDico[0] = defaultHarvest;
        }

        let harvestVarietyCharacteristicsGrainDicoCounter = Object.keys(harvestVarietyCharacteristicsGrainDico).length - 1; //- 1 car on a rajouté un defaultHarvest 
        let harvestVarietyCharacteristicsSilageDicoCounter = Object.keys(harvestVarietyCharacteristicsSilageDico).length - 1;

        let newColumns = [];
        //if (parcelIdFilteredList && parcelIdFilteredList[0]) {
            /* ↓ ajout colonne visible - (Miniature + nom de la parcelle) ↓ */
            newColumns.push({
                headerName: `${StringTranslate.nomcolumn}`,
                field: "parcelName",
                minWidth: 190,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.nomcolumn}</Typography>)
                },
                renderCell: params => {
                    return (
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                            {(params) ? (<ThumbnailParcelShapeFromPathInfos id={`listItemTbl_${params.row.thumbnailInfos.parcelId}`} {...params.row.thumbnailInfos} />) : (undefined)}
                            <Typography>{params.value}</Typography>
                        </Box>
                    )
                }
            });

            /* ↓ ajout colonne visible - (type de maïs) ↓ */
            newColumns.push({
                headerName: `Type de maïs`,
                field: "cornType",
                minWidth: 150,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.cornType} *</Typography>)
                },
                renderCell: params => {
                    return (
                        <Box
                            style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                            sx={{ width: "100%" }}
                        >
                            <FormControl fullWidth size="small" error={params.value <= 0}>
                                <Select
                                    labelId="select-cornType"
                                    id="select-type"
                                    value={params.value}
                                    label=""
                                    onChange={(event) => this.handleChangeCornType(params.row.parcelId, event.target.value)}
                                >
                                    {(params.value === 0) && <MenuItem value={0} disabled><em>{StringTranslate.selectItem}</em></MenuItem>}
                                    <MenuItem value={CornType.Ensilage}>{StringTranslate.ensilage}</MenuItem>
                                    <MenuItem value={CornType.Grain}>{StringTranslate.grain}</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    )
                }
            });

            /* ↓ ajout colonne visible - (caractéristiques de la variété de la culture) ↓ */
            newColumns.push({
                headerName: `Variété de culture`,
                field: "harvestVarietyId",
                minWidth: 180,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.harvestVariety} *</Typography>)
                },
                renderCell: params => {
                    return (
                        <Box
                            style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                            sx={{ width: "100%" }}
                        >
                            <FormControl fullWidth size="small">
                                <Autocomplete
                                    id="select-variety-id"
                                    value={(params.value === defaultHarvest.id) ? null : harvestVarietyCharacteristicsDico[parseInt(params.value)]}
                                    onChange={(event, newValue) => this.handleChangeharvestVarietyCharacteristics(params.row.parcelId, params.row.cornType, newValue)}
                                    disabled={(harvestVarietyCharacteristicsDicoCounter <= 0) || (params.row.cornType <= 0)}
                                    getOptionLabel={(value) => value.label}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        const { inputValue } = params;

                                        //Vérifie si la variété que le client écrit existe dans le dico
                                        const isExisting = options.some((option) => inputValue === option.label);
                                        if (inputValue !== '' && !isExisting) { 
                                            //Si ce n'est pas le cas alors on lui propose de la créer
                                            filtered.push({
                                                inputValue,
                                                label: `${StringTranslate.ajouter3} "${inputValue}"`,
                                                id: -1,
                                            });
                                        }

                                        return filtered;
                                    }}
                                    getOptionKey={(value) => value.id}
                                    options={
                                        (params.row.cornType === CornType.Ensilage) && (harvestVarietyCharacteristicsSilageDicoCounter > 0) ?
                                            harvestVarietyCharacteristicsSilageDico
                                            :
                                            (params.row.cornType === CornType.Grain) && (harvestVarietyCharacteristicsGrainDicoCounter > 0) ? harvestVarietyCharacteristicsGrainDico : []
                                    }
                                    renderOption={(props, option) => (
                                        <li {...props} key={`${option.id}-${params.row.parcelId}`}>
                                            {(option.id === -1) ?
                                                //Bouton qui permet d'ouvrir la pop up de création de la variété
                                                <Button color="primary" variant="contained" >{option.label}</Button> :
                                                option.label}
                                        </li>
                                    )}
                                    renderInput={(inputParams) => {
                                        const loading = this.state.loadingVarieties.includes(params.row.parcelId); 

                                        return (
                                            <TextField
                                                {...inputParams}
                                                error={inputParams.inputProps.value === defaultHarvest.label}
                                                size="small"
                                                label=""
                                                placeholder={defaultHarvest.label}
                                                /* Le composant <Autocomplete /> permet généralement de saisir des espaces dans le champ de saisie. 
                                                Ici, il est ajouté dans une définition de colonne <DataGrid /> via renderCell, 
                                                tout fonctionne à l'exception de la possibilité de saisir un espace, ce qui empêche le filtre.
                                                D'où la nécessité de rajouter "onKeyDown" : */
                                                onKeyDown={(e) => {
                                                    e.key === " " && e.stopPropagation();
                                                  }}
                                                InputProps={{
                                                    ...inputParams.InputProps,
                                                 endAdornment: (
                                                        <React.Fragment>
                                                           {loading ? <CircularProgress color="primary" size={20} /> : null}
                                                            {inputParams.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    )
                                                }}
                                            />
                                       )
                                    }}
                                /> 
                            </FormControl>
                        </Box>
                    )
                }
            });

            newColumns.push({
                headerName: `dry`,
                field: "harvestVarietyInfoId",
                minWidth: 170,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold'>{StringTranslate.percentage} *</Typography>)
                },
                renderCell: params => {
                    //liste des variétés appropriées:
                    const dicoOfVarieties = (params.row.cornType === CornType.Ensilage) ? 
                        harvestVarietyCharacteristicsSilageDico : harvestVarietyCharacteristicsGrainDico;
                    //culture et variété trouvées (en fonction du varietyId)
                    const enumCrop = lodashGet(params, 'row.harvestCrop.enumCrop', currentCrop);
                    const variety = lodashFind(dicoOfVarieties, (v) => { return v.id === params.row.harvestVarietyId; });
                    const varietyId = lodashGet(variety, 'id', defaultHarvest.id);
                    const varietyInfos = lodashGet(variety, 'varietyInfos', []).filter(vi => params.row.cornType === vi.harvestType);

                    //Personnalisation du rendu suivant s'il s'agit Soit du maïs grain (Soit autres cas)
                    // Rq:on aurai pu faire le choix technique de gérer toutes les possibilités de % en multipliant les 'HarvestVarietyInfo' (avec faux 'Id'), de façon à ne rien changer côté Front.
                    // Mais: cela signifie énormémant de fausses données fournis par la Web API juste pour visuellement quelques % sélectionnables !
                    if (((enumCrop === Crop.Corn) && (params.row.cornType === CornType.Grain)) && 
                        (cornDryingList !== undefined) && (cornDryingCounter > 0)) { //si on n'a pas la plage de % d'objectifs possibles, on gére comme du maïs ensilage !
                        const labelPercentageHarvestTarget = StringTranslate.humidity;
                        const cornDryTarget = lodashGet(params, 'row.percentage', undefined);

                        //Rq: Dans ce cas, on a reçu un entier en guise de valeur dans 'percentage' (et pas une info de variété)
                        return (
                            <Box
                                style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                                sx={{ width: "100%" }}
                            >
                                <FormControl fullWidth size="small">
                                    <Select
                                        error={(cornDryTarget === undefined) || (cornDryTarget < cornMinDryingTarget) || (cornDryTarget > cornMaxDryingTarget)}
                                        id="select-percentage"
                                        value={(cornDryTarget === undefined) ? defaultHarvest.defaultCornDryingTarget : cornDryTarget}
                                        label=""
                                        onChange={(newValue) => this.handleChangeCornDryingRate(params.row.parcelId, newValue)}
                                        disabled={(harvestVarietyCharacteristicsDicoCounter <= 0) || (varietyId === defaultHarvest.id)}
                                        MenuProps={{ PaperProps: { style: { maxHeight: '33%' } } }}
                                    >
                                        {/* select default */}
                                        {(cornDryTarget === defaultHarvest.defaultCornDryingTarget) && <MenuItem key={defaultHarvest.defaultCornDryingTarget} value={defaultHarvest.defaultCornDryingTarget} disabled>{defaultHarvest.label}</MenuItem>}

                                        {cornDryingList.map((itemDry) => {
                                            return <MenuItem key={itemDry} value={itemDry}>{itemDry}% {labelPercentageHarvestTarget}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        );
                    } else { //Soit : cas Maïs Ensilage (ou Maïs Grain sans la personnalisation possible ; ou autre culture): 
                        const labelPercentageHarvestTarget = 
                            (params.row.cornType === CornType.Ensilage) ? StringTranslate.dryMaterial:
                            (params.row.cornType === CornType.Grain) ? StringTranslate.humidity : "";
                            
                        return (
                            <Box
                                style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                                sx={{ width: "100%" }}
                            >
                                <FormControl fullWidth size="small">
                                    <Select
                                        error={(params.value === undefined) || (params.value <= defaultHarvest.id)}
                                        id="select-percentage"
                                        value={(params.value === undefined) ? defaultHarvest.id : params.value}
                                        label=""
                                        onChange={(newValue) => this.handleChangeharvestVarietyCharacteristicsPercentage(params.row.parcelId, newValue)}
                                        disabled={(harvestVarietyCharacteristicsDicoCounter <= 0) || (varietyId === defaultHarvest.id)}
                                    >
                                        {/* select default */}
                                        {(params.value === defaultHarvest.id) && <MenuItem key={defaultHarvest.id} value={defaultHarvest.id} disabled>{defaultHarvest.label}</MenuItem>}

                                        {varietyInfos.map((vi) => {
                                            return <MenuItem key={vi.id} value={vi.id}>{vi.percentageHarvestTarget}% {labelPercentageHarvestTarget}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        );
                    }
                }
            });

            /* ↓ ajout colonne visible - (date de semis) ↓ */
            const currentYear = new Date().getFullYear();
            const fisrtDayOCurrentYear = new Date(currentYear, 0, 1);
            const listOfParcelDetails = dicoFunction();
            let columnFund = undefined;
            if (listOfParcelDetails) {
                columnFund = listOfParcelDetails.find(column => (column.name === "dateSemi"));
            }
            if (columnFund === undefined) {
                columnFund = { name: "dateSemi", libeleColumn: "Date de semis" };
            }
            newColumns.push({
                headerName: columnFund.libeleColumn,
                field: "sowingDate",
                width: 170,
                type: "date",
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{columnFund.libeleColumn} *</Typography>)
                },
                renderCell: params => {
                    return (
                        <Box
                            style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                            sx={{ width: "100%" }}>
                            <FormControl>
                                <CustomDatePicker
                                    label={StringTranslate.libeleColumn}
                                    value={((params.value !== null) && (params.value !== undefined) && (params.value !== '')) ? new Date(params.value) : null}
                                    onChange={(newValue) => this.handleChangeSowingDate(params.row.parcelId, newValue)}
                                    placeholder={StringTranslate.formatDatePlaceHolder}
                                    //minDate={fisrtDayOCurrentYear}
                                    maxDate={new Date(currentYear + 1, 0, 1)}
                                    size={'small'}
                                />
                            </FormControl>
                        </Box>
                    )
                }
            });

            /* ↓ ajout colonne visible - (date de floraison) ↓ */
            newColumns.push({
                headerName: `Date de floraison`,
                field: "floweringDate",
                minWidth: 210,
                hideable: false,
                renderHeader: (params) => {
                    return (<Typography fontWeight='bold' >{StringTranslate.libelecolumnfloraison}</Typography>)
                },
                renderCell: params => {
                    return (
                        <Box
                            style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                            sx={{ width: "100%" }}
                        >
                            <FormControl>
                                <CustomDatePicker
                                    label={StringTranslate.libeleColumn}
                                    value={((params.value !== null) && (params.value !== undefined) && (params.value !== '')) ? new Date(params.value) : null}
                                    onChange={(newValue) => this.handleChangeFloweringDate(params.row.parcelId, newValue)}
                                    placeholder={StringTranslate.formatDatePlaceHolder}
                                    error={false}
                                    minDate={(params.row.sowingDate !== null) ? new Date(params.row.sowingDate) : fisrtDayOCurrentYear}
                                    maxDate={new Date()}
                                    actions={['clear']}
                                    size={'small'}
                                    clearable={true}
                                    disableFuture={true}
                                    shouldRespectLeadingZeros={true}
                                />
                            </FormControl>
                        </Box>
                    )
                }
            });

        //}

        return newColumns;
    }

    /* fonction permettant d'extraire les données qui seront utilisées dans le visuel */
    initialiseDatas(parcelIdFilteredList) {
        const { parcelDico, thumbnailParcelDico, harvestDico, harvestDicoCounter, clientId, specificInfosParcelIdsDone } = this.props;

        let parcels = ParcelsHelper.getParcelsDicoFromParcelIdList(parcelDico, parcelIdFilteredList);
        let newDatas = [];


        for (var itemPropName in parcels) {
            let temp = [];
            const parcel = parcels[itemPropName];

            if (parcel && ((specificInfosParcelIdsDone.length <= 0) || (specificInfosParcelIdsDone.find(id => id === parcel.id) === undefined))) {
                const thumbnailParcelItem = ParcelsHelper.selectParcelFromDicoById(thumbnailParcelDico, parcel.id);

                let harvest = undefined;
                if (harvestDicoCounter > 0) {
                    harvest = HarvestHelper.selectHarvestFromDicoByParcelId(harvestDico, parcel.id);
                }

                temp.push(parcel.id);
                temp.push(parcel.name);
                if (parcel.details) {
                    for (let itemDetailName in parcel.details) { 
                        const propValue = parcel.details[itemDetailName];
                        let value = (propValue) ? propValue : '';
                        if (itemDetailName.toLowerCase() === 'dateSemi') {
                            if (propValue && (propValue instanceof (Date))) {
                                value = DateHelper.formati18n(propValue, 'P');
                            }
                            else if (propValue && (DateHelper.getDateFromString(propValue) instanceof Date)) {
                                value = DateHelper.formati18n(new Date(propValue), 'P');
                            }
                        }

                        temp.push(value);
                    }
                }
                let parcelCrop = (parcel.details.length < 4) ? undefined : temp[3];

                let cornType = (harvest !== undefined) ?
                    (harvest.isGrain === undefined) ?
                        HarvestHelper.getCornTypeByCrop(parcelCrop) :
                        ((harvest.isGrain === true) ? CornType.Grain : CornType.Ensilage) :
                    HarvestHelper.getCornTypeByCrop(parcelCrop);

                const harvestTarget = lodashGet(harvest, 'dryOrHumidityThreshold', defaultHarvest.defaultCornDryingTarget);
                //Personnalisation du rendu suivant s'il s'agit Soit du maïs grain (Soit autres cas == autre culture ou Maïs ensilage ou pas les données personnalisant les % d'objectifs de récolte)
                //Rq: Dans ce cas, on a reçu un entier en guise de valeur (et pas une info de variété)
                /*if (((enumCrop === Crop.Corn) && (cornType === CornType.Grain)) && 
                    (cornDryingList !== undefined) && (cornDryingCounter > 0)) { //si on n'a pas la plage de % d'objectifs possibles, on gére comme du maïs ensilage !
                    cornDryTarget = lodashGet(harvest, 'dryOrHumidityThreshold', undefined);
                }*/

                newDatas.push({
                    id: parcel.id,
                    actions: parcel,
                    clientId: clientId,
                    parcelId: parcel.id,
                    parcel: parcel,
                    parcelName: temp[1],
                    sowingDate: lodashGet(harvest, "sowingDate", (parcel.details.length < 6) ? undefined : temp[5]),
                    thumbnailInfos: thumbnailParcelItem,
                    cornType: cornType,
                    TotalDegreeDaysUse: lodashGet(harvest, "TotalDegreeDaysUse", undefined),
                    percentage: harvestTarget,
                    floweringDate: lodashGet(harvest, "floweringDate", undefined),
                    estimatedDate: lodashGet(harvest, "estimatedDate", undefined),
                    degreeOfCertainty: lodashGet(harvest, "degreeOfCertainty", undefined),
                    generationDate: lodashGet(harvest, "generationDate", undefined),
                    harvestVarietyId: lodashGet(harvest, "harvestVarietyId", defaultHarvest.id),
                    harvestVarietyInfoId: lodashGet(harvest, "harvestVarietyInfoId", 0), //Uniquement si ce n'est pas un Maïs Grain (dont on a tout ce qu'il faut pour personnaliser la 'objectif de récolte) !
                    //sinon, on utilisera 'percentage'
                    isGrain: lodashGet(harvest, "isGrain", false),
                    harvestCrop: lodashGet(harvest, "harvestCrop", undefined),
                });

            }
        }
        return newDatas;
    }

    /* Fonction permettant de lancer le calcul pour avoir la date approximative de récolte des parcelles de maïs */
    onStartManagement() {
        const { datas } = this.state;
        const { generateResults, clientId, harvestVarietyCharacteristicsDico, currentCrop, parcelIdsSelected } = this.props;

        if (parcelIdsSelected.length > 0) {
            let datasToManage = [];
            datas.forEach(data => {//pour éviter l'avertissement suivant : "Function declared in a loop contains unsafe references to variable(s) 'sowingDate'" !
                if (parcelIdsSelected.includes(data.id)) {
                    //La date de semis, le type de maïs, la variété de maïs, le pourcentage (d'humidité ou de matière sèche) sont obligatoires pour lancer le calcul
                    if (!isNaN(new Date(data.sowingDate)) && (data.sowingDate !== null) &&
                        ((!isNaN(new Date(data.floweringDate) && (new Date(data.floweringDate).getTime() > new Date(data.sowingDate).getTime())) ||
                        isNaN(new Date(data.floweringDate)))) &&
                        (data.cornType !== CornType.None) &&
                        (data.harvestVarietyId > 0) && (data.percentage > 0)) {
                        
                        let element = {
                            id: data.id,
                            clientId: data.clientId,
                            parcelId: data.parcelId,
                            isGrain: (data.cornType === CornType.None) ?
                                undefined :
                                (data.cornType === CornType.Ensilage ?
                                    false : true),
                            dryOrHumidityThreshold: data.percentage, 
                            //TotalDegreeDaysUse: ..., //Rq : pas utile à transmettre car définit côté API ! 
                            harvestVarietyId: harvestVarietyCharacteristicsDico[data.harvestVarietyId].id,
                            harvestVarietyInfoId: data.harvestVarietyInfoId,
                            harvestCrop: {
                                enumCrop: currentCrop,
                            },
                            sowingDate: data.sowingDate,
                            floweringDate: data.floweringDate,
                            estimatedDate: data.estimatedDate,
                            degreeOfCertainty: data.degreeOfCertainty,
                            generationDate: data.generationDate,
                        };

                        datasToManage.push(element);
                    }
                }
            })

            //Si des données sont manquantes pour lancer le calcul
            this.setState({
                missingDatasToStartManagement: (datasToManage.length < parcelIdsSelected.length),
                generateResultsDoneCorrectly: (datasToManage.length > 0) ? true : false, 
            });

            if (datasToManage.length > 0) {
                generateResults(clientId, datasToManage);
            }
        }
    }

    handleCloseConfirmDialogAndRegister = (register = false) => {
        const { datasToSave } = this.state;
        const { goToNextStep, saveHarvests } = this.props;

        //Enregistrement des données :
        if (register === true) {
            //demande la sauvegarde
            //La redirection vers l'étape 1 est faite dans le saveDatas
            saveHarvests(datasToSave);
        }
        else {
            //Redirection vers létape 1 : 
            if (goToNextStep) {
                goToNextStep(HarvestStep.CHOIX_PARCELS);
            }
        }

        this.setState({
            datasToSave: [],
            openRegisteringDialog: false,
        });

    }

    /**
     * Fonction permettant de generé un tableau qui nous indique les parcelles qui on une varieté en train de charger
     * @returns {number[]} un tableau des id parcelles en train de charger
     */
    initializeLoadingVarieties() {
        const { parcelIdsSelected, parcelVarietiesMapping, harvestDico } = this.props;

        let loadingVarieties = [];

        // Si la parcelle est dans notre selection, et qu'elle ne se trouve pas dans le dico des anciennes harvest, et qu'elle n'a jamais été demandé,
        // alors elle sera en chargement
        parcelIdsSelected.forEach(parcelId => {
            const harvest = lodashGet(harvestDico, parcelId, undefined);

            if (parcelVarietiesMapping[parcelId] === undefined && harvest === undefined) {
                loadingVarieties.push(parcelId);
            }
        });        

        return loadingVarieties;
    }

    /* Ferme le dialog des messages d'erreur */
	closeDialogOfErrors = () => {
		const { clearHarvestMessageError } = this.props;

		this.setState({
			openDialogOfErrors: false,
		});

        if (clearHarvestMessageError)
		    clearHarvestMessageError();
	}


    ///////////////////////////////////////////////////////////////////////////
    // fonction de rendu visuel - layout général
    ///////////////////////////////////////////////////////////////////////////
    render() {

        const { showProfilMenuDialog, generatingHarvestDates, savingDatasBeforeGoBackToStep1, openPopupVariety /* , updateNbRowsPerPageTableHarvests */ } = this.props; // fonctions mapDispatchToProps

        const { datas, currentRowsOfTable, searchFilter, openRegisteringDialog, openDialogOfErrors, variety, action  } = this.state;

        return (
            <>
                {/* ↓↓ Partie visuel - affichage Pop d'une erreur ↓↓ */}
                {(openDialogOfErrors === true) && (
                    <AlertDialog popup={this.popupErrorDialog} handleChangePopup={this.closeDialogOfErrors} />
                )}

                {/* ↓↓ Partie visuel - affichage Popup pour la modification d'une variété ↓↓ */}
                {openPopupVariety ?
                    <VarietyForm variety={variety} action={action}/>
                : null}
                {/* ↓↓ partie - dialog de confirmation d'enregistrement ou non avant de retounrer à l'étape 1 ↓↓ */}
                <Dialog open={openRegisteringDialog}
                    onClose={() => this.handleCloseConfirmDialogAndRegister(true)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{StringTranslate.revenir}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{StringTranslate.saveDatasBeforeGoingBackToFirstStep}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleCloseConfirmDialogAndRegister(false)} variant="outlined" color="primary" autoFocus>
                            {StringTranslate.dontSaveDatas}
                        </Button>
                        <Button 
                            startIcon={(savingDatasBeforeGoBackToStep1 === true) ? <CircularProgress /> : null}
                            onClick={() => this.handleCloseConfirmDialogAndRegister(true)} variant="contained" color="primary">
                            {StringTranslate.enregistrer}
                        </Button>
                    </DialogActions>
                </Dialog>


                <TableContainer>
                    <Grid style={{ width: '100%' }} item xs={12}>
                        {/* partie tableau */}
                        <CustomDataGrid
                            disableSelectionOnClick={true}
                            tableName={TableType.harvests}
                            pageSize={10}
                            updateNbRowsPerPageTable={undefined}      // ==> pas utilsé dans customDataGrid
                            keepNonExistentRowsSelected={searchFilter !== "" ? true : false}
                            Toolbar={this.CustomToolbar}
                            toolbar={{
                                onStartManagement: () => this.onStartManagement(),
                                harvestDatas: datas,
                                //Concerne la zone de recherche dans le tableau :
                                value: searchFilter,
                                onChange: (event) => this.requestSearch(event.target.value, datas),
                                clearSearch: () => this.requestSearch('', datas),

                                showProfilMenuDialog: () => showProfilMenuDialog(ProfilIndex.aide_Fumure),
                            }}
                            loading={(generatingHarvestDates === true) || (savingDatasBeforeGoBackToStep1 === true)}
                            rows={(searchFilter !== "") ? currentRowsOfTable : datas}
                            columns={this.columns}
                            checkBoxActive={false}
                            disableColumnFilter={true}
                        />
                    </Grid>

                </TableContainer>

                {/* Boutons :
                 - retour vers l'étape 1 (choix des parcelles) */}
                <Grid container marginTop={3}>
                    <Grid item xs={4} sm={4}>
                       <Button 
                        variant="text" 
                        startIcon={ (savingDatasBeforeGoBackToStep1 === true) ? <CircularProgress color="inherit" size={20}/> : null }
                        disabled={savingDatasBeforeGoBackToStep1 === true}
                        onClick={this.goBackToParcelsChoice}>
                            {StringTranslate.revenir}
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={4}>

                    </Grid>

                    <Grid item xs={12} sm={4} sx={{ [theme.breakpoints.only('xs')]: { marginTop: 1 } }}>

                    </Grid>
                </Grid>

            </>
        );
    }
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant HOComponent */
const mapStateToProps = state => ({
    /* Harvest */
    errorHarvest: lodashGet(state, 'harvestData.errorMessage', undefined),
    parcelIdsSelected: lodashGet(state, 'harvestData.parcelIdsSelected', {}),
    harvestDico: lodashGet(state, 'harvestData.harvestDico', {}),
    harvestDicoCounter: lodashGet(state, 'harvestData.harvestDicoCounter', 0),
    varietiesCharacteristicsAlreadyLoaded: lodashGet(state, 'harvestData.varietiesCharacteristicsAlreadyLoaded', false),
    loadingVarietiesCharacteristics: lodashGet(state, 'harvestData.loadingVarietiesCharacteristics', false),
    harvestVarietyCharacteristicsDico: lodashGet(state, 'harvestData.harvestVarietyCharacteristicsDico', {}),
    harvestVarietyCharacteristicsDicoCounter: lodashGet(state, 'harvestData.harvestVarietyCharacteristicsDicoCounter', 0),
    generatingHarvestDates: lodashGet(state, 'harvestData.generatingHarvestDates', false),
    currentCrop: lodashGet(state, 'harvestData.currentCrop', false),
    specificInfosParcelIdsDone: lodashGet(state, 'harvestData.specificInfosParcelIdsDone', []),
    savingDatasBeforeGoBackToStep1: lodashGet(state, 'harvestData.savingDatasBeforeGoBackToStep1' , false),
    parcelVarietiesMapping: lodashGet(state, 'harvestData.parcelVarietiesMapping', {}),
    openPopupVariety: lodashGet(state, 'harvestData.openPopupVarietyForm', false),
    addingVariety: lodashGet(state, 'harvestData.addingVariety', false),
    parcelIdFocused: lodashGet(state, 'harvestData.parcelIdFocused', -1),
    lastAddedVariety: lodashGet(state, 'harvestData.lastAddedVariety', []),
    
    cornDryingList: lodashGet(state, 'harvestData.cornDryingList', []), //spécifique maïs Grain !!!!
    cornDryingCounter: lodashGet(state, 'harvestData.cornDryingCounter', 0), //spécifique maïs Grain !!!!
    cornMinDryingTarget: lodashGet(state, 'harvestData.cornMinDryingTarget', defaultHarvest.defaultCornDryingTarget), //spécifique maïs Grain !!!!
    cornMaxDryingTarget: lodashGet(state, 'harvestData.cornMaxDryingTarget', defaultHarvest.defaultCornDryingTarget), //spécifique maïs Grain !!!!

    /* Settings */
    language: lodashGet(state, 'settingsData.settings.language', StringTranslate.getLanguage()),

    /* Parcel */
    parcelDico: lodashGet(state, 'parcelsData.parcelDico', StringTranslate.getLanguage()),
    thumbnailParcelDico: lodashGet(state, 'parcelsData.thumbnailParcelDico', {}),

    //Infos provenant du reducer 'clientUserData':
    clientId: lodashGet(state, 'clientUserData.clientDatas.id', -1),
});

/* fonction permettant de fournir les fonctions (actions) au composant HOComponent */
const mapDispatchToProps = dispatch => ({
    showProfilMenuDialog: (index) => dispatch(ActionShowProfilMenuDialog(index)),
    goToNextStep: (step) => dispatch(ActionGoToStepOfHarvest(step)),
    //updateNbRowsPerPageTableHarvests: (rowsPerPage) => dispatch(UpdateNbRowsPerPageTable(rowsPerPage, TableType.harvests)),
    generateResults: (clientId, datasToManage) => dispatch(ActionGenerateResults(clientId, datasToManage)),
    saveHarvests: (harvests) => dispatch(ActionSaveHarvests(harvests)),
    selectIdsParcels: (parcelIdsSelected) => dispatch(ActionSelectIdsParcelsToStartManagement(parcelIdsSelected)),
    openPopupVarietyForm: (open) => dispatch(ActionOpenPopupVarietyForm(open)),
    getParcelIdFocused: (parcelId) => dispatch(ActionGetParcelIdFocused(parcelId)),
    resetParcelAndVarietyIds: () => dispatch(ActionResetParcelAndVarietyIdsSaved()),
    clearHarvestMessageError: () => dispatch(ActionClearHarvestMessageError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SpecificInfosManagement);