import React from 'react';
import CardShowed from './cardShowed.js';
import 'react-credit-cards/es/styles-compiled.css';

import {
    Grid, Button, Typography, CircularProgress,
    Checkbox, Link, Stack, Dialog, DialogTitle, DialogContent, DialogActions,
    IconButton, InputLabel, FormControlLabel, Box
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe
} from 'react-stripe-elements';
import sendError from '../../utils/errorService.js';
import L from 'leaflet';
import ConstantsContact from '../../utils/constantsContact.js';
import { ConfigAuthent } from "../../utils/configAuthent.js";

import getTheme from "../../themes/index.js";
let theme = getTheme();

/* constante (style) en lien avec les les éléments react-stripe-elements */
const options = {
    style: {
        base: {
            letterSpacing: '0.025em',
        },
        invalid: {
            color: theme.palette.error.main,
        }
    }
};

/* constantes de configuration */
const strings = {
    beginnerNumber: '**** **** ****', //beginnerNumber:'xxxx xxxx xxxx', //beginnerNumber:'0000 0000 0000', //beginnerNumber:'XXXXXXXXXXXX', //
    number: '****************',
    expiry: 'XXXX',
    cvc: 'XXX',
};

// inspiré de https://codesandbox.io/s/ovvwzkzry9
class StripeCreditCardView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            focused: '',
            errorCardNumber: undefined,
            errorExpiry: undefined,
            errorCvc: undefined,
            disabled: true,
            onSubmit: false,
            errorSubmit: undefined,
            // creditCard: (props.creditCard) ? props.creditCard : undefined, //pas utilisé uniquement depuis 'this.props' depuis render car si demande le changement de CB...
            // checkedCGV: false, // dans le cas de la case à cocher des CGV
            popUpChangeCard: false,
            newCardDialog: false,
        };

        this.changeCheckedCGV = this.changeCheckedCGV.bind(this);
        // this.handleChangeCheckedCGV = this.handleChangeCheckedCGV.bind(this);
        this.handleSubmitNewCard = this.handleSubmitNewCard.bind(this);
        this.handleResetForEditNewCard = this.handleResetForEditNewCard.bind(this);
        this.createCustomer = this.createCustomer.bind(this);
        this.handleCloseNewCardDialog = this.handleCloseNewCardDialog.bind(this);
        // this.compareExpDate = this.compareExpDate.bind(this);
    }

    /* fonction de validation des données - en lien avec le bouton payer */
    onValidate = () => {
        let isReadyToSubmit = (this.cardNumberReady && this.cardExpiryReady && this.cardCvcReady && this.props.checkedCGV) ? false : true; // bolléen de désactivation du bouton
        this.setState({ disabled: isReadyToSubmit });
    }

    /* fonction de callback liée aux changements de valeur du champ (formulaire) */
    handleChangeCardNumber = (ev) => {
        //si ev.error non-null, alors utiliser 'ev.error.message' ! on pourrai affiché un contour rouge...
        //valide si ev.complete = true !
        this.cardNumberReady = (!ev.error) && (ev.complete);
        this.setState({ errorCardNumber: (ev.error ? ev.error.message : undefined), errorSubmit: undefined });
        this.onValidate();
    }

    /* fonction de callback liée aux changements de valeur du champ (formulaire) */
    handleChangeCardExpiry = (ev) => {
        //si ev.error non-null, alors utiliser 'ev.error.message' ! on pourrai affiché un contour rouge...
        //valide si ev.complete = true !
        this.cardExpiryReady = (!ev.error) && (ev.complete);
        this.setState({ errorExpiry: (ev.error ? ev.error.message : undefined), errorSubmit: undefined });
        this.onValidate();
    }

    /* fonction de callback liée aux changements de valeur du champ (formulaire) */
    handleChangeCardCvc = (ev) => {
        //si ev.error non-null, alors utiliser 'ev.error.message' ! on pourrai affiché un contour rouge...
        //valide si ev.complete = true !
        this.cardCvcReady = (!ev.error) && (ev.complete);
        this.setState({ errorCvc: (ev.error ? ev.error.message : undefined), errorSubmit: undefined });
        this.onValidate();
    }

    /* fonction de callback liée aux changements de valeur du champ (case à cocher des CGV) */
    // handleChangeCheckedCGV = function() {
    //     // this.setState({checkedCGV:!this.state.checkedCGV}, () => this.onValidate());
    //     this.props.checkedCGV = !this.props.checkedCGV;
    // }

    changeCheckedCGV(event) {
        this.props.handleChangeCheckedCGV();
    }

    /* callback en lien avec l'animation de la carte bancaire */
    handleFocusElement = (value) => {
        if (!L.Browser.ie) // problème d'affichage avec IE
            this.setState({ focused: value });
    }

    /* fonction permettant de supprimer les informations d'une carte bancaire lors de sa mise en édition ? */
    handleResetForEditNewCard() {
        this.setState({ newCardDialog: true }); //pour afficher la zone de saisie de la nouvelle CB
    }

    handleCloseNewCardDialog() {
        this.setState({ newCardDialog: false, errorSubmit: undefined });
    }

    compareExpDate = ( expDate ) => {
        // True pour une date expirée
        // False si elle ne l'est pas

        // const { creditCard } = this.props;

        var currentDate = new Date();
        var currentMonth = currentDate.getMonth() + 1;
        var currentYear = currentDate.getFullYear();

        if ( expDate.split(' / ')[1] > (currentYear) ) {
            return false;
        } 
        else if ( (expDate.split(' / ')[1] === (currentYear)) && ( expDate.split(' / ')[0] >= (currentMonth) ) ) {
            return false;
        }
        else {
            return true;
        }
    }

    /* 
     * FONCTION CALLBACK - LANCEMENT DE LA SOUSCRIPTION CLIENT
     * récupération du token associé à la carte de crédit + création customer + souscription du customer à un plan + mise à jour des données
     */
    handleSubmitNewCard(event) {
        if (!this.props.stripe) return;

        if (event) event.preventDefault(); // on annule la propagation de l'événement
        if (this.state.onSubmit === true) return;
        this.setState({ disabled: true, onSubmit: true }); // on désactive le boutton pour éviter le multi click et on on enlève le bouton annuler pour une spinning icon

        // RÉCUPÉRATION D'UN TOKEN à partir des informations de carte bancaire saisi par l'utilisateur (⚠️ - api stripe directement appelé )
        this.props.stripe.createToken()
            .then((result) => {
                //console.log('[token]', result);
                if (result && result.token && result.token.id) // a Token was created successfully.
                    this.createCustomer(result.token.id); // création et souscription customer
                else {
                    sendError('stripeCreditCardView.handleSubmitNewCard-createToken', { 'token': 'null' });
                    this.setState({
                        errorExpiry: undefined, 
                        errorCardNumber: undefined, 
                        errorCvc: undefined, 
                        errorSubmit: (result.error && result.error.message) ? result.error.message : `${StringTranslate.errorCreateCBToken}`, // permet d'afficher le message d'erreur
                        onSubmit: false
                    });
                }
            })
            .catch((err) => {
                sendError('stripeCreditCardView.handleSubmitNewCard-createToken', { "err": err });
                this.setState({
                    errorExpiry: undefined, 
                    errorCardNumber: undefined, 
                    errorCvc: undefined, 
                    errorSubmit: (err && err.message) ? err.message : `${StringTranslate.errorCreateCBToken}`, // permet d'afficher le message d'erreur
                    onSubmit: false
                });
            });
    };

    /**
     *  fonction CRÉATION NOUVEAU CUSTOMER en BDD 
     *  Si la carte bancaire n'est pas valide , alors le customer ne sera pas créé
     * */
    createCustomer(tokenCardId) {
        //console.log('[tokenCardId]', tokenCardId);

        this.props.createStripeCustomer(tokenCardId)
            .then((result) => { // if (result = true) alors on peut demander la souscription du customer à un plan
                //console.log('[createCustomer - result]', result);

                //désormais l'ajout du client Stripe est dissocié de la souscription. (Le client doit valider sur le second bouton, suite au bon enregistrement de la CB)
                if (!result) {
                    sendError('stripeCreditCardView.createStripeCustomer-badResult', { 'err': "retour de création du 'customer' Stripe invalide!", 'clientId': this.props.clientId });
                    this.props.addErrorToSubscriptionProcess(StringTranslate.errorStripeCreateCustomer);
                }
                
                this.props.getClientBilling();
                this.setState({ 
                    newCardDialog: false, onSubmit: false, 
                    errorSubmit: (!result) ? `${StringTranslate.errorSaveCustomer}` : undefined, // permet d'afficher le message d'erreur
                });
            })
            .catch((err) => { // si on est en présence d'une erreur, il y a de fortes chances que le customer n'a pas été créé en BDD
                sendError('stripeCreditCardView.createStripeCustomer-err', { 'err': err, 'clientId': this.props.clientId });
                this.props.addErrorToSubscriptionProcess(StringTranslate.errorStripeCreateCustomer);
                
                this.props.getClientBilling();
                this.setState({ 
                    newCardDialog: false, onSubmit: false,
                    errorSubmit: `${StringTranslate.errorSaveCustomer}`, // permet d'afficher le message d'erreur 
                });
            })
    }

    renderNewCardDialog = () => {
        const { errorCardNumber, errorExpiry, errorCvc,
            onSubmit, errorSubmit, newCardDialog } = this.state;
        const hasError = ((errorCardNumber !== undefined) || (errorExpiry !== undefined) || (errorCvc !== undefined)) ? true : false;

        return (
            <Dialog
                open={newCardDialog}
                onClose={this.handleCloseNewCardDialog}
                id="dialog-new-creditCard">
                <DialogTitle>{StringTranslate.cardNewInput}
                    <IconButton
                        aria-label="close"
                        onClick={this.handleCloseNewCardDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseOutlinedIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent dividers sx={{ maxWidth: "355px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel>{`${StringTranslate.numberelement}`}</InputLabel>
                            <CardNumberElement
                                onChange={this.handleChangeCardNumber}
                                onReady={(el) => el.focus()}
                                onFocus={() => this.handleFocusElement('number')}
                                className='text-field-stripe'
                                {...options}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>{`${StringTranslate.expiryelement}`}</InputLabel>
                            <CardExpiryElement
                                onChange={this.handleChangeCardExpiry}
                                onFocus={() => this.handleFocusElement('expiry')}
                                className='text-field-stripe'
                                {...options}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>{`${StringTranslate.cvcelement}`}</InputLabel>
                            <CardCVCElement
                                onChange={this.handleChangeCardCvc}
                                onFocus={() => this.handleFocusElement('cvc')}
                                className='text-field-stripe'
                                {...options}
                            />
                        </Grid>
                        {/* { (!creditCard) ? <></> :
                            <Grid xs={12}><Typography>La nouvelle carte saisie remplacera celle déjà enregistrée</Typography></Grid>
                        } */}
                        {/* ↓↓ zones des messages d'erreur ↓↓ */}
                        <Grid item xs={12}>
                            {(errorCardNumber !== undefined) && (
                                <Grid item xs={12}>
                                    <Typography color='error' variant="caption">{errorCardNumber}</Typography>
                                </Grid>
                            )}
                            {(errorExpiry !== undefined) && (
                                <Grid item xs={12}>
                                    <Typography color='error' variant="caption">{errorExpiry}</Typography>
                                </Grid>
                            )}
                            {(errorCvc !== undefined) && (
                                <Grid item xs={12}>
                                    <Typography color='error' variant="caption">{errorCvc}</Typography>
                                </Grid>
                            )}

                            {(errorSubmit !== undefined) && (
                                <Grid item xs={12}>
                                    <Typography color='error' variant="caption">{errorSubmit}</Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Stack direction="row" spacing={1} justifyContent="flex-end">
                        <Button
                            color="error"
                            onClick={this.handleCloseNewCardDialog}>
                            {StringTranslate.annuler}
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            onClick={this.handleSubmitNewCard}
                            startIcon={(onSubmit) ? <CircularProgress color="inherit" size={24} /> : undefined} 
                            disabled={hasError}>
                            {onSubmit ? StringTranslate.submitting : StringTranslate.valider} 
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        )
    }

    /* fonction cycle de vie react */
    render() {
        //const { /*RAS*/ } = this.state;
        const { checkedCGV, creditCard } = this.props;

        let cultureValue = StringTranslate.getLanguage();
        if ((!cultureValue) || (cultureValue.length < 2)) {
            cultureValue = 'fr';
        }
        const languageValue = cultureValue.substring(0, 2);

        var oldCard = (creditCard) ? this.compareExpDate(creditCard.expirDateCc) : false;  // False si valable, True si expirée

        //Sur 'CardShowed' on ne met plus ceci: placeholders={{ name: `${StringTranslate.cbName}` }}
        return (<>
            {this.renderNewCardDialog()}

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>

                    {/* ↓↓ élément représentant l'image dynamique de la carte de crédit ↓↓ */}
                    <CardShowed
                        number={(creditCard) ? `${strings.beginnerNumber} ${creditCard.lastNumberCc}` : strings.number}
                        name={(creditCard) ? ' ' : `${StringTranslate.cbName}`}
                        expiry={(creditCard) ? ((creditCard.expirDateCcLight) ? creditCard.expirDateCcLight : creditCard.expirDateCc) : strings.expiry}
                        cvc={strings.cvc}
                        // focused={focused}
                        focused="number"
                        scrambledNumber={(creditCard) ? true : false}
                        placeholders={{ name: '' }}
                        locale={{ valid: `${StringTranslate.valid}`, }}
                    />
                    {/* ↓↓ Partie affichage des cartes acceptées ↓↓ */}
                    <Box sx={{ mt: 2, ml: 'calc(50% - 125px)'}}>
                        <img 
                            src={`${ConfigAuthent.ConstAndDefault.UrlTradCompteResources}${languageValue}/stripe-payment_${cultureValue}.jpg`} 
                            alt='securePayment' 
                            className='ssp-credit-card-payment-img'>
                        </img>
                    </Box>
                </Grid>

                {/* ↓↓ Partie - si on a déjà une carte de crédit en cours ↓↓ */}
                <Grid item xs={12} sm={12} md={6}>
                    
                    <Grid container spacing={2}>

                        {(!creditCard) ? undefined :
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1">{StringTranslate.subheader}</Typography>
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={2}>
                                        <Typography variant='h4'>{StringTranslate.endcard}</Typography>
                                        <Typography>{creditCard.lastNumberCc}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={2}>
                                        <Typography variant='h4'
                                            sx={{
                                                ...( (oldCard) && {
                                                    color: theme.palette.error.main
                                                })
                                            }}
                                        >
                                            {StringTranslate.expiration}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                ...( (oldCard) && {
                                                    color: theme.palette.error.main
                                                })
                                            }}
                                        >
                                            {creditCard.expirDateCc}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={checkedCGV} onChange={this.props.handleChangeCheckedCGV} color="primary" />}
                                label={<>
                                    <Typography>{StringTranslate.cgvtext}</Typography>
                                    <Link target='_blank' href={ConstantsContact.UrlCGV}>{StringTranslate.cgvlink}</Link>
                                </>}
                                labelPlacement="end"/>
                            </Grid>
                            <Grid item xs={12}>
                            <Button
                                variant={(!creditCard) ? "contained" : "outlined"}
                                color="primary"
                                onClick={this.handleResetForEditNewCard}>
                                {(!creditCard) ? (StringTranslate.cardNewInput) : (StringTranslate.changeOfCard)}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>);
    }
}

export default injectStripe(StripeCreditCardView);
