import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import getTheme from '../../../themes/index';

let theme = getTheme();


function IconeObservationAutre(props: SvgIconProps) {
    return ( 
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" strokeWidth="1.8" stroke="none" fill="current">
              <g>
                <path d="M7,18.56c-.23-.35-.9-1.37-1-1.67a11.06,11.06,0,0,1-1.24-5.15A11.27,11.27,0,1,1,26,16.89c-.16.3-.82,1.32-1.07,1.69L16,31.17Z" fill={theme.palette.primary.main}/>
                <path d="M16,1A10.78,10.78,0,0,1,26.77,11.74a10.6,10.6,0,0,1-1.19,4.92c-.12.24-.68,1.1-1,1.61L16,30.3l-8.54-12c-.36-.54-.92-1.4-1-1.64a10.59,10.59,0,0,1-1.18-4.92A10.78,10.78,0,0,1,16,1m0-1A11.78,11.78,0,0,0,5.53,17.12c.2.39,1.1,1.73,1.1,1.73L16,32l9.38-13.18s.89-1.34,1.09-1.73A11.76,11.76,0,0,0,16,0Z" fill={theme.palette.primary.main}/>
              </g>
              <circle cx="15.81" cy="11.6" r="2.94" fill={theme.palette.common.white}/>
            </svg>
        </SvgIcon>
    );
}

export default IconeObservationAutre;