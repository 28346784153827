import React from 'react';
import { connect } from 'react-redux';
import { Tooltip, Typography, Fab, Box } from '@mui/material';
import LayersIcon from '@mui/icons-material/Layers';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import { ActionLayersMenuOpen, ActionSetAnchorLayersPanel } from '../../redux/actions/contextApp.js';
import LayerSelectionMenu from '../../components/layerSelectionMenu';
 
import getTheme from '../../themes/index.js';

let theme = getTheme();

/**
 * composant bouton de sélection de layers - (le fonctionnel se trouve dans layerSelection.jsx )
 */
function LayerSelectionButtonControl(props) {

    /**
     * fonction callback lors du clique du bouton de sélection des layers 
     */
    const handleClickLayerSelectionButton = (event) => {
        if (event)
            event.stopPropagation(); // permet d'éviter que l'événement clique ne soit remonté à la carte (évite de zoomer)

        if (window.dataLayer) // google tag manager
            window.dataLayer.push({ 'event': 'suiviBouton', 'action': 'couche carto' });

        props.openLayersMenu(); //ouverture du menu des différentes couches
        
        if (props.setAnchorLayersPanel)
            props.setAnchorLayersPanel(event.currentTarget);
    }

    return (

        <Box 
            className='leaflet-control'
            sx={{
                zIndex:"1100"
            }}
        >

            {/* ↓ bouton - sélection de layer ↓ */}
            <div>
                <Tooltip title={<Typography color="inherit">{StringTranslate.tooltipcouche}</Typography>} placement="left">
                    <Fab 
                        size="small" 
                        onClick={handleClickLayerSelectionButton}
                        sx={{
                            backgroundColor: theme.palette.common.white,
                            color:theme.palette.primary.main
                        }}
                    >
                        <LayersIcon />
                    </Fab>
                </Tooltip>
            </div>
            
            {(props.menuLayerSelectionOpened) && <LayerSelectionMenu />}
        </Box>
    )
}

/* fonction permettant de passer le state global (ou fraction) de l'application au composant */
const mapStateToProps = function (state) {
    return {
        menuLayerSelectionOpened: (state && state.contextAppData) ? state.contextAppData.menuLayerSelectionOpened : false,

        settings: state.settingsData.settings, // seul moyen trouvé pour rafraichir le visuel (traduction) du fait que maintenant on rentre dans l'application sans attendre les informations du client
    };
}

/* fonction permettant de fournir les fonctions (actions) au composant */
const mapDispatchToProps = dispatch => ({
    openLayersMenu: () => dispatch(ActionLayersMenuOpen()),
    setAnchorLayersPanel: (target) => dispatch(ActionSetAnchorLayersPanel(target)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LayerSelectionButtonControl);