import React from "react";
import { connect } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Slide } from '@mui/material';
import { ActionClosePurchaseStoreResult, ActionGetClientDatasAfterSubscriptionAsk } from '../../redux/actions/clientUser';
import { ActionGoToMap } from '../../redux/actions/contextApp';
import StringTranslate from '../../assets/i18n/stringLanguage.jsx';
import sendError from '../../utils/errorService.js';

import getTheme from "../../themes/index.js";
let theme = getTheme();

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


////////////////////////////////////////////////////////////////////////////////////////
// Composant appliqué par dessus (position:absolute) le composant RenderMaps 
//  pour afficher le résultat de l'achat depuis un Store d'application mobile
////////////////////////////////////////////////////////////////////////////////////////
class DisplayPurchaseStoreResult extends React.Component {
    /* constructeur */
    constructor(props) {
        super(props);

        this.state = {
            //pas nécessaire: open: (props && (props. !== undefined) ? props. : false,
            
            //fullScreen: theme.breakpoints.down('md'), //<-A activer lorsque l'on sera avec le thème @mui !
            fullScreen: false,
        }

        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }

    /* 
     * fonction - MISE A JOUR DONNÉES + REDIRECTION ÉTAPE 4
     */
    updateDatasAfterSubscription() {
        // ↓ on récupère les données mises à jour suite à une souscription (client, clientBilling, parcels (parcelles nouvellement activées)) ↓
        this.props.getClientDatasAfterSubscription()
            .then(() => {
                /* ↓ demande la fermeture de la popup d'incitation en précisant l'action utilisateur */
                if (this.props.closePurchaseStoreResult) {
                    this.props.closePurchaseStoreResult();
                }
        
                //RQ : l'actualisation des données client et du parcours d'achat sera déjà réalisé par la méthode appelante !
                this.props.goToMap(); // on redirige l'utilisateur vers la carte
            })
            .catch((error) => {
                sendError('DisplayPurchaseStoreResult-updateDatasAfterSubscription', { 'err': "erreur sur le retour de 'getClientDatasAfterSubscription'.", 'error': error, 'clientId': this.props.clientId } );
            });
    }

    /* fonction lancée pour fermer l'écran de notification du résultat d'achat */
    handleCloseDialog() {
        this.updateDatasAfterSubscription();
    }

    /* cycle de vie react */
    render() {
        const { fullScreen } = this.state;
        const { showPurchaseStoreResult, errorMessage } = this.props; //proviennent de Redux !

        return (
            <Dialog fullScreen={fullScreen} open={showPurchaseStoreResult} TransitionComponent={Transition} 
                onClose={this.handleCloseDialog} aria-labelledby="responsive-dialog-title">
                {(showPurchaseStoreResult === true) && (<>
                    <DialogTitle id="responsive-dialog-title">{StringTranslate.dlgTitlePurchaseStoreResult}</DialogTitle>
                    <DialogContent>
                            {/* <Typography variant="body1" component="span">
                                {StringTranslate.dlgContentPurchaseStoreResult}
                            </Typography> */}
                            {((errorMessage !== undefined) && (errorMessage !== '')) ? (
                                <DialogContentText sx={{ color: theme.palette.error.main }}>
                                    {errorMessage}
                                </DialogContentText>
                            ) : (
                                <DialogContentText>
                                    {StringTranslate.messagePurchaseStoreGoodResult}
                                </DialogContentText>
                            )}
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' color="primary" onClick={this.handleCloseDialog} autoFocus>
                            {StringTranslate.btnClosePurchaseStoreResult}
                        </Button>
                    </DialogActions>
                </>)}
            </Dialog>
        );
    }
}            

/* fonction permettant de passer le state global (ou fraction) de l'application au composant AbstractProfil */
const mapStateToProps = state => ({
    showPurchaseStoreResult: (state.clientUserData && state.clientUserData.subscriptionProcess) ? state.clientUserData.subscriptionProcess.showPurchaseOnStoreResult : false,
    errorMessage: (state.clientUserData && state.clientUserData.subscriptionProcess) ? state.clientUserData.subscriptionProcess.error : undefined,
});

/* fonction permettant de fournir les fonctions (actions) au composant AbstractProfil */
const mapDispatchToProps = dispatch => ({
    getClientDatasAfterSubscription: () => dispatch( ActionGetClientDatasAfterSubscriptionAsk() ),
    closePurchaseStoreResult: () => dispatch( ActionClosePurchaseStoreResult() ),
    goToMap: () => dispatch( ActionGoToMap() ),
})

export default connect(mapStateToProps, mapDispatchToProps)(DisplayPurchaseStoreResult);
