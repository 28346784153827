import React from 'react';
import { Grid, Skeleton, Box } from '@mui/material';


class DataGridSkeleton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            spinningIcon: true, // affichage spinning icon
        }
    }

    render() {
        return (
            <Grid container spacing={2}>
                <Grid 
                    item xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >      {/* buttons */}
                    <Skeleton variant="rectangular" animation="wave" sx={{borderRadius:'8px', height:'40px', width:'262px'}}/>
                    <Box sx={{display:'flex', justifyContent:'flex-end'}}>
                        <Skeleton variant="rectangular" animation="wave" sx={{borderRadius:'8px', height:'40px', width:'170px'}}/>
                        <Skeleton variant="rectangular" animation="wave" sx={{borderRadius:'8px', height:'40px', width:'170px', ml:1}}/>
                    </Box>
                </Grid>
                <Grid item xs={12}>      {/* Besoin d'aide */}
                    <Skeleton variant="rectangular" animation="wave" sx={{borderRadius:'8px', height:'30px', width:'95px'}}/>
                </Grid>

                {/* Tableau */}
                <Grid item xs={12}>
                    <Skeleton animation="wave" height={30} width="80%" style={{ marginBottom: 6 }} />
                    <Skeleton animation="wave" height={30} width="80%" style={{ marginBottom: 6 }} />
                    <Skeleton animation="wave" height={30} width="80%" style={{ marginBottom: 6 }} />
                </Grid>
            </Grid>
        )
    }
}

export default DataGridSkeleton;