import React, { Component } from "react";
import { ButtonBase } from "@mui/material";

import getTheme from "../themes/index.js";
let theme = getTheme();

///////////////////////////////////////////////////////////////////////////
// Composant qui représente un bouton de sélection avec un layer
///////////////////////////////////////////////////////////////////////////
class LayerButton extends Component {
    ///////////////////////////////////////////////////////////////////////////
    // fonction de rendu visuel - layout général
    ///////////////////////////////////////////////////////////////////////////
    render() {
        const { src, buttonState, disabled, onSelectLayer, name } = this.props;
        
        return (
            <ButtonBase onClick={onSelectLayer} disabled={disabled}>
                { (buttonState === 'select') && <img src={src} style={{ border: '2px solid ' + theme.palette.primary.main }} className="imgLayer-select" alt={name}/> }
                { (buttonState === 'unselect') && <img src={src}  className="imgLayer-unselect" alt={name}/> }
                { (buttonState === 'unavailable') && <img src={src} className="imgLayer-unavailable" alt={name}/> }
            </ButtonBase>
        );
    }
}

export default LayerButton;
