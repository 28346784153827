import React, { Component } from 'react';
import { connect } from 'react-redux';

import { get as lodashGet, isNil as lodashIsNil } from 'lodash';

import { FormControl, InputLabel, Grid, CircularProgress, Select, MenuItem, Button, Card, Alert, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

import { ActionSelectChosenCrop } from '../../redux/actions/modelisation';
import { ActionShowProfilMenuDialog, ProfilIndex } from '../../redux/actions/contextApp';

import StringTranslate from '../../assets/i18n/stringLanguage';

class ModelisationCropChoice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            chosenCrop: undefined, // Culture de modélisation sélectionnée par le client
        }
    }

    /**
     * Méthode appelée lors du chanment de culture sélectionnée
     * @param {*} event 
     */
    handleChangeCrop(event) {
        this.setState({
            chosenCrop: event.target.value,
        });
    }

    /**
     * Méthode permettant de passée à l'étape suivante 
     * Permet également d'enregistrer la culture sélectionnée dans rédux
     */
    goToNextStep() {
        const { chosenCrop } = this.state;
        const { selectChosenCrop } = this.props;

        selectChosenCrop(chosenCrop);
    }

    render() {
        const { chosenCrop } = this.state;
        const { crops, showProfilMenuDialog } = this.props;

        return (
            <Grid container >
                <Grid item xs={12} marginY={2} >
                    <Card>
                        <Alert severity='info' icon={<InfoOutlined/>}>
                            <Typography>{StringTranslate.modelisationMessageInstructionCrop}</Typography>
                        </Alert>
                    </Card>
                </Grid>

                {/* Bouton d'aide */}
                <Grid item xs={6}>
                    <Button color="secondary" variant="text" onClick={() => showProfilMenuDialog(ProfilIndex.aide_Modelisation)}>
                        {StringTranslate.helpAsk}
                    </Button>
                </Grid>

                <Grid container justifyContent="center" alignItems="center" >
                    <Typography fontWeight="bold" m={2}>{StringTranslate.modelisationCropChoiceTitle}</Typography>
                </Grid>

                <Grid container justifyContent="center" alignItems="center" marginY={2} >
                    <FormControl sx={{ minWidth: 150 }} size="small" >
                        <InputLabel id="select-culture">
                            <Grid container>
                                <Grid item sx={{ mr: 1 }}>
                                    {(crops.length <= 0) && <CircularProgress color="inherit" size={20} />}
                                </Grid>
                                <Grid item>
                                    {StringTranslate.libelecolumnculture}
                                </Grid>
                            </Grid>
                        </InputLabel>
                        <Select
                            labelId="select-culture"
                            id="select"
                            value={chosenCrop || ''}
                            label={`${StringTranslate.searchPlaceholder} *`}
                            onChange={(event) => this.handleChangeCrop(event)}
                            disabled={(crops.length <= 0)}
                        >
                            {crops.map((c) =>
                                <MenuItem key={c.id} value={c}>{c.name}</MenuItem>
                            )}

                        </Select>
                    </FormControl>
                    <Button
                        variant="contained"
                        onClick={() => this.goToNextStep()}
                        sx={{ marginLeft: 2, }}
                        disabled={lodashIsNil(chosenCrop)}
                    >
                        {StringTranslate.modelisationCropChoiceButton}
                    </Button>
                </Grid>
                
                <Grid container justifyContent="center" alignItems="center" marginY={2} >
                    <Typography m={2}>{StringTranslate.modelisationCropChoiceHelper}</Typography>
                </Grid>
                
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        crops: lodashGet(state, 'modelisationData.culturesForModelisation', []),
        
        language: lodashGet(state, 'settingsData.settings.language', StringTranslate.getLanguage()), // Permet de raffraichir le composant lors d'un changement de langue
    };
}

function mapDispatchToProps(dispatch) {
    
    return {
        selectChosenCrop: (chosenCrop) => dispatch(ActionSelectChosenCrop(chosenCrop)),
        showProfilMenuDialog: (index) => dispatch(ActionShowProfilMenuDialog(index)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelisationCropChoice);