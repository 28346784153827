import { MsalProcess, StatesConnection } from '../actions/connection.js';
//import lodashGet from 'lodash/get';
//import lodashIsNil from 'lodash/isNil';
import { extractEmailFrom } from '../../utils/authentUtils';


const initialState = {
    account: undefined,
    emailOfAccount: undefined,
    idToken: undefined,
    accessToken: undefined,
    error: undefined,
    stateConnexion: StatesConnection.connecting,

    //pour l'actualisation du jeton d'authent:
    expiration: 0,
    expiresOn: undefined, //c'est une date, en principe !
    needUpdateToken: false,
    tokenFromSocialAccount: false,
};

function connectionReducer(state = initialState, action) {
    switch (action.type) {

        case MsalProcess.set_account:
            return Object.assign({}, state, {
                account: action.account,
                emailOfAccount: extractEmailFrom(action.account), // on récupère la valeur sinon on aura undefined
                stateConnexion: StatesConnection.connecting,
            })

        case MsalProcess.set_accountAndEmail: { //TODO : Pour mieux gèrer les authents depuis le natif, qui fournira les données avec les entités du natifs (et pas en simulant des données comme MSal Web)
            return Object.assign({}, state, {
                account: action.account,
                emailOfAccount: action.emailUser, // on récupère l'email fourni !
                stateConnexion: StatesConnection.connecting,
            });
        }

        case MsalProcess.set_idtoken:
            return Object.assign({}, state, {
                idToken: action.idToken,
                expiration: (action.idToken) ? action.idToken.expiration : 0,
                expiresOn: action.expiresOn,
                stateConnexion: StatesConnection.connecting,
            })

        case MsalProcess.update_idtoken: {
            if (action.idToken && action.idToken.objectId && (
                (!state.idToken) || (!state.idToken.objectId) || (state.idToken.objectId !== action.idToken.objectId))) {
                return Object.assign({}, state, {
                    idToken: action.idToken,
                    expiration: action.idToken.expiration,
                    expiresOn: action.expiresOn,
                    stateConnexion: StatesConnection.connecting,
                    needUpdateToken: false,
                });
            } else {
                return state;
            }
        }

        case MsalProcess.set_accesstoken:
            return Object.assign({}, state, {
                accessToken: action.accessToken,
                needUpdateToken: false,
            })

        case MsalProcess.setStateConnexion:
            return Object.assign({}, state, {
                stateConnexion: action.stateConnexion,
            })

        case MsalProcess.logout:
            return Object.assign({}, initialState, {
                account: undefined,
                emailOfAccount: undefined,
                idToken: undefined,
                accessToken: undefined,
                error: undefined,
                stateConnexion: StatesConnection.disconnecting,
                needUpdateToken: false,
                tokenFromSocialAccount: false,
            })

        case MsalProcess.logoutAndSignup:
            return Object.assign({}, initialState, {
                account: undefined,
                emailOfAccount: undefined,
                idToken: undefined,
                accessToken: undefined,
                error: undefined,
                stateConnexion: StatesConnection.disconnecting,
                needUpdateToken: false,
                tokenFromSocialAccount: false,
            })

        case MsalProcess.resetPwd:
            return Object.assign({}, state, {
                stateConnexion: StatesConnection.resetingPwd,
                needUpdateToken: false,
                tokenFromSocialAccount: false,
            })

        case MsalProcess.needNewToken:
            return Object.assign({}, state, {
                needUpdateToken: true,
                tokenFromSocialAccount: false,
            })

        case MsalProcess.updateNewAccountSocialProvider:
            return {
                ...state,
                account: {
                    ...state.account,
                    idTokenClaims: {
                        ...state.account.idTokenClaims,
                        country: action.countryStr,
                        extension_Telephone: action.phone
                    }
                },
                //pas besoin de toucher à 'emailOfAccount' qui n'a pas changé !
            }

        case MsalProcess.updateTokensFromNativeSocialProvider:
            return {
                ...state,
                idToken: action.idToken,
                expiresOn: action.expiresOn,
                accessToken: action.accessToken,
                needUpdateToken: false,
                tokenFromSocialAccount: true,
            }

        default:
            return state
    }
}

export default connectionReducer;
